import axios from "axios";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useAlert } from "../../modules/sgl-utils/DialogsProvider";

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

// create context
const PaymentBatchContext = createContext()

const PaymentBatchContextProvider = ({ children }) => {
    const navigate = useNavigate()
    const customerID = useAppSelector(state=> state.showCompany.company_id);
    const [selectedShow, setSelectedShow] = useState('')
    const [selectedShowEndDate, setSelectedShowEndDate] = useState('')
    const [selectedBatch, setSelectedBatch] = useState('')
    const [updateCreateBatchTabData, setUpdateCreateBatchTabData] = useState(false)
    const [updateMoveCreditToAccountTabData, setMoveCreditToAccountTabData] = useState(false)
    const [updateCheckoutEntriesTabData, setUpdateCheckoutEntriesTabData] = useState(false)
    const [updatePostPaymentsTabData, setUpdatePostPaymentsTabData] = useState({show_id: 0, batch_id: 0})
    const [selectedTab, setSelectedTab] = useState(0)
    const [refreshPaymentBatch, setRefreshPaymentBatch] = useState(false)
    const [openedFrom, setOpenedFrom] = useState('') // flag to check if payment batch is opened from listing or add new
    const [prizePreferences, setPrizePreferences] = useState([])
    const [taxFormEnabled, setTaxFormEnabled] = useState(false)

    const {payment_batch_id} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const alertDialog = useAlert()
    useEffect(() => {
        setSelectedShow(0) // clear show selection
        setSelectedBatch(0)
    }, [customerID])

    useEffect(() => {
        setSelectedBatch(isNaN(Number(payment_batch_id)) ? 0 :  Number(payment_batch_id))
        // Set the show If Detail page is open for already existing payment batch
        if(searchParams.get("batch_show_id")){
            setSelectedShow(searchParams.get("batch_show_id"))
        }
    }, [selectedShow])

    useEffect(() => {
        if(!openedFrom){
            if(isNaN(Number(payment_batch_id)) || payment_batch_id == 0){
                // no payment batch id passed in url opened from Add New
                setOpenedFrom('Add New')
            }else{
                // payment batch id is passed in url opened from listing
                setOpenedFrom('Listing')
            }
        }

        // get the prize preferences list
        getMetaData()
    }, [])

    const closePaymentBatchDetail = () => {
        navigate('/payment-batches')
    }

    const getMetaData = async() => {
        axios.get(`${NEST_API_URL}/payment-batches/getMetaData?customer_id=${customerID}`)
        .then(response => {
            if(response?.data?.success){
                setTaxFormEnabled(response?.data?.isTaxFormLookupEnabled)
                response?.data?.prizePreferencesList?.unshift({ label: 'All', value: '' });
                setPrizePreferences(response?.data?.prizePreferencesList)
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
    }

    return (
        <PaymentBatchContext.Provider value={{selectedShow, setSelectedShow, selectedBatch, setSelectedBatch, updateCreateBatchTabData, updateMoveCreditToAccountTabData, updatePostPaymentsTabData, setUpdateCreateBatchTabData, setMoveCreditToAccountTabData, setUpdatePostPaymentsTabData, closePaymentBatchDetail, selectedTab, setSelectedTab, refreshPaymentBatch, setRefreshPaymentBatch, selectedShowEndDate, setSelectedShowEndDate, openedFrom, setOpenedFrom, updateCheckoutEntriesTabData, setUpdateCheckoutEntriesTabData, prizePreferences, taxFormEnabled}}>
            {children}
        </PaymentBatchContext.Provider>
    );
}

// context consumer hook
const usePaymentBatchContext = () => {
    // get the context
    const context = useContext(PaymentBatchContext);
  
    // if `undefined`, throw an error
    if (context === undefined) {
      throw new Error("usePaymentBatchContext was used outside of its Provider");
    }
  
    return context;
};

export { usePaymentBatchContext, PaymentBatchContextProvider }