import { useIntl } from 'react-intl'
import { AccountRegister } from './AccountTab/AccountRegister';
import { ResponsibleParty } from './AccountTab/ResponsibleParty';
import { SummaryByShow } from './AccountTab/SummaryByShow';
import { WithholdingTax } from './AccountTab/WithholdingTax';

const AccountTab = (props) => {
    const intl = useIntl();

    const tabs = ["ACCOUNTREGISTER", "RESPONSIBLEPARTY", "PRIZEMONEYBYSHOW", "WITHHOLDINGTAX"]
    const componentTags = [
        <AccountRegister updateCallbackFromParent={props.updateCallbackFromParent}/>, <ResponsibleParty />, <SummaryByShow />, <WithholdingTax />
    ]

    return (
        <>
            <div className='d-flex flex-column mb-2 mt-1 border p-2 pt-1 bg-white'>
                <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                    {
                        tabs.map((tab, index) => {
                            return (
                                <li key={index} className='nav-item' onClick={() => props.setSelectedTab(index)}>
                                    <a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == props?.selectedSubTab ? ' active' : '')}
                                        tabIndex="-1"
                                        id={tab + "-tab"}
                                        data-bs-toggle='tab'
                                        href={"#" + tab + "-PeopleAccountTab-" +props?.current_people_id}
                                        data-tooltip-id={`PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.' + tab })}
                                    </a>
                                </li>
                            );
                        })
                    }
                </ul>

                <div className="tab-content mt-5 pb-2" id="myTabContent">
                    {
                        tabs.map((tab, index) => {
                            return (
                                <div key={index} className={"tab-pane fade show" + (index == props?.selectedSubTab ? " active" : "")} id={tab + "-PeopleAccountTab-" +props?.current_people_id} role="tabpanel" >
                                    {componentTags[index]}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    );
}

export { AccountTab }