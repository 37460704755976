import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useCurrencyFormatter } from '../../../modules/sgl-utils/Formatters';
import { useAppSelector } from '../../../redux/hooks';
import Decimal from 'decimal.js';
import EntriesGrid from './TrainerSplit/EntriesGrid';
import { useAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import { truncateToNumPlaces } from '../../../modules/sgl-utils/SglFunctions';
import { getRowData } from '../../../modules/sgl-utils/agGridHelpers';

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";
// import icon from "../../../../_metronic/assets/CustomIcons/trainer_split.png"
import Select from 'react-select';
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const TrainerSplit = ({ show, handleClose }) => {
    const intl = useIntl() 
    const currencyFormatter = useCurrencyFormatter()
    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)
    const [gridRef, setGridRef] = useState()

    // Entries that are trainer account in current show
    const [trainerAccountEntries,setTrainerAccountEntries] = useState([])
    // All entries of trainers (trainer account)
    const [entriesOfTrainers, setEntriesOfTrainers] = useState([])
   
    // Trainer Fee Options (Select)
    const [trainerFeeOptions, setTrainerFeeOptions] = useState([])
    const feeSelectRef = useRef()
    const trainerSelectRef = useRef()

    const [accountBalance, setAccountBalance] = useState(0)
    const splitPaymentAmountInputRef = useRef()
    const [isApplyBtnDisabled, setIsApplyBtnDisabled] = useState(true)
    const [isSplitPaymentAmountInputDisabled, setIsSplitPaymentAmountInputDisabled] = useState(false)

    const [info, setInfo] = useState("")
    const descriptionInputRef = useRef()
    
    const [forceReapplySplit, setForceReapplySplit] = useState(false)
    const [trainerOptions, setTrainerOptions] = useState([]);
    const [trainerFee, setTrainerFee] = useState();
    const [selectedTrainer, setSelectedTrainer] = useState();

    // Get Metadata
    useEffect(() => {
        if (show) { // Fetch list only once popup is displayed 
            if(showFinancialsLocked){
                alertDialog({ message: intl.formatMessage({ id: "Entries.QuickAction.TrainerSplit.Error.ShowLocked" }), title: "warning" })
                onClose()
                return
            }

            loadingOverlay({ show: true })

            axios.get(NEST_API_URL + `/entries/getTrainerSplitMetadata?show_id=${currentShowID}&customer_id=${customerId}`)
            .then(response => {

                if (response.data.trainerAccountEntries.length === 0) {
                    setInfo("No Trainer Account found in the show.")
                }

                setTrainerAccountEntries(response.data.trainerAccountEntries)
                setEntriesOfTrainers(response.data.entriesOfTrainers)

                loadingOverlay({ show: false })
            })
            .catch(() => loadingOverlay({ show: false }))
        }
    }, [show])

    useEffect(() => {
        if (gridRef) {
            const splitPaymentAmount = Number(splitPaymentAmountInputRef.current.value)
            calculateTrainerSplitAmount(splitPaymentAmount, accountBalance)
        }
    }, [gridRef, forceReapplySplit]) // applies trainer split on exclude change

    useEffect(() => {
        setSelectedTrainer(trainerOptions[0]?.value)
    }, [trainerOptions])

    useEffect(() => {
        if(descriptionInputRef?.current && !descriptionInputRef.current.value){
            descriptionInputRef.current.value = "Trainer's Split - "
        }
    }, [descriptionInputRef])

    useEffect(() => {
        if(selectedTrainer){
            descriptionInputRef.current.value = "Trainer's Split - "
            handleTrainerChange(selectedTrainer)
        }
    }, [selectedTrainer])

    const handleTrainerChange = (entryId) => {
        const selectedTrainerId = Number(entryId)
        const selectedTrainerAccount = trainerAccountEntries.find(trainerEntry => trainerEntry.trainer_id === selectedTrainerId)

        const _accountBalance = selectedTrainerAccount.balance
        const splitPaymentAmount = _accountBalance // Set Split amount as account balance on change
        splitPaymentAmountInputRef.current.value = splitPaymentAmount
        setAccountBalance(_accountBalance)

        if (selectedTrainerAccount) {
            let rowData = entriesOfTrainers.filter(entry => entry.trainer_id === selectedTrainerId)

            if (rowData.length > 0) { // Check if the trainer had any entries
                rowData = rowData.map(row => ({ ...row, amount: 0 })) // Set payment amount to zero on change
                gridRef.setRowData(rowData) // Update ag-grid
                gridRef.setPinnedBottomRowData([{ amount: 0 }])
                
                if (splitPaymentAmount > 0) { // split the balance evenly across entries
                    calculateTrainerSplitAmount(splitPaymentAmount, _accountBalance)
                    setIsApplyBtnDisabled(false)
                } else {
                    setInfo("")
                    setIsApplyBtnDisabled(true)
                }

                // const feeOptions = [];
                // feeOptions.push(...selectedTrainerAccount.Entryfees.map((ef) => ({
                //     value: ef.entryfees_id,
                //     label: ef.description + (ef.invoice_number ? ` (${ef.invoice_number})` : "")
                // })));
                // feeOptions.unshift({value: '0', label: 'All'})

                // // feeSelectRef.current.setValue(feeOptions[0]); // Select All
                // setTrainerFee(0);
                // setTrainerFeeOptions(feeOptions);

                setIsSplitPaymentAmountInputDisabled(false)
            } else {
                setInfo("")
                setIsApplyBtnDisabled(true)

                gridRef.setRowData([]) // Update ag-grid
                gridRef.setPinnedBottomRowData([{ amount: 0 }])
            }

            //set select fee dropdown options
            const feeOptions = [];
            feeOptions.push(...selectedTrainerAccount.Entryfees.map((ef) => ({
                value: ef.entryfees_id,
                label: ef.description + (ef.invoice_number ? ` (${ef.invoice_number})` : "")
            })));
            feeOptions.unshift({value: '0', label: 'All'})

            setTrainerFee(0);
            setTrainerFeeOptions(feeOptions);
        }
    }

    const handleTrainerFeeChange = (entryId) => {
        let splitPaymentAmount = 0
        const entryFeeId = Number(entryId)

        if (entryFeeId === 0) { // all is selected
            setIsSplitPaymentAmountInputDisabled(false)
            splitPaymentAmount = accountBalance
        } else {
            setIsSplitPaymentAmountInputDisabled(true)

            // const trainerId = Number(trainerSelectRef.current.getValue()[0]?.value)
            const trainerId = Number(selectedTrainer)
            const { Entryfees } = trainerAccountEntries.find(trainerEntry => trainerEntry.trainer_id === trainerId)
            const foundEntryFee = Entryfees.find(ef => ef.entryfees_id === entryFeeId)
            if (foundEntryFee?.fee_splitted) {// if already splitted
                splitPaymentAmount = 0
            } else if (accountBalance >= foundEntryFee.totalprice) {
                splitPaymentAmount = foundEntryFee.totalprice
            }
        }

        splitPaymentAmountInputRef.current.value = splitPaymentAmount
        calculateTrainerSplitAmount(splitPaymentAmount, accountBalance)
    }

    const calculateTrainerSplitAmount = (_splitPaymentAmount, _accountBalance) => {

        if(isNaN(_splitPaymentAmount)){
            alertDialog({message: "Please enter valid split amount."})
            return
        }

        let _info = ""
        let numEntries = 0
        const rowData = getRowData(gridRef)
        for (let row of rowData) {
            if (!row.exclude_from_trainer_split) {
                numEntries++
            } else { // Set amount to zero for excluded entries
                row.amount = 0
            }
        }
 
        let isCredit = false
        if (_splitPaymentAmount > _accountBalance) { //Split amount must be less than or equal to total balance
            alertDialog({ message: "Total split amount is greater than the balance of Trainer Account." })
        } else { //Split amount must be less than or equal to total balance
            if (numEntries > 0) {

                if (_splitPaymentAmount < 0) {
                    _splitPaymentAmount *= -1
                    isCredit = true
                }

                const distributedPay = truncateToNumPlaces(_splitPaymentAmount/numEntries, 2)  //get the payment distributed across entries
               
                let tempTotalAmount = (new Decimal(distributedPay)).times(numEntries)
                //const trainerId = Number(trainerSelectRef.current.getValue()[0].value)
                const trainerId = Number(selectedTrainer)
                const trainerEntryNumber = trainerAccountEntries.find(trainerEntry => trainerEntry.trainer_id === trainerId).number 
                _info = "Transferring Balance from Trainer Account " + trainerEntryNumber + " to Entry(s) "

                const entryNumbers = []
                for (let row of rowData) {
                    if (!row.exclude_from_trainer_split) {
                        if (tempTotalAmount.lessThan(_splitPaymentAmount)) {
                            row.amount = parseFloat(new Decimal(distributedPay).plus(0.01).toString())
                            tempTotalAmount = tempTotalAmount.plus(0.01)
                        } else {
                            row.amount = distributedPay
                        }
                        entryNumbers.push(row.number)
                    }
                }

                _info += (entryNumbers.join(", "))
        
                setIsApplyBtnDisabled(_splitPaymentAmount === 0)
            } else {
                _info = "No Entry found to transfer balance from Trainer Account."
                setIsApplyBtnDisabled(true)
            }   
        }

        setInfo(_info)
        let sum = new Decimal(0)
        for (let row of rowData) {
            if (isCredit) {
                row.amount *= -1
            }
            sum = sum.plus(new Decimal(row.amount))
        }
        sum = parseFloat(sum.toString())
        gridRef.setRowData(rowData)
        gridRef.setPinnedBottomRowData([{ amount: sum }])
    }

    const applySplit = async () => {
        let applied = new Decimal(0)
        const rowData = getRowData(gridRef)
        const entryNumbers = []

        rowData.forEach(row => { 
            applied = applied.plus(new Decimal(row.amount));
            if (!row.exclude_from_trainer_split) {
                entryNumbers.push(row.number)
            }
        })

        if(isNaN(splitPaymentAmountInputRef.current.value)){
            alertDialog({message: "Please enter valid split amount."})
            return
        }

        let splitPaymentAmount = new Decimal(splitPaymentAmountInputRef.current.value)
        if (!applied.equals(splitPaymentAmount)) {
            alertDialog({ message: "The apply amounts must match the total payment amount."})
            console.warn( `Applied: ${applied}, Split Payment Amount: ${splitPaymentAmount}`)
            return
        } 
        
        setIsApplyBtnDisabled(true)
        let _info = "Transferring Balance "
        const feeId = Number(trainerFee)
        const trainerId = Number(selectedTrainer)

        const trainer = trainerAccountEntries.find(trainerEntry => trainerEntry.trainer_id === trainerId)
        if (feeId > 0 && trainer.Entryfees.length > 0) { //update type if it is for fees
            const entryFee = trainer.Entryfees.find(ef => ef.entryfees_id === feeId)
            if (entryFee) {
                _info += "for "+ (entryFee.category !== "" ? entryFee.category + " - " : "") + entryFee.description
            }
        }
        _info += "from Trainer Account "+ trainer.number + " to Entry(s) "
        _info += (entryNumbers.join(", "))

        let description = _info
        if (descriptionInputRef.current.value !== "") {
            description = descriptionInputRef.current.value
        }

        splitPaymentAmount = parseFloat(splitPaymentAmount.toString())

        try {
            const response = await axios.post(NEST_API_URL + '/entries/applyTrainerSplit', {
                row_data: rowData,
                customer_id: customerId,
                description,
                info,
                fee_split_id: feeId,
                trainer_entry_id: trainer.entry_id,
                split_payment_amount: splitPaymentAmount 
            })
            setIsApplyBtnDisabled(false)
    
            if (response.data.success) {
                onClose()
            }
        } catch(reason) {
            setIsApplyBtnDisabled(false)
            alertDialog({ message:  reason.response.data.error })
        }

    }

    const addEntries = async (e) => {
        //if (!trainerSelectRef.current.getValue()[0].value) {
        if (!selectedTrainer) {
            alertDialog({ message: "Select a trainer account to add entries." })
            e.target.value = ""
            return
        } 

        const response = await axios.post(NEST_API_URL + "/entries/addEntriesToTrainerSplit", {
            entry_numbers: e.target.value,
            customer_id: customerId,
            show_id: currentShowID
        })

        const rowData = getRowData(gridRef)

        if (response.data.errors.length > 0) {
            alertDialog({ message: response.data.errors.join("<br/>") })
            e.target.focus()
        }

        for (let entry of response.data.entries) {
            if (rowData.findIndex(row => row.entry_id === entry.entry_id) === -1) { // Check if entry is not already present in grid
                rowData.push({ ...entry, amount: 0 })
            }
        }

        gridRef.setRowData(rowData)

        let sum = new Decimal(0)
        for (let row of rowData) {
            sum = sum.plus(new Decimal(row.amount))
        }
        sum = parseFloat(sum.toString())
        gridRef.setPinnedBottomRowData([{ amount: sum }])

        const splitPaymentAmount = Number(splitPaymentAmountInputRef.current.value)
        calculateTrainerSplitAmount(splitPaymentAmount, accountBalance)

        e.target.value = ""
    }

    const onClose = () => {
        setAccountBalance(0)
        setTrainerAccountEntries([])
        setEntriesOfTrainers([])
        setTrainerFeeOptions([])
        setInfo("")

        handleClose();  
    }

    useEffect(() => {
        // trainerAccountEntries.map(trainerEntry => <option value={trainerEntry.trainer_id}>{ (trainerEntry.Trainer ? trainerEntry.Trainer.lf_name : "") + " (" + trainerEntry.number + ")"}</option>)
        const getTrainerOptions = () => {
            const options = trainerAccountEntries?.map((trainerEntry) => ({
              value: trainerEntry.trainer_id,
              label: (trainerEntry.Trainer ? trainerEntry.Trainer.lf_name : "") + " (" + trainerEntry.number + ")"
            }));
            setTrainerOptions(options);
        }
        getTrainerOptions();
    }, [trainerAccountEntries])

    useEffect(() => {
        if(show){      
            const handleEnterKey = (event) => {
                let activeElement = document.activeElement
                let isButtonFocused = false
                let isNotAGGridEditableCell = (!event?.target?.className?.includes('ag-input-field-input') && !event?.target?.className?.includes('ag-text-field-input'))
               
                if (activeElement && activeElement.tagName === 'BUTTON') {
                    isButtonFocused = true
                }

                if (event.key === 'Enter' && !event.shiftKey && !isButtonFocused ) {
                    if (isNotAGGridEditableCell) {
                        let submitButton = document.getElementById('TrainerSplitSubmitButton')
                        submitButton?.click()
                    }
                }
            };
            
            document.addEventListener('keydown', handleEnterKey, true);
            
            return () => {
                document.removeEventListener('keydown', handleEnterKey, true);
            };
        }
    }, [show]);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-850px'
            show={show}
            onHide={onClose}
            backdrop="static"
            onEscapeKeyDown={onClose}
            // onKeyPress={(event) => {
            //     if (event.key === "Enter") {
            //         applySplit()
            //     }
            // }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.HEADING.TRAINERSPLIT' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className="d-flex mb-2">
                            {/* <div className='col-lg-2'>
                                <img 
                                    src={icon} 
                                    alt="trainer-split"
                                />
                            </div> */}
                            <div className='col'>
                                <div className='row mb-2'>
                                    <label className='col-lg-3 col-form-label fs-5 py-1 fw-bold' htmlFor='trainerEntries' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.TRAINERSPLIT.LABEL.TRAINERENTRIES">
                                        { intl.formatMessage({ id: "Entries.QuickAction.TrainerSplit.Label.TrainerEntries" }) }
                                    </label>
                                    <div className='col-lg-7'>
                                        <Select 
                                            ref={trainerSelectRef}
                                            inputId='selectFee'
                                            options={trainerOptions}
                                            defaultValue={trainerOptions[0]}
                                            styles={reactSelectStyles}
                                            onChange={(e) => setSelectedTrainer(e.value)}
                                            value = {trainerOptions.find((trainer) => trainer.value == selectedTrainer)}
                                            autoFocus
                                            tabIndex={1}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-3 col-form-label fs-5 py-1 fw-bold' htmlFor="selectFee" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.TRAINERSPLIT.LABEL.SELECTFEE">
                                        { intl.formatMessage({ id: "Entries.QuickAction.TrainerSplit.Label.SelectFee" }) }
                                    </label>
                                    <div className='col-lg-7'>
                                        <Select 
                                            ref={feeSelectRef}
                                            inputId='selectFee'
                                            options={trainerFeeOptions}
                                            styles={reactSelectStyles}
                                            onChange={(e) => {handleTrainerFeeChange(e.value); setTrainerFee(e.value)}}
                                            defaultValue={trainerFeeOptions[0]}
                                            value={trainerFeeOptions?.find((fee) => fee.value == trainerFee)}
                                            tabIndex={2}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-3 col-form-label fs-5 py-1 fw-bold' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.TRAINERSPLIT.LABEL.ACCOUNTBALANCE">
                                        { intl.formatMessage({ id: "Entries.QuickAction.TrainerSplit.Label.AccountBalance" }) }
                                    </label>
                                    <div className='col-lg-7 d-flex justify-content-between'>
                                        <label className='fs-5'>
                                            { currencyFormatter(accountBalance) }
                                        </label>
                                        <button 
                                            type="button"
                                            className='btn btn-sm btn-secondary fw-bold text-uppercase py-2' 
                                            onClick={() => {
                                                // Reset fees dropdown
                                                feeSelectRef.current.setValue({label: 'All', value: '0'}) // All
                                                setIsSplitPaymentAmountInputDisabled(false)
                                                splitPaymentAmountInputRef.current.value = accountBalance
                                                calculateTrainerSplitAmount(accountBalance, accountBalance)
                                            }}
                                            tabIndex={3}
                                            data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.TRAINERSPLIT.BUTTON.USEACTBALANCE"
                                        >
                                            { intl.formatMessage({ id: "Entries.QuickAction.TrainerSplit.Button.UseAccountBalance" }) }  
                                        </button>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-3 col-form-label fs-5 py-1 fw-bold' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.TRAINERSPLIT.LABEL.SPLITAMOUNT">
                                        { intl.formatMessage({ id: "Entries.QuickAction.TrainerSplit.Label.SplitAmount" }) }
                                    </label>
                                    <div className='col-lg-7 d-flex justify-content-between'>
                                        <input 
                                            ref={splitPaymentAmountInputRef}
                                            className='form-control form-control-sm fs-6 min-h-20px py-1 w-lg-100px '
                                            disabled={isSplitPaymentAmountInputDisabled}
                                            tabIndex={4}
                                        />
                                        <button 
                                            type="button"
                                            className='btn btn-sm btn-secondary fw-bold text-uppercase py-2'
                                            onClick={() => {
                                                calculateTrainerSplitAmount(
                                                    Number(splitPaymentAmountInputRef.current.value), 
                                                    accountBalance
                                                )
                                            }} 
                                            tabIndex={5}
                                            data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.TRAINERSPLIT.BUTTON.SPLITAMOUNT"
                                        >
                                            { intl.formatMessage({ id: "Entries.QuickAction.TrainerSplit.Label.SplitAmount" }) }
                                        </button>
                                    </div>
                                   
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-3 col-form-label fs-5 py-1 fw-bold' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.TRAINERSPLIT.LABEL.DESCRIPTION">
                                        { intl.formatMessage({ id: "Entries.QuickAction.TrainerSplit.Label.Description" }) }
                                    </label>
                                    <div className='col-lg-7'>
                                        <input 
                                            type="text"
                                            ref={descriptionInputRef}
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            tabIndex={6}
                                        />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>

                    <EntriesGrid 
                        gridRef={gridRef}
                        setGridRef={setGridRef}
                        info={info}
                        setForceReapplySplit={setForceReapplySplit}
                        tabIndex={7}
                    />
        
                    <div className='card-footer d-flex justify-content-end py-3 px-0 align-items-center'>
                        <div className='col'>
                            <div className='row'>
                                <label className='w-lg-150px col-form-label fs-5 py-1 fw-bold' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.TRAINERSPLIT.LABEL.ADDENTRIESTOSPLIT">
                                    { intl.formatMessage({ id: "Entries.QuickAction.TrainerSplit.Label.AddEntriesToSplit" }) }
                                </label>
                                <div className="col-lg-4">
                                    <input 
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        onBlur={addEntries}
                                        tabIndex={8}
                                    />
                                </div>
                            </div>
                        </div>
                        <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={9}>
                            {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button 
                            id='TrainerSplitSubmitButton'
                            type="button" 
                            className='btn btn-sm btn-dark fw-bold text-uppercase' 
                            onClick={applySplit}
                            disabled={isApplyBtnDisabled}
                            tabIndex={10}
                            data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.TRAINERSPLIT.BUTTON.APPLY"
                        >
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.APPLY" })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default TrainerSplit