import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../../_metronic/helpers'
import Select from 'react-select'
import { reactSelectStyles } from '../../../../modules/sgl-utils/fieldControls'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useAppSelector } from '../../../../redux/hooks'
import { useAlert, useConfirm, useFlashAlert, useLoadingOverlay } from '../../../../modules/sgl-utils/DialogsProvider'
import { NumericFormat } from 'react-number-format'
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters'
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../../modules/sgl-utils/SglFunctions'

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
 
const TransferWithholdingTax = ({show, handleClose, updateCallbackFromParent}) => {
    const intl = useIntl() 
    const parentFormMethods = useFormContext()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const people_id = parentFormMethods.getValues('people.people_id');
    const currencyCode = useAppSelector(state => state.showCompany.currency_code);
    const currencySymbol = intl.formatMessage({ id: `CURRENCY.CODE.SYMBOL.${currencyCode}` })
    const currencyFormatter = useCurrencyFormatter()
    const flashAlert = useFlashAlert()
    const loadingOverlay = useLoadingOverlay()

    const [transferErrorMessage, setTransferErrorMessage] = useState('');
    const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
    const [options, setOptions] = useState([])

    const defaultValues = {
        selectedYear: '',
        transferType: 'Account Register',
        taxWithheld: 0,
        taxPaid: 0,
        availableTaxForTransfer: 0,
        availableTaxToPay: 0,
        transferAmount: 0,
        paymentAmount: 0,
        transferDescription: '',
        paymentDescription: ''
    }
    const methods = useForm({
        defaultValues,
    });

    let selectedYear = methods.watch('selectedYear')
    let transferType = methods.watch('transferType')

    const getTransferWithholdingTaxData = async () => {
        if(!selectedYear){
            return;
        }

        // Show Loading Overlay
        loadingOverlay({ show: true, message: 'Loading W.H. Tax Details...' })
        await axios.get(`${NEST_API_URL}/withholding-tax/getTransferWithholdingTaxData?people_id=${people_id}&customer_id=${customer_id}&year=${selectedYear}`)
        .then(response => {

            if(response.data.success){
                setTransferErrorMessage('')
                setPaymentErrorMessage('')
                methods.setValue('taxWithheld', response.data.taxWithheld)
                methods.setValue('taxPaid', response.data.taxPaid)
                methods.setValue('availableTaxToPay', response.data.taxAvailableForCheck)
                methods.setValue('availableTaxForTransfer', response.data.taxAvailableForARTransfer)
                methods.setValue('transferAmount', response.data.taxAvailableForARTransfer)
                methods.setValue('paymentAmount', response.data.taxAvailableForCheck)
            }

        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            // Hide Loading Overlay
            loadingOverlay({ show: false })
        }); 
    }

    const getErrorMessage = (value) => {
        if(value <= 0){
            return intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.ERROR1' })
        }

        if(transferType === 'Account Register' && value > methods.getValues().availableTaxForTransfer){
            return `Transfer ${intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.ERROR2' })}`
        }else if(transferType === 'Check' && value > methods.getValues().availableTaxToPay){
            return `Payment ${intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.ERROR2' })}`
        }
    }

    const disableSubmitButton = () => {
        
        if(transferType === 'Account Register'){
            if(transferErrorMessage || methods.watch('availableTaxForTransfer') <= 0 || methods.watch('transferAmount') > methods.watch('availableTaxForTransfer')){
                return true
            }
        }

        if(transferType === 'Check'){
            if(paymentErrorMessage || methods.watch('availableTaxToPay') <= 0 || methods.watch('paymentAmount') > methods.watch('availableTaxToPay')){
                return true
            }
        }

        return false
    }

    const transferWithholdingTax = async(e) => {
        let endpoint 
        let requestData
        let data = methods.getValues()

        // remove prefix $ from amounts >>>
        if(data.transferAmount && data.transferAmount !== '' && isNaN(data.transferAmount)){
            data.transferAmount = Number(data.transferAmount.replace('$', ''))
        }

        if(data.paymentAmount && data.paymentAmount !== '' && isNaN(data.paymentAmount)){
            data.paymentAmount = Number(data.paymentAmount.replace('$', ''))
        }
        // remove prefix $ from amounts <<<

        let confirmMessage = ''
        let flashMessage = ''
        if(transferType === 'Account Register'){
            confirmMessage = `Are you sure you want to transfer ${currencyFormatter(data.transferAmount)} to account register? This action can't be undone.`
            flashMessage = `${currencyFormatter(data.transferAmount)} has been transferred to account successfully.`
            endpoint = 'transferWithholdingTaxToAccountRegister'
            requestData = {
                amount_transferred: data.transferAmount,
                year: data.selectedYear,
                description: data.transferDescription,
                people_id: people_id,
                customer_id: customer_id,
                people: parentFormMethods.getValues().people
            }

            if(!requestData.amount_transferred){
                return;
            }
        }else{
            confirmMessage = `Are you sure you want to pay ${currencyFormatter(data.paymentAmount)} to RTO as Balance Refund? This action can't be undone.`
            flashMessage = `${currencyFormatter(data.paymentAmount)} has been paid to RTO successfully.`
            endpoint = 'generateCheckForWithholingTax'
            requestData = {
                check_number: data.check_number,
                year: data.selectedYear,
                amount: data.paymentAmount,
                description: data.transferDescription,
                people_id: people_id,
                customer_id: customer_id,
                people: parentFormMethods.getValues().people
            }

            if(!requestData.amount){
                return;
            }
        }

        //show confirmation message
        const choice = await confirmDialog({ message: confirmMessage })

        if(!choice) { return; }

        loadingSpinnerBtnWait(e)
        axios.post( process.env.REACT_APP_NEST_API_URL + '/withholding-tax/' + endpoint, requestData)
        .then((response) => {
            if(response.data.success){
                flashAlert({ type: 'success', message: flashMessage });

                updateCallbackFromParent()

                // close the dialog
                handleClose()
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        }).finally(() => {
            loadingSpinnerBtnRelease(e)
        })
    }

    useEffect(() => { // prefill the values for year select
        let year = new Date().getFullYear()
        let month = new Date().getMonth()
        let dropDownOptions = []
        while(year >= 2014){
            dropDownOptions.push({value: year, label: year})
            year--
        }
        setOptions(dropDownOptions)

        if(month >= 11){
            methods.setValue('selectedYear', year)
        }else{
            methods.setValue('selectedYear', dropDownOptions[1].value)
        }
    }, []);

    useEffect(() => { // update the form data when year is changed
        getTransferWithholdingTaxData()
    }, [selectedYear]);

    useEffect(() => { // set the focus on amount fields 
        if(transferType === 'Account Register' && document.getElementById("transferAmount")){
            document.getElementById("transferAmount").focus()
            document.getElementById("transferAmount").select()
        }else if(transferType === 'Check' && document.getElementById("paymentAmount")){
            document.getElementById("paymentAmount").focus()
            document.getElementById("paymentAmount").select()
        }
        
    }, [transferType, methods.watch('selectedYear')])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
            show={show}
            onHide={handleClose}
            onKeyPress={(event) => {
                if (event.key === "Enter") {
                    transferWithholdingTax(event)
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .manual-override-form .w-lg-140px { width: 140px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.TITLE' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
            <FormProvider {...methods}>
                <form noValidate className='form manual-override-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>

                        {/* Accounting Year */}
                        <div className='row mb-2'>
                            <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='accounting_year' data-tooltip-id="PEOPLE.DETAIL.MODAL.TRANSFER/PAYW.H.TAX.LABEL.ACCOUNTINGYEAR">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.LABEL.ACCOUNTING.YEAR' })}</label>
                            <div className='col-lg-5'>
                                <Controller
                                    name = 'selectedYear'
                                    render={({field: {onChange, value, label}}) => (
                                    <Select
                                        value={value !== undefined ? options.filter((ss) => ss.value === value):""}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                        })}       
                                        options={options}
                                        onChange={(e) => {
                                            methods.setValue('selectedYear', e.value)
                                        }}
                                        placeholder='Select'
                                        styles={reactSelectStyles}
                                    />
                                    )}
                                />
                            </div>
                        </div> 

                        {/* Transfer Type */}
                        <div className='row mb-2 col-lg-12'>
                            <div className='col-lg-6 form-check-sm form-check-custom px-3'>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    id='transfer_to_account_register'
                                    checked={transferType === 'Account Register'}
                                    onChange={(e) => {
                                        methods.setValue('transferType', 'Account Register')
                                    }}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1 fw-bold' htmlFor='transfer_to_account_register' data-tooltip-id="PEOPLE.DETAIL.MODAL.TRANSFER/PAYW.H.TAX.LABEL.TRANSFERTOACCOUNTREGISTER"> {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.LABEL.TRANSFER.TO.ACCOUNT.REGISTER' })}</label>
                            </div> 
                            <div className='col-lg-6 form-check-sm form-check-custom px-3'>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    id='pay_to_rto'
                                    checked={transferType === 'Check'}
                                    onChange={(e) => {
                                        methods.setValue('transferType', 'Check')
                                    }}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1 fw-bold' htmlFor='pay_to_rto' data-tooltip-id="PEOPLE.DETAIL.MODAL.TRANSFER/PAYW.H.TAX.LABEL.PAYTORTO"> {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.LABEL.PAY.TO.RTO' })}</label>
                            </div>                    
                        </div>

                        {/* Tax Withheld */}
                        <div className='row mb-2'>
                            <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='tax_withheld' data-tooltip-id="PEOPLE.DETAIL.MODAL.TRANSFER/PAYW.H.TAX.LABEL.TAXWITHHELD">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.LABEL.TAX.WITHHELD' })}</label>
                            <label className='col-lg-5 col-form-label fs-5 py-1'>{currencyFormatter(methods.watch('taxWithheld'))}</label>
                        </div>

                        {/* Tax Paid */}
                        <div className='row mb-2'>
                            <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='tax_paid' data-tooltip-id="PEOPLE.DETAIL.MODAL.TRANSFER/PAYW.H.TAX.LABEL.TAXPAID">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.LABEL.TAX.PAID' })}</label>
                            <label className='col-lg-5 col-form-label fs-5 py-1'>{currencyFormatter(methods.watch('taxPaid'))}</label>
                        </div>

                        {/* Available for Transfer */}
                        <div className='row mb-2'>
                            <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='available_for_tansfer' data-tooltip-id={`${(transferType == 'Account Register')? "PEOPLE.DETAIL.MODAL.TRANSFER/PAYW.H.TAX.LABEL.AVAILABLEFORTRANSFER": "PEOPLE.DETAIL.MODAL.TRANSFER/PAYW.H.TAX.LABEL.AVAILABLEFORPAYMENT"}`}>{(transferType === 'Account Register') ? intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.LABEL.TAX.AVAILABLE.FOR.TRANSFER' }) : intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.LABEL.TAX.AVAILABLE.FOR.PAYMENT' })}</label>
                            <label className='col-lg-5 col-form-label fs-5 py-1'>{transferType === 'Account Register' ? currencyFormatter(methods.watch('availableTaxForTransfer')) : currencyFormatter(methods.watch('availableTaxToPay')) }</label>
                        </div>

                        {transferType === 'Account Register' ? (
                            <>
                                {/* Amount to be transferred to AR */}
                                <div className='row mb-2'>
                                    <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='payment_amount' data-tooltip-id="PEOPLE.DETAIL.MODAL.TRANSFER/PAYW.H.TAX.LABEL.PAYMENTAMOUNT">
                                        {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.LABEL.PAYMENT.AMOUNT' })}
                                    </label>
                                    <div className='col-lg-5'>
                                    <Controller
                                        control={methods.control}
                                        id='transferAmount'
                                        name="transferAmount"
                                        render={({ field }) => (
                                            <NumericFormat
                                                {...field}
                                                prefix={currencySymbol}
                                                id='transferAmount'
                                                allowNegative={false}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                value={methods.watch('transferAmount')}
                                                onValueChange={(values) => {
                                                    if(methods.watch('transferAmount') !== values.floatValue){
                                                        methods.setValue('transferAmount', values.value);
                                                        setTransferErrorMessage(getErrorMessage(values.value));
                                                    }
                                                }}
                                                className='form-control form-control-sm mb-2 mb-lg-0 fs-6 py-1'
                                            />
                                        )}
                                    />
                                    </div>
                                </div>

                                {/* Description for Amount Transferred to AR*/}
                                <div className='row mb-2'>
                                    <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='description' data-tooltip-id="PEOPLE.DETAIL.MODAL.TRANSFER/PAYW.H.TAX.LABEL.DESCRIPTION">
                                        {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.LABEL.DESCRIPTION' })}
                                    </label>
                                    <div className='col'>
                                        <textarea
                                            {...methods.register("transferDescription", { required: true })}
                                            rows={3}
                                            className='form-control form-control-sm mb-2 mb-lg-0 fs-6 py-1'
                                        ></textarea>
                                    </div>
                                </div>
                            </> ) :
                            (<>
                                {/* Payment Amount */}
                                <div className='row mb-2'>
                                    <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='payment_amount' data-tooltip-id="PEOPLE.DETAIL.MODAL.TRANSFER/PAYW.H.TAX.LABEL.PAYMENTAMOUNT"> {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.LABEL.PAYMENT.AMOUNT' })}</label>
                                    <div className='col-lg-5'>
                                    <Controller
                                        control={methods.control}
                                        id="paymentAmount"
                                        name="paymentAmount"
                                        render={({ field }) => (
                                            <NumericFormat
                                                {...field}
                                                prefix={currencySymbol}
                                                id='paymentAmount'
                                                allowNegative={false}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                value={methods.watch('paymentAmount')}
                                                onValueChange={(values) => {
                                                    if(methods.watch('paymentAmount') !== values.floatValue){
                                                        methods.setValue('paymentAmount', values.value);
                                                        setPaymentErrorMessage(getErrorMessage(values.value));
                                                    }
                                                }}
                                                className='form-control form-control-sm mb-2 mb-lg-0 fs-6 py-1'
                                            />
                                        )}
                                    />
                                    </div>
                                </div>

                                {/* Check Number */}
                                <div className='row mb-2'>
                                    <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='description' data-tooltip-id="PEOPLE.DETAIL.MODAL.TRANSFER/PAYW.H.TAX.LABEL.CHECKNUMBER"> {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.LABEL.CHECK.NUMBER' })}</label>
                                    <div className='col-lg-5'>
                                        <input
                                            type='text'
                                            {...methods.register("check_number")}
                                            rows={3}
                                            className='form-control form-control-sm mb-2 mb-lg-0 fs-6 py-1'
                                        />
                                    </div>
                                </div>

                                {/* Description */}
                                <div className='row mb-2'>
                                    <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='description' data-tooltip-id="PEOPLE.DETAIL.MODAL.TRANSFER/PAYW.H.TAX.LABEL.DESCRIPTION"> {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.LABEL.DESCRIPTION' })}</label>
                                    <div className='col'>
                                        <textarea
                                            {...methods.register("transferDescription", {required: true})}
                                            rows={2}
                                            className='form-control form-control-sm mb-2 mb-lg-0 fs-6 py-1'
                                        ></textarea>
                                    </div>
                                </div>
                            </>)
                        }


                        {/* Error Message */}
                        { (transferType === 'Account Register' && transferErrorMessage) || (transferType === 'Check' && paymentErrorMessage)  ?
                        <div className='row col-12'>                        
                            <label className='col-form-label mx-2 fs-5 text-danger'> {transferType === 'Account Register' ? transferErrorMessage : paymentErrorMessage} </label>
                        </div> : null
                        }
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>

                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} autoFocus>
                            {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.BUTTON.CANCEL' })}
                        </button>

                        <button type='button' className='btn btn-sm btn-dark fw-bold' disabled={disableSubmitButton()} onClick={(e) => transferWithholdingTax(e)}>
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.TRANSFER.WITHHOLDING.TAX.BUTTON.OK' })}
                        </button>
                    </div>
                </form>
            </FormProvider>
            </div>

            
        </Modal>,
        modalsRoot
    )
}

export default TransferWithholdingTax