import { useAuth } from "../auth"
import { useAlert } from "../sgl-utils/DialogsProvider"


const useAccessChecker = () => {
    const { currentCompany } = useAuth()
    const alertDialog = useAlert()

    const hasAtLeastOnePermission = (permissionsRequired) => {
        // Safety checks
        if (!currentCompany || !currentCompany.permissions) { return false }

        for (let permission of permissionsRequired) {
            if (currentCompany.permissions.includes(permission)) {
                return true
            }
        }
    
        return false
    }

    const hasAreaPermission = (area) => {
        // Safety checks
        if (!currentCompany || !currentCompany.permissions) { return false }

        const regex = new RegExp(`^${area}_.*`)
        return currentCompany.permissions.some(permission => regex.test(permission))
    }

    const hasAreaDeletePermission = (area) => {
        // Safety checks
        if (!currentCompany || !currentCompany.permissions) { return false }

        return currentCompany.permissions.includes(`cloud_${area}:delete`)
    }

    const hasAreaModifyPermission = (area) => {
        // Safety checks
        if (!currentCompany || !currentCompany.permissions) { return false }

        return currentCompany.permissions.includes(`cloud_${area}:modify-records`)
    }

    const hasAreaWritePermission = (area) => {
        // Safety checks
        if (!currentCompany || !currentCompany.permissions) { return false }

        return currentCompany.permissions.includes(`cloud_${area}:write`)
    }

    const hasSpecialPermissionToAccess = (permissionRequired) => {
        // Safety checks
        if (!currentCompany || !currentCompany.permissions) { return false }

        return currentCompany.permissions.includes(permissionRequired)
    }

    const handleDetailPageCatch = (response, loadingOverlay, props) => {
        if (response?.data?.statusCode === 403) { // UNAUTHORIZED ACCESS
            // Hide Loading Overlay
            loadingOverlay({ show: false })

            // Show Alert
            alertDialog({ title: 'Forbidden Access', message: 'You have insufficient privileges to access this resource.', icon: "stop" })

            // Close Modal
            if (props.closeModal()) {
                props.closeModal()
            }
        }
    }

    const checkCompanyAccess = ( menu_access , cloud_config ) => {
        
        // full cloud - allowed all
        if ( cloud_config == 'full_cloud' ) {
            return true
        }

        // similar access - allowed
        if ( cloud_config == menu_access ) {
            return true
        }

        // if a menu is available on no_cloud, it should be allowed
        if ( cloud_config == 'ingate_cloud' && menu_access == 'no_cloud' ) {
            return true
        }

        return false
    }
    return { hasAtLeastOnePermission, hasSpecialPermissionToAccess, hasAreaDeletePermission, hasAreaModifyPermission, hasAreaWritePermission, handleDetailPageCatch, hasAreaPermission , checkCompanyAccess }
}

export default useAccessChecker