import axios from "axios"
import { useAppSelector } from "../../redux/hooks"
import { useOutputContext, useOutputContextUpdater } from "../output-listing/OutputListingContext"
import { useAlert, useConfirm, useLoadingOverlay } from "../sgl-utils/DialogsProvider"
import { useLocation, useNavigate } from "react-router-dom"
import history from "../sgl-utils/unstableHistory"
import useAccessChecker from "./use-access-checker"
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const { PUBLIC_URL } = process.env

const useDeleteRecords = () => {
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const { getOutputSelectionSelectedRowsAreaIDs, getOutputSelectionSelectedRows } = useOutputContext()
    const outputContextUpdater = useOutputContextUpdater()
    const loadingOverlay = useLoadingOverlay()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const pathSplits = pathname.split('/')
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const { hasSpecialPermissionToAccess } = useAccessChecker();

    const getControllerPathFromArea = () => {
        let area = pathSplits[1] // entries, classes <- url part
        switch(area) {
            case 'masterfees':
                return 'master-fees'
            case 'classrules':
                return 'class-rules'
            case 'showseries':
                return 'show-series'
            case 'taxrates':
                return 'tax-rates'
            default:
                return area
        }
    }

    const deleteRecords = async () => {
        const selectedRecords = getOutputSelectionSelectedRowsAreaIDs()
        const totalRecords = selectedRecords.length
        let area = pathSplits[1]
        if (area === 'shows') {
            if (selectedRecords.includes(currentShowID)) {
                alertDialog({ message:"You cannot delete the Show you are logged in to."})
                return
            }
        }

        // Delete report only if the user is SG admin
        if (area == 'reports') {
            const isUserSgAdmin = hasSpecialPermissionToAccess('cloud_sgadmin:has_access');
            if (!isUserSgAdmin) {
                const selectedRows = getOutputSelectionSelectedRows();
                const containsSystemReports = selectedRows.some(row => row.is_system_report);
                if (containsSystemReports) {
                    alertDialog({ message: "You do not have permission to delete system reports."})
                    return;
                }
            }
        }

        if (totalRecords === 0) {
            alertDialog({ message: "You must highlight the records you want to delete."})
            return
        }

        const message = totalRecords === 1 ? "Are you sure you want to delete the highlighted record?" : `Are you sure you want to delete ${totalRecords} records?`
        const choice = await confirmDialog({ message })

        if (choice) {
            try {
                // Show Loading Overlay
                loadingOverlay({ show: true })

                const area = getControllerPathFromArea()
                const response = await axios.post(`${NEST_API_URL}/${area}/delete`, {
                    customer_id: customerId,
                    area_ids: selectedRecords
                })

                // Hide Loading Overlay
                loadingOverlay({ show: false })

                if (!response.data.success) {
                    alertDialog({ message: response.data.validationFeedback })
                } else {
                    // Refresh Listing
                    outputContextUpdater({action: 'refresh'})
                }
            } catch (reason) {
                // Hide Loading Overlay
                loadingOverlay({ show: false })
                alertDialog({ message: reason })
            }
        }
    }

    const deleteSingleRecord = async () => {
        const areaId = pathSplits.length === 4 ? pathSplits[3] : 0
        
        if (!areaId || isNaN(areaId)) { 
            console.warn("Bad method call!")
            return
        }

        // Show Loading Overlay
        loadingOverlay({ show: true })

        try {
            const area = getControllerPathFromArea()
            const response = await axios.delete(`${NEST_API_URL}/${area}/deleteRecord?customer_id=${customerId}&area_id=${areaId}`)

            // Hide Loading Overlay
            loadingOverlay({ show: false })
    
            if (!response.data.success) {
                alertDialog({ message: response.data.validationFeedback })
            } else {
                // Redirect
                history.push(`${PUBLIC_URL}/${pathSplits[1]}`, { allowNavigation: true })
            }
        } catch (reason) {
            // Hide Loading Overlay
            loadingOverlay({ show: false })
            alertDialog({ message: reason })
        }
       
    }

    return { deleteRecords, deleteSingleRecord }
}

export default useDeleteRecords