import { useState, useEffect, useRef } from 'react';

export const DeltaNumberRenderer = (params) => {
    const [isFlashing, setIsFlashing] = useState(false);
    const prevValueRef = useRef(params.value)
    const [delta, setDelta] = useState(0);
    const timerRef = useRef(null)
  
    useEffect(() => {
        if (prevValueRef.current !== null) {
            // Check for delta and trigger flashing effect
            if (params.value != prevValueRef.current) {
                let deltaVal = params.value - prevValueRef.current;
                if(params?.inverse){ //For some of our columns, delta works in inverse so there we need to send
                    deltaVal *= -1;
                }

                setDelta(deltaVal)
                setIsFlashing(true);
                clearTimeout(timerRef.current)
                timerRef.current = setTimeout(() => {
                    setIsFlashing(false);
                }, 4000); // Flash for 3 seconds
            }
        }

        prevValueRef.current = params.value

        // Clear timeout on unmount
        return () => { clearTimeout(timerRef.current)  }
    }, [params.value]);

    
    var ARROW_UP = '▲'; //'\u2191'
    var ARROW_DOWN = '▼'; //'\u2193'

    // Determine the arrow based on the sign of the delta
    const newArrow = delta > 0 ? ARROW_UP : delta < 0 ? ARROW_DOWN : '';

    // Style the component based on the sign of the delta
    const style = {
        color: delta > 0 ? 'green' : delta < 0 ? 'red' : 'black',
    };
  
    return <>
        {params.value}

        {isFlashing &&
            <span style={style}>&nbsp;{newArrow}{Math.abs(delta)}</span>        
        }
    </>;
  }