/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import { DateTimeRenderer2 } from '../../modules/output-listing/renderers/DateTimeRenderer';
import { useAlert, useConfirm, useFlashAlert, useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider';
import { useAppSelector } from '../../redux/hooks';
import axios from 'axios';

const ExecuteCronRenderer = (params) => {
  const intl = useIntl()
  const confirmDialog = useConfirm()
  const customerID = useAppSelector(state=> state.showCompany.company_id)
  const alertDialog = useAlert()
  const flashAlert = useFlashAlert()
  const loadingOverlay = useLoadingOverlay()

  return (
      <span className="text-danger" onClick={async () => {
        if (await confirmDialog({ message: `Are you sure you want to execute ${params.node.data.name} cron?`})) {
          loadingOverlay({show: true, message: `Executing Cron Job...`})
          axios.post( process.env.REACT_APP_NEST_API_URL + '/crons/executeCronJob', {
            job_name: params.node.data.name,
            customer_id: customerID,
          })
          .then((response) => {
            flashAlert({ type: 'success', message: `Cron Job - ${params.node.data.name} executed.` });
          })
          .catch((error) => {
            if (error.response) {
              alertDialog({message: error.response.data.error});
            }
          })
          .finally(() => {
            // Hide loading overlay
            loadingOverlay({ show: false })
          })
        }
      }}>{intl.formatMessage({ id: 'LIST.CRONJOB.EXECUTE' })}</span>
  )
}

const CronJobsList = () => {
  const intl = useIntl()
  const columnDefs = [
    { field: 'name', minWidth: 350, headerName: intl.formatMessage({ id: 'LIST.CRONJOB.NAME' }), filter: false},
    { field: 'next', minWidth: 200, headerName: intl.formatMessage({ id: 'LIST.CRONJOB.SCHEDULEDATE' }), headerClass: "ag-center-aligned-header", rowClass: "text-center", cellStyle: { textAlign: 'center' },filter: false, cellRenderer: DateTimeRenderer2 },
    { field: 'last', minWidth: 200, headerName: intl.formatMessage({ id: 'LIST.CRONJOB.LASTEXECUTIONDATE' }), headerClass: "ag-center-aligned-header", rowClass: "text-center", cellStyle: { textAlign: 'center' },filter: false, cellRenderer: DateTimeRenderer2 },
    { field: 'frequency', minWidth: 200, headerName: intl.formatMessage({ id: 'LIST.CRONJOB.FREQUENCY' }), headerClass: "ag-center-aligned-header", rowClass: "text-center", cellStyle: { textAlign: 'center' },filter: false },
    { flex: 1, headerName: intl.formatMessage({ id: 'LIST.CRONJOB.EXECUTE' }), headerClass: "ag-center-aligned-header", cellClass: 'text-center cursor-pointer', filter: false, cellRenderer: ExecuteCronRenderer }
  ]
  
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CRONJOB' })}</PageTitle>
      <OutputListingComponent area={'CronJobs'} columnDefs={columnDefs} apiEndPoint={'crons/list'} >
      </OutputListingComponent>
    </>
  )
}

export {CronJobsList}
