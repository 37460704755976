import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import axios from 'axios';
import { useIntl } from 'react-intl'
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import { Link } from "react-router-dom";

const RingsWidget = (props) => {
    const customerId = useAppSelector(state => state.showCompany.company_id);
    const [ringsInfo, setRingsInfo] = useState([{}]);
    const [isLoading, setIsLoading] = useState(true);
    const intl = useIntl()

    const lineColor = [
        '#7239ea',
        '#ffc701',
        '#50cd89',
        '#181c32',
        '#f1416c',
        '#1b84ff',
        '#17c653',
        '#E62721',
        '#f3bd00',
        '#FF69B4',
        '#97CD89',
        '#00CED1',
        '#663399',
        '#FFA500',
        '#FF4500',
        '#00FF7F',
        '#f8285a',
    ];

    let statusColor = [];
    statusColor['Completed'] = "badge-light-danger";
    statusColor['Upcoming'] = "badge-light-warning";
    statusColor['Unscheduled'] = "badge-light-info";
    statusColor['Live'] = "badge-light-success";

    const getRingsInfo = async () => {
        // axios.get('https://api.cloud.showgroundslive.com' + '/dashboard/getRingsInfo', {
        axios.get(process.env.REACT_APP_NEST_API_URL + '/dashboard/getRingsInfo', {
            params: {
                customer_id: customerId,
            }
        }).then(response => {
            setRingsInfo(response.data);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                if (customerId) {
                    await getRingsInfo();
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        // Set up interval to call fetchData every 35 seconds
        const intervalId = setInterval(fetchData, 35 * 1000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [customerId]);



    return (
        <div className="widget no-height-limit rings card mb-0">
            {isLoading &&
                <>
                    <br/>
                    <span className="spinner-border spinner-border-sm m-5" role="status" aria-hidden="true"></span>
                </>
            }
            {/*begin::Body*/}
            <div className="card-body py-1">
                {/*begin::Table container*/}
                {!isLoading &&
                    <div className="table-responsive">
                        {/*begin::Table*/}
                        <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                            {/*begin::Table head*/}
                            <thead>
                                <tr className="fw-bold text-muted">
                                    <th className="min-w-225px">Ring</th>
                                    <th className="min-w-125px">Videos</th>
                                    <th className="min-w-150px">On Course</th>
                                    <th className="min-w-75px">Source</th>
                                    <th className="min-w-100px">Status</th>
                                    <th className="min-w-100px">Time</th>
                                    <th className="min-w-125px">Progress</th>
                                    <th className="min-w-100px text-end">Actions</th>
                                </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody>
                                {ringsInfo && ringsInfo.map((ringInfo, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="position-relative ps-6 pe-3 py-2 d-flex flex-column overflow-hidden mw-275px">
                                                <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2" style={{ background: lineColor[index % lineColor.length] }}></div>
                                                <span className="text-gray-900 fw-bold d-block fs-6 mb-1 custom-truncate mw-275px" title={ringInfo.ring_name}>
                                                    {ringInfo.ring_name}
                                                </span>
                                                <span className="text-muted fw-bold d-block fs-7 custom-truncate mw-275px" title={ringInfo.class_group}>{ringInfo.class_group}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-between'>
                                                <span className="fw-bold fs-8" style={{color:"#17c653"}}>
                                                    Tagged
                                                </span>
                                                <span className="fw-bold fs-8" style={{color:"#f1416c"}}>
                                                    Untagged
                                                </span>
                                            </div>

                                            <div className={`progress h-7px my-1 rounded`}>
                                                <div
                                                    className={`progress-bar rounded-left`}
                                                    role='progressbar'
                                                    style={{ width: ringInfo.tagged_percentage + "%", background: "#17c653", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px", borderTopRightRadius: (ringInfo.untagged_percentage == 0) ? "6px": "0px" , borderBottomRightRadius: (ringInfo.untagged_percentage == 0) ? "6px": "0px" }}
                                                />
                                                <div
                                                    className={`progress-bar rounded-right`}
                                                    role='progressbar'
                                                    style={{ width: ringInfo.untagged_percentage + "%", background: "#f1416c", borderTopRightRadius: "6px", borderBottomRightRadius: "6px", borderTopLeftRadius: (ringInfo.tagged_percentage == 0) ? "6px": "0px" , borderBottomLeftRadius: (ringInfo.tagged_percentage == 0) ? "6px": "0px" }}
                                                />
                                            </div>

                                            <div className='d-flex justify-content-between'>
                                                <span className="fw-bold fs-8" style={{color:"#17c653"}}>
                                                    {ringInfo.tagged_videos}
                                                </span>
                                                <span className="fw-bold fs-8" style={{color:"#f1416c"}}>
                                                    {ringInfo.untagged_videos}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <span className="text-gray-900 fw-bold d-block fs-7 text-capitalize">
                                                {ringInfo.on_course_trip.horse}
                                            </span>
                                            <span className="text-muted fw-bold d-block fs-7 text-capitalize">
                                                {ringInfo.on_course_trip.rider}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="text-gray-900 fw-bold d-block fs-7">
                                                {ringInfo.data_source}
                                            </span>
                                        </td>
                                        {/* <td className="text-gray-900 fw-bold text-hover-primary fs-6">
                                    <div className="symbol-group symbol-hover flex-nowrap">
                                        {ringInfo && ringInfo.logged_in && ringInfo.logged_in.map((user, index) => {
                                            if(index < 10){
                                                return (
                                                    <OverlayTrigger
                                                    placement="top"
                                                    key={index} 
                                                    overlay={<BootstrapTooltip>{user.full_name}</BootstrapTooltip>}
                                                    >
                                                        <div className="symbol symbol-25px symbol-circle" title={user.full_name} data-bs-toggle="tooltip">
                                                            {
                                                            user.profile_photo_thumbnail?	
                                                            <img src={user.profile_photo_thumbnail} alt="image"/>:
                                                            <span className={`symbol-label text-inverse-warning fw-bold symbol-${index}`}>{user.first[0]}</span>
                                                            }
                                                        </div>
                                                    </OverlayTrigger>
                                                )
                                            }
                                        })}
                                        {ringInfo && ringInfo.logged_in && ringInfo.logged_in.length > 10 && 
                                            <a href="#" className="symbol symbol-25px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
                                                <span className={`symbol-label text-inverse-warning fw-bold symbol-more`}>+{ringInfo.logged_in.length - 10}</span>
                                            </a>
                                        }
                                    </div>
                                    <span className="text-muted fw-bold d-block fs-7">
                                        Logged In
                                    </span>
                                        </td> */}
                                        <td>
                                            <span className={"badge " + statusColor[ringInfo.group_status]}>{ringInfo.group_status}</span>
                                        </td>
                                        <td>
                                            <span className="text-gray-900 fw-bold d-block fs-7">
                                                {ringInfo.duration}
                                            </span>
                                        </td>
                                        <td>
                                            <div className="d-flex flex-column w-100 me-2">
                                                <div className="d-flex flex-stack mb-0">
                                                    <span className="text-muted me-2 fs-7 fw-bold">{ringInfo.progress}%</span>
                                                </div>
                                                <div className={`progress h-7px bg-secondary bg-opacity-50 mt-0 rounded`}>
                                                    <div
                                                        className={`progress-bar`}
                                                        role='progressbar'
                                                        style={{ width: ringInfo.progress + "%", background: lineColor[index % lineColor.length] }}
                                                    />
                                                </div>
                                            </div>
                                        </td>

                                        <td className="text-end ">
                                            <div className="d-flex justify-content-end">
                                                {ringInfo.video_asset_id > 0 &&
                                                    <a
                                                        href={`https://showgroundslive.com/js/videoembed/viewstream.html?video_asset_id=${ringInfo.video_asset_id}`}
                                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 rounded"
                                                        target="_blank"
                                                    >
                                                        <i className="fs-6 fa-solid fa-video"></i>{" "}
                                                    </a>
                                                }

                                                {ringInfo.day && ringInfo.ring_id &&
                                                    <Link
                                                        to={
                                                            '/classes/announcer-interface' +
                                                            '?selectedDate=' + ringInfo.day +
                                                            '&selectedRing=' + ringInfo.ring_id +
                                                            (ringInfo.class_group_id > 0 ? '&selectedClassGroup=' + ringInfo.class_group_id : '')
                                                        }
                                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm rounded"
                                                        target="_blank"
                                                    >
                                                        <i className="fs-6 fa-solid fa-bullhorn"></i>{" "}
                                                    </Link>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/*end::Table body*/}
                        </table>
                        {/*end::Table*/}
                    </div>
                }
                {/*end::Table container*/}
            </div>
            {/*begin::Body*/}
        </div>

    );
}


export { RingsWidget }