const IconRenderer = (params) => {
    if (params.value !== undefined) {
        if(params.value == "red"){
            return (<span className="square square-sm w-15px h-15px d-inline-flex align-items-center me-2 border border-dark" style={{backgroundColor: "red"}}>&nbsp;</span>)
        }else if(params.value == "yellow"){
            return (<span className="square square-sm w-15px h-15px d-inline-flex align-items-center me-2 border border-dark" style={{backgroundColor: "yellow"}}>&nbsp;</span>)
        }else if(params.value == "green"){
            return (<span className="square square-sm w-15px h-15px d-inline-flex align-items-center me-2 border border-dark" style={{backgroundColor: "green"}}>&nbsp;</span>)
        }
    }
}

export default IconRenderer;