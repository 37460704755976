import Select from "react-select"
import { useAuth } from "../auth"
import useChangeCompany from "../hooks/use-company-change"
import { useLocation, useNavigate } from "react-router-dom"
import { useConfirm } from "./DialogsProvider"
import { useEffect } from "react"
import { useSGLContext } from "../contexts/SGLContext"

const CompanyPickerComponent = ({ className }) => {
    const  { companies, currentCompany, currentUser } = useAuth()
    const { changeCompany } = useChangeCompany()
    const { searchParams, setSearchParams } = useSGLContext();
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const confirmDialog = useConfirm()
    
    //Checking if pathname is drilled down or in sub menu or in detail page .e.g. horses/detail, classes/order-of-go
    const pickerIsDisabled = pathname.split("/").filter( p => p).length > 1;

    useEffect(() => {
        const urlCustomerId = Number(searchParams.get("customer_id"))
        if(urlCustomerId){ 
            if (currentUser && currentUser.smc_access) {
                // Check if user sent company exists and user has access to it.
                let company = currentUser.smc_access.find(c => c.company_id === urlCustomerId)
                if (company) {
                    searchParams.delete('customer_id') // remove from url
                    setSearchParams(searchParams)
                    changeCompany(company).then() // it is an async function
                }
            }
        } 
    }, [currentUser])

    const handleCompanyChange = async (e) => { 
        const choice = await confirmDialog({ message: "Are you sure you want to switch show company?"})
        if (choice) {
            // Strip label/value from object as we don't need to save that
            const { label, value, ...newCompany } = e

            await changeCompany(newCompany)

            // Check if company was changed from a detail page, redirect to its list page.
            const index = pathname.indexOf('/detail') 
            if (index >= 0) {
                navigate(pathname.substring(0, index))
            }
        }
    }

    return (
        <Select 
            className={className}
            value={ currentCompany ? ({ label: currentCompany.name, value: currentCompany.company_id }) : ({}) }
            theme={theme => ({ ...theme, borderRadius: 0 })}
            onChange={ handleCompanyChange }
            options={ companies.map(company => ({ value: company.company_id, label: company.name, ...company })) }
            isDisabled={pickerIsDisabled}
        />
    )
}

export default CompanyPickerComponent