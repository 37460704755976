import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useSchedulerContext } from './SchedulerContext';
import axios from 'axios';
import TimeRenderer from '../../../modules/output-listing/renderers/TimeRenderer';
import PositiveNumberRenderer from '../../../modules/output-listing/renderers/PositiveNumberRenderer';
import RemoveRenderer from './RemoveRenderer';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const ClassGroupsScheduleGrid = (props) => {
  const intl = useIntl();
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const containerStyle = useMemo(() => ({ width: '100%', height: '340px' }), []);
  const rowHeight = 28;
  const headerHeight = 30;

  const {currentClassGroup, currentShowID, customerID, scheduleBreaksGridRef, classGroupScheduleGridRef, setClassGroupScheduleGridRef, scheduleBreakAddedParams, setScheduleBreakAddedParams, updateGroupSchedule} = useSchedulerContext();
  const [rowData, setRowData] = useState([]);
  
  //Grid Schedule
  const columnDefs = [
    { field: 'drag', headerName: "", sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, suppressSizeToFit: true, maxWidth: 28, minWidth: 28, editable: false, 
      rowDrag: (params) => {
        if (params.data.schedule_breaks_id > 0) {
          return true;
        }
        return false;
      }
    },
    { field: 'order_of_go', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.ORDER'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, minWidth: 40 ,headerClass: 'ag-center-aligned-header', cellRenderer: PositiveNumberRenderer, editable: false },
    { field: 'Entry.number', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.ENTRY'}), sortable: false, suppressMenu: true,cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, minWidth: 60,headerClass: 'ag-center-aligned-header', editable: false   },
    { field: 'Class.number', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.CLASS'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, minWidth: 60,headerClass: 'ag-center-aligned-header', editable: false  },
    { field: 'estimated_go_time', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.TIME'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, minWidth: 70,headerClass: 'ag-center-aligned-header',cellRenderer: TimeRenderer, editable: false  },
    { field: 'Entry.horse', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.HORSE'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px', 'paddingLeft': 5, 'paddingRight': 5}, minWidth: 140, editable: false  },
    { field: 'rider_name', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.RIDER'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px', 'paddingLeft': 5, 'paddingRight': 5}, minWidth: 140, cellRenderer: RemoveRenderer, editable: false  }
  ]

  useEffect(() => {
    updateGroupSchedule(false);    
    if (classGroupScheduleGridRef && scheduleBreaksGridRef) {
      if(!scheduleBreakAddedParams){
        var dropZoneParams = classGroupScheduleGridRef.getRowDropZoneParams({});
        setScheduleBreakAddedParams(dropZoneParams);
      }
    }
  }, [currentClassGroup]);


  const onGridReady = useCallback((params) => {
    //set new grid api in scheduler context
    setClassGroupScheduleGridRef(params.api);
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: false,
      editable: false,
      wrapHeaderText: true
    };
  }, []);

  const getRowStyle = useCallback((params) => {
    if (params.data.schedule_breaks_id > 0) {
        return { backgroundColor: '#feff7f' };
    }
  }, []);

  const onRowDragEnd = (params) => {
    /* GET ACTUAL ROW DROP INDEX WHEN DIFFERENT FROM OVERINDEX */
    let rowIndex = params.overIndex;
    params.api.forEachNode( (node, index) => {
      if(index == params.overIndex){
        if(params.y > (node.rowTop + (node.rowHeight/2))){ //check if 'y' is after half of row height
          rowIndex += 1;
        }
      }  
    });
    /* GET ACTUAL ROW DROP INDEX WHEN DIFFERENT FROM OVERINDEX */

    //add break to schedule
    axios.get(`${NEST_API_URL}/scheduler/addschedulebreaktogroup?show_id=${currentShowID}&customer_id=${customerID}&class_group_id=${currentClassGroup.class_group_id}&schedule_break_id=${params.node.data.schedule_breaks_id}&position=${rowIndex}`)
      .then(result => { 
        //Refresh class group schedule
        props.setScheduleStatus('Pending');
        updateGroupSchedule(true);
      })
      .catch(error => { console.error(error); throw error; });
  };
  
  return (
    <>
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            headerHeight={headerHeight}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={rowData}
            rowHeight={rowHeight}
            onGridReady={onGridReady}
            onRowDragEnd={onRowDragEnd}
            rowDragManaged = {true}
            suppressMoveWhenRowDragging = {false}
            animateRows = {true}     
            getRowStyle={getRowStyle} 
            suppressScrollOnNewData={true}
            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while group schedule is loading.</span>'}
          ></AgGridReact>
        </div>
    </div>
    </>
  );
};

export default ClassGroupsScheduleGrid;
  