import { Modal } from "react-bootstrap"
import { createPortal } from "react-dom"
import { useIntl } from "react-intl";
import { KTSVG } from "../../../../../_metronic/helpers";
import { useState } from "react"

const modalsRoot = document.getElementById('root-modals') || document.body

const EliminationReason = ({ disqualify_status_list, show, handleClose, setDisqualifyStatus }) => {
    const intl = useIntl();
    const [selectedReason, setSelectedReason] = useState('')


    const setEliminationReason = async () => {
        setDisqualifyStatus(selectedReason)
        
        handleClose()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => { //added key listener
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) { //call function only when no button is in focus, and apply button is enabled
                    event.stopPropagation();
                    event.preventDefault();

                    if(selectedReason != ''){
                        setEliminationReason()
                    }
                }
                if(event.key === 'Escape'){
                    event.stopPropagation();
                    event.preventDefault();

                    handleClose()
                }
              }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">Elimination Reason</h2>
                <div 
                    className='btn btn-sm btn-icon btn-active-color-dark' 
                    onClick={handleClose}
                >
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
    
            <div className='modal-body py-3 px-4 shadow-lg'>
                <div className='row mb-2'>
                    <label className='col-lg-4 col-form-label fs-5 py-1 pe-0' htmlFor="reason">
                        Select Elimination Reason
                    </label>

                    <div className='col-lg-8'>
                        <select 
                            id="reason" 
                            className='form-select form-select-sm fs-6 h-30px py-1'
                            value={selectedReason} 
                            onChange={e => setSelectedReason(e.target.value)}   
                            autoFocus
                            tabIndex={1}
                        >
                            {
                                disqualify_status_list.map(r => <option value={r.value}>{ r.label }</option>)
                            }   
                        </select>
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-3 px-0'>
                    <button 
                        type="button" 
                        id={"confirm_dialog_cancel_button"} 
                        className='btn btn-sm btn-secondary me-4 fw-bold' 
                        style={{ marginLeft: "auto" }} 
                        onClick={handleClose}
                        tabIndex={2}
                    >
                        { intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.CANCEL' }) }
                    </button>

                    <button 
                        type="submit" 
                        id={"confirm_dialog_submit_button"} 
                        className='btn btn-sm btn-dark fw-bold'
                        disabled={selectedReason == ''}
                        onClick={setEliminationReason}
                        tabIndex={3}
                    >
                        { intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.APPLY' }) }
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default EliminationReason