import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import axios from 'axios';

// Custom Components
import { ListNote } from './NotesTab/ListNote';
import { useAuth } from '../auth';
import { NoteDialog } from './NotesTab/NoteDialog';
import { useAppSelector } from '../../redux/hooks';
import useAccessChecker from '../hooks/use-access-checker';

/*
INTEGRATION PROCESS
    1. Add "notes" in react hook forms setup
    2. Add NotesTab component in the tabs component list
    3. Add notes in tab names list
*/

const NotesTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext()
    const { currentUser } = useAuth();
    const { hasAreaWritePermission } = useAccessChecker()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);

    const [newNoteID, setNewNoteID] = useState(0)
    const [showDialog, setShowDialog] = useState(false)

    const hasWriteAccess = hasAreaWritePermission('notes')

    const notes = methods.watch('notes')

    const addNote = async (note) => { // Create new note
        if (hasWriteAccess) { //check if user has write access
            let notes = methods.getValues('notes')

            let response = await axios.get(process.env.REACT_APP_NEST_API_URL + '/utility/getTimezoneInfo', {
                params: { customer_id: customer_id }
            })

            if(response.data?.date){
                notes.unshift({
                    note: note,
                    added: true,
                    newNoteID: newNoteID + 1,
                    user_id: currentUser.id,
                    date: response.data?.date,
                    time: response.data?.time,
                    user: currentUser.first_name + ' ' + currentUser.last_name
                })

                methods.setValue('notes', notes, { shouldDirty: true })
                setNewNoteID(newNoteID + 1)
            }
        }        
    }

    return (
        <>
            <div className='notes form-group mb-2'>
                { hasWriteAccess && <NoteDialog show={showDialog} handleClose={() => {setShowDialog(false)}} handleSubmit={addNote} /> }
                <div className='row mb-2 mx-0'>
                    <div className='col-lg-12 d-flex h2'>
                        {intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.NOTES.TITLE' })}
                        {
                            hasWriteAccess &&
                            <button onClick={() => {setShowDialog(true)}} className="btn btn-sm btn-secondary  fw-bold px-3 py-1 ms-5 text-uppercase" type="button">
                                <i className="fas fa-plus me-1"></i>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.NOTES.MODAL.NOTE.TITLE.ADDNOTE' })}
                            </button>
                        }
                    </div>

                    <div className={'col-lg-12 h6 mt-5' + (!notes?.length ? '' : ' d-none')}>
                        {intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.NOTES.LABEL.NONOTESFOUND' })}
                    </div>

                    <div className={'note-list col-lg-12 my-5' + (notes?.length ? '' : ' d-none')}>
                        {
                            notes?.map(note => {
                                if (!note?.deleted) {
                                    return (
                                        <ListNote data={note} />
                                    )
                                }
                                return null
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export { NotesTab }