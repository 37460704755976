import { useMemo, useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { removeDialogFromSession } from '../sgl-utils/SglFunctions';

type Props = {
  show: boolean
  onConfirm: () => void,
  onClose: () => void,
  message: string,
  title: string,
  icon: string,
  okButtonTitle: string,
  cancelButtonTitle: string,
  hide: boolean
} & typeof defaultProps;

const defaultProps = {
  hide: false
};

const modalsRoot = document.getElementById('root-modals') || document.body

const ConfirmDialog = ({ show, onConfirm, onClose, message, title, icon, okButtonTitle, cancelButtonTitle, hide }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const intl = useIntl()
  const okRef = useRef<HTMLButtonElement | null>(null); 

  useEffect(() => {
    if(!isLoading){
      if( okRef?.current){
        okRef?.current?.focus();
      }
      sessionStorage.setItem("modal_is_open", JSON.stringify(true));
      setShowModal(true)
      const storedModals = JSON.parse(sessionStorage.getItem('modals_open') || '[]');
      sessionStorage.setItem('modals_open', JSON.stringify([...storedModals, 'confirmDialog']));
    }else{
      setIsLoading(false)
    }
  }, [show])

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if(isLoading && !showModal) { return false; } //Do not handle this keybinder if dialog is not displayed
      if (event.key === "Enter") {
        if (okRef.current) {
          okRef.current.focus()
        }
        if(event.srcElement.id)
        {
          let button_id = event.srcElement.id
          if(button_id == "confirm_dialog_cancel_button")
          {
            hideModal();
          }else if(button_id == "confirm_dialog_submit_button"){
            submitConfirm()
          }
        }
        // submitConfirm()
      }
    };
  
    window.addEventListener("keydown", handleKeyDown);
  
    // Cleanup function to remove event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showModal]);
  
  const renderedIcon = useMemo(() => {
    switch(icon){
      case "stop": return <i className="fa-2xl fa-sharp fa-solid fa-circle-xmark text-danger"></i>
      case "warning": return <i className="fas fa-2xl fa-regular fa-triangle-exclamation text-warning"></i>
      case "ban": return <i className="fas fa-solid fa-ban fa-2xl text-danger"></i>
      case "comment": return <i className="fa-sharp fa-solid fa-comment-dots fa-2xl"></i>
      default: return <i className="fa-sharp fa-solid fa-circle-info fa-2xl" style={{ color: '#578EBE' }}></i>
    }
  }, [icon]);

  const renderedTitle = useMemo(() => {
    switch(title){
      case "note": return intl.formatMessage({ id: 'DIALOG.COMMON.TITLE.NOTE' })
      case "warning": return intl.formatMessage({ id: 'DIALOG.COMMON.TITLE.WARNING' })
    }
    return title ? title : intl.formatMessage({ id: 'DIALOG.COMMON.TITLE.CONFIRM' })
  }, [title]);

  const hideModal = () => {
    if(onClose){
      onClose()
    }
    setShowModal(false)
    sessionStorage.removeItem("modal_is_open")
    removeDialogFromSession('confirmDialog')
  }

  const submitConfirm = () => {
    if(onConfirm){
      onConfirm()
    }
    setShowModal(false)
    sessionStorage.removeItem("modal_is_open")
    removeDialogFromSession('confirmDialog')
  }

//   useEffect(() => {
//     const handleKeyDown = (event: any) => {
//       if (event.key === "Escape") {
//         hideModal();
//       } if (event.key === "Enter") {
//         submitConfirm()
//       }
//     };

//     document.addEventListener("keydown", handleKeyDown);

//     // Cleanup function to remove event listener on unmount
//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, []);

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px search-form'
      show={!isLoading && showModal}
      onHide={hideModal}
    >
      <div className='modal-header py-0 px-4'>
        <h2 className="fs-4">{renderedTitle}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={hideModal}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4 shadow-lg'>
        <form noValidate className='form' onSubmit={e => e.preventDefault()}>
          <div className='card-body p-0'>
            <div className='row mb-3'>
              <div className='col-lg-1 d-flex align-self-center'>{renderedIcon}</div>
              <div className='col ms-lg-2'>
                <span className="d-inline-block align-bottom" dangerouslySetInnerHTML={{__html: message}}></span>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-3 px-0'>

            {!hide && 
            <button type="button" id={"confirm_dialog_cancel_button"} className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={hideModal}>
              { cancelButtonTitle ? cancelButtonTitle : intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.CANCEL' }) }
            </button>}

            <button ref={okRef} type="submit" id={"confirm_dialog_submit_button"} className='btn btn-sm btn-dark fw-bold' onClick={submitConfirm}>
              { okButtonTitle ? okButtonTitle : intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.OK' }) }
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { ConfirmDialog }