import moment from 'moment';

// This function will return options for react-select component, you have to provide value and label key
// custom first option should be an object or null like {label: 'label name", value: 'value'}, first option will be added as first object
export const getCustomOptionsFromArray = (array, value, label, customFirstOption) => {
    const options = array?.map((obj) => ({
        value: obj[value], 
        label: obj[label]
    }));

    // If user is sending first option, then add it with the data
    if (customFirstOption && Object.keys(customFirstOption).length > 0) {
        options.unshift(customFirstOption);
    }

    return options;
}

// This function will return options for react-select component, where no keys are specified for value and label
// This will add value and label key automatically
// Provide custom object to add at the start or leave it null
export const getOptionsFromArray = (array, customFirstOption, customSecondOption) => {
    const options = array?.map((obj) => ({
        value: obj, 
        label: obj
    }));

    // If user is sending second option, then add it with the data
    if (customSecondOption && Object.keys(customSecondOption).length > 0) {
        options?.unshift(customSecondOption);
    }

    // If user is sending first option, then add it with the data
    if (customFirstOption && Object.keys(customFirstOption).length > 0) {
        options?.unshift(customFirstOption);
    }

    return options;
}

// Used in all advanced searches
export const searchOptions = [
    { value: 'replace', label: 'Replace (new search)' },
    { value: 'refine', label: 'Refine (search current list)' },
    { value: 'add_to', label: 'Add to (add to current list)' },
    { value: 'remove', label: 'Remove (remove from current list)' },
];

// Used in Advanced Search of Entries
export const entriesOptions = [
    { value: 'all_entries', label: 'On all entries' },
    { value: 'multiple_entries', label: 'Horses with multiple entries' },
    { value: 'one_entry', label: 'Horses with only one entry' },
];

export const entriesValueOptions = [
    { value: 'has', label: 'Has' },
    { value: 'is_missing', label: 'Is Missing' },
    { value: 'has_multiple', label: 'Has Multiple' },
];

export const classesOptions = [
    { value: '', label: 'Select' },
    { value: 'No Classes', label: 'No Classes' },
    { value: 'Any Class(es)', label: 'Any Class(es)' },
    { value: 'Any of Class(es)', label: 'Any of Class(es)' },
    { value: 'All of Class(es)', label: 'All of Class(es)' },
];

export const membershipOptions = [
    { value: '', label: 'Select' },
    { value: 'Has membership in', label: 'Has membership in' },
    { value: 'Has current membership in', label: 'Has current membership in' },
    { value: 'Is missing membership in', label: 'Is missing membership in' },
    { value: 'Is missing current membership in', label: 'Is missing current membership in' },
    { value: 'Has current temporary override', label: 'Has current temporary override' },
];

// For horses advanced search
export const memberCode = [
    { value: 'Class Between', label: 'Class Between' },
    { value: 'Show Between', label: 'Show Between' },
]

// Used in horses and people advanced search
export const getShowOptions = (shows, customFirstValue, customSecondValue) => {
    const options = shows?.map((showRecord) => ({
        value: showRecord.show_id,
        label: `${moment(showRecord.start_date).format('M/D/Y')} to ${moment(showRecord.end_date).format('M/D/Y')} - ${showRecord.show_name}`,
    }));

    // If user is sending second option, then add it with the data
    if (customSecondValue && Object.keys(customSecondValue).length > 0) {
        options?.unshift(customSecondValue);
    }

    // If user is sending first option, then add it with the data
    if (customFirstValue && Object.keys(customFirstValue).length > 0) {
        options?.unshift(customFirstValue);
    }

    return options;
}

// For division search form
export const ratingOptions = [
    { value: '', label: 'Select' },
    { value: 'A', label: 'A' },
    { value: 'AA', label: 'AA' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
]

export const datOptions = [
    { value: 'classes_on', label: 'Has Classes Scheduled On' },
    { value: 'scheduled_for_last', label: 'Last Class Scheduled For' },
]

// used in class detail -> prizes and result tab
export const prizeMoneyOptions = [
    { value: '', label: 'Select Prize Money Action' },
    // TODO: Commenting for now, will be used in future (hopefully)
    // { value: 'Apply Prize Money', label: 'Apply Prize Money' },
    { value: 'Rollback Prize Money', label: 'Rollback Prize Money' },
]