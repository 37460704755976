import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useAnnouncerToolContext } from './AnnouncerToolContext';

const GroupSettings = (props) => {
  const intl = useIntl();
  const [ groupSettings, setGroupSettings ] = useState()
  const { currentClassGroup, dropdowns, updateGroupSettings} = useAnnouncerToolContext()

  useEffect(() => {
    if(currentClassGroup)
    {
      setGroupSettings(currentClassGroup)
    }
  },[currentClassGroup])
  
  //Start: Set the options for drop downs
  const hunter_score_type_options =  dropdowns?.hunter_score_type_list?.length>0 && dropdowns?.hunter_score_type_list?.map(ht => 
    <option key={ht.value} value={ht.value} selected={groupSettings?.hunter_type && groupSettings?.hunter_type == ht.value}>
        { ht.label !== "" ? ht.label : '' }
    </option>
  )

  const hunter_derby_round_1_list =  dropdowns?.hunter_derby_round_1_list?.length>0 && dropdowns?.hunter_derby_round_1_list?.map(r1 => 
    <option key={r1.value} value={r1.value} selected={groupSettings?.derbytiebreakerone && groupSettings?.derbytiebreakerone == r1.value}>
        { r1.label !== "" ? r1.label : '' }
    </option>
  )

  const hunter_derby_round_2_list =  dropdowns?.hunter_derby_round_2_list?.length>0 && dropdowns?.hunter_derby_round_2_list?.map(r2 => 
    <option key={r2.value} value={r2.value} selected={groupSettings?.derbytiebreakertwo && groupSettings?.derbytiebreakertwo == r2.value}>
        { r2.label !== "" ? r2.label : '' }
    </option>
  )

  const hunter_score_by_options =  dropdowns?.hunter_score_by_list?.length>0 && dropdowns?.hunter_score_by_list?.map(hsb => 
    <option key={hsb.value} value={hsb.value} selected={groupSettings?.hunter_scoreby && groupSettings?.hunter_scoreby == hsb.value}>
        { hsb.label !== "" ? hsb.label : '' }
    </option>
  )

  const jumper_table_list =  dropdowns?.jumper_table_list?.length>0 && dropdowns?.jumper_table_list?.map(jt => 
    <option key={jt.value} value={jt.value} selected={groupSettings?.jumper_table && groupSettings?.jumper_table == jt.value}>
        { jt.label !== "" ? jt.label : '' }
    </option>
  )
  //End: Set the options for drop downs

  // Ignore when input is minus
  const onKeyDown = (event) => {
    if (event.key == '-') {
        event.preventDefault();
    }
  }

  return (
    <>
      {currentClassGroup?.group_type === 'Jumpers' && (
        <fieldset className='mb-0'>
          <legend className="fs-6 fw-bold d-flex">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LEGEND.JUMPERSETTINGS'})}<b></b></legend>

          <div className='row mb-0'>
            <label className='col-lg-3 col-form-label  fs-6 py-1' htmlFor='jumper_table' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.JUMPERSETTINGS.LABEL.JUMPERTABLE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.JUMPERTABLE'})}</label>
            <div className='col-lg-9'>
              <select id="jumper_table" className='form-select form-select-sm fs-6 h-20px py-1' onChange={(e) => { updateGroupSettings({jumper_table: e.target.value}) }}>
                { jumper_table_list }
              </select>
            </div>
          </div>

          <div className='row mb-0'>
            <label className='col-lg-3 col-form-label  fs-6 py-1' htmlFor='time_allowed' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.JUMPERSETTINGS.LABEL.TIMEALLOWED">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.TIMEALLOWED'})}</label>
            <div className='col-lg-2'>
              <input
                id='timeallowed_tripone'
                className='form-control fs-6 h-20px py-1 text-right pl-r-5'
                style={{ textAlign: "right" }}
                type='number'
                value={groupSettings?.timeallowed_tripone && groupSettings?.timeallowed_tripone || ''}
                onBlur={(e) => { groupSettings && groupSettings.timeallowed_tripone !== currentClassGroup.timeallowed_tripone && updateGroupSettings({timeallowed_tripone: e.target.value}) }}
                onChange={(e) => { groupSettings && setGroupSettings({...groupSettings, timeallowed_tripone: e.target.value})}}
                onKeyDown={onKeyDown}
                inputMode="numeric"
                placeholder="0"
              />
            </div>
            <label className='col-form-label fs-6 py-1 pe-0 col d-flex justify-content-center' htmlFor='jo_time_allowed' data-tooltip-id={`ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.JUMPERSETTINGS.LABEL.${(currentClassGroup?.round_labels?.r2?.abbr) ? currentClassGroup.round_labels.r2.abbr : 'JO'}TIMEALLOWED`}>
                {
                    intl.formatMessage({
                        id: `FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.${(currentClassGroup?.round_labels?.r2?.abbr) ? currentClassGroup.round_labels.r2.abbr : 'JO'}TIMEALLOWED`
                    })
                }
            </label>
            <div className='col-lg-2 d-flex justify-content-end'>
              <input
                id='timeallowed_jo'
                className='form-control fs-6 h-20px py-1 text-left pl-r-5'
                style={{ textAlign: "right" }}
                type='number'
                value={groupSettings?.timeallowed_jo && groupSettings?.timeallowed_jo || ''}
                onBlur={(e) => { groupSettings && groupSettings.timeallowed_jo !== currentClassGroup.timeallowed_jo && updateGroupSettings({timeallowed_jo: e.target.value}) }}
                onChange={(e) => { groupSettings && setGroupSettings({...groupSettings, timeallowed_jo: e.target.value})}}
                onKeyDown={onKeyDown}
                inputMode="numeric"
                placeholder="0"
              />
            </div>
          </div>
          <div className='row mb-0'>
            {
                currentClassGroup.has_optimum_time && 
                <>
                    <label 
                        className='col-lg-3 col-form-label fs-6 py-1 pe-0' 
                        htmlFor='optimalTimeTrip1'
                        title='Optimum Time R1'
                        data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.JUMPERSETTINGS.LABEL.OPTTIMER1"
                    >
                        {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.OPTIMUMITME.R1'})}
                    </label>
                    <div className='col-lg-2'>
                        <input
                            id='optimalTimeTrip1'
                            className='form-control fs-6 h-20px py-1 text-right pl-r-5'
                            value={groupSettings?.optimum_timeallowed_tripone}
                            onBlur={(e) => { groupSettings && groupSettings.optimum_timeallowed_tripone !== currentClassGroup.optimum_timeallowed_tripone && updateGroupSettings({ optimum_timeallowed_tripone: e.target.value }) }}
                            onChange={(e) => { groupSettings && setGroupSettings({...groupSettings, optimum_timeallowed_tripone: e.target.value})}}
                            onKeyDown={onKeyDown}
                            style={{ textAlign: "right" }}
                            type='number'
                            inputMode="numeric"
                            placeholder="0"
                        />
                    </div>
                </>
            }
            {
                currentClassGroup.has_optimum_time_r2 && 
                <>
                    <label 
                        className='col-form-label fs-6 py-1 pe-0 col d-flex justify-content-center' 
                        htmlFor='optimalTimeTrip2'
                        title={`Optimum Time ${currentClassGroup?.round_labels?.r2?.abbr ? currentClassGroup?.round_labels?.r2?.abbr : 'R2'}`}
                        data-tooltip-id={`"ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.JUMPERSETTINGS.LABEL.${currentClassGroup?.round_labels?.r2?.abbr ? currentClassGroup?.round_labels?.r2?.abbr : 'R2'}OPTTIME`}
                    >
                        {intl.formatMessage({id: `FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.OPTIMUMITME.${currentClassGroup?.round_labels?.r2?.abbr ? currentClassGroup?.round_labels?.r2?.abbr : 'R2'}` })}
                    </label>
                    <div className='col-lg-2 d-flex justify-content-end'>
                        <input
                            id='optimalTimeTrip2'
                            className='form-control fs-6 h-20px py-1 text-right pl-r-5'
                            value={groupSettings?.optimum_timeallowed_jo}
                            onBlur={(e) => { groupSettings && groupSettings.optimum_timeallowed_jo !== currentClassGroup.optimum_timeallowed_jo && updateGroupSettings({ optimum_timeallowed_jo: e.target.value }) }}
                            onChange={(e) => { groupSettings && setGroupSettings({...groupSettings, optimum_timeallowed_jo: e.target.value})}}
                            onKeyDown={onKeyDown}
                            style={{ textAlign: "right" }}
                            type='number'
                            inputMode="numeric"
                            placeholder="0"
                        />
                    </div>
                </>
            }
          {
            currentClassGroup.has_r2_callbacks && 
            <>
              <label className='col-lg-3 col-form-label  fs-6 py-1 pe-0' htmlFor='jogordertrips' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.JUMPERSETTINGS.LABEL.R2CALLBACKS">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.R2.CALLBACKS'})}</label>
              <div className='col-lg-2'>
                  <input
                      id='jogordertrips'
                      className='form-control fs-6 h-20px py-1 text-right pl-r-5'
                      value={groupSettings?.jogordertrips && groupSettings?.jogordertrips || 0}
                      onBlur={(e) => { groupSettings && groupSettings.jogordertrips !== currentClassGroup.jogordertrips && updateGroupSettings({jogordertrips: e.target.value}) }}
                      onChange={(e) => { groupSettings && setGroupSettings({...groupSettings, jogordertrips: e.target.value})}}
                      onKeyDown={onKeyDown}
                      style={{ textAlign: "right" }}
                      type='number'
                      inputMode="numeric"
                      placeholder="0"
                  />
              </div>
            </>
          }
            
          {
            Boolean(currentClassGroup.has_r3) === true  && 
            <>
              <label className='col-form-label fs-6 py-1 pe-0 col d-flex justify-content-center' htmlFor='timeallowed_r3' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.JUMPERSETTINGS.LABEL.JOOPTTIME">
                  { intl.formatMessage({ id: `FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.JOTIMEALLOWED` })}
              </label>
              <div className='col-lg-2 d-flex justify-content-end'>
                  <input
                      id='timeallowed_r3'
                      className='form-control fs-6 h-20px py-1 text-left pl-r-5'
                      style={{ textAlign: "right" }}
                      type='number'
                      value={groupSettings?.timeallowed_r3 ?? ''}
                      onBlur={(e) => { groupSettings && groupSettings.timeallowed_r3 !== currentClassGroup.timeallowed_r3 && updateGroupSettings({timeallowed_r3: e.target.value}) }}
                      onChange={(e) => { groupSettings && setGroupSettings({...groupSettings, timeallowed_r3: e.target.value})}}
                      onKeyDown={onKeyDown}
                      inputMode="numeric"
                      placeholder="0"
                  />
              </div>
            </>
          }
          </div>
        </fieldset>
      )}

      {(currentClassGroup?.group_type === 'Hunters') && (
        <fieldset className='mb-0'>
          <legend className=" fs-6 fw-bold d-flex">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LEGEND.HUNTERSETTINGS'})}<b></b></legend>

          <div className='row mb-0'>
            <label className='col-lg-4 col-form-label  fs-6 py-1' htmlFor='hunter_type' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.HUNTERSETTINGS.LABEL.HUNTERSCORETYPE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.HUNTERSCORETYPE'})}</label>
            <div className='col-lg-8'>
              <select id="hunter_type" className='form-select form-select-sm fs-6 h-20px py-1' onChange={(e) => { updateGroupSettings({hunter_type: e.target.value}) }}>
                {hunter_score_type_options}
              </select>
            </div>
          </div>

          <div className='row mb-0'>
            <label className='col-lg-4 col-form-label  fs-6 py-1 pe-0' htmlFor='hunter_scoreby' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.HUNTERSETTINGS.LABEL.HUNTERSCOREBY">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.HUNTERSCOREBY'})}</label>
            <div className='col-lg-8'>
              <select id="hunter_scoreby" className='form-select form-select-sm fs-6 h-20px py-1' onChange={(e) => { updateGroupSettings({hunter_scoreby: e.target.value}) }}>
                { hunter_score_by_options }
              </select>
            </div>
          </div>

        

            <div className='row mb-0'>
                <label className='col-lg-4 col-form-label  fs-6 py-0' htmlFor='jogordertrips' data-tooltip-id={`ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.HUNTERSETTINGS.LABEL.${currentClassGroup.group_score_type === 'Hunter Derby' ? 'R2.CALLBACKS' : 'JOGORDERTRIP'}`}>
                    { intl.formatMessage({id: `FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.${currentClassGroup.group_score_type === 'Hunter Derby' ? 'R2.CALLBACKS' : 'JOGORDERTRIP'}`}) }
                </label>
                <div className='col-lg-1 px-0 ms-3' style={{width:"42px"}}>
                    <input
                        id='jogordertrips'
                        className='form-control fs-6 h-20px py-1'
                        value={groupSettings?.jogordertrips && groupSettings?.jogordertrips || 0}
                        onBlur={(e) => { groupSettings && groupSettings.jogordertrips !== currentClassGroup.jogordertrips && updateGroupSettings({jogordertrips: e.target.value}) }}
                        onChange={(e) => { groupSettings && setGroupSettings({...groupSettings, jogordertrips: e.target.value})}}
                        onKeyDown={onKeyDown}
                        style={{ textAlign: "right" }}
                        type='number'
                        inputMode="numeric"
                        placeholder="0"
                    />
                </div>
         
                <label className='col d-flex justify-content-center col-form-label fs-6 py-0 fw-bold' htmlFor='numberOfColsOrPanels' data-tooltip-id={`ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.HUNTERSETTINGS.LABEL.NUMBEROF${currentClassGroup.group_score_type === 'Hunter Derby' ? 'PANELS' : 'SCORES'}`}>
                    { intl.formatMessage({id: `FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.NUMBEROF${currentClassGroup.group_score_type === 'Hunter Derby' ? 'PANELS' : 'SCORES'}`}) }
                </label>
                <div className='w-lg-75px d-flex justify-content-end'>
                    <select 
                        id="numberOfColsOrPanels"
                        className='w-lg-50px form-select h-20px py-1'
                        style={{ paddingLeft: '5px', paddingRight: '5px'}}
                        value={currentClassGroup.number_of_scores}
                        onChange={e => updateGroupSettings({ number_of_scores: Number(e.target.value) })} 
                    >
                    { (currentClassGroup.group_score_type === 'Hunter Derby' ? [1, 2, 3, 4] : [1, 2, 3, 4, 5, 6]).map((value) => <option key={value} value={value}>{value}</option>) }
                    </select>
                </div>
            </div>
           { (currentClassGroup?.group_score_type == 'Hunter Derby') && (
                <div className='row mb-0'>
                    <label className='col-lg-4 col-form-label fs-6 py-0 fw-bold' htmlFor='derbytiebreakerone' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.HUNTERSETTINGS.LABEL.TIEBREAKER">
                        { intl.formatMessage({id: `FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.TIEBREAKER`}) }
                    </label>
                    <div className='col-lg-4 pe-1' >
                        <select id="derbytiebreakerone" className='form-select form-select-sm fs-6 h-20px py-1' onChange={(e) => { updateGroupSettings({derbytiebreakerone: e.target.value}) }}>
                            {hunter_derby_round_1_list}
                        </select>
                    </div>
                    <div className='col-lg-4 ps-1'>
                        <select id="derbytiebreakertwo" className='form-select form-select-sm fs-6 h-20px py-1' onChange={(e) => { updateGroupSettings({derbytiebreakertwo: e.target.value}) }}>
                            {hunter_derby_round_2_list}
                        </select>
                    </div>
                </div>
            )}


        </fieldset>
      )}

    </>
  );
};

export default GroupSettings;
