import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useLoadingOverlay, useProgress, useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import axios from 'axios';
import { KTSVG } from '../../../../_metronic/helpers';
import "flatpickr/dist/themes/material_blue.css";
import Select from 'react-select';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const RemoveRuleFromClasses = ({ show, handleClose }) => {
    const intl = useIntl() 
    const loadingOverlay = useLoadingOverlay()
    const confirmDialog = useConfirm()
    const { progressBar } = useProgress()
    const alertDialog = useAlert()
    const { getOutputSelectionAreaIDs, getEventID } = useOutputContext();
    const customerId = useAppSelector(state=> state.showCompany.company_id)

    const [classRules, setClassRules] = useState([])
    const [selectedRule, setSelectedRule] = useState(null)
    const [classIds, setClassIds] = useState([])
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')


    // Metadata Call
    useEffect(() => {
        if (show) {
            loadingOverlay({ show: true })
            getOutputSelectionAreaIDs(false)
            .then(async res => {
                if (res.length === 0) {
                    alertDialog({ message: 'Please select class(es) to apply Rules.' })
                    loadingOverlay({ show: false })
                    handleClose()
                } else {
                    setClassIds(res)
                    const response = await axios.get( NEST_API_URL + `/classes/getAddRulesToClassesMetadata?customer_id=${customerId}`)
                    if (response.data.classRules.length > 0) {
                        setClassRules(response.data.classRules)
                        const firstRecord = response.data.classRules[0]
                        setSelectedRule({ label: firstRecord.rule, value: firstRecord.class_rule_id, ...firstRecord})
                    }

                    loadingOverlay({ show: false })
                }
            })
            
        }
    }, [show])

    const applyRules = async () => {
        setSuccessMessage('')
        setErrorMessage('')

        loadingOverlay({ show: true })
        const res1 = await axios.post( NEST_API_URL + `/classes/getClassesCountToRemoveRule`, {
            customer_id: customerId,
            class_rule: {
                classRuleId: selectedRule.class_rule_id,
                name: selectedRule.rule
            },
            class_ids: classIds 
        })
        loadingOverlay({ show: false })


        if (res1.data.success) {
            if(res1.data.classes_count > 0){
                const choice = await confirmDialog({ message: 'Are you sure you want to remove rule from '+res1.data.classes_count+' classes? Fees added by the rule must be removed manually from Entries.' })
                if (choice) {

                    const eventID = getEventID('remove-rule-from-classes')
                    try {
                        progressBar({ show: true, title: "Remove Rule From Classes", eventID, timeElapsed: true })
                        axios.post( NEST_API_URL + `/classes/bulkRemoveRuleFromClasses`, {
                            customer_id: customerId,
                            class_rule: {
                                classRuleId: selectedRule.class_rule_id,
                                name: selectedRule.rule
                            },
                            event_id: eventID,
                            class_ids: classIds
                        })
                        .then((res) => {
                            if(res.data?.success){
                                setSuccessMessage(res.data?.message??'')
                            } else {
                                setErrorMessage(res.data?.error??'')
                            }
                            progressBar({ show: false, eventID })

                        })
                        .finally(() => {
                            progressBar({ show: false, eventID })
                        })
                    } catch (reason) {
                        progressBar({ show: false, eventID })
                    }
                }
            } else {
                setErrorMessage(res1.data?.error??'')
            }
        }
    }

    const onClose = () => {
        handleClose()
    }
    
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px'
            show={show}
            onHide={onClose}
            backdrop='static'
            onKeyDown={(event) => { //replace onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) {
                    applyRules()
                }
                if(event.key === 'Escape'){
                    onClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'Classes.QuickAction.RemoveRuleFromClasses' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='card-body p-0 px-2'>
                    <div className='row mb-2'>
                        <label className='col-lg-3 col-form-label fs-5 py-1 fw-bold' htmlFor="selectRule">
                            {intl.formatMessage({ id: 'Classes.QuickAction.RemoveRuleFromClasses.Label.SelectRule' })}
                        </label>
                        <div className='col'>
                            <Select
                                type="text"
                                inputId="selectRule" 
                                theme={theme => ({...theme, borderRadius: 'none'})}
                                value={selectedRule}
                                onChange={e => { 
                                    // gridRef.setRowData(e.rowData)
                                    setSelectedRule(e)
                                    setSuccessMessage('')
                                    setErrorMessage('')                            
                                }}
                                options={classRules.map(cr => ({ label: cr.rule, value: cr.class_rule_id, ...cr}))}
                                autoFocus
                                tabIndex={1}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <label className='col-lg-3 col-form-label fs-5 py-1 fw-bold'>
                            {intl.formatMessage({ id: 'Classes.QuickAction.RemoveRuleFromClasses.Label.RuleType' })}
                        </label>
                        <label className='col col-form-label fs-5 py-1'>
                            { selectedRule ? selectedRule.ClassRuleType.type : '' }
                        </label>
                    </div>
                    
                </div>
                {(successMessage != '') &&
                    <div className='label-left-align mw-350px' style={{color: 'green', fontWeight: 'bold'}}>
                        <label className='text-green fw-bold'>{successMessage}</label>
                    </div>
                }
                {(errorMessage != '') &&
                    <div className='label-left-align mw-350px' style={{color: 'red', fontWeight: 'bold'}}>
                        <label className='text-red fw-bold'>{errorMessage}</label>
                    </div>
                }
                <div className='card-footer d-flex justify-content-end py-3 px-2'>

                    <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={3}>
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CLOSE" })}
                    </button>

                    <button 
                        type="button" 
                        className='btn btn-sm btn-dark fw-bold text-uppercase'
                        onClick={applyRules}
                        tabIndex={4}
                        >
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.REMOVE" })}
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default RemoveRuleFromClasses