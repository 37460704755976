import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {useIntl} from 'react-intl'
import { useSchedulerContext } from './SchedulerContext';


const TotalEntries = (props) => {
  const intl = useIntl();
  const {totalEntries} = useSchedulerContext();

  return (
    <>
        <input
            id='NUMBERENTRIES'
            type='text'
            className='form-control form-control-sm fs-6 min-h-20px py-1'
            placeholder="0"
            readOnly
            value = {totalEntries}
        />
    </>
  );
};

export default TotalEntries;
  