
import {useEffect, useState} from 'react'
import axios from 'axios';
import { useAppSelector } from '../../redux/hooks';
import { useSyncUpdateContext } from '../../modules/output-listing/synchronizeUpdateContext';
import ViewUserDialog from './ViewUserDialog';
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import { getUserName } from '../../modules/sgl-utils/SglFunctions'
import {useIntl} from 'react-intl'

const ActiveUsersWidget = (props) => {
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const { sse, removeAllEventListeners, addSubscribedEvents} = useSyncUpdateContext()
    const [showUsersDialog, setShowUsersDialog] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const intl = useIntl()

    const fetchUpdatedUsers = async () => {
        axios.get(process.env.REACT_APP_NEST_API_URL + '/utility/dashboard', {
            params: {
            customer_id: customerId,
            }
        }).then(response => {
            setLoggedInUser(response.data)
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (customerId) {
            setIsLoading(true);
            fetchUpdatedUsers()
        } 
    }, [customerId]);

	useEffect(() => {
		if(sse && customerId){
			removeAllEventListeners()
			let syncEvents = [{eventId: `Users-${customerId}`, callback: () => fetchUpdatedUsers()}]
			addSubscribedEvents(syncEvents)
		}
		
	}, [customerId, sse]);

    return (
      <>
        <ViewUserDialog show={showUsersDialog} handleClose={() => setShowUsersDialog(false)} users={loggedInUser} />
        <div className="widget active-users card card-flush mb-0">
            {isLoading &&
                <>
                    <br/>
                    <span className="spinner-border spinner-border-sm m-5" role="status" aria-hidden="true"></span>
                </>
            }

            {!isLoading &&
            <>
            <div className="card-header pt-5 ps-7 pe-0">
                <div className="card-title d-flex flex-column">    
                    <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{loggedInUser.length}</span>
                    <span className="text-gray-500 pt-1 fw-semibold fs-6">{intl.formatMessage({id: 'MENU.DASHBOARD.ACTIVEUSERS'})}</span>
                </div>
            </div>
            <div className="card-body d-flex flex-column justify-content-end pt-10 ps-7 pb-5 pe-0">	
                <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">{intl.formatMessage({id: 'MENU.DASHBOARD.SEEUSERS'})}</span>
                <div className="symbol-group symbol-hover flex-nowrap">
                    {loggedInUser && loggedInUser.map((user, index) => {
                        // Create a new JavaScript Date object based on the timestamp
                        // multiplied by 1000 so that the argument is in milliseconds, not seconds
                        const moment = require('moment')
                        const formattedTime = user.lastActivityTimestamp > 0 ? moment(user.lastActivityTimestamp * 1000).format("HH:mm A") : null;
                        
                        if(index < 10){
                            return (
                                <OverlayTrigger
                                key={index} 
                                placement="top"
                                overlay={<BootstrapTooltip>{getUserName(user)}<span className='d-block text-muted fw-semibold'><span>Last Seen:</span> {formattedTime ?? ''}</span></BootstrapTooltip>}
                                >
                                    <div onClick={() => setShowUsersDialog(true)} className="symbol symbol-35px symbol-circle" title={getUserName(user)} data-bs-toggle="tooltip">
                                        {
                                        user.profile_photo_thumbnail?	
                                        <img src={user.profile_photo_thumbnail} alt="image"/>:
                                        <span className={`symbol-label text-inverse-warning fw-bold symbol-${index}`}>{user.first[0]}{user.last_name[0]}</span>
                                        }
                                    </div>
                                </OverlayTrigger>
                            )
                        }
                    })}
                    {loggedInUser.length > 10 && 
                        <a href="#" className="symbol symbol-35px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users" onClick={() => setShowUsersDialog(true)}>
                            <span className={`symbol-label text-inverse-warning fw-bold symbol-more`}>+{loggedInUser.length - 10}</span>
                        </a>
                    }
                    
                </div>
                {/* <!--end::Users group--> */}
            </div>
            </>
            }
            {/* <!--end::Card body--> */}
        </div>
      </>  
    );
}
export {ActiveUsersWidget}