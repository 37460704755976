import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

// Custom Components
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer';
import TimeRenderer from '../../../modules/output-listing/renderers/TimeRenderer';
import { useReportsContext } from '../ReportsContext';
import { ViewHistoryModal } from './ViewHistoryModal';

const ReportsHistoryTab = () => {
    const intl = useIntl();
    const [showReport, setShowReport] = useState(false);
    const [template, setTemplate] = useState();
    const { response } = useReportsContext()
    const [reportId, setReportId] = useState();


    const columnDefs = [
        { field: 'creation_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.HISTORY.DATE' }), cellRenderer: DateRenderer},
        { field: 'creation_time', headerName: intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.HISTORY.TIME' }), cellRenderer: TimeRenderer },
        { field: 'created_by',flex:2, headerName: intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.HISTORY.USER' }), valueGetter: (params) => { return params.data?.CreatedBy?.first +' '+ params.data?.CreatedBy?.last_name } },
        { field: 'report_definition', flex:4, headerName: intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.HISTORY.CHANGE' })},
    ]

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);


    const rowData = response?.reportDefinition

    const onCellDoubleClick = (params) => {
        setTemplate(JSON.parse(params.data.report_definition))
        setReportId(params.data.report_id)
        setShowReport(true)
    }

    return (
        <>
            <ViewHistoryModal 
                show={showReport}
                handleClose={() => setShowReport(false)}
                template={template}
                Width="100%" 
                Height="100%"
                reportId={reportId}
            />
            <div className='form-group mb-2'>
                <div className='row mb-2'>                    
                    <div className='col-lg-12 align-items-center mb-2'>
                        <OutputListingGridStatic area ={ "Reports - History"} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle} onCellDoubleClick={onCellDoubleClick} ></OutputListingGridStatic>
                    </div>
                </div>
            </div>
        </>
    );
}

export { ReportsHistoryTab }