import { useIntl } from "react-intl"
import { useAlert, useLoadingOverlay, useProgress } from "../../../../modules/sgl-utils/DialogsProvider"
import { useOutputContext, useOutputContextUpdater } from "../../../../modules/output-listing/OutputListingContext"
import { useEffect, useState } from "react"
import { useAppSelector } from "../../../../redux/hooks"
import axios from "axios"
import { createPortal } from "react-dom"
import { Modal } from "react-bootstrap"
import { KTSVG } from "../../../../../_metronic/helpers"
import ShowsGrid from "./ShowsGrid"
import moment from "moment"


const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const modalsRoot = document.getElementById('root-modals') || document.body
 
const AllShows = ({show, handleClose, displayVerificationDialog, entryIds, setEntryIds, selectedShow, setSelectedShow}) => {
    const intl = useIntl() 
    const loadingOverlay = useLoadingOverlay()
    const alertDialog = useAlert()
    const {getOutputSelectionAreaIDs, getEventID} = useOutputContext()
    const [rowData, setRowData] = useState([])
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const currentShowId = useAppSelector(state => state.currentShow.show_id)
    const [selectedShowIndex, setSelectedShowIndex] = useState(-1)

    const handleSave = () =>{
        if(selectedShow){
            loadingOverlay({ show: true })
            // show progress bar
            axios.post(`${NEST_API_URL}/entries/verifyMoveEntriesToShow`, {
                customer_id: customerId,
                entry_ids: entryIds,
                source_show_id: currentShowId,
                destination_show_id: selectedShow.show_id
            })
            .then((res) => {
                loadingOverlay({ show: false })
                displayVerificationDialog(res.data.verification_data)
            })
            .catch((e) => {
                loadingOverlay({ show: false })
            })
        }
        else{
            // Alert if no show selected
            alertDialog({ message: intl.formatMessage({ id: "ENTRIES.MOVEENTRIESTOSHOW.NOSELECTEDSHOWERROR"})})
        }
    }

    useEffect(() => {
        if (show) {
            loadingOverlay({ show: true })
            getOutputSelectionAreaIDs(false)
            .then(res => { 
                if (res.length === 0) {
                    loadingOverlay({ show: false })
                    // error message if no entry is selected
                    alertDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.MOVEENTRIESTOSHOW.MODAL.ERROMESSAGE.SELECTENTRIES"}) })
                    handleClose()
                }
                else{
                    // if user selected any entries
                    setEntryIds(res)
                    // get shows to display in modal grid
                    axios.get(`${NEST_API_URL}/shows?customer_id=${customerId}`)
                    .then(response => {   
                        if(response?.data?.shows.length > 0)
                        {
                            let shows = response?.data?.shows
                            //START: Remove current source show from showslist
                            shows = shows.map((show, index) => {
                                if(show.show_id !== currentShowId){// update show_name
                                    const formattedStartDate = show.start_date !== "0000-00-00" ?moment(show.start_date).format('DD/MM/YY') : '';
                                    show.show_name = "[" + formattedStartDate + "] " + show.show_name
                                    return show
                                }else{
                                    setSelectedShowIndex(index-1)
                                    return null
                                }
                            })
                            shows = shows.filter((show) => show !== null && show?.islocked == 0);
                            //End: Remove current source show from showslist
                            setRowData(shows)
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            alertDialog({message: error.response.data.error});
                        }
                    })
                    .finally(() => loadingOverlay({ show: false }));
                    
                }
            })
        }  
    }, [show])

    return createPortal(
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
                show={show}
                onHide={handleClose}
                onKeyDown={(event) => {
                    let isButtonFocused = false
                    let activeElement = document.activeElement //gets the currently focussed element
                    if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                        isButtonFocused = true;
                    }
                    if (event.key === "Enter" && !isButtonFocused) {
                        handleSave()
                    }
                }}
            >
                <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
                <div className='modal-header py-0 px-4'>
                    <h2 className="fs-4">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.HEADING.MOVEENTRIESTOSHOW' })}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
    
                <div className='modal-body py-3 px-4'>
                    <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                        <div className='card-body p-0'>
                            <ShowsGrid
                                rowData={rowData} selectedShowIndex={selectedShowIndex} setSelectedShow={setSelectedShow} tabIndex={1}
                            />
                        </div>

                        <div className='card-footer d-flex justify-content-end py-3 px-0'>
                            <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={handleClose} tabIndex={2}>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CANCEL' })}
                            </button>

                            <button type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={() => handleSave()} tabIndex={3}>
                                <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.SELECT.SHOW' })}
                            </button>
                        </div>
                    </form>
                </div>
      </Modal>
      ,modalsRoot
    )
}

export default AllShows