import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { defaultColDef, getNarrowHeaderHeight,getNarrowRowHeight } from "../../../../modules/sgl-utils/agGridHelpers";
import { useFormContext } from "react-hook-form";

const OfficialGrid = ({ data, gridRef, tabIndex=0 }) => {
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    const methods = useFormContext()

    // Returns the headers for the grid
    const getHeaders = () => { 
        let headers = []
        let governing_org = methods?.getValues()?.governing_organization ? methods?.getValues()?.governing_organization  : ''
        // Set the header value for Membership No. column based on the governing organization
        if(governing_org == 'USEF'){ headers.push("USEF No.") }
        else if(governing_org == 'EC'){ headers.push("EC SLN") }
        else if(governing_org == 'FEI'){ headers.push("FEI No.") }
        else{ headers.push("Membership No.") }
        // Add Name column in the headers
        headers.push("Name")
        return headers
    }

    const getMembershipNumber = (params) => {
        let governing_org = methods?.getValues()?.governing_organization
        if(governing_org == 'USEF'){ return params?.data?.usef_number_ahsa_number }
        else if(governing_org == 'EC'){ return params?.data?.ec_sln }
        else if(governing_org == 'FEI'){ return params?.data?.fei_id }
        else{ return '' }
    }

    const onFirstDataRendered = useCallback((params) => {
        params.api.sizeColumnsToFit();
    }, []);

    const onGridReady = useCallback((params) => {
        params.api.sizeColumnsToFit();  
    }, []);

    let officialGridHeaders = getHeaders() // will return ['Name', 'USEF No.']
    const columnDefs = [
        { 
            //Header name will be decided based on the governing organization
            field: 'membership_no', 
            headerName: officialGridHeaders[0],
            headerClass: 'ag-center-aligned-header',
            cellStyle: { textAlign: 'center' },
            maxWidth: 100,
            valueGetter: getMembershipNumber,
            sortable: true
        },
        { 
            field: 'fl_name', 
            headerName: officialGridHeaders[1],
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            sortable: true
        }
    ]

    return (
        <div>
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        rowData={data}
                        onFirstDataRendered={onFirstDataRendered}
                        columnDefs={columnDefs} 
                        rowStyle={{ fontSize: '12px' }}
                        suppressScrollOnNewData={true}
                        headerHeight={getNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                        rowSelection= {'single'}
                        onGridReady={onGridReady}
                        overlayLoadingTemplate={'<span class="ag-overlay-loading-center">One moment please...</span>'}
                        {...(tabIndex !== 0 && { tabIndex })} //tabIndex is set conditionally, only if a prop was passed, provided that the prop is not 0 
                    />
                </div>
            </div>
        </div>
    )
}


export default OfficialGrid