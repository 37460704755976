import { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form';

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic'
import moment from 'moment';
import DateRenderer from '../../../../modules/output-listing/renderers/DateRenderer';


const modalsRoot = document.getElementById('root-modals') || document.body

const SelectShowForm = (props: any) => {
    const intl = useIntl()
    const methods = useFormContext();
    const [gridRef, setGridRef] = useState<any>()

    const [rowData, setRowData] = useState(methods.watch ('other_shows')?methods.watch ('other_shows'):[]);

    const columnDefs = [
        { 
            field: 'show_name',
            flex: 1, sortable: true, headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.SHOWS.SHOWNAME' })
        },
        { 
            field: 'start_date', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.SHOWS.STARTDATE' }), 
            cellClass: 'text-center', 
            cellRenderer: DateRenderer ,
            cellStyle: { textAlign: 'center' },
            width: 120
        },
        { 
            field: 'end_date', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.SHOWS.ENDDATE' }), 
            cellClass: 'text-center',
            cellRenderer: DateRenderer , 
            cellStyle: { textAlign: 'center' },
            width: 120
        },
    ]

    const updateRowData = () => {
        // if (methods.watch('exculeOutOfRangeShows') === true){
            let shows  = methods.watch ('other_shows')

            if (shows && shows.length > 0) {

                let shows_in_range: {
                    show_id: any;
                    start_date: number;}[] =[]

                for (let show of shows){
                    
                    let show_start_date = moment(show.start_date).format('YYYY-MM-DD')
                    let circuit_start_date = moment(methods.getValues ('circuit.start_date')).format('YYYY-MM-DD')
                    let show_end_date = moment(show.end_date).format('YYYY-MM-DD')
                    let circuit_end_date = moment(methods.getValues ('circuit.end_date')).format('YYYY-MM-DD')
                    
                    if(moment(show_start_date).isSameOrAfter(circuit_start_date) && moment(show_end_date).isSameOrBefore( circuit_end_date)){
                        shows_in_range.push(show)
                    } 
                }

                // set these 
                let rowData = shows.filter((shows: { show_id: any; }) => {
                    let is_deleted = shows_in_range.find((show_in_range) => {
                        return (shows.show_id == show_in_range.show_id)
                    })
                    if(is_deleted) {
                        return shows
                    }
                })  
                
                setRowData(rowData)

            }

        // } else {
        //     let shows  = methods.watch ('other_shows')
        //     if (shows && shows.length > 0) {
        //         setRowData (shows)
        //     }
        // }
    }

    useEffect ( ()=>{
        updateRowData ();
    }, [])

    function handleSelectedShows() {
        if (gridRef) {
            
            let selected_shows = gridRef.getSelectedRows()

            // and if any of these shows exist in deleted show array, delete that show from there too
            if(selected_shows.length > 0){

                const deletedShows = methods.watch ('show_deleted')?methods.getValues('show_deleted'): [];
                if (deletedShows.length > 0){
                    const deleted_shows  = deletedShows.filter((s: { show_id: any; }) => {
                        return !selected_shows.some((show: { show_id: any; }) => s.show_id === show.show_id);
                    });
                    methods.setValue('show_deleted', deleted_shows, {shouldDirty:true})
                }

                // if these selected shows were already in circuitxShow_data and were deleted from there, 
                // we will just adding them again into circuitxShow_data, so before adding 
                // we will check if their flag (deleted:true) change that flag to false
                for(let show of selected_shows){
                    if (show.deleted && show.circuitxshow_id && show.circuitxshow_id > 0 ){
                        show.deleted = false
                    }
                    
                }

                const updated_shows = [...methods.getValues ('circuitxShow_data'), ...selected_shows];
                methods.setValue( 'circuitxShow_data', updated_shows, {shouldDirty: true})
               

                // delete these selected shows from other shows
                const otherShows = methods.getValues ('other_shows')
                if(otherShows.length > 0){
                    const filtered_shows  = otherShows.filter((s: { show_id: any; }) => {
                        return !selected_shows.some((show: { show_id: any; }) => s.show_id === show.show_id);
                    });

                    methods.setValue('other_shows', filtered_shows, {shouldDirty:true})
                    setRowData(methods.getValues('other_shows'))
                }
                
            }

        }

        // methods.setValue('exculeOutOfRangeShows', false , {shouldDirty: false})
        props.setShowSelectShowForm(false); 

      }
    
    useEffect (()=>{
    })

    useEffect(() => {
    }, [gridRef, methods.getValues('other_shows')]);

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

    const handleHide = () => {
        // methods.setValue('exculeOutOfRangeShows', false , {shouldDirty: true})
        props.handleClose ()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px search-form'
            show={props.show}
            onHide={handleHide}
            onKeyDown={(event:any) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) {
                    handleSelectedShows()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.SHOWS.MODAL.SELECTSHOW.TITLE.SELECTSHOW' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleHide}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={handleHide}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <OutputListingGridStatic  area ={"Circuits - Show Form"} 
                                columnDefs={columnDefs} 
                                rowData={rowData} 
                                containerStyle={containerStyle}
                                setGridRef={setGridRef}
                                selection={'multiple'}
                            ></OutputListingGridStatic>
                        </div>
                        {/* <div className='row mb-2'>
                            <div className='col-lg-12 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    {...methods.register( 'exculeOutOfRangeShows', 
                                        {
                                            required: false
                                        })
                                    }
                                    id='EXCLUDESHOWS'
                                    autoFocus
                                    tabIndex={1}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='EXCLUDESHOWS' data-tooltip-id="CIRCUITS.DETAIL.MODAL.SELECTSHOW.LABEL.EXCLUDESHOWS"> {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.SHOWS.MODAL.SELECTSHOW.LABEL.EXCLUDESHOWS' })}</label>
                            </div>
                        </div> */}
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>

                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={(e) => {e.preventDefault(); props.setShowSelectShowForm(false)}} tabIndex={2}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={(e)=> {handleSelectedShows();e.preventDefault()}} tabIndex={3} data-tooltip-id="CIRCUITS.DETAIL.MODAL.SELECTSHOW.BUTTON.SELECT">
                            {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.SHOWS.MODAL.SELECTSHOW.BUTTON.SELECT' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { SelectShowForm }