import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import TemplateGrid from './PlacingTemplate/TemplateGrid';
import axios from 'axios';
import { KTSVG } from '../../../../_metronic/helpers';
import "flatpickr/dist/themes/material_blue.css";
import { removeDialogFromSession, truncateToNumPlaces } from '../../../modules/sgl-utils/SglFunctions';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import InputModal from '../../../modules/hocs/ModalHOC';
import { updateAGGridWithoutFlickering } from '../../../modules/sgl-utils/agGridHelpers';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const defaultTemplate = {
    label: 'Create New Template...', value: 0, placings: 0, addbackperentry: 0, addbackentries: 0, ClassPrizesTemplates: []
}

const PlacingTemplate = ({ show, handleClose }) => {
    const intl = useIntl() 
    const loadingOverlay = useLoadingOverlay()
    const confirmDialog = useConfirm()
    const {progressBar} = useProgress()
    const alertDialog = useAlert()
    const { getOutputSelectionAreaIDs, getEventID } = useOutputContext();
    const customerId = useAppSelector(state=> state.showCompany.company_id)
    const currencyCode = useAppSelector(state => state.showCompany.currency_code) 
    const showFinancialsLocked = useAppSelector(state=> state.currentShow.islocked)

    const [gridRef, setGridRef] = useState()
    const [classTemplates, setClassTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(defaultTemplate)
    const [applyToAllClasses, setApplyToAllClasses] = useState(false)

    const [showInputModal, setShowInputModal] = useState(false)
    const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true)
    const [isApplyBtnDisabled, setIsApplyBtnDisabled] = useState(true)

    // Get Ag-grid data
    const getRowData = (_gridRef) => {
        const rowData = []
        _gridRef.forEachNode(node => rowData.push(node.data));
        return rowData
    }

    // Metadata Call
    useEffect(() => {
        if (show && gridRef) {
            loadingOverlay({ show: true })
            axios.get( NEST_API_URL + `/classes/getPlacingsTemplateMetadata?customer_id=${customerId}`)
            .then(response => {

                const _classTemplates = response.data.classTemplates
                if (_classTemplates.length > 0) {
                    setClassTemplates(_classTemplates)
                    const lastTemplate = _classTemplates[_classTemplates.length - 1]
                    // setSelectedTemplate({ label: lastTemplate.template_name, value: lastTemplate.class_template_id, ...lastTemplate})
                }

                loadingOverlay({ show: false })
            })
            .catch(() => loadingOverlay({ show: false }))
        }  
    }, [show, gridRef])

    useEffect(() => {
        if (show) {
          const storedModals = JSON.parse(sessionStorage.getItem('modals_open') || '[]');
          sessionStorage.setItem('modals_open', JSON.stringify([...storedModals, 'placingTemplates']));
        }
    }, [show]);

    useEffect(() => {
        if(show){      
            const handleEnterKey = (event) => {
                let isNotAGGridEditableCell = (!event?.target?.className?.includes('ag-input-field-input') && !event?.target?.className?.includes('ag-text-field-input'))
                let activeElement = document.activeElement
                let isButtonFocused = false
               
                if (activeElement && activeElement.tagName === 'BUTTON') {
                    isButtonFocused = true
                }

                const storedModals = sessionStorage.getItem('modals_open');
                const modals_open = storedModals ? JSON.parse(storedModals) : [];
                const lastModal = modals_open.pop();
                if (event.key === 'Enter' && !event.shiftKey && !isButtonFocused && lastModal === 'placingTemplates') {
                    if (isNotAGGridEditableCell) {
                        let submitButton = document.getElementById('applyTemplateButton')
                        submitButton?.click()
                    }
                }
                if(event.key === 'Escape' && activeElement.id.includes('selectTemplate') && lastModal === 'placingTemplates'){ //in case Select element is in focus, explicitly call onClose function
                    onClose()
                }
            };
            
            document.addEventListener('keydown', handleEnterKey, true);
            
            return () => {
                document.removeEventListener('keydown', handleEnterKey, true);
            };
        }
    }, [show]);

    useEffect(() => {
        if (gridRef && selectedTemplate) {
            const sortedRows = selectedTemplate.ClassPrizesTemplates.sort((a, b) => a.place - b.place); // ASC sort on place            
            // Update the row data with correct sequential values for place column. During rendering numbers get changed
            const updatedRows = sortedRows.map((row, index) => {
                return {
                    ...row,
                    place: index + 1  // Ensure the 'place' is sequential, starting from 1
                };
            }); 
            
            // Trim excess rows if the number of rows exceeds Placings
            if (updatedRows.length > selectedTemplate.placings) {
                updatedRows.splice(selectedTemplate.placings); // Trim rows to match no of placings
            }       
            gridRef.setRowData(updatedRows); // Update row data in the grid
            updatePinnedRow()

            setIsApplyBtnDisabled(selectedTemplate.value === 0) // New Template
            setIsSaveBtnDisabled(selectedTemplate.value === 0) // New Template
        }
    }, [gridRef, selectedTemplate.ClassPrizesTemplates]) // On Select Template Change

    // Updates Ag-grid on blur of number of places
    const handleNumberOfPlacesBlur = () => {
        const rowData = getRowData(gridRef)
        let newPlacings = selectedTemplate.placings
        const oldPlacings = rowData.length

        if (newPlacings > oldPlacings) {
            for (let i = 0; i < newPlacings - oldPlacings; i++) {
                rowData.push({ place: oldPlacings + i + 1, points: 0, prize_money: 0, addbackpercent: 0, totalprize: 0})
            }    
        } else if (newPlacings < oldPlacings) {
            for (let i = 0; i < oldPlacings - newPlacings; i++) {
                rowData.pop()
            }
        }
        
        gridRef.setRowData(rowData)
        updatePinnedRow()
    }

    // !4D -> class_SetPlacing_RecalcTotal
    const reCalculateTotal = () => {
    
        const rowData = getRowData(gridRef)
        let totalAddBack = selectedTemplate.addbackperentry * selectedTemplate.addbackentries

        for (let row of rowData) {
            row.totalprize =  row.prize_money + truncateToNumPlaces((totalAddBack*row.addbackpercent)/100, 2)
        }

        updateAGGridWithoutFlickering({ current: {api: gridRef }}, rowData, 'place')
    }

    const inputRef = useRef()
    // Saves new Template
    const saveAs = async () => {
        const templateName = inputRef.current.value
        const rowData = getRowData(gridRef)

        let message = ''
        if (templateName.length === 0) {
            message = 'Please enter a name for Template.'
        } else if (templateName.length > 80) {
            message = 'Name is more than 80 characters'
        } else if (classTemplates.find(ct => ct.template_name === templateName)) {
            message = "A Template with same name exists."+ " Template is not saved."
        } else if (gridRef.getPinnedBottomRow(0).data.addbackpercent > 100) {
            message = "Total Add-Backs cannot exceed 100%."
        } 

        if (message) {
            alertDialog({ message, icon: 'stop'})
            setShowInputModal(false)
            return
        }

        try {
            const template = { ...selectedTemplate, template_name: templateName }
            delete template.class_template_id // Remove PKey as we have to create new template

            const response = await axios.post(NEST_API_URL + '/classes/createUpdateClassTemplate', {
                template,
                row_data: rowData.map(({class_prizes_template_id, ...rest}) => rest), // Remove PKey as we have to create new template
                customer_id: customerId
            })
            
            const { success, classTemplates, newClassTemplate } = response.data
            if (!success) {
                alertDialog({ message: response.data.error })
            } else {
                setClassTemplates(classTemplates)
                setSelectedTemplate({ label: newClassTemplate.template_name, value: newClassTemplate.class_template_id, ...newClassTemplate})
                setShowInputModal(false)
            }
        } catch (reason) {
            setShowInputModal(false)
        }   
    }

    // Updates Existing Class Template
    const updateTemplate = async () => {
        if (gridRef.getPinnedBottomRow(0).data.addbackpercent > 100) {
            alertDialog({ message: "Total Add-Backs cannot exceed 100%." })
            return
        } 

        try {
            if (!isSaveBtnDisabled) { //if save button is not disabled
                const rowData = getRowData(gridRef)
                setIsSaveBtnDisabled(true) //disable save button
                const response = await axios.post(NEST_API_URL + '/classes/createUpdateClassTemplate', {
                    template: {...selectedTemplate },
                    row_data: rowData,
                    customer_id: customerId
                })
                
                const { success, classTemplates, newClassTemplate } = response.data
                if (!success) {
                    alertDialog({ message: response.data.error })
                } else {
                    setClassTemplates(classTemplates)
                    setSelectedTemplate({ label: newClassTemplate.template_name, value: newClassTemplate.class_template_id, ...newClassTemplate})
                    setShowInputModal(false)
                }
                setIsSaveBtnDisabled(false) //enable save button after response received
            }            
        } catch (reason) {
            setIsSaveBtnDisabled(false) //enable save button in case of error
            setShowInputModal(false)
        }   
    }

    const deleteTemplate = async () => {
        if (selectedTemplate.value > 0) {// Not Create New Template...
           const choice = await confirmDialog({ message: "Delete Template "+ selectedTemplate.label +" ?" })
           if (choice) {
                const response = await axios.delete(NEST_API_URL + `/classes/removeClassTemplate?customer_id=${customerId}&class_template_id=${selectedTemplate.value}`)

                if (response.data.success) {
                    // Remove from current list
                    setClassTemplates(classTemplates.filter(ct => ct.class_template_id !== selectedTemplate.value))
                    setSelectedTemplate(defaultTemplate)
                } else {
                    alertDialog({ message: response.data.error })
                }
           }
        }
    }

    const updatePinnedRow = () => {
        
        reCalculateTotal()

        const rowData = getRowData(gridRef)

        let totalPrizeMoney = 0
        let totalAddBackPercent = 0
        let totalPrize = 0
        for (let row of rowData) {
            totalPrizeMoney += row.prize_money
            totalAddBackPercent += row.addbackpercent
            totalPrize += row.totalprize
        }

        gridRef.setPinnedBottomRowData([{
            place: 'Totals',
            prize_money: totalPrizeMoney,
            addbackpercent: totalAddBackPercent,
            totalprize: totalPrize
        }])
    }

    const applyTemplate = async () => {

        loadingOverlay({ show: true })
        let class_response;
        if(applyToAllClasses){ // Get current and future show classes
            class_response = await axios.get(NEST_API_URL + '/classes/getClassesByPrizeTemplate', {
                params:{
                    prize_template: selectedTemplate?.template_name,
                    customer_id: customerId,
                }
            })
        }
        let classIds = await getOutputSelectionAreaIDs()
        if(applyToAllClasses){ // Selection plus current and future show classes
            classIds = [...new Set([...classIds, ...class_response?.data ?? -1])] // Unique array
        }
        loadingOverlay({ show: false })

        let confirmMessge = "Selected Template will be applied to "+ classIds.length
        confirmMessge += applyToAllClasses ? " records in current and future shows classes. Do you want to continue?" : " records. Do you want to continue?"

        const choice = await confirmDialog({ message: confirmMessge })
        if (choice) {
            try {
                const eventID = getEventID('apply-placings-template')
                progressBar({ show: true, title: 'Applying Template', showProgress: 'inline', eventID })
                const response = await axios.post(NEST_API_URL + '/classes/applyPlacingsTemplate', {
                    class_template_id: selectedTemplate.value,
                    event_id: eventID,
                    customer_id: customerId,
                    apply_to_all_classes: applyToAllClasses,
                    class_ids: classIds
                })
                progressBar({ show: false, eventID })

                const { success, errorSet } = response.data
                if (!success) {
                    alertDialog({ message: response.data.error })
                } else if (errorSet.length > 0) {
                    alertDialog({ 
                        message: "Template is not applied to following classes because they have results: " + errorSet.join(", ") + "."
                    })
                }
            } catch (reason) {}   
        }
    }

    const onClose = () => {
        removeDialogFromSession('placingTemplates')
        handleClose()
    }
    
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={onClose}
            backdrop='static'
            // onKeyPress={(event) => {
            //     if (event.key == 'Enter') {
            //         applyTemplate()
            //     }
            // }}
        >
            {
                showInputModal &&
                <InputModal 
                    show={showInputModal}
                    handleClose={() => setShowInputModal(false)}
                    handleSubmit={saveAs}
                    modalTitle='Save As'
                >
                    <div className='row mb-2'>
                        <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor="newTemplateName">
                            { intl.formatMessage({ id: 'Classes.QuickAction.PlacingsTemplate.NameOfTemplate' }) }
                        </label>
                        <div className='col-lg-8'>
                            <input
                                ref={inputRef}
                                type="text"
                                id="newTemplateName"
                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                tabIndex={1}
                                autoFocus
                            />
                        </div>
                        <div className='card-footer d-flex justify-content-end py-3 px-3'>
                            <button 
                                type="button" 
                                className='btn btn-sm btn-secondary fw-bold text-uppercase me-3'
                                onClick={() => setShowInputModal(false)}
                                tabIndex={2}
                            >
                                {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CANCEL" })}
                            </button>
                            <button 
                                type="button" 
                                className='btn btn-sm btn-dark fw-bold text-uppercase'
                                onClick={saveAs}
                                tabIndex={3}
                            >
                                {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.SAVE" })}
                            </button>
                        </div>
                    </div>
                </InputModal>
            }
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'Classes.QuickAction.PlacingsTemplate.Modal.Heading' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='card-body p-0 px-2'>
                    <div className='row mb-2'>
                        <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor="selectTemplate">
                            { intl.formatMessage({ id: 'Classes.QuickAction.PlacingsTemplate.Label.SelectTemplate' }) }
                        </label>
                        <div className='col-lg-8'>
                            <Select
                                type="text"
                                inputId="selectTemplate" 
                                theme={theme => ({...theme, borderRadius: 'none'})}
                                value={selectedTemplate}
                                onChange={e => setSelectedTemplate(e)}
                                options={classTemplates.map(ct => ({ label: ct.template_name, value: ct.class_template_id, ...ct}))}
                                autoFocus
                                tabIndex={4}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor="numberOfPlaces" data-tooltip-id="CLASS.DETAIL.QUICKACTION.PLACINGTEMPLATE.LABEL.NUMBEROFPLACES">
                            { intl.formatMessage({ id: 'Classes.QuickAction.PlacingsTemplate.Label.NumberOfPlaces' }) }
                        </label>
                        <div className='w-lg-125px'>
                            <NumericFormat
                                id="numberOfPlaces"
                                value={selectedTemplate.placings}
                                className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                valueIsNumericString={true} // Allow only numeric input
                                allowNegative={false} // disallow negative values
                                onValueChange={ e => {
                                    e.floatValue = e.floatValue? e.floatValue: 0
                                    setSelectedTemplate(prevSelectedTemplate => ({
                                    ...prevSelectedTemplate, 
                                    placings: e.floatValue
                                }))}}
                                onBlur={handleNumberOfPlacesBlur}
                                isAllowed={values => {
                                    if(!values.value) return true
                                    const {floatValue} = values
                                    return floatValue >= 0 && floatValue < 100
                                }}
                                decimalScale={0}
                                tabIndex={5}
                            />      
                        </div>
                        <label className='col col-form-label fs-5 py-1' htmlFor="addBackEntry" data-tooltip-id="CLASS.DETAIL.QUICKACTION.PLACINGTEMPLATE.LABEL.ADDBACKENTRY">
                            { intl.formatMessage({ id: 'Classes.QuickAction.PlacingsTemplate.Label.Add-back-entry' }) }
                        </label>
                        <div className='w-lg-125px'>
                            <NumericFormat
                                id="addBackEntry"
                                value={selectedTemplate.addbackperentry}
                                className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                valueIsNumericString={true}// Allow only numeric input
                                allowNegative={false} // disallow negative values
                                onValueChange={ e => {
                                    e.floatValue = e.floatValue? e.floatValue: 0
                                    setSelectedTemplate(prevSelectedTemplate => ({
                                    ...prevSelectedTemplate, 
                                    addbackperentry: e.floatValue
                                }))}}
                                isAllowed={values =>{
                                    if(!values.value) return true;
                                    const {floatValue} = values
                                    return floatValue >= 0 && floatValue <= 100000
                                }}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={intl.formatMessage({ id: `CURRENCY.CODE.SYMBOL.${currencyCode}`})}
                                onBlur={reCalculateTotal}
                                tabIndex={6}
                            />      
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor="addBackEntryOverride" data-tooltip-id="CLASS.DETAIL.QUICKACTION.PLACINGTEMPLATE.LABEL.ADDBACKENTRYOVERRIDE">
                            { intl.formatMessage({ id: 'Classes.QuickAction.PlacingsTemplate.Label.Add-Back-Entry-Override' }) }
                        </label>
                        <div className='w-lg-125px'>
                            <NumericFormat
                                id="addBackEntryOverride"
                                value={selectedTemplate.addbackentries}
                                className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                valueIsNumericString={true} // Allow only numeric input
                                allowNegative={false} // disallow negative values
                                onValueChange={ e => {
                                    e.floatValue = e.floatValue? e.floatValue: 0
                                    setSelectedTemplate(prevSelectedTemplate => ({
                                    ...prevSelectedTemplate, 
                                    addbackentries: e.floatValue
                                }))}}
                                isAllowed={values =>{
                                    if(!values.value) return true;
                                    const {floatValue} = values
                                    return floatValue >= 0 && floatValue < 100
                                }}
                                onBlur={reCalculateTotal}
                                decimalScale={0}
                                tabIndex={7}
                            />      
                        </div>
                    </div>

                    <div className='d-flex justify-content-end mb-4 gap-3'>
                        <button 
                            type="button" 
                            className='btn btn-sm btn-secondary fw-bold text-uppercase' 
                            onClick={deleteTemplate}
                            tabIndex={8}
                            data-tooltip-id="CLASS.DETAIL.QUICKACTION.PLACINGTEMPLATE.BUTTON.DELETE">
                            {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.DELETE" })}
                        </button>

                        <button
                            type="button" 
                            className='btn btn-sm btn-secondary fw-bold text-uppercase'
                            onClick={updateTemplate}
                            disabled={isSaveBtnDisabled}
                            tabIndex={9}
                            data-tooltip-id="CLASS.DETAIL.QUICKACTION.PLACINGTEMPLATE.BUTTON.SAVE"
                            >
                            {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.SAVE" })}
                        </button>

                        
                        <button 
                            type="button" 
                            className='btn btn-sm btn-secondary fw-bold text-uppercase'
                            onClick={() => setShowInputModal(true)}
                            tabIndex={10}
                            data-tooltip-id="CLASS.DETAIL.QUICKACTION.PLACINGTEMPLATE.BUTTON.SAVEAS"
                            >
                            {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.SAVEAS" })}
                        </button>
                    </div>
                    <TemplateGrid 
                        setGridRef={setGridRef}
                        updatePinnedRow={updatePinnedRow}
                        tabIndex={11}
                    />
                    <div className='row'>
                        <div className='col form-check-sm form-check-custom ps-lg-3'>
                            <input
                                name="applyToAllClasses"
                                onChange={e => setApplyToAllClasses(e.target.checked)}
                                checked={applyToAllClasses}
                                className='form-check-input'
                                type='checkbox'
                                id='ApplyToAllClasses'
                                tabIndex={12}
                            />
                            <label className='col-form-label mx-2 fs-5 py-1' htmlFor='ApplyToAllClasses' data-tooltip-id="CLASS.DETAIL.QUCIKACTION.PLACINGTEMPLATE.LABEL.APPLYPRIZETEMPLATETOALLLINKEDCLASSES"> {intl.formatMessage({ id: 'Classes.QuickAction.PlacingsTemplate.Label.ApplyToAllClasses' })}</label>
                        </div>
                    </div>
                </div>
         
                <div className='card-footer d-flex justify-content-end py-3 px-2'>
                    <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={13}>
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CLOSE" })}
                    </button>

                    <button 
                        id='applyTemplateButton'
                        type="button" 
                        className='btn btn-sm btn-dark fw-bold text-uppercase'
                        disabled={isApplyBtnDisabled}
                        onClick={applyTemplate}
                        tabIndex={14}
                        data-tooltip-id="CLASS.DETAIL.QUICKACTION.PLACINGTEMPLATE.BUTTON.APPLY"
                        >
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.APPLY" })}
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default PlacingTemplate