import {ListHeader} from './header/ListHeader'

const ListToolbarWrapper = () => {
  return (
    <>      
        <ListHeader />
    </>
  )
}

const ListToolbarMain = () => (
        <ListToolbarWrapper />
)

export {ListToolbarMain}
