import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay, useProgress} from '../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext, useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext';
import axios from 'axios'
import { useIntl } from 'react-intl'


const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const useMarkEntriesAsPickedUp = () => {
    const intl = useIntl()
    const loadingOverlay = useLoadingOverlay()
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const outputContextUpdater = useOutputContextUpdater()
    const { getOutputSelectionAreaIDs, getEventID } = useOutputContext();
    const {progressBar} = useProgress()
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const customerId = useAppSelector(state => state.showCompany.company_id)

    const markEntriesAsPickedUp = async () => {
        loadingOverlay({ show: true })

        let entryIds = await getOutputSelectionAreaIDs(false)
        if (entryIds.length === 0) {
            loadingOverlay({ show: false })
            // error message if no entry is selected
            alertDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.MARKENTRIESASPICKEDUP.MODAL.ERROMESSAGE.SELECTENTRIES"}) })
        }
        else{
            // if user selected any entries
            loadingOverlay({ show: false })
            // ask user for confirmation
            confirmDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.MARKENTRIESASPICKEDUP.MODAL.CONFIRM"}) +" "+ entryIds.length +" " + intl.formatMessage({ id: "MENU.ENTRIES"}) + "?"})
            .then((choice) => {
                if(choice){
                    // close the modal if choice is false means user selected no
                    let eventID = getEventID("mark-entries-as-picked-up")
                    // show progress bar
                    progressBar({ show: true, eventID, showProgress: 'inline', title: intl.formatMessage({ id: "ENTRIES.MARKENTRIESASPICKEDUP.PROGRESSBAR.MESSAGE" }) })
                    axios.post( process.env.REACT_APP_NEST_API_URL + "/entries/markEntriesAsPickedUp", {
                        customer_id: customerId,
                        entry_ids: entryIds,
                        show_id: currentShowID,
                        progress_event_id: eventID
                    })
                    .then((res) => {
                        if(res.data.success){
                            // Alert user about success
                            alertDialog({ message: intl.formatMessage({ id: "ENTRIES.MARKENTRIESASPICKEDUP.SUCCESSMESSAGE"}) , icon: "info"})
                            //refresh list
                            outputContextUpdater({action: 'refresh'})
                        }
                        else{
                            alertDialog({ message: intl.formatMessage({ id: "ENTRIES.MARKENTRIESASPICKEDUP.NOENTRIESFOUND" }) })
                        }
                        
                    })
                    .catch((e) => {
                    })
                    .finally( () => {
                        progressBar({ show: false, eventID })
                    })
                }
            })
        }
    }

    return markEntriesAsPickedUp
}

export default useMarkEntriesAsPickedUp