
const ClassesSelectRenderer = (params, divisionClasses, selectedValue, divisionNumber, handleChange) => {
    return (
        <select 
            className='form-select form-select-sm fs-6 min-h-20px py-1 mt-2'
            onChange={e => handleChange(params.rowIndex, e.target.value, divisionNumber)}
            value={selectedValue}
            >
            { divisionClasses.map(cl => <option key={cl.class_id} value={cl.class_id}>{ `${cl.number} - ${cl.name}` }</option>) }
        </select>
    );
}

export default ClassesSelectRenderer;