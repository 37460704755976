export class DateStringEditor {
  init(params) {
    this.eInput = document.createElement('input');
    this.eInput.type = 'text';

    // Remove default CSS
    this.eInput.style.border = 'none';
    this.eInput.style.outline = 'none';

    // Add ag-Grid classes
    this.eInput.classList.add('ag-input-field-input', 'ag-text-field-input');

    // Set initial value
    if (params.charPress) {
      this.eInput.value = params.charPress;
    } else if (params.value !== undefined && params.value !== null) {
      this.eInput.value = params.value;
    }

    // Set placeholder
    this.eInput.placeholder = 'MM/DD/YYYY';
    this.eInput.style.fontSize = '13.5px';
    this.eInput.style.fontWeight = '400';

    // Handle keypress event
    this.eInput.addEventListener('keypress', (event) => {
      // Add your custom keypress logic if needed
    });

    this.cancelBeforeStart = false;
  }

  getGui() {
    return this.eInput;
  }

  afterGuiAttached() {
    this.eInput.focus();
  }

  isCancelBeforeStart() {
    return this.cancelBeforeStart;
  }

  isCancelAfterEnd() {
    // Add your custom logic if needed
    return false;
  }

  getValue() {
    return this.eInput.value;
  }

  destroy() {
    // Cleanup if needed
  }

  isPopup() {
    return false;
  }
}
