
import { ActiveUsersWidget } from './ActiveUsersWidget'
import { PaymentWidget } from './PaymentWidget'
import useAccessChecker from '../../modules/hooks/use-access-checker';
import { ActiveUsersAllCompaniesWidget } from './ActiveUsersAllCompaniesWidget';
import { useAuth } from '../../modules/auth';
import { QueuedJobsWidget } from './QueuedJobsWidget';
const AnalyticsTab = (props) => {
    const { hasAtLeastOnePermission } = useAccessChecker()
    const activeUsersPermissionsRequired = ['cloud_dashboard-analytics:active-users-widget'];
    const paymentsPermissionsRequired = ['cloud_dashboard-analytics:payments-widget'];
    const { currentUser } = useAuth()

    return (
      <>
        <div className='row m-5 mt-0 p-0 mb-0'>
          {
            hasAtLeastOnePermission(activeUsersPermissionsRequired) &&
              <div className='col-md-6 col-lg-6 col-xl-4 col-xxl-4 ms-0 p-0 me-5 mb-5' style={{ boxSizing: "border-box" }}>
                <ActiveUsersWidget />
              </div>
          }

          {
            hasAtLeastOnePermission(paymentsPermissionsRequired) &&
              <div className='col-md-6 col-lg-6 col-xl-5 col-xxl-5 ms-0 p-0 me-0 mb-5' style={{ boxSizing: "border-box" }}>
                <PaymentWidget />
              </div>
          }

{
            hasAtLeastOnePermission(['cloud_sgadmin:has_access']) &&
              <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 ms-0 p-0 me-5 mb-5' style={{ boxSizing: "border-box" }}>
                <ActiveUsersAllCompaniesWidget />
              </div>
          }

          {
            currentUser?.id == 510 && <QueuedJobsWidget />
          }
        </div>
      </>  
    );
}
export {AnalyticsTab}