import { PageTitle } from "../../../../_metronic/layout/core"
import image from '../../../../_metronic/assets/CustomImages/403.png'

const ForbiddenAccess = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Forbidden Access</PageTitle>
            <img className="mx-auto d-block" src={image} />
        </>
    )
}

export default ForbiddenAccess