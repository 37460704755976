import React, {useState, useRef} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAppSelector } from "../../../../redux/hooks"
import axios from 'axios'
import { useOutputContext, useOutputContextUpdater } from '../../../../modules/output-listing/OutputListingContext'
import { useAlert, useProgress } from '../../../../modules/sgl-utils/DialogsProvider'
import OfficialGrid from './OfficialGrid'
import { SearchInput } from '../../../entries/Detail/SearchInput'
import { useFormContext } from 'react-hook-form'

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
 
const AddNewOfficial = ({show, handleClose}) => {
    const intl = useIntl() 
    const currentShowId = useAppSelector(state => state.currentShow.show_id)
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const governingOrganization = useAppSelector(state => state.showCompany.governing_organization)
    const alertDialog = useAlert();
    const methods = useFormContext()

    const gridRef = useRef();
    const [oldSearchTerm, setOldSearchTerm] = useState('')
    const [keyPressTimeout, setKeyPressTimeout] = useState('')
    const [rowData, setRowData] = useState([])
    const [isJudge, setIsJudge] = useState(true)
    const [isCourseDesigner, setIsCourseDesigner] = useState(false)

    // add show_judges in show
    const addNewOfficial = () => {
        //If no row selected display error and return
        if (!gridRef?.current?.api || gridRef?.current?.api?.getSelectedRows().length == 0) {
            alertDialog({message: intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.NO.OFFICIAL.SELECTED.ERROR' })})
            return  
        }

        //Get existing officials and selected RTO
        let showOfficials = methods.getValues('officials') ?? []
        let selectedRTO = gridRef?.current?.api?.getSelectedRows()[0]
        // Check if the official is already present as either designer or judge
        const isDesignerPresent = showOfficials.some((official) => official.iscoursedesigner && official.people_id === selectedRTO.people_id);
        const isJudgePresent = showOfficials.some((official) => official.isjudge && official.people_id === selectedRTO.people_id);

        // If the official is already present as both designer and judge, display error and return
        if (isDesignerPresent && isJudgePresent) {
            return;
        }

        // If the official is already present in the same role, display error and return
        if ((isDesignerPresent && isCourseDesigner) || (isJudgePresent && isJudge)) {
            return;
        }
        
        //Add official in show 
        let newOfficial = {
            show_id: currentShowId,
            people_id: selectedRTO.people_id,
            iscoursedesigner: isCourseDesigner,
            isjudge: isJudge,
            fl_name: selectedRTO.fl_name,
            membership_number: selectedRTO.membership_number,
            sgl_id: 0
        } 
        showOfficials.push(newOfficial)
        methods.setValue('officials', showOfficials, {shouldDirty:true})
    }

    // functions for officials search bar >>>
    const onSearch = (e) => {
        if(e.key == 'Enter'){
            e.target.blur();
        }else{
            const timeout = setTimeout(function(){
                searchOfficials(e.target.value)
            }
            , 1000)
            if(keyPressTimeout){
                clearTimeout(keyPressTimeout);
            }
            setKeyPressTimeout(timeout)
        }
    }

    const onBlur = (e) => {
        searchOfficials(e.target.value)
    }

    const searchOfficials = (searchTerm) => {
        if(!searchTerm){// If search bar is empty
            setOldSearchTerm((prev_state) => searchTerm)
            setRowData([])
            return
        }

        if(searchTerm !== oldSearchTerm){
            //api to perform search
            setOldSearchTerm((prev_state) => searchTerm)
            gridRef?.current?.api?.showLoadingOverlay()
            axios.get(`${NEST_API_URL}/people/getRTOForOfficialsSelection?customer_id=${customerId}&search_term=${searchTerm}&show_id=${currentShowId}`)
            .then(response => {   
                //Set the data for grid
                setRowData(response.data.rtoRecords)

                if(response.data.createNewRecord){
                    /*
                    Note: If the person not found in local db but it is found look up than add it in rto_added list
                    on form submission we will check if the newly added rto is added as official in show than it will be send to BE to be created. 
                    */
                    let newPersonRecord = response.data.lookupPersonData.people_data
                    let membership_number = 0
                    if(governingOrganization == 'USEF'){
                        membership_number = newPersonRecord.usef_number_ahsa_number
                    }else if(governingOrganization == 'EC'){
                        membership_number = newPersonRecord.ec_sln
                    }else if(governingOrganization == 'FEI'){
                        membership_number = newPersonRecord.fei_id
                    }
                    
                    let newRTORecords = methods.getValues('rtos_added') ?? []
                    let existingNewRecord = newRTORecords.find((person) => {
                        if(governingOrganization == 'USEF' && person.person_data.usef_number_ahsa_number == membership_number){
                            return true
                        }else if(governingOrganization == 'EC' && person.person_data.ec_sln == membership_number){
                            return true
                        }else if(governingOrganization == 'FEI' && person.person_data.fei_id == membership_number){
                            return true
                        }
                        return false
                    })
                    if(!existingNewRecord || existingNewRecord?.length == 0){
                        newPersonRecord.membership_number = membership_number
                        newRTORecords.push({person_data: newPersonRecord, memberships_data: response.data.lookupPersonData.memberships_data})
                        methods.setValue('rtos_added', newRTORecords, {shouldDirty:true})
                    }
                }
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
            }).finally(() => {
                gridRef?.current?.api?.hideOverlay()
            })
        }
    }
    // function for officials search bar <<<

    return createPortal(
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
                show={show}
                onHide={handleClose}
                onKeyDown={(event) => {
                    let isButtonFocused = false
                    let activeElement = document.activeElement //gets the currently focussed element
                    if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                        isButtonFocused = true;
                    }
                    if (event.key === "Enter" && !isButtonFocused) {
                        addNewOfficial()
                    }
                }}
            >
                <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
                <div className='modal-header py-0 px-4'>
                    <h2 className="fs-4">{intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.HEADING' })}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
    
                <div className='modal-body py-3 px-4'>
                    {/* Search Bar */}
                    <div className="d-flex">
                    <SearchInput containerClass='mb-3 me-2 w-225px' onSearch={onSearch} onBlur={onBlur} fieldName={'rto_search_for_officials'} autoFocus tabIndex={1}/>
                    <label className="fw-bolder fs-6 me-5 mt-2">{intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.MESSAGE' })}</label>
                    </div>

                    <OfficialGrid data={rowData} gridRef={gridRef}/>

                    {/* Official Type */}
                    <div className="w-500px d-flex">
                        <label className="fw-bolder fs-6 me-5 mt-2" data-tooltip-id="SHOW.DETAIL.MODAL.ADDNEWOFFICIAL.LABEL.OFFICIALTYPE">{intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.RADIO.BUTTON.LABEL.OFFICIAL.TYPE' })}</label>

                        {/* Judge Checkbox */}
                        <label className="mt-checkbox mt-checkbox-outline me-8 align-items-center d-flex w-50px mt-2" data-tooltip-id="SHOW.DETAIL.MODAL.ADDNEWOFFICIAL.LABEL.JUDGE">
                        <input
                            type="radio"
                            checked={isJudge}
                            onChange={() => {
                                setIsJudge(!isJudge)
                                setIsCourseDesigner(!isCourseDesigner)
                            }}
                            tabIndex={3}
                        />&nbsp;<span className="fw-bolder fs-6 ms-1">{intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.RADIO.BUTTON.LABEL.JUDGE' })}</span>
                        </label>

                        {/* Course Designer Checkbox */}
                        <label className="mt-checkbox mt-checkbox-outline align-items-center d-flex w-200px mt-2">
                        <input
                            type="radio"
                            checked={isCourseDesigner}
                            className='add-search-radio'
                            onChange={() => {
                                setIsJudge(!isJudge)
                                setIsCourseDesigner(!isCourseDesigner)
                            }}
                            tabIndex={4}
                        />&nbsp;<span className="fw-bolder fs-6 ms-1 add-search-label" data-tooltip-id="SHOW.DETAIL.MODAL.ADDNEWOFFICIAL.LABEL.COURSEDESIGNER">{intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.RADIO.BUTTON.LABEL.COURSE.DESIGNER' })}</span>
                        </label>
                    </div>       

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={handleClose} tabIndex={5} data-tooltip-id="SHOW.DETAIL.MODAL.ADDNEWOFFICIAL.BUTTON.DONE">
                            {intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.BUTTON.DONE' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={() => addNewOfficial()} tabIndex={6} autoFocus data-tooltip-id="SHOW.DETAIL.MODAL.ADDNEWOFFICIAL.BUTTON.ADD">
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.BUTTON.ADD' })}
                        </button>
                    </div>
                </div>
      </Modal>
      ,modalsRoot
    )
}

export default AddNewOfficial