import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import { useRef, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Designer } from '@grapecity/activereports-react'

const modalsRoot = document.getElementById('root-modals') || document.body

const ViewPreviewModal = ({ show, handleClose, reportDetails, setReportDetails }) => {

    const intl = useIntl()
    const designerRef = useRef()

    const onClose = async () => {
        let reportDefinition = await designerRef.current.getReport()
        setReportDetails(reportDefinition)
        handleClose()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered view-preview-modal'
            show={show}
            onHide={onClose}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4"></h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <div id="viewer-host">
                    {reportDetails && 
                        <Designer report={reportDetails} ref={ designerRef } />
                    }
                </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-3 px-0'>
                <button className='btn btn-sm btn-dark fw-bold text-uppercase me-3' onClick={onClose} type="button">
                    {intl.formatMessage({ id: 'REPORT.POPUP.LABEL.OK' })}
                </button>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { ViewPreviewModal }