import { useState } from "react";
import { useAlert } from "../../../modules/sgl-utils/DialogsProvider";

const SelectRenderer = (params, updatePaymentBatchItems, pendingPayments, gridReference) => {
    let options = ['Prize Money Check', 'Balance Refund Check']
    let [ value, setValue ] = useState(params.node.data.payment_type)
    let alertDialog = useAlert()

    const handleChange = (e) => {
        // Allow payment_type change only if the batch item 'type' is not defined
        const types = ['Prize Money', 'Balance Refund'];
        if (!types.includes(params.data.PaymentBatch.type)) {
            updatePaymentBatchItems([params.node.data.payment_batchitems_id], 'updateBatchItem', 'payment_type', e.target.value, params.node.data.payment_type, gridReference);
            setValue(e.target.value)
            params.node.data.payment_type = e.target.value
        } else {
            // If batch item type is defined retain the previous payment type
            alertDialog({ message: `Type for ${params.data.PaymentBatch.type} only batch cannot be changed.` });
            let item = pendingPayments.find((payment) => payment.payment_batchitems_id === params.node.data.payment_batchitems_id)
            if(item){
                setValue(item.payment_type);
            }
        }
    }; 

    return (
        <select 
            className='form-select form-select-sm fs-7 py-0 mt-1'
            onChange={(e) => handleChange(e)}
            value={value}
            style={{height: '22px'}}
            >
            { options.map(option => <option value={option}>{ option }</option>) }
        </select>
    );
}

export default SelectRenderer;