import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import Select from "react-select";
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls';
import { useAlert, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import axios from 'axios';

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";
import moment from 'moment';
import useFileDownloader from '../../../modules/hooks/use-file-downloader';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const ExportShowSchedule = ({ show, handleClose }) => {
    const intl = useIntl() 
    const { getEventID } = useOutputContext();
    const { startFileDownloader, forceCloseFileDownloader } = useFileDownloader()
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const [showsData, setShowsData] = useState([])
    const [selectedShow, setSelectedShow] = useState()
    const [selectedDay, setSelectedDay] = useState()
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const currentShowName = useAppSelector(state => state.currentShow.show_name);

    const showOptions = showsData.map(({ show }) => ({ label: show.show_name, value: show.show_id }))
    const dayOptions = selectedShow ? showsData.find(({show}) => selectedShow.value === show.show_id).days.map((date, idx) => ({ label: `Day ${idx+1}: ${moment(date, 'YYYY-MM-DD').format('MMMM D, YYYY')}`, value: date})) : []

    const [includeStartTime, setIncludeStartTime] = useState(true)
    const [includeEndTime, setIncludeEndTime] = useState(true)
    const [includeSeparateTripCount, setIncludeSeparateTripCount] = useState(true)

    const loadingOverlay = useLoadingOverlay()
    const {progressBar} = useProgress()
    const alertDialog = useAlert()

    useEffect(() => {
        if (show) {
            loadingOverlay({ show: true })
            axios.get( NEST_API_URL + `/classes/getExportShowScheduleMetadata?customer_id=${customerId}`)
            .then(response => {
                setShowsData(response.data)

                if (response.data.length > 0) {
                    setSelectedShow({ 
                        label: currentShowName ? currentShowName : response.data[0].show.show_name, 
                        value: currentShowID ? currentShowID : response.data[0].show.show_id
                    })

            
                    const showData = response.data.find(obj => obj.show.show_id === currentShowID)
                    if (showData) {
                            setSelectedDay({ 
                                label: `Day 1: ${moment(showData.days[0], 'YYYY-MM-DD').format('MMMM D, YYYY')}`, 
                                value: showData.days[0] 
                            })
                    }else{
                        setSelectedDay({ 
                            label: `Day 1: ${moment(response.data[0].days[0], 'YYYY-MM-DD').format('MMMM D, YYYY')}`, 
                            value: response.data[0].days[0]
                        })
                    }
                }

                loadingOverlay({ show: false })
            })
            .catch(() => loadingOverlay({ show: false }))
        }  
    }, [show])

    const onClose = () => {
        handleClose();  
    }

    const exportShowSchedule = () => {
        const progressEventId = getEventID("export-show-schedule")
        const fileBufferEventId = getEventID("export-show-schedule-filebuffer")

        progressBar({ show: true, eventID: progressEventId, showProgress: 'inline', title: "Preparing Data for Export", timeElapsed: true })
        startFileDownloader(fileBufferEventId)
        
        axios.post( NEST_API_URL + `/classes/exportShowSchedule`, {
            customer_id: customerId,
            show_id: selectedShow.value,
            export_options: {
                includeStartTime,
                includeEndTime,
                includeSeparateTripCount
            },
            schedule_date: selectedDay.value,
            progress_event_id: progressEventId,
            file_buffer_event_id: fileBufferEventId
        })
        .then(() => progressBar({ show: false, eventID: progressEventId }))
        .catch(reason => {
            if (reason.response.data.error) {
                alertDialog({ message: reason.response.data.error })
            }

            progressBar({ show: false, eventID: progressEventId })
            forceCloseFileDownloader()
        })
    }   

    const handleShowChange = (e) => {
        setSelectedShow(e)

        const showData = showsData.find(obj => obj.show.show_id === e.value)
        if (showData) {
                setSelectedDay({ 
                    label: `Day 1: ${moment(showData.days[0], 'YYYY-MM-DD').format('MMMM D, YYYY')}`, 
                    value: showData.days[0] 
                })
        }
    }
    
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px search-form'
            show={show}
            onHide={onClose}
            onKeyDown={(event) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) {
                    exportShowSchedule()
                }
                if(event.key === 'Escape'){
                    onClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'TOOLBAR.QUICKACTION.EXPORTSHOWSCHEDULE' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <label className='col col-form-label fs-5 pt-0 fw-bold'>
                            {intl.formatMessage({ id: "CLASSES.QUICKACTION.EXPORTSHOWSCHEDULE.SUBHEADING.ONE" })}
                        </label>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-100px' htmlFor="selectShow" data-tooltip-id="CLASS.DETAIL.QUICKACTION.EXPORTSHOWSCHEDULE.LABEL.SELECTSHOW">
                                { intl.formatMessage({ id: "CLASSES.QUICKACTION.EXPORTSHOWSCHEDULE.LABEL.SELECTSHOW" }) }
                            </label>
                            <div className='col-lg-9'>
                                <Select
                                    value={selectedShow}
                                    styles={reactSelectStyles}
                                    inputId="selectShow"
                                    options={showOptions}
                                    onChange={handleShowChange}
                                    autoFocus
                                    tabIndex={1}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-100px' htmlFor="selectDay" data-tooltip-id="CLASS.DETAIL.QUICKACTION.EXPORTSHOWSCHEDULE.LABEL.SELECTDATE">
                            { intl.formatMessage({ id: "CLASSES.QUICKACTION.EXPORTSHOWSCHEDULE.LABEL.SELECTDATE" }) }
                            </label>
                            <div className='col-lg-9'>
                                <Select
                                    value={selectedDay}
                                    styles={reactSelectStyles}
                                    inputId="selectDay"
                                    options={dayOptions}
                                    onChange={e => setSelectedDay(e)}
                                    tabIndex={2}
                                />
                            </div>
                        </div>

                        <label className='col col-form-label fs-5 pt-0 fw-bold'>
                            {intl.formatMessage({ id: "CLASSES.QUICKACTION.EXPORTSHOWSCHEDULE.SUBHEADING.TWO" })}
                        </label>
                        <div className='row'>
                            <div className='w-lg-25px form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type="checkbox"
                                    id="exportScheduleOpt1" 
                                    checked={includeSeparateTripCount}
                                    onChange={e => setIncludeSeparateTripCount(e.target.checked)} 
                                    tabIndex={3}
                                />
                            </div>
                           
                            <label className='col col-form-label fs-5 py-1' htmlFor='exportScheduleOpt1' data-tooltip-id="CLASS.DETAIL.QUICKACTION.EXPORTSHOWSCHEDULE.LABEL.INCLUDESEPRATETRIPCOUNTSFORCLASSGROUPS">
                                { intl.formatMessage({ id: "CLASSES.QUICKACTION.EXPORTSHOWSCHEDULE.EXPORTOPTION.ONE" }) }
                            </label>
                        </div>

                        <div className='row'>
                            <div className='w-lg-25px form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type="checkbox"
                                    id="exportScheduleOpt2" 
                                    checked={includeStartTime}
                                    onChange={e => setIncludeStartTime(e.target.checked)} 
                                    tabIndex={4}
                                />
                            </div>
                           
                            <label className='col col-form-label fs-5 py-1' htmlFor='exportScheduleOpt2' data-tooltip-id="CLASS.DETAIL.QUICKACTION.EXPORTSHOWSCHEDULE.LABEL.INCLUDECLASSESTIMATEDSTARTTIME">
                                { intl.formatMessage({ id: "CLASSES.QUICKACTION.EXPORTSHOWSCHEDULE.EXPORTOPTION.TWO" }) }
                            </label>
                        </div>

                        <div className='row'>
                            <div className='w-lg-25px form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type="checkbox"
                                    id="exportScheduleOpt3" 
                                    checked={includeEndTime}
                                    onChange={e => setIncludeEndTime(e.target.checked)} 
                                    tabIndex={5}
                                />
                            </div>
                           
                            <label className='col col-form-label fs-5 py-1' htmlFor='exportScheduleOpt3' data-tooltip-id="CLASS.DETAIL.QUICKACTION.EXPORTSHOWSCHEDULE.LABEL.INCLUDECLASSESTIMATEDENDTIME">
                                { intl.formatMessage({ id: "CLASSES.QUICKACTION.EXPORTSHOWSCHEDULE.EXPORTOPTION.THREE" }) }
                            </label>
                        </div>
                    </div>
                   

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={6}>
                            {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CANCEL" })}
                        </button>

                        <button 
                            type="button" 
                            className='btn btn-sm btn-dark fw-bold text-uppercase' 
                            onClick={exportShowSchedule}
                            tabIndex={7}
                            data-tooltip-id="CLASS.DETAIL.QUICKACTION.EXPORTSHOWSCHEDULE.BUTTON.EXPORT">
                            {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.EXPORT" })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default ExportShowSchedule