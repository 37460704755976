import moment from 'moment';
import { useMemo, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import BooleanCheckmarkRenderer from '../../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import { useAlert, useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
import { useAuth } from '../../../modules/auth';
import NumberRenderer from '../../../modules/output-listing/renderers/NumberRenderer';
import DropdownRenderer from '../../../modules/output-listing/renderers/DropdownRenderer'


const EntriesTab = ({ callbackFromParent, dropTrip }) => {
    const intl = useIntl();
    const methods = useFormContext()
    const [gridRef, setGridRef] = useState()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const {currentUser} = useAuth()
    const show_financials_locked = methods.watch('show_tables.islocked')
    const [buttonText, setButtonText] = useState(intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.SCRATCH' }))

    const dropTripHandler = async () => {
        if (gridRef.getSelectedRows().length > 0) { // If row is selected
            if (methods.getValues("combined_class_id") > 0) {
                alertDialog({ message: "Entry cannot be dropped in Combined Class." })
            } else if(gridRef.getSelectedRows()[0].trip_verified){ //Checked for verified trips
                alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.DROPTRIPS.ALREADYVERIFIED"})})
            }
            // else if(gridRef.getSelectedRows()[0].gone_in){ //check for gone trips
            //     alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.DROPTRIPS.ALREADYGONE"})})
            // }
            else if(gridRef.getSelectedRows()[0].placing > 0){ //check for placed trips
                alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.DROPTRIPS.ALREADYPLACED"})})
            }
            else {
                let choice
                if(gridRef.getSelectedRows()[0].gone_in){
                    choice = await confirmDialog({ message: "The trip is already gone. Are you sure you want to drop the selected Entry?"})
                } else {
                    choice = await confirmDialog({ message: "Are you sure you want to drop the selected Entry?"})
                }
                if (choice) {
                    const selectedTrip = gridRef.getSelectedRows()[0]

                    const drop_res =  await dropTrip(selectedTrip)

                    if (drop_res?.error_message) {
                        alertDialog({ message: drop_res.error_message})
                    } else { // Trip can be dropped
                        if(drop_res?.dropped_trips?.length > 0){
                            let deletedTrips = methods.getValues("deletedTrips")

                            if (deletedTrips) {
                                deletedTrips = deletedTrips.concat(drop_res.dropped_trips)
                                methods.setValue("deletedTrips", deletedTrips, { shouldDirty: true })
                            } else {
                                methods.setValue("deletedTrips", drop_res.dropped_trips, { shouldDirty: true })
                            }
                            selectedTrip.isDropped = true // Mark trip as deleted

                        } 
                        

                         // Remove from all trips list
                        const individualTrips = methods.getValues("individual_trips")
                        methods.setValue("entryxclasses", methods.getValues("entryxclasses").filter(exc => !exc.isDropped))
                        methods.setValue("individual_trips", individualTrips.filter(exc => !exc.isDropped))
                    } 
                }
            }
        }
    }

    useEffect(() => {
        if(gridRef){
            let colDefs = gridRef.getColumnDefs()
            if(methods.getValues('classes.combined_class')){ 
                colDefs[10].hide = false
                gridRef.setColumnDefs(colDefs)
            }
            gridRef.setRowData(methods.getValues('entryxclasses'))
        }
    }, [gridRef, methods.getValues('entryxclasses')]);

    // class_GetTripRiderStatusColumn
    const scratchEntry = async () => {
        var selected = gridRef.getSelectedRows()
        let entryxclasses = methods.getValues('entryxclasses')

        if(selected.length > 0){   
            if(methods.getValues('combined_class_id') > 0){
                alertDialog({message: "Entry cannot be scratched in Combined Class."})
            }
            else{
                if(selected[0].scratch_trip){
                    let choice = await confirmDialog({message: "Are you sure you want to unscratch the selected Entry?"})
                    if(choice){
                        selected[0].scratch_trip = false
                        setScratchDisqualifyStatus(selected[0])

                        // Remove scratched flag of trip
                        delete selected[0]["isScratched"]

                        setButtonText(intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.SCRATCH' }))
                    }
                }
                else{
                    if(selected[0].trip_verified){ //Checked for verified trips
                        alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.SCRATCHEDTRIPS.ALREADYVERIFIED"})})
                    }
                    else if(selected[0].gone_in){ //check for gone trips
                        alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.SCRATCHEDTRIPS.ALREADYGONE"})})
                    }
                    else if(selected[0].placing > 0){ //check for placed trips
                        alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.SCRATCHEDTRIPS.ALREADYPLACED"})})
                    }
                    else{
                        let choice = await confirmDialog({message: "Are you sure you want to scratch the selected Entry?"})
                        if(choice){

                            selected[0].scratch_trip = true
                            setScratchDisqualifyStatus(selected[0])

                            // Set scratched flag of trip
                            selected[0].isScratched = true
                            
                            setButtonText(intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.UNSCRATCH' }))

                            let count = 0
                            entryxclasses.map(data => {
                                if(data.trip_verified == false && data.scratch_trip == false){
                                    count ++
                                }
                            })

                            if(!count){
                                methods.setValue('classes.results_verified', true)
                                if(methods.getValues('classes.verification_detail') == ""){ //Set verification detail
                                    methods.setValue('classes.verification_detail',moment().format("MM/DD/YY - h:mm A")+" - "+currentUser.first_name+" "+currentUser.last_name, {shouldDirty:true})
                                }
                            }
                        }
                    }
                }
            }
        }
        gridRef.setRowData(entryxclasses)
    }

    const setScratchDisqualifyStatus = (trip) => {
        if(trip.scratch_trip){//Set disqualify status as DNS on setting trip as scratched
            if(trip.disqualify_status_one == ""){ //disqualification status is not already set
                trip.disqualify_status_one = "DNS"
            }
        }
        else if(trip.disqualify_status_one == "DNS"){  //Clear Disqualify status on unscratch trip if its  DNS
            trip.disqualify_status_one = ""
        }
        return trip
    }

    // This function will changed the label of scratch button on the basis of scratch_trip
    const onCellClicked = () => {
        var selected = gridRef.getSelectedRows()
        if(selected[0].scratch_trip){
            setButtonText(intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.UNSCRATCH' }))
        }
        else{
            setButtonText(intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.SCRATCH' }))
        }
    }

    const columnDefs = [
        { field: 'order_of_go', headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.ORDEROFGO' })},
        { field: 'Entry.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.ENTRY' }) },
        { field: 'scratch_trip', headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.SCRATCHED' }), cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' } },
        { field: 'Entry.horse', width:200, headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.HORSE' }),},
        { field: 'Entry.owner', width:200, headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.OWNER' }),},
        { field: 'Entry.trainer', width:200, headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.TRAINER' }),},
        { field: 'rider_name', width:200,headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.RIDERNAME' }),},
        { field: 'Entry.status', headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.STATUS' }),},
        {
            field: 'fence_height',
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.FENCEHEIGHT' }),
            width: 210,
            sortable: false,
            cellRendererFramework: (params) => {
                const options = getFenceHeightList(params.data);
                const heights = methods.getValues('classes.heights_in_inches') ? methods.getValues('classes.heights_in_inches').split(",") : [];
                const disabled = options.length == 1 && heights?.length == 0;
        
                return (
                    <DropdownRenderer
                        name='fence_height'
                        params={params}
                        options={options}
                        column={'fence_height'}
                        value={params?.node.data?.fence_height}
                        rowId={params?.node.data?.entryxclasses_id}
                        onUpdate={handleUpdate}
                        disabled={disabled} 
                    />
                );
            },
          },
        { field: 'EntryRider.status', headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.RIDERSTATUS' }), },
        { field: 'ActualClass.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.ACTUALCLASS' }), cellClass: 'ag-right-aligned-cell', cellStyle: { textAlign: 'center' }, cellRenderer: NumberRenderer, hide: true},
        { field: 'Team.team_name', width:150,headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.TEAMNAME' }),},
    ]

    function getFenceHeightList(entryClass) {
        const heights = methods.getValues('classes.heights_in_inches')
            ? methods.getValues('classes.heights_in_inches').split(",")
            : [];
        
        const preselectedValue = entryClass?.fence_height;
    
        let dropdownList = [];
        const uniqueValues = new Set(); 
    
        dropdownList.push({ label: "Select", value: "" });
        uniqueValues.add(""); 
    
        if (preselectedValue) {
            const preselectedValueStr = preselectedValue.toString();
            if (!uniqueValues.has(preselectedValueStr)) {
                dropdownList.push({
                    label: preselectedValueStr,
                    value: preselectedValueStr
                });
                uniqueValues.add(preselectedValueStr);
            }
        }
    
        heights.forEach(height => {
            const parsedHeight = parseInt(height.trim(), 10);
            if (parsedHeight && !uniqueValues.has(parsedHeight.toString())) {
                dropdownList.push({
                    label: parsedHeight,
                    value: parsedHeight
                });
                uniqueValues.add(parsedHeight.toString());
            }
        });

        dropdownList.sort((a, b) => {
            const valueA = a.value === "" ? -Infinity : parseInt(a.value, 10);
            const valueB = b.value === "" ? -Infinity : parseInt(b.value, 10);
            return valueA - valueB;
        });
    
        return dropdownList;
    }
    
    
    const handleUpdate = (tripId, column, columnValue) => {
        let entryxclasses = methods.getValues('entryxclasses');
        let rowId = entryxclasses?.findIndex(trip => trip.entryxclasses_id == tripId)
        if (rowId >= 0 && rowId < entryxclasses.length) {
            const updatedEntryxclasses = [...entryxclasses];
        
            if (column === 'fence_height') {
                updatedEntryxclasses[rowId].fence_height = columnValue;
            }
            
            methods.setValue('entryxclasses', updatedEntryxclasses);
            methods.setValue('updated_trips_heights', updatedEntryxclasses, { shouldDirty: true });
        } 
    };
    
    
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

    return (
        <>
            <div className='row'>
                <div className='col-lg-11'>
                    <p className='mb-1 text-end'>Total: {methods.getValues('entryxclasses') && methods.getValues('entryxclasses').length}</p>
                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-lg-11 align-items-center mb-2'>
                    <OutputListingGridStatic key={methods.watch('classes.heights_in_inches') || methods.watch('classes.combined_class')} onRowDoubleClicked={(row_data) => {callbackFromParent(row_data.Entry.entry_id, "Entries", "EntryDetail", {"updated_trip": row_data}, row_data)}} area ={"Classes - Entries"} setGridRef={setGridRef} onCellClicked={onCellClicked} columnDefs={columnDefs} rowData={[]} containerStyle={containerStyle}></OutputListingGridStatic>
                </div>

                <div className='col-lg-1'>
                    <button disabled={show_financials_locked} type='button' className="btn btn-sm btn-secondary fw-bold col-12 text-uppercase mb-2" onClick={() => scratchEntry()} data-tooltip-id="CLASS.DETAIL.TAB.ENTRIES.BUTTON.SCRATCH">
                        { buttonText }
                    </button>
                    <div className="separator my-1 border-transparent d-xl-block d-lg-block d-md-none d-sm-none d-none"></div>
                    <button disabled={show_financials_locked} type='button' className='btn btn-sm btn-secondary fw-bold col-12 me-5 text-uppercase mb-2' onClick={dropTripHandler} data-tooltip-id="CLASS.DETAIL.TAB.ENTRIES.BUTTON.DROP">
                        {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.DROP' })}
                    </button>
                </div>
            </div>
        </>
    );
}

export { EntriesTab }