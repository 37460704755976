import {useIntl} from 'react-intl'
import { useNavigate } from 'react-router-dom';
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import BooleanCheckmarkRenderer from '../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import { BooleanFilterOptions } from '../../modules/output-listing/filters/BooleanFilter';
import { useAppSelector } from '../../redux/hooks';

const RingsList = () => {
    const intl = useIntl();
    const navigate = useNavigate()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    
    const columnDefs = [
        { field: 'ring_id', headerName: intl.formatMessage({id: 'LIST.RINGS.RINGID'}), filter: 'agNumberColumnFilter', hide: true },
        { field: 'name', flex: 1, headerName: intl.formatMessage({id: 'LIST.RINGS.NAME'}), filter: 'agTextColumnFilter', },
        { field: 'active', headerName: intl.formatMessage({id: 'LIST.RINGS.ACTIVE'}), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, },
    ]

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.RINGS'})}</PageTitle>
            <OutputListingComponent 
                key={customer_id} 
                area={'Rings'} 
                columnDefs={columnDefs} 
                apiEndPoint={'rings/list'}
                onRowDoubleClicked={
                    rowData => navigate(`/rings/detail/${rowData.ring_id}?customer_id=${customer_id}`)
                }
                onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL+'/rings/detail/'+row_data.ring_id+`?customer_id=${customer_id}`,'_blank')}
                />
        </>
    )
}

export {RingsList}
