/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from "react-router-dom";
import {CreateAppModal} from '../../../partials'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {useIntl} from 'react-intl'
import {useOutputContextUpdater, useOutputContext} from '../../../../app/modules/output-listing/OutputListingContext'
import { QuickActions } from '../../../../app/modules/list-toolbar/header/QuickActions';
import { VerifyEntriesMenu } from '../../../../app/modules/list-toolbar/header/VerifyEntriesMenu';
import { CreateShowLicenseMenu } from '../../../../app/modules/list-toolbar/header/CreateShowLicenseMenu';
import { AddHorseMenu } from '../../../../app/modules/list-toolbar/header/AddHorseMenu';
import { ReportType } from '../../../../app/pages/reports/ReportTypeModal';
import useAccessChecker from '../../../../app/modules/hooks/use-access-checker';
import useDeleteRecords from '../../../../app/modules/hooks/use-delete-record';
import { useAppSelector } from '../../../../app/redux/hooks';
import { useAlert } from '../../../../app/modules/sgl-utils/DialogsProvider';
import { AddRTOMenu } from '../../../../app/modules/list-toolbar/header/AddRTOMenu';
import { ModifyRecords } from '../../../../app/modules/components/ModifyRecords';

const Toolbar1 = () => {
  const intl = useIntl()
  const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)
  const currentShowId = useAppSelector(state => state.currentShow.show_id);
  const {outputGrid, getOutputSelectionRecordIDs} = useOutputContext();
  const alertDialog = useAlert()
  const { hasAreaWritePermission, hasAreaDeletePermission, hasAreaModifyPermission } = useAccessChecker()
  const location = useLocation();

  const area = location.pathname.substring(1) // shows, entries, classes ..
  // Show Save button only if User has WRITE Permissions for that (Detail) Area.
  const hasWriteAccess = hasAreaWritePermission(area) 
  // Show Delete button only if User has DELETE Permissions for that (Detail) Area.
  const hasDeleteAccess = hasAreaDeletePermission(area) 
  const hasModifyAccess = hasAreaModifyPermission(area) 

  const { deleteRecords } = useDeleteRecords()

  const [inputFormLocation, setInputFormLocation] = useState('#')
  const [module_name, setModule] = useState('')

  const addNewRecordButtonRef = useRef<HTMLAnchorElement>(null);

  const getInputFormLocation  = () => {
    let module = location.pathname.split('/')[1]
    setModule(module);
    // To make detail page parent part of route same as of its list page
    switch(module){
      case 'shows': return '/shows/detail/0'
      case 'classes': return '/classes/detail/0'
      case 'classrules': return '/classrules/detail/0'
      case 'circuitdivisions': return '/circuitdivisions/detail/0'
      case 'circuits': return '/circuits/detail/0'
      case 'horses': return '/horses/detail/0'
      case 'divisions': return '/divisions/detail/0'
      case 'stables': return '/stables/detail/0'
      case 'masterfees': return '/masterfees/detail/0'
      case 'fees': return '/fees/detail/0'
      case 'taxrates': return '/taxrates/detail/0'
      case 'entries': return '/entries/detail/0'
      case 'showseries': return '/showseries/detail/0'
      case 'people': return '/people/detail/0'
      case 'facilities': return '/facilities/detail/0'
      case 'rings': return '/rings/detail/0'
      case 'barns': return '/barns/detail/0'
      case 'payments': return '/payments/detail/0'
      case 'reports': return '/reports/detail/0'
      case 'organizations': return '/organizations/detail/0'
      case 'payment-batches': return '/payment-batches/detail/0'
      default: return location.pathname
    }
  }

  useEffect(() => {
    setInputFormLocation(getInputFormLocation())
  }, [location.pathname])


  onkeydown = (e: any) => {
    if((e.ctrlKey || e.metaKey) && (e.code==='KeyP' || e.keyCode == 80)){ //Ctrl + P
      e.preventDefault()

      // Do not show print dialogue on reports area on CTRL + P key press
      if (outputGrid?.area == 'Reports') {
        return;
      }

      setShowReportSelector(true)
    }

    if ((e.ctrlKey || e.metaKey) && (e.code === 'KeyB' || e.keyCode === 66)) { // Ctrl + B or Command + B
      e.preventDefault();
      if (addNewRecordButtonRef.current) {
        addNewRecordButtonRef.current.click();
      }
    }
  };

  const {classes} = useLayout()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const outputContextUpdater = useOutputContextUpdater();
  const {showReportSelector, setShowReportSelector, disablePrintButton} = useOutputContext();
  const [showModifyModal, setShowModifyModal] = useState(false)

  // Print entry row with the template
  const printRows = async () => {
    // if (context.outputGrid.area == 'Entries') {
        setShowReportSelector(true);
    // }
  }
  const disableAdd = ["Payments", "SiteMonitoring", "CronJobs", "ErrorsListing", "CloudApiLogs"]
  const disablePrint = ["SiteMonitoring", "CronJobs", "ErrorsListing", "Reports", "CloudApiLogs"]
  const disableDelete = ["SiteMonitoring", "CronJobs", "ErrorsListing", "CloudApiLogs"]
  const disableModify = ["SiteMonitoring", "CronJobs", "ErrorsListing", "Reports", "CloudApiLogs"]
  const currentShowIndependentAreas = ["SiteMonitoring", "CronJobs", "ErrorsListing", "CloudApiLogs", "Shows", "ClassRules", "Circuits", "ShowSeries", "People", "Horses", "Stables", "MasterFees", "Facilities", "Rings", "Organizations", "Reports"]
  const addBtnCursorstyle={ cursor: disableAdd.includes(outputGrid?.area) || (outputGrid?.area == 'Entries' && showFinancialsLocked) || (!currentShowIndependentAreas.includes(outputGrid?.area) && (Boolean(currentShowId) == false || currentShowId <= 0)) ? "not-allowed" : "pointer" }
  const printBtnCursorstyle={ cursor: disablePrint.includes(outputGrid?.area) ? "not-allowed" : "pointer" }
  const modifyBtnCursorstyle={ cursor: disableModify.includes(outputGrid?.area) ? "not-allowed" : "pointer" }
  const deleteBtnCursorstyle={ cursor: (disableDelete.includes(outputGrid?.area) || (['Payments', 'Entries'].includes(outputGrid?.area) && showFinancialsLocked)) ? "not-allowed" : "pointer" }

  return (
    <>
      <div className='toolbar' id='kt_toolbar'>
        {/* begin::Container */}
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <DefaultTitle />

          {/* begin::Actions */}
          
          <div className={"d-flex align-items-center py-1 w-1000px w-sm-1000px w-md-1000px " + (module_name == "entries" ? " w-lg-600px w-xl-600px w-xxl-600px" : " w-lg-500px w-xl-500px w-xxl-500px") } >
            {/* begin::Wrapper */}

            <div className="btn-toolbar" style={{display:"flex",width:"100%"}}>
                <div className="btn-group btn-group-solid">
                    <a href="#" onClick={() => outputContextUpdater({action: 'clearFiltersAndRefresh'})} className="btn btn-icon btn-active-light mx-5 me-3 px-8 py-8">
                      <div className="fa-item">
                          <span className="svg-icon svg-icon-gray-800 svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.5" d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z" fill="currentColor"/>
                          <path fillRule="evenodd" clipRule="evenodd" d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z" fill="currentColor"/>
                          </svg>
                          </span>
                          <br/>
                          <span className="fs-8 fw-bold">{intl.formatMessage({id: 'TOOLBAR.LABEL.ALL'})}</span>
                      </div>
                    </a>

                    {(module_name != 'shows' && module_name != 'horses' && module_name != 'people')?
                      <Link 
                      ref={addNewRecordButtonRef}
                      style={addBtnCursorstyle}
                      onClick={(event) => {
                        if (addBtnCursorstyle.cursor == 'not-allowed' || (outputGrid?.area == 'Entries' && showFinancialsLocked)) {
                          if(outputGrid?.area == 'Entries' && showFinancialsLocked){
                            alertDialog({ title: 'warning', message: 'Cannot add entries in locked show.' })
                          }
                          event.preventDefault();
                        }
                      }}
                      to={hasWriteAccess ? inputFormLocation : '#'} className="btn btn-icon btn-active-light me-3 px-8 py-8 add-new-record-button">
                        <div className="fa-item">
                            <i style={addBtnCursorstyle} className={`fa fa-plus-circle fs-1 text-${(hasWriteAccess && addBtnCursorstyle.cursor == 'pointer') ? "green" : "gray"}`}></i>
                            <br/>
                            <span style={addBtnCursorstyle} className="fs-8 fw-bold">{intl.formatMessage({id: 'TOOLBAR.LABEL.ADD'})}</span>
                        </div>
                      </Link>
                      : module_name == 'shows' ? <CreateShowLicenseMenu hasWriteAccess={hasWriteAccess} addNewRecordButtonRef={addNewRecordButtonRef} /> 
                      : module_name == 'horses' ? <AddHorseMenu hasWriteAccess={hasWriteAccess} addNewRecordButtonRef={addNewRecordButtonRef} /> 
                      : module_name == 'people' ? <AddRTOMenu hasWriteAccess={hasWriteAccess} addNewRecordButtonRef={addNewRecordButtonRef} /> 
                      : ""
                    }

                     {/* Template selector modal */}
                     {showReportSelector && 
                        <ReportType show={showReportSelector} handleClose={() => setShowReportSelector(false)} />
                    }

                    <a className="btn btn-icon btn-active-light me-3 px-8 py-8" onClick={() => disablePrint.includes(outputGrid?.area) || disablePrintButton ? '' : printRows()} style={printBtnCursorstyle}>
                      <div className="fa-item">
                          <i className={`fa fa-print fs-1 text-${(disablePrint.includes(outputGrid?.area)) || disablePrintButton ? "gray" : "gray-800"}`}></i>
                          <br/>
                          <span className="fs-8 fw-bold">{intl.formatMessage({id: 'TOOLBAR.LABEL.PRINT'})}</span>
                      </div>
                    </a>

                    <a 
                        className="btn btn-icon btn-active-light me-2 px-8 py-8" style={deleteBtnCursorstyle}
                        onClick={(hasDeleteAccess && !disableDelete.includes(outputGrid?.area) && !(['Payments', 'Entries'].includes(outputGrid?.area) && showFinancialsLocked)) ? async () => await deleteRecords() : undefined}
                    >
                      <div className="fa-item">
                          <i className={`fa fa-times-circle fs-1 text-${(['Payments', 'Entries'].includes(outputGrid?.area) && showFinancialsLocked) ? 'grey' : hasDeleteAccess ? "red" : "gray"}` }></i>
                          <br/>
                          <span className="fs-8 fw-bold">{intl.formatMessage({id: 'TOOLBAR.LABEL.DELETE'})}</span>
                      </div>
                    </a>

                    {
                      <a className="btn btn-icon btn-active-light me-3 px-8 py-8" onClick={() => disableModify.includes(outputGrid?.area) || !hasModifyAccess ? '' : setShowModifyModal(true)} style={modifyBtnCursorstyle}>
                      <div className="fa-item">
                          <i className={`fa fa-edit fs-1 text-${(disableModify.includes(outputGrid?.area) || !hasModifyAccess) ? "gray" : "blue"}`}></i>
                          <br/>
                          <span className="fs-8 fw-bold">{intl.formatMessage({id: 'TOOLBAR.LABEL.MODIFY'})}</span>
                      </div>
                      </a>
                    }
                    {
                      (showModifyModal && 
                        <ModifyRecords recordIds={[]} area={outputGrid?.area} show={showModifyModal} handleClose={() => setShowModifyModal(false)} />
                      )
                    }
                    
                    {(module_name == 'entries') &&
                      <VerifyEntriesMenu />
                    }

                </div>

              <div className="btn-group btn-group-solid" style={{marginLeft: "auto",order: "2"}}>
              
                  <a id="quick-action-top-menu" href="#" className="btn btn-icon btn-active-light me-2 px-14 py-8 d-flex" data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                    <div className="fa-item">
                        <i className="fa fa-cog fs-1 text-gray-800"></i>
                        <i className="fa fa-sort-down fs-7 text-gray-800"></i>
                        <br/>
                        <span className="fs-8 fw-bold">{intl.formatMessage({id: 'TOOLBAR.LABEL.QUICKACTION'})}</span>
                    </div>
                  </a>

                  <QuickActions />

              </div>

            </div>
            
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Container */}
      </div>
      <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />
    </>
  )
}

export {Toolbar1}
