import { useMemo, useRef, useState } from "react";
import { useAlert, useConfirm, useLoadingOverlay } from "../../../../modules/sgl-utils/DialogsProvider";
import { useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import { defaultColDef, getNarrowHeaderHeight, getNarrowRowHeight, renderCheckBox } from "../../../../modules/sgl-utils/agGridHelpers";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { useAppSelector } from "../../../../redux/hooks";
import DecimalPointRenderer from "../../../../modules/output-listing/renderers/DecimalPointRenderer";
import ChangeRider from "./ChangeRider";
import useAccessChecker from "../../../../modules/hooks/use-access-checker";
import { useCurrencyFormatter } from "../../../../modules/sgl-utils/Formatters";

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const StandingsGrid = ({ setStandingsGridRef, findCircuitPointsForStanding, showHiddenStandings, getStandings }) => {
    const intl = useIntl();
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const methods = useFormContext()
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const containerStyle = useMemo(() => ({ width: '100%', height: '200px' }), [])
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const { hasAreaWritePermission } = useAccessChecker()
    // Change Standing Rider Variables
    const [riders, setRiders] = useState([])
    const [showChangeRiderForm, setShowChangeRiderForm] = useState(false)
    const [selectedRider, setSelectedRider] = useState(-1)
    const standingRef = useRef(null)
    const currencyFormatter = useCurrencyFormatter()

    // !4D -> inf_HandleStandingsListBox
    function showColumn(value){
        const standingsSelectionMethod = methods.getValues('circuit_division.standingsselectionmethod')

        if (value === 'Horse' && (standingsSelectionMethod === 'Horse/Rider'  || standingsSelectionMethod === 'Horse' )){
            return false
        } else if (value === 'Owner' && standingsSelectionMethod === 'Owner'){
            return false
        } else if (value === 'Trainer' && standingsSelectionMethod === 'Trainer'){
            return false
        } else if (value === 'Rider' &&( standingsSelectionMethod === 'Rider' || standingsSelectionMethod === 'Horse/Rider' || standingsSelectionMethod === 'Horse')){
            return false
        } else if ( value === 'ChangeRiderOption' && standingsSelectionMethod === 'Horse'){
            return false
        }

        return true
    }

    const handleRiderChange = async (params) => {
        const standing = params.data
        if(standing.hide_standing) { // if standing is set as hide
            alertDialog({ message: 'Hidden standing cannot be modified.' })
        } else if (standing.position < 1) {
            alertDialog({ message: 'Standing with 0 position cannot be modified.' })
        } else {
            try {
                loadingOverlay({ show: true, message: 'Loading Standing Riders..' })

                const response = await axios.post(`${NEST_API_URL}/circuit-divisions/getChangeStandingsRiderMetadata`, {
                    standing,
                    customer_id: customerId
                })

                standingRef.current = standing
                setRiders(response.data.riders)
                if (response.data.riders.length > 0) {
                    setSelectedRider(response.data.riders[0].riderId)
                    setShowChangeRiderForm(true)
                } else { // Should Never Happen!
                    alertDialog({ message: 'No rider found for this standing.'})
                }

                loadingOverlay({ show: false, message: 'Loading Standing Riders..' })
            } catch (reason) {

            }
        }
    }

    const handleHideStanding = async (params, hideStanding) => {
        let choice = true
        const standing = params.node.data
        if(hideStanding && standing.position > 0) {
            choice = await confirmDialog({ message: 'Are you sure you want to hide the standing? All subsequent standing positions will be renumbered.' })
        }

        if (choice) {
            try {
                loadingOverlay({ show: true, message: 'Updating Standings..' })
    
                const response = await axios.patch(`${NEST_API_URL}/circuit-divisions/hideStandings`, {
                    customer_id: customerId,
                    standing,
                    hide_standing: hideStanding
                })
    
                await getStandings(showHiddenStandings)
                if (response.data.message) {
                    alertDialog({ message: response.data.message })
                } 
            } catch (reason) {

                // revert checkbox to original value in case of error.
                params.node.setDataValue('hide_standing', !hideStanding)

                if (reason?.response?.data.error) {
                    alertDialog({ message: reason.response.data.error })
                } else { 
                    alertDialog({ message: reason })
                }
            } finally{
                loadingOverlay({ show: false, message: 'Updating Standings..' })
            }
        } else { // revert checkbox to false if user rejects.
            params.node.setDataValue('hide_standing', false)
        }
    }

    const standingsBasedOnPoints = methods.watch('circuit_division.standingsbasedon') === 'Points'
    const columnDefs = [
        { 
            field: 'hide_standing', 
            width:80,
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.HIDE' }), 
            cellRenderer: params => renderCheckBox(params, (checked) => handleHideStanding(params, checked)), 
            cellClass: 'text-center',
            hide: !hasAreaWritePermission('circuits')
        },
        { 
            field: 'position', 
            width: 80, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.POS' }),
            cellClass: 'text-center' 
        },
        { 
            field: 'entry_numbers', 
            width: 250, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.ENTRIES' })
        },
        { 
            field: 'points',
            width: 120, 
            headerName: standingsBasedOnPoints ? 'Points' : 'Dollars',
            cellClass: 'text-center',
            cellRenderer: params => standingsBasedOnPoints ? DecimalPointRenderer(params) : currencyFormatter(params.value)
        },
        { 
            field: 'owner',
            hide: showColumn('Owner'),
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.OWNER' })
        },
        { 
            field: 'trainer',
            hide: showColumn('Trainer'),
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.TRAINER' })
        },
        { 
            field: 'horse',
            hide: showColumn('Horse'),
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.HORSE' })
        },
        { 
            field: 'rider',
            hide: showColumn('Rider'),
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.RIDER' })
        },
        { 
            headerName: ' ',
            hide: !hasAreaWritePermission('circuits') || showColumn('ChangeRiderOption'), 
            width: 150,
            cellRenderer: (params) => 
                <a className="change-rider-link date-label blue" onClick={() => handleRiderChange(params)}>
                    Change Rider
                </a>
        },
    ]

    return(
        <>
            { showChangeRiderForm && 
                <ChangeRider
                    selectedRider={selectedRider}
                    setSelectedRider={setSelectedRider}
                    standingRef={standingRef} 
                    riders={riders}
                    show={showChangeRiderForm}
                    handleClose={() => setShowChangeRiderForm(false)}
                    getStandings={getStandings}
                />
            }
            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                <AgGridReact  
                    columnDefs={columnDefs} 
                    rowData={[]} 
                    onCellClicked={params => {
                        if (params.colDef.field !== "hide_standing") {
                            findCircuitPointsForStanding(params.data)
                        }
                    }}
                    containerStyle={containerStyle}
                    defaultColDef={{ ...defaultColDef,  resizable: true }}
                    onGridReady={params => setStandingsGridRef(params.api)}
                    rowHeight={getNarrowRowHeight}
                    headerHeight={getNarrowHeaderHeight}
                    rowSelection='single'
                />
            </div>
        </>
    )
}

export default StandingsGrid