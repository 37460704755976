import { createSlice } from "@reduxjs/toolkit";
  
// Define initial state for currentShow object
const initialState = {
    sgl_id: 0,
    show_id: 0,
    show_name: '',
    show_address: '',
    city: '',
    state: '',
    start_date: '0000-00-00',
    end_date: '0000-00-00',
    display_date: '',
    islocked: 0,
    name_abbr: ''
}

const currentShowSlice = createSlice({
    name: 'currentShow',
    initialState: initialState,
    reducers: {
      setCurrentShow(state, action) {
        let show = action.payload;

        //set current show in local Storage and session storage
        localStorage.setItem('currentShow', JSON.stringify({ ...action.payload }));
        sessionStorage.setItem('currentShow', JSON.stringify({ ...action.payload }));

        return {
          ...state,
          sgl_id: show.sgl_id,
          show_id: show.show_id,
          show_name: show.show_name,
          city: show.city,
          state: show.state,
          show_address: show.show_address,
          start_date: show.start_date,
          end_date: show.end_date,
          display_date: show.display_date,
          islocked: show.islocked,
          name_abbr: show.name_abbr
        }
      },

      clearCurrentShow(state, action) {
        // // remove current show from session storage
        if (sessionStorage.getItem("currentShow")) {
          sessionStorage.removeItem('currentShow')
        }
        // remove current show from local storage
        if (localStorage.getItem("currentShow")) {
            localStorage.removeItem('currentShow')
        }

        // return a new state object based on the initial state
        return {
            ...initialState
        }
      }
    },
  })
  
  export const { setCurrentShow, clearCurrentShow } = currentShowSlice.actions

  export default currentShowSlice.reducer