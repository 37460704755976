import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { useFormContext} from "react-hook-form";
import BooleanCheckmarkRenderer from '../../../modules/output-listing/renderers/BooleanCheckmarkRenderer';

const PeopleTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext();

    const columnDefs = [
        { field: 'lf_name',flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.PEOPLE.NAME' })},
        { field: 'city',flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.PEOPLE.CITY' })},
        { field: 'state',flex: 1,  headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.PEOPLE.STATE' }) },
        { field: 'owner', headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.ENTRIES.OWNER' }), cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' } },
        { field: 'rider', headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.PEOPLE.RIDER' }), cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' } },
    ]

    const rowData = methods.getValues('people')

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

    return (
        <>
            <div className='form-group'>
                <p style={{paddingRight:"10px"}} className='mb-1 text-end'>{rowData.length}</p>
                <div className='row align-items-center mb-2'>
                    <OutputListingGridStatic onRowDoubleClicked={(row_data) => {props.callbackFromParent(row_data.people_id, "People", "PeopleDetail", row_data)}} area ={"Stables - People"} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle}></OutputListingGridStatic>
                </div>
            </div>
        </>
    );
}

export { PeopleTab }