import { useIntl } from 'react-intl'
import { useFormContext } from "react-hook-form";

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers';
import { useEffect } from 'react';

const SearchInput = (props) => {
    const intl = useIntl();

    const methods = useFormContext();
    const { register, setValue, watch} = methods

    const watchTrainerAccount = watch("trainer_account")

    const resetSearch = () => {
        setValue(props.fieldName && !watchTrainerAccount ? props.fieldName : 'search_1', "")
        if(props?.handleSearchTerm){
            props.handleSearchTerm('')
        }
    }

    // Move the focus to search field when PMR or RP is selected as others >>>
    const entryPrizeMoneyRecipientId = methods.watch("entry.prizemoneyrecipient_id")
    const entryResponsiblePartyId = methods.watch("entry.responsibleparty_id")
    useEffect(() => {
        if(!props?.disabled){
            if((props?.id === 'prize_money_recipient_search' && entryPrizeMoneyRecipientId == 0) || (props?.id === 'responsible_party_search' && entryResponsiblePartyId == 0)){
                let searchInput = document.getElementById(props?.id)
                    if(searchInput){
                        searchInput.focus()
                    }
            }
        }
    }, [props?.disabled, entryPrizeMoneyRecipientId, entryResponsiblePartyId])
    // Move the focus to search field when PMR or RP is selected as others <<<

    return (
        <>
            <div className={'d-flex align-items-center my-1 position-relative ' + props.containerClass}>
                <KTSVG
                    path='/media/icons/duotune/general/gen021.svg'
                    className='svg-icon-1 position-absolute ms-2'
                />
                <span className={`position-absolute end-0 me-2 ${props.disabled ? 'disable-reset-search' : ''}`} onClick={resetSearch} disabled={props.disabled}>
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr015.svg'
                        className='svg-icon-1'
                    />
                </span>
                <input
                    {...register(props.fieldName ? props.fieldName : 'search_1', props.registerOptions)}
                    type='text'
                    className={props.className?props.className:'form-control form-control-solid px-11 py-1 rounded-pill'}
                    placeholder={intl.formatMessage({ id: 'TOOLBAR.LABEL.SEARCH' })}
                    onKeyDown={props.onSearch}
                    disabled={props.disabled}
                    defaultValue={props.defaultValue}
                    value={props.value}
                    onBlur={props.onBlur}
                    tabIndex={props.tabIndex}
                    autoFocus={props.enableFocus?true:false}
                    id={props.id}
                    style={{
                        backgroundColor: props.disabled ? '#eff2f5' : '', 
                    }}
                    autoComplete='off'
                />
            </div>
        </>
    );
}

export { SearchInput }