import React, { Fragment, useEffect, useState } from "react";
import {useIntl} from 'react-intl'
import {useFormContext} from 'react-hook-form' 
import {Modal, Form} from 'react-bootstrap'

// Assets
import '../Detail/tab.css'
import '../ShowGallery/ImageUpload.css'
import { KTSVG } from "../../../../_metronic/helpers";
import { useAppSelector } from "../../../redux/hooks";
import { getExtensionOnly } from "./ImageUploadFunctions";

function Preview (props ) {
  const methods = useFormContext()
  const intl = useIntl()
  const [gallery, setGallery] = useState([])
  const [dragId, setDragId] = useState("")
  const [nameToChange, setNameToChange]= useState("nameToChange")
  const [showImageNameEditModal, setShowImageNameEditModal] = useState(false);
  const [idToBeUpdated, setIdToBeUpdated] = useState (0)
  const [imageToSee, setImageToSee] = useState()
  const [imageToSeeName, setImageToSeeName] = useState('')
  const [indexToNavigate, setIndexToNavigate] = useState(-1)
  const [imageNameToEdit, setImageNameToEdit] = useState("")
  const [updateState, setUpdateState]= useState(false)
  const company_url_path = useAppSelector(state=> state.showCompany.urlpath)

  let assetData = [
    {
      "sgl_id":0,
      "title": "", 
      "related_id" : 0,
      "related_table": "", 
      "type": "", 
      "filename": "", 
      "thumbnail": "", 
      "filepath": "", 
      "on_cdn"  : 0,
      "display_order" :"",
      "image_name":"",
      "modification_date":"",
      "modification_time" : 0,
    }
  ]

  useEffect(() =>{
    // if images in array (i.e., updateImagePreviewUrls) sent from props contains images less than 10
    if(props.imagesList.length < 10){ 
      if (!areArraysEqual(props.imagesList, gallery)) {
        setGallery (props.imagesList)
      }
    } 
    if(!areArraysEqual(props.imagesList, methods.getValues('images'))){
      updateAssetsData(props.imagesList)
    }
  },[props.imagesList]) // whenever imagePreviewsUrls are changed, gallery will be updated accordingly 

  //Change image in preview when next or preb button is clicked
  useEffect(() => {
    if(indexToNavigate >= 0){
      setImageToSeeName(gallery[indexToNavigate]?.name)
      if(typeof(gallery[indexToNavigate]?.file) == 'object'){
        setImageToSee(URL.createObjectURL(gallery[indexToNavigate]?.file))
      }else{
        setImageToSee(gallery[indexToNavigate]?.file)
      }                   
    }
  },[indexToNavigate])

  const handleImageNameUpdate = () => {
    if(nameToChange && nameToChange != "" ){ 
      handleNameChange(idToBeUpdated, nameToChange )
      closeImageNameUpdateModal()
    }
  }

  const closeImageNameUpdateModal = () => {
    setNameToChange("nameToChange")
    setShowImageNameEditModal(false)
    setIdToBeUpdated(0); 
  } 
  
  const deleteImage = (id,sgl_id) => {
    const { deleteImage } = props;
    deleteImage(id,sgl_id);
  };

  // Change image position when drag and drop is performed
  const handleImageDrop = (ev) => {
    ev.preventDefault();
    const dragImage = gallery.find((image) => image.id == dragId);
    const dropImage = gallery.find((image) => image.id == ev.currentTarget.id);
    const tempGallery =  moveItem(dragImage.id - 1, dropImage.id - 1);
    setGallery (tempGallery)
    updateAssetsData(tempGallery)
    setUpdateState(!updateState)
  };

  // this function will change position of images present in gallery from one index to another
  const moveItem = (from, to) => {  
    const index = gallery.splice(from, 1)[0];
    gallery.splice(to, 0, index);
    return gallery;
  }

  // Remove selected images
  const handleRemoved = () => {
    if (gallery.length > 0) {
      //update image list
      const filterImages = gallery.filter((image) => image.selected !== true)
      props.setImagesList(filterImages)
      updateAssetsData(filterImages)
      //update deleted image array
      let deletedImages = props.deletedImages ?? []
      gallery.map((image)=>{
        if (image.selected ==true) {
          deletedImages.push(image.sgl_id)
        }
      })
      props.setDeletedImages(deletedImages)
      setUpdateState(!updateState)
      {methods.setValue('deletedImage',  deletedImages , {shouldDirty:true}) }
    }
  }

  // Select or unselect all the images
  const updateSelectionForAllImages = (e) => {
      const tempGallery = gallery;
      tempGallery.map((image) => {
        image.selected = e.target.checked
      })
      setGallery(tempGallery)
  }

  // Open image lightbox when image is clicked
  const handleImageClick = (image, index) => {
    let imageFile = (typeof(image.file) == 'object') ? URL.createObjectURL(image.file) : image.file
    setImageToSeeName(image?.name)
    setImageToSee(imageFile)
    setIndexToNavigate(index)
    document.querySelector('.popup-image').style.display = 'block'
  }

  // Close image lightbox 
  const closeImagePreview = () => {
    setIndexToNavigate (-1); 
    document.querySelector('.popup-image').style.display = 'none'
  }

  // update name for the image
  const handleNameChange = (id,updatedName) => {
    const newGallery = props.imagesList 
    newGallery.map((image, index) => {
      if (index == id-1){ 
        const ext = getExtensionOnly(image.name);
        image.name = updatedName+"."+ext;
        image.title = updatedName; 
        return image
      }
    })
    props.setImagesList(newGallery); 
    setUpdateState(!updateState)
    updateAssetsData(newGallery)
  }

  // Return gallery of images
  const imageGallery=()=> {
    if (gallery.length > 0) {
      gallery.map((image, index) => {
        image.id = index + 1;
      });
    }

    return (
      <Fragment>
        <div className="gallery-container">
          <div>
            <span>
              <input type="checkbox" name= "select-all" onChange={(e) => {
                setUpdateState(!updateState)
                updateSelectionForAllImages(e)  
              }}/>
              <label htmlFor="select-all">{intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.PREVIEW.SELECTALL' })}</label>
            </span>
            <span> | </span>
            <span>
              <a className = "remove-selected-images" onClick={(e) => { handleRemoved() }}>{intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.PREVIEW.REMOVESELECTED' })}</a>
            </span>
            <span className="message" >{intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.PREVIEW.CLICKORDRAG' })}</span>
          </div>

          { gallery?.length > 0 &&
            gallery.map((image, index) => {
            return (
                <div className="col">
                  {/* For the Gallery below >>>*/}
                  <div className="gallery"
                    key={index}
                    id={image.id}
                    draggable
                    onDragOver={(e) => e.preventDefault()}
                    onDragStart={(e) => setDragId(e.currentTarget.id)}
                    onDrop={(e) => handleImageDrop(e)}
                  >

                    {/* Image LightBox >>>*/}
                    <div className="popup-image">
                      <div className="popup-image-background" onClick={() =>{ closeImagePreview() }}></div>
                        <span className="cancel" onClick={() => { closeImagePreview() }}>&times;</span>
                        
                        <div className="image-container">
                          <img className="enlarged-image" src={imageToSee} alt="Enlarged Image" />
                          {/* <p className="image-name">{imageToSeeName}</p> */}
                        </div>
                        
                        <a className="prev" onClick={(e) => {
                          e.preventDefault();  
                          if(indexToNavigate == 0){ 
                            setIndexToNavigate(gallery.length-1) 
                          } else {
                            setIndexToNavigate(indexToNavigate-1) 
                          }
                        }}>&#10094;</a>
                        <a className="next" onClick={(e) => {
                          e.preventDefault();  
                          if(indexToNavigate == gallery.length-1){
                            setIndexToNavigate(0) 
                          } else {
                            setIndexToNavigate(indexToNavigate+1) 
                          }   
                        }}>&#10095;</a>
                    </div> 
                    {/* Image LightBox <<<*/}

                    {/* Image Tools <<<*/}
                    <div className="tools col-lg-4">
                      <input
                        type="checkbox"
                        // defaultChecked={image.selected}
                        value={image.selected}
                        checked={image.selected}
                        id="select-checkbox"
                        onChange={(e)=>{
                          // check its value and store the id for this image
                          image.selected = e.target.checked
                          setUpdateState(!updateState)
                        }}
                      />

                      <i className="fa fa-pencil icon-pencil"
                        onClick={()=> {
                          setShowImageNameEditModal(true)
                          setImageNameToEdit(getImageNameOnly(image.name))
                          setIdToBeUpdated(image.id)
                        }}
                      ></i>
                      
                      <i className="fa fa-trash delete-icon"  onClick={() => deleteImage(image.id, image.sgl_id)}></i>
                    </div>
                    {/* Image Tools <<<*/}

                    {/* Image >>>*/}
                    {
                        <img
                          src= {(typeof(image.file) == 'object') ? URL.createObjectURL(image.file) : image.file }
                          alt={image?.name ?? ''}
                          onClick={() => {
                            handleImageClick(image.file, index)
                          }}
                        />
                    } 
                    {/* Image <<<*/}

                    {/* Image Name >>>*/}
                    <div className="d-flex flex-column align-items-center">
                      <span className="featuredImage"> {index ==0?"Featured Image":''}</span>
                      <span className="image-name">{getImageNameOnly(image.name)}</span>
                    </div>
                    {/* Image Name <<<*/}
                    
                  </div>
                  {/* For the Gallery below <<<*/}
                </div> 
            );
          })}
      </div>
      </Fragment>
    );
  }

  // Get image name other extension
  const getImageNameOnly = (filename) =>{
    const parts = filename.split(".");
    filename = parts[0];
    return filename;
  }

  const updateAssetsData = (imagesList) => {
    imagesList.map((image, index) => {
      assetData[index] = {
        // sglID will be zero for new record and will be >0 for already existing record
        sgl_id: image.sgl_id, 
        //name of image from gallery array,
        title: getImageNameOnly(image.name), 
        //current_sgl id in the masterfee table
        related_id: methods.getValues('master_fee.sgl_id'), 
         // for this case always: master_fees
        related_table: "master_fees",
        //always image for this case
        type: "image", 
        //  sglid inside master fee +_datetimestamp
        filename: methods.getValues('master_fee.sgl_id')+"_"+ image.timeStamp +"."+getExtensionOnly(image.name), 
        // thumb+ _sglid inside master fee +_datetimestamp
        thumbnail: "thumb_"  +methods.getValues('master_fee.sgl_id')+"_"+  image.timeStamp + "."+getExtensionOnly(image.name), 
        filepath: image.on_cdn ? `/assets/master_fees/${company_url_path}/` : `/assets/master_fees/`,
        on_cdn: image.on_cdn,
        display_order: index, // id of image from gallery array 
        modification_date:image.modification_date ,
        modification_time: image.modification_time,
      }
    })
    methods.setValue('files',  imagesList?.length > 0 ? assetData : [] , {shouldDirty:true})
  }

  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i]?.title !== arr2[i]?.title || arr1[i]?.display_order !== arr2[i]?.display_order || arr1[i]?.file !== arr2[i]?.url) {
        return false;
      }
    }
  
    return true;
  }
  
  return (
    <div className="row ">
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal modal-dialog modal-dialog-centered mw-400px search-form'
        show={showImageNameEditModal}
        onHide={closeImageNameUpdateModal}
        onKeyDown={(event) => { //replaced onKeyPress with onKeyDown
          let isButtonFocused = false
          let activeElement = document.activeElement //gets the currently focussed element
          if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
              isButtonFocused = true;
          }
          if (!event.shiftKey && event.key == 'Enter' && !isButtonFocused) {
            handleImageNameUpdate()
          }
        }}
      >
        <div className='modal-header py-0 px-4'>
          <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.PREVIEW.EDITIMAGENAME' })}</h2>
            <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={closeImageNameUpdateModal}>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
        </div>
        <div className='modal-body py-3 px-4'>
          <Form>
            <Form.Group className='mb-3'>
              <Form.Control as='textarea' defaultValue ={imageNameToEdit}  rows={1} autoFocus onChange={ (e) => setNameToChange(e.target.value) } tabIndex={1}/>
              <span className="field-empty-warning">
                {(nameToChange !== "nameToChange" && nameToChange === "") ? intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.PREVIEW.EDITIMAGENAME.ERROR' }) : ''}
              </span>
            </Form.Group>
          </Form>

          <div className='card-footer d-flex justify-content-end py-3 px-0'>
            <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={ closeImageNameUpdateModal } tabIndex={2}>
              {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.DISALLOWREASON.BUTTON.CANCEL'})}
            </button>

            <button type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={() => handleImageNameUpdate()} tabIndex={3}>
              <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
              {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.DISALLOWREASON.BUTTON.OK'})}
            </button>
          </div>
        </div>
      </Modal>
    
      <div className='col-lg-12'>
        <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.GROUPLABEL.GALLERY'})}<b></b></legend>
        <div className="mb-10">{imageGallery()}</div>
      </div>
    </div>
  )
}
export default Preview;