import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { renderCheckBox, defaultColDef, getNarrowHeaderHeight, getNarrowRowHeight } from "../../../../modules/sgl-utils/agGridHelpers";

const FeeGrid = ({ setFeeGridRef }) => {
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const containerStyle = useMemo(() => ({ width: '100%', height: '240px' }), []);
    const intl = useIntl()

    const columnDefs = [
        { 
            field: 'include', 
            width: 120, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.INCLUDE' }),  
            cellRenderer: renderCheckBox, 
            cellClass: 'text-center',  
        },
        { 
            field: 'description', 
            flex: 1, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.FEE' }),
            cellClass: 'text-left',
        }
    ]

    return (
        <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                <AgGridReact
                    defaultColDef={defaultColDef}
                    rowData={[]}
                    columnDefs={columnDefs} 
                    onGridReady={ params => { setFeeGridRef(params.api) }}
                    headerHeight={getNarrowHeaderHeight}
                    rowHeight={getNarrowRowHeight}
                />
            </div>
        </div>
    )
}

export default FeeGrid