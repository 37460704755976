import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { useState } from 'react'
import { useReportsContext } from '../ReportsContext'
import { useAppSelector } from '../../../redux/hooks'
import { useAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider'
import axios from 'axios';
const modalsRoot = document.getElementById('root-modals') || document.body

const ReportGroupModal = ({ show, handleClose }) => {
    const intl = useIntl();
    const loadingOverlay = useLoadingOverlay()
    const { metaData, setReportGroup } = useReportsContext();
    const [reportGroupName, setReportGroupName] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const customerID = useAppSelector(state=> state.showCompany.company_id);

    // Add report group to the db
    const handleSubmit = async () => {
        // Update report details
        loadingOverlay({ show: true })
        axios.post(process.env.REACT_APP_NEST_API_URL + '/utility/addListItems', {
            sgl_list_name: "Report Group",
            sgl_list_slug: "report_group",
            new_list_item: reportGroupName,
            customer_id: customerID,
        }).then((response) => {
            if (response?.data?.success) {
                metaData?.reportGroup?.push({ label: response?.data?.data?.label, value: response?.data?.data?.value })
                setReportGroup(response?.data?.data?.value);
                handleClose();
            } else {
                setErrorMessage(response?.data?.message);
            }
        })
            .catch(error => { console.error(error); throw error; })
            .finally(() => {
                loadingOverlay({ show: false })
            });
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='mmodal-dialog modal-dialog-centered mw-500px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => { //replace onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                  handleSubmit()
                }
              }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.REPORTS.REPORTGROUP.MODAL.TITLE' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>
            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='row mb-2'>
                        <label className='col-lg-5 col-form-label fw-bold fs-5 py-1' htmlFor='measurement_override_date' data-tooltip-id="REPORT.DETAIL.MODAL.ADDREPORTGROUP.LABEL.GROUPNAME">{intl.formatMessage({ id: 'FORM.INPUT.REPORTS.PLACEHOLDER.GROUPNAME' })}</label>

                        <div className='col'>
                            <div className="input-group date">
                                <input
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.REPORTS.PLACEHOLDER.GROUPNAME' })}
                                    onChange={(e) => {setReportGroupName(e.target.value)}}
                                    id='measurement_override_date'
                                    autoFocus
                                    tabIndex={1}
                                />
                            </div>
                        </div>
                    </div>

                    {errorMessage &&
                        <label className='error_message'>{errorMessage}</label>
                    }

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={2}>
                            {intl.formatMessage({ id: 'REPORT.POPUP.REPORTGROUP.CANCEL' })}
                        </button>

                        <button type='button' className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={handleSubmit} tabIndex={3} data-tooltip-id="REPORT.DETAIL.MODAL.ADDREPORTGROUP.BUTTON.ADD">
                            {intl.formatMessage({ id: 'REPORT.POPUP.REPORTGROUP.ADD' })}
                        </button>
                    </div>
                </form>
            </div>            
        </Modal>,
        modalsRoot
    )
}

export { ReportGroupModal }