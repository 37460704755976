/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line no-unused-vars
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import BooleanCheckmarkRenderer from '../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import { BooleanFilterOptions } from '../../modules/output-listing/filters/BooleanFilter';
import DateRenderer from '../../modules/output-listing/renderers/DateRenderer';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { useAlert, useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider';
import axios from 'axios'
import { useCurrencyFormatter } from '../../modules/sgl-utils/Formatters';
import { useAuth } from '../../modules/auth';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const PaymentsList = () => {
  const intl = useIntl();
  const customer_id = useAppSelector(state=> state.showCompany.company_id);
  const loadingOverlay = useLoadingOverlay()
  const currencyFormatter = useCurrencyFormatter()
  const alertDialog = useAlert()
  const { currentCompany } = useAuth()
  const hasWriteAccess = currentCompany && currentCompany.permissions.includes(`cloud_payments:verify-payments`) 

  const verifyPaymentChange = async (e, params) => {
    const currentChecked = e.target.checked;

    loadingOverlay({ show: true })
    try {
        const response = await axios.post( NEST_API_URL + '/payments/markPaymentsAsVerifiedOrUnverified', {
            customer_id,
            payment_ids: [params?.data?.payment_id]
        })

        if (!response.data.success) {
            throw new Error('An error occured while processing this request. Please try again!');
            //refresh list
            //outputContextUpdater({action: 'refresh'})
        }
    } catch (reason) {
        e.target.checked = !currentChecked;
        alertDialog({ title: 'Request Failed', message: reason, icon: "stop" })
        loadingOverlay({ show: false })
    } 
    loadingOverlay({ show: false })
  }

  const navigate = useNavigate();
  const columnDefs = [
    // { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.COMMONS.SGLID'}), filter: 'agNumberColumnFilter', filterParams: { suppressAndOrCondition: true,}, },
    { field: 'payment_id', headerName: intl.formatMessage({ id: 'LIST.PAYMENTS.PAYMENTID' }), filter: 'agNumberColumnFilter',hide:true },
    { field: 'payment_date', headerName: intl.formatMessage({ id: 'LIST.PAYMENTS.PAYMENTDATE' }), filter: 'agDateColumnFilter', cellRenderer: DateRenderer, cellStyle: { textAlign: 'center' }, },
    { field: 'type', headerName: intl.formatMessage({ id: 'LIST.PAYMENTS.TYPE' }), filter: 'agTextColumnFilter', },
    { field: 'status', headerName: intl.formatMessage({ id: 'LIST.PAYMENTS.STATUS' }), filter: 'agTextColumnFilter', },
    { field: 'cc_transid', width: 300, headerName: intl.formatMessage({ id: 'LIST.PAYMENTS.TRANSID' }), filter: 'agTextColumnFilter', },
    { field: 'refcode', headerName: intl.formatMessage({ id: 'LIST.PAYMENTS.REFCODE' }), filter: 'agTextColumnFilter', },
    { field: 'invoice_number', headerName: intl.formatMessage({ id: 'LIST.PAYMENTS.INVOICENO' }), filter: 'agTextColumnFilter', },
    { field: 'entry_number', headerName: intl.formatMessage({ id: 'LIST.PAYMENTS.ENTRY' }), sortable: false, cellStyle: ({textAlign: 'center'}) },
    { field: 'description', width: 400, headerName: intl.formatMessage({ id: 'LIST.PAYMENTS.DESCRIPTION' }), filter: 'agTextColumnFilter', },
    { 
        field: 'amount', 
        headerName: intl.formatMessage({ id: 'LIST.PAYMENTS.AMOUNT' }), 
        filter: 'agNumberColumnFilter',
        cellRenderer: params => currencyFormatter(params.value),
        cellStyle: { textAlign: 'right' }
    },
    { field: 'verified', headerName: intl.formatMessage({ id: 'LIST.PAYMENTS.VERIFIED' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellStyle: { textAlign: 'center' },
        cellRenderer: (params) => {
          if (params.node.rowPinned){
            return
          }else{
            return (
              <input
                  type="checkbox"
                  defaultChecked={params?.data?.verified?true:false}
                  onChange={(e) => {verifyPaymentChange(e, params)}}
                  value={params.value}
                  disabled={!hasWriteAccess}
              />
            )
          }
    },
    },
    { field: 'web_add', headerName: intl.formatMessage({ id: 'LIST.PAYMENTS.WEBPAYMENTS' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, },
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.PAYMENTS' })}</PageTitle>
      <OutputListingComponent key={customer_id} area={'Payments'} columnDefs={columnDefs} apiEndPoint={'payments/list'}
        onRowDoubleClicked={(row_data) => {navigate('/payments/detail/'+row_data.payment_id+`?customer_id=${customer_id}`)}}
        onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL + '/payments/detail/'+row_data.payment_id + `?customer_id=${customer_id}`,'_blank')}
      ></OutputListingComponent>
    </>
  )
}

export { PaymentsList }
