import moment from "moment";

export const DateTimeRenderer = (params) => {
    if(Boolean(params.value) && params.value != '0000-00-00'){
        const momentObj = moment(params.value, 'YYYY/MM/DD-HH:mm:ss');
        if(!momentObj.isValid()) return '';

        const dateTimeString = momentObj.format('M/D/YY hh:mm A');
        return dateTimeString;
    }

    return '';
}

// This renderer is for the cases like "2024/06/02-03:53:08"
export const DateTimeRenderer2 = (params) => {
    if(Boolean(params.value) && params.value != '0000-00-00'){
        const datetime = params.value.replace('-', ' ');

        const momentObj = moment(datetime, 'YYYY/MM/DD HH:mm:ss');
        if(!momentObj.isValid()) return '';

        const dateTimeString = momentObj.format('M/D/YY hh:mm A');
        return dateTimeString;
    }

    return '';
}

//Use this renderer when want to display date and time from two separate fields into single column
export const SeparatedDateTimeRenderer = (props) => {
    if (props?.date !== undefined && props?.time !== undefined) {
        const dateObj = moment(props.date);
        const timeObj = moment(props.time, 'HH:mm:ss'); // Assuming time format is 'HH:mm:ss'
        
        if (!isNaN(dateObj.year()) && timeObj.isValid()) {
            const dateTimeString = moment(`${props.date} ${props.time}`).format('M/D/YY hh:mm A');
            return dateTimeString;
        }
    }

    return '';
}