import { createContext, useContext, useState, useEffect, useRef } from "react";
import { useAppSelector } from '../../redux/hooks';
import axios from 'axios';
import { useAuth } from "../auth";
import { useProgress } from "../sgl-utils/DialogsProvider";
import { logSiteActivity } from "../sgl-utils/SglFunctions";
import { useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider'
import { useLocation,matchPath } from "react-router-dom";
import { pageInfo } from '../../../_metronic/layout/core';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
  
  // create contexts
  const OutputContext = createContext();
  const OutputContextUpdater = createContext();
  // context consumer hook
  const useOutputContext = () => {
    // get the context
    const context = useContext(OutputContext);
  
    // if `undefined`, throw an error
    if (context === undefined) {
      throw new Error("useOutputContext was used outside of its Provider");
    }
  
    return context;
  };
  
  // context consumer hook
  const useOutputContextUpdater = () => {
    // get the context
    const context = useContext(OutputContextUpdater);
  
    // if `undefined`, throw an error
    if (context === undefined) {
      throw new Error("useOutputContextUpdater was used outside of its Provider");
    }
  
    return context;
  };
  
  const OutputContextProvider = ({ children }) => {
 
    //initialize values for output context
    const [outputGrid, setOutputGrid] = useState({
        area: '',
        apiEndPoint: '',
        gridApi: null,
        columnApi: null,
        totalRecords: 0,
        filteredRecords: 0,
        displayedRecords: 0,
        subsetIDs: [],
        omitSubsetIDs: [],
    });
    const [outputSearchTerm, setOutputSearchTerm] = useState('');
    const [outputIncludeAllShows, setOutputIncludeAllShows] = useState(null);
    const [outputShowInactive, setOutputShowInactive] = useState(false);
    const [outputSearchType, setOutputSearchType] = useState('Replace');
    const [savedSelectionRecordIDs, setSavedSelectionRecordIDs] = useState([]);
    const [savedRefinedSelectionRecordIDs, setSavedRefinedSelectionRecordIDs] = useState([]);
    const [disableSearchMode, setDisableSearchMode] = useState(true);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const customerID = useAppSelector(state=> state.showCompany.company_id);
    const [outputAdvanceFilter, setOutputAdvanceFilter] = useState({});
    const [quickSearch, setQuickSearch] = useState({ searchCriteria: '', showProgress: false })
    const [outputVerificationData, setOutputVerificationData] = useState({})
    const [updateState, setUpdateState] = useState(true)
    const [verifiedData, setVerifiedData] = useState([])
    const { currentUser } = useAuth()
    const {progressBar} = useProgress()
    const [customFilters, setCustomFilters] = useState({});
    const [showReportSelector, setShowReportSelector] = useState(false);
    const [disablePrintButton, setDisablePrintButton] = useState(true);
    const [searchTriggered, setSearchTriggered] = useState(false);
    const searchTimeoutRef = useRef(null);
    const [isListOpen, setIsListOpen] = useState(false)
    const [previousPageType, setPreviousPageType] = useState(null);
    const shouldFocusSearch = useRef(false);
    const shouldHighlightSearch = useRef(false);
    const ControlMovedManuallyToAGGrid = useRef(false);
    const isDetailToList = useRef(false);
    const isFirstCustomerIDRender = useRef(true);
    const isFirstShowIDRender = useRef(true);
    const searchInputRef = useRef(null); 

    // State manages whether we need to show verification columns or not
    const [showVerificationColumns, setShowVerificationColumns] = useState(false)
    const showLoadingOverlay = useRef(true)
    const retainFiltersArea = ['QueuedJobs', 'CloudApiLogs', 'SiteMonitoring', 'ErrorsListing', 'Rings', 'Barns']
    const loadingOverlay = useLoadingOverlay();
    const { pathname } = useLocation()
    const pathSplits = pathname.split('/')

    useEffect(() => {
      if(customerID!==0){
        if (quickSearch.searchCriteria !== '') { // Quick Search was applied.
            setOutputState({ action: 'clearQuickSearchFilters' })
            setOutputState({ action: 'getServerData' })
            // Log quick search in site_monitoring_activity_log table
            logSiteActivity(currentShowID, customerID, {
              source: "Quick Search",
              tab_name: outputGrid?.area,
              activity: quickSearch.searchCriteria,
              description: 'Search'
          }) 
          shouldHighlightSearch.current = false;
          shouldFocusSearch.current = false;
          ControlMovedManuallyToAGGrid.current = false;

        }
      }
    }, [quickSearch])

    useEffect(() => {
      if(customerID!==0){
        if (Object.keys(outputAdvanceFilter).length > 0) { // Advance Search was applied.
          if(outputAdvanceFilter?.search === 'replace'){
            setOutputState({ action: 'clearAdvanceSearchFilters' })
          }
          setOutputState({ action: 'getServerData' })
          // Log advance search in site_monitoring_activity_log table
          logSiteActivity(currentShowID, customerID, {
            source: "Advanced Search",
            tab_name: outputGrid?.area,
            activity: 'Search',
            description: 'Search'
          })

          shouldHighlightSearch.current = false;
          shouldFocusSearch.current = false;
          ControlMovedManuallyToAGGrid.current = false;
        } 
      }
    }, [outputAdvanceFilter])

    const triggerSearch = (searchTerm) => {
      shouldHighlightSearch.current = false;
      shouldFocusSearch.current = true;
      ControlMovedManuallyToAGGrid.current = false;
      setOutputState({ action: 'clearSimpleSearchFilters' });
      setOutputState({ action: 'getServerData', searchTerm });
    };
    
    useEffect(() => {
      if (customerID !== 0 && !searchTriggered && outputSearchTerm && isListOpen) {
        searchTimeoutRef.current = setTimeout(() => {
          triggerSearch(outputSearchTerm);
        }, 800);
      }

      if (customerID !== 0 && outputSearchTerm && isListOpen && outputSearchType == 'Replace') {
          clearAddRefineSearchState()
      }

      return () => clearTimeout(searchTimeoutRef.current);
    }, [outputSearchTerm, searchTriggered]);

    useEffect(() => {
      if(customerID!==0){
        if(outputIncludeAllShows === true){
            // Reset verification data
            setOutputVerificationData({ cleared: true })
            setVerifiedData([])
        }
        else if(outputIncludeAllShows === false){
          setOutputState({ action: 'getServerData' })
        }
        shouldHighlightSearch.current = false;
        shouldFocusSearch.current = false;
        ControlMovedManuallyToAGGrid.current = false;
      }
    }, [outputIncludeAllShows]);

    useEffect(() => {
      if(customerID!==0){
        if(Object.keys(outputVerificationData).length > 0){
          if('filter' in outputVerificationData || 'cleared' in outputVerificationData){
            setOutputState({action: 'refresh'});
          }
          else if('verifyData' in outputVerificationData && outputVerificationData['verifyData']){ // perform verification when submit is called from verification tool
            getVerifiedData()
          }
          shouldHighlightSearch.current = false;
          shouldFocusSearch.current = false;
          ControlMovedManuallyToAGGrid.current = false;
        }
      }
    }, [outputVerificationData])

    useEffect(() => {
      if(customerID!==0){
        if (Object.keys(customFilters).length > 0) { // Custom Filters was applied.
          setOutputState({ action: 'clearCustomFilters' })
          setOutputState({ action: 'getServerData' })
          shouldHighlightSearch.current = false;
          shouldFocusSearch.current = false;
          ControlMovedManuallyToAGGrid.current = false;
        } 
      }
    }, [customFilters])

    useEffect(() => {
      if(customerID!==0){
        // fetch data again
        setOutputState({ action: 'getServerData' })
        shouldHighlightSearch.current = false;
        shouldFocusSearch.current = false;
        ControlMovedManuallyToAGGrid.current = false;
      }
    }, [outputShowInactive])

    useEffect(() => {
        if(customerID!==0){
          if (isFirstCustomerIDRender.current) {
            isFirstCustomerIDRender.current = false;
            return;
          }
          if(!retainFiltersArea.includes(outputGrid?.area)){//Don't remove filters for site monitoring list when show is changed
            setShowVerificationColumns(false)

            const stateKey = getSearchStateKey(outputGrid?.area, currentUser.id, customerID);
            sessionStorage.removeItem(stateKey);
            
            setOutputState({action: 'clearFiltersAndRefresh'})
            shouldHighlightSearch.current = false;
            shouldFocusSearch.current = false;
            ControlMovedManuallyToAGGrid.current = false;
          }
        }
    }, [customerID])

    useEffect(() => {
        if (isFirstShowIDRender.current) {
          isFirstShowIDRender.current = false;
          return;
        }
        if(customerID!==0 && !retainFiltersArea.includes(outputGrid?.area)){//Don't remove filters for site monitoring list when show is changed
            setShowVerificationColumns(false) // Hide (Entries) verification columns on show change
            
            const stateKey = getSearchStateKey(outputGrid?.area, currentUser.id, customerID);
            sessionStorage.removeItem(stateKey);
            setOutputState({action: 'clearFiltersAndRefresh'});
            shouldHighlightSearch.current = false;
            shouldFocusSearch.current = false;
            ControlMovedManuallyToAGGrid.current = false;
            //setOutputState({ action: 'getServerData' })
        }
        // setSavedRefinedSelectionRecordIDs([])
        clearAddRefineSearchState([])
        outputGrid.subsetIDs = [];
    }, [currentShowID] )


    //adding improved version of code through layout config variable to check if list page is opened last
    useEffect(() => {
      const isComingFromDetail = previousPageType === 'detail' && pageInfo.type === 'list';
      isDetailToList.current = isComingFromDetail;
      setPreviousPageType(pageInfo.type);

      switch (pageInfo.type) {
        case 'list': //listing pages are open
          if(customerID!==0){//Don't remove filters for site monitoring list when show is changed
            //const savedState = localStorage.getItem(outputGrid.area + "_" + currentUser.id + "_" + customerID +'_searchState');
            const stateKey = getSearchStateKey(outputGrid.area, currentUser.id, customerID);
            const savedState = sessionStorage.getItem(stateKey);

            if (isComingFromDetail) {
              if (savedState) {
                const stateJSON = JSON.parse(savedState);
                
                if(stateJSON.outputSearchTerm != ""){
                  shouldHighlightSearch.current = true;
                  shouldFocusSearch.current = false;
                  ControlMovedManuallyToAGGrid.current = false;
                }else{
                  shouldHighlightSearch.current = false;
                  shouldFocusSearch.current = false;
                  ControlMovedManuallyToAGGrid.current = false;
                }

                setOutputSearchTerm(stateJSON.outputSearchTerm);
                setOutputSearchType(stateJSON.outputSearchType);
                setSavedRefinedSelectionRecordIDs(stateJSON.savedRefinedSelectionRecordIDs);

                setIsListOpen(false)
              }else{
                setIsListOpen(true)
              }
            } else{
              sessionStorage.removeItem(stateKey);
              setOutputSearchTerm('');
              setOutputSearchType('Replace');
              setSavedRefinedSelectionRecordIDs([]);

              shouldHighlightSearch.current = false;
              shouldFocusSearch.current = false;
              ControlMovedManuallyToAGGrid.current = false;

              setIsListOpen(true);
            }
          }

          // When the area is changed, we need to disable the print button because the IDs are not loaded
          // We are enabling this in getServerData function, after receiving API response
          setDisablePrintButton(true);
          
          break;

        default:
          setIsListOpen(false)
          break;
      }
  }, [pageInfo.pathname])

    const clearAddRefineSearchState = () => {
      //localStorage.removeItem(outputGrid.area + "_" + currentUser.id + "_" + customerID +'_searchState');
      const stateKey = getSearchStateKey(outputGrid.area, currentUser.id, customerID);
      sessionStorage.removeItem(stateKey);
      setOutputSearchType('Replace')
      setSavedRefinedSelectionRecordIDs([])
    }

    const setOutputState = async (params) => {
      switch(params.action){
        case 'init':
          outputGrid.gridApi = null;
          outputGrid.totalRecords = 0;
          outputGrid.filteredRecords = 0;
          outputGrid.displayedRecords = 0;
          outputGrid.subsetIDs = [];
          outputGrid.omitSubsetIDs = [];
          setSavedSelectionRecordIDs([]);
          setSavedRefinedSelectionRecordIDs([]);
          setOutputSearchTerm('');
          setOutputAdvanceFilter({});
          setOutputVerificationData({})
          setVerifiedData([])
          setShowVerificationColumns(false)
          setQuickSearch({ searchCriteria: '', showProgress: false })
          setCustomFilters({})
          break;
      }

      if(!outputGrid.gridApi){
        return false;
      }

      let allSglIDs = [];
      switch(params.action){
        case 'clearFilters':
        case 'clearAdvanceSearchFilters':
        case 'clearSimpleSearchFilters':
        case 'clearQuickSearchFilters':
        case 'clearFiltersExceptSubset':
        case 'clearCustomFilters':
            if (params.action !== 'clearAdvanceSearchFilters') {
                setOutputAdvanceFilter({})
            }
            
            if ((params.action !== 'clearSimpleSearchFilters')) {
              if(!retainFiltersArea.includes(outputGrid?.area) || (retainFiltersArea.includes(outputGrid?.area) && params.action === 'clearFilters'))
              {
                setOutputSearchTerm('')
                clearAddRefineSearchState()
              }
            }

            if (params.action !== 'clearQuickSearchFilters') {
                setQuickSearch({ searchCriteria: '', showProgress: false })
            }

            setOutputState({action: 'unselectAll'}); // unselect records
            if (params.action !== 'clearFiltersExceptSubset') {
                setOutputState({ action: 'clearSubsetFilters' }) // Clear subset filters
            }
            
            outputGrid.gridApi.setFilterModel(null)
            if('filter' in outputVerificationData ){
              setOutputVerificationData({ filter: 'All' })
            }

            if(params.action !== 'clearSimpleSearchFilters' && !retainFiltersArea.includes(outputGrid?.area)){
              setCustomFilters({})//clear custom filters
            }
            
            break;
          
        case 'clearSubsetFilters': //get data from API
          outputGrid.subsetIDs = [];
          outputGrid.omitSubsetIDs = [];
          break;

        case 'clearFiltersAndRefresh':
          clearAddRefineSearchState();
          setOutputState({action: 'clearFilters'}); //clear applied search filters by users
          setOutputState({action: 'clearSubsetFilters'}); //clear subset
          setOutputState({action: 'refresh'}); //reload list with new settings
          break;

        case 'clearFiltersExceptSubsetAndGetData':
          clearAddRefineSearchState();
          setOutputState({action: 'clearFiltersExceptSubset'}); //clear subset
          setOutputState({action: 'getServerData'}); //reload list with new settings
          break;

        case 'clearFiltersAndGetData':
          clearAddRefineSearchState()
          setOutputState({action: 'clearFilters'}); //clear applied search filters by users
          setOutputState({action: 'getServerData'}); //reload list without deleting subset
          break;
        
        case 'selectAll':
          if(outputGrid.filteredRecords > outputGrid.displayedRecords){
            /*
            if(!outputIncludeAllShows){
              let allSglIDs = await getOutputSelectionSGLIDs();
              outputGrid.subsetIDs = allSglIDs.slice(); //copy array by value
              setOutputState({action: 'refresh'}); //reload list with new settings
            }
            */
          }

          outputGrid.gridApi.forEachNode(function (node) {
            node.setSelected(true);
          })
          break;
        
        case 'unselectAll':
          outputGrid.gridApi.forEachNode(function (node) { //Deselect all rows manually
            node.setSelected(false);
          })
          break;

        case 'omitSubset': //Remove selected rows from list
          var selectedRows = outputGrid.gridApi.getSelectedRows(); //get selected rows
          if(selectedRows.length > 0){
            // for(let selectedRow of selectedRows){
            //   outputGrid.omitSubsetIDs.push(selectedRow.sgl_id) //Set record ids to hide
            // }
            // // clearAddRefineSearchState()
            // setOutputState({action: 'getServerData'}); //clear other filters and refresh grid list


            // Store the IDs to omit first
            const idsToOmit = selectedRows.map(row => row.sgl_id);
            const stateKey = getSearchStateKey(outputGrid.area, currentUser.id, customerID);
            const savedState = sessionStorage.getItem(stateKey);
            
            if (savedState) {
              const stateJSON = JSON.parse(savedState);
              
              // If we have refined selection IDs, we need to remove omitted IDs from them
              if (stateJSON.savedRefinedSelectionRecordIDs?.length > 0) {
                const newRefinedIds = stateJSON.savedRefinedSelectionRecordIDs.filter(
                  id => !idsToOmit.includes(id)
                );
                setSavedRefinedSelectionRecordIDs(newRefinedIds);
                stateJSON.savedRefinedSelectionRecordIDs = newRefinedIds;
              }
              
              // Restore other state
              setOutputSearchTerm(stateJSON.outputSearchTerm);
              setOutputSearchType(stateJSON.outputSearchType);
              
              sessionStorage.setItem(stateKey, JSON.stringify(stateJSON));
              setIsListOpen(false);
            }
        
            // Add to omitSubsetIDs after filtering refinedSelectionIDs
            for(let id of idsToOmit) {
              outputGrid.omitSubsetIDs.push(id);
            }

            setOutputState({action: 'getServerData'});

          }
          break;

        case 'showSubset': //Filter list to only selected rows
          var selectedRows = outputGrid.gridApi.getSelectedRows(); //get selected rows
          if(selectedRows.length > 0){
            outputGrid.subsetIDs = [];
            for(let selectedRow of selectedRows){
              outputGrid.subsetIDs.push(selectedRow.sgl_id)
            }
            // clearAddRefineSearchState();
            setOutputState({action: 'clearFiltersExceptSubset'}); //clear other filters and refresh grid list
            setOutputState({action: 'getServerData'})
          }
          break;

        case 'saveSelection': //save current selection of record ids
          allSglIDs = await getOutputSelectionSGLIDs();
          setSavedSelectionRecordIDs(allSglIDs); //save records for current selection
          break;

        case 'saveRefinedSelection': //save current selection of record ids
          allSglIDs = await getOutputSelectionSGLIDs();
          setSavedRefinedSelectionRecordIDs(allSglIDs); //save records for current selection
          break;

        case 'useSavedSelection':
          if(savedSelectionRecordIDs.length > 0){
            setOutputState({action: 'clearSubsetFilters'}); //uselect records
            outputGrid.subsetIDs = savedSelectionRecordIDs.slice(); //copy array by value
            setOutputState({action: 'clearFiltersExceptSubsetAndGetData'}); //clear other filters except subset and refresh grid list
          }
          break;

        case 'getServerData': //get data from API
          outputGrid.gridApi.refreshInfiniteCache();
          break;

        case 'refresh': //get data from API
          outputGrid.gridApi.refreshInfiniteCache();
          
          // In add entries from other shows, we are making subset to newly added classes
          if (params.subsetIDs && params.subsetIDs.length > 0) {
            outputGrid.subsetIDs = params.subsetIDs
          }
          break;
        case 'getDataBySglIds': // get specific records of area (by Sgl Id)
          setOutputState({ action: 'clearFilters' }) // Clear ALL Filters
          outputGrid.subsetIDs = params.subsetIDs
          setOutputState({ action: 'getServerData' })
          break

        case 'seamlessRefresh': 
          showLoadingOverlay.current = false
          outputGrid.gridApi.refreshInfiniteCache();
          break;

        default: //refresh Grid by default
          setOutputState({action: 'clearSubsetFilters'}); //clear subset
          setOutputState({action: 'getServerData'}); //call grid api
          break;
      }
    };

    const getOutputSelectionSGLIDs = async () => {
      let sglIDs = [];
      if(outputGrid.filteredRecords <= outputGrid.displayedRecords) { //All records are displayed in list
        outputGrid.gridApi.forEachNode(function (node) {
          sglIDs.push(node.data.sgl_id);
        })
      } 
      else { //Get all the record ids for output listing
        let response = await getOutputSelectionRecordIDs();
        for(let record of response.data){
          sglIDs.push(record.sgl_id);
        }
      }
      return sglIDs;
    };

    // Returns primary id of currently opened area's selected rows
    const getOutputSelectionSelectedRowsAreaIDs = () => {
        const areaIDs = []
        if (outputGrid.gridApi.getSelectedRows().length > 0) {
            for (let row of outputGrid.gridApi.getSelectedRows()) {
                areaIDs.push(row.area_id)
            }
        } 
        return areaIDs
    }

    // Returns selected rows currently opened area
    const getOutputSelectionSelectedRows = () => {
        return outputGrid.gridApi.getSelectedRows()
    }

    // Returns primary id of currently opened area
    const getOutputSelectionAreaIDs = async (userSelection = true) => {
        let areaIDs = [];
        // Records are selected by user
        if (outputGrid.gridApi.getSelectedRows().length > 0 && userSelection) {
            for (let row of outputGrid.gridApi.getSelectedRows()) {
                areaIDs.push(row.area_id)
            }
        } else if(outputGrid.filteredRecords <= outputGrid.displayedRecords) { // All records are displayed in list
            outputGrid.gridApi.forEachNode(function (node) {
                areaIDs.push(node.data.area_id);
            })
        } else { // Get all the record ids for output listing
            let response = await getOutputSelectionRecordIDs();
            for(let record of response.data){
                areaIDs.push(record.area_id);
            }
        }
        return areaIDs;
    };

    const getOutputSelectionAreaIDsForSortedGrid = async () => {
      let areaIDs = [];
      // Records are selected by user
      if (outputGrid.gridApi.getSelectedRows().length > 0) {
        let selectedRows = outputGrid.gridApi.getSelectedRows()
        outputGrid.gridApi.forEachNode(function (node) {
          const row = selectedRows.find(selectedRow => selectedRow.entry_id == node.data.entry_id);
          if(row){
            areaIDs.push(row.area_id)
          }
        })
      } else if(outputGrid.filteredRecords <= outputGrid.displayedRecords) { // All records are displayed in list
        outputGrid.gridApi.forEachNode(function (node) {
            areaIDs.push(node.data.area_id);
        })
      } else { // Get all the record ids for output listing
          let response = await getOutputSelectionRecordIDs();
          for(let record of response.data){
              areaIDs.push(record.area_id);
          }
      }
      return areaIDs;
    };

    const getOutputSelectionRecordIDs = async () => { 
      return await getServerData({
        returnAll: true,
        returnIDs: true,
      });
    };

    const getVerifiedData = async () => {
      if(Object.keys(outputVerificationData).length > 0 && !outputVerificationData?.cleared){
        outputGrid.gridApi.showLoadingOverlay()
        let response = await getServerData({
          returnAll: true,
          verify: true,
        });
        outputGrid.gridApi.forEachNode(row => {
          let verifiedRow = response.data.find(data => data?.sgl_id == row.data?.sgl_id)
          if(verifiedRow){
            row.data = {...row.data, verification: verifiedRow?.verification, verification_status: verifiedRow?.verification_status}
          }
          row.setData(row.data);
        })

        setVerifiedData(response.data)
        outputGrid.gridApi.hideOverlay()
      }
    }

    const addVerificationData = (responseData = [], verifiedData = [], filter = '') => { // Add verification column(s) data in grid rows
      let returnData = responseData.map(row => {
        let verifiedRow = verifiedData.find(data => data?.sgl_id == row?.sgl_id)
        if(verifiedRow){
          row = {...row, verification: verifiedRow?.verification, verification_status: verifiedRow?.verification_status}
        }
        return row
      })

      return returnData
    }

    const getFilteredVerifiedIDs = () => {
      
      let returnData = verifiedData.filter(data => {
        let check = false
        if(outputVerificationData.filter == 'valid'){
          check = data.verification == 'valid'
        }else{
          check = outputVerificationData.filter == data.verification
        }
        return check
      }).map(data => data.sgl_id)

      
      if(outputVerificationData.filter == 'All'){
        let returnData = []
        verifiedData.map(data => {
          returnData.push(data.sgl_id)
        })
        return returnData
      }
      return returnData
    }

    const saveSearchState = () => {
      let searchState = {
        outputSearchTerm,
        outputSearchType,
        savedRefinedSelectionRecordIDs
      };
      //localStorage.setItem(outputGrid.area + "_" + currentUser.id + "_" + customerID +'_searchState', JSON.stringify(searchState));
      const stateKey = getSearchStateKey(outputGrid.area, currentUser.id, customerID);
      sessionStorage.setItem(stateKey, JSON.stringify(searchState));
    };


    const getSyncDataForGridUpdate = async (triggered_id) => {
      if(triggered_id){
        let response = await getServerData({
          optionalSearchId: triggered_id
        });
        return response.data;
      }
    }

    const getServerData = async (requestObj) => { 
      setDisableSearchMode(true)
      requestObj.customer_id = customerID;
      requestObj.show_id = currentShowID;
      requestObj.area = outputGrid.area; //area
      requestObj.subsetIDs = outputGrid.subsetIDs; //search on subset records
      requestObj.omitSubsetIDs = outputGrid.omitSubsetIDs; //exclude subset records
      requestObj.searchTerm = outputSearchTerm; //Search text
      requestObj.includeAllShows = outputIncludeAllShows; //Include all shows checkbox
      requestObj.filterModel = outputGrid.gridApi.getFilterModel(); //Column filters
      requestObj.advanceSearch = outputAdvanceFilter; //Advance filters
      requestObj.quickSearch = quickSearch // Add Quick Search Params
      requestObj.verificationData = outputVerificationData // Verification process data
      requestObj.customFilters = customFilters // custom filters
      requestObj.searchType = outputSearchType // Add search filters type
      requestObj.showInactiveMasterFees = outputShowInactive; //Include all fees active and inactive checkbox
      
      if(['Add', 'Refine'].includes(outputSearchType) && requestObj.searchTerm != ''){
        requestObj.refinedSubsetIDs = savedRefinedSelectionRecordIDs.slice(); //copy array by value
      }
   
      // Handle progress bar
      let progressBarDisplayed = false
      
      let eventId = ''
      if (quickSearch.showProgress) {
        eventId = getEventID('quick-search')
        requestObj.quickSearch = {...requestObj.quickSearch, eventId }

        progressBar({ show: true, eventID: eventId, showProgress: 'inline', title: quickSearch.searchCriteria, timeElapsed: true })
        progressBarDisplayed = true
      }


      // Verify entries only when "requestObj" has verify and all shows are not included
      if('verify' in requestObj && requestObj.verify && !outputIncludeAllShows){
        eventId = getEventID("entries-verify_entries")

        requestObj.verification = outputVerificationData // Verification data
        requestObj.event_id = eventId
        
        // Show Progress Bar
        progressBar({ show: true, eventID: eventId, showProgress: 'inline', title: 'Verifying Entries...', timeElapsed: true })
        progressBarDisplayed = true

      }

      if('filter' in outputVerificationData){
        requestObj.verifyFilterIDs = getFilteredVerifiedIDs()
        requestObj.verification = outputVerificationData
      }
      
      let url = `${NEST_API_URL}/${outputGrid.apiEndPoint}`
      const axiosResponse = await axios.post(url, requestObj);

      if(progressBarDisplayed){ // Hide Progress Bar
        progressBar({ show: false, eventID: eventId })
      }

      if(updateState){
        setVerifiedData(axiosResponse.data.data)
        setUpdateState(false)
      }

      // click search term is not empty, we enable the Add option
      if(outputSearchTerm != '' || requestObj.subsetIDs?.length > 0 || requestObj.omitSubsetIDs?.length > 0 || savedRefinedSelectionRecordIDs?.length > 0){
        setDisableSearchMode(false)
      }
      
      // If add search is applied, clear the search term
      // Previously, this was not allowing to add another search to the result because when we cleared the search term, the filters were also cleared
      // Now it will clear the search term after each add is applied
      if (['Refine', 'Add'].includes(outputSearchType)) {
        setOutputSearchTerm('')
      }

      // Enable print button after getting the data
      setDisablePrintButton(false);
      return axiosResponse.data;
    }
  
    const getEventID = (action) => {
      if(outputGrid.area.toLocaleLowerCase() !== ''){
        return `${currentUser?.id}-${customerID}-${outputGrid.area.toLocaleLowerCase()}-${action}-${Date.now()}`
      }else{
        return `${currentUser?.id}-${customerID}-${pathSplits[1].toLocaleLowerCase()}-${action}-${Date.now()}`
      }
    }

    // Get current selection ids for add_to, refine and remove searches
    const getCurrentRecordSetIds = async () => {
        loadingOverlay({ show: true, message: "Applying Search..."});
        try {
            const response = await getOutputSelectionSGLIDs();
            loadingOverlay({ show: false})
            return response;
        } catch(e) {
            loadingOverlay({ show: false})
            return [];
        }
    }

    // Get or create a unique tab ID stored in sessionStorage, this is to separately handle search state for each tab
    const getTabId = () => {
      let tabId = sessionStorage.getItem('tabId');
      if (!tabId) {
        tabId = `tab_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
        sessionStorage.setItem('tabId', tabId);
      }
      return tabId;
    };

    const getSearchStateKey = (area, userId, customerId) => {
      const tabId = getTabId();
      return `${area}_${userId}_${customerId}_${tabId}_searchState`;
    };
    

    return (
      // the Providers gives access to the context to its children
      <OutputContext.Provider value={{outputGrid, outputSearchTerm, setOutputSearchTerm, outputIncludeAllShows, setOutputIncludeAllShows, outputAdvanceFilter, setOutputAdvanceFilter, currentShowID, customerID, getServerData, outputVerificationData, setOutputVerificationData, verifiedData, setVerifiedData, addVerificationData, getOutputSelectionAreaIDs, getOutputSelectionAreaIDsForSortedGrid, getEventID, showVerificationColumns, setShowVerificationColumns, setQuickSearch, getOutputSelectionSelectedRows, getOutputSelectionSelectedRowsAreaIDs, quickSearch, showLoadingOverlay, customFilters, setCustomFilters, getCurrentRecordSetIds, outputSearchType, setOutputSearchType, savedRefinedSelectionRecordIDs, disableSearchMode, outputShowInactive, setOutputShowInactive, showReportSelector, setShowReportSelector, triggerSearch, setSearchTriggered, saveSearchState, disablePrintButton, setDisablePrintButton, setIsListOpen, getSyncDataForGridUpdate, setSavedRefinedSelectionRecordIDs, getSearchStateKey, shouldFocusSearch, shouldHighlightSearch, ControlMovedManuallyToAGGrid, isDetailToList, searchInputRef }}>
        <OutputContextUpdater.Provider value={setOutputState}>
          {children}
        </OutputContextUpdater.Provider>
      </OutputContext.Provider>
    );
  };
  
  export { OutputContextProvider, useOutputContext, useOutputContextUpdater };