import {useIntl} from 'react-intl'
import { useNavigate } from 'react-router-dom';
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import BooleanCheckmarkRenderer from '../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import { BooleanFilterOptions } from '../../modules/output-listing/filters/BooleanFilter';
import { useAppSelector } from '../../redux/hooks';
import { useCurrencyFormatter } from '../../modules/sgl-utils/Formatters';

const PaymentBatchesList = () => {
    const intl = useIntl();
    const currency = intl.formatMessage({ id: 'CURRENCY' })
    const navigate = useNavigate()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const currencyFormatter = useCurrencyFormatter()
    
    const columnDefs = [
        { field: 'payment_batch_id', headerName: intl.formatMessage({id: 'List.PAYMENT.METHODS.ID'}), filter: 'agNumberColumnFilter', hide: true },
        { field: 'batchno', width: 250, headerName: intl.formatMessage({id: 'List.PAYMENT.METHODS.BATCHNO'}), filter: 'agTextColumnFilter', },
        { field: 'Show.show_name', width: 400, headerName: intl.formatMessage({id: 'List.PAYMENT.METHODS.SHOW'}), filter: 'agTextColumnFilter', sortable: false},
        { field: 'posted_checks', headerName: intl.formatMessage({id: 'List.PAYMENT.METHODS.POSTED.CHECKS'}), sortable: false},
        { field: 'unposted_checks', headerName: intl.formatMessage({id: 'List.PAYMENT.METHODS.UNPOSTED.CHECKS'}), sortable: false},
        { field: 'amount', headerName: intl.formatMessage({id: 'List.PAYMENT.METHODS.AMOUNT'}), filter: 'agNumberColumnFilter', cellRenderer: params => currencyFormatter(params.value), cellStyle: { textAlign: 'right' } },
        { field: 'batch_posted', headerName: intl.formatMessage({id: 'List.PAYMENT.METHODS.POSTED'}), filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, sortable: false},
    ]

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PAYMENT.BATCHES'})}</PageTitle>
            <OutputListingComponent 
                area={'PaymentBatches'} 
                columnDefs={columnDefs} 
                apiEndPoint={'payment-batches/list'}
                onRowDoubleClicked={(row_data) => {navigate('/payment-batches/detail/'+row_data.payment_batch_id+`?customer_id=${customer_id}&batch_show_id=${row_data.show_id}`)}}
                onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL+'/payment-batches/detail/'+row_data.payment_batch_id+`?customer_id=${customer_id}&batch_show_id=${row_data.show_id}`,'_blank')}
            />
        </>
    )
}

export {PaymentBatchesList}
