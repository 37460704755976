import React,{useCallback} from 'react';
import {useIntl} from 'react-intl'
import { SaveModifiedColumnDefinitions } from '../sgl-utils/agGridHelpers';
import { useAuth } from '../auth';
import { useAppSelector } from '../../redux/hooks';
import { useOutputContext } from './OutputListingContext';
import { KTSVG } from '../../../_metronic/helpers';

const OutputListingBarMenu = (props) => {

    const intl = useIntl()
    const {currentUser} = useAuth()
    const context = useOutputContext()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);

    const resetColumns = () => {
        props.api.setColumnDefs(props.defaultDefs) //set to default column defs
        props.columnApi.resetColumnState()
        localStorage.removeItem(context.outputGrid.area) //removing settings from local storage
        SaveModifiedColumnDefinitions(props.api.getColumnDefs(), context.outputGrid.area, currentUser, customer_id) //upadting settings in db
      }

      //autosize all columns
      const autoSizeAll = useCallback(() => {
        props.columnApi.autoSizeAllColumns()
      }, []);

    return (
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>
            <button style={{backgroundColor:"transparent",border:"none"}} data-kt-menu-trigger="{default:'click'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                <KTSVG path="/media/icons/duotune/general/gen033.svg" className="svg-icon-muted svg-icon-2hx" />                        
            </button>   
    
            <div className="menu menu-sub menu-sub-dropdown menu-column menu-title-dark fw-normal py-0 fs-7 w-200px" style={{boxShadow:"none"}} data-kt-menu="true" data-kt-element="theme-mode-menu">
                <div className="menu-item px-2 my-0">
                    <a href="#" className="menu-link px-3 py-2 active" data-kt-element="mode" data-kt-value="light"  onClick={() => resetColumns()} >
                        <span className="menu-title">{intl.formatMessage({id:  "STATUSBAR.MENU.LABEL.RESET"})}</span>
                    </a>
                </div>

                <div className="menu-item px-2 my-0">
                    <a href="#" className="menu-link px-3 py-2 active" data-kt-element="mode" data-kt-value="light" onClick={() => autoSizeAll()}>
                        <span className="menu-title">{intl.formatMessage({id:  "STATUSBAR.MENU.LABEL.AUTOSIZE"})}</span>
                    </a>
                </div>

            </div>
        </div>

        )
}

export default OutputListingBarMenu