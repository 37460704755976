import React, {useEffect} from 'react'
import { useIntl } from 'react-intl'
import { useAlert, useConfirm, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider'
import { useOutputContext, useOutputContextUpdater} from '../../../modules/output-listing/OutputListingContext'
import axios from 'axios';
import { useAppSelector } from '../../../redux/hooks'
 
const SplitClass = ({show, handleClose}) => {
    const intl = useIntl() 
    const loadingOverlay = useLoadingOverlay()
    const alertDialog = useAlert()
    const {getOutputSelectionAreaIDs, getEventID} = useOutputContext()
    const outputContextUpdater = useOutputContextUpdater()
    const confirmDialog = useConfirm()
    const customerId = useAppSelector(state => state.showCompany.company_id);
    const currentShowId = useAppSelector(state => state.currentShow.show_id);

    useEffect(() => {
        if (show) {
            loadingOverlay({ show: true })
            getOutputSelectionAreaIDs()
            .then(res => { 
                if (res.length !== 1) { // only one class can be split
                    loadingOverlay({ show: false })
                    alertDialog({ message: intl.formatMessage({ id: "CLASSES.QUICKACTION.SPLITCLASS.MODAL.ERROMESSAGE"}) })
                    handleClose()
                }
                else{
                    // if user selected one class
                    loadingOverlay({ show: false })

                    // ask user for confirmation
                    confirmDialog({ message: intl.formatMessage({ id: "CLASSES.QUICKACTION.SPLITCLASS.MODAL.CONFIRM"}) + "?"})
                    .then((choice) => {
                        if(choice){
                            loadingOverlay({ show: true })
                            //Split the selected class
                            axios.post( process.env.REACT_APP_NEST_API_URL + "/classes/splitClass", {
                                customer_id: customerId,
                                class_id: res[0],
                                show_id: currentShowId
                            })
                            .then((res) => {
                                if(!res.data?.success){
                                    alertDialog({message: res?.data?.error});
                                }
                            })
                            .catch((error) => {
                                if (error.response) {
                                    alertDialog({message: error.response.data.error});
                                }
                            }).finally(() => {
                                loadingOverlay({ show: false })
                                handleClose()
                                outputContextUpdater({action: 'refresh'})
                            })
                        }else{
                            handleClose()
                        }
                    })
                }
            })
            .catch(() => loadingOverlay({ show: false }))
        }  
    }, [show])
}

export default SplitClass