import { useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import axios from 'axios';
import { KTSVG } from '../../../../_metronic/helpers';
import "flatpickr/dist/themes/material_blue.css";
import { downloadFileFromBuffer, loadingSpinnerBtnWait, loadingSpinnerBtnRelease, loadingSpinnerBtnWaitByEnter, loadingSpinnerBtnReleaseByEnter } from '../../../modules/sgl-utils/SglFunctions';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const ExportFeiResultsToExcel = ({ show, handleClose }) => {
    const intl = useIntl()
    const [teamClassNumber, setTeamClassNumber] = useState(0);
    const { getOutputSelectionAreaIDs, getEventID } = useOutputContext();
    const {progressBar} = useProgress()
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const alertDialog = useAlert()

    const exportFeiResultsToExcel = (e) => {
        loadingSpinnerBtnWaitByEnter(e);

        getOutputSelectionAreaIDs()
        .then((classIds)=>{
            // Not using generic export function because of changed record set IDs

            const progressEventId = getEventID('export-fei-results-to-excel') 
            progressBar({ show: true, eventID: progressEventId, showProgress: 'inline', title: 'Export FEI Results to Excel', timeElapsed: true })

            axios.post( NEST_API_URL + `/classes/exportFeiResultsToExcel`, {
                customer_id: customerId,
                show_id: currentShowID,
                progress_event_id: progressEventId,
                record_ids: classIds,
                team_class_number: teamClassNumber
            })
            .then((response) => {
                if (response.data.success) {
                    downloadFileFromBuffer(response.data.fileBuffer, response.data.fileName, response.data.fileType)
                    handleClose()
                } else {
                    alertDialog({ message: response.data.message })
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally( () => {
                loadingSpinnerBtnReleaseByEnter(e)
                progressBar({ show: false, eventID: progressEventId})
            })
        })
    }

    // Change handler for the team class number input field
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const sanitizedValue = inputValue.replace(/[^\d]/g, ''); // Remove non-digit characters
        setTeamClassNumber(parseInt(sanitizedValue));
    }


    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-400px'
            show={show}
            backdrop='static'
            onKeyDown={(event) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) {
                    let okButton = document.getElementById('ExportFeiResultsOKButton')
                    exportFeiResultsToExcel(okButton)
                }
                if(event.key === 'Escape'){
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'CLASSES.QUICKACTION.EXPORTFEIRESULTSTOEXCEL.TITLE' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>
            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body px-0'>
                        <div className='row mb-2 px-4'>
                            <div className='col-12'>
                                <label className='col-lg-12 col-form-label fs-5 py-1' htmlFor={'action'} data-tooltip-id="CLASS.DETAIL.QUICKACTION.EXPORTFEIRESULTSTOEXCEL.LABEL.ENTERCLASSTEAMNO">{intl.formatMessage({ id: 'CLASSES.QUICKACTION.EXPORTFEIRESULTSTOEXCEL.ENTERTEAMCLASSNUMBER' })} </label>
                            </div>
                            <div className='col-12'>
                                <input 
                                    className='fs-5'
                                    style={{ width: '100%' }}
                                    type='number' 
                                    onChange={(e) => handleInputChange(e)}
                                    autoFocus
                                    tabIndex={1}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3 px-4'>
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={2}>
                            {intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button id='ExportFeiResultsOKButton' type='button' className='btn btn-sm btn-dark fw-bold' onClick={exportFeiResultsToExcel} tabIndex={3}>
                            <span className="spinner-border spinner-border-sm d-none me-2"></span>
                            {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.OK' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
        , modalsRoot)
}

export default ExportFeiResultsToExcel;