/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import CurrencyRenderer from '../../modules/output-listing/renderers/CurrencyRenderer';
import { useCurrencyFormatter } from '../../modules/sgl-utils/Formatters';

const DivisionsList = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const customer_id = useAppSelector(state=> state.showCompany.company_id);
  const currencyFormatter = useCurrencyFormatter()
  const columnDefs = [
    // { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.COMMONS.SGLID'}), filter: 'agNumberColumnFilter', filterParams: { suppressAndOrCondition: true,}, },
    { field: 'division_id', headerName: intl.formatMessage({id: 'LIST.DIVISIONS.DIVISIONID'}), filter: 'agNumberColumnFilter',hide:true},
    { field: 'code', headerName: intl.formatMessage({id: 'LIST.DIVISIONS.CODE'}), filter: 'agTextColumnFilter', },
    { field: 'name', width: 300, headerName: intl.formatMessage({id: 'LIST.DIVISIONS.NAME'}), filter: 'agTextColumnFilter', },
    { field: 'show_name', width: 400, headerName: intl.formatMessage({id: 'LIST.DIVISIONS.SHOWNAME'}), filter: 'agTextColumnFilter', },
    { field: 'price', width: 150, headerName: intl.formatMessage({id: 'LIST.DIVISIONS.DIVISIONPRICE'}), filter: 'agNumberColumnFilter', cellRenderer: params => currencyFormatter(params.value), }
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DIVISIONS'})}</PageTitle>
      <OutputListingComponent key={customer_id} area={'Divisions'} columnDefs={columnDefs} apiEndPoint={'divisions/list'}  
        onRowDoubleClicked={(row_data) => {navigate('/divisions/detail/'+row_data.division_id+`?customer_id=${customer_id}`)}}
        onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL+'/divisions/detail/'+row_data.division_id+`?customer_id=${customer_id}`,'_blank')}>
      </OutputListingComponent>
    </>
  )
}

export {DivisionsList}
