import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import axios from 'axios';
import {useIntl} from 'react-intl'

const PaymentWidget = ({
    chartSize = 90,
    chartLine = 15,
    chartRotate = 145,
}) => {
    const chartRef = useRef(null);
    const [showId, setShowId] = useState();
    const [showName, setShowName] = useState();
    const [totalPayment, setTotalPayment] = useState();
    const [showPayments, setShowPayments] = useState([]);
    const customer_id = useAppSelector(state => state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const [isLoading, setIsLoading] = useState(true);
    const intl = useIntl()

    useEffect(() => {
        if(currentShowID > 0){
            setIsLoading(true);
            refreshChart();
            axios
                .get(
                    process.env.REACT_APP_NEST_API_URL +
                    '/dashboard/getShowPaymentsByTypes?customer_id=' + customer_id + '&show_id=' + currentShowID
                )
                .then((response) => {

                    const lineColor = [
                        '#50cd89',
                        '#7239ea',
                        '#f1416c',
                        '#ffc701',
                        '#1b84ff',
                        '#181c32',
                        '#17c653',
                        '#E62721',
                        '#f3bd00',
                        '#FF69B4',
                        '#97CD89',
                        '#00CED1',
                        '#663399',
                        '#FFA500',
                        '#FF4500',
                        '#00FF7F',
                        '#f8285a',
                    ];

                    setShowName(response.data.show_name);
                    setShowId(response.data.show_id);
                    const roundedTotalPayment = Number(response.data.total_payment);
                    setTotalPayment(roundedTotalPayment);

                    const paymentRecords = response.data.data.map((p, index) => {
                        const roundedAmount = Number(p.amount);
                        return {
                            p_type: p.p_type,
                            amount: roundedAmount,
                            color: lineColor[index % lineColor.length],
                        };
                    });

                    setShowPayments(paymentRecords);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching events:', error);
                });
            }
    }, [currentShowID, customer_id]); // eslint-disable-line react-hooks/exhaustive-deps

    const refreshChart = () => {
        if (!chartRef.current) {
            return;
        }

        setTimeout(() => {
            initChart(chartSize, chartLine, chartRotate, showPayments);
        }, 10);
    };

    // Call initChart after the component has mounted
    useEffect(() => {
        initChart(chartSize, chartLine, chartRotate, showPayments);
    }, [showPayments]);

    return (
        <div className={`widget show-payment card card-flush mb-0`}>
            {isLoading &&
                <>
                    <br/>
                    <span className="spinner-border spinner-border-sm m-5" role="status" aria-hidden="true"></span>
                </>
            }

            {!isLoading &&
            <>
            <div className='card-header pt-5 ps-7 pe-7'>
                <div className='card-title d-flex flex-column mw-100'>
                    <div className='d-flex align-items-center'>
                        <span className='fs-2 fw-bold text-gray-500 me-1 align-self-start'>$</span>
                        <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{totalPayment?.toLocaleString('en-US')}</span>
                    </div>
                    <span className="custom-truncate text-gray-500 pt-2 fw-bold fs-6 mw-100">
                        {showName}
                    </span>
                </div>
            </div>

            <div className='card-body pt-2 ps-7 pb-5 pe-7 d-flex flex-wrap align-items-center'>
                <div className='d-flex flex-center me-7 pt-2'>
                    <div
                        id='payment_chart'
                        ref={chartRef}
                        style={{ minWidth: chartSize + 'px', minHeight: chartSize + 'px' }}
                        data-kt-size={chartSize}
                        data-kt-line={chartLine}
                    ></div>
                </div>
                <div className='d-flex flex-column content-justify-center flex-row-fluid' style={{maxHeight: "100px",overflowY: "scroll"}}>
                    {showPayments
                        .filter((p) => p.amount !== 0)
                        .map((p, index) => (
                            <div className='d-flex fw-semibold align-items-center' key={index}>
                                <div
                                    className='bullet w-8px h-3px rounded-2 me-3'
                                    style={{ backgroundColor: p.color }}
                                ></div>
                                <div className='text-gray-500 flex-grow-1 me-4'>{p.p_type}</div>
                                <div className='fw-bolder text-gray-700 text-xxl-end'>${p.amount?.toLocaleString('en-US')}</div>
                            </div>
                        ))}
                </div>
            </div>
            </>
            }
        </div>
    );
};

const initChart = function (chartSize, chartLine, chartRotate, showPayments) {
    const el = document.getElementById('payment_chart');
    if (!el) {
        return;
    }
    el.innerHTML = '';

    const options = {
        size: chartSize,
        lineWidth: chartLine,
        rotate: chartRotate,
        stroke: {
            width: 1,
        },
    };

    const resolution = 3; // Increase the resolution factor
    const canvas = document.createElement('canvas');
    canvas.width = canvas.height = options.size * resolution;
    
    canvas.style.width = options.size + 'px'; // Set the display size using CSS
    canvas.style.height = options.size + 'px';

    el.appendChild(canvas);

    const ctx = canvas.getContext('2d');
    ctx.scale(resolution, resolution); // Scale the context
    ctx.translate(options.size / 2, options.size / 2);
    ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI);

    const radius = (options.size - options.lineWidth) / 2;

    const drawSegment = function (color, startAngle, endAngle) {
        ctx.beginPath();
        ctx.arc(0, 0, radius, startAngle, endAngle, false);
        ctx.strokeStyle = color;
        ctx.lineCap = 'butt';
        ctx.lineWidth = options.lineWidth;
        ctx.stroke();
    };

    const nonZeroPositivePayments = showPayments
        .filter((payment) => payment.amount > 0);

    const totalAmount = nonZeroPositivePayments.reduce((sum, payment) => sum + Number(payment.amount), 0);

    if (totalAmount > 0) {
        let startAngle = 0;
        let minSegmentAngle = 0.12; // Adjust this value as needed

        nonZeroPositivePayments.forEach((payment) => {
            const amount = Number(payment.amount);
            const percentage = amount / totalAmount;

            // Ensure that the segment is at least minSegmentAngle
            const angle = Math.max(percentage * Math.PI * 2, minSegmentAngle);

            const endAngle = startAngle + angle;

            drawSegment(payment.color, startAngle, endAngle);
            startAngle = endAngle;
        });
    }
};


export { PaymentWidget };
