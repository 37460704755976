import { createContext, useState, useContext } from "react";

const ReportsContext = createContext()

const ReportsContextProvider = ({ children }) => {
    const [reportName, setReportName] = useState('');
    const [area, setArea] = useState('');
    const [customerId, setCustomerId] = useState(-1);
    const [comments, setComments] = useState('');
    const [isSystemReport, setIsSystemReport] = useState(false);
    const [published, setPublished] = useState(false);
    const [response, setResponse] = useState();
    const [metaData, setMetaData] = useState();
    const [displayName, setDisplayName] = useState('');
    const [subReportId, setSubReportId] = useState();
    const [singlePrintPerRecord, setSinglePrintPerRecord] = useState(false);
    const [reportGroup, setReportGroup] = useState(null);
    const [systemReport, setSystemReport] = useState();
    const [subreportPrintAction, setSubreportPrintAction] = useState('');
    const [reportLabel, setReportLabel] = useState('');
    const [allowCsvExport, setAllowCsvExport] = useState(false);
    const [hiddenForCustomer, setHiddenForCustomer] = useState(false);

    return (
        <ReportsContext.Provider value={{ reportName, setReportName, area, setArea, customerId, setCustomerId, comments, setComments, isSystemReport, setIsSystemReport, published, setPublished, subReportId, setSubReportId, singlePrintPerRecord, setSinglePrintPerRecord, metaData, setMetaData, response, setResponse, displayName, setDisplayName, reportGroup, setReportGroup, systemReport, setSystemReport, subreportPrintAction, setSubreportPrintAction, reportLabel, setReportLabel, allowCsvExport, setAllowCsvExport, hiddenForCustomer, setHiddenForCustomer}}>
            {children}
        </ReportsContext.Provider>
    )
}

// context consumer hook
const useReportsContext = () => {
    // get the context
    const context = useContext(ReportsContext);
  
    // if `undefined`, throw an error
    if (context === undefined) {
      throw new Error("useReportContext was used outside of its Provider");
    }
  
    return context;
};


export { useReportsContext, ReportsContextProvider }
