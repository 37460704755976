import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useFormContext } from 'react-hook-form';

// Constants
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL


const ORGForHorses = (props) => {
  const intl = useIntl();
  const methods = useFormContext()
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const containerStyle = useMemo(() => ({ width: '100%', height: '200px' }), []);
  const rowHeight = 28;
  const headerHeight = 30;
  const [orgCodeHorses, setOrgCodeHorses] = useState([])
  
  const [personGridRef, setPersonGridRef] = useState ()

  const renderCheckBox = (params) => {
      return (
          <input
              type="checkbox"
              onChange={(e) => params.node.setDataValue(params.column.colId, e.target.checked)}
              defaultChecked={params.value}
          />
      )
  }

  //Grid Classes
  const columnDefs = [
    { field: 'select', headerName: "", sortable: false, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, suppressSizeToFit: true, maxWidth: 30, minWidth: 30, cellRenderer: renderCheckBox},
    { field: 'org_for_horses', flex: 1, headerName: intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.GRID.LABEL.ORGFORHORSES'}), cellStyle: {fontSize: '13px',textAlign: 'left', 'paddingLeft': 5, 'paddingRight': 5} },
  ];

  const onFirstDataRendered = useCallback((params) => {
      params.api.sizeColumnsToFit();
  }, []);
  
  const onCellEdit = useCallback (e => {
    let temp = orgCodeHorses;
    if(e.data.select == true){
      if (orgCodeHorses == null){
        setOrgCodeHorses(e.data.org_for_horses)
        temp.push(e.data.org_for_horses)
      } else {
        setOrgCodeHorses(orgCodeHorses => [...orgCodeHorses,e.data.org_for_horses])
        temp.push(e.data.org_for_horses)
      } 
    } else {
        setOrgCodeHorses((current) => current.filter((data) => data !== e.data.org_for_horses)
      )
      temp = temp.filter((data) => data !== e.data.org_for_horses)
    }
    methods.setValue('ORGForHorses', temp,  {shouldDirty: true})
  })

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: false,
      suppressMenu: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    // Grid Reference initialize to params.api
    setPersonGridRef (params.api)
  }, []);

  return (
    <>
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          headerHeight={headerHeight}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={props.rowData}
          rowHeight={rowHeight} 
          onFirstDataRendered={onFirstDataRendered}
          onCellValueChanged={onCellEdit}
          setGridRef={setPersonGridRef}
          onGridReady = {onGridReady}
        ></AgGridReact>
      </div>
    </div>
    </>
  );
};

export default ORGForHorses;
  