import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";
import useGenerateExport from '../../../modules/hooks/use-generate-export';
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import { HorseLookupForm } from '../../entries/Detail/General/HorseLookupForm';
import { set, useFormContext } from 'react-hook-form';
import { PersonLookupForm } from '../../entries/Detail/General/PersonLookupForm';
import { getFirstNameFromSearchString, getLastNameFromSearchString } from '../../../modules/sgl-utils/SglFunctions';

const modalsRoot = document.getElementById('root-modals') || document.body

const ImportFEIEntries = ({ show, handleClose, data, callbackFromParent }) => {
    const intl = useIntl()
    const loadingOverlay = useLoadingOverlay()
    const customerId = useAppSelector(state => state.showCompany.company_id);
    const [entriesGridRef, setEntriesGridRef] = useState();
    const importEventStyle = useMemo(() => ({ width: '100%', height: '400px' }), []);
    const { getEventID } = useOutputContext();
    const {progressBar} = useProgress()
    const alertDialog = useAlert();
    const confirmDialog = useConfirm();
    const [selectedArea, setSelectedArea] = useState('rider');
    const [selectAllChecked, setSelectAllChecked] = useState(false); // State for checkbox
    const [showHorseLookupForm, setShowHorseLookupForm] = useState(false);
    const [showPersonLookupForm, setShowPersonLookupForm] = useState(false);
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const [horseSearchList, setHorseSearchList] = useState([]);
    const [personSearchList, setPersonSearchList] = useState([]);
    const [horseCountStatus, setHorseCountStatus] = useState('');
    const [selectedRow, setSelectedRow] = useState({});
    const [selectedRiderRow, setSelectedRiderRow] = useState({});
    const methods = useFormContext();
    const [horseMappingGridRef, setHorseMappingGridRef] = useState();
    const [riderMappingGridRef, setRiderMappingGridRef] = useState();
    const [dialogData, setDialogData] = useState({ search_term: '' })
    const [personSearchCountStatus, setPersonSearchCountStatus] = useState('');
    const [entriesToImport, setEntriesToImport] = useState(0);
    const [eventsImported, setEventsImported] = useState(false);


    // Handle select all change for entries tab
    const handleSelectAllChange = (e) => {
        setSelectAllChecked(e.target.checked); // Update selectAllChecked state on checkbox change
        data.entriesMappings.forEach((row) => {
            if (!row.entry_exists) {
                row.selected = e.target.checked
            } else {
                row.selected = false;
            }
        });
        const count = data.entriesMappings.filter(r => r.selected).length;
        setEntriesToImport(count);
        entriesGridRef.setRowData(data.entriesMappings);
    };

    const onClose = () => {
        loadingOverlay({ show: false })
        handleClose();
    }

    // Check all entries when entry tab is opened (Same as 4D)
    useEffect(() => {
        if (selectedArea == 'entries') {
            setSelectAllChecked(true);
            data.entriesMappings.map((item) => {
                if (!item.entry_exists) {
                    item.selected = true
                } else {
                    item.selected = false;
                }
            });
            entriesGridRef?.setRowData(data.entriesMappings);
            const count = data.entriesMappings.filter(r => r.selected).length;
            setEntriesToImport(count);
        }
    }, [selectedArea])

    // Handle change for entries checkbox
    const changeEntriesSelection = (e, params) => {
        params.node.setDataValue(params.column.colId, e.target.checked)
        const count = data.entriesMappings.filter(r => r.selected).length;
        setEntriesToImport(count);
        setSelectAllChecked(false);
    }

    // Change rider function
    // This will set up the selected rider in the person lookup form
    const ChangeRider = async (params) => {
        setSelectedRiderRow(params);
        let riderName = params.data.local_rider_name != '' ? params.data.local_rider_name : '';
        let riderFeiId = params.data.local_rider_feiId != '' ? params.data.local_rider_feiId : '';
        let riderid = params.data.people_id != '' ? params.data.people_id : '';
        let riderState = params.data.local_rider_state != '' ? params.data.local_rider_state : '';
        let memberType = '';
        let competesIn = '';
        // Save selected local rider in variables

        if(riderName == '' && riderFeiId == '') {
            setPersonSearchList([]);
            handleSearchTerm(riderName)
            // setDialogData(prevState => ({ ...prevState, search_term: riderName }));
            setPersonSearchCountStatus('Found 0 RTOs in local database');
            setShowPersonLookupForm(true);
        } else {
            const riderObj = {fei_id: riderFeiId, lf_name: riderName, fl_name: riderName, state: riderState, member_type: memberType, competes_in: competesIn, people_id: riderid}
            setPersonSearchList([riderObj]);
            handleSearchTerm(riderName)
            // setDialogData(prevState => ({ ...prevState, search_term: riderName }));
            setPersonSearchCountStatus('Found 1 RTOs in local database');
            setShowPersonLookupForm(true);
        }
    }

    // Column defs for rider mappings
    const riderMappingColDefs = () => {
        return [
            { field: 'fei_id', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, width: 80, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.RTOFEIID" }) },
            { field: 'first_name', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, width: 100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.RTOFIRSTNAME" }) },
            { field: 'last_name', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, width: 100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.RTOLASTNAME" }) },
            { field: 'dob', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'center', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, width: 130, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.RTODATEOFBIRTH" }) },
            { field: 'country_code', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'center', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, width: 120, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.RTOCOMPETINGFOR" }) },
            { 
                field: '', 
                headerClass: 'custom-ag-grid-header', 
                cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, 
                width: 220, 
                sortable: true, 
                headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.RTOMATCHINGRTOINLOCALDB" }),
                cellRenderer: (params) => {
                    const riderNameString = params.data.local_rider_feiId && params.data.local_rider_feiId != '' ? `${params.data.local_rider_feiId}-${params.data.local_rider_name}` : params.data.local_rider_name;
                    return (
                        <a 
                            style={{ color: 'blue' }} 
                            href='#'
                            // onClick ={() => {getDetailPageInfo(params)}}
                            onClick ={() => params.data.people_id > 0?callbackFromParent(params.data.people_id,"People","PeopleDetail"):''}
                        >
                            {riderNameString}
                        </a>
                    )
                }
            },
            { 
                field: 'user_mapped', 
                headerClass: 'custom-ag-grid-header', 
                cellStyle: { textAlign: 'center', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, 
                width: 180, sortable: true, 
                headerName: '', 
                cellRenderer: (params) => {
                    return(
                        // TODO: Redirect to people or open people detail in a modal
                        <a onClick={() => ChangeRider(params)} style={{color: 'blue'}} href='#'>Change Rider</a>
                    )
                }
            }          
        ]
    }

    // Horse mappings column defs
    const horseMappingColDefs = () => {
        return [
            { field: 'fei_id', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, width: 80, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.HORSEFEIID" }) },
            { field: 'name', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, width: 100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.HORSEFEINAME" }) },
            { field: 'dob', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'center', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, width: 100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.HORSEDOB" }) },
            { field: 'dam', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, width: 100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.HORSEDAM" }) },
            { field: 'sire', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, width: 100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.HORSESIRE" }) },
            { field: 'owner', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, width: 100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.HORSEOWNER" }) },
            { 
                field: '', 
                headerClass: 'custom-ag-grid-header', 
                cellStyle: { textAlign: 'left', 
                paddingRight: '2px', paddingLeft: 
                '2px', fontSize: '12px' }, 
                width: 250, sortable: true, 
                headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.MATCHINGHORSEINLOCALDB" }),
                cellRenderer: (params) => {
                    const horseNameString = params.data.local_fei_id && params.data.local_fei_id != '' ? `${params.data.local_fei_id}-${params.data.local_horse_name}` : params.data.local_horse_name;
                    return(
                        <a 
                            onClick ={() => params.data.horse_id > 0?callbackFromParent(params.data.horse_id,"Horses","HorseDetail"):''}                            
                            style={{color: 'blue'}} 
                            href='#'
                        >
                            {horseNameString}
                        </a>
                    )
                }
            },
            { 
                field: '', 
                headerClass: 'custom-ag-grid-header', 
                cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, 
                width: 250, 
                sortable: true, 
                headerName: '',
                cellRenderer: (params) => {
                    return(
                        <a onClick={() => updateEntryHorse(params)} style={{color: 'blue'}} href='#'>Change Horse{params.value}</a>
                    )
                }
            },
        ]
    }

    // Entries mappings column defs
    const entriesMappingColDefs = () => {
        return [
            { 
                field: 'selected',
                headerName: "",
                headerClass: 'custom-ag-grid-header',
                cellStyle: {textAlign: 'center'},
                suppressSizeToFit: true,
                maxWidth: 50,
                cellRenderer: (params) => {
                    return (
                        <input
                            type="checkbox"
                            checked={params.data.entry_exists ? false : params.value}
                            disabled={params.data.entry_exists}
                            onChange={(e) => changeEntriesSelection(e, params)}
                        />
                )},
            },
            { field: 'horse_name', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px' }, width: 100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.HORSE" }) },
            { field: 'horse_fei_id', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px'  }, width: 120, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.HORSEFEIID" }) },
            { field: 'rider_lf_name', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px'  }, width: 150, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.RIDERLFNAME" }) },
            { field: 'rider_fei_id', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px'  }, width: 120, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.RIDERFEIID" }) },
            { field: 'owner_lf_name', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px'  }, width: 150, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.OWNERLFNAME" }) },
            { field: 'owner_fei_id', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px'  }, width: 120, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.OWNERFEIID" }) },
            { field: 'local_entry', headerClass: 'custom-ag-grid-header', cellStyle: { textAlign: 'left', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px'  }, width: 230, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.IMPORTFEIENTRIES.MODAL.MATCHINGENTRYINLOCALDB" }) },
        ]
    }

    // Update selectedArea state on radio change (Rider, Horse, Entries)
    const handleAreaChange = (event) => {
        setSelectedArea(event.target.value);
    };

    // Import entries, final step
    const importEntries = async () => {
        const selectedRows = data.entriesMappings.filter((item) => item.selected == true);

        if (selectedRows.length == 0) {
            alertDialog({ message: intl.formatMessage({ id: "SHOWS.IMPORTFEIEVENTS.MODAL.SELECTROWSMESSAGE"}) })
            return;
        }

        // confirm before importing 
        let choice = await confirmDialog({ message: "Are you sure you want to import "+selectedRows.length+" entries?" })
        if (!choice){
            return;
        }

        const event_id = getEventID("import-show-FEI-Entries");

        const postBody =  {
            show: methods.getValues('show'),
            customer_id: customerId,
            selected_entries: selectedRows,
            progress_event_id: event_id
        };
        // loadingOverlay({ show: true, message: "FEI import event" })
        progressBar({ show: true, eventID: event_id, showProgress: 'inline', title: "Importing FEI entries", timeElapsed: true })

        axios.post( process.env.REACT_APP_NEST_API_URL + '/shows/importFeiEntries', { params: postBody })
        .then((response) => {
            if (response?.data?.success) {
                progressBar({ show: false, eventID: event_id, showProgress: 'inline', title: "Importing FEI entries", timeElapsed: true })
                const feiImportedData = response?.data?.fei_imported_data;
                methods.setValue('fei_imported_data', feiImportedData, { shouldDirty: true })
                setEventsImported(true)

                // handleClose();
            } else {
                // display error message
            }
            loadingOverlay({ show: false, message: "FEI import evnets" })
        })
        .catch(() => loadingOverlay({ show: false, message: "FEI import entries" }))

    }

    // This function will return the selected row from lookup form
    // Do not delete unused parameters as it is called horse lookup form
    async function getSelectedHorse(newHorseData, organization){
        // Update the selected row with new horse data
        const oldHorseData = selectedRow.data;
        const oldHorseId = oldHorseData.horse_id;
        const oldHorseFeiId = oldHorseData.fei_id;

        if (oldHorseId != newHorseData.horse_id) {
            const updatedRow = {
                ...selectedRow.data,
                local_horse_name: newHorseData.name,
                horse_id: newHorseData.horse_id,
                local_fei_id: newHorseData.fei_id,
                local_trainer: newHorseData.trainer,
                local_owner: newHorseData.owner,
                local_horse_location: newHorseData.location,
                user_mapped: true,
            };
    
            // Get the index of the selected row
            // const rowIndex = selectedRow.rowIndex;
            const rowIndex =  data.horseMappings.findIndex(r => r.fei_id == oldHorseFeiId);
            data.horseMappings[rowIndex] = updatedRow;
            horseMappingGridRef.setRowData(data.horseMappings);

            // Update horse id and user mapped flag in entries mappings
            data.entriesMappings.forEach((item) => {
                if (item.horse_fei_id == oldHorseFeiId) {
                    // Setting user mapped
                    item.horse_name = newHorseData.name;
                    item.user_mapped = true;
                    item.horse_id = updatedRow.horse_id
                    item.horse_local_fei_id = updatedRow.local_fei_id
                    item.entry_exists = false
                    item.local_entry = ''
                }
            })
            if (newHorseData.horse_id > 0) {
                let updated_entries = data.entriesMappings.filter(e => e.horse_fei_id == oldHorseFeiId && e.rider_id > 0)
                let existing_entries = await getExistingEntries(updated_entries)
                if (existing_entries?.length > 0) {
                    // Update horse id and user mapped flag in entries mappings
                    for (let index = 0; index < data.entriesMappings?.length; index++) {
                        let existing_entry = existing_entries.find(e => e.horse_id == data.entriesMappings[index].horse_id && e.rider_id == data.entriesMappings[index].rider_id)
                        if (existing_entry) {
                            data.entriesMappings[index] = existing_entry
                        }
                    }

                }
            }

            // Update entries grid
            entriesGridRef?.setRowData(data.entriesMappings);
        }
    }

    async function getExistingEntries(updated_entries){
        const response = await axios.post(process.env.REACT_APP_NEST_API_URL + "/shows/getExistingEntries", {
            params: {
                updated_entries,
                show_id: methods.getValues('show.show_id'),
                customer_id: customerId,
                
            }
        })
        return response && response.data?response.data:[]
    
    }

    // Add new horse
    const addNewHorse = async (newHorseObj) => {
        const newHorseData = newHorseObj.horse_data;
        const oldHorseData = selectedRow.data;
        const oldHorseId = oldHorseData.horse_id;
        const oldHorseFeiId = oldHorseData.fei_id;

        if (oldHorseId != newHorseData.horse_id && newHorseData.name && newHorseData.name.trim() != '') {
            const updatedRow = {
                ...selectedRow.data,
                horse_id: newHorseData.horse_id,
                local_horse_name: newHorseData.name,
                local_fei_id: newHorseData.fei_id,      // In case of new horse, this will be empty but check in 4D
                local_trainer: '',
                local_owner: '',
                user_mapped: true,
            };
    
            // Get the index of the selected row
            // const rowIndex = selectedRow.rowIndex;
            const rowIndex =  data.horseMappings.findIndex(r => r.fei_id == oldHorseFeiId);

            data.horseMappings[rowIndex] = updatedRow;

            // Update grid
            horseMappingGridRef.setRowData(data.horseMappings);

            // Update horse id and user mapped flag in entries mappings
            // In case of new horse, no need to get entries again
            data.entriesMappings.forEach((item) => {
                if (item.horse_fei_id == oldHorseFeiId) {
                    // Setting user mapped
                    item.horse_name = newHorseData.name;
                    item.user_mapped = true;
                    item.horse_id = updatedRow.horse_id
                    item.horse_local_fei_id = updatedRow.local_fei_id
                }
            })

            // Update entries grid
            entriesGridRef?.setRowData(data.entriesMappings);
        }

    }

    // Class rules for changing bg color of row when update occurs
    const rowClassRules = useMemo(() => {
        return {
            'bg-expired': 'data.entry_exists',
            'bg-override': 'data.user_mapped',
        };
    }, []);


    // This function will basically trigger the horse lookup form with currently selected horse data
    const updateEntryHorse = async (params) => {
        setSelectedRow(params);
        // Save selected local horse in variables
        const horseId = params.data.horse_id;
        const trainer = params.data.local_trainer;
        const owner = params.data.local_owner;
        const location = params.data.local_horse_location;
        const horseName = params.data.local_horse_name != '' ? params.data.local_horse_name : '';
        const horseFeiId = params.data.local_fei_id != '' ? params.data.loca_fei_id : '';

        if (horseName == '' && horseFeiId == '') {
            setHorseSearchList([]);
            setHorseCountStatus('Found 0 horses in local database');
            setShowHorseLookupForm(true);
        } else {
            console.log(horseName)
            const horseObj = {name: horseName, trainer: trainer, owner: owner, location: location, horse_id: horseId, fei_id: horseFeiId, usef_no: '', ec_ppn: ''};
            setHorseSearchList([horseObj]);
            methods.setValue('horse_search', horseName)
            setHorseCountStatus('Found 1 horses in local database');
            setShowHorseLookupForm(true);
        }
    }
    
    //Function for handling Search Tem
    const handleSearchTerm = (search_term) => {
        let firstName = getFirstNameFromSearchString(search_term);
        let lastName = getLastNameFromSearchString(search_term);
        let searchTerm = firstName != ''? firstName + (lastName != '' ?' ':'')+ lastName : lastName == '' ? '' : lastName
        setDialogData((prevDialogData) => ({...prevDialogData, first_name: firstName, last_name: lastName, search_term: searchTerm}))
    }

    // This funtion is called when the user selects a person from the person lookup form
    // Update the person in rider list and entries list
    // Do not delete unused parameters as it is called people lookup form
    async function getSelectedPeople(newPeople, organization, person_role){
        // Update the selected row with new person data
        const oldRiderData = selectedRiderRow.data;
        const oldPeopleId = oldRiderData.people_id;
        const oldPeopleFeiId = oldRiderData.fei_id;

        if (oldPeopleId != newPeople.people_id) {
            const updatedRow = {
                ...selectedRiderRow.data,
                local_rider_feiId: newPeople.fei_id,    // TODO: Check if we need to update this or not
                local_rider_name: newPeople.fl_name,
                local_rider_state: newPeople.state,
                people_id: newPeople.people_id,
                user_mapped: true,
                is_mapped: true,
            };
    
            // Get the index of the selected row
            const rowIndex =  data.riderMappings.findIndex(r => r.fei_id == oldPeopleFeiId);
            data.riderMappings[rowIndex] = updatedRow;
            riderMappingGridRef.setRowData(data.riderMappings);

            // Update rider id and user mapped flag in entries mappings
            data.entriesMappings.forEach((item) => {

                if (item.rider_fei_id == oldPeopleFeiId) {
                    const [first, last] = newPeople.fl_name?newPeople.fl_name.split(' '):['', ''];

                    // Setting user mapped
                    item.user_mapped = true;
                    item.rider_id = updatedRow.people_id
                    item.rider_local_fei_id = updatedRow.fei_id
                    item.rider_fl_name = newPeople.fl_name
                    item.rider_lf_name = newPeople.lf_name
                    item.rider_first_name = first
                    item.rider_last_name = last 
                    item.entry_exists = false
                    item.local_entry = ''
                }
            })

            // TODO: Update entries again
            // Check if the rider is mapped to any horse
            if(newPeople.people_id > 0){
                let updated_entries = data.entriesMappings.filter(e => e.rider_fei_id == oldPeopleFeiId && e.horse_id > 0)
                let existing_entries = await getExistingEntries(updated_entries)
                if(existing_entries?.length > 0){
                    // Update horse id and user mapped flag in entries mappings
                    for (let index = 0; index<data.entriesMappings?.length; index++) {
                        let existing_entry = existing_entries.find(e => e.horse_id == data.entriesMappings[index].horse_id && e.rider_id == data.entriesMappings[index].rider_id)
                        if(existing_entry){
                            data.entriesMappings[index] = existing_entry
                        } 
                    }
                    
                }
            }

            // Update entries grid
            entriesGridRef?.setRowData(data.entriesMappings);
        }
    }

    //Function to return next negative ID for new people records
    // Do not delete unused parameters as it is called people lookup form
    const generateNewRTOId = (linked_people) => {
        // Generate a random number
        const randomPeopleId = -(Math.floor(Math.random() * 15000) + Date.now());
        return randomPeopleId;
    }

    // Add new people case
    // Do not delete unused parameters as it is called people lookup form
    const updatePeople = (newPeopleData, organization, person_role) => {
        const newPeople = newPeopleData.people_data;
        // Update the selected row with new person data
        const oldRiderData = selectedRiderRow.data;
        const oldPeopleId = oldRiderData.people_id;
        const oldPeopleFeiId = oldRiderData.fei_id;

        if (oldPeopleId != newPeople.people_id && newPeople.fl_name && newPeople.fl_name.trim() != '') {
            const updatedRow = {
                ...selectedRiderRow.data,
                local_rider_feiId: '',    // TODO: Check if we need to update this or not
                local_rider_name: newPeople.fl_name,
                local_rider_state: newPeople.state,
                user_mapped: true,
                people_id: newPeople.people_id,
                is_mapped: true,
            };
    
            // Get the index of the selected row
            const rowIndex =  data.riderMappings.findIndex(r => r.fei_id == oldPeopleFeiId);
            // const rowIndex = selectedRiderRow.rowIndex;
            data.riderMappings[rowIndex] = updatedRow;
            riderMappingGridRef.setRowData(data.riderMappings);

            // Update people id and user mapped flag in entries mappings
            data.entriesMappings.forEach((item) => {
                if (item.rider_fei_id == oldPeopleFeiId) {
                    item.rider_fl_name = newPeople.fl_name;
                    item.user_mapped = true;
                    item.rider_id = updatedRow.people_id;
                    item.rider_local_fei_id = ''; // Add empty for new people TODO: check in 4D
                    item.rider_fl_name = newPeople.fl_name
                    item.rider_lf_name = newPeople.lf_name
                    item.rider_first_name = newPeople.first
                    item.rider_last_name = newPeople.last_name
                    item.entry_exists = false
                    item.local_entry = ''
                }
            })

            // Update entries grid
            entriesGridRef?.setRowData(data.entriesMappings);
        }
    }

    // Filter AG Grid for horse and rider mappings
    const onFilterTextBoxChanged = useCallback((gridRef, value) => {
        gridRef.setQuickFilter(value);
    }, []);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px search-form'
            show={show}
            onHide={onClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    importEntries()
                }
            }}
        >
            {showHorseLookupForm && (
                <HorseLookupForm
                    show={showHorseLookupForm}
                    handleClose={() => setShowHorseLookupForm(false)}
                    customer_id={customerId}
                    horse_search_list={horseSearchList} // Search list (has initially current horse)
                    getSelectedHorse={getSelectedHorse} 
                    horse_search_status={horseCountStatus}
                    updateHorse={addNewHorse}
                    show_id={currentShowID}
                    default_org={'FEI'}
                />
            )}

            {showPersonLookupForm && (    
                <PersonLookupForm
                    show={showPersonLookupForm}
                    handleClose={() => setShowPersonLookupForm(false)}
                    customer_id={customerId}
                    // fieldName={'rider_search'}
                    dialogData={dialogData}
                    setDialogData={setDialogData}
                    person_search_list={personSearchList}
                    default_org={'FEI'}
                    person_search_status={personSearchCountStatus}
                    type={'RIDER'}
                    show_id={currentShowID}
                    handleSearchTerm={handleSearchTerm}
                    getSelectedPeople={getSelectedPeople}
                    generateNewRTOId={generateNewRTOId}
                    updatePeople={updatePeople}
                />
            )}

            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.MODAL.HEADING.FEISHOWEVENTS' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='col-12 mb-2'>
                    <div className='row'>
                        <div className='col-8'>
                            <div className='row'>
                                <div className='d-flex align-items-center'>
                                    <label className='fs-6 py-1 me-8' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.IMPORTENTRIES">{intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.LABEL.IMPORTENTRIES' })}</label>
                                    <div className='d-flex'>
                                        <div className='d-flex align-items-center me-3'>
                                            <input
                                                type="radio"
                                                name="mappingType"
                                                value="rider"
                                                checked={selectedArea === 'rider'}
                                                onChange={handleAreaChange}
                                                id='riderArea'
                                                autoFocus
                                                tabIndex={1}
                                            />
                                            <label htmlFor="riderArea" className='fs-6 py-1 ms-1' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.RIDERMAPPINGS">{intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.LABEL.RIDERMAPPINGS' })}</label>
                                        </div>
                                        <div className='d-flex align-items-center me-3'>
                                            <input
                                                type="radio"
                                                name="mappingType"
                                                value="horse"
                                                checked={selectedArea === 'horse'}
                                                onChange={handleAreaChange}
                                                id='horseArea'
                                                tabIndex={2}
                                            />
                                            <label htmlFor="horseArea" className='fs-6 py-1 ms-1' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.HORSEMAPPINGS">{intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.LABEL.HORSEMAPPINGS' })}</label>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <input
                                                type="radio"
                                                name="mappingType"
                                                value="entries"
                                                checked={selectedArea === 'entries'}
                                                onChange={handleAreaChange}
                                                id='entriesArea'
                                                tabIndex={3}
                                            />
                                            <label htmlFor="entriesArea" className='fs-6 py-1 ms-1' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.SELECTENTRIES">{intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.LABEL.SELECTENTRIES' })}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Rider Area */}
                            {selectedArea == 'rider' && (
                                <div className='row'>
                                        <div className='col-5'>
                                            <label className='fs-6 py-1 me-2' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.SEARCHFEIRIDER">{intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.LABEL.SEARCHFEIRIDER' })}</label>
                                        </div>
                                        <div className='col-7'>
                                            <input 
                                                type="text"
                                                onInput={(e) => onFilterTextBoxChanged(riderMappingGridRef, e.target.value)}
                                                tabIndex={4}
                                            />
                                        </div>
                                </div>
                            )}

                            {/* Horse Area */}
                            {selectedArea == 'horse' && (
                                <div className='row'>
                                    <div className='col-5'>
                                        <label className='fs-6 py-1 me-2' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.SEARCHFEIHORSE">{intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.LABEL.SEARCHFEIHORSE' })}</label>
                                    </div>
                                    <div className='col-7'>
                                        <input
                                            type='text'
                                            onInput={(e) => onFilterTextBoxChanged(horseMappingGridRef, e.target.value)}
                                            tabIndex={5}
                                        />
                                    </div>
                                </div>
                            )}

                            {/* Entries Area */}
                            {selectedArea == 'entries' && (
                                <>
                                    <div className='d-flex align-items-center'>
                                        <input
                                            type='checkbox'
                                            checked={selectAllChecked}
                                            onChange={handleSelectAllChange}
                                            id="selectAllCheckbox"
                                            tabIndex={6}
                                        />
                                        <label htmlFor="selectAllCheckbox" className='fs-6 py-1 ms-1' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.SELECTALLENTRIES">{intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.CHECKBOX.SELECTALLENTRIES' })}</label>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className='col-4'>
                            {selectedArea == 'rider' && (
                                <>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label className='fs-6 py-1 me-2' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.MAPPEDRIDERS">{intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.LABEL.MAPPEDRIDERS' })}{data.riderMappings?(data.riderMappings.filter(r => r.is_mapped).length):0}</label>
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            <label className='fs-6 py-1 me-2' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.NEWRIDERS">{intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.LABEL.NEWRIDERS' })}{data.riderMappings?(data.riderMappings.filter(r => !r.is_mapped).length):0}</label>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label className='fs-6 py-1 me-2' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.TOTALRIDERS"> {intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.LABEL.TOTALRIDERS' })}{data.riderMappings? data.riderMappings.length : 0}</label>
                                        </div>
                                    </div>
                                </>
                            )}

                            {selectedArea == 'horse' && (
                                <>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label className='fs-6 py-1 me-2' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.MAPPEDHORSES">{intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.LABEL.MAPPEDHORSES' })}{data.horseMappings?(data.horseMappings.filter(r => r.is_mapped).length):0}</label>
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            <label className='fs-6 py-1 me-2' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.NEWHORSES">{intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.LABEL.NEWHORSES' })}{data.horseMappings?(data.horseMappings.filter(r => !r.is_mapped).length):0}</label>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label className='fs-6 py-1 me-2' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.TOTALHORSES">{intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.LABEL.TOTALHORSES' })}{data.horseMappings? data.horseMappings.length : 0}</label>
                                        </div>
                                    </div>
                                </>
                            )}

                            {selectedArea == 'entries' && (
                                <>
                                    <div className='row'>
                                        <div className='col-12 d-flex flex-column align-items-end'>
                                            <label className='fs-6 py-1 me-2' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.TOTALENTRIESINFEIDB">{intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.LABEL.TOTALENTRIESINFEIDB' })}{data.entriesMappings? data.entriesMappings.length : 0}</label>
                                            <label className='fs-6 py-1 me-2' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.LABEL.TOTALENTRIESTOIMPORT">{intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.LABEL.TOTALENTRIESTOIMPORT' })}{entriesToImport}</label>
                                        </div>
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                </div>

                {/* Rider Area */}
                {selectedArea == 'rider' && (
                    <>
                        <div className='row mb-2'>
                            <div className='col-12'>
                                {/* Riders Grid */}
                                <OutputListingGridStatic
                                    key='import-rider-grid'
                                    columnDefs={riderMappingColDefs()}
                                    rowData={data.riderMappings}
                                    setGridRef={setRiderMappingGridRef}
                                    containerStyle={importEventStyle}
                                    headerHeight={30}
                                    rowHeight={27}
                                    rowClassRules={rowClassRules}
                                    tabIndex={7}
                                ></OutputListingGridStatic>
                            </div>
                        </div>

                        <div className='row mb-2'>
                            <div className='col-12 d-flex flex-end'>
                                <button type="button" onClick={handleClose} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' tabIndex={8} data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.BUTTON.CLOSE">
                                    {intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.BUTTON.CLOSE' })}
                                </button>
                                <button onClick={() => setSelectedArea('horse')} type="button" className='btn btn-sm btn-secondary fw-bold text-uppercase' tabIndex={9} data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.BUTTON.CONTINUE">
                                    {intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.BUTTON.CONTINUE' })}
                                </button>
                            </div>
                        </div>
                    </>
                )}

                {/* Horse Area */}
                {selectedArea == 'horse' && (
                    <>
                        <div className='row mb-2'>
                            <div className='col-12'>
                                {/* Horse Grid */}
                                <OutputListingGridStatic
                                    key='import-horse-grid'
                                    columnDefs={horseMappingColDefs()}
                                    rowData={data.horseMappings}
                                    setGridRef={setHorseMappingGridRef}
                                    containerStyle={importEventStyle}
                                    headerHeight={30}
                                    rowHeight={27}
                                    rowClassRules={rowClassRules}
                                    tabIndex={10}
                                ></OutputListingGridStatic>
                            </div>
                        </div>

                        <div className='row mb-2'>
                            <div className='col-12 d-flex flex-end'>
                                <button type="button" onClick={handleClose} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' tabIndex={11} data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.BUTTON.CLOSE">
                                    {intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.BUTTON.CLOSE' })}
                                </button>
                                <button onClick={() => setSelectedArea('entries')} type="button" className='btn btn-sm btn-secondary fw-bold text-uppercase' tabIndex={12} data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.BUTTON.CONTINUE">
                                    {intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.BUTTON.CONTINUE' })}
                                </button>
                            </div>
                        </div>
                    </>
                )}

                {/* Entries Area */}
                {selectedArea == 'entries' && (
                    <>
                        <div className='row mb-2'>
                            <div className='col-12'>
                                {/* Entries Grid */}
                                <OutputListingGridStatic
                                    key='import-horse-grid'
                                    columnDefs={entriesMappingColDefs()}
                                    rowData={data.entriesMappings}
                                    setGridRef={setEntriesGridRef}
                                    containerStyle={importEventStyle}
                                    headerHeight={30}
                                    rowHeight={27}
                                    rowClassRules={rowClassRules}
                                    tabIndex={13}
                                ></OutputListingGridStatic>
                            </div>
                        </div>

                        <div className='row mb-2'>

                                <div className='col-6'>
                                    {eventsImported && (

                                        <div className='text-danger'>
                                            <label className='fs-7 py-1 fw-bold'>Import Changes Pending. Click 'Save Show' to Apply Changes.</label>
                                        </div>
                                    )}
                                    {eventsImported && (

                                        <div className=''>
                                            <label className='fs-7 py-1 fw-bold'>{"Entries Imported: "+(methods.getValues('fei_imported_data.total_created_entries')?methods.getValues('fei_imported_data.total_created_entries'):0)+". Entries Updated: "+(methods.getValues('fei_imported_data.total_updated_entries')?methods.getValues('fei_imported_data.total_updated_entries'):0)+"."}</label>
                                        </div>
                                    )}
                                </div>
                            

                            <div className='col-6 d-flex flex-end'>
                                <button type="button" onClick={handleClose} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' tabIndex={14}>
                                    {intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.BUTTON.CLOSE' })}
                                </button>
                                <button onClick={() => importEntries()} type="button" className='btn btn-sm btn-secondary fw-bold text-uppercase' tabIndex={15} data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWEVENTS.BUTTON.IMPORTENTRIES">
                                    {intl.formatMessage({ id: 'SHOWS.IMPORTFEIENTRIES.MODAL.BUTTON.IMPORTENTRIES' })}
                                </button>
                            </div>
                        </div>
                    </>
                )}

            </div>
        </Modal>,
        modalsRoot
    )
}

export default ImportFEIEntries;