import { useContext } from "react"
import { createContext } from "react"
import { useSearchParams } from "react-router-dom"
import useStorage from "../hooks/use-storage"

const SGLContext = createContext()

// Use this context to keep global states in one place and avoid props drilling.
export const SGLContextProvider = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { localStorageChanged } = useStorage()

    return (
        <SGLContext.Provider value={{searchParams, setSearchParams, localStorageChanged }}>
            { children }
        </SGLContext.Provider>
    )
}
export const useSGLContext = () => {
    return useContext(SGLContext)
}
