/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import DateRenderer from '../../modules/output-listing/renderers/DateRenderer';
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks';

const CircuitsList = () => {
  const intl = useIntl();
  const customer_id = useAppSelector(state=> state.showCompany.company_id);

  const navigate = useNavigate ()

  const columnDefs = [
    // { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.COMMONS.SGLID'}), filter: 'agNumberColumnFilter', filterParams: { suppressAndOrCondition: true,}, },
    { field: 'circuit_id', headerName: intl.formatMessage({id: 'LIST.CIRCUITS.CIRCUITID'}),filter: 'agNumberColumnFilter',hide:true },
    { field: 'name', width: 400, headerName: intl.formatMessage({id: 'LIST.CIRCUITS.NAME'}), filter: 'agTextColumnFilter', },  
    { field: 'start_date', headerName: intl.formatMessage({id: 'LIST.CIRCUITS.STARTDATE'}), filter: 'agDateColumnFilter', cellRenderer: DateRenderer, cellStyle: {textAlign: 'center'},  },
    { field: 'end_date', headerName: intl.formatMessage({id: 'LIST.CIRCUITS.ENDDATE'}), filter: 'agDateColumnFilter', cellRenderer: DateRenderer,cellStyle: {textAlign: 'center'},},
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CIRCUITS'})}</PageTitle>
      <OutputListingComponent key={customer_id} area={'Circuits'} columnDefs={columnDefs} apiEndPoint={'circuits/list'}
        onRowDoubleClicked = { (row_data) => { navigate ('/circuits/detail/' + row_data.circuit_id +`?customer_id=${customer_id}`)}}
        onNewTabClick = { (row_data) => window.open(process.env.PUBLIC_URL+ '/circuits/detail/' + row_data.circuit_id +`?customer_id=${customer_id}`, '_blank')}
      ></OutputListingComponent>
    </>
  )
}

export {CircuitsList}
