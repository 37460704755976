import React, {useState, useEffect} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider'
import { useOutputContext, useOutputContextUpdater} from '../../../modules/output-listing/OutputListingContext'
import axios from 'axios';
import { useAppSelector } from '../../../redux/hooks'

const modalsRoot = document.getElementById('root-modals') || document.body
 
const AssignEntryNumbers = ({show, handleClose}) => {
    const intl = useIntl() 
    const loadingOverlay = useLoadingOverlay()
    const alertDialog = useAlert()
    const {progressBar} = useProgress()
    const {getOutputSelectionAreaIDsForSortedGrid, getEventID} = useOutputContext()
    const outputContextUpdater = useOutputContextUpdater()
    const confirmDialog = useConfirm()
    const [entryNumber, setEntryNumber] = useState('')
    const [entryIds, setEntryIds] = useState([])
    const customerId = useAppSelector(state => state.showCompany.company_id);
    const [showModal, setShowModal] = useState(false)
    const customerID = useAppSelector(state=> state.showCompany.company_id);
    
    const onClose = () => {
        setShowModal(false)
        handleClose()
    }

    const handleChange = (e) => {
        setEntryNumber(e.target.value)
    }

    const isNumber = (number) => {
        return !isNaN(parseInt(number, 10)) &&  number === parseInt(number, 10).toString()
    }

    const handleSave = async() =>{
        if(entryNumber.length > 0 && isNumber(entryNumber))//allow only numbers
        {
            onClose()//close the dialog
            loadingOverlay({ show: true })
            axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/validateEntryNumber', {//Validate the entered entry number
                params: {
                    number: entryNumber,
                    entry_id: entryIds[0],
                    customer_id: customerID,
                    check_results: false
                }
            }).then( async (response) => {
                if(!response.data.success) {//Display error message if entry number is not valid
                    alertDialog({ message: response.data.entryNumberError })
                }else{//If entry number is valid than assign the entry number to array of entries
                    let eventID = getEventID("assign-entry-number-tool")
                    // show progress bar
                    progressBar({ show: true, eventID, showProgress: 'inline', title: intl.formatMessage({ id: "ENTRIES.QUICKACTION.ASSIGNENTRYNUMBER.PROGRESSBAR.MESSAGE" }), timeElapsed: true })
                    await axios.patch( process.env.REACT_APP_NEST_API_URL + "/entries/assignEntryNumber", {
                        customer_id: customerId,
                        entry_ids: entryIds,
                        entry_number: entryNumber,
                        event_id: eventID
                    })
                    .then(response => {

                        if(!response.data.success) {
                            alertDialog({ message: response.data.entryNumberError })
                        }else{
                            // Alert user about updated entry number status
                            alertDialog({ message: response.data.message, icon: "info"})
                            //refresh list
                            outputContextUpdater({action: 'refresh'})
                        }          
                    })
                    .catch((error) => {
                        if (error.response) {
                            alertDialog({message: error.response.data.error});
                        }
                    }).finally(() => {
                        // close progress bar
                        progressBar( {show: false, eventID} )
                    })
                }
            }).finally(() => {
                loadingOverlay({ show: false })
            })
        }else{
            alertDialog({ message: intl.formatMessage({ id: "ENTERIES.QUICKACTION.ASSIGNENTRYNUMBERS.MODAL.INPUT.ERROR"}) })
        }
    }

    useEffect(() => {
        if (show) {
            loadingOverlay({ show: true })
            getOutputSelectionAreaIDsForSortedGrid(false)
            .then(res => { 
                if (res.length === 0) {
                    loadingOverlay({ show: false })
                    // error message if user did not select any entry
                    alertDialog({ message: intl.formatMessage({ id: "ENRIES.QUICKACTION.ASSIGNENTRYNUMBERS.EMPTYERROR"}) })
                    handleClose()
                }
                else{
                    // if user selected any entries
                    setEntryIds(res)
                    loadingOverlay({ show: false })
                    // ask user for confirmation
                    confirmDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.ASSIGNENTRYNUMBERS.MODAL.CONFIRM.MESSAGE1"}) +" "+ res.length +" " + intl.formatMessage({ id: "ENTRIES.QUICKACTION.ASSIGNENTRYNUMBERS.MODAL.CONFIRM.MESSAGE2"})})
                    .then((choice) => {
                        if(!choice){
                            // close the modal if choice is false means user selected no
                            handleClose()
                        }
                        else{
                            // user confirmation by selecting yes
                            setShowModal(true)
                            setEntryNumber('')
                        }
                    })
                }
            })
            .catch(() => loadingOverlay({ show: false }))
        }  
    }, [show])

    return createPortal(
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
                show={showModal}
                onHide={onClose}
                onKeyDown={(event) => {
                    let activeElement = document.activeElement
                    let isButtonFocused = false
                    if (activeElement && activeElement.tagName === 'BUTTON') {
                        isButtonFocused = true
                    }
                    if (event.key === "Enter" && !isButtonFocused) {
                        handleSave()
                    }
                }}
            >
                <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
                <div className='modal-header py-0 px-4'>
                    <h2 className="fs-4">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.HEADING.ASSIGNENTRYNUMBER' })}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
    
                <div className='modal-body py-3 px-4'>
                    <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                        <div className='card-body p-0'>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' htmlFor='ENTRY_NUMBER'>{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.ASSIGNENTRYNUMBERS.MODAL.FORM.LABEL.ENTERENTRYNUMBER' })}</label>
                                <div className='col-lg-7'>
                                    <input
                                        name="entry_number"
                                        value={entryNumber}
                                        onChange={handleChange}
                                        id='ENTRY_NUMBER'
                                        type='number'
                                        inputmode="numeric"
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'ENTERIES.QUICKACTION.ASSIGNENTRYNUMBERS.MODAL.INPUT.PLACEHOLDER' })}
                                        autoFocus
                                        tabIndex={1}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='card-footer d-flex justify-content-end py-3 px-0'>
                            <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={2}>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CANCEL' })}
                            </button>

                            <button type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={() => handleSave()} tabIndex={3}>
                                <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.SAVE' })}
                            </button>
                        </div>
                    </form>
                </div>
      </Modal>
      ,modalsRoot
    )
}

export default AssignEntryNumbers