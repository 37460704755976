import { useMemo, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import { USEFLookupMismatchModal } from './USEFLookupMismatchModal';
// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';
import { getExtraNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers';

import { useAlert } from '../../../../modules/sgl-utils/DialogsProvider';
import { isValidJson } from '../../../../modules/sgl-utils/SglFunctions';
import HideJsonRenderer from '../../../../modules/output-listing/renderers/HideJsonRenderer';

const USEFMembershipTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext()
    const [gridRef, setGridRef] = useState()
    const [rowData, setRowData] = useState()
    const alertDialog = useAlert()

    const columnDefs = [
        { field: 'key', flex:1, maxWidth: 245, minWidth:245, lockVisible: false, lockPosition: 'left', resizable: true, cellClass: 'text-start', cellStyle: {'border-right': '1px solid #eee'}
            , cellRenderer: 'agGroupCellRenderer',
            cellRendererSelector: function (params) {
                if (params.data.value != null && Array.isArray(params.data.value) && params.data.value?.length > 0) {
                    params.node.setExpanded(true);
                }
            }
        },
        { field: 'value', flex:1, lockVisible: false, lockPosition: 'left', resizable: true, cellClass: 'text-start', cellRenderer: HideJsonRenderer },
    ]

    useEffect(() => {   
        if(gridRef){
            if(methods.getValues('people.usef_response_eti_lookup') && isValidJson(methods.getValues('people.usef_response_eti_lookup'))){
                parseUSEFData(JSON.parse(methods.getValues('people.usef_response_eti_lookup')))
            }
            else{
                gridRef.setRowData([])
            }
        }
    }, [gridRef, methods.watch('people.usef_response_eti_lookup')])

    const parseUSEFData = (lookup) => {
        if (lookup){
            var usef_lookup_entries = Object.entries(lookup)
            let usef_data = []
            usef_lookup_entries.map(([key, value]) => {
                // if(value != null && Array.isArray(value)){
                //     value.map((data) => {
                //         let lookup = Object.entries(data)
                //         lookup.map(([key, value]) => {
                //             usef_data.push({'key': key, "value": value})
                //         })
                //     })
                // }
                // else{
                    usef_data.push({'key':key, "value":value})
                // }
            })
            gridRef.setRowData(usef_data)
        }
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '96vh' }), []);

    const lookup_data = () => { 
        let memObj = {}, org = "USEF";
        props.membershipGridState.forEachNode(node => {
          if(org == node.data.organization_code)
          memObj = node.data
        });
        if(!isNaN(memObj.membership_number) && memObj.membership_number > 0){
            props.getLookupData(org, memObj, 'usefLookup')
        } else {
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.TAB.ERROR.USEF.INVALID' })})
        }
    }

    const view_card = () => { 
        let memObj = {}, org = "USEF";
        props.membershipGridState.forEachNode(node => {
          if(org == node.data.organization_code)
          memObj = node.data
        });
        if(!isNaN(memObj.membership_number) && memObj.membership_number > 0){
            window.open(process.env.REACT_APP_USEF_CARDS_URL+'?id='+memObj.membership_number, "_blank")
        } else {
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.TAB.ERROR.USEF.INVALID' })})
        }
    }
    
    return (
        <>
            <div className='form-group'>
                <div className='row align-items-center mb-2 membership-data-grid'>
                    {props.showUsefMismatchForm && <USEFLookupMismatchModal show={props.showUsefMismatchForm} handleClose={() => props.setShowUsefMismatchForm(false)} usef_lookup_field={methods.getValues('usef_lookup_field')} people={'people'}/>}
                    <OutputListingGridStatic area ={"People - USEF Membership"} setGridRef={setGridRef} columnDefs={columnDefs} rowData={rowData?rowData:[]} containerStyle={containerStyle}  rowHeight={getExtraNarrowRowHeight} suppressRowClickSelection={true} masterDetail={true} keepDetailRows={true} keepDetailRowsCount={2} detailRowAutoHeight={true}></OutputListingGridStatic>
                </div>
                <div className='row align-items-center mb-2'>
                    <div className='col-auto'>
                        <button type="button"  onClick={() => (view_card())} className="btn btn-sm btn-secondary h-30px py-1 fw-bold text-uppercase">
                            {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.VIEWCARD' })}
                            </button>
                        </div>
                        <div className='col-lg-2 w-175px'>
                            <button type="button" onClick={() => (lookup_data())} className="btn btn-sm btn-secondary h-30px py-1  fw-bold text-uppercase">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.UPDATE' })}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export { USEFMembershipTab }