
import {useEffect, useState} from 'react'
import axios from 'axios';
import { useAppSelector } from '../../redux/hooks';
import { useSyncUpdateContext } from '../../modules/output-listing/synchronizeUpdateContext';
import ViewUserDialog from './ViewUserDialog';
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import { getUserName } from '../../modules/sgl-utils/SglFunctions'
import {useIntl} from 'react-intl'

const ActiveUsersAllCompaniesWidget = (props) => {
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const { sse, removeAllEventListeners, addSubscribedEvents} = useSyncUpdateContext()
    const [showUsersDialog, setShowUsersDialog] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const intl = useIntl()

    const fetchUpdatedUsers = async () => {
        axios.get(process.env.REACT_APP_NEST_API_URL + '/utility/dashboard/getActiveUsersAllCompanies' , {
            params: {
                customer_id: customerId
            }
        }).then(response => {
            setLoggedInUser(response.data)
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (customerId) {
            setIsLoading(true);
            fetchUpdatedUsers()
        } 
    }, [customerId]);

	useEffect(() => {
		if(sse && customerId){
			removeAllEventListeners()
			let syncEvents = [{eventId: `Users-${customerId}`, callback: () => fetchUpdatedUsers()}]
			addSubscribedEvents(syncEvents)
		}
		
	}, [customerId, sse]);

    return (
      <>
        {/* <ViewUserDialog show={showUsersDialog} handleClose={() => setShowUsersDialog(false)} users={loggedInUser} /> */}
        <div className="active-users card card-flush mb-0">
            {isLoading &&
                <>
                    <br/>
                    <span className="spinner-border spinner-border-sm m-5" role="status" aria-hidden="true"></span>
                </>
            }

            {!isLoading &&
            <>
            <div className="card-body">	
                <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">{intl.formatMessage({id: 'MENU.DASHBOARD.SEEUSERS'})}</span>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <tbody>
                        {
                            loggedInUser && loggedInUser.map((companies, index) => {
                                return <tr>
                                    <td className='text-dark fw-bold fs-3 col-4'>
                                        {companies.name}
                                    </td>
                                    <td>
                                        {
                                            companies.active_uses.map( (user , index) => {
                                                const moment = require('moment')
                                                const formattedTime = user.lastActivityTimestamp > 0 ? moment(user.lastActivityTimestamp * 1000).format("HH:mm A") : null;
                                                return (
                                                    <OverlayTrigger
                                                    key={index} 
                                                    placement="top"
                                                    overlay={<BootstrapTooltip>{getUserName(user)}<span className='d-block text-muted fw-semibold'><span>Last Seen:</span> {formattedTime ?? ''}</span></BootstrapTooltip>}
                                                    >
                                                        <div onClick={() => setShowUsersDialog(true)} className="symbol symbol-35px symbol-circle" title={getUserName(user)} data-bs-toggle="tooltip">
                                                            {
                                                            user.profile_photo_thumbnail?	
                                                            <img src={user.profile_photo_thumbnail} alt="image"/>:
                                                            <span className={`symbol-label text-inverse-warning fw-bold symbol-${index}`}>{user.first[0]}{user.last_name[0]}</span>
                                                            }
                                                        </div>
                                                    </OverlayTrigger>
                                                )
                                            })
                                        }
                                    </td>
                                </tr>
                            })
                        }
                            </tbody>
                        </table>
                    </div>
                {/* <!--end::Users group--> */}
            </div>
            </>
            }
            {/* <!--end::Card body--> */}
        </div>
      </>  
    );
}
export {ActiveUsersAllCompaniesWidget}