import React, {FC, useState} from 'react'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {useIntl} from 'react-intl'

const Toolbar5: FC = () => {
  const intl = useIntl()
  const {classes} = useLayout()

  return (
    <>
      <div className='toolbar' id='kt_toolbar'>
        {/* begin::Container */}
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <DefaultTitle />

          {/* begin::Actions */}
          {/* begin::Actions */}
        </div>
        {/* end::Container */}
      </div>
    </>
  )
}

export {Toolbar5}
