import React from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
// import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title='Shows' to='/dashboard' />
      {/* ERRORS */}
      <MenuInnerWithSub
        title='Errors'
        to='/error'
        fontIcon='bi-sticky'
        hasArrow={false}
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </MenuInnerWithSub>
    </>
  )
}
