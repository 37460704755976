/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import site_logo from '../../../_metronic/assets/CustomImages/logo.png'
import ResetPassword from './components/ResetPassword'
import { useIntl } from 'react-intl'

const AuthLayout = () => {
	const intl = useIntl()

  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundColor:"#fafafa"
        //backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
  	{/* begin::Content */}
      <div className='d-flex p-5 pb-lg-10'>
      {/* begin::Staging badge */}
      {process.env.REACT_APP_PRODUCTION != "true" ?
        <div className='d-flex align-items-center justify-content-center bg-success w-100 p-2'>
          <h1 className='text-white'>{intl.formatMessage({ id: 'STAGINGSITE' })}</h1>
        </div>
        : null}
      {/* end::Staging badge */}
      </div>
      {/* end::Content */}
  
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-10'>
        {/* begin::Logo */}
        <a href='#' className='mb-6'>
          <img alt='Logo' src={site_logo} className='h-55px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password/:token' element={<ResetPassword/>} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage, AuthLayout}
