import {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useFormContext, Controller} from 'react-hook-form'
import { useAlert } from '../../../modules/sgl-utils/DialogsProvider'
import Select from 'react-select'
import {
  reactSelectStyles,
} from '../../../modules/sgl-utils/fieldControls'
import { NumericFormat } from 'react-number-format';
import CurrencyRenderer from '../../../modules/output-listing/renderers/CurrencyRenderer';
import NumberRenderer from '../../../modules/output-listing/renderers/NumberRenderer';
// Custom Components
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic'
// Assets
import './tab.css'
import { AddFeeToShows } from './AddFeeToShows'
import axios from 'axios'

const GeneralTab = (props) => {
  const intl = useIntl()
  const methods = useFormContext()
  const watchdirectuserordering = methods.watch("master_fee.directuserordering");
  const watchweb_default_qty = methods.watch("master_fee.web_default_qty");
  const currency = intl.formatMessage({ id: 'CURRENCY' })
  const MAX_VAL = 9999;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };
  const columnDefs = [
    { field: 'show_name', headerName: intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.SHOW'}), width:500 },
    { field: 'rule', headerName: intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.CLASSRULE'}), },
    { field: 'sf_description', headerName: intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.RELATEDFEE'}), width: 300},
    { field: 'unit_price', headerName: intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.PRICE'}), cellRendererSelector: (params) => {return {component: CurrencyRenderer,params: {currency: currency,},}}, cellClass: 'ag-center-aligned-cell'},
    { field: 'mf_cost', headerName: intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.COST'}), cellRendererSelector: (params) => {return {component: CurrencyRenderer,params: {currency: currency,},}}, cellClass: 'ag-center-aligned-cell'},
    { field: 'sold_to_date', headerName: intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.QTYSOLD'}), cellClass: 'ag-center-aligned-cell', cellRenderer: NumberRenderer },
  ]
  const [rowData, setRowData] = useState(methods.getValues('fee_assigned'))
  const containerStyle = useMemo(() => ({width: '100%', height: '300px'}), [])
  const [addFeeToShows, setAddFeeToShows] = useState(false) // Show/hide the AddFeeToShow Dialog
  const [gridRef, setGridRef] = useState(false)
  const alertDialog = useAlert()
  const [fees_deleted, setFeesDeleted] = useState([])

  const handleAddFeeToShows = () => {
    setAddFeeToShows(true) //display AddFeeToShow Dialog
  }

  useEffect(() => {
    // When name, default_price or cost is updated, update the newly added show fee as they will store the master fee data
    let updatedFeeAssigned = []
    let previousFees = methods.getValues('fee_assigned') ?? []
    let showsToAddMasterFee = methods.getValues('showsToAddMasterFee') ?? []
    if(showsToAddMasterFee?.length > 0){
        updatedFeeAssigned = previousFees.map((fee) => {
            if(showsToAddMasterFee.includes(fee.show_id)){
                fee.sf_description = methods.getValues('master_fee.name')
                fee.unit_price = parseFloat(methods.getValues('master_fee.default_price'))
                fee.mf_cost = parseFloat(methods.getValues('master_fee.cost'))
            }
            return fee
        })
        methods.setValue('fee_assigned', updatedFeeAssigned)
        setRowData(updatedFeeAssigned)
        gridRef?.setRowData(updatedFeeAssigned)
    }

  }, [methods.watch('master_fee.name'), methods.watch('master_fee.default_price'), methods.watch('master_fee.cost')])

  useEffect(() => {
    setRowData(methods.watch('fee_assigned'))
    if(gridRef){ gridRef?.setRowData(methods.watch('fee_assigned')) }
    
  }, [methods.watch('fee_assigned')])

  const handleRemoveFeeFromShows = async () => {
    if (gridRef) {
      let selected_fee = gridRef.getSelectedRows();
      if (selected_fee.length > 0) {
        const { sf_showfees_id, sf_customer_id } = selected_fee[0];
        if(sf_showfees_id !== undefined && sf_customer_id !== undefined){
          axios
          .get(process.env.REACT_APP_NEST_API_URL + '/fees/validateShowFeesDeletion', {
              params: {
                showfees_id: sf_showfees_id,
                customer_id: sf_customer_id,
              },
            })
            .then((response) => {
              const { success, error_messages } = response.data;
              if (success) {
                let previousFees = methods.getValues('fee_assigned');
                if (previousFees) {
                    previousFees = previousFees.filter(item => item.show_id !== selected_fee[0]?.show_id);
                    methods.setValue('fee_assigned', previousFees)
                }
                // Add the selected row to fees_deleted
                setFeesDeleted([...fees_deleted, selected_fee[0]]);
                gridRef.applyTransaction({ remove: [selected_fee[0]] });
                setRowDataFromGrid()
              } else {
                // Validation errors occurred, display an alert
                alertDialog({message: error_messages.join("<br/>"), title: 'Error'})
              }
            })
        }else{
          // remove deleted fee from if already added
          let newAdded = methods.getValues('showsToAddMasterFee');
          if (newAdded) {
              newAdded = newAdded.filter(item => item.show_id !== selected_fee[0]?.show_id);
              methods.setValue('showsToAddMasterFee', newAdded, {shouldDirty: true})
          }

          let previousFees = methods.getValues('fee_assigned');
          if (previousFees) {
              previousFees = previousFees.filter(item => item.show_id !== selected_fee[0]?.show_id);
              methods.setValue('fee_assigned', previousFees)
          }
          gridRef.applyTransaction({ remove: [selected_fee[0]] })
          setRowDataFromGrid()
        }
      }
    }
  }

  const setRowDataFromGrid = () => {
    let newRowData = []
    gridRef.forEachNode(node => newRowData.push({...node.data, order: node.rowIndex +1}));
    setRowData(newRowData)
  }

  useEffect(() => {
    if(fees_deleted.length > 0){
      methods.setValue('fees_deleted', fees_deleted, {shouldDirty:true})
    }
  }, [fees_deleted])

  return (
    <>
      { addFeeToShows && <AddFeeToShows show={addFeeToShows} handleClose={setAddFeeToShows} showsMissingCurrentMasterFee={props.form_meta.showsMissingMasterFee} rule={props.form_meta.rule}/>}
      <div className='form-group mb-2 masterfee-tab'>
        <div className='row mb-2'>
          <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-205px' htmlFor='NAME'>
            {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.NAME'})}
          </label>
          <div className='col-lg-6'>
            <input
              {...methods.register('master_fee.name', {
                  required: "Master Fee name is required.",
              })}
              id='NAME'
              type='text'
              className='form-control form-control-sm  fs-6 min-h-20px py-1'
              // placeholder={intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.NAME' })}
            />
            { methods.formState.errors?.master_fee?.name && <div><span className='error_message'>{methods.formState.errors.master_fee.name?.message}</span></div>}
          </div>
          <div className='col'>
            <div className='row'>
              <label className='col-lg-2 col-form-label fs-5 py-1'></label>
              <div className='col-lg-9 form-check-sm form-check-custom ps-lg-3'>
                <input
                  {...methods.register('master_fee.active', {
                    required: false,
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='ACTIVE'
                />
                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='ACTIVE'>
                  {' '}
                  {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.ACTIVE'})}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-205px' htmlFor='DESCRIPTION'>
            {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.DESCRIPTION'})}
          </label>
          <div className='col-lg-6'>
            <input
              {...methods.register('master_fee.description', {
                required: false,
              })}
              id='DESCRIPTION'
              type='text'
              className='form-control form-control-sm  fs-6 min-h-20px py-1'
              // placeholder={intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.DESCRIPTION' })}
            />
          </div>
          <div className='col'>
            <div className='row'>
              <label className='col-lg-2 col-form-label fs-5 py-1 text-end' htmlFor='ABBR'>
                {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.ABBR'})}
              </label>
              <div className='col-lg-4'>
                <input
                  // TS will confirm this 
                  // {...methods.getValues('fee_assigned.sf_master_fee_id') > 0 && 
                  //   methods.getValues('fee_assigned.masterfees_changeenabled') ? 
                  //   {...methods.register('master_fee.abbreviation', {
                  //     required: false,
                  //   })} : 
                  //   {...methods.register('fee_assigned[0].sf_abbreviation', {
                  //     required: false,
                  //   })}
                  // }
                  {...methods.register('master_fee.abbreviation', {
                    required: false,
                  })}
                  id='ABBR'
                  type='text'
                  className='form-control form-control-sm  fs-6 min-h-20px py-1'
                  // placeholder={intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.ABBR' })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <label
            className='col-lg-1 col-form-label fs-5 py-1 w-lg-205px'
            htmlFor='FEEDDELIVERYEMAIL'
          >
            {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.FEEDDELIVERYEMAIL'})}
          </label>
          <div className='col-lg-6'>
              <input
                {...methods.register( 'master_fee.feed_delivery_email', 
                    {
                        required: false,
                        pattern:{
                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                            message: "Please enter a valid email"
                        }  
                    }
                  )
                }
                id='FEEDDELIVERYEMAIL'
                type='text'
                className='form-control form-control-sm  fs-6 min-h-20px py-1'
              />
              { methods.formState.errors?.master_fee?.feed_delivery_email && <div><span className='error_message'>{methods.formState.errors.master_fee.feed_delivery_email?.message}</span></div>}
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-205px' htmlFor='CATEGORY'>
            {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.CATEGORY'})}
          </label>
          <div className='col-lg-6'>
            <div className='row justify-content-between'>
              <div className='col-lg-5'>
                {props.form_meta.fee_category && (
                  <Controller
                    name='master_fee.category'
                    render={({field: {onChange, value, name}}) => (
                      <Select
                        options={props.form_meta.fee_category}
                        value={props.form_meta.fee_category.find((ss) => ss.value === value)}
                        id='category'
                        name={name}
                        isSearchable={true}
                        onChange={(category) => {
                          onChange(category.value)
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                        })}
                        styles={reactSelectStyles}
                        placeholder='Select'
                      />
                    )}
                  />
                )}
              </div>
              <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>
                <input
                  {...methods.register('master_fee.physical_delivery', {
                    required: false,
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='PHYSICALDELIVERY'
                />
                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='PHYSICALDELIVERY'>
                  {' '}
                  {intl.formatMessage({
                    id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.PHYSICALDELIVERY',
                  })}
                </label>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='row'>
              <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='DONATION'></label>
              <div className='col-lg-9 form-check-sm form-check-custom ps-lg-3'>
                <input
                  {...methods.register('master_fee.donation', {
                    required: false,
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='DONATION'
                />
                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='DONATION'>
                  {' '}
                  {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.DONATION'})}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-205px' htmlFor='DEFAULTPRICE'>
            {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.DEFAULTPRICE'})}
          </label>
          <div className='col-lg-6'>
            <div className='row justify-content-between'>
              <div className='col-lg-5'>
                 <Controller
                    control={methods.control}
                    name="master_fee.default_price"
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        id='DEFAULTPRICE'
                        prefix={'$'}
                        allowNegative={false}
                        value={value !== '' ? Number(value): ""}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        onValueChange={(e) => {
                          // Only update the value if it's changed by the user
                          if (Number(e.value) !== Number(value)) {
                            onChange(e.value);
                          }
                        }}
                        onFocus={(e) => {
                          e.target.select(); // Highlight the value
                        }}
                        className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                      />
                    )}
                 />
              </div>
              <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>
                <input
                  {...methods.register('master_fee.stablefee', {
                    required: false,
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='STABLEFEE'
                />
                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='STABLEFEE'>
                  {' '}
                  {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.STABLEFEE'})}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-205px' htmlFor='TAXRATE'>
            {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.TAXRATE'})}
          </label>
          <div className='col-lg-6'>
            <div className='row justify-content-between'>
              <div className='col-lg-5'>
                {props.form_meta.tax_rate && (
                  <Controller
                    name='master_fee.tax_rate_id'
                    render={({field: {onChange, value, name}}) => (
                      <Select
                        options={props.form_meta.tax_rate}
                        value={
                          methods.getValues('master_fee.tax_rate_id') === 0
                            ? props.form_meta.tax_rate.filter(
                                (option) => option.label === 'Non Taxable'
                              )
                            : props.form_meta.tax_rate.find((ss) => ss.value === value)
                        }
                        id='tax_rate'
                        name={name}
                        isSearchable={true}
                        onChange={(tax_rate) => {
                          onChange(tax_rate.value)
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                        })}
                        styles={reactSelectStyles}
                        placeholder='Select'
                      />
                    )}
                  />
                )}
              </div>
              <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>
                <input
                  {...methods.register('master_fee.appearonstatement', {
                    required: false,
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='APPEARONENTRYBLANK'
                />
                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='APPEARONENTRYBLANK'>
                  {' '}
                  {intl.formatMessage({
                    id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.APPEARONENTRYBLANK',
                  })}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-205px' htmlFor='DEFAULTQTY'>
            {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.DEFAULTQTY'})}
          </label>
          <div className='col-lg-6'>
            <div className='row justify-content-between'>
              <div className='col-lg-5'>
                 <Controller
                    control={methods.control}
                    name="master_fee.default_qty"
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        id='DEFAULTQTY'
                        value={Number(value)}
                        allowNegative={false}
                        onValueChange={(e) => {
                          onChange(e.value)
                        }}
                        isAllowed={withValueCap}
                        thousandSeparator=","
                        className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                      />
                    )}
                 />
              </div>
              <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>
                <input
                  {...methods.register('master_fee.feed_bedding_fee', {
                    required: false,
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='FEEDBREEDINGITEM'
                />
                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='FEEDBREEDINGITEM'>
                  {' '}
                  {intl.formatMessage({
                    id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.FEEDBEDDINGITEM',
                  })}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-205px' htmlFor='DEFAULTWEBQTY'>
            {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.DEFAULTWEBQTY'})}
          </label>
          <div className='col-lg-6'>
            <div className='row justify-content-between'>
              <div className='col-lg-5'>
                  <Controller
                    control={methods.control}
                    name="master_fee.web_default_qty"
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        id='DEFAULTWEBQTY'
                        value={Number(value)}
                        allowNegative={false}
                        onValueChange={(e) => {
                          onChange(e.value)
                        }}
                        isAllowed={withValueCap}
                        thousandSeparator=","
                        className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                        disabled = {watchdirectuserordering ? true : false}
                      />
                    )}
                 />
              </div>
              <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>
                <input
                  {...methods.register('master_fee.directuserordering', {
                    
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='AVAILABLEFORWEBORDERING'
                  disabled = {watchweb_default_qty > 0 ? true : false}
                />
                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='AVAILABLEFORWEBORDERING'>
                  {' '}
                  {intl.formatMessage({
                    id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.AVAILABLEFORWEBORDERING',
                  })}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <label
            className='col-lg-2 col-form-label fs-5 py-1 w-lg-205px'
            htmlFor='DEFAULTSHOWINVENTORYCAP'
          >
            {intl.formatMessage({
              id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.DEFAULTSHOWINVENTORYCAP',
            })}
          </label>
          <div className='col-lg-6'>
            <div className='row justify-content-between'>
              <div className='col-lg-5'>
                  <Controller
                    control={methods.control}
                    name="master_fee.default_show_inventory_cap"
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        id='DEFAULTSHOWINVENTORYCAP'
                        value={Number(value)}
                        allowNegative={false}
                        onValueChange={(e) => {
                          onChange(e.value)
                        }}
                        thousandSeparator=","
                        isAllowed={withValueCap}
                        className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                      />
                    )}
                 />
              </div>
              <div className='col-lg-1'></div>
              <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>
                <input
                  {...methods.register('master_fee.web_deposit_required', {
                    required: false,
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='WEBDEPOSITREQUIRED'
                />
                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='WEBDEPOSITREQUIRED'>
                  {' '}
                  {intl.formatMessage({
                    id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.WEBDEPOSITREQUIRED',
                  })}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-205px' htmlFor='COST'>
            {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.COST'})}
          </label>
          <div className='col-lg-6'>
            <div className='row justify-content-between'>
              <div className='col-lg-5'>
                 <Controller
                    control={methods.control}
                    name="master_fee.cost"
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        id='COST'
                        prefix={'$'}
                        value={value !== '' ? Number(value): ""}
                        allowNegative={false}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        onValueChange={(e) => {
                          onChange(e.value)
                        }}
                        onFocus={(e) => {
                          e.target.select(); // Highlight the value
                        }}
                        className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                      />
                    )}
                 />
              </div>
              <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>
                <input
                  {...methods.register('master_fee.dont_delete_forscratchentry', {
                    required: false,
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='DONTDELETEFORSCRATCHENTRY'
                />
                <label
                  className='col-form-label mx-2 fs-5 py-1'
                  htmlFor='DONTDELETEFORSCRATCHENTRY'
                >
                  {' '}
                  {intl.formatMessage({
                    id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.DONTDELETEFORSCRATCHENTRY',
                  })}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-205px'></label>
          <div className='col-lg-6'>
            <div className='row justify-content-between'>
              <div className='col-lg-5'></div>
              <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>
                <input
                  {...methods.register('master_fee.pass_through', {
                    required: false,
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='PASSTHROUGHT'
                />
                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='PASSTHROUGHT'>
                  {' '}
                  {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.PASSTHROUGHT'})}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-205px'></label>
          <div className='col-lg-6'>
            <div className='row justify-content-between'>
              <div className='col-lg-5'></div>
              <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>
                <input
                  {...methods.register('master_fee.card_fee', {
                    required: false,
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='CHANGECARDTOOL'
                />
                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='CHANGECARDTOOL'>
                  {' '}
                  {intl.formatMessage({
                    id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.CHANGECARDTOOL',
                  })}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid p-0'>
        <div className='row align-items-center'>
          <div className='col-lg-3'>
            <label className='col-form-label fw-bold fs-5 py-1 mb-0'>
              {intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.FEEASSIGNEDINSHOWS' })}:
            </label>
          </div>
          <div className='col-lg-8 text-end'>
            {rowData !== undefined ? rowData.length : ""}
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-lg d-flex align-items-center mb-2'>
              <OutputListingGridStatic
                area="MasterFees - General"
                columnDefs={columnDefs}
                rowData={rowData}
                containerStyle={containerStyle}
                setGridRef={setGridRef}
              />
          </div>

          <div className='col-lg-1'>
            <button
              className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2 d-block mb-2'
              type='button'
              onClick={handleAddFeeToShows}
            >
              <i className='fas fa-plus fs-5 px-1 py-3'></i>
            </button>
            <button
              className='btn btn-sm btn-secondary fw-bold px-2 py-2 d-block'
              type='button'
              onClick={handleRemoveFeeFromShows}
            >
              <i className='fas fa-minus fs-5 px-1 py-3'></i>
            </button>
            <div className='separator my-1 border-transparent d-xl-block d-lg-block d-md-none d-sm-none d-none'></div>
          </div>
        </div>
        </div>
      </div>
    </>
  )
}
export {GeneralTab}
