import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import { useAnnouncerToolContext } from "./AnnouncerToolContext"
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../redux/hooks'
import axios from 'axios'
import { useAlert, useConfirm, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider'
import AddRider from './AddRider'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const modalsRoot = document.getElementById('root-modals') || document.body

const ChangeRider = ({ show, handleClose }) => {
    const intl = useIntl()
    const alertDialog = useAlert()
    const { currentTrip, updateCompletedTripsForClass, preferences, setUpcomingTrips, selectedClassGroup } = useAnnouncerToolContext()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)
    const [entryRiders, setEntryRiders] = useState([])
    const [selectedRider, setSelectedRider] = useState()
    const [showAddRiderForm, setShowAddRiderForm] = useState(false)
    const loadingOverlay = useLoadingOverlay()
    const showId = useAppSelector(state => state.currentShow.show_id)
    const classNumber = currentTrip?.class_number ? currentTrip.class_number : currentTrip?.Class.number

    useEffect(() => {
        if (show && currentTrip) {
            const entryId = currentTrip.entry_id
            loadingOverlay({ show: true })
            axios.get(`${NEST_API_URL}/ingate-announcer/checkEntryCreditHold?customer_id=${customerId}&entry_id=${entryId}`)
            .then(res => {
                if (res.data.onCreditHold) {
                    alertDialog({ message: 'Entry is on credit hold.' })
                } 
                  
                if (res.data.entryRiders.length > 0) {
                    setEntryRiders(res.data.entryRiders)
                    setSelectedRider(currentTrip.rider_id)
                }

                loadingOverlay({ show: false })
            })
            .catch(reason => {
                alertDialog({ message: reason })
                loadingOverlay({ show: false })
            }) 
        }
    }, [currentTrip])


    const changeRider = async () => {
        try {
            loadingOverlay({ show: true })
            
            const response = await axios.get(`${NEST_API_URL}/ingate-announcer/changeTripRider?customer_id=${customerId}&entryxclasses_id=${currentTrip.entryxclasses_id}&rider_id=${selectedRider}&class_id=${currentTrip.class_id}&show_id=${showId}&class_group_id=${selectedClassGroup}`)   

            if (response.data.success) {
                setUpcomingTrips(response.data.upcomingTrips)
                updateCompletedTripsForClass(currentTrip.class_id, response.data.classCompletedTrips);    
            }
            handleClose()
        } catch(reason) {
            if (reason.response.data.error) {
                alertDialog({ message: reason.response.data.error })
            } else {
                alertDialog({ message: reason })
            }
        } finally {
            loadingOverlay({ show: false })
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_1'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal modal-dialog modal-dialog-centered mw-350px announcer-change-rider-form'
            show={show}
            onHide={handleClose}
            backdrop='static'
            onKeyPress={(event) => {
                if (event.key == 'Enter') {
                    changeRider()
                }
            }}
        >
            <AddRider
                entryRiders={entryRiders}
                setEntryRiders={setEntryRiders}
                setSelectedRider={setSelectedRider}
                show={showAddRiderForm}
                handleClose={() => setShowAddRiderForm(false)}
            />
            <div className="modal-header py-0 px-4">
                <h2 className="fs-4">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.MODAL.CHANGERIDER'})}</h2>
                <div className="btn btn-sm btn-icon btn-active-color-dark" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x"/>
                </div>
            </div>

            <div className="modal-body py-3 px-4">
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-100px pe-0' htmlFor='entry_no' data-tooltip-id="ANNOUNCERTOOL.DETAIL.MODAL.CHANGERIDER.LABEL.ENTRYNO">{intl.formatMessage({ id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.MODAL.LABEL.ENTRYNO' })}</label>
                            <div className='col'>
                                <label
                                    htmlFor='entry_no'
                                    className='mb-2 mb-lg-0 fs-6 h-30px py-1 '
                                >
                                    { currentTrip?.Entry?.number }
                                </label>
                            </div>
                        </div>

                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-100px pe-0' htmlFor='class_no' data-tooltip-id="ANNOUNCERTOOL.DETAIL.MODAL.CHANGERIDER.LABEL.CLASSNO">{intl.formatMessage({ id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.MODAL.LABEL.CLASSNO' })}</label>
                            <div className='col'>
                                <label
                                    htmlFor='entry_no'
                                    className='mb-2 mb-lg-0 fs-6 h-30px py-1 '
                                >
                                    { classNumber }
                                </label>
                            </div>
                        </div>

                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-100px pe-0' htmlFor='rider' data-tooltip-id="ANNOUNCERTOOL.DETAIL.MODAL.CHANGERIDER.LABEL.RIDER">{intl.formatMessage({ id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.MODAL.LABEL.RIDER' })}</label>
                            <div className='col'>
                                <select 
                                    id="rider" 
                                    className='form-select form-select-sm fs-6 h-30px py-1'
                                    value={selectedRider} 
                                    onChange={e => setSelectedRider(e.target.value)}   
                                >
                                    { entryRiders.map(er => <option value={er.rider_id}>{ er.rider_name }</option>)}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        { preferences['AddRiderFromIngate'] &&
                            <button 
                                type='button' 
                                className='btn btn-sm btn-dark me-4 fw-bold text-uppercase' 
                                onClick={() => setShowAddRiderForm(true)}
                                data-tooltip-id="ANNOUNCERTOOL.DETAIL.MODAL.CHANGERIDER.BUTTON.ADDNEW">
                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.BUTTON.ADDNEW' })}
                            </button>
                        }
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold text-uppercase' style={{ marginLeft: "auto" }} onClick={handleClose}>
                            {intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.NOTES.MODAL.NOTE.BUTTON.CANCEL' })}
                        </button>
                        <button 
                            type='button' 
                            className='btn btn-sm btn-dark fw-bold text-uppercase'
                            disabled={currentTrip?.rider_id == selectedRider || showFinancialsLocked}
                            onClick={changeRider}
                            autoFocus
                            data-tooltip-id="ANNOUNCERTOOL.DETAIL.MODAL.CHANGERIDER.BUTTON.APPLY"
                        >
                            {intl.formatMessage({ id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.MODAL.CHANGERIDER.APPLY' })}
                        </button>
                    </div>
                </form>

            </div>
        </Modal>,
        modalsRoot
    )
}

export { ChangeRider }