import {useIntl} from 'react-intl'
import axios from 'axios'
import { useSchedulerContext } from './SchedulerContext'
import { useAlert, useConfirm, useFlashAlert } from '../../../modules/sgl-utils/DialogsProvider';
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../modules/sgl-utils/SglFunctions';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const SaveSchedule = (props) => {
  const intl = useIntl()
  const {currentShowID, customerID, classGroupsGridRef, selectedDate, selectedRing, getClassGroups} = useSchedulerContext();
  const flashAlert = useFlashAlert()
  
  async function saveScheduleDayAndRing(event){
    let classGroupsList = [];
    classGroupsGridRef.forEachNode(node => classGroupsList.push(node.data));

    loadingSpinnerBtnWait(event)
    const response = await axios.post(`${NEST_API_URL}/scheduler/saveclassgroups`, {
        show_id: currentShowID,
        customer_id: customerID,
        day: selectedDate,
        ring_id: selectedRing,
        class_groups: classGroupsList
    });
    loadingSpinnerBtnRelease(event)

    props.setScheduleStatus('Saved')
    flashAlert({ type: 'success', message: intl.formatMessage({id: 'SCHEDULER.STATUS.SAVED'}) });
    getClassGroups(true); //refresh class groups list
  }

  return (
    <>
      <button type='button' className='btn btn-sm btn-dark fw-bold w-200px mb-2' onClick={(e) => { saveScheduleDayAndRing(e) }}>
        <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
        {intl.formatMessage({id: 'SCHEDULER.BUTTON.SAVESCHEDULE'})}
      </button>
    </>
  )
}

const DeleteClassGroups = (props) => {
  const intl = useIntl()
  const alertDialog = useAlert();
  const confirmDialog = useConfirm();
  const {currentShowID, customerID, classGroupsGridRef, setCurrentClassGroup, getClassGroups} = useSchedulerContext();
  const flashAlert = useFlashAlert()

  const handleClassGroupsDelete = async (event) => {
    if(!classGroupsGridRef) { alertDialog({message: 'Class Groups List not initialized.'}); return false;}
    
    let selectedRows = classGroupsGridRef.getSelectedNodes();
    if(selectedRows.length > 0){
      
      const choice = await confirmDialog({message: 'Are you sure want to delete selected Group(s)?' })
      if(!choice){ return false; }

      let class_groups_list = []
      for(let row of selectedRows){
        class_groups_list.push(row.data.class_group_id)
      }

      //Call webservice
      loadingSpinnerBtnWait(event)
      axios.delete(`${NEST_API_URL}/scheduler/deleteclassgroups?customer_id=${customerID}&show_id=${currentShowID}&class_groups_list=${class_groups_list}`)
      .then(result => {
        flashAlert({ type: 'success', message: intl.formatMessage({id: 'SCHEDULER.GROUP.DELETED'}) });
        setCurrentClassGroup({}) //clear selected class group
        getClassGroups(true); //refresh groups list
        props.setScheduleStatus('Pending');
      })
      .catch(async function (error) {
          if (error.response) {
              alertDialog({message: error.response.data.error});
          }
      })
      .finally(() =>{
        loadingSpinnerBtnRelease(event)
      });
    }
    else{
        alertDialog({message: 'Select atleast one group to delete.'});
    }
  }

  return (
  <>
    <button type="button" onClick={(e) => { handleClassGroupsDelete(e) }} className='btn btn-sm btn-secondary fw-bold h-30px py-1 px-1 w-125px align-items-center d-flex justify-content-center' data-tooltip-id="SIMPLESCHEDULER.DETAIL.BUTTON.DELETEGROUPS">
      <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-0 mt-1 mb-1" role="status" aria-hidden="true"></span>
      {intl.formatMessage({id: 'SCHEDULER.BUTTON.DELETEGROUP'})}
    </button>
  </>
  )
}

const RefreshSchedule = (props) => {
  const {getClassGroups} = useSchedulerContext();
  
  return (
    <>
      <div className='col-lg-1 mt-1'>
        <i onClick={() => getClassGroups(true) } className='far fa-solid fa-rotate text-success fs-1 fw-bold'></i>
      </div>
    </>
  )
}

const PendingSyncStats = (props) => {
  const intl = useIntl()
  const {scheduleSyncStats} = useSchedulerContext();

  return(
    <>
    { scheduleSyncStats.pending > 0 &&
      <>
        <div class="col col-3">
          <div class="d-flex align-items-center bg-light-danger rounded p-2 gutter-b">
            <div class="d-flex flex-column flex-grow-1 mr-2">
                <a class="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1">{intl.formatMessage({id: 'SCHEDULER.SYNCSTATSLABEL'})}: </a>
            </div>

            <span className="badge badge-square badge-danger py-1 font-size-lg fw-bold">{scheduleSyncStats.pending}</span>
          </div>
        </div>
      </>
    }
    </>
  );
}

export {SaveSchedule, DeleteClassGroups, RefreshSchedule, PendingSyncStats}
