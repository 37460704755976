import { KTSVG } from '../../../../_metronic/helpers'
import { ListFilter } from './ListFilter'
import { useOutputContextUpdater, useOutputContext } from '../../output-listing/OutputListingContext'
import {useIntl} from 'react-intl'
import { SelectionDropDown } from './SelectionDropDown'

const ListToolbar = () => {
  const intl = useIntl()
  const outputContextUpdater = useOutputContextUpdater();
  const {outputGrid} = useOutputContext();

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      { outputGrid.area != "Rings" && outputGrid.area != "Barns" ?
        <ListFilter /> : ""
      }

      <div className="">
          {/* <a href="#" className="btn btn-secondary fw-bolder px-4" data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
          {intl.formatMessage({id: 'TOOLBAR.LABEL.SELECTION'})}<KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-2 me-0 mx-1'  />
          </a> 

        <div className="menu menu-sub menu-sub-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 w-225px" data-kt-menu="true" data-kt-element="theme-mode-menu">

          <div className="menu-item px-2 my-0">
            <a href="#" onClick={() => outputContextUpdater({ action: 'clearFiltersAndRefresh' })} className="menu-link px-3 py-2 active" data-kt-element="mode" data-kt-value="light" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className="menu-title">{intl.formatMessage({ id: 'TOOLBAR.SELECTION.ALL' })}</span>
              <span>{intl.formatMessage({ id: 'TOOLBAR.SELECTION.ALL.SHORTCUT' })}</span>
            </a>
          </div>

          <div className="menu-item px-2 my-0">
            <a href="#" onClick={() => outputContextUpdater({action: 'showSubset'})} className="menu-link px-3 py-2 active" data-kt-element="mode" data-kt-value="light" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className="menu-title">{intl.formatMessage({id: 'TOOLBAR.SELECTION.SHOWSUBSET'})}</span>
              <span>{intl.formatMessage({id: 'TOOLBAR.SELECTION.SHOWSUBSET.SHORTCUT'})}</span>
            </a>
          </div>

          <div className="menu-item px-2 my-0">
            <a href="#" onClick={() => outputContextUpdater({action: 'omitSubset'})} className="menu-link px-3 py-2 active" data-kt-element="mode" data-kt-value="light">
              <span className="menu-title">{intl.formatMessage({id: 'TOOLBAR.SELECTION.OMITSUBSET'})}</span>
            </a>
          </div>

          <div className="menu-item px-2 my-0">
            <a href="#" onClick={() => outputContextUpdater({action: 'saveSelection'})} className="menu-link px-3 py-2 active" data-kt-element="mode" data-kt-value="light">
              <span className="menu-title">{intl.formatMessage({id: 'TOOLBAR.SELECTION.SAVE'})}</span>
            </a>
          </div>

          <div className="menu-item px-2 my-0">
            <a href="#" onClick={() => outputContextUpdater({action: 'useSavedSelection'})} className="menu-link px-3 py-2 active" data-kt-element="mode" data-kt-value="light">
              <span className="menu-title">{intl.formatMessage({id: 'TOOLBAR.SELECTION.USESAVED'})}</span>
            </a>
          </div>
        </div> */}
        <SelectionDropDown/>
      </div>
    </div>
  )
}

export { ListToolbar }
