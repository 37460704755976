import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    quickSearches: {
        people: [],
        payments: [],
        entries: []
    },
}

const showCompanyMetadataSlice = createSlice({
    name: 'showCompanyMetadata',
    initialState,
    reducers: {
        setQuickSearchAndActions (state, action) {
            const { payload } = action
            return {
                ...state,
                quickSearches: payload.quickSearches,
            }
        },
    },
  })
  
  export const { setQuickSearchAndActions } = showCompanyMetadataSlice.actions

  export default showCompanyMetadataSlice.reducer