import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../../_metronic/helpers'
import Flatpickr from "react-flatpickr";
import { date_options, setDate, maskDateInput } from '../../../../modules/sgl-utils/fieldControls'
import { AgGridReact } from 'ag-grid-react';
import { getNarrowHeaderHeight, getNarrowRowHeight, renderCheckBox, editableCellStyle, numberValueSetter } from '../../../../modules/sgl-utils/agGridHelpers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import axios from 'axios';
import { useAppSelector } from '../../../../redux/hooks';
import { useAlert, useConfirm } from '../../../../modules/sgl-utils/DialogsProvider';
import moment from 'moment';
import { addFloatingNumbers, loadingSpinnerBtnRelease, loadingSpinnerBtnReleaseByEnter, loadingSpinnerBtnWait, loadingSpinnerBtnWaitByEnter } from '../../../../modules/sgl-utils/SglFunctions';
import useReportPrint from '../../../../modules/hooks/use-report-print';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
 
const CreateWithholdingPaymentBatch = ({show, handleClose}) => {
    const intl = useIntl() 
    const containerStyle = useMemo(() => ({ width: '100%', height: '65vh' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const currencyFormatter = useCurrencyFormatter()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [rowData, setRowData] = useState([])
    const [batchName, setBatchName] = useState('')
    const [gridRef, setGridRef] = useState()
    const [checkAll, setCheckAll] = useState(false)
    const { printReport } = useReportPrint()


    const defaultColDef = useMemo(() => {
        return {
          minWidth: 100,
          width: 100,
          resizable: true,
          sortable: true,
          suppressMenu: true,
          suppressMovable: true,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          headerClass: "ag-center-aligned-header",
          singleClickEdit: true,
        };
    }, []);

    const columnDefs = [
        { 
            field: 'selected',
            headerClass: 'ag-center-aligned-header',
            cellRenderer: params => !params.node.rowPinned ? renderCheckBox(params, () => selectRTO(params), params?.node?.data?.actual_available_tax <= 0) : null,
            headerComponentFramework: CheckboxHeader,
            headerComponentParams: {
                headerName: '',
                onChange: (checked) => { 
                    const updatedRowData = rowData.map((data) => {
                        data['selected'] = data.actual_available_tax > 0 ? checked : false;
                        return data;
                    });
                
                    gridRef?.setRowData(updatedRowData)
                    setRowData(updatedRowData);
                    setCheckAll(checked)
                },
                checked: checkAll
            },
            width: 60,
            minWidth: 60,
            headerName: "",
            cellStyle: ({textAlign: 'center'})
        },
        { 
            field: 'name',
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.COL.RTO' }),
            flex: 1,
            cellStyle: { textAlign: 'left' }
        },{ 
            field: 'country', 
            headerClass: 'ag-center-aligned-header',
            width: 120,
            headerName: intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.COL.COUNTRY' }),
            cellStyle: { textAlign: 'center' },
            valueGetter: (params) => { 
                if(params.node.data?.country){
                    let value = params.node.data?.country
                    if(params.node.data?.country_tax_rate > 0){
                        value += ` (${params?.node.data?.country_tax_rate?.toFixed(2)}%)`
                    }

                    return value
                }
            }
        },
        { 
            field: 'country_tax_amount_limit', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.COL.TREATY" }),
            width: 115,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => !params.node.rowPinned ? currencyFormatter(params.value) : null
        },
        { 
            field: 'fiscal_year_prize_money_applied', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.COL.YTD.PRIZE" }),
            width: 125,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => !params.node.rowPinned ? currencyFormatter(params.value) : null
        },{ 
            field: 'total_prize_money',
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.COL.PRIZE.MONEY" }),
            width: 145,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => currencyFormatter(params.value)
        },{ 
            field: 'prize_money_applied', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.COL.APPLIED" }),
            width: 115,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => currencyFormatter(params.value)
        },{ 
            field: 'withholding_tax_rate', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.COL.TAX.RATE" }),
            width: 120,
            cellStyle: { textAlign: 'right' },
            valueGetter: (params) => { 
                if(!params.node.rowPinned){
                    return `${params?.data?.withholding_tax_rate?.toFixed(2)}%`
                }
            }
        },{ 
            field: 'tax_withheld', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.COL.TAX.WITHHELD" }),
            width: 155,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => currencyFormatter(params.value)
        },
        { 
            field: 'available_withholding_tax', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.COL.PAYABLE.IRS" }),
            width: 135,
            cellStyle: { textAlign: 'right', ...editableCellStyle },
            cellRenderer: params => currencyFormatter(params.value),
            editable: params => !params.node.rowPinned ?  true : false,
            valueSetter: numberValueSetter
        }
    ]

    const getUnAppliedWithholdingTaxes = async(e) => {
        if(!startDate || !endDate){
            return;
        }

        gridRef?.showLoadingOverlay()
        loadingSpinnerBtnWaitByEnter(e)
        await axios.get(`${NEST_API_URL}/withholding-tax/getDateRangeWithholdingTaxes?customer_id=${customer_id}&start_date=${startDate}&end_date=${endDate}`)
        .then(response => {
            if(response.data.success){
                // update the grid data
                let data = response.data.rtoWithholdingTaxDetails.map((data) => {
                    data['actual_available_tax'] = data.available_withholding_tax
                    data['selected'] = data.actual_available_tax > 0 ? checkAll : false;
                    return data
                })
                setRowData(data)
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            loadingSpinnerBtnReleaseByEnter(e)
            gridRef?.hideOverlay()
        }); 
    }

    const createPaymentBatch = (e) => {
        let selectedRTOs = rowData.filter((data)=> data.selected && data.tax_withheld > 0 && data.available_withholding_tax > 0)
        if(!selectedRTOs || selectedRTOs?.length === 0){
            alertDialog({message: intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.ERROR.RTO.NOT.SELECTED' })});
            return; // do nothing
        }
        
        if(!batchName){
            alertDialog({message: intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.ERROR.BATCH.MISSING' })});
            return;
        }

        let sumOfTaxToBePaid = selectedRTOs.reduce((val, data) => val + data.available_withholding_tax, 0)
        confirmDialog({ message: `${intl.formatMessage({ id: "PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.CONFIRM.MESSAGE"})} ${currencyFormatter(sumOfTaxToBePaid)} for ${selectedRTOs.length} RTO${selectedRTOs.length > 1 ? 's' : ''} ?`})
        .then(() => {
            
            let requestData = {
                amount: sumOfTaxToBePaid,
                batch_name: batchName,
                start_date: startDate,
                end_date: endDate,
                selectedPeople: selectedRTOs.map((rto) => {
                    return {
                        people_id: rto.people_id,
                        amount: rto.available_withholding_tax
                    }
                }),
                customer_id: customer_id
            }

            loadingSpinnerBtnWaitByEnter(e)
            axios.post( process.env.REACT_APP_NEST_API_URL + '/withholding-tax/createWithholdingPaymentBatch', requestData)
            .then((response) => {
                if(response.data.success){

                    let data = response.data.rtoWithholdingTaxDetails.map((data) => {
                        data['actual_available_tax'] = data.available_withholding_tax
                        data['selected'] = data.actual_available_tax > 0 ? checkAll : false;
                        return data
                    })
                    // refresh grid data 
                    setRowData(data)
                    gridRef.setRowData(data)

                    // clear batch name
                    setBatchName('')
                }
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
            }).finally(() => {
                loadingSpinnerBtnReleaseByEnter(e)
            })
        })
    }

    const selectRTO = (params) => {
        const updatedRowData = rowData.map((data) => {
            if (data.people_id === params?.node?.data?.people_id) {
                data['selected'] = params?.node?.data?.actual_available_tax > 0 ? params?.node?.data?.selected : false;
                if(!params?.node?.data?.selected && checkAll){
                    setCheckAll(false)
                }
            }
            return data;
        });
    
        gridRef?.setRowData(updatedRowData)
        setRowData(updatedRowData);
    };

    function CheckboxHeader({ headerName, onChange, checked }) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <div className='w-lg-20px form-check-sm form-check-custom py-2'>
                    <input
                        className='form-check-input'
                        type="checkbox"
                        id="checkAll" 
                        checked={checked}
                        onChange={(e) => onChange(e.target.checked)} 
                    />
                </div>
                { headerName.toUpperCase() }
            </div>
        );
    }

    const calculatePinnedRow = (rtoRecords) => {
        let footerRow = {
            prize_money_applied: 0,
            total_prize_money: 0,
            tax_withheld: 0,
            available_withholding_tax: 0
        }

        for (let row of rtoRecords){
            if(!row?.selected){ continue; }
            
            footerRow.prize_money_applied = addFloatingNumbers([footerRow.prize_money_applied, row.prize_money_applied], 2)
            footerRow.total_prize_money = addFloatingNumbers([footerRow.total_prize_money, row.total_prize_money], 2)
            footerRow.tax_withheld = addFloatingNumbers([footerRow.tax_withheld, row.tax_withheld], 2)
            footerRow.available_withholding_tax = addFloatingNumbers([footerRow.available_withholding_tax, row.available_withholding_tax], 2)
        }

        gridRef.setPinnedBottomRowData([footerRow])
    }

    const updatePaymentApplied = (params) => {
        let newAmount = parseFloat(params.data.available_withholding_tax)

        if(isNaN(newAmount)){
            alertDialog({message: intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.ERROR.NOT.NUMBER' })});
        }

        if(params.data.available_withholding_tax <= 0){
            alertDialog({message: intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.ERROR.NO.PAYMENT' })});
            newAmount = params.oldValue
        }

        if(parseFloat(params.data.available_withholding_tax, 2) > params.data.actual_available_tax){
            alertDialog({message: `${intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.ERROR.GREATER.PAYMENT' })} ${params.data.name} ${intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.ERROR.GREATER.PAYMENT1' })} $${params.data.actual_available_tax}.`});
            newAmount = params.oldValue
        }

        let updatedRowData = rowData.map((rowdata) => {
            if(rowdata.people_id === params.data.people_id){
                rowdata.available_withholding_tax = parseFloat(newAmount)
            }

            return rowdata
        })

        setRowData(updatedRowData)
        params.api.setRowData(updatedRowData)
    }

    useEffect(() => {
        if(gridRef){
            calculatePinnedRow(rowData)
        }
    }, [gridRef, rowData])

    const printWHTReport = async (event) => {
        // Show loading
        loadingSpinnerBtnWait(event)

        // 157 -> Circuit Division Standings
        await printReport(197, [], { startDate: startDate, endDate: endDate })

        // Hide loading
        loadingSpinnerBtnRelease(event)
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-xl search-form apply-withholding-tax-modal'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    let applyButton = document.getElementById('CreateWithholdingPaymentBatchApplyButton')
                    createPaymentBatch(applyButton)
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .manual-override-form .w-lg-140px { width: 140px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.POPUP.HEADING' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form manual-override-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>

                        {/* Heading */}
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1 fw-bold'>{intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.LABEL.CREATE' })}</label>
                        </div>

                        {/* Date Range */}
                        <div className='row mb-2 mw-800px'>
                            <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='DATERANGE'>{intl.formatMessage({id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.LABEL.DATE.RANGE'})}</label>

                            <div className='col-lg-3 '>
                                <div className="input-group date">
                                    <Flatpickr 
                                        className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                                        id="DATESFROM"
                                        autoComplete="off"
                                        value={startDate && startDate !== '0000-00-00' 
                                            ? moment(startDate).format('MM/DD/YYYY') 
                                            : null}
                                        placeholder="MM/DD/YYYY"
                                        options={date_options}
                                        onClose={(value, dateStr, instance) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }
                                             // Set the date with formatting logic
                                             setDate(dateStr, (formattedDate) => {
                                                setStartDate(formattedDate);
                                            });
                                        }}
                                        onChange={(value, dateStr, instance) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }
                                             // Set the date with formatting logic
                                             setDate(dateStr, (formattedDate) => {
                                                setStartDate(formattedDate);
                                            });
                                        }}
                                        onInput={(e) => { maskDateInput(e); }}
                                        autoFocus
                                        tabIndex={1}
                                    />
                                    <div className="input-group-append">
                                        <label htmlFor='DATESFROM' className='date-label'
                                            onClick={(e) => {
                                                e.stopPropagation(); 
                                                document.getElementById('DATESFROM')._flatpickr.open(); 
                                            }}
                                        >
                                        <span className="input-group-text">
                                            <i className="la la-calendar"></i>
                                        </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <label className='col-lg-1 col-form-label fs-5 py-1 mw-30px' htmlFor='TO'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.TO'})}</label>

                            <div className='col-lg-3'>
                                <div className="input-group date">
                                    <Flatpickr 
                                        className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                                        id="DATESTO"
                                        autoComplete="off"
                                        value={endDate && endDate !== '0000-00-00' 
                                            ? moment(endDate).format('MM/DD/YYYY') 
                                            : null}
                                        placeholder="MM/DD/YYYY"
                                        options={date_options}
                                        onChange={(value) => {
                                            const formattedDate = moment(value[0]).format('YYYY-MM-DD');
                                            setEndDate(formattedDate);
                                            setDate(formattedDate, (formattedDate) => {
                                                setEndDate(formattedDate);
                                            });
                                        }}
                                        onClose={(value, dateStr, instance) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }
                                            // Set the date with formatting logic
                                            setDate(dateStr, (formattedDate) => {
                                                setEndDate(formattedDate);
                                            });
                                        }}
                                        onInput={(e) => { maskDateInput(e); }}
                                        tabIndex={2}
                                    />
                                    <div className="input-group-append">
                                        <label htmlFor='DATESTO' className='date-label'
                                            onClick={(e) => {
                                                e.stopPropagation(); 
                                                document.getElementById('DATESTO')._flatpickr.open(); 
                                            }}
                                        >
                                            <span className="input-group-text">
                                                <i className="la la-calendar"></i>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-3'>
                                <button type='button' className='btn btn-sm btn-secondary fw-bold d-flex align-items-center' style={{ height: '30px' }} onClick={getUnAppliedWithholdingTaxes} tabIndex={3}>
                                    <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                    {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.BUTTON.FIND' })}
                                </button>
                            </div>
                        </div>

                        {/* Batch Name */}
                        <div className='row mb-2 mw-800px'>
                            <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='batch_name'>{intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.BATCH.NAME' })}</label>
                            <div className='col-lg-6'>
                                <input
                                    id='batch_name'
                                    type='text'
                                    value={batchName}
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    onChange={(e) => setBatchName(e.target.value)}
                                    tabIndex={4}
                                />
                            </div>
                        </div>

                        {/* RTO Grid */}
                        <div style={containerStyle}>
                            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                <AgGridReact
                                    onGridReady={params =>  { 
                                        setGridRef(params.api)
                                    }}
                                    defaultColDef={defaultColDef}
                                    rowData={rowData}
                                    columnDefs={columnDefs} 
                                    rowStyle={{ fontSize: '12px' }}
                                    suppressScrollOnNewData={true}
                                    headerHeight={getNarrowHeaderHeight}
                                    rowHeight={getNarrowRowHeight}
                                    onCellEditingStopped={(params) => {
                                        updatePaymentApplied(params)
                                    }}
                                    stopEditingWhenCellsLoseFocus={true}
                                    overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while RTO taxes are loading...</span>'}
                                    tabIndex={5}
                                />
                            </div>
                        </div>
                        
                    </div>

                    <div className='card-footer d-flex justify-content-between py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-secondary fw-bold' tabIndex={6} disabled={!startDate || !endDate || rowData?.length == 0 } onClick={printWHTReport}>
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.BUTTON.BATCH.PREVIEW' })}
                        </button>

                        <div>
                            <button type='button' className='btn btn-sm btn-secondary fw-bold me-4' onClick={handleClose} tabIndex={7}>
                                {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.BUTTON.DONE' })}
                            </button>

                            <button id='CreateWithholdingPaymentBatchApplyButton' type='button' className='btn btn-sm btn-dark fw-bold' onClick={createPaymentBatch} tabIndex={8}>
                                <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.CREATE.PAYMENT.BATCH.POPUP.BUTTON.CREATE.WITHHOLDING.BATCH' })}
                            </button>
                        </div>     
                    </div>
                </form>
            </div>

            
        </Modal>,
        modalsRoot
    )
}

export default CreateWithholdingPaymentBatch