import {useIntl} from 'react-intl'
import {useFormContext} from 'react-hook-form'  
import './tab.css' 
import ImageUpload from '../ShowGallery/ImageUpload';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { useAppSelector } from '../../../redux/hooks';

const ProductInfoTab = (props) => {
  const intl = useIntl()
  const methods = useFormContext()
  const company_name = useAppSelector(state=> state.showCompany.name)
  const ckEditorFontSizes = [ 'default', 8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48]
  const ckeditorToolbar = ['heading', '|', 'bold', 'italic', 'blockQuote', 'link','fontFamily', 'fontSize', 'numberedList', 'bulletedList', '|', 'insertTable', 'mediaEmbed', '|', 'undo', 'redo']

  return (
    <>
      <div className='form-group mb-2 masterfee-tab'>

        <div className='row mb-2'>
        {/* name */}
          <label className='col-lg-1 col-form-label fs-4 py-1 w-lg-110px' htmlFor='NAME'>
            <strong>
              {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.NAME'})}
            </strong>
          </label>
          
          <div className='col-lg-3 fs-5 py-1'>
              <strong>{methods.watch('master_fee.name')}</strong>
           </div>
           {/* category */}
          <label className='col-lg-2 col-form-label fs-4 py-1 w-lg-110px' htmlFor='CATEGORY'>
            <strong>{intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.CATEGORY'})}</strong>
          </label>
          <div className='col-lg-2 fs-5 py-1'>
              {methods.watch('master_fee.category')}
           </div>
           {/* status */}
           <label className='col-lg-2 col-form-label fs-4 py-1 w-lg-110px' htmlFor='STATUS'>
            <strong>{intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.STATUS'})}</strong>
          </label>
          <div className='col-lg-2 fs-5 py-1'>
              {(methods.watch('master_fee.active') == 1? "Active": "Inactive")}
           </div>
          
        </div>

        <div className='row mb-2'>
        {/*  company */}
          <label className='col-lg-1 col-form-label fs-4 py-1 w-lg-110px' htmlFor='NAME'>
            <strong>
              {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.COMPANY'})}
            </strong>
          </label>
          
          <div className='col-lg-3 fs-5 py-1'>
              {company_name}
           </div>
           {/* DEFAULT PRIZE */}
          <label className='col-lg-2 col-form-label fs-4 py-1 w-lg-110px' htmlFor='DEFAULTPRIZE'>
            <strong>{intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.DEFAULTPRIZE'})}</strong>
          </label>
          <div className='col-lg-2 fs-5 py-1'>
              {methods.watch('master_fee.default_price')}
           </div>
           {/* status */}
           <label className='col-lg-2 col-form-label fs-4 py-1 w-lg-110px' htmlFor='STATUS'>
            <strong>{intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.PHYSICALDELIVERY'})}</strong>
          </label>
          <div className='col-lg-2 fs-5 py-1'>
              {(methods.watch('master_fee.physical_delivery') == 1? "Yes": "No")}
           </div>
          
        </div>

        <div className='row mb-2'>
        {/* TAX RATE */}
          <label className='col-lg-1 col-form-label fs-4 py-1 w-lg-110px' htmlFor='TAXRATE'>
            <strong>
              {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.TAXRATE'})}
            </strong>
          </label>
          
          <div className='col-lg-2 fs-5 py-1'>
              {methods.getValues('tax_name')}
           </div>
        </div>
        
        <div className='row mb-2'>
        {/* BRAND */}
          <label className='col-lg-1 col-form-label fs-4 py-1 w-lg-110px' htmlFor='NABRANDME'>
            <strong>
              {intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.LABEL.BRAND'})}
            </strong>
          </label>
          
          <div className='col-lg-2 fs-5'>
            <input
              {...methods.register('master_fee.brand', {
              required: false,
              })}
              id= 'BRAND'
              type='text'
              className='form-control form-control-sm  fs-6 min-h-20px py-1'
            />
              
           </div>
        </div>

        <div className='row mb-2'>
          <label className='col-lg-1 col-form-label fs-4 py-1 w-lg-110px' htmlFor='WEBDESCRIPTION'>
            <strong>{intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.LABEL.DESCRIPTION'})}</strong>
          </label>
          <div className='col-lg-11 py-1'>
            <CKEditor
                editor={ Editor }
                data={methods.getValues('master_fee.web_description') }
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    if(data !== methods.getValues('master_fee.web_description')){
                      methods.setValue('master_fee.web_description',  data , {shouldDirty:true})  
                    }
                } }
                placeholder={intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.LABEL.DESCRIPTION' })}
                config={{
                  //extraPlugins: [uploadPlugin],
                  toolbar: ckeditorToolbar,
                  fontSize: {
                    options: ckEditorFontSizes
                  },
                }}
            />
            
          </div>
          
        </div>

        <div className='row mb-2'>
          <label className='col-lg-1 col-form-label fs-4 py-1 w-lg-110px' htmlFor='DESCRIPTION'>
            <strong>{intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.LABEL.SUMMARY'})}</strong>
          </label>
          <div className='col-lg-11 py-1'>
            <CKEditor
                editor={ Editor }
                data={methods.getValues('master_fee.web_summary') }
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    if(data !== methods.getValues('master_fee.web_summary')){
                      methods.setValue('master_fee.web_summary',  data , {shouldDirty:true}) 
                    } 
                } }
                config={{
                  //extraPlugins: [uploadPlugin],
                  toolbar: ckeditorToolbar,
                  fontSize: {
                    options: ckEditorFontSizes
                  },
                }}
            />
          </div>
        </div>
       
        <div className='row mb-2'>
              <label className='col-lg-1 col-form-label fs-4 py-1 w-lg-110px' htmlFor='GALLERY'>
                <strong>{intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.LABEL.GALLERY'})}</strong>
              </label>
              <div className='col-lg-11'>
                <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.GROUPLABEL.ADDIMAGES' })} <b></b></legend>
                <ImageUpload />
              </div>
        </div>

      </div>
    </>
  )
}

export {ProductInfoTab}

