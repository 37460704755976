import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form';

import axios from 'axios'
import { useAppSelector } from '../../../redux/hooks';
import { useEffect, useState } from 'react';

const StatsTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);

    const runStats = () => {
        
        props.setStatsResultLoading(true)
        // get show ids for the current session 
        let circuitxshows = methods.getValues('circuitxShow_data') 
        let show_ids = circuitxshows.map((show) => show.show_id);

        // call api for get statistics data
        axios.post( process.env.REACT_APP_NEST_API_URL + '/circuits/getStats', {
            params: {
                circuit_id: methods.getValues('circuit.circuit_id'),
                customer_id: customer_id,
                show_ids:show_ids,
            }
          }).then((response) => {
            if(response.data.success){
                props.setStatsResultLoading(false)
                methods.setValue ('statistics.unique_stables_count', response.data.statistics.unique_stables_count)
                methods.setValue ('statistics.unique_riders_count', response.data.statistics.unique_riders_count)
                methods.setValue ('statistics.unique_trainers_count', response.data.statistics.unique_trainers_count)
                methods.setValue ('statistics.unique_owners_count', response.data.statistics.unique_owners_count)
                methods.setValue ('statistics.unique_horse_count', response.data.statistics.unique_horse_count)
            }
            props.setStatsResultLoading(false)
        })
    }
    return (
        <>
            <div className='form-group'>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1' data-tooltip-id="CIRCUITS.DETAIL.TAB.STATS.LABEL.UNIQUEHORSES">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.STATS.UNIQUEHORSES' })}</label>
                    <label className='col-lg-10 col-form-label fs-5 py-1'>{methods.watch('statistics.unique_horse_count')}</label>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1' data-tooltip-id="CIRCUITS.DETAIL.TAB.STATS.LABEL.UNIQUEOWNERS">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.STATS.UNIQUEOWNERS' })}</label>
                    <label className='col-lg-10 col-form-label fs-5 py-1'>{methods.watch('statistics.unique_owners_count')}</label>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1' data-tooltip-id="CIRCUITS.DETAIL.TAB.STATS.LABEL.UNIQUETRAINERS">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.STATS.UNIQUETRAINERS' })}</label>
                    <label className='col-lg-10 col-form-label fs-5 py-1'>{methods.watch('statistics.unique_trainers_count')}</label>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1' data-tooltip-id="CIRCUITS.DETAIL.TAB.STATS.LABEL.UNIQUERIDERS">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.STATS.UNIQUERIDERS' })}</label>
                    <label className='col-lg-10 col-form-label fs-5 py-1'>{methods.watch('statistics.unique_riders_count')}</label>
                </div>
                <div className='row mb-5'>
                    <label className='col-lg-2 col-form-label fs-5 py-1' data-tooltip-id="CIRCUITS.DETAIL.TAB.STATS.LABEL.UNIQUESTABLES">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.STATS.UNIQUESTABLES' })}</label>
                    <label className='col-lg-10 col-form-label fs-5 py-1'>{methods.watch('statistics.unique_stables_count')}</label>
                </div>
                <div className='row align-items-center mb-2'>
                    <div className='col-lg-2 w-175px'>
                        <button type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12"  onClick={(e)=> {runStats();e.preventDefault()}}  data-tooltip-id="CIRCUITS.DETAIL.TAB.STATS.BUTTON.RUNSTATISTICS">
                            {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.STATS.RUNSTATISTICS' })}
                        </button>
                    </div>
                    <div className='col-lg-2 w-175px'>
                        {props.statsResultLoading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export { StatsTab }