import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { KTSVG } from '../../../../_metronic/helpers';
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { getExtraNarrowRowHeight } from '../../../modules/sgl-utils/agGridHelpers';
import { useFormContext } from 'react-hook-form';

const modalsRoot = document.getElementById('root-modals') || document.body

const AddFeeToShows = ({show, handleClose, showsMissingCurrentMasterFee, rule}) => {
    const intl = useIntl()
    const [gridRef, setGridRef] = useState()
    const methods = useFormContext()

    const columnDefs = [
        { field: '', checkboxSelection: true, minWidth: 70, maxWidth: 70, sortable: false },
        { field: 'show_name', width: 350, headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.NAME' }), filter: 'agTextColumnFilter', wrapText: true, autoHeight: true }
    ]

    const [rowData, setRowData] = useState(showsMissingCurrentMasterFee ?? [])
    const [noSelectedShows, setNoSelectedShows] = useState(0)
    const containerStyle = { width: '100%', height: '400px' }

    useEffect(() => {
        let shows = methods.getValues('fee_assigned') ?? []
        let showIds = shows.map((s) => s.show_id)
        let updatedRowData = rowData.filter((data) => !showIds.includes(data.show_id))
        setRowData((prevState) => updatedRowData)
    }, [])

    function handleAddButtonClick() {
        if (gridRef !== null) {
            let shows = methods.getValues('showsToAddMasterFee') ?? []
            let selected_shows = gridRef.getSelectedRows();
            let selectedIds = selected_shows.map((show) => show.show_id) ?? [];
            
            if (selectedIds.length > 0) {
               methods.setValue('showsToAddMasterFee', [...selectedIds,...shows], {shouldDirty: true})
            }else{
                methods.setValue('showsToAddMasterFee', shows, {shouldDirty: true})
            }

            //update the fee_assigned array to display the newly added fee in the grid
            let updatedFeeAssigned = []
            let previousFees = methods.getValues('fee_assigned') ?? []
            for(let show of selected_shows){
                updatedFeeAssigned.push({show_id: show.show_id, show_name: show.show_name, rule: previousFees?.length > 0 ? previousFees[0].rule : 'N/A', sold_to_date: 0, sf_description: methods.getValues('master_fee.name'), unit_price: parseFloat(methods.getValues('master_fee.default_price')), mf_cost: parseFloat(methods.getValues('master_fee.cost'))})
            }
            methods.setValue('fee_assigned', [...updatedFeeAssigned, ...previousFees])
            handleClose()
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused){
                    event.preventDefault()
                    if(noSelectedShows !== 0){
                        handleAddButtonClick();
                    }
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                {/* Modal Title */}
                <h2 className='fs-4'>
                    {intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.MODAL.ADDFEETOSHOW.TITLE' })}
                </h2>
                {/* Close button */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={() => handleClose()}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                    {/* Row for Labels */}
                    <div className='mb-2 d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <label className={`col-auto col-form-label fs-5 fw-bold py-1 ${rowData?.length === 0 ? 'text-danger' : ''}`}>{rowData?.length > 0 ? `${intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.MODAL.ADDFEETOSHOW.LABEL.FEESTOBEADDED1' })} (${methods.getValues('master_fee.name')})` : intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.MODAL.ADDFEETOSHOW.LABEL.ERROR' })}</label>
                        </div>
                    </div>
                    {/* AG-Grid Component */}
                    <div className='mb-2'>
                        <OutputListingGridStatic
                            // area={'Entries - Membership Detail'}
                            setGridRef={setGridRef}
                            columnDefs={columnDefs}
                            rowData={rowData}
                            containerStyle={containerStyle}
                            rowHeight={getExtraNarrowRowHeight}
                            selection={'multiple'}
                            rowMultiSelectWithClick={true}
                            onRowSelected={() => {setNoSelectedShows(gridRef.getSelectedRows().length)}}
                        />
                    </div>

                    <div className='d-flex align-items-center justify-content-between'>

                        <div className='row mb-2'>
                            {/* Column Group */}
                            <div className='col-auto'>
                                {/* Column 1 */}
                                <div className='row mb-2'>
                                    <label className='col-auto col-form-label fs-5 fw-bold py-1'>
                                        {intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.GENERAL.MODAL.LABEL.SELECTEDSHOW' })}
                                    </label>
                                    <div className='col-auto px-0'>
                                        <label className='col-auto col-form-label fs-5 py-1 px-0'>
                                            {noSelectedShows}
                                        </label>
                                    </div>
                                </div>
                                {/* End Column 1 */}
                            </div>
                            {/* End Column Group */}
                        </div>
                        {/* Close and Add Buttons */}
                        <div className='d-flex'>
                            <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold text-uppercase' onClick={() => handleClose()}
                            >
                                {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.FEES.MODAL.ADDFEETOSHOW.BUTTON.CLOSE' })}
                            </button>
                            <button type='button' className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={() => handleAddButtonClick()} disabled={noSelectedShows === 0} autoFocus>
                                {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.FEES.MODAL.ADDFEETOSHOW.BUTTON.ADD' })}
                            </button>
                        </div>
                    </div>
            </div>
        </Modal>,
        modalsRoot
    );
};

export { AddFeeToShows };
