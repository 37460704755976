import { useEffect, useState } from 'react';
import {useIntl} from 'react-intl'
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Custom Components
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';

export const dirtyValues = (
  dirtyFields,
  allValues
) => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object?.fromEntries(
    Object?.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key])
    ])
  );
};

const WaiversTab = () => {
  const intl = useIntl();
  const methods = useFormContext()
  const alertDialog = useAlert()
  const confirmDialog = useConfirm()
  const loadingOverlay = useLoadingOverlay()
  const { show_id } = useParams();
  const customer_id = useAppSelector((state) => state.showCompany.company_id)

  const { dirtyFields } = methods.formState; 

  const copyDataFromPreferences = async (e) => {
    e.preventDefault()

    loadingOverlay({ show: true })
    let response = await axios.get(process.env.REACT_APP_NEST_API_URL + '/shows/system-pref-waivers', {
      params: {
        customer_id: customer_id,
      },
    })

    if(response?.data){
      methods.setValue('entry_blank_waivers', { waiver_text: response.data?.waiver_text,  waiver_additionalterms: response.data?.waiver_additionalterms }, { shouldDirty: true })
      alertDialog({message: 'Entry blank waiver text copied successfully for this show. Please make changes and click Update button to save waiver text for this show.', title: '', icon: 'info'})
    }else{
      alertDialog({message: 'Entry blank waiver text not found in system preferences.', title: '', icon: 'info'})
    }

    loadingOverlay({ show: false })
  }

  const saveWaivers = async (e) => {
    let entry_blank_waivers = methods.getValues('entry_blank_waivers')

    // Validate waivers
    if(entry_blank_waivers && !entry_blank_waivers?.waiver_text && !entry_blank_waivers?.waiver_additionalterms){
      if(!await confirmDialog({ message: 'Are you sure you want to remove the show specific entry blank waiver text? Entry blank waiver text from system preferences will be used for this show.', icon: 'warning'})){
        alertDialog({message: 'Please update entry blank waiver text and click Update button.', title: 'warning'})
        return
      }
    }

    let dirty_values = dirtyValues(dirtyFields, { entry_blank_waivers: entry_blank_waivers })
    if(Object.keys(dirty_values).length == 0){
      alertDialog({message: 'Please change the entry blank waiver text before updating.', title: 'warning'})
      return
    }

    loadingOverlay({ show: true })
    let response = await axios.post( process.env.REACT_APP_NEST_API_URL + '/shows/saveEntryBlankWaivers', {
      params: {
        show_id: methods.getValues('show.show_id'),
        customer_id: customer_id,
        data: entry_blank_waivers
      }
    })
    loadingOverlay({ show: false })

    if(response?.data?.updated_waiver){
      methods.setValue('entry_blank_waivers', response?.data?.updated_waiver)
    }

    if(response.data?.message){
      alertDialog({message: response.data?.message, title: '', icon: 'info'})
    }
  }

  return (
    <>
        <div className='row mb-1'>
            <label className='col col-form-label fs-5 py-0 d-flex align-items-end' htmlFor="ENTRYBLANK" data-tooltip-id="SHOW.DETAIL.TAB.WAIVERS.LABEL.ENTRYBLANKWAIVERTEXT">
              {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WAIVERS.LABEL.ENTRYBLANK'})}
            </label>
            <div className='col-lg-1 w-lg-225px d-flex justify-content-end'>
                <button type="button" className='btn btn-sm btn-secondary fw-bold h-30px py-1 float-end' onClick={copyDataFromPreferences} data-tooltip-id="SHOW.DETAIL.TAB.WAIVERS.BUTTON.COPYFROMPREFERENCES">
                  {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WAIVERS.BUTTON.COPYFROM'})}
                </button>
            </div>
        </div>
        <div className='row mb-4'>
          <div className='col-lg-12'>
          <textarea {...methods.register( 'entry_blank_waivers.waiver_text', { required: false })} id="ENTRYBLANK" rows="5" className='form-control form-control-sm fs-6 py-1'></textarea>
          </div>
        </div>

        <div className='row mb-1'>
          <label className='col-lg-12 col-form-label fs-5 py-0 d-flex align-items-center' htmlFor="ENTRYBLANKADDITIONAL" data-tooltip-id="SHOW.DETAIL.TAB.WAIVERS.LABEL.ENTRYBLANKADDITIONALTERMSANDCONDITIONS">
              {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WAIVERS.LABEL.ENTRYBLANKADDITIONAL'})}
          </label>
        </div>

        <div className='row mb-1'>
          <div className='col-lg-12'>
          <textarea {...methods.register( 'entry_blank_waivers.waiver_additionalterms', { required: false })} id="ENTRYBLANKADDITIONAL" rows="5" className='form-control form-control-sm fs-6 py-1'></textarea>
          </div>
        </div>
        
        <div className='row mb-2'>
          <div className='col-lg-8'>
            <p className='fs-6'>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WAIVERS.LABEL.TIP'})}</p>
          </div>
          <div className='col-lg-4 align-items-center d-flex justify-content-end' >
            <button className='btn btn-sm btn-secondary fw-bold h-30px py-1' type='button' onClick={saveWaivers} data-tooltip-id="SHOW.DETAIL.TAB.WAIVERS.BUTTON.UPDATE">
              {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WAIVERS.BUTTON.UPDATE'})}
            </button>
          </div>
        </div>


    </>
    )
  }
  
export {WaiversTab}