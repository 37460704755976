/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import { useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom'

const ClassRulesList = () => {
  const intl = useIntl();
  const navigate = useNavigate()
  const customer_id = useAppSelector(state=> state.showCompany.company_id);

  const columnDefs = [
    // { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.COMMONS.SGLID'}), filter: 'agNumberColumnFilter', filterParams: { suppressAndOrCondition: true,}, },
    { field: 'class_rule_id', headerName: intl.formatMessage({id: 'LIST.CLASSRULES.CLASSRULEID'}), filter: 'agNumberColumnFilter', /*hide:true*/},
    { field: 'classRuleType_type', width: 300,  headerName: intl.formatMessage({id: 'LIST.CLASSRULES.TYPE'}) , filter: 'agTextColumnFilter', }, 
    { field: 'rule', width: 300,  headerName: intl.formatMessage({id: 'LIST.CLASSRULES.RULE'}) , filter: 'agTextColumnFilter', },  
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CLASSRULES'})}</PageTitle>
      <OutputListingComponent key={customer_id} area={'ClassRules'} columnDefs={columnDefs} apiEndPoint={'class-rules/list'}
        onRowDoubleClicked={(row_data) => {navigate('/classrules/detail/'+row_data.class_rule_id+`?customer_id=${customer_id}`)}}
        onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL+'/classrules/detail/'+row_data.class_rule_id+`?customer_id=${customer_id}`,'_blank')}
      ></OutputListingComponent>
    </>
  )
}

export {ClassRulesList}
