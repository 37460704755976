import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import Select from 'react-select';

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Assets
import "flatpickr/dist/themes/material_blue.css";

// Constants
import { date_options, reactSelectStyles, setDate, maskDateInput} from '../../../../modules/sgl-utils/fieldControls';
import { useAppSelector } from '../../../../redux/hooks';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import axios from 'axios';
import moment from 'moment';
import { useAuth } from '../../../../modules/auth';
import { useAlert, useLoadingOverlay } from '../../../../modules/sgl-utils/DialogsProvider';
import { getDefaultOverrideDate } from '../../../../modules/sgl-utils/SglFunctions';
import { useOutputContext } from '../../../../modules/output-listing/OutputListingContext';
import SetMembershipOverridesVerifyDialog from './SetMembershipOverrideVerifyDialog';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const SetMembershipOverridesForm = ({ show, handleClose }) => {
    const intl = useIntl()
    const { currentUser } = useAuth()
    const customerID = useAppSelector(state => state.showCompany.company_id)
    const currentShowID =  useAppSelector(state => state.currentShow.show_id)
    const currentShowEndDate =  useAppSelector(state => state.currentShow.end_date)
    const {getOutputSelectionAreaIDs, getEventID} = useOutputContext()
    const [ecOverrideReasonsForHorse, setECOverrideReasonsForHorse] = useState([])
    const [ecOverrideReasonsForRTO, setECOverrideReasonsForRTO] = useState([])
    const [membershipLevels, setMembershipLevels] = useState([])
    const [clearMemberbershipOverride, setClearMembershipOverride] = useState(false)
    const [entryIds, setEntryIds] = useState([])
    const [showOverrideVerifyDialog, setOverrideVerifyDialog] = useState(false)
    const [existingOverrides, setExistingOverrides] = useState([])
    const [entries, setEntries] = useState([])

    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const defaultValues = {
        set_override_for: 'Rider',
        customer_id: customerID ,
        ec_override_reason: '',
        ec_override_reason_label: '',
        inactive_override_date: getDefaultOverrideDate(currentShowEndDate),
        inactive_override_reason: '',
        name_mismatch_accepted: 0,
        organization_code: 'USEF',
        override_membershiplevel: '', 
        override_by_user: currentUser.id,
        owner_nonmemberfeepaid: 0,
        owner_upgradefeepaid: 0, 
        rider_nonmemberfeepaid: 0,
        rider_upgradefeepaid: 0,
        this_show_only: 0,
        show_id: 0
    }

    const methods = useForm({
        defaultValues
    });
    //Don't remove these as they update layout according to new values
    let organization_code = methods.watch('organization_code')
    let set_override_for = methods.watch('set_override_for')

    const getMetaData = async () =>{//Set data in drop down on page load
        loadingOverlay({ show: true })
        await axios.get(`${NEST_API_URL}/entries/getOverrideMetaData?customer_id=${customerID}&show_id=${currentShowID}`)
        .then(response => {
            setECOverrideReasonsForHorse(response?.data?.ec_horse_override_reasons ?? [])
            setECOverrideReasonsForRTO(response?.data?.ec_rider_override_reasons ?? [])
            setMembershipLevels(response?.data?.ec_membership_levels ?? [])
        }).catch((error) => {
            if (error.response) {
            alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            loadingOverlay({ show: false })
        })
    }

    const getMembershipLevel = () => {// fill option for ec membership level
        const membershipLevelOptions = membershipLevels?.map((membershipLevel) => ({value: membershipLevel.value, label: membershipLevel.label}));
        membershipLevelOptions?.unshift({label: 'None', value: ''})
        return membershipLevelOptions;
    }

    const getECOverrideReasons = () => {// fill option for ec override reasons
        let reasons = []
        if(methods.getValues('set_override_for') == 'Horse'){
            reasons = ecOverrideReasonsForHorse
        }else{//For Rider/Owner/Trainer
            reasons = ecOverrideReasonsForRTO
        }
        const ecOverrideReasons = reasons?.map((reason) => ({value: reason.value, label: reason.label}));
        ecOverrideReasons?.unshift({label: 'Select', value: ''})
        return ecOverrideReasons;
    }

    const setOverrideForOptions = () => {// fill option for set override for drop down
        let overrideOptions = ['Rider', 'Owner', 'Trainer', 'Horse']
        return overrideOptions.map((reason) => ({value: reason, label: reason}))
    }

    const setOrganizationOptionsForOverride = () => {// fill option for set override for drop down
        let orgForOverride = ['USEF', 'EC', 'FEI', 'USHJA']
        return orgForOverride.map((reason) => ({value: reason, label: reason}))
    }

    // [enable/disable] or [show/hide] the input fields
    const setInputFieldsProperty = (field) => {
        let org = methods.getValues('organization_code')
        let overrideFor = methods.getValues('set_override_for')
        //Disable accept name mismatch checkbox for USEF, USHJA and EC(in case of horse)
        if(field == "name_mismatch_accepted"){
            if(org == 'USEF' || org == 'USHJA' || (org == 'EC' && overrideFor == 'Horse')){
                return true
            }
        }else if(field == 'ec_override_reason' || field == 'membership_level'){
        //Hide ec override drop down and membership level for all org other than EC
            if(org !== 'EC'){
                return true
            }
        }else if(field == 'hide_fee_applied' || field == 'set_reason_label' ){
        //Hide fee applied section and replace Reason label with Notes for EC
            if(org == 'EC'){
                return true
            }
        }
        return false
    }

    const handleClear = () => {// delete override data
        //Clear all values other than organization and set_override_for
        let org = methods.getValues('organization_code')
        let set_override_for = methods.getValues('set_override_for')
        methods.reset(defaultValues)
        methods.setValue('inactive_override_date','0000-00-00')
        methods.setValue('organization_code',org)
        methods.setValue('set_override_for',set_override_for)
        setClearMembershipOverride(true)
    }

    const validateOverrideMembership = () => {
        let data = methods.getValues()
        let overrideReason = data.organization_code == 'EC' ? data.ec_override_reason : data.inactive_override_reason

        if((data.inactive_override_date == '0000-00-00' && overrideReason == '' && clearMemberbershipOverride)){//delete the record
            return {isValid: true, message: 'delete'}
        }else if(data.inactive_override_date == '0000-00-00' && overrideReason == ''){
            return {isValid: false, message: intl.formatMessage({ id: 'ENTRIES.OVERRIDE.ERROR.MESSAGE.FOR.MISSING.DATE.AND.REASON' })}
        }else if(data.inactive_override_date == '0000-00-00' && overrideReason !== ''){
            return {isValid: false, message: intl.formatMessage({ id: 'ENTRIES.OVERRIDE.ERROR.MESSAGE.FOR.MISSING.DATE' })}
        }else if(data.inactive_override_date !== '0000-00-00' && overrideReason == ''){
            return {isValid: false, message: intl.formatMessage({ id: 'ENTRIES.OVERRIDE.ERROR.MESSAGE.FOR.MISSING.REASON' })}
        }else if(entryIds?.length == 0){//Check if there are entries in selection or not
            return {isValid: false, message: intl.formatMessage({ id: 'ENTRIES.OVERRIDE.ERROR.MESSAGE.FOR.MISSING.ENTRIES' })}
        }else if(!methods.getValues('organization_code')){ //Check if some organization is selected or not
            return {isValid: false, message: intl.formatMessage({ id: 'ENTRIES.OVERRIDE.ERROR.MESSAGE.FOR.MISSING.ORG' })}
        }
        return {isValid: true, message: ''}
    }

    const overrideMembership = async() => {// Create/Update/Delete the override
        let checkIsValid = validateOverrideMembership()
        if(!checkIsValid.isValid){
        //Check if data entered is valid or not
            alertDialog({message: checkIsValid.message})
            return
        }

        //Set show_id based on this_show_only checkbox
        if(methods.getValues('this_show_only')){
            methods.setValue('show_id', currentShowID)
        }else{
            methods.setValue('show_id', 0)
        }

        //Get existing override records
        loadingOverlay({ show: true })
        await axios.post(`${NEST_API_URL}/entries/getExistingOverridesForEntries`, {
            customer_id: customerID,
            entry_ids: entryIds,
            show_id: currentShowID,
            org_code: methods.getValues('organization_code'),
            entity_type: methods.getValues('set_override_for')
        })
        .then(response => {
            setExistingOverrides(response?.data?.existingOverrides)
            setEntries(response?.data?.entries)
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
                loadingOverlay({ show: false })
            }
        })
        .finally(() => {
            loadingOverlay({ show: false })
            setOverrideVerifyDialog(true)
        })
    }

    useEffect(() => {
        if (show) {//Get Entry Ids in selection
            loadingOverlay({ show: true })
            getOutputSelectionAreaIDs(false)
            .then(res => { 
                setEntryIds(res)
            })
            .catch((error) => {
                if (error.response) {
                alertDialog({message: error.response.data.error});
                }
            })
            .finally(() => {
                loadingOverlay({ show: false })
                getMetaData()// Get drop down values
            })
        }  
    },[])

    useEffect(() => {
        //Clear out fields that are disabled when organization_code or set_override_for is changed
        if(organization_code == 'USEF' || organization_code == 'USHJA' || (organization_code == 'EC' && set_override_for == 'Horse')){
            methods.setValue('name_mismatch_accepted', false, {shouldDirty: true})
        }
        if(organization_code !== 'EC'){
            methods.setValue('ec_override_reason', '', {shouldDirty: true})
            methods.setValue('membership_level', '', {shouldDirty: true})
        }
    },[organization_code, set_override_for])

    useEffect(() => {
        if(clearMemberbershipOverride){
            overrideMembership()
        }
    },[clearMemberbershipOverride])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    overrideMembership()
                }
                if (event.key === 'Escape') {
                    handleClose()
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .manual-override-form .w-lg-140px { width: 140px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.TITLE.MANUALOVERRIDE' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <FormProvider {...methods}>
                    <form noValidate className='form manual-override-form' onSubmit={e => e.preventDefault()}>
                        <div className='card-body p-0'>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px w-lg-140px' htmlFor='set_override_for' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.MANUALOVERRIDEFORINACTIVEMEMBERSHIP.LABEL.SETOVERRIDEFOR">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDES.MODAL.LABEL.SETOVERRIDEFOR' })}</label>
                                <div className='col-lg-5'>
                                    <Controller
                                        name = 'set_override_for'
                                        render={({field: {onChange, value, label}}) => (
                                        <Select
                                            value={value !== undefined ? setOverrideForOptions().filter((ss) => ss.value === value):""}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}       
                                            options={setOverrideForOptions()}
                                            onChange={(e) => {
                                                methods.setValue('set_override_for', e.value, {shouldDirty: true})
                                            }}
                                            placeholder='Select'
                                            styles={reactSelectStyles}
                                            autoFocus
                                            tabIndex={1}
                                        />
                                        )}
                                    /> 
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px w-lg-140px' htmlFor='organization_code' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.MANUALOVERRIDEFORINACTIVEMEMBERSHIP.LABEL.ORGANIZATION">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDES.MODAL.LABEL.ORGANIZATION' })}</label>
                                <div className='col-lg-5'>
                                    <Controller
                                        name = 'organization_code'
                                        render={({field: {onChange, value, label}}) => (
                                        <Select
                                            value={value !== undefined ? setOrganizationOptionsForOverride().filter((ss) => ss.value === value):""}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}       
                                            options={setOrganizationOptionsForOverride()}
                                            onChange={(e) => {
                                                methods.setValue('organization_code', e.value, {shouldDirty: true})
                                            }}
                                            placeholder='Select'
                                            styles={reactSelectStyles}
                                            tabIndex={2}
                                        />
                                        )}
                                    /> 
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='OVERRIDEDATE' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.MANUALOVERRIDEFORINACTIVEMEMBERSHIP.LABEL.OVERRIEDDATE">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDES.MODAL.LABEL.OVERRIDEDATE' })}</label>
                                <div className='col-lg-5'>
                                    <div className="input-group date">
                                        <Controller
                                            {...methods.register('inactive_override_date', { required: true })}
                                            name="inactive_override_date"
                                            control={methods.control}
                                            render={({ field: { onChange, value, name } }) => {
                                                const formattedValue = (value && moment(value).isValid())
                                                    ? moment(value).format('MM/DD/YYYY')
                                                    : '';

                                                return (
                                                    <Flatpickr
                                                        autoComplete="off"
                                                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                                                        id="inactive_override_date"
                                                        placeholder="MM/DD/YYYY"
                                                        name={name}
                                                        value={formattedValue}
                                                        options={date_options}
                                                        onClose={(value, dateStr, instance) => {
                                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                            if (!isValidDate) {
                                                                instance.input.value = ''; // Clear the input if the date is invalid
                                                            }
                                                            setDate(dateStr, onChange); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                        }}
                                                        onChange={(value, dateStr, instance) => {
                                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                            if (!isValidDate) {
                                                                instance.input.value = ''; // Clear the input if the date is invalid
                                                            }
                                                            setDate(dateStr, onChange); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                        }}
                                                        onInput={(e) => { maskDateInput(e) }}
                                                        tabIndex={3}
                                                    />
                                                );
                                            }}
                                        />

                                        <div className="input-group-append">
                                            <label htmlFor='inactive_override_date' className='date-label'
                                                onClick={(e) => {
                                                    e.stopPropagation(); 
                                                    document.getElementById('inactive_override_date')._flatpickr.open(); 
                                                }}
                                            >
                                                <span className="input-group-text">
                                                    <i className="la la-calendar"></i>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='THISSHOWONLY'></label>
                                <div className='col-lg-7 form-check-sm form-check-custom ps-lg-3'>
                                    <input
                                        {...methods.register("this_show_only", {required: false})}
                                        className='form-check-input'
                                        type='checkbox'
                                        id='THISSHOWONLY'
                                        tabIndex={4}
                                    />
                                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='THISSHOWONLY' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.MANUALOVERRIDEFORINACTIVEMEMBERSHIP.LABEL.THISSHOWONLY"> {intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDES.MODAL.LABEL.THISSHOWONLY' })}</label>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='ACCEPTNAMEMISMATCH'></label>
                                <div className='col-lg-7 form-check-sm form-check-custom ps-lg-3'>
                                    <input
                                        {...methods.register("name_mismatch_accepted", {required: false})}
                                        className='form-check-input'
                                        type='checkbox'
                                        id='ACCEPTNAMEMISMATCH'
                                        disabled={setInputFieldsProperty('name_mismatch_accepted')}
                                        tabIndex={5}
                                    />
                                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='ACCEPTNAMEMISMATCH' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.MANUALOVERRIDEFORINACTIVEMEMBERSHIP.LABEL.ACCEPTNAMEMISMATCH"> {intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDES.MODAL.LABEL.ACCEPTNAMEMISMATCH' })}</label>
                                </div>
                            </div>
                            <div className={'row mb-2' + (setInputFieldsProperty('membership_level') ? ' d-none' : "")}>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px w-lg-140px' htmlFor='override_membershiplevel' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.MANUALOVERRIDEFORINACTIVEMEMBERSHIP.LABEL.MEMBERSHIPLEVEL">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDES.MODAL.LABEL.MEMBERSHIPLEVELS' })}</label>
                                <div className='col-lg-5'>
                                    <Controller
                                        name = 'override_membershiplevel'
                                        render={({field: {onChange, value, label}}) => (
                                        <Select
                                            value={value !== undefined ? getMembershipLevel().filter((ss) => ss.value === value):""}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}       
                                            options={getMembershipLevel()}
                                            onChange={(e) => {
                                                methods.setValue('override_membershiplevel', e.value, {shouldDirty: true})
                                                if(methods.getValues('inactive_override_reason') == ''){
                                                    methods.setValue('inactive_override_reason', 'Membership upgraded at show', {shouldDirty: true})
                                                }
                                            }}
                                            placeholder='Select'
                                            styles={reactSelectStyles}
                                            tabIndex={6}
                                        />
                                        )}
                                    /> 
                                </div>
                            </div>
                            <div className={'row mb-2' + (setInputFieldsProperty('ec_override_reason') ? ' d-none' : "")}>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='ec_reason' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.MANUALOVERRIDEFORINACTIVEMEMBERSHIP.LABEL.ECREASON">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDES.MODAL.LABEL.REASON' })}</label>
                                <div className='col'>
                                    <Controller
                                        name = 'ec_override_reason'
                                        render={({field: {onChange, value, label}}) => (
                                        <Select
                                            value={value !== undefined ? getECOverrideReasons().filter((ss) => ss.value === value):""}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}       
                                            options={getECOverrideReasons()}
                                            onChange={(e) => {
                                                methods.setValue('ec_override_reason', e.value, {shouldDirty: true})
                                                methods.setValue('ec_override_reason_label', e.label, {shouldDirty: true})
                                            }}
                                            placeholder='Select'
                                            styles={reactSelectStyles}
                                            tabIndex={7}
                                        />
                                        )}
                                    /> 
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='REASON' data-tooltip-id={setInputFieldsProperty('set_reason_label') ?  "ENTRIES.DETAIL.QUICKACTION.MANUALOVERRIDEFORINACTIVEMEMBERSHIP.LABEL.NOTES" : "ENTRIES.DETAIL.QUICKACTION.MANUALOVERRIDEFORINACTIVEMEMBERSHIP.LABEL.REASON"}> {setInputFieldsProperty('set_reason_label') ? intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDES.MODAL.LABEL.NOTES' }) : intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDES.MODAL.LABEL.REASON' })}</label>
                                <div className='col'>
                                    <textarea
                                        {...methods.register("inactive_override_reason", {required: true})}
                                        rows={3}
                                        className='form-control form-control-sm mb-2 mb-lg-0 fs-6 py-1'
                                        tabIndex={8}
                                    ></textarea>
                                </div>
                            </div>
                            <fieldset className={'mb-2' + (setInputFieldsProperty('hide_fee_applied') ? ' d-none' : "")}>
                                <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.GROUPLABEL.FEEAPPLIED' })} <b></b></legend>
                                <div className='row mb-2'>
                                    <div className='col-lg-12 form-check-sm form-check-custom ps-lg-3'>
                                        <input
                                            {...methods.register("owner_nonmemberfeepaid", {required: false})}
                                            className='form-check-input'
                                            type='checkbox'
                                            id='NONMEMBERSHIPFEEPAID'
                                            tabIndex={9}
                                        />
                                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor='NONMEMBERSHIPFEEPAID' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.MANUALOVERRIDEFORINACTIVEMEMBERSHIP.SECTION.FEEAPPLIED.LABEL.NONMEMBERSHIPFEEPAID"> {intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.NONMEMBERSHIPFEEPAID' })}</label>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-lg-12 form-check-sm form-check-custom ps-lg-3'>
                                        <input
                                            {...methods.register("owner_upgradefeepaid", {required: false})}
                                            className='form-check-input'
                                            type='checkbox'
                                            id='UPGRADEFEEPAID'
                                            tabIndex={10}
                                        />
                                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor='NONMEMBERSHIPFEEPAID' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.MANUALOVERRIDEFORINACTIVEMEMBERSHIP.SECTION.FEEAPPLIED.LABEL.UPGRADEFEEPAID"> {intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.UPGRADEFEEPLAN' })}</label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        {
                            showOverrideVerifyDialog && 
                            <SetMembershipOverridesVerifyDialog show={showOverrideVerifyDialog} closeMainDialog={handleClose} handleClose={()=>{setClearMembershipOverride(false); setOverrideVerifyDialog(false)}} overrideData={methods.getValues()} existingOverrides={existingOverrides} setExistingOverrides={setExistingOverrides} clearMemberbershipOverride={clearMemberbershipOverride} entries={entries}/>
                        }

                        <div className='card-footer d-flex justify-content-end py-3 px-0'>
                            <button type='button' className='btn btn-sm btn-secondary fw-bold' onClick={handleClear} tabIndex={12} data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.MANUALOVERRIDEFORINACTIVEMEMBERSHIP.BUTTON.CLEAROVERRIDE">
                                {intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDES.MODAL.BUTTON.CLEAROVERRIDE' })}
                            </button>

                            <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={13} data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.MANUALOVERRIDEFORINACTIVEMEMBERSHIP.BUTTON.DONE">
                                {intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDES.MODAL.BUTTON.DONE' })}
                            </button>

                            <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={overrideMembership} tabIndex={14} data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.MANUALOVERRIDEFORINACTIVEMEMBERSHIP.BUTTON.EXECUTE">
                                {intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDES.MODAL.BUTTON.EXECUTE' })}
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { SetMembershipOverridesForm }


