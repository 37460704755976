
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import { SeparatedDateTimeRenderer } from '../../modules/output-listing/renderers/DateTimeRenderer';

const QueuedJobsList = () => {
  const intl = useIntl()

  const columnDefs = [
    { field: 'sgl_id', maxWidth: 100, headerName: intl.formatMessage({ id: 'LIST.QUEUEDJOBS.ID' }), filter: 'agNumberColumnFilter'},
    { field: 'job_name', minWidth: 200, headerName: intl.formatMessage({ id: 'LIST.QUEUEDJOBS.JOBNAME' }), filter: 'agTextColumnFilter', },
    { field: 'status', minWidth: 200, headerName: intl.formatMessage({ id: 'LIST.QUEUEDJOBS.STATUS' }), filter: 'agTextColumnFilter', },
    { field: 'SglLogin.email_address', minWidth: 200, headerName: intl.formatMessage({ id: 'LIST.QUEUEDJOBS.USER' }), filter: 'agTextColumnFilter', },
    { field: 'unix_time1', minWidth: 100, headerName: intl.formatMessage({ id: 'LIST.QUEUEDJOBS.QUEUEDTIME' }), filter: 'agDateColumnFilter', headerClass: "ag-center-aligned-header", rowClass: "text-center", cellStyle: { textAlign: 'center' }, cellRendererFramework: (params) => {
      return (
          <SeparatedDateTimeRenderer params = {params} date={params?.data?.queued_time?.split("T")[0]} time={params?.data?.queued_time?.split("T")[1]}/>
      )}
    },
    { field: 'unix_time2', minWidth: 100, headerName: intl.formatMessage({ id: 'LIST.QUEUEDJOBS.COMPLETIONTIME' }), filter: 'agDateColumnFilter', headerClass: "ag-center-aligned-header", rowClass: "text-center", cellStyle: { textAlign: 'center' }, cellRendererFramework: (params) => {
      return (
          <SeparatedDateTimeRenderer params = {params} date={params?.data?.completion_time?.split("T")[0]} time={params?.data?.completion_time?.split("T")[1]}/>
      )}
    },
    // { field: 'username', minWidth: 200, headerName: intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.USER' }), filter: 'agTextColumnFilter', },
    { field: 'Company.urlpath', minWidth: 100, headerName: intl.formatMessage({ id: 'LIST.QUEUEDJOBS.COMPANY' }), filter: 'agTextColumnFilter', valueGetter: function getURLPath(params) { return (params?.data?.customer_id == 0)?"":params?.data?.Company.urlpath?.toUpperCase();} ,cellStyle: { textAlign: 'center' }}, 
    { field: 'processing_time', minWidth: 100, headerName: intl.formatMessage({ id: 'LIST.QUEUEDJOBS.PROCESSINGTIME' }), filter: 'agTextColumnFilter'}
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.QUEUEDJOBS' })}</PageTitle>
      <OutputListingComponent area={'QueuedJobs'} columnDefs={columnDefs} apiEndPoint={'utility/queued-jobs'} >
      </OutputListingComponent>
    </>
  )
}

export { QueuedJobsList }
