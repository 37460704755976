import { useIntl } from 'react-intl'

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import { useOutputContext, useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const useUpdateEntriesBalances = () => {
    const intl = useIntl()
    const {progressBar} = useProgress()
    const customerID = useAppSelector(state => state.showCompany.company_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const { getOutputSelectionAreaIDs, getEventID } = useOutputContext();
    const outputContextUpdater = useOutputContextUpdater()

    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const loadingOverlay = useLoadingOverlay()

    const updateEntriesBalances = async () => {
        loadingOverlay({ show: true })
        //Get selected peopls ids
        getOutputSelectionAreaIDs()
        .then(async (res) => { 
            if (res.length === 0) {
                loadingOverlay({ show: false })
                // error message if no entries selected
                alertDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.UPDATE.ENTRIES.BALANCES.NO.RECORD.SELECTED"}) })
            }
            else{
                let choice = await confirmDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.UPDATE.ENTRIES.BALANCES.CONFIRM"})})
                if(!choice){ 
                    loadingOverlay({ show: false })
                    return 
                }

                //Set progress bar
                const progressEventId = getEventID(`update_entries_balances_${customerID}`)
                progressBar({ show: true, eventID: progressEventId, showProgress: 'inline', title: "Updating Entries Balance", timeElapsed: true })
                
                axios.post( NEST_API_URL + `/entries/updateEntriesBalances`, {
                    customer_id: customerID,
                    entry_ids: res,
                    eventId: progressEventId
                })
                .then((response) => {
                    if(response?.data?.success){
                        alertDialog({message: `Balance updated for ${response?.data?.processedEntries} entries.`, title: 'Success', icon: 'info'});
                        //refresh list
                        outputContextUpdater({action: 'refresh'})
                    }else{
                        alertDialog({message: response.data.error});
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        alertDialog({message: error.response.data.error});
                    }
                })
                .finally( () => {
                    loadingOverlay({ show: false })
                    progressBar({ show: false, eventID: progressEventId})
                })
            }
        })
        .catch(() => loadingOverlay({ show: false }))
    }

    return updateEntriesBalances
    
}

export default useUpdateEntriesBalances


