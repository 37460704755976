import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { useFormContext } from 'react-hook-form';
import axios from 'axios';
import { useAppSelector } from '../../../../redux/hooks';
import { useEffect } from 'react';
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../../modules/sgl-utils/SglFunctions';
import { useAlert } from '../../../../modules/sgl-utils/DialogsProvider';

const modalsRoot = document.getElementById('root-modals') || document.body

const PmrRpChangeDialog = ({ show, handleClose, prevPeopleId, setPrevPeopleId, prevPeopleSource, setPrevPeopleSource, setDisplayEntriesChangesDetail, setEntriesAndARChangesDetail, rollbackPeoplePayments, entriesAndARChangesDetail, calledFor }) => {
    const intl = useIntl()
    const methods = useFormContext()
    const customer_id = useAppSelector(state=> state.showCompany.company_id)
    let alertDialog = useAlert()

    // Create dialog message
    let dialogMessage = (calledFor === 'PMR') ? intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.PRIZEMONEYRECIPIENT.CHANGE.CONFIRM.MESSAGE' }) : intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.RESPONSIBLEPARTY.CHANGE.CONFIRM.MESSAGE' })
    let sourceCurrentValue = (calledFor === 'PMR') ? methods.getValues('entry.prizemoneyrecipientsource') : methods.getValues('entry.responsiblepartysource')
    if(prevPeopleSource === sourceCurrentValue){
        let peopleChanged = (calledFor === 'PMR') ? 'Prize Money Recipient' : 'Responsible Party'
        if(prevPeopleSource == 1){ //Owner was set as PMR/RP 
            dialogMessage = `Owner is set as ${peopleChanged}. ${dialogMessage}`
        }else if(prevPeopleSource == 3){ //Trainer was set as PMR/RP 
            dialogMessage = `Trainer is set as ${peopleChanged}. ${dialogMessage}`
        }else if(![0, 4].includes(prevPeopleSource) && prevPeopleSource){ //Rider was set as PMR/RP 
            dialogMessage = `Rider is set as ${peopleChanged}. ${dialogMessage}`
        }
    }

    /*
        Rollback all the payments applied by that PMR (prize money) or RP (balance refund)
    */
    const rollbackThePaymentAppliedByPeople = async(event) => { 
        if(event?.target?.type == "button"){
            loadingSpinnerBtnWait(event)
        }

        if(calledFor === 'PMR'){
            methods.setValue('prize_money_action', 'PMR Change')
            //rollback the applied prize money
            let prizeMoneyRollback = await rollbackPeoplePayments(false)
            if(!prizeMoneyRollback){
                closeDialog()
                return 
            }

            setPrevPeopleId( methods.getValues('entry.prizemoneyrecipient_id'))
            setPrevPeopleSource( methods.getValues('entry.prizemoneyrecipientsource'))

        }else if(calledFor === 'RP'){
            //rollback the balance refunds
            let rollbackBalanceRefund = await rollbackPeoplePayments()
            if(!rollbackBalanceRefund){
                closeDialog()
                return 
            }

            methods.setValue('balanceRefunded', true)
            setPrevPeopleId( methods.getValues('entry.responsibleparty_id'))
            setPrevPeopleSource( methods.getValues('entry.responsiblepartysource'))
        }

        if(event?.target?.type == "button"){
            loadingSpinnerBtnRelease(event)
        }

        //clear data
        setEntriesAndARChangesDetail(null)

        //close dialog
        handleClose()
    }

    const closeDialog = () => { //Don't update the PMR or RP
        if(calledFor === 'PMR'){
            methods.setValue('entry.prizemoneyrecipient_id', prevPeopleId, {shouldDirty: true})
            if(prevPeopleSource !== methods.getValues('entry.prizemoneyrecipientsource')){
                // PMR changed from PMR section
                methods.setValue('entry.prizemoneyrecipientsource', prevPeopleSource, {shouldDirty: true})
            }else{
                // Set PMR source to Others if RTO was used as PMR and people record is changed
                methods.setValue('entry.prizemoneyrecipientsource', 4, {shouldDirty: true}) 
            }
        }else if(calledFor === 'RP'){
            methods.setValue('entry.responsibleparty_id', prevPeopleId, {shouldDirty: true})
            if(prevPeopleSource !== methods.getValues('entry.responsiblepartysource')){
                // RP changed from RP section
                methods.setValue('entry.responsiblepartysource', prevPeopleSource, {shouldDirty: true})
            }else{
                // Set RP source to Others if RTO was used as RP and people record is changed
                methods.setValue('entry.responsiblepartysource', 4, {shouldDirty: true})
            }
        }
        //clear data
        setEntriesAndARChangesDetail(null)

        handleClose()
    }

    const loadPrizeMoneyRollbackDetails = async() => {
        if(!prevPeopleId || !methods.getValues('entry')?.entry_id){
            return 
        }

        let paymentxEntriesForOtherEntries = methods.getValues('paymentxEntriesForOtherEntries') ? methods.getValues('paymentxEntriesForOtherEntries').filter((pxe) => pxe.sgl_id <= 0) : []
        let tripsWithAppliedPrizeMoney = methods.getValues('trips')?.filter((trip) => trip.prize_money_spent)
        let tripsWithAppliedPrizeMoneyIds = tripsWithAppliedPrizeMoney?.map((trip) => trip.entryxclasses_id)
        //load the entries whose balance will be changed after prize money is rolled back
        await axios.post( process.env.REACT_APP_NEST_API_URL + '/accounting/loadEntriesAndAccountRegisterDataOnPMRChange', {
            customer_id: customer_id,
            entry: methods.getValues('entry'),
            entry_id: methods.getValues('entry').entry_id,
            trip_ids: tripsWithAppliedPrizeMoneyIds,
            pmr_id: prevPeopleId,
            paymentxentries: paymentxEntriesForOtherEntries,
            accountRegisters: methods.getValues('updated_account_registers') ? methods.getValues('updated_account_registers').filter((ar) => ar.accountingregister_id <= 0) : []
        }).then( async response => {
            if(response?.data?.success){
                setEntriesAndARChangesDetail(response.data)
            }else{
                alertDialog({message: response?.data?.error})
            }
        })
    }

    const loadBalanceRefundRollbackDetails = async() => {
        if(!prevPeopleId || !methods.getValues('entry')?.entry_id){
            return 
        }
        let paymentxEntriesForOtherEntries = methods.getValues('paymentxEntriesForOtherEntries') ? methods.getValues('paymentxEntriesForOtherEntries').filter((pxe) => pxe.sgl_id <= 0) : []
        //load the entries whose balance will be changed after balance refund is rolled back
        await axios.post( process.env.REACT_APP_NEST_API_URL + '/accounting/loadEntriesAndAccountRegisterDataOnRPChange', {
            customer_id: customer_id,
            entry: methods.getValues('entry'),
            entry_id: methods.getValues('entry').entry_id,
            rp_id: prevPeopleId,
            paymentxentries: paymentxEntriesForOtherEntries,
            accountRegisters: methods.getValues('updated_account_registers') ? methods.getValues('updated_account_registers').filter((ar) => ar.accountingregister_id <= 0) : []
        }).then( async response => {
            if(response.data.success){
                setEntriesAndARChangesDetail(response.data)
            }else{
                alertDialog({message: response?.data?.error})
            }
        })
    }

    useEffect(() => {
        if(calledFor === 'PMR'){
            loadPrizeMoneyRollbackDetails()
        }else if(calledFor === 'RP'){
            loadBalanceRefundRollbackDetails()
        }
    }, [])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px search-form'
            show={show}
            onHide={closeDialog}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    rollbackThePaymentAppliedByPeople(event)
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'DIALOG.COMMON.TITLE.CONFIRM' })}</h2>
                
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={closeDialog}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-3'>
                        <div className='col-lg-1 d-flex align-self-center'>{<i className="fa-sharp fa-solid fa-circle-info fa-2xl" style={{ color: '#578EBE' }}></i>}</div>
                        <div className='col ms-lg-2'>
                            <span className="d-inline-block align-bottom" dangerouslySetInnerHTML={{__html: dialogMessage}}></span>
                        </div>
                        </div>
                    </div>
                    
                    <div className='card-footer d-flex justify-content-between py-3 px-0'>
                        <div>
                            {
                                <button className='btn btn-sm btn-secondary fw-bold me-3 text-uppercase' type="button" onClick={() => setDisplayEntriesChangesDetail(true)}>
                                { intl.formatMessage({ id: "FORM.INPUT.ENTRIES.TAB.CLASSES.PRIZEMONEYRECIPIENT.CHANGE.BUTTON.VIEWDETAILS"})}
                                </button>
                            }
                            
                        </div>
                        <div>
                            <button className='btn btn-sm btn-secondary fw-bold me-3 text-uppercase' type="button" onClick={closeDialog}>
                                { intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CANCEL"})}
                            </button>
                            <button className='btn btn-sm btn-dark fw-bold text-uppercase' type="button" onClick={rollbackThePaymentAppliedByPeople} autoFocus>
                                <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                                { intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.OK"})}
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { PmrRpChangeDialog }