import {useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

import { useIntl } from 'react-intl'
import "flatpickr/dist/themes/material_blue.css";
import { KTSVG } from '../../../_metronic/helpers';
import { useForm } from 'react-hook-form';
import { useOutputContext } from '../../modules/output-listing/OutputListingContext'
import { useAppSelector } from '../../redux/hooks';
import { searchOptions } from '../../modules/sgl-utils/reactSelectOptions';
import ReactSelectComponent from '../../modules/sgl-utils/ReactSelectComponent';

type Props = {
    show: boolean
    onSubmitForm: ({}) => void,
    onClearFilter: () => void,
    handleClose: () => void
  }

const modalsRoot = document.getElementById('root-modals') || document.body

const SearchOrganizationsForm = ({ show, onSubmitForm, onClearFilter, handleClose }: Props) => {
    const intl = useIntl()
    
    const { register, reset, getValues, control } = useForm({});
    const context=useOutputContext()

    const handleReset = () => {
        reset();
    }

    const handleSave = async () => {
        var data = getValues();
        onSubmitForm(data);
    }

    useEffect(() => {
        reset(context.outputAdvanceFilter,{keepDefaultValues:true})
    },[]);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-650px search-form'
            show={show}
            onHide={handleClose}
            onKeyPress={(event:any) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) { //call function only if no button is in focus
                  handleSave();
                }
                if (event.key === "Escape") {
                  handleClose();
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.ORGANIZATIONS.TITLE.SEARCHORGANIZATIONS' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='ORGANIZATIONNAME'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.ORGANIZATIONS.LABEL.ORGANIZATIONNAME' })}</label>
                            <div className='col-lg-6'>
                                <input
                                    id='ORGANIZATIONNAME'
                                    type='text'
                                    {...register("org_name")}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.ORGANIZATIONS.LABEL.ORGANIZATIONNAME' })}
                                    autoFocus
                                    tabIndex={1}
                                />
                            </div>
                        </div>

                        <div className='row mb-2'>
                            <div className='col-lg-3 offset-lg-3 d-flex align-items-center'>
                                <div className='form-check-sm form-check-custom me-2'>
                                    <input
                                        className='form-check-input'
                                        {...register("horse")}
                                        type='checkbox'
                                        id='ISFORHORSE'
                                        tabIndex={8}
                                    />
                                </div>
                                <label className='col-form-label fw-bold fs-5 py-1' htmlFor='ISFORHORSE'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.ORGANIZATIONS.LABEL.ISFORHORSE' })}</label>
                            </div>

                            <div className='col-lg-3  d-flex align-items-center'>
                                <div className='form-check-sm form-check-custom me-2'>
                                    <input
                                        className='form-check-input'
                                        {...register("person")}
                                        type='checkbox'
                                        id='ISFORPEOPLE'
                                        tabIndex={8}
                                    />
                                </div>
                                <label className='col-form-label fw-bold fs-5 py-1' htmlFor='ISFORPEOPLE'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.ORGANIZATIONS.LABEL.ISFORPEOPLE' })}</label>
                            </div>
                        </div>
               
                        <div className='row mb-2'>
                            <div className='col-lg-9 offset-lg-3 d-flex align-items-center'>
                                <div className='form-check-sm form-check-custom me-2'>
                                    <input
                                        className='form-check-input'
                                        {...register("system")}
                                        type='checkbox'
                                        id='ISSYSTEMORG'
                                        tabIndex={8}
                                    />
                                </div>
                                <label className='col-form-label fw-bold fs-5 py-1' htmlFor='ISSYSTEMORG'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.ORGANIZATIONS.LABEL.ISSYSTEMORG' })}</label>
                            </div>
                        </div>

                    

                        <div className='row mb-2'>
                            <div className='col-lg-9 offset-lg-3 d-flex align-items-center'>
                                <div className='form-check-sm form-check-custom me-2'>
                                    <input
                                        className='form-check-input'
                                        {...register("exact_matches")}
                                        type='checkbox'
                                        id='EXACTMATCHESONLY'
                                        tabIndex={8}
                                    />
                                </div>
                                <label className='col-form-label fw-bold fs-5 py-1' htmlFor='EXACTMATCHESONLY'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.DIVISIONS.LABEL.EXACTMATCHESONLY' })}</label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='SEARCH'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.DIVISIONS.LABEL.SEARCH' })}</label>
                            <div className='col-lg-6'>
                                <ReactSelectComponent 
                                    name='search'
                                    id='SEARCH'
                                    options={searchOptions} 
                                    control={control} 
                                    methods={null} 
                                    tabIndex={9}
                                />
                            </div>
                        </div>
                    
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type="button" className='btn btn-sm btn-secondary fw-bold' onClick={handleReset} tabIndex={10}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CLEARVALUES' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={11}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-dark fw-bold' onClick={handleSave} tabIndex={12}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.SEARCH' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { SearchOrganizationsForm }