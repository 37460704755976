/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { BooleanFilterOptions } from '../../modules/output-listing/filters/BooleanFilter';

const HorsesList = () => {
  const intl = useIntl();
  const navigate = useNavigate()
  const customer_id = useAppSelector(state=> state.showCompany.company_id);

  const columnDefs = [
    // { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.COMMONS.SGLID'}), filter: 'agNumberColumnFilter', filterParams: { suppressAndOrCondition: true,}, },
    { field: 'horse_id', headerName: intl.formatMessage({ id: 'LIST.HORSES.HORSEID' }), filter: 'agNumberColumnFilter',hide: true,  cellStyle: params => { if (params?.data?.on_vet_hold) { return {color: 'var(--kt-primary)'}; } return null; } },
    { field: 'organization', headerName: intl.formatMessage({ id: 'LIST.HORSES.ORGANIZATION' }), sortable: false, filter: false, cellStyle: params => { if (params?.data?.on_vet_hold) { return {color: 'var(--kt-primary)'}; } return null; } },
    { field: 'membership_id', headerName: intl.formatMessage({ id: 'LIST.HORSES.MEMBERSHIPID' }), sortable: true, filter: false,  cellStyle: params => { if (params?.data?.on_vet_hold) { return {color: 'var(--kt-primary)'}; } return null; }},
    { field: 'name', width: 300, headerName: intl.formatMessage({ id: 'LIST.HORSES.NAME' }), filter: 'agTextColumnFilter', cellStyle: params => { if (params?.data?.on_vet_hold) { return {color: 'var(--kt-primary)'}; } return null; } },
    { field: 'lf_name', width: 300, headerName: intl.formatMessage({ id: 'LIST.HORSES.LFNAME' }), filter: 'agTextColumnFilter',  cellStyle: params => { if (params?.data?.on_vet_hold) { return {color: 'var(--kt-primary)'}; } return null; }},
    { field: 'height', headerName: intl.formatMessage({ id: 'LIST.HORSES.HEIGHT' }), filter: 'agTextColumnFilter', cellStyle: params => { if (params?.data?.on_vet_hold) { return {color: 'var(--kt-primary)'}; } return null; } },
    { field: 'status', headerName: intl.formatMessage({ id: 'LIST.HORSES.STATUS' }), filter: 'agTextColumnFilter',  cellStyle: params => { if (params?.data?.on_vet_hold) { return {color: 'var(--kt-primary)'}; } return null; }},
    { field: 'on_vet_hold', headerName: intl.formatMessage({ id: 'LIST.HORSES.HOLD' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: params => { const cellValue = params.value ? 'On Vet Hold' : ''; return <span style={{ color: 'var(--kt-primary)' }}>{cellValue}</span>; }},
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.HORSES' })}</PageTitle>
      <OutputListingComponent key={customer_id} area={'Horses'} columnDefs={columnDefs} apiEndPoint={'horses/list'}
        onRowDoubleClicked={(row_data) => {navigate('/horses/detail/'+row_data.horse_id+`?customer_id=${customer_id}`)}} 
        onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL+'/horses/detail/'+row_data.horse_id+`?customer_id=${customer_id}`,'_blank')}>
      </OutputListingComponent>
    </>
  )
}

export {HorsesList}
