
import axios from "axios"
import { useOutputContext } from "../../../modules/output-listing/OutputListingContext"
import { useAlert, useProgress } from "../../../modules/sgl-utils/DialogsProvider"
import { useAppSelector } from "../../../redux/hooks"
import useFileDownloader from "../../../modules/hooks/use-file-downloader"

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const useExportDivisionClassAnalysis = () => {
    const { getOutputSelectionSelectedRowsAreaIDs, getEventID } = useOutputContext()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const alertDialog = useAlert()
    const { startFileDownloader, forceCloseFileDownloader } = useFileDownloader()
    const {progressBar} = useProgress()

    const exportDivisionClassAnalysis = async () => {

        const fileBufferEventId = getEventID('export-division-class-analysis-fb')
        const progressBarEventId = getEventID('export-division-class-analysis-pb')
        try {
            const circuitIds = await getOutputSelectionSelectedRowsAreaIDs()
            if (circuitIds.length === 0) {
                alertDialog({ message: 'Please select at least one circuit at a time to export division class analysis.' })
            } else if (circuitIds.length > 1){
                alertDialog({ message: 'Division class analysis can only be exported for single circuit.' })   
            } else { 
                progressBar({ show: true, eventID: progressBarEventId, title: 'Export Division Class Analysis' })
                startFileDownloader(fileBufferEventId)

                const response = await axios.get(`${NEST_API_URL}/circuits/exportDivisionClassAnalysis`, {
                    params: {
                        customer_id: customerId,
                        circuit_id: circuitIds[0],
                        progress_bar_event_id: progressBarEventId,
                        file_buffer_event_id: fileBufferEventId
                    }
                })
            }
        } catch (reason) {
            forceCloseFileDownloader()   
        } finally {
            progressBar({ show: false, eventID: progressBarEventId })
        }
    }

    return exportDivisionClassAnalysis
}

export default useExportDivisionClassAnalysis