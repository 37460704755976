import axios from "axios"
import { useOutputContext } from "../../../modules/output-listing/OutputListingContext"
import { useAlert, useConfirm, useLoadingOverlay } from "../../../modules/sgl-utils/DialogsProvider"
import { useAppSelector } from "../../../redux/hooks"

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const useUpdateStandings = () => {
    const { getOutputSelectionSelectedRowsAreaIDs } = useOutputContext()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const loadingOverlay = useLoadingOverlay()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()

    const updateStandings = async () => {

        try {
            loadingOverlay({ show: true })

            const circuitIds = await getOutputSelectionSelectedRowsAreaIDs()
            if (circuitIds.length === 0) {
                alertDialog({ message: 'Please select atleast one circuit at a time to calculate standings.' })
            } else if (circuitIds.length > 1){
                alertDialog({ message: 'Please select one circuit at a time to calculate standings.' })   
            } else { 
               
                const choice = await confirmDialog({ message: 'This will re-calculate all standings for the selected circuit. Do you want to continue?'})
                if (choice) {
                    const response = await axios.post(`${NEST_API_URL}/circuit-divisions/calculateCircuitStandings`, {
                        customer_id: customerId,
                        circuit_id: circuitIds[0],
                        execution_schedule: {
                            runImmediately: true,
                            schedule: { date: null, time: null } 
                        } 
                    })

                            
                    if (response.data.success) {
                        alertDialog({ message: `Standings for all divisions are in queue for processing.`, icon: 'info'})
                    } else {
                        alertDialog({ message: response.data.error })
                    }
                }
            }
        } catch (reason) {
            
        } finally {
            loadingOverlay({ show: false })
        }
    }

    return updateStandings
}

export default useUpdateStandings