import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import Select from "react-select";
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls';
import { useAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers';
import { downloadTextFile } from '../../../modules/sgl-utils/SglFunctions';
import useGenerateExport from '../../../modules/hooks/use-generate-export';

const modalsRoot = document.getElementById('root-modals') || document.body

const EntriesExportFeePaidEmails = ({ show, handleClose }) => {
    const intl = useIntl() 
    const alertDialog = useAlert()
    const customerId = useAppSelector(state=> state.showCompany.company_id)
    const loadingOverlay = useLoadingOverlay()
    const [feePaidYears, setFeePaidYears] = useState([])
    const [masterFees, setMasterFees] = useState([])
    const [roles, setRoles] = useState({
        riders: false,
        trainer: false,
        owner: false,
        rp: false,
        pmr: false
    })
    const [feePaidYear, setFeePaidYear] = useState("")
    const [masterFeeId, setMasterFeeId] = useState("")
    const generateExport = useGenerateExport()


    useEffect(()=> {
        if(!show){
            return;
        }
        loadingOverlay({ show: true })

        axios.get( process.env.REACT_APP_NEST_API_URL + `/entries/getExportFeePaidEmailsMetaData?customer_id=${customerId}`)
        .then(
            (res) => {
                if(res.data.success){
                    setFeePaidYears(res.data.feePaidYears.map(year => ({ label: year, value: year })));
                    res.data.masterFees.unshift({name: "All", master_fee_id: -1 }) //Adding first option as all
                    setMasterFees(res.data.masterFees.map(masterFee => ({ label: masterFee.name, value: masterFee.master_fee_id })))
                }
            }
        )
        .catch( (err) => {
            handleClose()
        })
        .finally( ()=>{
            loadingOverlay({ show: false })
        })
    }, [show])

    useEffect(() => {
        if(feePaidYears.length > 0)
            setFeePaidYear(feePaidYears[feePaidYears.length - 1].value)
    }, [feePaidYears])

    const exportFeePaidEmails = () => {
        if(!masterFeeId){
            alertDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTFEEPAIDEMAILS.SELECTFEES" })})
            return
        } 

        if(!feePaidYear){
            alertDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTFEEPAIDEMAILS.SELECTYEAR" })})
            return
        }
        if(!(roles.riders || roles.trainer || roles.owner || roles.rp || roles.pmr)){
            alertDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTFEEPAIDEMAILS.SELECTROLE" })})
            return;
        }

        const settings = {
            url: 'entries/exportFeePaidEmails',
            progressBarTitle: 'Preparing Data for Export',
            feePaidYear: feePaidYear,
            masterFeeId: masterFeeId,
            roles: roles,
            customer_id: customerId,
        };

        // Generate export
        generateExport(settings);

        // loadingOverlay({ show: true })

        // axios.post(process.env.REACT_APP_NEST_API_URL + "/entries/exportFeePaidEmails", {
        //     fee_paid_year: feePaidYear,
        //     master_fee_id: masterFeeId,
        //     roles: roles,
        //     customer_id: customerId,
        // }).then(
        //     (res) => {
        //         if(res.data.success){
        //             downloadTextFile(res.data.emailTextData, res.data.filename)
        //             handleClose()
        //         }
        //         else{
        //             alertDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTFEEPAIDEMAILS.NORECORDS" })})
        //         }
        //     }
        // ).catch((e) => {
        // })
        // .finally( () => {
        //     loadingOverlay({ show: false })
        // })

    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered export-fee-paid-emails-modal mw-400px '
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => {
                let activeElement = document.activeElement
                let isButtonFocused = false
                if (activeElement && activeElement.tagName === 'BUTTON') {
                    isButtonFocused = true
                }
                if (event.key === "Enter" && !isButtonFocused) {
                  exportFeePaidEmails()
                }
                if (event.key === "Escape") {
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTFEEPAIDEMAILS.MODAL.HEADER" })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-4 px-10'>
                <div className='row mb-2 align-items-center'>
                    <label className='col-lg-4 col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.FILTERENTRIESFORRTOEXPORT.LABEL.YEAR">{intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTFEEPAIDEMAILS.LABEL.YEAR" })}</label>
                    <div className='col-lg-8'>
                        <Select
                            id="year"
                            value={feePaidYears.find(option => option.value == feePaidYear)}
                            styles={reactSelectStyles}
                            options={feePaidYears}
                            onChange={e => setFeePaidYear(e.value)}
                        />
                    </div>
                </div>
                <div className='row mb-2 align-items-center'>
                    <label className='col-lg-4 col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.FILTERENTRIESFORRTOEXPORT.LABEL.FEEPAID">{intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTFEEPAIDEMAILS.LABEL.FEEPAID" })}</label>
                    <div className='col-lg-8'>
                        <Select
                            id="feePaid"
                            styles={reactSelectStyles}
                            options={masterFees}
                            onChange={e => setMasterFeeId(e.value)}
                            placeholder={""}
                            // value={feePaidName}
                            autoFocus
                        />
                    </div>
                </div>
                <div className='row mb-2 align-items-center'>
                    <label className='col-lg-4 col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.FILTERENTRIESFORRTOEXPORT.LABEL.ROLE">{intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTFEEPAIDEMAILS.LABEL.ROLE" })}</label>
                </div>
                <div className='row mb-2 px-6 align-items-center'>
                    <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='Riders'
                                value= '1'
                                onChange={ e => setRoles({ ...roles, riders: e.target.checked })}
                            />
                        </div>
                        <label className='col-form-label fs-6 py-0' htmlFor='Riders' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.FILTERENTRIESFORRTOEXPORT.LABEL.RIDERS"> {intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTFEEPAIDEMAILS.LABEL.ROLE.RIDERS" })} </label>
                    </div>
                    <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='Trainers'
                                value= '1'
                                onChange={ e => setRoles({...roles, trainer: e.target.checked })}
                            />
                        </div>
                        <label className='col-form-label fs-6 py-0' htmlFor='Trainers' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.FILTERENTRIESFORRTOEXPORT.LABEL.TRAINERS"> {intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTFEEPAIDEMAILS.LABEL.ROLE.TRAINERS"})} </label>
                    </div>
                </div>
                <div className='row mb-2 px-6 align-items-center'>
                    <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='Owners'
                                value= '1'
                                onChange={ e => setRoles({ ...roles, owner: e.target.checked })}
                            />
                        </div>
                        <label className='col-form-label fs-6 py-0' htmlFor='Owners' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.FILTERENTRIESFORRTOEXPORT.LABEL.OWNERS"> {intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTFEEPAIDEMAILS.LABEL.ROLE.OWNERS" })} </label>
                    </div>
                    <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='ResponsibleParty'
                                value= '1'
                                onChange={ e => setRoles({ ...roles, rp: e.target.checked })}
                            />
                        </div>
                        <label className='col-form-label fs-6 py-0' htmlFor='ResponsibleParty' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.FILTERENTRIESFORRTOEXPORT.LABEL.RESPONSIBLEPARTY"> {intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTFEEPAIDEMAILS.LABEL.ROLE.RP" })} </label>
                    </div>
                </div>
                <div className='row mb-2 px-6 align-items-center'>
                    <div className='col-lg-8 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='PrizeMoneyRecipients'
                                value= '1'
                                onChange={ e => setRoles({ ...roles, pmr: e.target.checked })}
                            />
                        </div>
                        <label className='col-form-label fs-6 py-0' htmlFor='PrizeMoneyRecipients' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.FILTERENTRIESFORRTOEXPORT.LABEL.PRIZEMONEYRECIPIENT"> {intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTFEEPAIDEMAILS.LABEL.ROLE.PMR" })} </label>
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-3 px-0'>
                    <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={handleClose}>
                        Cancel
                    </button>

                    <button 
                        type="button" 
                        className='btn btn-sm btn-dark fw-bold text-uppercase'
                        onClick={exportFeePaidEmails}
                        data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.FILTERENTRIESFORRTOEXPORT.BUTTON.SEARCH"
                    >
                        <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                        Search
                    </button>
                </div>
            </div>

            </Modal>, modalsRoot)
}

export default EntriesExportFeePaidEmails;