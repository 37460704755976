/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import ORGForHorses from './ORGForHorses';
import ORGForPersons from './ORGForPersons';
import { useOutputContext } from '../../modules/output-listing/OutputListingContext'
import { useForm, FormProvider } from 'react-hook-form'
import { useAlert } from '../../modules/sgl-utils/DialogsProvider';
import axios from 'axios'
import { useAppSelector } from '../../redux/hooks'

// Constants
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

type Props = {
  show: boolean
  handleClose: () => void
  setAreResultsCleared: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const VerifyEntriesForm = ({show, handleClose, setAreResultsCleared }: Props) => {
  const intl = useIntl()
  const alertDialog = useAlert()
  const customerID = useAppSelector(state => state.showCompany.company_id);
  const [horseOrganizations, setHorseOrganizations] = useState([])
  const [peopleOrganizations, setPeopleOrganizations] = useState([])

  const { outputVerificationData, setOutputVerificationData , setShowVerificationColumns, outputIncludeAllShows } = useOutputContext()
  let defaultValues = {
    "formData":{
      "ushja_horse":false,
      "ec_horse":false,
      "usef_horse":false,
      "fei_horse":false,
      "usef_rto":false,
      "ec_rto":false,
      "ushja_rto":false,
      "fei_rto": false,
      "verify_all":false,
      "verify_class":false,
      "horse_type":false,
      "meas_card":false,
      "horse_age_verified":false,
      "horse_age":false,
      "horse_height": false,
      "rider_status": false,
      "rider_age":false,
      "rider_membership":false,
      "microchip":false,
      "past_balance":false,
      "signature_obtained":false,
      "ssn":false,
      "includeAllShows": outputIncludeAllShows,
      "taxStatus_rto":false,
    },
    "ORGForHorses":{},
    "ORGForPersons":{}, 
    "number_of_prefs":0
  }

  const methods = useForm({
    defaultValues
  })
  
  let watchVerifyClassrequirements = methods.watch("formData.verify_class");
  let [horseType, setHorsetype] = useState(false)
  let [horseHeight, setHorseHeight] = useState(false)
  let [horseAgeVerified, setHorseAgeVerified] = useState(false)
  let [horseAge, setHorseAge] = useState(false)
  let [measCard, setMeasCard] = useState(false)
  let [riderAge, setRiderAge] = useState(false)
  let [riderMembership, setRiderMembership] = useState(false)
  let [riderStatus, setRiderStatus] = useState(false)

  const onSubmit = async() => {
    if(shouldVerify()){ // Check if user has selected at least one criterion
      setAreResultsCleared(false) // Reset result clear state

      if(typeof outputVerificationData === 'object' && typeof methods.getValues() === 'object' && JSON.stringify(outputVerificationData) != JSON.stringify(methods.getValues())){
        // webservice to update the user prefs
        await axios.post( process.env.REACT_APP_NEST_API_URL + '/preferences/saveUserPref', {
          pref_name: 'VerificationToolData',
          pref_value: JSON.stringify(methods.getValues()),
          customer_id: customerID
        })
      }

      setOutputVerificationData({...methods.getValues(), verifyData: true})

			// Show verifications column
			setShowVerificationColumns(true)

      handleCustomClose()
    } else {
      alertDialog({message: 'Please define the criteria to verify the entry status and try again.', title: 'warning'})
      handleCustomClose()
    }
  }

  const setRequirements = (value: any) =>{
    if (value == false){ 
      methods.setValue("formData.horse_type", horseType, {shouldDirty:true})
      methods.setValue("formData.rider_age", riderAge, {shouldDirty:true})
      methods.setValue("formData.rider_status", riderStatus, {shouldDirty:true})
      methods.setValue("formData.rider_membership", riderMembership, {shouldDirty:true})
      methods.setValue("formData.horse_age_verified", horseAgeVerified, {shouldDirty:true})
      methods.setValue("formData.meas_card", measCard, {shouldDirty:true})
      methods.setValue("formData.horse_age", horseAge, {shouldDirty:true})
      methods.setValue("formData.horse_height", horseHeight, {shouldDirty:true})
    } else {
      methods.setValue("formData.horse_type", false, {shouldDirty:true})
      methods.setValue("formData.rider_age", false, {shouldDirty:true})
      methods.setValue("formData.rider_status", false, {shouldDirty:true})
      methods.setValue("formData.rider_membership", false, {shouldDirty:true})
      methods.setValue("formData.horse_age_verified", false, {shouldDirty:true})
      methods.setValue("formData.meas_card", false, {shouldDirty:true})
      methods.setValue("formData.horse_age", false, {shouldDirty:true})
      methods.setValue("formData.horse_height", false, {shouldDirty:true})
    }
    
    methods.setValue("formData.verify_class", value, {shouldDirty:true})
    return value
  }
  
  const shouldVerify = () => {

    // get total count of progress bar items
    let number_of_prefs = 0;
    
    // for non system organization of horses, get orgs
    var sizeOfOrgHorses = Object.keys(methods.getValues('ORGForHorses')).length;
    if( sizeOfOrgHorses>0 ){
      number_of_prefs = number_of_prefs+1
    }

    // for non system organization of persons, get orgs
    var sizeOfORGForPersons = Object.keys(methods.getValues('ORGForPersons')).length;
    if( sizeOfORGForPersons>0 ){
      number_of_prefs = number_of_prefs+1
    }

    // increment number_of_prefs for each checked checkbox
    Object.keys(methods.getValues('formData')).forEach(key => {
      if (methods.getValues(("formData." + key) as any)) {
        number_of_prefs++;
      }
    });

    if(number_of_prefs>0){
      methods.setValue("number_of_prefs", number_of_prefs, {shouldDirty:true})
      return true
    }
      
		methods.setValue("number_of_prefs", 0, {shouldDirty:true})
    return false
  }

  const getVerificationToolPrefs = async() => {
    let prefValue
    await axios.get(`${NEST_API_URL}/preferences/getUserPrefs?customer_id=${customerID}&pref_name=VerificationToolData`)
    .then((response) => {
      if(response.data){
        prefValue = JSON.parse(response.data.prefvalue)
        setOutputVerificationData(prefValue)
        methods.reset(prefValue)
      }
    })

    return prefValue
  }

  const handleCustomClose = () => {
    methods.reset(defaultValues)
    setHorseOrganizations([])
		setPeopleOrganizations([])
    handleClose()
  }

  useEffect(() => {
		if (show && horseOrganizations.length === 0 && peopleOrganizations.length === 0) {
			axios.get(`${NEST_API_URL}/entries/getVerifyEntryMetaData`, {
                params: {
                    customer_id: customerID, 
                    area: 'Verify Entry' 
                }
            }) // Added area because organizations are excluded on the basis of area
      .then(async(response) => { 
        let prefValue: any = await getVerificationToolPrefs()

				let org_for_horse_data: any = [];
				org_for_horse_data = response?.data?.horseOrganizations?.map((org: any) => {
          let prefHorseOrgs = Array.isArray(prefValue?.ORGForHorses) ? prefValue?.ORGForHorses?.find((horseOrg: any) => horseOrg == org.code) : null
          return { select: prefHorseOrgs ? true : false, org_for_horses: org.code }
				})
	
				let org_for_people_data: any = [];
				org_for_people_data = response?.data?.peopleOrganizations?.map((org: any) => {
          let prefPersonOrgs = Array.isArray(prefValue?.ORGForPersons) ? prefValue?.ORGForPersons?.find((personOrg: any) => personOrg.org_for_persons == org.code) : null
          if(prefPersonOrgs){
            return prefPersonOrgs
          }
					return {select: false, org_for_persons: org.code ,r: false, t:false, o:false}
				})

        setHorseOrganizations(org_for_horse_data)
				setPeopleOrganizations(org_for_people_data)
    	}) 
		}
  }, [show, horseOrganizations, peopleOrganizations])

  return createPortal(
    <Modal
      id='kt_modal_verify_entries'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal modal-dialog modal-dialog-centered mw-850px search-form'
      show={show}
      onHide={handleCustomClose}
      onKeyDown={(event: any) => {
        let isButtonFocused = false
          let activeElement = document.activeElement //gets the currently focussed element
          if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
              isButtonFocused = true;
          }
        if (event.key == 'Enter' && !isButtonFocused) { //call function only when no button is in focus
          onSubmit()
        }
      }}
    >
      <div className='modal-header py-0 px-4'>
        <h2 className="fs-4">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.TITLE.VERIFYENTRIES'})}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleCustomClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4'>
      <FormProvider {...methods}>
          <form noValidate className='form' onSubmit={e => e.preventDefault()}>
            <div className='card-body p-0'>

            <div className="row mb-2">
              <div className='col-lg-6'> 
                <fieldset>
                    <legend className="fs-5 fw-bold d-flex"><label data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.SYSTEMORGANIZATIONHORSES.LABEL.SYSTEMORGANIZATIONHORSE">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.GROUPLABEL.SYSTEMORGHORSES'})}</label><b></b></legend>

                    <div className='row mb-2'>
                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='USHJAHORSE'
                            {...methods.register("formData.ushja_horse")}
                            autoFocus
                            tabIndex={1}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="USHJAHORSE" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.SYSTEMORGANIZATIONHORSES.LABEL.USHJAHORSE">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.USHJAHORSE'})}</label>
                      </div>

                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='ECHORSE'
                            {...methods.register("formData.ec_horse")}
                            tabIndex={2}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="ECHORSE" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.SYSTEMORGANIZATIONHORSES.LABEL.ECHORSE">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.ECHORSE'})}</label>
                      </div>

                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='USEFHORSE'
                            {...methods.register("formData.usef_horse")}
                            tabIndex={3}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="USEFHORSE" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.SYSTEMORGANIZATIONHORSES.LABEL.USEFHORSE">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.USEFHORSE'})}</label>
                      </div>

                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='FEIHORSE'
                            {...methods.register("formData.fei_horse")}
                            tabIndex={4}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="FEIHORSE" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.SYSTEMORGANIZATIONHORSES.LABEL.FEIHORSE">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.FEIHORSE'})}</label>
                      </div>

                    </div>
                </fieldset>
              </div>

              <div className='col-lg-6'> 
                <fieldset>
                    <legend className="fs-5 fw-bold d-flex"><label data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.SYSTEMORGANIZATIONPERSONS.LABEL.SYSTEMORGANIZATIONPERSONS">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.GROUPLABEL.SYSTEMORGPERSONS'})}</label> <b></b></legend>

                    <div className='row mb-2'>
                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='USEFRTO'
                            {...methods.register("formData.usef_rto")}
                            tabIndex={5}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="USEFRTO" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.SYSTEMORGANIZATIONPERSONS.LABEL.USEFALLRTOS">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.USEFRTO'})}</label>
                      </div>

                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='ECRTO'
                            {...methods.register("formData.ec_rto")}
                            tabIndex={6}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="ECRTO" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.SYSTEMORGANIZATIONPERSONS.LABEL.ECALLRTOS">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.ECRTO'})}</label>
                      </div>

                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='USHJARTO'
                            {...methods.register("formData.ushja_rto")}
                            tabIndex={7}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="USHJARTO" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.SYSTEMORGANIZATIONPERSONS.LABEL.USHJAALLRTOS">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.USHJARTO'})}</label>
                      </div>

                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='FEIALLRTO'
                            {...methods.register("formData.fei_rto")}
                            tabIndex={8}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="FEIALLRTO" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.SYSTEMORGANIZATIONPERSONS.LABEL.FEIALLRTOS">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.FEIALLRTO'})}</label>
                      </div>
                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='TAXSTATUS'
                            {...methods.register("formData.taxStatus_rto")}
                            tabIndex={9}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="TAXSTATUS" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.SYSTEMORGANIZATIONPERSONS.LABEL.TAXSTATUS">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.TAXSTATUS'})}</label>
                      </div>
                    </div>

                </fieldset>
              </div>
            </div>

            <div className="row mb-2">
              <div className='col-lg-12'> 
                <fieldset>
                    <legend className="fs-5 fw-bold d-flex"><label data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.NONSYSTEMORGANIZATION.LABEL.NONSYSTEMORGANIZATIONS">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.GROUPLABEL.NONSYSTEMORG'})}</label> <b></b></legend>
                    <div className="row mb-2">
                      <div className='col-lg-6 og-for-horse-grid'> 
                        <ORGForHorses rowData={horseOrganizations} />
                      </div>

                      <div className='col-lg-6 og-for-persons-grid'> 
                        <ORGForPersons rowData={peopleOrganizations} />
                      </div> 
                    </div>
                </fieldset>
              </div>
            </div>


            <div className="row mb-2">

              <div className='col-lg-12 d-flex align-items-center'>
                <div className='form-check-sm form-check-custom  me-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='VERIFYALL'
                    {...methods.register("formData.verify_all")}
                    tabIndex={12}
                  />
                </div>
                <label className='form-check-label fs-6 py-1' htmlFor="VERIFYALL" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.NONSYSTEMORGANIZATION.LABEL.VERIFYALLMEMBERSHIPFORDURATIONOFTHESHOW">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.VERIFYALL'})}</label>
              </div>

              <div className='col-lg-12 d-flex align-items-center'>
                <div className='form-check-sm form-check-custom  me-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='VERIFYCLASS'
                    {...methods.register("formData.verify_class")}
                    onChange={(e) => {
                      setRequirements(e.target.checked)
                    }}
                    tabIndex={13}
                  />
                </div>
                <label className='form-check-label fs-6 py-1' htmlFor="VERIFYCLASS" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.NONSYSTEMORGANIZATION.LABEL.VERIFYCLASSREQUIREMENTS">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.VERIFYCLASS'})}</label>
              </div>

            </div>


            <div className="row mb-2">
              <div className='col-lg-6'> 
                <fieldset>
                    <legend className="fs-5 fw-bold d-flex"><label data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.HORSEREQUIREMENTS.LABEL.HORSEREQUIREMENTS">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.GROUPLABEL.HORSEREQUIREMENTS'})}</label> <b></b></legend>

                    <div className='row mb-2'>
                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='HORSETYPE'
                            {...methods.register("formData.horse_type")}
                            onChange={(e) => {
                              setHorsetype(e.target.checked)
                            }}
                            disabled = {watchVerifyClassrequirements?true:false}
                            tabIndex={14}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="HORSETYPE" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.HORSEREQUIREMENTS.LABEL.HORSETYPE">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.HORSETYPE'})}</label>
                      </div>

                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='MEASCARD'
                            {...methods.register("formData.meas_card")}
                            onChange={(e) => {
                              setMeasCard(e.target.checked)
                            }}
                            disabled = {watchVerifyClassrequirements?true:false}
                            tabIndex={15}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="MEASCARD" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.HORSEREQUIREMENTS.LABEL.MEASUREMENTCARD">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.MEASCARD'})}</label>
                      </div>

                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='HORSEAGEVERIFIED'
                            {...methods.register("formData.horse_age_verified")}
                            onChange={(e) => {
                              setHorseAgeVerified(e.target.checked)
                            }}
                            disabled = {watchVerifyClassrequirements?true:false}
                            tabIndex={16}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="HORSEAGEVERIFIED" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.HORSEREQUIREMENTS.LABEL.HORSEAGEVERIFIED">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.HORSEAGEVERIFIED'})}</label>
                      </div>

                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='HORSEAGE'
                            {...methods.register("formData.horse_age")}
                            onChange={(e) => {
                              setHorseAge(e.target.checked)
                            }}
                            disabled = {watchVerifyClassrequirements?true:false}
                            tabIndex={17}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="HORSEAGE" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.HORSEREQUIREMENTS.LABEL.HORSEAGE">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.HORSEAGE'})}</label>
                      </div>

                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='HORSEHEIGHT'
                            {...methods.register("formData.horse_height")}
                            onChange={(e) => {
                              setHorseHeight(e.target.checked)
                            }}
                            disabled = {watchVerifyClassrequirements?true:false}
                            tabIndex={18}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="HORSEHEIGHT" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.HORSEREQUIREMENTS.LABEL.HORSEHEIGHT">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.HORSEHEIGHT'})}</label>
                        </div>
                    </div>

                </fieldset>
              </div>

              <div className='col-lg-6'> 
                <fieldset>
                    <legend className="fs-5 fw-bold d-flex">  <label data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.RIDERREQUIREMENTS.LABEL.RIDEREQUIREMENTS">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.GROUPLABEL.RIDERREQUIREMENTS'})}</label> <b></b></legend>

                    <div className='row mb-2'>
                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='RIDERSTATUS'
                            {...methods.register("formData.rider_status")}
                            onChange={(e) => {
                              setRiderStatus(e.target.checked)
                            }}
                            disabled = {watchVerifyClassrequirements?true:false}
                            tabIndex={19}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="RIDERSTATUS" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.RIDERREQUIREMENTS.LABEL.RIDERSTATUS">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.RIDERSTATUS'})}</label>
                      </div>

                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='RIDERAGE'
                            {...methods.register("formData.rider_age")}
                            onChange={(e) => {
                              setRiderAge(e.target.checked)
                            }}
                            disabled = {watchVerifyClassrequirements?true:false}
                            tabIndex={20}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="RIDERAGE" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.RIDERREQUIREMENTS.LABEL.RIDERAGE">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.RIDERAGE'})}</label>
                      </div>

                      <div className='col-lg-6 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='RIDERMEMBERSHIP'
                            {...methods.register("formData.rider_membership")}
                            onChange={(e) => {
                              setRiderMembership(e.target.checked)
                            }}
                            disabled = {watchVerifyClassrequirements?true:false}
                            tabIndex={21}
                          />
                        </div>
                        <label className='form-check-label fs-6 py-1' htmlFor="RIDERMEMBERSHIP" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.RIDERREQUIREMENTS.LABEL.RIDERMEMBERSHIP">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.RIDERMEMBERSHIP'})}</label>
                      </div>
                    </div>

                </fieldset>
              </div>
            </div>

            
            <div className="row mb-2">

              <fieldset>
                <legend className="fs-5 fw-bold d-flex"><label data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.OTHER.LABEL.Other">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.GROUPLABEL.OTHER'})}</label> <b></b></legend>
                <div className='row mb-2'>
                  <div className='col-lg-3 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='PASTBALANCE'
                        {...methods.register("formData.past_balance")}
                        tabIndex={22}
                      />
                    </div>
                    <label className='form-check-label fs-6 py-1' htmlFor="PASTBALANCE" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.OTHER.LABEL.PASTBALANCEDUE">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.PASTBALANCE'})}</label>
                  </div>

                  <div className='col-lg-3 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='MICROCHIP'
                        {...methods.register("formData.microchip")}
                        tabIndex={23}
                      />
                    </div>
                    <label className='form-check-label fs-6 py-1' htmlFor="MICROCHIP" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.OTHER.LABEL.MICROCHIP">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.MICROCHIP'})}</label>
                  </div>

                  <div className='col-lg-3 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='SIGNATURESOBTAINED'
                        {...methods.register("formData.signature_obtained")}
                        tabIndex={24}
                      />
                    </div>
                    <label className='form-check-label fs-6 py-1' htmlFor="SIGNATURESOBTAINED" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.OTHER.LABEL.SIGNATURESOBTAINED">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.SIGNATURESOBTAINED'})}</label>
                  </div>

                  <div className='col-lg-3 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='SSN'
                        {...methods.register("formData.ssn")}
                        tabIndex={25}
                      />
                    </div>
                    <label className='form-check-label fs-6 py-1' htmlFor="SSN" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.SECTION.OTHER.LABEL.SSNORFEDERALID">{intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.LABEL.SSN'})}</label>
                  </div>
                </div>

              </fieldset>
            </div>
              
            </div> {/* END Card Body */}

            <div className='card-footer d-flex justify-content-end py-3 px-0'>
              <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{marginLeft:"auto"}} onClick={handleCustomClose} tabIndex={26}>
                {intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.BUTTON.CANCEL'})}
              </button>

              <button type='button' onClick={onSubmit} className='btn btn-sm btn-dark fw-bold' tabIndex={27} data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.VERIFYENTRIES.BUTTON.CONTINUE">
                {intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.BUTTON.CONTINUE'})}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {VerifyEntriesForm}
