import { useIntl } from 'react-intl'
import { ShowSection } from '../ShowSection';
import { useEffect, useMemo, useRef, useState } from 'react';
import { usePaymentBatchContext } from '../../PaymentBatchContext';
import axios from 'axios';
import { RTOsGrid } from './RTOsGrid';
import { EntriesGrid } from './EntriesGrid';
import { useOutputContext } from '../../../../modules/output-listing/OutputListingContext';
import { useAlert, useFlashAlert, useProgress } from '../../../../modules/sgl-utils/DialogsProvider';
import { useAppSelector } from '../../../../redux/hooks';
import { AlertDialog } from '../../../../modules/modals/AlertDialog';
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const CheckoutEntriesTab = () => {
    const intl = useIntl();
    const { selectedShow, closePaymentBatchDetail, updateCheckoutEntriesTabData, setUpdateCheckoutEntriesTabData }= usePaymentBatchContext()
    const {progressBar} = useProgress()
    const { getEventID } = useOutputContext()
    const alertDialog = useAlert()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const flashAlert = useFlashAlert()
    
    let [errorMessage, setErrorMessage] = useState('') // error message displayed below show select
    let [peopleList, setPeopleList] = useState([]) // RTOs displayed in left grid
    let [selectedRTO, setSelectedRTO] = useState() // currently selected RTO from RTO grid
    let [entriesList, setEntriesList] = useState([]) // Entries displayed in right grid

    const checkoutEntries = async() => {
        if(!selectedRTO){
            alert({message: 'Select a RTO for entries checkout.'})
            return
        }

        let entries = entriesList.filter((entry) => entry.include).map((entry) => { return {entry_id: entry.entry_id, amountApplied: entry.amountApplied} })
        if(entries.length == 0){
            alert({message: 'Select entries for checkout.'})
            return
        }

        const eventID = getEventID('checkout-entries')
        progressBar({ show: true, eventID, timeElapsed: true, title: 'Checkout Entries' })
        await axios.post(`${NEST_API_URL}/payment-batches/checkoutEntries`, {
            peopleId: selectedRTO?.people_id,
            show_id: selectedShow,
            customer_id: customerId,
            entries,
            event_id: eventID
        }).then((response) => {
            if(response.data.success){
                //TODO: display dialog
                let message = ''
                if(response.data.updatedEntries > 0){
                    message = `${response.data.updatedEntries} ${response.data.updatedEntries > 1 ? 'entries' : 'entry'} checked out successfully.`
                }

                if(response.data.failedEntries > 0){
                    message = `${response.data.failedEntries} ${response.data.failedEntries > 1 ? 'entries' : 'entry'} not checked out successfully.`
                }

                if(message && response.data.updatedEntries <= 0){
                    flashAlert({ message }); //display error message
                }else{
                    flashAlert({ type: 'success', message });
                }

                if(response.data.updatedEntries > 0){ // refresh dat only if sone entry is paid
                    // refresh payment batch data
                    setUpdateCheckoutEntriesTabData(!updateCheckoutEntriesTabData)
                }
            }else{
                alert({message: response.data.error})
            }
        }).finally(() => {
            progressBar({ show: false, eventID })
        })
    }

    return (
        <>     
            <div>
                {/* Show Select */}
                <ShowSection tabName={'CHECKOUTENTRIES'} />
            </div> 

            <div className='row'>
                {/* RTOs Grid */}
                <div className='col-7'>
                    <RTOsGrid peopleList={peopleList} setPeopleList={setPeopleList} errorMessage={errorMessage} setErrorMessage={setErrorMessage} selectedRTO={selectedRTO} setSelectedRTO={setSelectedRTO} setEntriesList={setEntriesList}/> 
                </div>

                {/* Entries Grid */}
                <div className='col-5'>
                    <EntriesGrid checkoutEntries={checkoutEntries} entriesList={entriesList} setEntriesList={setEntriesList} selectedRTO={selectedRTO} setSelectedRTO={setSelectedRTO}/>
                </div>
            </div>
        </>
    );
}

export { CheckoutEntriesTab }