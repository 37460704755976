import { useIntl } from 'react-intl'
import { useAppSelector } from '../../redux/hooks';
import axios from 'axios';
import { useState, useEffect, useCallback, useRef , useLayoutEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from "react-hook-form";
import { useAlert, useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider';
import { useNavigate } from 'react-router-dom';
// Custom Components
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import { EntriesTab } from './Detail/EntriesTab';
import { GeneralTab } from './Detail/GeneralTab';
import { PeopleTab } from './Detail/PeopleTab';
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions';
import DetailPageModal from '../../modules/sgl-utils/DetailPageModal';
import { PageTitle } from '../../../_metronic/layout/core'
import history from '../../modules/sgl-utils/unstableHistory'
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt';
import { HistoryTab } from '../../modules/components/HistoryTab';
import useAutoFocus from '../../modules/hooks/use-auto-focus';
import HelpLink from '../../modules/components/HelpLink'

const { PUBLIC_URL } = process.env

export const dirtyValues = (
	dirtyFields,
	allValues
) => {
	// NOTE: Recursive function.
	// If *any* item in an array was modified, the entire array must be submitted, because there's no
	// way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
	if (dirtyFields === true || Array.isArray(dirtyFields)) {
		return allValues;
	}
	// Here, we have an object.
	return Object.fromEntries(
		Object.keys(dirtyFields).map((key) => [
			key,
			dirtyValues(dirtyFields[key], allValues[key])
		])
	);
};

const StableDetail = (props) => {
	const intl = useIntl();
	const { stable_id } = useParams();
	let current_stable_id = props.id || props.id == 0 ? props.id : stable_id
	const customer_id = useAppSelector(state => state.showCompany.company_id);
	const currentShowID = useAppSelector(state => state.currentShow.show_id);
	const [loading, setLoading] = useState(true)
	const alertDialog = useAlert()
	const navigate = useNavigate();
	const loadingOverlay = useLoadingOverlay()
	const [rowClicked, setRowClicked] = useState("")
	const [record_id, setRecordId] = useState("")
	const [sub_area, setSubArea] = useState("")
	const [child_component, setChildComponent] = useState("")
	const [open_forms, setOpenForms] = useState([])
	const [gridRowData, setGridRowData] = useState('')
	const [stableMetaData, setStableMetaData] = useState({})

	const getDetailPageInfo = (id, area, component, data) => {
		setRecordId(id)
		setSubArea(area)
		setChildComponent(component)
		sessionStorage.setItem("dialog_is_open_in_stable", true)
		setRowClicked(rowClicked => !rowClicked)
		const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
		setOpenForms(forms_open)
		setGridRowData(data)
	};

	// To open same detail page multiple times 
	useEffect(() => {
		if (sessionStorage.getItem("dialog_is_open_in_stable") == "true") {
			sessionStorage.removeItem("dialog_is_open_in_stable")
			setRowClicked(true)
			const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
			if (open_forms.includes(sub_area + "_" + record_id) == false) {
				forms_open.push(sub_area + "_" + record_id);
				sessionStorage.setItem('forms_open', JSON.stringify(forms_open));
			}
		}
	}, [rowClicked])

	const [update_data, setUpdateData] = useState(0)
	// prevent function being recreated on state change
	const updateData = useCallback(() => { setUpdateData(update_data + 1) }, [update_data]);

	const methods = useForm({
		defaultValues:
		{
			"stable": {
				"sync_id": "",
				"stable_id": 0,
				"name": "",
				"address": "",
				"address_two": "",
				"city": "",
				"state": "",
				"zip": "",
				"telephone": "",
				"fax": "",
				"url": "",
				"email": "",
				"comments": "",
				"customer_id": 0,
				"sgl_id": 0,
				"stable_uuid": ""
			},
			"entry": [],
			"show": [],
			"people": [],
		}
	});
	const { handleSubmit, reset, formState, setFocus } = methods
	const { dirtyFields } = formState;
	useNavigationPrompt(formState)
	const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
	const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);

	const tabs = ["GENERAL", "ENTRIES", "PEOPLE", "HISTORY"]
	const componentTags = [<GeneralTab form_meta = {stableMetaData}/>, <EntriesTab callbackFromParent={getDetailPageInfo}/>, <PeopleTab callbackFromParent={getDetailPageInfo}/>, <HistoryTab area="Stables" rowData={methods.getValues("change_history_logs")} />]
    const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	});
	
	useEffect(() => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');
		if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
			next_button.disabled = true;
			last_button.disabled = true;
		}
		if (row_selected == 0 && prev_button && first_button) {
			prev_button.disabled = true;
			first_button.disabled = true;
		}
	}, [loading])

	const onSubmit = async (form_data, e) => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');

		// means detail page is not open in modal
		if (props?.id === undefined) {
			next_button.disabled = false;
			prev_button.disabled = false;
			last_button.disabled = false;
			first_button.disabled = false;
		}

		let submit_data = dirtyValues(dirtyFields, form_data)
		loadingOverlay({ show: true })
		return axios.post(process.env.REACT_APP_NEST_API_URL + '/stables/detail', {
			params: {
				stable_id: current_stable_id,
				customer_id: customer_id,
				data: submit_data
			}
		}).then((response) => {
			loadingOverlay({ show: false })
			if (response.data.success) {
				// Use Case (Param Id changed): Next/Prev buttons
				if (props?.id == undefined && Number(methods.getValues('stable.stable_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))) {
					if (row_selected && row_selected != "" && row_ids[row_selected]) {
						setRowClicked(false)
						setLoading(true)
						history.push(`${PUBLIC_URL}/stables/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
					}
				}
				else if (props?.id == undefined && current_stable_id > 0) {
					history.push(`${PUBLIC_URL}/stables`, { allowNavigation: true })
				}
				else if(props?.id == undefined && current_stable_id == 0){
					history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
				}
				else {
					if (props.updateCallbackFromParent) {
						props.updateCallbackFromParent()
						props.closeModal()
					}
				}
				if (row_selected == row_ids?.length - 1 && props?.id === undefined) {
					if (next_button && last_button) {
						next_button.disabled = true;
						last_button.disabled = true;
						prev_button.disabled = false;
						first_button.disabled = false;
					}
				}
				if (row_selected == 0 && props?.id === undefined) {
					if (prev_button && first_button) {
						prev_button.disabled = true;
						first_button.disabled = true;
						next_button.disabled = false;
						last_button.disabled = false;
					}
				}
			} else {
				alertDialog({ message: response.data.error_message, title: 'Error' })
			}
		})
	}

	useEffect(() => {
		// Show loading overlay
		loadingOverlay({ show: true })
		axios.all(
			[
				axios.get(process.env.REACT_APP_NEST_API_URL + '/stables/detail', {
					params: {
						stable_id: current_stable_id,
						customer_id: customer_id,
						show_id: currentShowID,
					}
				}),
				axios.get(process.env.REACT_APP_NEST_API_URL + '/stables/metaData', {
					params: {
						stable_id: current_stable_id,
						customer_id: customer_id
					},
				}),
			]  
			).then (
				axios.spread( ({data : stablesData}, { data: stableMetaData },) => {
					//Check if record id is invalid then redirect to list page
					if ((stablesData || stablesData === '') && isInvalidRecord(stablesData.stable, current_stable_id, 'stable_id')){
						navigate('/stables')
						loadingOverlay({show: false})
					}
					if(props?.updatedData){
						for(let key of Object.keys(props.updatedData)){
							if(stablesData.stable[key] != props.updatedData[key]){
								methods.setValue(`stable.${key}`, props.updatedData[key], {shouldDirty:true})
							}
						}
					}

					// Assign default value 1 if phone country code and fax country code values are empty
					if(stablesData.stable && !stablesData.stable.phone_country_code){
						stablesData.stable.phone_country_code = 1
					}
					if(stablesData.stable && !stablesData.stable.fax_country_code){
						stablesData.stable.fax_country_code = 1
					}

					if(props?.id === undefined && Number(methods.getValues('stable.stable_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
						reset(stablesData)
					}else{
						reset(stablesData, { keepDirtyValues: true })
					}
					setStableMetaData(stableMetaData)
				
					if(sessionStorage.getItem('forms_open') == undefined || current_stable_id == stable_id){
						sessionStorage.setItem('forms_open', JSON.stringify(["Stables_"+Number(current_stable_id)]));
					}
					// Hide loading overlay
					loadingOverlay({show: false})
					setLoading(false)
				})
			)
	}, [stable_id, update_data])

	useAutoFocus('NAME', loading, update_data)

	return (
		<>
		{!loading && (
			<>
			{props?.id == undefined ? <PageTitle breadcrumbs={[]} description={[methods.watch('stable.name'), methods.getValues('stable.stable_id')]}>{intl.formatMessage({ id: 'MENU.STABLES' })}</PageTitle> : ""}
			<FormProvider {...methods}>
				<div id={"stables_detail_page"} className='modal-body py-3 px-4 stable-input-form input-form'>
					<form id={"StableDetail_" + current_stable_id} noValidate className='form max-width' onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } }}>
					<HelpLink tooltip="STABLE.DETAIL.LINK.HELP" classname="top" />
						<div className='card-body p-0'> {/* Card Body */}
							<div className="form-group row"> {/* Column Group */}
								<div className='row mb-2'>
									<div className='col-lg-6'>
										<div className='row mb-2'>
											<label className='col-lg-2 col-form-label fw-bold fs-5 py-1 w-lg-100px' htmlFor='NAME' data-tooltip-id="STABLE.DETAIL.LABEL.NAME">{intl.formatMessage({ id: 'FORM.INPUT.STABLES.LABEL.NAME' })}</label>
											<div className='col'>
												<input
													{...methods.register('stable.name',
														{
															required: "Stable name is required."
														}
													)
													}
													id='NAME'
													type='text'
													className='form-control form-control-sm  fs-6 min-h-20px py-1'
												/>
												{methods.formState.errors?.stable?.name && <div><span className='error_message'>{methods.formState.errors.stable.name?.message}</span></div>}
											</div>
										</div>
									</div>
								</div> {/* End Column Group */}
								<div className='d-flex flex-column my-2 border p-2 bg-white'>
									<ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
										{
											tabs.map((tab, index) => {
												return (
													<li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
														<a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == selectedTab ? ' active' : '')}
															tabIndex="-1" // to make the tab links non-focusable
															id={tab + "-tab"}
															data-bs-toggle='tab'
															href={"#" + tab + "-Stables-" + current_stable_id}
															data-tooltip-id={`STABLE.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.' + tab })}
														</a>
													</li>
												);
											})
										}
									</ul>
									<div className="tab-content mt-5 pb-2" id="myTabContent">
										{
											tabs.map((tab, index) => {
												return (
													<div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab + "-Stables-" + current_stable_id} role="tabpanel" >
														<HelpLink tooltip={"STABLE.DETAIL.TAB."+tab+".LINK.HELP"}  />
														{componentTags[index]}
													</div>
												);
											})
										}
									</div>
								</div>
							</div> {/* End Card Body */}
						</div> {/* End Card Body */}
						<InputFormFooter goBackPath={'/stables'} propId={props?.id} data={'stable'} />
					</form>
				</div>
			</FormProvider>
			{rowClicked && <DetailPageModal area={sub_area} detail_page={child_component} record_id={record_id} updateCallbackFromParent={updateData} open_forms={open_forms} gridRowData={gridRowData} />}
			</>
    		)}
		</>
	);
}
export { StableDetail }