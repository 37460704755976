import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { KTSVG } from '../../../../../_metronic/helpers';
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';
import { useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import { useAppSelector } from '../../../../redux/hooks';
import { useAlert, useFlashAlert } from '../../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const TransferCreditForm = ({ show, handleClose, transferCreditDetail, setTransferCreditDetail, updateCallbackFromParent, callFormSubmit }) => {
    const intl = useIntl();
    let methods = useFormContext();
    const alertDialog = useAlert()
    const currencyFormatter = useCurrencyFormatter();
    const currencyCode = useAppSelector(state => state.showCompany.currency_code)
    const customer_id = useAppSelector(state=> state.showCompany.company_id) 
    const flashAlert = useFlashAlert()

    let [creditAmount, setCreditAmount] = useState(0)
    let [errorMessage, setErrorMessage] = useState('')

    const containerStyle = useMemo(() => ({ width: '100%', height: '200px' }), []);

    const columnDefs = [
        { field: 'name', flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.TRANSFERCREDIT.NAME' }) },
        { field: 'role_in_entry', flex: 2, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.TRANSFERCREDIT.ROLEINENTRY' }) },
        { field: 'credit_amount', flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.TRANSFERCREDIT.CREDITAMOUNT' }) }
    ];

    let rowData = [];
    if (methods.getValues('entry.prizemoneyrecipient_id') !== methods.getValues('entry.responsibleparty_id')) {
        rowData = [
            {
                name: methods.getValues('entry.responsibleparty'),
                role_in_entry: 'Responsible Party',
                credit_amount: currencyFormatter(transferCreditDetail?.rpCredit)
            },
            {
                name: methods.getValues('entry.prizemoneyrecipient'),
                role_in_entry: 'Prize Money Recipient',
                credit_amount: currencyFormatter(transferCreditDetail?.pmrCredit)
            }
        ];
    } else {
        rowData = [
            {
                name: methods.getValues('entry.prizemoneyrecipient'),
                role_in_entry: 'Responsible Party/Prize Money Recipient',
                credit_amount: currencyFormatter(transferCreditDetail.totalCredit)
            }
        ];
    }

    const updateCreditAmount = () => {
        if(methods.getValues('entry.balance') > transferCreditDetail.totalCredit){
            setCreditAmount(transferCreditDetail.totalCredit ?? 0)
        }else{
            setCreditAmount(methods.getValues('entry.balance') ?? 0)
        }
    }

    const closeDialog = () => {
        setTransferCreditDetail(null);
        handleClose();
    };

    const validateCreditAmount = () => {
        if(creditAmount > 0){
            if(creditAmount > transferCreditDetail.totalCredit){
                alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TRANSFER.CREDIT.FROM.NOT.ENOUGH.BALANCE' }) });
                updateCreditAmount()
                return
            }

            if(creditAmount > methods.getValues('entry.balance')){
                alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TRANSFER.CREDIT.FROM.OVERPAY.ENTRY' }) });
                updateCreditAmount()
            }
        }
    }

    const transferCreditFromAccountRegister = async() => {
        if(creditAmount <= 0){
            alertDialog({ message: "Please enter valid amount to transfer credit." })
            return
        }

        await axios.post(`${NEST_API_URL}/accounting/moveCreditFromARToEntry`, {
            customer_id: customer_id,
            entry_id: methods.getValues('entry.entry_id'),
            amount: creditAmount
        }).then((response) => {
            // updateCallbackFromParent() TODO [FN]
            //callFormSubmit()
            if(response?.data?.success && response?.data?.paymentxentries){
                methods.setValue('paymentxEntries', response?.data?.paymentxentries)

                let message = []
                if(response?.data?.amountTransferredByRP){
                    message.push(`${currencyFormatter(response?.data?.amountTransferredByRP)} has been moved from Account ${methods.getValues('entry.responsibleparty')} to Entry #${methods.getValues('entry.number')}.`)
                }
                
                if(response?.data?.amountTransferredByPMR){
                    message.push(`${currencyFormatter(response?.data?.amountTransferredByPMR)} has been moved from Account ${methods.getValues('entry.prizemoneyrecipient')} to Entry #${methods.getValues('entry.number')}.`)
                }

                if(message.length > 0){
                    alertDialog({message: message.join('<br/>')})
                }
            }else{
                alertDialog({ message: response?.data?.error})
            }
        }).finally(() => {
            closeDialog()
        })
    }

    useEffect(() => {
        updateCreditAmount()
        let trips = methods.getValues('trips') ?? []
        let tripWithUnappliedPrizeMoney = trips?.filter((trip) => !trip.prize_money_spent && trip.total_prize_money > 0)
        if(tripWithUnappliedPrizeMoney.length > 0){
            setErrorMessage(tripWithUnappliedPrizeMoney.length === trips.length ? 'Note: Prize Money is not applied for entry.' : 'Note: Prize Money is not applied for some trips in entry.')
        }
    }, [])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-950px search-form'
            show={show}
            onHide={closeDialog}
            onKeyPress={(event) => {
                if (event.key === "Enter") {
                    closeDialog();
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.TRANSFERCREDIT.TITLE.TRANSFERCREDIT' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={closeDialog}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='modal-body py-3 px-4'>
                <form noValidate className='form ' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.MODAL.TRANSFERCREDITFROMACCOUNT.LABEL.INFO">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.TRANSFERCREDIT.INFO' })}<b>{methods.getValues('entry.number')}</b></label>
                        </div>
                        <div className='row mb-2'>
                            <OutputListingGridStatic
                                area={"Entries - Accounts Transfer Credit"}
                                columnDefs={columnDefs}
                                rowData={rowData}
                                containerStyle={containerStyle}
                            ></OutputListingGridStatic>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px' htmlFor='SUGGESTEDAMOUNT' data-tooltip-id="ENTRIES.DETAIL.MODAL.TRANSFERCREDITFROMACCOUNT.LABEL.SUGGESTEDAMOUNT">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.TRANSFERCREDIT.LABEL.SUGGESTEDAMOUNT' })}</label>
                            <div className='col-lg-4'>
                                <NumericFormat
                                    id="SUGGESTEDAMOUNT"
                                    value={currencyFormatter(creditAmount)}
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    allowNegative={false} // disallow negative values
                                    onValueChange={(e)=>{
                                        setCreditAmount(e.value)
                                    }}
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.TRANSFERCREDIT.LABEL.SUGGESTEDAMOUNT' })}
                                    prefix={intl.formatMessage({ id: `CURRENCY.CODE.SYMBOL.${currencyCode}` })}
                                    onBlur={validateCreditAmount}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <label className='col col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.MODAL.TRANSFERCREDITFROMACCOUNT.LABEL.WARNING">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.TRANSFERCREDIT.WARNING' })}</label>
                        </div>
                    </div>
                    <div className='card-footer py-2 px-0'>
                        <div className="row">
                            <div className="col-6">
                                {
                                    errorMessage ? (
                                        
                                            <label className='col-form-label fs-5 text-danger'> 
                                            { errorMessage }
                                            </label>
                                    ) : null
                                }
                            </div>
                            <div className="col-6 text-end">
                                <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' onClick={closeDialog}>
                                    {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                                </button>
                                <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={transferCreditFromAccountRegister}>
                                    {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.OK' })}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    );
};

export { TransferCreditForm };
