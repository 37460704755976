import React from 'react';
import { createRoot } from 'react-dom/client';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

const Tooltip = ({ message, position, tooltipHeight }) => {
    if (!message) return null;
    return (
        <div className="tooltip-display" style={{
            position: 'absolute',
            top: position.top,
            left: position.left,
            background: '#57595d',
            color: '#ffffff',
            padding: '0px 12px',
            borderRadius: '4px',
            whiteSpace: 'nowrap',
            zIndex: 9999,
            border: '1px solid #707275',
            outline: '1px solid #000000',
            fontSize: '14px',
            height: tooltipHeight + 'px',
            lineHeight: (tooltipHeight - 3) + 'px',
        }}>
            {message}
        </div>
    );
};

const useApplyToolTips = () => {
    const intl = useIntl();
    const supportedTags = ['label', 'button', 'a'];
    const tooltipHeight = 26;
    const tooltipPointer = 7;

    useEffect(() => {
        const tooltipMap = new Map();

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {

                // Handle added nodes
                mutation.addedNodes.forEach(node => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        supportedTags.forEach(tag => {
                            if (node.tagName.toLowerCase() === tag) {
                                applyTooltipIfNeeded(node);
                            }
                            node.querySelectorAll(tag).forEach(applyTooltipIfNeeded);
                        });
                    }
                });

                // Handle removed nodes
                mutation.removedNodes.forEach(node => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        supportedTags.forEach(tag => {
                            if (node.tagName.toLowerCase() === tag && tooltipMap.has(node)) {
                                const { tooltipRoot, tooltipElement } = tooltipMap.get(node);
                                tooltipRoot.unmount();
                                document.body.removeChild(tooltipElement);
                                tooltipMap.delete(node);
                            }
                            node.querySelectorAll(tag).forEach(child => {
                                if (tooltipMap.has(child)) {
                                    const { tooltipRoot, tooltipElement } = tooltipMap.get(child);
                                    tooltipRoot.unmount();
                                    document.body.removeChild(tooltipElement);
                                    tooltipMap.delete(child);
                                }
                            });
                        });
                    }
                });

                // Handle attribute changes
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-tooltip-id') {
                    supportedTags.forEach(tag => {
                        if (mutation.target.tagName.toLowerCase() === tag) {
                            applyTooltipIfNeeded(mutation.target);
                        }
                    });
                }

                // Handle visibility changes
                if (mutation.type === 'attributes' && (mutation.attributeName === 'style' || mutation.attributeName === 'class')) {
                    supportedTags.forEach(tag => {
                        if (mutation.target.tagName.toLowerCase() === tag && tooltipMap.has(mutation.target)) {
                            const { tooltipRoot, tooltipElement } = tooltipMap.get(mutation.target);
                            if (getComputedStyle(mutation.target).display === 'none' || mutation.target.getAttribute('aria-hidden') === 'true') {
                                tooltipRoot.unmount();
                                document.body.removeChild(tooltipElement);
                                tooltipMap.delete(mutation.target);
                            }
                        }
                    });
                }
                
            });
        });

        const applyTooltipIfNeeded = (node) => {
            if (node.hasAttribute('data-tooltip-id')) {
                const tooltipId = node.getAttribute('data-tooltip-id');
                let message = '';
                try {
                    message = intl.formatMessage({ id: tooltipId, defaultMessage: " " });
                    if (message === tooltipId) {
                        message = " ";
                    }

                    if (message && message !== " ") {
                        if (node.classList.contains('help-link')) {
                            node.setAttribute('href', message);
                            node.style.display = 'inline-block';
                        } else {
                            let tooltipRoot, tooltipElement;
                            node.onmouseenter = (e) => {
                                tooltipElement = document.createElement('div');
                                document.body.appendChild(tooltipElement);
                                tooltipRoot = createRoot(tooltipElement);
                                const position = node.getBoundingClientRect();
                                const computedStyle = window.getComputedStyle(node);
                                const paddingLeft = parseFloat(computedStyle.paddingLeft);
                                const paddingTop = parseFloat(computedStyle.paddingTop);
                                tooltipRoot.render(
                                    <Tooltip
                                        message={message}
                                        position={{
                                            top: position.top - tooltipHeight - tooltipPointer + paddingTop + window.scrollY,
                                            left: position.left + paddingLeft + window.scrollX
                                        }}
                                        tooltipHeight={tooltipHeight}
                                    />
                                );
                                tooltipMap.set(node, { tooltipRoot, tooltipElement });
                            };

                            node.onmouseleave = () => {
                                if (tooltipMap.has(node)) {
                                    const { tooltipRoot, tooltipElement } = tooltipMap.get(node);
                                    tooltipRoot.unmount();
                                    document.body.removeChild(tooltipElement);
                                    tooltipMap.delete(node);
                                }
                            };
                        }
                    }
                } catch (error) {
                    //console.error('Tooltip error:', error);
                }
            }
        };

        observer.observe(document.body, {
            childList: true,
            subtree: true,
            attributes: true,
            attributeFilter: ['data-tooltip-id', 'style', 'class', 'aria-hidden']
        });

        return () => {
            observer.disconnect();
        };
    }, []);

    return null;
};

export default useApplyToolTips;
