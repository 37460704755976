import { useCallback } from "react";

const CustomHeader = ({displayName, refreshComponent, disabled }) => {
    const handleButtonClick = useCallback(async () => {
        refreshComponent();
    }, []);

    return (
        <div className="custom-add-to-show-button-container">
            <button className="btn btn-sm btn-secondary fw-bolder text-uppercase custom-add-to-show-button" onClick={handleButtonClick} disabled={disabled}> {displayName} </button>
        </div>
    );
};

export default CustomHeader;
