
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import { SeparatedDateTimeRenderer } from '../../modules/output-listing/renderers/DateTimeRenderer';

const ErrorsList = () => {
  const intl = useIntl()

  const columnDefs = [
    { field: 'sgl_id', maxWidth: 100, headerName: intl.formatMessage({ id: "LIST.ERRORSLIST.ID" }), filter: 'agNumberColumnFilter'},
    { field: 'currentmethod', minWidth: 170, headerName: intl.formatMessage({ id: 'LIST.ERRORSLIST.METHODS' }), filter: 'agTextColumnFilter'},
    { field: 'currentprocess', minWidth: 250, headerName: intl.formatMessage({ id: 'LIST.ERRORSLIST.PROCESS' }), filter: 'agTextColumnFilter', },
    { field: 'currenterror', minWidth: 100, headerName: intl.formatMessage({ id: 'LIST.ERRORSLIST.ERRORCODE' }), filter: 'agTextColumnFilter', },
    { field: 'description', minWidth: 400, headerName: intl.formatMessage({ id: 'LIST.ERRORSLIST.DESCRIPTION' }), filter: 'agTextColumnFilter', },
    { field: 'currentdate', minWidth: 200, headerName: intl.formatMessage({ id: 'LIST.ERRORSLIST.DATE' }), filter: 'agDateColumnFilter', headerClass: "ag-center-aligned-header", rowClass: "text-center", cellStyle: { textAlign: 'center' }, cellRendererFramework: (params) => {
        return (
            <SeparatedDateTimeRenderer params = {params} date={params?.data?.currentdate} time={params?.data?.currenttime}/>
        );
      } },
    { field: 'Company.urlpath', minWidth: 100, headerName: intl.formatMessage({ id: 'LIST.ERRORLISTING.COMPANY' }), filter: 'agTextColumnFilter', valueGetter: function getURLPath(params) { return params?.data?.Company.urlpath?.toUpperCase()} ,cellStyle: { textAlign: 'center' }}, 
    { field: 'currentuser', minWidth: 200, headerName: intl.formatMessage({ id: 'LIST.ERRORSLIST.USER' }), filter: 'agTextColumnFilter'}
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.ERRORS' })}</PageTitle>
      <OutputListingComponent area={'ErrorsListing'} columnDefs={columnDefs} apiEndPoint={'utility/errors-list'} >
      </OutputListingComponent>
    </>
  )
}

export { ErrorsList }
