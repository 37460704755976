import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'
import moment from 'moment'
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters'
import { useFormContext } from 'react-hook-form'
import { useAlert, useConfirm } from '../../../../modules/sgl-utils/DialogsProvider';
import Select from "react-select";
import { reactSelectStyles } from '../../../../modules/sgl-utils/fieldControls'
import { useAppSelector } from '../../../../redux/hooks'
import axios from 'axios'
import { usePhoneNumberFormatter } from '../../../../modules/sgl-utils/Formatters'

type Props = {
    show: boolean
    handleClose: () => void,
    rowData: any
    entryFeeGridRef: any
}

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const FeeDetailForm = ({ show, handleClose, rowData, entryFeeGridRef }: Props) => {
    const intl = useIntl()
    const methods = useFormContext()
    const currencyFormatter = useCurrencyFormatter()
    const customerID = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);

    const [showTrainerStallInventory, setShowTrainerStallInventory] = useState(false)
    const [showOrderDeliverySection, setShowOrderDeliverySection] = useState(false)
    const [showCheckInOutSection, setShowCheckInOutSection] = useState(false)
    const [showBarns, setShowBarns] = useState([]) // barns available for the current
    const [selectedBarn, setSelectedBarn] = useState<any>({}) // barn associated with the fee
    const confirmDialog = useConfirm () 
    const alertDialog = useAlert();
    const phoneFormatter = usePhoneNumberFormatter();

    useEffect(() => {
        getShowBarns()
    }, [])

    useEffect(() => {   
        if (rowData?.ShowFee?.MasterFee) {
            //  && rowData['feed_bedding_fee']
            setShowOrderDeliverySection(rowData.ShowFee.MasterFee.physical_delivery)
        } else {
            setShowOrderDeliverySection(false)
        }
      
        if (rowData?.ShowFee?.MasterFee) {
            setShowCheckInOutSection(
                rowData.ShowFee.MasterFee.stablefee && 
                rowData['stall_checkin_date'] && rowData['stall_checkin_time'] &&
                rowData['stall_checkout_date'] && rowData['stall_checkout_time'] ? true : false
            )
            setShowTrainerStallInventory(
                methods.getValues('entry.trainer_account') && (rowData.ShowFee?.stablefee || rowData.ShowFee?.MasterFee?.stablefee)
            )
        } else {
            setShowCheckInOutSection(false)
            setShowTrainerStallInventory(false)
        }
    }, [rowData])

    useEffect(() => {
        // if the barn entered in fee is present in show barns then auto select it other wise select others options
        let delivery_barn_location = methods.getValues(`entryFeesData.entryFees[${rowData.rowIndex}].delivery_barn_location`)
        if(delivery_barn_location){
            let _barnSelected = showBarns?.find((barn) => String(barn['value'])?.toLowerCase()?.trim() == delivery_barn_location?.toLowerCase()?.trim())
            if(_barnSelected){
                setSelectedBarn(_barnSelected)
            }else{
                setSelectedBarn({label: 'Others', value: 'Others'})
            }
        }else{
            // no barn entered for the fee
            setSelectedBarn({label: 'Select', value: ''})
        }
    }, [showBarns, methods.getValues(`entryFeesData.entryFees[${rowData.rowIndex}].delivery_barn_location`)])

    const openImageInNewTab = () => {
        const BASE_URL = process.env.REACT_APP_SGL_API_URL
        window.open(rowData?.uploaded_to_cloud==1 ? rowData.cloud_delivery_image_url : BASE_URL + rowData.delivery_image_url, '_blank', 'noopener,noreferrer')
    }

    const updateEntryStall = async(value: any) => {
        "FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.CONFIRM.ENTRYSTALL.REMOVE"

        let message = 'REMOVE'
        if(value){
            message = 'SET'
        }
        let choice = await confirmDialog({message:  intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.CONFIRM.ENTRYSTALL.'+message}) , icon: "comment"})
        if(choice){
            rowData.entry_stall = value
            methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].entry_stall`, value)
            methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].isUpdated`, true)
            // Rerender Ag-Grid
            const entryFees = methods.getValues("entryFeesData.entryFees")
            const filteredEntryFees = entryFees.filter((entryFee : any) => !entryFee.isDeleted)
            entryFeeGridRef.setRowData(filteredEntryFees)
        }

    }

    const getShowBarns = async() => {
        // load the barn for the current show
        await axios.get(`${NEST_API_URL}/shows/getShowBarns?show_id=${currentShowID}&customer_id=${customerID}`)
        .then(response => {
            if(response.data){
                setShowBarns(response.data)
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        });
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-650px search-form'
            show={show}
            onHide={handleClose}
            onKeyPress={(event: any) => {
                if (event.key === "Enter") {
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{ intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.HEADING' }) }</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.LABEL.DESCRIPTION">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.DESCRIPTION' })}</label>
                            <label className='col col-form-label fs-5 py-1 fw-bold'>
                                { rowData.description }
                            </label>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.LABEL.UNITPRICE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.UNITPRICE' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                        { currencyFormatter(rowData.unitprice) }
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.LABEL.QTY">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.QTY' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                        { rowData.quantity }
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.LABEL.TOTAL">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.TOTAL' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                        { currencyFormatter(rowData.totalprice) }
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.LABEL.TIME">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.TIME' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                        { 
                                            rowData.time_added 
                                            ? 
                                                moment(rowData.time_added,"HH:mm:ss").format('h:mm A')
                                                :
                                            "" 
                                        }
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.LABEL.DATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.DATE' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                        { moment(rowData.date).isValid() && rowData.date ? moment(rowData.date).format("MM/DD/YYYY") : "" }
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.LABEL.CATEGORY">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.CATEGORY' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                        { rowData.category }
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.LABEL.INVOICENUMBER">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.INVOICENUM' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                        { rowData.invoice_number }
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.LABEL.ADDEDFROMWEB">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.ADDEDFROMWEB' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                        { rowData.ordersupplyfromweb ? "Yes" : "No" }
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.LABEL.TAXAPPLIED">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.TAXAPPLIED' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                        { rowData.ShowFee?.ShowTaxRate?.tax_name ? rowData.ShowFee.ShowTaxRate.tax_name : "None"}
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.LABEL.FEEREFUNDED">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.FEEREFUNDED' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                        { rowData.refund ? "Yes" : "No"}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={'row mb-5' + (showTrainerStallInventory ? '' : ' d-none')}>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold'></label>
                            <div className='col form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='TRAINERSTALLINVENTORY'
                                    checked={rowData.entry_stall}
                                    onChange={(e) => {updateEntryStall(e.target.checked)}}

                                />
                                <label className='col-form-label mx-2 fs-5 py-1 fw-bold' htmlFor='TRAINERSTALLINVENTORY' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.LABEL.TRAINERSTALLINVENTORY"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.TRAINERSTALLINVENTORY' })}</label>
                            </div>
                        </div>
                        <fieldset className={'mb-5' + (showOrderDeliverySection ? '' : ' d-none')}>
                            <legend className="fs-5 col">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.GROUPLABEL.ORDERDELIVERY' })} <b></b></legend>
                            <div className='row mb-2'>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.ORDERDELIVERY.LABEL.DELIVERED">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.DELIVERED' })}</label>
                                        <label className='col col-form-label fs-5 py-1'>
                                            {methods.getValues(`entryFeesData.entryFees[${rowData.rowIndex}].delivered`) ? 'Yes' : 'No'}
                                        </label>
                                        <div className='col-lg-1 w-lg-150px py-1'>
                                            <a href='#' 
                                                className='col fs-5 py-1 text-info' 
                                                onClick={async () =>  { 
                                                    let choice = await confirmDialog({
                                                        message: intl.formatMessage({id:  "FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.WARNING"}),
                                                        title: "",
                                                        icon: "stop",
                                                        okButtonTitle: intl.formatMessage({id: 'DIALOG.COMMON.BUTTON.OK'}),
                                                        cancelButtonTitle: intl.formatMessage({id: 'DIALOG.COMMON.BUTTON.CANCEL'})
                                                    })
                                                    if(choice){
                                                        methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].delivered`, 0)
                                                        // Mark row as updated
                                                        methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].isUpdated`, true)
                                                        methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].delivery_date`, '0000-00-00')
                                                        methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].delivery_time`, '00:00:00')
                                                        methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].delivered_by`, '')
                                                        entryFeeGridRef.setRowData(methods.getValues(`entryFeesData.entryFees`) ?? [])
                                                    }
                                                }}
                                                data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.ORDERDELIVERY.LINK.UNDELIVERITEM"
                                            >
                                                { methods.getValues(`entryFeesData.entryFees[${rowData.rowIndex}].delivered`) ? 'Undeliver Item' : null }
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.ORDERDELIVERY.LABEL.NOTIFYPHONE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.NOTIFYPHONENUM' })}</label>
                                        <label className='col col-form-label fs-5 py-1'>
                                            { phoneFormatter(rowData.delivery_notification_phone) }
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                            <div className='col-lg-6'>
                                    <div className='row'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.ORDERDELIVERY.LABEL.PRINTED">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.PRINTED' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                        { rowData.printed ? "Yes" : "No" }
                                    </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.ORDERDELIVERY.LABEL.TRAINER">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.TRAINER' })}</label>
                                        <label className='col col-form-label fs-5 py-1'>
                                            { rowData.trainer_name }
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-6 form-check-sm form-check-custom ps-lg-3'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px fw-bold'></label>
                                    <input
                                        checked={methods.getValues(`entryFeesData.entryFees[${rowData.rowIndex}].priority_order`)}
                                        onChange={(e) => {
                                            methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].priority_order`, e.target.checked)
                                            methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].isUpdated`, true)
                                            // Rerender Ag-Grid
                                            const entryFees = methods.getValues("entryFeesData.entryFees")
                                            const filteredEntryFees = entryFees.filter((entryFee : any) => !entryFee.isDeleted)
                                            entryFeeGridRef.setRowData(filteredEntryFees)
                                        }}
                                        className='form-check-input'
                                        type='checkbox'
                                        id='PRIORITYORDER'
                                    />
                                    <label className='col-form-label mx-2 fs-5 py-1 fw-bold' htmlFor='PRIORITYORDER' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.ORDERDELIVERY.LABEL.PRIORITYORDER"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.PRIORITYORDER' })}</label>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.ORDERDELIVERY.LABEL.ARRIVALDATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.ARRIVALDATE' })}</label>
                                        <label className='col col-form-label fs-5 py-1'>
                                            {/* display arrival date and time only if arrival is in or after year 2000 */}
                                            { moment(rowData.arrival_date).isValid() && moment(rowData.arrival_date).isSameOrAfter(moment('2000-01-01'))? moment(rowData.arrival_date).format("MM/DD/YYYY") : ""}
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.ORDERDELIVERY.LABEL.ARRIVALTIME">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.ARRIVALTIME' })}</label>
                                        <label className='col col-form-label fs-5 py-1'>
                                            { rowData.arrival_time && rowData.arrival_time != "00:00:00"? moment(rowData.arrival_time,"HH:mm:ss").format('h:mm A') : "" }
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label 
                                    htmlFor="barnLocation"
                                    className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.ORDERDELIVERY.LABEL.BARNLOCATION">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.BARNLOCATION' })}</label>
                                <div className='col-lg-9'>
                                    <div className='row'>
                                        {/* Barn Drop Down */}
                                        <div className='col-lg-6'>
                                            <Select
                                                placeholder="Any"
                                                value={selectedBarn}
                                                options = {showBarns}
                                                isSearchable={true}
                                                onChange={(option) => {
                                                    let _selectedBarn = ''
                                                    setSelectedBarn(option)
                                                    if(option.value == 'Others'){
                                                        // Clear out the delivery_barn_location field as user will enter it manually
                                                        _selectedBarn = ''
                                                    }else {
                                                        _selectedBarn = option.value
                                                    }

                                                    methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].delivery_barn_location`, _selectedBarn)
                                                    methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].isUpdated`, true)
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                            /> 
                                        </div>

                                        {/* Input Field for Others */}
                                        {
                                            selectedBarn['value'] == 'Others' ?
                                                <div className='col-lg-6'>
                                                    <input
                                                        value={methods.getValues(`entryFeesData.entryFees[${rowData.rowIndex}].delivery_barn_location`)}
                                                        onChange={(e) => {
                                                            methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].delivery_barn_location`, e.target.value)
                                                            methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].isUpdated`, true)
                                                        }}
                                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                                        type='text'
                                                        id='barnLocation'
                                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.BARNLOCATION' })}
                                                    />
                                                </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' htmlFor="stallLocation" data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.ORDERDELIVERY.LABEL.STALLLOCATION">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.STALLLOCATION' })}</label>
                                <div className='col-lg-9'>
                                    <input
                                        value={methods.getValues(`entryFeesData.entryFees[${rowData.rowIndex}].delivery_stall_location`)}
                                        onChange={(e) => {
                                            methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].delivery_stall_location`, e.target.value)
                                            methods.setValue(`entryFeesData.entryFees[${rowData.rowIndex}].isUpdated`, true)
                                        }}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        type='text'
                                        id='stallLocation'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.STALLLOCATION' })}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.ORDERDELIVERY.LABEL.DELIVEREDBY">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.DELIVEREDBY' })}</label>
                                <label className='col col-form-label fs-5 py-1'>
                                    {methods.getValues(`entryFeesData.entryFees[${rowData.rowIndex}].delivered_by`)}
                                </label>
                            </div>
                            <div className='row mb-5'>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.ORDERDELIVERY.LABEL.DATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.DELIVERYDATE' })}</label>
                                        <label className='col col-form-label fs-5 py-1'>
                                            { moment(methods.getValues(`entryFeesData.entryFees[${rowData.rowIndex}].delivery_date`)).isValid() ? moment(methods.getValues(`entryFeesData.entryFees[${rowData.rowIndex}].delivery_date`)).format("MM/DD/YYYY") : ""}
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.ORDERDELIVERY.LABEL.TIME">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.DELIVERYTIME' })}</label>
                                        <label className='col col-form-label fs-5 py-1'>
                                            { methods.getValues(`entryFeesData.entryFees[${rowData.rowIndex}].delivery_time`) && methods.getValues(`entryFeesData.entryFees[${rowData.rowIndex}].delivery_time`) != '00:00:00' ?moment(methods.getValues(`entryFeesData.entryFees[${rowData.rowIndex}].delivery_time`),"HH:mm:ss").format('h:mm A'): "" }
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={'row justify-content-end mb-2' + ((rowData?.uploaded_to_cloud==1 && rowData?.cloud_delivery_image_url) || (!(rowData?.uploaded_to_cloud==1) && rowData['delivery_image_url']) ? '' : ' d-none')}>
                                <div className='col-lg-1 w-lg-200px'>
                                    <button type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase" onClick={ openImageInNewTab } data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.ORDERDELIVERY.BUTTON.VIEWDELIVERYPICTURE">
                                        {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.BUTTON.VIEWDELIVEYPIC' })}
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className={'mb-5' + (showCheckInOutSection ? '' : ' d-none')}>
                            <legend className="fs-5 col">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.GROUPLABEL.STALLCHECKINCHECKOUTINFO' })} <b></b></legend>
                            <div className='row mb-2'>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.STALLCHECKINCHECKOUTINFO.LABEL.CHECKINDATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.CHECKINDATE' })}</label>
                                        <label className='col col-form-label fs-5 py-1'>
                                            { moment(rowData.stall_checkin_date).isValid() ? moment(rowData.stall_checkin_date).format("MM/DD/YYYY") : "" }
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.STALLCHECKINCHECKOUTINFO.LABEL.CHECKINTIME">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.CHECKINTIME' })}</label>
                                        <label className='col col-form-label fs-5 py-1'>
                                            { moment(rowData.stall_checkin_time,"HH:mm:ss").format('h:mm A') }
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.STALLCHECKINCHECKOUTINFO.LABEL.CHECKOUTDATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.CHECKOUTDATE' })}</label>
                                        <label className='col col-form-label fs-5 py-1'>
                                            { moment(rowData.stall_checkout_date).isValid() ? moment(rowData.stall_checkout_date).format("MM/DD/YYYY") : "" }
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.SECTION.STALLCHECKINCHECKOUTINFO.LABEL.CHECKOUTTIME">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.FEEDETAIL.LABEL.CHECKOUTTIME' })}</label>
                                        <label className='col col-form-label fs-5 py-1'>
                                            { rowData.stall_checkout_time != "00:00:00" ? moment(rowData.stall_checkout_time,"HH:mm:ss").format('h:mm A') : "" }
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button className='btn btn-sm btn-dark text-uppercase' onClick={handleClose} type="button" autoFocus data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYFEEDESCRIPTION.BUTTON.DONE">
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.BUTTON.DONE' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { FeeDetailForm }