import { useEffect, useState } from "react"
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers'

type Props = {
    show: boolean
    handleClose: () => void
    handleSubmit: (note: String) => void
    data: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const NoteDialog = ({ show, handleClose, handleSubmit, data }: Props) => {
    const intl = useIntl()

    const [note, setNote] = useState('')
    const onSave = () => {
        handleSubmit(note)
        setNote('')
        handleClose()
    }

    useEffect(() => {
        if (!show) {
            setNote('')
        }
        else{
            if (data) { //if opened as edit notes form, pre-fill the notes data
                setNote(data?.note ?? '')
            }
        }
    }, [show])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-650px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event:any) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (!event.shiftKey && event.key == 'Enter' && !isButtonFocused) { //call function only if no button is in focus
                    onSave()
                }
                if(event.key === 'Escape' && activeElement?.id.includes('select')){
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.NOTES.MODAL.NOTE.TITLE.' + (data ? 'EDITNOTE' : 'ADDNOTE') })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <div className='col-lg-12'>
                                <textarea
                                    rows={6}
                                    className='form-control form-control-sm mb-2 mb-lg-0 fs-6 py-1'
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                    onKeyDown={(e:any) => {
                                        if (e.key == "Enter" && e.shiftKey) {
                                            setNote(note);
                                        }
                                    }}
                                    autoFocus
                                    tabIndex={1}
                                ></textarea>
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold text-uppercase' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={2}
                        >
                            {intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.NOTES.MODAL.NOTE.BUTTON.CANCEL' })}
                        </button>
                        <button type='button' className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={onSave} tabIndex={3}>
                            {intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.NOTES.MODAL.NOTE.BUTTON.' +  (data ? 'SAVE' : 'ADD')})}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { NoteDialog }