import { useEffect, useMemo, useState} from 'react'
import { useFormContext } from "react-hook-form";
import { AgGridReact } from 'ag-grid-react';
import { defaultColDef, getNarrowHeaderHeight, getNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers';
import DecimalPointRenderer from '../../../../modules/output-listing/renderers/DecimalPointRenderer';

const DEFAULT_COLUMN_DEF = [
    { 
        field: 'horse',
        flex: 1, 
        maxWidth: 250,
        cellClass: 'text-left'
    },
    { 
        field: 'number', 
        headerName: 'Entry',
        width: 100
    },
    {
        field: 'overFencePoints',
        headerName: 'O/F Sub',
        width: 100,
        cellRenderer: params => DecimalPointRenderer(params, 1, false)
    },
    {
        field: 'total',
        cellRenderer: params => DecimalPointRenderer(params, 1, false),
        flex: 1,
        maxWidth: 160
    }
]

const PointSummaryTab =() => {
    const methods = useFormContext();
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), [])
    const [gridRef, setGridRef] = useState(null)
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [columnDefs, setColumnDefs] = useState(DEFAULT_COLUMN_DEF)
  
    useEffect(() => {
        const pointSummary = methods.getValues('pointSummary')
        const pointSummaryClasses = methods.getValues('pointSummaryClasses')
        if (gridRef){
            if (pointSummaryClasses && pointSummaryClasses.length > 0) {
                const newColumnDefs = [DEFAULT_COLUMN_DEF[0], DEFAULT_COLUMN_DEF[1]]
                for (const key of pointSummaryClasses) {
                    if (!isNaN(key)) {
                        newColumnDefs.push({
                            field: key,
                            headerName: key,
                            width: 100,
                            cellRenderer: params => DecimalPointRenderer(params, 1, false)
                        })
                    }
                }
                newColumnDefs.push(DEFAULT_COLUMN_DEF[2])

                for (const key of pointSummaryClasses) {
                    if (key.includes('-US')) {
                        newColumnDefs.push({
                            field: key,
                            headerName: key.split('-')[0],
                            width: 100,
                            cellRenderer: params => DecimalPointRenderer(params, 1, false)
                        })
                    }
                }

                newColumnDefs.push(DEFAULT_COLUMN_DEF[3])

                setColumnDefs(newColumnDefs)
            }
        
            if(pointSummary){
                gridRef.setRowData(pointSummary)
            }
        }
    }, [gridRef, methods.watch('pointSummary')])

    return (
        <div className='form-group'>
            <div className='row mb-2 pe-5'>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact 
                        rowData={[]}
                        columnDefs={columnDefs} 
                        containerStyle={containerStyle}
                        defaultColDef={{ ...defaultColDef, sortable: true, cellClass: 'text-center' }}
                        onGridReady={params => setGridRef(params.api)}
                        rowHeight={getNarrowRowHeight}
                        headerHeight={getNarrowHeaderHeight}
                    />
                </div>
            </div>
        </div> 
    );
}

export { PointSummaryTab }