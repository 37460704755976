import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext, useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext';
import ClassesGrid from './AddClassesFromOtherShow/ClassesGrid';
import axios from 'axios';
import { useConfirm } from '../../../modules/sgl-utils/DialogsProvider';

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { createArrayOfNumbersFromText } from '../../../modules/sgl-utils/SglFunctions';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const AddClassesFromOtherShow = ({ selectedShow, show, handleClose }) => {
    const intl = useIntl() 
    const { getEventID } = useOutputContext();
    const customerId = useAppSelector(state=> state.showCompany.company_id)
    const currentShowId = useAppSelector(state => state.currentShow.show_id)
    const [gridRef, setGridRef] = useState()
    const [classes, setClasses] = useState([])
    const outputContextUpdater = useOutputContextUpdater()

    const [selectAllClasses, setSelectAllClasses] = useState(false)
    const [showSelected, setShowSelected] = useState(false)
    const [numSelected, setNumSelected] = useState(0)

    const loadingOverlay = useLoadingOverlay()
    const {progressBar} = useProgress()
    const alertDialog = useAlert()
    let confirmDialog = useConfirm()
    const currentShowName = useAppSelector(state => state.currentShow.show_name);

    // Get Ag-grid data
    const getRowData = (_gridRef) => {
        const rowData = []
        _gridRef.forEachNode(node => rowData.push(node.data));
        return rowData
    }

    useEffect(() => {
        if (show && gridRef) {
            loadingOverlay({ show: true })
            axios.get( NEST_API_URL + `/classes/getAddClassesFromOtherShowMetadata?show_id=${selectedShow.show_id}&customer_id=${customerId}`)
            .then(response => {

                const classes = response.data.classes.map(cl => ({ ...cl, selected: false }))
                gridRef.setRowData(classes)
                setClasses(classes)

                loadingOverlay({ show: false })
            })
            .catch(() => loadingOverlay({ show: false }))
        }  
    }, [show, gridRef])

    useEffect(() => {
        if (gridRef) {
            const rowData = getRowData(gridRef).map(row => ({ ...row, selected: selectAllClasses }))
            gridRef.setRowData(rowData)
            setNumSelected(selectAllClasses > 0 ? rowData.length : 0)
        }
    }, [gridRef, selectAllClasses])

    useEffect(() => {
        if (gridRef) {
            let rowData = getRowData(gridRef)
            if (showSelected) {
                rowData = rowData.filter(row => row.selected)
            } else {
                for (let cl of classes) { // Loop through original classes data
                    if (!rowData.find(row => row.number === cl.number)) { // Check if already present in ag-grid?
                        rowData.push(cl)
                    }
                }
                rowData.sort((a, b) => a.number - b.number)
            }
            gridRef.setRowData(rowData)
        }
    }, [gridRef, showSelected])

    const addClassesByNumbers = (e) => {
        const rowData = getRowData(gridRef)
        const classNumbersList = createArrayOfNumbersFromText(e.target.value)
        const errors = []
        for (let number of classNumbersList) {
            const currentSelectionIdx = rowData.findIndex(row => row.number === number)
            const allClassesIdx = classes.findIndex(row => row.number === number)
            if (allClassesIdx >= 0 || currentSelectionIdx >= 0) { // present in either current selection or all classes
                if (currentSelectionIdx >= 0) {
                    rowData[currentSelectionIdx].selected = true
                } else {
                    rowData.push({ ...classes[allClassesIdx], selected: true })
                }
            } else {
                errors.push("Class "+ number + " not found in Source show.")
            }
        }

        if (errors.length > 0) {
            alertDialog({ message: errors.join("<br/>")})
        }

        e.target.value = "" //clear the variable for next search
        rowData.sort((a, b) => a.number - b.number)
        setNumSelected(rowData.filter(row => row.selected).length)
        gridRef.setRowData(rowData)
    }

    const onClose = (action="", subsetIds=[]) => {
        setSelectAllClasses(false)
        setShowSelected(false)

        // Refresh Class List
        if (action === "Import Completed") {
            outputContextUpdater({action: 'refresh', subsetIDs: subsetIds})    // We need to subset the imported
        } else {
            outputContextUpdater({action: 'refresh'})    // We need to subset the imported classes
        }
        handleClose();  
    }

    const addClassesFromOtherShow = async () => {
        const choice = await confirmDialog({ message: `Are you sure you want to add ${numSelected} class(es) to this show: ${currentShowName}?` })

        // If user cancels the operation then return
        if (!choice) return;

        const eventId = getEventID("add-classes-from-other-show")
        progressBar({ show: true, title: "Importing Classes", eventID: eventId, timeElapsed: true })

        // Set subset ids because we need to subset the imported classes
        const response = await axios.post( NEST_API_URL + `/classes/addClassesFromOtherShow`, {
            source_class_ids: getRowData(gridRef).filter(row => row.selected).map(row => row.class_id), // Send only selected classes
            current_show_id: currentShowId,
            customer_id: customerId,
            event_id: eventId,
            source_show_name: selectedShow.show_name
        })

        progressBar({ show: false, eventID: eventId })

        if (response.data.success) {
            onClose("Import Completed", response.data.new_class_sgl_ids)
        } else {
            onClose()
        }
        
    }
    
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px'
            show={show}
            onHide={onClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) {
                    addClassesFromOtherShow()
                }
                if (event.key == 'Escape') {
                    onClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'Classes.QuickAction.AddClassesFromOtherShow.Modal.Heading' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='card-body p-0 px-2'>
                    <div className='row mb-2'>
                        <label className='col-lg-3 col-form-label fs-5 py-1' htmlFor="sourceShow">
                            { intl.formatMessage({ id: 'Classes.QuickAction.AddClassesFromOtherShow.Label.SourceShow' }) }
                        </label>
                        <div className='col-lg-9'>
                            <input
                                type="text"
                                id="sourceShow" 
                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                disabled={true}
                                value={selectedShow.show_name}
                                autoFocus
                                tabIndex={1}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <label className='col-lg-3 col-form-label fs-5 py-1' htmlFor="enterClasses">
                            { intl.formatMessage({ id: 'Classes.QuickAction.AddClassesFromOtherShow.Label.EnterClasses' }) }
                        </label>
                        <div className='col-lg-9'>
                            <input
                                type="text"
                                id="enterClasses" 
                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                onBlur={addClassesByNumbers}
                                tabIndex={2}
                            />
                        </div>
                    </div>

                    <div className='d-flex justify-content-between mb-2'>
                        <div className='row'>
                            <div className='w-lg-25px form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type="checkbox"
                                    id="selectAllClasses" 
                                    checked={selectAllClasses}
                                    onChange={e => setSelectAllClasses(e.target.checked)} 
                                    tabIndex={3}
                                />
                            </div>
                            
                            <label className='col col-form-label fs-5 py-1' htmlFor='selectAllClasses'>
                                { intl.formatMessage({ id: 'Classes.QuickAction.AddClassesFromOtherShow.Label.SelectAllClasses' }) }
                            </label>
                        </div>

                        <div className='row'>
                            <div className='w-lg-25px form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type="checkbox"
                                    id="showSelected" 
                                    checked={showSelected}
                                    onChange={e => setShowSelected(e.target.checked)} 
                                    tabIndex={4}
                                />
                            </div>
                            
                            <label className='col col-form-label fs-5 py-1' htmlFor='showSelected'>
                                { intl.formatMessage({ id: 'Classes.QuickAction.AddClassesFromOtherShow.Label.ShowSelected' }) }
                            </label>
                        </div>
                    </div>
                    <ClassesGrid 
                        setGridRef={setGridRef}
                        setNumSelected={setNumSelected}
                        tabIndex={5}
                    />
                </div>
         
                <div className='card-footer d-flex justify-content-end py-3 px-2'>
                    <label className="col col-form-label fs-5 py-1 fw-bold">
                        { numSelected > 0 ? (numSelected + " Class(es) Selected.") : "No Class Selected." }
                    </label>
                    <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={6}>
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CANCEL" })}
                    </button>

                    <button 
                        type="button" 
                        className='btn btn-sm btn-dark fw-bold text-uppercase'
                        disabled={numSelected === 0} 
                        onClick={addClassesFromOtherShow}
                        autoFocus
                        tabIndex={7}
                        >
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.IMPORT" })}
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default AddClassesFromOtherShow