import { useMemo, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';
import { useFormContext, Controller } from 'react-hook-form'
import SymbolRenderer from '../../../../modules/output-listing/renderers/SymbolRenderer';
import Select from "react-select";
import { reactSelectStyles } from '../../../../modules/sgl-utils/fieldControls'
import { useAppSelector } from '../../../../redux/hooks';
import axios from 'axios'
import { useParams } from 'react-router-dom';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import { addFloatingNumbers } from '../../../../modules/sgl-utils/SglFunctions';

const WithholdingTax = () => {
    const intl = useIntl();
    const methods = useFormContext()
    const currency = intl.formatMessage({ id: 'CURRENCY' })
    const [options, setOptions] = useState([]);
    const [gridRef, setGridRef] = useState();
    const [rowData, setRowData] = useState();
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const people_id = methods.getValues('people.people_id');
    const currencyFormatter = useCurrencyFormatter()

    const rowClassRules = useMemo(() => {
        return {
            'bg-red-grid-row': 'data.withheld_amount_invalid'
        };
    }, []);

    const columnDefs = [
        { field: 'entry_number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.WITHHOLDINGTAX.ENTRY' }) },
        { field: 'class_number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.WITHHOLDINGTAX.CLASS' }) },
        { field: 'show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.WITHHOLDINGTAX.SHOW' }), width: 400,
            valueGetter: params => {
                if(params.data && params.data.show_name){
                    let date = new Date(params.data.end_date)
                    return params.data.show_name.concat(' [',date.getFullYear(),']')
                }
            }
        },
        { field: 'total_prize_money', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.WITHHOLDINGTAX.PRIZEMONEY' }), aggFunc: 'sum', cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center'}, },
        { field: 'withholding_tax', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.WITHHOLDINGTAX.TAXWITHHELD' }), aggFunc: 'sum', cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center'}, },
        { field: 'withholding_tax_paid', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.WITHHOLDINGTAX.PAID' }), aggFunc: 'sum', cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center'}, },
        { field: 'withholding_tax_difference', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.WITHHOLDINGTAX.DIFFERENCE' }), aggFunc: 'sum', cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center'}, },
        { field: 'withholding_tax_rate', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.WITHHOLDINGTAX.RATE' }), cellRenderer: params => SymbolRenderer(params.value), cellStyle: {textAlign: 'center'}, },
        { field: 'should_have_withheld', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.WITHHOLDINGTAX.SHOULDHAVEWITHHELD' }), aggFunc: 'sum', cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center'}, },
    ]

    const containerStyle = useMemo(() => ({ width: '100%', height: '55vh' }), []);

    useEffect(() => {
        if(gridRef && methods.getValues('withholdingTax')){
            gridRef.setRowData(methods.getValues('withholdingTax'))
            calculateFooterValues(methods.getValues('withholdingTax'))
        }
    }, [gridRef, methods.getValues('withholdingTax')]);

    const calculateFooterValues = (withholdingTaxes) => {
        let prizeMoneyTotal = 0
        let taxWithheldTotal = 0
        let paidTotal = 0
        let differenceTotal = 0
        let shouldHaveWithHeld = 0

        withholdingTaxes.map(withholdingTax => {
            prizeMoneyTotal = addFloatingNumbers([prizeMoneyTotal, withholdingTax.total_prize_money], 2) 
            taxWithheldTotal = addFloatingNumbers([taxWithheldTotal, withholdingTax.withholding_tax], 2)
            paidTotal = addFloatingNumbers([paidTotal, withholdingTax.withholding_tax_paid], 2)
            differenceTotal = addFloatingNumbers([differenceTotal, withholdingTax.withholding_tax_difference], 2)
            shouldHaveWithHeld = addFloatingNumbers([shouldHaveWithHeld, withholdingTax.should_have_withheld], 2)
        })

        gridRef.setPinnedBottomRowData([{
            total_prize_money: prizeMoneyTotal,
            withholding_tax: taxWithheldTotal, 
            withholding_tax_paid: paidTotal,
            withholding_tax_difference: differenceTotal, 
            should_have_withheld: shouldHaveWithHeld}])

    }

    useEffect(() => {
        let year = new Date().getFullYear()
        let dropDownOptions = []
        while(year >= 2014){
            dropDownOptions.push({value: year, label: year})
            year--
        }
        setOptions(dropDownOptions)
    }, []);

    const withHoldingTaxByYear = async(year) => {
        gridRef?.showLoadingOverlay()
        methods.setValue('withHoldingTaxYear', year)
        await axios.get( process.env.REACT_APP_NEST_API_URL + '/people/withholdingTaxDetail', {
            params: {
                people_id,
                customer_id,
                year: year
            }
        }).then ((response) => {
            gridRef.setRowData(response.data)
            calculateFooterValues(response.data)
        }).finally(() => {
            gridRef?.hideOverlay()
        })
    }

    return (
        <>
            <div className='form-group'>
            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label fw-bold fs-5 py-1 w-lg-100px' htmlFor='fiscal_year' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.WITHHOLDINGTAX.LABEL.FISCALYEAR">
                    { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.WITHHOLDINGTAX.FISCALYEAR' }) }
                </label>
                <div className='col-lg-2'>
                    <Controller
                        name='fiscal_year'
                        render={
                            ({field: {onChange, value, name}}) => (
                                <Select
                                    id='fiscal_year'
                                    options={options}
                                    value={options.find(
                                        (ss) => ss.value === value
                                    )}
                                    name={name}
                                    isSearchable={true}
                                    onChange={(e) => {
                                        onChange(e)
                                        withHoldingTaxByYear(e.value)
                                    }}
                                    defaultValue={{label:new Date().getFullYear(), value:new Date().getFullYear()}}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                    })}
                                    styles={reactSelectStyles}
                                    placeholder={ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.WITHHOLDINGTAX.FISCALYEAR' }) }
                                />
                            )
                        }
                    />
                </div>
            </div>
                <div className='row align-items-center mb-2'>
                    <OutputListingGridStatic  area ={"People - Account WithholdingTax"} rowClassRules={rowClassRules} setGridRef={setGridRef} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle}></OutputListingGridStatic>
                </div>
            </div>
        </>
    );
}

export { WithholdingTax }