/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import BooleanCheckmarkRenderer from '../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import { BooleanFilterOptions } from '../../modules/output-listing/filters/BooleanFilter';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { getAllColumnDefinitions } from '../../modules/sgl-utils/agGridHelpers';
import { useAuth } from '../../modules/auth';
import { useOutputContext } from '../../modules/output-listing/OutputListingContext';
import { useCurrencyFormatter } from '../../modules/sgl-utils/Formatters';
import CustomHeader from './CustomHeader';
import axios from "axios";
import { useAlert, useLoadingOverlay } from "../../modules/sgl-utils/DialogsProvider";
import { useOutputContextUpdater } from '../../modules/output-listing/OutputListingContext';

const MasterFeesList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const show_id = useAppSelector(state => state.currentShow.show_id)
  const customer_id = useAppSelector(state => state.showCompany.company_id);
  const currencyFormatter = useCurrencyFormatter()
  const currentUser = useAuth()
  const { setOutputShowInactive } = useOutputContext();
  const [selectedRows, setSelectedRows] = useState([]);
  const outputContextUpdater = useOutputContextUpdater()
  const loadingOverlay = useLoadingOverlay()
  const alertDialog = useAlert()
  const [refreshKey, setRefreshKey] = useState(0);
  let refreshComponent = useCallback(() => { setRefreshKey(prevKey => prevKey + 1); }, []);
  const { currentCompany } = useAuth()
  const hasWriteAccess = currentCompany && currentCompany.permissions.includes(`cloud_masterfees:write`) 

  useEffect(() => {
    setShowInActive()
  }, [])

  useEffect(() => {
    if (selectedRows.length == 0 || refreshKey == 0) return; 

    const callApi = async () => {
      loadingOverlay({ show: true });
      try {
        const response = await axios.post(process.env.REACT_APP_NEST_API_URL + '/shows/fillShowFeeDataFromMasterFees', {
          params: {
            master_fee_ids: selectedRows,
            customer_id: customer_id,
            show_id: show_id
          }
        }
        );

        if (!response.data.success) {
          throw new Error('An error occurred while processing this request. Please try again!');
        }

      } catch (reason) {
        alertDialog({ title: 'Request Failed', message: reason.message, icon: 'stop' });
      } finally {
        outputContextUpdater({action: 'refresh'})
        loadingOverlay({ show: false });
      }
    };

    callApi();
  }, [refreshKey]); 


  const onInputChange = (e, params) => {
    const currentChecked = e.target.checked;
    const { master_fee_id } = params.data;

    setSelectedRows((prevSelectedRows) => {
      if (currentChecked) {
        return [...prevSelectedRows, master_fee_id];
      } else {
        return prevSelectedRows.filter((id) => id !== master_fee_id);
      }
    });

  };

  const columnDefs = [
    // { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.COMMONS.SGLID'}), filter: 'agNumberColumnFilter', filterParams: { suppressAndOrCondition: true,}, },
    {
      field: 'isShowFeeCreated', filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellStyle: { textAlign: 'center' },
      minWidth: 145,
      maxWidth: 145,
      headerComponentFramework: () => (
        <CustomHeader
          displayName = {intl.formatMessage({ id: 'LIST.MASTERFEES.ADDTOSHOW' })}
          refreshComponent={refreshComponent}
          disabled={!hasWriteAccess}
        />
      ),
      cellRenderer: (params) => {
        if (params.node.rowPinned) {
          return
        } else {
          return (
            <input
              type="checkbox"
              defaultChecked={Number(params?.data?.isShowFeeCreated)}
              onChange={(e) => { onInputChange(e, params) }}
              value={params.value}
              disabled={Number(params?.data?.isShowFeeCreated) || !hasWriteAccess}
            />
          )
        }
      }
    },
    { field: 'master_fee_id', headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.ID' }), filter: 'agNumberColumnFilter', hide: true },
    { field: 'name', width: 300, headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.NAME' }), filter: 'agTextColumnFilter', },
    { field: 'description', width: 300, headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.DESCRIPTION' }), filter: 'agTextColumnFilter', },
    { field: 'category', headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.CATEGORY' }), filter: 'agTextColumnFilter', },
    { field: 'directuserordering', headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.WEBORDERING' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, },
    { field: 'physical_delivery', headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.PHYSICALDELIVERY' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, },
    { field: 'active', headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.ACTIVE' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, },
    {
      field: 'default_price',
      headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.DEFAULTPRICE' }),
      filter: 'agNumberColumnFilter',
      cellRenderer: params => currencyFormatter(params.value),
      cellStyle: { textAlign: 'right' }
    },
  ]

  const setShowInActive = async () => {
    await getAllColumnDefinitions(currentUser?.user_id, customer_id, 'set')
    let showInactiveMasterFee = localStorage.getItem('show_inactive_masterfees')
    showInactiveMasterFee = showInactiveMasterFee && ['true', '1', true, 1].includes(showInactiveMasterFee) ? true : false
    setOutputShowInactive(showInactiveMasterFee)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.FEE' })}</PageTitle>
      <OutputListingComponent key={customer_id} area={'MasterFees'} columnDefs={columnDefs} apiEndPoint={'master-fees/list'}
        onRowDoubleClicked={(row_data) => { navigate('/masterfees/detail/' + row_data.master_fee_id + `?customer_id=${customer_id}`) }}
        onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL + '/masterfees/detail/' + row_data.master_fee_id + `?customer_id=${customer_id}`, '_blank')}>
      </OutputListingComponent>
    </>
  )
}

export { MasterFeesList }