/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import DateRenderer from '../../modules/output-listing/renderers/DateRenderer';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';

const ShowsList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const customer_id = useAppSelector(state=> state.showCompany.company_id);

  const columnDefs = [
    // { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.COMMONS.SGLID'}), filter: 'agNumberColumnFilter', filterParams: { suppressAndOrCondition: true, }, },
    { field: 'show_id', headerName: intl.formatMessage({id: 'LIST.SHOWS.SHOWID'}), filter: 'agNumberColumnFilter',hide:true},
    { field: 'show_name', width: 400, headerName: intl.formatMessage({id: 'LIST.SHOWS.SHOWNAME'}), filter: 'agTextColumnFilter', },
    { field: 'start_date', headerName: intl.formatMessage({id: 'LIST.SHOWS.STARTDATE'}), filter: 'agDateColumnFilter', cellRenderer: DateRenderer, cellStyle: { textAlign: 'center' },},
    { field: 'end_date', headerName: intl.formatMessage({id: 'LIST.SHOWS.ENDDATE'}), filter: 'agDateColumnFilter', cellRenderer: DateRenderer, cellStyle: { textAlign: 'center' },},
    { field: 'entry_count', headerName: intl.formatMessage({id: 'LIST.SHOWS.ENTRYCOUNT'}), filter: false},
    // { field: 'mdfive_license', width: 300, headerName: intl.formatMessage({id: 'LIST.SHOWS.MD5LICENCE'}), filter: 'agTextColumnFilter', },
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SHOWS'})}</PageTitle>
      <OutputListingComponent key={customer_id} area={'Shows'} columnDefs={columnDefs} apiEndPoint={'shows/list'} 
        onRowDoubleClicked={(row_data) => {navigate('/shows/detail/'+row_data.show_id+`?customer_id=${customer_id}`)}} 
        onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL+'/shows/detail/'+row_data.show_id+`?customer_id=${customer_id}` ,'_blank')}>
      </OutputListingComponent>
    </>
  )
}

export {ShowsList}
