export const customConfirm  = (question) => {
    return new Promise(r => {
        const behind = document.createElement("div");
        behind.style = `
            position:fixed;
            width:100%;
            height:100%;
            background: rgba(0,0,0,0.3);
            left:0;
            top:0;
            z-index: 999;
                display: flex;
                align-items: center;
                justify-content: center;
            `
        behind.setAttribute("tabindex", "0");
        behind.setAttribute("id","customConfirmDialog");
        behind.onkeydown = function(e) {
            if(e.key == "Escape" || e.key == "Enter"){
                if(e.key == "Escape"){
                    r(false);
                    behind.remove();
                }else if(e.key == "Enter"){
                    r(true);
                    behind.remove();
                } 
            }
        }
        document.body.append(behind)
        const container = document.createElement("div");
        container.style = `
            max-width: 450px !important;
            margin-right: auto;
            margin-left: auto;
            height: 146px;
            background-color:#f4f8fb;
            `
        const header = document.createElement("div");
        header.style=`background-color:#fefefe`;
        header.innerHTML = '<div class="modal-header py-0 px-4"><h2 class="fs-4">Confirm</h2><div class="btn btn-sm btn-icon btn-active-color-dark" onclick="document.getElementById(\'custom_confirm_cancel_button\').click()"><span class="svg-icon svg-icon-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mh-50px"><rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect><rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect></svg></span></div></div>';
        container.append(header)
        const q = document.createElement("span");
        q.innerHTML = '<div class="row mb-3" style="padding:5px 13px;"><div class="col-lg-1 d-flex align-self-center"><i class="fa-sharp fa-solid fa-circle-info fa-2xl" style="color: rgb(87, 142, 190);"></i></div><div class="col ms-lg-2"><span class="d-inline-block align-bottom">'+question+'</span></div></div>';
        container.append(q)
        const ync = document.createElement("div"); //yes no container
        ync.style =
            `
            align-items: center;
            justify-content: center;
            display:flex;
            `
        var btn = document.createElement("span");
        btn.innerText = "CANCEL";
        btn.id = "custom_confirm_cancel_button"
        btn.style = `
            background-color: #e1e5f2;
            border-bottom-color: rgb(157, 160, 169);
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-left-color: rgb(157, 160, 169);
            border-left-style: solid;
            border-left-width: 1px;
            border-right-color: rgb(157, 160, 169);
            border-right-style: solid;
            border-right-width: 1px;
            border-top-color: rgb(157, 160, 169);
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-top-style: solid;
            border-top-width: 1px;
            box-shadow: none;
            box-sizing: border-box;
            color: rgb(22, 22, 24);
            cursor: pointer;
            display: block;
            font-family: Lato, Helvetica, "sans-serif";
            font-size: 12.025px;
            font-weight: 600;
            line-height: 18.0333px;
            margin-bottom: 0px;
            margin-left: 275.1px;
            margin-right: 13px;
            margin-top: 0px;
            outline-color: rgb(22, 22, 24);
            outline-style: none;
            outline-width: 0px;
            padding-bottom: 7.5px;
            padding-left: 17.25px;
            padding-right: 17.25px;
            padding-top: 7.5px;
            pointer-events: auto;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;
            `
        btn.onclick = function() {
            r(false);
            behind.remove();
        }
        ync.append(btn)
        btn = btn.cloneNode();
        btn.onclick = function() {
            r(true);
            behind.remove();
        }
        btn.style.backgroundColor = "#000000"
        btn.style.color = "#ffffff"
        btn.style.borderColor = "#000000"
        btn.innerText = "OK";
        btn.style.marginLeft = "0px"
        ync.append(btn)
        container.append(ync)
        behind.append(container)
        behind.focus()
    })
}