/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import DateRenderer from '../../modules/output-listing/renderers/DateRenderer';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';

const ShowSeriesList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const customer_id = useAppSelector(state=> state.showCompany.company_id);

  const columnDefs = [
    // { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.COMMONS.SGLID'}), filter: 'agNumberColumnFilter', filterParams: { suppressAndOrCondition: true,},},
    { field: 'show_series_id', headerName: intl.formatMessage({id: 'LIST.SHOWSERIES.SHOWSERIESID'}), filter: 'agNumberColumnFilter', hide:true },
    { field: 'series_name', width: 300, headerName: intl.formatMessage({id: 'LIST.SHOWSERIES.SERIESNAME'}), filter: 'agTextColumnFilter', },
    { field: 'series_start', headerName: intl.formatMessage({id: 'LIST.SHOWSERIES.SERIESSTART'}), filter: 'agDateColumnFilter', cellRenderer: DateRenderer, cellStyle: {textAlign: 'center'},  },
    { field: 'series_end', headerName: intl.formatMessage({id: 'LIST.SHOWSERIES.SERIESEND'}), filter: 'agDateColumnFilter', cellRenderer: DateRenderer, cellStyle: {textAlign: 'center'},},
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SHOWSERIES'})}</PageTitle>
      <OutputListingComponent key={customer_id} area={'ShowSeries'} columnDefs={columnDefs} apiEndPoint={'show-series/list'}  
        onRowDoubleClicked={(row_data) => {navigate('/showseries/detail/'+row_data.show_series_id+`?customer_id=${customer_id}`)}}
        onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL+'/showseries/detail/'+row_data.show_series_id+`?customer_id=${customer_id}`,'_blank')}>
      </OutputListingComponent>
    </>
  )
}

export {ShowSeriesList}
