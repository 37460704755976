import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form';

// Custom Components
import { HorseSection } from './General/HorseSection';
import { PersonSection } from './General/PersonSection';
import { StableSection } from './General/StableSection';

const GeneralTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext()

    return (
        <>
            <div className='form-group mb-2'>
                <HorseSection generateNewRTOId={props.generateNewRTOId} form_meta={props.form_meta} horseSuspensionStatus={props.horseSuspensionStatus} callbackFromParent={props.callbackFromParent} getRiderStatus={props.getRiderStatus}/>
                <fieldset className='mb-2'>
                    <PersonSection 
                        generateNewRTOId={props.generateNewRTOId} 
                        getSelectedPeople={props.getSelectedPeople} 
                        updatePeople={props.updatePeople} 
                        callSearchPersonApi={props.callSearchPersonApi}
                        getFirstNameFromSearchString={props.getFirstNameFromSearchString}
                        getLastNameFromSearchString={props.getLastNameFromSearchString}
                        type={"OWNER"} 
                        person_id={methods.watch('entry.entryowner_id')} 
                        rowData={methods.getValues('personMemberships')} 
                        RTOSuspensionStatus={props.RTOSuspensionStatus}
                        callbackFromParent={props.callbackFromParent}
                        updateCallbackFromParent={props.updateCallbackFromParent}
                        entry_id={props.entryId}
                        callFormSubmit={props.callFormSubmit}
                        showTaxInformation={props.showTaxInformation}
                        setShowTaxInformation={props.setShowTaxInformation}
                        personIdForTaxStatusLookup={props.personIdForTaxStatusLookup} 
                        setPersonIdForTaxStatusLookup={props.setPersonIdForTaxStatusLookup}
                        selectedTaxFormRequestType={props.selectedTaxFormRequestType}
                        clearMembership={props.clearMembership}
                    />
                </fieldset>
                <fieldset className='mb-2'>
                    <PersonSection 
                        generateNewRTOId={props.generateNewRTOId} 
                        getSelectedPeople={props.getSelectedPeople} 
                        updatePeople={props.updatePeople}  
                        callSearchPersonApi={props.callSearchPersonApi} 
                        getFirstNameFromSearchString={props.getFirstNameFromSearchString}
                        getLastNameFromSearchString={props.getLastNameFromSearchString}
                        type={"TRAINER"} 
                        person_id={methods.watch('entry.trainer_id')} 
                        rowData={methods.getValues('personMemberships')} 
                        RTOSuspensionStatus={props.RTOSuspensionStatus}
                        callbackFromParent={props.callbackFromParent}
                        entry_id={props.entryId}
                        callFormSubmit={props.callFormSubmit}
                        showTaxInformation={props.showTaxInformation}
                        setShowTaxInformation={props.setShowTaxInformation}
                        personIdForTaxStatusLookup={props.personIdForTaxStatusLookup} 
                        setPersonIdForTaxStatusLookup={props.setPersonIdForTaxStatusLookup}
                        selectedTaxFormRequestType={props.selectedTaxFormRequestType}
                        clearMembership={props.clearMembership}
                    />
                </fieldset>
                <StableSection callbackFromParent={props.callbackFromParent}/>
            </div>
        </>
    );
}

export { GeneralTab }