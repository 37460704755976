import { useOutputContext } from '../../output-listing/OutputListingContext'
import { useEffect, useState } from "react";
import useQuickActions from "../../hooks/use-quick-action";
import useAccessChecker from '../../hooks/use-access-checker';
import { logSiteActivity } from '../../sgl-utils/SglFunctions';
import { useAppSelector } from "../../../redux/hooks";
import { useAlert } from '../../sgl-utils/DialogsProvider';
import axios from 'axios';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const QuickActions = () => {
    const {outputGrid} = useOutputContext();
    const { entriesQuickActions, classesQuickActions, paymentsQuickActions, horsesQuickActions, stableQuickActions, peopleQuickActions, masterFeesQuickActions, showFeesQuickActions, ringsQuickActions, showsQuickActions, divisionsQuickActions, circuitQuickActions } = useQuickActions()
    const { hasAtLeastOnePermission } = useAccessChecker()
    const [currentQuickAction, setCurrentQuickAction] = useState("")
    const customerID = useAppSelector(state=> state.showCompany.company_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const [isTaxFormLookupEnabled, setIsTaxFormLookupEnabled] = useState(false)
    const alertDialog = useAlert()

    const modalActions = [
        ...entriesQuickActions.filter(qa => qa.type === 'Modal'),
        ...classesQuickActions.filter(qa => qa.type === 'Modal'),
        ...showsQuickActions.filter(qa => qa.type === 'Modal'),
        ...divisionsQuickActions.filter(qa => qa.type === 'Modal'),
        ...horsesQuickActions.filter(qa => qa.type === 'Modal'),
        ...peopleQuickActions.filter(qa => qa.type === 'Modal'),
        ...showFeesQuickActions.filter(qa => qa.type === 'Modal'),
        ...circuitQuickActions.filter(qa => qa.type === 'Modal')
    ]

    const handleClick = async (event, title, type=null, handler=null) => {
        event.preventDefault()
        // Log quick actions in site_monitoring_activity_log table
        logSiteActivity(currentShowID, customerID, {
            source: "Quick Actions",
            tab_name: outputGrid?.area,
            activity: title,
            description: 'Open'
        })
        if (type && type !== 'Modal' && handler) {
            await handler();
        } else {
            setCurrentQuickAction(title);
        }
    }

    // Display Override Missing Tax Status option and Export Tax Status List in sub menu only if tax form lookup is enabled
    const checkTaxFormEnabledForRTO = (quickAction) => {
        let quickActionsToCheckTaxStatusLookup = ['Override Missing Tax Status', 'Export Tax Status List', 'Run TIN Check']
        if(quickActionsToCheckTaxStatusLookup.includes(quickAction)){
            return isTaxFormLookupEnabled
        }
        return true
    }
    
    useEffect(() => {
        axios.get(`${NEST_API_URL}/people/getTaxFormPostLookupEnabled?customer_id=${customerID}`)
        .then(response => {
                if(response?.data?.success){
                    setIsTaxFormLookupEnabled(response?.data?.isTaxFormLookupEnabled)
                }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        });
    }, [customerID])

    return (
        <>
            {
                modalActions
                    .filter(({ title }) => currentQuickAction === title)
                    .map(({ Component }, i) => 
                        <Component
                            key={i}
                            show={true}
                            handleClose={() => setCurrentQuickAction("")}
                        />
                    )
            }          
            { outputGrid.area === "Classes" &&
                <div className="menu menu-sub menu-sub-dropdown quick-action-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 w-250px " data-kt-menu="true" data-kt-element="theme-mode-menu">
                    {
                        classesQuickActions
                        .map(({ title, handler, type, permissionsRequired }, i) => 
                            // Check if there are permissions to access, then those permissions must exist.
                            (permissionsRequired.length === 0 || hasAtLeastOnePermission(permissionsRequired)) &&
                            <div key={i} className="menu-item px-2 my-0 cursor-pointer" onClick={
                                (e) => handleClick(e, title, type, handler) 
                            }>
                                <div className="menu-link px-3 py-2 active">
                                    <span className="menu-title">{ title }</span>
                                </div>
                            </div>
                        ) 
                    }
                </div>
            }
            { outputGrid.area === "Entries" &&
                <div className="menu menu-sub menu-sub-dropdown quick-action-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 w-250px " data-kt-menu="true" data-kt-element="theme-mode-menu">
                    {
                        entriesQuickActions
                        .map(({ title, handler, type, permissionsRequired}, i) => 
                            // Check if there are permissions to access, then those permissions must exist.
                            (permissionsRequired.length === 0 || hasAtLeastOnePermission(permissionsRequired)) &&
                            <div key={i} className="menu-item px-2 my-0 cursor-pointer" onClick={(e) => handleClick(e, title, type, handler)}>
                                <div className="menu-link px-3 py-2 active">
                                    <span className="menu-title">{ title }</span>
                                </div>
                            </div>
                        ) 
                    }
                </div>
            }
            {
                outputGrid.area === "Payments" &&
                <div className="menu menu-sub menu-sub-dropdown quick-action-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 w-300px " data-kt-menu="true" data-kt-element="theme-mode-menu">
                    { paymentsQuickActions.map(({ title, handler, type, permissionsRequired }, i) => 
                        hasAtLeastOnePermission(permissionsRequired) &&
                        <div key={i} className="menu-item px-2 my-0 cursor-pointer" onClick={
                            (e) => handleClick(e, title, type, handler)
                        }>
                            <div className="menu-link px-3 py-2 active">
                                <span className="menu-title">{ title }</span>
                            </div>
                        </div>
                    ) 
                    }
                </div>
            }
            {
                outputGrid.area === "Horses" &&
                <div className="menu menu-sub menu-sub-dropdown quick-action-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 w-150px " data-kt-menu="true" data-kt-element="theme-mode-menu">
                    { horsesQuickActions.map(({ title, handler, type, permissionsRequired }, i) => 
                        (permissionsRequired.length === 0 || hasAtLeastOnePermission(permissionsRequired)) &&
                        <div key={i} className="menu-item px-2 my-0 cursor-pointer" onClick={
                            (e) => handleClick(e, title, type, handler)
                        }>
                            <div className="menu-link px-3 py-2 active">
                                <span className="menu-title">{ title }</span>
                            </div>
                        </div>
                    ) 
                    }
                </div>
            }
            {
                outputGrid.area === "Stables" &&
                <div className="menu menu-sub menu-sub-dropdown quick-action-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 w-150px " data-kt-menu="true" data-kt-element="theme-mode-menu">
                    { stableQuickActions.map(({ title, handler, type, permissionsRequired }, i) => 
                        hasAtLeastOnePermission(permissionsRequired) &&
                        <div key={i} className="menu-item px-2 my-0 cursor-pointer" onClick={
                            (e) => handleClick(e, title, type, handler)
                        }>
                            <div className="menu-link px-3 py-2 active">
                                <span className="menu-title">{ title }</span>
                            </div>
                        </div>
                    ) 
                    }
                </div>
            }
            {
                outputGrid.area === "People" &&
                <div className={'menu menu-sub menu-sub-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 w-250px'}  data-kt-menu="true" data-kt-element="theme-mode-menu">
                    { peopleQuickActions.map(({ title, handler, type, permissionsRequired }, i) => 
                        ((permissionsRequired.length === 0 || hasAtLeastOnePermission(permissionsRequired)) && checkTaxFormEnabledForRTO(title)) &&
                        <div key={i} className="menu-item px-2 my-0 cursor-pointer" onClick={
                            (e) => handleClick(e, title, type, handler)
                        }>
                            <div className="menu-link px-3 py-2 active">
                                <span className="menu-title">{ title }</span>
                            </div>
                        </div>
                    ) 
                    }
                </div>
            }
            {
                outputGrid.area === "MasterFees" &&
                <div className="menu menu-sub menu-sub-dropdown quick-action-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 w-150px " data-kt-menu="true" data-kt-element="theme-mode-menu">
                    { masterFeesQuickActions.map(({ title, handler, type, permissionsRequired }, i) => 
                        hasAtLeastOnePermission(permissionsRequired) &&
                        <div key={i} className="menu-item px-2 my-0 cursor-pointer" onClick={
                            (e) => handleClick(e, title, type, handler)
                        }>
                            <div className="menu-link px-3 py-2 active">
                                <span className="menu-title">{ title }</span>
                            </div>
                        </div>
                    ) 
                    }
                </div>
            }
            {
                outputGrid.area === "Fees" &&
                <div className="menu menu-sub menu-sub-dropdown quick-action-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 w-200px " data-kt-menu="true" data-kt-element="theme-mode-menu">
                    { showFeesQuickActions.map(({ title, handler, type, permissionsRequired }, i) => 
                        (permissionsRequired.length === 0 || hasAtLeastOnePermission(permissionsRequired)) &&
                        <div key={i} className="menu-item px-2 my-0 cursor-pointer" onClick={
                            (e) => handleClick(e, title, type, handler)
                        }>
                            <div className="menu-link px-3 py-2 active">
                                <span className="menu-title">{ title }</span>
                            </div>
                        </div>
                    ) 
                    }
                </div>
            }
            {
                outputGrid.area === "Rings" &&
                <div className="menu menu-sub menu-sub-dropdown quick-action-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 w-150px " data-kt-menu="true" data-kt-element="theme-mode-menu">
                    { ringsQuickActions.map(({ title, handler, type, permissionsRequired }, i) => 
                        hasAtLeastOnePermission(permissionsRequired) &&
                        <div key={i} className="menu-item px-2 my-0 cursor-pointer" onClick={
                            (e) => handleClick(e, title, type, handler)
                        }>
                            <div className="menu-link px-3 py-2 active">
                                <span className="menu-title">{ title }</span>
                            </div>
                        </div>
                    ) 
                    }
                </div>
            }
            { outputGrid.area === "Shows" &&
                <div className="menu menu-sub menu-sub-dropdown quick-action-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 w-250px " data-kt-menu="true" data-kt-element="theme-mode-menu">
                    {
                        showsQuickActions
                        .map(({ title, handler, type, permissionsRequired }, i) => 
                            // Check if there are permissions to access, then those permissions must exist.
                            (permissionsRequired.length === 0 || hasAtLeastOnePermission(permissionsRequired)) &&
                            <div key={i} className="menu-item px-2 my-0 cursor-pointer" onClick={
                                (e) => handleClick(e, title, type, handler) 
                            }>
                                <div className="menu-link px-3 py-2 active">
                                    <span className="menu-title">{ title }</span>
                                </div>
                            </div>
                        ) 
                    }
                </div>
            }
            {/* For Divisions Area */}
            { outputGrid.area === "Divisions" &&
                <div className="menu menu-sub menu-sub-dropdown quick-action-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 w-250px " data-kt-menu="true" data-kt-element="theme-mode-menu">
                    {
                        divisionsQuickActions
                        .map(({ title, handler, type, permissionsRequired }, i) => 
                            // Check if there are permissions to access, then those permissions must exist.
                            (permissionsRequired.length === 0 || hasAtLeastOnePermission(permissionsRequired)) &&
                            <div key={i} className="menu-item px-2 my-0 cursor-pointer" onClick={(e) => handleClick(e, title, type, handler)}>
                                <div className="menu-link px-3 py-2 active">
                                    <span className="menu-title">{ title }</span>
                                </div>
                            </div>
                        ) 
                    }
                </div>
            }
            { outputGrid.area === "Circuits" &&
                <div className="menu menu-sub menu-sub-dropdown quick-action-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 w-250px " data-kt-menu="true" data-kt-element="theme-mode-menu">
                    {
                        circuitQuickActions
                        .map(({ title, handler, type, permissionsRequired }, i) => 
                            // Check if there are permissions to access, then those permissions must exist.
                            (permissionsRequired.length === 0 || hasAtLeastOnePermission(permissionsRequired)) &&
                            <div key={i} className="menu-item px-2 my-0 cursor-pointer" onClick={(e) => handleClick(e, title, type, handler)}>
                                <div className="menu-link px-3 py-2 active">
                                    <span className="menu-title">{ title }</span>
                                </div>
                            </div>
                        ) 
                    }
                </div>
            }
        </>
    )
}

export { QuickActions }
