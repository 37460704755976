import { useIntl } from "react-intl"
import { PageTitle } from "../../../../_metronic/layout/core"
import { SchoolingRoundsContextProvider, useSchoolingRoundsContext } from "./SchoolingRoundsContext"
import { useEffect, useRef } from "react"
import axios from "axios"
import { useAlert } from "../../../modules/sgl-utils/DialogsProvider"
import RoundTripsGrid from "./RoundTripsGrid"
import { getRowData } from "../../../modules/sgl-utils/agGridHelpers"
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait, overrideEventTarget } from "../../../modules/sgl-utils/SglFunctions"
import moment from "moment"
import { handlePrompt } from "../../../modules/sgl-utils/unstableHistory"
import HelpLink from "../../../modules/components/HelpLink"
const BASE_URL = process.env.REACT_APP_NEST_API_URL

const SchoolingRoundsWrapper = () => {
    return (
        <SchoolingRoundsContextProvider>
            <SchoolingRounds />
        </SchoolingRoundsContextProvider>
    )
}

const SchoolingRounds = () => {
    const { currentShow, customerId, gridRef, schoolingShowFee, showForm, numEntries, setNumEntries } = useSchoolingRoundsContext()
    const alertDialog = useAlert()
    const entryNumberInputRef = useRef()
    const intl = useIntl()
    
    useEffect(() => {
        let unblock
        // Block navigation and register a callback that fires when a navigation attempt is blocked.
        if (numEntries > 0) {
            unblock = handlePrompt('You have some schooling round entries in the list. Are you sure you want to leave this page?')
        }

        return () => { if (typeof unblock === "function") { unblock() } }
    }, [numEntries])

    const addSchoolingRoundEntry = async (event) => {
        try {
            loadingSpinnerBtnWait(event)

            const entryNumber = Number(entryNumberInputRef.current.value)
        
            let message = ''
            const rowData = getRowData(gridRef)
            if (isNaN(entryNumber) || entryNumber <= 0) { // Validate Entry Number
                message = 'Please enter valid Entry number.'
            } else if (rowData.find(r => r.number === entryNumber )) {
                message = `Entry #${entryNumber} is already added. To add a schooling round click the horse name.`
            }

            if (message) {
                alertDialog({ message })
                return
            }

            const response = await axios.get(`${BASE_URL}/ingate-announcer/addSchoolingRoundEntry`, {
                params: { 
                    customer_id: customerId,
                    show_id: currentShow.show_id,
                    entry_number: entryNumber,
                    schooling_show_fees_id: schoolingShowFee.showfees_id
                }
            })

            if (response.data.success) {
                const entry = response.data.entry 
                entry.trips = 1
                entry.lastTrip =  moment().format('HH:mm:ss');

                gridRef.applyTransaction({ 
                    add: [entry], 
                    addIndex: rowData.length // insert at end
                }) 

                setNumEntries(prevNumEntries => prevNumEntries + 1)
            }
        } catch (reason) {
            if (reason?.response?.data?.message) {
                alertDialog({ message: reason?.response?.data?.message })
            } else {
                alertDialog({ message: 'Something went wrong..' })
            }
        } finally {
            entryNumberInputRef.current.value = ''
            entryNumberInputRef.current.focus()
            loadingSpinnerBtnRelease(event)
        }
    }

    return (
        <>
            <PageTitle 
                breadcrumbs={[]}
                description={[currentShow.show_name]}    
            >   
                {intl.formatMessage({id: 'MENU.SCHOOLINGROUNDS'})}
            </PageTitle>
            <HelpLink tooltip="SCHOOLINGROUND.DETAIL.LINK.HELP" classname="top" />
            { showForm &&
            <div className='modal-body py-0 px-4 scheduler' style={{ minHeight: '75vh' }}>
                <div className="row mb-2">
                    <RoundTripsGrid />
                </div>

                <div className='row mb-4'>
                    <label 
                        className='w-lg-75px col-form-label fs-5 py-1' 
                        htmlFor='entryNumber'
                        data-tooltip-id="SCHOOLINGROUND.DETAIL.LABEL.ENTRY"
                    >
                        Entry
                    </label>

                    <div className='col-lg-2 position-relative'>
                        <input
                            ref={entryNumberInputRef}
                            type="number"
                            id='entryNumber'
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            onKeyDown={event => {
                                if (event.key === 'Enter') {
                                    addSchoolingRoundEntry(overrideEventTarget(event, 'addEntryButton'))
                                }
                            }}
                        />
                    </div>
        
                    <div className='col-lg-2 w-100px me-3'>
                        <button 
                            id='addEntryButton'
                            className='btn btn-sm btn-secondary fw-bold h-30px py-1 w-100px'
                            type='button' 
                            disabled={!currentShow.show_id || currentShow?.islocked} // No Current Show
                            onClick={(event) => addSchoolingRoundEntry(event)}
                            data-tooltip-id="SCHOOLINGROUND.DETAIL.BUTTON.ADD"
                        >
                            <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                            Add
                        </button>
                    </div>
                </div>    
            </div>
            }
        </>
    )
}

export { SchoolingRoundsWrapper as SchoolingRounds }