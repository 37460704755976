import { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import axios from 'axios'

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers'

// Custom Components
import { useAlert, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider'
import { useAppSelector } from '../../../redux/hooks'
import { renderCheckBox } from '../../../modules/sgl-utils/agGridHelpers'
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic'
import { downloadTextFile } from '../../../modules/sgl-utils/SglFunctions'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const modalsRoot = document.getElementById('root-modals') || document.body

const ReplicateFeesToSeries = ({ show, handleClose }) => {
    const intl = useIntl()
    const alertDialog = useAlert()
    const {progressBar} = useProgress()
    const loadingOverlay = useLoadingOverlay()
    const { getEventID, getOutputSelectionSelectedRowsAreaIDs } = useOutputContext()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)
    const showId = useAppSelector(state => state.currentShow.show_id)
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

    const [gridRef, setGridRef] = useState()
    const [rowData, setRowData] = useState([])
    const [seriesShows, setSeriesShows] = useState([])

    const columnDefs = [
        { field: 'description', width: 250, headerName: intl.formatMessage({ id: 'SHOWFEES.QUICKACTION.REPLICATEFESSTOSERIES.LABEL.FEE' }) },
    ]

    useEffect(() => {
        if (show && gridRef) {
            if(showFinancialsLocked){
                alertDialog({ message: intl.formatMessage({ id: "SHOWFEES.QUICKACTION.REPLICATEFESSTOSERIES.ERROR.SHOWLOCKED" }), title: "warning" })
                handleClose()
                return
            }

            loadingOverlay({ show: true })
            const show_fee_ids = getOutputSelectionSelectedRowsAreaIDs()
            if (show_fee_ids.length === 0) {
                alertDialog({ message: 'Please select some show fees first.' })
                loadingOverlay({ show: false })
                handleClose()
            } else {
                axios.post(`${NEST_API_URL}/fees/getShowFeesToReplicate`, {
                    customer_id: customerId,
                    show_fee_ids: show_fee_ids,
                    show_id: showId
                }).then(res => {
                    if (!res.data?.success) {
                        alertDialog({ message: res.data.message })
                        handleClose()
                    } else {
                        let colDefs = [...columnDefs, ...res.data.series_shows.map(show => {
                            let headerText = show.name_abbr ? show.name_abbr : show.show_name
                            if (headerText.length > 18) {
                                headerText = headerText.slice(0, 15) + '...';
                            }
                            return { field: `show_${show.show_id}`, headerName: headerText, cellRenderer: renderCheckBox, cellClass: 'text-center', flex: 1, minWidth: 160, cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }
                        })]

                        gridRef.setColumnDefs(colDefs)
                        setRowData(res.data.show_fees)
                        setSeriesShows(res.data.series_shows)
                    }
                }).catch(err => {
                    alertDialog({ message: err?.response?.data?.error ?? err })
                    handleClose()
                }).finally(() => {
                    loadingOverlay({ show: false })
                })
            }
        }
    }, [show, gridRef])

    const selectAll = () => {
        let rows = []
        const columns = gridRef.getColumnDefs().filter(col => col.field != 'description').map(col => col.field)
        gridRef.forEachNode(node => {
            for(const col of columns){
                node.data[col] = true
            }
            rows.push(node.data)
        })

        gridRef.setRowData(rows)
    }

    const createFees = () => {
        const eventID = getEventID('replicate-fees-to-series')
        progressBar({ show: true, eventID, showProgress: 'inline', title: 'Replicate Entries to Series', timeElapsed: true })

        let show_fee_data = []
        const columns = gridRef.getColumnDefs().filter(col => col.field != 'description').map(col => col.field)
        gridRef.forEachNode(node => {
            let data = {...node.data}

            let show_ids = []
            for(const col of columns){
                if(data[col]){
                    show_ids.push(parseInt(col.split('_')[1]))
                }

                delete data[col]
            }

            data.show_ids = show_ids
            show_fee_data.push(data)
        })

        axios.post(`${NEST_API_URL}/fees/replicateFeeToSeries`, {
            customer_id: customerId,
            show_fee_data: show_fee_data,
            series_shows: seriesShows,
            show_id: showId,
            event_id: eventID
        }).then(res => {
            if(res.data?.success && res.data?.error_file_content){ // Download error file
                downloadTextFile(res.data.error_file_content, 'ReplicateFeesToSeries_Errors.csv', 'text/csv')
            }else if(!res.data?.success && res.data?.message){
                alertDialog({ message: res.data.message })
            }
        }).catch(async err => {
            await alertDialog({ message: err?.response?.data?.error ?? err })
        }).finally(() => {
            progressBar({ show: false, eventID })
            handleClose()
        })
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-1100px-max-width w-900px'
            show={show}
            onHide={handleClose}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'SHOWFEES.QUICKACTION.REPLICATEFESSTOSERIES.TITLE' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col col-form-label fw-bold fs-5 py-1'>
                                {intl.formatMessage({ id: 'SHOWFEES.QUICKACTION.REPLICATEFESSTOSERIES.LABEL.CONFIRM' })}
                            </label>
                        </div>
                        <div className='row mb-2'>
                            <OutputListingGridStatic
                                columnDefs={columnDefs}
                                rowData={rowData}
                                defaultColDef={columnDefs}
                                setGridRef={setGridRef}
                                containerStyle={containerStyle}
                            ></OutputListingGridStatic>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>

                        <div className='col-lg-6'>
                            <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' onClick={selectAll}>
                                {intl.formatMessage({ id: 'SHOWFEES.QUICKACTION.REPLICATEFESSTOSERIES.BUTTON.SELECTALL' })}
                            </button>
                        </div>
                        <div className='col-lg-6 d-flex justify-content-end'>
                            <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' onClick={handleClose}>
                                {intl.formatMessage({ id: 'SHOWFEES.QUICKACTION.REPLICATEFESSTOSERIES.BUTTON.DONE' })}
                            </button>

                            <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={createFees} autoFocus>
                                {intl.formatMessage({ id: 'SHOWFEES.QUICKACTION.REPLICATEFESSTOSERIES.BUTTON.CREATEFEES' })}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { ReplicateFeesToSeries }