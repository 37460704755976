
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form';

// Custom Components
import Select from "react-select";
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls';

const Attributes = (props) => {
    const intl = useIntl();
    const methods = useFormContext()

    return (
        <>
        <div className='attributes-grid-container col-10 mb-5'> 

            <div className='attributes-grid col form-control p-0 pb-2'>
  
                <div className='attributes-header row mb-2 border-bottom mx-0 py-1'>
                    <label className='col-3 col-form-label fw-bolder fs-5 py-1 text-center' htmlFor='show_address'>{intl.formatMessage({id: 'FORM.INPUT.CLASSRULE.ATTRIBUTEVALUE.ATTRIBUTE'})}</label>
                    <div className="col-lg-1 attribute-heading-separator"></div>
                        <label className='col col-form-label fw-bolder fs-5 py-1 text-center' htmlFor='show_address'>{intl.formatMessage({id: 'FORM.INPUT.CLASSRULE.ATTRIBUTEVALUE.VALUE'})}</label>
                    </div>

                    {
                        !methods.watch('class_rule_attributes')?.length ?
                        <div className='row justify-content-center align-items-center' style={{ height: '160px' }}>
                            {intl.formatMessage({id: 'FORM.INPUT.CLASSRULE.ERROR.NOATTRIBUTESFOUND'})}
                        </div>
                        :null
                    }

                    { 
                        methods.watch('class_rule_attributes').map ((classRuleTypeAttribute, index) => { 
                        return (
                        <div key={index} className='row mb-2 px-3'>
                            <div className='col-3'>
                                <label className=' col-form-label fs-5 py-1 w-lg-150px' htmlFor='fee_id' data-tooltip-id={`CLASSRULES.DETAIL.TAB.ATTRIBUTES.LABEL.${classRuleTypeAttribute.field_name?.replace(' ', '')?.toUpperCase()}`}>{classRuleTypeAttribute.field_name}</label>
                            </div>
                            {  
                                classRuleTypeAttribute.field_name === 'Fee Name'? // feeList
                                <div className='col'>
                                    <Select
                                        options = {methods.watch ('master_fee_data')}
                                        menuPosition='fixed'
                                        value={ (props.get_fee_id() !== undefined || props.get_fee_id() !== 0) ?
                                            methods.getValues ('master_fee_data').filter((ss) => ss.value === props.get_fee_id())
                                            :props.get_fee_id()
                                        }
                                        id = "fee_id"
                                        isSearchable={true}
                    
                                        onChange={(e)=>{
                        
                                            const index = methods.getValues('class_rule_attributes').findIndex((element) => element.field_name === classRuleTypeAttribute.field_name);
                                            const updatedArray = [...methods.getValues('class_rule_attributes') ] // Create a copy of the original array
                                            updatedArray[index].fee_id = e.value; // Update the value at that specified index
                                            updatedArray[index].value = e.label;
                                            methods.setValue("class_rule_attributes", updatedArray, {shouldDirty: true});
                    
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                        })}
                                        styles={{...reactSelectStyles,  menuPortal: base => ({ ...base, zIndex: 9999 })}}
                                    />    
                                </div>
                                :
                                <div className='col'>
                                    <input
                                        {...methods.register(classRuleTypeAttribute.field_name)}
                                        id='NAME'
                                        type='text'
                                        value = {props.get_field_name (classRuleTypeAttribute.field_name) !== undefined ? classRuleTypeAttribute.value: props.get_field_name (classRuleTypeAttribute.field_name) }
                                        onChange={(e) => {
                        
                                            const index = methods.getValues('class_rule_attributes').findIndex((element) => element.field_name === classRuleTypeAttribute.field_name);
                                            const updatedArray = [...methods.getValues('class_rule_attributes') ] // Create a copy of the original array
                                            // updatedArray[index].fee_id = e.target.value; // Update the value at that specified index
                                            updatedArray[index].value = e.target.value; // Update the value at that specified index
                                            methods.setValue("class_rule_attributes", updatedArray, {shouldDirty: true});
                        
                                        }
                                        }
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    /> 
                                </div>
                            }
                        </div>
                        );
                    })
                    }
                </div>
            </div>
        </>
    );
}

export { Attributes }