import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import { Core } from "@grapecity/activereports"
import { fontDescriptors } from './modules/arjs-utils/arjsConstants'

import ToolTipProvider from './modules/sgl-utils/ToolTipProvider'
// Get the ActiveReportsJS license key from the environment variables
const activeReportJsLicenseKey = process.env.REACT_APP_ACTIVE_REPORT_JS_LICENSE;

// If license key is not null
if (activeReportJsLicenseKey) {
    // Set the ActiveReportsJS license key using the Core module
    Core.setLicenseKey(activeReportJsLicenseKey);
}

  // Assuming this code runs before displaying or exporting reports
async function registerFontsAsync() {
  try {
    await Core.FontStore.registerFonts(...fontDescriptors);
  } catch (error) {
      console.error("Error registering fonts:", error);
  }
}

registerFontsAsync();

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <ToolTipProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </ToolTipProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
