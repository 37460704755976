import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useAlert, useLoadingOverlay } from "../../../modules/sgl-utils/DialogsProvider";
import { useAppSelector } from "../../../redux/hooks";

const BASE_URL = process.env.REACT_APP_NEST_API_URL

const SchoolingRoundsContext = createContext()

const SchoolingRoundsContextProvider = ({ children }) => {
    const [gridRef, setGridRef] = useState(null)
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const [showForm, setShowForm] = useState(false)
    const [currentShow, setCurrentShow] = useState({
        show_id: 0,
        show_name: ''
    })
    const [schoolingShowFee, setSchoolingShowFee] = useState({
        showfees_id: 0
    })
    const [numEntries, setNumEntries] = useState(0)

    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    
    useEffect(() => {
        const getSchoolingRoundsMetaData = async () => {
            try {
                loadingOverlay({ show: true, message: 'Loading data..'})
                const response = await axios.get(`${BASE_URL}/ingate-announcer/getSchoolingRoundsMetaData?customer_id=${customerId}`)

                if (response.data.success) {
                    setCurrentShow(response.data.show)
                    setSchoolingShowFee(response.data.schoolingShowFee)
                }
            } catch (reason) {
                if (reason?.response?.data?.message) {
                    alertDialog({ message: reason?.response?.data?.message })
                } else {
                    alertDialog({ message: reason })
                }
            } finally {
                loadingOverlay({ show: false, message: 'Loading data..'})
            }
        }

        getSchoolingRoundsMetaData()
    }, [])

    useEffect(() => {
        if (currentShow && schoolingShowFee) {
            setShowForm(currentShow.show_id > 0 && schoolingShowFee.showfees_id > 0)
        }
    }, [currentShow, schoolingShowFee])

    return (
        <SchoolingRoundsContext.Provider 
            value={{
                currentShow,
                customerId,
                gridRef, 
                setGridRef,
                schoolingShowFee,
                setSchoolingShowFee,
                showForm,
                numEntries,
                setNumEntries
            }}
        >
            {children}
        </SchoolingRoundsContext.Provider>
    );
}

// context consumer hook
const useSchoolingRoundsContext = () => {
    // get the context
    const context = useContext(SchoolingRoundsContext);
  
    // if `undefined`, throw an error
    if (context === undefined) {
      throw new Error("useSchoolingRoundsContext was used outside of its Provider");
    }
  
    return context;
};

export { useSchoolingRoundsContext, SchoolingRoundsContextProvider }