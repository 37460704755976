import { useState } from 'react'
import { useIntl } from 'react-intl'
import { CreateShowLicense } from '../../../pages/shows/CreateShowLicense';
const CreateShowLicenseMenu = ({ hasWriteAccess, addNewRecordButtonRef }: { hasWriteAccess: boolean; addNewRecordButtonRef: React.RefObject<HTMLAnchorElement> }) => {
    const intl = useIntl()
    const [showLicenseModal, setShowLicenseModal] = useState(false)

    return (
        <>
            <div>
                <a ref={addNewRecordButtonRef} href='#' className="btn btn-icon btn-active-light me-3 px-8 py-8 add-new-record-button"
                    onClick={() => hasWriteAccess && setShowLicenseModal(true)}>
                    <div className="fa-item">
                        <i className={`fa fa-plus-circle fs-1 text-${hasWriteAccess ? "green" : "gray"}`}></i>
                        <br/>
                        <span className="fs-8 fw-bold">{intl.formatMessage({id: 'TOOLBAR.LABEL.ADD'})}</span>
                    </div>
                </a>
                
            </div>
            {(showLicenseModal) &&
                <CreateShowLicense show={showLicenseModal} handleClose={() => setShowLicenseModal(false)} />
            }
        </>
    )
}

export { CreateShowLicenseMenu }
