import React, { useState, useEffect } from 'react'
import { Designer } from "@grapecity/activereports-react";
import { useIntl } from 'react-intl';
import { ViewPreviewModal } from './ViewPreviewModal';


const DesignerTab = ({save, report, Width, Height, activeReportRef, onUploadReport, onSaveAs}) => {
    const intl = useIntl()
    const [showPreview, setShowPreview] = useState(false);
    const [reportDetails, setReportDetails] = useState(null);
    
    const handlePerviewModal = async () => {
        let reportDefinition = await activeReportRef.current.getReport()
        setReportDetails(reportDefinition)
        setShowPreview(true)
    }

    useEffect(() => {
        setReportDetails(report)
    }, [report]);


    return (
    <>
        <ViewPreviewModal 
            show={showPreview}
            handleClose={() => setShowPreview(false)}
            reportDetails={reportDetails}
            setReportDetails={setReportDetails}

        />
        <div className='row mb-5 d-flex justify-content-end'>
            <div className='col-lg-1 w-lg-150px'>
                <button type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase" onClick={onUploadReport} data-tooltip-id="REPORT.DETAIL.TAB.DESIGNER.BUTTON.UPLOADREPORT">
                    {intl.formatMessage({id: 'FORM.INPUT.REPORTS.DESIGNER.BUTTON.UPLOADREPORT'})}
                </button>
            </div>

            <div className='col-lg-1 w-lg-150px'>
                <button type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase" onClick={() =>handlePerviewModal()} data-tooltip-id="REPORT.DETAIL.TAB.DESIGNER.BUTTON.OPENINPREVIEW">
                    {intl.formatMessage({id: 'FORM.INPUT.REPORTS.DESIGNER.BUTTON.OPENINPREVIEW'})}
                </button>
            </div>
        </div>
        {reportDetails?.definition && 
            <div id="designer-host">
                <Designer onSave={save} onSaveAs={onSaveAs} report={reportDetails} Width={Width} Height={Height} ref={activeReportRef} />
            </div>
        }
    </>
  )
}

export { DesignerTab }