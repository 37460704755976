
import Select from 'react-select'
import { reactSelectStyles } from '../../modules/sgl-utils/fieldControls'
import { useEffect } from 'react'
import { useOutputContext } from '../../modules/output-listing/OutputListingContext'
import { useAppSelector } from '../../redux/hooks'

const RingsDisplayFilter = () => {
    const { customFilters, setCustomFilters } = useOutputContext();
    const customerID = useAppSelector(state=> state.showCompany.company_id);

    const dropDownOptions = [
        { value: "All", label: "All" },
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" },
    ]

    useEffect(() => {
        setCustomFilters({ ...customFilters, display: "Active" })
    }, [])

    useEffect(() => {
        setCustomFilters(() => {return {...customFilters, company: customerID, display: "Active" }})
    }, [customerID])

    const reactSelectStyles = {
        control: (provided, state) => ({
          ...provided,
          background: state?.isDisabled? '#eff2f5' : '#fff',
          height: 40,
          minHeight: 40,
          fontSize: 14,
          fontFamily: 'Lato',
          color: "#3F4254",
          borderColor: "#A1A5B7",
          borderWidth: 1,
          boxShadow: 'none',
          borderRadius: 0,
          outline:0,
          width: 150,
          '&:focus': {
              borderColor: '#A1A5B7',
          },
          '&:hover': {
              borderColor: '#A1A5B7',
          }
        })
    }

    return (
        <div className='w-500px ms-4' style={{ display: 'flex', gap: '10px' }}>
            <Select
                options={dropDownOptions}
                id='display'
                name={'display'}
                isSearchable={true}
                className='fs-6'
                onChange={e => setCustomFilters({...customFilters, display: e.value })}
                value={dropDownOptions.find((option) => option.value == customFilters.display)}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                })}
                styles={reactSelectStyles}
            />
        </div>
    )
}

export { RingsDisplayFilter }
