import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { AnalyticsTab } from './AnalyticsTab';
import { RingsTab } from './RingsTab';
import { CalendarTab } from './CalendarTab';
import useAccessChecker from '../../modules/hooks/use-access-checker';
import { useIntl } from 'react-intl';
import { QueuedJobsTab } from './QueuedJobsTab';

const DashboardWrapper = () => {
  const intl = useIntl();
  const { hasAtLeastOnePermission } = useAccessChecker();
  const [isLoading, setIsLoading] = useState(true);

  const ringsPermissionsRequired = ['cloud_dashboard-rings:rings-widget'];
  const calendarPermissionsRequired = ['cloud_dashboard-calendar:calendar-widget'];

  const [tabs, setTabs] = useState(['ANALYTICS']);
  const [componentTags, setComponentTags] = useState([<AnalyticsTab key="ANALYTICS" />]);

  const [activeTab, setActiveTab] = useState('ANALYTICS');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const updateTabsAndComponents = async () => {
      let newTabs = [];
      let newComponentTags = [];

      if (hasAtLeastOnePermission(ringsPermissionsRequired)) {
        newTabs.push('RINGS');
        newComponentTags.push(<RingsTab key="RINGS" />);
      }

      if (hasAtLeastOnePermission(calendarPermissionsRequired)) {
        newTabs.push('CALENDAR');
        newComponentTags.push(<CalendarTab key="CALENDAR" />);
      }

      newTabs.push('QUEUEDJOBS')
      newComponentTags.push(<QueuedJobsTab key="QUEUEDJOBS" />)

      setTabs((prevTabs) => [...prevTabs, ...newTabs]);
      setComponentTags((prevTags) => [...prevTags, ...newComponentTags]);
      setIsLoading(false);
    };

    updateTabsAndComponents();
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <div id="dashboard">
        {!isLoading && (
          <div className="card-toolbar mt-5">
            <ul
              className="nav py-3 px-5 "
              role="tablist"
              tabIndex="-1"
              style={{ overflowX: 'auto', overflowY: 'hidden' }}
            >
              {tabs.map((tab, index) => (
                <li key={index} className="nav-item">
                  <a
                    tabIndex="-1"
                    className={
                      'nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold px-4 me-1 rounded' +
                      (index === 0 ? ' active' : '')
                    }
                    id={tab + '-tab'}
                    data-bs-toggle="tab"
                    href={'#' + tab}
                    onClick={() => handleTabClick(tab)}
                  >
                    {intl.formatMessage({ id: 'DASHBOARD.TAB.' + tab })}
                  </a>
                </li>
              ))}
            </ul>
            <div className="tab-content" id="myTabContent">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className={`tab-pane fade show ${activeTab === tab ? 'active' : ''}`}
                  id={tab}
                  role="tabpanel"
                >
                  {React.isValidElement(componentTags[index]) && (
                    React.cloneElement(componentTags[index], { isActiveTab: (activeTab == tab) })
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { DashboardWrapper };
