import { useMemo, useState, useEffect } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers';
import { useIntl } from 'react-intl'
import { removeDialogFromSession } from '../../../../modules/sgl-utils/SglFunctions';

type Props = {
  show: boolean
  handleClose: () => void,
  message: string,
  title: string,
  icon: string,
  dropInputRef: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ClassAddWarningDialog = ({ show, handleClose, message, title, icon, dropInputRef }: Props) => {
  const intl = useIntl()

  useEffect(() => {
    if (show) {
      const storedModals = JSON.parse(sessionStorage.getItem('modals_open') || '[]');
      sessionStorage.setItem('modals_open', JSON.stringify([...storedModals, 'classAddWarning']));
    }
  }, [show]);

  useEffect(() => {
    if (show) {
        const handleEnterKey = (event: any) => {
            event.preventDefault();
            event.stopPropagation();
            if (event.key === "Enter" || event.key === "Escape") {
                const storedModals = sessionStorage.getItem('modals_open');
                const modals_open = storedModals ? JSON.parse(storedModals) : [];
                const lastModal = modals_open.pop();
                if (lastModal === 'classAddWarning') {
                  closeDialog()
                }
            }
        };

        document.addEventListener('keydown', handleEnterKey, true);

        return () => {
            document.removeEventListener('keydown', handleEnterKey, true);
        };
    }
  }, [show]);

  const renderedIcon = useMemo(() => {
    switch(icon){
      case "stop": return <i className="fa-2xl fa-sharp fa-solid fa-circle-xmark text-danger"></i>
      case "info": return <i className="fa-sharp fa-solid fa-circle-info fa-2xl" style={{ color: '#578EBE' }}></i>
      case "ban": return <i className="fas fa-solid fa-ban fa-2xl text-danger"></i>
      case "comment": return <i className="fa-sharp fa-solid fa-comment-dots fa-2xl"></i>
      default: return <i className="fas fa-2xl fa-regular fa-triangle-exclamation text-warning"></i>
    }
  }, [icon]);

  const renderedTitle = useMemo(() => {
    switch(title){
      case "confirm": return intl.formatMessage({ id: 'DIALOG.COMMON.TITLE.CONFIRM' })
      case "note": return intl.formatMessage({ id: 'DIALOG.COMMON.TITLE.NOTE' })
      case "warning": return intl.formatMessage({ id: 'DIALOG.COMMON.TITLE.WARNING' })
    }
    return title ? title : intl.formatMessage({ id: 'DIALOG.COMMON.TITLE.ALERT' })
  }, [title]);

  const closeDialog = () => {
    removeDialogFromSession('classAddWarning')
    handleClose()
    // Move focus to drop Input
    if (dropInputRef) {
        setTimeout(() => {
            dropInputRef.current.focus();
        }, 400);
    }
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
      show={show}
      onHide={closeDialog}
    //   onKeyPress={(event: any) => {
    //     if (event.key === "Enter") {
    //         handleClose()
    //     }
    // }}
    >
      <div className='modal-header py-0 px-4'>
        <h2 className="fs-4">{renderedTitle}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={closeDialog}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4'>
        <form noValidate className='form' onSubmit={e => e.preventDefault()}>
          <div className='card-body p-0'>
            <div className='row mb-3'>
              <div className='col-lg-1 d-flex align-self-center'>{renderedIcon}</div>
              <div className='col ms-lg-2'>
                <span className="d-inline-block align-bottom" style={{whiteSpace: 'pre-line'}}>{message}</span>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-3 px-0'>
            <button  type = "button" className='btn btn-sm btn-dark fw-bold' onClick={closeDialog} autoFocus>
              { intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.OK' }) }
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { ClassAddWarningDialog }