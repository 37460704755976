import { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import UpcomingShowsGrid from './ReplicateEntriesToSeries/UpcomingShowsGrid'
import { useAlert, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider'
import { useAppSelector } from '../../../redux/hooks'
import axios from 'axios'
import FeeGrid from './ReplicateEntriesToSeries/FeeGrid'
import { getRowData, renderCheckBox } from '../../../modules/sgl-utils/agGridHelpers'
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const modalsRoot = document.getElementById('root-modals') || document.body


const ReplicateEntriesToSeries = ({ show, handleClose }) => {
    const intl = useIntl() 
    const {progressBar} = useProgress()
    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const { getEventID, getOutputSelectionSelectedRowsAreaIDs }  = useOutputContext()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const showId = useAppSelector(state => state.currentShow.show_id)
    const replicateSignatureInputRef = useRef(null)
    const copyClassesInputRef = useRef(null)
    const [copyFees, setCopyFees] = useState(false)
    const [selectAllShows, setSelectAllShows] = useState(false)
    const [futureSeriesShows, setFutureSeriesShows] = useState([])
 
    const [feeGridRef, setFeeGridRef] = useState(null)

    const [columnDefs, setColumnDefs] = useState([
        { 
            field: 'number', 
            headerName: 'Entry',  
            width: 90 
        },
        { 
            field: 'horse', 
            width: 130
        },
        { 
            field: 'trainer', 
            width: 130
        },
        { 
            field: 'owner', 
            width: 130
        },
        { 
            field: 'status', 
            width: 400,   
        }
    ])
    const [upcomingShowsGridRef, setUpcomingShowsGridRef] = useState(null)

    // Handles select all shows checkbox
    useEffect(() => {
        if (upcomingShowsGridRef) {
            const rowData = getRowData(upcomingShowsGridRef)
            rowData.forEach(row => {
                for (const key in row) {
                    let show_obj = futureSeriesShows.find(show => show.show_id == key)
                    if (!isNaN(Number(key)) && show_obj && !show_obj.islocked) { // We set show columns field as show_id which is always a number.
                        row[key] = selectAllShows
                    }
                }
            })
            upcomingShowsGridRef.setRowData(rowData)
        }
    }, [upcomingShowsGridRef, selectAllShows])

    // Handles copy fees checkbox
    useEffect(() => {
        if (feeGridRef) {
            const rowData = getRowData(feeGridRef)
            rowData.forEach(row => row.include = copyFees)
            feeGridRef.setRowData(rowData)
        }
    }, [feeGridRef, copyFees])


    useEffect(() => {
        if (show && upcomingShowsGridRef) {
            const validateAndGetMetaData = async () => {   // Validates classes in selection
                try {
                    loadingOverlay({ show: true })
                    const entryIds = getOutputSelectionSelectedRowsAreaIDs()
                    if (entryIds.length === 0) {
                        alertDialog({ message: 'Please select some entries first.'})
                        handleClose()
                    } else {
                        const response = await axios.post(`${NEST_API_URL}/entries/getReplicateEntriesToSeriesMetadata`, {
                            customer_id: customerId,
                            entry_ids: entryIds,
                            show_id: showId
                        }) 

                        if (response.data.success) {
                            if (response.data.message) 
                                alertDialog({ message: response.data.message })

                            setColumnDefs(prevColumnDefs => {
                                const newColumnDefs = structuredClone(prevColumnDefs)
                                const lastColumnDef = newColumnDefs.pop()
                                for (const futureSeriesShow of response.data.futureSeriesShows) {
                                    newColumnDefs.push({
                                        field: String(futureSeriesShow.show_id),
                                        headerName:  futureSeriesShow.name_abbr ? futureSeriesShow.name_abbr : futureSeriesShow.show_name.substring(0, 12),
                                        cellRenderer: params => renderCheckBox(params, false, futureSeriesShow.islocked > 0), 
                                        width: 120,
                                        cellClass: 'text-center',  
                                    })
                                }
                                newColumnDefs.push(lastColumnDef)
                                return newColumnDefs
                            })

                            for (const data of response.data.addSeriesData) {
                                for (const futureSeriesShow of response.data.futureSeriesShows) {
                                    data[futureSeriesShow.show_id] = false 
                                    data['islocked'] = futureSeriesShow.islocked
                                }
                            }

                            setFutureSeriesShows(response.data.futureSeriesShows)
                            upcomingShowsGridRef.setRowData(response.data.addSeriesData) 
                            feeGridRef.setRowData(response.data.addSeriesFeeData)
                        }
                    }

                } catch (reason) {
                    let error = reason?.response?.data?.error ?? reason
                    alertDialog({ message: error })
                    handleClose()
                } finally {
                    loadingOverlay({ show: false })
                }
            }
            validateAndGetMetaData()
        }
        
    }, [show, upcomingShowsGridRef])

    const addEntryToSeries = async () => {
        const eventID = getEventID('add-entry-to-series')
        try {
            const feesGridRowData = getRowData(feeGridRef) 
            const upcomingShowsGridRowData = getRowData(upcomingShowsGridRef)            
            progressBar({ show: true, eventID, showProgress: 'inline', title: 'Replicate Entries to Series', timeElapsed: true })

            const response = await axios.post(`${NEST_API_URL}/entries/replicateEntriesToSeries`, {
                customer_id: customerId,
                current_show_id: showId,
                replicate_digital_signatures: replicateSignatureInputRef.current.checked,
                copy_classes: copyClassesInputRef.current.checked,
                copy_fees: copyFees,
                entry_fees_to_add: feesGridRowData.filter(ef => ef.include), // send only selected entry fees data
                entries_data: upcomingShowsGridRowData,
                event_id: eventID
            })

            upcomingShowsGridRef.setRowData(response.data.entriesData)

        } catch (reason) {
            alertDialog({ message: reason })
        } finally {
            progressBar({ show: false, eventID })
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-1100px-max-width'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) {
                    addEntryToSeries()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">Add Entry to Series</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col col-form-label fw-bold fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.ADDENTRYTOSERIES.LABEL.QUESTION"> 
                            Would you like to add these entries to any further shows in this series?
                            </label>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-3 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='KEEPENTRYNUM'
                                    autoFocus
                                    tabIndex={1}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='KEEPENTRYNUM' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.ADDENTRYTOSERIES.LABEL.KEEPENTRYNUM"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.LABEL.KEEPENTRYNUM' })}</label>
                            </div>
                            <div className='col-lg-3 form-check-sm form-check-custom'>
                                <input
                                    ref={replicateSignatureInputRef}
                                    className='form-check-input'
                                    type='checkbox'
                                    id='REPLICATEDIGISIGNS'
                                    tabIndex={2}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='REPLICATEDIGISIGNS' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.ADDENTRYTOSERIES.LABEL.REPLICATEDIGISIGNS"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.LABEL.REPLICATEDIGISIGNS' })}</label>
                            </div>
                            <div className='col-lg-2 form-check-sm form-check-custom'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='selectAllShows'
                                    value={selectAllShows}
                                    onChange={e => setSelectAllShows(e.target.checked)}
                                    tabIndex={3}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='selectAllShows' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.ADDENTRYTOSERIES.LABEL.SELECTALLSHOWS">
                                    Select All Shows
                                </label>
                            </div>
                            <div className='col-lg-2 form-check-sm form-check-custom'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='copyFees'
                                    onChange={e => setCopyFees(e.target.checked)}
                                    checked={copyFees}
                                    tabIndex={4}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='copyFees' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.ADDENTRYTOSERIES.LABEL.COPYFEES">
                                    Copy Fees
                                </label>
                            </div>
                            <div className='col-lg-2 form-check-sm form-check-custom'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='copyClasses'
                                    ref={copyClassesInputRef}
                                    tabIndex={5}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='copyClasses' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.ADDENTRYTOSERIES.LABEL.COPYCLASSES">
                                    Copy Classes
                                </label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.ADDENTRYTOSERIES.LABEL.UPCOMINGSERIESSHOWS"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.LABEL.UPCOMMINGSERIESSHOWS' })}</label>
                        </div>
                        <div className='row mb-2'>
                            <UpcomingShowsGrid
                                setUpcomingShowsGridRef={setUpcomingShowsGridRef}
                                columnDefs={columnDefs}
                                tabIndex={6}
                            />
                        </div>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.ADDENTRYTOSERIES.LABEL.CARRYFORWARD"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.LABEL.CARRYFORWARD' })}</label>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-9'>
                                <FeeGrid
                                    copyFees={copyFees}
                                    setFeeGridRef={setFeeGridRef}
                                    tabIndex={7}
                                />
                            </div>
                            <div className='col-lg-3 align-self-end'>
                                <div className='row mb-2 justify-content-end'>
                                    <div className='col-lg-1 w-lg-200px'>
                                        <button 
                                            type='button' 
                                            className="btn btn-sm btn-dark fw-bold px-2 py-2 text-uppercase col-12"
                                            onClick={addEntryToSeries}    
                                            tabIndex={8}
                                            data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.ADDENTRYTOSERIES.BUTTON.CREATEENTRIES"
                                        >
                                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.BUTTON.CREATEENTRIES' })}
                                        </button>
                                    </div>
                                </div>
                                <div className='row justify-content-end'>
                                    <div className='col-lg-1 w-lg-200px'>
                                        <button 
                                            type='button' 
                                            className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12"
                                            onClick={handleClose}
                                            tabIndex={9}
                                            data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.ADDENTRYTOSERIES.BUTTON.DONE"
                                        >
                                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.BUTTON.DONE' })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { ReplicateEntriesToSeries }