import { useEffect, useState } from 'react';
import zxcvbn from 'zxcvbn';


const PasswordStrengthMeter = ({ password }) => {
    const [strength, setStrength] = useState(0)
    const [strengthMessge, setStrengthMessage] = useState('')

    const calculateStrength = (password) => {
        const lowerCaseRegex = /[a-z]/;
        const upperCaseRegex = /[A-Z]/;
        const numericRegex = /[0-9]/;
        const specialCharRegex = /[^A-Za-z0-9]/;

        // Calculate the overall strength
        let count = 1
        const total = 4
        const zxcvbnResult = zxcvbn(password)
        const zxcvbnScore = zxcvbnResult.score

        if (password.length >= 8) count++
        if (lowerCaseRegex.test(password) && upperCaseRegex.test(password) && numericRegex.test(password) && specialCharRegex.test(password)) count++

        if (zxcvbnScore >= 3 && count == 3) {
            count++
        }

        const overallStrength = (count / total) * 100;
        setStrength(overallStrength)

        setStrengthMessage(getPasswordStrengthText(count))
    };

    const getPasswordStrengthText = (score) => {
        switch (score) {
            case 0:
                return 'Very Weak';
            case 1:
                return 'Weak';
            case 2:
                return 'Fair';
            case 3:
                return 'Good';
            case 4:
                return 'Strong';
            default:
                return '';
        }
    };

    useEffect(() => {
        calculateStrength(password)
    }, [password])

    // Function to set color based on strength
    const getColor = (strength) => {
        if (strength < 30) return 'red';
        if (strength < 60) return 'orange';
        if (strength < 90) return 'yellow';
        return 'green';
    };

    return (
        <div className='pt-2 w-100'>
            <div
                // role="progressbar"
                style={{ width: `${strength}%`, backgroundColor: getColor(strength), borderRadius: 20, height: '4px' }}
                aria-valuenow={strength}
                aria-valuemin="0"
                aria-valuemax="100"
            >
            </div>

            <div className='d-flex justify-content-end mx-0'>
                <p className='text-muted m-0'>{strengthMessge}</p>
            </div>
        </div>
    );
};

export default PasswordStrengthMeter