import { useMemo } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic'
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters'
import CurrencyRenderer from '../../../../modules/output-listing/renderers/CurrencyRenderer'
import moment from 'moment'


const modalsRoot = document.getElementById('root-modals') || document.body

const PaymentDetailForm = ({ show, handleClose, selectedPaymentxEntry }) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()
    const currency = intl.formatMessage({ id: 'CURRENCY'})
    const columnDefs = [
        { field: 'Entry.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.ENTRY' }) },
        { field: 'amount_applied', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.AMOUNTAPPLIED' }), cellRendererSelector: () => ({ component: CurrencyRenderer, params: { currency }}) , cellClass: 'text-center' },
        { field: 'Entry.horse', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.HORSE' }) },
        { field: 'Entry.Show.show_name', flex: 2, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.SHOW' }) },
    ]

    const rowData = selectedPaymentxEntry?.Payment?.Paymentxentries

    const containerStyle = useMemo(() => ({ width: '100%', height: '200px' }), []);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-850px search-form'
            show={show}
            onHide={handleClose}
            onKeyPress={(event) => {
                if (event.key === "Enter") {
                    handleClose()
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-125px fw-bold { width: 145px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.TITLE.ENTRYPAYMENTDETAIL' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose} >
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYPAYMENTDETAIL.LABEL.DESCRIPTION">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.LABEL.DESCRIPTION' })}</label>
                            <label className='col col-form-label fs-5 py-1'>
                                { 
                                    selectedPaymentxEntry?.Payment?.description 
                                }
                            </label>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-6'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYPAYMENTDETAIL.LABEL.AMOUNT">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.LABEL.AMOUNT' })}</label>
                                <label className='col col-form-label fs-5 py-1'>
                                    { currencyFormatter(selectedPaymentxEntry?.Payment?.amount) }
                                </label>
                            </div>
                            <div className='col-lg-6'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-80px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYPAYMENTDETAIL.LABEL.TYPE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.LABEL.TYPE' })}</label>
                                <label className='col col-form-label fs-5 py-1'>
                                    { selectedPaymentxEntry?.Payment?.type }
                                </label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-6'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYPAYMENTDETAIL.LABEL.DATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.LABEL.DATE' })}</label>
                                <label className='col col-form-label fs-5 py-1'>
                                    { 
                                        moment(selectedPaymentxEntry?.Payment?.payment_date).isValid() ? moment(selectedPaymentxEntry?.Payment?.payment_date).format("MM/DD/YYYY") : "" }
                                </label>
                            </div>
                            <div className='col-lg-6'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-80px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYPAYMENTDETAIL.LABEL.TIME">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.LABEL.TIME' })}</label>
                                <label className='col col-form-label fs-5 py-1'>
                                    { moment(selectedPaymentxEntry?.Payment?.creation_time,"HH:mm:ss").format('h:mm A') }
                                </label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-6'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYPAYMENTDETAIL.LABEL.TRANSID">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.LABEL.TRANSID' })}</label>
                                <label className='col col-form-label fs-5 py-1'>
                                    { selectedPaymentxEntry?.Payment?.cc_transid }
                                </label>
                            </div>
                            <div className='col-lg-6'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-80px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYPAYMENTDETAIL.LABEL.INVOICENUMBER">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.LABEL.INVOICENUM' })}</label>
                                <label className='col col-form-label fs-5 py-1'>
                                    { selectedPaymentxEntry?.Payment?.invoice_number }
                                </label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-6'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYPAYMENTDETAIL.LABEL.STATUS">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.LABEL.STATUS' })}</label>
                                <label className='col col-form-label fs-5 py-1'>
                                    { selectedPaymentxEntry?.Payment?.status }
                                </label>
                            </div>
                            <div className='col-lg-6'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-80px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYPAYMENTDETAIL.LABEL.REFCODE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.LABEL.REFCODE' })}</label>
                                <label className='col col-form-label fs-5 py-1'>
                                    { selectedPaymentxEntry?.Payment?.refcode }
                                </label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYPAYMENTDETAIL.LABEL.USER">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.LABEL.USER' })}</label>
                            <label className='col col-form-label fs-5 py-1'>{ selectedPaymentxEntry?.Payment?.paymentUserName?.lf_name}</label>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-6'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYPAYMENTDETAIL.LABEL.VERIFIED">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.LABEL.VERIFIED' })}</label>
                                <label className='col col-form-label fs-5 py-1'>
                                    { selectedPaymentxEntry?.Payment?.verified ? "Yes" : "No" }
                                </label>
                            </div>
                            <div className='col-lg-6'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-80px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYPAYMENTDETAIL.LABEL.REVERESED">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.LABEL.REVERSED' })}</label>
                                <label className='col col-form-label fs-5 py-1'>
                                    { selectedPaymentxEntry?.Payment?.reversed ? "Yes" : "No" }
                                </label>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYPAYMENTDETAIL.LABEL.WEBPAYMENT">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.LABEL.WEBPAYMENT' })}</label>
                            <label className='col col-form-label fs-5 py-1'>
                                { selectedPaymentxEntry?.Payment?.web_add ? "Yes" : "No" }
                            </label>
                        </div>
                        <fieldset className='mb-2'>
                            <legend className="fs-5 fw-bold col">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.GROUPLABEL.PAYMENTDISTRIBUTION' })} <b></b></legend>
                            <div className='row mb-2'>
                                <OutputListingGridStatic
                                    area ={"Entires - Account Payment Detail"}
                                    columnDefs={columnDefs}
                                    rowData={rowData}
                                    containerStyle={containerStyle}
                                ></OutputListingGridStatic>
                            </div>
                        </fieldset>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={handleClose} autoFocus data-tooltip-id="ENTRIES.DETAIL.MODAL.ENTRYPAYMENTDETAIL.BUTTON.DONE">
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.BUTTON.DONE' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { PaymentDetailForm }