import { useEffect } from "react";

const useAutoFocus = (id, loading, refresh = 0, area = '', record_id = null, timeout = 500) => {
    useEffect(() => {
        let inputElement = document.getElementById(id);

        switch (area) {
            case "classes":
                if (record_id == 0) {
                    let focusInputElement = document.getElementById('number');
                    if (focusInputElement) {
                        inputElement = focusInputElement;
                    }
                } else {
                    let focusInputElement = document.getElementById('sponsor');
                    if (focusInputElement) {
                        inputElement = focusInputElement;
                    }
                }
                break;
            case "entries":
                if (record_id == 0) {
                    let focusInputElement = document.getElementById('ENTRYNUMBER');
                    if (focusInputElement) {
                        inputElement = focusInputElement;
                    }
                }
                break;
            case "showfees":
                // TODO - make it conditional
                // if (record_id == 0) {
                //     let focusInputElement = document.getElementById('SELECTFEE');
                //     if (focusInputElement) {
                //         inputElement = focusInputElement;
                //     }
                // } else {
                //     let focusInputElement = document.getElementById('SHOWDESCRIPTION');
                //     if (focusInputElement) {
                //         inputElement = focusInputElement;
                //     }
                // }

                let focusInputElement = document.getElementById('SHOWDESCRIPTION');
                if (focusInputElement) {
                    inputElement = focusInputElement;
                }
                break;
            default:
                break;
        }

        if (inputElement) {
            setTimeout(() => {
                inputElement.focus();
            }, timeout);
        }
    }, [id, refresh, loading])
}

export default useAutoFocus;
