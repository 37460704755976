import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import { useNavigate } from "react-router-dom"
import { useAppSelector } from '../../redux/hooks';
import { useOutputContext } from '../../modules/output-listing/OutputListingContext';
import { useCurrencyFormatter } from '../../modules/sgl-utils/Formatters';

const PeopleList = () => {
    const intl = useIntl();
    const navigate = useNavigate()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const {quickSearch} = useOutputContext()
    const currencyFormatter = useCurrencyFormatter()

    const columnDefs = [
        // { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.COMMONS.SGLID'}), filter: 'agNumberColumnFilter', filterParams: { suppressAndOrCondition: true,}, },
        { field: 'people_id', headerName: intl.formatMessage({ id: 'LIST.PEOPLE.PEOPLEID' }), filter: 'agNumberColumnFilter', hide:true},
        { field: 'organization', headerName: intl.formatMessage({ id: 'LIST.PEOPLE.ORGANIZATION' }), sortable: false, filter: false, },
        { field: 'membership_id', headerName: intl.formatMessage({ id: 'LIST.PEOPLE.MEMBERSHIPID' }), sortable: true, filter: false, },
        { field: 'lf_name', width: 400, headerName: intl.formatMessage({ id: 'LIST.PEOPLE.LFNAME' }), filter: 'agTextColumnFilter', },
        { field: 'city', width: 300, headerName: intl.formatMessage({ id: 'LIST.PEOPLE.CITY' }), filter: 'agTextColumnFilter', },
        { 
            field: 'state',
            headerName: intl.formatMessage({ id: 'LIST.PEOPLE.STATE' }), 
            filter: 'agTextColumnFilter', 
            cellClass: 'text-center',	
        },
        { 
            field: 'account_balance', 
            headerName: intl.formatMessage({ id: 'LIST.PEOPLE.BALANCE' }), 
            filter: 'agNumberColumnFilter',
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: 'right' }
        },
        // { field: 'isfarmrto', headerName: intl.formatMessage({ id: 'LIST.PEOPLE.ISFARMMRTO' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, },
        // { field: 'owner',  headerName: intl.formatMessage({ id: 'LIST.PEOPLE.OWNER' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, },
        // { field: 'rider', headerName: intl.formatMessage({ id: 'LIST.PEOPLE.RIDER' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, },
        // { field: 'trainer', headerName: intl.formatMessage({ id: 'LIST.PEOPLE.TRAINER' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, },
    ]

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: "MENU.PEOPLE" })}</PageTitle>
            <OutputListingComponent 
                key={`${customer_id}_${quickSearch?.searchCriteria}`} 
                area={'People'} columnDefs={columnDefs} apiEndPoint={'people/list'} 
                onRowDoubleClicked={
                    (rowData) => {quickSearch?.searchCriteria == "Unapplied Prize Money" ? navigate(`/people/detail/${rowData.people_id}?customer_id=${customer_id}&selectedTab=2&selectedSubTab=2`) : navigate(`/people/detail/${rowData.people_id}?customer_id=${customer_id}`)}
                }
                onNewTabClick={(row_data) => {quickSearch?.searchCriteria == "Unapplied Prize Money" ? window.open(process.env.PUBLIC_URL+'/people/detail/'+row_data.people_id+`?customer_id=${customer_id}&selectedTab=2&selectedSubTab=2`,'_blank') : window.open(process.env.PUBLIC_URL+'/people/detail/'+row_data.people_id+`?customer_id=${customer_id}`,'_blank')}}
            />
        </>
    )
   
}

export {PeopleList}
