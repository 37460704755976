import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {useAuth} from '../../../app/modules/auth'
import { useAppSelector } from '../../redux/hooks';
import { SaveModifiedColumnDefinitions, overwriteColumnDefinitions, getNarrowHeaderHeight, getNarrowRowHeight } from '../../modules/sgl-utils/agGridHelpers';

const OutputListingGridStatic = (props) => {
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const gridApi = useRef(null);
  const [columnDefs, setColumnDefs] = useState(props.columnDefs);
  const {currentUser} = useAuth()
  const customer_id = useAppSelector(state=> state.showCompany.company_id);
  const rowHeight = props.rowHeight > 0 ? props.rowHeight : getNarrowRowHeight;
  const headerHeight =  props.headerHeight > 0 ? props.headerHeight : getNarrowHeaderHeight;
  const gridClassName = props.gridClassName ? props.gridClassName : "ag-theme-alpine";
  const areasToMoveFocusToFirstRecord = ['People - Account SummaryByShow 1'] // RTO Detail -> Account Tab -> Summary By Show
  const defaultColDef = useMemo(() => {
    return {
      minWidth: 110,
      width: 110,
      resizable: true,
      sortable: true,
      suppressMenu: true,
      suppressMovable: true,
      cellStyle: function(params) {
        if (typeof params.value === 'number') {
            return {textAlign: 'center'};
        } else {
          return {textAlign: 'left'};
        }
      },
      wrapHeaderText: true,
      autoHeaderHeight: props.headerHeight ? false : true,
      headerClass: "ag-center-aligned-header",
      singleClickEdit: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    // Grid Reference initialize to params.api
    if(props.setGridRef){
       props.setGridRef(params.api)
    }
    if(props.setGridColumnApiRef){
      props.setGridColumnApiRef(params.columnApi)
   }
    if(props.entriesSearchDialog || areasToMoveFocusToFirstRecord.includes(props.area)){
      gridApi.current = params.api
    }
  }, []);

   // this event will trigger on column sort
   const onSortChanged = useCallback((params) => {
    if(params.source === 'uiColumnSorted' && props.area != undefined){
      SaveModifiedColumnDefinitions(params.api.getColumnDefs(), props.area, currentUser, customer_id)
    }
  });
  
  // this event will trigger on manual column resize
  const onColumnResized = useCallback((params) => {
    if(params.source === 'uiColumnDragged' && params.finished && props.area != undefined) // safety check to tell column resize is done manually and resize drag is complete
    {
      SaveModifiedColumnDefinitions(params.api.getColumnDefs(), props.area, currentUser, customer_id)
    }
  });

  const onRowDoubleClicked = useCallback((params) => {
    if ( typeof( props.onRowDoubleClicked ) === 'function') {
      props.onRowDoubleClicked(params.data)
    }
  }, []);
  
  useEffect(()=>{
    let colDefs = localStorage.getItem(props.area)
    if (colDefs != "undefined" && props.area != undefined){
      let custom_column_definitions = JSON.parse(colDefs)
      let overwrite_colDefs = overwriteColumnDefinitions(custom_column_definitions, props.columnDefs)
      if(overwrite_colDefs.length > 0){
        setColumnDefs(overwrite_colDefs)
      }
    }
  },[])

    useEffect(() => {
      const handleArrowKeys = (event) => {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            if (gridApi.current) {
                const selectedNode = gridApi.current.getSelectedNodes()[0];
                if (selectedNode) {
                    const selectedRowIndex = selectedNode.rowIndex;
                    const rowCount = gridApi.current.getDisplayedRowCount();
    
                    let newRowIdx;
                    if (event.key === 'ArrowDown') {
                        newRowIdx = selectedRowIndex + 1;
                        if (newRowIdx >= rowCount) {
                            newRowIdx = rowCount - 1;
                        }
                    } else {
                        newRowIdx = selectedRowIndex - 1;
                        if (newRowIdx < 0) {
                            newRowIdx = 0;
                        }
                    }
    
                    let found = false;
                    gridApi.current.forEachNode(node => {
                        if (!found && node?.rowIndex === newRowIdx) {
                            node.setSelected(true);
                            gridApi.current.ensureIndexVisible(node.rowIndex);
                            found = true;
                        }
                    });
                }
            }
        }
      };
      // Attach the keydown event listener
      document.addEventListener('keydown', handleArrowKeys);
      // Cleanup the event listener when the component unmounts
      return () => document.removeEventListener('keydown', handleArrowKeys) 
    }, []);
  

  const goToFirstRow = useCallback((params) => {
    if(props.entriesSearchDialog || areasToMoveFocusToFirstRecord.includes(props.area)){
      params.api.getDisplayedRowAtIndex(0)?.setSelected(true);
      params.api.ensureIndexVisible(0);
    }
    if(props.setGridRef){
      props.setGridRef(params.api)
    }
  });

  const handleGridTabKey = (e) => {
    const isTabKey = e.event.key === 'Tab';
  
    if (isTabKey && (props?.area == 'Entries - HorseLookupForm' || props?.area == 'Entries - PersonLookupForm')) {
      // Move focus to the next component after the grid
      let nextComponent = ''
      if(props?.area == 'Entries - PersonLookupForm'){
        nextComponent = document.getElementById('add_new_person_lookup_form')
      }else{
        nextComponent = document.getElementById('add_new_horse_lookup_form')
      }
      if (nextComponent) {
        nextComponent.focus();
      }
    }
  };

  const detailCellRendererParams = useMemo(() => {
    return {
        detailGridOptions: {
            rowHeight: rowHeight,
            columnDefs: [
                { field: 'key', cellStyle: {'border-right': '1px solid #eee'}},
                { field: 'value', flex: 2, wrapText: true, autoHeight: true, cellStyle: {textAlign: 'left'} },
            ],
            defaultColDef: {
                flex: 1,
            },
        },

        getDetailRowData: (params) => {
            var lookup_entries = Object.entries(params.data);
            let lookupData = [];
            lookup_entries.map(([key, value]) => {
                if (value != null && Array.isArray(value)) {
                  if (value.length > 0) {
                      value.map((data) => {
                      let lookup = Object.entries(data);
                      lookup.map(([key, value]) => {
                          lookupData.push({ key: key, value: value });
                      });
                      });
                  }
                }
            });

            params.successCallback(lookupData);
        },
    };
  }, [])

  const isRowMaster = useMemo(() => {
    return (dataItem) => {
        if(props.area === 'RTO - Account Register Distribution Detail'){
          return true
        }
        return dataItem ? dataItem?.value?.length > 0 && Array.isArray(dataItem?.value) : false;
    };
  }, [])

  return (
    <>
    <div style={props.containerStyle}>
      <div style={gridStyle} className={gridClassName}>
        {props.columnDefs.length > 0 &&
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={props.rowData}
          onCellEditingStopped ={props.stopEditing}
          onRowDoubleClicked={onRowDoubleClicked}
          // onCellValueChanged = {props.rowDataUpdated}
          onGridReady = {onGridReady}
          rowSelection={props.selection??'single'}
          rowClassRules={props.rowClassRules ? props.rowClassRules : []}
          onCellDoubleClicked={props.onCellDoubleClick}
          onCellClicked={props.onCellClicked}
          gridOptions={props.gridOptions}
          components={props.components}
          animateRows={props.animateRows ?? false}
          onCellValueChanged={props.onCellValueChanged}
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          groupIncludeFooter={props.groupIncludeFooter}
          groupIncludeTotalFooter={props.groupIncludeTotalFooter}
          onSortChanged={onSortChanged}
          onColumnResized={onColumnResized}
          rowDragManaged={props?.rowDragManaged ?? false}
          onRowDragEnd={props.onRowDragEnd}
          undoRedoCellEditing={props?.undoRedoCellEditing ?? false}
          suppressRowHoverHighlight={true}
          enableRangeSelection={props.enableRangeSelection ?? false}
          copyHeadersToClipboard={false}
          rowMultiSelectWithClick={props.rowMultiSelectWithClick ?? false}
          suppressRowClickSelection={props.suppressRowClickSelection}
          rowDragEntireRow={props.rowDragEntireRow}
          onDragStopped={props.onDragStopped}
          onFirstDataRendered = {goToFirstRow}
          onModelUpdated = {goToFirstRow}
          overlayLoadingTemplate={'<span class="ag-overlay-loading-center">One moment please...</span>'}
          stopEditingWhenCellsLoseFocus={props?.stopEditingWhenCellsLoseFocus ?? false}
          tabIndex={props.tabIndex ?? 0}
          onCellKeyDown={(e) => handleGridTabKey(e)}
          // Enabling Master / Detail
          isRowMaster={props.isRowMaster?? isRowMaster}
          masterDetail={props?.masterDetail}
          keepDetailRows={props?.keepDetailRows}
          keepDetailRowsCount={props?.keepDetailRowsCount}
          detailRowAutoHeight={props?.detailRowAutoHeight}
          detailCellRendererParams={props?.detailCellRendererParams ?? detailCellRendererParams}
          groupDefaultExpanded={props?.groupDefaultExpanded}
          // End enabling Master / Detail
          onSelectionChanged={props?.onRowSelected}
          getRowStyle={props?.getRowStyle}
          suppressScrollOnNewData={props?.suppressScrollOnNewData}
          pinnedTopRowData={props?.pinnedTopRowData}
        ></AgGridReact>
        }
      </div>
    </div>
    </>
  );
};

export default OutputListingGridStatic;
  