/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState, useMemo} from 'react'
import {useIntl} from 'react-intl'
import { useForm, FormProvider} from "react-hook-form";
import axios from 'axios'
import { useAppSelector } from '../../redux/hooks';
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import "flatpickr/dist/themes/material_blue.css";
import Select from "react-select";
import { useLoadingOverlay, useAlert } from '../../modules/sgl-utils/DialogsProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import DetailPageModal from '../../modules/sgl-utils/DetailPageModal';
import { useCallback } from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {reactSelectStyles} from '../../modules/sgl-utils/fieldControls';
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions';
import history from '../../modules/sgl-utils/unstableHistory';
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt';
import './Detail/styles.css';
import { HistoryTab } from '../../modules/components/HistoryTab';
import { Attributes } from './Detail/Attributes';
import useAutoFocus from '../../modules/hooks/use-auto-focus';
import HelpLink from '../../modules/components/HelpLink'

const { PUBLIC_URL } = process.env

export const dirtyValues = (
  dirtyFields,
  allValues
) => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key])
    ])
  );
};

const ClassRuleDetail = (props) => {
  const intl = useIntl();
  const {class_rule_id} = useParams();
  let current_class_rule_id = props.id || props.id == 0 ? props.id : class_rule_id
  
  const customer_id = useAppSelector(state=> state.showCompany.company_id);
  const [form_meta, setFormMeta] = useState({})
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate();
  const loadingOverlay = useLoadingOverlay()
  const dispatch = useAppDispatch()
  
  const alertDialog = useAlert();
  const [rowClicked, setRowClicked] = useState("")
  const [record_id, setRecordId] = useState("")
  const [sub_area, setSubArea] = useState("")
  const [child_component, setChildComponent] = useState("")

  const [ruleTypes, setRuletypes] = useState([])

  const [open_forms, setOpenForms] = useState([])

  // To open same detail page multiple times 
  useEffect(() => {
    if(sessionStorage.getItem("dialog_is_open_in_show") == "true"){
      sessionStorage.removeItem("dialog_is_open_in_show")
      setRowClicked(true)
      const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
      if(open_forms.includes(sub_area+"_"+record_id) == false){
        forms_open.push(sub_area+"_"+record_id);
        sessionStorage.setItem('forms_open', JSON.stringify(forms_open));
      }
    }
  }, [rowClicked])

	const [update_data, setUpdateData] = useState(0)
  // prevent function being recreated on state change
  const updateData = useCallback(() => {setUpdateData(update_data + 1) }, [update_data]);


  const methods = useForm({
    defaultValues: 
    {
        "class_rule":{
            "class_rule_id": 0,
            "class_rule_type_id" : 0,
            "class_rule_uuid": "",
            "customer_id": 0,
            "effective_end_date":"",
            "effective_end_time" :"",
            "effective_start_date":"",
            "effective_start_time": "",
            "rule": "",
            "sgl_id": 0,
            "sync_id":"",

        },

        "class_rule_attributes": [],
        "master_fee_data" : [],

    },
  }); 
  const { register, handleSubmit, reset, formState, setFocus} = methods
  const { dirtyFields } = formState; 
  useNavigationPrompt(formState)
  const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
  const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);

	useEffect(() => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');
		if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
			next_button.disabled = true;
			last_button.disabled = true;
		}
		if (row_selected == 0 && prev_button && first_button) {
			prev_button.disabled = true;
			first_button.disabled = true;
		}
	}, [loading])

  const onSubmit = async (form_data) => {  
    let error = ''
    if(form_data.class_rule.rule == ''){
      error = intl.formatMessage({id: 'FORM.INPUT.CLASSRULE.RULENAME.ERROR'})
    }

    if(error == '') {
      let submit_data = dirtyValues(dirtyFields, form_data)

      const next_button = document.getElementById('next-btn');
      const last_button = document.getElementById('last-btn');
      const prev_button = document.getElementById('prev-btn');
      const first_button = document.getElementById('first-btn');

      // means detail page is not open in modal
      if(props?.id === undefined ){
        next_button.disabled = false;
        prev_button.disabled = false;
        last_button.disabled = false;
        first_button.disabled = false;
      }
      
      loadingOverlay({show: true})
      return axios.post( process.env.REACT_APP_NEST_API_URL + '/class-rules/detail', {
        params: {
          class_rule_id: class_rule_id,
          customer_id: customer_id,
          data: submit_data
        }
      }).then((response) => {
        loadingOverlay({show: false})
        if(response.data.success) {
          // Use Case (Param Id changed): Next/Prev buttons
          if(props?.id == undefined && Number(methods.getValues('class_rule.class_rule_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
            if(row_selected && row_selected != "" && row_ids[row_selected]){
              setLoading(true)
              history.push(`${PUBLIC_URL}/classrules/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
            }
          }
          else if(props?.id == undefined && current_class_rule_id > 0){
            history.push(`${PUBLIC_URL}/classrules`, { allowNavigation: true })
          }
          else if(props?.id == undefined && current_class_rule_id == 0){
            history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
          }
          else{
            if(props.updateCallbackFromParent){
              props.updateCallbackFromParent()
              props.closeModal()
            }
          }
          if( row_selected == row_ids?.length -1 && props?.id === undefined){
            if (next_button && last_button) {
                next_button.disabled = true;
                last_button.disabled = true;
                prev_button.disabled = false;
                first_button.disabled = false;
            }
          }
          if(row_selected == 0 && props?.id === undefined){
            if (prev_button && first_button) {
                prev_button.disabled = true;
                first_button.disabled = true;
                next_button.disabled = false;
                last_button.disabled = false;
            }
          }
      } else {
          alertDialog({message: response.data.error_message, title: 'Error'})
      }
      })
    } else {
      alertDialog({message: error, title: 'Error'})

    }
  }

  useEffect(() => {
    
    // Show loading overlay
    loadingOverlay({show: true})
    axios.all(
    [
      axios.get( process.env.REACT_APP_NEST_API_URL + '/class-rules/detail', {
        params: {
          class_rule_id: class_rule_id,
          customer_id: customer_id,
        }
      }),
      axios.get( process.env.REACT_APP_NEST_API_URL + '/class-rules/MetaData', {
        params: {
            customer_id: customer_id,
        }
      }),
    ]  
    ).then (
      axios.spread( ({data : classRuleData},{data : classRuleMetaData}) => {

        //Check if record id is invalid then redirect to list page
        if ((classRuleData || classRuleData === '') && isInvalidRecord(classRuleData?.class_rule, class_rule_id, 'class_rule_id')){
          navigate('/classrules')
          loadingOverlay({show: false})
        }
        setFormMeta(classRuleMetaData)
        setRuletypes(classRuleMetaData.rule_type)
        reset(classRuleData)

        // Hide loading overlay
        loadingOverlay({show: false})
        setLoading(false)

      })
    )
  },[class_rule_id])

  const get_fee_id = () => {
    const index = methods.watch('class_rule_attributes').findIndex((element) => element.field_name === 'Fee Name');
     if (index >= 0){
      const updatedArray = [...methods.watch('class_rule_attributes') ] // Create a copy of the original array
      return (updatedArray[index].fee_id != 0 ? updatedArray[index].fee_id: 0); // return fee id
    }
    return 0
                     
  }
  const get_field_name = (field_name) =>{ 
    const index = methods.watch('class_rule_attributes').findIndex((element) => element.field_name === field_name);
    if (index >= 0){
      const updatedArray = [...methods.getValues('class_rule_attributes') ] // Create a copy of the original array
      return (updatedArray[index].value ? updatedArray[index].value: null); // return required value
    }
    return null
  }

  const tabs = ["ATTRIBUTES", "HISTORY"]
    const componentTags = [
      <Attributes get_fee_id={get_fee_id} get_field_name={get_field_name}/>,
      <HistoryTab area="Class Rules" rowData={methods.getValues("change_history_logs") ?? []} />
  ]
  const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	});

    const getData = (class_rule_type_id) => {

        methods.setValue ('class_rule.class_rule_type_id', class_rule_type_id, {shouldDirty: true})
        let type = ruleTypes.find (rt => rt.class_rule_type_id = class_rule_type_id)
        methods.setValue('rule_type', class_rule_type_id ,  {shouldDirty:true}) 
        axios.get( process.env.REACT_APP_NEST_API_URL + '/class-rules/getValueAtributeData', {
            params: {
                class_rule_type_id: class_rule_type_id,
                class_rule_id: class_rule_id,
                customer_id: customer_id,
                type: type.type
            }
          }).then((response) => {
            if(response.data.success){
                methods.setValue ('class_rule_attributes', response.data.class_rule_attributes, {shouldDirty: true})
            }
            else{
                alertDialog({message: response.data.error_message, title: 'Error'})
            }
        })

    }

    useAutoFocus('rule_name', loading)

  return (
   <>
   	{!loading && (
			<>
      {props?.id == undefined ? <PageTitle breadcrumbs={[]} description={[methods.watch('class_rule.rule'), methods.getValues('class_rule.class_rule_id')]}>{intl.formatMessage({id: 'MENU.CLASSRULES'})}</PageTitle> : "" }
      <div id={"classrules_detail_page"} className='modal-body py-3 px-4 show-input-form input-form'>
   
        <FormProvider {...methods}>
          <form id={"ClassRuleDetail_"+class_rule_id} noValidate className='form max-width' onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }>
            {/*  */}
            <HelpLink tooltip="CLASSRULES.DETAIL.LINK.HELP" classname="top" />
            <div className='card-body p-0'> {/* Card Body */}
              
              <div className='row mb-2'>
                <div className='col-10'> 

                  <div className='row mb-2'>
                    <label className='col-2 col-form-label  fw-bold fs-5 py-1' htmlFor="rule_name" data-tooltip-id="CLASSRULES.DETAIL.LABEL.RULENAME">{intl.formatMessage({id: 'FORM.INPUT.CLASSRULES.LABEL.RULENAME'})}</label>

                    <div className='col-6 '>
                      <input
                        {...register( 'class_rule.rule', 
                          {
                            required: false
                          })
                        }
                        id='rule_name'
                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                      />
                    </div>
                  </div>

                  <div className='row mb-4'>
                    <label className='col-2 col-form-label fw-bold fs-5 py-1' htmlFor='rule_type' data-tooltip-id="CLASSRULES.DETAIL.LABEL.RULETYPE">{intl.formatMessage({id: 'FORM.INPUT.CLASSRULES.LABEL.RULETYPE'})}</label>

                    <div className='col-6 '>
                      <Select
                          options = {ruleTypes}
                          
                          value={methods.getValues ('class_rule.class_rule_type_id') !== undefined ? ruleTypes.filter((ss) => ss.value === methods.getValues ('class_rule.class_rule_type_id')):""}
                          id = "rule_type"
                          isSearchable={true}
                          onChange={(e)=>{
                            getData(e.value)
                          }}
                          theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                          })}
                          styles={reactSelectStyles}
                          placeholder="Select"
                      />    
                    </div>
                  </div>

                </div>

                {/* <div className='attributes-grid-container col-10 mb-5'> 

                  <div className='attributes-grid col form-control p-0 pb-2'>
                    
                    <div className='attributes-header row mb-2 border-bottom mx-0 py-1'>
                      <label className='col-3 col-form-label fw-bolder fs-5 py-1 text-center' htmlFor='show_address'>{intl.formatMessage({id: 'FORM.INPUT.CLASSRULE.ATTRIBUTEVALUE.ATTRIBUTE'})}</label>
                      <div className="col-lg-1 attribute-heading-separator"></div>
                      <label className='col col-form-label fw-bolder fs-5 py-1 text-center' htmlFor='show_address'>{intl.formatMessage({id: 'FORM.INPUT.CLASSRULE.ATTRIBUTEVALUE.VALUE'})}</label>
                    </div>

                    {
                      !methods.watch('class_rule_attributes')?.length ?
                      <div className='row justify-content-center align-items-center' style={{ height: '160px' }}>
                        {intl.formatMessage({id: 'FORM.INPUT.CLASSRULE.ERROR.NOATTRIBUTESFOUND'})}
                      </div>
                      :null
                    }

                    { 
                    methods.watch('class_rule_attributes').map ((classRuleTypeAttribute, index) => { 
                      return (
                      <div key={index} className='row mb-2 px-3'>
                        <div className='col-3'>
                          <label className=' col-form-label fs-5 py-1 w-lg-150px' htmlFor='fee_id'>{classRuleTypeAttribute.field_name}</label>
                        </div>
                          {  
                            classRuleTypeAttribute.field_name === 'Fee Name'? // feeList
                              <div className='col'>
                                <Select
                                    options = {methods.getValues ('master_fee_data')}
                                    
                                    value={ (get_fee_id() !== undefined || get_fee_id() !== 0) ?
                                            methods.getValues ('master_fee_data').filter((ss) => ss.value === get_fee_id())
                                            :get_fee_id()}
                                    id = "fee_id"
                                    isSearchable={true}
                                    
                                    onChange={(e)=>{
                                      
                                      const index = methods.getValues('class_rule_attributes').findIndex((element) => element.field_name === classRuleTypeAttribute.field_name);
                                      const updatedArray = [...methods.getValues('class_rule_attributes') ] // Create a copy of the original array
                                      updatedArray[index].fee_id = e.value; // Update the value at that specified index
                                      updatedArray[index].value = e.label;
                                      methods.setValue("class_rule_attributes", updatedArray, {shouldDirty: true});
                                  
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                    })}
                                    styles={reactSelectStyles}
                                />    
                              </div>
                          :
                          <div className='col'>
                            <input
                                {...methods.register(classRuleTypeAttribute.field_name)}
                                id='NAME'
                                type='text'
                                value = {get_field_name (classRuleTypeAttribute.field_name) !== undefined ? classRuleTypeAttribute.value: get_field_name (classRuleTypeAttribute.field_name) }
                                onChange={(e) => {
                                  
                                  const index = methods.getValues('class_rule_attributes').findIndex((element) => element.field_name === classRuleTypeAttribute.field_name);
                                  const updatedArray = [...methods.getValues('class_rule_attributes') ] // Create a copy of the original array
                                  // updatedArray[index].fee_id = e.target.value; // Update the value at that specified index
                                  updatedArray[index].value = e.target.value; // Update the value at that specified index
                                  methods.setValue("class_rule_attributes", updatedArray, {shouldDirty: true});
                                  
                                  }
                                }
                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                              /> 
                          </div>
                        }
                      </div>
                      );
                    })
                  }
                  </div>
                </div> */}
              </div>

              <div className='d-flex flex-column my-2 border p-2 bg-white'>
                <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                    {
                        tabs.map((tab, index) => {
                            return (
                                <li key={index} className='nav-item'  onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
                                    <a className={"nav-link text-active-dark px-6 py-1 text-gray-700" + (index == selectedTab ? ' active' : '') + (index == 9 ? ' min-w-140px text-center' : '') }
                                        tabIndex="-1"
                                        id={tab + "-tab"}
                                        data-bs-toggle='tab'
                                        href={"#" + tab + "-Class-Rule-" +current_class_rule_id}
                                        data-tooltip-id={`CLASSRULES.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.CLASS.RULE.TAB.' + tab })}
                                    </a>
                                </li>
                            );
                        })
                    }
                </ul>
              
                <div className="tab-content mt-5 pb-2" id="myTabContent">
                    {
                        tabs.map((tab, index) => {
                            return (
                                <div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab  + "-Class-Rule-" +current_class_rule_id} role="tabpanel" >
                                    <HelpLink tooltip={"CLASSRULES.DETAIL.TAB."+tab+".LINK.HELP"}  />
                                    {componentTags[index]}
                                </div>
                            );
                        })
                    }
                </div>
              </div>

            </div>{/* End Card Body */}

            <InputFormFooter goBackPath={'/classrules'} propId={props?.id}  data={'class_rule'}/>
            
          </form>
        </FormProvider>
      </div>
      {rowClicked && <DetailPageModal area={sub_area} detail_page={child_component} record_id={record_id} updateCallbackFromParent={updateData} open_forms={open_forms} />}
    	</>
    	)}
    </>
  )
}

export {ClassRuleDetail}
