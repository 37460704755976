import { useState, useMemo, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext, Controller } from 'react-hook-form'
import { reactSelectStyles } from '../../modules/sgl-utils/fieldControls'
import OutputListingGridStatic from '../../modules/output-listing/OutputListingGridStatic';
import DateRenderer from '../../modules/output-listing/renderers/DateRenderer';
import NumberRenderer from '../../modules/output-listing/renderers/NumberRenderer';
import Select from "react-select";
import axios from 'axios'
import { useAppSelector } from '../../redux/hooks';
import { useParams } from 'react-router-dom';

const EntriesTab = ({ entriesSelectOptions, callbackFromParent }) => {
    const intl = useIntl();
    const methods = useFormContext()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const people_id = methods.getValues('people.people_id')

    const columnDefs = [
        { field: 'number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ENTRIES.ENTRY' }) },
        { field: 'balance', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ENTRIES.BALANCE' }), cellRenderer: NumberRenderer},
        { field: 'Show.show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ENTRIES.SHOWNAME' }), width: 400 },
        { field: 'Show.start_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ENTRIES.STARTDATE' }), cellClass: 'text-center', cellRenderer: DateRenderer, },
        { field: 'horse', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ENTRIES.HORSE' }), width: 200},
        { field: 'owner', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ENTRIES.OWNER' }),width: 200 },
        { field: 'trainer', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ENTRIES.TRAINER' }),width: 200 },
        { field: 'responsibleparty', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ENTRIES.RESPONSIBLEPARTY' }),width: 200 },
        { field: 'rider_list', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ENTRIES.RIDERLIST' }), width: 300},
    ]

    const [entriesGridRef, setEntriesGridRef] = useState()
    
    const containerStyle = useMemo(() => ({ width: '100%', height: '60vh' }), []);

    const getPeopleEntries = (type) => {
        axios.get(process.env.REACT_APP_NEST_API_URL + '/people/entries', {
            params: {
                people_id,
                customer_id,
                type
            },
        })
        .then(({data: peopleEntries }) => {
            entriesGridRef.setRowData(peopleEntries)
        })
    }

    useEffect(() => {
        if (entriesGridRef && methods.getValues('peopleEntries')) {
            entriesGridRef.setRowData(methods.getValues('peopleEntries'))
        }
    }, [entriesGridRef, methods.getValues('peopleEntries')]);

    return (
        <>
            <div className='form-group'>
                <div className='row mb-2'>
                    <label 
                        className='col-form-label fs-5 py-1 w-lg-200px'
                        htmlFor='rto_type'
                        data-tooltip-id="PEOPLE.DETAIL.TAB.ENTRIES.LABEL.ENTRIESWHEREPERSONWAS">
                        { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ENTRIES.SELECTPERSONTYPE' }) }
                    </label>
                    <div className='col-lg-2'>
                        {
                            entriesSelectOptions &&
                            <Controller
                                name='rto_type'
                                render={({
                                    field: { onChange, value, name },
                                }) => (
                                    <Select
                                        placeholder="Any"
                                        value={value}
                                        options = {entriesSelectOptions}
                                        name={name}
                                        id="rto_type"
                                        isSearchable={true}
                                        onChange={(option) => {
                                            onChange(option)
                                            getPeopleEntries(option.value)
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                        })}
                                        styles={reactSelectStyles}
                                    />    
                                )}
                            /> 
                        }
                    </div>
            </div>
                <div className='row align-items-center mb-2'>
                    <OutputListingGridStatic 
                        onRowDoubleClicked={(row_data) => {callbackFromParent(row_data.entry_id, "Entries", "EntryDetail", row_data)}}
                        area ={"People - Entries"}
                        setGridRef={setEntriesGridRef} 
                        columnDefs={columnDefs} 
                        containerStyle={containerStyle} 
                    />
                </div>
            </div>
        </>
    );
}

export { EntriesTab }