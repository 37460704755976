import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { defaultColDef, getNarrowHeaderHeight, getNarrowRowHeight } from "../../../../modules/sgl-utils/agGridHelpers";

const UpcomingShowsGrid = ({ setUpcomingShowsGridRef, columnDefs, tabIndex=0 }) => {
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const containerStyle = useMemo(() => ({ width: '100%', height: '240px' }), []);

    return (
        <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                <AgGridReact
                    defaultColDef={{ ...defaultColDef, resizable: true }}
                    rowData={[]}
                    columnDefs={columnDefs} 
                    onGridReady={ params => { setUpcomingShowsGridRef(params.api) }}
                    headerHeight={getNarrowHeaderHeight}
                    rowHeight={getNarrowRowHeight}
                    suppressHorizontalScroll={false}
                    rowStyle={{ fontSize: '12px' }}
                    {...(tabIndex !== 0 && { tabIndex })} //tabIndex is set conditionally, only if a prop was passed, provided that the prop is not 0 
                />
            </div>
        </div>
    )
}

export default UpcomingShowsGrid