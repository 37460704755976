import { useRef, useState, useEffect } from "react";
import Select from 'react-select'
import {useIntl} from 'react-intl'
import { reactSelectAsMenuStyles } from '../../sgl-utils/fieldControls'
import { useOutputContextUpdater } from "../../output-listing/OutputListingContext";

const SelectionDropDown = () => {
    const intl = useIntl()
    const lastKeyPressed = useRef(null);
    const selectRef = useRef(null);
    const outputContextUpdater = useOutputContextUpdater();
    const [os, setOS] = useState("");

    useEffect(() => {
        setOS(getOS());
    }, []);

    const getOS = () => {
        const userAgent = window.navigator.userAgent;
        if (userAgent.indexOf("Win") !== -1) return "Windows";
        if (userAgent.indexOf("Mac") !== -1) return "MacOS";
        if (userAgent.indexOf("X11") !== -1) return "UNIX";
        if (userAgent.indexOf("Linux") !== -1) return "Linux";
        return "Unknown";
    }
    
    const getShortcutLabel = (macShortcutId, winShortcutId) => {
        if (os === "MacOS") {
            return intl.formatMessage({ id: macShortcutId });
        } else {
            return intl.formatMessage({ id: winShortcutId });
        }
    }

    const selectionOptions = [
        {label: intl.formatMessage({ id: 'TOOLBAR.SELECTION.ALL' }) + ' ' + getShortcutLabel('TOOLBAR.SELECTION.ALL.SHORTCUT.MAC', 'TOOLBAR.SELECTION.ALL.SHORTCUT.WIN'), value: 'clearFiltersAndRefresh' },
        {label: intl.formatMessage({ id: 'TOOLBAR.SELECTION.SHOWSUBSET' }) + ' ' + getShortcutLabel('TOOLBAR.SELECTION.SHOWSUBSET.SHORTCUT.MAC', 'TOOLBAR.SELECTION.SHOWSUBSET.SHORTCUT.WIN'), value: 'showSubset' },
        {label: intl.formatMessage({ id: 'TOOLBAR.SELECTION.OMITSUBSET' }) + ' ' + getShortcutLabel('TOOLBAR.SELECTION.OMITSUBSET.SHORTCUT.MAC', 'TOOLBAR.SELECTION.OMITSUBSET.SHORTCUT.WIN'), value: 'omitSubset' },
        {label: intl.formatMessage({ id: 'TOOLBAR.SELECTION.SAVE' }), value: 'saveSelection' },
        {label: intl.formatMessage({ id: 'TOOLBAR.SELECTION.USESAVED' }), value: 'useSavedSelection' }
    ];
    
    const handleMouseOver = (e) => {
        if(selectRef){
            selectRef.current.focus();
        }
    }

    const handleMouseLeave = (e) => {
        if(selectRef){
            selectRef.current.blur();
        }
    }

    const handleOnChange = (e) => {
        if(lastKeyPressed && lastKeyPressed.current == 'Tab') { return }
        outputContextUpdater({action: e.value})
        if (selectRef && selectRef.current) {
            selectRef.current.blur();
            selectRef.current.select.clearValue();
        }
        lastKeyPressed = ''
    }

    const handleKeyDown = (e) => {
        if(e.key == 'Tab'){
            lastKeyPressed = e.key
        }
    }

    return(
        <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} style={{ whiteSpace: 'pre-wrap' }}>
          <Select
            ref={selectRef}
            theme={(theme) => ({ ...theme, borderRadius: 0 })}
            options={selectionOptions}
            onChange={e => handleOnChange(e)}
            placeholder='Selection'
            isSearchable={false}
            styles={reactSelectAsMenuStyles}
            openMenuOnFocus={true}
            onKeyDown={handleKeyDown}
            />
        </div>
    )
}
export {SelectionDropDown}