/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useEffect, useState } from "react"
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive} from '../../../helpers'
import CompanyPickerComponent from '../../../../app/modules/sgl-utils/CompanyPickerComponent'
import cloudMenu from './aside_menu.json'
import { useAuth } from '../../../../app/modules/auth';
import useAccessChecker from '../../../../app/modules/hooks/use-access-checker'

interface Item {
    name: string
    link: string
    icon: string
    permissions: string[]
}

export function AsideMenuMain() {
  const intl = useIntl()
  const {pathname} = useLocation()
  const { currentCompany } = useAuth()
  const { hasAtLeastOnePermission, hasAreaPermission, checkCompanyAccess } = useAccessChecker()
  const isProductionServer = process.env.REACT_APP_PRODUCTION === 'true' ? true : false

  const [activeMenuItem, setActiveMenuItem] = useState<Item>();
  const {currentUser} = useAuth()

  const getMenuState = () => {
    const menuState = localStorage.getItem('menuState_'+ currentUser?.id);
    return (menuState != "undefined" && menuState != "" && menuState != null) ? JSON.parse(menuState) : {};
  };
  
  const setMenuState = (state: any) => {
    localStorage.setItem('menuState_'+ currentUser?.id, JSON.stringify(state));
  };

  const [menuState, setMenuStateState] = useState(getMenuState());

  useEffect(() => {
    setMenuState(menuState);
  }, [menuState]);

  const handleMenuClick = (menuName: any, parentElement: any) => {
      const isParentOpen = parentElement.classList.contains('show');
      
      if (isParentOpen) {
          parentElement.classList.remove('show');
      } else {
          parentElement.classList.add('show');
      }

      setMenuStateState((prevState: any) => {
        const newState = {
            ...prevState,
            [menuName]: {
                open: !isParentOpen,
            },
        };
        setMenuState(newState);
        return newState;
    });
  };

  useEffect(() => {
    cloudMenu.menu.forEach((menuItem: any) => {
      const isSubmenuActive = menuItem.submenu?.some((item: Item) => {
        let is_active = checkIsActive(pathname, item.link)
        if ( is_active ) {
          setActiveMenuItem(item)
        }
        return is_active;
      });
      if (isSubmenuActive) {
        setMenuStateState((prevState: any) => {
          const newState = {
            ...prevState,
            [menuItem.name]: {
              open: true,
            },
          };
          setMenuState(newState);
          return newState;
        });
      }
    });
  }, [pathname]);


  return (
    <>
      <div className='mx-4 my-2'>
        <CompanyPickerComponent  className='menu-title' />
      </div>

      <div className="menu-item">
        <a className="menu-link" target="_blank" href={`https://showgroundslive.freshdesk.com/support/tickets/new?ticket_form=showgroundslive_cloud_beta_feedback`}>
          <span className="menu-icon">
            <i className="fs-3 fa-solid fa-question-circle"></i>
          </span>
          <span className="menu-title">Contact Support</span>
        </a>
      </div>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-1'></div>
        </div>
      </div>
      <div key={-1} className='menu-item'>
        <Link className={clsx('menu-link', {active: checkIsActive(pathname, '/dashboard' )})} to='/dashboard'>
          <span className="menu-icon"><i className="fs-3 fa-solid fa-table-columns"></i></span>
          <span className='menu-title'>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</span>
        </Link>
      </div>

      {
        // Safety check: if company is set!
        currentCompany &&
        cloudMenu.menu.map((menuItem: any, i) => {
          const isSubmenuActive = menuItem.submenu?.some((item:Item) => checkIsActive(pathname, item.link));
          const isMenuOpen = menuState[menuItem.name]?.open || false;
          return (
            (menuItem?.permissions?.length == 0 || hasAtLeastOnePermission(menuItem.permissions)) && (checkCompanyAccess ( menuItem.access , currentCompany.cloud_config ) && (isProductionServer?menuItem.production: true))  ?   
              <React.Fragment key={i}>
                 {
                  menuItem.name == 'MENU.ADMINISTRATION' && hasAreaPermission('admin') &&
                  <div className='menu-item'>
                    <div className='menu-content'>
                      <div className='separator mx-1 my-1'></div>
                    </div>
                  </div>
                }
                <div className={clsx('menu-item', { 'menu-accordion': menuItem.submenu.length > 0, show: (isSubmenuActive && isMenuOpen) || isMenuOpen})} onClick={(e) => menuItem.submenu.length > 0 && handleMenuClick(menuItem.name, e.currentTarget)}>
                    {
                      menuItem.name == 'MENU.ADMINISTRATION' && hasAreaPermission('admin') ?
                      <a 
                          className={clsx('menu-link', {active: checkIsActive(pathname, menuItem.link)})} 
                          target='_blank'
                          href={`${process.env?.REACT_APP_SGL_ADMIN_URL}/${currentCompany?.urlpath ?? ''}/auth` ?? '#'}
                      >
                          <span className="menu-icon"><i className={`fs-3 fa-solid ${menuItem.icon}`}></i></span>
                          <span className='menu-title'>{intl.formatMessage({id: menuItem.name })}</span>
                          {
                          menuItem.submenu.length > 0  &&
                            <span className="menu-arrow"></span>
                          }
                      </a>
                      : menuItem.name != 'MENU.ADMINISTRATION' ?
                      <Link 
                          className={clsx('menu-link', {active: checkIsActive(pathname, menuItem.link)})} 
                          to={menuItem.submenu.length === 0 ? menuItem.link : '#'}
                      >
                          <span className="menu-icon"><i className={`fs-3 fa-solid ${menuItem.icon}`}></i></span>
                          <span className='menu-title'>{intl.formatMessage({id: menuItem.name })}</span>
                          {
                          menuItem.submenu.length > 0  &&
                            <span className="menu-arrow"></span>
                          }
                      </Link>
                      : null
                    }
                    {
                      menuItem.submenu.length > 0 && (
                      <div className={clsx('menu-sub menu-sub-accordion', { show: (isSubmenuActive && isMenuOpen) || isMenuOpen })}>
                        {menuItem.submenu.map((item: Item, sub_i : any) => hasAtLeastOnePermission(item.permissions) &&
                            <div key={sub_i} className='menu-item ps-4'  onClick={(e) => { handleMenuClick(menuItem.name, e.currentTarget.closest('.menu-item.menu-accordion')) }}>
                                <Link className={clsx('menu-link', {active: checkIsActive(pathname, item.link )})} to={item.link}>
                                <span className="menu-icon"><i className={`fs-6 fa-solid ${item.icon}`}></i></span>
                                <span className='menu-title'>{intl.formatMessage({id: item.name})}</span>
                                </Link>
                            </div>
                        )}
                      </div>
                      )
                    }
                </div>
              </React.Fragment>
            :
              <React.Fragment key={i}></React.Fragment>
          )
        })
      }
    </>
  )
}
