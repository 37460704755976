import { useEffect, useState } from "react"
import Select from "react-select"
import { reactSelectStyles } from "../../sgl-utils/fieldControls"
import { useAuth } from "../core/Auth"
import useChangeCompany from "../../hooks/use-company-change"

const CompanySelect = () => {
    const [loading, setLoading] = useState(false)
    const { currentUser } = useAuth()
    const [selectedCompany, setSelectedCompany] = useState({})
    const [status, setStatus] = useState("")
    const { changeCompany } = useChangeCompany()

    useEffect(() => {
        if (currentUser) {
            // Check if default company is set for the current user
            const defaultCompanyId = currentUser.default_sgl_cloud_company
            const companies = currentUser.smc_access

            // find company with the company_id in companies list to set label
            const company = companies.find(c => c.company_id == defaultCompanyId)

            if (company) { // If user still has access to default company
                // Set default value for company select
                setSelectedCompany({
                    label: company.name, value: defaultCompanyId
                })
            }
        }
    }, [currentUser])


    const handleSubmit = async () => {
        try {
            if (selectedCompany && selectedCompany.value) { 
                const companies = currentUser?.smc_access
                const company = companies.find(
                    c => c.company_id == selectedCompany.value
                )
                await changeCompany(company)
            } else {
                setLoading(false)
                setStatus("Please select a company to continue")
            }
        
        } catch (error) {
            setLoading(false)
        }
    }

    const companyOptions = currentUser?.smc_access.map(company => ({
        label: company.name, value: company.company_id
    }))

    return (
        <form
            className='form w-100'
            noValidate
        >
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>Sign in to Showgrounds Cloud</h1>
            </div>

            { status ? 
                <div className='mb-lg-10 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{status}</div>
                </div> : null 
            }

            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Show Company</label>
                    </div>
                </div>
                <Select
                    options={companyOptions}
                    style={reactSelectStyles}
                    onChange={e => setSelectedCompany(e)}
                    value={selectedCompany}
                />
            </div>
            <div className='text-center'>
                <button
                    type="button"
                    className='btn btn-lg btn-primary w-100 mb-5'
                    onClick={handleSubmit}
                >
                    {!loading && <span className='indicator-label'>Select</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
        </form>
    )
}

export default CompanySelect