import { useIntl } from 'react-intl'

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const useOverrideForMissingTaxStatus = () => {
    const intl = useIntl()
    const {progressBar} = useProgress()
    const customerID = useAppSelector(state => state.showCompany.company_id)
    const { getOutputSelectionAreaIDs, getEventID } = useOutputContext();

    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const loadingOverlay = useLoadingOverlay()

    const overrideForMissingTaxStatus = async () => {
        loadingOverlay({ show: true })
        //Get selected peopls ids
        getOutputSelectionAreaIDs()
        .then(res => { 
            if (res.length === 0) {
                loadingOverlay({ show: false })
                // error message if no rto selected
                alertDialog({ message: intl.formatMessage({ id: "RTO.QUICKACTION.OVERRIDE.MISSING.TAX.STATUS.NO.RECORD.SELECTED"}) })
            }
            else{
                /* Search for RTO having following properties
                1- Have federal_id or social_security_number
                2- Have ssn_notes
                3- No Tax Status Membership
                */
                axios.post( NEST_API_URL + `/people/getMissingTaxFormRTO`, {
                    customer_id: customerID,
                    people_ids: res
                })
                .then((response) => {
                    if(response?.data?.success){
                        // get the number of rto missing tax status 
                        let noOfRTOMissingTaxStatus = response?.data?.filteredPeopleIds?.length
                        if(noOfRTOMissingTaxStatus > 0){
                            // ask user for confirmation
                            confirmDialog({ message: intl.formatMessage({ id: "RTO.QUICKACTION.OVERRIDE.MISSING.TAX.STATUS.CONFIRM.PART1"}) + noOfRTOMissingTaxStatus + intl.formatMessage({ id: "RTO.QUICKACTION.OVERRIDE.MISSING.TAX.STATUS.CONFIRM.PART2"})})
                            .then((choice) => {
                                if(choice){
                                    //Set progress bar
                                    const progressEventId = getEventID('override-missing-tax-status')
                                    progressBar({ show: true, eventID: progressEventId, showProgress: 'inline', title: "Applying Tax Status Override", timeElapsed: true })
                                    //Webservice to create manual requests >>>
                                    axios.post( NEST_API_URL + `/people/createTaxStatusManualForm`, {
                                        customer_id: customerID,
                                        people_ids: response?.data?.filteredPeopleIds,
                                        progress_event_id: progressEventId,
                                        membership_type: 'W-9'
                                    }).then((res) => {
                                        // Do Nothing
                                    }).catch((error) => {
                                        if (error.response) {
                                            alertDialog({message: error.response.data.error});
                                        }
                                    }).finally(() => {
                                        progressBar({ show: false, eventID: progressEventId})
                                    })
                                    //Webservice to create manual requests <<<
                                }
                            })
                        }else{
                            alertDialog({ message: intl.formatMessage({ id: "RTO.QUICKACTION.OVERRIDE.MISSING.TAX.STATUS.NO.RECORD.SELECTED"}) })
                        }
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        alertDialog({message: error.response.data.error});
                    }
                })
                .finally( () => {
                    loadingOverlay({ show: false })
                })
            }
        })
        .catch(() => loadingOverlay({ show: false }))
    }

    return overrideForMissingTaxStatus
    
}

export default useOverrideForMissingTaxStatus


