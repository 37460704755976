import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import moment from 'moment';
import { LoadingOverlay } from './LoadingOverlay';
import { useIntl } from 'react-intl'

type Props = {
    show: boolean,
    eventID: string, // Required
    showMessage: boolean,
    showProgress: string, // Possible values: above, inline, inside, none
    timeElapsed: boolean, // If true it will start a stopwatch
    handleClose: () => void,
    title: string,
    showProgressMessageOnly: boolean, // Show LoadingOverlay instead of ProgressBar component if true
    showCancelButton: boolean,
    handleCancel: () => void,
    clientSideProgress?: number,
    clientSideMessage: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ProgressBar = ({ show, eventID, handleClose, showMessage = true, showProgress, timeElapsed, title = "", showProgressMessageOnly = false, showCancelButton = false, handleCancel, clientSideProgress = 0, clientSideMessage = ""  }: Props) => {
    const [showModal, setShowModal] = useState(false)
    const [progress, setProgress] = useState(0)
    const [message, setMessage] = useState("")
    const [time, setTime] = useState("")
    const [sse, setSse] = useState<EventSource>()
    const [sseInterval, setSseInterval] = useState<NodeJS.Timer>()
    const intl = useIntl()

    useEffect(() => {
        if (clientSideProgress > 0) {
            setProgress(clientSideProgress);
        }
    }, [clientSideProgress]);

    useEffect(() => {
        if (clientSideMessage != "") {
            setMessage(clientSideMessage);
        }
    }, [clientSideMessage]);

    useEffect(() => {
        if (sse) {
            // Define the event handler function
            const handleProgressEvent = (e: any) => {
                let data = JSON.parse(e.data)
                if (data?.event_id == eventID) {
                    setProgress(parseInt(data.progress ?? 0.00))
                    setMessage(data.message ?? "")
                }
            };

            sse.addEventListener('progress', handleProgressEvent)

            sse.onerror = e => {
                console.log(e)
                handleSseClose()
            }

            // Cleanup function to remove the event listener when the component unmounts
            return () => {
                if (sse) {
                    sse.removeEventListener('progress', handleProgressEvent);
                    sse.close()
                }
            };        
        }
    }, [sse])

    useEffect(() => {
        if (eventID && show) {
            setShowModal(show)
            
            if (!sse) {
                setSse(new EventSource(`${process.env.REACT_APP_NEST_API_URL}/utility/getProgress?event_id=${eventID}`))
            }
            
            let startTime: any = moment()
            setTime('00:00:00')

            if (!sseInterval) {
                let interval = setInterval(() => {
                    setTime(moment(moment().diff(startTime)).format('00:mm:ss'))
                })
                setSseInterval(interval)
            }
           
        } else {
            handleSseClose()
        }
    }, [show, eventID])

    const handleSseClose = () => {
        hideModal()
        // Clear interval
        try {
            clearInterval(sseInterval as any) 
        } catch (err) {
            // Do Nothing
        }

        try { // Check if there is sse to close
            sse?.close()
        } catch (err) {
            // Do Nothing
        }
        
        setSseInterval(undefined)
        setSse(undefined)

        return true
    }

    const hideModal = () => {
        setShowModal(false)

        // Clear progress and message
        setProgress(0)
        setMessage("")

        if (handleClose) {
            handleClose()
        }
    }

    const handleCancelClick = () => {
        handleCancel();  // Call the cancel function passed as prop
        hideModal();
    }

    if(showProgressMessageOnly){ // Show LoadingOverlay instead of ProgressBar component
        return <LoadingOverlay show={showModal} message={message} handleClose={handleSseClose} />
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            backdrop="static"
            dialogClassName='modal-dialog modal-dialog-centered mw-600px search-form'
            show={showModal}
            // onHide={hideModal}
            onHide={handleCancelClick}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4 py-2">{ title }</h2>
            </div>
            <div className='modal-body py-3 px-4 shadow-lg'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className="d-flex flex-column w-100 me-2">
                            <div className='row p-0 m-0 mb-2'>
                                <div className={"col-lg-10 ps-0" + (showMessage ? '' : ' d-none')}>
                                    <span className="fs-6 fw-bold">{message}</span>
                                </div>
                                <div className={"col-lg-2 pe-0 d-flex justify-content-end" + (message && timeElapsed ? '' : ' d-none')}>
                                    <span className="fs-6">{time}</span>
                                </div>
                            </div>
                            { showProgress != "none" &&
                                <>
                                    <div className={"d-flex flex-stack justify-content-end mb-2" + (showProgress == 'above' ? '' : ' d-none')}>
                                        <span className="text-muted me-2 fs-7 fw-semibold">{progress}%</span>
                                    </div>
                                    <div className='d-flex align-items-center p-0 m-0'>
                                        <div className="progress border border-1 border-secondary w-100">
                                            <div className="progress-bar text-white fw-bold bg-success" role="progressbar" style={{ width: `${progress}%` }}>
                                                {showProgress == 'inside' ? `${progress}%` : ''}
                                            </div>
                                        </div>
                                        <div className={'text-muted ms-1 fs-7 fw-semibold' + (showProgress == 'inline' ? '' : ' d-none')}>{progress}%</div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className={"col-lg-12 pe-0 d-flex justify-content-end mt-4" + (showCancelButton ? '' : ' d-none')}>
                            <button type="button" id={"confirm_dialog_cancel_button"} className='btn btn-sm btn-secondary fw-bold' onClick={handleCancelClick}>
                                { intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.CANCEL' }) }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { ProgressBar }