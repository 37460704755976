import { Modal } from "react-bootstrap"
import { KTSVG } from "../../../_metronic/helpers"
import { useIntl } from "react-intl"
import { createPortal } from "react-dom"
import { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from "ag-grid-react";
import { getNarrowHeaderHeight, getNarrowRowHeight, SaveModifiedColumnDefinitions } from "../sgl-utils/agGridHelpers";
import { useAuth } from "../auth";
import { useAnnouncerToolContext } from "../../pages/classes/announcer-interface/AnnouncerToolContext";

const modalsRoot = document.getElementById('root-modals') || document.body
const AgGridSettings = ({ show, handleClose, gridRefs, initialColumnDefs }) => {
    const intl = useIntl()
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const containerStyle = useMemo(() => ({ width: '100%', minHeight: '200px' }), []);
    const [settingsGridRef, setSettingsGridRef] = useState(null)
    const [nonNullIndex, setNonNullIndex] = useState(-1)
    const {currentUser} = useAuth()
    const {currentClassGroup, customerID, disabledColumns} = useAnnouncerToolContext()
    const gridRef = useRef()
    const scoreColumnsFields = [
        'scoreone',
        'scoretwo',
        'scorethree',
        'scorefour',
        'scorefive',
        'scoresix',
        'total_score'
    ]
    const columnDefs = [
        { 
            field: 'drag', 
            headerName: '', 
            width: 50,
            rowDrag: true
        },
        {
            field: 'columnName',
            headerName: 'Column',
            headerClass: 'ag-left-aligned-header',
            flex: 1
        },
        {
            field: 'visible',
            headerName: 'Visible',
            width: 100,
            cellRenderer: (params) => <label className={`toggle-switch ${params.data.visible ? 'checked' : ''}`}>
                <input 
                    type="checkbox" 
                    checked={params.data.visible}
                    onChange={(e) => { //Changes visibility status of field (gone in and entry number are always visible and can not be changed)
                        if(params.data.field !== 'Entry.number' && params.data.field !== 'gone_in'){
                            params.data.visible = !params.data.visible
                            updateVisibility(params.data.field, params.data.visible)
                            params.api.refreshCells() //re-renders cells to update display
                        }
                    }} 
                />&nbsp;
                <div className="slider"></div>
            </label>
        }
    ]

    var currentScoreFields = []

    //Used to get reference key for column definition settings
    const getReferenceKey = () => { //Key to store coldef settings
        if (!currentClassGroup) return 'Class Group Not Found!'// should never happen
            
        let referenceKey = `Stagger Grid - ${currentClassGroup.group_type}`
        if (currentClassGroup.group_score_type) {
                referenceKey += ( ` ${currentClassGroup.group_score_type}`)
        }
            return referenceKey
        }

    const onRowDragEnd = (params) => { //Change the stagger grid positions to match that of AgGridSettings modal
        var index = 0
        settingsGridRef?.rowModel?.rowsToDisplay.forEach((rowNode) => { //Iterate through AgGridSettings modal rows
            gridRefs.current?.forEach((ref, ind) => { //Iterate through all stagger grids present
                let updatedIndex = index
                if(ref.current){
                    if(rowNode.data.field === 'scores'){ //In case where scores is dragged, move position of all columns related to score                        
                        scoreColumnsFields.forEach((scoreField) => {
                            ref.current.columnApi.moveColumns([`${scoreField}`], updatedIndex++)
                            if(ind === (gridRefs.current?.length - 1)){ //ensure that when last table on screen is being updated, the overall index is also updated
                                index = updatedIndex
                            }
                        })
                    }
                    else{ //move column to the specified index
                        ref.current.columnApi.moveColumns([`${rowNode.data.field}`], updatedIndex)
                    }
                }
            })
            index++
        })
        //Save changes to db
        SaveModifiedColumnDefinitions(gridRefs?.current[nonNullIndex]?.current.api.getColumnDefs(), getReferenceKey(), currentUser, customerID)
    }

    //hide or show the selected field
    const updateVisibility = (field, visible) => {
        gridRefs.current?.forEach((ref, index) => {
            if(ref.current){
                if(field === 'scores'){ //if scores field's visibility is being updated
                    currentScoreFields.forEach((fieldName) => { //loop through score fields found on current page and update visibility
                        ref.current.columnApi.setColumnVisible(fieldName, visible)
                    })
                }
                else{
                    ref.current.columnApi.setColumnVisible(field, visible)
                }
            }
        })
        //save changes to db
        SaveModifiedColumnDefinitions(gridRefs?.current[nonNullIndex]?.current.api.getColumnDefs(), getReferenceKey(), currentUser, customerID)
    }

    const onClose = () => {
        setSettingsGridRef(null)
        handleClose()
    }

    const resetAll = () => { //Display all columns and re order columns

        if (gridRefs.current) {
            gridRefs.current.forEach((ref, index) => {
                if (ref.current && initialColumnDefs[index]) {
                    // Create a state that only includes order information
                    const orderState = initialColumnDefs[index].map((colDef, pos) => ({
                        colId: colDef.field,
                        order: pos
                    }));
    
                    // Apply this state with applyOrder set to true
                    ref.current.columnApi.applyColumnState({
                        state: orderState,
                        applyOrder: true,
                    });
                }
            });
        }

        if (settingsGridRef) {
            const currentRows = [];
            settingsGridRef?.rowModel?.rowsToDisplay.forEach((rowNode) => { //update visibility of all stagger grid fields found in modal
                updateVisibility(rowNode.data.field, true)
                currentRows.push(rowNode.data)
            })

            if( currentClassGroup?.group_type == "Hunters" ){
                // Locate the position of the first score field in the first stagger grid
                const scoresPosition = gridRefs.current[0].current.columnApi.getAllDisplayedColumns().findIndex(col => col.colDef.field === "scoreone");

                // Separate the scores row from the other rows
                const nonScoresRows = currentRows.filter(row => row.field !== "scores");
                const scoresRow = currentRows.find(row => row.field === "scores");

                // Sort the non-scores rows
                const sortedNonScoresRows = nonScoresRows.sort((a, b) => {
                    const aIndex = initialColumnDefs[0].findIndex(def => def.field === a.field);
                    const bIndex = initialColumnDefs[0].findIndex(def => def.field === b.field);
                    return aIndex - bIndex;
                });

                // Insert the scores row at the correct position
                sortedNonScoresRows.splice(scoresPosition, 0, scoresRow);

                // Set the row data in the settings grid
                settingsGridRef.setRowData(sortedNonScoresRows);
            }else{
                const sortedNonScoresRows = currentRows.sort((a, b) => {
                    const aIndex = initialColumnDefs[0].findIndex(def => def.field === a.field);
                    const bIndex = initialColumnDefs[0].findIndex(def => def.field === b.field);
                    return aIndex - bIndex;
                });

                settingsGridRef.setRowData(sortedNonScoresRows);
            }

        }        
        
        if (gridRef.current) {
            gridRef?.current?.api?.forEachNode(node => node.data.visible = true);
            gridRef?.current?.api?.refreshCells()
        }
        
        SaveModifiedColumnDefinitions(gridRefs?.current[nonNullIndex]?.current.api.getColumnDefs(), getReferenceKey(), currentUser, customerID)
        
    }

    useEffect(() => {
        const firstNonNullIndex = gridRefs?.current?.findIndex(ref => ref.current !== null && ref.current !== undefined); //Find index of first stagger grid ref of active page
        currentScoreFields = []
        if(firstNonNullIndex !== -1){
            setNonNullIndex(firstNonNullIndex)
            const columnApi = gridRefs?.current[firstNonNullIndex]?.current.columnApi
            if (columnApi && settingsGridRef) {
                const allColumns = columnApi.getAllGridColumns();
                let showedColumns = allColumns.filter(column => !disabledColumns.includes(column.colDef.field));
                const rowData = []
                showedColumns.forEach((column, index) => { //push column to settings list if it is not disabled by class group
                    if(!scoreColumnsFields.includes(column.userProvidedColDef.field)){
                            rowData.push({
                                uniqueRowId: index,
                                columnName: column.userProvidedColDef.headerName.toUpperCase() ?? column.userProvidedColDef.field.toUpperCase(),
                                visible: column.visible,
                                field: column.userProvidedColDef.field
                            })
                    }
                    else{ //For scores in hunter type, they will all be represented by one 'scores' row
                        if(!rowData.some(obj => obj.columnName.toLowerCase() === 'scores')){
                            rowData.push({
                                uniqueRowId: index,
                                columnName: 'SCORES',
                                visible: column.visible,
                                field: 'scores'
                            })
                        }
                        currentScoreFields.push(column.userProvidedColDef.field) //adding score field to active score fields list
                    }
                })
                settingsGridRef.setRowData(rowData)
                if(rowData.some(obj => obj.field === 'scores' && obj.visible === true)){ //Ensure all score fields are visible after returning from class group where only some score fields were visible
                    updateVisibility('scores', true)
                }
            }
        }
    }, [settingsGridRef, gridRefs])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-450px search-form'
            show={show}
            onHide={onClose}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">Column Settings</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
  
            <div className='modal-body py-3 px-4 shadow-lg'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='col mb-2' style={containerStyle}>
                            <div style={gridStyle} className="ag-theme-alpine">
                                <AgGridReact
                                    ref={gridRef}
                                    onGridReady={params => setSettingsGridRef(params.api)}
                                    defaultColDef={{
                                        sortable: false, 
                                        suppressMenu: true, 
                                    }}      
                                    columnDefs={columnDefs}
                                    rowData={[]}
                                    headerHeight={getNarrowHeaderHeight}
                                    rowHeight={getNarrowRowHeight}
                                    domLayout='autoHeight'
                                    rowDragManaged={true}
                                    onRowDragEnd={onRowDragEnd}     
                                    animateRows = {true} 
                                      
                                />
                            </div>
                        </div>
                    </div>
        
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type="button" className='btn btn-sm btn-secondary me-4 fw-bold' onClick={resetAll}>
                            {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.RESETDEFAULTS' })}
                        </button>
                        
                        <button 
                            type="submit" 
                            className='btn btn-sm btn-dark fw-bold' 
                            onClick={onClose} 
                            autoFocus
                        >
                           { intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CLOSE' }) }
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default AgGridSettings