
import moment from 'moment';

const TimeRenderer = (params) => {
    if (params.value !== undefined) {
        if(params.value && params.value!='00:00:00'){
            const momentObj = moment(params.value, "HH:mm:ss");
            if(!momentObj.isValid()) return '';

            const formattedTime = momentObj.format('h:mm A');
            return formattedTime;
        } 
    }

    return '';
}

export default TimeRenderer;