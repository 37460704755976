import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import {useAuth} from '../core/Auth'

const initialValues = {
  newPassword: '',
  confirmPassword: '',
};

const lowerCaseRegex = /[a-z]/;
const upperCaseRegex = /[A-Z]/;
const numericRegex = /[0-9]/;
const specialCharRegex = /[^A-Za-z0-9]/;

const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Minimum 8 characters')
    .matches(lowerCaseRegex, 'Must contain at least one lowercase letter')
    .matches(upperCaseRegex, 'Must contain at least one uppercase letter')
    .matches(numericRegex, 'Must contain at least one numeric digit')
    .matches(specialCharRegex, 'Must contain at least one special character')
    .required('Password is required'),
    // .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    // .required('Confirm Password is required'),

    confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

export default function UpdatePassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [resetSuccess, setResetSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { setUpdatePassword} = useAuth()

  const skipUpdatePassword = () => {
    setLoading(true);
    return axios.post(process.env.REACT_APP_NEST_API_URL + '/auth/skipUpdatePassword', {
    }).then(()=>{
      setUpdatePassword( false )
    })
    
  }

  const resetPassword = (newPassword: string, confirmPassword: string) => {
    return axios.post(process.env.REACT_APP_NEST_API_URL + '/auth/updatePassword', {
      newPassword: newPassword,
      confirmPassword: confirmPassword
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      resetPassword(values.newPassword, values.confirmPassword)
        .then((response) => {

          if ( response.data.success ) {
            setHasErrors(false);
            setLoading(false);
            setResetSuccess(true)
          } else {
            setHasErrors(true);
            setLoading(false);
            setResetSuccess(false)
            setSubmitting(false);
            setStatus(response.data.message);
            setErrorMessage(response.data.message)
          }
          
        })
        .catch(() => {
          setHasErrors(true);
          setLoading(false);
          setSubmitting(false);
          setStatus('Failed to reset password. Please try again.');
        });
    },
  });


  return (
    <>
      { resetSuccess ? (
        <div>
          <div className='bg-light-info p-8 rounded'>
            <div className='text-info font-weight-bold fs-2 text-center text-dark mb-10'>Password updated successfully.</div>
            <div className='d-flex justify-content-center align-items-center'>
              <button onClick={() => { skipUpdatePassword() }} type='submit' id='kt_password_reset_skip' className='btn btn-lg btn-primary fw-bolder'>
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Strengthen Your Security: Update Your Password</h1>
          </div>
          <div className="fp_message">
              <div>
                <p>Your security is our top priority! We’ve noticed that your current password may not meet the latest security standards. A strong password helps protect your account from unauthorized access and keeps your personal information safe.</p>
                
                <h3 style={{ color: '#555' }}>Tips for Creating a Strong Password:</h3>
                <ul>
                    <li><strong>Use a Mix of Characters:</strong> Include uppercase and lowercase letters, numbers, and symbols.</li>
                    <li><strong>Make It Lengthy:</strong> Aim for at least 8 characters.</li>
                    <li><strong>Avoid Common Words:</strong> Steer clear of easily guessable information like birthdays or common phrases.</li>
                </ul>
              </div>
          </div>

          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                {errorMessage?errorMessage:"Sorry, looks like there are some errors detected, please try again."}
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>Password updated successfully.</div>
              <div className='d-flex align-items-center'>
                <button onClick={() => { skipUpdatePassword() }} type='submit' id='kt_password_reset_skip' className='btn btn-lg btn-primary fw-bolder'>
                  {!loading && <span className='indicator-label'>Continue</span>}
                  {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
                </button>
              </div>
            </div>
          )}

          <div className={`fv-row ${formik.values.newPassword ? `mb-0` : `mb-10` }`}>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>New Password</label>
                {/* end::Label */}
              </div>
            </div>
            <div className="position-relative">
              <input
                id='newPassword'
                type='password'
                placeholder='New Password'
                autoComplete='off'
                {...formik.getFieldProps('newPassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                  },
                  {
                    'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                  }
                )}
              />
            </div>
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.newPassword}</span>
                </div>
              </div>
            )}
            {formik.values.newPassword && <PasswordStrengthMeter password={formik.values.newPassword}/>}

          </div>

          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Confirm Password</label>
                {/* end::Label */}
              </div>
            </div>
            <div className="position-relative">
              <input
                id='confirmPassword'
                type='password'
                placeholder='Confirm Password'
                autoComplete='off'
                {...formik.getFieldProps('confirmPassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                  },
                  {
                    'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                  }
                )}
              />
            </div>
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>

          <div className='d-flex justify-content-between pb-lg-0'>
            <div className='d-flex align-items-center'>
              <a href="javascript:void(0)" onClick={() => { skipUpdatePassword() }} type='submit' id='kt_password_reset_skip'>
                {!loading && <span className='indicator-label'>Skip</span>}
              </a>
            </div>
            <div className='d-flex align-items-center'>
              <button type='submit' id='kt_password_reset_submit' className='btn btn-lg btn-primary fw-bolder'>
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>

        </form>
      )}
    </>
  )
}