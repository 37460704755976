import React, {useEffect, useState} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import axios from 'axios'
import { KTSVG } from '../../../../_metronic/helpers'
import { useAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider'
import { useAppSelector } from '../../../redux/hooks'
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls'
import Select from 'react-select';
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../modules/sgl-utils/SglFunctions'

const modalsRoot = document.getElementById('root-modals') || document.body
 
const AddOfficial = ({show, handleClose, classGroupIds, setScheduleStatus}) => {
    const intl = useIntl() 
    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const customer_id = useAppSelector((state) => state.showCompany.company_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const [selectedOfficial, setSelectedOfficial] = useState('')
    const [showOfficialsList, setShowOfficialsList] = useState([])
    const [updateMessage, setUpdateMessage] = useState('')

    const addOfficial = (e) => {
        //No official selected
        if(!selectedOfficial){
            alertDialog({ message: intl.formatMessage({ id: "CLASSES.QUICKACTION.POPUP.HEADING.ADD.OFFICALS.TO.CLASSES.ERROMESSAGE.NO.OFFICIAL.SELECTED"}) })
            return
        }
        
        //Webservice to add official in selected classes >>>
        loadingSpinnerBtnWait(e)
        axios.post( process.env.REACT_APP_NEST_API_URL + "/classes/addOfficialsToClassGroups", {
            customer_id: customer_id,
            show_id: currentShowID,
            class_group_ids: classGroupIds,
            people_id: selectedOfficial
        })
        .then((res) => {
            setUpdateMessage(res.data.updateMessage)
            setScheduleStatus('Pending')
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        }).finally(() => {
            loadingSpinnerBtnRelease(e)
        })
        //Webservice to add official in selected classes <<<
    }
    
    useEffect(() => {
        if (show) {
            //Webservice to get the official for the current show >>>
            axios.get( process.env.REACT_APP_NEST_API_URL + '/shows/getShowJudges', {
                params: {
                    customer_id: customer_id,
                    show_id: currentShowID,
                }
            }).then((res) => {
                setShowOfficialsList(res.data.showJudgeDropDownList)
            }).catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
            }).finally(() => {
                loadingOverlay({ show: false })
            })
            //Webservice to get the official for the current show <<<
        }  
    }, [show])

    return createPortal(
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
                show={show}
                onHide={handleClose}
                onKeyPress={(event) => {
                    if (event.key === "Enter") {
                        addOfficial(event)
                    }
                }}
            >
                <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
                <div className='modal-header py-0 px-4'>
                    <h2 className="fs-4">{intl.formatMessage({ id: 'CLASSES.QUICKACTION.POPUP.HEADING.ADD.OFFICALS.TO.CLASSGROUPS' })}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
    
                <div className='modal-body py-3 px-4'>
    
                    <div className="row mb-2">
                        <label className="col-lg-3 fw-bolder fs-6 mt-2" data-tooltip-id="SIMPLESCHEDULER.DETAIL.MODAL.ASSIGNOFFICALTOCLASSGROUPS.LABEL.ASSIGNOFFICIAL">{intl.formatMessage({ id: 'CLASSES.QUICKACTION.POPUP.HEADING.ADD.OFFICALS.LABEL.ASSIGN.OFFICIAL' })}</label>
                        <div className='col-lg-9'>
                            <Select
                                value={selectedOfficial !== undefined ? showOfficialsList.filter((ss) => ss.value === selectedOfficial):""}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}       
                                options={showOfficialsList}
                                onChange={(e) => {
                                    setUpdateMessage('')
                                    setSelectedOfficial(e.value)
                                }}
                                placeholder='Select an official to add..'
                                styles={reactSelectStyles}
                            />
                        </div>
                    </div>  

                    <div className='card-footer d-flex justify-content-between py-3 px-0'>
                        
                        <div className='label-left-align mw-350px'>
                            <label className='text-green fw-bold'>{updateMessage}</label>
                        </div>
                        
                        <div className='buttons-right-align'>
                            <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={handleClose} data-tooltip-id="SHOW.DETAIL.MODAL.ADDNEWOFFICIAL.BUTTON.DONE">
                                {intl.formatMessage({ id: 'CLASSES.ADD.NEW.OFFICIALS.DIALOG.BUTTON.DONE' })}
                            </button>

                            <button type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={(e) => addOfficial(e)} data-tooltip-id="SHOW.DETAIL.MODAL.ADDNEWOFFICIAL.BUTTON.ADD">
                                <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                {intl.formatMessage({ id: 'CLASSES.ADD.NEW.OFFICIALS.DIALOG.BUTTON.ADD' })}
                            </button>
                        </div>
                    </div>

                </div>
      </Modal>
      ,modalsRoot
    )
}

export default AddOfficial