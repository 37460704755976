import { AgGridReact } from "ag-grid-react";
import { useIntl } from "react-intl";
import { getRowData, renderCheckBox } from "../../../../modules/sgl-utils/agGridHelpers";
import { getNarrowHeaderHeight,getNarrowRowHeight } from "../../../../modules/sgl-utils/agGridHelpers";
import { useCurrencyFormatter } from "../../../../modules/sgl-utils/Formatters";
import { useEffect, useMemo, useRef } from "react";
import './../tab.css'
import DateRenderer from "../../../../modules/output-listing/renderers/DateRenderer";
import PreferenceRenderer from "./PreferenceRenderer";

const PostedPaymentsGrid = ({ postedPayments, setPostedPayments, updatePaymentBatchItems, getSumForPostedPayments, displayLoadingOverlay }) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()
    const containerStyle = useMemo(() => ({ width: '100%', height: '43vh' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const postedPaymentsGridRef = useRef()

    useEffect(() => {
        if(displayLoadingOverlay){
            postedPaymentsGridRef?.current?.api?.showLoadingOverlay()
        }else{
            postedPaymentsGridRef?.current?.api?.hideOverlay()
        }
    }, [displayLoadingOverlay])

    useEffect(() => {
        if(postedPaymentsGridRef?.current){
            postedPaymentsGridRef?.current?.api?.setPinnedBottomRowData([{amount: getSumForPostedPayments()}])
        }
    }, [postedPayments])

    const defaultColDef = useMemo(() => {
        return {
          minWidth: 110,
          width: 110,
          resizable: true,
          sortable: true,
          suppressMenu: true,
          suppressMovable: true,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          headerClass: "ag-center-aligned-header",
          singleClickEdit: true,
        };
    }, []);

    const checkPayments = (params) => {
        const updatedPostedPayments = getRowData(postedPaymentsGridRef?.current?.api)?.map((payment) => {
            if (payment.payment_batchitems_id === params?.node?.data?.payment_batchitems_id) {
                payment['include'] = params?.node?.data?.include;
            }
            return payment;
        });
    
        postedPaymentsGridRef?.current?.api?.setRowData(updatedPostedPayments)
        setPostedPayments(updatedPostedPayments);
    };

    const columnDefs = [
        { 
            field: 'payment_batchitems_id', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.ID' }), 
            width: 90,
            cellStyle: { textAlign: 'center' },
            hide: true
        },
        { 
            field: 'include',
            headerClass: 'ag-center-aligned-header',
            cellRenderer: params => (params.node.rowPinned || params.node.data.void_check) ? null : renderCheckBox(params, () => {
                checkPayments(params)
            }),
            maxWidth: 60,
            headerName: "",
            cellStyle: ({textAlign: 'center'})
        },
        { 
            field: 'posted_date', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.DATE' }),
            cellRenderer: DateRenderer,
            cellStyle: { textAlign: 'center' }
        },
        { 
            field: 'People.fl_name', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.RTO" }),
            minWidth: 200,
            flex: 1,
            cellStyle: { textAlign: 'left' }
        },
        { 
            field: 'prize_preference', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.PAYMENT.TYPE" }),
            width: 160,
            cellRenderer : params => params.node.rowPinned ? null : PreferenceRenderer(params, updatePaymentBatchItems, postedPaymentsGridRef, 'posted'),
        },
        { 
            field: 'check_number', 
            width: 130,
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.CHECKNO" }),
            editable: true
        },
        { 
            field: 'payment_type', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.TYPE" }),
            width: 155,
            cellStyle: { textAlign: 'left' }
        },
        { 
            field: 'description', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.DESCRIPTION" }),
            flex: 1.25,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { textAlign: 'left' }
        },
        { 
            field: 'prize_money_amount', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.COL.PRIZE.MONEY" }),
            wrapHeaderText: true,
            autoHeaderHeight: true,
            sortable: false,
            width: 150,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => params.node.rowPinned ? null : currencyFormatter(params.value)
        },
        { 
            field: 'balance_refund_amount', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.COL.BALANCE.REFUND" }),
            wrapHeaderText: true,
            autoHeaderHeight: true,
            sortable: false,
            width: 150,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => params.node.rowPinned ? null : currencyFormatter(params.value)
        },
        { 
            field: 'amount', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.COL.PAID" }),
            wrapHeaderText: true,
            autoHeaderHeight: true,
            width: 150,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => currencyFormatter(params.value)
        }
    ]

    const onCellEditingStopped = (params) => {
        const {data, colDef, newValue, oldValue} = params
        // update batch item only if data is changed
        if(newValue !== oldValue){
            updatePaymentBatchItems([data.payment_batchitems_id], 'updateBatchItem', colDef.field, newValue, oldValue, postedPaymentsGridRef)
        }
    } 

    const getRowStyle = (params) => {
        if (params.node.rowPinned) {
            return { fontWeight: "bold" };
        }
    };

    const rowClassRules = {
        // display voided check as red colored row
        'expired-text': function(params) {
            return params.node.data?.void_check;
        }
    }

    return (
        <div>
            {/* Posted Check Grid */}
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact
                        ref={postedPaymentsGridRef}
                        defaultColDef={defaultColDef}
                        rowData={postedPayments}
                        columnDefs={columnDefs} 
                        rowStyle={{ fontSize: '12px' }}
                        suppressScrollOnNewData={true}
                        headerHeight={getNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                        stopEditingWhenCellsLoseFocus={true}
                        onCellEditingStopped={onCellEditingStopped}
                        getRowStyle={params => getRowStyle(params)}
                        rowClassRules={rowClassRules}
                        overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while posted payments are loading...</span>'}
                    />
                </div>
            </div>
          
            <div className="col-lg-6">
                <div className='col form-check-sm form-check-custom'>
                    <div className="square-shape-payment-batch"></div>
                    <label className='col-form-label mx-2 fs-5 py-1'> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.VOIDCHECK.LAB' })}</label>
                </div>
            </div>

        </div>
       
    )
}


export default PostedPaymentsGrid