import { AgGridReact } from "ag-grid-react"
import { defaultColDef, getRowData, renderCheckBox } from "../../../../modules/sgl-utils/agGridHelpers"
import { Modal } from "react-bootstrap"
import { createPortal } from "react-dom"
import { useEffect, useMemo, useState } from "react"
import { KTSVG } from "../../../../../_metronic/helpers"
import { useFormContext } from "react-hook-form"
import { createArrayOfNumbersFromText, multiSort } from "../../../../modules/sgl-utils/SglFunctions"
import CheckboxHeader from "../../../../modules/components/CheckboxHeader"
import { useIntl } from "react-intl"
import { useAlert } from "../../../../modules/sgl-utils/DialogsProvider"
import { useCurrencyFormatter } from "../../../../modules/sgl-utils/Formatters"
import BooleanCheckmarkRenderer from "../../../../modules/output-listing/renderers/BooleanCheckmarkRenderer"
const modalsRoot = document.getElementById('root-modals') || document.body

export const ApplyOrRollbackModal = ({ show, handleClose, prizeMoneyActionRef, handlePrizeMoneyAction }) => {
    const [gridRef, setGridRef] = useState(null)
    const methods = useFormContext()
    const containerStyle = useMemo(() => ({ width: '100%', height: '400px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), [])
    const [selectAllEntries, setSelectAllEntries] = useState(false)
    const intl = useIntl()
    const action = prizeMoneyActionRef.current === 'Apply Prize Money' ? 'Apply' : 'Rollback'
    const alertDialog = useAlert()
    const currencyFormatter = useCurrencyFormatter()

    const isDisabledForAction = (prizeMoneySpent) => {
        if (action === 'Apply') {
            return prizeMoneySpent
        } else {
            return !prizeMoneySpent
        }
    }

    useEffect(() => {
        if (gridRef) {
            const rowData = getRowData(gridRef)
            rowData.forEach(row => row.select = isDisabledForAction(row.prizeMoneySpent) ? false: selectAllEntries)
            gridRef.setRowData(rowData)
        }
    }, [gridRef, selectAllEntries])

    const columnDefs = [
        {
            field: 'select',
            cellRenderer: params => renderCheckBox(params, null, isDisabledForAction(params.data.prizeMoneySpent)),
            width: 70,
            headerComponentFramework: CheckboxHeader,
            headerComponentParams: {
                headerName: '',
                onChange: (checked) => { 
                    setSelectAllEntries(checked)
                },
                checked: selectAllEntries
            },
            cellClass: 'text-center'
        },
        {
            field: 'placing',
            width: 110,
            cellClass: 'text-center'
        },
        {
            field: 'splitCode',
            width: 120,
            cellClass: 'text-center'
        },
        {
            field: 'entry',
            width: 90,
            cellClass: 'text-center'	
        },
        {
            field: 'horse',
            flex: 1,
        },
        {
            field: 'rider',
            flex: 1
        }, 
        {
            field: 'totalPrizeMoney',
            headerName: 'Prize',
            width: 120,
            cellClass: 'text-center',
            cellRenderer: params => currencyFormatter(params.data.totalPrizeMoney)
        },
        {
            field: 'status',
            width: 100,
            cellRenderer: BooleanCheckmarkRenderer,
            cellClass: 'text-center',
            headerName: 'Applied'
        }
    ]

    useEffect(() => {
        if (gridRef) {
            const trips = methods.getValues('entryxclasses')
            const classPrizes = methods.getValues('class_prizes')
            const rowData = []
            for (const cp of classPrizes) {
               const winningNumbers = createArrayOfNumbersFromText(cp.winningnums)

               for (const winningNumber of winningNumbers) {
                    const trip = trips.find(trip => trip.Entry && trip.Entry.number === Number(winningNumber))
                    if (trip && trip.total_prize_money > 0) { // Entry Has Prize Money
                        rowData.push({
                            entryxclasses_id: trip.entryxclasses_id,
                            select: false,
                            placing: cp.place,
                            splitCode: cp.splitcode,
                            entry: trip.Entry.number,
                            horse: trip.Entry.horse,
                            rider: trip.rider_name,
                            totalPrizeMoney: trip.total_prize_money,
                            prizeMoneySpent: trip.prize_money_spent,
                            status: trip.prize_money_spent,
                            number: trip.Entry.number
                        })
                    }
               }
               multiSort(rowData, { placing: 'asc', splitCode: 'asc' })
            }
            gridRef.setRowData(rowData)
        }  
    }, [gridRef])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
            show={show}
            onHide={handleClose}
            backdrop='static'
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{ prizeMoneyActionRef.current }</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div> 
            
            <div className='modal-body py-3 px-4'>
                <div className='card-body'>
                    <div className="row mb-2">
                        <div style={containerStyle}>
                            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                <AgGridReact
                                    onGridReady={params => setGridRef(params.api)}
                                    columnDefs={columnDefs}
                                    rowData={[]}
                                    defaultColDef={defaultColDef}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3'>
                        <button 
                            type="button" 
                            className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' 
                            onClick={handleClose}
                        >
                            {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CLOSE" })}
                        </button>

                        <button 
                            id='applyTemplateButton'
                            type="button" 
                            className='btn btn-sm btn-dark fw-bold text-uppercase'
                            onClick={async () => {
                                const rowData = getRowData(gridRef)
                                const entryXClasses = rowData.filter(row => row.select).map(row => ({ entryxclasses_id: row.entryxclasses_id, number: row.number  }))
                                if (entryXClasses.length === 0) {
                                    alertDialog({ message: action === 'Apply' ? 'Prize money is already applied for all Entries.' : 'No Entry available for Rollback' })
                                    return
                                }

                                await handlePrizeMoneyAction(action, entryXClasses)
                                handleClose()
                            }}
                            data-tooltip-id={`CLASS.DETAIL.MODAL.APPLYORROLLBACKMODAL.BUTTON.${action.toUpperCase()}`}
                        >
                            {intl.formatMessage({ id: `FORM.INPUT.COMMON.BUTTON.${action.toUpperCase()}` })}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )

}