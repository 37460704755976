import {DivisionDetail} from '../../pages/divisions/Detail'
import {ClassDetail} from '../../pages/classes/Detail'
import {EntryDetail} from '../../pages/entries/Detail'
import {ShowFeeDetail} from '../../pages/fees/Detail'
import {HorseDetail} from '../../pages/horses/Detail'
import { PeopleDetail } from '../../pages/people/Detail'
import { CircuitDivisionDetail } from '../../pages/circuitdivisions/Detail'
import { StableDetail } from '../../pages/stables/Detail'
import { ShowDetail } from '../../pages/shows/Detail'
import { ClassRuleDetail } from '../../pages/classrules/Detail'
import {useEffect, useState, useCallback} from 'react'
import {Modal} from 'react-bootstrap'
import { useAppSelector } from '../../redux/hooks'
import {useIntl} from 'react-intl'
import React from 'react'
import moment from 'moment'
import useAccessChecker from '../hooks/use-access-checker'
import useKey from '../hooks/use-key'

const DetailPageModal = (props) => {
  const intl = useIntl()
  const [show, setShow] = useState(true)
  const currentShowName = useAppSelector(state => state.currentShow.show_name);
  const { hasAreaWritePermission} = useAccessChecker()

  const components = {
    DivisionDetail,
    ClassDetail,
    EntryDetail,
    ShowFeeDetail,
    HorseDetail,
    PeopleDetail,
    CircuitDivisionDetail,
    StableDetail,
    ShowDetail,
    ClassRuleDetail,
  }

  const getArea = () => {
    let area = ""
    switch(props.area) {
        case 'Circuit Divisions':
            area = 'circuits'
            break
        default:
            area = props.area ? props.area.toLowerCase() : '' 
    }

    return area
  }

  const DynamicComponent = components[props?.detail_page]

  const [modalClosed, setModalClosed] = useState(false);

  const handleClose = () => {
    if (!modalClosed) {
      setShow(false);
      const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
      forms_open.splice(-1);
      sessionStorage.setItem('forms_open', JSON.stringify(forms_open));
      setModalClosed(true); // Set the modalClosed flag to true to indicate that the modal has been closed
    }
  };

  useEffect (() => {
  }, [props.record_id])


  const handleNext = () => {
    if(props.area === 'Circuit Divisions'){
      let array = props.circuitDivisionIds
      const index = array.indexOf(props.record_id);
      if (index !== -1 && index < props.circuitDivisionIds.length - 1) {
        props.setRecordId(props.circuitDivisionIds[index + 1]);
      }
    }
  }

  const handlePrev = () => {
    if(props.area === 'Circuit Divisions'){
      let array = props.circuitDivisionIds
      const index = array.indexOf(props.record_id);
      if (index > 0 && index <= props.circuitDivisionIds.length - 1) {
        props.setRecordId(props.circuitDivisionIds[index - 1]);
      }
    }
  }

  const updateRowIndex = (event) =>{
    if(props.area === 'Circuit Divisions'){

      if (event === 'Last'){
        const index = props.circuitDivisionIds.length - 1
        props.setRecordId(props.circuitDivisionIds[index]);
      } else if (event == 'First'){
        props.setRecordId(props.circuitDivisionIds[0]);
      }
    }
  }

  useEffect(() => {
      const handleKeyPress = (event) => {
        const isModalOpen = JSON.parse(sessionStorage.getItem("modal_is_open") || "false");
        const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
        const fullString = forms_open[forms_open.length - 1];
        const parts = fullString.split('_');
        const numberPart = parts[1];

        let isButtonFocused = false
        const activeElement = document.activeElement;
        let isReactSelectFocused = false;
        let isSearchFocused = false;
        let isAgGridTextInputFocused = false;
        const modalElements = document.querySelectorAll('[id^="kt_modal_create_app"]');
        let modalElement = modalElements[modalElements.length - 1];
        let isCkEditor = false; //for master fee Product info tab
        const isFlatpickr = activeElement.classList.contains('flatpickr-input');
       
        if (activeElement && activeElement.tagName === 'BUTTON') {
          isButtonFocused = true;
        } else if (
            activeElement &&
            activeElement.id &&
            activeElement.id.startsWith('react-select')
        ) {
            isReactSelectFocused = true;
        } else if (
            activeElement.getAttribute('name') === 'owner_search' ||
            activeElement.getAttribute('name') === 'horse_search' ||
            activeElement.getAttribute('name') === 'trainer_search' ||
            activeElement.getAttribute('name') === 'search_1' || 
            activeElement.getAttribute('name') === 'prize_money_recipient_search' ||
            activeElement.getAttribute('name') === 'responsible_party_search' || 
            activeElement.getAttribute('name') === 'rider_search' ||
            activeElement.getAttribute('name') === 'horse_search_in_modal' ||
            activeElement.getAttribute('name') === 'stable_search' ||
            // activeElement.getAttribute('name') === 'entry.number' ||
            activeElement.getAttribute('name') === 'ENTERDROPS' ||
            activeElement.getAttribute('name') === 'ENTERADDS'
        ) {
            isSearchFocused = true;
        } else if(activeElement?.className.includes('ck-editor')){
            isCkEditor = true;
        } else if(activeElement.className === 'ag-input-field-input ag-text-field-input'){
            isAgGridTextInputFocused = true
        }
        
        if (event.key === 'Enter' && !isModalOpen && Number(props.record_id) === Number(numberPart) && !event.shiftKey && !isButtonFocused && modalElement == null /*&& !isReactSelectFocused*/ && !isSearchFocused && !isCkEditor && !isAgGridTextInputFocused && !isFlatpickr) {
          const saveButton = document.getElementById(`save_btn_${forms_open[forms_open.length - 1]}`);
          saveButton && saveButton.click();
        }
      };

      document.addEventListener('keydown', handleKeyPress, true);
          
      return () => {
        document.removeEventListener('keydown', handleKeyPress, true);
      };
    }, []);

  return (
    <>
    {  props.open_forms.includes(props.area+"_"+props.record_id) ? "" :
      <Modal show={show} fullscreen={true} onHide={handleClose} enforceFocus={false}>
        <div className='modal-header py-2 px-4 mb-1' style={{height: "50px", boxShadow:"1px 1px 1px #999", display:"flex", flexDirection:"column", justifyContent: "center" }}>
          <h2 className='fs-3' style={{color:"black"}} >{props?.area}</h2>
          <p style={{marginBottom: "0"}}>{currentShowName}</p>
        </div>
        <Modal.Body>
          {/* <DynamicComponent id={props?.record_id} updatedData={props?.prev_data} updateCallbackFromParent={props.updateCallbackFromParent} closeModal={handleClose} parent_id={props.parent_id} parent_area={props.parent_area}/> */}
          <DynamicComponent id={props?.record_id} updatedData={props?.prev_data} updateCallbackFromParent={props.updateCallbackFromParent} closeModal={handleClose} parent_id={props.parent_id} shows={props.shows}  parent_area={props.parent_area} setValueFromClass={props.setValueFromClass}/>
        </Modal.Body>
        <div className='card-footer input-form-buttons py-3 px-0 row'>
          {' '}
          {/* Card Footer */}
          <div className='input-form-buttons-inner d-flex'>
          <div className='col-1' style={{width: '3%'}}>&nbsp;</div>
          <div className='col-1' style={{width: '7%'}}><label className="form-label fs-7 fw-bold"><b style={{color: '#2274a5'}}>ID: </b><span>{props?.record_id}</span></label></div>
            <div className='col d-flex flex-column'>
                <label className="form-label fs-7 fw-bold" style={{ whiteSpace: 'pre' }}>
                    <b style={{color: '#2274a5'}}>Created</b>{'\t'}
                    {props?.gridRowData?.creation_date && moment(props?.gridRowData?.creation_date).isValid() ? (
                      <span>
                      {moment(props?.gridRowData?.creation_date).format('M/DD/YYYY')} {'\t'}
                      {props?.gridRowData?.creation_time && moment(props?.gridRowData?.creation_time, 'HH:mm:ss').isValid()
                          ? moment(props?.gridRowData?.creation_time, 'HH:mm:ss').format('h:mm A')
                          : ''}
                      </span>
                    ) : ''}
                </label>

                <label className="form-label fs-7 fw-bold" style={{ whiteSpace: 'pre' }}>
                    <b style={{color: '#2274a5'}}>Modified</b>{'\t'}
                    {props?.gridRowData?.modified_date && moment(props?.gridRowData?.modified_date).isValid() ? (
                        <span>
                            {moment(props?.gridRowData?.modified_date).format('M/DD/YYYY')} {'\t'}
                            {props?.gridRowData?.modified_time && moment(props?.gridRowData?.modified_time, 'HH:mm:ss').isValid()
                                ? moment(props?.gridRowData?.modified_time, 'HH:mm:ss').format('h:mm A')
                                : ''}
                        </span>
                    ) : ''}
                </label>
            </div>

            {
              (props.area === 'Circuit Divisions')? 
              <div className='col-4 d-flex justify-content-center'>
                <button 
                  className='btn btn-sm btn-secondary me-2 fw-bold pe-3 ps-4' 
                  disabled={(props.circuitDivisionIds.indexOf(props.record_id) <= 0)} 
                  onClick={(e) => {e.preventDefault(); updateRowIndex("First")}} >
                        <i className='fas fa-angle-double-left fs-4'></i>
                </button>
                <button id="cancel_btn" 
                  disabled={(props.circuitDivisionIds.indexOf(props.record_id) <= 0)} 
                  className='btn btn-sm btn-secondary me-2 fw-bold pe-3 ps-4'
                  onClick={() => handlePrev()}>
                    <i className='fas fa-angle-left fs-4'></i>
                  {/* {intl.formatMessage({id: 'FORM.INPUT.COMMON.BUTTON.PREV'})} */}
                </button>
                <button id="cancel_btn" 
                  disabled={(props.circuitDivisionIds.indexOf(props.record_id) >= props.circuitDivisionIds.length-1)} 
                  className='btn btn-sm btn-secondary me-2 fw-bold pe-3 ps-4' 
                  onClick={() => handleNext()}>
                    <i className='fas fa-angle-right fs-4'></i>
                  {/* {intl.formatMessage({id: 'FORM.INPUT.COMMON.BUTTON.NEXT'})} */}
                </button>
                <button 
                  className='btn btn-sm btn-secondary me-7 fw-bold pe-3 ps-4' 
                  disabled={(props.circuitDivisionIds.indexOf(props.record_id) >= props.circuitDivisionIds.length-1)}
                  onClick={(e) => {e.preventDefault(); updateRowIndex("Last")}} >
                    <i className='fas fa-angle-double-right fs-4'></i>
                </button>
              </div>
              : 
              <div className="col-5">&nbsp;</div>
            }
            <div className="col-2">&nbsp;</div>
            <div className='d-flex justify-content-end'>
              <button id="cancel_btn" className='btn btn-sm btn-secondary me-4 me-sm-2 fw-bold' onClick={() => handleClose()}>
                {intl.formatMessage({id: 'FORM.INPUT.COMMON.BUTTON.CANCEL'})}
              </button>

              { hasAreaWritePermission(getArea()) &&
              <button id={`save_btn_${props.area}_${props.record_id}`} className='btn btn-sm btn-dark fw-bold me-20' type='submit' form={props.detail_page+"_"+ props.record_id}>
                {intl.formatMessage({id: 'FORM.INPUT.COMMON.BUTTON.SAVE'})}
              </button>
              }
            </div>
            
          </div>
        </div>{' '}
        {/* End Card Footer */}
      </Modal>
    }
    </>
  )
}

export default React.memo(DetailPageModal);