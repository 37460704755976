import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'

// Custom Components
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import { FormProvider, set, useForm, Controller } from 'react-hook-form';

import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions';
import { useAppSelector } from '../../redux/hooks';
import { useAlert, useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider';
import { HistoryTab } from '../../modules/components/HistoryTab'
import {TaxRatesTab} from './Detail/TaxRatesTab'
import { NumericFormat } from 'react-number-format';
import history from '../../modules/sgl-utils/unstableHistory';
import useAutoFocus from '../../modules/hooks/use-auto-focus';
import HelpLink from '../../modules/components/HelpLink'

const { PUBLIC_URL } = process.env

export const dirtyValues = (dirtyFields, allValues) => {
    // NOTE: Recursive function.
    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues;
    }
    // Here, we have an object.
    return Object.fromEntries(
        Object.keys(dirtyFields).map((key) => [
            key,
            dirtyValues(dirtyFields[key], allValues[key])
        ])
    );
};

const TaxRateDetail = (props) => {
    const { tax_rate_id } = useParams();
    let currentShowTaxRateId = props.id || props.id == 0 ? props.id : tax_rate_id
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const customerId = useAppSelector(state => state.showCompany.company_id);
    const loadingOverlay = useLoadingOverlay();
    const [loading, setLoading] = useState(true)
    const intl = useIntl();
    const navigate = useNavigate();
    const alertDialog = useAlert()
    const methods = useForm({
        defaultValues: {
            "show": {
                masterfees_changeenabled: true,
                show_id: 0,
            },
            "show_tax_rate": {
                show_tax_rates_id: tax_rate_id,
                tax_name: '',
                tax_abbreviation: '',
                customerId: customerId,
                rate: 0
            },
            "tax_rates": [],
            "tax_rates_deleted": [],
        }
    })
    const { register, handleSubmit, reset, formState, setFocus } = methods;
    const { dirtyFields } = formState;
    const [tabs, setTabs] = useState(['HISTORY'])
    const [componentTags, setComponentTags] = useState([<HistoryTab area="Taxrates" rowData={methods.getValues("change_history_logs")} />]);
    const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
    const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);
    const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	});

    // Get data from API
    useEffect(() => {
        // Get detail page data
        // Get data from API
        loadingOverlay({ show: true })
        axios.get(`${process.env.REACT_APP_NEST_API_URL}/tax-rates/detail`, {
            params: {
                tax_rate_id: tax_rate_id,
                customer_id: customerId,
                show_id: currentShowID,
            }
        })
            .then((response) => {
                // TODO: Check valid data
                if ((response?.data || response?.data === '') && isInvalidRecord(response.data.show_tax_rate, currentShowTaxRateId, 'show_tax_rates_id')) {
                    navigate('/taxrates')
                }

                // Set data to form
                reset(response.data)
                if (response?.data?.show?.masterfees_changeenabled) {
                    setTabs(["TAXRATES", "HISTORY"])
                    setComponentTags([
                        <TaxRatesTab tax_rate_id={currentShowTaxRateId}/>,
                        <HistoryTab area="Taxrates" rowData={methods.getValues("change_history_logs")} />
                    ])
                } else {
                    setTabs(["HISTORY"])
                    setComponentTags([
                        <HistoryTab area="Taxrates" rowData={methods.getValues("change_history_logs")} />
                    ])
                }
                loadingOverlay({ show: false })
                setLoading(false)
            }
            ).catch((error) => {
                console.log(error)
                loadingOverlay({ show: false })
                setLoading(false)
            })
    }, [tax_rate_id])

	useEffect(() => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');
		if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
			next_button.disabled = true;
			last_button.disabled = true;
		}
		if (row_selected == 0 && prev_button && first_button) {
			prev_button.disabled = true;
			first_button.disabled = true;
		}
	}, [loading])

    // Submit call to API
    const onSubmit = async (formData, e) => {
        const next_button = document.getElementById('next-btn');
        const last_button = document.getElementById('last-btn');
        const prev_button = document.getElementById('prev-btn');
        const first_button = document.getElementById('first-btn');

        // means detail page is not open in modal
        if(props?.id === undefined){
          next_button.disabled = false;
          prev_button.disabled = false;
          last_button.disabled = false;
          first_button.disabled = false;
        }

        let data = dirtyValues(dirtyFields, formData)
        let body = {
            params: {
                show_tax_rates_id: currentShowTaxRateId,
                customer_id: customerId,
                data: data,
                show_id: currentShowID
            }
        }

        loadingOverlay({ show: true })
        await axios.post(`${process.env.REACT_APP_NEST_API_URL}/tax-rates/detail`, body)
            .then(async (response) => {
                if (response.data.success) {
                    if(response.data.alert_message != ''){
                        await alertDialog({ message: response.data.alert_message, title: 'Alert' })
                    }
                    // Use Case (Param Id changed): Next/Prev buttons
                    if(props?.id == undefined && Number(methods.getValues('show_tax_rate.show_tax_rates_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
                        if(row_selected && row_selected != "" && row_ids[row_selected]){
                            setLoading(true)
                            history.push(`${PUBLIC_URL}/taxrates/detail/${row_ids[row_selected]}?customer_id=${customerId}${window.location.hash}`, { allowNavigation: true })
                        }
                    }
                    else if(props?.id == undefined && currentShowTaxRateId > 0){
                        history.push(`${PUBLIC_URL}/taxrates`, { allowNavigation: true })
                    }
                    else if(props?.id == undefined && currentShowTaxRateId == 0){
                        history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
                    }
                    if( row_selected == row_ids?.length -1 && props?.id === undefined){
                        if (next_button && last_button) {
                            next_button.disabled = true;
                            last_button.disabled = true;
                            prev_button.disabled = false;
                            first_button.disabled = false;
                        }
                    }
                    if(row_selected == 0 && props?.id === undefined){
                        if (prev_button && first_button) {
                            prev_button.disabled = true;
                            first_button.disabled = true;
                            next_button.disabled = false;
                            last_button.disabled = false;
                        }
                    }
                } else {
                    alertDialog({ message: response.data.error_message, title: 'Error' })
                }
                loadingOverlay({ show: false })
            }
            ).catch((error) => {
                loadingOverlay({ show: false })
                console.log(error)
            })
    }

    useAutoFocus('DESCRIPTION', loading)

    return (
        <>
        {!loading && (
			<>
            {props?.id == undefined ? <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.TAXRATES' })}</PageTitle> : ""}
            <div id={"taxrates_detail_page"} className='modal-body py-3 px-4'>
                <FormProvider {...methods}>
                    
                    <form id={"TAXRATESDETAIL_" + tax_rate_id} noValidate className='form max-width' onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } }}>
                    <HelpLink tooltip="TAXRATE.DETAIL.LINK.HELP" classname="top" />
                        <div className='card-body p-0'> {/* Card Body */}
                            <div className="form-group row"> {/* Column Group */}
                                <div className='row mb-2'>
                                    <div className='col-lg-6'>
                                        <div className='row mb-2'>
                                            <label className='col-lg-2 col-form-label fs-5 fw-bold py-1' htmlFor='DESCRIPTION' data-tooltip-id="TAXRATE.DETAIL.LABEL.DESCRIPTION">{intl.formatMessage({ id: 'FORM.INPUT.TAXRATES.LABEL.DESCRIPTION' })}</label>
                                            <div className='col-lg-8'>
                                                <input
                                                    {...register('show_tax_rate.tax_name', { required: "Tax name is required.", })}
                                                    id='DESCRIPTION'
                                                    type='text'
                                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.TAXRATES.LABEL.DESCRIPTION' })}
                                                />
                                                { methods.formState.errors?.show_tax_rate?.tax_name && <div><span className='error_message'>{methods.formState.errors?.show_tax_rate?.tax_name?.message}</span></div>}
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <label className='col-lg-2 col-form-label fs-5 fw-bold py-1' htmlFor='ABBREVIATION' data-tooltip-id="TAXRATE.DETAIL.LABEL.ABBREVIATION">{intl.formatMessage({ id: 'FORM.INPUT.TAXRATES.LABEL.ABBREVIATION' })}</label>
                                            <div className='col-lg-2'>
                                                <input
                                                    {...register('show_tax_rate.tax_abbreviation')}
                                                    id='ABBREVIATION'
                                                    type='text'
                                                    maxLength={3}
                                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.TAXRATES.PLACEHOLDER.ABBREVIATION' })}
                                                />
                                            </div>
                                        </div>

                                        {/* Show tax rate for old shows, new shows have the grid */}
                                        {!methods.getValues('show.masterfees_changeenabled') &&
                                            <>
                                                <div className='row mb-2'>
                                                    <label className='col-lg-2 col-form-label fs-5 fw-bold py-1' htmlFor='TAXRATE' data-tooltip-id="TAXRATE.DETAIL.LABEL.TAXRATES">{intl.formatMessage({ id: 'FORM.INPUT.TAXRATES.LABEL.TAXRATE' })}</label>
                                                    <div className='col-lg-2'>
                                                        <Controller
                                                            control={methods.control}
                                                            name="show_tax_rate.rate"
                                                            render={({ field: { onChange, name, value } }) => (
                                                                <NumericFormat
                                                                    suffix={'%'}
                                                                    id='TAXRATE'
                                                                    name={name}
                                                                    type="text"
                                                                    value={value > 0 ? (value * 100).toFixed(2) : ''} // display rate in %
                                                                    decimalScale={2}
                                                                    allowNegative={false}
                                                                    onValueChange={(e) => {
                                                                        // save rate in decimal
                                                                        onChange(parseFloat(e.value)/100)
                                                                    }}
                                                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row mb-2'>
                                                    <label className='offset-2 col-lg-2 col-form-label fs-5 py-1' htmlFor='SHOWID'>{methods.getValues("show.show_id")}</label>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div> {/* End Column Group */}
                            </div> {/* End Card Body */}
                        </div> {/* End Card Body */}

                        <div className='col-lg-12 d-flex flex-column my-2 border p-2 bg-white'>
                            <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                                {
                                    tabs.map((tab, index) => {
                                        return (
                                            <li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
                                                <a className={"nav-link text-active-dark px-6 py-1 text-gray-700" + (index == selectedTab ? ' active' : '') + (index == 9 ? ' min-w-140px text-center' : '') }
                                                    tabIndex="-1"
                                                    id={tab + "-tab"}
                                                    data-bs-toggle='tab'
                                                    href={"#" + tab + "-Taxrate-" +currentShowTaxRateId}
                                                    data-tooltip-id={`TAXRATE.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.TAXRATES.TAB.' + tab })}
                                                </a>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                            
                            <div className="tab-content mt-5 pb-2" id="myTabContent">
                                {
                                    tabs.map((tab, index) => {
                                        return (
                                            <div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab  + "-Taxrate-" +currentShowTaxRateId} role="tabpanel" >
                                                <HelpLink tooltip={"TAXRATE.DETAIL.TAB."+tab+".LINK.HELP"}  />
                                                {componentTags[index]}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>

                        <InputFormFooter goBackPath={'/taxrates'} />
                    </form>
                </FormProvider>
            </div>
            </>
    		)}
        </>
    );
}

export { TaxRateDetail }