import {useMemo, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {reactSelectStyles} from '../../../modules/sgl-utils/fieldControls'
import {useFormContext} from 'react-hook-form'
import {useAlert} from '../../../modules/sgl-utils/DialogsProvider'
import {useAppSelector} from '../../../redux/hooks'
import {AgGridReact} from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import axios from 'axios'
import { defaultColDef, getNarrowHeaderHeight,getNarrowRowHeight } from '../../../modules/sgl-utils/agGridHelpers'
import useAccessChecker from '../../../modules/hooks/use-access-checker'

const RingsTab = (props) => {
  const intl = useIntl()
  const [rowData, setRowData] = useState([])
  const [selectedRing, setSelectedRing] = useState(null)
  const alertDialog = useAlert()
  const [selectedRowData, setSelectedRowData] = useState(null)
  const methods = useFormContext()
  const [addedRings, setAddedRings] = useState([])
  const [deletedRings, setDeletedRings] = useState([])
  const [ringxFacilityGridApi, setRingxFacilityGridApi] = useState(null)
  const [ringxFacilityColumnApi, setRingxFacilityColumnApi] = useState(null)
  const customer_id = useAppSelector((state) => state.showCompany.company_id)
  const { hasAreaDeletePermission } = useAccessChecker()
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
      // Set isMounted to true after the first render
      setIsMounted(true)
  }, [])

  const containerStyle = useMemo(() => ({width: '100%', height: '300px'}), [])
  useEffect(() => {
    setRowData(methods.getValues('associated_rings'))
  }, [methods.getValues('associated_rings')])

  const handleOnRowSelected = (event) => {
    if (event.node.selected) {
      setSelectedRowData(event.data);
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 110,
      width: 110,
      resizable: true,
      sortable: false,
      suppressMenu: true,
      suppressMovable: true,
      cellStyle: function (params) {
        if (typeof params.value === 'number') {
          return {textAlign: 'center'}
        } else {
          return {textAlign: 'left'}
        }
      },
      wrapHeaderText: true,
      autoHeaderHeight: true,
      headerClass: 'ag-center-aligned-header',
      singleClickEdit: true,
    }
  }, [])

  const addRow = (ring) => {
    if (ring && ring.value !== '') {
      // Check if ring is defined and its value is not an empty string
      const newId = rowData.length + 1
      const newRow = {
        ring_id: ring.value,
        ring_number: newId,
        ring_name: ring.label,
      }

      // Check if row with same ring_id already exists
      const existingRow = rowData.find((row) => row.ring_id === ring.value)
      if (existingRow) {
        alertDialog({message: `The selected ring (${ring.label}) has already been added.`, title: 'Alert'})
        return
      }

      // Add the new row to the addedRings array
      setAddedRings([...addedRings, newRow])
      setRowData([...rowData, newRow])
      props.updateAllUpcomingShows()
    }
  }

  useEffect(() => {
      // Skip the effect on the initial render
      if (!isMounted) return;
      // update added_rings array
      methods.setValue('rings', addedRings, {shouldDirty: true})
      methods.setValue('facility.number_of_rings', rowData.length, {shouldDirty: true} )
  }, [addedRings])

  const columnDefs = [
    {
      field: 'ring_number',
      headerName: intl.formatMessage({id: 'FORM.INPUT.FACILITIES.TAB.RINGS.LABEL.NUMBER'}),
    },
    {
      field: 'ring_name',
      flex: 2,
      headerName: intl.formatMessage({id: 'FORM.INPUT.FACILITIES.TAB.RINGS.LABEL.RINGNAME'}),
      editable: true,
      cellClass: 'editable-cell',
    },
  ]



  const onGridReady = (params) => {
    setRingxFacilityGridApi(params.api)
    setRingxFacilityColumnApi(params.columnApi)
  }

  const handleOnRowDragEnd = () => {
    // Get the total number of rows in the grid
    let totalRowCount = ringxFacilityGridApi.getDisplayedRowCount()

    // Iterate through each row index and retrieve the row data object
    const newOrderedAllRings = []
    for (let i = 0; i < totalRowCount; i++) {
      let displayedRowData = ringxFacilityGridApi.getDisplayedRowAtIndex(i).data
      displayedRowData.ring_number = i + 1
      newOrderedAllRings.push(displayedRowData)
    }

    // Add the new row to the addedRings array
    setAddedRings(newOrderedAllRings)

    setRowData(newOrderedAllRings)

    // clear column state (sort order)
    ringxFacilityColumnApi.resetColumnState()
    props.updateAllUpcomingShows()
  }

  const handleOnMinusClick = async () => {
    if (!selectedRowData) {
      // no row is selected, do nothing
      return;
    }
  
    axios
    .get(process.env.REACT_APP_NEST_API_URL + '/facilities/validateRingDeletion', {
      params: {
        facility_id: props.facility_id,
        ring_id: selectedRowData.ring_id,
        customer_id: customer_id,
      },
    })
    .then((response) => {
      const { count, error_message } = response.data;
      if (count > 0) {
        // Display error message
        alertDialog({message: error_message, title: 'Error'})
      } else {
        // Proceed with deleting the row
        setDeletedRings([...deletedRings, selectedRowData]);
        const newRowData = rowData.filter((row) => row.ring_id !== selectedRowData.ring_id);
  
        // Update the 'number' property of the remaining rows
        for (let i = 0; i < newRowData.length; i++) {
          newRowData[i].ring_number = i + 1;
        }
  
        // Clear the selected row
        setSelectedRowData(null);
  
        // Add the new row to the addedRings array
        setAddedRings(newRowData)

        // Save the updated row data to state
        setRowData(newRowData);
        props.updateAllUpcomingShows()
      }
    })
    .catch((error) => {
      // Handle error
      console.error(error);
    });
  
  };

  useEffect(() => {
    if(deletedRings.length > 0){
      methods.setValue('deleted_rings', deletedRings, {shouldDirty: true})
      methods.setValue('facility.number_of_rings', rowData.length, {shouldDirty: true} )
    }
  }, [deletedRings])

  const onCellEditingStopped = (params) => {
    // Update the rowData with the edited value
    const {data, colDef, newValue, oldValue} = params
    const updatedRowData = rowData.map((row) => {
      if (row === data) {
        return {
          ...row,
          [colDef.field]: newValue,
        }
      }
      return row
    })
    setRowData(updatedRowData)
    if(newValue !== oldValue) {
      setAddedRings(updatedRowData)
      props.updateAllUpcomingShows()
    }
  }

  return (
    <>
      <div className='row mb-2'>
        <div className='col-lg-4'>
          {props.form_meta.active_rings && (
            <Select
              options={props.form_meta.active_rings}
              id='ring'
              isSearchable={true}
              onChange={(selectedRing) => {
                setSelectedRing(selectedRing)
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              styles={reactSelectStyles}
              placeholder='Select'
            />
          )}
        </div>
        <button
          className='btn btn-sm btn-secondary fw-bold text-uppercase h-120 py-1 d-flex align-items-center mx-1'
          style={{width: 'fit-content'}}
          type='button'
          onClick={() => {
            addRow(selectedRing)
          }}
          disabled={!selectedRing}
          data-tooltip-id="FACILITIES.DETAIL.TAB.RINGS.BUTTON.ADDTOFACILITY"
        >
          Add to Facility
        </button>
      </div>

      <div className='row mb-2'>
        <div className='col-lg d-flex align-items-center mb-2'>
          <div className='ag-theme-alpine' style={{height: '350px', width: '100%'}}>
            <AgGridReact
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowData={rowData}
              containerStyle={containerStyle}
              rowDragEntireRow={true}
              rowDragManaged={true}
              suppressMoveWhenRowDragging={false}
              animateRows={true}
              onDragStopped={handleOnRowDragEnd}
              onRowSelected={handleOnRowSelected}
              rowSelection={'single'}
              onCellEditingStopped={onCellEditingStopped}
              headerHeight={getNarrowHeaderHeight}
              rowHeight={getNarrowRowHeight}
              suppressRowHoverHighlight={true}
              stopEditingWhenCellsLoseFocus={true}
            ></AgGridReact>
          </div>
        </div>

        <div className='col-lg-1'>
          { hasAreaDeletePermission('rings') &&
          <button
            className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2'
            type='button'
            onClick={handleOnMinusClick}
            data-tooltip-id="FACILITIES.DETAIL.TAB.RINGS.BUTTON.REMOVE"
          >
            <i className='fas fa-minus fs-5 px-1 py-3'></i>
          </button>
          }
        </div>
      </div>
    </>
  )
}

export {RingsTab}
