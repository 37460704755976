import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

type Props = {
    show: boolean
    message: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const PersistentLoadingOverlay = ({ show, message}: Props) => {
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const intl = useIntl()

    if (!message) {
        message = intl.formatMessage({ id: 'LOADINGOVERLAY.MODAL.MESSAGE.LOADING' })
    }

    useEffect(() => {
        if(!isLoading){
            setShowModal(true)
        }else{
            setIsLoading(false)
        }
          
        if(!show){
            setShowModal(false)
        }
    }, [show])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-250px search-form'
            show={showModal}
            backdrop="static"
        >
        <div className='modal-body py-5 shadow-lg loading-bar'>
            <form noValidate className='form' onSubmit={e => e.preventDefault()}>
            <div className='card-body d-flex flex-row align-items-end justify-content-center'>
                <div className='row'>
                    <div className="spinner-border text-dark" role="status">
                        <span className="sr-only" dangerouslySetInnerHTML={{__html: message }}></span>
                    </div>
                    <div className='col'>
                        <span className="fs-4 mxr-1 font-weight-light" dangerouslySetInnerHTML={{__html: message}}></span>
                    </div>
                  
                </div>
            </div>
            </form>
        </div>
        </Modal>,
        modalsRoot
    )
}

export { PersistentLoadingOverlay }