import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Assets
import "flatpickr/dist/themes/material_blue.css";
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';
import { useMemo } from 'react';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';

const modalsRoot = document.getElementById('root-modals') || document.body

const ReversedAmountDialog = ({ show, handleClose, data }) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()

    const containerStyle = useMemo(() => ({ width: '100%', height: '200px' }), []);
    const columnDefs = [
        { field: 'name', headerName: intl.formatMessage({ id: 'PAYMENT.BATCH.REVERSED.AMOUNT.COL.RTO' }), width: 300 },
        { field: 'checkAmount', headerName: intl.formatMessage({ id: 'PAYMENT.BATCH.REVERSED.AMOUNT.COL.CHECK.AMOUNT'}), width: 120, cellRenderer: params => currencyFormatter(params.value), flex: 1 },
        { field: 'reversedAmount', headerName: intl.formatMessage({ id: 'PAYMENT.BATCH.REVERSED.AMOUNT.COL.REVERSED'}), width: 120, cellRenderer: params => currencyFormatter(params.value), flex: 1 },
    ]

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered check-reversed-amount'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'PAYMENT.BATCH.REVERSED.AMOUNT.TITLE' })}</h2>
                
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>

                    <div className='card-body p-0'>
                        <div className='row mb-3'>
                        <span className="d-inline-block align-bottom fs-6" dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: 'PAYMENT.BATCH.REVERSED.AMOUNT.MESSAGE' }) }}></span>
                        </div>
                    </div>

                    <fieldset className='mb-2'>
                        <div className='row'>
                            <OutputListingGridStatic
                                columnDefs={columnDefs}
                                rowData={data? data : []}
                                containerStyle={containerStyle}
                            ></OutputListingGridStatic>
                        </div>
                    </fieldset>

                    
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <div>
                            <button className='btn btn-sm btn-secondary fw-bold text-uppercase' type="button" onClick={handleClose} autoFocus>
                            { intl.formatMessage({ id: "PAYMENT.BATCH.REVERSED.AMOUNT.BUTTON.CLOSE"})}
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { ReversedAmountDialog }