import { AgGridReact } from "ag-grid-react";
import { memo, useMemo } from "react";
import { useIntl } from "react-intl";
import { renderCheckBox } from "../../../../modules/sgl-utils/agGridHelpers";
import { defaultColDef, getNarrowHeaderHeight, getNarrowRowHeight  } from "../../../../modules/sgl-utils/agGridHelpers";

const ClassesGrid = ({ setGridRef, tabIndex=0 }) => {
    const intl = useIntl()
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    const columnDefs = [
        { 
            field: 'selected', 
            headerName: "", 
            cellRenderer: params => renderCheckBox(
                params, 
                null,
                params.data.combined_class_id > 0
            ),
            width: 50,
        },
        { 
            field: 'number',
            headerName: intl.formatMessage({ id: 'LIST.CLASSES.NUMBER' }),
            width: 90,
            cellStyle: () => ({ textAlign: 'center' })
        },
        { 
            field: 'name', 
            headerName: intl.formatMessage({ id: "LIST.CLASSES.NAME" }), 
            flex: 1
        },
        {
            field: 'tripCount',
            headerName: 'count',
            width: 90
        }
    ]

    return (
        <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                <AgGridReact
                    defaultColDef={defaultColDef}
                    rowData={[]}
                    columnDefs={columnDefs} 
                    onGridReady={params =>  { 
                        setGridRef(params.api)
                    }}
                    rowStyle={{ fontSize: '14px' }}
                    headerHeight={getNarrowHeaderHeight}
                    rowHeight={getNarrowRowHeight}
                    {...(tabIndex !== 0 && { tabIndex })} //tabIndex is set conditionally, only if a prop was passed, provided that the prop is not 0   
                />
            </div>
        </div>
    )
}


export default memo(ClassesGrid)