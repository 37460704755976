/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import BooleanCheckmarkRenderer from '../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import { BooleanFilterOptions } from '../../modules/output-listing/filters/BooleanFilter';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { useCurrencyFormatter } from '../../modules/sgl-utils/Formatters';

const FeesList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const customer_id = useAppSelector(state=> state.showCompany.company_id);
  const currencyFormatter = useCurrencyFormatter()

  const columnDefs = [
    // { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.COMMONS.SGLID'}), filter: 'agNumberColumnFilter', filterParams: { suppressAndOrCondition: true,}, },
    { field: 'showfees_id', headerName: intl.formatMessage({ id: 'LIST.FEES.SHOWFEESID' }), filter: 'agNumberColumnFilter',hide: true},
    { field: 'description', width: 400, headerName: intl.formatMessage({ id: 'LIST.FEES.DESCRIPTION' }), filter: 'agTextColumnFilter', },
    { field: 'category', headerName: intl.formatMessage({ id: 'LIST.FEES.CATEGORY' }), filter: 'agTextColumnFilter', },
    { 
        field: 'unit_price', 
        headerName: intl.formatMessage({ id: 'LIST.FEES.UNITPRICE' }), 
        cellRenderer: (params) => currencyFormatter(params.value), 
        filter: 'agNumberColumnFilter',
        cellStyle: { textAlign: 'right' }
    },
    { field: 'default_qty', headerName: intl.formatMessage({ id: 'LIST.FEES.DEFAULTQTY' }), filter: 'agNumberColumnFilter'},
    { field: 'web_default_qty', headerName: intl.formatMessage({ id: 'LIST.FEES.WEBDEFAULTQTY' }), filter: 'agNumberColumnFilter' },
    { field: 'feed_bedding_fee', headerName: intl.formatMessage({ id: 'LIST.FEES.FEEDBEDDINGFEE' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, },
    { field: 'sold_to_date', headerName: intl.formatMessage({ id: 'LIST.FEES.SOLDTODATE' }), filter: 'agNumberColumnFilter'},
    { field: 'inventory_cap', headerName: intl.formatMessage({ id: 'LIST.FEES.INVENTORYCAP' }), filter: 'agNumberColumnFilter'},
    { 
        field: 'cost', 
        headerName: intl.formatMessage({ id: 'LIST.FEES.COST' }), 
        filter: 'agNumberColumnFilter',  
        cellRenderer: (params) => currencyFormatter(params.value),
        cellClass: { textAlign: 'right'}
    },
  ]
  
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.FEE' })}</PageTitle>
      <OutputListingComponent key={customer_id} area={'Fees'} columnDefs={columnDefs} apiEndPoint={'fees/list'}
        onRowDoubleClicked={(row_data) => {navigate('/fees/detail/'+row_data.showfees_id+`?customer_id=${customer_id}`)}}
        onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL+'/fees/detail/'+row_data.showfees_id+`?customer_id=${customer_id}`,'_blank')}>
        </OutputListingComponent>
    </>
  )
}

export { FeesList }