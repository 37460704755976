import React, { useEffect, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
type Props = {
  show: boolean
  title: string,
  message: string,
  type: string,
  icon: string,
  handleClose: () => void,
  timeout: number,
}

const FlashAlert = ({ show, title, message, type, icon, handleClose, timeout = 5000 }: Props) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMetadata, setAlertMetadata] = useState({class: '', title: '', message: '', icon: ''});

  useEffect(() => {
    if (show) {
      initAlertMetadata()
      setShowAlert(true)

      const timer = setTimeout(() => hideAlert(), timeout);
      return () => clearTimeout(timer);
    }
  }, [show])

  const hideAlert = () => {
    if(handleClose){
        handleClose()
    }
    setShowAlert(false)
  }

  const initAlertMetadata = () => {
    let cssClass = '';
    let alertTitle = '';
    let alertMessage = '';
    let alertIcon = '';

    switch (type) {
      case 'success': //Green -> success
        cssClass = 'success';
        alertTitle = 'Success';
        alertIcon = '/media/icons/duotune/general/gen043.svg';
        break;

      case 'info': //purple -> information
        cssClass = 'info';
        alertTitle = 'Notice';
        alertIcon = '/media/icons/duotune/general/gen045.svg';
        break;

      default: //Red -> Error
        cssClass = 'danger';
        alertTitle = 'Error';
        alertIcon = '/media/icons/duotune/general/gen040.svg';
        break;
    }

    //override default value from props
    alertTitle = title ? title : alertTitle;
    alertMessage = message ? message : alertMessage;
    alertIcon = icon ? icon : alertIcon;

    setAlertMetadata({class: cssClass, title: alertTitle, message: alertMessage, icon: alertIcon});
  };


  return (showAlert && 
    <div className={`alert alert-dismissible bg-light-${alertMetadata?.class} flash-alert-container d-flex flex-column flex-sm-row mb-10 position-fixed top-0 end-0 start-sm-20 mr-1`}>
      {alertMetadata?.icon && 
      <span className={`svg-icon svg-icon-2hx svg-icon-${alertMetadata?.class} me-1 mb-5 mb-sm-0`}>
        <KTSVG
          path={alertMetadata?.icon}
          className={`svg-icon-2hx svg-icon-${alertMetadata?.class} me-1 mb-5 mb-sm-0`}
        />
      </span>
      }

      <div className={`d-flex flex-column pe-0 pe-sm-10`}>
        {alertMetadata?.title && 
        <h4 className={`mb-1`}>{alertMetadata?.title}</h4>
        }

        {alertMetadata?.message && 
        <span>{alertMetadata?.message}</span>
        }
      </div>

      <button type="button" className="position-absolute position-sm-relative m-0 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" onClick={() => hideAlert()}>
          <span className={`svg-icon svg-icon-1 svg-icon-${alertMetadata?.class}`}>
            <KTSVG
              path='/media/icons/duotune/arrows/arr061.svg'
              className={`svg-icon-2x svg-icon-${alertMetadata?.class}`}
            />
          </span>
      </button>
    </div>
  );
};


export default FlashAlert;