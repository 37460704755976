import {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useAlert} from '../../../modules/sgl-utils/DialogsProvider'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { useFormContext } from "react-hook-form";
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer';

import { TaxRateInfoForm } from './TaxRateInfoForm';

const TaxRatesTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext();
    const [gridRef, setGridRef] = useState(false)
    const [rowData, setRowData] = useState([])
    const alertDialog = useAlert()
    const [showTaxRateForm, setShowTaxRateForm] = useState(false);
    const [selectedTaxRow, setSelectedTaxRow] = useState({ effective_date: '00-00-0000', rate: '0.00' });
    const [deletedTaxRates, setDeletedTaxRates] = useState([]);
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

    // AG Grid Column Definitions
    const columnDefs = [
        { field: 'effective_date', cellStyle: { textAlign: 'center' }, cellRenderer: DateRenderer, flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.TAXRATES.EFFECTIVEDATE' }) },
        { field: 'rate', flex: 1, cellStyle: { textAlign: 'right' }, headerName: intl.formatMessage({ id: 'FORM.INPUT.TAXRATES.RATE' }), valueFormatter: (params) => { const value = parseFloat(params.value); return isNaN(value) ? '' : (value * 100).toFixed(2) + '%'; } },
    ]

    // Update grid when tax rates change
    useEffect(() => {
      const taxRates = methods.getValues('tax_rates');
      setRowData(taxRates);
      // Update grid
      if (gridRef) {
          gridRef?.setRowData(taxRates);
      }

  }, [methods.watch('tax_rates')])


  // Find deleted records and remove them from tax rates whenever deletedTaxRates changes
  useEffect(() => {
      const taxRates = methods.getValues('tax_rates');
      let updatedTaxRates = [];

      taxRates.map((taxRate) => {
          const isTaxRateDeleted = deletedTaxRates.find((deletedTaxRate) => {
              return deletedTaxRate.taxrates_id == taxRate.taxrates_id
          })

          if (!isTaxRateDeleted) {
              updatedTaxRates.push(taxRate);
          }
      })

      setRowData(updatedTaxRates);
      // methods.setValue('tax_rates', updatedTaxRates, { shouldDirty: true })
      methods.setValue('tax_rates_deleted', deletedTaxRates, { shouldDirty: true });
      // Update grid
      if (gridRef) {
          gridRef?.setRowData(taxRates);
      }
  }, [deletedTaxRates])


  // Delete tax rate from tax rate grid
  const deleteSelectedTaxRate = () => {
      let isTaxRateUsed = false
      // TODO: check in 4d for this
      if (gridRef) {
          let selectedTaxRate = gridRef.getSelectedRows()

          if (selectedTaxRate.length > 0) {
              // If the tax rate is not used anywhere, we can delete it
              if (!isTaxRateUsed) {
                  setDeletedTaxRates([...deletedTaxRates, selectedTaxRate[0]])
              }
          } else {
              alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.TAXRATES.ERROR.MESSAGE.PLEASESELECTTAXRATETODELETE' }) })
          }
      }
  }

  return (
    <>
        {showTaxRateForm &&
            <TaxRateInfoForm
                show={showTaxRateForm}
                handleClose={() => {setShowTaxRateForm(false); setSelectedTaxRow({ effective_date: '00-00-0000', rate: '0.00' })}}
                selectedTaxRow={selectedTaxRow}
            />
        }
        <fieldset className='col-6'>
          <div className='row'>
              <label className='col-11 col-form-label fs-5 py-1 text-end' data-tooltip-id="TAXRATE.DETAIL.TAB.TAXRATES.LABEL.TOTAL">Total: {rowData !== undefined ? rowData.length : ""}</label>
          </div>
          <div className='row mb-2'>
              <div className='col'>
                  <OutputListingGridStatic
                      area={"TaxRates - Detail"}
                      columnDefs={columnDefs}
                      rowData={rowData}
                      containerStyle={containerStyle}
                      setGridRef={setGridRef}
                      onRowDoubleClicked={(row_data) => {
                            setSelectedTaxRow(row_data);
                            setShowTaxRateForm(true);
                        }
                    }
                  >
                  </OutputListingGridStatic>
              </div>
              <div className='col-lg-1'>
                  <button type='button' onClick={() => {setShowTaxRateForm(true)}} className="btn btn-sm btn-secondary me-2 fw-bold px-2 py-2" data-tooltip-id="TAXRATE.DETAIL.TAB.TAXRATES.BUTTON.ADD">
                      <i className="fas fa-plus fs-5 px-1 py-3"></i>
                  </button>
                  <div className="separator my-1 border-transparent d-xl-block d-lg-block d-md-none d-sm-none d-none"></div>
                  <button type='button' onClick={() => deleteSelectedTaxRate()} className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2' data-tooltip-id="TAXRATE.DETAIL.TAB.TAXRATES.BUTTON.REMOVE">
                      <i className='fas fa-minus fs-5 px-1 py-3'></i>
                  </button>
              </div>
          </div>
      </fieldset>
    </>
    )
  }
  
export {TaxRatesTab}