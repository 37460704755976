import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { getExtraNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers'
import HideJsonRenderer from '../../../../modules/output-listing/renderers/HideJsonRenderer'
// Metronic Components
import { useMemo } from 'react'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Assets
import "flatpickr/dist/themes/material_blue.css";

// Constants
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';

type Props = {
    show: boolean
    handleClose: () => void
    lookupResponse: []
    handleUpdateData: () => void
    membershipCardUrl: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const MembershipDetailForm = ({ show, handleClose, lookupResponse, handleUpdateData, membershipCardUrl }: Props) => {
    const intl = useIntl()

    const columnDefs = [
        { field: 'key', maxWidth: 245, minWidth:245, flex: 1, lockVisible: false, lockPosition: 'left', cellStyle: {'border-right': '1px solid #eee'}
            , cellRenderer: 'agGroupCellRenderer',
            cellRendererSelector: function (params: {
                node: any, data: any 
            }) {
                if (params.data.value != null && Array.isArray(params.data.value) && params.data.value?.length > 0) {
                    params.node.setExpanded(true);
                }
            }
        },
        { field: 'value', flex:1, lockVisible: false, lockPosition: 'left', cellStyle: {textAlign: 'left'}, cellRenderer: HideJsonRenderer},
    ]

    const rowData = lookupResponse


    const containerStyle = useMemo(() => ({ width: '100%', height: '400px' }), []);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event :any) => {
                if (event.key === "Enter") {
                    handleUpdateData()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.MODAL.MEMBERSHIPDETAIL.TITLE.MEMBERSHIPDETAIL' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row align-items-center mb-2 membership-data-grid'>
                            <OutputListingGridStatic  area ={"Entries - Membership Detail"} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle} rowHeight={getExtraNarrowRowHeight} suppressRowClickSelection={true} masterDetail={true} keepDetailRows={true} keepDetailRowsCount={2} detailRowAutoHeight={true}></OutputListingGridStatic>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        {/* Link to view the membership card */}
                        {membershipCardUrl && (
                            <a href={membershipCardUrl} target="_blank" rel="noopener noreferrer" className='btn btn-sm btn-dark fw-bold text-uppercase me-2'>
                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.MODAL.MEMBERSHIPDETAIL.BUTTON.VIEWCARD' })}
                            </a>
                        )}
                        <button className='btn btn-sm btn-dark fw-bold text-uppercase me-2' type='button' onClick={() => handleUpdateData()} autoFocus>
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.MODAL.MEMBERSHIPDETAIL.BUTTON.DONE' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { MembershipDetailForm }