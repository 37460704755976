import { useMemo, useState, useEffect, useRef} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers'

// Constants
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import  { useCurrencyFormatter } from '../../../modules/sgl-utils/Formatters';
import { addFloatingNumbers } from '../../../modules/sgl-utils/SglFunctions'
import { numberValueSetter, updateAGGridWithoutFlickering } from '../../../modules/sgl-utils/agGridHelpers'
import { Controller } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

const modalsRoot = document.getElementById('root-modals') || document.body

const RefundPaymentForm = ({ show, handleClose, refundPayment, callRefundAPI, refunded_amount, methods, paymentCreatedToday }) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()  
    const [gridRef, setGridRef] = useState()
    const [errorMessage, setErrorMessage] = useState ('') 
    const confirmDialog = useConfirm()
    const [refundType, setRefundType] = useState('full')
    const [isAmountEditable, setIsAmountEditable] = useState(false)
    
    // To clear error message when reason entered 
    useEffect(() => {
        if(methods.watch('reason') != '' && errorMessage == 'Please enter a reason' ){
            setErrorMessage('')
        }
      }, [methods.watch('reason')])

      useEffect(() => {
        if(show){      
            const handleEnterKey = (event) => {
                let activeElement = document.activeElement
                let isButtonFocused = false
                let isNotAGGridEditableCell = (!event?.target?.className?.includes('ag-input-field-input') && !event?.target?.className?.includes('ag-text-field-input'))
               
                if (activeElement && activeElement.tagName === 'BUTTON') {
                    isButtonFocused = true
                }

                if (event.key === 'Enter' && !event.shiftKey && !isButtonFocused ) {
                    if (isNotAGGridEditableCell) {
                        let submitButton = document.getElementById('RefundPaymentSubmitButton')
                        submitButton?.click()
                    }
                }
            };
            
            document.addEventListener('keydown', handleEnterKey, true);
            
            return () => {
                document.removeEventListener('keydown', handleEnterKey, true);
            };
        }
    }, [show]);

    useEffect(() => {
        // reset the checkbox whenever form is loaded
        methods.setValue('chargeback', false)
        // set refund type based on the amount already refunded
        if(refunded_amount > 0){
            // some of the payment is already refunded so refund type will be partial
            setRefundType('partial')
        }else{
            // no amount is refunded so refund type is full
            setRefundType('full')
        }
    }, [])

    useEffect(() => {
        // set the refund amount
        methods.setValue('refund_amount', addFloatingNumbers([refundPayment.amount, -1*refunded_amount], 2))

        // select all the distributions if selected refund type is full or payment created today
        if(gridRef){
            let updatedPXE = methods.getValues('paymentxentries')
            if(refundType == 'full' || paymentCreatedToday){
                // Select all the distributions
                updatedPXE = updatedPXE.map((pxe) => {
                    pxe.check = true
                    return pxe
                })
            }else if(refundType == 'partial'){
                // Select all the distributions
                updatedPXE = updatedPXE.map((pxe) => {
                    pxe.check = false
                    return pxe
                })
            }

            // update the refunded amount in the distributions
            methods.setValue('paymentxentries', updatedPXE)
            payment_splitRefundAmount() 

            // set isAmountEditable state variable
            setIsAmountEditable(refundType == 'partial' && !paymentCreatedToday)
        }
    }, [gridRef, refundType])

    useEffect(() => {
        if(gridRef){
            // update the editable field for column defs
            updateColumnDefs()
        }
    }, [gridRef, isAmountEditable])

    const updateColumnDefs = () => {
        let colDefs = gridRef?.getColumnDefs()
        if(isAmountEditable){
            colDefs[3].iseditable = true // checkbox
            colDefs[8].editable = true // refund amount
        }else{
            colDefs[3].iseditable = false // checkbox
            colDefs[8].editable = false // refund amount
        }

        gridRef.setColumnDefs(colDefs)
    }

    const renderCheckBox = (params) => {
        return (
            <input
                name="ag-grid-checkbox-renderer"
                type="checkbox"
                onChange={(e) => params.node.setDataValue(params.column.colId, e.target.checked)}
                checked={params.value}
                disabled={!params.colDef?.iseditable}
            />
        )
    }

    const paymentxEntriesColumnDefs = [
        { field: 'sgl_id', hide:true },
        { field: 'entry_id', hide:true },
        { field: 'payment_id', hide:true },
        { field: 'check', cellClass: (params) => params?.colDef?.iseditable ? 'editable-cell' : '', headerName: "", cellRenderer: params => params.node.rowPinned ? null : renderCheckBox(params),  maxWidth: 60, minWidth: 60,},
        { field: 'entry_number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.TAB.GENERAL.NUMBER' }), minWidth: 90, maxWidth: 90 },
        { field: 'horse',flex: 3, headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.TAB.GENERAL.HORSE' }) },                
        { field: 'show_name', flex: 2, headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.TAB.GENERAL.SHOW' })},
        { field: 'available_amount',flex: 1,  cellStyle: { textAlign: "center" }, headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.TAB.GENERAL.AVAILABLE' }), cellRenderer: params => params.node.rowPinned ? null : currencyFormatter(params.value), cellStyle: { textAlign: 'center' }}, //ar_ApplyRefund_AvailableAmount
        { field: 'refund_amounts',flex: 1, editable: true, cellClass: (params) => params?.colDef?.editable ? 'editable-cell' : '', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.MODAL.REFUND.LABEL.REFUNDAMOUNT' }),  cellRenderer: params => currencyFormatter(params.value), cellStyle: { textAlign: 'center' }, valueSetter: numberValueSetter }, //ar_ApplyRefund_Amount
    ]
    let paymentxEntriesRowData = methods.watch('paymentxentries')
    // .map(fillCheckboxesData => {return {...fillCheckboxesData,check: false,refund_amounts:0}} )  

    // !4D -> payment_splitRefundAmount
    function payment_splitRefundAmount (splitAmountInEntries=true){
        let refundAmount = methods.getValues('refund_amount')
        let availableRefundAmount = addFloatingNumbers([refundPayment.amount,  -1*refunded_amount], 2)
        let refundPaymentError = ""        
        if(refundAmount<0){
            methods.setValue('refund_amount', 0)
        } else if(parseInt(refundAmount)>parseInt(availableRefundAmount)){                        
            refundPaymentError = "Refund amount cannot exceed amount available for refund."            
        }

        let amountToSplit = refundAmount
        let grid_updated = false
        let refundAmountSumlet = 0

        let paymentxentries = methods.watch('paymentxentries')
        for(let row of paymentxentries){
            if(row.refund_amounts<0){ //ignore negative values
                row.refund_amounts = 0
                grid_updated = true
            }

            if(row.check){ //Checkbox is selected. Payment distribution set to be included for refund            
                if(splitAmountInEntries){ //need to split amount between distributions 
                    row.refund_amounts = 0
                    grid_updated = true
                    if(amountToSplit>0){//amount left to distribute
                        row.refund_amounts = Math.min(row.available_amount,amountToSplit)
                        grid_updated = true
                        amountToSplit = addFloatingNumbers([amountToSplit , -1*row.refund_amounts], 2)
                    }
                } //End split amount
                if(parseFloat(row.refund_amounts)>parseFloat(row.available_amount)){ //Check entries refund exceed allowed refund
                    row.refund_amounts = 0
                    grid_updated = true
                    refundPaymentError = "Refund amount for entry distribution cannot exceed amount available for refund."
                }

                refundAmountSumlet = addFloatingNumbers([refundAmountSumlet, row.refund_amounts], 2)

            } else {
                row.refund_amounts = 0
                grid_updated = true
            }

        }

        if(grid_updated){
            // gridRef.setRowData (paymentxentries)  
            updateAGGridWithoutFlickering(({ current: { api : gridRef}}), paymentxentries) // update grid without rerendering             
            methods.setValue('paymentxentries', paymentxentries)
        }

        if (refundPaymentError == ""){  //display error text after performing split amount
            if (parseFloat(refundAmount) != parseFloat(refundAmountSumlet)){ //refundAmountSum value comes from update footer
                refundPaymentError = "Refund Amount does not match with sum of Refund Distributions."
            }
        }
        setErrorMessage(refundPaymentError)

    }
    
    function onCellValueChanged (params){
        // update data for checkbox only. refund amount is updated using onCellEditingStopped
        if(params?.column?.colId != 'check'){ return } 

        let selected = params.node.data
        
        let paymentxentries = methods.watch('paymentxentries')
        paymentxentries = paymentxentries.map(row => {
            if (row.sgl_id === selected.sgl_id) {
              return { ...row, ...selected };
            }
            return row;
        });
        
        // gridRef.setRowData(paymentxentries)
        updateAGGridWithoutFlickering(({ current: { api : gridRef}}), paymentxentries) // update grid without rerendering    
        methods.setValue('paymentxentries', paymentxentries)
        payment_splitRefundAmount()        
    }

    function onRefundAmountChange(){
        payment_splitRefundAmount()        
    }

    function getTotalRefundAmount() {
        let total_refund_amount = methods.watch('paymentxentries').map(pxe => parseFloat(pxe.refund_amounts)).reduce(function (total, num) { return addFloatingNumbers([total, num], 2) }, 0);
        return total_refund_amount
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

    const submitCancel = () => {
        handleClose();

    }
    
    const handleSave = async () => {
        let selected_rows = methods.getValues('paymentxentries').filter(pxe => pxe.check)
        let refund_amount = methods.getValues('refund_amount')
        let reason = methods.getValues('reason')
        let chargeback = methods.getValues('chargeback')

        let error = ''
        if(selected_rows.length <= 0) {
            error = 'Please select atleast one row to apply refund amount'
        } else if(refund_amount <= 0) {
            error = 'Please enter a valid amount to refund'
        } else if(reason == '') {
            error = 'Please enter a reason'
        } 

        if( error == ''){

            if(chargeback){
                // get confirmation for user
                const choice = await confirmDialog({ message: 'Are you sure you want to refund the payment as Chargeback?' })
                if(!choice){
                    return
                }
            }

            let paymentFormData = {
                refund_amount: refund_amount,
                refund_reason: reason,
                selected_entries: selected_rows,
                chargeback
            }

            callRefundAPI(paymentFormData)
        } else {
            setErrorMessage(error)
        }
         
    }

    const onCellEditingStopped = (params) => {
        let selected = params.node.data

        if(isNaN(selected?.refund_amounts)){
            // don't allow non-numeric data
            selected.refund_amounts = 0
        }
        
        let paymentxentries = methods.watch('paymentxentries')
        paymentxentries = paymentxentries.map(row => {
            if (row.sgl_id === selected.sgl_id) {
              return { ...row, ...selected };
            }
            return row;
        });
        
        // gridRef.setRowData(paymentxentries)
        updateAGGridWithoutFlickering(({ current: { api : gridRef}}), paymentxentries) // update grid without rerendering    
        methods.setValue('paymentxentries', paymentxentries)
        payment_splitRefundAmount(params?.column?.colId == 'refund_amounts' ? false : true)   
    }

    useEffect(() => {
        if(gridRef){
            //recalculate the refunded amount
            let totalRefundAmount = getTotalRefundAmount()
            gridRef.setPinnedBottomRowData([{
                refund_amounts: totalRefundAmount
            }])
        }
    }, [gridRef, methods.watch('paymentxentries')])


    const getRowStyle = (params) => {
        if (params.node.rowPinned) {
            return { fontWeight: "bold" };
        }
    };

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-950px-max-width'
            show={show}
            onHide={submitCancel}
            // onKeyPress={(event) => {
            //     if (event.key === "Enter") {
            //         handleSave()
            //     }
            // }}
        >
            <style>{"@media (min-width: 992px) { .add-payment-form .w-lg-160px { width: 160px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{paymentCreatedToday ? intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.VOIDPAYMENT' }) : intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.MODAL.REFUND.LABEL.REFUNDPAYMENT' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={submitCancel}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-payment-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        {/* Radio Button for full and partial refunds*/}
                        {
                            !paymentCreatedToday ?
                            <div className='row mb-2'>
                                <label className='col-lg-3 col-form-label fs-5 py-1 w-lg-120px pe-0'></label>
                                <div className='col-lg-3 form-check-sm form-check-custom px-3 refund-radio'>
                                    <input
                                        className='form-check-input'
                                        type='radio'
                                        id='fullRefund'
                                        checked={refundType === 'full'}
                                        onChange={(e) => {
                                            setRefundType('full')
                                        }}
                                    />
                                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='fullRefund'> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.MODAL.REFUND.LABEL.FULLREFUND' })}</label>
                                </div> 
                                <div className='col-lg-3 form-check-sm form-check-custom px-3 mw-150px'>
                                    <input
                                        className='form-check-input'
                                        type='radio'
                                        id='partialRefund'
                                        checked={refundType === 'partial'}
                                        onChange={(e) => {
                                            setRefundType('partial')
                                        }}
                                    />
                                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='partialRefund'> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.MODAL.REFUND.LABEL.PARTIALREFUND' })}</label>
                                </div>                   
                            </div> : null
                        }
                        {/* Radio Button for full and partial refunds */}

                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fs-5 py-1 w-lg-120px pe-0' data-tooltip-id="PAYMENTS.DETAIL.MODAL.REFUNDPAYMENT.LABEL.AVAILABLEAMOUNT">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.MODAL.REFUND.LABEL.AVAILABLEAMOUNT' })}:</label>
                            <label className='col col-form-label fs-5 py-1 d-flex ps-5'>{currencyFormatter(refundPayment.amount)}</label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fs-5 py-1 w-lg-120px pe-0' data-tooltip-id="PAYMENTS.DETAIL.MODAL.REFUNDPAYMENT.LABEL.AVAILABLEFORREFUND">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.MODAL.REFUND.LABEL.AVAILABLEFORREFUND' })}:</label>
                            <label  className='col col-form-label fs-5 py-1 d-flex ps-5'>{currencyFormatter(addFloatingNumbers([refundPayment.amount, -1*refunded_amount], 2))}</label>
                        </div>
                        <div className='row mb-2'>
                            <label id="availableforrefund_amount" className='col-lg-3 col-form-label fs-5 py-1 w-lg-120px pe-0' data-tooltip-id="PAYMENTS.DETAIL.MODAL.REFUNDPAYMENT.LABEL.REFUNDAMOUNT">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.MODAL.REFUND.LABEL.REFUNDAMOUNT' })}:</label>
                            <div className='col-lg-4'>
                                {/* <input                
                                    {...methods.register("refund_amount", { required: false })}
                   
                                    id='refund_amount'
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    // onChange={onRefundAmountChange}
                                    onBlur={onRefundAmountChange}
                                    // onLoad={onRefundAmountLoad}
                                    autoFocus
                                    tabIndex={1}
                                /> */}
                                <Controller
                                    control={methods.control}
                                    name="refund_amount"
                                    render={({ field: { onChange, value} }) => (
                                    <NumericFormat
                                        id='refund_amount'
                                        allowNegative={false}
                                        value={value !== '' ? Number(value): ""}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        onValueChange={(e) => {
                                            // Only update the value if it's changed by the user
                                            if (Number(e.value) !== Number(value)) {
                                                onChange(e.value);
                                            }
                                        }}
                                        onBlur={onRefundAmountChange}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        disabled={!isAmountEditable}
                                    />
                                    )}
                                />
                            </div>
                            <div className='col-lg-4'>
                            <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold h-30px py-1 w-200px ' style={{ marginLeft: "auto" }} onClick={onRefundAmountChange} tabIndex={2} data-tooltip-id="PAYMENTS.DETAIL.MODAL.REFUNDPAYMENT.BUTTON.DISTRIBUTEAMOUNT">
                                {intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.MODAL.REFUND.BUTTON.DISTRIBUTEAMOUNT' })}
                            </button>
                            </div>
                        </div>

                        {/* Chargeback Checkbox */}
                        <div className='row mb-2'>
                            <div className='col-3'></div>
                            <div className='col-4'>
                                <div className='col form-check-sm form-check-custom'>
                                    <input
                                        {...methods.register("chargeback", 
                                        {
                                            required: false
                                        })
                                        }
                                        className='form-check-input '
                                        type='checkbox'
                                        id='chargeback'
                                    />
                                    <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='chargeback'> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.CHARGEBACK' })}</label>
                                </div> 
                            </div>
                        </div>
                        
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fs-5 py-1 w-lg-120px pe-0' data-tooltip-id="PAYMENTS.DETAIL.MODAL.REFUNDPAYMENT.LABEL.REASON">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.MODAL.REFUND.LABEL.REASON' })}:</label>
                            <div className='col-lg-8'>
                                <textarea
                                    {...methods.register("reason", { required: false })}

                                    id='reason'
                                    rows={3}
                                    // type='text'
                                    className='form-control form-control-sm fs-6 min-h-90px py-1'
                                    tabIndex={3}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fs-5 py-1 w-lg-120px pe-0 fw-bold' data-tooltip-id="PAYMENTS.DETAIL.MODAL.REFUNDPAYMENT.LABEL.REFUNDDISTRIBUTION">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.MODAL.REFUND.LABEL.REFUNDDISTRIBUTION' })}:</label>
                        </div>
                        <div className='row mb-2'>

                            <div className='col'>
                                <OutputListingGridStatic 
                                    area ={"Payments - Refund Payment"} 
                                    selection={'multiple'} 
                                    columnDefs={paymentxEntriesColumnDefs} 
                                    onCellValueChanged = {onCellValueChanged}
                                    rowData={paymentxEntriesRowData} 
                                    containerStyle={containerStyle} 
                                    setGridRef = {setGridRef}
                                    stopEditingWhenCellsLoseFocus={true}
                                    tabIndex={4}
                                    getRowStyle={params => getRowStyle(params)}
                                    suppressScrollOnNewData={true}
                                    stopEditing={onCellEditingStopped}
                                ></OutputListingGridStatic>
                            </div>
                        </div>
                        {/* <div className='row mb-2'>
                            <label className=' col-11 text-end'>{currencyFormatter (getTotalRefundAmount())}</label>
                        </div> */}
                        
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <label className='ps-0 fs-5 fw-bold error_message'>{errorMessage}</label>

                     
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={submitCancel} tabIndex={5}>
                            {intl.formatMessage({ id: 'BUTTON.CANCEL' })}
                        </button>
                        <button
                            id='RefundPaymentSubmitButton'
                            type='button' className='btn btn-sm btn-dark fw-bold' 
                            onClick={e => {e.preventDefault(); handleSave();}}
                            disabled={errorMessage != ''}
                            tabIndex={6}
                            data-tooltip-id="PAYMENTS.DETAIL.MODAL.REFUNDPAYMENT.BUTTON.APPLY"
                            >
                            {intl.formatMessage({ id: 'BUTTON.APPLY' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { RefundPaymentForm }