import { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import UpcomingShowsGrid from './AddToSeries/UpcomingShowsGrid'
import { useAlert, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider'
import { useAppSelector } from '../../../redux/hooks'
import { useFormContext } from 'react-hook-form'
import axios from 'axios'
import FeeGrid from './AddToSeries/FeeGrid'
import { getRowData } from '../../../modules/sgl-utils/agGridHelpers'
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const modalsRoot = document.getElementById('root-modals') || document.body

const AddToSeriesForm = ({ show, handleClose }) => {
    const intl = useIntl() 
    const methods = useFormContext()
    const {progressBar} = useProgress()
    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const { getEventID }  = useOutputContext()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const showId = useAppSelector(state => state.currentShow.show_id)
    const entryId = methods.getValues('entry.entry_id')
    const replicateSignatureInputRef = useRef(null)
    const [selectAllShows, setSelectAllShows] = useState(false)
 
    const [feeGridRef, setFeeGridRef] = useState(null)
    const [upcomingShowsGridRef, setUpcomingShowsGridRef] = useState(null)

    useEffect(() => {
        if (upcomingShowsGridRef) {
            const rowData = getRowData(upcomingShowsGridRef)
            rowData.forEach(r => {
                if(!r.disabled){
                    r.selected = selectAllShows
                }
            })
            upcomingShowsGridRef.setRowData(rowData)
        }
    }, [upcomingShowsGridRef, selectAllShows])

    useEffect(() => {
        if (show && upcomingShowsGridRef && entryId) {
            const validateAndGetMetaData = async () => {   // Validates classes in selection
                try {
                    loadingOverlay({ show: true })
                    const response = await axios.get(`${NEST_API_URL}/entries/getAddEntryToSeriesMetadata?customer_id=${customerId}&entry_id=${entryId}&show_id=${showId}`) 

                    if (response.data.success) {
                        upcomingShowsGridRef.setRowData(response.data.addSeriesData) 
                        feeGridRef.setRowData(response.data.addSeriesFeeData)
                    }

                } catch (reason) {
                    let error = reason?.response?.data?.error ?? 'Something went wrong..'
                    alertDialog({ message: error })
                } finally {
                    loadingOverlay({ show: false })
                }
            }
            validateAndGetMetaData()
        }
        
    }, [show, upcomingShowsGridRef, entryId])

    const addEntryToSeries = async () => {
        const eventID = getEventID('add-entry-to-series')
        try {
            const feesGridRowData = getRowData(feeGridRef) 
            const upcomingShowsGridRowData = getRowData(upcomingShowsGridRef)            
            progressBar({ show: true, eventID, showProgress: 'inline', title: 'Add Entry to Series', timeElapsed: true })

            const response = await axios.post(`${NEST_API_URL}/entries/addEntryToSeries`, {
                customer_id: customerId,
                current_show_id: showId,
                entry_id: entryId,
                replicate_digital_signatures: replicateSignatureInputRef.current.checked,
                entry_fees_to_add: feesGridRowData.filter(ef => ef.include), // send only selected entry fees data
                series_future_shows: upcomingShowsGridRowData,
                event_id: eventID
            })

            upcomingShowsGridRef.setRowData(response.data.seriesFutureShows)

        } catch (reason) {
            alertDialog({ message: reason })
        } finally {
            progressBar({ show: false, eventID })
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    addEntryToSeries()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">Add Entry to Series</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col col-form-label fw-bold fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDENTRYTOSERIES.LABEL.QUESTION"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.QUESTION' })}</label>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-4 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='KEEPENTRYNUM'
                                    autoFocus
                                    tabIndex={1}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='KEEPENTRYNUM' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDENTRYTOSERIES.LABEL.KEEPENTRYNUM"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.LABEL.KEEPENTRYNUM' })}</label>
                            </div>
                            <div className='col-lg-4 form-check-sm form-check-custom'>
                                <input
                                    ref={replicateSignatureInputRef}
                                    className='form-check-input'
                                    type='checkbox'
                                    id='REPLICATEDIGISIGNS'
                                    tabIndex={2}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='REPLICATEDIGISIGNS' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDENTRYTOSERIES.LABEL.REPLICATEDIGISIGNS"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.LABEL.REPLICATEDIGISIGNS' })}</label>
                            </div>
                            <div className='col-lg-4 form-check-sm form-check-custom'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='selectAllShows'
                                    value={selectAllShows}
                                    onChange={e => setSelectAllShows(e.target.checked)}
                                    tabIndex={3}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='selectAllShows' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDENTRYTOSERIES.LABEL.SELECTALLSHOWS">
                                    Select All Shows
                                </label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDENTRYTOSERIES.LABEL.UPCOMINGSERIESSHOWS"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.LABEL.UPCOMMINGSERIESSHOWS' })}</label>
                        </div>
                        <div className='row mb-2'>
                            <UpcomingShowsGrid
                                setUpcomingShowsGridRef={setUpcomingShowsGridRef}
                            />
                        </div>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDENTRYTOSERIES.LABEL.CARRYFORWARD"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.LABEL.CARRYFORWARD' })}</label>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-9'>
                                <FeeGrid
                                    setFeeGridRef={setFeeGridRef}
                                />
                            </div>
                            <div className='col-lg-3 align-self-end'>
                                <div className='row mb-2 justify-content-end'>
                                    <div className='col-lg-1 w-lg-200px'>
                                        <button 
                                            type='button' 
                                            className="btn btn-sm btn-dark fw-bold px-2 py-2 text-uppercase col-12"
                                            onClick={addEntryToSeries}    
                                            tabIndex={4}
                                            data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDENTRYTOSERIES.BUTTON.CREATEENTRIES"
                                        >
                                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.BUTTON.CREATEENTRIES' })}
                                        </button>
                                    </div>
                                </div>
                                <div className='row justify-content-end'>
                                    <div className='col-lg-1 w-lg-200px'>
                                        <button 
                                            type='button' 
                                            className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12"
                                            onClick={handleClose}
                                            tabIndex={5}
                                            data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDENTRYTOSERIES.BUTTON.DONE"
                                        >
                                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDTOSERIES.BUTTON.DONE' })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { AddToSeriesForm }