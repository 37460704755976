import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useLoadingOverlay, useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
import ClassesGrid from './UnCombineClasses/ClassesGrid';
import axios from 'axios';
import { KTSVG } from '../../../../_metronic/helpers';
import "flatpickr/dist/themes/material_blue.css";
import Select from 'react-select';
import { useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const UnCombineClasses = ({ show, handleClose }) => {
    const intl = useIntl() 
    const loadingOverlay = useLoadingOverlay()
    const outputContextUpdater = useOutputContextUpdater()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const showId = useAppSelector(state => state.currentShow.show_id)
    const customerId = useAppSelector(state=> state.showCompany.company_id)

    const [gridRef, setGridRef] = useState()
    const [combinedClasses, setCombinedClasses] = useState([])
    const [selectedCombinedClass, setSelectedCombinedClass] = useState(null)

    // Metadata Call
    useEffect(() => {
        if (show && gridRef) {

            const validateAndGetMetaData = async () => { 
                try {
                    loadingOverlay({ show: true })
          
                    const response = await axios.post(`${NEST_API_URL}/classes/getUnCombineClassesMetadata`, {
                        show_id: showId,
                        customer_id: customerId
                    })
                    
                    if (!response.data.success) { // Validations Failed!
                        alertDialog({ message: response.data.error })
                        handleClose() 
                    } else { // Validations Passed! We will always have at least 1 combinedClass in such case! 
                        setSelectedCombinedClass(response.data.combinedClasses[0])
                        setCombinedClasses(response.data.combinedClasses)
                        gridRef.setRowData(response.data.combinedClasses[0].rowData)
                    }
                } catch (reason) {
                    alertDialog({ message: reason.response.data.message })
                } finally {
                    loadingOverlay({ show: false })
                } 
            }

            validateAndGetMetaData()
        }
    }, [show, gridRef])

    const unCombineClasses = async () => {
        try {
            loadingOverlay({ show: true })
            const response = await axios.post( NEST_API_URL + `/classes/unCombineClassValidations`, {
                customer_id: customerId,
                combined_class: selectedCombinedClass
            })

            if (response.data.success) {
                if (await confirmDialog({ message: 'Are you sure you want to uncombine these classes?' })) {
                    const res2 = await axios.post( NEST_API_URL + `/classes/unCombineClasses`, {
                        customer_id: customerId,
                        actual_class_ids: response.data.actualClassIds,
                        combined_class_id: selectedCombinedClass.value
                    })
                    onClose(res2.data.classSglIds)
                }
            } else {
                alertDialog({ message: response.data.error })
            }
        } catch (reason) {
            alertDialog({ message: reason.response.data.message })
        } finally {
            loadingOverlay({ show: false })
        }
    }

    const onClose = (sglIds) => {
        outputContextUpdater({ action: 'getDataBySglIds', subsetIDs: sglIds }) // Refresh listing with currently combined classes
        handleClose() 
    }
    
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px'
            show={show}
            onHide={handleClose}
            backdrop='static'
            onKeyDown={(event) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    unCombineClasses()
                }
                else if(event.key === 'Escape'){
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'Classes.QuickAction.UnCombineClasses' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='card-body p-0 px-2'>
                    <div className='row mb-2'>
                        <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor="selectClass">
                            {intl.formatMessage({ id: 'Classes.QuickAction.UnCombineClasses.Label.SelectClass' })}
                        </label>
                        <div className='col'>
                            <Select
                                type="text"
                                inputId="selectClass" 
                                theme={theme => ({...theme, borderRadius: 'none'})}
                                value={selectedCombinedClass}
                                onChange={e => { 
                                    gridRef.setRowData(e.rowData)
                                    setSelectedCombinedClass(e)
                                }}
                                options={combinedClasses}
                                autoFocus
                                tabIndex={1}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <ClassesGrid setGridRef={setGridRef} tabIndex={2} />
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-3 px-2'>
                    <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={handleClose} tabIndex={3}>
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CLOSE" })}
                    </button>

                    <button 
                        type="button" 
                        className='btn btn-sm btn-dark fw-bold text-uppercase'
                        onClick={unCombineClasses}
                        tabIndex={4}
                        >
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.UNCOMBINE" })}
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default UnCombineClasses