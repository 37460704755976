import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { renderCheckBox,getNarrowRowHeight, getExtraNarrowHeaderHeight, numberValueSetter, getRowData, updateAGGridWithoutFlickering } from "../../../../modules/sgl-utils/agGridHelpers";
import { useCurrencyFormatter } from "../../../../modules/sgl-utils/Formatters";
import CheckboxHeader from "../../../../modules/components/CheckboxHeader";
import { addFloatingNumbers } from "../../../../modules/sgl-utils/SglFunctions";

const EntriesGrid = ({ info, entriesWithBalanceOnly, setEntriesWithBalanceOnly, checkAll, setCheckAll, setForceReapplySplit, setGridRef, autoApplyPrizeMoney, setAutoApplyPrizeMoney, gridRef, trigger, updatePrizeMoneySpent, autoApplyPrizeMoneyPayment, setAutoApplyPrizeMoneyPayment, calculateCreditCardServiceFee, updateInfo, updateIncludeForAllEntries, recalculate }) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    const defaultColDef = {
        suppressMenu: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: params => {
            let style = {textAlign: 'left'}
            if (typeof params.value === 'number') {
                style = { textAlign: 'right' }
            } else if (typeof params.value === 'boolean') {
                style = { textAlign: 'center' }
            }
           return { ...style } 
        },
        headerClass: "ag-center-aligned-header",
        sortable: true,
        resizable: true
    }

    const columnDefs = [
        { 
            field: 'Show.show_name', 
            headerName: intl.formatMessage({ id: 'ENTRIES.FEESPLIT.MODAL.GRID.SHOW' }), 
            flex: 3,
        },
        { 
            field: 'number',
            headerName: intl.formatMessage({ id: "LIST.ENTRIES.NUMBER" }),
            width: 90,
            cellClass: 'text-center'
        },
        { 
            field: 'horse', 
            headerName: intl.formatMessage({ id: "LIST.ENTRIES.HORSE" }), 
            flex: 2,
        },
        { 
            field: 'owner', 
            headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.OWNER" }), 
            flex: 2,
        },
        { 
            field: 'rider_list', 
            headerName: intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.GRID.RIDER" }), 
            flex: 2,
        },
        { 
            field: 'prizemoneyrecipient', 
            headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.PMR" }), 
            flex: 2,
        },
        { 
            field: 'availablePrizeMoney', 
            headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.AVAILABLEPRIZEMONEY" }), 
            width: 125,
            cellRenderer: params => params.node.rowPinned ? null : currencyFormatter(params.value),
            cellStyle: { textAlign: 'right' },
            cellClass: params => (!params.data.prizeMoneyCanBeApplied && params.data.availablePrizeMoney > 0) ? 'text-danger' : ''
        },
        { 
            field: 'balance', 
            headerName: intl.formatMessage({ id: 'ENTRIES.APPLYPAYMENTS.MODAL.GRID.BALANCE' }),
            width: 110,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: "right" }
        },
        { 
            field: 'apply', 
            width: 120,
            cellRenderer: params => params.node.rowPinned ? null : renderCheckBox(params, unCheckEntryPayment),
            headerComponentFramework: CheckboxHeader,
            headerComponentParams: {
                headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.APPLY" }),
                onChange: (checked) => { 
                    setCheckAll(checked)
                    updateIncludeForAllEntries(checked)
                },
                checked: checkAll
            },
            resizable: false
        },
        { 
            field: 'prizeMoneyPayment',
            headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.PRIZEMONEYPAYMENT" }),
            width: 110,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: "right" }
        },
        { 
            field: 'payment',
            headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.PAYMENT" }),
            maxWidth: 110,
            cellRenderer: params => currencyFormatter(params.value),
            editable: params => params.data.apply, // Allow edit only if apply is true
            cellStyle: params => getEditableCellStyles(params),
            valueSetter: numberValueSetter,
            flex: 2
        }
    ]

    useEffect(() => {
        if (gridRef) {
            updatePrizeMoneySpent()
        }
    }, [gridRef, autoApplyPrizeMoney, trigger])

    useEffect(() => {
        // by default check the include checkbox for all the entries
        setCheckAll(true)
    }, [gridRef])

    useEffect(() => {
        // recalculate the total every time the grid values are updated
        calculateTotals()
    }, [getRowData(gridRef)])

    // when entry include checkbox is unchecked. uncheck the header include if it is checked.
    const unCheckEntryPayment = (checked) => {
        // setForceReapplySplit(prevState => !prevState)
        if(gridRef){
            recalculate(true)
        }
        if(!checked && checkAll){
            setCheckAll(false)
        }
    }

    // calculate the total balance, prize money payment and payment
    const calculateTotals = () => {
        const rowData = getRowData(gridRef)
        let totalBalance = 0
        let totalPrizeMoneyPayment = 0
        let totalPayment = 0

        rowData.forEach(row => {           
            totalBalance = addFloatingNumbers([row.balance, totalBalance], 2)
            if (row.apply) {   
                totalPrizeMoneyPayment = addFloatingNumbers([row.prizeMoneyPayment, totalPrizeMoneyPayment], 2)
                totalPayment = addFloatingNumbers([row.payment, totalPayment], 2)
            }
        })

        gridRef?.setPinnedBottomRowData([{
            balance: totalBalance,
            prizeMoneyPayment: totalPrizeMoneyPayment,
            payment: totalPayment
        }])
    }

    const getEditableCellStyles = (params) => {
        if(params.node.rowPinned){ // bottom pinned row
            return { textAlign: "right"}
        }

        if(params.data.apply){ // payment column is editable
            return { textAlign: "right", border: '1px solid rgb(202, 202, 202)' }
        }
        
        // payment column is not editable
        return { textAlign: "right", backgroundColor: '#f4f8fb', border: '1px solid rgb(202, 202, 202)' }
    }

    return (
        <div>
            <div className="row mb-2 d-flex align-items-end">
                <label className='col col-form-label fs-5 py-1'>
                    { intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.GRID.LABEL" }) }
                </label>

                
                <label className='col col-form-label fs-5 py-1'>
                    { info.split("\n").map(i => <>{i}<br/></>) }
                </label>
               
                <div className="col d-flex flex-column">
                    <div className='d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom ps-lg-3 py-2 me-2'>
                            <input
                                className='form-check-input'
                                type="checkbox"
                                id="autoApplyPrizeMoney" 
                                checked={autoApplyPrizeMoney}
                                // Note: Remove disabled and uncomment onChange if Transfer Prize Money checkbox is enabled in future
                                // onChange={e => setAutoApplyPrizeMoney(e.target.checked)} 
                                disabled
                            />
                        </div>

                        <label 
                            className='col-form-label fs-5 py-1'
                            htmlFor="autoApplyPrizeMoney" 
                        >
                            { intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.LABEL.TRANSFERPRIZEMONEY" }) }
                        </label>
                    </div>

                    <div className='d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom ps-lg-3 py-2 me-2'>
                            <input
                                className='form-check-input'
                                type="checkbox"
                                id="autoApplyPrizeMoneyPayment" 
                                checked={autoApplyPrizeMoneyPayment}
                                onChange={e => {
                                    // Note: Remove setAutoApplyPrizeMoney if Transfer Prize Money checkbox is enabled in future
                                    setAutoApplyPrizeMoneyPayment(e.target.checked)
                                    setAutoApplyPrizeMoney(e.target.checked)
                                }} 
                            />
                        </div>

                        <label 
                            className='col-form-label fs-5 py-1'
                            htmlFor="autoApplyPrizeMoneyPayment" 
                        >
                            { intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.LABEL.APPLYPRIZEMONEYPAYMENTS" }) }
                        </label>
                    </div>
                </div>
                
                <div className='w-lg-25px form-check-sm form-check-custom ps-lg-3 py-2'>
                    <input
                        className='form-check-input'
                        type="checkbox"
                        id="entriesWithBalance" 
                        checked={entriesWithBalanceOnly}
                        onChange={e => setEntriesWithBalanceOnly(e.target.checked)} 
                    />
                </div>

                <label 
                    className='col-form-label fs-5 py-1 w-lg-200px'
                    htmlFor="entriesWithBalance"
                    data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.ENTRIESWITHBALANCEONLY"
                >
                    { intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.LABEL.ENTRIESWITHBALANCE" }) }
                </label>
            </div>
    
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact
                        onGridReady={params => setGridRef(params.api)}
                        defaultColDef={defaultColDef}
                        rowData={[]}
                        rowStyle={{ fontSize: '13px', 'paddingLeft': 0, 'paddingRight': 0}}
                        columnDefs={columnDefs} 
                        suppressScrollOnNewData={true}
                        headerHeight={getExtraNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                        stopEditingWhenCellsLoseFocus={true}
                        singleClickEdit={true}
                        getRowStyle={params => {
                            if (params.node.rowPinned) {
                                return { fontWeight: "bold" };
                            }
                        }}
                        onCellEditingStopped={() => {
                            calculateTotals() // recalculate the pinned row data
                            calculateCreditCardServiceFee() // recalculate the service fee
                            updateInfo() // update entries owed balance information
                        }}
                        onSortChanged={() => {
                            // as prize money distribution can change so recalculate
                            // recalculate(true)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}


export default EntriesGrid