import { useEffect, useState, useContext } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import axios from 'axios';

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Assets
import "flatpickr/dist/themes/material_blue.css";

// Constants
import { useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { useAppSelector } from '../../../../redux/hooks';
import { EntryIdContext } from '../../Detail';
import { useAlert } from '../../../../modules/sgl-utils/DialogsProvider';

type Props = {
    show: boolean
    handleClose: () => void
    handleSave: (value: any) => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ChangeCreditCardForm = ({ show, handleClose, handleSave }: Props) => {
    const intl = useIntl()
    const alertDialog = useAlert()
    const methods = useFormContext()
    const customer_id = useAppSelector(state => state.showCompany.company_id);
    const { entry_id } = useContext(EntryIdContext)

    const [cards, setCards] = useState<any>([])
    const [card, setCard] = useState('')
    const [cardName, setCardName] = useState('')
    const [cardNumber, setCardNumber] = useState('')
    const [cardType, setCardType] = useState('')
    const [cardExpirationDate, setCardExpirationDate] = useState('')
    const [paymentMethod, setPaymentMethod] = useState<any>({})

    useEffect(() => {
        if (show) {
            axios.post(process.env.REACT_APP_NEST_API_URL + '/entries/getAvailableCardsForEntries', {
                customer_id,
                entry_id: methods.getValues('entry.entry_id'),
                entries: [methods.getValues('entry')],
            }).then(response => {
                setCards(response.data.entryPaymentMethods)
                if(response.data.entryPaymentMethods.length === 0){
                    alertDialog({ message:'No card is available to choose from.', icon: 'comment', title: 'warning' })
                    handleClose()
                }
            })
        }
    }, [show])

    useEffect(() => {
        const selectedPaymentMethodIndex = cards.findIndex((pm: any) => {
            return pm.payment_method_id == methods.getValues('entry.secured_payment_method_id')
        })

        if (selectedPaymentMethodIndex >= 0) {
            const selectedCard = cards[selectedPaymentMethodIndex]
            onCardChange({ target: { value: selectedCard.payment_method_id } })
        }else if (cards.length > 0) {
            onCardChange({ target: { value: cards[0].payment_method_id } })
        }
    }, [cards])

    const onCardChange = (e: any) => {
        const selectedCard = cards.find((c: any) => c.payment_method_id == e.target.value) 
       
        setCard(selectedCard ? e.target.value : '')
        setCardNumber(selectedCard ? 'XXXXXXXXXX' + selectedCard['card_lastfour'] : '')
        setCardName(selectedCard ? selectedCard['name_on_card'] : '')
        setCardType(selectedCard ? selectedCard['card_type'] : '')
        setCardExpirationDate(selectedCard ? selectedCard['card_exp'] : '')
        setPaymentMethod(selectedCard ?? {})
    }

    const onSave = async (event: any) => {
        if(paymentMethod && Object.keys(paymentMethod).length){
            handleSave(paymentMethod)
            onClose()
        }
    }

    const onClose = () => {
        onCardChange({ target: { value: '' } }) // Clear state
        handleClose();
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
            show={show}
            onHide={onClose}
            onKeyPress={(event: any) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    onSave(event)
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4"></h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 fw-bold py-1' htmlFor='SELECTCC'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.CHANGECC.LABEL.SELECTCC' })}</label>
                        </div>
                        <div className='row mb-2'>
                            <div className='col'>
                                <select id="SELECTCC" className='form-select form-select-sm fs-6 min-h-20px py-1' value={card} onChange={onCardChange}>
                                    {
                                        cards.map((card: any) => {
                                            return (
                                                <option key={card.payment_method_id} value={card.payment_method_id}>{card.dropdown_label}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-175px' htmlFor='CCNUM' data-tooltip-id="ENTRIES.DETAIL.MODAL.CHANGECREDITCARD.LABEL.CREDITCARDNUMBER">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.CHANGECC.LABEL.CCNUM' })}</label>
                            <div className='col'>
                                <input
                                    id='CCNUM'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.CHANGECC.LABEL.CCNUM' })}
                                    value={cardNumber}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-175px' htmlFor='NAMEONCARD' data-tooltip-id="ENTRIES.DETAIL.MODAL.CHANGECREDITCARD.LABEL.NAMEONCARD">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.CHANGECC.LABEL.NAMEONCARD' })}</label>
                            <div className='col'>
                                <input
                                    id='NAMEONCARD'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.CHANGECC.LABEL.NAMEONCARD' })}
                                    value={cardName}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-175px' htmlFor='CARDTYPE' data-tooltip-id="ENTRIES.DETAIL.MODAL.CHANGECREDITCARD.LABEL.CARDTYPE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.CHANGECC.LABEL.CARDTYPE' })}</label>
                            <div className='col-lg-5'>
                                <input
                                    id='CARDTYPE'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.CHANGECC.LABEL.CARDTYPE' })}
                                    value={cardType}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-175px' htmlFor='EXPIRATIONDATE' data-tooltip-id="ENTRIES.DETAIL.MODAL.CHANGECREDITCARD.LABEL.EXPIRATIONDATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.CHANGECC.LABEL.EXPIRATIONDATE' })}</label>
                            <div className='col-lg-5'>
                                <PatternFormat
                                    valueIsNumericString={true}
                                    value={cardExpirationDate}
                                    id="expiration"
                                    format="##/##"
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.EXPIRATIONFORMAT' })}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type="button" className='btn btn-sm btn-dark fw-bold me-5 text-uppercase' onClick={onClose}>
                            {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={onSave} autoFocus>
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.OK' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { ChangeCreditCardForm }