import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { AddRTOForm } from '../../../pages/people/AddRTOForm';

const AddRTOMenu = ({ hasWriteAccess, addNewRecordButtonRef }: { hasWriteAccess: boolean; addNewRecordButtonRef: React.RefObject<HTMLAnchorElement> }) => {
    const intl = useIntl()
    const [showRTOLookupForm, setShowRTOLookupForm] = useState(false)
    const customer_id = useAppSelector((state) => state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const governingOrganization = useAppSelector(state => state.showCompany.governing_organization)
    const [dialogData, setDialogData] = useState({
        first_name: '',
        last_name: '',
        city: '',
        state:'',
        usef_no: '',
        search_term: '',
        company_name: ''
    })

    return (
        <>
            <div>
                <a ref={addNewRecordButtonRef} href='#' className="btn btn-icon btn-active-light me-3 px-8 py-8 add-new-record-button"
                    onClick={() => hasWriteAccess && setShowRTOLookupForm(true)}>
                    <div className="fa-item">
                        <i className={`fa fa-plus-circle fs-1 text-${hasWriteAccess ? "green" : "gray"}`}></i>
                        <br/>
                        <span className="fs-8 fw-bold">{intl.formatMessage({id: 'TOOLBAR.LABEL.ADD'})}</span>
                    </div>
                </a>
                
            </div>
            {(showRTOLookupForm) &&
                <AddRTOForm 
                    show={showRTOLookupForm} 
                    handleClose={() => setShowRTOLookupForm(false)}
                    customer_id={customer_id} 
                    show_id={currentShowID}
                    default_org={governingOrganization}
                    fieldName={'people_search'} 
                    dialogData={dialogData}    
                    setDialogData={setDialogData} 
                />
            }
        </>
    )
}

export { AddRTOMenu }
