import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import { useAppSelector } from '../../redux/hooks';
import { useFormContext } from 'react-hook-form';
import { useForm } from 'react-hook-form'
import { useOutputContext } from '../../modules/output-listing/OutputListingContext';

// Constants
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL


const NonSystemOrg = ({tabIndex=0}) => {
  const intl = useIntl();
  const methods = useFormContext()
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const containerStyle = useMemo(() => ({ width: '100%', height: '200px' }), []);
  const rowHeight = 28;
  const customerID = useAppSelector(state => state.showCompany.company_id);
  const headerHeight = 30;
  const [orgCodes, setOrgCodes] = useState([])
  
  const [personGridRef, setPersonGridRef] = useState ()
  const [rowData, setRowData] = useState([]);
  const context=useOutputContext()
  const { watch } = useFormContext()
  const nonSystemORG = watch('nonSystemORG');

  const renderCheckBox = (params) => {
      return (
          <input
              type="checkbox"
              onChange={(e) => params.node.setDataValue(params.column.colId, e.target.checked)}
              defaultChecked={params.value}
          />
      )
  }


  //Grid Classes
  const columnDefs = [
    { field: 'select', headerName: "", sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, suppressSizeToFit: true, maxWidth: 30, minWidth: 30, cellRenderer: renderCheckBox},
    { field: 'non_system_org', headerName: intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.GROUPLABEL.NONSYSTEMORG'}), sortable: false , suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'left', 'paddingLeft': 5, 'paddingRight': 5} },
  ];

 
  useEffect(() => { 
    axios.get(`${NEST_API_URL}/entries/getVerifyEntryMetaData`, {
        params: {
            customer_id: customerID,
            area: 'Advance Search'
        }
    }) // Added area because organizations are excluded on the basis of area
      .then(response => {
        let org_data = [];
        response?.data?.horseOrganizations?.map((org) => {
          let isSelected = false
          if(context?.outputAdvanceFilter && context?.outputAdvanceFilter?.nonSystemORG?.length > 0 && context?.outputAdvanceFilter.nonSystemORG.filter(systemORG => systemORG == org.code).length > 0){
            isSelected = true
          }
          org_data.push ( {select: isSelected, non_system_org: org.code} )
        })
 
        response?.data?.peopleOrganizations?.map((org) => {
          // first check if org_data already have this organization code or not
          const isFound = org_data.some(element => {
              if (element.non_system_org === org.code) {
                  return true;
              }
          })
          if(!isFound){ // if this code was not already added, then add
            let isSelected = false
            if(context?.outputAdvanceFilter && context?.outputAdvanceFilter?.nonSystemORG?.length > 0 && context?.outputAdvanceFilter.nonSystemORG.filter(systemORG => systemORG == org.code).length > 0){
              isSelected = true
            }
            org_data.push ( {select: isSelected, non_system_org: org.code } )
          }
         
        })


        setRowData(org_data);
        personGridRef?.setRowData(org_data);
      })
    .catch(error => { console.error(error); throw error; });


  },[]);

  useEffect(()=>{//When clear value button is pressed
    if(nonSystemORG?.length == 0){
      setRowData((rowData) =>
        rowData.map((data) => ({...data, select: false}))
      );
    }
  },[nonSystemORG])

  const onFirstDataRendered = useCallback((params) => {
      params.api.sizeColumnsToFit();
  }, []);
  
  const onCellEdit = useCallback (e => {
    let temp = orgCodes;
    if(e.data.select == true){
      if (orgCodes == null){
        setOrgCodes(e.data.non_system_org)
        temp.push(e.data.non_system_org)
      } else {
        setOrgCodes(orgCodes => [...orgCodes,e.data.non_system_org])
        temp.push(e.data.non_system_org)
      } 
    } else {
        setOrgCodes((current) => current.filter((data) => data !== e.data.non_system_org)
      )
      temp = temp.filter((data) => data !== e.data.non_system_org)
    }
    methods.setValue('nonSystemORG', temp,  {shouldDirty: true})
  })

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: false
    };
  }, []);

  const onGridReady = useCallback((params) => {
    // Grid Reference initialize to params.api
    setPersonGridRef (params.api)
  }, []);

  return (
    <>
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          headerHeight={headerHeight}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowData}
          rowHeight={rowHeight} 
          onFirstDataRendered={onFirstDataRendered}
          onCellValueChanged={onCellEdit}
          setGridRef={setPersonGridRef}
          onGridReady = {onGridReady}
          {...(tabIndex !== 0 && { tabIndex })} //tabIndex is set conditionally, only if a prop was passed, provided that the prop is not 0   
        ></AgGridReact>
      </div>
    </div>
    </>
  );
};

export default NonSystemOrg;
  