import {FC, useEffect, useState} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import { useAppSelector } from '../../app/redux/hooks'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

//import deMessages from './messages/de.json'
import enMessages from './messages/en.json'
//import enTooltips from './messages/en.tooltip.json'
// import esMessages from './messages/es.json'
// import frMessages from './messages/fr.json'
// import jaMessages from './messages/ja.json'
// import zhMessages from './messages/zh.json'
import { WithChildren } from '../helpers'
import axios from 'axios';

// const allMessages = {
//   de: deMessages,
//   en: enMessages,
//   es: esMessages,
//   fr: frMessages,
//   ja: jaMessages,
//   zh: zhMessages,
// }

interface Messages {
  [key: string]: string;
}

const mergeMessages = (mainMessages:Messages, tooltips:Messages) => {
  // Merge the tooltip messages into the main messages
  const mergedMessages = { ...mainMessages, ...tooltips };
  return mergedMessages;
};

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const [tooltipMessages, setTooltipMessages] = useState<Messages>({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchTooltips = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_NEST_API_URL}/common/getTooltips?customer_id=-1`);
        setTooltipMessages(response.data);
        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching tooltips:', error);
        setIsLoaded(true);
      }
    };
    fetchTooltips();
  }, []);
  
  if (!isLoaded) {
    return null;
  }

  //const messages = enMessages;//allMessages[locale]
  const messages = mergeMessages(enMessages, tooltipMessages);
  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
