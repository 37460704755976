import React, {useEffect, useState} from 'react'
import { createPortal } from 'react-dom'
import { Modal, Tab } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useFormContext } from 'react-hook-form'
import { useAlert, useConfirm } from '../../../../modules/sgl-utils/DialogsProvider'
import { useAppSelector } from '../../../../redux/hooks'
import axios from 'axios'

const modalsRoot = document.getElementById('root-modals') || document.body
 
const UpdateOfficialTypeModal = ({show, handleClose, data}) => {
    const intl = useIntl() 
    const methods = useFormContext()
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const [isJudge, setIsJudge] = useState(data.isjudge)
    const [isCourseDesigner, setIsCourseDesigner] = useState(data.iscoursedesigner)
    const customer_id = useAppSelector((state) => state.showCompany.company_id);

    useEffect(() => {
        //default selected value should be other than current type
        if(data.isjudge){
            setIsJudge(false)
            setIsCourseDesigner(true)
        }else{
            setIsJudge(true)
            setIsCourseDesigner(false)
        }
    }, [])

    const updateOfficialType = async (isJudge, isCourseDesigner) => {
        const officials = methods.getValues().officials;

        // Create a map to count occurrences of each people_id
        const peopleIdCount = officials.reduce((acc, official) => {
            acc[official.people_id] = (acc[official.people_id] || 0) + 1;
            return acc;
        }, {});

        // Check if the occurrences of data.people_id is greater than 1
        if (peopleIdCount[data.people_id] > 1) {
            alertDialog({
                message: intl.formatMessage({ id: 'SHOWS.UPDATE.OFFICIALS.TYPE.DIALOG.WARNING.SAMETYPE' })
            });
            return;
        }

        // loop through the officials array and update the selected official >>>
        for (let i = 0; i < officials.length; i++) {
            if (officials[i].people_id == data.people_id) {
                
                let role = data?.isjudge ? 'isjudge' : data?.iscoursedesigner ? 'iscoursedesigner' : ''
                let isJudgeUsed = await getClassJudgesRecordForOfficial(data.people_id, role)
                //Display confirmtion message if saved official which is used in classes >>>
                if (methods.getValues().show.show_id && officials[i].sgl_id && isJudgeUsed) {
                    const choice = await confirmDialog({ message: intl.formatMessage({ id: 'SHOWS.UPDATE.OFFICIALS.TYPE.DIALOG.WARNING' }) });
                    if (!choice) { continue; }
                }
                //Display confirmtion message if saved official which is used in classes <<<
                officials[i].isjudge = isJudge;
                officials[i].iscoursedesigner = isCourseDesigner;
            }
        }
        // loop through the officials array and update the selected official <<<
        methods.setValue("officials", officials, { shouldDirty: true });
        handleClose();
    };

    const getClassJudgesRecordForOfficial = async(people_id, role = '') => {
        let isJudgeUsed = false
        //Web request to check if official is used in any class >>>
        await axios.get(`${process.env.REACT_APP_NEST_API_URL}/people/getClassJudgesRecordForOfficial?show_id=${methods.getValues().show.show_id}&customer_id=${customer_id}&people_id=${people_id}&role=${role}`)
        .then(response => {
           if(response.data.classesCount > 0){
                isJudgeUsed = true 
           }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        //Web request to check if official is used in any class <<<
        return isJudgeUsed
    }
    

    return createPortal(
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
                show={show}
                onHide={handleClose}
                onKeyDown={(event) => { //replaced onKeyPress with onKeyDown
                    let isButtonFocused = false
                    let activeElement = document.activeElement //gets the currently focussed element
                    if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                        isButtonFocused = true;
                    }
                    if (event.key === "Enter" && !isButtonFocused) {
                        updateOfficialType()
                    }
                }}
            >
                <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
                <div className='modal-header py-0 px-4'>
                    <h2 className="fs-4">{intl.formatMessage({ id: 'SHOWS.UPDATE.OFFICIALS.TYPE.DIALOG.HEADING' })}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
    
                <div className='modal-body py-3 px-4'>
    
                    {/* Official Type */}
                    <div className="w-500px d-flex">
                        <label className="fw-bolder fs-6 me-5 mt-2" data-tooltip-id="SHOW.DETAIL.MODAL.UPDATEOFFICIALTYPE.LABEL.TYPE">{intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.RADIO.BUTTON.LABEL.OFFICIAL.TYPE' })}</label>

                        {/* Judge Checkbox */}
                        <label className="mt-checkbox mt-checkbox-outline me-8 align-items-center d-flex w-50px mt-2" data-tooltip-id="SHOW.DETAIL.MODAL.UPDATEOFFICIALTYPE.LABEL.JUDGE">
                        <input
                            type="radio"
                            checked={isJudge}
                            onChange={() => {
                                setIsJudge(!isJudge)
                                setIsCourseDesigner(!isCourseDesigner)
                            }}
                            autoFocus
                            tabIndex={1}
                        />&nbsp;<span className="fw-bolder fs-6 ms-1">{intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.RADIO.BUTTON.LABEL.JUDGE' })}</span>
                        </label>

                        {/* Course Designer Checkbox */}
                        <label className="mt-checkbox mt-checkbox-outline align-items-center d-flex w-200px mt-2" data-tooltip-id="SHOW.DETAIL.MODAL.UPDATEOFFICIALTYPE.LABEL.COURSEDESIGNER">
                        <input
                            type="radio"
                            checked={isCourseDesigner}
                            className='add-search-radio'
                            onChange={() => {
                                setIsJudge(!isJudge)
                                setIsCourseDesigner(!isCourseDesigner)
                            }}
                            tabIndex={2}
                        />&nbsp;<span className="fw-bolder fs-6 ms-1 add-search-label">{intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.RADIO.BUTTON.LABEL.COURSE.DESIGNER' })}</span>
                        </label>
                    </div>       

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={handleClose} tabIndex={3}>
                            {intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.BUTTON.CANCEL' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={() => updateOfficialType(isJudge, isCourseDesigner)} tabIndex={4}>
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.BUTTON.OK' })}
                        </button>
                    </div>
                </div>
      </Modal>
      ,modalsRoot
    )
}

export default UpdateOfficialTypeModal