import { useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useFormContext, Controller } from "react-hook-form";
import { KTSVG } from '../../../../../_metronic/helpers'
import "flatpickr/dist/themes/material_blue.css";
import VISALogo from '../../../../../_metronic/assets/CardImages/Card_Visa.jpeg';
import AMEXLogo from '../../../../../_metronic/assets/CardImages/Card_Amex.jpeg';
import DISCOVERLogo from '../../../../../_metronic/assets/CardImages/Card_Discover.jpeg';
import MCLogo from '../../../../../_metronic/assets/CardImages/Card_MC.jpeg';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import { useAppSelector } from '../../../../redux/hooks';
import axios from 'axios';
import { useAlert, useConfirm, useLoadingOverlay } from '../../../../modules/sgl-utils/DialogsProvider';
import { EntryIdContext } from '../../Detail';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { addFloatingNumbers, checkSocialOrFederalId, getArrayFromObject } from '../../../../modules/sgl-utils/SglFunctions';

type Props = {
    show: boolean
    handleClose: () => void    
    rowData: any
    getSubmitData: any
    getMembershipsUpdatedRecords:any
    setLockPrizeMoneyAction:any
}

const modalsRoot = document.getElementById('root-modals') || document.body
const Buffer = require("buffer").Buffer;

const initialState = {
    creditCardExp: ""
}

const AddPaymentForm = ({ show, handleClose, getSubmitData, getMembershipsUpdatedRecords, setLockPrizeMoneyAction }: Props) => {
    const intl = useIntl()
    const loadingOverlay = useLoadingOverlay()
    const currencyFormatter = useCurrencyFormatter()
    const currencyCode = useAppSelector(state => state.showCompany.currency_code) 

    const methods = useFormContext();
    const { watch, register, setValue } = methods
    const alertDialog = useAlert();
    const watchCardType = watch("cardType")
    const [entryPaymentMethods, setEntryPaymentMethods] = useState<any>([])  
    const [paymentTypes, setPaymentTypes] = useState<any>([])
    const [listOfStates, setListOfStates] = useState<any>([])
    const [listOfCountries, setListOfCountries] = useState<any>([])
    const [isDisabledForNon_Card, setisDisabledForNon_Card] = useState<boolean>(true)
    const [isDisabledForCard, setIsDisabledForCard] = useState<boolean>(false)
    const [isEnabledForOtherCardType, setisEnabledForOtherCardType] = useState<boolean>(false)
    const [hasCiTWarning, setHasCiTWarning] = useState<boolean>(false)
    const [isDisabledForCompanyGateway, setIsDisabledForCompanyGateway] = useState<boolean>(true)
    const [refCodeLabel, setRefCodeLabel] = useState<string>("REFCODE")
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id)    
    const {entry_id, setEntryId} = useContext(EntryIdContext)    
    const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
    const confirmDialog = useConfirm ()
    const [paymentData, setPaymentData] = useState(initialState)
    // Prize Money state variables
    const [unappliedPrizeMoney, setUnappliedPrizeMoney] = useState<number>(0) // unapplied prize money for entry
    const [unusedPrizeMoney, setUnusedPrizeMoney] = useState<number>(0) // unused prize money (unused prize money present in A/R + unapplied prize money if Transfer checkboxis checked)
    const [totalPrizeMoney, setTotalPrizeMoney] = useState<number>(0) // unapplied prize money + unused prize money
    const [useablePrizeMoney, setUseablePrizeMoney] = useState<number>(0) // unused prize money amount that can be used for entry payment
    const [unuseablePrizeMoney, setUnuseablePrizeMoney] = useState(0) // prize money amount that can't be used bcz of the debit Account Register or any other reason
    const [balanceAfterPrizeMoney, setBalanceAfterPrizeMoney] = useState<number>(0) // entry balance after prize money payment is applied
    const [appliedPrizeMoneyPayment, setAppliedPrizeMoneyPayment] = useState<number>(0) // prize money amount used for entry payment
    const [prizePaymentError, setPrizePaymentError] = useState('') // errors bcz of which prize money payment can't be applied to entry
    const [totalWithholdingTaxDeducted, setTotalWithholdingTaxDeducted] = useState(0) // total withholding tax deducted when prize money will be applied
    const [isPrizeMoneyPaymentApplicable, setIsPrizeMoneyPaymentApplicable] = useState(false) // flag to check if we can apply prize money payment to entry
    const [transferredCredit, setTransferredCredit] = useState(0) // the credit that will be transferred from PMR/RP to entry
    const [prizeMoneyPrefs, setPrizeMoneyPrefs] = useState({
        PMR_SSN_Required: false,
        Ignore_PMR_RP_Mismatch: false
    })
    const [transferableAmounts, setTransferableAmounts] = useState({
        totalCredit: 0,
        // rp data
        rpCredit: 0, 
        rpBalance: 0,
        rpErrors: [],

        //pmr data
        pmrCredit: 0,
        pmrBalance: 0,
        pmrErrors: []
        
    })
    const [moveFrom, setMoveFrom] = useState('') // RP or PMR
    const [moveCreditFromAccountError, setMoveCreditFromAccountError] = useState('')
    const [prizeMoneyError, setPrizeMoneyError] = useState<string>('')
    const [disablePMRMoveCreditFromAccount, setDisablePMRMoveCreditFromAccount] = useState(false)
    const [disableRPMoveCreditFromAccount, setDisableRPMoveCreditFromAccount] = useState(false)
    // error/ warnings because of which prize money paymet can't be applied
    const [disablePMRMoveCreditFromAccountReason, setDisablePMRMoveCreditFromAccountReason] = useState<any>([])
    const [disableRPMoveCreditFromAccountReason, setDisableRPMoveCreditFromAccountReason] = useState<any>([])
    // New Structure Change: Payment Type Id of Selected Payment Type
    const [paymentType, setPaymentType] = useState<string>("")
    const [paymentTypeId, setPaymentTypeId] = useState(0)
    // enable negative amount for payment category 'Miscellaneous'
    const [allowNegativeAmount, setAllowNegativeAmount] = useState(false)
    const amountRef = useRef<HTMLInputElement | null>(null);

    const handleCustomeClose = () => {
        handleClose();
        //clear last payment amount info entered        
        methods.setValue('paymentAmount', 0, {shouldDirty: true}) // clear amount
        methods.setValue('paymentType',"", {shouldDirty: true}) // clear payment type
        methods.setValue('moveCreditFromAccount', false, { shouldDirty: true }) // clear the Transfer credit from account checkbox
        cc_initPaymentVars() // clear all info
    }
    
    function setPaymentMethodRelatedData(selectedPaymentMethod: any) {  
        setHasCiTWarning( !selectedPaymentMethod['has_cit'] )
        methods.setValue('forceCIT', selectedPaymentMethod['has_cit'], { shouldDirty: true })
        methods.setValue("creditCardNum",selectedPaymentMethod['card_lastfour'], {shouldDirty: true}) //[Payment_Method]Card_LastFour
        setPaymentData(prevPaymentData => ({ ...prevPaymentData, creditCardExp: selectedPaymentMethod['card_exp'] }))             
        methods.setValue("creditCardCVV",selectedPaymentMethod['card_securitycode'], {shouldDirty: true})
        methods.setValue("creditCardName",selectedPaymentMethod['name_on_card'], {shouldDirty: true})
        methods.setValue("creditCardName_First",selectedPaymentMethod['card_first'], {shouldDirty: true})
        methods.setValue("creditCardName_Last",selectedPaymentMethod['card_last'], {shouldDirty: true})
        methods.setValue("email",selectedPaymentMethod['email'], {shouldDirty: true})
        methods.setValue("addr1",selectedPaymentMethod['address_1'], {shouldDirty: true})
        methods.setValue("addr2",selectedPaymentMethod['address_2'], {shouldDirty: true})
        methods.setValue("cityname",selectedPaymentMethod['city'], {shouldDirty: true})
        methods.setValue("state",selectedPaymentMethod['state'], {shouldDirty: true})
        methods.setValue("zip",selectedPaymentMethod['zip'], {shouldDirty: true})
        methods.setValue("country",selectedPaymentMethod['country'], {shouldDirty: true})
        methods.setValue("phoneNumber",selectedPaymentMethod['phone'], {shouldDirty: true})
        methods.setValue("peopleID",selectedPaymentMethod['people_id'])
        methods.setValue("paymentMethodID",selectedPaymentMethod['payment_method_id'], {shouldDirty: true})
        methods.setValue("cardType",selectedPaymentMethod['card_type'], {shouldDirty: true})
        methods.setValue("CardOwnerID",selectedPaymentMethod['people_id'], {shouldDirty: true})
        methods.setValue("RetainCard",selectedPaymentMethod['retained'], {shouldDirty: true})        
    }

    function cc_initPaymentVars () {    
        setPaymentData(prevPaymentData => ({ ...prevPaymentData, creditCardExp: '' }))
        methods.setValue("updateRTO",0, {shouldDirty: true})
        methods.setValue("CardUpdated",false, {shouldDirty: true}) // check if user updated any stored info for card See cc_CardDataUpdated 
        methods.setValue("RetainCard",true, {shouldDirty: true})    
        methods.setValue("CardOwner","", {shouldDirty: true})
        methods.setValue("CardOwnerID","", {shouldDirty: true})        
        methods.setValue("base64CompleteCardNumber","", {shouldDirty: true})
        methods.setValue("creditCardNum","", {shouldDirty: true})
        methods.setValue("creditCardExp","", {shouldDirty: true})
        methods.setValue("creditCardCVV","", {shouldDirty: true})
        methods.setValue("creditCardName","", {shouldDirty: true})
        methods.setValue("creditCardName_First","", {shouldDirty: true})
        methods.setValue("creditCardName_Last","", {shouldDirty: true})
        methods.setValue("newPaymentCode","", {shouldDirty: true}) //ref code
        methods.setValue("newPaymentDescription","", {shouldDirty: true})
        methods.setValue("cardType","", {shouldDirty: true})
        methods.setValue("refCode","", {shouldDirty: true})        
        methods.setValue("email","", {shouldDirty: true})
        methods.setValue("addr1","", {shouldDirty: true})
        methods.setValue("addr2","", {shouldDirty: true})
        methods.setValue("cityname","", {shouldDirty: true})
        methods.setValue("state","", {shouldDirty: true})
        methods.setValue("country","", {shouldDirty: true})
        methods.setValue("bankNorthStateName","", {shouldDirty: true})
        methods.setValue("bankNorthCountryNames","", {shouldDirty: true})
        methods.setValue("zip","", {shouldDirty: true})
        methods.setValue("country_code","", {shouldDirty: true})
        methods.setValue("cardTypeIcon","", {shouldDirty: true})
        methods.setValue("phoneNumber","", {shouldDirty: true})
        methods.setValue("peopleID","", {shouldDirty: true})        
        methods.setValue("paymentMethodID", 0, {shouldDirty: true})
        methods.setValue("creditCardServiceFeeAmount","", {shouldDirty: true})
        methods.setValue("totalTransactionAmount","", {shouldDirty: true})
        methods.setValue('forceCIT', false, { shouldDirty: true })
    }    

    const addPaymentForEntry = async (data :any, customer_id: number, entry_id: number) => {
        try {
            const result = await axios.post(`${NEST_API_URL}/entries/addPaymentForEntry`, {
                entry_id: entry_id,  
                customer_id: customer_id,              
                data: data,
                paymentAmount: data.paymentAmount
            })
                      
            if(result.data.success) {                                    
                methods.setValue("paymentxEntries", result.data.paymentxEntries)
                if(entry_id == 0 ){
                    window.history.replaceState({}, '', `${result.data.entryData.entry.entry_id}`);
                    setEntryId(result.data.entryData.entry.entry_id)
                }
                methods.reset(result.data.entryData)
                handleCustomeClose(); // close dialog after payment success only  
                setLockPrizeMoneyAction(false) // enable the prize money action drop down  
                
                if(result.data.errorMessage){
                    alertDialog({title: 'Error', message: result.data.errorMessage, icon: "stop" })
                }
            }
        } catch (reason: any) {
            if (reason?.response?.data?.statusCode === 403) {
                alertDialog({ title: 'Forbidden Access', message: 'You have insufficient privileges to perform this action.', icon: "stop" })
            } else {
                alertDialog({ title: 'Error', message: reason?.response?.data?.error ?? 'Something went wrong..' })
            }
        } 
    }
    
    // !4D -> [Dialogs].NewEntryPayment.Button_OK
    const handleSave = async () => {
        getMembershipsUpdatedRecords()
        let form_data = methods.getValues()
        let submmittedData = getSubmitData(form_data)
        let data = {...submmittedData, ...paymentData, moveFrom, paymentType, paymentTypeId }
        var entry = methods.getValues('entry')
        data.entry = entry

        const selectedCardId = Number(methods.getValues('selectedCardId'))
        data.selectedCardId = isNaN(selectedCardId) ? 0 : selectedCardId
        data.peopleID = methods.getValues('peopleID')
        data.RetainCard = methods.getValues('RetainCard')        
        data.updateRTO = methods.getValues('updateRTO')
        data.state = methods.getValues('state')
        data.country = methods.getValues('country')
        data.phoneNumber = methods.getValues('phoneNumber')
        data.cityname = methods.getValues('cityname')    
        
        let paymentAmount = Number(data.paymentAmount)
        if (isNaN(paymentAmount) ) {
            paymentAmount = 0
        }
        data.paymentAmount = paymentAmount  

        data.autoApplyPrizeMoney = unappliedPrizeMoney > 0 ? data.autoApplyPrizeMoney : false // if there is unapplied prize money and Transfer checkbox is checked then true otherwise false
        data.autoApplyPrizeMoneyPayment = appliedPrizeMoneyPayment > 0 ? data.applyPrizeMoneyPayment : false
        data.prizeMoneyAmountToUse = data.applyPrizeMoneyPayment ? appliedPrizeMoneyPayment : 0 // if Apply Prize Money Payment checkbox is checked
        data.moveCreditFromAccount = data.moveCreditFromAccount && transferredCredit > 0 ? data.moveCreditFromAccount : false // move the credit from account only if Transfer Credit From Account checkbox is checked and there is some available credit to be transferred
        data.creditTransferred = data.moveCreditFromAccount ? transferredCredit : 0 // amount that will be transferred from PMR/RP to entry
        
        // Use only amount that will make balance 0 if unapplied prize money is greater than entry balance
        if (data.prizeMoneyAmountToUse >= entry.balance) {
            data.prizeMoneyAmountToUse = entry.balance
        }

        // Whether user has added payment or selected only auto apply prize money option
        data.addPayment = data.paymentType ? true : false
        
        if(data.base64CompleteCardNumber){                
            let encodedNumber = (Number(data.base64CompleteCardNumber) * 2).toString()
            encodedNumber = Buffer.from(encodedNumber).toString('base64');
            data.base64CompleteCardNumber = encodedNumber
        }   

        const { autoApplyPrizeMoney, applyPrizeMoneyPayment, moveCreditFromAccount } = data
        let error = ''   
        let totalPaymentAmount = paymentAmount

        if (!autoApplyPrizeMoney && !applyPrizeMoneyPayment && !moveCreditFromAccount) { // Ignore validations if prize money is transferred to A/R or it is used for entry payments or both
            if(!data.paymentType) {// Payment type is not set
                error = "A payment type is required."
            }
        } else {
            totalPaymentAmount += data.prizeMoneyAmountToUse // Add only that much unapplied prize money to payment amount that is necessary to make entry balance 0
        }

        if (data.paymentType && !paymentAmount) {
            error = 'You must enter a valid amount.'
        }

        if (data.paymentType === 'Move Credit From Account') {
            if (!['RP', 'PMR'].includes(moveFrom)) {
                error = 'Please select account from where you want to move credit.'
            } else if (paymentAmount + data.prizeMoneyAmountToUse > entry.balance) {
                error = 'You cannot overpay entry with credit transfer.'
            } else {
                if (moveFrom === 'RP' && (paymentAmount > Math.abs(transferableAmounts.rpCredit) || transferableAmounts.rpCredit > 0)) { // Has Debit Balance or Trying to transfer amount greater than available balance for RP
                    error = 'The selected account has insufficient credit for the payment.'
                } else if (moveFrom === 'PMR' && (paymentAmount > Math.abs(transferableAmounts.pmrCredit) || transferableAmounts.pmrCredit > 0)) { // Has Debit Balance or Trying to transfer amount greater than available balance for PMR
                    error = 'The selected account has insufficient credit for the payment.'
                }
            }      
        }

        if (data.moveCreditFromAccount) {
            if (!['RP', 'PMR'].includes(moveFrom)) {
                error = 'Please select account from where you want to move credit.'
            } else if (transferredCredit + data.prizeMoneyAmountToUse > entry.balance) {
                error = 'You cannot overpay entry with credit transfer.'
            } else {
                if (moveFrom === 'RP' && (transferredCredit > Math.abs(transferableAmounts.rpCredit) || transferableAmounts.rpCredit > 0)) { // Has Debit Balance or Trying to transfer amount greater than available balance for RP
                    error = 'The selected account has insufficient credit for the payment.'
                } else if (moveFrom === 'PMR' && (transferredCredit > Math.abs(transferableAmounts.pmrCredit) || transferableAmounts.pmrCredit > 0)) { // Has Debit Balance or Trying to transfer amount greater than available balance for PMR
                    error = 'The selected account has insufficient credit for the payment.'
                }
            }      
        }

        if (error) {
            alertDialog({ message: error, title: 'Error' })
            return
        }

        // Is Payment added manually? Check if payment amount will overpay the entry
        if(data.addPayment && totalPaymentAmount > entry.balance){
            const overpaidAmount = Math.abs(entry.balance - totalPaymentAmount)
            if (!await confirmDialog ({ message: `The Entry will be overpaid and will have a Credit balance of $${String(overpaidAmount.toFixed(2))}. Do you wish to proceed?` }) ) {
                return
            }     
        }
        
        //validate add payment and check with errors and confirmations 
        try {
            loadingOverlay({ show: true })
            const response = await axios.post(`${NEST_API_URL}/entries/validatePaymentForEntries`, {
                entry_ids: [methods.getValues('entry.entry_id')],  
                customer_id: customer_id,              
                data: data
            })
        
            let userChoice = true
            if (response.data.message) {
                loadingOverlay({ show: false })
                userChoice = await confirmDialog ({ message: response.data.message })
                loadingOverlay({ show: true })
            }        
            
            if (userChoice) {
                await addPaymentForEntry (data, customer_id, methods.getValues('entry.entry_id'))
            }                       
        } catch (reason: any) {  
            if (reason?.response?.data?.statusCode === 403) {
                alertDialog({ title: 'Forbidden Access', message: 'You have insufficient privileges to perform this action.', icon: "stop" })
            } else {
                alertDialog({ title: 'Error', message: reason?.response?.data?.error ?? 'Something went wrong..' })
            }
        } finally {
            loadingOverlay({ show: false })
        }
    }

    useEffect(() => {
        if(currentShowID){
            const getMetadata = async () => {
                loadingOverlay({ show: true, message: 'Getting Metadata' })
                const response = await axios.post(`${NEST_API_URL}/payments/addPaymentMetadata`, {
                    customer_id,
                    show_id: currentShowID,
                    entry_id: methods.getValues('entry.entry_id'),
                    entry: methods.getValues('entry'),
                    // pass pmr and rp so that when pmr/rp ssn or federal_id is updated from entry it is passed to BE as it is updated on FE but not on BE
                    prizemoneyrecipient: methods.getValues(`linked_people[${methods.getValues('entry.prizemoneyrecipient_id')}].people_data`),
                    responsibleparty: methods.getValues(`linked_people[${methods.getValues('entry.responsibleparty_id')}].people_data`)
                })

                // Calculate unapplied prize money and withholding tax deducted
                let _unappliedPrizeMoney = 0
                let _totalWithholdingTaxDeducted = 0
                const trips = methods.getValues('trips')
                for (const trip of trips) {
                    if (!trip.prize_money_spent) {
                        // calculate the withholding tax applied to the trip >>>
                        let isWithHoldingTaxApplicable = methods.getValues('entryWithholdingTaxApplicable')
                        let withholdingTaxRate = response.data.withHoldingTaxRate
                        if(isWithHoldingTaxApplicable){ 
                            // calculate the withHolding TaxAmount
                            let withHoldingTaxAmount = (withholdingTaxRate/100) * trip.total_prize_money
            
                            //add it in _totalWithholdingTaxDeducted
                            if (withHoldingTaxAmount > 0) { 
                                _totalWithholdingTaxDeducted = addFloatingNumbers([_totalWithholdingTaxDeducted, withHoldingTaxAmount], 2)
                            }
                        }
                        // calculate the withholding tax applied to the trip <<<

                        _unappliedPrizeMoney += trip.total_prize_money
                    }
                }

                // update the total withholding tax applied to all the trips
                setTotalWithholdingTaxDeducted(_totalWithholdingTaxDeducted)

                // if prize money is rolledback and add payment is called (entry is not saved)
                if(methods.getValues('prize_money_action') == 'Rollback'){
                    // calculate pmr's A/R balance after rollback as updated A/R changes are not safed in db yet
                    let updatedAccountRegisters = methods.getValues('updated_account_registers') ?? []
                    // get newly created AR only and calculate their amount
                    updatedAccountRegisters = updatedAccountRegisters.filter((ar:any) => ar.accountingregister_id < 0)
                    let newARAmount = addFloatingNumbers(getArrayFromObject('amount', updatedAccountRegisters), 2)
                    // update the pmr balance by adding the newly created A/R amount
                    response.data.transferableAmounts.pmrBalance = addFloatingNumbers([response.data.transferableAmounts.pmrBalance, newARAmount], 2)
                    // update the availableAppliedPrizeMoney to zero as the prize money moved to A/R is already rolledback. So, it can't be used for entry payments.
                    response.data.availableAppliedPrizeMoney = 0
                }else{
                    // remove response.data.availableAppliedPrizeMoney from the pmr balance 
                    response.data.transferableAmounts.pmrBalance = addFloatingNumbers([response.data.transferableAmounts.pmrBalance, response.data.availableAppliedPrizeMoney], 2)
                }

                // calculate the useable prize money by removing the withholding tax from unapplied prize money
                let _useablePrizeMoney = addFloatingNumbers([_unappliedPrizeMoney, -1*_totalWithholdingTaxDeducted, response.data.availableAppliedPrizeMoney], 2)
                let _totalPrizeMoney =_useablePrizeMoney

                // calculate the prize money amount that can't be use for entry payment
                let _unUseablePrizeMoney = 0
                if(response.data.transferableAmounts.pmrBalance >= 0 && _useablePrizeMoney > 0){
                    // If pmrBalance is debit and we have some useablePrizeMoney then
                    if(response.data.transferableAmounts.pmrBalance >= _useablePrizeMoney){
                        // Case 1: pmr debit balance is more then useablePrizeMoney then no prize money amount can be used
                        _unUseablePrizeMoney = _useablePrizeMoney
                    }else{
                        /* Case 2: pmr debit balance is less then useablePrizeMoney then remove the debit account register amount from useablePrizeMoney
                        remaining amount is useable. */
                        _unUseablePrizeMoney = response.data.transferableAmounts.pmrBalance
                    }

                }else if(_useablePrizeMoney > 0){
                    // If pmrBalance is credit and we have some useablePrizeMoney then
                    // if(useablePrizeMoney > -1*response.data.transferableAmounts.pmrBalance){
                    //     // If useablePrizeMoney is more then the pmr balance then prize money amount equal to pmr balance can be used 
                    //     _unUseablePrizeMoney = addFloatingNumbers([useablePrizeMoney, response.data.transferableAmounts.pmrBalance], 2)
                    // }
                }

                _useablePrizeMoney = addFloatingNumbers([_useablePrizeMoney, -1*_unUseablePrizeMoney])

                // Get system preference for auto apply prize money and on the Transfer/ Apply checkbox based on it
                const autoTransferPrizeMoney = _unappliedPrizeMoney > 0 ? response.data.autoApplyPrizeMoney : false
                const autoApplyPrizeMoneyPayment = _useablePrizeMoney > 0 && methods.getValues('entry.balance') > 0 ? response.data.autoApplyPrizeMoney : false
                methods.setValue('autoApplyPrizeMoney', autoTransferPrizeMoney, { shouldDirty: true })
                methods.setValue('applyPrizeMoneyPayment', autoApplyPrizeMoneyPayment, { shouldDirty: true })

                // update state variables
                setUnappliedPrizeMoney(_unappliedPrizeMoney)
                setUnusedPrizeMoney(autoTransferPrizeMoney ? _totalPrizeMoney : response.data.availableAppliedPrizeMoney)
                setTotalPrizeMoney(_totalPrizeMoney)
                setUseablePrizeMoney(_useablePrizeMoney)
                setUnuseablePrizeMoney(_unUseablePrizeMoney)
                setPrizeMoneyPrefs(response.data.prizeMoneyPrefs)

                setListOfCountries(response.data.countries)
                setListOfStates(response.data.states)      
                setPaymentTypes (response.data.paymentTypes)
                setTransferableAmounts(response.data.transferableAmounts)
                methods.setValue("paymentGatewayNameToUse", response?.data?.paymentGateway?.name)
                loadingOverlay({ show: false, message: 'Getting Metadata' })   
            }
            getMetadata()
        }
        setEntryPaymentMethods([]) // clear cards info on page load         
    }, [currentShowID, methods.getValues('entryWithholdingTaxApplicable')])

    useEffect(() => {
        recalculatePrizeMoneyApplied()
    }, [methods.watch('autoApplyPrizeMoney'), methods.watch('applyPrizeMoneyPayment'), methods.watch('moveCreditFromAccount'), unappliedPrizeMoney, unusedPrizeMoney, unuseablePrizeMoney, totalWithholdingTaxDeducted, transferableAmounts, prizeMoneyPrefs, moveFrom])


    useEffect(() => { // Set default payment method data in fields
        const securedPaymentMethodId = methods.getValues('entry.secured_payment_method_id')
        handleCreditCardChange(securedPaymentMethodId)
    }, [entryPaymentMethods])

    useEffect(() => {

        // check if we should disable PMR move credit from account 
        let disablePMR = false
        let pmrDisableReason:any = []

        // pmr not found
        if(!methods.getValues(`linked_people[${methods.getValues('entry.prizemoneyrecipient_id')}].people_data`)){
            pmrDisableReason.push('PMR not found.')
        }

        if (transferableAmounts?.pmrErrors?.length > 0){
            // PMR cannot be used bcz of 'Credit on hold', 'Taxform Missing', 'Pending Account Balance', 'SSN/Fid Missing'
            pmrDisableReason.push(...transferableAmounts.pmrErrors)
        }
        
        if(!prizeMoneyPrefs?.Ignore_PMR_RP_Mismatch && methods.watch('entry.responsibleparty_id') != methods.watch('entry.prizemoneyrecipient_id')){
            // Ignore_PMR_RP_Mismatch is false and pmr is not same rp
            disablePMR = true
            pmrDisableReason.push(intl.formatMessage({ id: 'ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PAYMENT.ERROR.PMR.RP.MISMATCH' }))
        }

        // check if we should disable RP move credit from account 
        let disableRP = false
        let rpDisableReason:any = []

        // rp not found
        if(!methods.getValues(`linked_people[${methods.getValues('entry.responsibleparty_id')}].people_data`)){
            rpDisableReason.push('RP not found.')
        }

        if (transferableAmounts?.rpErrors?.length > 0){
            // RP cannot be used bcz of 'Credit on hold', 'Taxform Missing', 'Pending Account Balance', 'SSN/Fid Missing'
            rpDisableReason.push(...transferableAmounts.rpErrors)
        }
        
        // update state variables
        methods.getValues('moveCreditFromAccount') ? setDisablePMRMoveCreditFromAccount(disablePMR) : setDisablePMRMoveCreditFromAccount(true)
        setDisablePMRMoveCreditFromAccountReason(pmrDisableReason)
        setDisableRPMoveCreditFromAccountReason(rpDisableReason)
        
    }, [prizeMoneyPrefs, methods.watch('entry.responsibleparty_id'), methods.watch('entry.prizemoneyrecipient_id'), transferableAmounts])

    useEffect(() => {
        // Note: Remove this useEffect if Transfer Prize Money checkbox is enabled in future
        methods.setValue('autoApplyPrizeMoney', methods.watch('applyPrizeMoneyPayment'))
    }, [methods.watch('applyPrizeMoneyPayment')])

    useEffect(() => {
        // handle which account will transfer the amount to the entry
        let _moveFrom = 'RP'
        if(transferableAmounts.rpCredit >= 0 && transferableAmounts.pmrCredit < 0 && !disablePMRMoveCreditFromAccount){
            _moveFrom = 'PMR'
        }
        setMoveFrom(_moveFrom)

    }, [transferableAmounts, disablePMRMoveCreditFromAccount])

    useEffect(() => {
        if(methods.getValues('moveCreditFromAccount')){
            setDisableRPMoveCreditFromAccount(false)
            if(!prizeMoneyPrefs?.Ignore_PMR_RP_Mismatch && methods.watch('entry.responsibleparty_id') != methods.watch('entry.prizemoneyrecipient_id')){
                setDisablePMRMoveCreditFromAccount(true)
            }else{
                setDisablePMRMoveCreditFromAccount(false)
            }
        }else{
            setDisablePMRMoveCreditFromAccount(true)
            setDisableRPMoveCreditFromAccount(true)
        }
    }, [methods.getValues('moveCreditFromAccount')])

    const onCreditCardNumberChange = async () => {

        let card_num = methods.getValues('creditCardNum')
        if(isNaN(card_num)){
            card_num = ''
        }
        methods.setValue("base64CompleteCardNumber", card_num, {shouldDirty: true})
        var replaced = card_num.replace(/.(?=.{4,}$)/g, '*'); 

        methods.setValue('creditCardNum', replaced, {shouldDirty: true})
        
    }

    const onCreditCardNumberFocus = async () => {

        let card_num = methods.getValues('base64CompleteCardNumber')
        methods.setValue("creditCardNum", card_num, {shouldDirty: true})
        
    }

    const onPaymentTypeChange = async (e: any) => {
        const selectedPaymentType = e.target.value

        let foundPaymentType = paymentTypes.find((pt: any) => pt.label === selectedPaymentType)
        if (foundPaymentType) { // Should always be available.
            setPaymentTypeId(foundPaymentType.sgl_id)
            // allow negative payment
            setAllowNegativeAmount(foundPaymentType?.PaymentTypeCategory?.name == 'Miscellaneous')
        }else{
            //don't allow negative payment
            setAllowNegativeAmount(false)
        }

        cc_initPaymentVars ();  
        setPaymentType(selectedPaymentType);
        setIsDisabledForCard(false) // disable all
        setisDisabledForNon_Card(false) //disable all
        setisEnabledForOtherCardType (false) //disable all
        setHasCiTWarning(false)
        
        if(selectedPaymentType == methods.getValues("paymentGatewayNameToUse") ){
           
            setIsDisabledForCard(true) // enable cards objects
            setIsDisabledForCompanyGateway(false)
            if (entryPaymentMethods.length === 0) {
                const response = await axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/getAvailableCardsForEntries', {
                    customer_id,
                    entry_id: methods.getValues('entry.entry_id'),
                    show_id: currentShowID,
                    entries: [methods.getValues('entry')]
                })             
                                 
                setEntryPaymentMethods(response.data.entryPaymentMethods)
                methods.setValue("entSecuredPaymentMethodID", response.data.entSecuredPaymentMethodID, { shouldDirty: true })
            }
        } else {
            setisDisabledForNon_Card(true) // enable non-cards objects
            setEntryPaymentMethods([]) // clear cards info
            setIsDisabledForCompanyGateway(true)
        }

        setValue('paymentType', selectedPaymentType, { shouldDirty: true })       

        if (selectedPaymentType == methods.getValues("paymentGatewayNameToUse")) {
            setRefCodeLabel("CCAUTHCODE")
        } else if (foundPaymentType?.PaymentTypeCategory?.name == 'Check') {
            setRefCodeLabel("CHECKNUM")
        }else{
            setRefCodeLabel("REFCODE")
        }    
        
        if(e.target.value){
            amountRef?.current?.focus()
        }
    }
    const handleCreditCardChange = (value: string) => {
        setHasCiTWarning(false)

        let selectedPaymentMethod = null
        let selectedCardId = Number(value)
        if(selectedCardId > 0){
            //find matching payment method info to fill in dialog and display to user
            selectedPaymentMethod = entryPaymentMethods.find((pm: any) => pm.payment_method_id == selectedCardId)
            methods.setValue('selectedCardId', selectedPaymentMethod ? selectedCardId : '0', { shouldDirty: true })
        } else {
            methods.setValue('selectedCardId', isNaN(selectedCardId) ? '0' : value, { shouldDirty: true })
        }

        setisEnabledForOtherCardType (false)
        
        if(!selectedCardId || selectedCardId === -1){
            cc_initPaymentVars ();   
            
            if(selectedCardId === -1){
                setisEnabledForOtherCardType (true)
            }         
        } else if (selectedPaymentMethod) { 
            setPaymentMethodRelatedData(selectedPaymentMethod);
        }
    }

    const renderCardLogo = () => {
        switch (watchCardType) {
            case 'visa': return <img className='h-42 w-45px' src={VISALogo} />
            case 'american_express': return <img className='h-42 w-45px' src={AMEXLogo} />
            case 'discover': return <img className='h-42 w-45px' src={DISCOVERLogo} />
            case 'master': return <img className='h-42 w-45px' src={MCLogo} />
        }
    }

    const createCardOwnerOption = () => {
        if (methods.getValues("entry")) {

            let entryRiders = methods.getValues("entry.EntryRiders")?methods.getValues("entry.EntryRiders"):[]
            entryRiders = entryRiders.filter((rider:any) => !rider.isDeleted)
            let rider_ids = entryRiders.map((key:any) => key.rider_id)
            const entry = methods.getValues('entry')

            let new_owner_ids = []

            if(Number(entry?.prizemoneyrecipient_id)){
                new_owner_ids.push(Number(entry?.prizemoneyrecipient_id))
            }
            if(entry?.entryowner_id){
                new_owner_ids.push(entry?.entryowner_id)
            }
            if(entry?.trainer_id){
                new_owner_ids.push(entry.trainer_id)
            }
            if(Number(entry?.responsibleparty_id)){
                new_owner_ids.push(Number(entry.responsibleparty_id))
            }
            if(rider_ids.length > 0){
                new_owner_ids = [...new_owner_ids, ...rider_ids]
            }

            let cardOwners:any = {};
            for(let new_owner_id of new_owner_ids){
                cardOwners[new_owner_id] = methods.getValues(`linked_people[${new_owner_id}].people_data.fl_name`)
            }

            if (entryRiders) {
                entryRiders.map(
                    (er:any) =>  {
                        // Add rider if is not already deleted or not present in the list1
                        if(!er.isDeleted && !cardOwners[er.rider_id]){
                            cardOwners[er.rider_id] = er.rider_name
                        }
                    }
                )
            }

            return cardOwners
        }
    }

    const cardOwnerSelect = () => {
        if (methods.getValues("entry")) {
            let cardOwners = createCardOwnerOption()

            return (
                <>
                <select
                    className='form-select form-select-sm fs-6 min-h-20px py-1 w-lg-350px'
                    disabled={!isEnabledForOtherCardType}
                    onChange={(e) => updateCardOwners(e)}
                    value={methods.watch('CardOwnerID')}
                    >
                    <option value=''>Select...</option>

                    {Object.keys(cardOwners).map(rto_id => {
                            return <option value={rto_id}>{cardOwners[rto_id]}</option>
                        })
                    }
                </select>
                </>

            )
        }
    }

    const updateCardOwners = (e:any) => {
        if(e.target.value != ''){
            let index = e.nativeEvent.target.selectedIndex;
            let label = e.nativeEvent.target[index].text;
            methods.setValue('CardOwnerID', e.target.value, {shouldDirty: true})
            methods.setValue('CardOwner', label, {shouldDirty: true})
        }
    }

    function getCreditCardServiceFee(){
        let total_service_fee = 0
        var deposit_amount_val = methods.getValues("paymentAmount")
        if (isNaN(deposit_amount_val) || deposit_amount_val === '') {
          deposit_amount_val = 0;
        }

        if(methods.getValues("show.credit_card_service_fee") && methods.getValues("show.credit_card_service_fee_id") > 0 && methods.getValues('show.paymentGatewayNameToUse')==paymentType){
            var fee_percent = parseFloat(methods.getValues("show.credit_card_service_fee_percent"));
            if(deposit_amount_val > 0 && fee_percent > 0 && fee_percent < 100){
                //Get credit card service fee in show w.r.t payment amount
                total_service_fee = parseFloat(((deposit_amount_val) * (fee_percent/100)).toFixed(2))
            }
        } 

        return total_service_fee;
      }
      
    const getTotalAmountToDisplay = (addCreditCardServiceFee=true) => {
        let amount = parseFloat(methods.getValues('paymentAmount'))
        if (!amount || isNaN(amount)) {
            amount = 0;
        }

        if (methods.getValues('applyPrizeMoneyPayment')) {
            amount = addFloatingNumbers([amount, appliedPrizeMoneyPayment], 2)
        }

        if (methods.getValues('moveCreditFromAccount')) {
            amount = addFloatingNumbers([amount, transferredCredit], 2)
        }

        if(!addCreditCardServiceFee){ // return total amount other then service fee to calculate the balance after payment
            return amount
        }

        return addFloatingNumbers([amount, getCreditCardServiceFee()], 2);
      }

    const getBalanceAfterPayment = () => {
        // get the total amount that will be paid to the entry (payment + prize money payment)
        let amountApplied = getTotalAmountToDisplay(false) 
        /*
        let balanceAfterPayment = 0
        if(amountApplied > 0){
            // remove the payment amount from entry's balance to calculate the entry's balance
            balanceAfterPayment = addFloatingNumbers([methods.getValues('entry.balance'), -1*amountApplied], 2)
        }else{
            // no payment will be made so balance after payment will remain same
            balanceAfterPayment = methods.getValues('entry.balance')
        }
        */
        let balanceAfterPayment = addFloatingNumbers([methods.getValues('entry.balance'), -1*amountApplied], 2)

        return balanceAfterPayment
    }

    const openRtoDetail = (type: string) => {
        let rtoId = 0
        if(type == 'RP') {
            rtoId = methods.getValues('entry.responsibleparty_id')
        }else {
            rtoId = methods.getValues('entry.prizemoneyrecipient_id')
        }

        if(rtoId){
            window.open(process.env.PUBLIC_URL+'/people/detail/'+rtoId+`?customer_id=${customer_id}&selectedTab=2`,'_blank')
        }
    }

    useEffect(() => {
        let error = ''
        if (methods.getValues('entry.balance') <= 0.01) {
            error = intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TRANSFER.CREDIT.FROM.ACCOUNT.ERROR' })
        } else {
            // setMoveFrom('RP')
        }

        if (paymentType !== 'Move Credit From Account') {
            error = ''
        }

        setMoveCreditFromAccountError(error)
    }, [transferableAmounts, paymentType])

    const recalculatePrizeMoneyApplied = () => {
        
        const autoApplyPrizeMoney = methods.watch('autoApplyPrizeMoney');
        let _totalWithholdingTaxDeducted = totalWithholdingTaxDeducted
        let _unusedPrizeMoney = unusedPrizeMoney;
        let _unappliedPrizeMoney = addFloatingNumbers([unappliedPrizeMoney, autoApplyPrizeMoney ? -1*_totalWithholdingTaxDeducted : 0], 2); // remove withholding tax for unapplied prize money if Transfer prize money checkbox is checked
        let _unuseablePrizeMoney = unuseablePrizeMoney
        let _totalPrizeMoney = addFloatingNumbers([autoApplyPrizeMoney ? 0 : _totalWithholdingTaxDeducted, totalPrizeMoney], 2) // add withholding tax in total prize money applied if Transfer prize money checkbox is not schecked
        let _balanceAfterPrizeMoney = 0
        let _appliedPrizeMoney = 0
        let _transferredCredit = 0

        //Step 1: Update unused prize money amount >>>
        if (autoApplyPrizeMoney) {
            // Unused prize money will be the sum of unapplied and unused prize money
            _unusedPrizeMoney = _totalPrizeMoney;
        } else if (!autoApplyPrizeMoney) {
            // Remove the unapplied prize money from unused prize money
            _unusedPrizeMoney = Math.abs(addFloatingNumbers([_totalPrizeMoney, -1 * _unappliedPrizeMoney], 2));
        }
        //Step 1: Update unused prize money amount <<<

        //Step 2: update useable prize money amount >>>
        let _useablePrizeMoney = addFloatingNumbers([_unusedPrizeMoney, -1*_unuseablePrizeMoney], 2)
        if(_useablePrizeMoney > 0){
            // Some credit is not useable bcz of debit balance. Display only the useable credit amount
            _useablePrizeMoney = _useablePrizeMoney
        }else{
            // all the credit is not useable bcz of missing ssn or some other reason. Display unused prize money in red color
            _useablePrizeMoney = _unusedPrizeMoney
        }
        _useablePrizeMoney = methods.getValues('entry.balance') < _useablePrizeMoney ? methods.getValues('entry.balance') : _useablePrizeMoney
        //Step 2: update useable prize money amount <<<

        //Step 3: update error messages >>>
        let _prizeMoneyPaymentError = ''
        let allowPrizeMoneyPayments = true
        let pmr = methods.getValues(`linked_people[${methods.getValues('entry.prizemoneyrecipient_id')}].people_data`)
        try {
            if(!pmr){
                _prizeMoneyPaymentError = 'PMR not found.'
                allowPrizeMoneyPayments = false
                setPrizeMoneyError(_prizeMoneyPaymentError)
            }
            else if(!checkSocialOrFederalId(methods.getValues(`linked_people[${methods.getValues('entry.prizemoneyrecipient_id')}].people_data`)) && prizeMoneyPrefs.PMR_SSN_Required){
                _prizeMoneyPaymentError = `Prize Money payment cannot be applied because Prize Money Recipient's Federal ID or Social Security Number are not valid.`
                allowPrizeMoneyPayments = false
            } else if (methods.getValues('entry.prizemoneyrecipient_id') != methods.getValues('entry.responsibleparty_id') && !prizeMoneyPrefs.Ignore_PMR_RP_Mismatch){ 
                _prizeMoneyPaymentError = `Prize Money payment cannot be applied due to PMR and RP mismatch.`
                allowPrizeMoneyPayments = false
            }
            else {
                // error other then 'Pending Balance' e.g on credit hold 
                let filteredErrors = transferableAmounts?.pmrErrors?.filter((error) => error != 'Pending Account Register Balance')
                _prizeMoneyPaymentError = filteredErrors?.toString().replace(',', ', ')
                if(filteredErrors.length > 0){ // if errors other then 'Pending Account Register Balance' found then no prize money payment can be used
                    allowPrizeMoneyPayments = false
                }

                // handle pending balance errors
                if(allowPrizeMoneyPayments){
                    if(transferableAmounts.pmrBalance >= 0 && _unusedPrizeMoney <= transferableAmounts.pmrBalance && _unusedPrizeMoney){
                        allowPrizeMoneyPayments = false
                        _prizeMoneyPaymentError = transferableAmounts?.pmrErrors?.toString().replace(',', ', ')
                        if(!_prizeMoneyPaymentError){
                            _prizeMoneyPaymentError = 'Pending Account Register Balance'
                        }
    
                    }
                }
            }

        } catch (reason) {}
        //Step 3: update error messages <<<

        // error for partiallly applied prize money due to pending balance 
        if(allowPrizeMoneyPayments && _unuseablePrizeMoney && _balanceAfterPrizeMoney > 0){
            _prizeMoneyPaymentError = `${currencyFormatter(Math.min(_balanceAfterPrizeMoney, _unuseablePrizeMoney))} ${intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.PENDING.BALANCE.ERROR' })}`
        }

        //Step 4: update prize money payment applied >>>
        if(!allowPrizeMoneyPayments || !_useablePrizeMoney){
            /* if there are some error while apply prize money payments or unapplied prize money is equal to unuseable prize money amount 
            then no prize money payments will be applied to the entry */
        }else{
            // if useablePrizeMoney is more the entry balance then payment made will be equal to entry balance otherwise the all prize money will paid to the entry
            if(_useablePrizeMoney >= methods.watch('entry.balance')){
                _appliedPrizeMoney = methods.watch('entry.balance')
            }else{
                _appliedPrizeMoney = _useablePrizeMoney
            }
        }
        //Step 4: update prize money payment applied <<<

        //Step 5: update move credit from account amount >>>
        if(methods.getValues('moveCreditFromAccount')){
            if (!['RP', 'PMR'].includes(moveFrom) || methods.getValues('entry.balance') <= 0) {
                // do nothing
            } else {
                let balanceAfterPrizeMoneyPayment = methods.getValues('entry.balance')
                if(methods.getValues('applyPrizeMoneyPayment')){
                    balanceAfterPrizeMoneyPayment = addFloatingNumbers([methods.getValues('entry.balance'), -1*_appliedPrizeMoney], 2)
                }

                if (moveFrom === 'RP' && transferableAmounts.rpCredit < 0) { // credit transferred from RP and there is some transferable credit present for RP
                    _transferredCredit = Math.min(Math.abs(transferableAmounts.rpCredit), balanceAfterPrizeMoneyPayment)
                } else if (moveFrom === 'PMR' && transferableAmounts.pmrCredit < 0) { // credit transferred from PMR and there is some transferable credit present for PMR
                    _transferredCredit = Math.min(Math.abs(transferableAmounts.pmrCredit), balanceAfterPrizeMoneyPayment)
                }
            }
        }
        //Step 5: update move credit from account amount <<<

        //Step 6: update balance after payment >>>
        let availableCredit = moveFrom == 'RP' ? transferableAmounts?.rpCredit : transferableAmounts?.pmrCredit
        if((!allowPrizeMoneyPayments || !_useablePrizeMoney) && (availableCredit >= 0)){
            /* if there are some error while apply prize money payments or unapplied prize money is equal to unuseable prize money amount 
            then no prize money payments will be applied to the entry */
            _balanceAfterPrizeMoney = methods.watch('entry.balance')
        }else{
            if(allowPrizeMoneyPayments && _useablePrizeMoney > 0){
                // if useablePrizeMoney is more the entry balance then payment made will be equal to entry balance otherwise the all prize money will paid to the entry
                if(_useablePrizeMoney >= methods.watch('entry.balance')){
                    _balanceAfterPrizeMoney = 0
                }else{
                    _balanceAfterPrizeMoney = addFloatingNumbers([methods.watch('entry.balance'), -1*_useablePrizeMoney], 2)
                }
            }else{
                _balanceAfterPrizeMoney = methods.watch('entry.balance')
            }

            if(moveFrom){
                if(availableCredit < 0){
                    // if useablePrizeMoney is more the entry balance then payment made will be equal to entry balance otherwise the all prize money will paid to the entry
                    if(Math.abs(availableCredit) >= _balanceAfterPrizeMoney){
                        _balanceAfterPrizeMoney = 0
                    }else{
                        _balanceAfterPrizeMoney = addFloatingNumbers([_balanceAfterPrizeMoney, -1*Math.abs(availableCredit)], 2)
                    }
                }
            }
        }
        //Step 6: update balance after payment <<<

        // Note: Change _totalPrizeMoney with _unusedPrizeMoney if Transfer Prize Money checkbox is enabled in future
        // if no prize money can be used for entry payment then uncheck the checkbox
        if(!allowPrizeMoneyPayments || !_totalPrizeMoney){
            methods.setValue('applyPrizeMoneyPayment', false, { shouldDirty: true })
        }
        
        // if no pmr present uncheck Transfer prize money checkbox
        if(!pmr){
            methods.setValue('autoApplyPrizeMoney', false, { shouldDirty: true })
        }

        // update state variables
        setAppliedPrizeMoneyPayment(methods.getValues('applyPrizeMoneyPayment') ? _appliedPrizeMoney : 0)
        setBalanceAfterPrizeMoney(_balanceAfterPrizeMoney)
        // Note: Change _totalPrizeMoney with _unusedPrizeMoney if Transfer Prize Money checkbox is enabled in future
        setPrizePaymentError(_totalPrizeMoney <= 0 || methods.watch('entry.balance') <= 0 ? '' : _prizeMoneyPaymentError)
        setIsPrizeMoneyPaymentApplicable(allowPrizeMoneyPayments)
        setUseablePrizeMoney(_useablePrizeMoney)
        setTransferredCredit(_transferredCredit)
        if (_unusedPrizeMoney !== unusedPrizeMoney) {
            setUnusedPrizeMoney(_unusedPrizeMoney);
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-750px search-form'
            show={show}
            onHide={handleCustomeClose}
            onKeyDown={(event: any) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) { //call function only when no button is in focus
                    handleSave()
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .add-payment-form .w-lg-160px { width: 180px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.ADDPAYMENT' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleCustomeClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-payment-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        { //(unappliedPrizeMoney > 0 && methods.watch('entry.balance') > 0) && 
                        [
                        <fieldset>
                            <legend className="fs-5 fw-bold">Prize Money/ Credit Transfer</legend>
                        </fieldset>,
                        // Transfer Prize Money Checkbox
                        <div className='row'>   
                            <div className='w-25px form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...methods.register("autoApplyPrizeMoney")}
                                    className='form-check-input'
                                    type='checkbox'
                                    id='autoApplyPrizeMoney'
                                    // Note: Remove true from disabled condition if Transfer Prize Money checkbox is enabled in future
                                    disabled={unappliedPrizeMoney <= 0 || !methods.getValues(`linked_people[${methods.getValues('entry.prizemoneyrecipient_id')}].people_data`) || true} // disable if pmr not exists or no unapplied prize money found
                                />
                            </div>
                            <label 
                                className='col col-form-label fs-5 py-1' 
                                htmlFor='autoApplyPrizeMoney'
                                data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PRIZEMONEY.LABEL.APPLYPRIZEMONEYFROMPMR"
                            >
                                Transfer Prize Money to PMR ({ methods.watch('entry.prizemoneyrecipient') }) 
                            </label>
                            <label className={`col-lg-2 col-form-label fs-5 py-1 text-end ${prizeMoneyError && 'text-danger'}`}>
                                { currencyFormatter(unappliedPrizeMoney) }
                            </label>
                        </div>,
                        prizeMoneyError &&
                        <div className='row'>
                            <label className='col col-form-label fs-5 py-1 text-danger'>
                                { prizeMoneyError }
                            </label>
                        </div>,
                        <div className='row'>   
                        {/* Apply Prize Money Checkbox */}
                            <div className='w-25px form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...methods.register("applyPrizeMoneyPayment")}
                                    className='form-check-input'
                                    type='checkbox'
                                    id='applyPrizeMoneyPayment'
                                    // Note: add !unusedPrizeMoney in disabled condition if Transfer Prize Money checkbox is enabled in future
                                    disabled={methods.watch('entry.balance') <= 0 || !isPrizeMoneyPaymentApplicable}
                                />
                            </div>
                            <label 
                                className='col col-form-label fs-5 py-1' 
                                htmlFor='applyPrizeMoneyPayment'
                                data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PRIZEMONEY.LABEL.APPLYPRIZEMONEYFROMPMR"
                            >
                                Apply Prize Money Payment from PMR ({ methods.watch('entry.prizemoneyrecipient') }) 
                            </label>
                            <label className={`col-lg-2 col-form-label fs-5 py-1 text-end ${!isPrizeMoneyPaymentApplicable && useablePrizeMoney && methods.watch('entry.balance') > 0 && 'text-danger'}`}>
                            { currencyFormatter(methods.watch('entry.balance') <= 0 ? 0 : useablePrizeMoney) }
                            </label>
                        </div>,
                        prizePaymentError ?
                        <div className='row'>
                            <label className='col col-form-label fs-5 py-1 text-danger'>
                                { prizePaymentError }
                            </label>
                        </div> : null,
                        <div className='row'>   
                        {/* Move Credit From Account */}
                            <div className='w-25px form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...methods.register("moveCreditFromAccount")}
                                    className='form-check-input'
                                    type='checkbox'
                                    id='moveCreditFromAccount'
                                    // Note: disable entry has no debit balance or there is no credit available for pmr and rp
                                    disabled={methods.watch('entry.balance') <= 0 || (transferableAmounts.pmrCredit >= 0 && transferableAmounts.rpCredit >= 0)}
                                />
                            </div>
                            <label 
                                className='col col-form-label fs-5 py-1' 
                                htmlFor='moveCreditFromAccount'
                            >
                                Transfer Credit From Account
                            </label>
                            <label className={`col-lg-2 col-form-label fs-5 py-1 text-end`}>
                            { currencyFormatter(transferredCredit) }
                            </label>
                        </div>,
                        <>
                        {
                            <div className='row'>
                                <div className='w-25px form-check-sm form-check-custom ps-lg-3'></div>
                                <div className='col form-check-sm form-check-custom ms-3'>
                                        <input
                                        className='form-check-input'
                                        type="radio"
                                        id="moveFromRP"
                                        checked={moveFrom === "RP"} 
                                        onChange={() => setMoveFrom("RP")}
                                        name="setOrderType"
                                        disabled ={disableRPMoveCreditFromAccount} 
                                    />
                                    <label className='form-check-label fs-6 py-0' htmlFor="moveFromRP">
                                        { `${methods.watch('entry.responsibleparty')} (${methods.watch('entry.responsibleparty_id') != methods.watch('entry.prizemoneyrecipient_id') ? 'RP' : 'RP/PMR'} - ${currencyFormatter(transferableAmounts.rpCredit < 0 ? Math.abs(transferableAmounts.rpCredit) : 0)})` }
                                    </label>
                                    <div className='justify-content-center ms-2'>
                                        <i className={"fas fa-external-link py-0 fs-5 fa-solid cursor-pointer"} onClick={() => openRtoDetail('RP')}></i>
                                    </div>
                                </div> 
                            </div>
                        }
                        </>,
                        <div>
                        {
                            !moveCreditFromAccountError && disableRPMoveCreditFromAccountReason?.length > 0 && methods.watch('entry.balance') > 0 &&
                            <div className='row'>
                                <div className='w-25px form-check-sm form-check-custom ps-lg-3'></div>
                                <label className='col col-form-label fs-6 py-0 text-danger text-start'> 
                                    { `${disableRPMoveCreditFromAccountReason.toString().replace(',', ', ')}` }
                                </label>
                            </div>
                        }
                        </div>,
                        methods.getValues('entry.prizemoneyrecipient_id') != methods.getValues('entry.responsibleparty_id') ?
                        <div className='row'>
                            <div className='w-25px form-check-sm form-check-custom ps-lg-3'></div>
                            <div className='col form-check-sm form-check-custom ms-3'>
                                <input
                                    className='form-check-input'
                                    type="radio"
                                    id="moveFromPMR"
                                    checked={moveFrom === "PMR"} 
                                    onChange={() => setMoveFrom("PMR")}
                                    name="setOrderType"
                                    disabled ={disablePMRMoveCreditFromAccount} 
                                />
                                <label className='form-check-label fs-6 py-0' htmlFor="moveFromPMR">
                                    { `${methods.watch('entry.prizemoneyrecipient')} (PMR - ${currencyFormatter(transferableAmounts.pmrCredit < 0 ? Math.abs(transferableAmounts.pmrCredit) : 0)})` }
                                </label>
                                <div className='justify-content-center ms-2'>
                                    <i className={"fas fa-external-link py-0 fs-5 fa-solid cursor-pointer"} onClick={() => openRtoDetail('PMR')}></i>
                                </div>
                            </div>
                            </div> : null,
                        <div>
                        {
                            moveCreditFromAccountError === '' && methods.watch('entry.responsibleparty_id') != methods.watch('entry.prizemoneyrecipient_id') && disablePMRMoveCreditFromAccountReason?.length > 0 && methods.watch('entry.balance') > 0 &&
                            <div className='row'>
                                <div className='w-25px form-check-sm form-check-custom ps-lg-3'></div>
                                <label className='col col-form-label fs-6 py-0 text-danger text-start'> 
                                    { `${disablePMRMoveCreditFromAccountReason.toString().replace(',', ', ')}` }
                                </label>
                            </div>
                        }
                        </div>,
                        <div className='row'>
                            <label className='col col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PRIZEMONEY.LABEL.ENTRYBALANCE">
                               Entry Balance
                            </label>
                            <label className='col-lg-2 col-form-label fs-5 py-1 text-end'>
                                { currencyFormatter(methods.watch('entry.balance')) } 
                            </label>
                        </div>,
                        totalWithholdingTaxDeducted > 0 && methods.watch('autoApplyPrizeMoney') ?
                        <div className='row'>
                            <label className='col col-form-label fs-5 py-1'>
                            Withholding Tax
                            </label>
                            <label className='col-lg-2 col-form-label fs-5 py-1 text-end'>
                                { currencyFormatter(totalWithholdingTaxDeducted) } 
                            </label>
                        </div> : null,
                        <div className='row'>
                            <label className='col col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PRIZEMONEY.LABEL.BALANCEAFTERPRIZEMONEY">
                                Balance After Prize Money/ Credit Transfer
                            </label>
                            <label className='col-lg-2 col-form-label fs-5 py-1 text-end'>
                                { currencyFormatter(balanceAfterPrizeMoney) }
                            </label>
                        </div>]}

                        <fieldset>
                            <legend className="fs-5 fw-bold">Payment</legend>
                        </fieldset>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='TYPE' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PAYMENT.LABEL.TYPE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.TYPE' })}</label>
                            <div className='w-lg-250px pe-0'>
                                <select id="TYPE" className='form-select form-select-sm fs-6 min-h-20px py-1' onChange={onPaymentTypeChange}  tabIndex={1} autoFocus>
                                    <option value=''>Select</option>
                                    {
                                        paymentTypes.map( (pt:any) => {
                                            return (
                                                <option value={pt.label}>{pt.value}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        { paymentType === 'Move Credit From Account' &&
                        <>
                        <div className='row'>
                            <label className='col-lg-1 col-form-label fs-5 py-0 w-lg-160px'>
                                { !moveCreditFromAccountError && intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ACCOUNT' })}
                            </label>
                            { moveCreditFromAccountError &&
                                <label className='col col-form-label fs-5 py-1 text-danger'>
                                    { moveCreditFromAccountError }
                                </label>
                            }
                            { moveCreditFromAccountError === '' && methods.watch('entry.balance') > 0 && methods.getValues(`linked_people[${methods.getValues('entry.responsibleparty_id')}].people_data`) &&
                            <div className='col form-check-sm form-check-custom ms-3'>
                                    <input
                                    className='form-check-input'
                                    type="radio"
                                    id="moveFromRP"
                                    checked={moveFrom === "RP"} 
                                    onChange={() => setMoveFrom("RP")}
                                    name="setOrderType"
                                    disabled ={disableRPMoveCreditFromAccount} 
                                />
                                <label className='form-check-label fs-6 py-0' htmlFor="moveFromRP">
                                    { `${methods.watch('entry.responsibleparty')} (${methods.watch('entry.responsibleparty_id') != methods.watch('entry.prizemoneyrecipient_id') ? 'RP' : 'RP/PMR'} - ${currencyFormatter(transferableAmounts.rpCredit < 0 ? Math.abs(transferableAmounts.rpCredit) : 0)})` }
                                </label>
                                <div className='justify-content-center ms-2'>
                                    <i className={"fas fa-external-link py-0 fs-5 fa-solid cursor-pointer"} onClick={() => openRtoDetail('RP')}></i>
                                </div>
                            </div> 
                            }
                        </div>
                        {
                            !moveCreditFromAccountError && disableRPMoveCreditFromAccountReason?.length > 0 && methods.watch('entry.balance') > 0 &&
                            <>
                                <label className='col-lg-1 col-form-label fs-5 py-0 w-lg-160px'>
                                </label>
                                <label className='col col-form-label fs-5 px-0 py-0 text-danger text-start'> 
                                    { `${disableRPMoveCreditFromAccountReason.toString().replace(',', ', ')}` }
                                </label>
                            </>
                        }
                        {  moveCreditFromAccountError === '' && methods.watch('entry.responsibleparty_id') != methods.watch('entry.prizemoneyrecipient_id') && methods.watch('entry.balance') > 0 &&  methods.getValues(`linked_people[${methods.getValues('entry.prizemoneyrecipient_id')}].people_data`) &&
                        <div className='row'>
                            <label className='col-lg-2 col-form-label fs-5 py-0 w-lg-160px'></label>
                            <div className='col form-check-sm form-check-custom ms-3'>
                                <input
                                    className='form-check-input'
                                    type="radio"
                                    id="moveFromPMR"
                                    checked={moveFrom === "PMR"} 
                                    onChange={() => setMoveFrom("PMR")}
                                    name="setOrderType"
                                    disabled ={disablePMRMoveCreditFromAccount} 
                                />
                                <label className='form-check-label fs-6 py-0' htmlFor="moveFromPMR">
                                    { `${methods.watch('entry.prizemoneyrecipient')} (PMR - ${currencyFormatter(transferableAmounts.pmrCredit < 0 ? Math.abs(transferableAmounts.pmrCredit) : 0)})` }
                                </label>
                                <div className='justify-content-center ms-2'>
                                    <i className={"fas fa-external-link py-3 fs-5 fa-solid cursor-pointer"} onClick={() => openRtoDetail('PMR')}></i>
                                </div>
                            </div>
                        </div> }
                        {
                            moveCreditFromAccountError === '' && methods.watch('entry.responsibleparty_id') != methods.watch('entry.prizemoneyrecipient_id') && disablePMRMoveCreditFromAccountReason?.length > 0 && methods.watch('entry.balance') > 0 &&
                            <>
                                <label className='col-lg-1 col-form-label fs-5 py-0 w-lg-160px'>
                                </label>
                                <label className='col col-form-label fs-5 px-0 py-0 text-danger text-start'> 
                                    { `${disablePMRMoveCreditFromAccountReason.toString().replace(',', ', ')}` }
                                </label>
                            </>
                        }
                        </> }
                        <div className='row mb-2'></div>
                        
                        {
                        !isDisabledForCompanyGateway &&
                        <>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px'></label>
                                <div className='col'>
                                    <select {...register("selectedCardId")} 
                                        className='form-select form-select-sm fs-6 min-h-20px py-1' 
                                        disabled={isDisabledForNon_Card} 
                                        onChange={e => handleCreditCardChange(e.target.value)} 
                                        tabIndex={2}
                                    >
                                        <option value='0'>Select card ... </option>
                                        {
                                            entryPaymentMethods.map((pm: any) => <option value={pm.payment_method_id}>{pm.dropdown_label}</option>)
                                        }
                                        <option value='-1'>Other</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='CREDITCARDNUM' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PAYMENT.LABEL.CREDITCARDNUMBER">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.CREDITCARDNUM' })}</label>
                                <div className='col pe-0'>
                                    <input
                                        {...register("creditCardNum", 
                                            {
                                                required: false,                                            
                                            })
                                        }
                                        id='CREDITCARDNUM'
                                        type='text'                                    
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.CREDITCARDNUM' })}
                                        disabled={!(isEnabledForOtherCardType)}
                                        onBlur={onCreditCardNumberChange}
                                        onFocus={onCreditCardNumberFocus}
                                        tabIndex={3}
                                    />
                                </div>
                                <div className='w-lg-65px text-end'>
                                    {renderCardLogo()}
                                </div>
                            </div>
                        
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='EXPIRATIONDATE' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PAYMENT.LABEL.EXPIRATIONDATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.EXPIRATIONDATE' })}</label>
                                <div className='col-lg-2'>
                                    <PatternFormat
                                        valueIsNumericString={true}
                                        value={paymentData.creditCardExp}
                                        id="expiration"
                                        format="##/##"
                                        onValueChange={(e)=> setPaymentData(prevPaymentData => ({...prevPaymentData, creditCardExp: e.value}))}
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.EXPIRATIONFORMAT' })}
                                        disabled={!(isEnabledForOtherCardType)}
                                        tabIndex={4}
                                    />
                                </div>
                            
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='CREDITSECURITYCODE' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PAYMENT.LABEL.CREDITSECURITYCODE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.CREDITSECURITYCODE' })}</label>
                                <div className='w-lg-100px'>                                    
                                    <Controller
                                        control={methods.control}
                                        name="creditCardCVV"
                                        render={({ field: { onChange, name, value } }) => (
                                            <NumericFormat
                                                id="CREDITSECURITYCODE"
                                                value={value}
                                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                type="text"
                                                name={name}
                                                allowNegative={false} // disallow negative values
                                                onValueChange={(e)=>{
                                                    onChange(e.value)
                                                }}
                                                isAllowed={(value: any) =>  value.floatValue ? value.floatValue < 10000 : true} // Max 4 digit
                                                disabled={!(isDisabledForCard)}
                                                tabIndex={5}
                                                allowLeadingZeros={true}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='NAMEONCARD' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PAYMENT.LABEL.NAMEONCARD">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.NAMEONCARD' })}</label>
                                <div className='col'>
                                    <input
                                        {...register("creditCardName", 
                                        {
                                            required: false
                                        })
                                        }
                                        id='NAMEONCARD'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.NAMEONCARD' })}                                    
                                        disabled={!isEnabledForOtherCardType}
                                        tabIndex={6}
                                    />
                                </div>
                            </div>  
                        
                            <div className='row force_cit_row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px'>&nbsp;</label>
                                <div className='col form-check-sm form-check-custom ps-lg-3'>
                                    <input
                                        {...methods.register("forceCIT", 
                                            {
                                                required: false
                                            })
                                        }
                                        className='form-check-input'
                                        type='checkbox'
                                        id='FORCECIT'
                                        tabIndex={7}
                                    />
                                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='FORCECIT' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PAYMENT.LABEL.CIT"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.FORCECIT' })}</label>
                                </div>
                            </div>
                            
                            {
                            hasCiTWarning && 
                            <div className='row cit_warning mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px'>&nbsp;</label>
                                <div className='col'>
                                    <label className='text-danger'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.CITWARNING' })} </label>
                                </div>
                            </div>
                            }
                        </>
                        }

                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='paymentAmount' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PAYMENT.LABEL.AMOUNT">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.AMOUNT' })}</label>
                            <div className='w-lg-250px pe-0'>
                                <Controller
                                    control={methods.control}
                                    name="paymentAmount"
                                    render={({ field: { onChange, name, value } }) => (
                                        <NumericFormat
                                            id="paymentAmount"
                                            value={Number(value)}
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            type="text"
                                            name={name}
                                            allowNegative={allowNegativeAmount} // allow negative values for payment with payment category 'Miscellaneous'
                                            onValueChange={(e)=>{
                                                onChange(e.value)
                                            }}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            prefix={intl.formatMessage({ id: `CURRENCY.CODE.SYMBOL.${currencyCode}`})}
                                            tabIndex={8}
                                            getInputRef={amountRef}
                                        />
                                    )}
                                />
                            </div>        
                        </div>

                        { !isDisabledForCompanyGateway &&
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PAYMENT.LABEL.SERVICEFEE"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.SERVICEFEE' })}</label>
                            <label className='col col-form-label fs-5 py-1'>
                                { currencyFormatter(getCreditCardServiceFee())}
                            </label>
                        </div>
                        }

                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px'> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.PRIZEMONEY' })}</label>
                            <label className='col col-form-label fs-5 py-1'>
                                { currencyFormatter(appliedPrizeMoneyPayment)}
                            </label>
                        </div>

                        {/* Credit Transferred from PMR/RP Account */}
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px'> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.ACCOUNTTRANSFER' })}</label>
                            <label className='col col-form-label fs-5 py-1'>
                                { currencyFormatter(transferredCredit)}
                            </label>
                        </div>

                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px'  data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PAYMENT.LABEL.TOTALAMOUNT"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.TOTALAMOUNT' })}</label>
                            <label className='col col-form-label fs-5 py-1'>
                                { currencyFormatter(getTotalAmountToDisplay()) }
                            </label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px'> Balance After Payment </label>
                            <label className='col col-form-label fs-5 py-1'>
                                { currencyFormatter(getBalanceAfterPayment(), true) }
                            </label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor={refCodeLabel} data-tooltip-id={`ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PAYMENT.LABEL.${refCodeLabel}`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.' + refCodeLabel })}</label>
                            <div className='w-lg-250px pe-0'>
                                <input
                                 {...methods.register("refCode", 
                                    {
                                        required: false
                                    })
                                }
                                    id={refCodeLabel}
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.' + refCodeLabel })}
                                    tabIndex={9}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='DESCRIPTION' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PAYMENT.LABEL.DESCRIPTION">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.DESCRIPTION' })}</label>
                            <div className='col'>
                                {/* <textarea
                                    {...methods.register("newPaymentDescription", 
                                        {
                                            required: false
                                        })
                                    }
                                    id="DESCRIPTION"
                                    rows={2}
                                    className='form-control h-100 form-control-sm fs-6'
                                    tabIndex={10}
                                ></textarea> */}
                                <input
                                    {...register("newPaymentDescription", 
                                    {
                                        required: false
                                    })
                                    }
                                    id='DESCRIPTION'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    tabIndex={10}
                                />
                            </div>
                        </div>
                        <div className={'row mb-2' + (isDisabledForCompanyGateway ? ' d-none' : '')}>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='WHOSECARD' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.PAYMENT.LABEL.WHOSECARDISTHIS">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.WHOSECARD' })}</label>
                            <div className='col'>
                                {cardOwnerSelect()}
                            </div>
                            <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3' style={{ width: '146px' }}>
                                <input
                                    {...register("RetainCard", 
                                        {
                                            required: false
                                        })
                                    }
                                    className='form-check-input'
                                    type='checkbox'
                                    id='STORETHISCARD'
                                    disabled={!isEnabledForOtherCardType}
                                    tabIndex={11}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='STORETHISCARD'> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.STORETHISCARD' })}</label>
                            </div>
                        </div>
                        <fieldset className={'mb-2' + (isDisabledForCompanyGateway ? ' d-none' : '')}>
                            <legend className="fs-5 fw-bold col">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.GROUPLABEL.BILLINGADDRESS' })} <b></b></legend>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='ADDRESSONE' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.BILLINGADDRESS.LABEL.ADDRESS1">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ADDRESSONE' })}</label>
                                <div className='col'>
                                        <input
                                        {...methods.register("addr1", 
                                            {
                                                required: false
                                            })
                                        }
                                        id='ADDRESSONE'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ADDRESSONE' })}
                                        tabIndex={12}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='ADDRESSTWO' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.BILLINGADDRESS.LABEL.ADDRESS2">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ADDRESSTWO' })}</label>
                                <div className='col'>
                                    <input
                                        
                                        {...methods.register("addr2", 
                                           {
                                               required: false
                                           })
                                       }
                                        id='ADDRESSTWO'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ADDRESSTWO' })}
                                        tabIndex={13}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='CITY' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.BILLINGADDRESS.LABEL.CITY">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.CITY' })}</label>
                                <div className='col'>
                                    <input                                        
                                        {...methods.register("cityname", 
                                           {
                                               required: false
                                           })
                                       }
                                        id='CITY'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.CITY' })}
                                        tabIndex={14}
                                    />
                                </div>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-30px px-0' htmlFor='STATE' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.BILLINGADDRESS.LABEL.STATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.STATE' })}</label>
                                <div className='col'>
                                    <select id="STATE" className='form-select form-select-sm fs-6 min-h-20px py-1'
                                    {...methods.register("state", 
                                        {
                                            required: false
                                        })
                                    }
                                    tabIndex={15}
                                    >        
                                        {
                                            listOfStates.map( (state:any) => {                                                                                            
                                                return (
                                                        <option value={state.value}>{state.label}</option>
                                                    )                                                
                                            })
                                            
                                        }
                                    </select>
                                </div>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-20px px-0' htmlFor='ZIP' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.BILLINGADDRESS.LABEL.ZIP">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ZIP' })}</label>
                                <div className='col'>
                                    <input
                                        {...methods.register("zip", 
                                        {
                                            required: false
                                        })
                                    }
                                        id='ZIP'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ZIP' })}
                                        tabIndex={16}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='COUNTRY' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.BILLINGADDRESS.LABEL.COUNTRY">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.COUNTRY' })}</label>
                                <div className='col'>
                                    <select id="COUNTRY" className='form-select form-select-sm fs-6 min-h-20px py-1'
                                    {...methods.register("country", 
                                        {
                                            required: false
                                        })
                                     }
                                     tabIndex={17}
                                    >                                                                            
                                        {                                        
                                            listOfCountries.map( (country:any) => {                                                
                                                    return (
                                                        <option value={country.value}>{country.label}</option>
                                                    )                                                
                                            })
                                        }
                                    </select>
                                </div>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-40px px-0' htmlFor='PHONE' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.BILLINGADDRESS.LABEL.PHONE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.PHONE' })}</label>
                                <div className='col'>
                                    {/* <input
                                        {...methods.register("phoneNumber", 
                                        {
                                            required: false
                                        })
                                    }
                                        id='PHONE'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.PHONE' })}
                                        tabIndex={18}
                                    /> */}
                                    <Controller
                                        control={methods.control}
                                        name="phoneNumber"
                                        render={({ field: { onChange, value} }) => (
                                            <PatternFormat
                                                id="PHONE"
                                                format="(###) ###-####"
                                                value={methods.getValues('phoneNumber') === '' ? '' :typeof value == 'string' ? Number(value?.match(/\d+/g)?.join('')): value }
                                                onValueChange={(e)=>{
                                                    onChange(e.value)
                                                }}
                                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.PHONE' })}
                                                tabIndex={18}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='EMAIL' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.BILLINGADDRESS.LABEL.EMAIL">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.EMAIL' })}</label>
                                <div className='col'>
                                    <input
                                        {...methods.register("email", 
                                            {
                                                required: false
                                            })
                                        }
                                        id='EMAIL'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.EMAIL' })}
                                        tabIndex={19}
                                    />
                                </div>
                                <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3' style={{ width: '146px' }}>
                                    <input
                                        {...methods.register("updateRTO", 
                                            {
                                                required: false
                                            })
                                        }
                                        className='form-check-input'
                                        type='checkbox'
                                        id='UPDATERTO'
                                        tabIndex={20}
                                    />
                                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='UPDATERTO' data-tooltip-id="ENTRIES.DETAIL.MODAL.ADDPAYMENT.SECTION.BILLINGADDRESS.LABEL.UPDATERTO"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.UPDATERTO' })}</label>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleCustomeClose} tabIndex={21}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                        </button>
                        <button 
                            type='button' 
                            className='btn btn-sm btn-dark fw-bold' 
                            onClick={e => {e.preventDefault(); handleSave();}} 
                            tabIndex={22}
                            // Don't allow moving credit from account when entry no debit balance.
                            disabled={moveCreditFromAccountError !== ''}	
                        >
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.OK' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { AddPaymentForm }