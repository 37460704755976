import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAnnouncerToolContext } from './AnnouncerToolContext';
import { ChangeRider } from './ChangeRider';

const TripDetails = (props) => {
  const intl = useIntl();
  const [showRiderChange, setShowRiderChange] = useState(false);
  const { selectedRow, currentTrip } = useAnnouncerToolContext()
  

  return (
    <>
    <fieldset className='mb-0'>
      <legend className=" fs-6 fw-bold d-flex">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.TRIPDETAILS.LEGEND.TRIPDETAILS'})}<b></b></legend>


        <div className='row mb-0'>
          <label className='col-lg-3 col-form-label    fs-6 py-1' htmlFor="HORSE" data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.TRIPDETAILS.LABEL.HORSE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.HORSE'})}</label>

            <div className='col-lg-9 '>
              <input
                id="HORSE"
                type='text'
                className='form-control fw-bold fs-6 h-20px py-1 readonly'
                value = {currentTrip?.Entry?.horse ?  `${currentTrip.Entry.number} - ${currentTrip.Entry.horse}` : ''}
                disabled
                
              />
            </div>
        </div>

        <div className='row mb-0'>
          <label className='col-lg-1 col-form-label fs-6 py-1' htmlFor="RIDER" data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.TRIPDETAILS.LABEL.RIDER">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.RIDER'})}</label>
          <label className='col-lg-2 col-form-label fs-6 py-1 ps-5' htmlFor="RIDER">
            <a 
                href="#" className="text-decoration-none text-decoration-underline text-danger" 
                onClick={() => currentTrip && !currentTrip.scratch_trip && setShowRiderChange(true)} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.TRIPDETAILS.LABEL.CHANGE">
                {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.CHANGE'})}</a>
          </label>

            <div className='col-lg-9 '>
              <input
                id="RIDER"
                type='text'
                className='form-control   fs-6 h-20px py-1 readonly'
                value = {currentTrip && currentTrip?.rider_name && currentTrip?.rider_name || ''}
                disabled
                
              />
            </div>
        </div>

        <div className='row mb-0'>
          <label className='col-lg-3 col-form-label    fs-6 py-1' htmlFor="OWNER" data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.TRIPDETAILS.LABEL.OWNER">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.OWNER'})}</label>

            <div className='col-lg-9'>
              <input
                id="OWNER"
                type='text'
                className='form-control   fs-6 h-20px py-1 readonly'
                value = {currentTrip && currentTrip?.Entry?.owner && currentTrip?.Entry?.owner || ''}
                disabled
                
              />
            </div>
        </div>

        <div className='row mb-0'>
          <label className='col-lg-3 col-form-label    fs-6 py-1' htmlFor='CITYSTATE' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.TRIPDETAILS.LABEL.CITYSTATE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.CITYSTATE'})}</label>

            <div className='col-lg-6 '>
              <input
                id='CITYSTATE1'
                className='form-control   fs-6 h-20px py-1'
                value = {currentTrip && currentTrip?.Entry?.Owner?.city && currentTrip?.Entry?.Owner?.city || ''}
                type={'text'}
                disabled
                
              />
            </div>
            <div className='col-lg-3 '>
              <input
                id='CITYSTATE2'
                className='form-control   fs-6 h-20px py-1'
                value = {currentTrip && currentTrip?.Entry?.Owner?.state && currentTrip?.Entry?.Owner?.state || ''}
                type={'text'}
                disabled
                
              />
            </div>
        </div>

        <div className='row mb-0'>
          <label className='col-lg-3 col-form-label fs-6 py-1' htmlFor="TRAINER" data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.TRIPDETAILS.LABEL.TRAINER">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.TRAINER'})}</label>

          <div className='col-lg-9 '>
            <input
                id="TRAINER"
                type='text'
                className='form-control   fs-6 h-20px py-1 readonly'
                value = {currentTrip && currentTrip?.Entry?.trainer && currentTrip?.Entry?.trainer || ''}
                disabled
                
            />
          </div>
        </div>

        <div className='row mb-0'>
          <label className='col-lg-3 col-form-label    fs-6 py-1' htmlFor="TYPE" data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.TRIPDETAILS.LABEL.TYPE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.LABEL.TYPE'})}</label>

          <div className='col-lg-9 '>
            <input
                id="TYPE"
                type='text'
                className='form-control   fs-6 h-20px py-1 readonly'
                value = {currentTrip && currentTrip?.Entry?.Horse?.type && currentTrip?.Entry?.Horse?.type || ''}
                disabled
                
            />
          </div>
        </div>

      </fieldset>
      { showRiderChange && selectedRow &&
          <ChangeRider show={showRiderChange} handleClose={() => setShowRiderChange(false)} />
      }
    </>
  );
};

export default TripDetails;
  