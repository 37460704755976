import { useRef, useState } from "react"; 
import {useFormContext} from 'react-hook-form'
import axios from 'axios';  
import { useAlert } from "../../../modules/sgl-utils/DialogsProvider";
import { useIntl } from "react-intl";
import { useAppSelector } from "../../../redux/hooks";
import { getExtensionOnly } from "./ImageUploadFunctions";

function Uploader (props) {
  const intl = useIntl()
  const alertDialog = useAlert()
  const methods = useFormContext()
  const [imageValidationError, setImageValidationError] =  useState(null) 
  const [updateState, setUpdateState] = useState(false)
  const customer_id = useAppSelector(state=> state.showCompany.company_id)
  const company_url_path = useAppSelector(state=> state.showCompany.urlpath)
  const inputFile = useRef(null); 
  
  const selectedFilesHandler = (e, fileDropped=null, isDrag=false) => {
    if (checkFiles(e, fileDropped, isDrag)) {
      // if we try to upload more than 9 files, user should should not be allowed to do that,
      // not a single should be uploaded then 
      // if length of image preview url is greater than 9, 
      // means already 9 images are uploaded there then again user should not be able to upload a single file
      const files = isDrag ? fileDropped : Array.from(e.target.files);
      if (files.length < 10 && props.imagesList.length < 9){ 
        //files.forEach((imageFile, index) => { 
        for(let index=0 ; index<files?.length; index++)
        {
            let imageFile = files[index]
            const timeStamp = ((new Date().getTime()).toString())+index
            const fileName = methods.getValues('master_fee.sgl_id')+"_"+timeStamp+"."+getExtensionOnly(imageFile.name)
            // api call to upload file on cloud 
            let formData = new FormData()
            formData.append('file', imageFile);
            formData.append('customer_id', customer_id)
            formData.append('company_url_path', company_url_path)
            formData.append('file_name', fileName)
            axios.post( process.env.REACT_APP_NEST_API_URL + `/master-fees/upload?customer_id=${15}`, formData
            ).then((response) => {
              if(response.data.success) {
                //Do Nothing
              }
            }).catch((reason) => {
              let errors = reason.response.data.response.errors.join("<br/>")
              alertDialog({message: errors, title: 'warning'})
            })
            // api call to upload this image on cloud <<<

            // update image list >>>
            const lastModified = imageFile.lastModified;
            const lastModifiedDate = new Date(lastModified);
            // Format date as YYYY-MM-DD
            const formattedDate = `${lastModifiedDate.getFullYear()}-${(lastModifiedDate.getMonth() + 1).toString().padStart(2, '0')}-${lastModifiedDate.getDate().toString().padStart(2, '0')}`;
            // Format time as HH:mm:ss
            const formattedTime = `${lastModifiedDate.getHours().toString().padStart(2, '0')}:${lastModifiedDate.getMinutes().toString().padStart(2, '0')}:${lastModifiedDate.getSeconds().toString().padStart(2, '0')}`;
               
            const result = {
                file: imageFile,
                size: imageFile.size,
                name: imageFile.name,
                timeStamp: timeStamp,
                modification_time: formattedTime,  //file.lastModified,
                modification_date: formattedDate, 
                sgl_id: 0,
                selected:false,
                on_cdn:true
            }; 
            setImageValidationError(null);
            setUpdateState(!updateState)
            props.updateImagesList(result);
            // update image list <<<
        }
         // });

      } else { 
        alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.FILEUPLOADER.IMAGENOERROR' }), title: 'warning'})
      }
     resetInputFileField()
    }
  };

  // function to check size of provided image and its type
  const checkFiles=(event, fileDropped, isDrag) =>{
    let files = isDrag ? fileDropped : event.target.files;
    let err = "";
    const types = ["image/png", "image/jpeg", "image/jpg"];
    for (let x = 0; x < files.length; x += 1) {
      if (types.every((type) => files[x].type !== type)) {
        err += `${files[x].type} ${intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.FILEUPLOADER.FORMATERRORMESSAGE' })}\n`;
      }
      if (types.every((size) => files[x].size > 20e6)) {
        err += `${intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.FILEUPLOADER.SIZEERRORMESSAGE' })}\n`;
      }
    }
    if (err !== "" && !isDrag) {
      event.target.value = null;
      setImageValidationError (err);
      return false;
    }
    return true;
  }

  // Function to reset the input file element 
  const resetInputFileField = () => { 
    if (inputFile.current) { 
        inputFile.current.value = ""; 
        inputFile.current.type = "text"; 
        inputFile.current.type = "file"; 
    } 
  }; 

  const handleDrop = event => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    // Now you can handle the dropped files as needed
    selectedFilesHandler(event, files, true);
  };
  
  const allowDrop = event => {
    event.preventDefault();
  };

  return (
    <>
      <div id="main" onDrop={handleDrop} onDragOver={allowDrop}>
        <input
          type="file"
          name="file"
          id="fileUpload"
          className="custom-file-input"
          onChange={event => selectedFilesHandler(event)}
          accept="image/png, image/jpeg, image/jpg"
          ref={inputFile}
          multiple
        />
        <p>{intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.FILEUPLOADER.MESSAGE' })}</p>
    
        {imageValidationError ? (
          <span className="error-msg">{imageValidationError}</span>
        ) : null}
      </div>
    </>
  );
}

export default Uploader;

