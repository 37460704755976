import { useState} from 'react'
import { Link } from "react-router-dom";
import { useOutputContext} from '../../output-listing/OutputListingContext'
import { useIntl } from 'react-intl'
import verify_entries_icon from '../../../../_metronic/assets/CustomIcons/protect.png'
import { VerifyEntriesForm } from '../../../pages/entries/VerifyEntriesForm';

const VerifyEntriesMenu = () => {
    const intl = useIntl()
    const {outputGrid, setOutputVerificationData, outputVerificationData, setVerifiedData, outputIncludeAllShows, setShowVerificationColumns} = useOutputContext();
    const [showVerifyEntries, setShowVerifyEntries] = useState(false)
    const [areResultsCleared, setAreResultsCleared] = useState(false)

    const clearVerification = () => {
        if(!outputIncludeAllShows){
            setAreResultsCleared(true)
            // Hide columns
            setShowVerificationColumns(false)

            // Reset verification data
            setOutputVerificationData({ cleared: true })
            setVerifiedData([])
        }
    }

    const showFilteredVerifiedEntries = (filter: string) => {
        // verification result is not cleared 
        if(!areResultsCleared) { 
            if(!outputIncludeAllShows && 
                Object.keys(outputVerificationData).length && 
                outputVerificationData.filter != filter // already filtered on that criterion
            ){
                setOutputVerificationData({...outputVerificationData, filter})
            }
        }
    };

    const showVerificationDialog = () => {
        if(!outputIncludeAllShows){
            setShowVerifyEntries(true)
        }
    }

    return (
        <>
            {outputGrid.area == "Entries" &&    
                <div>
                    <a href="#" className="btn btn-icon btn-active-light me-2 px-14 py-8" data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                        <div className="fa-item">
                            <img className="verify-entries-icon" src={verify_entries_icon} />
                            <i className="fa fa-sort-down fs-7 text-gray-800"></i>
                            <br/>
                            <span className="fs-8 fw-bold" style={{position: "relative",top: "-1.2px"}}>{intl.formatMessage({id: 'TOOLBAR.LABEL.VERIFICATION'})}</span>
                        </div>
                    </a>

                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 w-225px " data-kt-menu="true" data-kt-element="theme-mode-menu">
                        <div className="menu-item px-2 my-0">
                            <Link to={"#"} data-toggle='modal' data-bs-target='#kt_modal_verify_entries' onClick={showVerificationDialog} className="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="light">
                                <span className="menu-title">{intl.formatMessage({ id: 'TOOLBAR.VERIFICATION.VERIFYENTRIES' })}</span>
                            </Link>
                        </div>
                        <div className="menu-item px-2 my-0">
                            <Link to={"#"} className="menu-link px-3 ps-6 py-2" data-kt-element="mode" data-kt-value="light" onClick={() => showFilteredVerifiedEntries('invalid')}>
                                <span className="menu-title">{intl.formatMessage({ id: 'TOOLBAR.VERIFICATION.SHOWUNVERIFIEDENTRIES' })}</span>
                            </Link>
                        </div>
                        <div className="menu-item px-2 my-0">
                            <Link to={"#"} className="menu-link px-3 ps-6 py-2" data-kt-element="mode" data-kt-value="light" onClick={() => showFilteredVerifiedEntries('valid')}>
                                <span className="menu-title">{intl.formatMessage({ id: 'TOOLBAR.VERIFICATION.SHOWVERIFIEDENTRIES' })}</span>
                            </Link>
                        </div>
                        <div className="menu-item px-2 my-0">
                            <Link to={"#"} className="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="light" onClick={clearVerification}>
                                <span className="menu-title">{intl.formatMessage({ id: 'TOOLBAR.VERIFICATION.CLEARVERIFCATION' })}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            }
            <VerifyEntriesForm 
                show={showVerifyEntries} 
                handleClose={() => setShowVerifyEntries(false)} 
                setAreResultsCleared={setAreResultsCleared} 
            />
        </>
    )
}

export { VerifyEntriesMenu }
