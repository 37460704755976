import { useMemo, useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import axios from 'axios';
import { useForm, FormProvider } from 'react-hook-form'
import history from '../../modules/sgl-utils/unstableHistory'
import { useAlert } from '../../modules/sgl-utils/DialogsProvider'

// Metronic Components
import { KTSVG } from '../../../_metronic/helpers'

// Custom Components
import OutputListingGridStatic from '../../modules/output-listing/OutputListingGridStatic'
import { SearchInput } from '../entries/Detail/SearchInput'
import { useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider'

import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait} from '../../modules/sgl-utils/SglFunctions'

type Props = {
    show: boolean
    handleClose: () => void
    customer_id: number
    default_org: string
    show_id: number
}

const default_horse = { 
    "horse_data":{
        "horse_id":-1,
        "name":"",
        "color":"",
        "sex":"",
        "age":0,
        "height":"",
        "meas_card_no":"",
        "dob":'0000-00-00',
        "breed":"",
        "sire":"",
        "dam":"",
        "status":"",
        "customer_id":0,
        "sgl_id":null,
        "microchip_number":"",
        "usef_measurement_cards":"[]",
        "on_vet_hold":0
    },
    "memberships_data": []
}

const { PUBLIC_URL } = process.env
const modalsRoot = document.getElementById('root-modals') || document.body

const AddHorseForm = ({ show, handleClose , customer_id, default_org, show_id }: Props) => {
    const intl = useIntl()

    const [organization, setOrganization] = useState(default_org)
    const [resultGridRef, setResultGridRef] = useState<any>(null)
    const [rowData, setRowData] = useState<any>([])
    const [search_status, setSearchStatus] = useState("")

    const methods = useForm();
    const { register, handleSubmit, watch, reset, formState, getValues, setValue } = methods
    const [searchedHorseName, setSearchedHorseName] = useState('')// Keep track of previous search
    const [searchedOrgName, setSearchedOrgName] = useState('')// Keep track of previous org
    const [enableLookup, setEnableLookup] = useState(false)// Keep track of previous org
    const [keyPressTimeout, setKeyPressTimeout] = useState<any>('')
    const loadingOverlay = useLoadingOverlay()
    const alertDialog = useAlert()

    const columnDefs = [
        { field: getDisplayField(default_org), headerName: organization + ' ' + intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.NUM' })},
        { field: 'name', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.HORSE' }) },
        { field: 'trainer', width: 200, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.TRAINER' }) },
        { field: 'owner', width: 200, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.OWNER' }) },
        { field: 'location', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.LOCATION' }) },
    ]

    const rowClassRules = useMemo(() => {
        return {
            'bg-expired': 'data.suspended',
            'bg-none': 'data.none',
        };
    }, []);

    const containerStyle = useMemo(() => ({ width: '100%', height: '250px' }), []);

    function getDisplayField(org:string){
        if(org === 'USEF') {
            return 'usef_no'
        } else if(org === 'EC') {
            return 'ec_ppn'
        } else if(org === 'FEI') {
            return 'fei_id'
        }
    }

    function callSearchHorseApi(search_term: string){
        if(search_term.trim()){
            loadingOverlay({show: true})
            axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/searchHorse', {
                params: {
                    horse_name: search_term,
                    customer_id: customer_id,
                    show_id: methods.getValues('entry.show_id')?methods.getValues('entry.show_id'):show_id,
                }
            }) 
            .then(async result => { 
                loadingOverlay({show: false})
                if(result.data.success){
                    if(result.data.update_horse){ // update horse directly 
             
                    }else { // open search dialog
                        setRowData(result.data.horses_list)
                    }
                    
                } 
            })
        }
    }

    function callLookupHorseApi(search_term: string, org_name:string, lookup_horse: boolean, event:any){
        if(search_term?.trim() == '' || !search_term){
            return;
        }

        if(searchedHorseName !== search_term || searchedOrgName !== org_name || enableLookup !== lookup_horse){
            setSearchedHorseName(search_term);
            setSearchedOrgName(org_name);
            setEnableLookup(lookup_horse);
            (event?.target?.type == "button") && loadingSpinnerBtnWait(event)
            resultGridRef?.showLoadingOverlay()
            axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/searchHorseByName', {
                params: {
                horse_name: search_term,
                customer_id: customer_id,
                org_name: org_name,
                lookup_horse: lookup_horse,
                show_id: show_id
                }
            }) 
            .then(result => { 
                (event?.target?.type == "button") && loadingSpinnerBtnRelease(event)
                setSearchStatus(result.data.horse_count_status)
                setRowData(result.data.horses_list)
            }).finally(() => {
                resultGridRef?.hideOverlay()
            })
        }
    }

    const onOrganizationChange = (e: any) => {
        setOrganization(intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.LABEL.' + e.target.value }))
        let columnDefs = resultGridRef?.getColumnDefs()
        columnDefs[0].headerName = e.target.value + ' ' + intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.NUM' })
        columnDefs[0].field = getDisplayField(e.target.value) 
        resultGridRef.setColumnDefs(columnDefs)
        callLookupHorseApi(methods.getValues('horse_search_in_modal'), e.target.value, true, e)
    }

    const submitCancel = () => {
        if(handleClose){
            handleClose()
        }
    }

    const addNewHorse = () =>{
        let search_text = methods.getValues('horse_search_in_modal');
        if(!search_text || search_text?.trim() == ''){
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDHORSE.WARNING.HORSE' })})
            return;
        }

        loadingOverlay({show: true})
        return axios.post( process.env.REACT_APP_NEST_API_URL + '/horses/detail', {
        params: {
            horse_id: 0,
            customer_id: customer_id,
            data: {horse: {name: methods.getValues('horse_search_in_modal')}},
            show_id: show_id
        }
        }).then((response) => {
            loadingOverlay({show: false})
            if(response.data.success) {
                handleClose()
                history.push(`${PUBLIC_URL}/horses/detail/${response.data.horse_id}?customer_id=${response.data.customer_id}`, { allowNavigation: true })
            } else {
                alertDialog({message: response.data.error_message, title: 'Error'})
            }
            
        }).catch((error) => {
            loadingOverlay({show: false})
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        });
        
    }

    async function getSelectedHorse(horse: any, organization: any, number_lookup = false){
        // Create new horse from lookup data
        loadingOverlay({show: true})
        axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/selectedHorse', {
            params: {
                horse: horse,
                customer_id: customer_id,
                number_lookup: false,
                show_id: show_id,
                organization: organization
            }
        }) 
        .then(async (result) => { 
            loadingOverlay({show: false})
            if(result.data.success){
                loadingOverlay({show: true})
                // Parse and transform result.data.horse
                let horseData = result?.data?.horse?.horse_data;
                let membershipsData = result?.data?.horse?.memberships_data;

                // Change horse_data object name to horse
                let transformedHorseData = {
                    horse: horseData,
                    organization_members_added: membershipsData?.filter((membership: { membership_number: any }) => membership?.membership_number)
                }
                    
                return axios.post( process.env.REACT_APP_NEST_API_URL + '/horses/detail', {
                    params: {
                        horse_id: 0,
                        customer_id: customer_id,
                        data: transformedHorseData,
                        show_id: show_id
                    }
                    }).then((response) => {
                        loadingOverlay({show: false})
                        if(response.data.success) {
                            handleClose()
                            history.push(`${PUBLIC_URL}/horses/detail/${response.data.horse_id}?customer_id=${response.data.customer_id}`, { allowNavigation: true })
                        } else {
                            alertDialog({message: response.data.error_message, title: 'Error'})
                        }
                        
                    }).catch((error) => {
                        loadingOverlay({show: false})
                        if (error.response) {
                            alertDialog({message: error.response.data.error});
                        }
                    });
            
            }
        })
    }

    const selectHorse = (row_data:any = {}) =>{
        var selectedRow = resultGridRef?.getSelectedRows()
        if(selectedRow?.length > 0){
            if(selectedRow[0].horse_id > 0 && selectedRow[0].h_customer_id){
                // Navigate to existing horse
                handleClose()
                history.push(`${PUBLIC_URL}/horses/detail/${selectedRow[0].horse_id}?customer_id=${selectedRow[0].h_customer_id}`, { allowNavigation: true })
            }else{
                getSelectedHorse(selectedRow[0], organization)
            }    
        }else if(Object.keys(row_data)?.length > 0){ // Row double click handling 
            if(row_data.horse_id > 0 && row_data.h_customer_id){
                // Navigate to existing horse
                handleClose()
                history.push(`${PUBLIC_URL}/horses/detail/${row_data.horse_id}?customer_id=${row_data.h_customer_id}`, { allowNavigation: true })
            }else{
                getSelectedHorse(row_data, organization)
            }  
        }
    }

    const callLookup = (e:any) =>{
        callLookupHorseApi(methods.getValues('horse_search_in_modal'), organization, true, e)
    }

    const handleKeyPress = (event:any) => {
        if (event.key === 'Enter') {
            // callLookupHorseApi(methods.getValues('horse_search_in_modal'), organization, true, event)
            callSearchHorseApi(methods.getValues('horse_search_in_modal'))
        }else{
            const isTabKey = event.key === 'Tab';
            if(isTabKey){
                return
            }
            const timeout = setTimeout(function(){
                // callLookupHorseApi(methods.getValues('horse_search_in_modal'), organization, true, event)
                callSearchHorseApi(methods.getValues('horse_search_in_modal'))
            }
            , 1000)
            if(keyPressTimeout){
                clearTimeout(keyPressTimeout);
            }
            setKeyPressTimeout(timeout)
        }
    };

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            enforceFocus={true}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-850px search-form'
            show={show}
            onHide={submitCancel}
            onKeyDown={(event :any) => {
                const activeElement = document.activeElement;
                if (event.key === "Enter" && activeElement && activeElement.tagName !== 'BUTTON') {
                    selectHorse()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">
                    {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.ADDHORSE.TITLE.HORSE' }) + ' - ' + organization + ' ' + intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.TITLE.LOOKUP' })}
                </h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <FormProvider {...methods}>
                    <form noValidate className='form ' onSubmit={e => e.preventDefault()}>
                        <div className='card-body p-0'>
                            <div className='row mb-2'>
                                <div className='col-lg-12'>
                                    <div className='row'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' htmlFor='GOVERNINGORG' data-tooltip-id="ENTRIES.DETAIL.MODAL.HORSELOOKUP.LABEL.GOVERNINGORG">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.LABEL.GOVERNINGORG' })}</label>
                                        <div className='col-lg-1 d-flex align-items-center w-lg-100px ps-6'>
                                            <input className="form-check-sm me-1" type="radio" value="USEF" id="USEF" name='GOVERNINGORG' defaultChecked={organization == 'USEF'} onChange={onOrganizationChange} tabIndex={1}/>
                                            <label className='col-form-label fs-5 py-1 ignore-max-width me-5' htmlFor='USEF' data-tooltip-id="ENTRIES.DETAIL.MODAL.HORSELOOKUP.LABEL.USEF"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.LABEL.USEF' })}</label>
                                        </div>
                                        <div className='col-lg-1 d-flex align-items-center w-lg-75px ps-6 me-3'>
                                            <input className="form-check-sm me-1" type="radio" value="EC" id="EC" name='GOVERNINGORG' defaultChecked={organization == 'EC'} onChange={onOrganizationChange} tabIndex={1}/>
                                            <label className='col-form-label fs-5 py-1 ignore-max-width me-5' htmlFor='EC' data-tooltip-id="ENTRIES.DETAIL.MODAL.HORSELOOKUP.LABEL.EC"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.LABEL.EC' })}</label>
                                        </div>
                                        {/* {
                                            sglLite? null : */}
                                            <div className='col-lg-1 d-flex align-items-center w-lg-100px ps-6'>
                                                <input className="form-check-sm me-1" type="radio" value="FEI" id="FEI" name='GOVERNINGORG' defaultChecked={organization == 'FEI'} onChange={onOrganizationChange} tabIndex={1}/>
                                                <label className='col-form-label fs-5 py-1 ignore-max-width me-5' htmlFor='FEI' data-tooltip-id="ENTRIES.DETAIL.MODAL.HORSELOOKUP.LABEL.FEI"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.LABEL.FEI' })}</label>
                                            </div>
                                        {/* } */}
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className='row mb-2'>
                                        <label className='col col-form-label fs-5 py-1' htmlFor='USEFSEARCH'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.LABEL.' + organization + 'SEARCH' })}</label>
                                    </div>
                                    <div className='row mb-0'>
                                        <div className='col-lg-1 w-lg-325px'>
                                            <div className='row'>
                                                <SearchInput 
                                                    id='horse_search_in_modal'
                                                    fieldName={'horse_search_in_modal'} 
                                                    containerClass={'pe-0'} 
                                                    onSearch={handleKeyPress}
                                                    enableFocus={true}
                                                    tabIndex={2}
                                                    className={"form-control form-control-solid px-11 py-1 bg-white"}
                                                />
                                            </div>
                                        </div>
                                        <div className={'col-lg-1 w-lg-175px d-flex align-items-center'}>
                                            <button type='button'  onClick={(event)=>{callLookup(event);}} className="btn btn-sm btn-secondary fw-bold px-2 py-0 col-12 text-uppercase h-30px" tabIndex={3} data-tooltip-id={`ENTRIES.DETAIL.MODAL.HORSELOOKUP.BUTTON.${organization}NAMELOOKUP`}>
                                                <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-0 mb-0" role="status" aria-hidden="true" style={{position: "relative",bottom: "1px"}}></span>
                                                {organization + ' ' + intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.BUTTON.NAMELOOKUP' })}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-12 col-form-label fs-5 py-1'>{search_status}</label>
                            </div>
                            <div className='row mb-2'>
                                <OutputListingGridStatic
                                    columnDefs={columnDefs}
                                    rowData={rowData}
                                    rowClassRules={rowClassRules}
                                    containerStyle={containerStyle}
                                    setGridRef={setResultGridRef}
                                    entriesSearchDialog={true}
                                    tabIndex={4}
                                    onRowDoubleClicked={(row_data: any) => {selectHorse(row_data)}}
                                ></OutputListingGridStatic>
                            </div>
                        </div>

                        <div className='card-footer d-flex justify-content-end py-3 px-0'>
                            <button type='button' onClick={()=>{addNewHorse();}} className='btn btn-sm btn-secondary fw-bold text-uppercase' tabIndex={5} id={'add_new_horse_lookup_form'} data-tooltip-id="ENTRIES.DETAIL.MODAL.HORSELOOKUP.BUTTON.ADDNEW">
                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.BUTTON.ADDNEW' })}
                            </button>

                            <button type='button' onClick={submitCancel} className='btn btn-sm btn-secondary me-4 fw-bold text-uppercase' style={{ marginLeft: "auto" }} tabIndex={6}>
                                {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                            </button>

                            <button type='button' onClick={()=>{selectHorse();}} className='btn btn-sm btn-dark fw-bold text-uppercase' tabIndex={7} data-tooltip-id="ENTRIES.DETAIL.MODAL.HORSELOOKUP.BUTTON.SELECT">
                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.HORSELOOKUP.BUTTON.SELECT' })}
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { AddHorseForm }