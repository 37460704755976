/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import BooleanCheckmarkRenderer from '../../modules/output-listing/renderers/BooleanCheckmarkRenderer';

const OrganizationsList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const customer_id = useAppSelector(state=> state.showCompany.company_id);

  const columnDefs = [
    // { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.COMMONS.SGLID'}), filter: 'agNumberColumnFilter', filterParams: { suppressAndOrCondition: true,}, },
    { field: 'organization_id', headerName: intl.formatMessage({ id: 'LIST.ORGANIZATIONS.ORGANIZATIONID' }), filter: 'agNumberColumnFilter', hide: true },
    { field: 'priority', headerName: intl.formatMessage({ id: 'LIST.ORGANIZATIONS.PRIORITY' }), filter: 'agNumberColumnFilter'},
    { field: 'name', width: 300, headerName: intl.formatMessage({ id: 'LIST.ORGANIZATIONS.NAME' }), filter: 'agTextColumnFilter', },
    { field: 'code', headerName: intl.formatMessage({ id: 'LIST.ORGANIZATIONS.CODE' }), filter: 'agTextColumnFilter', },
    { field: 'city', headerName: intl.formatMessage({ id: 'LIST.ORGANIZATIONS.CITY' }), filter: 'agTextColumnFilter', },
    { field: 'state', headerName: intl.formatMessage({ id: 'LIST.ORGANIZATIONS.STATE' }), filter: 'agTextColumnFilter', },
    { field: 'managed', headerName: intl.formatMessage({ id: 'LIST.ORGANIZATIONS.MANAGED' }), cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' } },
    { field: 'system', headerName: intl.formatMessage({ id: 'LIST.ORGANIZATIONS.SYSTEM' }), cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }},
    { field: 'horse', headerName: intl.formatMessage({ id: 'LIST.ORGANIZATIONS.HORSE' }), cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }  },
    { field: 'person', headerName: intl.formatMessage({ id: 'LIST.ORGANIZATIONS.PERSON' }), cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' } },
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.ORGANIZATIONS' })}</PageTitle>
      <OutputListingComponent key={customer_id} area={'Organizations'} columnDefs={columnDefs} apiEndPoint={'organizations/list'}
        onRowDoubleClicked={(row_data) => {navigate('/organizations/detail/'+row_data.organization_id+`?customer_id=${customer_id}`)}}
        onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL+'/organizations/detail/'+row_data.organization_id+`?customer_id=${customer_id}`,'_blank')}>

      </OutputListingComponent>
    </>
  )
}

export { OrganizationsList }
