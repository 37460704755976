import { createSlice } from "@reduxjs/toolkit";
  
// Define initial state for currentDetailPageInfo object
const initialState = {
    current_list_record_index: -1,
    open_forms_list: [],
    list_ids : [],
}

const currentDetailPageInfoSlice = createSlice({
    name: 'currentDetailPageInfo',
    initialState: initialState,
    reducers: {
      setCurrentListRecordIndex: (state, action)=>{
          state.current_list_record_index = action.payload
      },
      setOpenFormsList: (state, action)=>{
        state.open_forms_list = action.payload
      },
      setListIds: (state, action)=>{
        state.list_ids = action.payload
      },
    },
  })
  
  export const { setCurrentListRecordIndex, setOpenFormsList, setListIds} = currentDetailPageInfoSlice.actions

  export default currentDetailPageInfoSlice.reducer