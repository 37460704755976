import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_NEST_API_URL
const AUTH_LOCAL_STORAGE_KEY = 'sgl-auth-react-cloud'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot_password`
export const LOGOUT_URL = `${API_URL}/auth/logout`

// Server should return AuthModel
export function login(email: string, password: string, remember_me: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
    remember_me,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function logoutUser(inactivityLogout = false){
  let company =  localStorage.getItem('currentShowCompany')
  let authInfo: any = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) // get auth info from local storage
  let user_id = JSON.parse(authInfo).id 

  return axios.get<AuthModel>(LOGOUT_URL, {
    params: {
      token: localStorage.getItem('browser_token'),
      customer_id: company? JSON.parse(company).company_id: 0,
      unique_token: sessionStorage.getItem('user-tab-token'),
      user_id: user_id,
      inactivityLogout: inactivityLogout
    }
  })
}