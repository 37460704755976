import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import './Checklist/tab.css';
import { ChecklistItem } from './Checklist/ChecklistItem';

const ChecklistTab = () => {
    const intl = useIntl();
    const methods = useFormContext()

    const checklist = methods.watch('checklist')

    return (
        <>
            {/* Remove id for Original layout and first layout */}
            <div id="entry-checklist-tab" className='form-group mb-2 px-3 py-5'>
                {
                    checklist?.length ? checklist?.map(item => {
                        return(
                            <ChecklistItem data={item} />
                        )
                    }) : <div className='fw-bold fs-5'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CHECKLIST.LABEL.NOITEMSFOUND'})}</div>
                }
            </div>
        </>
    );
}

export { ChecklistTab }