import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import {useFormContext} from "react-hook-form";
import BooleanCheckmarkRenderer from '../../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import NumberRenderer from '../../../modules/output-listing/renderers/NumberRenderer';
import useAccessChecker from '../../../modules/hooks/use-access-checker';
import { useAppSelector } from '../../../redux/hooks';

const ClassesTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext();
    const { hasAreaWritePermission } = useAccessChecker()
    const show_financials_locked = useAppSelector(state=> state.currentShow.islocked)

    const columnDefs = [
        { field: 'number', headerName: intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.CLASSES.CLASS' }),  cellClass: 'ag-right-aligned-cell' },
        { field: 'name',flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.CLASSES.NAME' }),    },
        { field: 'required', headerName: intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.CLASSES.REQUIRED' }),   cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }},
        { field: 'results_verified', headerName: intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.CLASSES.VERIFIED' }),    cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' } },
        { field: 'class_price', headerName: intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.CLASSES.CLASSPRICE' }),   cellClass: 'ag-right-aligned-cell', cellRenderer: NumberRenderer},
        { field: 'description',flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.CLASSES.DESCRIPTION' }),    },
    ]

    const rowData = methods.getValues('classesList')

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

    return (
        <>
            <div className='form-group'>
                <div className='row mb-2 pe-4'>
                    <label className='col col-form-label fs-5 py-1 text-end' data-tooltip-id="DIVISION.DETAIL.TAB.GENERAL.LABEL.TOTAL">{rowData.length}</label>
                    <label className='col-lg-1 w-50px p-0'></label>
                </div>
                <div className='row mb-2 pe-4'>
                    <div className='col'>
                        <OutputListingGridStatic onRowDoubleClicked={(row_data) => {props.callbackFromParent(row_data.class_id, "Classes", "ClassDetail", row_data)}} area ={"Divisions - Classes"}  columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle}></OutputListingGridStatic>
                    </div>
                    <div className='col-lg-1 w-50px p-0'>

                    {  hasAreaWritePermission('classes') &&
                        <button type="button" disabled={props.divisionID == 0 || props.divisionID == '0' || show_financials_locked ? true : false} className="btn btn-sm btn-secondary me-2 fw-bold px-2 py-2" onClick ={() => props.callbackFromParent(0,"Classes","ClassDetail", {}, methods?.getValues('division'))} data-tooltip-id="DIVISION.DETAIL.TAB.CLASSES.BUTTON.ADD">
                            <i className="fas fa-plus fs-5 px-1 py-3" ></i>
                        </button>
                    }
                    </div>
                </div>
            </div>
        </>
    );
}

export { ClassesTab }