import {useIntl} from 'react-intl'
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { useFormContext, Controller  } from "react-hook-form";
import Select from "react-select";
import moment from 'moment';
import {reactSelectStyles, date_options, time_options_12hours, setDate, setTime} from '../../../modules/sgl-utils/fieldControls';
import { NumericFormat} from 'react-number-format';
import { maskDateInput } from '../../../modules/sgl-utils/fieldControls';
import useAccessChecker from '../../../modules/hooks/use-access-checker';
import { useEffect, useState } from 'react';
import { useConfirm, useFlashAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import { useAppSelector } from '../../../redux/hooks';
import axios from 'axios';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const AdditionalTab = (props) => {
  const intl = useIntl();
  const methods = useFormContext();
  const { hasSpecialPermissionToAccess } = useAccessChecker()
  const [rollingShow, setRollingShow] = useState()
  const confirmDialog = useConfirm()
  const loadingOverlay = useLoadingOverlay()
  const showId = methods.watch('show.show_id')
  const customerId = useAppSelector(state => state.showCompany.company_id)
  const flashAlert = useFlashAlert()

  useEffect(() => {
    setRollingShow(methods.watch('show.rolling_show') ? true : false)
  }, [methods.watch('show.rolling_show')])

  const toggleRollingShow = async (checked) => {
    const confirm = await confirmDialog({ message: `Are you sure you want to ${checked ? 'set' : 'unset'} current show as rolling show?`})
    if (confirm) {
        try {
            loadingOverlay({ show: false })
            const response = await axios.patch(`${NEST_API_URL}/shows/toggleRollingShow`, {
                customer_id: customerId,
                show_id: showId,
                rolling_show: checked
            })

            if (response.data.success) {
                setRollingShow(checked)
                flashAlert({ type: 'success', message: `Show ${checked? 'set' : 'unset'} as rolling show.`});
            }
        } catch {
            flashAlert({ type: 'error', message: `Something went wrong..`});
        }
        finally {
            loadingOverlay({ show: false })
        }
    }
  }

  const show_financials_locked = methods.watch('show.islocked')

  return (
    <>
    <div className="form-group row px-2"> {/* Column Group */}
            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='paper_entry_closing_date' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.LABEL.PAPERENTRYCLOSINGDATE">
                <span className=''>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.PAPERENTRY'})}</span>
                </label>

             
                <div className='col-lg-3 '>
                    <div className="input-group date">
                        <Controller
                            name="show.paper_entry_closing_date"
                            render={({ field: { onChange, value, name } }) => {
                                const formattedValue = (value && moment(value).isValid())
                                    ? moment(value).format('MM/DD/YYYY')
                                    : '';

                                return (
                                    <Flatpickr
                                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                                        id="paper_entry_closing_date"
                                        name={name}
                                        autoComplete="off"
                                        value={formattedValue}
                                        placeholder="MM/DD/YYYY"
                                        options={date_options}
                                        onClose={(value, dateStr, instance) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }

                                            // Use setDate with dateStr and onChange
                                            setDate(dateStr, onChange);
                                        }}
                                        onChange={(value, dateStr, instance) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }

                                            // Use setDate with dateStr and onChange
                                            setDate(dateStr, onChange);
                                        }}
                                        onInput={(e) => { maskDateInput(e) }}
                                    />
                                );
                            }}
                        />
                        <div className="input-group-append">
                            <label htmlFor='paper_entry_closing_date' className='date-label'
                                onClick={(e) => {
                                    e.stopPropagation(); 
                                    document.getElementById('paper_entry_closing_date')._flatpickr.open(); 
                                }}
                            >
                                <span className="input-group-text">
                                    <i className="la la-calendar"></i>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                {methods.getValues('show.paper_entry_closing_date')? <label className='col-lg col-form-label  fw-bold fs-5 py-1 me-5 text-danger'>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.PAPERENTRYCLOSINGDATE'}) + " " + moment(methods.getValues('show.paper_entry_closing_date')).format('MM/DD/YYYY')}</label>: ""}
              
            </div>

            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='company_payment_gateway_id' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.LABEL.PAYMENTGATEWAY">
                <span className=''>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.PAYMENTGATEWAY'})}</span>
                </label>

                <div className='col-lg-3 '>
                  {
                    props.form_meta.company_payment_gateways && 
                    <Controller
                        name="show.company_payment_gateway_id"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                        <Select
                            options = {props.form_meta.company_payment_gateways}
                            defaultValue={props.form_meta.company_payment_gateways.find((ss) => ss.value === value)}
                            id = "company_payment_gateway_id"
                            name={name}
                            isSearchable={true}
                            onChange={(company_payment_gateways) => {
                                onChange(company_payment_gateways.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                        />    
                        )}
                    />
                }
                </div>

                &nbsp;&nbsp;&nbsp;&nbsp;
              {/* {methods.watch('show.company_payment_gateway_id')} */}
              
            </div>

            <div className='row mb-2 mt-5'>
            <fieldset>
            <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.GROUPLABEL.AUTOMATICFEES'})} <b></b></legend>


            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='post_entry_fee' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.POSTENTRYFEE">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.POSTENTRYFEE'})}</label>

                <div className='col-lg-3 '>
                    {
                    props.form_meta.show_fees && 
                    <Controller
                        name="show.post_entry_fee"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                        <Select
                            options = {props.form_meta.show_fees}
                            value={
                                methods.getValues('show.post_entry_fee') === 0
                                  ? props.form_meta.show_fees.filter(
                                      (option) => option.label === 'None'
                                    )
                                  : props.form_meta.show_fees.find((ss) => ss.value === value)
                            }
                            id = "post_entry_fee"
                            name={name}
                            isSearchable={true}
                            onChange={(show_fees) => {
                                onChange(show_fees.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                        />    
                        )}
                    />
                    }
                </div>

                <label className='col-lg-1 col-form-label  fs-5 py-1 w-125px' htmlFor='post_entry_date' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.POSTENTRYFEECHARGEAFTER">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.CHARGEAFTER'})}</label>

                <div className='col-lg-2'>
                    <div className="input-group date">
                        <Controller
                            name="show.post_entry_date"
                            render={({ field: { onChange, value, name } }) => {
                                const formattedValue = (value && moment(value).isValid())
                                    ? moment(value).format('MM/DD/YYYY')
                                    : '';

                                return (
                                    <Flatpickr
                                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                                        id="post_entry_date"
                                        name={name}
                                        autoComplete="off"
                                        value={formattedValue}
                                        placeholder="MM/DD/YYYY"
                                        options={date_options}
                                        onClose={(value, dateStr, instance) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }

                                            // Use setDate with dateStr and onChange
                                            setDate(dateStr, onChange);
                                        }}
                                        onChange={(value, dateStr, instance) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }

                                            // Use setDate with dateStr and onChange
                                            setDate(dateStr, onChange);
                                        }}
                                        onInput={(e) => { maskDateInput(e) }}
                                    />
                                );
                            }}
                        />
                        <div className="input-group-append">
                            <label htmlFor='post_entry_date' className='date-label'
                                onClick={(e) => {
                                    e.stopPropagation(); 
                                    document.getElementById('post_entry_date')._flatpickr.open(); 
                                }}
                            >
                            <span className="input-group-text">
                                <i className="la la-calendar"></i>
                            </span>
                            </label>
                        </div>
                    </div>
                </div>

                <label className='col-lg-1 col-form-label  fs-5 py-1 w-50px' htmlFor='AT' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.POSTENTRYFEEAT">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.AT'})}</label>
                <div className='col-lg-2 w-150px'>
                    <div className="input-group date">
                    <Controller
                        name="show.post_entry_time"
                        render={({
                            field: { onChange, value, name },
                        }) => (

                        <Flatpickr 
                            className="form-control form-control-sm  fs-6 min-h-20px py-1"
                            id = "post_entry_time"
                            name = {name}
                            autoComplete={"off"}
                            value={value && value !== "00:00:00" ? value : null}
                            placeholder="Select Time"
                            options={time_options_12hours}
                            onClose={(value) => setTime(value, onChange)}
                            onChange={(value) => setTime(value, onChange)}
                        />
                        )}
                    />
                        <div className="input-group-append">
                            <label htmlFor='post_entry_time' className='date-label'
                                onClick={(e) => {
                                    e.stopPropagation(); 
                                    document.getElementById('post_entry_time')._flatpickr.open(); 
                                }}
                            >
                                <span className="input-group-text">
                                    <i className="la la-clock-o"></i>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                </div>

            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='entry_scratch_fee' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.ENTRYSCRATCHFEE">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.ENTRYSCRATCHFEE'})}</label>

                <div className='col-lg-3 '>
                {
                    props.form_meta.show_fees && 
                    <Controller
                        name="show.entry_scratch_fee"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                        <Select
                            options = {props.form_meta.show_fees}
                            value={
                                methods.getValues('show.entry_scratch_fee') === 0
                                  ? props.form_meta.show_fees.filter(
                                      (option) => option.label === 'None'
                                    )
                                  : props.form_meta.show_fees.find((ss) => ss.value === value)
                            }
                            id = "entry_scratch_fee"
                            name={name}
                            isSearchable={true}
                            onChange={(show_fees) => {
                                onChange(show_fees.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                        />    
                        )}
                    />
                }
                </div>

                <label className='col-lg-2 col-form-label  fs-5 py-1 w-125px' htmlFor='class_bandit_fee' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.BANDITFEE">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.BANDITFEE'})}</label>

                <div className='col-lg-2 '>
                 {
                    props.form_meta.show_fees && 
                    <Controller
                        name="show.class_bandit_fee"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                        <Select
                            options = {props.form_meta.show_fees}
                            value={
                                methods.getValues('show.class_bandit_fee') === 0
                                  ? props.form_meta.show_fees.filter(
                                      (option) => option.label === 'None'
                                    )
                                  : props.form_meta.show_fees.find((ss) => ss.value === value)
                            }
                            id = "class_bandit_fee"
                            name={name}
                            isSearchable={true}
                            onChange={(show_fees) => {
                                onChange(show_fees.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                        />    
                        )}
                    />
                }
                </div>
            </div>

            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='ingate_add_fee' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.INGATEADDFEE">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.INGATEADDAFEE'})}</label>

                <div className='col-lg-3 '>
                 {
                    props.form_meta.show_fees && 
                    <Controller
                        name="show.ingate_add_fee"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                        <Select
                            options = {props.form_meta.show_fees}
                            value={
                                methods.getValues('show.ingate_add_fee') === 0
                                  ? props.form_meta.show_fees.filter(
                                      (option) => option.label === 'None'
                                    )
                                  : props.form_meta.show_fees.find((ss) => ss.value === value)
                            }
                            id = "ingate_add_fee"
                            name={name}
                            isSearchable={true}
                            onChange={(show_fees) => {
                                onChange(show_fees.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                        />    
                        )}
                    />
                }
                </div>
            </div>

            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='class_add_fee' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.CLASSADDFEE">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.CLASSADDFEE'})}</label>

                <div className='col-lg-3 '>
                {
                    props.form_meta.show_fees && 
                    <Controller
                        name="show.class_add_fee"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                        <Select
                            options = {props.form_meta.show_fees}
                            value={
                                methods.getValues('show.class_add_fee') === 0
                                  ? props.form_meta.show_fees.filter(
                                      (option) => option.label === 'None'
                                    )
                                  : props.form_meta.show_fees.find((ss) => ss.value === value)
                            }
                            id = "class_add_fee"
                            name={name}
                            isSearchable={true}
                            onChange={(show_fees) => {
                                onChange(show_fees.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                        />    
                        )}
                    />
                }
                </div>

                <label className='col-lg-1 col-form-label  fs-5 py-1 w-125px' htmlFor='class_add_date' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.CLASSADDFEECHARGEAFTER">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.CHARGEAFTER'})}</label>

                <div className='col-lg-2'>
                <div className="input-group date">
                        <Controller
                            name="show.class_add_date"
                            render={({ field: { onChange, value, name } }) => {
                                const formattedValue = (value && moment(value).isValid())
                                    ? moment(value).format('MM/DD/YYYY')
                                    : '';

                                return (
                                    <Flatpickr
                                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                                        id="class_add_date"
                                        name={name}
                                        autoComplete="off"
                                        value={formattedValue}
                                        placeholder="MM/DD/YYYY"
                                        options={date_options}
                                        onClose={(value, dateStr, instance) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }

                                            // Use setDate with dateStr and onChange
                                            setDate(dateStr, onChange);
                                        }}
                                        onChange={(value, dateStr, instance) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }

                                            // Use setDate with dateStr and onChange
                                            setDate(dateStr, onChange);
                                        }}
                                        onInput={(e) => { maskDateInput(e) }}
                                    />
                                );
                            }}
                        />
                        <div className="input-group-append">
                            <label htmlFor='class_add_date' className='date-label'
                                onClick={(e) => {
                                    e.stopPropagation(); 
                                    document.getElementById('class_add_date')._flatpickr.open(); 
                                }}
                            >
                                <span className="input-group-text">
                                    <i className="la la-calendar"></i>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                <label className='col-lg-1 col-form-label  fs-5 py-1 w-50px' htmlFor='class_add_fee_time' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.CLASSADDFEEAT">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.AT'})}</label>
                <div className='col-lg-2 w-150px'>
                    <div className="input-group date">
                    <Controller
                        name="show.class_add_fee_time"
                        render={({
                            field: { onChange, value, name },
                        }) => (

                        <Flatpickr 
                            className="form-control form-control-sm  fs-6 min-h-20px py-1"
                            id = "class_add_fee_time"
                            name = {name}
                            autoComplete={"off"}
                            value={value && value !== "00:00:00" ? value : null}
                            placeholder="Select Time"
                            options={time_options_12hours}
                            onClose={(value) => setTime(value, onChange)}
                            onChange={(value) => setTime(value, onChange)}
                        />
                        )}
                    />
                        <div className="input-group-append">
                            <label htmlFor='class_add_fee_time' className='date-label'
                              onClick={(e) => {
                                e.stopPropagation(); 
                                document.getElementById('class_add_fee_time')._flatpickr.open(); 
                              }}
                            >
                                <span className="input-group-text">
                                    <i className="la la-clock-o"></i>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className='col-lg-1 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom me-2'>
                        <input
                        {...methods.register( 'show.class_add_fee_each', 
                        {
                          required: false
                        })
                      }
                        className='form-check-input'
                        type='checkbox'
                        id='class_add_fee_each'
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='class_add_fee_each' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.CLASSADDFEEEACH"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.EACH'})}</label>
                </div>
            </div>


            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='class_drop_fee' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.CLASSDROPFEE">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.CLASSDROPFEE'})}</label>

                <div className='col-lg-3 '>
                 {
                    props.form_meta.show_fees && 
                    <Controller
                        name="show.class_drop_fee"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                        <Select
                            options = {props.form_meta.show_fees}
                            value={
                                methods.getValues('show.class_drop_fee') === 0
                                  ? props.form_meta.show_fees.filter(
                                      (option) => option.label === 'None'
                                    )
                                  : props.form_meta.show_fees.find((ss) => ss.value === value)
                            }
                            id = "class_drop_fee"
                            name={name}
                            isSearchable={true}
                            onChange={(show_fees) => {
                                onChange(show_fees.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                        />    
                        )}
                    />
                }
                </div>

                <label className='col-lg-1 col-form-label  fs-5 py-1 w-125px' htmlFor='class_drop_fee_date' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.CLASSDROPFEECHARGEAFTER">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.CHARGEAFTER'})}</label>

                <div className='col-lg-2'>
                <div className="input-group date">
                        <Controller
                            name="show.class_drop_fee_date"
                            render={({ field: { onChange, value, name } }) => {
                                const formattedValue = (value && moment(value).isValid())
                                    ? moment(value).format('MM/DD/YYYY')
                                    : '';

                                return (
                                    <Flatpickr
                                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                                        id="class_drop_fee_date"
                                        name={name}
                                        autoComplete="off"
                                        value={formattedValue}
                                        placeholder="MM/DD/YYYY"
                                        options={date_options}
                                        onClose={(value, dateStr, instance) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }

                                            // Use setDate with dateStr and onChange
                                            setDate(dateStr, onChange);
                                        }}
                                        onChange={(value, dateStr, instance) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }

                                            // Use setDate with dateStr and onChange
                                            setDate(dateStr, onChange);
                                        }}
                                        onInput={(e) => { maskDateInput(e) }}
                                    />
                                );
                            }}
                        />
                        <div className="input-group-append">
                            <label htmlFor='class_drop_fee_date' className='date-label'
                                onClick={(e) => {
                                    e.stopPropagation(); 
                                    document.getElementById('class_drop_fee_date')._flatpickr.open(); 
                                  }}
                            >
                                <span className="input-group-text">
                                    <i className="la la-calendar"></i>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                <label className='col-lg-1 col-form-label  fs-5 py-1 w-50px' htmlFor='class_drop_fee_time' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.CLASSDROPFEEAT">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.AT'})}</label>
                <div className='col-lg-2 w-150px'>
                    <div className="input-group date">
                    <Controller
                        name="show.class_drop_fee_time"
                        render={({
                            field: { onChange, value, name },
                        }) => (

                        <Flatpickr 
                            className="form-control form-control-sm  fs-6 min-h-20px py-1"
                            id = "class_drop_fee_time"
                            name = {name}
                            autoComplete={"off"}
                            value={value && value !== "00:00:00" ? value : null}
                            placeholder="Select Time"
                            options={time_options_12hours}
                            onClose={(value) => setTime(value, onChange)}
                            onChange={(value) => setTime(value, onChange)}
                        />
                        )}
                    />
                        <div className="input-group-append">
                            <label htmlFor='class_drop_fee_time' className='date-label'
                                onClick={(e) => {
                                    e.stopPropagation(); 
                                    document.getElementById('class_drop_fee_time')._flatpickr.open(); 
                                }}
                            >
                                <span className="input-group-text">
                                    <i className="la la-clock-o"></i>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className='col-lg-1 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom me-2'>
                        <input
                        {...methods.register( 'show.class_drop_fee_each', 
                            {
                            required: false
                            })
                        }
                        className='form-check-input'
                        type='checkbox'
                        id='class_drop_fee_each'
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='class_drop_fee_each' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.CLASSDROPFEEEACH"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.EACH'})}</label>
                </div>
            </div>


            <div className='row mb-3'>
                <div className='col-lg-3 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                        <input
                        {...methods.register( 'show.credit_card_service_fee', 
                            {
                            required: false
                            })
                        }
                        className='form-check-input'
                        type='checkbox'
                        id='credit_card_service_fee'
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='credit_card_service_fee' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.CHARGECREDITCARDSERVICEFEE"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.CHARGECC'})}</label>
                </div>

                <label className='col-lg-2 col-form-label  fs-5 py-1 w-125px' htmlFor='credit_card_service_fee_id' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.CCSERVICEFEE">
                    <span className=''>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.CCSERVICEFEE'})}</span>
                </label>

                <div className='col-lg-4 '>
                {
                    props.form_meta.show_fees && 
                    <Controller
                        name="show.credit_card_service_fee_id"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                        <Select
                            options = {props.form_meta.show_fees}
                            value={
                                methods.getValues('show.credit_card_service_fee_id') === 0
                                  ? props.form_meta.show_fees.filter(
                                      (option) => option.label === 'None'
                                    )
                                  : props.form_meta.show_fees.find((ss) => ss.value === value)
                            }
                            id = "credit_card_service_fee_id"
                            name={name}
                            isSearchable={true}
                            onChange={(show_fees) => {
                                onChange(show_fees.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                        />    
                        )}
                    />
                }
                </div>

                <label className='col-lg-1 col-form-label  fs-5 py-1 w-50px px-1 ms-3' htmlFor='credit_card_service_fee_percent' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.%Fee">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.PERCFEE'})}</label>

                <div className='col-lg-1 '>
                 <Controller
                    control={methods.control}
                    name="show.credit_card_service_fee_percent"
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        id='credit_card_service_fee_percent'
                        allowNegative={false}
                        value={value !== '' ? Number(value): ""}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        onValueChange={(e) => {
                            // Only update the value if it's changed by the user
                            if (Number(e.value) !== Number(value)) {
                                onChange(e.value);
                            }
                        }}
                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                      />
                    )}
                 />
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-lg-3 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                        <input
                        {...methods.register( 'show.taxable_classes', 
                            {
                            required: false
                            })
                        }
                        className='form-check-input'
                        type='checkbox'
                        id='taxable_classes'
                        disabled={show_financials_locked}
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='taxable_classes' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.CHARGETAXFORSHOWCLASSES"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.CHARGETAX'})}</label>
                </div>

                <label className='col-lg-2 col-form-label  fs-5 py-1 w-125px' htmlFor='class_tax_rate_id' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.CLASSTAXRATE">
                    <span className=''>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.CLASSTAXRATE'})}</span>
                </label>

                <div className='col-lg-4 '>
                {   methods.getValues('show.masterfees_changeenabled') ? 
                    props.form_meta.tax_rates_4d && 
                    <Controller
                        name="show.class_tax_rate_id"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                        <Select
                            options = {props.form_meta.tax_rates_4d}
                            defaultValue={props.form_meta.tax_rates_4d.find((ss) => ss.value === "")}
                            id = "class_tax_rate_id"
                            name={name}
                            value={props.form_meta.tax_rates_4d.find((ss) => ss.value === value)}
                            isSearchable={true}
                            onChange={(tax_rates_4d) => {
                                onChange(tax_rates_4d.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                            isDisabled={show_financials_locked}
                        />    
                        )}
                    />
                    :
                    props.form_meta.show_tax_rates && 
                    <Controller
                        name="show.class_tax_rate_id"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                        <Select
                            options = {props.form_meta.show_tax_rates}
                            defaultValue={props.form_meta.show_tax_rates.find((ss) => ss.value === "")}
                            id = "class_tax_rate_id"
                            name={name}
                            value={props.form_meta.tax_rates_4d.find((ss) => ss.value === value)}
                            isSearchable={true}
                            onChange={(show_tax_rates) => {
                                onChange(show_tax_rates.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                            isDisabled={show_financials_locked}
                        />    
                        )}
                    />
                }
                </div>

            </div>

            <div className='row mb-2'>
                <div className='col-lg-4 d-flex align-items-center'>
                <div className='form-check-sm form-check-custom  me-2'>
                    <input
                    {...methods.register( 'show.auto_fees_once_per_horse', 
                        {
                        required: false
                        })
                    }
                    className='form-check-input'
                    type='checkbox'
                    id='auto_fees_once_per_horse'
                    disabled={show_financials_locked}
                    />
                </div>
                <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='auto_fees_once_per_horse' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.CHARGEDEFAULTFEESONCEPERUNIQUEHORSE"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.CHARGEDEFAULT'})}</label>
                </div>
            </div>

            <div className='row'>
                <div className='col-lg-4 d-flex align-items-center'>
                <div className='form-check-sm form-check-custom  me-2'>
                    <input
                    {...methods.register( 'show.enable_ca_addscratchrule', 
                        {
                        required: false
                        })
                    }
                    className='form-check-input'
                    type='checkbox'
                    id='enable_ca_addscratchrule'
                    />
                </div>
                <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='enable_ca_addscratchrule' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.AUTOMATICFEES.LABEL.ENABLECALIFORNIAADDSCRATCHRULE"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.ENABLECALIFORNIA'})}</label>
                </div>
            </div>
            </fieldset>
            </div>

            <div className="form-group row mt-5"> {/* Column Group */}
                <div className='col-lg-8'>{/* Column 1 */}
                <fieldset>
                    <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.GROUPLABEL.ENTRYBLANK'})} <b></b></legend>

                    <div className='row mb-2'>
                    <label className='col-lg-3 col-form-label  fs-5 py-1' htmlFor='entry_blank_checks_payable_to' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.ENTRYBLANKSETTINGS.LABEL.CHECKSPAYABLETO">
                        <span className=''>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.CHECKSPAYABLE'})}</span>
                    </label>

                    <div className='col-lg-9 '>
                        <input
                        {...methods.register( 'show.entry_blank_checks_payable_to', 
                            {
                            required: false
                            })
                        }
                        id='entry_blank_checks_payable_to'
                        type='text'
                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        // placeholder={intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.CHECKSPAYABLE'})}
                        />
                    </div>
                    </div>

                    <div className='row mb-2'>
                    <label className='col-lg-3 col-form-label  fs-5 py-1' htmlFor='entry_blank_address' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.ENTRYBLANKSETTINGS.LABEL.MAILTO">
                        <span className=''>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.MAILTO'})}</span>
                    </label>

                    <div className='col-lg-9 '>
                        <input
                        {...methods.register( 'show.entry_blank_address', 
                            {
                            required: false
                            })
                        }
                        id='entry_blank_address'
                        type='text'
                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        // placeholder={intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.MAILTO'})}
                        />
                    </div>
                    </div>

                    <div className='row mb-2'>
                    <label className='col-lg-3 col-form-label  fs-5 py-1' htmlFor='entry_blank_info' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.ENTRYBLANKSETTINGS.LABEL.INFO">
                        <span className=''>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.INFO'})}</span>
                    </label>

                    <div className='col-lg-9 '>
                        <input
                        {...methods.register( 'show.entry_blank_info', 
                            {
                            required: false
                            })
                        }
                        id='entry_blank_info'
                        type='text'
                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        // placeholder={intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.INFO'})}
                        />
                    </div>
                    </div>

                </fieldset>
            </div> {/* END Column 1 */}

            <div className='col-lg-4 mt-5 mt-sm-5 mt-md-5 mt-lg-7 mg-xl-7'>{/* Column 2 */}
                {/* <div className='row mb-2'>
                    <div className='col-lg-12 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                        <input
                        {...methods.register( 'show.dont_auto_apply_prizes', 
                            {
                            required: false
                            })
                        }
                        className='form-check-input'
                        type='checkbox'
                        id='dont_auto_apply_prizes'
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='dont_auto_apply_prizes' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.ENTRYBLANKSETTINGS.LABEL.DONTAUTOAPPLYPRIZEMONEY"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.DONTAUTO'})}</label>
                    </div>
                </div> */}

                {/* <div className='row mb-2'>
                    <div className='col-lg-12 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                            <input
                            {...methods.register( 'show.ignore_prize_money_mismatch', 
                                {
                                required: false
                                })
                            }
                            className='form-check-input'
                            type='checkbox'
                            id='ignore_prize_money_mismatch'
                            />
                        </div>
                        <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='ignore_prize_money_mismatch' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.ENTRYBLANKSETTINGS.LABEL.APPLYPRIZEMONEYDESPITEOWNERPRIZEMONEYMISMATCH"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.APPLYPRIZE'})}</label>
                    </div>
                </div> */}
                {hasSpecialPermissionToAccess('cloud_sgadmin:has_access') &&
                <div className='row mb-2'>
                    <div className='col-lg-12 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                        <input
                            checked={rollingShow}
                            className='form-check-input'
                            type='checkbox'
                            id='rolling_show'
                            onChange={e => toggleRollingShow(e.target.checked)}
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='rolling_show' data-tooltip-id="SHOW.DETAIL.TAB.ADDITIONAL.SECTION.ENTRYBLANKSETTINGS.LABEL.SETASROLLINGSHOW"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.ADDITIONAL.LABEL.SETAS'})}</label>
                    </div>
                </div>
                }
            </div> {/* END Column 2 */}

            </div> {/* End Column Group */}

        </div> {/* End Column Group */}
    </>
    )
  }
  
export {AdditionalTab}