import { useState , useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import axios from 'axios';
import ReactJson from 'react-json-view'
import useAccessChecker from '../../../modules/hooks/use-access-checker';

const SpreedlyTab = (props) => {
    const intl = useIntl();
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const [ transactionDetail , setTransactionDetail ] = useState('')
    const [ transcriptDetail , setTranscriptDetail ] = useState('')
    const { hasSpecialPermissionToAccess } = useAccessChecker()

    useEffect(() => {

        if ( !hasSpecialPermissionToAccess( 'cloud_sgadmin:has_access' ) ) {
            return;
        }

        axios.get(process.env.REACT_APP_NEST_API_URL + '/payments/spreedlyDetails', {
            params: {
              payment_id:props.id,
              customer_id,
            },
        }).then( ( spreedlyDetails ) => {
            setTransactionDetail( spreedlyDetails?.data?.transactionDetail )
            setTranscriptDetail( spreedlyDetails?.data?.transcriptDetail )
        })
    },[]);
    
    return (
        <>
            <div className='row mb-1'>
                <label className='col col-form-label fs-5 py-0 d-flex align-items-end' htmlFor="TRANSACTION">
                    {intl.formatMessage({id: 'FORM.INPUT.PAYMENTS.TAB.SPREEDLY.TRANSACTION'})}
                </label>
                <div>
                    <a target="_blank" href={`https://app.spreedly.com/transaction-details/${transactionDetail?.transaction?.token}`}>View on Spreedly</a>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-lg-12'>
                    <ReactJson
                        src={transactionDetail}
                        theme={'monokai'}
                        style={
                            {
                                maxHeight: '400px',
                                overflowY: 'scroll'
                            }
                        }
                        iconStyle='triangle'
                        displayObjectSize={false}
                        displayDataTypes={false}
                        name={false}
                    />
                </div>
            </div>

            <div className='row mb-1'>
            <label className='col-lg-12 col-form-label fs-5 py-0 d-flex align-items-center' htmlFor="TRANSCRIPT">
                {intl.formatMessage({id: 'FORM.INPUT.PAYMENTS.TAB.SPREEDLY.TRANSCRIPT'})}
            </label>
            </div>

            <div className='row mb-1'>
                <div className='col-lg-12'>
                    <textarea id="TRANSCRIPT" rows="10" value={transcriptDetail} className='form-control form-control-sm fs-6 py-1'></textarea>
                </div>
            </div>
        </>
    );
}

export { SpreedlyTab }