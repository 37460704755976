import clsx from 'clsx'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'

/* Title only toolbar */
const Toolbar2 = () => {

    const {classes} = useLayout()

    return (
        <div className='toolbar' id='kt_toolbar' style={{height: "50px", boxShadow:"1px 1px 1px #999", borderTop:"1px solid #ccc"}}>
            <div style={{textAlign: "center", display:"flex", flexDirection:"column", justifyContent: "center", marginLeft: "-15px" }} id='kt_toolbar_container' className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}>
                <DefaultTitle />
            </div>
        </div>
    )
}

export { Toolbar2 }
