import { Modal } from "react-bootstrap"
import { createPortal } from "react-dom"
import { KTSVG } from "../../../_metronic/helpers"
import { useIntl } from "react-intl"
import Flatpickr from "react-flatpickr";
import { date_options, time_options_12hours, maskDateInput } from "../sgl-utils/fieldControls";
import { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { useAppSelector } from "../../redux/hooks";
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const modalsRoot = document.getElementById('root-modals') || document.body

const TaskScheduler = ({ show, message, handleSubmit, handleClose }) => {
    const [showDateTimePicker, setShowDateTimePicker] = useState(false)

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-450px search-form'
            show={show}
            onHide={handleClose}
        >
            { 
                showDateTimePicker && <DateTimePicker
                    show={showDateTimePicker}
                    handleSubmit={(date, time) => { 
                        handleClose() // close task scheduler
                        handleSubmit(date, time)
                    }}
                    handleClose={() => {
                        setShowDateTimePicker(false)
                        handleClose() // close task scheduler
                    }}
                />
            }
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">Confirm</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
    
            <div className='modal-body py-3 px-4 shadow-lg'>
                <div className='card-body p-0'>
                    <div className='row mb-3'>
                        <div className='col-lg-1 d-flex align-self-center'>
                            <i className="fa-sharp fa-solid fa-circle-info fa-2xl" style={{ color: '#578EBE' }}></i>
                        </div>
                        <div className='col ms-lg-2'>
                            <span className="d-inline-block align-bottom" dangerouslySetInnerHTML={{__html: message}}></span>
                        </div>
                    </div>
                </div>
    
                <div className='card-footer d-flex justify-content-end py-3 px-0'>
                    <button
                        type="button"
                        className='btn btn-sm btn-secondary me-2 fw-bold' style={{ marginLeft: "auto" }}
                        onClick={handleClose}
                    >
                        Cancel
                    </button>
                    <button 
                        type="button" 
                        className='btn btn-sm btn-secondary me-2 fw-bold' 
                        onClick={() => setShowDateTimePicker(true)}
                    >
                        Schedule
                    </button>
        
                    <button 
                        type="button"
                        className='btn btn-sm btn-dark fw-bold' 
                        onClick={() => {
                            handleClose()
                            handleSubmit(null, null)
                        }} 
                        autoFocus
                    >
                        Immediately
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}


const DateTimePicker = ({ show, handleClose, handleSubmit }) => {
    const intl = useIntl()
    const today = new Date()
    const [date, setDate] = useState(today)
    const [time, setTime] = useState(today)
    const customerId = useAppSelector(state => state.showCompany.company_id)

    useEffect(() => {
        if (show) {
            const getTimezoneInfo = async () => {
                const response = await axios.get(`${NEST_API_URL}/utility/getTimezoneInfo?customer_id=${customerId}`)
                setDate(moment(response.data.date).format('MM/DD/YYYY'))
                setTime(response.data.time)
            }

            getTimezoneInfo()
        }
    }, [show])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-450px'
            show={show}
            onHide={handleClose}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">Schedule Task</h2>
                <div 
                    className='btn btn-sm btn-icon btn-active-color-dark' 
                    onClick={handleClose}
                >
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
    
            <div className='modal-body py-3 px-4 shadow-lg'>
                <div className='row mb-2'>
                    <label className='col-lg-3 col-form-label fs-5 py-1 pe-0' htmlFor="scheduleTaskDate">
                        Schedule on
                    </label>

                    <div className='col-lg-4 '>
                        <div className="input-group date">
                            <Flatpickr 
                                className="form-control form-control-sm fs-6 min-h-20px py-1"
                                id="scheduleTaskDate"
                                value={date}
                                options={date_options}
                                onChange={dt => setDate(dt[0])}
                                autoComplete={"off"}
                                onInput={(e) => { maskDateInput(e) }}
                            />
                            <div className="input-group-append">
                                <label htmlFor='scheduleTaskDate' className='date-label'
                                    onClick={(e) => {
                                        e.stopPropagation(); 
                                        document.getElementById('scheduleTaskDate')._flatpickr.open(); 
                                    }}
                                >
                                    <span className="input-group-text">
                                        <i className="la la-calendar"></i>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <label className='col-lg-1 col-form-label  fs-5 py-1' htmlFor='scheduleTaskTime'>
                       at
                    </label>
                    <div className='col-lg-4 '>
                        <div className="input-group date">
                            <Flatpickr 
                                className="form-control form-control-sm fs-6 min-h-20px py-1"
                                id="scheduleTaskTime"
                                placeholder="00 : 00"
                                value={time}
                                options={time_options_12hours}
                                onClose={dt => setTime(moment(dt[0]).format('HH:mm:ss'))}
                                onChange={dt => setTime(moment(dt[0]).format('HH:mm:ss'))}
                                autoComplete={"off"}
                            />
                            <div className="input-group-append">
                                <label htmlFor='scheduleTaskTime' className='date-label'
                                  onClick={(e) => {
                                    e.stopPropagation(); 
                                    document.getElementById('scheduleTaskTime')._flatpickr.open(); 
                                  }}
                                >
                                    <span className="input-group-text">
                                        <i className="la la-clock"></i>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>    
                </div>
                <div className='card-footer d-flex justify-content-end py-3 px-0'>
                    <button 
                        type="button" 
                        id={"confirm_dialog_cancel_button"} 
                        className='btn btn-sm btn-secondary me-4 fw-bold' 
                        style={{ marginLeft: "auto" }} 
                        onClick={handleClose}
                    >
                        { intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.CANCEL' }) }
                    </button>

                    <button 
                        type="submit" 
                        id={"confirm_dialog_submit_button"} 
                        className='btn btn-sm btn-dark fw-bold' 
                        onClick={() => {
                            handleClose()
                            handleSubmit(moment(date).format('YYYY-MM-DD'), time)
                        }} 
                        autoFocus
                    >
                        { intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.OK' }) }
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default TaskScheduler