import { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import { getExtraNarrowHeaderHeight, getNarrowRowHeight, getRowData } from '../../../modules/sgl-utils/agGridHelpers'
import { AgGridReact } from 'ag-grid-react'
import { useCurrencyFormatter } from '../../../modules/sgl-utils/Formatters'
import { addFloatingNumbers } from '../../../modules/sgl-utils/SglFunctions'
const modalsRoot = document.getElementById('root-modals') || document.body

const EntriesWithPastBalance = ({ show, handleClose, data }) => {
    const intl = useIntl() 
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const currencyFormatter = useCurrencyFormatter()
    const [gridRef, setGridRef] = useState(null)

    const defaultColDef = {
        suppressMenu: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: params => {
            let style = {textAlign: 'left'}
            if (typeof params.value === 'number') {
                style = { textAlign: 'right' }
            } else if (typeof params.value === 'boolean') {
                style = { textAlign: 'center' }
            }
           return { ...style } 
        },
        headerClass: "ag-center-aligned-header",
        sortable: true,
        resizable: true
    }

    const columnDefs = [       
        { 
            field: 'number',
            headerName: intl.formatMessage({ id: "FORM.INPUT.ENTRIES.LABEL.PAST.BALANCES.COL.NUMBER" }),
            width: 90,
            cellClass: 'text-center'
        },
        { 
            field: 'Show.show_name', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.PAST.BALANCES.COL.SHOW' }), 
            flex: 3,
        },
        { 
            field: 'horse', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.ENTRIES.LABEL.PAST.BALANCES.COL.HORSE" }), 
            flex: 2,
        },
        { 
            field: 'responsibleparty', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.ENTRIES.LABEL.PAST.BALANCES.COL.RP" }), 
            flex: 2,
        },
        { 
            field: 'trainer', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.ENTRIES.LABEL.PAST.BALANCES.COL.TRAINER" }), 
            flex: 2,
        },
        { 
            field: 'balance', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.PAST.BALANCES.COL.BALANCE' }),
            width: 110,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: "right" },
            cellStyle: params => getCellStyles(params),
        }
    ]

    useEffect(() => {
        if(gridRef){
            const rowData = getRowData(gridRef)
            let totalBalance = 0

            rowData.forEach(row => {           
                totalBalance = addFloatingNumbers([row.balance, totalBalance], 2)
            })

            gridRef?.setPinnedBottomRowData([{
                balance: totalBalance
            }])
        }
    }, [gridRef])

    const getCellStyles = (params) => {
        if(params.node.rowPinned){ // bottom pinned row
            return { textAlign: "right", fontWeight: "bold"}
        }

        return { textAlign: "right"}
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
            show={show}
            onHide={handleClose}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{`Responsible Party Past Entries With Balance`}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div style={containerStyle}>
                            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                <AgGridReact
                                    onGridReady={params => setGridRef(params.api)}
                                    defaultColDef={defaultColDef}
                                    rowData={data?.rpPastBalanceEntries ?? []}
                                    rowStyle={{ fontSize: '13px', 'paddingLeft': 0, 'paddingRight': 0}}
                                    columnDefs={columnDefs} 
                                    headerHeight={getExtraNarrowHeaderHeight}
                                    rowHeight={getNarrowRowHeight}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Footer */}
                    <div className='card-footer py-4 px-0'>
                        <div className='row m-0 p-0'>
                            <div className='col m-0 p-0 d-flex justify-content-end'>
                                <button type="button" className='btn btn-sm btn-secondary fw-bold text-uppercase' onClick={handleClose}>
                                    {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.PAST.BALANCES.BUTTON.CLOSE' })}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { EntriesWithPastBalance }