/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import { useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import DateRenderer from '../../modules/output-listing/renderers/DateRenderer';

const TaxRatesList = () => {
  const customerId = useAppSelector(state=> state.showCompany.company_id);
  const currentShowStartDate = useAppSelector(state=> state.currentShow.start_date);
  const intl = useIntl();
  const navigate = useNavigate()

  
  const applyCellStyle = (params) => {
    if (params?.data?.['effective_date']) {
      const effectiveDate = new Date(params.data['effective_date']);
      if (effectiveDate > new Date(currentShowStartDate)) {
        return { color: 'var(--kt-primary)' };
      }
    }
    
    return null;
  };

  const columnDefs = [
    // { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.COMMONS.SGLID'}), filter: 'agNumberColumnFilter', filterParams: { suppressAndOrCondition: true,}, },
    { field: 'show_tax_rates_id', headerName: intl.formatMessage({ id: 'LIST.TAXRATES.SHOWTAXRRATESID' }), filter: 'agNumberColumnFilter', hide:true, cellStyle: params => applyCellStyle(params) },
    { field: 'tax_name', width: 300, headerName: intl.formatMessage({ id: 'LIST.TAXRATES.TAXNAME' }), filter: 'agTextColumnFilter', cellStyle: params => applyCellStyle(params) },
    { field: 'effective_date', headerName: intl.formatMessage({ id: 'LIST.TAXRATES.EFFECTIVEDATE' }), cellRenderer: params => DateRenderer(params, 'TaxRate Listing'), cellClass: 'text-center', },
    { field: 'rate', width:100, headerName: intl.formatMessage({ id: 'LIST.TAXRATES.RATE' }), filter: false, sortable: false, valueFormatter: (params) => { const value = parseFloat(params.value); if (isNaN(value) || value == 0) { return ''; } return (value * 100).toFixed(2) + '%'; }, cellStyle: params => applyCellStyle(params), cellClass: 'text-center', },
    { field: 'show_name', width: 400, headerName: intl.formatMessage({ id: 'LIST.TAXRATES.SHOWNAME' }), filter: 'agTextColumnFilter', cellStyle: params => applyCellStyle(params)},
  ]

return (
    <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.TAXRATES' })}</PageTitle>
        <OutputListingComponent
            key={`${customerId}_${currentShowStartDate}`}
            area={'TaxRates'}
            columnDefs={columnDefs}
            apiEndPoint={'tax-rates/list'}
            onRowDoubleClicked={(row_data) => {
                navigate('/taxrates/detail/' + row_data.show_tax_rates_id + `?customer_id=${customerId}`);
            }}
            onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL + '/taxrates/detail/' + row_data.show_tax_rates_id + `?customer_id=${customerId}`,'_blank')}
        />
    </>
);
}

export {TaxRatesList}
