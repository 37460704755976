import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider, useLayout} from './core'
import {useLocation} from 'react-router-dom'
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import { OutputContextProvider } from '../../app/modules/output-listing/OutputListingContext'
import { AlertDialogProvider, ConfirmDialogProvider, DatePickerDialogProvider, DateRangeDialogProvider, FlashAlertProvider, LoadingOverlayProvider, PersistentLoadingOverlayProvider, ProgressBarProvider, TaskSchedulerContextProvider } from '../../app/modules/sgl-utils/DialogsProvider'
import { SyncUpdateContextProvider } from '../../app/modules/output-listing/synchronizeUpdateContext'
import { SGLContextProvider } from '../../app/modules/contexts/SGLContext'

const MasterLayout = () => {
  const location = useLocation()
  const {setPageLayout} = useLayout();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)

    //set layout, menu and toolbar based on route location
    setPageLayout(location)
  }, [location.key])

  return (
    <PageDataProvider>
        <SGLContextProvider>
      <ThemeModeProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
            <ConfirmDialogProvider>
                <AsideDefault />
                <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                  <FlashAlertProvider>
                    <TaskSchedulerContextProvider>
                      <SyncUpdateContextProvider>
                        <HeaderWrapper />
                        <AlertDialogProvider>
                            <ProgressBarProvider>
                              <DateRangeDialogProvider>
                                <DatePickerDialogProvider>
                                <LoadingOverlayProvider>
                                  <PersistentLoadingOverlayProvider>
                                    <OutputContextProvider>
                                    <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                                        <Toolbar />
                                        <div className='post d-flex flex-column-fluid' id='kt_post'>
                                            <Content>
                                                <Outlet />
                                            </Content>
                                        </div>
                                    </div>
                                    </OutputContextProvider>
                                  </PersistentLoadingOverlayProvider>
                                </LoadingOverlayProvider>
                                </DatePickerDialogProvider>
                              </DateRangeDialogProvider>
                            </ProgressBarProvider>  
                        </AlertDialogProvider>
                      </SyncUpdateContextProvider>
                    </TaskSchedulerContextProvider>
                  </FlashAlertProvider>
                  <Footer />
                </div>
            </ConfirmDialogProvider>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
      </SGLContextProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
