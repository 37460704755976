import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import ShowsGrid from './ExportECResults/ShowsGrid';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import { useAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import { useLoadReportAndPrint } from '../../reports/ReportTypeModal';
import { useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../modules/sgl-utils/SglFunctions';

const modalsRoot = document.getElementById('root-modals') || document.body

const ExportECResults = ({ show, handleClose }) => {
    const intl = useIntl() 
    const { getOutputSelectionAreaIDs } = useOutputContext()
    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id)

    const [showModal, setshowModal] = useState(false)
    const [displayErrors, setDisplayErrors] = useState(false)
    const confirmDialog = useConfirm()
    const [showLookupData, setShowLookUpData] = useState({})
    const [selectedShowId, setSelectedShowId] = useState(0)
    const [rowData, setRowData] = useState([])

    useEffect(() => {
        if (show) { 
            loadingOverlay({ show: true })
            getOutputSelectionAreaIDs()
            .then(res => { 
                if (res.length !==  1){
                    loadingOverlay({ show: false })
                    // error message if no show is selected
                    alertDialog({ message: intl.formatMessage({ id: "SHOWS.EXPORTECRESULTS.MODAL.SELECTSHOWSMESSAGE"}) })
                    handleClose()
                }
                else if (res.length ===  1) {
                    let currentShowID = res
                    setSelectedShowId(currentShowID)
                    axios.get( process.env.REACT_APP_NEST_API_URL + '/shows/getECExportMetadata', {
                        params: {
                          show_id: currentShowID,
                          customer_id: customerId
                        }
                      })
                    .then(async response => {
                        
                        // api will return success(T/F), error_message, flag for display alert and data to display in modal
                        if(!response.data.success){
                            alertDialog({ message: response.data.erorr_message })
                            loadingOverlay({ show: false })
                            handleClose()
                        } else {
                            if(response.data.alert_message != ''){
                                let choice = await confirmDialog({message: response.data.alert_message, icon: 'warning', title: 'alert'})
                                if(choice){
                                    setshowModal(true)
                                    loadingOverlay({ show: true })
                                    const classes_list = response.data.classes_list
                                    setRowData(classes_list)
                                    setShowLookUpData(response.data.show_lookup_data)
                                } else {
                                    handleClose()
                                }
                            } else {
                                setshowModal(true)
                                loadingOverlay({ show: true })
                                const classes_list = response.data.classes_list
                                setRowData(classes_list)
                                setShowLookUpData(response.data.show_lookup_data)
                            }
                        }

                        loadingOverlay({ show: false })
                    })
                    .catch(() => loadingOverlay({ show: false }))
                } 
            })
            .catch(() => loadingOverlay({ show: false }))
        }
    }, [show])


    const onClose = () => {
        loadingOverlay({ show: false })
        handleClose();  
    }

    // Print errors, this will call Active report js to create an EC Results Export Errors report
    // const printExportErrors = (e) => {
        // loadingOverlay({ show: true, message: `Loading Report...` })
        // axios.post(process.env.REACT_APP_NEST_API_URL + '/reports/getPrefReportForPrinting', {
        //     pref_name: 'ECErrorReportId',
        //     customer_id: customerId,
        //     show_id: selectedShowId?.length > 0 ? selectedShowId[0] : currentShowID,    // Safety check
        //     params: { ids: [] }, // Adding dummy ids to avoid error
        // })
        //     .then(async (response) => {
        //         if (response?.data?.success) {
        //             const report = response?.data?.report;
        //             report.report_definition = JSON.parse(report?.report_definition);

        //             // Adding params object here
        //             // Sending errors to backend was causing errors
        //             const customParamsObj = { ids: [], errorList: exportErrors };
        //             for (let parameter of report.parameters) {
        //                 if (parameter.Name == 'params') {
        //                     parameter.Value = JSON.stringify(customParamsObj)
        //                 }
        //             }

        //             // Load report to ARJS and the print
        //             await loadReportsAndPrint(report);
        //         } else {
        //             alertDialog({ message: response?.data?.message })
        //         }
        //         loadingOverlay({ show: false })
        //         loadingSpinnerBtnRelease(e);
        //     })
        //     .catch((error) => {
        //         console.log(error)
        //         loadingOverlay({ show: false })
        //         loadingSpinnerBtnRelease(e);
        //     })
    // }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px search-form'
            show={showModal}
            onHide={onClose}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{displayErrors?intl.formatMessage({ id: 'SHOWS.QUICKACTION.POPUP.HEADING.EXPORTECRESULTSERRORS' }):intl.formatMessage({ id: 'SHOWS.QUICKACTION.POPUP.HEADING.EXPORTECRESULTS' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className="d-flex mb-2">
                            
                            <div className='col'>
                                {(displayErrors) ?
                                <div className='row mb-2'>
                                    <label className='col-lg-4 col-form-label fs-5 py-1 fw-bold text-danger'>
                                        { intl.formatMessage({ id: "SHOWS.EXPORTECRESULTS.MODAL.EXPORTECRESULTSERRORS"  }) }
                                    </label>
                                </div>
                                :
                                <div className='row mb-2'>
                                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="SHOW.DETAIL.QUICKACTION.EXPORTECRESULTS.LABEL.ECSHOWCODE">
                                        { intl.formatMessage({ id: "SHOWS.EXPORTECRESULTS.MODAL.ECSHOWCODE"  }) }
                                    </label>
                                    <label className='col-lg-2 col-form-label fs-5 py-1'>
                                        {showLookupData.Id}
                                    </label>

                                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="SHOW.DETAIL.QUICKACTION.EXPORTECRESULTS.LABEL.ECSHOWNAME">
                                        { intl.formatMessage({ id: "SHOWS.EXPORTECRESULTS.MODAL.ECSHOWNAME" }) }
                                    </label>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-375px'>
                                        {showLookupData.Name}
                                    </label>
                                </div>
                                }
                            </div> 
                        </div>
                     
                    </div>

                    <ShowsGrid 
                        rowData={rowData}
                        showId={selectedShowId}
                        onClose = {onClose}
                        setDisplayErrors = {setDisplayErrors}
                    />

                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default ExportECResults