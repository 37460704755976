import Select from "react-select"
import { KTSVG } from "../../../../_metronic/helpers"
import { useState } from "react"
import { reactSelectStyles } from "../../../modules/sgl-utils/fieldControls"


export const JobCountByStatusByWidget = ({ getQueuedJobsStatus, selectedDuration, setSelectedDuration,queuedJobsData }) => {
    const durationOptions = [
        { label: '30 minutes', value: '30-minutes' },
        { label: '60 minutes', value: '1-hours' },
        { label: '3 hours', value: '3-hours' },
        { label: '6 hours', value: '6-hours' },
        { label: '12 hours', value: '12-hours' },
        { label: '24 hours', value: '1-days' },
        { label: '3 days', value: '3-days' },
        { label: '7 days', value: '7-days' },
    ]

    return (
        <div className="widget no-height-limit card">
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold text-dark'>Overview</span>
                </h3>
                
                <div className="d-flex align-items-center">
                    <i onClick={async () => await getQueuedJobsStatus()} className='far fa-solid fa-rotate text-success fs-1 fw-bold cursor-pointer'></i>
                    <div className="mx-2 w-lg-125px">
                        <Select 
                            styles={reactSelectStyles}
                            options={durationOptions}
                            value={selectedDuration}
                            onChange={e => setSelectedDuration(e)}
                        />
                    </div>
                </div>
            </div>
            <div className='card-body pt-5 d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light-danger'>
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr015.svg'
                            className='svg-icon-2x svg-icon-danger'
                        />
                    </span>
                    </div>
                    <div className='d-flex flex-column'>
                        <a href='#' className='text-dark text-hover-danger fs-6 fw-bold'>
                            { queuedJobsData.jobCountByStatus.Failed } Failed
                        </a>
                        <span className='text-muted fw-semibold'>Count of jobs that have failed</span>
                    </div>
                </div>
                <div className='d-flex align-items-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                        <span className='symbol-label bg-light-success'>
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr029.svg'
                                className='svg-icon-2x svg-icon-success'
                            />
                        </span>
                    </div>
                    <div className='d-flex flex-column'>
                        <a href='#' className='text-dark text-hover-success fs-6 fw-bold'>
                            { queuedJobsData.jobCountByStatus.Started } In-Progress
                        </a>
                        <span className='text-muted fw-semibold'>Jobs that are currently executing</span>
                    </div>
                </div>
                <div className='d-flex align-items-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                        <span className='symbol-label bg-light-success'>
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr016.svg'
                                className='svg-icon-2x svg-icon-success'
                            />
                        </span>
                    </div>
                    <div className='d-flex flex-column'>
                        <a href='#' className='text-dark text-hover-success fs-6 fw-bold'>
                            { queuedJobsData.jobCountByStatus.Completed } Completed
                        </a>
                        <span className='text-muted fw-semibold'>Jobs that have already been completed</span>
                    </div>
                </div>
                <div className='d-flex align-items-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                        <span className='symbol-label bg-light-warning'>
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr029.svg'
                                className='svg-icon-2x svg-icon-warning'
                            />
                        </span>
                    </div>
                    <div className='d-flex flex-column'>
                        <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                            { queuedJobsData.jobCountByStatus.Queued } Queued
                        </a>
                        <span className='text-muted fw-semibold'>Jobs that are in waiting to be processed</span>
                    </div>
                </div>
                <div className='d-flex align-items-center mb-7'>
                    <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light-danger'>
                        <KTSVG
                        path='/media/icons/duotune/general/gen044.svg'
                        className='svg-icon-2x svg-icon-danger'
                        />
                    </span>
                    </div>
                    <div className='d-flex flex-column'>
                    <a href='#' className='text-dark text-hover-danger fs-6 fw-bold'>
                        { queuedJobsData.jobCountByStatus.Stalled } Stalled
                    </a>
                    <span className='text-muted fw-semibold'>Jobs stalled due to some reason</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
