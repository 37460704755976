import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import { useState } from 'react'
import { getDisabledFieldsForRtoPreference } from '../../../../modules/sgl-utils/SglFunctions'

const modalsRoot = document.getElementById('root-modals') || document.body

const BankingDetailsDialog = ({ show, handleClose, pmrId, updatePMR }) => {
    const intl = useIntl();
    const methods = useFormContext();
    const [bankingInfo, setBankingInfo] = useState({
        bank_name: methods.getValues(`linked_people[${pmrId}].people_data.bank_name`),
        account_number: methods.getValues(`linked_people[${pmrId}].people_data.account_number`),
        swift_code: methods.getValues(`linked_people[${pmrId}].people_data.swift_code`),
        routing_number: methods.getValues(`linked_people[${pmrId}].people_data.routing_number`)
    })
    const [disableFields, setDisableFields] = useState(getDisabledFieldsForRtoPreference(methods.watch(`linked_people[${pmrId}].people_data.prize_preference`)))

    const handleSave = () => {

        for(let field of Object.keys(bankingInfo)){
            updatePMR(field, bankingInfo[field])
        }

        handleClose()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{methods.getValues(`linked_people[${pmrId}].people_data.prize_preference`) == 'Wire Trasfer' ? intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.MODAL.BANKING.DETAILS' }) : `${methods.getValues(`linked_people[${pmrId}].people_data.prize_preference`)} Details`}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0 d-flex'>
                        <div className='row mb-2'>
                        {/* Bank Name */}
                        {
                            disableFields.includes('bank_name') ? null : 
                            <div className='row mb-2'>
                                <label 
                                    className='col-lg-4 col-form-label fs-5 py-1'
                                    htmlFor='bankName'>
                                    { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.BANKNAME' }) }
                                </label>
                                <div className='col-lg-7'>
                                    <input
                                        id='bankName'
                                        type='text'
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.BANKNAME' })}
                                        value={bankingInfo.bank_name}
                                        onChange={(e) => { setBankingInfo({...bankingInfo, bank_name: e.target.value}) }}
                                    />
                                </div>
                            </div>
                        }
                        

                        {/* Bank Account No */}
                        {
                            disableFields.includes('account_number') ? null :
                            <div className='row mb-2'>
                                <label 
                                    className='col-lg-4 col-form-label fs-5 py-1'
                                    htmlFor='accountNo'>
                                    { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.BANKACCOUNTNO' }) }
                                </label>
                                <div className='col-lg-7'>
                                    <input
                                        id='accountNo'
                                        type='text'
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.BANKACCOUNTNO' })}
                                        value={bankingInfo.account_number}
                                        onChange={(e) => { setBankingInfo({...bankingInfo, account_number: e.target.value}) }}
                                    />
                                </div>
                            </div>
                        }

                        {/* Routing No */}
                        {
                            disableFields.includes('routing_number') ? null :
                            <div className='row mb-2'>
                                <label 
                                    className='col-lg-4 col-form-label fs-5 py-1'
                                    htmlFor='routingNo'>
                                    { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.ROUTING.NO' }) }
                                </label>
                                <div className='col-lg-7'>
                                    <input
                                        id='routingNo'
                                        type='text'
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.ROUTING.NO' })}
                                        value={bankingInfo.routing_number}
                                        onChange={(e) => { setBankingInfo({...bankingInfo, routing_number: e.target.value}) }}
                                    />
                                </div>
                            </div>
                        }

                        {/* Swift Code */}
                        {
                            disableFields.includes('swift_code') ? null :
                            <div className='row mb-2'>
                                <label 
                                    className='col-lg-4 col-form-label fs-5 py-1'
                                    htmlFor='swiftCode'>
                                    { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.SWIFTCODE' }) }
                                </label>
                                <div className='col-lg-7'>
                                    <input
                                        id='swiftCode'
                                        type='text'
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.SWIFTCODE' })}
                                        value={bankingInfo.swift_code}
                                        onChange={(e) => { setBankingInfo({...bankingInfo, swift_code: e.target.value}) }}
                                    />
                                </div>
                            </div>
                        }
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>

                        <button className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase'
                            type="button"
                            onClick={handleClose}
                        >
                            { intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CANCEL"})}  
                        </button>

                        <button className='btn btn-sm btn-dark fw-bold text-uppercase' type="button" onClick={handleSave} autoFocus>
                        { intl.formatMessage({ id: "FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.MODAL.SAVE"})}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { BankingDetailsDialog }