import { useIntl } from 'react-intl'
import { useEffect, useMemo, useRef, useState } from 'react';
import { usePaymentBatchContext } from '../../PaymentBatchContext';
import axios from 'axios';
import { useAppSelector } from '../../../../redux/hooks';
import { useAlert, } from '../../../../modules/sgl-utils/DialogsProvider';
import { getNarrowHeaderHeight, getNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers';
import { AgGridReact } from 'ag-grid-react';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import { multiSort } from '../../../../modules/sgl-utils/SglFunctions';
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const RTOsGrid = ({ peopleList, setPeopleList, errorMessage, setErrorMessage, selectedRTO, setSelectedRTO, setEntriesList}) => {
    const intl = useIntl();
    const containerStyle = useMemo(() => ({ width: '100%', height: '65vh' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const customerID = useAppSelector(state => state.showCompany.company_id);
    const currencyFormatter = useCurrencyFormatter()
    const alertDialog = useAlert()
    const { selectedShow, refreshPaymentBatch, updateCheckoutEntriesTabData, entriesList }= usePaymentBatchContext()

    let gridRef = useRef()
    const defaultColDef = useMemo(() => {
        return {
          minWidth: 110,
          width: 110,
          resizable: true,
          sortable: true,
          suppressMenu: true,
          suppressMovable: true,
          cellStyle: function(params) {
            if (typeof params.value === 'number') {
                return {textAlign: 'center'};
            } else {
              return {textAlign: 'left'};
            }
          },
          wrapHeaderText: true,
          autoHeaderHeight: true,
          headerClass: "ag-center-aligned-header",
          singleClickEdit: true,
        };
    }, []);

    const columnDefs = [
        { 
            field: 'fl_name', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.GRID.COL.RTO' }),
            flex: 1,
        },
        { 
            field: 'entries_balance', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.GRID.COL.ENTRIESBALANCE" }),
            width: 110,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: 'right' }
        },
        { 
            field: 'account_credit', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.GRID.COL.AVAILABLEARBALANCE" }),
            width: 110,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: 'right' }
        },
        { 
            field: 'available_credit', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.GRID.COL.AVAILABLECREDIT" }),
            width: 110,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: 'right' }
        },
        { 
            field: 'status', 
            width: 160,
            flex: 1,
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.GRID.COL.STATUS" }),
            resizable: true,
            valueGetter: (params) => params?.data?.status?.toString().replace(',', ', ')
        }
    ]

    const handleRowDoubleClicked = (row_data) => {
        // open RTO detail with 'Account Tab' 
        if(row_data.data.people_id){
            window.open(process.env.PUBLIC_URL+'/people/detail/'+row_data.data.people_id+`?customer_id=${customerID}&selectedTab=2`,'_blank')
        }
    }

    const loadRTOForCheckoutEntries = async(clearSelectedRTO=true) => {
        setErrorMessage('')
        setEntriesList([])
        gridRef?.current?.api?.showLoadingOverlay()

        await axios.get(`${NEST_API_URL}/payment-batches/getCheckoutEntries`, {
            params: { 
                customer_id: customerID,
                show_id: selectedShow
            }
        }).then((response) => {
            if(response.data.success){
                // update RTO List
                setPeopleList(response.data.processedRtos.map((rto) => {
                    rto.creditNotUsed = rto.available_credit
                    return rto
                }))

                if(clearSelectedRTO){
                    setSelectedRTO() //clear the selection
                }else{
                    let personSelected = response.data.processedRtos.find((rto) => rto.people_id == selectedRTO.people_id)
                    setSelectedRTO(personSelected)
                    if(personSelected){
                        personSelected.entries = multiSort(personSelected.entries, { number: 'asc' })
                        setEntriesList(personSelected.entries)
                    }
                }

                // update error message
                let rtoNotUsedForPayoff = response.data.processedRtos.filter((rto) => rto.available_credit == 0)
                if(rtoNotUsedForPayoff.length > 0){
                    setErrorMessage(`${rtoNotUsedForPayoff.length} ${rtoNotUsedForPayoff.length > 1 ? 'RTOs' : 'RTO'} cannot be used for entries checkout.`)
                }

            }else{
                alertDialog({message: response.data.error})
            }
        }).catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            gridRef?.current?.api?.hideOverlay()
        })
    }

    useEffect(() => {
        if(selectedShow){
            gridRef?.current?.api?.deselectAll();
            loadRTOForCheckoutEntries() // refresh RTOs data
        }else{
            setErrorMessage('')
            setPeopleList([])
            setEntriesList([])
        }   
    }, [selectedShow, refreshPaymentBatch])

    useEffect(() => {
        if(selectedShow){
            loadRTOForCheckoutEntries(false) // refresh RTOs data
        }else{
            setErrorMessage('')
            setPeopleList([])
            setEntriesList([])
        }   
    }, [updateCheckoutEntriesTabData])

    useEffect(() => {
        if(selectedRTO){
            // update entries list
            if(selectedRTO?.entries){
                selectedRTO.entries = multiSort(selectedRTO?.entries, { number: 'asc' })
                setEntriesList(selectedRTO?.entries)
            }else{
                setEntriesList([])
            }
        }
    }, [selectedRTO])

    useEffect(() => {
    
        if(gridRef?.current && selectedRTO){
            let rowNodeToSelect
            gridRef?.current?.api?.forEachNode((rowNode) => {
                if(rowNode.data.people_id == selectedRTO?.people_id)
                {
                    rowNodeToSelect =  rowNode
                }
            });

            if(rowNodeToSelect){
                // select RTO
                gridRef?.current?.api?.selectNode(rowNodeToSelect, true);
            }
        }
    }, [gridRef, selectedRTO, entriesList, peopleList])

    return (
        <>
            {/* Grid */}
            <div>    
                {/* RTOs count */}
                <div className='row'>
                    {/* Error Message */}
                    <div className='col-9'>
                        {/* <label className='col-form-label fs-5 py-1 text-danger text-start'>{ errorMessage }</label> */}
                    </div>
                    <div className='col-3 d-flex justify-content-end'>
                        <label className='col-form-label fs-5 py-1 text-end'>
                            {  peopleList.length  } { peopleList.length === 1 ? 'RTO' : 'RTOs' }
                        </label>
                    </div>
                </div>

                <div style={containerStyle}>
                    <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                        <AgGridReact
                            ref={gridRef}
                            defaultColDef={defaultColDef}
                            rowData={peopleList}
                            columnDefs={columnDefs} 
                            rowStyle={{ fontSize: '12px' }}
                            headerHeight={getNarrowHeaderHeight}
                            rowHeight={getNarrowRowHeight}
                            suppressMovableColumns={true}
                            rowSelection="single"
                            suppressScrollOnNewData={true}
                            getRowClass={params => {
                                if (params?.data?.available_credit == 0 || !params?.data?.canPayEntries) {
                                    return 'expired-text'
                                }  
                            }}
                            onRowClicked={(params) => {
                                params.node.data.entries = params?.node?.data?.entries.map((entry) => {
                                    entry['include'] = false
                                    entry['amountApplied'] = 0
                                    return entry
                                })

                                params.node.data.creditNotUsed = params.node.data.available_credit
                                setSelectedRTO(params?.node?.data)
                            }}
                            onRowDoubleClicked={(row_data) => handleRowDoubleClicked(row_data)}
                            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while RTOs are loading...</span>'}
                        />
                    </div>
                </div>
            </div>
        </>   
    );
}

export { RTOsGrid }