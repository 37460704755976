import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import { HistoryTab } from '../../../modules/components/HistoryTab'
import "flatpickr/dist/themes/material_blue.css";
import { useSchedulerContext } from './SchedulerContext'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const History = ({show, handleClose}: Props) => {
  const intl = useIntl()
  const { changeHistoryLogs } = useSchedulerContext()

  return createPortal(
    <Modal
      id='kt_modal_history'
      enforceFocus={false}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered modal-xl'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-header py-0 px-4' >
        <h2 className="fs-4" >{intl.formatMessage({id: 'SCHEDULER.HISTORY.TITLE.HISTORY'})}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4'>
        <form noValidate className='form' onSubmit={e => e.preventDefault()}>
          <div className='card-body p-0'>
            <div className="form-group row">
                <HistoryTab
                    area="Scheduler" 
                    rowData={changeHistoryLogs}
                    gridStyles={ { height: '75vh'} }
                />
            </div>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {History}
