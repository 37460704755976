import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { getUserName } from '../../modules/sgl-utils/SglFunctions'


const modalsRoot = document.getElementById('root-modals') || document.body
 
const ViewUserDialog = ({show, handleClose, users}) => {
    const intl = useIntl()
    const onClose = () => {
        handleClose()
    }

    return createPortal(
        <Modal
            id='dashboard_active_user_dialog'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog mw-650px bg-white'
            show={show}
            onHide={onClose}
            onKeyDown={(event) => {
                if (event.key === "Enter") {
                    onClose()
                }
            }}
            style={{height:'calc(100vh - 50px)'}}
        >
            <div className='modal-header py-0 px-4 border-0 d-flex justify-content-end bg-white pt-2'>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            {/* <!--begin::Modal body--> */}
            {/* <div className="modal-body scroll-y mx-3 mx-xl-3 pt-0 pb-10 bg-white"> */}
            <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-10 bg-white">
                <h2 className="fs-1 text-center">{intl.formatMessage({ id: 'MENU.DASHBOARD.VIEWUSERDIALOG.HEADER' })}</h2>
                {/* <!--begin::Users--> */}
                <div className="mb-5">
                    <div className="scroll-y me-n7 pe-7" style={{height:'calc(100vh - 200px)', overflowY: 'scroll'}}>
                        {users.map((user,index) => {
                            const moment = require('moment');
                            const formattedTime = user.lastActivityTimestamp > 0 ? moment(user.lastActivityTimestamp * 1000).format("HH:mm A") : null;
                            return (
                                <div key={index} className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                                    <div className="d-flex align-items-center">
                                        <div className="symbol symbol-35px symbol-circle">
                                            {user.profile_photo_thumbnail? 
                                            <img alt="Pic" src={user.profile_photo_thumbnail} />
                                            :
                                            <span className="symbol-label bg-danger text-light fw-bold">
                                                {user.first[0]}                                            
                                            </span>}
                                        </div>
                                        <div className="ms-6">
                                            <a className="d-flex align-items-center fs-5 fw-bold text-gray-900">
                                                {getUserName(user)}
                                            </a>
                                            <div className="fw-semibold text-muted">{user.email_address}</div>
                                            <div className="fw-semibold text-muted">Last Seen: {formattedTime ?? ''}</div>
                                        </div>
                                    </div>

                                    {/* <div class="d-flex flex-column align-items-end justify-content-center">
                                        <div class="fw-bold text-success text-uppercase fs-8">{intl.formatMessage({ id: 'MENU.DASHBOARD.VIEWUSERDIALOG.CURRENTLYVIEWING' })}</div>
                                        <div class="fw-bold text-gray-900"><i class="fa fa-circle fs-8 text-success me-1"></i> Classes</div>
                                    </div> */}

                                </div>
                            )})
                        }
                    </div>
                </div>
            {/* <!--end::Users--> */}

            </div>
        </Modal>
        ,modalsRoot
    )
}

export default ViewUserDialog