import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { KTSVG } from '../../../../_metronic/helpers';
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { getExtraNarrowRowHeight } from '../../../modules/sgl-utils/agGridHelpers';
import CurrencyRenderer from '../../../modules/output-listing/renderers/CurrencyRenderer';
import { useAppSelector } from '../../../redux/hooks';
import axios from 'axios';
import { useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';

type Props = {
    show: boolean;
    handleClose: () => void;
    allMasterFeesNotAdded: any[];
    allActiveMasterFeesNotAdded: any[];
    activeFeesCount : String;
    updateCallbackFromParent: () => void;
    show_id: Number;
};

const modalsRoot = document.getElementById('root-modals') || document.body

const AddShowFee = ({ show, handleClose, allMasterFeesNotAdded, allActiveMasterFeesNotAdded, activeFeesCount, updateCallbackFromParent, show_id }: Props) => {
    const intl = useIntl()
    const loadingOverlay = useLoadingOverlay()
    const currency = intl.formatMessage({ id: 'CURRENCY' })
    const [gridRef, setGridRef] = useState<any>()
    const customer_id = useAppSelector((state) => state.showCompany.company_id)

    const columnDefs = [
        { field: '', checkboxSelection: true, minWidth: 50, maxWidth: 50, sortable: false },
        { field: 'name', width: 280, headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.NAME' }), filter: 'agTextColumnFilter', wrapText: true, autoHeight: true },
        { field: 'description', width: 180, headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.DESCRIPTION' }), filter: 'agTextColumnFilter', wrapText: true, autoHeight: true },
        { field: 'category', width: 130, headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.CATEGORY' }), filter: 'agTextColumnFilter', wrapText: true, autoHeight: true },
        { field: 'default_qty', width: 80, headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.DEFAULTQTY' }), filter: 'agNumberColumnFilter' },
        { field: 'web_default_qty', width: 80, headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.WEBDEFAULTQTY' }), filter: 'agNumberColumnFilter' },
        { field: 'default_price', headerName: intl.formatMessage({ id: 'LIST.MASTERFEES.DEFAULTPRICE' }), cellRendererSelector: (params: any) => {return {component: CurrencyRenderer,params: {currency: currency,},}}},
    ]

    const [rowData, setRowData] = useState(allActiveMasterFeesNotAdded)
    const containerStyle = { width: '100%', height: '400px' }

    const [searchFee, setSearchFee] = useState('')
    const [showInactive, setShowInactive] = useState(false)

    const handleCheckboxChange = (event: { target: { checked: any; }; }) => {
        if (event.target.checked) {
          setShowInactive(true)
          setRowData(allMasterFeesNotAdded)
        } else {
          setRowData(allActiveMasterFeesNotAdded)
        }
    }

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFee(event.target.value);
    }

    useEffect(() => {
        // Implement the logic for filtering rowData based on searchFee and showInactive state
        const filteredData = allMasterFeesNotAdded.filter((fee) => {
          const isActive = fee.active === 1;
          return (
            (showInactive || isActive) &&
            (searchFee === '' ||
              fee.name.toLowerCase().includes(searchFee.toLowerCase()))
          );
        });
    
        setRowData(filteredData);
    }, [searchFee, showInactive, allMasterFeesNotAdded])

    function handleAddButtonClick() {
        if (gridRef !== null) {
            loadingOverlay({ show: true })
            let selected_fees = gridRef.getSelectedRows();
            let selectedIds = selected_fees.map((fee: { master_fee_id: any; }) => fee.master_fee_id);
            
            if (selectedIds.length > 0) {
                axios.post(process.env.REACT_APP_NEST_API_URL + '/shows/fillShowFeeDataFromMasterFees', {
                    params: {
                      master_fee_ids: selectedIds,
                      customer_id: customer_id,
                      show_id: show_id
                    }
                  }).then((response) => {
                    if(response.data.success){
                        updateCallbackFromParent()
                        handleClose()
                    }
                    loadingOverlay({ show: false })
                  })
            }else{
                loadingOverlay({ show: false })
                handleClose()
            }
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px search-form' // Increase the width here
            show={show}
            onHide={handleClose}
            onKeyDown={(event:any) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused){
                    event.preventDefault()
                    handleAddButtonClick();
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                {/* Modal Title */}
                <h2 className='fs-4'>
                    {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.FEES.MODAL.ADDFEETOSHOW.TITLE' })}
                </h2>
                {/* Close button */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                    {/* Row for Labels */}
                    <div className='mb-2 d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <label className='col-auto col-form-label fs-5 fw-bold py-1' data-tooltip-id="SHOW.DETAIL.MODAL.ADDFEETOSHOW.LABEL.FEESTOBEADDED">{intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.FEES.MODAL.ADDFEETOSHOW.LABEL.FEESTOBEADDED' })}</label>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <label className='col-auto col-form-label fs-5 fw-bold py-1' data-tooltip-id="SHOW.DETAIL.MODAL.ADDFEETOSHOW.LABEL.TOTALACTIVE">{intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.FEES.MODAL.ADDFEETOSHOW.LABEL.TOTALACTIVE' })}{activeFeesCount}</label>
                        </div>
                    </div>
                    {/* AG-Grid Component */}
                    <div className='mb-2'>
                        <OutputListingGridStatic
                            // area={'Entries - Membership Detail'}
                            setGridRef={setGridRef}
                            columnDefs={columnDefs}
                            rowData={rowData}
                            containerStyle={containerStyle}
                            rowHeight={getExtraNarrowRowHeight}
                            selection={'multiple'}
                            rowMultiSelectWithClick={true}
                        />
                    </div>

                    <div className='d-flex align-items-center justify-content-between'>

                        <div className='row mb-2'>
                            {/* Column Group */}
                            <div className='col-auto'>
                                {/* Column 1 */}
                                <div className='row mb-2'>
                                    <label className='col-auto col-form-label fs-5 fw-bold py-1' htmlFor='NAME' data-tooltip-id="SHOW.DETAIL.MODAL.ADDFEETOSHOW.LABEL.SEARCHFEE">
                                        {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.FEES.MODAL.ADDFEETOSHOW.LABEL.SEARCHFEE' })}
                                    </label>
                                    <div className='col-auto'>
                                        <input
                                            id='NAME'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            onChange={handleSearchInputChange}
                                            autoFocus
                                            tabIndex={1}
                                        />
                                    </div>
                                </div>
                                {/* End Column 1 */}
                            </div>
                            <div className='col-auto'>
                                {/* Column 2 */}
                                <div className='row mb-2'>
                                    <div className='col-lg-8 form-check-sm form-check-custom ps-lg-3'>
                                        <input
                                            id='ACTIVE'
                                            type='checkbox'
                                            className='form-check-input'
                                            onChange={handleCheckboxChange}
                                            tabIndex={2}
                                        />
                                        <label
                                            className='col-auto col-form-label fs-5 fw-bold py-1'
                                            htmlFor='ACTIVE'
                                            style={{ marginLeft: '10px' }}
                                            data-tooltip-id="SHOW.DETAIL.MODAL.ADDFEETOSHOW.LABEL.SHOWINACTIVE"
                                        >
                                            {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.FEES.MODAL.ADDFEETOSHOW.LABEL.SHOWINACTIVE' })}
                                        </label>
                                    </div>
                                </div>
                                {/* End Column 2 */}
                            </div>
                            {/* End Column Group */}
                        </div>
                        {/* Close and Add Buttons */}
                        <div className='d-flex'>
                            <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold text-uppercase' onClick={() => handleClose()} tabIndex={3}
                            >
                                {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.FEES.MODAL.ADDFEETOSHOW.BUTTON.CLOSE' })}
                            </button>
                            <button type='button' className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={() => handleAddButtonClick()} tabIndex={4} data-tooltip-id="SHOW.DETAIL.MODAL.ADDFEETOSHOW.BUTTON.ADD">
                                {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.FEES.MODAL.ADDFEETOSHOW.BUTTON.ADD' })}
                            </button>
                        </div>
                    </div>
            </div>
        </Modal>,
        modalsRoot
    );
};

export { AddShowFee };
