import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAlert } from '../../modules/sgl-utils/DialogsProvider'
import { PersonLookupForm } from './Detail/General/PersonLookupForm';
import { useAppSelector } from '../../redux/hooks';
import { FormProvider, useForm } from 'react-hook-form'
import ClassAddDrop from './Detail/Classes/ClassAddDrop'
import axios from 'axios'
import Select from 'react-select'
import { reactSelectStyles } from '../../modules/sgl-utils/fieldControls'
import { getLastNameFromSearchString, getFirstNameFromSearchString } from '../../modules/sgl-utils/SglFunctions'

// Metronic Components
import { KTSVG } from '../../../_metronic/helpers'
import { createArrayOfNumbersFromText, removeDialogFromSession } from '../../modules/sgl-utils/SglFunctions'

const modalsRoot = document.getElementById('root-modals') || document.body

const RapidAddDrop = ({ show, handleClose }) => {
    const intl = useIntl()
    const alertDialog = useAlert()
    
    const customer_id = useAppSelector((state) => state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)
    const methods = useForm()

    const [errors, setErrors] = useState([])
    const [messages, setMessages] = useState([])
    const [entryNumber, setEntryNumber] = useState(0)
    const childRef = useRef()
    const [selectedRider, setSelectedRider] = useState()
    const executeButtonRef = useRef()
    const entryNumberInputRef = useRef()
    const [entry, setEntry] = useState()
    const [entryRiders, setEntryRiders] = useState([])
    const [classesToAdd, setClassesToAdd] = useState("")
    const [classesToDrop, setClassesToDrop] = useState("")
    const [riderOptions, setRiderOptions] = useState();

    useEffect(() => {
        const getRiderOptions = () => {
            const options = entryRiders?.map((er) => ({
                value: er.rider_id, 
                label: `${er.rider_name} ${er.Rider?.rider_status ? " (" + er.Rider.rider_status + ")" : ""} `
            }));
            setRiderOptions(options);
        }

        getRiderOptions();
    }, [entryRiders])


    const riders = entryRiders.map(er => 
        <option value={er.rider_id}>
            { `${er.rider_name} ${er.Rider?.rider_status ? " (" + er.Rider.rider_status + ")" : ""} ` }
        </option> 
    )

    
    const governingOrganization = useAppSelector(state => state.showCompany.governing_organization)
    const [dialogData, setDialogData] = useState({
        first_name: '',
        last_name: '',
        city: '',
        state:'',
        usef_no: 0,
        search_term: '',
        company_name: ''
    })

    const [showPersonLookup, setShowPersonLookup] = useState(false)
    const saveEntryRiderRapidAddDrop = async (people) => {
        await axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/saveEntryRider', {
            params: {
                people,
                customer_id,
                show_id: currentShowID,
                entry_id: entry.entry_id
            }
        })
        .then(response => {
            if (response.data.success) {
                setEntryRiders(response.data.entry.EntryRiders)
                // Select newly added rider
                setSelectedRider(response.data.newRiderId)
            } else if (response.data.error) {
                alertDialog({message: response.data.error, title: "error"})
            }
        })
    } 

    // valid input regex (can contain numbers, comma, hyphen, plus, space, period)
    const regex = /^[\d,-\s+\.]+$/

    useEffect(() => {
        if (errors.length === 0 && entryNumber > 0) {
            const handleKeyDown = (event) => {
                if (event.key === "Escape") {
                    removeDialogFromSession('RapidClassAddDrop')
                    handleClose();
                } if (event.key === "Enter") {
                    //handleExecute()
                }
            };

            document.addEventListener("keydown", handleKeyDown);

            // Cleanup function to remove event listener on unmount
            return () => {
                document.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [errors, entryNumber]);

    const getEntryFromNumber = async () => {
        setErrors([])
        if (entryNumber == 0) { return }
        //Call webservice only when valid entry numbers are entered
        let isNumber = /^[0-9]+$/.test(entryNumber)
        if (!isNumber) {
            setErrors(['Please enter valid entry number.'])

            // Set focus to entry number input
            entryNumberInputRef.current.focus();
            entryNumberInputRef.current.select();

            return
        }

        await axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/getEntryFromNumber', {
            params: {
                entry_number: entryNumber,
                customer_id,
                show_id: currentShowID,
            }
        })
        .then(response => {
            // set riders list
            setEntryRiders(response.data.entry.EntryRiders)
            const riderToSelect = response.data.entry.EntryRiders[0]
            setSelectedRider(riderToSelect.rider_id)
            setEntry(response.data.entry)
            // clear error
            setErrors([])
            // clear messages
            setMessages([])

            // enable execute button
            executeButtonRef.current.disabled = false
        })
        .catch(e => { 
            if (e.response.data.error) {
                // Clear Entry Riders to update Select
                setEntryRiders([])

                // Clear selected rider
                setSelectedRider()

                // Empty the entry
                setEntry(null)

                // set error
                setErrors([e.response.data.error])

                // disable execute button
                executeButtonRef.current.disabled = true

                // focus entry number input
                entryNumberInputRef.current.focus();
                entryNumberInputRef.current.select();
            }
        })
    }

    const handleExecute = () => {
        const riderId = selectedRider
        const number = entryNumber

        // Clears state values on execute
        clearErrorMessages()

        if(!riderId){
            setErrors(['Please select a rider first.'])
            return
        }

        if (classesToAdd !== "" || classesToDrop != "") {
            if ((regex.test(classesToAdd) || regex.test(classesToDrop)) && isClassesArrayValid(classesToAdd, classesToDrop)) { // Check for valid input
                childRef.current.callRapidAddDropClassByNumbers(number, riderId, classesToAdd, classesToDrop)
            } else {
                setErrors(["Please provide valid input."])
            }
        }
    }

    const clearErrorMessages = () => {
        setErrors([])
        setMessages([])
    }

    const clearStates = () => {
        setClassesToAdd("")
        setClassesToDrop("")
        setEntryRiders([])
        setEntry(null)
        setSelectedRider()
        setEntryNumber(0)
    }

    const handleRapidAddDropClose = () => {
        // Clears state values on close
        clearStates()
        clearErrorMessages()
        removeDialogFromSession('RapidClassAddDrop')
        handleClose()
    }

    const handleAddRiderButton = () => {
        if (!executeButtonRef.current.disabled) {
            setShowPersonLookup(true)
        }
    }

    const isClassesArrayValid = (classesToAdd, classesToDrop) => {
        let classesToAddArray = createArrayOfNumbersFromText(classesToAdd)
        let classesToDropArray = createArrayOfNumbersFromText(classesToDrop)
        let valid = true
        if(classesToAdd.length > 0 && classesToAddArray.length === 0){
            valid = false
        }

        if(classesToDrop.length > 0 && classesToDropArray.length === 0){
            valid = false
        }
        return valid
    }

    useEffect(() => {
        if(entryNumberInputRef && entryNumber == 0){
            entryNumberInputRef.current.focus();
            entryNumberInputRef.current.select();
        }
    }, [])

    useEffect(() => {
        if (show) {
            if(showFinancialsLocked){
                alertDialog({message: intl.formatMessage({ id: "ENTRIES.RIDERCLASSADDDROP.MODAL.SHOWLOCKED" }), title: "warning"})
                handleRapidAddDropClose()
                return
            }
            const storedModals = JSON.parse(sessionStorage.getItem('modals_open') || '[]');
            sessionStorage.setItem('modals_open', JSON.stringify([...storedModals, "RapidClassAddDrop"]));
        }
    }, [show]);

    //Function for handling Search Tem
    const handleSearchTerm = (search_term) => {
        let firstName = getFirstNameFromSearchString(search_term)
        let lastName = getLastNameFromSearchString(search_term)
        let searchTerm = firstName != ''? firstName + (lastName != '' ?' ':'')+ lastName : lastName == '' ? '' : lastName
        setDialogData((prevDialogData) => ({...prevDialogData, first_name: firstName, last_name: lastName, search_term: searchTerm}))
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px search-form'
            show={show}
            onHide={handleRapidAddDropClose}
            onKeyDown={(event) => {
                const storedModals = sessionStorage.getItem('modals_open');
                const modals_open = storedModals ? JSON.parse(storedModals) : [];
                const lastModal = modals_open.pop();
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && lastModal === 'RapidClassAddDrop' && !isButtonFocused) { //call function only when no button is in focus
                    handleExecute()
                }
            }}
        >
            <FormProvider {...methods}>
                <ClassAddDrop 
                    ref={childRef}
                    person_id={selectedRider}
                    setErrors={setErrors}
                    setMessages={setMessages}
                    clearStates={clearStates}
                    calledFrom="Rapid"
                    entryNumberInputRef={entryNumberInputRef}
                />
                <form noValidate className='form max-width'>
                    {showPersonLookup && <PersonLookupForm
                        show={showPersonLookup}
                        handleClose={() => {methods.setValue('rider_search', '');setShowPersonLookup(false);}} 
                        customer_id={Number(customer_id)} 
                        fieldName={'rider_search'} 
                        dialogData={dialogData}    
                        setDialogData={setDialogData}                 
                        person_search_list={[]}
                        default_org={governingOrganization}
                        person_search_status=""
                        type = {'RIDER'}
                        show_id = {currentShowID}
                        handleSearchTerm={handleSearchTerm}
                        getSelectedPeople={saveEntryRiderRapidAddDrop}
                        generateNewRTOId={() => -1}
                        updatePeople={saveEntryRiderRapidAddDrop}
                        isTransactionalMode={false}
                    />
                    }
                </form>
            </FormProvider>

            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.HEADING.RIDERCLASSADDDROP' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleRapidAddDropClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fs-5 py-1' htmlFor="entryNumber" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDCLASSADDDROP.LABEL.ENTRYNUMBER">
                                {intl.formatMessage({ id: 'ENTRIES.RIDERCLASSADDDROP.MODAL.ENTRYNUMBER' })}
                            </label>
                            <div className='col-lg-3'>
                                <input
                                    ref={entryNumberInputRef}
                                    autoFocus
                                    onChange={e => setEntryNumber(e.target.value)}
                                    value={entryNumber}
                                    onBlur={getEntryFromNumber} 
                                    id="entryNumber"
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'ENTRIES.RIDERCLASSADDDROP.MODAL.ENTRYNUMBER' })}
                                    tabIndex={1}
                                    onFocus={(e) => {
                                        e.target.select(); // Highlight the value
                                    }}
                                />
                            </div>
                            <label className='col-lg-6 col-form-label fs-5 py-1'>
                                { entry?.horse }
                            </label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDCLASSADDDROP.LABEL.RIDER">
                                {intl.formatMessage({ id: 'ENTRIES.RIDERCLASSADDDROP.MODAL.RIDER' })}
                            </label>
                            <div className='col-lg-6'>
                                {/* <select
                                    className='form-select  form-select-sm fs-6 min-h-20px py-1'
                                    onChange={(e) => setSelectedRider(e.target.value)}
                                    value={selectedRider}
                                >
                                    { riders }
                                </select> */}
                                <Select
                                    onChange={(e) => setSelectedRider(e.value)}
                                    value={riderOptions?.find((option) => option.value == selectedRider) ?? ''}
                                    styles={reactSelectStyles}
                                    options={riderOptions}
                                    tabIndex={2}
                                />
                            </div>
                            
                            {/* <div 
                                className='col-lg-1 d-flex align-items-center btn-secondary w-lg-35px cursor-pointer'
                                onKeyDown={ e => e.key === "Enter" ? handleAddRiderButton() : null }
                                onClick ={handleAddRiderButton}
                                tabIndex={3}
                            >
                                <i className="fa-sharp fa-solid fa-add fa-xl"></i>
                            </div> */}

                            <div className='col-lg-1 py-0 px-0'>
                                <button 
                                    type='button' 
                                    onClick ={handleAddRiderButton} 
                                    className="btn btn-sm btn-secondary fw-bold py-1 px-2 h-100 cursor-pointer" 
                                    onKeyDown={ e => e.key === "Enter" ? handleAddRiderButton() : null }
                                    tabIndex={3}
                                    disabled={!entry || !/^[0-9]+$/.test(entryNumber) || entryNumber == 0}
                                >
                                    <i className="fas fa-plus fs-5 px-1 py-3"></i>
                                </button>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fs-5 py-1' htmlFor="classesToAdd" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDCLASSADDDROP.LABEL.ENTERADDS">
                                {intl.formatMessage({ id: 'ENTRIES.RIDERCLASSADDDROP.MODAL.ENTERADDS' })}
                            </label>
                            <div className='col-lg-8'>
                                <input
                                    value={classesToAdd}
                                    onChange={e => setClassesToAdd(e.target.value)}
                                    id="classesToAdd"
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'ENTRIES.RIDERCLASSADDDROP.MODAL.ENTERADDS' })}
                                    tabIndex={4}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-3 col-form-label fs-5 py-1' htmlFor="classesToDrop" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDCLASSADDDROP.LABEL.ENTERDROPS">
                                {intl.formatMessage({ id: 'ENTRIES.RIDERCLASSADDDROP.MODAL.ENTERDROPS' })}
                            </label>
                            <div className='col-lg-8'>
                                <input
                                    value={classesToDrop}
                                    onChange={e => setClassesToDrop(e.target.value)}
                                    id="classesToDrop"
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'ENTRIES.RIDERCLASSADDDROP.MODAL.ENTERDROPS' })}
                                    tabIndex={5}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className='col-lg-3 col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDCLASSADDDROP.LABEL.PROGRESSERROR">
                                Progress/Error:
                            </label>
                            <div className='col-lg-8'>
                                {
                                    errors.map(error => 
                                        <label className='row error_message'>
                                            <span>{error}</span>
                                        </label>
                                    )
                                }
                                {
                                    messages.map(message => 
                                        <label className='row col-form-label fs-5 py-1 text-success'>
                                            <span>{message}</span>
                                        </label>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase'
                            type="button"
                            onClick={handleRapidAddDropClose}
                            onKeyDown={(e) => { if (e.key === "ESC") { handleRapidAddDropClose() }}}
                            tabIndex={6}
                            data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDCLASSADDDROP.BUTTON.DONE"
                        >
                            { intl.formatMessage({ id: 'ENTRIES.RIDERCLASSADDDROP.MODAL.BUTTON.DONE' }) } 
                        </button>
                        <button 
                            className='btn btn-sm btn-dark fw-bold text-uppercase' 
                            type="button"
                            ref={executeButtonRef}
                            onClick={handleExecute}
                            tabIndex={7}
                            data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDCLASSADDDROP.BUTTON.EXECUTE"
                            // onKeyPress={(e) => { if (e.key === "Enter") { handleExecute() }}}
                        >
                            { intl.formatMessage({ id: 'ENTRIES.RIDERCLASSADDDROP.MODAL.BUTTON.EXECUTE' }) } 
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default RapidAddDrop