import { useIntl } from 'react-intl'
import { useAppSelector } from '../../redux/hooks';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { parsePhoneNumber } from 'libphonenumber-js';

export const useCurrencyFormatter = ()  => {
    const intl = useIntl();
    const currencyCode = useAppSelector(state => state.showCompany.currency_code);
    const currencySymbol = intl.formatMessage({ id: `CURRENCY.CODE.SYMBOL.${currencyCode}` })
    
    const currencyFormatter = (value, isCR = false) => {
        if (value && !isNaN(value)) {
            const valueToDisplay = Math.abs(Number(value)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
            if (Number(value) < 0) {
                if (isCR) {
                    return currencySymbol + valueToDisplay + 'CR' 
                }
                return '-' + currencySymbol + valueToDisplay;
            }
    
            return currencySymbol + valueToDisplay;
        } else {
            return currencySymbol + '0.00';
        }
    }

    return currencyFormatter
}

export const PhoneNumberFormatter = (input) => input? input.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"): ''

// where we do not have a separate country code field, will return the number without the country code
export const phoneParserForSingleInputField = (phoneNumber) => { 
    try {
        console.log('phoneNumber', phoneNumber)
        // Getting the formatted phone number
        if (!phoneNumber) {
            return '';
        }

        if (typeof phoneNumber !== 'string') {
            phoneNumber = String(phoneNumber);
        }

        let formattedPhone = phoneNumber.replace(/\D/g, ''); // Remove all non-numeric characters

        if (formattedPhone.length < 10) {
            formattedPhone = formattedPhone; // Return the original string if it does not have 10 digits
        } else if (formattedPhone.length == 10) { //  If the number has 10 digits
            // If the number has 10 digits or less, format as (XXX) XXX-XXXX
            formattedPhone = formattedPhone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        }  else if (formattedPhone.length > 10) { // If the number has more than 10 digits
            // If the number has more than 10 digits, consider the first 1-3 digits as the country code
            const countryCodeLength = Math.min(3, formattedPhone.length - 10); // Max 3 digits for country code
            const countryCode = formattedPhone.slice(0, countryCodeLength);
            const mainNumber = formattedPhone.slice(countryCodeLength, countryCodeLength + 10);
            const extraDigits = formattedPhone.slice(countryCodeLength + 10);
    
            // formattedNumber = `+${countryCode} (${mainNumber.slice(0, 3)}) ${mainNumber.slice(3, 6)}-${mainNumber.slice(6)}`;
            formattedPhone = mainNumber;
    
            if (extraDigits) {
                formattedPhone += `${extraDigits}`;
            }
        }
        
        return formattedPhone;
    } catch (error) {
        return phoneNumber;  // Return the original string in case of any parsing errors
    }
}

// Get country code from a phone field where we do not have a separate country code field, e.g. delivery_notification_phone in entry fee OR sgl_mobile in people table
// This function will return the country code from the phone number
// This will be used with React intl-tel-input
export const getCountryCodeIsoFromPhoneNumber = (phoneNumber) => {
    let countryCode = '';
    try {
        if (!phoneNumber) {
            countryCode = 'us'; // Default country code is US
        }

        if (typeof phoneNumber !== 'string') {
            phoneNumber = String(phoneNumber);
        }

        // If phone number is of 0 length, return default country code
        if (phoneNumber.length == 0) {
            countryCode = 'us'; // Default country code is US
        } else if(phoneNumber.length <= 9) {
            countryCode = ''; // No country code
        } else {
            if (phoneNumber.length == 10) {
                countryCode = 'us'; // Default country code is US
            } else {
                countryCode = parsePhoneNumber(`+${phoneNumber}`, 'US')?.country?.toLowerCase();
            }
        }

        return countryCode;
    } catch (error) {
        return '';  // Return the original string in case of any parsing errors
    }

}

export const updateCountryCodeInPhoneNumber = (phoneNumber, countryCode) => {   // Used with React intl-tel-input, flag update
    if (!phoneNumber) {
        return '';
    }

    if (typeof phoneNumber !== 'string') {
        phoneNumber = String(phoneNumber);
    }

    // Remove all non-numeric characters
    phoneNumber = phoneNumber.replace(/\D/g, '');

    if (phoneNumber.length < 10) {
        return phoneNumber; // Return the original string if it does not have 10 digits
    } else if(phoneNumber.length >= 10) {
        countryCode = countryCode || '1'; // Default country code is US
        return countryCode + phoneNumber; // Add country code to the phone number
    }
}


export const usePhoneNumberFormatter = () => {

    const phoneFormatter = (phoneNumber) => {
        try {
            if (!phoneNumber) {
                return ''
            }

            if (typeof phoneNumber !== 'string') {
                phoneNumber = String(phoneNumber)
            }

            let formattedNumber = phoneNumber;
        
            if (phoneNumber.length <= 10) {
                // If the number has 10 digits or less, format as (XXX) XXX-XXXX
                formattedNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
            } else {
                // If the number has more than 10 digits, consider the first 1-3 digits as the country code
                const countryCodeLength = Math.min(3, phoneNumber.length - 10); // Max 3 digits for country code
                const countryCode = phoneNumber.slice(0, countryCodeLength);
                const mainNumber = phoneNumber.slice(countryCodeLength, countryCodeLength + 10);
                const extraDigits = phoneNumber.slice(countryCodeLength + 10);
        
                formattedNumber = `+${countryCode} (${mainNumber.slice(0, 3)}) ${mainNumber.slice(3, 6)}-${mainNumber.slice(6)}`;
        
                if (extraDigits) {
                    formattedNumber += `${extraDigits}`;
                }
            }
        
            return formattedNumber;
            
        } catch (error) {
            console.log(error)
            return phoneNumber; // Return the original string in case of any parsing errors
        }
    }

    return phoneFormatter
}