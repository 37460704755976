import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../redux/hooks';
import { useAlert, useLoadingOverlay } from '../../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import moment from 'moment';
import { KTSVG } from '../../../../../_metronic/helpers';
import AddClassesFromOtherShow from '../AddClassesFromOtherShow';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const ShowSelector = ({  show, handleClose }) => {
    const intl = useIntl() 
    const alertDialog = useAlert()
    const customerId = useAppSelector(state=> state.showCompany.company_id)
    const currentShowId = useAppSelector(state => state.currentShow.show_id);
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)
    const [shows, setShows] = useState([])
    const [selectedShow, setSelectedShow] = useState() 

    const [showImportClassesModal, setShowImportModal] = useState(false)

    const loadingOverlay = useLoadingOverlay()

    useEffect(() => {
        if (show) {
            loadingOverlay({ show: true })
            axios.get( NEST_API_URL + `/shows?customer_id=${customerId}`)
            .then(response => {
                let _shows = response.data.shows

                if (_shows.length > 0) {

                    // delete source show from shows list
                    const sourceShowIndex = _shows.findIndex(sh => sh.show_id === currentShowId)
                    _shows = _shows.filter(sh => sh.show_id !== currentShowId)
                    
                    if (_shows.length > 0) {
                        setShows(_shows)
                        if (sourceShowIndex < _shows.length) {
                            setSelectedShow(_shows[sourceShowIndex].show_id)
                        } else {
                            setSelectedShow(_shows[0].show_id) // set to first show
                        }
                    }
                }

                loadingOverlay({ show: false })
            })
            .catch(() => loadingOverlay({ show: false }))
        }  
    }, [show])

    return createPortal(
        <>
            <AddClassesFromOtherShow 
                show={showImportClassesModal}
                handleClose={() => {
                    handleClose() // Close Show Selector Modal
                    setShowImportModal(false) // Close Import Class Modal
                }}
                selectedShow={{ ...shows.find(show => show.show_id === Number(selectedShow))}}
            />
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
                show={show}
                onHide={handleClose}
                onKeyDown={(event) => {
                    let isButtonFocused = false
                    let activeElement = document.activeElement //gets the currently focussed element
                    if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                        isButtonFocused = true;
                    }
                    if (event.key == 'Enter' && !isButtonFocused) {
                        setShowImportModal(true)
                    }
                }}
            >
                <div className='modal-header py-0 px-4'>
                    <h2 className="fs-4">{intl.formatMessage({ id: 'Classes.QuickAction.ShowSelector.Heading' })}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>

                <div className='modal-body py-3 px-6'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col'>
                                <div className='d-flex justify-content-between'>
                                    <label className='col-form-label fs-5 py-1' htmlFor='showSelected'>
                                        { intl.formatMessage({ id: 'Classes.QuickAction.ShowSelector.Label.Shows' }) }
                                    </label>
                                    <label className='col-form-label fs-5 py-1' htmlFor='showSelected'>
                                        { shows.length }
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col'>
                                <select
                                    className='form-select form-select-sm fs-5 min-h-20px py-1'
                                    size={15}
                                    value={selectedShow}
                                    onChange={e => setSelectedShow(e.target.value)}
                                    autoFocus
                                    tabIndex={1}
                                >
                                    {
                                        shows.map(show => 
                                            <option value={show.show_id}>{`[${moment(show.start_date).format("MM-DD-YY")}] ${show.show_name}`}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
            
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={handleClose} tabIndex={2}>
                            {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CANCEL" })}
                        </button>

                        <button 
                            type="button" 
                            className='btn btn-sm btn-dark fw-bold text-uppercase' 
                            onClick={() => setShowImportModal(true)}
                            disabled={shows.length === 0}
                            autoFocus
                            tabIndex={3}
                            >
                            {intl.formatMessage({ id: 'Classes.QuickAction.ShowSelector.Button.SelectShow' })}
                        </button>
                    </div>
                </div>
            </Modal>
        </>,
        modalsRoot
    )
}

export default ShowSelector