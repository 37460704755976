
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import { SeparatedDateTimeRenderer } from '../../modules/output-listing/renderers/DateTimeRenderer';

const SiteMonitoringList = () => {
  const intl = useIntl()

  const columnDefs = [
    { field: 'sgl_id', maxWidth: 100, headerName: intl.formatMessage({ id: 'LIST.SITEMONITORING.ID' }), filter: 'agNumberColumnFilter'},
    { field: 'source', minWidth: 190, headerName: intl.formatMessage({ id: 'LIST.SITEMONITORING.SOURCE' }), filter: 'agTextColumnFilter'},
    { field: 'tab_name', minWidth: 250, headerName: intl.formatMessage({ id: 'LIST.SITEMONITORING.TABNAME' }), filter: 'agTextColumnFilter', },
    { field: 'activity', minWidth: 250, headerName: intl.formatMessage({ id: 'LIST.SITEMONITORING.ACTIVITY' }), filter: 'agTextColumnFilter', },
    { field: 'description', minWidth: 250, headerName: intl.formatMessage({ id: 'LIST.SITEMONITORING.DESCRIPTION' }), filter: 'agTextColumnFilter', },
    { field: 'unix_time', minWidth: 100, headerName: intl.formatMessage({ id: 'LIST.SITEMONITORING.DATE' }), filter: 'agDateColumnFilter', headerClass: "ag-center-aligned-header", rowClass: "text-center", cellStyle: { textAlign: 'center' }, cellRendererFramework: (params) => {
        return (
            <SeparatedDateTimeRenderer params = {params} date={params?.data?.date} time={params?.data?.local_time}/>
        );
      } },
    { field: 'Company.urlpath', minWidth: 100, headerName: intl.formatMessage({ id: 'LIST.SITEMONITORING.COMPANY' }), filter: 'agTextColumnFilter', valueGetter: function getURLPath(params) { return params?.data?.Company.urlpath?.toUpperCase()} ,cellStyle: { textAlign: 'center' }}, 
    { field: 'user_name', minWidth: 100, headerName: intl.formatMessage({ id: 'LIST.SITEMONITORING.USER' }), filter: 'agTextColumnFilter'}
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.MONITORING' })}</PageTitle>
      <OutputListingComponent area={'SiteMonitoring'} columnDefs={columnDefs} apiEndPoint={'utility/site-monitoring-list'} >
      </OutputListingComponent>
    </>
  )
}

export { SiteMonitoringList }
