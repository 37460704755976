/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {FormLabel, Modal} from 'react-bootstrap'
import {initialQueryState, KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import "flatpickr/dist/themes/material_blue.css";
import { useSchedulerContext } from './SchedulerContext'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useAlert } from '../../../modules/sgl-utils/DialogsProvider'
import axios from 'axios'
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../modules/sgl-utils/SglFunctions'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

type Props = {
  show: boolean
  handleClose: () => void
  setScheduleStatus: (status: string) => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const MoveGroup = ({show, handleClose, setScheduleStatus}: Props) => {
  const intl = useIntl()
  const {currentClassGroup, selectedDate, selectedRing, showDates, showRings, classGroupsGridRef, setCurrentClassGroup, getClassGroups} = useSchedulerContext();
  const { register, watch, reset, getValues, control, setValue } = useForm({});
  const alertDialog = useAlert();
  const [selectedRingName, setSelectedRingName] = useState('');
  const moveGroupDay = watch('day', '0000-00-00');
  const [moveGroupRings, setMoveGroupRings] = useState(Array());
  
  useEffect(() => {
    //initialize data in form
    reset({
      show_id: currentClassGroup.show_id,
      customer_id: currentClassGroup.customer_id,
      day: currentClassGroup.day,
      ring_id: '-1',
      class_group_ids: [],
    })
  }, [currentClassGroup]);

  useEffect(() => {
    if(show){ //modal shown
      let selectedClassGroups:any = []
      let selectedRows = classGroupsGridRef.getSelectedNodes()
    
      if(selectedRows.length == 0){ //No valid groups selected after applying filter?
        handleClose()
        alertDialog({message: 'Select atleast one group to move.'})
      }
      else{
        //Get group ids for move class group
        for(let row of selectedRows){
          selectedClassGroups.push(row.data.class_group_id)
        }

        //set class group ids in form data to be submitted
        setValue('class_group_ids', selectedClassGroups) //set filtered class groups in form object

        //Set first ring at start
        if(moveGroupRings.length > 0){
          setValue('ring_id', moveGroupRings[0].ring_id);
        }
      }

      //Set ring name of current scheduler ring
      for(let showRing of showRings){
        if(selectedRing == showRing.ring_id){
          setSelectedRingName(showRing.ring_name);
          break;
        }
      }
    }
  }, [show]);

  //Object Method: [Dialogs]Class_Scheduler_MoveGrp.as_ClassMoveGrp_FilterDay
  useEffect(() => {
    let data = getValues();
    let moveDialogRings: any[] = [];
    for(let showRing of showRings){
      //Check to do not allow user to select same ring and day for class group where it is already scheduled
      if(selectedDate == data.day){ //scheduler view date matches selected day in move group dialog
        if(selectedRing == showRing.ring_id){
          continue; //ignore this ring from dropdown list
        }
      }
      //append ring in rings dropdown list
      moveDialogRings.push(showRing)
    }

    //set selected ring in dropdown
    if(moveDialogRings.length > 0){
      //Get ring index in dropdown list of selected class group(s)
      let vl_find = 0;
      for(let key in moveDialogRings){
        if(moveDialogRings[key].ring_id == selectedRing){ //actual ring of class group found in rings dropdown list
          vl_find = parseInt(key);
          break;
        }
      }

      //select ring for the class group in dropdown list 
      setValue('ring_id', moveDialogRings[vl_find].ring_id);
    }

    //update data in state variable
    setMoveGroupRings(moveDialogRings);
  }, [moveGroupDay]);

  const handleMove = (event: React.MouseEvent<HTMLElement>) => {
    let data = getValues()

    //Get day number of selected data from days list
    data.day_number = '';
    for(let key in showDates){
      if(showDates[key] == data.day){
        data.day_number = parseInt(key) + 1;
        break;
      }
    }

    //Get ring no. of selected ring from show rings list based on ring_id
    data.ring = '';
    data.ring_name = '';
    for(let ring_x_show of moveGroupRings){
      if(ring_x_show.ring_id == data.ring_id){
          data.ring = ring_x_show.ring_number;
          data.ring_name = ring_x_show.ring_name;
          break;
      }
    }

    //call webservice to move class group
    loadingSpinnerBtnWait(event)
    axios.patch(`${NEST_API_URL}/scheduler/moveclassgroups`, data)
    .then(result => {   
      handleClose() //close dialog
      setCurrentClassGroup({}) //clear selected class group
      getClassGroups(true); //refresh groups list

      //show pending state save message if atleast one group is successfully moved
      if('movedGroups' in result.data && result.data.movedGroups.length > 0) { 
        setScheduleStatus('Pending');
      }

      //show errors for class groups which were not moved and show errors in alert dialog
      if('errors' in result.data && result.data.errors.length > 0) { 
        alertDialog({message: result.data.errors.join(`<br/>`)});
      }
    })
    .catch(async function (error) {
        if (error.response) {
          alertDialog({message: error.response.data.error});
        }
    })
    .finally(() =>{
        loadingSpinnerBtnRelease(event)
    });
  }

  return createPortal(
    <Modal
      id='kt_modal_move_group'
      enforceFocus={false}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered modal'
      show={show}
      onHide={handleClose}
      onKeyPress={(event: any) => {
        if (event.key == 'Enter') {
            handleMove(event)
        }
      }}
    >
      <div className='modal-header py-0 px-4' >
        <h2 className="fs-4" >{intl.formatMessage({id: 'SCHEDULER.MOVEGROUP.TITLE.MOVEGROUPS'})}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4'>
        <form noValidate className='form' onSubmit={e => e.preventDefault()}>
          <div className='card-body p-0'> {/* Card Body */}
            
          <div className="form-group row"> {/* Column Group */}
          <p className="fw-bold">Move selected group(s) from {moment(selectedDate).format('dddd, MMMM D, YYYY')} and {selectedRingName} to:</p>
          
          <div className='row mb-2'>
            <div className='col-lg-12 '>
              <select
                id='MOVEGROUPDAY'
                className='form-select  form-select-sm fs-6 min-h-20px py-1'
                {...register("day")}
                autoFocus
              >
              {
                showDates.map((date: any) => {
                    let currFormattedDate = moment(date).format('dddd, MMMM D, YYYY');
                    return <option key={date} value={date}>{currFormattedDate}</option>
                })
              }
              </select>
            </div>
          </div>

          <div className='row mb-2'>
            <div className='col-lg-12 '>
              <select
                id='MOVEGROUPRING'
                className='form-select  form-select-sm fs-6 min-h-20px py-1'
                {...register("ring_id")}
              >
              {
                moveGroupRings.map((ring: any) =>
                  <option key={ring.ring_id} value={ring.ring_id}>{(ring?.ring_name ? ring.ring_name : ring?.Ring?.name)} {ring?.Ring?.active ? '' : '[INACTIVE]' }</option>)
              }
              </select>
            </div>
          </div>

          </div> {/* End Column Group */}


          </div>{/* End Card Body */}

          <div className='card-footer py-3'>
            <div className='row mb-2'>
              <div className='col-lg-12 pe-9 d-flex justify-content-end'>
                <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' onClick={handleClose}>
                  {intl.formatMessage({id: 'SCHEDULER.MOVEGROUP.BUTTON.CANCEL'})}
                </button>

                <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={(e) => { handleMove(e) }} autoFocus>
                  <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                  {intl.formatMessage({id: 'SCHEDULER.MOVEGROUP.BUTTON.MOVE'})}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {MoveGroup}
