import {useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import {useFormContext} from 'react-hook-form'

const FacilityTab = (props) => {
  const intl = useIntl();
  const [gridRef, setGridRef] = useState(false)
  const methods = useFormContext()
  const rowData = methods.getValues('linkedFacilities')

  const columnDefs = [
    { field: 'name', flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.BARNS.TAB.FACILITIES.NAME' })},
    { field: 'total_barns_count', width: 100, headerName: intl.formatMessage({ id: 'FORM.INPUT.BARNS.TAB.FACILITIES.NUMBEROFBARNS' }),  cellStyle: {textAlign: 'center'}},
  ]

  const containerStyle = useMemo(() => ({ width: '100%', height: '320px' }), []);

  return (
    <>
        <div className='row mb-2'>
            <div style={{display: "flex", justifyContent: 'right'}}>{rowData !== undefined ? rowData.length : ""}</div>
            <div className='col-lg d-flex align-items-center mb-2'>
                    <OutputListingGridStatic area ={"Rings - Facilities Tab"} setGridRef={setGridRef} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle}></OutputListingGridStatic>
            </div>
        </div>
    </>
    )
  }
  
export {FacilityTab}