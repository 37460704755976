import { useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { useAppSelector } from "../../redux/hooks"

export const QueuedJobsWidget = () => {
    const intl = useIntl()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const [rtoSse, setRtoSse] = useState(null)
    const [rtoMessage, setRtoMessage] = useState('')
    const rtoEventId = `updateRTOMemberships-${customerId}`
    const horseEventId = `updateHorseMemberships-${customerId}`
    const [horseSse, setHorseSse] = useState(null)
    const [horseMessage, setHorseMessage] = useState('')

    useEffect(() => {
        if (!rtoSse && customerId) {
            setRtoSse(new EventSource(`${process.env.REACT_APP_NEST_API_URL}/utility/getProgress?event_id=${rtoEventId}`))
        }

        if (!horseSse && customerId) {
            setHorseSse(new EventSource(`${process.env.REACT_APP_NEST_API_URL}/utility/getProgress?event_id=${horseEventId}`))
        }
    }, [customerId])
    
    useEffect(() => {
        if (rtoSse) {
            // Define the event handler function
            const handleProgressEvent = (e) => {
                let data = JSON.parse(e.data)
                if (data?.event_id == rtoEventId) {
                    setRtoMessage(data ?? null)
                }
            };

            rtoSse.addEventListener('progress', handleProgressEvent)

            rtoSse.onerror = e => {
                console.log(e)
            }
    
            // Cleanup function to remove the event listener when the component unmounts
            return () => {
                if (rtoSse) {
                    rtoSse.removeEventListener('progress', handleProgressEvent);
                    rtoSse.close()
                }        
            }
        }
    }, [rtoSse])

    useEffect(() => {
        if (horseSse) {
            // Define the event handler function
            const handleProgressEvent = (e) => {
                let data = JSON.parse(e.data)
                if (data?.event_id == horseEventId) {
                    setHorseMessage(data ?? "")
                }
            };

            horseSse.addEventListener('progress', handleProgressEvent)

            horseSse.onerror = e => {
                console.log(e)
            }
    
            // Cleanup function to remove the event listener when the component unmounts
            return () => {
                if (horseSse) {
                    horseSse.removeEventListener('progress', handleProgressEvent);
                    horseSse.close()
                }        
            }
        }
    }, [horseSse])

    return <div className="col-lg-8 widget active-users card card-flush mb-0">
            <div className="card-header pt-5 ps-7 pe-0">
                <div className="card-title d-flex flex-column">    
                    <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">Membership Events</span>
                    <span className="text-gray-500 pt-1 fw-semibold fs-6">RTO {`${rtoMessage?.progress?.toFixed(1) ?? ''} - ${rtoMessage?.message ?? ''}`}</span>
                    <span className="text-gray-500 pt-1 fw-semibold fs-6">Horse  {`${horseMessage?.progress?.toFixed(1) ?? ''} - ${horseMessage?.message ?? ''}`}</span>
                </div>
            </div>
    </div>

}