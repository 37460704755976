import clsx from 'clsx'
import {FC, useState, useEffect } from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'
import { useSyncUpdateContext } from '../../../../../app/modules/output-listing/synchronizeUpdateContext' 
import { useAuth } from '../../../../../app/modules/auth'

const DefaultTitle: FC = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config, classes} = useLayout()
  const { currentUsers, pendingUpdates } = useSyncUpdateContext()
  const { pathname } = useLocation()
  const [isDetailPage, setIsDetailPage] = useState(false)

  useEffect(() => {
    setIsDetailPage(pathname.includes('/detail'))
  }, [pathname])

  let badge_count = 0;
  const user = useAuth()
  return (
    <div
      id='kt_page_title'
      data-kt-swapper='false'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
    >
      {/* begin::Title */}
      {pageTitle && (
        <div className="d-flex flex-column align-items-center text-center default-page-title">
          <h1 className={`text-dark fw-bolder my-1 fs-3 ${pageTitle === "RTOs" ? "no-transform" : ""}`}> {pageTitle} </h1>
          {pageDescription && config.pageTitle /*&& config.pageTitle.description*/ && (
            <>
              <p style={{marginBottom: "0"}}>{pageDescription}</p>
            </>
          )}
        </div>
      )}
      {/* end::Title */}
      <div className="d-flex justify-content-center align-items-center user-badges">
      {(currentUsers && user.currentUser) &&
        <>
          {(isDetailPage && pendingUpdates > 0) &&
            <div className="d-flex justify-content-center align-items-center mx-5">
              <i className="fa-regular fa-hourglass-half text-danger"></i>
              <div className='fw-bold mx-2 me-5 text-danger'>{ pendingUpdates }</div>
            </div>
          }

          {currentUsers.length > 1 && 
            <a 
              href="#" 
              data-toggle="tooltip" 
              data-theme="dark"
              data-placement="top"
              title='Users viewing this record'
              className = {"user-badge bg-warning"}
              style={{zIndex:`11`}}
            >
                {/* <span className='fa-solid fa-mask'></span> */}
              <span className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M12.8434797,16 L11.1565203,16 L10.9852159,16.6393167 C10.3352654,19.064965 7.84199997,20.5044524 5.41635172,19.8545019 C2.99070348,19.2045514 1.55121603,16.711286 2.20116652,14.2856378 L3.92086709,7.86762789 C4.57081758,5.44197964 7.06408298,4.00249219 9.48973122,4.65244268 C10.5421727,4.93444352 11.4089671,5.56345262 12,6.38338695 C12.5910329,5.56345262 13.4578273,4.93444352 14.5102688,4.65244268 C16.935917,4.00249219 19.4291824,5.44197964 20.0791329,7.86762789 L21.7988335,14.2856378 C22.448784,16.711286 21.0092965,19.2045514 18.5836483,19.8545019 C16.158,20.5044524 13.6647346,19.064965 13.0147841,16.6393167 L12.8434797,16 Z M17.4563502,18.1051865 C18.9630797,18.1051865 20.1845253,16.8377967 20.1845253,15.2743923 C20.1845253,13.7109878 18.9630797,12.4435981 17.4563502,12.4435981 C15.9496207,12.4435981 14.7281751,13.7109878 14.7281751,15.2743923 C14.7281751,16.8377967 15.9496207,18.1051865 17.4563502,18.1051865 Z M6.54364977,18.1051865 C8.05037928,18.1051865 9.27182488,16.8377967 9.27182488,15.2743923 C9.27182488,13.7109878 8.05037928,12.4435981 6.54364977,12.4435981 C5.03692026,12.4435981 3.81547465,13.7109878 3.81547465,15.2743923 C3.81547465,16.8377967 5.03692026,18.1051865 6.54364977,18.1051865 Z" fill="#ffffff"/>
                </g>
              </svg></span>
            </a>
          }
          {currentUsers.map((userDetail: any, index: number) => {
            badge_count++;
            if(userDetail.sgl_id != user.currentUser?.id){
              if(index < 5){
                return (
                  userDetail.profile_photo_thumbnail? 
                <a 
                  href="#" 
                  data-toggle="tooltip" 
                  data-theme="dark"
                  data-placement="top"
                  title={userDetail.first + ' ' + userDetail.last_name}
                  className = {"user-badge"+" badge-no-"+index} 
                  style={{zIndex: 10- index}}
                  key={index}>
                    <img 
                      src={userDetail.profile_photo_thumbnail}
                      alt='Image'/>
                </a>
                :
                <a 
                  href="#" 
                  data-toggle="tooltip" 
                  data-theme="dark"
                  data-placement="top"
                  title={userDetail.first + ' ' + userDetail.last_name}
                  className = {"user-badge"+" badge-no-"+index}
                  style={{zIndex: 10 - index}}
                  key={index}>
                    <span>
                      {userDetail.first[0]+ userDetail.last_name[0]}
                    </span>
                </a>
                )
              }
              {currentUsers.length > 5 && (
                <a 
                href="#" 
                data-toggle="tooltip" 
                data-theme="dark"
                data-placement="top"
                title={"More Users"}
                className = {"count-badge"+" badge-no-"+badge_count}
                key={badge_count}>
                  <span>
                    +{currentUsers.length - 5}
                  </span>
                </a>
              )}
            }})}
				</>
				} 
        </div>

      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <>
            {config.pageTitle.direction === 'row' && (
              <span className='h-20px border-gray-200 border-start mx-4'></span>
            )}
            <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
              {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  {!item.isSeparator ? (
                    <Link className='text-muted text-hover-primary' to={item.path}>
                      {item.title}
                    </Link>
                  ) : (
                    <span className='bullet bg-gray-200 w-5px h-2px'></span>
                  )}
                </li>
              ))}
              <li className='breadcrumb-item text-dark'>{pageTitle}</li>
            </ul>
          </>
        )}
    </div>
  )
}

export {DefaultTitle}
