import { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../../../_metronic/helpers";
import { reactSelectStyles } from "../../../../modules/sgl-utils/fieldControls";
import Select from "react-select";
import { AgGridReact } from "ag-grid-react";
import { defaultColDef, getNarrowHeaderHeight, getNarrowRowHeight } from "../../../../modules/sgl-utils/agGridHelpers";
import { useFormContext } from "react-hook-form";
import CheckboxHeader from "../../../../modules/components/CheckboxHeader";


const modalsRoot = document.getElementById('root-modals') || document.body
export const AddClassesModal = ({ show, handleClose, showOptions, listOfDivisions, selectedShow, selectedDivision, handleCDCSelectedClasses, setClassesRowData, rowData, setSelectedDivision, getClassRecords, setSelectedShow, getDivisionsForSelectedShow, setRowData, classesRowData, checkAll, setCheckAll  }) => {
    const intl = useIntl()
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const methods = useFormContext()

    const renderCheckBox = (params) => {
        const handleClick = (event) => {
            event.stopPropagation();
            // params.node.setSelected(params.data.selected?true:false);
          };
        return (
            <input
                type="checkbox"
                defaultChecked={params.data.selected?true:false}
                onChange={handleClick}
                onClick={(event) => event.stopPropagation()}
            />
        )
    }

    const columnDefs = [
        { 
            field: 'selected', 
            sortable: true, 
            cellStyle: { textAlign: 'center' }, 
            width: 75, 
            cellRenderer: renderCheckBox,
            headerComponentFramework: CheckboxHeader,
            headerComponentParams: {
                headerName: '',
                onChange: (checked) => { 
                    if (checked) {
                        handleAddAll()
                    } else {
                        handleUnselectAll()
                    }

                    setCheckAll(checked)
                },
                checked: checkAll
            },
        },
        { 
            field: 'name', 
            flex: 1, 
            sortable: true,
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.CLASSNAME' }) 
        },
        { 
            field: 'number',  
            width: 120,
            cellClass: 'text-center',
            sortable: true,
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.NUMBER' }), 
        },
        { 
            field: 'show_name',  
            sortable: true,
            flex: 1, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.SHOW' })
        },
        { 
            field: 'usef_section_code',
            width: 120,
            sortable: true,  
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.USEFSECTION' }), 
            cellClass: 'text-center' 
        },
    ]

    const handleUnselectAll = () => {
        // debugger
        // check if this row was classesRowData (old circuit division class from database)
        let deleteCDC = []
        if (methods.getValues('delete_circuit_division_classes')){
            deleteCDC = methods.getValues('delete_circuit_division_classes')
        }
        
        const tempRowData = []
        let prevClassesRowData = structuredClone(classesRowData)
        for (const row of rowData) { // Loop through all rows and mark them unselected

            // Find the row in Selected Classes Grid & remove from grid if found.
            const found = prevClassesRowData?.find(item => (item.class_id === row.class_id));
            
            if(found){
                deleteCDC.push (row)
                methods.setValue('delete_circuit_division_classes', deleteCDC, {shouldDirty: true})
            }

            // remove this from classes row data
            prevClassesRowData = prevClassesRowData.filter(item => item.class_id !== row.class_id);
           
            tempRowData.push({...row, selected: false })

            methods.setValue('circuit_division.status', 'Computation required', { shouldDirty: true })
        }

        methods.setValue('circuit_division_classes', prevClassesRowData)
        setClassesRowData(prevClassesRowData);
        setRowData(tempRowData)
    }


    const handleRowClicked = useCallback((params) => {
        handleCDCSelectedClasses(params.data, false)
        methods.setValue('circuit_division.status', 'Computation required', { shouldDirty: true })
    })
    
    const handleAddAll = () => {
        let updatedRowData = []
        for (let row of rowData){
            if(!row.selected){
                methods.setValue('circuit_division.status', 'Computation required', { shouldDirty: true })
                let updated_row = handleCDCSelectedClasses (row, true)
                updatedRowData.push(updated_row)
            }
        }

        const updatedClassesRowData = [...classesRowData];
        for(let row of updatedRowData){
            updatedClassesRowData.unshift(row);
        }
        setClassesRowData(updatedClassesRowData);
        methods.setValue('circuit_division_classes',updatedClassesRowData)

        if(updatedRowData.length > 0){
            let updatedArray = rowData.map(row => {
                let updated_row = updatedRowData.find(local => local.class_id === row.class_id);
                if (updated_row) {
                    return { ...row, selected: updated_row.selected?true:false };
                }
                return row;
            });
            setRowData(updatedArray);
        }
    }

    const onClose = () => {
        setCheckAll(false)
        setSelectedShow(0)
        setRowData([])
        setSelectedDivision(0)
        handleClose()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            onHide={onClose}
            backdrop='static'
            onKeyDown={(event) => {
                if(event.key === 'Escape'){
                    onClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">Add Classes</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='row mb-2'>
                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-75px' htmlFor='SHOW'>{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.LABEL.SHOW' })}</label>
                    <div className='col-lg-6'>
                        <Select 
                            styles={reactSelectStyles}
                            options={showOptions}
                            value={showOptions.find(s => s.value === selectedShow) ?? ''}
                            onChange={(e) => {
                                setSelectedShow(e.value)
                                getDivisionsForSelectedShow(e.value)
                            }}
                        />
                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-75px' htmlFor='DIVISION'>{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.LABEL.DIVISION' })}</label>
                    <div className='col-lg-6'>
                        <Select 
                            styles={reactSelectStyles}
                            options={listOfDivisions}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            value={listOfDivisions.find(d => d.value === selectedDivision) ?? ''}
                            onChange={(e) => {
                                setSelectedDivision(e.value)
                                getClassRecords(e.value)
                            }}
                            placeholder='Select Division...'
                        />
                    </div>
                </div>
                <div className='row'>
                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-75px' htmlFor='SEARCH'>{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.LABEL.SEARCH' })}</label>
                    <div className='col-lg-6'>
                        <input
                            {
                                ...methods.register('searchTerm')
                            }
                            id='SEARCH'
                            // onBlur={(e) => {
                            //     methods.setValue('searchTerm', e.target.value, {shouldDirty: true})
                            //     getClassRecords()
                            //     e.preventDefault()
                            // }}
                            type='text'
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.LABEL.SEARCH' })}
                        />
                    </div>
                </div>
                <div className='row'>
                    <label className='col col-form-label fs-5 py-1 text-end'>Total: {rowData?.length ?? 0}</label>
                </div>
                <div className='row'>
                    <div style={containerStyle}>
                        <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                            <AgGridReact
                                columnDefs={columnDefs} 
                                defaultColDef={{
                                    ...defaultColDef, 
                                    resizable: true, 
                                    headerClass: "ag-center-aligned-header", 
                                    wrapHeaderText: true,
                                    autoHeaderHeight: true,
                                }}
                                onRowClicked={handleRowClicked}
                                selection={'multiple'}
                                rowData={rowData} 
                                rowStyle={{ fontSize: '14px' }}
                                headerHeight={getNarrowHeaderHeight}
                                rowHeight={getNarrowRowHeight}
                            />
                        </div>
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-3 px-0'>
                    <div className='buttons-right-align'>
                        <button type="button" className='btn btn-sm btn-secondary fw-bold text-uppercase' onClick={onClose}>
                            {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CLOSE" })}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}