import  { useEffect, useState } from "react";
import {useFormContext} from 'react-hook-form'
import "../Detail/tab.css"
import Preview from "./preview";
import Uploader from "./uploader";
import { useAlert } from "../../../modules/sgl-utils/DialogsProvider";
import { getExtensionOnly, getTimeStampOnly } from "./ImageUploadFunctions";
import { useIntl } from "react-intl";

const max_images_allowed = 9 ;

function ImageUpload(){
    const intl = useIntl()
    const alertDialog = useAlert()
    const methods = useFormContext() 
    const [imagesList, setImagesList] = useState([]) 
    const [deletedImages, setDeletedImages] = useState([])
    const [updateState, setUpdateState] = useState(false)
    let images = methods.watch('images')

    // Add image in the imagesList
    const updateImagesList = (imageToBeAdded) => 
    {
        if (imagesList.length < max_images_allowed){
          // update images list 
            const oldImagesList = imagesList;
            oldImagesList[oldImagesList.length] = imageToBeAdded;
            setImagesList([...oldImagesList])
            setUpdateState(!updateState)
            methods.setValue('files',  oldImagesList , {shouldDirty:true})
        } else {
            alertDialog({message: intl.formatMessage({id: 'FORM.INPUT.MASTERFEES.TAB.PRODUCTINFO.MESSAGE.LIMITEXCEED'}), title: 'warning'})
        }
    };

    // delete image from imagesList
    const deleteImage = (id, sgl_id) => {
      // updated image list
      if (imagesList.length > 0) {
        const filterImages = imagesList.filter((image) => image.id !== id);
        setImagesList(filterImages) // delete in list
      }
      // If image present in db is removed update deleted image list
      if(sgl_id > 0){
        const oldDeletedImages = deletedImages;
        oldDeletedImages[oldDeletedImages.length] = sgl_id;
        setDeletedImages([...oldDeletedImages])
      }
      setUpdateState(!updateState)
    };

    useEffect(()=>{
      const oldImagesList = [];
      // when page first renders, update ImagesList and ImagesList2 from the backend images
      // check if there is any image in backend
      if (methods.getValues('images') && methods.getValues('images').length > 0){
        // now get images data from the backend images
        methods.getValues('images').map(async (image)=>{
          // we are allowed to have 9 images only so we will check display order of image first
          if (oldImagesList?.length <= max_images_allowed){ 
            var sglId = 0;
            if(image.sgl_id!==0 && image.sgl_id !==null && image.sgl_id > 0){
              sglId = image.sgl_id;
            }
            const newImage = {
              file:  image.url,
              size: image.size,
              title: image.title,
              name: image.title+"."+getExtensionOnly(image.url),
              timeStamp:  getTimeStampOnly(image.filename),
              modification_time: image.modification_time,
              modification_date: image.modification_date,
              sgl_id: sglId,
              selected:false,
              display_order: image.display_order,
              related_id: image.related_id,
              related_table: image.related_table,
              type: image.type, 
              filename: image.filename,
              filepath: image.filepath,
              on_cdn: image.on_cdn
            }; 
            // if image preview URLs already have images 
            // (from backend or front end by drag drop, no more image from backend should be set in image preview URLs)
            if(oldImagesList?.length < 9  && oldImagesList?.length >= 0 )
            {
              oldImagesList[oldImagesList.length] = newImage;
            } 
          }
          setImagesList([...oldImagesList])
          setUpdateState(!updateState)
        }) 
      }else{
          setImagesList([])
          setUpdateState(!updateState)
      }

    }, [images])

    useEffect(() => {
      if(deletedImages?.length > 0){
        methods.setValue('deletedImage',  deletedImages , {shouldDirty:true})
      }
    }, [deletedImages])

    return (
      <div>
        <Uploader updateImagesList={updateImagesList} imagesList={imagesList} />
        {(imagesList.length > 0 )? (<Preview  imagesList={imagesList} setImagesList={setImagesList} deletedImages={deletedImages} setDeletedImages={setDeletedImages} deleteImage={deleteImage} />
          ) : null} 
      </div>
    );
} 
export default ImageUpload;