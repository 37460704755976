import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import Select from 'react-select';

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Assets
import "flatpickr/dist/themes/material_blue.css";

// Constants
import { date_options, reactSelectStyles, setDate, maskDateInput } from '../../../../modules/sgl-utils/fieldControls';
import { useAppSelector } from '../../../../redux/hooks';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { findReplaceOrAdd, getDefaultOverrideDate, preventNonNumericKeys, removeElementFromArray } from '../../../../modules/sgl-utils/SglFunctions';
import axios from 'axios';
import moment from 'moment';
import ReactSelectComponent from '../../../../modules/sgl-utils/ReactSelectComponent';
import { useAuth } from '../../../../modules/auth';
import { useAlert, useLoadingOverlay } from '../../../../modules/sgl-utils/DialogsProvider';
import { deleteMembershipOverride } from './MembershipFunctions';
import { NumericFormat } from 'react-number-format';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const AdditionalManualOverrideForm = ({ show, handleClose, rowData, personId, calledFrom, personType }) => {
    const intl = useIntl()
    const { currentUser } = useAuth()
    const customerID = useAppSelector(state => state.showCompany.company_id)
    const currentShowID =  useAppSelector(state => state.currentShow.show_id)
    const currentShowEndDate =  useAppSelector(state => state.currentShow.end_date)
    const [riderStatus, setRiderStatus] = useState([])
    const [reasons, setReasons] = useState([])
    const [membershipLevels, setMembershipLevels] = useState([])
    const [clearMemberbershipOverride, setClearMembershipOverride] = useState(false)
    const alertDialog = useAlert()
    const detailFormMethods = useFormContext()
    const loadingOverlay = useLoadingOverlay()
    const membershipLevelAllowedOrg = ['EC'] //enable membership level drop down 
    const allowNameMismatchOrg = ['FEI', 'EC'] //enable allow name mismatch checkbox
    const hideFeeApplied = ['EC'] //hide fee applied section 
    const membershipOverrideDefault = {
        customer_id: customerID ,
        ec_override_reason: '',
        inactive_override_date: getDefaultOverrideDate(currentShowEndDate),
        inactive_override_reason: '',
        memberships_override_id: 0,
        memberships_id: 0,
        name_mismatch_accepted: 0,
        organization_code: rowData.organization_code,
        override_membershiplevel: '',   
        override_by_user: currentUser.id,
        owner_nonmemberfeepaid: 0,
        owner_upgradefeepaid: 0,  
        people_id: personId,
        rider_nonmemberfeepaid: 0,
        rider_upgradefeepaid: 0,
        rider_status: '',
        rider_age: 0,
        trainer_nonmemberfeepaid: 0,
        trainer_upgradefeepaid: 0,
        this_show_only: 0,
        membership_number: ''
    }
    //Set default values
    let defaultValues
    if(rowData?.membership_override){
        defaultValues = rowData?.membership_override
        defaultValues.this_show_only = (defaultValues?.show_id && defaultValues?.show_id > 0) ? 1 : 0
    }else{
        defaultValues = membershipOverrideDefault
    }

    const methods = useForm({
        defaultValues
    });
    const show_id = methods.watch('show_id')
    const overRideReasonMemberships = ['USEF', 'USHJA', 'EC']

    const getMetaData = async () =>{//Set data in drop down on page load
        loadingOverlay({ show: true })
        await axios.get(`${NEST_API_URL}/people/getManualOverrideMetaData?customer_id=${customerID}&show_id=${currentShowID}&organization=${rowData.organization_code}`)
        .then(response => {
            setReasons(response?.data?.ec_rider_override_reasons ?? []);
            setRiderStatus(response?.data?.rider_status ?? []);
            setMembershipLevels(response?.data?.ec_membership_levels ?? [])
        }).catch((error) => {
            if (error.response) {
            alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            loadingOverlay({ show: false })
        })
    }

    const getRiderStatusOptions = () => {// fill option for rider options
        const horseTypeOptions = riderStatus?.map((horseType) => ({value: horseType.value, label: horseType.label}));
        horseTypeOptions?.unshift({label: 'Select', value: ''})
        return horseTypeOptions;
    }

    const getReasonOptions = () => {// fill option for ec reason drop down
        let reasonOptions = []
        if(rowData.organization_code == 'EC'){
            reasonOptions = reasons?.map((reason) => ({value: reason.value, label: `${reason.label} [${reason.value}]`}));
        }
        else{
            reasonOptions = reasons?.map((reason) => ({value: reason.value, label: `${reason.label}`}));
        }
        reasonOptions?.unshift({label: 'Select', value: ''})
        return reasonOptions;
    }

    const getMembershipLevel = () => {// fill option for RTO EC membership levels
        const membershipLevelOptions = membershipLevels?.map((membershipLevel) => ({value: membershipLevel.value, label: membershipLevel.label}));
        membershipLevelOptions?.unshift({label: 'None', value: ''})
        return membershipLevelOptions;
    }

    const handleClear = () => {// delete override data
        setClearMembershipOverride(true)
        methods.reset(membershipOverrideDefault)
        methods.setValue('inactive_override_date','0000-00-00')
    }

    const validateOverrideMembership = () => {
        let data = methods.getValues()
        let overrideReason = overRideReasonMemberships.includes(rowData.organization_code) ? data.ec_override_reason : data.inactive_override_reason

        if((data.inactive_override_date == '0000-00-00' && overrideReason == '' && clearMemberbershipOverride)){//delete the record
            return {isValid: true, message: 'delete'}
        }else if(data.inactive_override_date == '0000-00-00' && overrideReason == ''){
            return {isValid: false, message: 'You must provide inactive override date and reason to change membership status.'}
        }else if(data.inactive_override_date == '0000-00-00' && overrideReason !== ''){
            return {isValid: false, message: 'You must provide inactive override date to change membership status.'}
        }else if(data.inactive_override_date !== '0000-00-00' && overrideReason == ''){
            return {isValid: false, message: 'You must provide a reason to change membership status.'}
        }
        return {isValid: true, message: ''}
    }

    const overrideMembership = () => {// Create/Update/Delete the override
        //Check if data entered is valid or not
        let checkIsValid = validateOverrideMembership()
        if(!checkIsValid.isValid){//error found
            alertDialog({message: checkIsValid.message})
            return
        }

        if(methods.getValues().this_show_only){
            methods.setValue('show_id', currentShowID)
        }else{
            methods.setValue('show_id', 0)
        }
        let organizationMembers =  []
        let membershipOverrideRemoved = []
        let membershipOverrideAdded = []
        let membershipIndex = -1

        //Get existing overrides information
        if(calledFrom == 'people'){
            organizationMembers = detailFormMethods.getValues().organizationMembers
            membershipIndex = organizationMembers.findIndex((obj => obj.organization_code == rowData.organization_code))
            membershipOverrideRemoved = detailFormMethods.getValues().membership_override_removed ?? []
            membershipOverrideAdded = detailFormMethods.getValues().membership_override_added ?? []
        }else{
            organizationMembers = detailFormMethods.getValues("linked_people")?.[personId]?.membership_data
            membershipIndex = organizationMembers.findIndex((obj => obj.organization_code == rowData.organization_code))
            membershipOverrideRemoved = detailFormMethods.getValues("linked_people")?.[personId]?.membership_override_data?.membership_override_removed ?? []
            membershipOverrideAdded = detailFormMethods.getValues("linked_people")?.[personId]?.membership_override_data?.membership_override_added ?? []
        }

        if(membershipIndex == -1){//Should never happen
            alertDialog({message: 'Organization not found.'})
        }
        
        if(checkIsValid.message == 'delete'){//delete the membership record
            let updatedData = deleteMembershipOverride(organizationMembers[membershipIndex], membershipOverrideAdded, membershipOverrideRemoved)
            membershipOverrideAdded = updatedData.membership_override_added
            membershipOverrideRemoved = updatedData.membership_override_removed
            
        }else{//add or update the membership override
            let isOverrideValid = moment(methods.getValues().inactive_override_date).isSameOrAfter(currentShowEndDate)
            if(isOverrideValid){
                organizationMembers[membershipIndex].membership_override = methods.getValues()
                if(methods.getValues().this_show_only){
                    organizationMembers[membershipIndex].membership_override.show_id = currentShowID
                }else{
                    organizationMembers[membershipIndex].membership_override.show_id = 0
                }
                // organizationMembers[membershipIndex].membership_override.membership_number = rowData.membership_number ? rowData.membership_number : 'temp'
            }else{
                organizationMembers[membershipIndex].membership_override = null
            }
            organizationMembers[membershipIndex].override = true

            if(rowData.membership_number){
                methods.setValue('membership_number', rowData.membership_number)
            }
            else if(rowData.organization_code == 'EC'){
                methods.setValue('membership_number', 'temp')
            }
            else if(rowData.organization_code == 'USEF' || rowData.organization_code == 'USHJA'){
                let overrideReason = methods.getValues('ec_override_reason') == '6'? 'NM': methods.getValues('ec_override_reason') == '3'? `JAS ${moment(methods.getValues('inactive_override_date')).format('MM/DD/YY')}`: reasons.find(r => r.value == methods.getValues('ec_override_reason')).label
                methods.setValue('membership_number', overrideReason)
            }

            // organizationMembers[membershipIndex].membership_number = rowData.membership_number ? rowData.membership_number : 'temp'
            if(rowData.membership_number){
                organizationMembers[membershipIndex].membership_number = rowData.membership_number
            }
            else if(rowData.organization_code == 'EC'){
                organizationMembers[membershipIndex].membership_number = 'temp'
            }
            else if(rowData.organization_code == 'USEF' || rowData.organization_code == 'USHJA'){
                let overrideReason = methods.getValues('ec_override_reason') == '6'? 'NM': methods.getValues('ec_override_reason') == '3'? `JAS ${moment(methods.getValues('inactive_override_date')).format('MM/DD/YY')}`: reasons.find(r => r.value == methods.getValues('ec_override_reason')).label
                organizationMembers[membershipIndex].membership_number = overrideReason
            }

            organizationMembers[membershipIndex].override_by_user = currentUser.id
            organizationMembers[membershipIndex].isUpdated = true
            membershipOverrideAdded = findReplaceOrAdd(membershipOverrideAdded, methods.getValues(), 'organization_code')
            membershipOverrideRemoved = removeElementFromArray(membershipOverrideRemoved, organizationMembers[membershipIndex], 'organization_code')
        }

        //Update the overrides in detail page form
        if(calledFrom == 'people'){
            detailFormMethods.setValue('organizationMembers', organizationMembers, { shouldDirty: true })
            detailFormMethods.setValue(`membership_override_removed`, membershipOverrideRemoved, { shouldDirty: true })
            detailFormMethods.setValue(`membership_override_added`, membershipOverrideAdded, { shouldDirty: true })
        }else{
            let membership_data = detailFormMethods.getValues('linked_people')
            membership_data[personId].membership_data = organizationMembers
            membership_data[personId].membership_override_data = {
                entity_type: personType,
                membership_override_removed: membershipOverrideRemoved,
                membership_override_added: membershipOverrideAdded
            }
            detailFormMethods.setValue('linked_people', membership_data, {shouldDirty: true})
        }
        handleClose()//close the dialog
    }

    useEffect(() => {
        getMetaData()
    },[])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => { //replace onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (!event.shiftKey && event.key === "Enter" && !isButtonFocused) {
                    overrideMembership()
                }
                if (event.key === 'Escape') {
                    handleClose()
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .manual-override-form .w-lg-140px { width: 140px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.TITLE.MANUALOVERRIDE' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <FormProvider {...methods}>
                    <form noValidate className='form manual-override-form' onSubmit={e => e.preventDefault()}>
                        <div className='card-body p-0'>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='ORGANIZATION' data-tooltip-id="PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.LABEL.ORGANIZATION"> {intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.ORGANIZATION' })}</label>
                                <label className='col col-form-label fs-5 py-1'>{rowData.organization_code}</label>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='MEMBERSHIPID' data-tooltip-id="PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.LABEL.MEMBERSHIPID"> {intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.MEMBERSHIPID' })}</label>
                                <label className='col col-form-label fs-5 py-1'>{rowData.membership_number}</label>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='NAMEONEC' data-tooltip-id="PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.LABEL.NAMEONEC"> {intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.NAMEONEC' })}</label>
                                <label className='col col-form-label fs-5 py-1'>{rowData.membership_name}</label>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='OVERRIDEDATE' data-tooltip-id="PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.LABEL.OVERRIDEDATE">{intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.OVERRIDEDATE' })}</label>
                                <div className='col-lg-5'>
                                    <div className="input-group date">
                                        <Controller
                                            {...methods.register('inactive_override_date', { required: true })}
                                            name='inactive_override_date'
                                            render={({ field: { onChange, value, name } }) => {
                                                // Format value for display
                                                const formattedValue = value ? moment(value).format('MM/DD/YYYY') : '';

                                                return (
                                                    <Flatpickr
                                                        allowInput={true}
                                                        autoComplete="off"
                                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                                        id='inactive_override_date'
                                                        placeholder='MM/DD/YYYY'
                                                        name={name}
                                                        value={formattedValue}
                                                        options={date_options}
                                                        onClose={(value, dateStr, instance) => {
                                                            // Handle date formatting and masking
                                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();
                                                            
                                                            if (!isValidDate) {
                                                                instance.input.value = ''; // Clear input if date is invalid
                                                            }

                                                            setDate(dateStr, onChange); // Set the typed/selected date
                                                        }}
                                                        onChange={(value, dateStr, instance) => {
                                                            // Handle date formatting and masking
                                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();
                                                            
                                                            if (!isValidDate) {
                                                                instance.input.value = ''; // Clear input if date is invalid
                                                            }

                                                            setDate(dateStr, onChange); // Set the typed/selected date
                                                        }}
                                                        onInput={(e) => maskDateInput(e)} // Apply input masking
                                                        tabIndex={1}
                                                    />
                                                );
                                            }}
                                        />
                                        <div className="input-group-append">
                                            <label htmlFor='inactive_override_date' className='date-label'
                                                onClick={(e) => {
                                                    e.stopPropagation(); 
                                                    document.getElementById('inactive_override_date')._flatpickr.open(); 
                                                }}
                                            >
                                                <span className="input-group-text">
                                                    <i className="la la-calendar"></i>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px w-lg-140px' htmlFor='MEMBERSHIPLEVEL' data-tooltip-id="PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.LABEL.MEMBERSHIPLEVEL">{intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.MEMBERSHIPLEVEL' })}</label>
                                <div className='col-lg-5'>
                                    <Controller
                                        name = 'override_membershiplevel'
                                        render={({field: {onChange, value, label}}) => (
                                        <Select
                                            value={value !== undefined ? getMembershipLevel().filter((ss) => ss.value === value):""}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}
                                            isDisabled={!membershipLevelAllowedOrg.includes(rowData.organization_code)}         
                                            options={getMembershipLevel()}
                                            onChange={(e) => {
                                                methods.setValue('override_membershiplevel', e.value, {shouldDirty: true})
                                                if(methods.getValues('inactive_override_reason') == ''){
                                                    methods.setValue('inactive_override_reason', 'Membership upgraded at show', {shouldDirty: true})                     
                                                }
                                            }}
                                            placeholder='Select'
                                            styles={reactSelectStyles}
                                            tabIndex={2}
                                        />
                                        )}
                                    /> 
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='THISSHOWONLY'></label>
                                <div className='col-lg-7 form-check-sm form-check-custom ps-lg-3'>
                                    <input
                                        {...methods.register("this_show_only", {required: false})}
                                        className='form-check-input'
                                        type='checkbox'
                                        id='THISSHOWONLY'
                                        tabIndex={3}
                                    />
                                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='THISSHOWONLY' data-tooltip-id="PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.LABEL.THISSHOWONLY"> {intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.THISSHOWONLY' })}</label>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='ACCEPTNAMEMISMATCH'></label>
                                <div className='col-lg-7 form-check-sm form-check-custom ps-lg-3'>
                                    <input
                                        {...methods.register("name_mismatch_accepted", {required: false})}
                                        className='form-check-input'
                                        type='checkbox'
                                        id='ACCEPTNAMEMISMATCH'
                                        disabled={!allowNameMismatchOrg.includes(rowData.organization_code)}
                                        tabIndex={4}
                                    />
                                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='ACCEPTNAMEMISMATCH' data-tooltip-id="PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.LABEL.ACCEPTNAMEMISMATCH"> {intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.ACCEPTNAMEMISMATCH' })}</label>
                                </div>
                            </div>
                            <div className={'row mb-2' + (rowData && (!overRideReasonMemberships.includes(rowData.organization_code)) ? ' d-none' : "")}>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='ec_reason' data-tooltip-id="PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.LABEL.ADDREASON">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.MODAL.LABEL.ADD.REASON' })}</label>
                                <div className='col'>
                                    <ReactSelectComponent
                                        name='ec_override_reason'
                                        id='ec_override_reason'
                                        options={getReasonOptions()} 
                                        methods={methods}
                                        tabIndex={5}
                                    /> 
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='REASON' data-tooltip-id={rowData && overRideReasonMemberships.includes(rowData.organization_code) ? "PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.LABEL.NOTES" : "PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.LABEL.REASON"}> {rowData && overRideReasonMemberships.includes(rowData.organization_code) ? intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.NOTES' }): intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.REASON' })}</label>
                                <div className='col'>
                                    <textarea
                                        {...methods.register("inactive_override_reason", {required: true})}
                                        rows={3}
                                        className='form-control form-control-sm mb-2 mb-lg-0 fs-6 py-1'
                                        tabIndex={6}
                                    ></textarea>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='rider_status' data-tooltip-id="PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.LABEL.RIDERSTATUS">{intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.RIDERSTATUS' })}</label>
                                <div className='col'>
                                    <ReactSelectComponent 
                                        name='rider_status'
                                        id='rider_status'
                                        options={getRiderStatusOptions()} 
                                        methods={methods}
                                        tabIndex={7}
                                    />
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-140px' htmlFor='AGE' data-tooltip-id="PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.LABEL.AGE">{intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.AGE' })}</label>
                                <div className='col-lg-2'>
                                    <Controller
                                        control={methods.control}
                                        name="rider_age"
                                        render={({ field: { onChange, name, value } }) => (
                                            <NumericFormat
                                                id='rider_age'
                                                name={name}
                                                value={Number(value)}
                                                decimalScale={0}
                                                allowNegative={false}
                                                onValueChange={(e) => {
                                                onChange(e.value)
                                                }}
                                                className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                tabIndex={8}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <fieldset className={'mb-2' + (rowData && hideFeeApplied.includes(rowData.organization_code) ? ' d-none' : "")}>
                                <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.GROUPLABEL.FEEAPPLIED' })} <b></b></legend>
                                <div className='row mb-2'>
                                    <div className='col-lg-12 form-check-sm form-check-custom ps-lg-3'>
                                        <input
                                            {...methods.register("owner_nonmemberfeepaid", {required: false})}
                                            className='form-check-input'
                                            type='checkbox'
                                            id='NONMEMBERSHIPFEEPAID'
                                            tabIndex={9}
                                        />
                                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor='NONMEMBERSHIPFEEPAID' data-tooltip-id="PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.SECTION.FEEAPPLIED.LABEL.NONMEMBERSHIPFEEPAID"> {intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.NONMEMBERSHIPFEEPAID' })}</label>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-lg-12 form-check-sm form-check-custom ps-lg-3'>
                                        <input
                                            {...methods.register("owner_upgradefeepaid", {required: false})}
                                            className='form-check-input'
                                            type='checkbox'
                                            id='UPGRADEFEEPAID'
                                            tabIndex={10}
                                        />
                                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor='NONMEMBERSHIPFEEPAID' data-tooltip-id="PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.SECTION.FEEAPPLIED.LABEL.UPGRADEFEEPAID"> {intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.LABEL.UPGRADEFEEPLAN' })}</label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        <div className='card-footer d-flex justify-content-end py-3 px-0'>
                            <button type='button' className='btn btn-sm btn-secondary fw-bold' onClick={handleClear} tabIndex={11} data-tooltip-id="PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.BUTTON.CLEAROVERRIDE">
                                {intl.formatMessage({ id: 'FORM.MEMBERSHIP.OVERRIDE.FORM.BUTTON.CLEAROVERRIDE' })}
                            </button>

                            <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={12}>
                                {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                            </button>

                            <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={overrideMembership} tabIndex={13} data-tooltip-id="PEOPLE.DETAIL.MODAL.MANUALOVERRIDE.BUTTON.SAVE">
                                {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.SAVE' })}
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { AdditionalManualOverrideForm }

