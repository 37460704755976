import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form';

const HunterIncrementTab = () => {
    const intl = useIntl();
    const [numberOfCols, setNumberOfCols] = useState(0)
    const [numberOfRows, setNumberOfRows] = useState(0)
    const [matrix, setMatrix] = useState([])
    const methods = useFormContext()

    useEffect(() => {
        setMatrix(prevMatrix => {
            const newMatrix = Array(numberOfRows + 1).fill(0).map(() => Array(numberOfCols).fill(0))
            // retain previous state data
            const prevRows = prevMatrix.length 
            const prevCols = prevMatrix.length > 0 ? prevMatrix[0].length : 0
            for (let i = 0; i < Math.min(prevRows, numberOfRows+1); i++) {
                for (let j = 0; j < Math.min(prevCols, numberOfCols); j++) {
                    newMatrix[i][j] = prevMatrix[i][j]
                }
            }

            // Set value & mark field dirty 
            methods.setValue('updatedHunterIncrement', { numberOfCols, numberOfRows, matrix: newMatrix }, { shouldDirty: true })

            const { success, error_message } = validateIncrementTable(newMatrix)
            methods.setValue('increment_table_error', success ? '' : error_message, { shouldDirty: false })
            return newMatrix
        })
    }, [numberOfRows, numberOfCols])

    useEffect(() => {
        const jsonHiTable = methods.getValues('circuit.hunter_increment_json')
        if (jsonHiTable) {
            try {
                const parsedHiTable = JSON.parse(jsonHiTable)
                if (
                    parsedHiTable.numberOfCols !== undefined &&
                    parsedHiTable.numberOfRows !== undefined &&
                    parsedHiTable
                ) {
                    setNumberOfCols(parsedHiTable.numberOfCols)
                    setNumberOfRows(parsedHiTable.numberOfRows)
                    setMatrix(parsedHiTable.matrix)
                }
            } catch (error) {
                console.log(`Error parsing Increment Table: ${error}`)
            }
        } else {
            setNumberOfCols(0)
            setNumberOfRows(0)
            setMatrix([])
        }
    }, [methods.watch('circuit.hunter_increment_json')])

    const validateIncrementTable = (newMatrix) => {
        if (
            numberOfRows !== undefined &&
            numberOfCols !== undefined &&
            newMatrix
        ) {
            let prevMaxRange = -1 
            for (let col = 0; col < numberOfCols; col++) {
                const [firstPart, secondPart] = String(newMatrix[0][col]).split('-')
                let minRange = 0

                const plusPos = firstPart.indexOf('+') // for last range cases e.g., 30+  
                if (plusPos !== -1) { 
                    minRange = parseInt(firstPart.substring(0, plusPos))
                } else {
                    minRange = parseInt(firstPart)
                }

                let maxRange = 0
                if (secondPart !== undefined) { // Check if input had range or not
                    maxRange = parseInt(secondPart)
                }

                // Safety check to handle invalid values
                if (isNaN(minRange)) {
                    minRange = 0
                }
                if (isNaN(maxRange)) {
                    maxRange = 0
                }

                // RULE 1: First Range should always start with 1
                if (col === 0 && minRange !== 1) {
                    return { success: false, error_message: 'First range should always start with 1.' }
                }

                // RULE 3: Ranges should be in sequence & increasing order
                // RULE 3a: First Range Should Always be less than Second Range
                if (secondPart) { // There is a second part (max-range)
                    if (minRange >= maxRange) {
                        return { success: false, error_message: `Column #${col+1} has invalid range. Min range value cannot be greater than Max range value.` }
                    }
                }

                if (prevMaxRange !== - 1) { //  If there is a previous range..
                    // Rule 3b. Then min range of current range should be greater than max range of previous range
                     // Suppress error if minRange is 0 (Assume user has to provide input)
                    if (minRange !== 0 && prevMaxRange >= minRange) {
                        return { success: false, error_message: `Ranges should not overlap. Min range in Column #${col+1} should be greater than max range value of previous column.` }
                    }

                    // Rule 3c: Then min range of current range should be equal to max range of previous range + 1
                    // Suppress error if minRange is 0 (Assume user has to provide input)
                    if (minRange !== 0 && prevMaxRange + 1 !== minRange) {
                        return { success: false, error_message: `Ranges should be in sequence. Min range in Column #${col+1} should be ${prevMaxRange + 1}.` }
                    }
                }

                prevMaxRange = maxRange
            }
        }
        return { success: true }
    }

    function checkNoFormat(value){
        const regex = /^[\d+-]*$/;

        if (regex.test(value)) {
            return value
        } else {
            return ''

        }
    }

    return (
        <>
            <div id="hunter-increment-tab" className='form-group'>
                <div className='row mb-2'>
                    <label 
                        className='col-lg-1 col-form-label fs-5 py-1 w-lg-175px' 
                        htmlFor='NUMOFCOLUMNS'
                        data-tooltip-id="CIRCUITS.DETAIL.TAB.HUNTERINCREMENT.LABEL.NUMBEROFCOLUMNS"
                    >
                        {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.HUNTERINCREMENT.LABEL.NUMOFCOLUMNS' })}
                    </label>
                    <div className='col-lg-1 w-lg-75px'>
                        <input
                            id='NUMOFCOLUMNS'
                            value={numberOfCols} 
                            onChange={e => isNaN(e.target.value)? setNumberOfCols(0):  setNumberOfCols(Number(e.target.value))} 
                            type='text'
                            className='form-control form-control-sm  fs-6 min-h-20px py-1 number-input'
                        />
                    </div>
                    <label className='col-lg-1 w-lg-50px'></label>
                    <label 
                        className='col-lg-1 col-form-label fs-5 py-1 w-lg-225px' 
                        htmlFor='ADDITIONALCHAMPIONPOINTS'
                        data-tooltip-id="CIRCUITS.DETAIL.TAB.HUNTERINCREMENT.LABEL.ADDITIONALCHAMPIONPOINTS"
                    >
                        {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.HUNTERINCREMENT.LABEL.ADDITIONALCHAMPIONPOINTS' })}
                    </label>
                    <div className='col-lg-1 w-lg-75px'>
                        <input
                            {...methods.register( 'circuit.h_i_champ_additional_points', 
                                {
                                required: false
                                })
                            }
                            id='ADDITIONALCHAMPIONPOINTS'
                            type='text'
                            className='form-control form-control-sm  fs-6 min-h-20px py-1 number-input'
                        />
                    </div>
                </div>
                <div className='row mb-2'>
                    <label 
                        className='col-lg-1 col-form-label fs-5 py-1 w-lg-175px' 
                        htmlFor='NUMOFPLACES'
                        data-tooltip-id="CIRCUITS.DETAIL.TAB.HUNTERINCREMENT.LABEL.NUMBEROFPLACES"
                    >
                        {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.HUNTERINCREMENT.LABEL.NUMOFPLACES' })}
                    </label>
                    <div className='col-lg-1 w-lg-75px'>
                        <input
                            id='NUMOFPLACES'
                            type='text'
                            value={numberOfRows} 
                            onChange={e => isNaN(e.target.value)? setNumberOfRows(0):  setNumberOfRows(Number(e.target.value))} 
                            className='form-control form-control-sm  fs-6 min-h-20px py-1 number-input'
                        />
                    </div>
                    <label className='col-lg-1 w-lg-50px'></label>
                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-225px' htmlFor='ADDITIONALCHAMPIONPOINTS' data-tooltip-id="CIRCUITS.DETAIL.TAB.HUNTERINCREMENT.LABEL.ADDITIONALRESERVEPOINTS">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.HUNTERINCREMENT.LABEL.ADDITIONALRESERVEPOINTS' })}</label>
                    <div className='col-lg-1 w-lg-75px'>
                        <input
                            {...methods.register( 'circuit.h_i_reserve_additional_points', 
                                {
                                required: false
                                })
                            }
                            id='ADDITIONALRESERVEPOINTS'
                            type='text'
                            className='form-control form-control-sm  fs-6 min-h-20px py-1 number-input'
                        />
                    </div>
                </div>
                { numberOfRows > 0 &&
                [
                <div className='row mb-2'>
                    <label className='col col-form-label fs-5 py-1 text-danger'>{ methods.watch('increment_table_error') }</label>
                </div>,
                <div className='placing-info-wrapper'>
                    <label className='col fs-6 py-0 mb-2 fst-italic'>{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.HUNTERINCREMENT.PLACINGINFO' })}</label>

                    { matrix.length > 1 && matrix.map((row, rowIndex) =>
                        <div className={'row' + (rowIndex==0?' placing-row':'') } >
                            <label className={'col-form-label py-0 fw-bold'+ (rowIndex==0?' fs-5':' fs-4')}>
                                { rowIndex === 0 ? 'Placing' : rowIndex }
                            </label>
                            <span className='col input-wrapper'>
                            {  
                                row.map((data, colIndex) => 
                                    <div>
                                        <input
                                            value={data}
                                            onChange={e => 
                                                setMatrix(prevMatrix => {
                                                    const newMatrix = []
                                                    for (const prevMatrixRow of prevMatrix) {
                                                        newMatrix.push([...prevMatrixRow])
                                                    }
                                                    // first row has text inputs as it will have range of data.
                                                    // newMatrix[rowIndex][colIndex] = rowIndex !== 0 ? (isNaN(e.target.value)? 0 : Number(e.target.value)) : checkNoFormat(e.target.value)
                                                    newMatrix[rowIndex][colIndex] = e.target.value

                                                    // Set value & mark field dirty 
                                                    methods.setValue('updatedHunterIncrement', { numberOfCols, numberOfRows, matrix: newMatrix }, { shouldDirty: true })

                                                    return newMatrix
                                                })
                                            }
                                            onBlur={e => 
                                                setMatrix(prevMatrix => {
                                                    const newMatrix = []
                                                    for (const prevMatrixRow of prevMatrix) {
                                                        newMatrix.push([...prevMatrixRow])
                                                    }
                                                    // first row has text inputs as it will have range of data.
                                                    newMatrix[rowIndex][colIndex] = rowIndex !== 0 ? (isNaN(e.target.value)? 0 : Number(e.target.value)) : checkNoFormat(e.target.value)

                                                    // Set value & mark field dirty 
                                                    methods.setValue('updatedHunterIncrement', { numberOfCols, numberOfRows, matrix: newMatrix }, { shouldDirty: true })

                                                    const { success, error_message } = validateIncrementTable(newMatrix)
                                                    methods.setValue('increment_table_error', success ? '' : error_message, { shouldDirty: false })
                                                    return newMatrix
                                                })
                                            }
                                            type='text'
                                            className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        />
                                    </div>
                                )
                            }
                            </span>
                        </div>
                    )}
                </div>]}
            </div>
        </>
    );
}

export { HunterIncrementTab }