import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { getExtraNarrowRowHeight } from '../../../modules/sgl-utils/agGridHelpers'
// Metronic Components
import { useMemo } from 'react'

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers'

// Assets
import "flatpickr/dist/themes/material_blue.css";

// Constants
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import HideJsonRenderer from '../../../modules/output-listing/renderers/HideJsonRenderer'

type Props = {
    show: boolean
    handleClose: () => void
    lookupResponse: []
    handleUpdateData: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const MembershipDetailForm = ({ show, handleClose, lookupResponse, handleUpdateData }: Props) => {
    const intl = useIntl()

    const columnDefs = [
        { field: 'key', flex: 1, lockVisible: false, lockPosition: 'left', cellStyle: {'border-right': '1px solid #eee'}
            , cellRenderer: 'agGroupCellRenderer',
            cellRendererSelector: function (params: {
                node: any, data: any 
            }) {
                if (params.data.value != null && Array.isArray(params.data.value) && params.data.value?.length > 0) {
                    params.node.setExpanded(true)
                }
            }
        },
        { field: 'value', flex:2, lockVisible: false, lockPosition: 'left', cellStyle: {textAlign: 'left'}, wrapText: true, autoHeight: true, cellRenderer: HideJsonRenderer }
    ]

    const rowData = lookupResponse


    const containerStyle = useMemo(() => ({ width: '100%', height: '400px' }), []);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event:any) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    event.preventDefault()
                    handleUpdateData();
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.GENERAL.MODAL.ECLOOKUPDETAIL.TITLE.ECSHOWINFO' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row align-items-center mb-2 membership-data-grid'>
                            <OutputListingGridStatic  area ={"Entries - Membership Detail"} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle} rowHeight={getExtraNarrowRowHeight} masterDetail={true} keepDetailRows={true} keepDetailRowsCount={2} detailRowAutoHeight={true}></OutputListingGridStatic>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button className='btn btn-sm btn-secondary me-4 fw-bold' type='button' onClick={() => handleUpdateData()} autoFocus>
                            {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.WAIVERS.BUTTON.UPDATE' })}
                        </button>
                        <button className='btn btn-sm btn-dark fw-bold text-uppercase' type='button' onClick={() => handleClose()}
                        >
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.MODAL.MEMBERSHIPDETAIL.BUTTON.DONE' })}
                        </button>
                        
                    </div>
                    
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { MembershipDetailForm }