import { LoadingComponent } from "../LoadingComponent"


export const SlowestJobsWidget = ({ isLoading, queuedJobsData }) => {

    return (
        <div className={`card widget no-height-limit`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>5 Most Slow</span>
                </h3>
            </div>
            { isLoading ? <LoadingComponent /> : 
            <div className='card-body py-3'>
                <div className='tab-content'>
                <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
                    <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                        <thead>
                        <tr className='border-0'>
                            <th className='p-0 min-w-150px'></th>
                            <th className='p-0 min-w-140px'></th>
                        </tr>
                        </thead>
                        <tbody>
                        { 
                            queuedJobsData.slowestQueuedJobs.map(queuedJob => {
                                return(<tr>
                                    <td>
                                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                            { queuedJob.job_name }
                                        </a>
                                        <span className='text-muted fw-semibold d-block'>Average Processing Time: { queuedJob.avg_processing_time } seconds</span>
                                    </td>
                                    <td className='text-end text-muted fw-semibold'>
                                        <div>{ queuedJob.job_count } Job(s)</div>
                                    </td>
                                </tr>)
                            }) 
                        }
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
            </div> }
        </div>
    )
}