import React, { useEffect, useState} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import axios from 'axios'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useFlashAlert } from '../../../../modules/sgl-utils/DialogsProvider'
import { useAppSelector } from '../../../../redux/hooks'
import { downloadTextFile, loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../../modules/sgl-utils/SglFunctions'
import { usePaymentBatchContext } from '../../PaymentBatchContext'
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters'

const modalsRoot = document.getElementById('root-modals') || document.body
 
const PaymentQBExport = ({show, handleClose, postedPayments, pendingPayments, getPaymentsSum, eventIDQBExportForm, setEventIDQBExportForm}) => {
    const intl = useIntl() 
    const {selectedShow, selectedBatch}= usePaymentBatchContext()
    const customerID = useAppSelector(state => state.showCompany.company_id);
    const [exportPosted, setExportPosted] = useState(false)
    const [exportNonPosted, setExportNonPosted] = useState(false)
    const [markAsPrinted, setMarkAsPrinted] = useState(true)
    const [description, setDescription] = useState('')
    let selectedPendingPayments = pendingPayments.filter((payment) => payment.include)
    const currencyFormatter = useCurrencyFormatter()
    const flashAlert = useFlashAlert()

    useEffect(() => {
        if(postedPayments?.length > 0){
            setExportPosted(true)
            setExportNonPosted(false)
        }else{
            setExportPosted(false)
            setExportNonPosted(true)
        }
    }, [])

    const exportToQB = async () => {
        if(eventIDQBExportForm){
            loadingSpinnerBtnWait(eventIDQBExportForm)
        }

        let paymentBatchItemIds = []
        if(exportPosted){
            paymentBatchItemIds = postedPayments.map((payment) => payment.payment_batchitems_id)
        }else if(exportNonPosted){
            paymentBatchItemIds = selectedPendingPayments.map((payment) => payment.payment_batchitems_id)
        }

        axios.post(process.env.REACT_APP_NEST_API_URL + `/payment-batches/exportPaymentBatchForQB`, {
            customer_id: customerID,
            show_id: selectedShow,
            payment_batch_id: selectedBatch,
            payment_batchItem_ids: paymentBatchItemIds,
            markAsPrinted: markAsPrinted ? 'Y' : 'N',
            description
        })
        .then(async response => {
            if(response.data.success){
                await downloadTextFile(response.data.file_info.text_data, response.data.file_info.doc_name);
                // Display Flash Message
                flashAlert({ type: 'success', message: intl.formatMessage({id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.EXPORT.COMPLETED'}) });
                handleClose()
            }
        })
        .catch((error) => {
            // Do Nothing
        })
        .finally(() => {
            if(eventIDQBExportForm){
                loadingSpinnerBtnRelease(eventIDQBExportForm)
            }
            setEventIDQBExportForm(null)
        })
    }

    return createPortal(
        <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-450px search-form'
        show={show}
        onHide={handleClose}
        onKeyPress={(event) => {
            if (event.key === "Enter") {
                exportToQB()
            }
        }}
    >
        <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
        <div className='modal-header py-0 px-4'>
            <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.BUTTON.EXPORT.TO.QB' })}</h2>
            <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
        </div>
    
        <div className='modal-body py-3 px-4'>
    
            {/* CheckBox: Mark As To Print */}
            <div className='row-mb-2 d-flex align-items-center'>
                <div className='form-check-sm form-check-custom me-2'>
                    <input
                        className='form-check-input'
                        checked={markAsPrinted}
                        type='checkbox'
                        id='MARKTOPRINT'
                        onChange={(e) => {
                            setMarkAsPrinted(e.target.checked)
                        }}
                    />
                </div>
                <label className='col-form-label fs-6 fw-bold py-1' htmlFor="MARKTOPRINT" data-tooltip-id="PAYMENTBATCHES.DETAIL.MODAL.EXPORTTOQB.LABEL.MARKASTOPRINTFORQB">
                    {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.EXPORT.TOQB.CHECKBOX' })}
                </label>
            </div>
    
            {/* Radio Button: Export Posted */}
            <div className='d-flex align-items-center'>
                <div className='form-check-sm form-check-custom me-2'>
                    <input
                        className='form-check-input'
                        checked={exportPosted}
                        type='radio'
                        id='EXPORTPOSTED'
                        onChange={(e) => {
                            setExportPosted(true)
                            setExportNonPosted(false)
                        }}
                        disabled={postedPayments?.length === 0}
                    />
                </div>
                <label className='col-form-label fs-6 fw-bold py-1' htmlFor="MARKTOPRINT" data-tooltip-id="PAYMENTBATCHES.DETAIL.MODAL.EXPORTTOQB.LABEL.EXPORTPOSTED">
                    {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.EXPORT.TOQB.RADIO.POSTED' }) + '(' + postedPayments?.length +'): ' + currencyFormatter(getPaymentsSum('posted'))}
                </label>
            </div>
    
            {/* Radio Button: Export Non Posted */}
            <div className='d-flex align-items-center'>
                <div className='form-check-sm form-check-custom me-2'>
                    <input
                        className='form-check-input'
                        checked={exportNonPosted}
                        type='radio'
                        id='EXPORTNONPOSTED'
                        onChange={(e) => {
                            setExportPosted(false)
                            setExportNonPosted(true)
                        }}
                        disabled={selectedPendingPayments?.length === 0}
                    />
                </div>
                <label className='col-form-label fs-6 fw-bold py-1' htmlFor="MARKTOPRINT" data-tooltip-id="PAYMENTBATCHES.DETAIL.MODAL.EXPORTTOQB.LABEL.EXPORTNONPOSTED">
                    {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.EXPORT.TOQB.RADIO.NONPOSTED' }) + '(' + selectedPendingPayments?.length +'): ' + currencyFormatter(getPaymentsSum('pending'))}
                </label>
            </div>
    
            {/* Description */}
            <div className='row mb-2 mt-3'>
                <label className='col-lg-1 col-form-label fw-bold fs-6 py-1 w-lg-100px pe-0' htmlFor='DESCRIPTION' data-tooltip-id="PAYMENTBATCHES.DETAIL.MODAL.EXPORTTOQB.LABEL.DESCRIPTION">
                    {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.EXPORT.TOQB.INPUT.DESCRIPTION' })}
                </label>
                <div className='col'>
                    <input
                        id='DESCRIPTION'
                        type='text'
                        className='form-control mb-2 mb-lg-0 fs-6 h-30px py-1'
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                    />
                </div>
            </div>
    
            <div className='card-footer d-flex justify-content-end py-3 px-0'>
    
                <div className='buttons-right-align'>
                    <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={handleClose}>
                        {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                    </button>
    
                    <button type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={(e) => exportToQB()} data-tooltip-id="PAYMENTBATCHES.DETAIL.MODAL.EXPORTTOQB.BUTTON.DONE">
                        <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                        {intl.formatMessage({ id: 'CLASSES.ADD.NEW.OFFICIALS.DIALOG.BUTTON.DONE' })}
                    </button>
                </div>
            </div>
    
        </div>
    </Modal>
    
      ,modalsRoot
    )
}

export default PaymentQBExport