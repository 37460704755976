import { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';

// Assets
import './noteTab.css'
import { NoteDialog } from './NoteDialog';
import { useFormContext } from 'react-hook-form';
import { useConfirm } from '../../sgl-utils/DialogsProvider';
import useAccessChecker from '../../hooks/use-access-checker';

const ListNote = (props) => {
    const confirmDialog = useConfirm()
    const { hasAreaWritePermission, hasAreaDeletePermission } = useAccessChecker()

    const [enableReadMore, setEnableReadMore] = useState(false)
    const [enableReadMoreProcessed, setEnableReadMoreProcessed] = useState(false)
    const [readMore, setReadMore] = useState(false)
    const [showDialog, setShowDialog] = useState(false)

    const intl = useIntl();
    const noteRef = useRef(null);
    const methods = useFormContext()

    const hasWriteAccess = hasAreaWritePermission('notes')
    const hasDeleteAccess = hasAreaDeletePermission('notes')
    
    useEffect(() => { // Show/Hide Read More link
        if (!enableReadMoreProcessed) {
            let interval = setInterval(() => {
                if (checkOverflow(noteRef.current)) { // Check if text is overflowing
                    setEnableReadMore(true)
                    clearInterval(interval)
                    setEnableReadMoreProcessed(true)
                }else if(noteRef?.current?.offsetHeight > 0 || noteRef?.current?.scrollHeight > 0){
                    clearInterval(interval)
                    setEnableReadMore(false)
                    setEnableReadMoreProcessed(true)
                }
            })
        }
    }, [enableReadMoreProcessed])

    useEffect(() => { // Process read more link
        setReadMore(false)
        setEnableReadMoreProcessed(false)
    }, [props?.data?.sgl_id, props?.data?.newNoteID])

    const editNote = async (note) => { //Edit note
        if (props.data?.user_id !== 26800 && hasWriteAccess) { //do not allow editing system-generated notes, or if user does not have permission to write
            let notes = methods.getValues('notes') //get notes array

            let selectedNote
            if (notes?.length > 0) { //if at least one note exists
                selectedNote = notes.find(noteData => noteData === props.data) //find the note matching the edited note in notes array         

                if (selectedNote) {
                    selectedNote.note = note //edit the note value
                    selectedNote.edited = true //set edited flag for note
                    methods.setValue('notes', notes, { shouldDirty: true }) //update the notes array            
                }
            }    
        }        
    }

    const deleteNote = async() => { //Delete note
        if (props.data?.user_id !== 26800 && hasDeleteAccess) { //do not allow deleting system-generated notes, or if user does not have permission to delete
            const confirm = await confirmDialog({ message: `Are you sure you want to delete the note?`})
            if (confirm) { //if user confirms deletion
                let notes = methods.getValues('notes') //get notes array
                let deletedNotes = methods.getValues('deleted_notes') ?? []

                let selectedNote
                if (notes?.length > 0) { //if at least one note exists
                    selectedNote = notes.find(noteData => noteData === props.data) //find the note matching the edited note in notes array         

                    if (selectedNote) {
                        // Find the index of the selectedNote
                        const index = notes.indexOf(selectedNote)

                        // Remove the note if it exists
                        if (index !== -1) {
                            notes.splice(index, 1)
                        }
                        methods.setValue('notes', notes, { shouldDirty: true }) //update the notes array
                        
                        if (!selectedNote?.newNoteID && !selectedNote?.added) { //ensure that note is already existing, and is not new
                            // Add note to deletedNotes array
                            deletedNotes.push(selectedNote)
                            methods.setValue('deleted_notes', deletedNotes, { shouldDirty: true }) //update the deleted notes array
                        }                        
                    }
                }  
            }              
        }
    }

    const renderEdit = () => {
        if (props.data?.user_id !== 26800 && hasWriteAccess) { // if note is system-generated or user does not have write access for notes, do not render edit icon
            return (
                <span className="cursor-pointer link-primary fw-bold fs-6 text-decoration-underline"
                    onClick={()=> {
                        openNotesDialog()
                    }}
                >
                    {intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.NOTES.MODAL.NOTE.TITLE.EDIT' })}
                </span>
            )            
        }
        return
    }

    const renderDelete = () => {
        if (props.data?.user_id !== 26800 && hasDeleteAccess) { // if note is system-generated or user does not have delete access for notes, do not render delete icon
            return (
                <span className="cursor-pointer link-primary fw-bold ms-8 fs-6 text-decoration-underline"
                    onClick={()=> {
                        deleteNote()
                    }}
                >
                    {intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.NOTES.MODAL.NOTE.TITLE.DELETE' })}
                </span>
            )            
        }
        return
    }

    const checkOverflow = textContainer => { // Check if text is overflowing
        if (textContainer) {
            return (
                textContainer.offsetHeight > 44 ||  textContainer.offsetHeight < textContainer.scrollHeight || textContainer.offsetWidth < textContainer.scrollWidth
            )
        }
        return false;
    };

    const openNotesDialog = () => {
        if (props.data?.user_id !== 26800) { //if note is system-generated, do not open edit dialog
            setShowDialog(true)
        }        
    }

    return (
        <div className={'row list-note py-2'} onDoubleClick={openNotesDialog}>
            <NoteDialog show={showDialog} handleClose={() => {setShowDialog(false)}} handleSubmit={editNote} data={props.data}/>
            <div className='col-lg-12'>
                <div className='row justify-content-between'>
                    <div className='col-lg-6 fs-5 fw-bold'>
                        {props.data?.user ?? ''}
                    </div>
                    <div className='col-lg-6 text-end'>
                        <div className='mb-2'>                            
                            {renderEdit()}
                            {renderDelete()}
                        </div>
                        <div>
                            {props?.data?.date && props.data.date != '0000-00-00' ? `${intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.NOTES.CREATED' })}: ${moment(moment(props.data.date).format('YYYY-MM-DD') + ' ' + props.data.time).format('MM/DD/YYYY [a]t hh:mm A')} ${props.data.user ? `by ${props.data.user}` : ''}` : ''}
                        </div>
                        <div>
                            {props?.data?.modified_date && props?.data?.modified_date != '0000-00-00' ? `${intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.NOTES.MODIFIED' })}: ${moment(moment(props.data.modified_date).format('YYYY-MM-DD') + ' ' + props.data.modified_time).format('MM/DD/YYYY [a]t hh:mm A')} ${props.data.modified_user ? `by ${props.data.modified_user}` : ''}` : ''}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-12'>
                <div className={'row' + (props.data?.user || (props?.data?.date && props.data.date != '0000-00-00') ? ' mt-1' : '')}>
                    <div ref={noteRef} className={'col fs-5' + (enableReadMoreProcessed && enableReadMore && !readMore ? ' note-text-ellipse pe-0' : '')} style={{textAlign : (readMore ? 'justify' : ''), whiteSpace: 'pre-wrap'}}>
                        {props.data?.note ?? ''}
                    </div>
                    {
                        enableReadMoreProcessed && enableReadMore &&
                        <div className='col-lg-1 text-end fw-bold d-flex align-items-end justify-content-end' style={{ width: readMore ? '77px' :'84px' }}>
                            <a className='text-overflow' onClick={() => setReadMore(!readMore)}>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.NOTES.LABEL.' + (readMore ? "READLESS" : "READMORE") })}
                            </a>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export { ListNote }