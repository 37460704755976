import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

const modalsRoot = document.getElementById('root-modals') || document.body

const ScratchDropPopup = ({ show, handleClose, scratchDropPopupData, callConfirmDivisionDrop, callRapidAddDropClassByNumbers, calledFrom }) => {

    const handleClick = (action) => {
        if (calledFrom === "Entries") {
            callConfirmDivisionDrop(
                scratchDropPopupData.class_numbers, 
                action, 
                scratchDropPopupData.divisionClassesToDrop,
                scratchDropPopupData.divisionClassesToScratch,
                scratchDropPopupData.classesNotDroppable,
            )
        } else if (calledFrom === "Rapid") {
            callRapidAddDropClassByNumbers(
                scratchDropPopupData.entryNumber,
                scratchDropPopupData.riderId,
                "",
                scratchDropPopupData.classesToDrop,
                scratchDropPopupData.showId,
                action,
                scratchDropPopupData.classesNotDroppable,
                scratchDropPopupData.divisionClassesToScratch,
                scratchDropPopupData.divisionClassesToDrop,
                scratchDropPopupData.addResponse,
                scratchDropPopupData.invalidClasses,
            )
        }
      
        handleClose()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-400px search-form'
            show={show}
            onHide={() => handleClick("Cancel")}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    handleClick("Cancel")
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                {/* <h2 className="fs-4">{intl.formatMessage({ id: '' })}</h2> */}
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={() => handleClick("Cancel")}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0 d-flex'>
                        <div className='row mb-3'>
                            <div className='col-lg-1 d-flex align-self-center'>
                                <i className="fa-2xl fa-sharp fa-solid fa-circle-xmark text-danger"></i>
                            </div>
                            <div className='col ms-lg-2'>
                                <span className="d-inline-block align-bottom">{ scratchDropPopupData?.message }</span>
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button 
                            type='button' 
                            className="btn btn-sm me-4 fw-bold text-uppercase btn-dark"  
                            style={{ marginLeft: "auto" }} 
                            onClick={() => handleClick("Cancel")}
                            autoFocus>
                            Cancel
                        </button>

                        <button type='button' className="btn btn-sm fw-bold me-4 text-uppercase btn-secondary" 
                           onClick={() => handleClick("Scratch")}
                           data-tooltip-id="ENTRIES.DETAIL.MODAL.SCRATCHDROP.BUTTON.SCRATCH">
                            Scratch
                        </button>

                        <button type='button' className="btn btn-sm fw-bold text-uppercase btn-secondary" 
                        onClick={() => handleClick("Drop")}
                        data-tooltip-id="ENTRIES.DETAIL.MODAL.SCRATCHDROP.BUTTON.DROP">
                            Drop
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { ScratchDropPopup }