import React from 'react';
import useApplyToolTips from '../hooks/use-apply-tooltips';

interface Props {}

const ToolTipProvider: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ children }) => {
    useApplyToolTips();  // Use the tooltip hook

    return <>{children}</>;  // Render children
};

export default ToolTipProvider;
