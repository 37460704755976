import {useEffect, useState} from 'react'
import MoveEntriesToShowVerification from './MoveEntriesToShow/MoveEntriesToShowVerification'
import AllShows from './MoveEntriesToShow/AllShows'
 
const MoveEntriesToShow = ({show, handleClose}) => {
    const [displayMoveEntriesToShowVerificationDialog, setDisplayMoveEntriesToShowVerificationDialog] = useState(false)
    const [displayAllShows, setDisplayAllShows] = useState(show)
    const [verificationData, setVerificationData] = useState(null)
    const [entryIds, setEntryIds] = useState([])
    const [selectedShow, setSelectedShow] = useState(null)
    
    const onClose = () => {
        setDisplayMoveEntriesToShowVerificationDialog(false) 
        handleClose()
    }

    const displayVerificationDialog = (verificationData) => {
        setVerificationData(verificationData)
        setDisplayAllShows(false)
        setDisplayMoveEntriesToShowVerificationDialog(true)
    }

    return (
        displayAllShows ?
             <AllShows 
                show={displayAllShows} 
                handleClose={handleClose} 
                displayVerificationDialog={displayVerificationDialog} 
                entryIds={entryIds} setEntryIds={setEntryIds} 
                selectedShow={selectedShow} 
                setSelectedShow={setSelectedShow}
            /> :
            <MoveEntriesToShowVerification 
                show={displayMoveEntriesToShowVerificationDialog} 
                handleClose={() => onClose()} 
                verificationData={verificationData} 
                entryIds={entryIds} 
                setEntryIds={setEntryIds} 
                selectedShow={selectedShow} 
                setSelectedShow={setSelectedShow}
            />
    )
}

export default MoveEntriesToShow