/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../helpers'
import { useAppSelector } from '../../../app/redux/hooks'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
})

const PageDataProvider: FC<WithChildren> = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: []
  breadcrumbs?: Array<PageLink>
  usePipeSeparator?: boolean
}

const PageTitle: FC<Props & WithChildren> = ({children, description, breadcrumbs, usePipeSeparator=false}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs} = usePageData()
  const currentShowName = useAppSelector(state => state.currentShow.show_name);
  const currentShowNameAbbr = useAppSelector(state => state.currentShow.name_abbr);
  let formatted_description = description && description !== null && description.length > 1
    ? `| ${description.slice(0, -1).join('| ')}${description[description.length - 1] !== null && description[description.length - 1] !== undefined ? ` | (#${description[description.length - 1]})` : ""}`
    : description
      ? `| ${description.join(' | ')}`
      : "";

  let formatted_description_heading = description && description !== null && description.length > 1
    ? !usePipeSeparator ? 
    `${description.slice(0, -1).join(', ')}${description[description.length - 1] !== null && description[description.length - 1] !== undefined ? ` (#${description[description.length - 1]})` : ""}` :
    `${description[description.length - description.length] !== null && description[description.length - description.length] !== undefined ? `(#${description[description.length - description.length]}) ` : ""}${description.slice(1).join(' | ')}`
    : description
      ? !usePipeSeparator ? description : `(#${description})`
      : "";

  let area = children ? ` | ${children.toString()}` : ''
  
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
      document.title = `${currentShowNameAbbr !== "" ? currentShowNameAbbr : currentShowName} ${area}`;
    }
    return () => {
      setPageTitle('')
    }
  }, [children, currentShowName, currentShowNameAbbr])

  useEffect(() => {
    if (description && description.some(item => item !== null && item !== "" && item !== 0 && item !== undefined)) {
      setPageDescription(formatted_description_heading.toString())
      document.title = `${currentShowNameAbbr !== "" ? currentShowNameAbbr : currentShowName} ${formatted_description}`;
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  return <></>
}

const PageDescription: FC<WithChildren> = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, PageTitle, PageDataProvider, usePageData}
