import { useEffect, useMemo, useRef, useState } from 'react';
import {useIntl} from 'react-intl'
import Split from 'react-split'
import UpcomingTripsGrid from './UpcomingTripsGrid';
import StaggerGrid from './StaggerGrid';
import GroupSettings from './GroupSettings';
import TripDetails from './TripDetails';
import ScoringMode from './ScoringMode';
import { useAnnouncerToolContext } from './AnnouncerToolContext';
import { QuickAdd } from './QuickAdd';
import axios from 'axios';
import { useAlert } from '../../../modules/sgl-utils/DialogsProvider';
import { getCurrentTimeStamp, loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../modules/sgl-utils/SglFunctions';
import { useSyncUpdateContext } from '../../../modules/output-listing/synchronizeUpdateContext';
import AgGridSettings from '../../../modules/modals/AgGridSettings';

const SGL_API_URL = process.env.REACT_APP_SGL_API_URL
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const   IngateAnnouncerTab = (props) => {
  const intl = useIntl();
  const [quickAddDialog,setQuickAddDialog] = useState(false)
  const [currentPage, setCurrentPage] = useState(0);
  const classesPerPage = 2;
  const classCounting = ["First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh", "Eighth", "Ninth", "Tenth",];
  const { selectedClassGroup, currentClassGroup, classesWithCompletedTrips, errors, orderUpcomingR2JO, setOrderUpcomingR2JO, classSplits, currentShowID, selectedDate, selectedRing, currentCompany, showRings, currentTrip, upcomingTrips, customerID, refreshGrids, lastIngateActivity, lastStaggerUpdateRef, updateGroupSettings, showAgGridSettingsModal, setShowAgGridSettingsModal, showGridLoadingOverlays } = useAnnouncerToolContext()
  var divs = []
  var dynamic_height;
  let splits = "B"
  let classAndPageNumberMapping = {}
  const alertDialog = useAlert();
  const { removeAllEventListeners, removeEventListenersByPrefix, addSubscribedEvents } = useSyncUpdateContext()
  const timeIntervalRef = useRef(null)
  const staggerGridRefs = useRef([]) // Refs of all the stagger grids rendered
  const [initialDefs, setInitialDefs] = useState({});

  const handleInitialDefs = (index, defs) => {
    setInitialDefs(prev => ({ ...prev, [index]: defs }));
  };

  useEffect(() => {
    // Remove existing event listeners
    removeEventListenersByPrefix('ingate-class-update')

    if (currentClassGroup) {      
      // Store references to the callback functions
      const syncEvents = [];

      // When class group is set, add event listener based on each class_id
      for (let cgxc of currentClassGroup?.ClassGroupxclasses) {
        // Store the callback function for later removal
        syncEvents.push({
          eventId: `ingate-class-update-${customerID}-${currentShowID}-${cgxc?.class_id}`, 
          callback: () => { 
                if (timeIntervalRef.current) {
                    clearTimeout(timeIntervalRef.current)
                }
                const difference = getCurrentTimeStamp() - lastStaggerUpdateRef.current
                // If last update we received was before 5 seconds, then refresh grids. 
                if (difference > 5) {
                    refreshGrids(false)
                } else { // Delay updates for 5 seconds as last update was received in last 5 seconds.
                    timeIntervalRef.current = setTimeout(() =>  {  refreshGrids(false); }, 5000)
                }
            }
        })
      }
      
      // Set the (action/callback) with key event id, in a state which will be used next time to remove event listener
      addSubscribedEvents(syncEvents)
    }
  }, [currentClassGroup]);

  useEffect(() => {
    // Remove existing event listeners
    removeAllEventListeners()

  }, []);

  if(currentClassGroup && classesWithCompletedTrips && classesWithCompletedTrips.length > 0){  
      
    divs = currentClassGroup?.ClassGroupxclasses.map((cgxc, index) => {
      //Set the grid height
      if(index % 2 == 0){
        if((index+1) == classesWithCompletedTrips.length){
          dynamic_height = "calc(100% - 45px)";
        }else{
          dynamic_height = "calc(100% - 30px)";
        }
      }else{
          dynamic_height = "calc(100% - 45px)";
      }

      //Set grid label
      let grid_label = classCounting[index] + ' Class: ' + cgxc?.Class?.number + " - " + cgxc?.Class?.name;

      //Set the splits if they are other than []/A (by default "B")
      let class_split = classSplits[cgxc?.Class?.class_id]
      if(class_split.length !== 0)
      {
        if(class_split[0] !== "A")
        {
          splits = class_split[0]
        }
      }
      //Store page against class numbers
      classAndPageNumberMapping[cgxc?.Class?.number] = Math.floor(index / classesPerPage);

      return (
        <StaggerGrid key={index} label={grid_label} grid_no={index} dynamic_height={dynamic_height} class_number={cgxc?.Class?.number} class_name={cgxc?.Class?.name} class_id={cgxc?.class_id} splits={splits} gridRefs = {staggerGridRefs}  handleInitialDefs={handleInitialDefs} />
      );
    });
  }else{
    //If no classes present display empty grid
    divs = [<StaggerGrid dynamic_height={"calc(100% - 45px)"} grid_no={0} gridRefs = {staggerGridRefs} handleInitialDefs={handleInitialDefs} />]
  }

  const pageCount = divs.length > 0 && Math.ceil(divs.length / classesPerPage) || 0;

  const paginatedDivs = useMemo(() => {
    const startIndex = currentPage * classesPerPage;
    const endIndex = (currentPage + 1) * classesPerPage;
    if(divs.length > 0)
    
      return divs.slice(startIndex, endIndex)
    else
      return divs;
  }, [currentPage, divs]);

  const handlePageChange = (selectedPage) => {
    // setShowLoading(true);
    setCurrentPage(selectedPage);
  };

  const renderPaginationButtons = () => {
    return (
      paginatedDivs.length > 0 &&
      <div className="col d-flex justify-content-end align-items-center">
        {Boolean(selectedDate && selectedRing && selectedClassGroup) && (
          <div
              onClick={() => { if (!showGridLoadingOverlays) { setShowAgGridSettingsModal(true) }}}
              className='me-3 fw-bold cursor-pointer'
              style={{position:"relative",zIndex: 10}}
          >
            <i className={`fas fa-gear fs-6 ${showGridLoadingOverlays ? 'text-secondary' : 'text-dark'}`}></i>
          </div>
        )}
        <button
          type="button"
          disabled={currentPage === 0}
          onClick={() => handlePageChange(currentPage - 1)}
          className='btn btn-sm btn-secondary me-2 fw-bold w-50px h-30px rect'
        >
          <i className="fas fa-angle-double-left fs-8"></i>
        </button>

        <button
          type="button"
          disabled={currentPage === pageCount - 1}
          onClick={() => handlePageChange(currentPage + 1)}
          className='btn btn-sm btn-secondary fw-bold w-50px h-30px rect'
        >
          <i className="fas fa-angle-double-right fs-8"></i>
        </button>
      </div> || null
    );
  };

  useEffect(() => {
    setCurrentPage(0)
  }, [selectedClassGroup]);

  useEffect(() => {
    if(currentTrip && classAndPageNumberMapping){ 
      if(classAndPageNumberMapping[currentTrip.Class.number] !== currentPage && lastIngateActivity == 'Trip Gone')
      {// If the selectedTrip is not present on curret page and selected trip is gone than change current page
        setCurrentPage(classAndPageNumberMapping[currentTrip.Class.number])
      }
    }
  }, [upcomingTrips, classesWithCompletedTrips]);

  const [isRightSidebarVisible, setRightSidebarVisible] = useState(true);

  const handleRightSidebarToggle = () => {
    setRightSidebarVisible(!isRightSidebarVisible);
  };
  
  //Horizontal Split Size
  var splitSizes = localStorage.getItem('split-sizes')

  if (splitSizes) {
    splitSizes = JSON.parse(splitSizes)
  } else {
    splitSizes = isRightSidebarVisible ? [29, 44, 27] : [35, 65, 0]
  }

  const openReaderboard = () => {
    const companyUrlPath = currentCompany.urlpath // company url eg esp
    let ring = showRings.filter(ring => ring.ring_id == selectedRing)//get selected ring from showRings
    let ringNumber = ring.length > 0 ? ring[0].ring_number : null
    if(companyUrlPath && SGL_API_URL && currentShowID && selectedDate && selectedRing && ringNumber)
    {
      //Sample url: https://pbiec.com/readerboard?show=20289&date=2023-08-31&ring=1&ring_id=1
      const readerboardUrl = `${SGL_API_URL}/${companyUrlPath}/readerboard?show=${currentShowID}&date=${selectedDate}&ring=${ringNumber}&ring_id=${selectedRing}`;
      // Open the link in a new tab/window
      window.open(readerboardUrl, '_blank');
    }
  };

  const openAnnoucerToolInNewTab = () => {
    const currentUrl = new URL(window.location.href);
    if(selectedDate && selectedRing)
    {
      let currentClassGroupParameter = selectedClassGroup ? `&selectedClassGroup=${selectedClassGroup}` : ''
      //Sample url: http://localhost:3011/sglcloud/classes/announcer-interface?selectedDate=2021-07-04&selectedRing=3&selectedClassGroup=50151
      const announcerToolUrl = currentUrl.origin+ currentUrl.pathname+`?selectedDate=${selectedDate}&selectedRing=${selectedRing}${currentClassGroupParameter}`;   
      // Open the link in a new tab/window
      window.open(announcerToolUrl, '_blank');
    }
  };

  const openClassDetailPage = () => {
    const companyUrlPath = currentCompany.urlpath // company url eg esp
    let classId = currentClassGroup?.ClassGroupxclasses?.length > 0 ? currentClassGroup?.ClassGroupxclasses[0].class_id : null // class groups first class

    if(companyUrlPath && SGL_API_URL && classId)
    {
      //Sample Url: https://showgroundslive.com/esp/classes/detail?cid=255217&sid=20160
      const classDetailUrl = `${SGL_API_URL}/${companyUrlPath}/classes/detail?cid=${classId}&sid=${currentShowID}`;
      // Open the link in a new tab/window
      window.open(classDetailUrl, '_blank');
    }
  };

  const sendTripJson = (event, action) => {
    if(currentClassGroup && currentTrip)
    {
      loadingSpinnerBtnWait(event)
      axios.get(`${NEST_API_URL}/ingate-announcer/sendTripJson?show_id=${currentShowID}&customer_id=${customerID}&class_group_id=${currentClassGroup.class_group_id}&entryxclasses_id=${currentTrip.entryxclasses_id}&update_type=${action}&button_type=${action}`)
      .then(response => {

      })
      .catch((error) => {
        if (error.response) {
          alertDialog({message: error.response.data.error});
        }
      })
      .finally(() => {
        loadingSpinnerBtnRelease(event)
      });     
    }          
  }

  return (
    <>

        { showAgGridSettingsModal && <AgGridSettings 
            gridRefs={staggerGridRefs}
            show={showAgGridSettingsModal}
            handleClose={() => setShowAgGridSettingsModal(false)}
            initialColumnDefs={initialDefs}
        /> }

      <Split
            className="split-horizontal split-bottom py-2"
            minSize={isRightSidebarVisible ? [240, 500, 380] : [240, 500, 0]}
            maxSize={isRightSidebarVisible ? [Infinity, Infinity, 380] : [Infinity, Infinity, 0]}
            gutterSize={5}
            snapOffset={0}
            //sizes={isRightSidebarVisible ? splitSizes : [splitSizes[0], splitSizes[1]+splitSizes[2], 0]}
            sizes={isRightSidebarVisible ? splitSizes[2]>0 && splitSizes[2]<1 ? [splitSizes[0], (splitSizes[1]+splitSizes[2])-20, 20] : splitSizes : [splitSizes[0], splitSizes[1]+splitSizes[2], 0]}
            onDragEnd={(splitSizes) => {localStorage.setItem('split-sizes', JSON.stringify(splitSizes))}}
        >
            
            <div className='remainingtrips pe-2' style={{height:'calc(100% - 25px)'}}>
                { errors.length > 0 &&
                  <div className="remainingtrips-overlay">&nbsp;</div>
                }
                {<UpcomingTripsGrid handleOpen={() => setQuickAddDialog(true)}></UpcomingTripsGrid>}
            </div>

            <div className='staggers'>
                { errors.length > 0 &&
                  <div className="staggers-overlay">
                    <div className="background">&nbsp;</div>
                    <div className="messages">
                      {
                        errors.map(error =>
                          <p>{error}</p>
                        )
                      }
                    </div>
                  </div>
                }

                <div className="pagination mx-2">
                  { (currentClassGroup?.after_delay_jo || currentClassGroup?.after_delay_r2) ? (
                  <div className='col-lg-4 d-flex align-items-center'>
                      <div className='form-check-sm form-check-custom  me-2'>
                      <input
                          className='form-check-input'
                          type='checkbox'
                          id='ORDERJO'
                          value= '1'
                          checked={orderUpcomingR2JO}
                          onChange={(e) => 
                            setOrderUpcomingR2JO(e.target.checked)
                          }
                      />
                      </div>
                      <label className='col-form-label fs-6 py-0' htmlFor='ORDERJO' data-tooltip-id={`ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.LABEL.ORDER${ (currentClassGroup?.round_labels?.r2?.abbr ? currentClassGroup.round_labels.r2.abbr : 'R2')}FORCALLBACKTRIPS`}> 
                        { intl.formatMessage({ 
                            id: `FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.LABEL.ORDER${ (currentClassGroup?.round_labels?.r2?.abbr ? currentClassGroup.round_labels.r2.abbr : 'R2')}` 
                          })
                        }
                      </label>
                  </div>
                  ) : null }
                  { (currentClassGroup?.configurable_r3) && (
                  <div className='col-lg-3 d-flex align-items-center'>
                      <div className='form-check-sm form-check-custom  me-2'>
                      <input
                          className='form-check-input'
                          type='checkbox'
                          id='enableR3'
                          value= '1'
                          checked={currentClassGroup?.enable_r3}
                          onChange={(e) => updateGroupSettings({ enable_r3: e.target.checked })}
                      />
                      </div>
                      <label className='col-form-label fs-6 py-0' htmlFor='enableR3' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.LABEL.ENABLEJUMPOFFROUND"> 
                        { intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.LABEL.ENABLER3'}) }
                      </label>
                  </div>
                  ) }
                  {renderPaginationButtons()}
                </div>
                {paginatedDivs.length > 1 &&
                <Split
                    className="split-vertical split"
                    direction="vertical"
                    minSize={50}
                    gutterSize={5}
                    snapOffset={0}
                >
                    {paginatedDivs.length > 0 && paginatedDivs.map((div) => (
                      <div className='p-2 py-0'>{div}</div>
                    ))}
                </Split> ||
                    paginatedDivs.length > 0 && paginatedDivs.map((div) => (
                      <div className='p-2 py-0' style={{height: "100%"}}>{div}</div>
                    ))
                }
            </div>

            <div className='scoring ps-2' style={{"position":"relative"}}>
              <div className='d-flex justify-content-end toggle-button mb-0' style={{position:"absolute",right: "0px",width:"100%"}}>
                { isRightSidebarVisible &&
                  <div className="row mb-0 ms-0 mw-350px" style={{position:"absolute",left:"7px"}}>
                    {
                      currentClassGroup?.group_type == "Jumpers" &&
                      <>
                        <div className="col-lg-1 border-top border-0 mt-0 square-shape-ingate"></div>
                        <p className="col fs-7 m-0 mt-2 px-2">
                            {intl.formatMessage({id: `FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.RIGHTSIDEBAR.TOP.SECTIONJUMPER.${ (currentClassGroup?.round_labels?.r2?.abbr) ? currentClassGroup.round_labels.r2.abbr : 'R2'}`})}
                        </p>
                      </>
                    }
                    { currentClassGroup?.group_type == "Hunters" &&
                      <>
                        <div className="col-lg-1 border-top border-0 mt-0 square-shape-ingate"></div>
                        <p className="col fs-7 m-0 mt-2 px-2">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.RIGHTSIDEBAR.TOP.SECTIONHUNTER'})}</p>
                      </>
                    }
                  </div>
                }
                <button type="button" onClick={handleRightSidebarToggle}
                        style={{position:"absolute",right:"0px"}}
                        className='btn btn-sm btn-secondary fw-bold ps-3 pe-2 pt-1 pb-1'>
                        {isRightSidebarVisible ? <i className="fas fa-angle-right fs-5"></i> : <i className="fas fa-angle-left fs-4"></i>}
                </button>
              </div>
                {isRightSidebarVisible && (
                
                    <div className='col-lg-12'>

                    <div className='d-flex justify-content-start py-1 px-0 pt-0'>
                        <button type="button" className='btn btn-sm btn-secondary me-1 fw-bold orange fs-8 lh-sm' onClick={openReaderboard} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.BUTTON.READERBOARD">
                            Readerboard
                        </button>

                        <button type="button" className='btn btn-sm btn-secondary me-1 fw-bold orange fs-8 lh-sm' onClick={openAnnoucerToolInNewTab} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.BUTTON.SWITCHANNOUNCER">
                            Switch Announcer
                        </button>

                        <button type="button" className='btn btn-sm btn-secondary fw-bold orange fs-8 lh-sm' onClick={openClassDetailPage} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.BUTTON.CLASSRESULTS">
                            Class Results
                        </button>
                    </div>

                    {<GroupSettings></GroupSettings>}

                    {<TripDetails></TripDetails>}
                    
                    <div className='d-flex justify-content-start py-0 px-0 w-300px' style={{zIndex:"2",position:"relative"}}>
                        <button type="button" className='btn btn-sm btn-secondary me-1 fw-bold orange fs-8 lh-sm' onClick={(e) => sendTripJson(e,'Before')} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.TRIPDETAILS.BUTTON.BEFORE">
                          <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1" role="status" aria-hidden="true"></span>{intl.formatMessage({id: 'ANNOUNCER.INGATEANNOUNCER.BEFORE.BUTTON'})}
                        </button>

                        <button type="button" className='btn btn-sm btn-secondary me-1 fw-bold orange fs-8 lh-sm' onClick={(e) => sendTripJson(e,'On Course')} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.TRIPDETAILS.BUTTON.ONCOURSE">
                            <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1" role="status" aria-hidden="true"></span>{intl.formatMessage({id: 'ANNOUNCER.INGATEANNOUNCER.ONCOURSE.BUTTON'})}
                        </button>

                        <button type="button" className='btn btn-sm btn-secondary fw-bold orange fs-8 lh-sm' onClick={(e) => sendTripJson(e,'After')} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.TRIPDETAILS.BUTTON.AFTER">
                        <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1" role="status" aria-hidden="true"></span>{intl.formatMessage({id: 'ANNOUNCER.INGATEANNOUNCER.AFTER.BUTTON'})}
                        </button>
                    </div>

                    {<ScoringMode></ScoringMode>}

                    </div>

                )}

              </div>
        </Split>


        { quickAddDialog && 
          <QuickAdd show={quickAddDialog} handleClose={() => setQuickAddDialog(false)} />
        }
    </>
  );
};

export {IngateAnnouncerTab};
  