import { AgGridReact } from "ag-grid-react";
import { useIntl } from "react-intl";
import { getRowData, renderCheckBox } from "../../../../modules/sgl-utils/agGridHelpers";
import { getNarrowHeaderHeight,getNarrowRowHeight } from "../../../../modules/sgl-utils/agGridHelpers";
import { useCurrencyFormatter } from "../../../../modules/sgl-utils/Formatters";
import { useEffect, useMemo, useRef, useState } from "react";
import './../tab.css'
import DateRenderer from "../../../../modules/output-listing/renderers/DateRenderer";
import SelectRenderer from "../SelectRenderer";
import { useAlert, useConfirm, useFlashAlert } from "../../../../modules/sgl-utils/DialogsProvider";
import { useAppSelector } from "../../../../redux/hooks";
import axios from "axios";
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from "../../../../modules/sgl-utils/SglFunctions";
import { usePaymentBatchContext } from "../../PaymentBatchContext";
import { ReversedAmountDialog } from "./ReversedAmountDialog";
import { NumericFormat } from "react-number-format";
import PreferenceRenderer from "./PreferenceRenderer";

const RequestedPaymentsGrid = ({ pendingPayments, setPendingPayments, updatePaymentBatchItems, getRequestedPaymentsSum , displayLoadingOverlay, checkAll, setCheckAll, reversedAmountData, setReversedAmountData, displayReverseAmountDialog, setDisplayReverseAmountDialog}) => {
    const intl = useIntl()
    const flashAlert = useFlashAlert()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const currencyFormatter = useCurrencyFormatter()
    const customerID = useAppSelector(state => state.showCompany.company_id);
    const containerStyle = useMemo(() => ({ width: '100%', height: '44vh' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const pendingPaymentsGridRef = useRef()
    const {selectedShow, selectedBatch, updateCreateBatchTabData, setUpdateCreateBatchTabData}= usePaymentBatchContext()
    const [startingCheckNo, setStartingCheckNo] = useState(0)

    useEffect(() => {
        if(displayLoadingOverlay){
            pendingPaymentsGridRef?.current?.api?.showLoadingOverlay()
        }else{
            pendingPaymentsGridRef?.current?.api?.hideOverlay()
        }
    }, [displayLoadingOverlay])

    useEffect(() => {
        if(pendingPaymentsGridRef?.current){
            pendingPaymentsGridRef?.current?.api?.setPinnedBottomRowData([{amount: getRequestedPaymentsSum()}])
        }
    }, [pendingPayments])

    const defaultColDef = useMemo(() => {
        return {
          minWidth: 110,
          width: 110,
          resizable: true,
          sortable: true,
          suppressMenu: true,
          suppressMovable: true,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          headerClass: "ag-center-aligned-header",
          singleClickEdit: true,
        };
    }, []);

    function CheckboxHeader({ headerName, onChange, checked }) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <div className='w-lg-20px form-check-sm form-check-custom py-2'>
                    <input
                        className='form-check-input'
                        type="checkbox"
                        id="checkAll" 
                        checked={checked}
                        onChange={(e) => onChange(e.target.checked)} 
                    />
                </div>
                { headerName.toUpperCase() }
            </div>
        );
    }

    const checkPayments = (params, checkAllPayments, checked) => {
        const updatedPendingPayments = getRowData(pendingPaymentsGridRef?.current?.api)?.map((payment) => {
            if (!checkAllPayments && payment.payment_batchitems_id === params?.node?.data?.payment_batchitems_id) {
                payment['include'] = params?.node?.data?.include;

                // update checkAll to false if one pending payment is unselected
                if(!params?.node?.data?.include && checkAll){
                    setCheckAll(false)
                }
            } else if (checkAllPayments) {
                payment['include'] = checked;
            }
            return payment;
        });
    
        pendingPaymentsGridRef?.current?.api?.setRowData(updatedPendingPayments)
        setPendingPayments(updatedPendingPayments);
    };

    const autoFillChecks = (event) => {
        let selectedPendingPayments = pendingPayments.filter((payment) => payment.include)
        if(selectedPendingPayments.length > 0){
            selectedPendingPayments = selectedPendingPayments.map((payment) => {return payment.payment_batchitems_id})
            updatePaymentBatchItems(selectedPendingPayments, 'autoFillChecks', 'check_number', startingCheckNo, '', pendingPaymentsGridRef, event)
        }else{
            flashAlert({ message: `Please select some pending checks to auto-fill the check numbers.` });
        }
    }
    

    const columnDefs = [
        { 
            field: 'payment_batchitems_id', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.ID' }), 
            width: 90,
            cellStyle: { textAlign: 'center' },
            hide: true
        },
        { 
            field: 'include',
            cellRenderer: params => params.node.rowPinned ? null : renderCheckBox(params, () => {
                checkPayments(params, false, null)
            }),
            maxWidth: 70,
            headerComponentFramework: CheckboxHeader,
            headerComponentParams: {
                headerName: '',
                onChange: (checked) => { 
                    checkPayments(null, true, checked)
                    setCheckAll(checked)
                },
                checked: checkAll
            },
            cellStyle: ({textAlign: 'center'})
        },
        { 
            field: 'payment_date', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.DATE' }),
            cellRenderer: DateRenderer,
            cellStyle: { textAlign: 'center' }
        },
        { 
            field: 'People.fl_name', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.RTO" }),
            minWidth: 150,
            flex: 1,
            cellStyle: { textAlign: 'left' }
        },
        { 
            field: 'prize_preference', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.PAYMENT.TYPE" }),
            width: 160,
            cellRenderer : params => params.node.rowPinned ? null : PreferenceRenderer(params, updatePaymentBatchItems, pendingPaymentsGridRef),
        },
        { 
            field: 'posted', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.Posted" }),
            width: 30,
            valueGetter: (params) => { 
                if(params.node.rowPinned) return ''
                if(params?.data?.posted) return 'Yes'
                return 'No'
            } ,
            cellStyle: { textAlign: 'center' }
        },
        { 
            field: 'check_number', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.CHECKNO" }),
            width: 100,
            editable: true,
            cellStyle: { textAlign: 'left' }
        },
        { 
            field: 'payment_type', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.TYPE" }),
            width: 210,
            cellRenderer : params => params.node.rowPinned ? null : SelectRenderer(params, updatePaymentBatchItems, pendingPayments),
        },
        { 
            field: 'description', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.COL.DESCRIPTION" }),
            width: 200,
            flex: 1.25,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            editable: true,
            cellStyle: { textAlign: 'left' }
        },
        { 
            field: 'prize_money_amount', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.COL.PRIZE.MONEY" }),
            wrapHeaderText: true,
            autoHeaderHeight: true,
            sortable: false,
            width: 110,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => params.node.rowPinned ? null : currencyFormatter(params.value)
        },
        { 
            field: 'balance_refund_amount', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.COL.BALANCE.REFUND" }),
            wrapHeaderText: true,
            autoHeaderHeight: true,
            sortable: false,
            width: 110,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => params.node.rowPinned ? null : currencyFormatter(params.value)
        },
        { 
            field: 'amount', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.COL.PAID" }),
            wrapHeaderText: true,
            autoHeaderHeight: true,
            width: 150,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => currencyFormatter(params.value)
        }
    ]

    const onCellEditingStopped = (params) => {
        const {data, colDef, newValue, oldValue} = params
        // update batch item only if data is changed
        if(newValue !== oldValue){
            updatePaymentBatchItems([data.payment_batchitems_id], 'updateBatchItem', colDef.field, newValue, oldValue, pendingPaymentsGridRef)
        }
    }   

    const getRowStyle = (params) => {
        if (params.node.rowPinned) {
            return { fontWeight: "bold" };
        }
    };
    

    return (
        <div>
            { displayReverseAmountDialog ?
                <ReversedAmountDialog show={displayReverseAmountDialog} handleClose={() => {setReversedAmountData([]); setDisplayReverseAmountDialog(false)}} data={reversedAmountData}/> : null
            }

            {/* Autofill Check Number  */}
            <div className='row mb-2 col-lg-8'>
                <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 label-width-130 pe-0'  data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.POSTPAYMENTS.LABEL.STARTINGCHECK"> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.STARTINGCHECKNO' })}</label>
                <div className='col-lg-2'>
                    <NumericFormat
                        id='startingCheckNo'
                        allowNegative={false}
                        decimalScale={0}
                        value={Number(startingCheckNo)}
                        type='text'
                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        onValueChange={(e)=>{
                            setStartingCheckNo(e.value)
                        }}
                    />
                </div>
                <div className='col-lg-2 px-0'>
                    <button type="button" className='btn btn-sm btn-secondary fw-bold h-30px w-150px py-1 px-4' onClick={(e) => autoFillChecks(e)}  data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.POSTPAYMENTS.BUTTON.AUTOFILL">
                        <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                        {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.BUTTON.AUTOFILL' })}
                    </button>
                </div>
                <div className='col-lg-5 d-flex align-items-center'>
                    <label className='col-lg-12 col-form-label fs-6 py-1 pe-0'> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.AUTOFILL' })}</label>
                </div>
            </div>

            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact
                        ref={pendingPaymentsGridRef}
                        defaultColDef={defaultColDef}
                        rowData={pendingPayments}
                        columnDefs={columnDefs} 
                        rowStyle={{ fontSize: '12px' }}
                        suppressScrollOnNewData={true}
                        headerHeight={getNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                        stopEditingWhenCellsLoseFocus={true}
                        suppressRowHoverHighlight={true}
                        onCellEditingStopped={onCellEditingStopped}
                        getRowStyle={params => getRowStyle(params)}
                        overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while pending payments are loading...</span>'}
                    />
                </div>
            </div>
        
        </div>
       
    )
}

export default RequestedPaymentsGrid