import { useState, useEffect } from 'react';
import { Buffer } from 'buffer';
// import JSZip from 'jszip';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const DOWNLOAD_TYPE_ZIP = "ZIP"
const DOWNLOAD_TYPE_FILE = "FILE"

const useFileDownloader = () => {
    const [eventSource, setEventSource] = useState()
    const [downloadFileType, setDownloadFileType] = useState()
    const [zip, setZip] = useState()
    let files = [] // Don't need to render

    // An EventSource instance opens a persistent connection to an HTTP server, which sends events in text/event-stream format. The connection remains open until closed by calling EventSource.close().
    const createEventSource = (eventId, downloadFileType = DOWNLOAD_TYPE_FILE) => {
        if (!eventSource) { // Check if event source is already initialized
            
           if (downloadFileType === DOWNLOAD_TYPE_ZIP) {
                // setZip(new JSZip())
           }     

           setDownloadFileType(downloadFileType)
            
            setEventSource(
                new EventSource( `${NEST_API_URL}/utility/getFile?event_id=${eventId}`)
            )
        }
    }

    // The close() method of the EventSource interface closes the connection, if one is made.
    // This method is not safe to use. Close connection from backend. 
    const closeEventSource = () => {
        eventSource?.close() // Check if there is event source to close
        setEventSource(null)
        setZip(null)
    }

    const _downloadAsFile = (data) => {
        if (data.closeConnection) {
            return
        }

        return new Promise((resolve) => {
            const buffer = Buffer.from(data.buffer, 'base64');
            const blob = new Blob([buffer], { type: data.content_type });
    
            const tempLink = document.createElement('a');
            tempLink.href = URL.createObjectURL(blob);
            tempLink.download = data.filename;
            tempLink.style.display = 'none';
    
            document.body.appendChild(tempLink);
            tempLink.click();
    
            setTimeout(() => {
                document.body.removeChild(tempLink);
                URL.revokeObjectURL(tempLink.href);
                resolve();
            }, 100); // Adjust the delay as needed
        });
    }

    const _downloadAsZip = async (data) => {
        if (!zip) {
            console.error("Download file type not set.")
            return
        }

        if (!data.closeConnection) {
            // Receive a buffer and convert it into blob and then download
            const buffer = Buffer.from(data.buffer, 'base64');
            zip.file(data.filename, buffer)
            return
        }

        // Generate the ZIP file and create a download link
        try {  
            const zipBlob = await zip.generateAsync({ type: 'blob' })
            const tempLink = document.createElement('a');
            tempLink.href = URL.createObjectURL(zipBlob);
            tempLink.download = 'files.zip';

            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            URL.revokeObjectURL(tempLink.href);
        } catch (err) {
            console.error('Error generating ZIP file:', err);
        }
    }

    useEffect(() => {
        if (eventSource) {
            const handleFileDownload = async payload => {
                let data = JSON.parse(payload.data)

                if (downloadFileType === DOWNLOAD_TYPE_ZIP) {
                    await _downloadAsZip(data)
                } else if (downloadFileType === DOWNLOAD_TYPE_FILE) {
                    files.push(data)
                }
                
                if (data.closeConnection) {

                    if (downloadFileType === DOWNLOAD_TYPE_FILE) {
                        for (const file of files) {
                            await _downloadAsFile(file);
                        }
                    }

                    closeEventSource()
                }
            }

            eventSource.addEventListener('filebuffer', handleFileDownload) 

            eventSource.onerror = e => {
                console.error(e)
                closeEventSource()
            }

            // Cleanup function to remove the event listener when the component unmounts
            return () => {
                if (eventSource) {
                    eventSource.removeEventListener('filebuffer', handleFileDownload)
                    eventSource.close()
                }
            };
        }
    }, [eventSource])

    return { startFileDownloader: createEventSource, forceCloseFileDownloader: closeEventSource }
}

export default useFileDownloader