import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useAlert, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider'
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext'
import axios from 'axios'
import { useAppSelector } from '../../../redux/hooks'
import { useIntl } from 'react-intl'

const modalsRoot = document.getElementById('root-modals') || document.body

const UpdateMemberships = ({show, handleClose}) => {
    const alertDialog = useAlert()
    const intl = useIntl()
    const {progressBar} = useProgress()
    const loadingOverlay = useLoadingOverlay()
    const {getOutputSelectionAreaIDs, getEventID} = useOutputContext()
    const [entryIds , setEntryIds] = useState([])
    const customerId = useAppSelector(state => state.showCompany.company_id);

    const onClose = () => {
        handleClose()
    }

    const membershipsUpdater = (res) => {
        loadingOverlay({show: false})
        let eventID = getEventID("update-memberships-tool")
        progressBar({ show: true, eventID, showProgress: 'inline', title: intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.HEADING.UPDATEMEMBERSHIPS" }), timeElapsed: true })   
        axios.post( process.env.REACT_APP_NEST_API_URL + "/entries/updateMemberships", {
            customer_id: customerId,
            entry_ids: res,
            event_id: eventID
        }).then((res) => {
           progressBar({ show: false, eventID })
           handleClose()
        }).catch((e) => {
           progressBar({ show: false, eventID })
           handleClose()
        })
    }

    useEffect(() => {
        if (show) {
            loadingOverlay({ show: true })
            getOutputSelectionAreaIDs(false)
            .then(res => { 
                if (res.length === 0) {
                    loadingOverlay({ show: false })
                    alertDialog({ message: intl.formatMessage({ id: "ENRIES.UPDATEMEMBERSHIPS.EMPTYERROR"}) })
                    handleClose()
                }
                else{
                    setEntryIds(res)
                    membershipsUpdater(res)
                }
            })
        }
    }, [show])
    
    return createPortal(
        <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
        show={show}
        onHide={onClose}
        >
            <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
        </Modal>,
        modalsRoot
    )
}
export default UpdateMemberships