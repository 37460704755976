import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { useFormContext } from 'react-hook-form'

const ResultsTab = () => {
    const intl = useIntl();
    const methods = useFormContext()

    const columnDefs = [
        { field: 'Entry.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.RESULTS.ENTRY' })},
        { field: 'placing', headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.RESULTS.PLACING' })},
        { field: 'Class.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.RESULTS.CLASS' })},
        { field: 'Class.name', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.RESULTS.CLASSNAME' }), },
        { field: 'total_prize_money', headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.RESULTS.TOTALPRIZEMONEY' }), cellStyle: {textAlign: 'center'}, valueFormatter: params => params.data?.total_prize_money.toFixed(2)},
    ]

    const rowData = methods.getValues('results')

    const containerStyle = useMemo(() => ({ width: '100%', height: '60vh' }), []);

    return (
        <>
            <div className='form-group'>
                <p className='mb-1 text-end'>Total: {methods.getValues('results') && methods.getValues('results').length}</p>
                <div className='row align-items-center mb-2'>
                    <OutputListingGridStatic  area ={"Horses - Results"} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle}></OutputListingGridStatic>
                </div>
            </div>
        </>
    );
}

export { ResultsTab }