import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../../_metronic/helpers'
import Select from 'react-select'
import { reactSelectStyles } from '../../../../modules/sgl-utils/fieldControls'
import { useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { getNarrowHeaderHeight, getNarrowRowHeight, renderCheckBox } from '../../../../modules/sgl-utils/agGridHelpers'
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters'
import axios from 'axios'
import { useFormContext } from 'react-hook-form'
import { useAppSelector } from '../../../../redux/hooks'
import { useAlert, useConfirm, useFlashAlert } from '../../../../modules/sgl-utils/DialogsProvider'
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../../modules/sgl-utils/SglFunctions'

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
 
const ReverseTaxPaymentToIRS = ({show, handleClose, updateCallbackFromParent}) => {
    const intl = useIntl() 
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const currencyFormatter = useCurrencyFormatter()
    const parentFormMethods = useFormContext()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const flashAlert = useFlashAlert()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const people_id = parentFormMethods.getValues('people.people_id');

    const [selectedYear, setSelectedYear] = useState()
    const [yearOptions, setYearOptions] = useState()
    const [rowData, setRowData] = useState([])
    const irsPaymentsGrid = useRef()

    const defaultColDef = useMemo(() => {
        return {
          minWidth: 100,
          width: 100,
          resizable: true,
          sortable: true,
          suppressMenu: true,
          suppressMovable: true,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          headerClass: "ag-center-aligned-header",
          singleClickEdit: true,
        };
    }, []);

    const columnDefs = [
        { 
            field: 'include',
            headerClass: 'ag-center-aligned-header',
            cellRenderer: params => renderCheckBox(params, () => {
                selectIRSPayment(params)
            }),
            width: 60,
            minWidth: 60,
            headerName: "",
            cellStyle: ({textAlign: 'center'})
        },
        { 
            field: 'Entry.number', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.REVERSE.IRS.TAX.PAYMENTS.GRID.COL.ENTRY' }),
            cellStyle: { textAlign: 'center' }
        },{ 
            field: 'Class.number', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.REVERSE.IRS.TAX.PAYMENTS.GRID.COL.CLASS' }),
            cellStyle: { textAlign: 'center' }
        },
        { 
            field: 'Entry.horse', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.REVERSE.IRS.TAX.PAYMENTS.GRID.COL.HORSE" }),
            flex: 1,
            cellStyle: { textAlign: 'left' }
        },{ 
            field: 'Entry.Show.show_name', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.REVERSE.IRS.TAX.PAYMENTS.GRID.COL.SHOW" }),
            minWidth: 280,
            cellStyle: { textAlign: 'left' }
        },
        { 
            field: 'amount', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.REVERSE.IRS.TAX.PAYMENTS.GRID.COL.AMOUNT" }),
            wrapHeaderText: true,
            autoHeaderHeight: true,
            width: 120,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => currencyFormatter(params.value)
        }
    ]

    const getIRSReversePaymentData = async () => {
        if(!selectedYear){
            return;
        }
        
        irsPaymentsGrid?.current?.api?.showLoadingOverlay()
        await axios.get(`${NEST_API_URL}/withholding-tax/getReverseIRSTaxPaymentData?people_id=${people_id}&customer_id=${customer_id}&year=${selectedYear}`)
        .then(response => {
            if(response.data.success){
                setRowData(response.data.IRSTaxPayments)
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            // Do Nothing
            irsPaymentsGrid?.current?.api?.hideOverlay()
        }); 
    }

    const selectIRSPayment = (params) => {
        const updatedRowData = rowData.map((data) => {
            if (data.entryxclasses_id === params?.node?.data?.entryxclasses_id) {
                data['selected'] = !params?.node?.data?.selected;
            }
            return data;
        });
    
        irsPaymentsGrid?.current?.api?.setRowData(updatedRowData)
        setRowData(updatedRowData);
    };

    const reverseIRSPayments = (e) => {
        // get selected trips
        let selectedTrips = rowData.filter((data)=> data.selected)
        if(selectedTrips?.length === 0){
            alertDialog({message: 'Please select some IRS payments for reversal.'})
            return; // do nothing
        }

        let paymentReversed = selectedTrips.reduce((val, trip) => val + trip.amount, 0)
        let selectedTripsIds = selectedTrips.map((trip) => trip.entryxclasses_id)
        confirmDialog({ message: `${intl.formatMessage({ id: "FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.REVERSE.IRS.TAX.PAYMENTS.GRID.CONFIRM.MESSAGE"})} ${currencyFormatter(paymentReversed)} ? This action can't be undone.`})
        .then(() => {

            let requestData = {
                entryxclasses_ids: selectedTripsIds,
                year: selectedYear,
                people_id: people_id,
                customer_id: customer_id,
                people: parentFormMethods.getValues().people
            }

            loadingSpinnerBtnWait(e)
            axios.post( process.env.REACT_APP_NEST_API_URL + '/withholding-tax/reverseIRSTaxPayment', requestData)
            .then((response) => {
                if(response.data.success){
                    flashAlert({ type: 'success', message: `${currencyFormatter(paymentReversed)} IRS payment has been reversed successfully.` });
                    updateCallbackFromParent()

                    // close the dialog
                    handleClose()
                }
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
            }).finally(() => {
                loadingSpinnerBtnRelease(e)
            })
        })
    }

    useEffect(() => { // prefill the year select option
        let year = new Date().getFullYear()
        let month = new Date().getMonth()
        let dropDownOptions = []
        while(year >= 2014){
            dropDownOptions.push({value: year, label: year})
            year--
        }
        setYearOptions(dropDownOptions)

        if(month >= 11){
            setSelectedYear(year)
        }else{
            setSelectedYear(dropDownOptions[1].value)
        }
    }, []);

    useEffect(() => {
        getIRSReversePaymentData()
    }, [selectedYear]);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px search-form'
            show={show}
            onHide={handleClose}
            onKeyPress={(event) => {
                if (event.key === "Enter") {
                    reverseIRSPayments(event)
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .manual-override-form .w-lg-140px { width: 140px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.REVERSE.IRS.TAX.PAYMENTS.TITLE' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form manual-override-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>

                        {/* Fiscal Year */}
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 mw-100px' htmlFor='fiscal_year' data-tooltip-id="PEOPLE.DETAIL.MODAL.IRSPAYMENTS.LABEL.FISCALYEAR">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.REVERSE.IRS.TAX.PAYMENTS.LABEL.FISCAL.YEAR' })}</label>
                            <div className='col-lg-3'>
                                <Select
                                    value={selectedYear !== undefined ? yearOptions.filter((ss) => ss.value === selectedYear):""}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                    })}       
                                    options={yearOptions}
                                    onChange={(e) => {
                                       setSelectedYear(e.value)
                                    }}
                                    placeholder='Select'
                                    styles={reactSelectStyles}
                                />
                            </div>
                        </div>

                        {/* Grid */}
                        <div style={containerStyle}>
                            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                <AgGridReact
                                    ref={irsPaymentsGrid}
                                    defaultColDef={defaultColDef}
                                    rowData={rowData}
                                    columnDefs={columnDefs} 
                                    rowStyle={{ fontSize: '12px' }}
                                    suppressScrollOnNewData={true}
                                    headerHeight={getNarrowHeaderHeight}
                                    rowHeight={getNarrowRowHeight}
                                    overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading IRS Payments...</span>'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>

                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} autoFocus>
                            {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.REVERSE.IRS.TAX.PAYMENTS.BUTTON.CANCEL' })}
                        </button>

                        <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={(e) => reverseIRSPayments(e)}>
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.MODAL.REVERSE.IRS.TAX.PAYMENTS.BUTTON.OK' })}
                        </button>
                    </div>
                </form>
            </div>

            
        </Modal>,
        modalsRoot
    )
}

export default ReverseTaxPaymentToIRS