import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useOrderOfGoContext } from '../OrderOfGoContext';
import { getExtraNarrowHeaderHeight, getNarrowRowHeight, updateAGGridWithoutFlickering } from '../../../../modules/sgl-utils/agGridHelpers';

const OrderedTripsGrid = ({ rowData }) => {
    const intl = useIntl();
    const { orderBy, setManualOrderedTrips, setAllTrips, orderedTripGridApi, setOrderedTripGridApi, setOOGStatus, orderedTripColumnApi, setOrderedTripColumnApi } = useOrderOfGoContext()
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const containerStyle = useMemo(() => ({ width: '100%', minHeight: '200px' }), []);
    const gridRef = useRef();

    const columnDefs = [
        {   
            field: 'order_of_go', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.ORDER"}), 
            cellClass: 'text-center',
            maxWidth: 90,
        },
        {   
            field: 'Entry.number', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.ENTRY"}), 
            cellClass: 'text-center',
            maxWidth: 90,
        },
        {   
            field: 'Class.number', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.CLASS"}), 
            cellClass: 'text-center',
            maxWidth: 80,
        },
        {   
            field: 'Entry.horse', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.HORSE"}), 
            flex: 1,
        },
        {   
            field: 'rider_name', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.RIDER"}), 
            flex: 1
        },
        { 
            field: 'rider_status', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.RIDERSTATUS"}), 
            maxWidth: 100
        },
        { 
            field: 'Entry.trainer', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.TRAINER"}), 
            flex: 1
        },
    ]

    useEffect(() => {
        if (orderedTripGridApi) {
            updateAGGridWithoutFlickering(gridRef, rowData)
        }
    }, [orderBy, orderedTripGridApi, rowData])

    function getRowStyle(params) {
        let backgroundColor = "#FFFFFF"

        if (params.node.data.web_signup) {
            backgroundColor = "#00FF00"
        } else {
            if (orderBy === "Trainer" && params.node.data.trainerRowColor) {
                backgroundColor = params.node.data.trainerRowColor
            } else if (orderBy === "Rider" && params.node.data.riderRowColor) {
                backgroundColor = params.node.data.riderRowColor
            } 
        }
        return { backgroundColor }
    };

    const onGridReady = (params) => {
        setOrderedTripGridApi(params.api);
        setOrderedTripColumnApi(params.columnApi)
    };

    const handleOnRowDragEnd = () => {
        // Get the total number of rows in the grid
        let totalRowCount = orderedTripGridApi.getDisplayedRowCount();

        // Iterate through each row index and retrieve the row data object
        const newOrderedAllTrips = []
        for (let i = 0; i < totalRowCount; i++) {
            let displayedRowData = orderedTripGridApi.getDisplayedRowAtIndex(i).data
            displayedRowData.order_of_go = i + 1
            newOrderedAllTrips.push(displayedRowData)
        }

        setAllTrips(newOrderedAllTrips)
        setManualOrderedTrips(newOrderedAllTrips)
        setOOGStatus("Order of Go is set temporarily and will not be set until saved")

        // clear column state (sort order)
        orderedTripColumnApi.resetColumnState()
    }

    return (
        <>
            <div className="col">
                <div className="row">
                    <fieldset>
                        <legend className='mt-2 fs-5 py-1 fw-bold d-flex justify-content-between align-items-center'>
                            <span>{ intl.formatMessage({id: 'ORDEROFGO.LABEL.ORDEREDTRIPS'}) }</span>
                            <label data-tooltip-id="ORDEROFGO.DETAIL.SECTION.ORDEREDTRIPS.LABEL.TOTALTRIPS">
                                { intl.formatMessage({id: 'ORDEROFGO.LABEL.TOTALTRIPS'}) }
                                {": "}
                                { rowData.length }
                            </label>
                        </legend>
                    </fieldset>
                </div>
            </div>
            <div className="col d-flex justify-content-between">
                <div style={containerStyle}>
                    <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                            ref={gridRef}
                            defaultColDef={{
                                cellStyle: { 'paddingLeft': 0, 'paddingRight': 0 },
                                headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header',
                                sortable: true, 
                                suppressMenu: true, 
                                wrapHeaderText: true,
                                autoHeaderHeight: true,
                            }}
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            rowHeight={getNarrowRowHeight}
                            headerHeight={getExtraNarrowHeaderHeight}
                            rowDragEntireRow={true}
                            rowDragManaged = {true}
                            suppressMoveWhenRowDragging = {false}
                            animateRows = {true}      
                            rowSelection={'multiple'}
                            rowDragMultiRow={true}
                            onDragStopped={handleOnRowDragEnd}
                            enableRangeSelection={false}
                            stopPropagationOnGhostParent={true}
                            getRowStyle={params => getRowStyle(params)}
                            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while ordered list is being loaded..</span>'}
                            domLayout='autoHeight'
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrderedTripsGrid