import { AgGridReact } from "ag-grid-react"
import { useMemo } from "react";
import { defaultColDef, getNarrowHeaderHeight, getNarrowRowHeight  } from "../../../../modules/sgl-utils/agGridHelpers";
import { useIntl } from "react-intl";

const AllEntriesGrid = ({ setGridRef, onSelectionChanged }) => {
    const intl = useIntl()
    const containerStyle = useMemo(() => ({ width: '100%', height: '405px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    const columnDefs = [
        { 
            field: 'Entry.number',
            headerName: intl.formatMessage({ id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.VERIFYROSTER.GRID.ALLENTRIES" }),
            flex: 1
        }
    ]

    return (
        <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                <AgGridReact
                    defaultColDef={defaultColDef}
                    rowData={[]}
                    columnDefs={columnDefs} 
                    onGridReady={params =>  { 
                        setGridRef(params.api)
                    }}
                    gridOptions={{ 
                        rowSelection: 'multiple' 
                    }}
                    onSelectionChanged={onSelectionChanged}
                    rowDragEntireRow={true}
                    suppressMoveWhenRowDragging={true}
                    headerHeight={getNarrowHeaderHeight}
                    rowHeight={getNarrowRowHeight}
                />
            </div>
        </div>
    )
}

export default AllEntriesGrid