import { useMemo } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic'
import { renderCheckBox } from '../../../../modules/sgl-utils/agGridHelpers'
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters'

type Props = {
    show: boolean
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const RollForwardForm = ({ show, handleClose }: Props) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()

    const columnDefs = [
        { field: 'checked_off', headerName: "",  cellRenderer: renderCheckBox, cellClass: 'text-center',  },
        { field: 'entry_number', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.ENTRYNUMBER' }) },
        { field: 'show_name', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.SHOWNAME' }),   },
        { field: 'show_end_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.SHOWENDDATE' }), cellClass: 'text-center',  },
        { field: 'balance', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.BALANCE' }),valueFormatter: currencyFormatter, cellClass: 'text-end',  },
    ]

    const rowData = [
        { checked_off: true, entry_number: 706, show_name: "2020 ESP Fall I (#261900)", show_end_date: '10/11/2020', balance: '100.00' },
    ];

    const containerStyle = useMemo(() => ({ width: '100%', height: '200px' }), []);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-800px search-form'
            show={show}
            onHide={handleClose}
            onKeyPress={(event: any) => {
                if (event.key === "Enter") {
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.TITLE.ROLLFORWARD' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form ' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1'>
                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.INFOSTART' })}
                                DIABLE ROUGE DE PICOBELLO Z/SAMANTHA WIGHT
                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.INFOEND' })}
                                </label>
                        </div>
                        <div className='row mb-2'>
                            <OutputListingGridStatic
                                area ={"Entries - Account RollForward"}
                                columnDefs={columnDefs}
                                rowData={rowData}
                                containerStyle={containerStyle}
                            ></OutputListingGridStatic>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-12 col-form-label fs-5 py-1'>0 {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.CREDITBALANCESTOTALING' })} $0.00</label>
                            <label className='col-lg-12 col-form-label fs-5 py-1'>1 {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.PASTBALANCESTOTALING' })} $100.00</label>
                            <label className='col-lg-12 col-form-label fs-5 py-1'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.WARNING' })}</label>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} autoFocus>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                        </button>
                        <button type='button' className='btn btn-sm btn-dark fw-bold'>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.OK' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { RollForwardForm }