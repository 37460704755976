import { useEffect, useState } from 'react';
import { useAnnouncerToolContext } from './AnnouncerToolContext';
import axios from "axios";
import { useAlert } from '../../../modules/sgl-utils/DialogsProvider';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL


const CheckboxRenderer = (props) => {
  const { currentShowID, customerID, currentClassGroup, setGoneStatus, setUpcomingTrips, isTimeDifferenceValid, setLastIngateActivity } = useAnnouncerToolContext()
  const alertDialog = useAlert();
  const [isScratchRequestPending, setIsScratchRequestPending] = useState(false)
  const [isCheckedInRequestPending, setIsCheckedInRequestPending] = useState(false)

  const handleChange = (event, props) => {
    const column = props?.column;
    const rowData = props?.params?.data;
    const checked = event.target.checked ? true : false;
    
    if (column === 'gone_in') {
      props.params.node.setDataValue('gone_in', checked)
      setGoneStatus(rowData.class_id, rowData.entryxclasses_id, checked)
      isTimeDifferenceValid(checked)

    } else if(column === 'scratch_trip') {
      if(rowData.trip_verified){ //Checked for verified trips
        alertDialog({message: "A verified entry cannot be scratched."})
      }else{
        setIsScratchRequestPending(true)
        props.params.node.setDataValue('scratch_trip', checked)
        axios.patch(`${NEST_API_URL}/ingate-announcer/setScratchStatusForTrip`, {
            show_id: currentShowID,
            customer_id: customerID,
            class_group_id: currentClassGroup.class_group_id,
            class_id: rowData.class_id,
            entryxclasses_id: rowData.entryxclasses_id,
            scratch_trip: checked
        })
        .then(response => {
            if (response.data.success) {
                setUpcomingTrips(response?.data?.upcomingTrips)   
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
          setIsScratchRequestPending(false)
        });
      }
    } else if(column === 'checked_in') {
      setIsCheckedInRequestPending(true)
      props.params.node.setDataValue('checked_in', checked)
      axios.patch(`${NEST_API_URL}/ingate-announcer/setCheckedInForTrip`, {
          show_id: currentShowID,
          customer_id: customerID,
          class_group_id: currentClassGroup.class_group_id,
          class_id: rowData.class_id,
          entryxclasses_id: rowData.entryxclasses_id,
          checked_in: checked
      })
      .then(response => {
          if (response.data.success) {
              setUpcomingTrips(response?.data?.upcomingTrips)   
          }
      })
      .catch((error) => {
          if (error.response) {
              alertDialog({message: error.response.data.error});
          }
      })
      .finally(() => {
        setIsCheckedInRequestPending(false)
      });
    }

    if(column === 'gone_in')
    {
      setLastIngateActivity(checked ? 'Trip Gone' : 'Trip Not Gone')
    }else if(column == 'scratch_trip')
    {
      setLastIngateActivity(checked ? 'Trip Scratched' : 'Trip Not Scratched')
    }
  };  
  
  const handleChecked = (props) => {
    const column = props?.column;
    const rowData = props?.params?.data;

    if(column == 'gone_in'){
      return rowData?.gone_in ?? false;
    }
    else if(column == 'scratch_trip'){
      return rowData?.scratch_trip ?? false;
    }
    else if(column == 'checked_in'){
      return rowData?.checked_in ?? false;
    }
    
    return false;
  }

  // disabled gone_in checkbox: 
  // 1- when upcoming trip is scratched trip
  // 2- when upcoming trip is gone is checked and user again click on it 
  // 3- when stagger trip is gone is unchecked and user again click on it 
  // 4- when scratched trip webservice is called till its response is received 
  // 5- when checked_in trip webservice is called till its response is received
  const handleDisabled = (props) => {
    const column = props?.column;
    const rowData = props?.params?.data;

    if(column == 'gone_in'){
      if(rowData?.scratch_trip && !rowData?.gone_in){ //trip marked as scratched AND not gone
        return true;
      }
      else if(props?.grid == 'stagger' && !rowData?.gone_in){ //trip marked as ungo from completed trips
        return true;
      }
      else if(props?.grid == 'upcomingTrips' && rowData?.gone_in){ //trip set as gone from upcoming trips
        return true;
      }
    }
    else if(column == 'scratch_trip'){
      return isScratchRequestPending;
    }
    else if(column == 'checked_in'){
      return isCheckedInRequestPending;

    }

    return false;
  }
  
  return (
    <input
      type="checkbox"
      checked={handleChecked(props)} 
      onChange={(e) => handleChange(e, props)}
      disabled={handleDisabled(props)}
    />
  );
};

export default CheckboxRenderer;
