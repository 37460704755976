import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { useFormContext, Controller } from 'react-hook-form'
import Select from "react-select";
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls'
import { PatternFormat} from 'react-number-format';
import moment from 'moment'
import {useState, useEffect} from "react"
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../redux/hooks'
import { useAlert } from '../../../modules/sgl-utils/DialogsProvider'
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../modules/sgl-utils/SglFunctions'

const modalsRoot = document.getElementById('root-modals') || document.body

const GeneralPaymentMethodPopup = ({ handleClose, handleSubmit, paymentMethodPopupEditMode, peopleMetaData, getPaymentMethodsList }) => {
    const intl = useIntl()
    const methods = useFormContext()
    const people_id = methods.getValues('people.people_id')
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const alertDialog = useAlert()

    // state management for lock icon in edit mode
    const [cardNumberLockState, setCardNumberLockState] = useState("lock")
    const [expirationLockState, setExpirationLockState] = useState("lock")
    const [listOfStates, setlistOfStates] = useState([])
    const [listOfCountries, setlistOfCountries] = useState([])
    methods.watch("newPaymentMethod.last_used_date")

    useEffect(() => {
        axios.get( process.env.REACT_APP_NEST_API_URL + '/payments/payment_GetStatesCountries', {
            params: {
            customer_id: customerId,                                
            }
        }).then(states_countries => { 
            setlistOfCountries(states_countries.data.countries)
            setlistOfStates(states_countries.data.states)
        })
    }, [])

    // Copied people address to payment method form
    const copyAddressFromRTO = () => { 
        methods.setValue("newPaymentMethod.address_1", methods.getValues("people.address"))
        methods.setValue("newPaymentMethod.address_2", methods.getValues("people.address_two"))
        methods.setValue("newPaymentMethod.city", methods.getValues("people.city"))
        methods.setValue("newPaymentMethod.email", methods.getValues("people.email_address"))
        methods.setValue("newPaymentMethod.phone", methods.getValues("people.phonenumber"))
        let preselect_country = listOfCountries.find(country => {
            return country.label.toLowerCase() == methods.getValues("people.country").toLowerCase()
        })
        methods.setValue("newPaymentMethod.country", preselect_country.value)
        methods.setValue("newPaymentMethod.state", methods.getValues("people.state"))
        methods.setValue("newPaymentMethod.zip", methods.getValues("people.postal_code"))
    }

    const savePaymentMethod = (event) => {
        event.preventDefault()
        
        const data = methods.getValues("newPaymentMethod")
        data.card_number = data.card_lastfour

        loadingSpinnerBtnWait(event)

        return axios
            .post( process.env.REACT_APP_NEST_API_URL + '/payment-methods/savePaymentMethod', {
                params: {
                    peopleId: people_id,
                    customer_id: customerId,
                    data
                }
            })
            .then(async (response) => {
                await getPaymentMethodsList()
                handleClose()
                loadingSpinnerBtnRelease(event)
            })
            .catch((reason) => {
                // if errors are in the form of Array
                if(Array.isArray(reason.response.data.error)) { 
                    alertDialog({message: reason.response.data.error.join(`<br/>`), title: 'error'});
                } else {
                    alertDialog({message: reason.response.data.error, title: 'error'})
                }
                loadingSpinnerBtnRelease(event)
            })
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-650px search-form'
            show={true}
            onHide={handleClose}
            onKeyDown={(event) => { //replace onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    let okButton = document.getElementById('Ok')
                    okButton?.click()
                }
                if (event.key === 'Escape') {
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">
                {
                    paymentMethodPopupEditMode ?  
                        intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.EDITCREDITCARD' })   
                    :
                        intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.ADDCREDITCARD' })
                }
                </h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3'> 
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body'>
                        <div className='row mb-2'>
                            <label 
                                className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px pe-0'
                                htmlFor='creditCardNumber'
                                data-tooltip-id="PEOPLE.DETAIL.MODAL.ADDCREDITCARD.LABEL.CREDITCARDNUMBER">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.CREDITCARDNUMBER' }) } 
                                <span className='px-1 text-red'>*</span>
                            </label>
                            <div className='col-lg-8'>
                                <Controller
                                    name="newPaymentMethod.card_lastfour"
                                    rules={{ required: true }}
                                    render={({ field: { name, onChange, value} }) => (
                                        <input
                                            id="creditCardNumber"
                                            name={name}
                                            value={
                                                /* Apply formatting only if
                                                    - in edit mode
                                                    - card number field is locked
                                                */
                                                paymentMethodPopupEditMode && cardNumberLockState === "lock" ? `XXXX-XXXX-XXXX-${value}` : value
                                            }
                                            onChange={(event)=>{
                                                onChange(event.target.value)
                                            }}
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.CREDITCARDNUMBER' })}
                                            disabled={paymentMethodPopupEditMode && cardNumberLockState === "lock"}
                                            autoFocus
                                            tabIndex={1}
                                        />
                                    )}
                                />
                            </div>
                            {/* Display lock icons only when in edit mode */
                                paymentMethodPopupEditMode &&
                                <label className='col-lg-1'>
                                    <i 
                                        className={`fa-solid fa-${cardNumberLockState} py-3`}
                                        onClick={() => {
                                            setCardNumberLockState("lock-open")
                                            methods.setValue("newPaymentMethod.card_lastfour", "")
                                        }}
                                    ></i>
                                </label>
                            }
                        </div>
                        <div className='row mb-2'>
                            <label 
                                className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px pe-0'
                                htmlFor='nameOnCard'
                                data-tooltip-id="PEOPLE.DETAIL.MODAL.ADDCREDITCARD.LABEL.NAMEONCARD">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.NAMEONCARD' }) }
                                <span className='px-1 text-red'>*</span>
                            </label>
                            <div className='col-lg-8'>
                                <input
                                    {...methods.register("newPaymentMethod.name_on_card", { required: true })}
                                    id="nameOnCard"
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.NAMEONCARD' })}
                                    tabIndex={2}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label 
                                className='col-form-label fs-5 py-1 w-lg-125px'
                                htmlFor='expiration'
                                data-tooltip-id="PEOPLE.DETAIL.MODAL.ADDCREDITCARD.LABEL.EXPIRATION">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.EXPIRATION' }) }
                                <span className='px-1 text-red'>*</span>
                            </label>
                            <div className='col-lg-3'>
                                <Controller
                                    control={methods.control}
                                    name="newPaymentMethod.card_exp"
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value} }) => (
                                        <PatternFormat
                                            valueIsNumericString={true}
                                            id="expiration"
                                            format="##/##"
                                            value={methods.getValues('newPaymentMethod.card_exp') === '' ? '' : value}
                                            onValueChange={(e)=>{
                                                onChange(e.value)
                                            }}
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.EXPIRATIONFORMAT' })}
                                            disabled={paymentMethodPopupEditMode && expirationLockState === "lock"}
                                            tabIndex={3}
                                        />
                                    )}
                                />
                            </div>
                            {/* Display lock icons only when in edit mode */
                                paymentMethodPopupEditMode &&
                                <label className='col-lg-1'>
                                    <i 
                                        className={`fa-solid fa-${expirationLockState} py-3 pxl-0`}
                                        onClick={() => setExpirationLockState("lock-open")}
                                    ></i>
                                </label>
                            }
                        </div>
                        <div className='row mb-2'>
                            <label 
                                className='col-form-label fs-5 py-1 w-lg-125px'
                                htmlFor='description'
                                data-tooltip-id="PEOPLE.DETAIL.MODAL.ADDCREDITCARD.LABEL.DESCRIPTION">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.DESCRIPTION' }) }
                            </label>
                            <div className='col-lg-8'>
                                <input
                                    {
                                        ...methods.register("newPaymentMethod.description", { required: false })
                                    }
                                    id="description"
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.DESCRIPTION' })}
                                    tabIndex={4}
                                    />
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-3'>
                            <button className='btn btn-sm btn-secondary fw-bold' onClick={copyAddressFromRTO} type="button" tabIndex={5} data-tooltip-id="PEOPLE.DETAIL.MODAL.ADDCREDITCARD.BUTTON.COPYADDRESSFROMRTO">
                                <i className="fa-solid fa-copy"></i>
                                {intl.formatMessage({ id: "FORM.BUTTON.PEOPLE.MODAL.LABEL.COPYADDRESSFROMRTO" })}
                            </button>
                        </div>
                    <fieldset className='mb-2'>
                        <legend className="fs-5 fw-bold col-lg-12">
                            { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.HEADING.BILLINGADDRESS' }) }
                        </legend>
                    </fieldset>
                    <div className='row mb-2'>
                            <label 
                                className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px'
                                htmlFor='addressOneForCard'
                                data-tooltip-id="PEOPLE.DETAIL.MODAL.ADDCREDITCARD.SECTION.BILLINGADDRESS.LABEL.ADDRESS1">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.ADDRESSONE' }) }
                            </label>
                            <div className='col-lg-9'>
                                <input
                                    {...methods.register("newPaymentMethod.address_1", { required: false })}
                                    id="addressOneForCard"
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.ADDRESSONE' })}
                                    tabIndex={6}
                                    />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label 
                                className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px'
                                htmlFor='addressTwoForCard'
                                data-tooltip-id="PEOPLE.DETAIL.MODAL.ADDCREDITCARD.SECTION.BILLINGADDRESS.LABEL.ADDRESS2">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.ADDRESSTWO' }) }
                            </label>
                            <div className='col-lg-9'>
                                <input
                                    {...methods.register("newPaymentMethod.address_2", { required: false })}
                                    id="addressTwoForCard"
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.ADDRESSTWO' })}
                                    tabIndex={7}
                                    />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label 
                                className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px'
                                htmlFor='cityForCard'
                                data-tooltip-id="PEOPLE.DETAIL.MODAL.ADDCREDITCARD.SECTION.BILLINGADDRESS.LABEL.CITY">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.CITY' }) }
                            </label>
                            <div className='w-lg-125px'>
                                <input
                                    {...methods.register("newPaymentMethod.city", { required: false })}
                                    id="cityForCard"
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.CITY' })}
                                    tabIndex={8}
                                    />
                            </div>
                            <label 
                                className='col-form-label fs-5 py-1 w-lg-50px'
                                htmlFor='stateForCard'
                                data-tooltip-id="PEOPLE.DETAIL.MODAL.ADDCREDITCARD.SECTION.BILLINGADDRESS.LABEL.STATE">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.STATE' }) }
                            </label>
                            <div className='w-lg-125px'>
                                {
                                    listOfStates &&
                                    <Controller
                                        name='newPaymentMethod.state'
                                        render={({
                                            field: { onChange, value, name },
                                        }) => (
                                            <Select
                                                id="stateForCard"
                                                placeholder="Select"
                                                options={listOfStates}
                                                value={
                                                    listOfStates.find(
                                                        state =>  state.value === value || state.abbreviation === value
                                                    )
                                                }
                                                name={name}
                                                isSearchable={true}
                                                onChange={(state) => {
                                                    onChange(state.label);
                                                    methods.setValue("newPaymentMethod.state", state.value)
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                                tabIndex={9}
                                            />    
                                        )}
                                    /> 
                                }
                            </div>
                            <label 
                                className='col-form-label fs-5 py-1 w-lg-50px'
                                htmlFor='zipForCard'
                                data-tooltip-id="PEOPLE.DETAIL.MODAL.ADDCREDITCARD.SECTION.BILLINGADDRESS.LABEL.ZIP">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.ZIP' }) }
                            </label>
                            <div style={{width: "118px"}}>
                                <input
                                    {...methods.register("newPaymentMethod.zip", { required: false })}
                                    id="zipForCard"
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.ZIP' })}
                                    tabIndex={10}
                                    />
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <label 
                                className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px'
                                htmlFor='countryForCard'
                                data-tooltip-id="PEOPLE.DETAIL.MODAL.ADDCREDITCARD.SECTION.BILLINGADDRESS.LABEL.COUNTRY">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.COUNTRY' }) }
                            </label>
                            <div className='col-lg-3'>
                            {
                                    listOfCountries &&
                                    <Controller
                                        name='newPaymentMethod.country'
                                        render={({
                                            field: { onChange, value, name },
                                        }) => (
                                            <Select
                                                id="countryForCard"
                                                placeholder="Select"
                                                options={listOfCountries}
                                                value={
                                                    listOfCountries.find(
                                                        country =>  { 
                                                            return country.value === value
                                                        }
                                                    )
                                                }
                                                name={name}
                                                isSearchable={true}
                                                onChange={(country) => {
                                                    onChange(country.label);
                                                    methods.setValue("newPaymentMethod.country", country.value)
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                                tabIndex={11}
                                            />    
                                        )}
                                    /> 
                                }
                            </div>
                            <label 
                                className='col-lg-2 col-form-label fs-5 py-1 w-lg-50px'
                                htmlFor='phoneForCard'
                                data-tooltip-id="PEOPLE.DETAIL.MODAL.ADDCREDITCARD.SECTION.BILLINGADDRESS.LABEL.PHONE">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.PHONE' }) }
                            </label>
                            <div style={{width: "262px"}}>
                                <Controller
                                    control={methods.control}
                                    name="newPaymentMethod.phone"
                                    render={({ field: { onChange, value} }) => (
                                        <PatternFormat
                                            id="phoneForCard"
                                            format="(###) ###-####"
                                            value={methods.getValues('newPaymentMethod.phone') === '' ? '' :typeof value == 'string' ? Number(value?.match(/\d+/g)?.join('')): value }
                                            onValueChange={(e)=>{
                                                onChange(e.value)
                                            }}
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.PHONE' })}
                                            tabIndex={12}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label 
                                className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px'
                                htmlFor='emailForCard'
                                data-tooltip-id="PEOPLE.DETAIL.MODAL.ADDCREDITCARD.SECTION.BILLINGADDRESS.LABEL.EMAIL">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.EMAIL' }) }
                            </label>
                            <div className='col-lg-9'>
                                <input
                                   {...methods.register("newPaymentMethod.email", { required: false })}
                                    id="emailForCard"
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.EMAIL' })}
                                    tabIndex={13}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        {
                            paymentMethodPopupEditMode && <div className='col-lg-9'>
                                <label className='col-lg-4 col-form-label fs-6 py-1'>
                                    Last used: { 
                                        moment(methods.getValues("newPaymentMethod.last_used_date")).isValid()
                                        ?
                                            moment(methods.getValues("newPaymentMethod.last_used_date")).format("MM/DD/YYYY")
                                        :
                                            "N/A"
                                    }
                                </label>
                                </div>
                        }
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={14}>
                            {intl.formatMessage({ id: 'FORM.BUTTON.PEOPLE.MODAL.LABEL.CANCEL' })}
                        </button>
                        <button type='button' className='d-flex align-items-center justify-content-center btn btn-sm btn-dark fw-bold spinner spinner-dark spinner-right' id ="Ok" onClick={(event) => savePaymentMethod(event)} tabIndex={15}
                        >
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                           {intl.formatMessage({ id: 'FORM.BUTTON.PEOPLE.MODAL.LABEL.OK' })}
                        </button>
                    </div>
            </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { GeneralPaymentMethodPopup }