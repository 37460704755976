import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { useFormContext, FormProvider } from 'react-hook-form'
import {useState} from "react"

type Props = {
  show: boolean
  handleClose: () => void
  handleSubmit: (onVetHold: any, onVetHoldReason: any) => void
  onVetHold: string
  onVetHoldReason: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const GeneralPutOnHoldForm = ({ show, handleClose, handleSubmit, onVetHold, onVetHoldReason }: Props) => {
  const intl = useIntl()
  const methods = useFormContext()

  const [on_vet_hold, setOnVetHold] = useState(methods.getValues(onVetHold));
  const [on_vet_hold_reason, setOnVetHoldReason] = useState(methods.getValues(onVetHoldReason));


  const buttonCheck = () =>{
    handleSubmit(on_vet_hold, on_vet_hold_reason) 
    handleClose()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px search-form'
      show={show}
      onHide={handleClose}
      onKeyDown={(event:any) => {
        let isButtonFocused = false
        let activeElement = document.activeElement //gets the currently focussed element
        if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
            isButtonFocused = true;
        }
        if (!event.shiftKey && event.key === "Enter" && !isButtonFocused) {
            if(on_vet_hold && on_vet_hold_reason != ""){ // Do not allow enter key if an option hasn't been selected
              event.preventDefault()
              buttonCheck()
            }
        }
    }}
    >
      <div className='modal-header py-0 px-4'>
        <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.MODAL.TITLE.PUTONHOLD' })}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4'> 
          <form noValidate className='form' onSubmit={e => e.preventDefault()}>
            <div className='card-body p-0'>
              <div className='row mb-2'>
                <div className='col-lg-12 form-check-sm form-check-custom ps-lg-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='onVetHold'
                    value={on_vet_hold}
                    onChange={(e) => {setOnVetHold(e.target.checked)}}
                    autoFocus
                    tabIndex={1}
                  />
                  <label className='col-form-label mx-2 fs-5 py-1' htmlFor='onVetHold' data-tooltip-id="HORSE.DETAIL.MODAL.PUTONHOLD.LABEL.ONVETHOLD"> {intl.formatMessage({ id: 'FORM.INPUT.HORSES.MODAL.LABEL.ONVETHOLD' })}</label>
                </div>
              </div>
              <div className='row mb-2'>
                <label className='col-lg-12 col-form-label fs-5 py-1' htmlFor='on_vet_hold_reason' data-tooltip-id="HORSE.DETAIL.MODAL.PUTONHOLD.LABEL.REASON"> {intl.formatMessage({ id: 'FORM.INPUT.HORSES.MODAL.LABEL.REASON' })}</label>
                <div className='col-lg-12'>
                  <textarea
                    id="on_vet_hold_reason"
                    rows={5}
                    className='form-control form-control-sm mb-2 mb-lg-0 fs-6 py-1'
                    value={on_vet_hold_reason}
                    onChange={(e) => setOnVetHoldReason(e.target.value)}
                    tabIndex={2}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-3 px-0'>

              <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={3}>
                {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
              </button>
              <button type='button' className='btn btn-sm btn-dark fw-bold'
              id ="Ok"
              onClick = {buttonCheck}
              disabled = {!on_vet_hold || on_vet_hold_reason == ""}
              tabIndex={4}
              >
                {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.OK' })}
              </button>
            </div>
          </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { GeneralPutOnHoldForm }