import { useIntl } from 'react-intl'

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const useRunTinCheck = () => {
    const intl = useIntl()
    const {progressBar} = useProgress()
    const customerID = useAppSelector(state => state.showCompany.company_id)
    const { getOutputSelectionAreaIDs, getEventID } = useOutputContext();

    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const loadingOverlay = useLoadingOverlay()

    const performTinCheck = async () => {
        loadingOverlay({ show: true })
        //Get selected peopls ids
        getOutputSelectionAreaIDs()
        .then(res => { 
            if (res.length === 0) {
                loadingOverlay({ show: false })
                // error message if no rto selected
                alertDialog({ message: intl.formatMessage({ id: "RTO.QUICKACTION.RUN.TIN.CHECK.NO.RECORD.SELECTED"}) })
            }
            else{
                // Search for RTO that are not tin verified
                axios.post( NEST_API_URL + `/people/getTinUnverifiedRTO`, {
                    customer_id: customerID,
                    people_ids: res
                })
                .then((response) => {
                    if(response?.data?.success){
                        // get the number of rto that are not tin verified
                        let noOfRTOWithTinUnverified = response?.data?.people_ids?.length
                        let message = noOfRTOWithTinUnverified > 1 ? " RTO's ?" : " RTO ?"
                        if(noOfRTOWithTinUnverified > 0){
                            // ask user for confirmation
                            confirmDialog({ message: intl.formatMessage({ id: "RTO.QUICKACTION.RUN.TIN.CHECK.CONFIRM"}) + noOfRTOWithTinUnverified + message})
                            .then((choice) => {
                                if(choice){
                                    //Set progress bar
                                    const progressEventId = getEventID('run-tin-check')
                                    progressBar({ show: true, eventID: progressEventId, showProgress: 'inline', title: "Running Tin Check", timeElapsed: true })
                                    //Webservice to check tin verification and update RTO's data>>>
                                    axios.post( NEST_API_URL + `/people/rtoRunTinCheck`, {
                                        customer_id: customerID,
                                        people_ids: response?.data?.people_ids,
                                        progress_event_id: progressEventId
                                    }).then((res) => {
                                        // Do Nothing
                                    }).catch((error) => {
                                        if (error.response) {
                                            alertDialog({message: error.response.data.error});
                                        }
                                    }).finally(() => {
                                        progressBar({ show: false, eventID: progressEventId})
                                    })
                                    //Webservice to check tin verification and update RTO's data<<<
                                }
                            })
                        }
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        alertDialog({message: error.response.data.error});
                    }
                })
                .finally( () => {
                    loadingOverlay({ show: false })
                })
            }
        })
        .catch(() => loadingOverlay({ show: false }))
    }

    return performTinCheck
    
}

export default useRunTinCheck


