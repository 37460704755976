import {useState, useRef} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAppSelector } from "../../../../redux/hooks"
import axios from 'axios'
import { useOutputContext, useOutputContextUpdater } from '../../../../modules/output-listing/OutputListingContext'
import { useAlert, useLoadingOverlay, useProgress } from '../../../../modules/sgl-utils/DialogsProvider'
import { getArrayFromObject } from '../../../../modules/sgl-utils/SglFunctions'
import OverridesGrid from './OverridesGrid'

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
 
const SetMembershipOverridesVerifyDialog = ({show, handleClose, overrideData, existingOverrides, clearMemberbershipOverride, entries, closeMainDialog}) => {
    const intl = useIntl() 
    const currentShowId = useAppSelector(state => state.currentShow.show_id)
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const {getEventID} = useOutputContext()
    const outputContextUpdater = useOutputContextUpdater()
    const loadingOverlay = useLoadingOverlay()
    const {progressBar} = useProgress()
    const alertDialog = useAlert()
    const gridRef = useRef();
    const [totalOverrides, setTotalOverrides] = useState([existingOverrides.length])
    let gridMessage = clearMemberbershipOverride ? intl.formatMessage({ id: "ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.SELECT.STATEMENT.TOREMOVE" }) : intl.formatMessage({ id: "ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.SELECT.STATEMENT.TOADD" })
    gridMessage = gridMessage + overrideData.set_override_for + 's: '

    const applyManualOverride = () =>{
        let entityForUpdateOverride = []
        let action =  clearMemberbershipOverride? 'delete': 'add/update'
        let entryIds = []
        //Get all the ids for which override will be added/updated/deleted
        const allRowNodes = gridRef?.current?.api?.getModel().rowsToDisplay
        allRowNodes?.map((node)=>{
            if(node.data.apply_override || node.data.apply_override === undefined){
                entityForUpdateOverride.push(node.data)
            }
        })

        if(entityForUpdateOverride.length > 0){
            let entity_ids = getArrayFromObject('entity_id', entityForUpdateOverride, true)
            if(overrideData.set_override_for === 'Horse'){
                entries = entries.filter((entry) => entity_ids.includes(entry.horse_id))
            }else if(overrideData.set_override_for === 'Rider'){
                entries = entries.filter((entry) => entity_ids.includes(entry.rider_id))
            }else if(overrideData.set_override_for === 'Owner'){
                entries = entries.filter((entry) => entity_ids.includes(entry.entryowner_id))
            }else if(overrideData.set_override_for === 'Trainer'){
                entries = entries.filter((entry) => entity_ids.includes(entry.trainer_id))
            }
            entryIds = getArrayFromObject('entry_id', entries, true)
        }
        // show progress bar
        handleClose()
        closeMainDialog()
        let eventID = getEventID("set-membership-override")
        progressBar({ show: true, eventID, showProgress: 'inline', title: intl.formatMessage({ id: "ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDES.PROGRESSBAR.MESSAGE" }) }) 
        axios.post(`${NEST_API_URL}/entries/applyBulkOverride`, {
            customer_id: customerId,
            event_id: eventID,
            show_id: currentShowId,
            action,
            entry_ids: entryIds,
            entities: entityForUpdateOverride,
            override_data: overrideData
        })
        .then(response => {
            if(response?.data?.success){
                
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            progressBar( {show: false, eventID} )
            loadingOverlay({ show: false })
            outputContextUpdater({action: 'refresh'})
        })
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-800px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    applyManualOverride()
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'MODAL.MANUALOVERRIDE.TITLE.MANUALOVERRIDE' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='row mb-2'>
                            <label className='col-lg-5 col-form-label fs-5 py-1 fw-bold'>{intl.formatMessage({ id: "ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.ORGANIZATION" })}</label>
                            <label className='col-lg-7 col-form-label fs-5 py-1'>{overrideData?.organization_code}</label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-5 col-form-label fs-5 py-1 fw-bold'>{intl.formatMessage({ id: "ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.OVERRIDEDATE" })}</label>
                            <label className='col-lg-7 col-form-label fs-5 py-1'>{overrideData?.inactive_override_date}</label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-5 col-form-label fs-5 py-1 fw-bold'>{intl.formatMessage({ id: "ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.OVERRIDEREASON" })}</label>
                            <label className='col-lg-7 col-form-label fs-5 py-1'>{overrideData?.organization_code === 'EC' ? overrideData?.ec_override_reason_label : overrideData?.inactive_override_reason}</label>
                        </div>
                    </div>
                
                    <div className='col-lg-6'>
                        <div className='row mb-2'>
                            <label className='col-lg-7 col-form-label fs-5 py-1 fw-bold'>{intl.formatMessage({ id: "ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.ROLE" })}</label>
                            <label className='col-lg-5 col-form-label fs-5 py-1'>{overrideData?.set_override_for}</label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-7 col-form-label fs-5 py-1 fw-bold'>{intl.formatMessage({ id: "ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.OVERRIDEFORTHISSHOWONLY" })}</label>
                            <label className='col-lg-5 col-form-label fs-5 py-1'>{overrideData?.this_show_only ? intl.formatMessage({ id: "ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.YES" }) : intl.formatMessage({ id: "ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.NO" })}</label>
                        </div>
                    </div>
                </div>

                <div className='row mb-2'>
                    <label className='col-lg-7 col-form-label fs-5 py-1 fw-bold'>{gridMessage}</label>
                </div>

                <OverridesGrid data={existingOverrides} gridRef={gridRef} totalOverrides={totalOverrides} setTotalOverrides={setTotalOverrides} tabIndex={1}/>

                <div className='card-footer d-flex justify-content-end py-3 px-0'>
                    <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={handleClose} tabIndex={2}>
                        {intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.BUTTON.CANCEL' })}
                    </button>

                    <button type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={() => applyManualOverride()} disabled={totalOverrides <= 0 ? true: false} tabIndex={3}>
                        <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.BUTTON.APPLY' })}
                    </button>
                </div>
            </div>
        </Modal>
    ,modalsRoot
    )
}

export default SetMembershipOverridesVerifyDialog