import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import ClassRuleAttributesGrid from './AddRuleToClasses/ClassRuleAttributesGrid';
import axios from 'axios';
import { KTSVG } from '../../../../_metronic/helpers';
import "flatpickr/dist/themes/material_blue.css";
import Select from 'react-select';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const AddRuleToClasses = ({ show, handleClose }) => {
    const intl = useIntl() 
    const loadingOverlay = useLoadingOverlay()
    const {progressBar} = useProgress()
    const alertDialog = useAlert()
    const { getOutputSelectionAreaIDs, getEventID } = useOutputContext();
    const customerId = useAppSelector(state=> state.showCompany.company_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id);

    const [gridRef, setGridRef] = useState()
    const [classRules, setClassRules] = useState([])
    const [selectedRule, setSelectedRule] = useState(null)
    const [classIds, setClassIds] = useState([])
    const [updateMessage, setUpdateMessage] = useState('')

    // Metadata Call
    useEffect(() => {
        if (show && gridRef) {
            loadingOverlay({ show: true })
            getOutputSelectionAreaIDs()
            .then(async res => {
                if (res.length === 0) {
                    alertDialog({ message: 'Please select class(es) to apply Rules.' })
                    loadingOverlay({ show: false })
                    handleClose()
                } else {
                    setClassIds(res)
                    const response = await axios.get( NEST_API_URL + `/classes/getAddRulesToClassesMetadata?customer_id=${customerId}&show_id=${currentShowID}&action=add`)
                    if (response.data.classRules.length > 0) {
                        setClassRules(response.data.classRules)
                        const firstRecord = response.data.classRules[0]
                        setSelectedRule({ label: firstRecord.rule, value: firstRecord.class_rule_id, ...firstRecord})
                        gridRef.setRowData(firstRecord.rowData)
                    }

                    loadingOverlay({ show: false })
                }
            })
            
        }
    }, [show, gridRef])

    const applyRules = async () => {
        setUpdateMessage("")

        const eventID = getEventID('add-rule-to-classes')
        try {
            progressBar({ show: true, title: "Apply Rule to Classes", eventID, timeElapsed: true })
            let res = await axios.post( NEST_API_URL + `/classes/bulkAddRuleToClasses`, {
                customer_id: customerId,
                class_rule: {
                    classRuleId: selectedRule.class_rule_id,
                    name: selectedRule.rule
                },
                event_id: eventID,
                class_ids: classIds
            })
            setUpdateMessage(res?.data?.message)

            progressBar({ show: false, eventID })
        } catch (reason) {
            progressBar({ show: false, eventID })
        }
    }

    const onClose = () => {
        handleClose()
    }
    
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px'
            show={show}
            onHide={onClose}
            backdrop='static'
            onKeyDown={(event) => { //replace onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) {
                    applyRules()
                }
                if(event.key === 'Escape'){
                    onClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'Classes.QuickAction.AddRuleToClasses' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='card-body p-0 px-2'>
                    <div className='row mb-2'>
                        <label className='col-lg-3 col-form-label fs-5 py-1 fw-bold' htmlFor="selectRule" data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDRULETOCLASSES.LABEL.ADDRULE">
                            {intl.formatMessage({ id: 'Classes.QuickAction.AddRuleToClasses.Label.AddRule' })}
                        </label>
                        <div className='col'>
                            <Select
                                type="text"
                                inputId="selectRule" 
                                theme={theme => ({...theme, borderRadius: 'none'})}
                                value={selectedRule}
                                onChange={e => { 
                                    gridRef.setRowData(e.rowData)
                                    setSelectedRule(e)
                                }}
                                options={classRules.map(cr => ({ label: cr.rule, value: cr.class_rule_id, ...cr}))}
                                autoFocus
                                tabIndex={1}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <label className='col-lg-3 col-form-label fs-5 py-1 fw-bold' data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDRULETOCLASSES.LABEL.RULETYPE">
                            {intl.formatMessage({ id: 'Classes.QuickAction.AddRuleToClasses.Label.RuleType' })}
                        </label>
                        <label className='col col-form-label fs-5 py-1'>
                            { selectedRule ? selectedRule.ClassRuleType.type : '' }
                        </label>
                    </div>

                    <div className='row mb-2'>
                        <ClassRuleAttributesGrid 
                            setGridRef={setGridRef}
                            tabIndex={2}
                        />
                    </div>
                    
                </div>
                {/* <div className='card-footer d-flex justify-content-end py-3 px-2'> */}
                <div className='card-footer d-flex justify-content-between py-3 px-0'>
                    <div className='label-left-align mw-350px' style={{fontWeight: 'bold'}}>
                            { updateMessage ? <label className='text-green fw-bold'>{updateMessage}</label> :null }
                    </div>
                    <div className='buttons-right-align'>
                        <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={3}>
                            {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CLOSE" })}
                        </button>

                        <button 
                            type="button" 
                            className='btn btn-sm btn-dark fw-bold text-uppercase'
                            onClick={applyRules}
                            tabIndex={4}
                            data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDRULETOCLASSES.LABEL.APPLY"
                            >
                            {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.APPLY" })}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default AddRuleToClasses