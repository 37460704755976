import { Modal } from "react-bootstrap"
import { createPortal } from "react-dom"
import { useIntl } from "react-intl"
import { KTSVG } from "../../../../../_metronic/helpers"
import { useEffect, useMemo, useRef, useState } from "react"
import { useAppSelector } from "../../../../redux/hooks"
import axios from "axios"
import { reactSelectStyles } from "../../../../modules/sgl-utils/fieldControls"
import Select from "react-select"
import OutputListingGridStatic from "../../../../modules/output-listing/OutputListingGridStatic"
import { useFormContext } from "react-hook-form"
import { useCurrencyFormatter } from "../../../../modules/sgl-utils/Formatters"

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const SetFeiPrizeTemplate = ({ show, handleClose, recalculatePrizeMoney, setSplitCode }) => {
    const intl = useIntl();
    const [gridRef, setGridRef] = useState();
    const [rowData, setRowData] = useState([]);
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const showId = useAppSelector(state => state.currentShow.show_id);
    const [classStarts, setClassStarts] = useState('');
    const [chartType, setChartType] = useState({ value: 'Chart 1', label: 'Chart 1' });
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const methods = useFormContext();
    const feiCompetitionPrizeMoney = methods.getValues("classes.fei_competition_prize_money");
    const currencyFormatter = useCurrencyFormatter();
    const inputRef = useRef();

    // Template grid column definitions
    const getTemplateGridColDefs = () => {
        return [
            { field: 'place', cellStyle: { textAlign: 'center' }, width: 50, sortable: true, headerName: '' },
            { field: 'addbackpercent', cellStyle: { textAlign: 'right' }, width: 100, sortable: true, headerName: '%', cellRenderer : params => params.value + '%'},
            { field: 'prize_money', cellStyle: { textAlign: 'right' }, width: 150, sortable: true, cellRenderer: params => currencyFormatter(params.value), headerName: intl.formatMessage({ id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.SETFEITEMPLATE.GRID.PRIZEAMOUNT" })},
        ]
    }

    // Options for react select
    const prizeTemplateOptions = [
        { value: 'Chart 1', label: 'Chart 1' },
        { value: 'Chart 2', label: 'Chart 2' }
    ]

    // Get data for grid, generates dynamically on the basis of entered "Number of starts" and "Chart type
    const generatePrizeTemplate = async () => {
        //  Do not proceed if class starts is empty
        if (classStarts == '') {
            return;
        }

        await axios.post(`${NEST_API_URL}/classes/getFeiPrizeTemplateData`, {
            customer_id: customerId,
            show_id: showId,
            chart_type: chartType?.value,
            class_starts: classStarts,
            fei_competition_prize_money: feiCompetitionPrizeMoney,
        }).then(response => {
            if (response.data.success) {
                setRowData(response.data.prize_distribution)
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        // Automatically the cursor should go to input field
        if (inputRef) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [])

    useEffect(() => {
        generatePrizeTemplate()
    }, [chartType])
    


    // Apply selected prize money to the class
    const applyPrizeMoney = async () => {
        methods.setValue('classes.prize_template', 'Custom', { shouldDirty: true })
        let msg = 'FEI Prize Money Distribution template with '+chartType?.value+' and '+classStarts+' starts applied.'
        methods.setValue('fei_temp_log_msg', msg, { shouldDirty: true })
        let response = await axios.post(`${NEST_API_URL}/classes/getFeiPrizeTemplateData`, {
            customer_id: customerId,
            show_id: showId,
            chart_type: chartType?.value,
            class_starts: classStarts,
            fei_competition_prize_money: feiCompetitionPrizeMoney,
        })
        if(response.data.success){
            await recalculatePrizeMoney(response.data.prize_distribution);

        }
        // Setting split code because when user applied a split and then changed the prize template, the total split becomes 1, so we need to reset it to A
        setSplitCode({'label': 'A', 'value': 'A'}) 
        handleClose();
    }


    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            backdrop="static"
            dialogClassName='modal-dialog modal-dialog-centered fei-prize-template-modal'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) {
                    applyPrizeMoney()
                }
                if (event.key == 'Escape') {
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{ intl.formatMessage({ id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.SETFEITEMPLATE.HEADING.SETFEIPRIZETEMPLATE" })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='card-body p-0'>
                    <div className='row mb-2'>
                        <label className='col-5 col-form-label fs-5 py-1' htmlFor="selectShow" data-tooltip-id="CLASS.DETAIL.MODAL.SETFEIPRIZETEMPLATE.LABEL.PRIZEMONEYDISTRIBUTION">
                            { intl.formatMessage({ id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.SETFEITEMPLATE.PRIZEMONEYDISTRIBUTION" })}
                        </label>
                        <div className='col-7'>
                           <Select 
                                options={prizeTemplateOptions}
                                styles={reactSelectStyles}
                                value={chartType}
                                onChange={(selectedOption) => setChartType(selectedOption)}
                                tabIndex={1}
                           />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <label className='col-5 col-form-label fs-5 py-1' htmlFor="selectShow" data-tooltip-id="CLASS.DETAIL.MODAL.SETFEIPRIZETEMPLATE.LABEL.NUMBEROFSTARTS">
                            { intl.formatMessage({ id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.SETFEITEMPLATE.NUMBEROFSTARTS" })}
                        </label>
                        <div className='col-7'>
                            <input 
                                ref={inputRef}
                                type="number" 
                                className='form-control fs-6 min-h-20px py-1'
                                placeholder="0"
                                min={0}
                                value={classStarts}
                                onChange={(event) => {setClassStarts(event.target.value)}}
                                onBlur={generatePrizeTemplate}
                                tabIndex={2}
                            />
                        </div>
                    </div>

                    <div className="row mb-2">
                        {/* Grid */}
                        <div style={containerStyle}>
                            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                <OutputListingGridStatic
                                    key='fei-template-grid'
                                    columnDefs={getTemplateGridColDefs()}
                                    rowData={rowData}
                                    setGridRef={setGridRef}
                                    containerStyle={containerStyle}
                                    tabIndex={3}
                                ></OutputListingGridStatic>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <div className='card-footer d-flex justify-content-end py-3 px-0'>
                                <button onClick={handleClose} type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' tabIndex={4}>
                                    {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.SETFEITEMPLATE.MODAL.BUTTON.CLOSE' })}
                                </button>
                                <button onClick={applyPrizeMoney} type="button" className='btn btn-sm btn-secondary fw-bold text-uppercase' tabIndex={5} data-tooltip-id="CLASS.DETAIL.MODAL.SETFEIPRIZETEMPLATE.BUTTON.APPLY">
                                    {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.SETFEITEMPLATE.MODAL.BUTTON.APPLY' })}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}


export default SetFeiPrizeTemplate;