import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { Designer } from '@grapecity/activereports-react'
import { KTSVG } from '../../../../_metronic/helpers'
import { useState } from 'react'
import { useParams } from 'react-router-dom';

const modalsRoot = document.getElementById('root-modals') || document.body

const ViewHistoryModal = ({ show, handleClose, template, Height, Width, reportId}) => {
    const [reportStorage, setReportStorage] = useState(new Map());
    const { report_id } = useParams();

    // On save as for report, this will download .rdlx file on system
    const onSaveAs = async (info) => {
        const reportId = info.id || report_id;
        const reportDefinition = JSON.stringify(info?.definition);
        setReportStorage(new Map(reportStorage.set(reportId, info.definition)));
        
        // Download file -- start here
        const blob = new Blob([reportDefinition], {type: 'application/json'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${reportId}.rdlx-json`; // Will be downloaded as this name and extension
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);        
        // Download file -- end here

        return Promise.resolve({id: reportId, displayName: reportId });
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered view-preview-modal'
            show={show}
            onHide={handleClose}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4"></h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <div id="viewer-host">
                    <Designer 
                        report={{  id: reportId, definition: template,  displayName: ' '}}
                        onSaveAs={onSaveAs}
                        onSave={onSaveAs}
                    />
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { ViewHistoryModal }