import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useLoadingOverlay, useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext, useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext';
import ClassesGrid from './CombineClasses/ClassesGrid';
import axios from 'axios';
import { KTSVG } from '../../../../_metronic/helpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { removeDialogFromSession } from '../../../modules/sgl-utils/SglFunctions';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const CombineClasses = ({ show, handleClose }) => {
    const intl = useIntl() 
    const { getOutputSelectionSelectedRowsAreaIDs } = useOutputContext();
    const customerId = useAppSelector(state=> state.showCompany.company_id)
    const showId = useAppSelector(state => state.currentShow.show_id)
    const [gridRef, setGridRef] = useState()
    const outputContextUpdater = useOutputContextUpdater()
    const { outputGrid } = useOutputContext();
    const loadingOverlay = useLoadingOverlay()
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const [masterClass, setMasterClass] = useState({
        name: '',
        number: '',
        combined_class: false
    })

    // Get Ag-grid data
    const getRowData = (_gridRef) => {
        const rowData = []
        _gridRef.forEachNode(node => rowData.push(node.data));
        return rowData
    }

    useEffect(() => {
        if (show) {
          const storedModals = JSON.parse(sessionStorage.getItem('modals_open') || '[]');
          sessionStorage.setItem('modals_open', JSON.stringify([...storedModals, "CombineClasses"]));
        }
    }, [show]);

    useEffect(() => {
        if (show && gridRef) { // Run only when modal is open & ag-grid reference is set/available.
            const validateAndGetMetaData = async () => {   // Validates classes in selection
                try {
                    loadingOverlay({ show: true })
                    const classIds = await getOutputSelectionSelectedRowsAreaIDs() // Get class_id of selected rows

                    let message = ''
                    if (classIds.length === 0) message = 'Please select class(es) first.'
                    else if (classIds.length > 10) message = 'You cannot select more than 10 classes to be combined.'
                    else { // Get metadata and run validations on backend.
                        const response = await axios.post(`${NEST_API_URL}/classes/getCombineClassesMetadata`, {
                            class_ids: classIds,
                            customer_id: customerId
                        })
                        
                        if (!response.data.success) { // Validations Failed!
                            message = response.data.error 
                        } else { // Validations Passed!
                            setMasterClass(response.data.masterClass)
                            for (const cl of response.data.allNonCombinedClasses) { // By default, all classes are included.
                                cl.selected = true 
                            }
                            gridRef.setRowData(response.data.allNonCombinedClasses)
                        }
                    }

                    if (message) { // Display Alert and Close Dialog.
                        alertDialog({ message })
                        closeDialog()
                    }
                } catch (reason) {
                    alertDialog({ message: reason.response.data.message })
                } finally {
                    loadingOverlay({ show: false })
                } 
            }

            validateAndGetMetaData()
        }  
    }, [show, gridRef])

    const addClassesByNumbers = async (e) => {
        if (e.target.value === '') return

        try {
            loadingOverlay({ show: true })
            
            const { data: { errors, validatedClasses }} = await axios.post(`${NEST_API_URL}/classes/combineClasses/addClassesByNumbers`, {
                show_id: showId,
                customer_id: customerId,
                class_numbers: e.target.value,
                master_class: masterClass
            })

            const rowData = getRowData(gridRef)
            for (const cl of validatedClasses) {
                if (rowData.find(r => r.class_id === cl.class_id)) 
                    errors.push(`Class ${cl.number} is already selected to be combined in list.`)
                else 
                    rowData.push({ ...cl, selected: true, tripCount: cl.Entryxclasses.length })
            }

            if (errors.length > 0)
                alertDialog({ message: errors.join('<br />')})
            
            e.target.value = "" //clear the variable for next search
            rowData.sort((a, b) => a.number - b.number)
            gridRef.setRowData(rowData)
    
        } catch (error) {
            alertDialog({ message: error.response.data.message })
        } finally {
            loadingOverlay({ show: false })
        }
    }

    const onClose = (sglIds) => {
        outputContextUpdater({ action: 'getDataBySglIds', subsetIDs: sglIds }) // Refresh listing with currently combined classes
        closeDialog();  
    }

    const closeDialog =() => {
        removeDialogFromSession('CombineClasses')
        handleClose();
    }

    // !4D -> [Class].CombineClass.bAdd
    const combineClasses = async () => {

        const rowData = getRowData(gridRef)
        const actualClassIds = []
        for (const row of rowData) {
            if (row.selected && row.combined_class_id === 0) { // class is selected and is not already an actual class.
                actualClassIds.push(row.class_id)
            }
        }

        let message = ''
        if (actualClassIds.length < 2) 
            message = 'Please select more than one class to combine.'
        else if (!masterClass.name)
            message = 'Please enter Class Name for combined class.'
        else if (!masterClass.combined_class && Number(masterClass.number) <= 0)
            message = 'Please enter Class Number for combined class.'

        if (message) { 
            alertDialog({ message })
            return
        }

        try {
            loadingOverlay({ show: true })
            const res1 = await axios.post( NEST_API_URL + `/classes/validateCombineClasses`, {
                master_class: masterClass,
                customer_id: customerId,
                actual_class_ids: actualClassIds 
            })

            if (res1.data.success) {
                const choice = await confirmDialog({ message: 'Are you sure you want to combine selected classes?' })
                if (choice) {
                    const res2 = await axios.post( NEST_API_URL + `/classes/combineClasses`, {
                        combined_class: res1.data.combinedClass,
                        customer_id: customerId,
                        actual_class_ids: actualClassIds 
                    })

                    if (res2.data.success) {
                        onClose(res2.data.classSglIds)
                    }
                } else {
                    closeDialog()
                }
            } else
                alertDialog({ message: res1.data.error })
        }  catch (error) {
            alertDialog({ message: error.response.data.message })
        } finally {
            loadingOverlay({ show: false })
        }
    }
    
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={closeDialog}
            onKeyDown={(event) => {
                const storedModals = sessionStorage.getItem('modals_open');
                const modals_open = storedModals ? JSON.parse(storedModals) : [];
                const lastModal = modals_open.pop();
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && lastModal == 'CombineClasses' && !isButtonFocused) {
                    combineClasses()
                }
                if (event.key == 'Escape') {
                    closeDialog()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'Classes.QuickAction.CombineClasses' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={closeDialog}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='card-body p-0 px-2'>
                    <div className='row mb-2'>
                        <label className='col-lg-3 col-form-label fs-5 py-1' htmlFor="masterClassNumber" data-tooltip-id="CLASS.DETAIL.QUICKACTION.COMBINECLASSES.LABEL.CLASSNUMBER">
                            { intl.formatMessage({ id: 'Classes.QuickAction.CombineClasses.Label.ClassNumber' }) }
                        </label>
                        <label className='col-lg-1 col-form-label fs-5 py-1 pe-0'>
                            { masterClass.combined_class ? masterClass.number : 11 }
                        </label>
                        {
                            !masterClass.combined_class &&
                            <div className='col-lg-2 pe-0'>
                                <input
                                    type="text"
                                    id="masterClassNumber" 
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    value={masterClass.number}
                                    onChange={e => setMasterClass(prevMasterClass => ({ ...prevMasterClass, number: e.target.value }))}
                                    autoFocus
                                    tabIndex={1}
                                />
                            </div>
                        }
                        { !masterClass.combined_class && <label className='col col-form-label fs-6 py-1'>(Combined classes start with prefix 11)</label> }
                    </div>
                    <div className='row mb-2'>
                        <label className='col-lg-3 col-form-label fs-5 py-1' htmlFor="masterClassName" data-tooltip-id="CLASS.DETAIL.QUICKACTION.COMBINECLASSES.LABEL.CLASSNAME">
                            { intl.formatMessage({ id: 'Classes.QuickAction.CombineClasses.Label.ClassName' }) }
                        </label>
                        <div className='col-lg-9'>
                            <input
                                type="text"
                                id="masterClassName" 
                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                value={masterClass.name}
                                onChange={e => setMasterClass(prevMasterClass => ({ ...prevMasterClass, name: e.target.value }))}
                                tabIndex={2}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <label className='col-lg-3 col-form-label fs-5 py-1' htmlFor="enterClasses" data-tooltip-id="CLASS.DETAIL.QUICKACTION.COMBINECLASSES.LABEL.ENTERCLASSES">
                            { intl.formatMessage({ id: 'Classes.QuickAction.CombineClasses.Label.EnterClasses' }) }
                        </label>
                        <div className='col-lg-9'>
                            <input
                                type="text"
                                id="enterClasses" 
                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                onBlur={addClassesByNumbers}
                                tabIndex={3}
                            />
                        </div>
                    </div>
                    <ClassesGrid 
                        setGridRef={setGridRef}
                        tabIndex={4}
                    />
                </div>
         
                <div className='card-footer d-flex justify-content-end py-3 px-2'>
                    <label className="col col-form-label fs-5 py-1 fw-bold">Selected classes will be combined.</label>
                    <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={closeDialog} tabIndex={5}>
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CANCEL" })}
                    </button>

                    <button 
                        type="button" 
                        className='btn btn-sm btn-dark fw-bold text-uppercase'
                        onClick={combineClasses}
                        tabIndex={6}
                        data-tooltip-id="CLASS.DETAIL.QUICKACTION.COMBINECLASSES.BUTTON.COMBINE"
                        >
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.COMBINE" })}
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default CombineClasses