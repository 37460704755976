import {useIntl} from 'react-intl'
import { useNavigate } from 'react-router-dom';
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import { useAppSelector } from '../../redux/hooks';

const FacilitiesList = () => {
    const intl = useIntl();
    const navigate = useNavigate()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    
    const columnDefs = [
        { field: 'facility_id', headerName: intl.formatMessage({id: 'LIST.FACILITIES.FACILITYID'}), filter: 'agNumberColumnFilter', hide: true },
        { field: 'name', width: 300, headerName: intl.formatMessage({id: 'LIST.FACILITIES.NAME'}), filter: 'agTextColumnFilter', },
        { field: 'address', width: 300, headerName: intl.formatMessage({id: 'LIST.FACILITIES.ADDRESS'}), filter: 'agTextColumnFilter', },
        { field: 'city', headerName: intl.formatMessage({id: 'LIST.FACILITIES.CITY'}), filter: 'agTextColumnFilter', },
        { field: 'state', headerName: intl.formatMessage({id: 'LIST.FACILITIES.STATE'}), filter: 'agTextColumnFilter', },
        { field: 'total_rings_count', headerName: intl.formatMessage({id: 'LIST.FACILITIES.NUMBEROFRINGS'}), filter: 'agNumberColumnFilter'},
    ]

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.FACILITIES'})}</PageTitle>
            <OutputListingComponent 
                key={customer_id} 
                area={'Facilities'} 
                columnDefs={columnDefs} 
                apiEndPoint={'facilities/list'}
                onRowDoubleClicked={
                    rowData => navigate(`/facilities/detail/${rowData.facility_id}?customer_id=${customer_id}`)
                }
                onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL+'/facilities/detail/'+row_data.facility_id+`?customer_id=${customer_id}`,'_blank')}
                />
        </>
    )
}

export {FacilitiesList}
