import { useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import axios from 'axios';
import { KTSVG } from '../../../../_metronic/helpers';
import "flatpickr/dist/themes/material_blue.css";
import Select from 'react-select';
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls'
import { loadingSpinnerBtnWait, loadingSpinnerBtnWaitByEnter } from '../../../modules/sgl-utils/SglFunctions';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const SuppressWebResults = ({ show, handleClose }) => {
    const intl = useIntl()
    const { getOutputSelectionAreaIDs, getEventID } = useOutputContext();
    const {progressBar} = useProgress()
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const [action, setAction] = useState(null)


    const actions = [
        {
            label: intl.formatMessage({ id: 'CLASSES.QUICKACTION.SUPPRESSWEBRESULTS.ACTIONS.SUPPRESS' }),
            value: "suppress"
        },
        {
            label: intl.formatMessage({ id: 'CLASSES.QUICKACTION.SUPPRESSWEBRESULTS.ACTIONS.UNSUPPRESS' }),
            value: "unsuppress"
        }
    ]

    const suppressWebResults = (e) =>{
        if(!action){
            return
        }

        const progressEventId = getEventID('suppress-web-results')
        loadingSpinnerBtnWaitByEnter(e);
        // loadingOverlay({show:true})
        getOutputSelectionAreaIDs()
        .then((classIds)=>{
            progressBar({ show: true, eventID: progressEventId, showProgress: 'inline', title: "Progress", timeElapsed: true })

            axios.post( NEST_API_URL + `/classes/suppressWebResults`, {
                customer_id: customerId,
                show_id: currentShowID,
                progress_event_id: progressEventId,
                class_ids: classIds,
                suppress_web_results: action == "suppress"

            })
            .then(() => {
                loadingSpinnerBtnWaitByEnter(e)
                handleClose()
            })
            .catch(reason => {
                loadingSpinnerBtnWaitByEnter(e)
            })
            .finally( () => {
                progressBar({ show: false, eventID: progressEventId})
            })
        })
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-400px'
            show={show}
            backdrop='static'
            onKeyDown={(event) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) {
                    let saveButton = document.getElementById('SuppressWebResultsSaveButton')
                    suppressWebResults(saveButton)
                }
                if(event.key === 'Escape'){
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'CLASSES.QUICKACTION.SUPPRESSWEBRESULTS.TITLE' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>
            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body px-0'>
                        <div className='row mb-2 px-4'>
                            <div className='col-4'>
                                <label className='col-lg-12 col-form-label fs-5 py-1' htmlFor={'action'}>{intl.formatMessage({ id: 'CLASSES.QUICKACTION.SUPPRESSWEBRESULTS.SELECTACTION' })} </label>
                            </div>
                            <div className='col-8'>
                                <Select
                                    id='action'
                                    options={actions}
                                    // value={}
                                    name={'action'}
                                    isSearchable={true}
                                    onChange={(selectedAction) => {
                                        setAction(selectedAction.value)
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                    })}
                                    styles={reactSelectStyles}
                                    placeholder={ intl.formatMessage({ id: 'CLASSES.QUICKACTION.SUPPRESSWEBRESULTS.ACTIONS.PLACEHOLDER' }) }
                                    autoFocus
                                    tabIndex={1}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3 px-4'>
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={2}>
                            {intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button id='SuppressWebResultsSaveButton' type='button' className='btn btn-sm btn-dark fw-bold' onClick={suppressWebResults} tabIndex={3}>
                            <span className="spinner-border spinner-border-sm d-none me-2"></span>
                            {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.SAVE' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
        , modalsRoot)
}

export default SuppressWebResults;