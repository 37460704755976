
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import { Controller, FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import axios from 'axios';
import { useAppSelector } from '../../redux/hooks'
import { useOutputContext } from '../../modules/output-listing/OutputListingContext';
import Select from "react-select";
import { NumericFormat } from 'react-number-format';
import { useFormContext } from 'react-hook-form';
import { reactSelectStyles } from '../../modules/sgl-utils/fieldControls';

// Metronic Components
import { KTSVG } from '../../../_metronic/helpers'

// Assets
import "flatpickr/dist/themes/material_blue.css";
import ReactSelectComponent from '../../modules/sgl-utils/ReactSelectComponent';
import { searchOptions } from '../../modules/sgl-utils/reactSelectOptions';


type Props = {
    show: boolean
    handleClose: () => void
    onSubmitForm:({})=>void
    onClearFilter:()=>void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SearchClassesForm = ({ show, onSubmitForm, onClearFilter, handleClose }: Props) => {
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const customer_id = useAppSelector(state=> state.showCompany.company_id)
    const { register, watch, reset, getValues, control, setValue, formState } = useForm({});
    const intl = useIntl()
    const [formMetaData,setFormMetaData]=useState<any>()
    const context=useOutputContext()
    const methods = useFormContext()
    const selectStyles = reactSelectStyles?.control ?? {}

    const handleReset = () => {
        reset();
        //reseting dropdowns in the form
        setValue("horse_type","")
        setValue("scheduled_date","")
        setValue("class_type","")
        setValue("division","")
        setValue("rider_status","")
        setValue("schedule_ring_id","")
        setValue("template_name","")
    }

    useEffect(() => {
        //to pre-fill search fields
        reset(context.outputAdvanceFilter,{keepDefaultValues:true})
      },[]);

    const handleSave = async () => {
        let data = getValues();
        onSubmitForm(data);
    }

    useEffect(() => {
        //calling metadata api to populate form dropdowns
        if(show){
            axios.get(process.env.REACT_APP_NEST_API_URL + '/classes/classAdvanceSearchMetaData', {
                params: {
                customer_id: customer_id,
                show_id: currentShowID
                },
            }).then((res) => {
                if(res.status===200){
                setFormMetaData(res.data)
                }            
            }).catch((e) => {
            })
        }
    },[show])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-xl search-form mw-650px'
            show={show}
            onHide={handleClose}
            onKeyPress={(event:any) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) { //call function only when no button is in focus
                  handleSave();
                }
                if (event.key === "Escape") {
                    handleClose();
                }
            }}
        >
            <div className='modal-header py-0 px-4' >
                <h2 className="fs-4" >{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.TITLE.SEARCHCLASSES' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>
                
            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'> {/* Card Body */}

                        <div className="form-group"> {/* Column Group */}
                            <div className='row mb-2'>
                                <div className='col-lg-1 w-lg-145px pe-0'></div>
                                <div className='col form-check-sm form-check-custom px-3'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        {...register("cancelled_class")}
                                        id='CANCELLEDCLASS'
                                        autoFocus
                                        tabIndex={1}
                                    />
                                    <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='CANCELLEDCLASS'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.CANCELLEDCLASS' })}</label>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='CLASSNUM'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.CLASSNUMNAME' })}</label>
                                <div className='col'>
                                    {/* <input
                                        id='CLASSNUM'
                                        type='number'
                                        {...register('number')}
                                        className='form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1 number-input'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.PLACEHOLDER.CLASSNUM' })}
                                    /> */}
                                    <Controller
                                        name="number"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                        <NumericFormat
                                            {...field}
                                            id="CLASSNUM"
                                            allowNegative={false}
                                            decimalScale={0}
                                            className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1 number-input"
                                            placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.PLACEHOLDER.CLASSNUM' })}
                                            tabIndex={2}
                                        />
                                        )}
                                    />
                                </div>
                                <div className='col'>
                                    <input
                                        id="CLASSNAME"
                                        type='text'
                                        {...register('name')}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.PLACEHOLDER.CLASSNAME' })}
                                        tabIndex={3}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='CLASSTYPE'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.CLASSTYPE' })}</label>
                                <div className='col'>
                                <Controller
                                    name='class_type'
                                    control = {control}
                                    render={({field: {onChange, value, name}}) => (
                                    <Select
                                        styles={reactSelectStyles}
                                        options={formMetaData?.class_type}
                                        id='CLASSTYPE'
                                        value={ value ? formMetaData?.class_type.find((ele:any) => { return ele.value === value}) : "" }
                                        name={name}
                                        isSearchable={true}
                                        onChange={(class_type:any) => {
                                            onChange(class_type.value)  
                                        }}
                                        placeholder='Select'
                                        tabIndex={4}
                                    />
                                    )}
                                />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-4 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='DIVISION'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.DIVISION' })}</label>
                                <div className='col'>
                                <Controller
                                    name='division'
                                    control = {control}
                                    render={({field: {onChange, value, name}}) => (
                                    <Select
                                        styles={reactSelectStyles}
                                        options={formMetaData?.division}
                                        id='DIVISION'
                                        value={ value? formMetaData?.division.find((ele:any) => { return ele.value === value }) : "" }
                                        name={name}
                                        isSearchable={true}
                                        onChange={(division:any) => {
                                            onChange(division.value)  
                                        }}
                                        placeholder='Select'
                                        tabIndex={5}
                                    />
                                    )}
                                />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg- col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='HORSENAME'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.HORSENAME' })}</label>
                                <div className='col'>
                                    <input
                                        id='HORSENAME'
                                        type='tel'
                                        {...register('horse_name')}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.HORSENAME' })}
                                        tabIndex={6}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='RIDERFIRSTNAME'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.RIDER' })}</label>
                                <div className='col'>
                                    <input
                                        id='RIDERFIRSTNAME'
                                        type='text'
                                        {...register('rider_first_name')}
                                        className='form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.PLACEHOLDER.FIRSTNAME' })}
                                        tabIndex={7}
                                    />
                                </div>
                                <div className='col'>
                                    <input
                                        id="RIDERLASTNAME"
                                        type='text'
                                        {...register('rider_last_name')}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.PLACEHOLDER.LASTNAME' })}
                                        tabIndex={8}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-4 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='TRAINERFIRSTNAME'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.TRAINER' })}</label>
                                <div className='col'>
                                    <input
                                        id='TRAINERFIRSTNAME'
                                        type='text'
                                        {...register('trainer_first_name')}
                                        className='form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.PLACEHOLDER.FIRSTNAME' })}
                                        tabIndex={9}
                                    />
                                </div>
                                <div className='col'>
                                    <input
                                        id="TRAINERLASTNAME"
                                        type='text'
                                        {...register('trainer_last_name')}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.PLACEHOLDER.LASTNAME' })}
                                        tabIndex={10}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='OWNERFIRSTNAME'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.OWNER' })}</label>
                                <div className='col'>
                                    <input
                                        id='OWNERFIRSTNAME'
                                        type='text'
                                        {...register('owner_first_name')}
                                        className='form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.PLACEHOLDER.FIRSTNAME' })}
                                        tabIndex={11}
                                    />
                                </div>
                                <div className='col'>
                                    <input
                                        id="OWNERLASTNAME"
                                        type='text'
                                        {...register('owner_last_name')}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.PLACEHOLDER.LASTNAME' })}
                                        tabIndex={12}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='SCHEDULEDAYNUM'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.SCHEDULEDAYNUM' })}</label>
                                <div className='col'>
                                    <Controller
                                        name='scheduled_date'
                                        control = {control}
                                        render={({ 
                                            field: { onChange, value, name }
                                        }) => (
                                        <Select
                                            styles={reactSelectStyles}
                                            options={formMetaData?.days.map((date: moment.MomentInput, idx: number) => ({ label: `Day ${idx+1}: ${moment(date, 'YYYY-MM-DD').format('MMMM D, YYYY')}`, value: date}))}
                                            id='SCHEDULEDAYNUM'
                                            value={ value ? formMetaData?.days.find((ele:any) => { return ele.value === value}) : "" }
                                            name={name}
                                            isSearchable={true}
                                            onChange={(schedule_day:any) => {
                                                onChange(schedule_day.value)  
                                            }}
                                            placeholder='Select Date'
                                            tabIndex={14}
                                        />
                                        )}
                                     />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg- col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' style={{whiteSpace:"nowrap"}} htmlFor='SCHEDULERINGNUM'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.SCHEDULERINGNUM' })}</label>
                                <div className='col'>
                                <Controller
                                        name='schedule_ring_id'
                                        control = {control}
                                        render={({ 
                                            field: { onChange, value, name }
                                        }) => (
                                        <Select
                                            styles={reactSelectStyles}
                                            options={formMetaData?.rings}
                                            id='SCHEDULERINGID'
                                            value={ value ? formMetaData?.rings.find((ele:any) => { return ele.value === value}) : "" }
                                            name={name}
                                            isSearchable={true}
                                            onChange={(schedule_ring_id:any) => {
                                                onChange(schedule_ring_id.value)  
                                            }}
                                            placeholder='Select'
                                            tabIndex={14}
                                        />
                                    )}
                                     />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg- col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' style={{whiteSpace:"nowrap"}} htmlFor='PLACINGTEMPLATE'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.PLACINGTEMPLATE' })}</label>
                                <div className='col'>
                                <Controller
                                        name='template_name'
                                        control = {control}
                                        render={({ 
                                            field: { onChange, value, name }
                                        }) => (
                                        <Select
                                            styles={reactSelectStyles}
                                            options={formMetaData?.class_templates}
                                            id='PLACINGTEMPLATE'
                                            value={ value ? formMetaData?.class_templates.find((ele:any) => { return ele.label === value}) : "" }
                                            name={name}
                                            isSearchable={true}
                                            onChange={(template_name:any) => {
                                                if(template_name.value == ''){
                                                    onChange('')  
                                                }else{
                                                    onChange(template_name.label)  
                                                }
                                               
                                            }}
                                            placeholder='Select'
                                            tabIndex={15}
                                        />
                                    )}
                                     />
                                </div>
                            </div>
                            <fieldset className='mb-2 mt-5'>
                                <legend className="fs-4 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.GROUPLABEL.CLASSREQUIREMENTS' })} <b></b></legend>
                                <div className='row mb-2'>
                                    {/* <div className='col-lg-1 w-lg-145px pe-0'></div> */}
                                    <div className='col-4 form-check-sm form-check-custom px-3'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            {...register("horse_req_age")}
                                            id='HORSEREQAGE'
                                            tabIndex={15}
                                        />
                                        <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='HORSEREQAGE'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.HORSEREQAGE' })}</label>
                                    </div>
                                    <div className='col-4 form-check-sm form-check-custom px-3'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            {...register("measurement_card")}
                                            id='MEASUREMENTCARD'
                                            tabIndex={16}
                                        />
                                        <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='MEASUREMENTCARD'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.MEASUREMENTCARD' })}</label>
                                    </div>
                                    <div className='col-4 form-check-sm form-check-custom px-3'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            {...register("horse_age_verified")}
                                            id='HORSEAGEVERIFIED'
                                            tabIndex={17}
                                        />
                                        <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='HORSEAGEVERIFIED'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.HORSEAGEVERIFIED' })}</label>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    {/* <div className='col-lg-1 w-lg-145px pe-0'></div> */}
                                    
                                    <div className='col-4 form-check-sm form-check-custom px-3'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            {...register("rider_req_age")}
                                            id='RIDERREQAGE'
                                            tabIndex={18}
                                        />
                                        <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='RIDERREQAGE'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.RIDERREQAGE' })}</label>
                                    </div>
                                    <div className='col-4 form-check-sm form-check-custom px-3'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            {...register("rider_membership")}
                                            id='RIDERMEMBERSHIP'
                                            tabIndex={19}
                                        />
                                        <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='RIDERMEMBERSHIP'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.RIDERMEMBERSHIP' })}</label>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='HORSETYPE'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.HORSETYPE' })}</label>
                                    <div className='col'>
                                    <Controller
                                        name='horse_type'
                                        control = {control}
                                        render={({
                                            field: { onChange, value, name }
                                        }) => (
                                        <Select
                                            styles={reactSelectStyles}
                                            options={formMetaData?.horse_type}
                                            id='HORSETYPE'
                                            value={ value ? formMetaData?.horse_type.find((ele:any) => { return ele.value === value}) : "" }
                                            name={name}
                                            isSearchable={true}
                                            onChange={(horse_type:any) => {
                                                onChange(horse_type.value)  
                                            }}
                                            placeholder='Select'
                                            tabIndex={20}
                                        />
                                    )}
                                     />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='RIDERSTATUS'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.RIDERSTATUS' })}</label>
                                    <div className='col'>
                                    <Controller
                                        name='rider_status'
                                        control = {control}
                                        render={({
                                            field: {onChange, value, name}
                                        }) => (
                                        <Select
                                            styles={reactSelectStyles}
                                            options={formMetaData?.rider_status}
                                            id='RIDERSTATUS'
                                            value={ value ? formMetaData?.rider_status.find((ele:any) => { return ele.value === value}) : "" }
                                            name={name}
                                            isSearchable={true}
                                            onChange={(rider_status:any) => {
                                                onChange(rider_status.value)  
                                            }}
                                            placeholder='Select'
                                            tabIndex={21}
                                        />
                                    )}
                                     />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-5 form-check-sm form-check-custom px-3'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            {...register("class_requirements_missing")}
                                            id='ClassRequirementsMissing'
                                            tabIndex={22}
                                        />
                                        <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='ClassRequirementsMissing'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.CLASSREQUIREMENTSMISSING' })}</label>
                                    </div>
                                    <div className='col-3'></div>
                                    <div className='col-4 form-check-sm form-check-custom px-3'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            {...register("missing_officials")}
                                            id='MissingOfficials'
                                            tabIndex={23}
                                        />
                                        <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='MissingOfficials'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.OFFICIALSMISSING' })}</label>
                                    </div>
                                </div>
                            </fieldset>
                            <div className='row mb-2'>
                                <div className='col-lg-1 w-lg-145px pe-0'></div>
                                <div className='col form-check-sm form-check-custom px-3'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='EXACTMATCHESONLY'
                                        {...register("exact_matches")}
                                        tabIndex={24}
                                    />
                                    <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='EXACTMATCHESONLY'> {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.EXACTMATCHESONLY' })}</label>
                                </div>
                            </div>

                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='SEARCH'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.CLASSES.LABEL.SEARCH' })}</label>
                                    <div className='col'>
                                    <ReactSelectComponent 
                                        name='search'
                                        id='SEARCH'
                                        options={searchOptions} 
                                        control={control} 
                                        methods={null}
                                        tabIndex={25}
                                    />
                                    </div>
                                </div>
                        </div> {/* End Column Group */}


                    </div>{/* End Card Body */}

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type="button" className='btn btn-sm btn-secondary fw-bold' onClick={() => handleReset()} tabIndex={26}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CLEARVALUES' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={27}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-dark fw-bold' onClick={() => handleSave()} tabIndex={28}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.SEARCH' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { SearchClassesForm }