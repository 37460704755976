import { useLayoutEffect, useEffect, useState, useRef } from 'react';
import {useIntl} from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setCurrentShow } from '../../redux/reducers/currentShowReducer';
import axios from 'axios';
import { showPicker } from '../../../_metronic/layout/core';
import { useSGLContext } from '../contexts/SGLContext';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const ShowPicker = (props) => {
    const intl = useIntl()
    const [shows, setShows] = useState([]);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const customerID = useAppSelector(state=> state.showCompany.company_id);
    const renderShowPicker = useAppSelector(state => state.renderShowPicker.trigger_render);
    const prevRenderValue= useRef(renderShowPicker);
    const dispatch = useAppDispatch();
    const { searchParams, setSearchParams } = useSGLContext()
    const newShowId = useAppSelector(state => state.newShowId.show_id)
    const onInputForm = showPicker.disabled //false; //!(config.toolbar.display);
    const [showBackgroundColor, setShowBackgroundcolor] = useState('#fedec9') //used as background color for show picker dropdown

    useLayoutEffect(() => {
        let unmounted = false;
        async function getCustomerShows() {
            try {
                const response = await axios.get(`${NEST_API_URL}/shows?customer_id=${customerID}`);
                const showsResponse = response.data;

                if (!unmounted) {
                    /* SET DEFAULT SHOW BASED ON SHOWS LIST */
                    let show = null

                    // first check in session storage, if not found then get from local starage
                    // we need to use session storage as we need to add support for multiple shows in different tabs
                    if (!show) {
                        show = sessionStorage.getItem('currentShow');

                        // Set show in localStorage if show was present in session storage but not in localStorage.
                        // NOTE: We clear show from localStorage on login page.
                        if (show && !localStorage.getItem('currentShow')) {
                            localStorage.setItem('currentShow', JSON.stringify(show))
                        }

                        if(!show){
                            show = localStorage.getItem('currentShow');
                        }
    
                        if(show){ //show in session/local storage found
                            show = JSON.parse(show);
                            show = show.hasOwnProperty('show_id') ? show : null;

                            //check if show from session/local storage has same current/upcoming status as new show data
                            if(show){
                                const matchingShow = showsResponse.shows.find(updatedShow => updatedShow.sgl_id === show.sgl_id); //find matching show
                                if(matchingShow && matchingShow.currentOrUpcoming !== show.currentOrUpcoming){ //if current/upcoming status does not match
                                    show = matchingShow //set show to the updated one
                                }
                            }

                            if(show && 'currentShowSetAt' in show){
                                const millis = Date.now() - show.currentShowSetAt;
                                const diffInHours = Math.abs(millis / 3600000);
                                if(diffInHours > 18){
                                    show = null;
                                }
                            }
                        }
                    }

                    if(!show && showsResponse.shows && showsResponse.shows.length > 0){
                        if('defaultShow' in showsResponse){
                            show = showsResponse.defaultShow;
                        }
                        else{
                            show = showsResponse.shows[0]; //Set first show as default show
                        }
                    }

                    if (prevRenderValue.current !== renderShowPicker) {
                        let selected_show = showsResponse.shows.find(r_show => r_show.show_id == show.show_id)
                        if(selected_show){
                            show = selected_show
                        }
                    }

                    if(show){
                        updateCurrentShow(show, prevRenderValue.current !== renderShowPicker);
                    }
                    /* SET DEFAULT SHOW BASED ON SHOWS LIST */

                    prevRenderValue.current = renderShowPicker

                    //set shows list dropdown options
                    setShows(showsResponse.shows);
                }
            } catch (reason) {
                console.log(reason)
            }
        }
        //Get shows list for show company
        getCustomerShows();
        return () => {
            unmounted = true;
        };
    }, [customerID, renderShowPicker]);
    
    //UseEffect for updating Show in ShowPicker if show From a record is different
    useEffect(() => {
        if (newShowId > 0){ //To avoid showPicker unwanted value changes
            changeCurrentShowPickerShow(newShowId)
        }
    }, [newShowId, shows])

    useEffect(() => {
        const urlShowId = Number(searchParams.get("show_id"))
        const urlCustomerId = Number(searchParams.get("customer_id"))
        if(urlShowId){ // Check if show_id is passed in url as query parameter 
            let show = null
            if (urlCustomerId) { // If customer_id is present in params then we will only set show once state has shows list for this company.
                // This will be called when company passed in param is not current company in redux so this will ignore show param change.
                show = shows.find(s => s.show_id === urlShowId && s.customer_id === urlCustomerId)
            } else {
                show = shows.find(s => s.show_id === urlShowId)
            }

            if (show) {
                searchParams.delete('show_id') // remove from url
                setSearchParams(searchParams)
                updateCurrentShow(show)
            }
        } 
    }, [shows])

    function updateCurrentShow(show, force_change = false){
        if(!currentShowID || currentShowID != show.show_id || force_change){
            //Update show in redux state
            show.currentShowSetAt = Date.now();
            dispatch(setCurrentShow(show));
        }
        //Set background color of dropdown based on show (green if current/upcoming, orange otherwise)
        if(show.currentOrUpcoming){
            setShowBackgroundcolor('#ddf6e6') //set show picker dropdown background to green colour
        }
        else{
            setShowBackgroundcolor('#fedec9') //set show picker dropdown background to orange colour
        }
    }

    //Function to handle change Show in ShowPicker if showPicker change is not from event
    function changeCurrentShowPickerShow(newShowId){
        if(newShowId){
            for(let show of shows){
                if(show && show.show_id == newShowId){
                    updateCurrentShow(show);
                    break;
                }
            }
        }
    }

    function changeCurrentShow(e){
        let show_id = e.currentTarget.value;
        if(show_id){
            for(let show of shows){
                if(show.show_id == show_id){
                    updateCurrentShow(show);
                    break;
                }
            }
        }
    }

    return (
        <>
            {/* begin::Label */}
            <span className='fs-3 fw-bolder text-dark pe-4 text-nowrap d-none d-md-block d-lg-block d-xl-block d-xxl-block'>
              {intl.formatMessage({id: 'TOOLBAR.LABEL.SHOW'})}:
            </span>
            {/* end::Label */}

            {/* begin::Select */}
            <select
                className='form-select form-select-solid w-m-600px w-lg-600px w-xl-600px w-xxl-600px fw-bolder text-dark fs-5'
                id='main-show-picker'
                data-control='select2'
                data-placeholder='Select Show'
                data-hide-search='true'
                style={{backgroundColor: showBackgroundColor}}
                value={currentShowID}
                disabled={onInputForm}
                onChange={(e) => changeCurrentShow(e)}
            >
                {shows.map(show =>
                <option key={show.sgl_id} value={show.show_id} style={{ backgroundColor: show.currentOrUpcoming ? '#ddf6e6' : '#fedec9'}}>{show.show_name} | {show.display_date}</option>
                )};
            </select>
            {/* end::Select  */}
        </>
    );
}
export default ShowPicker;