import { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useAlert, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider'
import axios from 'axios'
import { useAppSelector } from '../../../redux/hooks'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic'
import { loadingSpinnerBtnRelease, loadingSpinnerBtnReleaseByEnter, loadingSpinnerBtnWait, loadingSpinnerBtnWaitByEnter } from '../../../modules/sgl-utils/SglFunctions'

const modalsRoot = document.getElementById('root-modals') || document.body

const FeiRapidAdds = ({ show, handleClose }) => {
    const [gridRef, setGridRef] = useState();
    const [rowData, setRowData] = useState([]);
    const [classNumbers, setClassNumbers] = useState('');
    const [horseFeiIds, setHorseFeiIds] = useState('');
    const currentShowId = useAppSelector(state => state.currentShow.show_id);
    const customerId = useAppSelector(state => state.showCompany.company_id);
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked) 
    const alertDialog = useAlert();
    const containerStyle = useMemo(() => ({ width: '100%', height: '180px' }), []);
    const loadingOverlay = useLoadingOverlay()
    const intl = useIntl();

    useEffect(() => {
        if(show && showFinancialsLocked){
            if(showFinancialsLocked){
                alertDialog({ title: "warning", message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.FEIRAPIDCLASSADD.ERROR.SHOWLOCKED"}) })
                onClose()
                return
            }
        }
    }, [show])

    const onClose = () => {
        handleClose()
    }

    const getColumnDefs = () => {
        return [
            { field: 'message', width: '500', cellStyle: { textAlign: 'left' }, sortable: true, headerName: '' },
        ]
    }

    // This function will validate on input blur and as well as called on submit based on actions
    const feiRapidAdd = async (event, action) => {
        // valid input regex (can contain numbers, comma, hyphen, plus, space, period)
        const regex = /^[\d,-\s+\.]+$/
        if(classNumbers && !regex.test(classNumbers)){
            alertDialog({message: "Please enter valid class numbers."})
            setClassNumbers('')
            return
        }

        const body = {
            show_id: currentShowId,
            customer_id: customerId,
            class_numbers: classNumbers,
            horse_fei_ids: horseFeiIds,
        }

        const url = action == 'validate' ? `${process.env.REACT_APP_NEST_API_URL}/entries/rapidFeiAddMetaData` : `${process.env.REACT_APP_NEST_API_URL}/entries/addRapidFeiEntry`

        // Send post request
        // Do not procees if classnumbers or horse fei ids are empty in different cases
        let proceed = false;

        if (action == 'validate') {
            if (classNumbers !== '' || horseFeiIds !== '') {    // Proceed if either class id or horse fei id is not empty
                proceed = true;
            }
        } else if (action == 'add') {
            if (classNumbers !== '' && horseFeiIds !== '') {    // For add, both class id and horse fei id should not be empty
                proceed = true;
            }
        }

        if (proceed) {
            if (action == 'add') {
                loadingSpinnerBtnWaitByEnter(event)
                loadingOverlay({ show: true })
            }
            await axios.post(url, body)
                .then((response) => {
                    if (response.data.success) {
                        if (response.data.alert) {
                            alertDialog({message: response.data.messages[0].message})
                            setClassNumbers('');
                            setRowData([]);
                        } else {
                            setRowData(response.data.messages)
                        }

                        if (response.data.alertMessages.length > 0) {
                            let alerts = '';

                            for (let alert of response.data.alertMessages) {
                                alerts += alert.message + '<br>';
                            }

                            alertDialog({ message: alerts })
                        }
                    }
                    if (action == 'add') {
                        loadingSpinnerBtnReleaseByEnter(event)
                        loadingOverlay({ show: false })
                    }
                })
                .catch((error) => {
                    if (action == 'add') {
                        loadingSpinnerBtnReleaseByEnter(event)
                        loadingOverlay({ show: false })
                    }
                })
        } else {
            return false;
        }
        return false;
    }

    // This function is written to handle blur event on input fields because when normally we were clicking submit button
    // The request was sent for validate and no request generated for add action, so we are handling it here
    const handleBlur = (event, action) => {
        setTimeout(() => {
            // If active element is not button then only call the function
            if (!document?.activeElement || document?.activeElement?.nodeName !== 'BUTTON') {
                feiRapidAdd(event, action);
            }
        }, 0);
    };

    // Class rules for colors
    const rowClassRules = useMemo(() => {
        return {
            'bg-expired': 'data.error',
            'bg-valid': '!data.error',
        };
    }, []);



    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
            show={show}
            onHide={onClose}
            onKeyDown={(event) => { //replace onKeyPress with onKeyDown
                let activeElement = document.activeElement
                let isButtonFocused = false
                if (activeElement && activeElement.tagName === 'BUTTON') {
                    isButtonFocused = true
                }
                if (event.key == 'Enter' && !isButtonFocused) {
                    let executeButton = document.getElementById('FeiRapidAddExecuteButton')
                   feiRapidAdd(executeButton, 'add');
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.HEADING.FEIRAPIDCLASSADD' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            {/* Modal body */}
            <div className='modal-body py-3 px-4'>
                <div className='row mb-2'>
                    <div className='col-2'>
                        <label className='col-form-label fs-5 py-1 w-lg-145px' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.FEIRAPIDCLASSADD.LABEL.CLASSNUMBER">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.LABEL.CLASSNUMBER' })}</label>
                    </div>
                    <div className='col-10'>
                        <input
                            type='text'
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            onChange={(e) => setClassNumbers(e.target.value)}
                            onBlur={(e) => handleBlur(e, 'validate')}
                            value={classNumbers}
                            autoFocus
                            tabIndex={1}
                        />
                    </div>
                </div>

                {/* Horse fei id text area */}
                <div className='row mb-2'>
                    <div className='col-12 '>
                        <label className='col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.FEIRAPIDCLASSADD.LABEL.ENTERHORSEFEIIDS">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.LABEL.ENTERHORSEFEIID' })}</label>
                    </div>
                    <div className='col-12'>
                        <textarea
                            className='form-control form-control-sm fs-6 min-h-20px py-1'
                            rows={7}
                            onChange={(e) => setHorseFeiIds(e.target.value)}
                            onBlur={(e) => handleBlur(e, 'validate')}
                            tabIndex={2}
                        />
                    </div>
                </div>

                {/* Errors grid */}
                <div>
                    <div className='col-12 '>
                        <label className='col-form-label fs-5 py-1 min-height' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.FEIRAPIDCLASSADD.LABEL.PROGRESS/ERRORMESSAGE">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.LABEL.PROGRESSORERROR' })}</label>
                    </div>
                    <div className='col-12 membership-data-grid'>
                        <OutputListingGridStatic
                            key='rapid-add-error-grid'
                            columnDefs={getColumnDefs()}
                            rowData={rowData}
                            setGridRef={setGridRef}
                            containerStyle={containerStyle}
                            rowClassRules={rowClassRules}
                            headerheight={0}
                            tabIndex={3}
                        ></OutputListingGridStatic>
                    </div>
                </div>

                {/* Footer buttons */}
                <div className='card-footer d-flex justify-content-center py-3 px-0'>
                    <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={4} data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.FEIRAPIDCLASSADD.BUTTON.DONE">
                        {intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.BUTTON.DONE' })}
                    </button>

                    <button
                        id='FeiRapidAddExecuteButton'
                        type="button"
                        className='btn btn-sm btn-dark fw-bold text-uppercase'
                        onClick={(e) => feiRapidAdd(e, 'add')}
                        tabIndex={5}
                        data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.FEIRAPIDCLASSADD.BUTTON.EXECUTE"
                    >
                        <span className="spinner-border spinner-border-sm d-none me-2" aria-hidden="true"></span>
                        {intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.BUTTON.EXECUTE' })}
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}
export default FeiRapidAdds;