/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import { useAppDispatch } from '../../../redux/hooks';
import { Link, useSearchParams , useNavigate } from 'react-router-dom'
import useChangeCompany from '../../hooks/use-company-change'
import { setNewShowId } from '../../../redux/reducers/newShowIdReducer'
import { SGL_CLOUD_REFERER } from '../core/AuthHelpers'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
  remember_me: 'no',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser, setCompanies , setUpdatePassword} = useAuth()
  const [searchParams, setSearchParams] = useSearchParams();
  const { setCompanyAuto } = useChangeCompany()
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password, values.remember_me === 'yes' ? 'yes' : 'no' )
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
        setUpdatePassword(auth.update_password)

        const companies = user.smc_access

        // Set All Companies
        setCompanies(companies)
        // Set company
        // - if passed in URL
        // - is a valid company
        // - and user has access to that company
        let urlCustomerId = Number(searchParams.get("customer_id"))
        let company = null
        if(urlCustomerId){  // /auth?customer_id=15
           // Do nothing but keeping the case here as auth pages are not handled through sgl-cloud-referer
        } else { //Check if referer URL called to set access/open url for specific company
          const sglReferer = sessionStorage.getItem(SGL_CLOUD_REFERER) 
          if (sglReferer) { //Referer specified?
            const { queryParams } = JSON.parse(sglReferer)
            if (queryParams) {
              if (queryParams.customer_id) { //Referral URL has customer_id param
                urlCustomerId = Number(queryParams.customer_id)
              }
            } 
          }
        }

        // Check if user sent company exists and user has access to it.
        if (urlCustomerId > 0) {
            company = companies.find(c => c.company_id === urlCustomerId)
        }
        
        if (company) { // company from params found and is allowed access company for user ??
            setCompanyAuto(user, company)
        } else if (companies.length === 1) { // Set company for single company user
            setCompanyAuto(user, companies[0])
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Login Failed: Your email or password is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(()=>{
        const togglePassword = document.querySelector('#togglePassword');
        const passwordField = document.querySelector('#login-password');
        togglePassword?.addEventListener('click', () => {
            // Toggle the type attribute using
            // getAttribure() method
            const type = passwordField?.getAttribute('type') === 'password' ? 'text' : 'password';
            passwordField?.setAttribute('type', type);
            // Toggle the eye and bi-eye icon
            togglePassword?.classList.toggle('bi-eye');
        });
  },[])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign in to Showgrounds Cloud</h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
        // <div className='mb-10 bg-light-info p-8 rounded'>
        //     <div className='text-info'>
        //       Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
        //       continue.
        //     </div>
        // </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          id='login-email'
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className="fv-help-block">
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
          </div>
        </div>
        <div className="position-relative">
          <input
            id='login-password'
            type='password'
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <i className="bi bi-eye-slash fs-3" id="togglePassword"></i>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='flex-50'>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
        </div>
        {/* begin::Forgot Password Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              >
              Forgot Password ?
            </Link>
        {/* end:: Forgot Password Link */}
        </div>
      </div>
      
      {/* end::Form group */}

      {/* begin::Remember Me */}
      <div className='form-check form-check-sm mb-5'>
        <input
          style={{ transform: 'scale(0.7)' }}
          className='form-check-input form-check-input-sm'
          type='checkbox'
          id='remember_me'
          {...formik.getFieldProps('remember_me')}
          checked={formik.values.remember_me === 'yes'}
          value='yes'
          onChange={(e) =>
            formik.setFieldValue('remember_me', e.target.checked ? 'yes' : 'no')
          }
        />
        <label className='form-check-label form-check-label-sm' htmlFor='remember_me'>
          Remember Me
        </label>
      </div>
      {/* end::Remember Me */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
