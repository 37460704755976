import { useMemo, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import { useAlert } from '../../../../modules/sgl-utils/DialogsProvider';
import HideJsonRenderer from '../../../../modules/output-listing/renderers/HideJsonRenderer';

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';
import { getExtraNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers';
import { isValidJson } from '../../../../modules/sgl-utils/SglFunctions';

const MembershipTab = (props) => {
    const intl = useIntl();
    const [gridRef, setGridRef] = useState()
    const [rowData, setRowData] = useState()
    const methods = useFormContext()
    const alertDialog = useAlert()

    const columnDefs = [
        { field: 'key', maxWidth: 245, minWidth:245, flex: 1, lockVisible: false, lockPosition: 'left', cellClass: 'text-start', cellStyle: {'border-right': '1px solid #eee'}
            ,cellRenderer: 'agGroupCellRenderer',
            cellRendererSelector: function (params) {
                if (params.data.value != null && Array.isArray(params.data.value) && params.data.value?.length > 0) {
                    params.node.setExpanded(true);
                }
            }
        },
        { field: 'value', flex: 1, lockVisible: false, lockPosition: 'left', cellClass: 'text-start', cellRenderer: HideJsonRenderer },
    ]

    const containerStyle = useMemo(() => ({ width: '100%', height: '96vh' }), []);

    useEffect(() => {
        if(gridRef){
            if (props.tabname.toLowerCase() == "norcal" && methods.getValues('people.norcal_lookup_response') && isValidJson(methods.getValues('people.norcal_lookup_response'))){
                parseLookupData(JSON.parse(methods.getValues('people.norcal_lookup_response')))
            }
            else if (props.tabname == "OHJA" && methods.getValues('people.ohja_lookup_response') && isValidJson(methods.getValues('people.ohja_lookup_response'))){
                parseLookupData(JSON.parse(methods.getValues('people.ohja_lookup_response')))
            }
            else if (props.tabname == "USHJA" && methods.getValues('people.ushja_lookup_response') && isValidJson(methods.getValues('people.ushja_lookup_response'))){
                parseLookupData(JSON.parse(methods.getValues('people.ushja_lookup_response')))
            }
            else{
                gridRef.setRowData([])
            }
        }
    }, [gridRef, methods.getValues('people.norcal_lookup_response'), methods.getValues('people.ohja_lookup_response'), methods.getValues('people.ushja_lookup_response')])

    const parseLookupData = (lookup) => {
        if (lookup){
            var lookup_entries = Object.entries(lookup)
            let lookupResponseData = []
            lookup_entries.map(([key, value]) => {
                // if(value != null && Array.isArray(value)){
                //     value.map((data) => {
                //         let lookup = Object.entries(data)
                //         lookup.map(([key, value]) => {
                //             lookupResponseData.push({'key': key, "value": value})
                //         })
                //     })
                // }
                // else{
                    lookupResponseData.push({'key':key, "value":value})
                // }
            })
            gridRef.setRowData(lookupResponseData)
        }
    }

    const lookup_data = (associated_membership) => {
        let memObj = {}
        let org = props.tabname

        if(associated_membership){ // If associated membership is return, fetch associated membership row data
            org = associated_membership
        }

        props.membershipGridState.forEachNode(node => {
          if(org == node.data.organization_code){
            memObj = JSON.parse(JSON.stringify(node.data))
          }
        });

        if(associated_membership){ // Update the associated membership with tabname organization code. for e.g: If USEF membership is fetch change it to norcal
            memObj.organization_code = props.tabname
        }
        
        if (props.tabname.toLowerCase() == "norcal"){
            if(String(memObj.membership_number)?.trim() != ""){
                props.getLookupData(org, memObj, 'emLookup', false, associated_membership)
            } else {
                alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.TAB.ERROR.EC.INVALID' })})
            } 
        }
        else if (props.tabname.toLowerCase() == "ohja"){
            if(String(memObj.membership_number)?.trim() != ""){
                props.getLookupData(org, memObj, 'emLookup', false, associated_membership)
            } else {
                alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.TAB.ERROR.NORCAL.INVALID' })})
            } 
        }
        else if (props.tabname.toLowerCase() == "ushja"){
            if(String(memObj.membership_number)?.trim() != ""){
                props.getLookupData(org, memObj, 'ushjaLookup')
            } else {
                alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.TAB.ERROR.USHJA.INVALID' })})
            } 
        }
    }

    return (
        <>
            <div className='form-group'>
                <div className='row align-items-center mb-2 membership-data-grid'>
                    <OutputListingGridStatic area ={"People - Membership"} setGridRef={setGridRef} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle} rowHeight={getExtraNarrowRowHeight} suppressRowClickSelection={true} masterDetail={true} keepDetailRows={true} keepDetailRowsCount={2} detailRowAutoHeight={true}></OutputListingGridStatic>
                </div>
                <div className='row align-items-center mb-2'>
                    <div className='col-lg-5 w-350px'>
                        <button type="button" onClick={() => (lookup_data())} className="btn btn-sm btn-secondary fw-bold h-30px py-1 text-uppercase me-2">
                            {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.UPDATE' })}
                        </button>
                        {props.tabname != 'USHJA' && 
                            <button type="button" onClick={() => (lookup_data(props.tabname.toLowerCase() == 'norcal'? 'USEF': props.tabname.toLowerCase() == 'ohja'? 'EC': null))} className="btn btn-sm btn-secondary fw-bold h-30px py-1 text-uppercase">
                                {intl.formatMessage({ id: `FORM.INPUT.PEOPLE.TAB.ADDITIONAL.BUTTON.${props.tabname.toUpperCase()}` })}
                            </button>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export { MembershipTab }