import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Assets
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import { KTSVG } from '../../../../_metronic/helpers';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls';
import axios from 'axios';

// Constants
const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const ClearOrganizationDates = ({ show, handleClose }) => {
    const [selectedOrganization, setSelectedOrganization] = useState({orgCode: '', clearStartDate: false, clearEndDate: false});
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const customerId = useAppSelector(state => state.showCompany.company_id);
    const alertDialog = useAlert();
    const confirmDialog = useConfirm();
    const { getOutputSelectionAreaIDs } = useOutputContext();
    const loadingOverlay = useLoadingOverlay();
    const intl = useIntl();

    // Get metadata
    useEffect(() => {
        axios.get(`${NEST_API_URL}/people/getClearOrganizationDatesMetaData`, {
            params: {
                customer_id: customerId,
                entity_type: 'People'
            }
        })
            .then(response => {
                if (response.data.success) {
                    // Create thses options for react select
                    const options = response.data.organizations.map(org => ({
                        label: org.code, value: org.code
                    }))

                    setOrganizationOptions(options);
                }
            })
            .catch((error) => {
                setOrganizationOptions([]);
            })
    }, [])

    const handleSubmit = async () => {
        // Validate on FE to avoid unnecessary API calls
        // If no organization is selected, then show error
        if (selectedOrganization.orgCode === '') {
            alertDialog({ message: intl.formatMessage({ id: 'PEOPLE.QUICKACTION.CLEARORGDATES.POPUP.ALERT.SELECTORGANIZATIONERRORMESSAGE' }) });
            return false;
        }

        // If not clearing any date, then show error
        if (!selectedOrganization.clearStartDate && !selectedOrganization.clearEndDate) {
            alertDialog({ message: intl.formatMessage({ id: 'PEOPLE.QUICKACTION.CLEARORGDATES.POPUP.ALERT.SELECTDATESERRORMESSAGE' }) });
            return false;
        }

        // Get selected people ids
        loadingOverlay({ show: true, message: 'Fetching Records...' })
        let peopleIds = await getOutputSelectionAreaIDs();
        loadingOverlay({ show: false, message: 'Fetching Records...' })

        if (peopleIds.length === 0) {
            alertDialog({ message: intl.formatMessage({ id: 'PEOPLE.QUICKACTION.CLEARORGDATES.POPUP.ALERT.NOPEOPLEINSELECTION' }) });
            return false;
        };

        // If everything is fine, then ask user to confirm
        const choice = await confirmDialog({ message: `Are you sure you wish to clear the dates for ${selectedOrganization.orgCode} for the ${peopleIds.length} selected people.` })

        if (choice) {
            loadingOverlay({ show: true, message: 'Clear Organization Dates' })
            // If user confirms, then call API
            axios.post(`${NEST_API_URL}/people/clearPeopleOrganizationDates`, {
                customer_id: customerId,
                record_ids: peopleIds,
                org_code: selectedOrganization.orgCode,
                clear_start_date: selectedOrganization.clearStartDate,
                clear_end_date: selectedOrganization.clearEndDate,
            })
                .then(async response => {
                    if (response.data.success) {
                        // Close modal
                        handleClose();
                    } else {
                        alertDialog({ message: response?.data?.errorMessage })
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        alertDialog({ message: intl.formatMessage({ id: 'GENERIC.ERROR.MESSAGE' }) });
                    }
                })
                .finally(() => {
                    loadingOverlay({ show: false, message: 'Clear Organization Dates' })
                    handleClose()
                })
            
        } else {
            return false;
        }

    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-480px search-form'
            show={show}
            onHide={handleClose}
            onEscapeKeyDown={handleClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    handleSubmit();
                }
                if (event.key === 'Escape') {
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'PEOPLE.QUICKACTION.CLEARORGDATES.POPUP.HEADING' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            {/* Body */}
            <div className='modal-body py-3 px-4'>
                {/* Message */}
                <div className='row mb-2'>
                    <label className='col-lg-12 col-form-label fs-5 py-1 w-lg-140px w-lg-140px'>
                        {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.CLEARORGDATES.POPUP.LABEL.MESSAGE' })}
                    </label>
                </div>

                {/* Select Organizations */}
                <div className='row mb-2'>
                    <label className='col-lg-3 col-form-label  fs-5 py-1 w-lg-140px w-lg-140px' htmlFor='organization'>
                        {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.CLEARORGDATES.POPUP.LABEL.ORGANIZATION' })}
                    </label>
                    <div className='col-lg-9'>
                        <Select
                            options={organizationOptions}
                            style={reactSelectStyles}
                            onChange={(selectedOption) => {
                                setSelectedOrganization(prevState => ({
                                    ...prevState,
                                    orgCode: selectedOption.value
                                }));
                            }}
                            value={organizationOptions.find(org => org.value == selectedOrganization.orgCode)}
                            autoFocus
                            tabIndex={1}
                        />
                    </div>
                </div>

                {/* Checkboxes */}
                <div className='row mb-2'>
                    {/* Clear start date checkbox */}
                    {/* Added this empty div because offset was not working */}
                    <div className='col-lg-3'></div> 
                    <div className='col-lg-9 form-check-sm form-check-custom ps-lg-3'>
                        <input
                            id="clearStartDate"
                            className='form-check-input'
                            type='checkbox'
                            onChange={(e) => {
                                setSelectedOrganization(prevState => ({
                                    ...prevState,
                                    clearStartDate: e.target.checked
                                }));
                            }}
                            tabIndex={2}
                        />
                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor="clearStartDate">
                            {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.CLEARORGDATES.POPUP.LABEL.CLEARSTARTDATE' })}
                        </label>
                    </div>
                </div>
                <div className='row mb-2'>
                    {/* Clear end date checkbox */}
                    <div className='col-lg-3'></div> 
                    <div className='col-lg-9 form-check-sm form-check-custom ps-lg-3'>
                        <input
                            id="clearEndDate"
                            className='form-check-input'
                            type='checkbox'
                            onChange={(e) => {
                                setSelectedOrganization(prevState => ({
                                    ...prevState,
                                    clearEndDate: e.target.checked
                                }));
                            }}
                            tabIndex={3}
                        />
                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor="clearEndDate">
                            {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.CLEARORGDATES.POPUP.LABEL.CLEARENDDATE' })}
                        </label>
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-3 px-0'>

                    <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={4}>
                        {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.CLEARORGDATES.POPUP.LABEL.CANCEL' })}
                    </button>

                    <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={handleSubmit} tabIndex={5}>
                        {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.CLEARORGDATES.POPUP.LABEL.CLEAR' })}
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default ClearOrganizationDates;
