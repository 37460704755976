import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { renderCheckBox } from "../../../../modules/sgl-utils/agGridHelpers";
import { useCurrencyFormatter } from "../../../../modules/sgl-utils/Formatters";
import { defaultColDef, getNarrowHeaderHeight,getNarrowRowHeight } from "../../../../modules/sgl-utils/agGridHelpers";
import { getRowData } from "../../../../modules/sgl-utils/agGridHelpers";
import Decimal from "decimal.js";

const EntriesGrid = ({ gridRef, setGridRef, info, setForceReapplySplit, tabIndex=0 }) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    const columnDefs = [
        { 
            field: 'exclude_from_trainer_split',
            headerName: intl.formatMessage({ id: "Entries.QuickAction.TrainerSplit.Grid.Exclude" }),
            cellRenderer: params => !params.node.rowPinned ? renderCheckBox(params, () => setForceReapplySplit(prevState => !prevState)) : null,
            width: 100,
            cellStyle: () => ({ textAlign: 'left' })
        },
        { 
            field: 'number', 
            headerName: intl.formatMessage({ id: 'LIST.ENTRIES.NUMBER' }), 
            cellStyle: { textAlign: 'center' },
            width: 100,
        },
        { 
            field: 'owner', 
            headerName: intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.GRID.OWNER" }), 
            flex: 1,
        },
        { 
            field: 'horse', 
            headerName: intl.formatMessage({ id: 'LIST.ENTRIES.HORSE' }),
            flex: 1,
        },
        { 
            field: 'amount', 
            headerName: intl.formatMessage({ id: "Entries.QuickAction.TrainerSplit.Grid.Amount" }),
            width: 120,
            cellRenderer: params => currencyFormatter(params.value),
            editable: params => !params.data.exclude_from_trainer_split && !params.node.rowPinned, // Allow edit only if exclude is false,
            valueSetter: params => { 
                params.data.amount = parseFloat(params.newValue)
                return true
            }
        },
    ]

    return (
        <div>
            <div className="row mb-2">
                <label className='col-lg-4 col-form-label fs-5 py-1 me-10'>
                    { intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.GRID.LABEL" }) }
                </label>
                <label className='col col-form-label fs-5 py-1 text-truncate'>
                        { info }
                </label>
            </div>
    
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        rowData={[]}
                        columnDefs={columnDefs} 
                        rowStyle={{ fontSize: '12px' }}
                        onGridReady={params =>  { 
                            setGridRef(params.api)
                        }}
                        onCellEditingStopped={ () => {
                            const rowData = getRowData(gridRef)
                            let sum = new Decimal(0)
                            for (let row of rowData) {
                                sum = sum.plus(new Decimal(row.amount))
                            }
                            gridRef.setPinnedBottomRowData([{ amount: parseFloat(sum.toString()) }])
                        }}
                        suppressScrollOnNewData={true}
                        headerHeight={getNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                        stopEditingWhenCellsLoseFocus={true}
                        {...(tabIndex !== 0 && { tabIndex })} //tabIndex is set conditionally, only if a prop was passed, provided that the prop is not 0 
                    />
                </div>
            </div>
        </div>
       
    )
}


export default EntriesGrid