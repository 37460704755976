import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import { useFormContext } from 'react-hook-form';

const modalsRoot = document.getElementById('root-modals') || document.body

const ImportFEIResults = ({ show, handleClose, eventsData, getImportMappings }) => {
    const intl = useIntl() 
    const loadingOverlay = useLoadingOverlay()
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const [displayErrors, setDisplayErrors] = useState(false)
    const [gridRef, setGridRef] = useState();
    const eventsContainerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const resultsContainerStyle = useMemo(() => ({ width: '100%', height: '200px' }), []);
    const [importStatsData, setImportStatsData] = useState([]);
    const [importAccessError, setImportAccessError] = useState('');
    
    const { getEventID } = useOutputContext();
    const {progressBar} = useProgress()
    const alertDialog = useAlert();
    const confirmDialog = useConfirm();
    const methods = useFormContext();
    const [eventsImported, setEventsImported] = useState(false);

    // Render checkbox for import entries
    const renderCheckBox = (params) => {
        return (
            <input
                type="checkbox"
                onChange={(e) => {
                    params.node.setDataValue(params.column.colId, e.target.checked)
                }}
                checked={params.data.import_entries}
                disabled={!params.data.import_events}
            />
        )
    }

    // Event checkbox change handler
    const renderImportEventOpenCheckbox = async (e, params) => {
        let value = e.target.checked;
        if (value == true) {
            if (params.data.event_present_in_db) {
                let choice = await confirmDialog({ message: "This event has already been imported. Are you sure you want to import it again?" })

                if (!choice){
                    value = false;
                    e.target.checked = false;
                    return;
                }
             }
             params.node.setDataValue('import_entries', true);

        } else {
            // Update import entries to false
            params.node.setDataValue('import_entries', false);
        }
        params.node.setDataValue(params.column.colId, value);
    }

    // Cell renderer for is_open field in events list
    const renderEventOpenCheckbox = (params) => {
        return (
            <input
                type="checkbox"
                disabled={true}
                defaultChecked={params.value}
            />
        )
    }

    const onClose = () => {
        loadingOverlay({ show: false })
        handleClose();  
    }

    // Close this modal and open another modal
    const importFeiEntries = async () => {
        await getImportMappings();
        handleClose();
    }

    // Column definitions for events grid
    const getEventsColDefs = () => {
        return [
            { 
                field: 'import_events',
                headerName: "", 
                cellStyle: {textAlign: 'center'},
                suppressSizeToFit: true,
                maxWidth: 50,
                headerClass: 'custom-ag-grid-header', 
                cellRenderer: (params) => {
                    return (
                        <input
                            type="checkbox"
                            defaultChecked={!params.data.event_present_in_db?true:false}
                            onChange={(e) => {renderImportEventOpenCheckbox(e, params)}}
                            value={params.value}
                        />
                )},
            },
            { field: 'event_code', headerClass: 'custom-ag-grid-header',  cellStyle: {textAlign: 'left'},  width:170, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.EVENTCODE" })},
            { 
                field: 'import_entries',
                headerName: "Import Entries", 
                cellStyle: {textAlign: 'center'},
                suppressSizeToFit: true,
                width: 125,
                headerClass: 'custom-ag-grid-header', 
                cellRenderer: renderCheckBox
            },
            { field: 'total_horses', headerClass: 'custom-ag-grid-header',  cellStyle: {textAlign: 'center'}, width:100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.TOTALHORSES" })},
            { field: 'total_athletes', headerClass: 'custom-ag-grid-header',   cellStyle: {textAlign: 'center'}, width: 128, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.TOTALATHLETES" }) },
            { field: 'discipline_name', headerClass: 'custom-ag-grid-header',  cellStyle: {textAlign: 'left'}, width: 100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.DISCIPLINE" })},
            { field: 'category', headerClass: 'custom-ag-grid-header',  cellStyle: {textAlign: 'left'}, width: 100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.CATEGORY" })},
            // { field: 'is_open', cellStyle: {textAlign: 'left'}, width: 50, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.OPEN" })},
            { 
                field: 'is_open',
                headerClass: 'custom-ag-grid-header', 
                headerName: "Open", 
                cellStyle: {textAlign: 'center'},
                suppressSizeToFit: true,
                maxWidth: 80,
                cellRenderer: renderEventOpenCheckbox
            },
            { field: 'event_type_code', headerClass: 'custom-ag-grid-header',  cellStyle: {textAlign: 'left'}, width: 30, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.TYPECODE" })},
        ]
    }

    // Column defs for error list
    const getSummaryColDefs = () =>{
        return [
            { field: 'title', headerClass: 'custom-ag-grid-header', cellStyle: {textAlign: 'left'}, width:150, sortable: true, headerName: ''},
            { field: 'received', headerClass: 'custom-ag-grid-header', cellStyle: {textAlign: 'center'}, width:140, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.TOTALRECEIVED" })},
            { field: 'created', headerClass: 'custom-ag-grid-header', cellStyle: {textAlign: 'center'}, width:120, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.CREATED" })},
            { field: 'existing', headerClass: 'custom-ag-grid-header', cellStyle: {textAlign: 'center'}, width:120, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.EXISTING" })},
        ]
    }

    const importEvents = async () => {
        let selected_rows = eventsData.filter(row => row.import_events == true);
        let selected_events = selected_rows.map(row => row.event_code);

        if (selected_rows.length == 0) {
            alertDialog({ message: intl.formatMessage({ id: "SHOWS.IMPORTFEIEVENTS.MODAL.SELECTROWSMESSAGE"}) })
            return;
        }

        const event_id = getEventID("import-show-FEI-Events");

        const postBody =  {
            show: methods.getValues('show'),
            customer_id: customerId,
            events_to_import: selected_rows,
            progress_event_id: event_id
        };
        progressBar({ show: true, eventID: event_id, showProgress: 'inline', title: "Importing FEI data", timeElapsed: true })

        axios.post( process.env.REACT_APP_NEST_API_URL + '/shows/importFeiEvents', { params: postBody })
        .then((response) => {
            if (response?.data?.success) {
                if(response?.data?.display_access_msg){
                    setImportAccessError(response?.data?.display_access_msg)
                }
                setImportStatsData(response?.data?.import_stats_array);
                progressBar({ show: false, eventID: event_id, showProgress: 'inline', title: "Importing FEI data", timeElapsed: true })
                const feiImportedData = response?.data?.fei_imported_data;
                methods.setValue('fei_imported_data', feiImportedData, { shouldDirty: true })
                setEventsImported(true)
            } else {
                // loadingOverlay({ show: false, message: "FEI Import Events" })
                progressBar({ show: false, eventID: event_id, showProgress: 'inline', title: "Importing FEI data", timeElapsed: true })
                alertDialog({ message: response.data.error_message})
                
            }
        })
        .catch(() => progressBar({ show: false, eventID: event_id, showProgress: 'inline', title: "Importing FEI data", timeElapsed: true }))
    }

    // Refresh the cell here so that the import entries is now enabled
    const onCellValueChanged = async (params) => {
        if (params.column.colId == 'import_events') {
            gridRef.refreshCells({ force: true })
            gridRef.refreshCells({ rowNodes: [params.rowIndex] });
            gridRef.refreshCells({ columns: [params.column.colId] });
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={true}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px search-form'
            show={show}
            onHide={onClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    importEvents()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.MODAL.HEADING.FEISHOWEVENTS' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className="d-flex mb-2">
                            <div className='col-2'>
                                <label className='col-lg-12 col-form-label fs-5 py-1 fw-bold' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWRESULTS.LABEL.FEISHOWCODE">
                                    { intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.FEISHOWCODE"  }) }
                                </label>
                            </div>
                            <div className='col-10'>
                                <label className='col-lg-12 col-form-label fs-5 py-1'>
                                    {methods.getValues('show')?.fei_show_code}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <div className='col-12'>
                            {/* Events grid */}
                            <OutputListingGridStatic
                                key='export-grid'
                                columnDefs={getEventsColDefs()}
                                rowData={eventsData}
                                setGridRef={setGridRef}
                                containerStyle={eventsContainerStyle}
                                onCellValueChanged = {onCellValueChanged}
                                headerHeight={30}
                                autoFocus
                            ></OutputListingGridStatic>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex justify-content-center'>
                                        <div className='text-danger'>
                                            {eventsImported && (
                                                <label className='fs-7 py-1 fw-bold'>Import Changes Pending. Click 'Save Show' to Apply Changes.</label>
                                            )}
                                        </div>
                                    </div>
                                    <div className='d-flex flex-end'>
                                        <button type="button" onClick={(e) => { onClose(); e.preventDefault()}} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' daa-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWRESULTS.BUTTON.CLOSEFEIEXPORT">
                                            {intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.MODAL.BUTTON.CLOSEFEIEXPORT' })}
                                        </button>
                                        <button type="button" onClick={() => importFeiEntries()} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWRESULTS.BUTTON.IMPORTENTRIES">
                                            {intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.MODAL.BUTTON.IMPORTENTRIES' })}
                                        </button>
                                        <button type="button" disabled={eventsImported} onClick={() => importEvents()} className='btn btn-sm btn-secondary fw-bold text-uppercase' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWRESULTS.BUTTON.IMPORTEVENTS">
                                            {intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.MODAL.BUTTON.IMPORTEVENTS' })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-2'>
                                    <label className='col-lg-12 col-form-label fs-5 py-1 fw-bold' data-tooltip-id="SHOW.DETAIL.MODAL.FEISHOWRESULTS.LABEL.IMPORTRESULTSERRORS">
                                        {intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.LABEL.IMPORTRESULTSERRORS' })}
                                    </label>
                                </div>
                                <div className='col-7'>
                                    {/* Summary grid */}
                                    <OutputListingGridStatic
                                        key='export-grid'
                                        columnDefs={getSummaryColDefs()}
                                        rowData={importStatsData}
                                        setGridRef={setGridRef}
                                        containerStyle={resultsContainerStyle}
                                        headerHeight={30}
                                    ></OutputListingGridStatic>
                                </div>
                                <div className='col-3 text-danger'>
                                    {importAccessError?intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.LABEL.IMPORTRESULTACCESSERRORS' }):''}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default ImportFEIResults;