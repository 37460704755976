 import { useIntl } from 'react-intl'
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls';
import Select from "react-select";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAppSelector } from '../../../redux/hooks';
import moment from 'moment'
import { useNavigate } from 'react-router';
import { usePaymentBatchContext } from '../PaymentBatchContext';

const ShowSection = ({tabName}) => {
    const intl = useIntl();
    const [showsList, setShowList] = useState([])
    const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
    const customerID = useAppSelector(state => state.showCompany.company_id);
    const navigate = useNavigate()
    const {selectedShow, setSelectedShow, setSelectedShowEndDate, setUpdatePostPaymentsTabData, setSelectedBatch, refreshPaymentBatch, setRefreshPaymentBatch, openedFrom}= usePaymentBatchContext()

    useEffect(() => {
        axios.get(`${NEST_API_URL}/shows?customer_id=${customerID}`)
        .then(response => {   
            let showOptions = response?.data?.shows?.map((showRecord) => ({
                value: showRecord.show_id,
                label: showRecord.show_name,
                end_date: showRecord.end_date
            }));
            showOptions?.unshift({value: '', label: 'Pick Show...'});
            setShowList(showOptions ?? [])
        })
    }, [customerID])

    useEffect(() => {
        if(selectedShow){
            let show = showsList.find((show) => show.value == selectedShow)
            setSelectedShowEndDate(moment(show?.end_date).format('MM/DD/YY'))
        }else{
            setSelectedShowEndDate('')
        }
    }, [showsList, selectedShow])

    return (
        <>
        {/* Hide the Top label if payment batch is opened from listing */}
            {
                openedFrom === 'Add New' &&
                <div className='row mb-2 col-6 mt-2'>
                    <label className='col-form-label fw-bold fs-5 py-1 pe-0' data-tooltip-id={`PAYMENTBATCHES.DETAIL.TAB.${tabName}.LABEL.STEP`}>{intl.formatMessage({ id: `FORM.INPUT.PAYMENT.BATCHES.TAB.${tabName}.Message` })}</label>
                </div>
            }
            
            {/* Show Selection Drop Down */}
            <div className='row mb-2 col-lg-8'>
                <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 label-width-130 pe-0' htmlFor='SelectedShow'  data-tooltip-id={`PAYMENTBATCHES.DETAIL.TAB.${tabName}.LABEL.SHOW`}>{intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.SHOW.SECTION.LAB' })}</label>
                <div className='col min-w-450px mw-450px'>
                    <Select 
                        options={showsList}
                        id="SelectedShow"
                        value={showsList?.find((option) => option.value == selectedShow ?? '')}
                        onChange={(e) => {
                            setSelectedShow(e.value)
                            setSelectedBatch(0) // clear payment batch selection
                            setUpdatePostPaymentsTabData({show_id:e.value, batch_id: 0 })
                            navigate('/payment-batches/detail/'+0+`?customer_id=${customerID}&batch_show_id=${e.value}`)
                        }}
                        styles={reactSelectStyles}
                        isDisabled={!(openedFrom === 'Add New')} // disable select if payment batch is opened from listing
                    />
                </div>

                <div className='col w-lg-75px px-0 d-flex align-items-center' onClick={() => setRefreshPaymentBatch(!refreshPaymentBatch)}>
                    <i className='far fa-solid fa-rotate text-success fs-2 fw-bold cursor-pointer'></i>
                </div>
            </div>
        </>
    );
}

export { ShowSection }