import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls'
import { useReportsContext } from '../ReportsContext'
import moment from 'moment'
import { ReportGroupModal } from './ReportGroupModal'

const GeneralTab = ({report_id}) => {
    const intl = useIntl()
    const { metaData, setCustomerId, customerId, comments, setComments, singlePrintPerRecord, setSinglePrintPerRecord, subReportId, setSubReportId, response, reportGroup, setReportGroup, systemReport, setSystemReport, subreportPrintAction, setSubreportPrintAction } = useReportsContext()
    const [showGroupModal, setShowGroupModal] = useState(false);

  return (
    <>     
        {
        ((singlePrintPerRecord || report_id == 0)  )?
        <>
            {/* Sub-reports Dropdown DROPDOWN */}
            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='subreport_id' data-tooltip-id="REPORT.DETAIL.TAB.GENERAL.LABEL.SUBREPORT">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.REPORT'})}</label>

                <div className='col-lg-4'>
                    { metaData && 
                    <Select
                        placeholder="Select"
                        options={metaData.subReport}
                        value={subReportId?.split(',').map((id) => metaData.subReport?.find((subreport) => subreport.value == id))}
                        id="subreport_id"
                        isSearchable={true}
                        onChange={(e) => {
                            setSubReportId(e.map((subreport) => subreport.value).join(','));
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                        })}
                        styles={reactSelectStyles}
                        isMulti={true}
                    />  
                    }  
                </div>
                <label className='col mx-2 fs-5 py-1 text-primary' htmlFor='single_print_per_record'><em>{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.SINGLEPAGENOTE'})}</em></label>
            </div> 

            {/* Print action dropdown */}
            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='subreport_id' data-tooltip-id="REPORT.DETAIL.TAB.GENERAL.LABEL.PRINTACTION">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.PRINTACTION'})}</label>

                <div className='col-lg-4'>
                    { metaData && 
                    <Select
                        placeholder="Select"
                        options={metaData.subreportPrintAction}
                        value={metaData?.subreportPrintAction?.find((action) => action.value == subreportPrintAction)}
                        id="subreport_print_action"
                        isSearchable={true}
                        onChange={(e) => {
                            setSubreportPrintAction(e.value);
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                        })}
                        styles={reactSelectStyles}
                    />  
                    }  
                </div>
            </div> 
        </>
         :null
        }      
    {/* CUSTOMER DROPDOWN */}
        <div className='row mb-2'>
            <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='company_name' data-tooltip-id="REPORT.DETAIL.TAB.GENERAL.LABEL.COMPANY">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.COMPANY'})}</label>

            <div className='col-lg-4'>
                { metaData && 
                <Select
                    placeholder="Select"
                    options={metaData.smc}
                    value={metaData.smc?.find((ss) => ss.value == customerId)}
                    id="company_name"
                    isSearchable={true}
                    onChange={(e) => {
                        setCustomerId(e.value);
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                    })}
                    styles={reactSelectStyles}
                />  
                }  
            </div>
        </div>

    {/* System Report */}
        {report_id == 0 ?
         <div className='row mb-2'>
            <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='system_report' data-tooltip-id="REPORT.DETAIL.TAB.GENERAL.LABEL.SYSTEMREPORTNO">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.SYSTEMREPORTNUMBER'})}</label>

            <div className='col-lg-4'>
                <input
                    value={systemReport}
                    type='text'
                    id='system_report'
                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                    onChange={(e) => setSystemReport(e.target.value)}
                />
            </div>
        </div>
        : null }

        {/* Report group dropdown */}
        <div className='row mb-2'>
            <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='report_group' data-tooltip-id="REPORT.DETAIL.TAB.GENERAL.LABEL.REPORTGROUP">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.GROUP'})}</label>

            <div className='col-lg-4'>
                { metaData && 
                <Select
                    isSearchable={true}
                    placeholder="Select"
                    options={metaData?.reportGroup}
                    value={reportGroup && metaData.reportGroup?.find((g) => g.value == reportGroup)}
                    id="report_group"
                    onChange={(e) => {
                        setReportGroup(e.value);
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                    })}
                    styles={reactSelectStyles}
                />  
                }
            </div>

            {/* Add new group button */}
            { metaData && 
            <button
                className='btn btn-sm btn-secondary fw-bold text-uppercase h-120 py-1 d-flex align-items-center mx-1'
                style={{width: 'fit-content'}}
                type='button'
                onClick={() => {
                    setShowGroupModal(true)
                }}
                data-tooltip-id="REPORT.DETAIL.TAB.GENERAL.BUTTON.ADD"
            >
                <i className='fas fa-plus fs-5 px-1 py-3'></i>
            </button> }

            {/* Add new report group modal */}
            { showGroupModal && <ReportGroupModal show={showGroupModal} handleClose={() => setShowGroupModal(false)} /> }
        </div>
        <div className='row mb-2'>
            <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='comments' data-tooltip-id="REPORT.DETAIL.TAB.GENERAL.LABEL.NOTES/COMMENTS">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.NOTES'})}</label>

            <div className='col-lg-6'>
                <textarea
                    value={comments}
                    placeholder="Comments"
                    id="comments"
                    className='form-control h-150px form-control fs-6'
                    onChange={(e) => setComments(e.target.value)}
                ></textarea>  
            </div>
        </div>
        { response?.report?.CreatedBy ? 
        <>
            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='created_by_label' data-tooltip-id="REPORT.DETAIL.TAB.GENERAL.LABEL.CREATEDBY">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.CREATEDBY'})}</label>
                <label className='col-lg-3 col-form-label fs-5 py-1' htmlFor='created_by'>{ response?.report?.CreatedBy ? response?.report?.CreatedBy?.first + ' ' + response?.report?.CreatedBy?.last_name: null }</label>
            </div>
            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='created_datetime_label'  data-tooltip-id="REPORT.DETAIL.TAB.GENERAL.LABEL.CREATEDDATETIME">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.CREATEDATETIME'})}</label>
                <label className='col-lg-3 col-form-label fs-5 py-1' htmlFor='created_datetime'>{response?.report?.creation_date && moment(response?.report?.creation_date).isValid()? moment(response.report.creation_date).format('MM/DD/YYYY'): null} {response?.report?.creation_time && response.report.creation_time != '00:00:00' ? moment(response.report.creation_time, "HH:mm:ss").format('h:mm A'): null}</label>
            </div>
            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='updated_by_label'  data-tooltip-id="REPORT.DETAIL.TAB.GENERAL.LABEL.UPDATEDBY">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.UPDATEDBY'})}</label>
                <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='updated_by'>{ response?.report?.ModifiedBy? response?.report?.ModifiedBy?.first + ' ' + response?.report?.ModifiedBy?.last_name: null }</label>
            </div>
            <div className='row mb-2'>
                <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='modified_datetime_label'  data-tooltip-id="REPORT.DETAIL.TAB.GENERAL.LABEL.UPDATEDDATETIME">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.UPDATEDATETIME'})}</label>
                <label className='col-lg-4 col-form-label fs-5 py-1' htmlFor='modified_datetime'>{response?.report?.modified_date && moment(response?.report?.modified_date).isValid()? moment(response.report.modified_date).format('MM/DD/YYYY'): null} {response?.report?.modified_time && response?.report?.modified_time != '00:00:00' ? moment(response?.report?.modified_time, "HH:mm:ss").format('h:mm A'): null}</label>
            </div>
        </>
        :
        null}
    </>
  )
}

export { GeneralTab }
