import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { AddHorseForm } from '../../../pages/horses/AddHorseForm';

const AddHorseMenu = ({ hasWriteAccess, addNewRecordButtonRef }: { hasWriteAccess: boolean; addNewRecordButtonRef: React.RefObject<HTMLAnchorElement> }) => {
    const intl = useIntl()
    const [showHorseLookupForm, setShowHorseLookupForm] = useState(false)
    const customer_id = useAppSelector((state) => state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const governingOrganization = useAppSelector(state => state.showCompany.governing_organization)

    return (
        <>
            <div>
                <a ref={addNewRecordButtonRef} href='#' className="btn btn-icon btn-active-light me-3 px-8 py-8 add-new-record-button"
                    onClick={() => hasWriteAccess && setShowHorseLookupForm(true)}>
                    <div className="fa-item">
                        <i className={`fa fa-plus-circle fs-1 text-${hasWriteAccess ? "green" : "gray"}`}></i>
                        <br/>
                        <span className="fs-8 fw-bold">{intl.formatMessage({id: 'TOOLBAR.LABEL.ADD'})}</span>
                    </div>
                </a>
                
            </div>
            {(showHorseLookupForm) &&
                <AddHorseForm 
                show={showHorseLookupForm} 
                handleClose={() => setShowHorseLookupForm(false)}
                customer_id={customer_id} 
                show_id={currentShowID}
                default_org={governingOrganization}
              />
            }
        </>
    )
}

export { AddHorseMenu }
