import { useEffect, useState } from "react"

const useStorage = () => { 
    const [localStorageChanged, setLocalStorageChanged] = useState(0)

    const updatedLocalStorage = () => { 
        console.warn('Change in local storage detected.')
        setLocalStorageChanged(prevLocalStorageChanged => prevLocalStorageChanged + 1)
    }

    useEffect(() => {
        window.addEventListener('storage', updatedLocalStorage);
        window.addEventListener('custom-col-defs-updated-event', updatedLocalStorage);
        
        // Remove the event listener when the component unmounts
        return () => {  
            window.removeEventListener('storage', updatedLocalStorage);
            window.removeEventListener('custom-col-defs-updated-event', updatedLocalStorage);
        }
    }, [])

    return { localStorageChanged }
}

export default useStorage