import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { useFormContext } from "react-hook-form";

const DivisionsTab = () => {
    const intl = useIntl();
    const methods = useFormContext();

    const columnDefs = [
        { field: 'name', flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.DIVIDIONS.NAME' }), },
        { field: 'price', headerName: intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.DIVIDIONS.PRICE' }),  },
        { field: 'rating', headerName: intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.DIVIDIONS.RATING' }),  },
        { field: 'number_of_classes', headerName: intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.DIVIDIONS.NUMBEROFCLASSES' }),    },
    ]

    const rowData = methods.getValues('divisionsList')

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

    return (
        <>
            <div className='form-group'>
                <div className='row mb-2 pe-4'>
                    <label className='col col-form-label fs-5 py-1 text-end'>{rowData.length}</label>
                    <label className='col-lg-1 w-50px p-0'></label>
                </div>
                <div className='row mb-2 pe-4'>
                    <div className='col'>
                        <OutputListingGridStatic area ={"Divisions - Division"} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle}></OutputListingGridStatic>
                    </div>
                    <div className='col-lg-1 w-50px p-0'>
                        <button type='button' className="btn btn-sm btn-secondary me-2 fw-bold px-2 py-2" onClick={(e)=>e.preventDefault()}>
                            <i className="fas fa-plus fs-5 px-1 py-3"></i>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export { DivisionsTab }