/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, { useEffect } from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout, showPicker} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import ShowPicker from '../../../../app/modules/sgl-utils/ShowPickerComponent'
import axios from 'axios'
import { useAppDispatch, useAppSelector } from '../../../../app/redux/hooks'
import { setQuickSearchAndActions } from '../../../../app/redux/reducers/showCompanyMetadata'
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

export function HeaderWrapper() {
  const dispatch = useAppDispatch()
  const customerId = useAppSelector(state => state.showCompany.company_id)
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config
  
  useEffect(() => {
    axios.get(`${NEST_API_URL}/utility/getShowCompanyMetadata?customer_id=${customerId}`)
    .then(res => {
      dispatch(setQuickSearchAndActions(res.data))
    })
  }, [customerId])

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 d-none'>
            <Link to='/dashboard' className='d-lg-none'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/sgl.png')} className='h-40px' />
            </Link>
          </div>
        )}
        {/* end::Logo */}

        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 d-none'>
            <Link to='/' className='d-lg-none'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/sgl.png')} className='h-40px' />
            </Link>
          </div>
        )}

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          {/* begin::Action wrapper */}
          {header.left === 'show-picker' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <ShowPicker disabled = {showPicker.disabled}/>
            </div>
          )}
          {/* end::Action wrapper */}


          <div className='d-flex align-items-stretch flex-shrink-0 me-4'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
