import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import { useCallback, useMemo, useState } from 'react'
import { useAppSelector } from '../../../redux/hooks'
import axios from 'axios'
import { useAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider'
import { AgGridReact } from 'ag-grid-react'
import { getNarrowHeaderHeight, getNarrowRowHeight } from '../../../modules/sgl-utils/agGridHelpers'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const modalsRoot = document.getElementById('root-modals') || document.body

const AddRider = ({ entryRiders, setEntryRiders, setSelectedRider, show, handleClose }) => {
    const intl = useIntl()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const [gridRef, setGridRef] = useState([])
    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const [searchTerm, setSearchTerm] = useState('')

    const columnDefs = [
        {
            field: 'membership_number', 
            headerName: 'Memebership No.'
        },
        {
            field: 'name',
            headerName: 'Name'
        }
    ]

    const onGridReady = useCallback((params) => {
        // Grid Reference initialize to params.api
        if(setGridRef){
           setGridRef(params.api)
        }
    }, []);

    const search = async (e) => {
        if (!e.target.value) return;

        try {
            loadingOverlay({ show: true })
            
            const response = await axios.get(`${NEST_API_URL}/people/searchPeopleByNameOrMembershipNumber?customer_id=${customerId}&search_term=${e.target.value}`)   

            gridRef.setRowData(response.data.people)
            if (response.data.people.length > 0) {
                gridRef.getRowNode(0).setSelected(true, true)
            }
        } catch(reason) {
            if (reason.response.data.error) {
                alertDialog({ message: reason.response.data.error })
            } else {
                alertDialog({ message: reason })
            }
        } finally {
            loadingOverlay({ show: false })
        }
    }

    const addRider = () => {
        
        const selectedRows = gridRef.getSelectedRows()
        
        let message = ''
        if (selectedRows.length === 0) {
            message = 'Please select a person.'
        } else if(selectedRows[0].on_credit_hold) {
            message = `${selectedRows[0].name} is on credit hold.`
        } else if(selectedRows[0].on_medical_hold) {
            message = `${selectedRows[0].name} is on medical hold.`
        }

        if (message) 
            return alertDialog({ message });

        // Already not in dropdown, then append to drop-down
        if (entryRiders.find(er => er.rider_id !== selectedRows[0].rider_id)) { 
            setEntryRiders(prevEntryRiders => {
                const newEntryRiders = [...prevEntryRiders]
                newEntryRiders.push({ rider_id: selectedRows[0].people_id, rider_name:  selectedRows[0].name})
                return newEntryRiders
            })

            // Update selected rider to newly added rider
            setSelectedRider(selectedRows[0].people_id)
        }
        onClose()
    }

    const onClose = () => {
        setSearchTerm('')
        handleClose()
    }

    return createPortal(
        <Modal
            id='kt_modal_1'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal modal-dialog modal-dialog-centered mw-550px announcer-change-rider-form'
            show={show}
            onHide={onClose}
            onKeyPress={(event) => {
                if (event.key == 'Enter') {
                    addRider()
                }
            }}
        >
            <div className="modal-header py-0 px-4">
                <h2 className="fs-4">Add new Rider</h2>
                <div className="btn btn-sm btn-icon btn-active-color-dark" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}>
                    <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x"/>
                </div>
            </div>

            <div className="modal-body py-3 px-4">
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <div className='col-lg-6'>
                                <div className={'d-flex align-items-center my-1 position-relative'}>
                                    <KTSVG
                                        path='/media/icons/duotune/general/gen021.svg'
                                        className='svg-icon-1 position-absolute ms-2'
                                    />
                                    <span 
                                        className='position-absolute end-0 me-2'
                                        onClick={() => setSearchTerm('')}
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr015.svg'
                                            className='svg-icon-1'
                                        />
                                    </span>
                                    <input
                                        value={searchTerm}
                                        type='text'
                                        className='form-control form-control-solid px-11 py-1 rounded-pill'
                                        placeholder={intl.formatMessage({ id: 'TOOLBAR.LABEL.SEARCH' })}
                                        onBlur={search}
                                        onChange={e => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </div>
                            <label className='col col-form-label fs-5 pe-0' htmlFor='entry_no' data-tooltip-id="ANNOUNCERTOOL.DETAIL.MODAL.ADDNEWRIDER.LABEL.ENTERNAMEORMEMBERSHIPNO">Enter Name or Membership No.</label>
                        </div>

                        <div style={gridStyle} className="ag-theme-alpine">
                            <AgGridReact 
                                rowData={[]}
                                columnDefs={columnDefs}
                                containerStyle={containerStyle}
                                onGridReady={onGridReady}
                                rowSelection="single"
                                rowHeight={getNarrowRowHeight}
                                headerHeight={getNarrowHeaderHeight}
                            />
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold text-uppercase' style={{ marginLeft: "auto" }} onClick={onClose}>
                            {intl.formatMessage({ id: 'BUTTON.CANCEL' })}
                        </button>
                        <button 
                            type='button' 
                            className='btn btn-sm btn-dark fw-bold text-uppercase'
                            onClick={addRider}
                            autoFocus
                            disabled={showFinancialsLocked}
                        >
                            {intl.formatMessage({ id: 'BUTTON.OK' })}
                        </button>
                    </div>
                </form>

            </div>
        </Modal>,
        modalsRoot
    )
}

export default AddRider