/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import BooleanCheckmarkRenderer from '../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import { useAppSelector } from '../../redux/hooks';
import useAccessChecker from '../../modules/hooks/use-access-checker';
import { useAlert, useConfirm } from '../../modules/sgl-utils/DialogsProvider';
import axios from 'axios'
import { renderCheckBox } from '../../modules/sgl-utils/agGridHelpers';

const ReportsList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const customer_id = useAppSelector(state=> state.showCompany.company_id);
  const { hasSpecialPermissionToAccess } = useAccessChecker();
  const alertDialog = useAlert()
  const confirmDialog = useConfirm()

  const onRowDoubleClicked = (row_data) => {
    const editSystemReportPermission = hasSpecialPermissionToAccess('cloud_reports:edit-system-reports');
    if (row_data.is_system_report && editSystemReportPermission) {  // If selected record is a system report and user has edit permission
        navigate('/reports/detail/'+row_data.sgl_id+`?customer_id=${customer_id}`)
    } else if (!row_data.is_system_report) {    // If selected record is not a system report
        navigate('/reports/detail/'+row_data.sgl_id+`?customer_id=${customer_id}`)
    } else {    // when selected record is a system report and user does not have edit permission
        // Show alert that user does not have permission to edit system reports
        alertDialog({ message: "You do not have permission to edit system reports."})
    }
  }

  // Hide report from print dialogue for a specific customer
  const hideReportForCustomer = async (params) => {
    // Show choice dialog to confirm the action only if its being hidden
    let hideReport = params.data.hidden;
    let choice = !params.data.hidden ? true : await confirmDialog({ message: "Are you sure you want to hide this report?" });

    // If user say no, then make hide false
    if (!choice) {
        params.data.hidden = false

        // setdatavalue
        params.node.setDataValue('hidden', false)
  
        // Rerender the grid
        params.api.refreshCells()
        return;
    } 

    // If user confirms, then hide the report
    await axios.post(process.env.REACT_APP_NEST_API_URL + '/reports/updateReportHiddenStatus', {
        report_id: params.data.sgl_id,
        customer_id: customer_id,
        hide_report: hideReport
    })
        .then((response) => {
        }).catch((error) => {
            if (error.message) {
                alertDialog({ message: error.message })
            }
        })
  }

  const columnDefs = [
    { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.REPORTS.ID'}), filter: 'agNumberColumnFilter', },
    { field: 'name', width: 300, headerName: intl.formatMessage({id: 'LIST.REPORTS.NAME'}), filter: 'agTextColumnFilter', },
    { field: 'area', width: 200, headerName: intl.formatMessage({id: 'LIST.REPORTS.AREA'}), filter: 'agTextColumnFilter', },
    { field: 'is_published', headerName: intl.formatMessage({id: 'LIST.REPORTS.ACTIVE'}), filter: 'agTextColumnFilter', cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' } },
    { field: 'is_system_report', width: 200, headerName: intl.formatMessage({id: 'LIST.REPORTS.SYSTEMREPORT'}), filter: 'agTextColumnFilter', cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' } },
    { 
        field: 'hidden',
        cellRenderer: params => renderCheckBox(params, async () => {hideReportForCustomer(params)}),
        width: 90,
        headerComponentFramework: (params) => { return  <div className="text-center">{ params?.headerName?.toUpperCase() }</div> },
        headerComponentParams: { headerName: intl.formatMessage({id: 'LIST.REPORTS.HIDEREPORT'}) },
        cellStyle: ({textAlign: 'center'})
    },    
    { field: 'modified_date', width: 200, valueGetter: (params) => { return (moment(params.data?.modified_date).format('MM/DD/YYYY') + ' ' + moment(params.data?.modified_time, 'HH:mm:ss').format('hh:mm A'))}, headerName: intl.formatMessage({id: 'LIST.REPORTS.UPDATEDAT'}), filter: 'agTextColumnFilter', cellStyle: { textAlign: 'center' } },
    { field: 'ShowManagementCompany.urlpath', flex:1, headerName: intl.formatMessage({id: 'LIST.REPORTS.COMPANY'}), filter: 'agTextColumnFilter', cellStyle: { textAlign: 'center' }, cellRenderer: (params) => {return params.data?.customer_id == 0? "any": params.data?.ShowManagementCompany?.urlpath} },
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.REPORTS'})}</PageTitle>
      <OutputListingComponent key={customer_id} area={'Reports'} columnDefs={columnDefs} apiEndPoint={'reports/list'} 
        onRowDoubleClicked={(row_data) => onRowDoubleClicked(row_data)}></OutputListingComponent>
    </>
  )
}

export {ReportsList}
