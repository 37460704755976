import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { defaultColDef, getNarrowHeaderHeight,getNarrowRowHeight } from "../../../../modules/sgl-utils/agGridHelpers";

const ErrorGrid = ({ data, tabIndex=0 }) => {
    const intl = useIntl()
    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    const columnDefs = [
        { 
            field: 'error', 
            headerName: intl.formatMessage({ id: 'QUICKACTION.MOVEENTRIESTOSHOW.ERRORMESSAGELIST.SHOW' }),
            headerClass: 'ag-center-aligned-header',
            cellStyle: { textAlign: 'center' },
            cellClass: 'ag-left-aligned-cell'
        }
    ]

    const onFirstDataRendered = useCallback((params) => {
        params.api.sizeColumnsToFit();
    }, []);

    const onGridReady = useCallback((params) => {
        params.api.sizeColumnsToFit();  
      }, []);

    return (
        <div>
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        rowData={data}
                        onFirstDataRendered={onFirstDataRendered}
                        columnDefs={columnDefs} 
                        rowStyle={{ fontSize: '12px' }}
                        suppressScrollOnNewData={true}
                        headerHeight={getNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                        rowSelection= {'single'}
                        onGridReady={onGridReady}
                        {...(tabIndex !== 0 && { tabIndex })} //tabIndex is set conditionally, only if a prop was passed, provided that the prop is not 0 
                    />
                </div>
            </div>
        </div>
    )
}


export default ErrorGrid