import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useFormContext } from 'react-hook-form';

const ORGForPersons = (props) => {
  const intl = useIntl();
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const containerStyle = useMemo(() => ({ width: '100%', height: '200px' }), []);
  const rowHeight = 28;
  const headerHeight = 30;
  const methods = useFormContext();
  const [updateState, setUpdateState] = useState(false);
  
  const [rowData, setRowData] = useState([]);
 
	useEffect(() => {
		if (props.rowData) {
			setRowData(props.rowData)
		}
	}, [props.rowData])

  const renderCheckBox = (params) => {
      return (
          <input
              type="checkbox"
              onClick={(e) => params.node.setDataValue(params.column.colId, e.target.checked)}
              defaultChecked={params.value}
          />
      )
  }

  //Grid Classes
  const columnDefs = [
    { field: 'select', headerName: "", sortable: false, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, suppressSizeToFit: true, maxWidth: 30, minWidth: 30, cellRenderer: renderCheckBox},
    { field: 'org_for_persons', flex:1, headerName: intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.GRID.LABEL.ORGFORPERSONS'}), suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'left', 'paddingLeft': 5, 'paddingRight': 5} },
    { field: 'r', headerName: intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.GRID.LABEL.R'}), cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, suppressSizeToFit: true, maxWidth: 50, minWidth: 30, cellRenderer: renderCheckBox},
    { field: 't', headerName: intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.GRID.LABEL.T'}), cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, suppressSizeToFit: true, maxWidth: 50, minWidth: 30, cellRenderer: renderCheckBox},
    { field: 'o', headerName: intl.formatMessage({id: 'FORM.VERIFY.ENTRIES.GRID.LABEL.O'}), cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, suppressSizeToFit: true, maxWidth: 50, minWidth: 30, cellRenderer: renderCheckBox},
  ];

  const onFirstDataRendered = useCallback((params) => {
      params.api.sizeColumnsToFit();
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: false,
      suppressMenu: true,
    };
  }, []);

  const getOnlyTrueOrgRTO =  () => {
    let tempValues = []
    // tempValues will save only those org check that are true
    rowData.map(data => {
      if(data.select == true){
        tempValues.push(data)
      }
    })
    return tempValues;
  }
  const onCellEdit = useCallback (e => {
    if(e.colDef.field == 'select' && e.data.select == true){
      rowData.map((row,index) => {
        if (row.org_for_persons == e.data.org_for_persons){
          row.select = true;
          row.r = true;
          row.t = true;
          row.o = true;
          setRowData(rowData);
          setUpdateState(!updateState);
        }
      })
    } else if(e.colDef.field == 'select' && e.data.select == false){
      rowData.map((row,index) => {
        if (row.org_for_persons == e.data.org_for_persons){
          row.select = false;
          row.r = false;
          row.t = false;
          row.o = false;
          setRowData(rowData);
          setUpdateState(!updateState);
        } 
      })
    }
    if (e.colDef.field == 'r'){
      if (e.data.r == true){
        rowData.map((row,index) => {
          if (row.org_for_persons == e.data.org_for_persons){
            if(row.select != true){
              row.select = true;
              setRowData(rowData);
              setUpdateState(!updateState);
            }
          }
        })
      } else {
        rowData.map((row,index) => {
          if (row.org_for_persons == e.data.org_for_persons){
            if(row.select != false && row.t == false && row.o == false){
              row.select = false;
              setRowData(rowData);
              setUpdateState(!updateState);
            }
          }
        })
      }
    } else if (e.colDef.field == 't'){
      if (e.data.t == true){
        rowData.map((row,index) => {
          if (row.org_for_persons == e.data.org_for_persons){
            if(row.select != true){
              row.select = true;
              setRowData(rowData);
              setUpdateState(!updateState);
            }
          }
        })
      } else {
        rowData.map((row,index) => {
          if (row.org_for_persons == e.data.org_for_persons){
            if(row.select != false && row.r == false && row.o == false){
              row.select = false;
              setRowData(rowData);
              setUpdateState(!updateState);
            }
          }
        })
      }
    } else if (e.colDef.field == 'o'){
      if (e.data.o == true){
        rowData.map((row,index) => {
          if (row.org_for_persons == e.data.org_for_persons){
            if(row.select != true){
              row.select = true;
              setRowData(rowData);
              setUpdateState(!updateState);
            }
          }
        })
      } else {
        rowData.map((row,index) => {
          if (row.org_for_persons == e.data.org_for_persons){
            if(row.select != false && row.t == false && row.r == false){
              row.select = false;
              setRowData(rowData);
              setUpdateState(!updateState);
            }
          }
        })
      }
    } 
    methods.setValue('ORGForPersons', getOnlyTrueOrgRTO(),  {shouldDirty: true})
    setUpdateState(!updateState); 
  })

  const onGridReady = useCallback((params) => {
    // Grid Reference initialize to params.api
  }, []);

  return (
    <>
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          headerHeight={headerHeight}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowData} 
          rowHeight={rowHeight}
          onFirstDataRendered={onFirstDataRendered}
          onCellValueChanged={onCellEdit}
          onGridReady = {onGridReady}
        ></AgGridReact>
      </div>
    </div>
    </>
  );
};

export default ORGForPersons;
  