
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers';
import "flatpickr/dist/themes/material_blue.css";
import { useEffect } from 'react';

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalHOC = ({ show, handleClose, modalTitle = "", children, handleSubmit }) => {
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={true}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px'
            show={show}
            backdrop='static'
            onHide={handleClose}
            onKeyDown={(event) => {
                let activeElement = document.activeElement
                let isButtonFocused = false
                if (activeElement && activeElement.tagName === 'BUTTON') {
                    isButtonFocused = true
                }
                if (event.key == 'Enter' && !isButtonFocused) {
                    handleSubmit()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{ modalTitle }</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                { children }
            </div>
        </Modal>,
        modalsRoot
    )
}

export default ModalHOC