import axios from "axios";
import { useAnnouncerToolContext } from "./AnnouncerToolContext";
import { useAlert } from "../../../modules/sgl-utils/DialogsProvider";
import { useState } from "react";
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const R3TripCheckboxRenderer = (params) => {
    const { currentShowID, customerID, currentClassGroup, setClassesWithCompletedTrips } = useAnnouncerToolContext()
    const alertDialog = useAlert()
    const [isR3ChangeCallPending, setIsR3ChangeCallPending] = useState(false)

    const handleChange = async (event, params) => {
        setIsR3ChangeCallPending(true)
        const checked = event.target.checked ? true : false;
        const entryXClass = params.data

        try {
            params.node.setDataValue('r3_trip', checked)
            const response = await axios.patch(`${NEST_API_URL}/ingate-announcer/setTeamTripForRound3`, {
                show_id: currentShowID,
                customer_id: customerID,
                class_group_id: currentClassGroup.class_group_id,
                class_id: entryXClass.class_id,
                entryxclasses_id: entryXClass.entryxclasses_id,
                team_id: entryXClass.team_id,
                checked,
            })

            if (response.data.success) {
                // Set completed trips for classes of class group
                setClassesWithCompletedTrips(response.data.completedTrips) 
            }
        } catch (reason) {
            if (reason.response) {
                alertDialog({message: reason.response.data.error});
            }
        } finally {
            setIsR3ChangeCallPending(false)
        }
    }
    
    return (
        (params.data?.r3_qualified || params.data?.r3_trip) ? // Show Checkbox for Team Trips only
            <input
                type="checkbox"
                checked={params.data.r3_trip} 
                onChange={(e) => handleChange(e, params)}
                disabled={isR3ChangeCallPending}
            />
            :
            <></>
    );
};

export default R3TripCheckboxRenderer;
