
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useForm, FormProvider } from 'react-hook-form';
import { KTSVG } from '../../../../_metronic/helpers';
import axios from 'axios';
import { useAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import { useEffect, useState } from 'react';
import PasswordStrengthMeter from '../../../modules/auth/components/PasswordStrengthMeter';

type Props = {
    show: boolean
    handleClose: () => void
    handleSave: ({ }) => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ChangePasswordDialog = ({ show, handleSave, handleClose }: Props) => {
    const intl = useIntl()
    const methods = useForm({
        defaultValues: {
            current_password: '',
            new_password: '',
            confirm_password: '',
            error_message: '',
        },
    });
    const { register, getValues, watch, reset } = methods
    const loadingOverlay = useLoadingOverlay()
    const alertDialog = useAlert()
    const error_message = watch('error_message')
    const [passwordValidationError, setPasswordValidationError] = useState('')

    const onClose = () => {
        reset()
        handleClose()
    }

    const onSave = () => {
        loadingOverlay({ show: true })
        let data = getValues()
        axios.post(process.env.REACT_APP_NEST_API_URL + '/auth/changePassword', { ...data })
            .then((res) => {
                if(res.data.success) {
                    loadingOverlay({ show: false })
                    alertDialog({ title:  intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.CHANGEPASSWORD' }), message: res.data.message, icon: "info" })
                    handleClose()
                } else {
                    loadingOverlay({ show: false })
                    alertDialog({ title:  intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.CHANGEPASSWORD' }), message: res.data.message, icon: "stop" })
                }

            })
            .catch((e) => {
                loadingOverlay({ show: false })
                alertDialog({ title: 'Change Password', message: intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.CHANGEPASSWORD.ERROR' }), icon: "stop" })
            })
            
        onClose()
    }

    const validateInputs = () => {
        return !watch("current_password") || !watch("new_password") || !watch("confirm_password") || !checkPasswordsMatch
    }
    
    const checkPasswordsMatch = () => {
        const newPasswordValue = watch('new_password');
        const confirmPasswordValue = watch('confirm_password');
        if (newPasswordValue && confirmPasswordValue) {
            if (newPasswordValue === confirmPasswordValue) {
                methods.setValue("error_message", "")
            } else {
                methods.setValue("error_message", intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.CHANGEPASSWORD.MISMATCH' }))
            }
        }
    }

    useEffect(() => {
        checkPasswordsMatch()
    }, [ watch("new_password"), watch("confirm_password") ])

    useEffect(() => {
        // VALIDAT PASSWORD
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?]{8,20}$/; 
		if (watch('new_password') && !passwordRegex.test(watch("new_password"))) {
			setPasswordValidationError(intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.CHANGEPASSWORD.MESSAGE'}))
		} else {
            setPasswordValidationError("")
        }

	}, [ watch("new_password")])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-xl search-form mw-550px'
            show={show}
            onHide={handleClose}
            onKeyPress={(event :any) => {
                if (event.key === "Enter") {
                    onSave()
                }
            }}
        >
            <div className='modal-header py-0 px-4' >
                <h2 className="fs-4" >{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.CHANGEPASSWORD' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <FormProvider {...methods}>
                    <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                        <div className='card-body p-0'> {/* Card Body */}
                            <div className="form-group"> {/* Column Group */}
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='CURRENTPASSWORD'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.CHANGEPASSWORD.LABEL.CURRENTPASSWORD' })}</label>
                                    <div className='col'>
                                        <input
                                            id='CURRENTPASSWORD'
                                            type='password'
                                            {...register('current_password')}
                                            className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='NEWPASSWORD'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.CHANGEPASSWORD.LABEL.NEWPASSWORD' })}</label>
                                    <div className='col'>
                                        <input
                                            id='NEWPASSWORD'
                                            type='password'
                                            {...register('new_password')}
                                            className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        />
                                         <div className={`row ${passwordValidationError? '' : 'd-none'}`}>
                                            <p className='col-lg-12 fs-7 text-danger m-0'>{passwordValidationError}</p>
                                        </div>
                                        {watch('new_password') && <PasswordStrengthMeter password={watch('new_password')}/>}
                                    </div>
                                </div>
                                <div className='row mb-4'>
                                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='CONFIRMPASSWORD'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.CHANGEPASSWORD.LABEL.CONFIRMPASSWORD' })}</label>
                                    <div className='col'>
                                        <input
                                            id='CONFIRMPASSWORD'
                                            type='password'
                                            {...register('confirm_password')}
                                            className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        />
                                    <div className={`row ${error_message?.length ? '' : 'd-none'}`}>
                                        <p className='col-lg-12 fs-6 text-danger'>{error_message}</p>
                                    </div>
                                    </div>
                                </div>
                            </div> {/* End Column Group */}
                        </div>{/* End Card Body */}

                        <div className='card-footer d-flex justify-content-end py-3 px-0'>

                            <button type="button" className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={onClose}>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CLOSE' })}
                            </button>

                            <button type="submit" className='btn btn-sm btn-dark fw-bold' onClick={onSave} disabled={validateInputs()} autoFocus>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.UPDATE' })}
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { ChangePasswordDialog }