import { useIntl } from 'react-intl'
import { useAppSelector } from '../../redux/hooks';
import axios from 'axios';
import { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider, Controller} from "react-hook-form";
import { PatternFormat, NumericFormat} from 'react-number-format';
import { useAlert, useLoadingOverlay} from '../../modules/sgl-utils/DialogsProvider';
import { useNavigate } from 'react-router-dom';

// Custom Components
// import { GeneralTab } from './Detail/GeneralTab';
// import { HistoryTab } from '../../modules/input/HistoryTab';
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions';
import { PageTitle } from '../../../_metronic/layout/core'
import history from '../../modules/sgl-utils/unstableHistory';
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt';
import { HistoryTab } from '../../modules/components/HistoryTab';
import { GeneralTab } from './Detail/General';
import useAutoFocus from '../../modules/hooks/use-auto-focus';
import HelpLink from '../../modules/components/HelpLink'
import useAccessChecker from '../../modules/hooks/use-access-checker';

const { PUBLIC_URL } = process.env

export const dirtyValues = (
    dirtyFields,
    allValues
  ) => {
    // NOTE: Recursive function.
  
    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
      return allValues;
    }
  
    // Here, we have an object.
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [
        key,
        dirtyValues(dirtyFields[key], allValues[key])
      ])
    );
  };

const OrganizationsDetail = (props) => {
    const intl = useIntl();
    const {organization_id} = useParams();
    let current_organization_id = 	props.id || props.id == 0 ? props.id : organization_id
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const[loading,setLoading] = useState(true)
    const alertDialog = useAlert()
    const navigate = useNavigate();
    const loadingOverlay = useLoadingOverlay()
    const { hasSpecialPermissionToAccess } = useAccessChecker()

    const methods = useForm({
        defaultValues: 
        {
            "organization":{
                "sync_id":"",
                "code":"",
                "name":"",
                "address":"",
                "addresstwo":"",
                "city":"",
                "state":"",
                "phone":"",
                "email":"",
                "url":"",
                "active":0,
                "system":0,
                "organization_id":0,
                "horse":0,
                "person":0,
                "priority":null,
                "customer_id":0,
                "sgl_id":0,
                "managed":0,
                "available_on_web":0,
                "organization_uuid":""
            }
        }
      }); 

    const tabs = ["GENERAL", "HISTORY"]
    const componentTags = [
        <GeneralTab />,
        <HistoryTab area="Organizations" rowData={methods.getValues("change_history_logs") ?? []} />
    ]
    const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	});
    
    useEffect(() => {
        // Show loading overlay
        loadingOverlay({show: true})
        axios.all(
        [
          axios.get( process.env.REACT_APP_NEST_API_URL + '/organizations/detail', {
            params: {
                organization_id: organization_id,
              customer_id: customer_id,
            }
          })
        ]  
        ).then (
          axios.spread( ({data : organizationData}) => {
            //Check if record id is invalid then redirect to list page
            if ((organizationData || organizationData === '') && isInvalidRecord(organizationData.organization, organization_id, 'organization_id')){
              navigate('/organizations')
              loadingOverlay({show: false})
          }
        
          // Assign default value 1 if phone country code and fax country code values are empty
          if(organizationData.organization && !organizationData.organization.phone_country_code){
            organizationData.organization.phone_country_code = 1
          }
            reset(organizationData)
      
            // Hide loading overlay
            loadingOverlay({show: false})
            setLoading(false)
          })
        )
    },[organization_id])

    const { handleSubmit, reset, formState, setFocus} = methods
    const { dirtyFields } = formState; 
    useNavigationPrompt(formState)
    const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
    const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);

	useEffect(() => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');
		if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
			next_button.disabled = true;
			last_button.disabled = true;
		}
		if (row_selected == 0 && prev_button && first_button) {
			prev_button.disabled = true;
			first_button.disabled = true;
		}
	}, [loading])

    const onSubmit = async (form_data) => { 
        const next_button = document.getElementById('next-btn');
        const last_button = document.getElementById('last-btn');
        const prev_button = document.getElementById('prev-btn');
        const first_button = document.getElementById('first-btn');
  
        // means detail page is not open in modal
        if(props?.id === undefined){
            next_button.disabled = false;
            prev_button.disabled = false;
            last_button.disabled = false;
            first_button.disabled = false;
        }
        
        let submit_data = dirtyValues(dirtyFields, form_data)
        loadingOverlay({show: true})

        if(!methods.getValues('organization.code')?.trim() || !methods.getValues('organization.name')?.trim()){
            alertDialog({message: 'Organization Code and Name cannot be empty.'})
            loadingOverlay({show: false})
            return;
        }

        return axios.post( process.env.REACT_APP_NEST_API_URL + '/organizations/detail', {
          params: {
            organization_id: organization_id,
            customer_id: customer_id,
            data: submit_data
          }
        }).then((response) => {
        loadingOverlay({show: false})
          if(response.data.success) {
            // Use Case (Param Id changed): Next/Prev buttons
            if(props?.id == undefined && Number(methods.getValues('organization.organization_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
                if(row_selected && row_selected != "" && row_ids[row_selected]){
                    setLoading(true)
                    history.push(`${PUBLIC_URL}/organizations/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
                }
            }
            else if(props?.id == undefined && current_organization_id > 0){
                history.push(`${PUBLIC_URL}/organizations`, { allowNavigation: true })
            }
            else if(props?.id == undefined && current_organization_id == 0){
                history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
            }
            if( row_selected == row_ids?.length -1 && props?.id === undefined){
                if (next_button && last_button) {
                    next_button.disabled = true;
                    last_button.disabled = true;
                    prev_button.disabled = false;
                    first_button.disabled = false;
                }
              }
            if(row_selected == 0 && props?.id === undefined){
                if (prev_button && first_button) {
                    prev_button.disabled = true;
                    first_button.disabled = true;
                    next_button.disabled = false;
                    last_button.disabled = false;
                }
            }
        } else {
            alertDialog({message: response.data.error_message, title: 'Error'})
        }
        })
    }

    useAutoFocus('CODE', loading)
    
    return (
        <>
        {!loading && (
        <>
        {props?.id == undefined ? <PageTitle breadcrumbs={[]} description={[methods.watch('organization.name'), methods.getValues('organization.organization_id')]}>{intl.formatMessage({ id: 'MENU.ORGANIZATIONS' })}</PageTitle> : ""}
        <FormProvider {...methods}>
            <div id={"organizations_detail_page"} className='modal-body py-3 px-4 masterfee-input-form input-form'>
                <form noValidate className='form max-width' onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } }}>
                <HelpLink tooltip="ORGANIZATIONS.DETAIL.LINK.HELP" classname="top" />
                    <div className='card-body p-0'> {/* Card Body */}
                        <div className="form-group row">
                            <div className='col-6'> 
                                {/* <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='SYSTEM'>
                                        {' '}
                                        {intl.formatMessage({id: 'FORM.INPUT.ORGANIZATIONS.LABEL.SYSTEM'})}
                                    </label> 
                                    <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>

                                        <input
                                        {...methods.register('organization.system', {
                                            required: false,
                                        })}
                                        className='form-check-input'
                                        type='checkbox'
                                        id='SYSTEM'
                                        disabled={true}
                                        />
                                            
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='MANAGED'>
                                        {' '}
                                        {intl.formatMessage({id: 'FORM.INPUT.ORGANIZATIONS.LABEL.MANAGED'})}
                                    </label> 
                                    <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>
                                        <input
                                        {...methods.register('organization.managed', {
                                            required: false,
                                        })}
                                        className='form-check-input'
                                        type='checkbox'
                                        id='MANAGED'
                                        disabled={methods.watch('organization.system')}
                                        />
                                            
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='ACTIVE'>
                                        {' '}
                                        {intl.formatMessage({id: 'FORM.INPUT.ORGANIZATIONS.LABEL.ACTIVE'})}
                                    </label> 
                                    <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>
                                        <input
                                        {...methods.register('organization.active', {
                                            required: false,
                                        })}
                                        className='form-check-input'
                                        type='checkbox'
                                        id='ACTIVE'
                                        disabled={methods.watch('organization.system')}
                                        />
                                            
                                    </div>
                                </div> */}
                                <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='CODE' data-tooltip-id="ORGANIZATIONS.DETAIL.LABEL.CODE">{intl.formatMessage({ id: 'FORM.INPUT.ORGANIZATIONS.LABEL.CODE' })}</label>
                                    <div className='col'>
                                        <input
                                            {...methods.register( 'organization.code', 
                                            {
                                                    required: false
                                            }
                                            )
                                            }
                                            id='CODE'
                                            type='text'
                                            // disabled={!hasSpecialPermissionToAccess('cloud_sgadmin:has_access')}
                                            disabled={current_organization_id > 0 && (methods?.getValues('hasMemberships'))}
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        />
                                    </div>
                                </div>
                                {/* <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='PRIORITY'>{intl.formatMessage({ id: 'FORM.INPUT.ORGANIZATIONS.LABEL.PRIORITY' })}</label>
                                    <div className='col'>
                                        <Controller
                                            control={methods.control}
                                            name="organization.priority"
                                            render={({ field: { onChange, name, value } }) => (
                                            <NumericFormat
                                                id='PRIORITY'
                                                value={Number(value)}
                                                allowNegative={false}
                                                onValueChange={(e) => {
                                                onChange(e.value)
                                                }}
                                                thousandSeparator=","
                                                // isAllowed={withValueCap}
                                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                            />
                                            )}
                                        />
                                    </div>
                                </div> */}
                                <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='NAME'  data-tooltip-id="ORGANIZATIONS.DETAIL.LABEL.NAME">{intl.formatMessage({ id: 'FORM.INPUT.ORGANIZATIONS.LABEL.NAME' })}</label>
                                    <div className='col'>
                                        <input
                                            {...methods.register( 'organization.name', 
                                            {
                                                    required: false
                                            }
                                            )
                                            }
                                            id='NAME'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        />
                                    </div>
                                </div>
                                {/* <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='ADDRESS'>{intl.formatMessage({ id: 'FORM.INPUT.ORGANIZATIONS.LABEL.ADDRESS' })}</label>
                                    <div className='col'>
                                        <input
                                            {...methods.register( 'organization.address', 
                                            {
                                                    required: false
                                            }
                                            )
                                            }
                                            id='ADDRESS'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='ADDRESSTWO'>{intl.formatMessage({ id: 'FORM.INPUT.ORGANIZATIONS.LABEL.ADDRESSTWO' })}</label>
                                    <div className='col'>
                                        <input
                                            {...methods.register( 'organization.addresstwo', 
                                            {
                                                    required: false
                                            }
                                            )
                                            }
                                            id='ADDRESSTWO'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='CITY'>{intl.formatMessage({ id: 'FORM.INPUT.ORGANIZATIONS.LABEL.CITY' })}</label>
                                    <div className='col'>
                                        <input
                                            {...methods.register( 'organization.city', 
                                            {
                                                    required: false
                                            }
                                            )
                                            }
                                            id='CITY'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='STATE'>{intl.formatMessage({ id: 'FORM.INPUT.ORGANIZATIONS.LABEL.STATE' })}</label>
                                    <div className='col'>
                                        <input
                                            {...methods.register( 'organization.state', 
                                            {
                                                    required: false
                                            }
                                            )
                                            }
                                            id='STATE'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='PHONE'>{intl.formatMessage({ id: 'FORM.INPUT.ORGANIZATIONS.LABEL.PHONE' })}</label>
                                    <div className='col'>
                                    <Controller
                                            control={methods.control}
                                            name="organization.phone"
                                            render={({ field: { onChange, value} }) => (
                                            <PatternFormat
                                                format="(###) ###-####"
                                                value={methods.getValues('organization.phone') == '' ? '' : Number(value)}
                                                onValueChange={(e)=>{
                                                    onChange(e.value)
                                                    if(e.value.length < 10){
                                                        methods.setError('organization.phone',{
                                                            type: "custom",
                                                            message: "Phone number should be minimum 10 numbers"
                                                        })
                                                    }
                                                    else{
                                                        methods.clearErrors('organization.phone')
                                                    }
                                                }}
                                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            />
                                            )}
                                        />
                                    { methods.formState.errors?.organization?.phone && <div><span className='error_message'>{methods.formState.errors.organization?.phone?.message}</span></div>}
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='EMAIL'>{intl.formatMessage({ id: 'FORM.INPUT.ORGANIZATIONS.LABEL.EMAIL' })}</label>
                                    <div className='col'>
                                        <input
                                            {...methods.register( 'organization.email', 
                                            {
                                                    required: false,
                                                    pattern:{
                                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                                                        message: "Please enter a valid email"
                                                    }  
                                            }
                                            )
                                            }
                                            id='EMAIL'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        />
                                        { methods.formState.errors?.organization?.email && <div><span className='error_message'>{methods.formState.errors.organization.email?.message}</span></div>}

                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='URL'>{intl.formatMessage({ id: 'FORM.INPUT.ORGANIZATIONS.LABEL.URL' })}</label>
                                    <div className='col'>
                                        <input
                                            {...methods.register( 'organization.url', 
                                            {
                                                    required: false
                                            }
                                            )
                                            }
                                            id='URL'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='FORHORSES'>
                                        {' '}
                                        {intl.formatMessage({id: 'FORM.INPUT.ORGANIZATIONS.LABEL.FORHORSES'})}
                                    </label> 
                                    <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>
                                        <input
                                        {...methods.register('organization.horse', {
                                            required: false,
                                        })}
                                        className='form-check-input'
                                        type='checkbox'
                                        id='FORHORSES'
                                        disabled={methods.watch('organization.system')}
                                        />
                                            
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='FORPEOPLE'>
                                        {' '}
                                        {intl.formatMessage({id: 'FORM.INPUT.ORGANIZATIONS.LABEL.FORPEOPLE'})}
                                    </label> 
                                    <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>
                                        <input
                                        {...methods.register('organization.person', {
                                            required: false,
                                        })}
                                        className='form-check-input'
                                        type='checkbox'
                                        id='FORPEOPLE'
                                        disabled={methods.watch('organization.system')}
                                        />
                                            
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-3 col-form-label  fw-bold fs-5 py-1' htmlFor='AVAILABLEON'>
                                        {' '}
                                        {intl.formatMessage({id: 'FORM.INPUT.ORGANIZATIONS.LABEL.AVAILABLEON'})}
                                    </label> 
                                    <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3 w-lg-250px'>
                                        <input
                                        {...methods.register('organization.available_on_web', {
                                            required: false,
                                        })}
                                        className='form-check-input'
                                        type='checkbox'
                                        id='AVAILABLEON'
                                        disabled={methods.watch('organization.system')}
                                        />
                                            
                                    </div>
                                </div> */}
                            
                            
                            </div>
                        </div>

                        <div className='d-flex flex-column my-2 border p-2 bg-white'>
                            <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                                {
                                    tabs.map((tab, index) => {
                                        return (
                                            <li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
                                                <a className={"nav-link text-active-dark px-6 py-1 text-gray-700" + (index == selectedTab ? ' active' : '') + (index == 9 ? ' min-w-140px text-center' : '') }
                                                    tabIndex="-1"
                                                    id={tab + "-tab"}
                                                    data-bs-toggle='tab'
                                                    href={"#" + tab + "-Organization-" +current_organization_id}
                                                    data-tooltip-id={`ORGANIZATIONS.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.ORGANIZATION.TAB.' + tab })}
                                                </a>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        
                            <div className="tab-content mt-5 pb-2" id="myTabContent">
                                {
                                    tabs.map((tab, index) => {
                                        return (
                                            <div key={index} className={"tab-pane fade show" + (index == 0 ? " active" : "")} id={tab + "-Organization-" +current_organization_id} role="tabpanel" >
                                                <HelpLink tooltip={"ORGANIZATIONS.DETAIL.TAB."+tab+".LINK.HELP"}  />
                                                {componentTags[index]}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>

                    </div> {/* End Card Body */}

                    <InputFormFooter goBackPath={'/organizations'} data={'organization'}/>
                </form>
            </div>
            </FormProvider>
            </>
    		)}
        </>
    );
}

export { OrganizationsDetail }