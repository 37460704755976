import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import { checkVersion } from 'version-rocket'
import packageInfo from '../package.json'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
//Redux
import { Provider } from 'react-redux'
import store from './app/redux/store'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import './app/sglcloud.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

checkVersion({
  localPackageVersion: packageInfo.version,
  originVersionFileUrl: `${window.location.origin}/version.json`,
  pollingTime: 30000,
  immediate: true,
  // Refer to API for more configuration options
} , {
  // cancelButtonText: 'Cancel',
  buttonStyle: 'background-color:#e51f20;',
  imageUrl: `${window.location.origin}/media/logos/sgl.png`
})

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <Provider store={store}>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </Provider>
      </MetronicI18nProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  )
}
