import React, {useState, useEffect} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider'
import { useOutputContext, useOutputContextUpdater} from '../../../modules/output-listing/OutputListingContext'
import axios from 'axios';
import { useAppSelector } from '../../../redux/hooks'

const modalsRoot = document.getElementById('root-modals') || document.body
 
const SetLocation = ({show, handleClose}) => {
    const intl = useIntl() 
    const loadingOverlay = useLoadingOverlay()
    const alertDialog = useAlert()
    const {progressBar} = useProgress()
    const {getOutputSelectionAreaIDs, getEventID} = useOutputContext()
    const outputContextUpdater = useOutputContextUpdater()
    const confirmDialog = useConfirm()
    const [location, setLocation] = useState("")
    const [entryIds, setEntryIds] = useState([])
    const customerId = useAppSelector(state => state.showCompany.company_id);
    const [showModal, setShowModal] = useState(false)
    
    const onClose = () => {
        setShowModal(false)
        handleClose()
    }

    const handleChange = (e) => {
        setLocation(e.target.value)
    }

    const handleSave = () =>{
        if(location.length <= 20){
            let eventID = getEventID("set-Location-tool")
            setShowModal(false)
            // show progress bar
            progressBar({ show: true, eventID, showProgress: 'inline', title: intl.formatMessage({ id: "ENTRIES.SETLOCATION.PROGRESSBAR.MESSAGE" }) })
            axios.post( process.env.REACT_APP_NEST_API_URL + "/entries/setEntriesLocation", {
                customer_id: customerId,
                entry_ids: entryIds,
                location: location,
                event_id: eventID
            })
            .then((res) => {
                // close progress bar
                progressBar({ show: false, eventID })
                // Alert user about set location status
                alertDialog({ message: intl.formatMessage({ id: "ENTRIES.SETLOCATION.SUCCESSMESSAGE"}) + ` ${entryIds.length} ` + intl.formatMessage({ id: "MENU.ENTRIES"}), icon: "info"})
                //refresh list
                outputContextUpdater({action: 'refresh'})
                handleClose()
            })
            .catch((e) => {
            })
        }
        else{
            // Alert if location is more than 20 characters
            alertDialog({ message: intl.formatMessage({ id: "ENTRIES.SETLOCATION.LENGTHERROR"})})
        }
    }

    useEffect(() => {
        if (show) {
            loadingOverlay({ show: true })
            getOutputSelectionAreaIDs(false)
            .then(res => { 
                if (res.length === 0) {
                    loadingOverlay({ show: false })
                    // error message if no entry is selected
                    alertDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.SETLOCATION.MODAL.ERROMESSAGE.SELECTENTRIES"}) })
                    handleClose()
                }
                else{
                    // if user selected any entries
                    setEntryIds(res)
                    loadingOverlay({ show: false })
                    // ask user for confirmation
                    confirmDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.SETLOCATION.MODAL.CONFIRM"}) +" "+ res.length +" " + intl.formatMessage({ id: "MENU.ENTRIES"}) + "?"})
                    .then((choice) => {
                        if(!choice){
                            // close the modal if choice is false means user selected no
                            handleClose()
                        }
                        else{
                            // user confirmation by selecting yes
                            setShowModal(true)
                            setLocation("")
                        }
                    })
                }
            })
            .catch(() => loadingOverlay({ show: false }))
        }  
    }, [show])

    return createPortal(
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
                show={showModal}
                onHide={onClose}
                onKeyDown={(event) => {
                    let isButtonFocused = false
                    let activeElement = document.activeElement //gets the currently focussed element
                    if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                        isButtonFocused = true;
                    }
                    if (event.key === "Enter" && !isButtonFocused) {
                        handleSave()
                    }
                }}
            >
                <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
                <div className='modal-header py-0 px-4'>
                    <h2 className="fs-4">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.HEADING.SETLOCATION' })}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
    
                <div className='modal-body py-3 px-4'>
                    <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                        <div className='card-body p-0'>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' htmlFor='LOCATION'>{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.MODAL.FORM.LABEL.ENTERLOCATION' })}</label>
                                <div className='col-lg-7'>
                                    <input
                                        name="location"
                                        onChange={handleChange}
                                        value={location}
                                        id='LOCATION'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.LOCATION' })}
                                        autoFocus
                                        tabIndex={1}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='card-footer d-flex justify-content-end py-3 px-0'>
                            <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={2}>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CANCEL' })}
                            </button>

                            <button type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={() => handleSave()} tabIndex={3}>
                                <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.SAVE' })}
                            </button>
                        </div>
                    </form>
                </div>
      </Modal>
      ,modalsRoot
    )
}

export default SetLocation