import {useIntl} from 'react-intl'
import {useAppSelector} from '../../redux/hooks'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {useForm, FormProvider} from 'react-hook-form'
import {useAlert, useLoadingOverlay, useConfirm} from '../../modules/sgl-utils/DialogsProvider'
import {useNavigate} from 'react-router-dom'
import {PatternFormat} from 'react-number-format'
import {Controller} from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

// Custom Components
import {InputFormFooter} from '../../modules/sgl-utils/InputFormFooter'
import {isInvalidRecord} from '../../modules/sgl-utils/SglFunctions'
import {PageTitle} from '../../../_metronic/layout/core'
import { RingsTab } from './Detail/RingsTab'
import { BarnsTab } from './Detail/BarnsTab'
import { ShowsTab } from './Detail/ShowsTab'
import history from '../../modules/sgl-utils/unstableHistory';
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt'
import { HistoryTab } from '../../modules/components/HistoryTab'
import useAutoFocus from '../../modules/hooks/use-auto-focus'
import parsePhoneNumber from 'libphonenumber-js'
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';
import { PhoneNumberFormatter } from '../../modules/sgl-utils/Formatters'
import HelpLink from '../../modules/components/HelpLink'

const { PUBLIC_URL } = process.env

export const dirtyValues = (dirtyFields, allValues) => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])])
  )
}

const FacilitiesDetail = (props) => {
  const intl = useIntl()
  const {facility_id} = useParams()
  const [form_meta, setFormMeta] = useState({})
  const customer_id = useAppSelector((state) => state.showCompany.company_id)
  const alertDialog = useAlert()
  const navigate = useNavigate()
  const loadingOverlay = useLoadingOverlay()
  const confirmDialog = useConfirm()
  const [loading, setLoading] = useState(true)

  const methods = useForm({
    defaultValues: {
      facility: {
        name: '',
        address: '',
        city: '',
        state: '',
        postal_code: '',
        fax: '',
        phone: '',
        number_of_rings: '',
        iam_enabled: '',
        farmtek_port: '',
        iam_ipaddress: '',
        iam_port: '',
        iam_ipaddresstwo: '',
        iam_porttwo: '',
        iam_pyramid_updates: '',
        phone_country_code: '',
        fax_country_code: ''
      },
      rings: [],
      deleted_rings: [],
      update_future_shows_rings: 0,
      deleted_barns: [],
      barns: []
    },
  })

  const updateFutureShowRing = () => {
    methods.setValue("update_future_shows_rings", 1, {shouldDirty: true})
  };

  const tabs = ["RINGS", "BARNS", "SHOWS", "HISTORY"]
  const componentTags = [<RingsTab form_meta={form_meta} facility_id={facility_id} updateAllUpcomingShows={updateFutureShowRing}/>, <BarnsTab form_meta={form_meta} facility_id={facility_id}/>, <ShowsTab />,  <HistoryTab area="Facilities" rowData={methods.getValues("change_history_logs")} />]
  const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	});

  const {handleSubmit, reset, formState, setFocus} = methods

  const {dirtyFields} = formState
  useNavigationPrompt(formState)
  const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
	const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);

	useEffect(() => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');
		if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
			next_button.disabled = true;
			last_button.disabled = true;
		}
		if (row_selected == 0 && prev_button && first_button) {
			prev_button.disabled = true;
			first_button.disabled = true;
		}
	}, [loading])

  const onSubmit = async (form_data) => {
    if (methods.getValues("update_future_shows_rings") === 1 && methods.getValues("future_shows_count") > 0) {
      const futureShowsCount = methods.getValues("future_shows_count");
      let message = `Facility Rings have been successfully updated. `;
      if (futureShowsCount === 1) {
        message += `Would you like to apply these updates to the upcoming (${futureShowsCount}) show?`;
      } else {
        message += `Would you like to apply these updates to the upcoming (${futureShowsCount}) shows?`;
      }
      let choice = await confirmDialog({ message });
      if (!choice) {
        methods.setValue('update_future_shows_rings', 0, {shouldDirty: true})
        // Manually update the value of the field in form_data
        form_data['update_future_shows_rings'] = 0
      }
    } 

    const next_button = document.getElementById('next-btn');
    const last_button = document.getElementById('last-btn');
    const prev_button = document.getElementById('prev-btn');
    const first_button = document.getElementById('first-btn');

    // means detail page is not open in modal
    if(props?.id === undefined){
      next_button.disabled = false;
      prev_button.disabled = false;
      last_button.disabled = false;
      first_button.disabled = false;
    }

    let submit_data = dirtyValues(dirtyFields, form_data)
    loadingOverlay({show: true})
    return axios
      .post(process.env.REACT_APP_NEST_API_URL + '/facilities/detail', {
        params: {
          facility_id,
          customer_id,
          data: submit_data,
        },
      })
      .then((response) => {
        loadingOverlay({show: false})
        if (response.data.success) {
          // Use Case (Param Id changed): Next/Prev buttons
          if(props?.id == undefined && Number(methods.getValues('facility.facility_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
            if(row_selected && row_selected != "" && row_ids[row_selected]){
              setLoading(true)
              history.push(`${PUBLIC_URL}/facilities/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
            }
          }
          else if(props?.id == undefined && facility_id > 0){
            history.push(`${PUBLIC_URL}/facilities`, { allowNavigation: true })
          }
          else if(props?.id == undefined && facility_id == 0){
            history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
          }
          if( row_selected == row_ids?.length -1 && props?.id === undefined){
            if (next_button && last_button) {
                next_button.disabled = true;
                last_button.disabled = true;
                prev_button.disabled = false;
                first_button.disabled = false;
            }
          }
          if(row_selected == 0 && props?.id === undefined){
            if (prev_button && first_button) {
                prev_button.disabled = true;
                first_button.disabled = true;
                next_button.disabled = false;
                last_button.disabled = false;
            }
          }
        } else {
          alertDialog({message: response.data.error_message, title: 'Error'})
        }
      })
  }

  useEffect(() => {
    // Show loading overlay
    loadingOverlay({show: true})
    axios
      .all([
        axios.get(process.env.REACT_APP_NEST_API_URL + '/facilities/detail', {
          params: {
            facility_id,
            customer_id,
          },
        }),
        axios.get( process.env.REACT_APP_NEST_API_URL + '/facilities/metaData', {
          params: {
              customer_id: customer_id,
          }
        }),
      ])
      .then(
        axios.spread(({data: facilityData}, {data : facilityMetaData}) => {
          //Check if record id is invalid then redirect to list page
          if ((facilityData || facilityData === '') && isInvalidRecord(facilityData.facility, facility_id, 'facility_id')) {
            navigate('/facilities')
            loadingOverlay({show: false})
          }
          setFormMeta(facilityMetaData)

          if(facilityData.facility && !facilityData.facility.phone_country_code){
            facilityData.facility.phone_country_code = 1
          }

          if(facilityData.facility && !facilityData.facility.fax_country_code){
            facilityData.facility.fax_country_code = 1
          }

          reset(facilityData)
          // Hide loading overlay
          loadingOverlay({show: false})
          setLoading(false)
        })
      )
  }, [facility_id])

  useEffect(() => {
    if(methods.getValues('facility.phone') && methods.getValues('facility.phone').length < 10){
      methods.setError('facility.phone',{
        type: "custom",
        message: intl.formatMessage({id: 'FORM.INPUT.ALL.PHONE.VALIDATION.MESSAGE'})
      })
    }
    if(methods.getValues('facility.fax') && methods.getValues('facility.fax').length < 10){
      methods.setError('facility.fax',{
        type: "custom",
        message: intl.formatMessage({id: 'FORM.INPUT.ALL.FAX.VALIDATION.MESSAGE'})
      })     
    }
  }, [methods.getValues('facility.fax'), methods.getValues('facility.phone')]);

  useAutoFocus('NAME', loading)

  return (
    <>
    {!loading && (
			<>
      {props?.id == undefined ? (
        <PageTitle breadcrumbs={[]} description={[methods.watch('facility.name'), methods.getValues('facility.facility_id')]}>{intl.formatMessage({id: 'MENU.FACILITIES'})}</PageTitle>
      ) : (
        ''
      )}
      <FormProvider {...methods}>
        <div id={"facilities_detail_page"} className='modal-body py-3 px-4 showfee-input-form input-form'>
          <form noValidate className='form max-width' onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }>
          <HelpLink tooltip="FACILITIES.DETAIL.LINK.HELP" classname="top" />
            <div className='card-body p-0'>
              {' '}
              {/* Card Body */}
              <div className='form-group row'>
                <div className='row mb-2'>
                  {' '}
                  {/* Column Group */}
                  <div className='col-lg-6'>
                    {' '}
                    {/* Column 1 */}
                    <div className='row mb-2'>
                      <label
                        className='col-lg-3 col-form-label fs-5 fw-bold py-1 w-lg-150px'
                        htmlFor='NAME'
                        data-tooltip-id="FACILITIES.DETAIL.LABEL.NAME"
                      >
                        {intl.formatMessage({id: 'FORM.INPUT.FACILITIES.NAME'})}
                      </label>
                      <div className='col-lg-8'>
                        <input
                          {...methods.register('facility.name', {
                            required: 'Facility name is required.',
                          })}
                          id='NAME'
                          type='text'
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        />
                        {methods.formState.errors?.facility?.name && (
                          <div>
                            <span className='error_message'>
                              {methods.formState.errors.facility.name?.message}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <label
                        className='col-lg-3 col-form-label fs-5 fw-bold py-1 w-lg-150px'
                        htmlFor='ADDRESS'
                        data-tooltip-id="FACILITIES.DETAIL.LABEL.ADDRESS"
                      >
                        {intl.formatMessage({id: 'FORM.INPUT.FACILITIES.ADDRESS'})}
                      </label>
                      <div className='col-lg-8'>
                        <input
                          {...methods.register('facility.address')}
                          id='ADDRESS'
                          type='text'
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        />
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <label
                        className='col-lg-3 col-form-label fs-5 fw-bold py-1 w-lg-150px'
                        htmlFor='CITY'
                        data-tooltip-id="FACILITIES.DETAIL.LABEL.CITY/POSTALCODE"
                      >
                        {intl.formatMessage({id: 'FORM.INPUT.FACILITIES.CITY'}) +
                          '/' +
                          intl.formatMessage({id: 'FORM.INPUT.FACILITIES.POSTALCODE'})}
                      </label>
                      <div className='col-lg-5'>
                        <input
                          {...methods.register('facility.city')}
                          id='CITY'
                          type='text'
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        />
                      </div>
                      <div className='col-lg-3'>
                        <input
                          {...methods.register('facility.postal_code')}
                          id='CITY'
                          type='text'
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        />
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <label
                        className='col-lg-2 col-form-label fs-5 fw-bold py-1 w-lg-150px'
                        htmlFor='STATE'
                        data-tooltip-id="FACILITIES.DETAIL.LABEL.STATE"
                      >
                        {intl.formatMessage({id: 'FORM.INPUT.FACILITIES.STATE'})}
                      </label>
                      <div className='col-lg-8'>
                        <input
                          {...methods.register('facility.state')}
                          id='STATE'
                          type='text'
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        />
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <label
                        className='col-lg-2 col-form-label fs-5 fw-bold py-1 w-lg-150px'
                        htmlFor='PHONE'
                        data-tooltip-id="FACILITIES.DETAIL.LABEL.PHONENO"
                      >
                        {intl.formatMessage({id: 'FORM.INPUT.FACILITIES.PHONENO'})}
                      </label>
                      <div className='col-lg-8 '>
                        <Controller
                            control={methods.control}
                            name="facility.phone"
                            render={({ field: { onChange, value} }) => (
                                <IntlTelInput
                                  id='PHONE'
                                  inputClassName='form-control fs-6 py-0'
                                  containerClassName='intl-tel-input country-code-picker'
                                  placeholder=''
                                  style={{ width: '100%', borderRadius: '0px' }}
                                  preferredCountries={["us", "ca"]}
                                  defaultCountry={parsePhoneNumber(`+${methods.getValues('facility.phone_country_code')}${value}`, 'US')?.country?.toLowerCase()}
                                  allowDropdown={true}
                                  value={PhoneNumberFormatter(value)}
                                  onPhoneNumberChange={(_isValid, _rawValue,_countryData,_formattedValue,_extension) => {
                                      const unformattedValue = _rawValue.replace(/\D/g, ''); // Remove non-digit characters
                                      onChange(unformattedValue)
                                      if(unformattedValue.length < 10){
                                          methods.setError('facility.phone',{
                                              type: "custom",
                                              message: intl.formatMessage({id: 'FORM.INPUT.ALL.PHONE.VALIDATION.MESSAGE'})
                                          })
                                      }
                                      else{
                                          methods.clearErrors('facility.phone')
                                      }
                                  }}
                                  onSelectFlag={(currentNumber, country) => {if(country){ methods.setValue('facility.phone_country_code', country.dialCode, { shouldDirty: true})}}}
                              />
                            )}
                        />
                        { methods.formState.errors?.facility?.phone && <div><span className='error_message'>{methods.formState.errors.facility.phone?.message}</span></div>}
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <label
                        className='col-lg-2 col-form-label fs-5 fw-bold py-1 w-lg-150px'
                        htmlFor='FAX'
                        data-tooltip-id="FACILITIES.DETAIL.LABEL.FAX"
                      >
                        {intl.formatMessage({id: 'FORM.INPUT.FACILITIES.FAX'})}
                      </label>
                      <div className='col-lg-8 '>
                        <Controller
                            control={methods.control}
                            name="facility.fax"
                            render={({ field: { onChange, value} }) => (
                                <IntlTelInput
                                  id='FAX'
                                  inputClassName='form-control fs-6 py-0'
                                  containerClassName='intl-tel-input country-code-picker'
                                  placeholder=''
                                  style={{ width: '100%', borderRadius: '0px' }}
                                  preferredCountries={["us", "ca"]}
                                  defaultCountry={parsePhoneNumber(`+${methods.getValues('facility.fax_country_code')}${value}`, 'US')?.country?.toLowerCase()}
                                  allowDropdown={true}
                                  value={PhoneNumberFormatter(value)}
                                  onPhoneNumberChange={(_isValid, _rawValue,_countryData,_formattedValue,_extension) => {
                                      const unformattedValue = _rawValue.replace(/\D/g, ''); // Remove non-digit characters
                                      onChange(unformattedValue)
                                      if(unformattedValue.length < 10){
                                          methods.setError('facility.fax',{
                                              type: "custom",
                                              message: intl.formatMessage({id: 'FORM.INPUT.ALL.FAX.VALIDATION.MESSAGE'})
                                          })
                                      }
                                      else{
                                          methods.clearErrors('facility.fax')
                                      }
                                  }}
                                  onSelectFlag={(currentNumber, country) => {if(country){ methods.setValue('facility.fax_country_code', country.dialCode, { shouldDirty: true})}}}
                              />
                            )}
                        />
                        { methods.formState.errors?.facility?.fax && <div><span className='error_message'>{methods.formState.errors.facility.fax?.message}</span></div>}
                      </div>
                    </div>
                  </div>{' '}
                  {/* End Column 1 */}
                    <div className='col-lg-6'>
                      {' '}
                      {/* Column 2 */}
                      <h5 className='mt-2 fs-4 mb-1'>{intl.formatMessage({id: 'FORM.INPUT.FACILITIES.IAMBOARDSETTINGS'})}</h5>
                      <div className='row mb-2'>
                        <div className='col-lg-3 form-check-sm form-check-custom ps-lg-3'>
                          <input
                            {...methods.register('facility.iam_enabled', {
                              required: false,
                            })}
                            className='form-check-input'
                            type='checkbox'
                            id='IAMENABLED'
                          />
                          <label className='col-form-label mx-2 fs-5 fw-bold' htmlFor='IAMENABLED' data-tooltip-id="FACILITIES.DETAIL.LABEL.USEIAMBOARD">
                            {intl.formatMessage({id: 'FORM.INPUT.FACILITIES.IAMENABLED'})}
                          </label>
                        </div>

                        <div className='col-lg-4 form-check-sm form-check-custom'>
                          <input
                            {...methods.register('facility.iam_pyramid_updates', {
                              required: false,
                            })}
                            className='form-check-input'
                            type='checkbox'
                            id='IAMPYRAMIDYPDATES'
                          />
                          <label
                            className='col-form-label mx-2 fs-5 fw-bold py-1'
                            htmlFor='IAMPYRAMIDYPDATES'
                            data-tooltip-id="FACILITIES.DETAIL.LABEL.ENABLEPYRAMIDUPDATES"
                          >
                            {intl.formatMessage({
                              id: 'FORM.INPUT.FACILITIES.PYRAMIDUPDATES',
                            })}
                          </label>
                        </div>
                      </div>
                      <div className='row mb-2'>
                        <label className='col-lg-2 col-form-label fs-5 fw-bold py-1 w-lg-150px' htmlFor='IAMIPADDRESS' data-tooltip-id="FACILITIES.DETAIL.LABEL.IPADDRESS">
                          {intl.formatMessage({ id: 'FORM.INPUT.FACILITIES.IP' })}
                        </label>
                        <div className='col-lg-4'>
                          <input
                            {...methods.register('facility.iam_ipaddress', {
                              required: false,
                              pattern: {
                                value: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                                message: 'Invalid IP address',
                              },
                            })}
                            id='IAMIPADDRESS'
                            type='text'
                            className='form-control form-control-sm fs-6 min-h-20px py-1'
                          />
                          {methods?.formState?.errors?.facility?.iam_ipaddress && (
                            <span className='error_message'>{methods.formState.errors.facility.iam_ipaddress.message}</span>
                          )}
                        </div>
                        <div className='col-lg-2'>
                          <Controller
                            control={methods.control}
                            name="facility.iam_port"
                            render={({ field: { onChange, name, value } }) => (
                              <NumericFormat
                                id="IAMPORT"
                                name={name}
                                allowNegative={false}
                                decimalScale={0}
                                value={value === 0 ? "" : value}
                                type="text"
                                className="form-control form-control-sm fs-6 min-h-20px py-1"
                                onValueChange={(e) => {
                                  if (Number(e.value) !== Number(value)) {
                                    onChange(e.value);
                                  }
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className='row mb-2'>
                        <label className='col-lg-2 col-form-label fs-5 fw-bold py-1 w-lg-150px' htmlFor='IAMIPADDRESSTWO' data-tooltip-id="FACILITIES.DETAIL.LABEL.IPADDRESS2PORT">
                        {intl.formatMessage({id: 'FORM.INPUT.FACILITIES.IP'}) + '2/' + intl.formatMessage({id: 'FORM.INPUT.FACILITIES.PORT'})}
                        </label>
                        <div className='col-lg-4'>
                          <input
                          {...methods.register('facility.iam_ipaddresstwo', {
                            required: false,
                            pattern: {
                              value: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                              message: 'Invalid IP address',
                            },
                          })}
                          id='IAMIPADDRESSTWO'
                          type='text'
                          className='form-control form-control-sm fs-6 min-h-20px py-1'
                        />
                         {methods?.formState?.errors?.facility?.iam_ipaddresstwo && (
                            <span className='error_message'>{methods.formState.errors.facility.iam_ipaddresstwo.message}</span>
                          )}
                        </div>
                        <div className='col-lg-2'>
                          <Controller
                            control={methods.control}
                            name="facility.iam_porttwo"
                            render={({ field: { onChange, name, value } }) => (
                              <NumericFormat
                                id="IAMPORTTWO"
                                name={name}
                                allowNegative={false}
                                decimalScale={0}
                                value={value === 0 ? "" : value}
                                type="text"
                                className="form-control form-control-sm fs-6 min-h-20px py-1"
                                onValueChange={(e) => {
                                  if (Number(e.value) !== Number(value)) {
                                    onChange(e.value);
                                  }
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className='row mb-2'>
                        <label className='col-lg-2 col-form-label fs-5 fw-bold py-1 w-lg-150px' htmlFor='FARMTEKPORT' data-tooltip-id="FACILITIES.DETAIL.LABEL.FARMTEKPORT">
                          {intl.formatMessage({
                            id: 'FORM.INPUT.FACILITIES.FARMTEKPORT',
                          })}
                        </label>
                        <div className='col-lg-4'>
                          <Controller
                            control={methods.control}
                            name="facility.farmtek_port"
                            render={({ field: { onChange, name, value } }) => (
                              <NumericFormat
                                id="FARMTEKPORT"
                                name={name}
                                allowNegative={false}
                                decimalScale={0}
                                value={value === 0 ? "" : value}
                                type="text"
                                className="form-control form-control-sm fs-6 min-h-20px py-1"
                                onValueChange={(e) => {
                                  if (Number(e.value) !== Number(value)) {
                                    onChange(e.value);
                                  }
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      {/* <div className='row mb-2'>
                        <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='SERIES' data-tooltip-id="FACILITIES.DETAIL.LABEL.NUMBEROFRINGS">
                          {intl.formatMessage({
                            id: 'FORM.INPUT.FACILITIES.NUMBEROFRINGS',
                          })}
                        </label>
                        <div className='col-lg-4'>
                        <label className='col-lg-3 col-form-label fs-5 py-1'>{methods.getValues('facility.number_of_rings')}</label>
                        </div>
                      </div> */}
                    </div>
                  {/* End Column 2 */}
                </div>{' '}
                {/* End Column Group */}
                {facility_id > 0 ? 
                <div className='d-flex flex-column my-2 border p-2 bg-white'>
                  <ul
                    tabIndex="-1"
                    className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap'
                    id='myTab'
                    role='tablist'
                    style={{overflowX: 'auto', overflowY: 'hidden'}}
                  >
                    {tabs.map((tab, index) => {
                      return (
                        <li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
                          <a
                            tabIndex="-1"
                            className={
                              'nav-link text-active-dark px-10 py-1 text-gray-700' +
                              (index == selectedTab ? ' active' : '')
                            }
                            id={tab + '-tab'}
                            data-bs-toggle='tab'
                            href={'#' + tab}
                            data-tooltip-id={`FACILITIES.DETAIL.TAB.${tab}`}
                          >
                            {intl.formatMessage({id: 'FORM.INPUT.FACILITIES.TAB.' + tab})}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                  <div className='tab-content mt-5 pb-2' id='myTabContent'>
                    {tabs.map((tab, index) => {
                      return (
                        <div
                          key={index} 
                          className={'tab-pane fade show' + (index == selectedTab ? ' active' : '')}
                          id={tab}
                          role='tabpanel'
                        >
                          <HelpLink tooltip={"FACILITIES.DETAIL.TAB."+tab+".LINK.HELP"}  />
                          {componentTags[index]}
                        </div>
                      )
                    })}
                  </div>
                </div> : ""
                }
              </div>{' '}
              {/* End Card Body */}
            </div>{' '}
            {/* End Card Body */}
            <input type='hidden' {...methods.register('facility.number_of_rings')} />
            <InputFormFooter goBackPath={'/facilities'}  data={'facility'}/>
          </form>
        </div>
      </FormProvider>
      </>
    	)}
    </>
  )
}

export {FacilitiesDetail}
