import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useOrderOfGoContext } from '../OrderOfGoContext';
import { getExtraNarrowHeaderHeight, getNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers';
import { useAlert, useFlashAlert } from '../../../../modules/sgl-utils/DialogsProvider';

const ManualGrid = ({ rowData }) => {
    const intl = useIntl();
    const { manualRowData, setManualRowData, setManualOrderedTrips, orderedTripColumnApi, setManualGridApi, collapseMultipleTrips, isOrderOfGoSet, editOOG, orderType } = useOrderOfGoContext()
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const containerStyle = useMemo(() => ({ width: '100%', minHeight: '200px' }), []);
    const alertDialog = useAlert()
    const flashAlert = useFlashAlert()
    
    const columnDefs = [
        {   
            field: 'Entry.number', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.ENTRY"}), 
            cellClass: 'text-center',
            maxWidth: 90,
        },
        {   
            field: 'Class.number', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.CLASS"}), 
            cellClass: 'text-center',
            maxWidth: 90,
        },
        {   
            field: 'Entry.horse', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.HORSE"}), 
            flex: 1,
        },
        {   
            field: 'rider_name', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.RIDER"}), 
            flex: 1,
        },
        { 
            field: 'Entry.trainer', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.TRAINER"}),  
            flex: 1,
        },
    ]

    const handleRowDoubleClicked = (params) => {

        const selectedTrip = manualRowData.find(trip => trip.entryxclasses_id === params.data.entryxclasses_id)

        let message = ''
        if (isOrderOfGoSet && !editOOG) // Don't allow a re-ordering when order is already set, Allow editing OOG when order unlocked
            message = 'Order of Go for selected Class Group is already set. Please clear the order first.'
        else if (orderType !== "SetOrder")
            message = 'Order of Go for selected Class Group cannot be set. Please select correct Order Type.'
        else if (!selectedTrip) { // SHOULD NEVER HAPPEN!
            message = 'Trip not found.'
        }

        if (message) {
            alertDialog({ message })
            return
        }

        let tripsToOrder = [selectedTrip]
        if (collapseMultipleTrips) {
            // Get all trips with Entry number same as selected trip
            tripsToOrder = manualRowData.filter(trip => trip.Entry.number === selectedTrip.Entry.number)
        }

        let newOrder = rowData.length + 1
        for (const trip of tripsToOrder) {
            trip.order_of_go = newOrder++
        }

        // Remove row(s) from Manual (unordered)
        setManualRowData(prevManualRowData => {
            let newManualRowData = [...prevManualRowData]
            
            if (collapseMultipleTrips) {
                newManualRowData = newManualRowData.filter(trip => trip.Entry.number !== selectedTrip.Entry.number)
            } else {
                newManualRowData = newManualRowData.filter(trip => trip.entryxclasses_id !== selectedTrip.entryxclasses_id)
            }
            
            return newManualRowData
        })

        // Add row(s) to ordered grid
        setManualOrderedTrips(prevManualOrderedTrips => {
            return [...prevManualOrderedTrips, ...tripsToOrder]
        })

        // Display Flash Message
        const orderForFlashMessage = collapseMultipleTrips ? tripsToOrder.map(trip => trip.order_of_go).join(',') : selectedTrip.order_of_go
        flashAlert({ type: 'success', message: `Entry #${selectedTrip.Entry.number} ordered at ${orderForFlashMessage}.`});

        // clear column state (sort order)
        orderedTripColumnApi.resetColumnState()
    }

    const onGridReady = (params) => {
        setManualGridApi(params.api)
    };
    
    // This will show on the unordered trips (manual grid) only the unique entry numbers.
    const getOneTripPerEntry = (manualRowData) => {
        const tempObject = {}
        for (const row of manualRowData) {
            if (!tempObject[row.Entry.number]) {
                tempObject[row.Entry.number] = row
            }
        }

        return Object.values(tempObject)
    }

    return (
        <>
            <div className="col cursor-pointer">
                <fieldset>
                    <legend className='mt-2 fs-5 py-1 fw-bold'>
                        <span>
                            { intl.formatMessage({ id: "ORDEROFGO.MANUALGRID.LABEL.STEP.TWO" }) }
                        </span>
                    </legend>
                </fieldset>
            </div>
            <div className={`col d-flex`}>
                <div style={containerStyle}>
                    <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                            defaultColDef={{
                                sortable: true, 
                                suppressMenu: true, 
                                suppressSizeToFit: true,
                                headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header',
                                cellStyle: { 'paddingLeft': 0, 'paddingRight': 0 },
                            }}
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            rowData={collapseMultipleTrips ? getOneTripPerEntry(manualRowData) : manualRowData}
                            headerHeight={getExtraNarrowHeaderHeight}
                            rowHeight={getNarrowRowHeight}
                            rowDragEntireRow={true}
                            rowDragManaged = {true}
                            suppressMoveWhenRowDragging = {false}
                            animateRows = {true}      
                            rowSelection={'multiple'}
                            enableRangeSelection={false}
                            onRowDoubleClicked={handleRowDoubleClicked}
                            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while manual list is being loaded..</span>'}
                            domLayout='autoHeight'
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManualGrid