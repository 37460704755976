import axios from "axios"
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from "../../../modules/sgl-utils/SglFunctions"
import { useSchedulerContext } from "./SchedulerContext"
import { useState } from "react"
import { useIntl } from "react-intl"
import { useAlert } from "../../../modules/sgl-utils/DialogsProvider"
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const MinutesPerTrip = () => {
    const intl = useIntl()
    const alertDialog = useAlert()
    const [overFenceMinutesPerTrip, setOverFenceMinutesPerTrip] = useState('')
    const [underSaddleMinutesPerTrip, setUnderSaddleMinutesPerTrip] = useState('')
    const { getClassGroups, selectedDate, selectedRing, currentShowID, customerID } = useSchedulerContext()
    const updateMinutesPerTripForRing = async (event) => {
        if (underSaddleMinutesPerTrip === '' && overFenceMinutesPerTrip === '') {
            alertDialog({ message: 'Please enter valid Minutes Per Trip'})
            return
        }

        try {
            loadingSpinnerBtnWait(event)
            const response = await axios.post(`${NEST_API_URL}/scheduler/updateMinutesPerTripForRing`, {
                show_id: currentShowID,
                customer_id: customerID,
                ring_id: selectedRing,
                day: selectedDate,
                under_saddle_minutes_per_trip: underSaddleMinutesPerTrip,
                over_fence_minutes_per_trip: overFenceMinutesPerTrip
            })

            if (response.data.success) {
                //refresh list of class groups from the api response
                getClassGroups(true);
            }
        } catch (reason) {
        } finally {
            setUnderSaddleMinutesPerTrip('')
            setOverFenceMinutesPerTrip('')
            loadingSpinnerBtnRelease(event)
        }
    }

    return (
        <>
            <label 
                className='col-form-label fs-5 py-1' 
                htmlFor='overFenceMinutesPerTrip'
                style={{ width: '160px '}}
                data-tooltip-id="SETPLANNEDTIME.DETAIL.LABEL.MINUTESPERTRIPOVERFENCE"
            >
                <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.OVERFENCE.MINPERTRIP'})}</span>
            </label>
            <div className='w-lg-75px'>
                <input
                    id='overFenceMinutesPerTrip'
                    type='number'
                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                    value={overFenceMinutesPerTrip}
                    onChange={e => setOverFenceMinutesPerTrip(e.target.value)}
                />
            </div>
            <label 
                className='col-form-label fs-5 py-1' 
                htmlFor='underSaddleMinutesPerTrip'
                style={{ width: '160px '}}
                data-tooltip-id="SETPLANNEDTIME.DETAIL.LABEL.MINUTESPERTRIPUNDERSADDLE"
            >
                <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.UNDERSADDLE.MINPERTRIP'})}</span>
            </label>
            <div className='w-lg-75px'>
                <input
                    id='underSaddleMinutesPerTrip'
                    type='number'
                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                    value={underSaddleMinutesPerTrip}
                    onChange={e => setUnderSaddleMinutesPerTrip(e.target.value)}
                />
            </div>
            <div className='w-150px d-flex align-items-center'>
                <button 
                    type="button" 
                    onClick={updateMinutesPerTripForRing} 
                    className='btn btn-sm btn-secondary fw-bold h-30px py-1 px-1 w-125px'
                    data-tooltip-id="SETPLANNEDTIME.DETAIL.BUTTON.APPLY"
                >
                    <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                    {intl.formatMessage({id: 'SCHEDULER.SIGNUPGROUP.BUTTON.APPLY'})}
                </button>
            </div>
        </>
    )
}

export default MinutesPerTrip