import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  company_id: -1,
  name: "",
  urlpath: "",
  governing_organization: 'USEF',
  currency_code: "USD",
  // Use to disable certain features for sgl lite companies
  sgl_lite: true
}

const showCompanySlice = createSlice({
    name: 'showCompany',
    initialState: initialState,
    reducers: {
      setShowCompany(state, action) {
        let smc = action.payload;

        //set current show company in session Storage and local storage
        localStorage.setItem('currentShowCompany', JSON.stringify({ ...action.payload, currentShowCompanySetAt: Date.now() }));
        sessionStorage.setItem('currentShowCompany', JSON.stringify({ ...action.payload, currentShowCompanySetAt: Date.now() }));

        return {
          ...state,
          company_id: smc.company_id,
          name: smc.name,
          urlpath: smc.urlpath,
          governing_organization: smc.governing_organization,
          // Set currency_code to default (USD) incase of empty
          currency_code: smc.currency_code ? smc.currency_code.toUpperCase() : "USD",
          sgl_lite: true
        }
      },
    },
  })
  
  export const { setShowCompany } = showCompanySlice.actions

  export default showCompanySlice.reducer