import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useSchedulerContext } from './SchedulerContext';
import IconRenderer from './IconRenderer';
import DurationRenderer from './DurationRenderer';
import TimeRenderer from '../../../modules/output-listing/renderers/TimeRenderer';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const ClassGroupsGrid = (props) => {
  const gridRef = props.classGroupsGridRef;
  const intl = useIntl();
  const gridStyle = useMemo(() => ({ height: '212px', width: '100%' }), []);
  const containerStyle = useMemo(() => ({ width: '100%', height: '212px' }), []);
  const rowHeight = 27;

  const {setShowDates, setShowRings, setSelectedDate, setSelectedRing, setSelectedRingNo, getClassGroups, setClassGroupsGridRef, setCurrentClassGroup} = useSchedulerContext();
  const [rowData, setRowData] = useState([]);

  //Grid Class Groups
  const columnDefs = [
      { field: 'icon', headerName: "", sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, suppressSizeToFit: true, maxWidth: 30, minWidth: 30, cellRenderer: IconRenderer},
      { field: 'group_sequence', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.NUMBER'}), sortable: false, suppressMenu: true,cellStyle: {fontSize: '13px', 'paddingLeft': 5, 'paddingRight': 5}, maxWidth: 30,  minWidth: 30  },
      { field: 'group_name', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.GROUP'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px', 'paddingLeft': 5, 'paddingRight': 5}, minWidth: 200, resizable :true    },
      { field: 'class_list', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.CLASSES'}), sortable: false , suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, resizable :true, minWidth: 80   ,headerClass: 'ag-center-aligned-header' },
      { field: 'trips_to_go', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.TRIPSTOGO'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, maxWidth: 60  , minWidth: 60 ,headerClass: 'ag-center-aligned-header'},
      { field: 'class_time_per_trip', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.MINPERTRIP'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, maxWidth: 65 , minWidth: 65  ,headerClass: 'ag-center-aligned-header'},
      { field: 'class_setup_prep_time', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.CLASSPREP'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, maxWidth: 70  , minWidth: 70  ,headerClass: 'ag-center-aligned-header'},
      { field: 'duration', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.ESTDURATION'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, maxWidth: 85 , minWidth: 85  ,headerClass: 'ag-center-aligned-header' , cellRenderer: DurationRenderer},
      { field: 'estimated_start_time', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.ESTSTART'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5}, maxWidth: 70  , minWidth: 70  ,headerClass: 'ag-center-aligned-header', cellRenderer: TimeRenderer},
      { field: 'estimated_end_time', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.ESTEND'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5} , maxWidth: 70 , minWidth: 70 ,headerClass: 'ag-center-aligned-header',cellRenderer: TimeRenderer },
      { field: 'start_time_default', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.PLANNEDSTART'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 5, 'paddingRight': 5} , maxWidth: 100 , minWidth: 100  ,headerClass: 'ag-center-aligned-header', cellRenderer: TimeRenderer}
  ]
  
  useEffect(() => {
    setShowDates(props.showDates)
  }, [props.showDates]);

  useEffect(() => {
    setShowRings(props.showRings)
  }, [props.showRings]);

  useEffect(() => {
    setSelectedDate(props.date)
    setSelectedRing(props.ring)

    //Get ring no. of selected ring from show rings list based on ring_id
    let ringNo = 0;
    for(let ring_x_show of props.showRings){
      if(ring_x_show.ring_id == props.ring){
        ringNo = ring_x_show.ring_number;
          break;
      }
    }
    setSelectedRingNo(ringNo)
  }, [props.date, props.ring]);

  const setAutoScroll = useCallback(() => {
    if (gridRef && gridRef.current && gridRef.current.api) {
      const newGridOptions = {
        domLayout: props.autoHeight ? 'autoHeight' : 'normal',
      };
  
      gridRef.current.api.setDomLayout(newGridOptions.domLayout);
  
      const classGroupGridWrapperElement = document.querySelector('#classGroupGridWrapper');
      if (classGroupGridWrapperElement) {
        classGroupGridWrapperElement.style.height = props.autoHeight ? 'auto' : '212px';
      }

      const classGroupGridElement = document.querySelector('#classGroupGrid');
      if (classGroupGridElement) {
        classGroupGridElement.style.height = props.autoHeight ? 'auto' : '212px';
      }
    }
  }, [props.autoHeight, gridRef]);

  useEffect(() => {
    setAutoScroll();
  }, [props.autoHeight, setAutoScroll]);

  const onFirstDataRendered = useCallback((params) => {
      params.api.sizeColumnsToFit();
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: false,
      editable: false,
      wrapHeaderText: true
    };
  }, []);

  const onGridReady = useCallback((params) => {
    //set new grid api in scheduler context
    setClassGroupsGridRef(params.api);
  }, []);

  const classGroupSelected = useCallback((params) => {
    //set new grid api in scheduler context
    setCurrentClassGroup(params.data);
  }, []);

  const getRowStyle = params => {
      if (params.data.schedule_break == 1) {
          return { backgroundColor: '#feff7f' };
      }
  };
  
  const onDragStopped = params => {
    getClassGroups(false); 
    props.setScheduleStatus('Pending');
  };

  return (
    <>
    <div style={containerStyle} id="classGroupGridWrapper">
      <div style={gridStyle} className="ag-theme-alpine" id="classGroupGrid">
        <AgGridReact
          ref={gridRef}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowData}
          rowHeight={rowHeight}
          onFirstDataRendered={onFirstDataRendered}
          getRowStyle={getRowStyle}
          rowDragEntireRow={true}
          rowDragManaged = {true}
          suppressMoveWhenRowDragging = {false}
          animateRows = {true}      
          onGridReady = {onGridReady}
          onRowClicked={classGroupSelected}
          rowSelection={'multiple'}
          enableRangeSelection={false}
          onDragStopped = {onDragStopped}
          overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while groups list is loading.</span>'}
        ></AgGridReact>
      </div>
    </div>
    </>
  );
};

export default ClassGroupsGrid;
  