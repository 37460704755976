import { useIntl } from 'react-intl'
import { useAppSelector } from '../../redux/hooks';
import axios from 'axios';
import { useState, useEffect, useCallback} from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider} from "react-hook-form";
import { useAlert, useLoadingOverlay, useConfirm} from '../../modules/sgl-utils/DialogsProvider';
import { useNavigate } from 'react-router-dom';
import {Controller} from "react-hook-form";
import { NumericFormat} from 'react-number-format';
import DetailPageModal from '../../modules/sgl-utils/DetailPageModal';

// Custom Components
import { GeneralTab } from './Detail/GeneralTab';
import { RefundPaymentForm } from './Detail/RefundPaymentsForm';
import { HistoryTab } from '../../modules/components/HistoryTab';
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions';
import { PageTitle } from '../../../_metronic/layout/core'
import history from '../../modules/sgl-utils/unstableHistory';
import VISALogo from '../../../_metronic/assets/CardImages/Card_Visa.jpeg';
import AMEXLogo from '../../../_metronic/assets/CardImages/Card_Amex.jpeg';
import DISCOVERLogo from '../../../_metronic/assets/CardImages/Card_Discover.jpeg';
import MCLogo from '../../../_metronic/assets/CardImages/Card_MC.jpeg';
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt';
import useAccessChecker from '../../modules/hooks/use-access-checker';
import useAutoFocus from '../../modules/hooks/use-auto-focus';
import HelpLink from '../../modules/components/HelpLink'
import { SpreedlyTab } from './Detail/SpreedlyTab';
import moment from 'moment'

const { PUBLIC_URL } = process.env

export const dirtyValues = (
    dirtyFields,
    allValues
  ) => {
    // NOTE: Recursive function.
  
    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
      return allValues;
    }
  
    // Here, we have an object.
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [
        key,
        dirtyValues(dirtyFields[key], allValues[key])
      ])
    );
  };

  const PaymentDetail = (props) => {
    const intl = useIntl();
    const [form_meta, setFormMeta] = useState({})
    const {payment_id} = useParams();
    const loadingOverlay = useLoadingOverlay()
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const { hasSpecialPermissionToAccess } = useAccessChecker()
    const [loading, setLoading] = useState(true)

	let current_payment_id = props.id == "" ? 0 : props.id || props.id == 0 ? props.id : payment_id

	// const [update_data, setUpdateData] = useState(0)
    const navigate = useNavigate();
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)
    const [showRefundPaymentForm, setShowRefundPaymentForm] = useState(false)

    const [rowClicked, setRowClicked] = useState("")
    const [record_id, setRecordId] = useState("")
    const [updatedPaymentxentry, setUpdatedPaymentxentry] = useState({})
    const [sub_area, setSubArea] = useState("")
    const [child_component, setChildComponent] = useState("")
    const [payment_saved, setPaymentSaved] = useState(false)
    const [paymentCreatedToday, setPaymentCreatedToday] = useState(false)

    const [open_forms, setOpenForms] = useState([])
    const getDetailPageInfo = (id, area, component, amount_applied, paymentxentry_id) => {
      setRecordId(id)
      let reverse_payment = methods.getValues('reverse_payment')
      if(reverse_payment && reverse_payment.paymentxentries && reverse_payment.paymentxentries.length > 0){
        let new_pxe = reverse_payment.paymentxentries.find(pxe => pxe.entry_id == id)
        new_pxe['Payment'] = reverse_payment.new_payment
        setUpdatedPaymentxentry({paymentxentry_id, amount_applied, new_pxe})

      } else {
          setUpdatedPaymentxentry({paymentxentry_id, amount_applied})
      }
      setSubArea(area)
      setChildComponent(component)
      sessionStorage.setItem("dialog_is_open_in_payment", true)
      setRowClicked(rowClicked => !rowClicked)
      const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
      setOpenForms(forms_open)
    };
  
    // To open same detail page multiple times 
    useEffect(() => {
      if(sessionStorage.getItem("dialog_is_open_in_payment") == "true"){
        sessionStorage.removeItem("dialog_is_open_in_payment")
        setRowClicked(true)
        const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
        if(open_forms.includes(sub_area+"_"+record_id) == false){
          forms_open.push(sub_area+"_"+record_id);
          sessionStorage.setItem('forms_open', JSON.stringify(forms_open));
        }
      }
    }, [rowClicked])
  
    const [update_data, setUpdateData] = useState(0)
    // prevent function being recreated on state change
    const updateData = useCallback(() => {setUpdateData(update_data + 1) }, [update_data]);

    const methods = useForm({
        defaultValues: 
        {
            "payment":{
                "payment_id":0,
                "payment_date":"",
                "description":"",
                "amount":0,
                "type":"",
                "refcode":null,
                "verified":null,
                "cc_transid":"",
                "entry":null,
                "sgl_id":null,
                "web_add":null,
                "invoice_number":null,
                "status":null,
                "reversed": 0
            },
            'paymentxentries': [],
            'payment_method': null,
            'spreedlyEnabled': false,
            'reverse_payment': null,
            'refund_payment': null,
            'refunded_amount': 0.00,
            'refund_amount': 0.00,
        }
      }); 
      const { register, handleSubmit, reset, formState, setFocus } = methods
      const { dirtyFields } = formState;
    useNavigationPrompt(formState)
    const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
    const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);

    const tabs = [ "GENERAL" , "HISTORY" , ...(hasSpecialPermissionToAccess( 'cloud_sgadmin:has_access' ) && methods.getValues('payment.cc_transid') ? [ "SPREEDLY" ] : [] )  ]
    const componentTags = [
        <GeneralTab form_meta={form_meta} callbackFromParent={getDetailPageInfo}/>, 
        <HistoryTab area={"Payment"} rowData={methods.getValues('change_history_logs')} />,
        ...(hasSpecialPermissionToAccess( 'cloud_sgadmin:has_access' ) && methods.getValues('payment.cc_transid') ? [ <SpreedlyTab id={current_payment_id} /> ] : [] )
    ]
    const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	});

      useEffect(() => {
        // Show loading overlay
        loadingOverlay({show: true})
        let existing_paymentxentries = methods.getValues('paymentxentries')
           
        axios.all(
            [
                axios.get( process.env.REACT_APP_NEST_API_URL + '/payments/detail', {
                    params: {
                      payment_id: current_payment_id,
                      customer_id: customer_id,
                    }
                  }) 
            ]  
        ).then (
            axios.spread( ({data : paymentData} ) => {
                
                //Check if record id is invalid then redirect to list page
                if((paymentData || paymentData === '') && isInvalidRecord(paymentData.payment, current_payment_id, 'payment_id')){
                    navigate('/payments')
                    loadingOverlay({show: false})
                }
                if(update_data > 0){
                    let existing_paymentxentries = methods.getValues('paymentxentries')
                    for(let pxe of existing_paymentxentries){
                        let new_pxe = paymentData?.paymentxentries.find(row => row.sgl_id == pxe.sgl_id)
                        if(new_pxe){
                            pxe['entry_balance'] = new_pxe['entry_balance']
                        }
                    }
                    methods.getValues('paymentxentries')
                } else {
                    if(props?.id === undefined && Number(methods.getValues('payment.payment_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
                        reset(paymentData)
                      }else{
                        reset(paymentData, { keepDirtyValues: true })
                    }
                }
                if(sessionStorage.getItem('forms_open') == undefined || current_payment_id == payment_id){
                    sessionStorage.setItem('forms_open', JSON.stringify(["Payments_"+Number(current_payment_id)]));
                }
                  
                loadingOverlay({show: false})
                setLoading(false)
            })
        )
    },[payment_id, update_data, payment_saved])

	useEffect(() => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');
		if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
			next_button.disabled = true;
			last_button.disabled = true;
		}
		if (row_selected == 0 && prev_button && first_button) {
			prev_button.disabled = true;
			first_button.disabled = true;
		}
	}, [loading])

    useEffect(() => {
        setPaymentCreatedToday(methods.getValues('current_date') && moment(methods.getValues('current_date')).isSame(methods.getValues('payment.payment_date')))
    }, [methods.watch('payment.payment_date')])

    const onSubmit = async (form_data) => { 
        let submit_data = dirtyValues(dirtyFields, form_data)
        let paymentxentries = methods.getValues('paymentxentries')
        let updated_paymentxentries = paymentxentries.filter(pxe => pxe.isUpdated)
        submit_data.updated_paymentxentries = updated_paymentxentries
        let total_amount = getTotalAmount()
        if(total_amount != methods.watch('payment.amount')){
            let choice = await confirmDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.WARNING.AMOUNTdIFFERENT' }), title: 'Warning', icon:'warning', okButtonTitle: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.WARNING.BUTTON.OK' }), cancelButtonTitle: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.WARNING.BUTTON.CANCEL' })})
            if(choice){
                callSaveApi(submit_data)                
            }
        } else {
            callSaveApi(submit_data)
        }
    }

    function callSaveApi(submit_data){
        loadingOverlay({show: true})
        const next_button = document.getElementById('next-btn');
        const last_button = document.getElementById('last-btn');
        const prev_button = document.getElementById('prev-btn');
        const first_button = document.getElementById('first-btn');
  
        // means detail page is not open in modal
        if(props?.id === undefined){
            next_button.disabled = false;
            prev_button.disabled = false;
            last_button.disabled = false;
            first_button.disabled = false;
        }

        return axios.post( process.env.REACT_APP_NEST_API_URL + '/payments/detail', {
            params: {
                payment_id: current_payment_id,
                customer_id: customer_id,
                show_id: currentShowID,
                data: submit_data
            }
        }).then((response) => {
            loadingOverlay({show: false})

            if(response.data.success) {
                // if(props?.id == undefined){

                // } else {
                    // if(props.updateCallbackFromParent){
                    //     props.updateCallbackFromParent()
                    //     props.closeModal()
                    // }
                // }

                // Use Case (Param Id changed): Next/Prev buttons
                if(props?.id == undefined && Number(methods.getValues('payment.payment_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
                    if(row_selected && row_selected != "" && row_ids[row_selected]){
                        history.push(`${PUBLIC_URL}/payments/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
                    }
                }
                else if(props?.id == undefined && current_payment_id > 0){
                    history.push(`${PUBLIC_URL}/payments`, { allowNavigation: true })
                }
                else if(props?.id == undefined && current_payment_id == 0){
					history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
				}
                
                if( row_selected == row_ids?.length -1 && props?.id === undefined){
                    if (next_button && last_button) {
                        next_button.disabled = true;
                        last_button.disabled = true;
                        prev_button.disabled = false;
                        first_button.disabled = false;
                    }
                }
                if(row_selected == 0 && props?.id === undefined){
                    if (prev_button && first_button) {
                        prev_button.disabled = true;
                        first_button.disabled = true;
                        next_button.disabled = false;
                        last_button.disabled = false;
                    }
                }
            } else {
                alertDialog({message: response.data.error_message, title: 'Error'})
            }
        })
    }

    function callRefundAPI(paymentFormData){
        let form_data = methods.getValues()
        let submit_data = dirtyValues(dirtyFields, form_data)
        let paymentxentries = methods.getValues('paymentxentries')
        let updated_paymentxentries = paymentxentries.filter(pxe => pxe.isUpdated)
        submit_data.updated_paymentxentries = updated_paymentxentries

        loadingOverlay({show: true})

        return axios.post( process.env.REACT_APP_NEST_API_URL + '/payment-methods/refundTransaction', {
            params: {
                payment: methods.getValues('payment'),
                customer_id: customer_id,
                show_id: currentShowID,
                paymentFormData: paymentFormData,
                submit_data: submit_data
            }
        }).then((response) => {

            if(response.data.success) {
                RefundPaymentFormReset();
                setPaymentSaved(!payment_saved)
                // methods.setValue('payment', response.data.payment, {shouldDirty:true})
                // methods.setValue('refund_payment', response.data.refund_response, {shouldDirty:true})
                // methods.setValue('refunded_amount', refunded_amount + response.data.refundAmount)
                alertDialog({message: 'Payment refunded successfully', title: 'info', icon: 'info'})
                

            } else {
                alertDialog({message: response.data.error, title: 'Error'})
            }
        }).finally(() => {
            loadingOverlay({show: false})
        })
    }

    function getTotalAmount(){
        let paymentxentries = methods.watch('paymentxentries')
        let total_amount = paymentxentries.map(pxe => parseFloat(pxe.amount_applied)).reduce(function (total, num) { return total + num; }, 0);
        return total_amount
    }
   
    
    const renderCardLogo = () => {
        switch (methods.getValues('payment_method.card_type')) {
            case 'visa': return <img className='h-60 w-60px' src={VISALogo} />
            case 'american_express': return <img className='h-60 w-60px' src={AMEXLogo} />
            case 'discover': return <img className='h-60 w-60px' src={DISCOVERLogo} />
            case 'master': return <img className='h-60 w-60px' src={MCLogo} />
        }
    }

    async function reverseTransaction() {
        let payment = methods.getValues('payment')
        if (payment['reversed'] || payment['type'] == "void" || payment['Type'] == "refund" || payment['Type'] =="reversed"){  //These transactions cannot be reversed
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.ERROR.CANNOTREVERSE' }), title: 'Error'})

        } else {
            if (payment['type'] == "Authorize.net" || payment['Type'] == "SecureNet" || payment['type'] == "TD Bank" || payment['type'] == "PayPal"){
                if (!methods.getValues('spreedlyEnabled')){
                    alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.ERROR.CANNOTREVERSE' }), title: 'Error'})
                } else {
                    let choice = await confirmDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.CONFIRM.REVERSE.MANNUAL' }), title: 'Warning', icon:'warning', okButtonTitle: intl.formatMessage({ id: 'BUTTON.OK' }), cancelButtonTitle: intl.formatMessage({ id: 'BUTTON.CANCEL' })})
                    if(choice){
                        // loadingOverlay({show: true})
                        callReverseApi()                
                    }               

                }

            } else {
                let choice = await confirmDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.CONFIRM.REVERSE' }), title: 'Warning', icon:'warning', okButtonTitle: intl.formatMessage({ id: 'BUTTON.OK' }), cancelButtonTitle: intl.formatMessage({ id: 'BUTTON.CANCEL' })})
                    if(choice){
                        // loadingOverlay({show: true})
                        callReverseApi()                
                    }  
            }
            
        }
    }

    function callReverseApi(){
        let submit_data = {
            payment: methods.getValues('payment'),
            pxes: methods.getValues('paymentxentries')
        }
        return axios.post( process.env.REACT_APP_NEST_API_URL + '/payment-methods/reverseTransaction', {
            params: {
                payment_id: current_payment_id,
                customer_id: customer_id,
                data: submit_data
            }
        }).then((response) => {
            loadingOverlay({show: false})

            if(response.data.success) {
                methods.setValue('payment', response.data.payment, {shouldDirty:true})
                methods.setValue('reverse_payment', response.data.reverse_payment, {shouldDirty:true})
                reverseEntryBalances()
                alertDialog({message: 'Payment reversed successfully', title: 'info', icon: 'info'})
            } else {
                alertDialog({message: response.data.error, title: 'Error'})
            }
        })
    }

    function reverseEntryBalances(){
        let paymentxentries = methods.getValues("paymentxentries")
        for(let pxe of paymentxentries){
            pxe.entry_balance = pxe.entry_balance + pxe.amount_applied
        }
        methods.setValue("paymentxentries", paymentxentries)

    }

    const refundTransaction = () => {
        // todo: complete this funtion
        setShowRefundPaymentForm(true)
    }

    function RefundPaymentFormReset(){
        methods.setValue('reason', '')
        methods.setValue('refund_amount', 0)

        let paymentxentries = methods.getValues('paymentxentries').map(fillCheckboxesData => {return {...fillCheckboxesData,check: false,refund_amounts:0}})
        methods.setValue('paymentxentries', paymentxentries)
        setShowRefundPaymentForm(false);
    }

    useAutoFocus('REFERENCE', loading, update_data)
    return (
        <>
            {showRefundPaymentForm && 
                <RefundPaymentForm 
                show={showRefundPaymentForm} 
                handleClose={() => RefundPaymentFormReset()} 
                refundPayment={methods.getValues('payment')} 
                callRefundAPI={callRefundAPI}
                // payment_distribution={methods.getValues('paymentxentries').map(fillCheckboxesData => {return {...fillCheckboxesData,check: false,refund_amounts:0}})} 
                refunded_amount={methods.getValues('refunded_amount')} 
                methods={methods}
                paymentCreatedToday={paymentCreatedToday} // if the payment refund dialog is opened for the payment made in current date then all the payment fields will be disabled
                />
            }

            {props?.id == undefined ? <PageTitle breadcrumbs={[]} description={[methods.watch('payment.type'), methods.watch('payment.description'), methods.getValues('payment.payment_id')]}>{intl.formatMessage({ id: 'MENU.PAYMENTS' })}</PageTitle> : ""}
            <FormProvider {...methods}>
			<div id={"payments_detail_page"} className='modal-body py-3 px-4 horse-input-form input-form'>
                <form noValidate className='form max-width' onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }>
                <HelpLink tooltip="PAYMENTS.DETAIL.LINK.HELP" classname="top" />
                    <div className='card-body p-0'> {/* Card Body */}
						<div className="form-group row"> {/* Column Group */}
							<div className='row mb-2'>
								<div className='col-lg-8'>
									<div className='row mb-2'>
										<label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-100px' htmlFor='DATE' data-tooltip-id="PAYMENTS.DETAIL.LABEL.DATE">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.DATE' })}</label>
										<div className='col-lg-4'>
											<input
												{...methods.register( 'payment.payment_date', 
													{
														required: false
													}
												  )
												}
												id='DATE'
												type='date'
                                                disabled={true}
												className='form-control form-control-sm fs-6 min-h-20px py-1'
												placeholder={intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.DATE' })}
											/>
										</div>
										<label className='col-lg-2 col-form-label fw-bold fs-5 py-1 me-xl-2 me-lg-2 me-xxl-3' htmlFor='STATUS' data-tooltip-id="PAYMENTS.DETAIL.LABEL.STATUS">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.STATUS' })}</label>
										<div className='col-lg-4'>
											<input
												{...methods.register( 'payment.status', 
                                                    {
                                                        required: false
                                                    }
                                                  )
                                                }
												id='STATUS'
												type='text'
                                                disabled={true}
												className='form-control form-control-sm fs-6 min-h-20px py-1'
											/>
										</div>

                                        {/* <label className='col-lg-3 text-end col-form-label fw-bold fs-5 py-1 me-xl-2 me-lg-2 me-xxl-3' htmlFor='WEBPAYMENT'>{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.WEBPAYMENT' })}</label> */}
										
									</div>
                                    <div className='row mb-2'>
										<label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-100px' htmlFor='DATE' data-tooltip-id="PAYMENTS.DETAIL.LABEL.TYPE">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.TYPE' })}</label>
										<div className='col-lg-4'>
											<input
												{...methods.register( 'payment.type', 
													{
														required: false
													}
												  )
												}
												id='TYPE'
												type='text'
                                                disabled={true}
												className='form-control form-control-sm fs-6 min-h-20px py-1'
											/>
										</div>
										<label className='col-lg-2 col-form-label fw-bold fs-5 py-1 me-xl-2 me-lg-2 me-xxl-3' htmlFor='INVOICENUMBER' data-tooltip-id="PAYMENTS.DETAIL.LABEL.INVOICENUMBER">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.INVOICENUMBER' })}</label>
										<div className='col-lg-4'>
											<input
												{...methods.register( 'payment.invoice_number', 
                                                    {
                                                        required: false
                                                    }
                                                  )
                                                }
												id='INVOICENUMBER'
												type='text'
                                                disabled={true}
												className='form-control form-control-sm fs-6 min-h-20px py-1'
											/>
										</div>
									</div>
                                    <div className='row mb-2'>
										<label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-100px' htmlFor='REFERENCE' data-tooltip-id="PAYMENTS.DETAIL.LABEL.REFERENCE">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.REFERENCE' })}</label>
										<div className='col-lg-4'>
											<input
												{...methods.register( 'payment.refcode', 
													{
														required: false
													}
												  )
												}
												id='REFERENCE'
												type='text'
												className='form-control form-control-sm fs-6 min-h-20px py-1'
											/>
										</div>
										<label className='col-lg-2 col-form-label fw-bold fs-5 py-1 me-xl-2 me-lg-2 me-xxl-3' htmlFor='TRANSACTIONID' data-tooltip-id="PAYMENTS.DETAIL.LABEL.TRANSACTION">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.TRANSACTIONID' })}</label>
										<div className='col-lg-4'>
											<input
												{...methods.register( 'payment.cc_transid', 
                                                    {
                                                        required: false
                                                    }
                                                  )
                                                }
												id='TRANSACTIONID'
												type='text'
                                                disabled={true}
												className='form-control form-control-sm fs-6 min-h-20px py-1'
											/>
										</div>
									</div>
                                    <div className='row mb-2'>
										<label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-100px' htmlFor='MEMO' data-tooltip-id="PAYMENTS.DETAIL.LABEL.DESCRIPTION">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.MEMO' })}</label>
										<div className='col-lg-4'>
											<input
												{...methods.register( 'payment.description', 
													{
														required: false
													}
												  )
												}
												id='MEMO'
												type='text'
												className='form-control form-control-sm fs-6 min-h-20px py-1'
											/>
										</div>
										<label className='col-lg-2 col-form-label fw-bold fs-5 py-1 me-xl-2 me-lg-2 me-xxl-3' htmlFor='AMOUNT' data-tooltip-id="PAYMENTS.DETAIL.LABEL.AMOUNT">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.AMOUNT' })}</label>
										<div className='col-lg-4'>
                                            <Controller
                                                control={methods.control}
                                                name="payment.amount"
                                                render={({ field: { onChange, value} }) => (
                                                <NumericFormat
                                                    id='AMOUNT'
                                                    prefix={'$'}
                                                    allowNegative={true}
                                                    value={value !== '' ? Number(value): ""}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    onValueChange={(e) => {
                                                        // Only update the value if it's changed by the user
                                                        if (Number(e.value) !== Number(value)) {
                                                          onChange(e.value);
                                                        }
                                                    }}
                                                    onFocus={(e) => {
                                                        e.target.select(); // Highlight the value
                                                    }}
                                                    disabled = {true}
                                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                />
                                                )}
                                            />
										</div>
									</div>
                                    
								</div>
                                <div className='col-4'>
                                    <div className='row mx-0'>
                                        <div className='col form-check-sm form-check-custom'>
                                            <input
                                                {...register("payment.web_add", 
                                                {
                                                    required: false
                                                })
                                                }
                                                className='form-check-input '
                                                type='checkbox'
                                                id='web_add'
                                                disabled={true}
                                            />
                                            <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='web_add' data-tooltip-id="PAYMENTS.DETAIL.LABEL.WEBPAYMENT"> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.WEBPAYMENT' })}</label>
                                        </div>

                                        {/* Chargeback Read only */}
                                        <div className='col form-check-sm form-check-custom'>
                                            <input
                                                {...register("payment.chargeback", 
                                                {
                                                    required: false
                                                })
                                                }
                                                className='form-check-input '
                                                type='checkbox'
                                                id='chargeback'
                                                disabled={true}
                                            />
                                            <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='chargeback'> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.CHARGEBACK' })}</label>
                                        </div> 
                                    </div>
                                    {methods.getValues('payment_method') && <>
                                        <div className='row'>
                                            <label className='col-form-label fs-5 py-1 fw-bold' htmlFor='paid_using_credit_card' data-tooltip-id="PAYMENTS.DETAIL.LABEL.PAIDUSINGCREDITCARD"> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.PAIDUSINGCREDITCARD' })}</label>
                                        </div>
                                    
                                        <div className='col form-control py-0 px-3'>
                                            <div className='row'>
                                                <label className='col-lg-4 col-form-label fs-5 py-1 w-lg-120px pe-0'>{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.CREDITCARDNUM' })}:</label>
                                                <label className={'col col-form-label fs-5 py-1 d-flex ps-0'+(methods.getValues('payment_method.redacted')?' text-danger':'')}>XXXX-XXXX-{methods.getValues('payment_method.card_lastfour')}</label>
                                            </div>
                                            <div className='row'>
                                                <label className='col-lg-4 col-form-label fs-5 py-1 w-lg-120px pe-0'>{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.CARDTYPE' })}:</label>
                                                <div className='col' style={{padding: '0'}}>
                                                    {renderCardLogo()}
                                                </div>
                                                {/* <label className='col col-form-label fs-5 py-1 d-flex justify-content-end ps-0'>XXXX-XXXX-{methods.getValues('payment_method.card_lastfour')}</label> */}
                                            </div>
                                            <div className='row'>
                                                <label className='col-lg-4 col-form-label fs-5 py-1 w-lg-120px pe-0'>{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.NAMEONCARD' })}:</label>
                                                <label className={'col col-form-label fs-5 py-1 d-flex ps-0'+(methods.getValues('payment_method.redacted')?' text-danger':'')}>{methods.getValues('payment_method.name_on_card')}</label>
                                            </div>
                                            
                                        </div>
                                    </>}
                                    
                                </div>
                            </div>
                           
                        </div> {/* End Column Group */}
                        {hasSpecialPermissionToAccess('cloud_payments:refund-payments') && 
                            <div className='d-flex justify-content-center'>
                            {methods.getValues('payment_method') ?
                                <button type='button' disabled = { (methods.getValues('payment.reversed') == 1) || showFinancialsLocked } className="btn btn-sm btn-secondary fw-bold col-12 h-30px py-1 w-200px text-uppercase" onClick={(e)=>{refundTransaction(); e.preventDefault()}} data-tooltip-id="PAYMENTS.DETAIL.BUTTON.REFUNDTRANSACTION"> { paymentCreatedToday ? intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.VOIDPAYMENT' }) : intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.REFUNDTRANSACTION' })}</button>
                            :
                                <button type='button' disabled = { (methods.getValues('payment.reversed') == 1) || showFinancialsLocked || (methods.getValues('payment.type') == 'Prize Money')} className="btn btn-sm btn-secondary fw-bold col-12 h-30px py-1 w-200px text-uppercase" onClick={(e)=>{reverseTransaction(); e.preventDefault()}} data-tooltip-id="PAYMENTS.DETAIL.BUTTON.REVERSETRANSACTION"> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.LABEL.REVERSETRANSACTION' })}</button>
                            }
                            </div>
                        }
                        <div className='d-flex flex-column my-2 border p-2 bg-white'>
								<ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
									{
										tabs.map((tab, index) => 
											<li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
												<a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == selectedTab ? ' active' : '')}
													tabIndex="-1"
                                                    id={tab + "-tab"}
													data-bs-toggle='tab'
													href={"#" + tab + "-Payment-" + current_payment_id}
                                                    data-tooltip-id={`PAYMENTS.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.TAB.' + tab })}
												</a>
											</li>
										)
									}
								</ul>
								<div className="tab-content mt-5 pb-2" id="myTabContent">
									{
										tabs.map((tab, index) => {
											return (
												<div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab + "-Payment-" + current_payment_id} role="tabpanel" >
                                                    <HelpLink tooltip={"PAYMENTS.DETAIL.TAB."+tab+".LINK.HELP"}  />
													{componentTags[index]}
												</div>
											);
										}) 
									}
                                    
								
							</div>
						</div>
					</div> {/* End Card Body */}
					<InputFormFooter goBackPath={'/payments'} propId={props?.id}  data={'payment'}/> 
				</form>
			</div>
		</FormProvider>
        {rowClicked && <DetailPageModal prev_data={updatedPaymentxentry} area={sub_area} detail_page={child_component} record_id={record_id} updateCallbackFromParent={updateData} open_forms={open_forms}/>}
        </>
    )
  }

  export { PaymentDetail }