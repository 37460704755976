/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useNavigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth, AuthLayout, SGL_CLOUD_REFERER} from '../modules/auth'
import {App} from '../App'
import { LicenseManager } from  'ag-grid-enterprise'
import CompanySelect from '../modules/auth/components/CompanySelect'
import UpdatePassword from '../modules/auth/components/UpdatePassword'
import { unstable_HistoryRouter as Router } from 'react-router-dom'
import unstableHistory from '../modules/sgl-utils/unstableHistory'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL, REACT_APP_AG_GRID_LICENSE} = process.env

if(REACT_APP_AG_GRID_LICENSE){ //Set AG Grid Enterprise license key
  LicenseManager.setLicenseKey(REACT_APP_AG_GRID_LICENSE?.toString());
}

const AppRoutes: FC = () => {
  const { currentUser, currentCompany, updatePassword } = useAuth()

  return (
    // <BrowserRouter basename={PUBLIC_URL}>
    <Router history={unstableHistory} basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser && currentCompany ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='auth/*' />} />
            </>
          ) : currentUser ? 
            (updatePassword)?
            <>
            <Route element={<AuthLayout />}>
                <Route path="/*" element={<UpdatePassword /> } />
            </Route>
            </>
            :
            <Route element={<AuthLayout />}>
                <Route path="/*" element={<CompanySelect /> } />
            </Route>
          : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              {/* BeforeAuthWrapper set the path of referer before redirecting to auth */}
              {/* <Route path='*' element={<Navigate to='/auth' />} /> */}
              <Route path='*' element={<BeforeAuthWrapper />} />
            </>
          )}
        </Route>
      </Routes>
    </Router>
    // </BrowserRouter>
  )
}

const BeforeAuthWrapper = () => {
    const navigate = useNavigate()
    useEffect(() => {
        try {

            // Clear Show from Local Storage on Log In
            // If user has opened a new tab, most recent show will be set.
            // If user came to login page after inactivity logout (from same TAB), show will retain. 
            localStorage.removeItem('currentShow')

            // Get current url
            const pathname = window.location.pathname
            const queryString = window.location.search
            
            // Don't set referer if url contains auth
            // Handling for multiple reloads of auth page incase of wrong password etc.
            if (!pathname.includes("auth")) {
                // Parse the query string into a URLSearchParams object
                const queryParams = new URLSearchParams(queryString);

                // Create an object to store all query parameters
                const allParams: any = {};

                // Iterate through the query parameters and store them in the object
                queryParams.forEach((value, key) => { allParams[key] = value })

                sessionStorage.setItem(SGL_CLOUD_REFERER, JSON.stringify({ pathname, queryParams: allParams }))
            }
            navigate('/auth' )
        } catch(err) {
            console.log("Error setting referer")
            navigate('/auth' )
        }
    }, [])

    return <></>
}

export {AppRoutes}
