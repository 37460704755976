import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { useFormContext, FormProvider } from 'react-hook-form'
import {useState} from "react"

type Props = {
  show: boolean
  handleClose: () => void
  horseName: string
  acceptMismatch: () => void
  handleClearMembership: (org: string) => void,
  name: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const FEINameMismatchAlert = ({ show, handleClose, horseName, acceptMismatch, handleClearMembership, name}: Props) => {
  const intl = useIntl()
  const methods = useFormContext()


  const buttonCheck = () =>{ 
    acceptMismatch()
    handleClose()
  }

  const cancelCheck = () => {
    handleClearMembership('FEI')
    handleClose()
  }


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px search-form'
      show={show}
      onHide={handleClose}
      onKeyDown={(event :any) => { //replaced onKeyPress with onKeyDown
        let isButtonFocused = false
        let activeElement = document.activeElement //gets the currently focussed element
        if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
            isButtonFocused = true;
        }
        if (event.key === "Enter" && !isButtonFocused) {
          event.preventDefault() //avoid chained enter press
          buttonCheck()
        }
      }}
    >
      <div className='modal-header py-0 px-4'>
        <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.FEIMEMBERSHIP.MODAL.TITLE.FEINAMEMISMATCH' })}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4'> 
          <form noValidate className='form' onSubmit={e => e.preventDefault()}>
            <div className='card-body p-0'>
              <div className='row mb-2'>
                <label className='col-form-label mx-2 fs-5 py-1'>{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.FEIMEMBERSHIP.ACCEPTNAMEMISMATCH.MODAL.MESSAGE' })}</label>
                <label className='col-form-label mx-2 fs-5 py-1' data-tooltip-id="HORSE.DETAIL.MODAL.NAMEMISMATCHALERT.LABEL.NAMEONFILE">Name on File: {name}</label>
                <label className='col-form-label mx-2 fs-5 py-1' data-tooltip-id="HORSE.DETAIL.MODAL.NAMEMISMATCHALERT.LABEL.DATABASENAME">FEI Database Name: {horseName}</label>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-3 px-0'>

              <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={cancelCheck} autoFocus data-tooltip-id="HORSE.DETAIL.MODAL.NAMEMISMATCHALERT.BUTTON.REJECT">
                {intl.formatMessage({ id: 'BUTTON.REJECT' })}
              </button>
              <button type='button' className='btn btn-sm btn-dark fw-bold'
              id ="Ok"
              onClick = {buttonCheck}
              autoFocus
              data-tooltip-id="HORSE.DETAIL.MODAL.NAMEMISMATCHALERT.BUTTON.ACCEPT"
              >
                {intl.formatMessage({ id: 'BUTTON.ACCEPT' })}
              </button>
            </div>
          </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { FEINameMismatchAlert }