
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useForm, FormProvider } from 'react-hook-form';
import { KTSVG } from '../../../../_metronic/helpers';

type Props = {
    show: boolean
    handleClose: () => void
    handleSave: ({ }) => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ChangeEmailDialog = ({ show, handleSave, handleClose }: Props) => {
    const intl = useIntl()
    const methods = useForm({
        defaultValues: {
            new_email: '',
            confirm_email: '',
            error_message: '',
        },
    });
    const { register, getValues, watch, reset } = methods

    const error_message = watch('error_message')

    const onClose = () => {
        reset()
        handleClose()
    }

    const onSave = () => {
        let data = getValues()
        handleSave(data)
        onClose()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-xl search-form mw-450px'
            show={show}
            onHide={handleClose}
            onKeyPress={(event :any) => {
                if (event.key === "Enter") {
                    onSave()
                }
            }}
        >
            <div className='modal-header py-0 px-4' >
                <h2 className="fs-4" >{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.CHANGEEMAIL' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <FormProvider {...methods}>
                    <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                        <div className='card-body p-0'> {/* Card Body */}
                            <div className="form-group"> {/* Column Group */}
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-100px pe-0' htmlFor='NEWEMAIL'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.CHANGEEMAIL.LABEL.NEWEMAIL' })}</label>
                                    <div className='col'>
                                        <input
                                            id='NEWEMAIL'
                                            type='text'
                                            {...register('new_email')}
                                            className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-4'>
                                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-100px pe-0' htmlFor='VERIFYEMAIL'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.CHANGEEMAIL.LABEL.VERIFYEMAIL' })}</label>
                                    <div className='col'>
                                        <input
                                            id='VERIFYEMAIL'
                                            type='text'
                                            {...register('confirm_email')}
                                            className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        />
                                    </div>
                                </div>
                                <div className={`row mb-2 ${error_message?.length ? '' : 'd-none'}`}>
                                    <label className='col-lg-12 col-form-label fw-bold fs-5 py-1' style={{ color: 'rgb(230, 39, 33)' }}>{error_message}</label>
                                </div>
                            </div> {/* End Column Group */}
                        </div>{/* End Card Body */}

                        <div className='card-footer d-flex justify-content-end py-3 px-0'>

                            <button type="button" className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={onClose}>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CLOSE' })}
                            </button>

                            <button type="submit" className='btn btn-sm btn-dark fw-bold' onClick={onSave} autoFocus>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.UPDATE' })}
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { ChangeEmailDialog }