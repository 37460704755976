import { useIntl } from 'react-intl'
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls'
import { useState, useEffect } from 'react';
import parsePhoneNumber from 'libphonenumber-js'
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';
import { PhoneNumberFormatter } from '../../../modules/sgl-utils/Formatters'

// Assets
import "flatpickr/dist/themes/material_blue.css";


const GeneralTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext();
    const [stateOptions, setStateOptions] = useState([]);
    const [stableState, setStableState] = useState({label: 'Select...', value: ''});

    // Set state for react select dropdown value
    useEffect(() => {
        const stableState = stateOptions.find(
            state => state.value?.toLowerCase() === methods.watch("stable.state")?.toLowerCase() 
        )

        if (stableState) {
            setStableState(stableState);
        } else {
            setStableState({label: 'Select...', value: ''});
        }

    }, [stateOptions, methods.watch("stable.state")])

    useEffect(() => {
        // Set state options based on the selected country
        if (methods.watch("stable.country_code") === 'USA') {
            setStateOptions(props.form_meta.usStates);
        } else if (methods.watch("stable.country_code") === 'CAN') {
            setStateOptions(props.form_meta.canadianProvinces);
        } else {
            setStateOptions([]); // Empty array for other countries
        }
    }, [methods.watch("stable.country_code")])

    useEffect(() => {
        if(methods.watch('stable.telephone') && methods.watch('stable.telephone')?.length < 10){
            methods.setError('stable.telephone',{
                type: "custom",
                message: intl.formatMessage({id: 'FORM.INPUT.ALL.PHONE.VALIDATION.MESSAGE'})
            })
        }
        if(methods.watch('stable.fax') && methods.watch('stable.fax')?.length < 10){
            methods.setError('stable.fax',{
                type: "custom",
                message: intl.formatMessage({id: 'FORM.INPUT.ALL.FAX.VALIDATION.MESSAGE'})
            })
        }
    }, [methods.getValues('stable.fax'), methods.getValues('stable.telephone')]);
    
    const onCountryChange = (country) => {
        // Set state options based on the selected country
        if (country === 'USA') {
            setStateOptions(props.form_meta.usStates);
        } else if (country === 'CAN') {
            setStateOptions(props.form_meta.canadianProvinces);
        } else {
            setStateOptions([]); // Empty array for other countries
        }
        // Reset stable.state value when country changes
        methods.setValue("stable.state", "", {shouldDirty: true});
        setStableState({ label: 'Select...', value: '' });
    }

    const onStateChange = (selectedState) => {
        methods.setValue("stable.state", selectedState.value, {shouldDirty: true});
        setStableState(selectedState);
    }

    const onStateBlur = () => {
        const state = stateOptions.find(
            state => state.value?.toLowerCase() === methods.watch("stable.state")?.toLowerCase()
        )

        if (state) {
            methods.setValue("stable.state", state.value, {shouldDirty: true})
        } else {
            methods.setValue("stable.state", "", {shouldDirty: true})
        }
    }

    return (
        <>
            <div className='form-group row mb-2'>
                <div className='row mb-2'>
                    <div className='col-lg-5'> {/* Column 1 */}
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1 w-lg-100px' htmlFor='ADDRESS' data-tooltip-id="STABLE.DETAIL.TAB.GENERAL.LABEL.ADDRESS">{intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.GENERAL.LABEL.ADDRESS' })}</label>
                            <div className='col-lg-9'>
                                <input
                                    {...methods.register( 'stable.address', 
                                        {
                                            required: false,
                                            pattern: {
                                                value: /^[#.0-9a-zA-Z\s,-]+$/,
                                                message: "Please enter valid address"
                                            }
                                        }
                                      )
                                    }
                                    id='ADDRESS'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                />
                                { methods.formState.errors?.stable?.address && <div><span className='error_message'>{methods.formState.errors.stable.address?.message}</span></div>}
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1 w-lg-100px' htmlFor='ADDRESS2' data-tooltip-id="STABLE.DETAIL.TAB.GENERAL.LABEL.ADDRESS2">{intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.GENERAL.LABEL.ADDRESS2' })}</label>
                            <div className='col-lg-9'>
                                <input
                                    {...methods.register( 'stable.address_two', 
                                        {
                                           required: false,
                                           pattern: {
                                            value: /^[#.0-9a-zA-Z\s,-]+$/,
                                            message: "Please enter valid address"
                                            }
                                        }
                                     )
                                    }
                                    id='ADDRESS2'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                />
                                  { methods.formState.errors?.stable?.address_two && <div><span className='error_message'>{methods.formState.errors.stable.address_two?.message}</span></div>}
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1 w-lg-100px' htmlFor='country_code' data-tooltip-id="STABLE.DETAIL.TAB.GENERAL.LABEL.COUNTRY">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.COUNTRY'})}</label>

                            <div className='col-lg-8 '>
                                {
                                    props.form_meta.countries && 
                                    <Controller
                                        name='stable.country_code'
                                        render={({
                                            field: { onChange, value, name },
                                        }) => (
                                        <Select
                                            placeholder="Select"
                                            options = {props.form_meta.countries}
                                            value = {props.form_meta.countries.find((c) => c.value === value)}
                                            name={name}
                                            id="country_code"
                                            isSearchable={true}
                                            onChange={(country) => {
                                                onChange(country.value);
                                                onCountryChange(country.value);
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}
                                            styles={reactSelectStyles}
                                        />    
                                        )}
                                    />
                                }
                            </div>

                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1'>{methods.watch('stable.country_code')}</label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1 w-lg-100px' htmlFor='CITY' data-tooltip-id="STABLE.DETAIL.TAB.GENERAL.LABEL.CITY">{intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.GENERAL.LABEL.CITY' })}</label>
                            <div className='col-lg-9'>
                                <input
                                    {...methods.register( 'stable.city', 
                                       {
                                            required: false
                                       }
                                     )
                                    }
                                    id='CITY'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1 w-lg-100px' htmlFor='STATEPROVINCE' data-tooltip-id="STABLE.DETAIL.TAB.GENERAL.LABEL.STATE/PROVINCE">{intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.GENERAL.LABEL.STATEPROVINCE' })}</label>
                            <div className='col-lg-9'>
                                <div className='row'>
                                {
                                    stateOptions?.length > 0 &&
                                        <div className='col-lg-10'>
                                            <Controller
                                                id='STATEPROVINCE'
                                                name='stable.state'
                                                render={({
                                                    field: { onChange, value, name },
                                                }) => (
                                                    <Select
                                                        placeholder="Select"
                                                        options={stateOptions}
                                                        value={stableState}
                                                        name={name}
                                                        isSearchable={true}
                                                        onChange={(state) => {
                                                            onChange(state.label);
                                                            onStateChange(state)
                                                        }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                        })}
                                                        styles={reactSelectStyles}
                                                    />
                                                )}
                                            /> 
                                        </div> 
                                    }
                                    <div className='col'>
                                        <input
                                            id='STATEPROVINCE'
                                            {...methods.register("stable.state", { required: false })}
                                            type='text'
                                            className='form-control form-control-sn fs-6 min-h-20px py-1'
                                            onBlur={onStateBlur}
                                        />
                                    </div>
                   
                                </div>

                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1 w-lg-100px' htmlFor='POSTALCODE' data-tooltip-id="STABLE.DETAIL.TAB.GENERAL.LABEL.POSTALCODE">{intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.GENERAL.LABEL.POSTALCODE' })}</label>
                            <div className='col-lg-9'>
                                <input
                                    {...methods.register( 'stable.zip', 
                                    {
                                            required: false
                                    }
                                    )
                                    }
                                    id='POSTALCODE'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1 w-lg-100px' htmlFor='PHONE' data-tooltip-id="STABLE.DETAIL.TAB.GENERAL.LABEL.PHONE">{intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.GENERAL.LABEL.PHONE' })}</label>
                            <div className='col-lg-9'>
                                <Controller
                                    control={methods.control}
                                    name="stable.telephone"
                                    render={({ field: { onChange, value} }) => (
                                        <IntlTelInput
                                            id='PHONE'
                                            inputClassName='form-control fs-6 py-0'
                                            containerClassName='intl-tel-input country-code-picker'
                                            style={{ width: '100%', borderRadius: '0px' }}
                                            placeholder=''
                                            preferredCountries={["us", "ca"]}
                                            defaultCountry={parsePhoneNumber(`+${methods.getValues('stable.phone_country_code')}${value}`, 'US')?.country?.toLowerCase()}
                                            allowDropdown={true}
                                            value={PhoneNumberFormatter(value)}
                                            onPhoneNumberChange={(_isValid, _rawValue,_countryData,_formattedValue,_extension) => {
                                                const unformattedValue = _rawValue.replace(/\D/g, ''); // Remove non-digit characters
                                                onChange(unformattedValue)
                                                if(_countryData.dialCode){
                                                    methods.setValue('stable.phone_country_code', _countryData.dialCode, { shouldDirty: true  })
                                                }
                                                if(unformattedValue.length < 10){
                                                    methods.setError('stable.telephone',{
                                                        type: "custom",
                                                        message: intl.formatMessage({id: 'FORM.INPUT.ALL.PHONE.VALIDATION.MESSAGE'})
                                                    })
                                                }
                                                else{
                                                    methods.clearErrors('stable.telephone')
                                                }
                                            }}
                                            onSelectFlag={(currentNumber, country) => {if(country){ methods.setValue('stable.phone_country_code', country.dialCode, { shouldDirty: true})}}}
                                        />
                                    )}
                                />
                                { methods.formState.errors?.stable?.telephone && <div><span className='error_message'>{methods.formState.errors.stable.telephone?.message}</span></div>}
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1 w-lg-100px' htmlFor='FAX' data-tooltip-id="STABLE.DETAIL.TAB.GENERAL.LABEL.FAX">{intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.GENERAL.LABEL.FAX' })}</label>
                            <div className='col-lg-9'>
                                <Controller
                                    control={methods.control}
                                    name="stable.fax"
                                    render={({ field: { onChange, value} }) => (
                                        <IntlTelInput
                                            id='FAX'
                                            inputClassName='form-control fs-6 py-0'
                                            containerClassName='intl-tel-input country-code-picker'
                                            style={{ width: '100%', borderRadius: '0px' }}
                                            placeholder=''
                                            preferredCountries={["us", "ca"]}
                                            defaultCountry={parsePhoneNumber(`+${methods.getValues('stable.fax_country_code')}${value}`, 'US')?.country?.toLowerCase()}
                                            allowDropdown={true}
                                            value={PhoneNumberFormatter(value)}
                                            onPhoneNumberChange={(_isValid, _rawValue,_countryData,_formattedValue,_extension) => {
                                                const unformattedValue = _rawValue.replace(/\D/g, ''); // Remove non-digit characters
                                                onChange(unformattedValue)
                                                if(_countryData.dialCode){
                                                    methods.setValue('stable.fax_country_code', _countryData.dialCode, { shouldDirty: true  })
                                                }
                                                if(unformattedValue.length < 10){
                                                    methods.setError('stable.fax',{
                                                        type: "custom",
                                                        message: intl.formatMessage({id: 'FORM.INPUT.ALL.FAX.VALIDATION.MESSAGE'})
                                                    })
                                                }
                                                else{
                                                    methods.clearErrors('stable.fax')
                                                }
                                            }}
                                            onSelectFlag={(currentNumber, country) => {if(country){ methods.setValue('stable.fax_country_code', country.dialCode, { shouldDirty: true})}}}
                                        />
                                        
                                    )}
                                />
                                { methods.formState.errors?.stable?.fax && <div><span className='error_message'>{methods.formState.errors.stable.fax?.message}</span></div>}
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1 w-lg-100px' htmlFor='EMAIL' data-tooltip-id="STABLE.DETAIL.TAB.GENERAL.LABEL.EMAIL">{intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.GENERAL.LABEL.EMAIL' })}</label>
                            <div className='col-lg-9'>
                                <input
                                    {...methods.register( 'stable.email', 
                                       {
                                            required: false,
                                            pattern:{
                                                value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                                                message: "Please enter a valid email"
                                            }  
                                       }
                                     )
                                    }
                                    id='EMAIL'
                                    type='text'
                                    // name='email'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                />
                                { methods.formState.errors?.stable?.email && <div><span className='error_message'>{methods.formState.errors.stable.email?.message}</span></div>}
                            
                            </div>
                        </div>
                      
                        <div className='row mb-2'>
                            {methods.watch('stable.url')?.includes("http://") || methods.watch('stable.url')?.includes("https://") ? 
                            <a className='col col-form-label fs-5 py-1 text-info w-lg-100px'  target="_blank"  href={ methods.watch('stable.url')} data-tooltip-id="STABLE.DETAIL.TAB.GENERAL.LINK.STABLEURL">{intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.GENERAL.LABEL.STABLEURL' })}</a>
                            : <a className='col col-form-label fs-5 py-1 text-info w-lg-100px'  target="_blank"  href={ "//" + methods.watch('stable.url')} data-tooltip-id="STABLE.DETAIL.TAB.GENERAL.LINK.STABLEURL">{intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.GENERAL.LABEL.STABLEURL' })}</a>
                            }
                           
                            <div className='col-lg-9'>
                                <input
                                    {...methods.register( 'stable.url', 
                                       {
                                             required: false,
                                             pattern: {
                                                value: /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/,
                                                message: "Please enter a valid URL"
                                             }
                                       }
                                     )
                                    }
                                    id='STABLEURL'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                />
                                 { methods.formState.errors?.stable?.url && <div><span className='error_message'>{methods.formState.errors.stable.url?.message}</span></div>}
                            </div>
                        </div>
                    </div> {/* End Column 1 */}
                    <div className='col-lg-7'> {/* Column 2 */}
                        <div className='row mb-2'>
                            <label className='col-form-label fs-5 py-1' htmlFor='COMMENTS' data-tooltip-id="STABLE.DETAIL.TAB.GENERAL.LABEL.COMMENTS">{intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.GENERAL.LABEL.COMMENTS' })}</label>
                            <div>
                                <textarea
                                    {...methods.register( 'stable.comments', 
                                        {
                                            required: false
                                        }
                                      )
                                    }
                                    id="COMMENTS"
                                    rows={11}
                                    className='form-control h-100 form-control-sm fs-6'
                                ></textarea>
                            </div>
                        </div>
                    </div> {/* End Column 2 */}
                </div>
            </div>
        </>
    );
}

export { GeneralTab }