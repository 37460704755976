import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext} from "react-hook-form";
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer';

const EntriesTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext();
    const rowData = methods.getValues('entry')

    const columnDefs = [
        { field: 'number', headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.ENTRIES.ENTRY' }), cellClass: 'ag-right-aligned-cell' },
        { field: 'show_name', width: 400, headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.ENTRIES.SHOWNAME' }) },
        { field: 'start_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.ENTRIES.STARTDATE' }), cellClass: 'text-center', cellRenderer: DateRenderer },
        { field: 'end_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.ENTRIES.ENDDATE' }), cellClass: 'text-center', cellRenderer: DateRenderer },
        { field: 'horse', width: 200, headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.ENTRIES.HORSE' }) },
        { field: 'trainer',width: 200, headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.ENTRIES.TRAINER' }) },
        { field: 'owner',width: 200, headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.ENTRIES.OWNER' }) },
        { field: 'status', headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.ENTRIES.STATUS' }) },
    ]

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

    return (
        <>
            <div className='form-group'>
                <p style={{paddingRight:"10px"}} className='mb-1 text-end'>{rowData.length}</p>
                <div className='row align-items-center mb-2'>
                    <OutputListingGridStatic onRowDoubleClicked={(row_data) => {props.callbackFromParent(row_data.entry_id, "Entries", "EntryDetail", row_data)}} area ={"Stables - Entries"} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle}></OutputListingGridStatic>
                </div>
            </div>
        </>
    );
}

export { EntriesTab }