import { useMemo, useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Modal, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';

type Props = {
    show: boolean
    handleClose: () => void
    usef_lookup_field: []
    horse: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const USEFLookupMismatchModal = ({ show, handleClose, usef_lookup_field, horse }: Props) => {
    const intl = useIntl()
    const [gridApi, setGridApi] = useState<any>()
    const methods = useFormContext()

    useEffect(() => {
        if(gridApi){
        gridApi.setRowData(usef_lookup_field)
        }
    }, [])

    const getGridData = () =>{
        gridApi.getSelectedNodes().map((row:any)=>{
            // delete row.data.check
            // return row.data

            if(row.data.field == 'Breed'){
                methods.setValue(horse+'.breed', row.data.USEFLookup,{shouldDirty:true})
            }
            else if(row.data.field == 'Dam s sire'){
                methods.setValue(horse+'.dam_s_sire', row.data.USEFLookup,{shouldDirty:true})
            }
            else if(row.data.field == 'Dam'){
                methods.setValue(horse+'.dam', row.data.USEFLookup,{shouldDirty:true})
            }
            else if(row.data.field == 'Sire'){
                methods.setValue(horse+'.sire', row.data.USEFLookup,{shouldDirty:true})
            }
        })
    }

    const columnDefs = [
        { field: 'check', flex:1, headerName: "", checkboxSelection: true, cellClass: 'text-center',  maxWidth: 60, minWidth: 60, },
        { field: 'field', flex:1, headerName: intl.formatMessage({ id: "FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.USEFMEMBERSHIP.MODAL.MODALNAME.FILED" })  },
        { field: 'onFile', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.USEFMEMBERSHIP.MODAL.MODALNAME.ON-FILE' }) },
        { field: 'USEFLookup', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.USEFMEMBERSHIP.MODAL.MODALNAME.USEFLOOKUP' })  },
    ]

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown ={(event :any) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    event.preventDefault() //Avoid chained enter press
                    getGridData(); handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.USEFMEMBERSHIP.MODAL.MODALNAME.TITLE.USEFMISMATCH' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col col-form-label fw-bold fs-5 py-1' data-tooltip-id="HORSE.DETAIL.MODAL.USEFLOOKUPMISMATCH.LABEL.USEFDATA"> {intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.USEFMEMBERSHIP.MODAL.MODALNAME.LABEL.USEFDATA' })}</label>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-12 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='KEEPENTRYNUM'
                                    onChange = {(e) => {
                                        if(e.target.checked){
                                            gridApi.selectAll()
                                        }else{
                                            gridApi.deselectAll()
                                        }
                                    }}
                                    autoFocus
                                    tabIndex={1}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='KEEPENTRYNUM' data-tooltip-id="HORSE.DETAIL.MODAL.USEFLOOKUPMISMATCH.LABEL.SELECTALL"> {intl.formatMessage({ id: "FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.USEFMEMBERSHIP.MODAL.MODALNAME.LABEL.SELECTALL" })}</label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                        <div className='row mb-5'>
                            <OutputListingGridStatic  area ={"Horses - USEFLookupMismatch"} selection={'multiple'} setGridRef={setGridApi} columnDefs={columnDefs} rowData={usef_lookup_field} containerStyle={containerStyle}></OutputListingGridStatic>
                        </div>
                        <div className='row justify-content-end'>
                                    <div className='col-lg-1 w-lg-150px'>
                                        <button type='button' onClick={handleClose} className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12" tabIndex={2}>
                                            {intl.formatMessage({ id: "FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.USEFMEMBERSHIP.MODAL.MODALNAME.BUTTON.CANCEL" })}
                                        </button>
                                    </div>
                                    <div className='col-lg-1 w-lg-150px'>
                                        <button type='button' onClick={()=>{getGridData(); handleClose()}} className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12" tabIndex={3} data-tooltip-id="HORSE.DETAIL.MODAL.USEFLOOKUPMISMATCH.BUTTON.UPDATE">
                                            {intl.formatMessage({ id: "FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.USEFMEMBERSHIP.MODAL.MODALNAME.BUTTON.UPDATE" })}
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { USEFLookupMismatchModal }