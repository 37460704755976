import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls'
import moment from "moment"
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { GeneralPaymentMethodPopup } from './GeneralPaymentMethodPopup'
import { useState , useEffect, useMemo, useRef} from 'react';
import { PatternFormat} from 'react-number-format';
import axios from 'axios';
import { useConfirm, useAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import { useAppSelector } from '../../../redux/hooks'
import { controlLookupFields } from './AdditonalTab/MembershipFunctions';
import parsePhoneNumber from 'libphonenumber-js'
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';
import { PhoneNumberFormatter } from '../../../modules/sgl-utils/Formatters'
import { maskDateInput } from '../../../modules/sgl-utils/fieldControls';

// Assets
import CardVisa from "../../../../_metronic/assets/CardImages/Card_Visa_Small.jpeg"
import CardMaster from "../../../../_metronic/assets/CardImages/Card_MC_Small.jpeg"
import CardAmericanExpress from "../../../../_metronic/assets/CardImages/Card_Amex_Small.png"
import CardDiscover from "../../../../_metronic/assets/CardImages/Card_Discover_small.jpeg"
import "flatpickr/dist/themes/material_blue.css";
import USEFLogo from '../../../../_metronic/assets/CustomIcons/USEF_Icon.jpeg'
import ECLogo from '../../../../_metronic/assets/CustomIcons/EC_icon.png'
import FEILogo from '../../../../_metronic/assets/CustomIcons/fei_icon.jpeg'

// Constants
import { date_options, setDate } from '../../../modules/sgl-utils/fieldControls';
import { useParams } from 'react-router-dom';
import { getDisabledFieldsForRtoPreference, isValidJson } from '../../../modules/sgl-utils/SglFunctions';
import { PDFDocument } from 'pdf-lib';


const GeneralTab = ({ peopleMetaData, setShowTaxInformation, setSelectedTaxFormRequestType, setRunTinCheck }) => {
    const methods = useFormContext();
    const intl = useIntl();
    const people_id = methods.getValues('people.people_id')
    const [creditCardGridRef, setCreditCardGridRef] = useState()
    const containerStyle = useMemo(() => ({ width: '100%', height: '150px' }), []);
    const redactCardButtonRef = useRef();

    const [showPaymentMethodPopup, setShowPaymentMethodPopup] = useState(false)
    const [paymentMethodPopupEditMode, setPaymentMethodPopupEditMode] = useState("Add")
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const loadingOverlay = useLoadingOverlay()
    const [taxMembership, setTaxMembership] = useState({});
    const [personState, setPersonState] = useState({label: 'Select...', value: ''});
    const [taxFormStatus, setTaxFormStatus] = useState('') // tax form status/ membership level
    const [taxFormType, setTaxFormType] = useState('') // tax form type/ membership number
    const [taxformWarning, setTaxformWarning] = useState([]) // warning if status is review for taxform
    const [isTaxStatusValid, setIsTaxStatusValid] = useState(false) // flag to check if taxform membership is valid or not
    const [isTaxFormLookupEnabled, setIsTaxFormLookupEnabled] = useState(false)
    const [taxDocumentAction, setTaxDocumentAction] = useState('')
    const [disableFields, setDisableFields] = useState([]) // flag to disable bank related fields based on prize preference selected

    methods.watch("people.country_code");
    methods.watch("people.competing_nationality")
    methods.watch("people.age")
    methods.watch("people.state")

    // styling for redacted credit card rows
    const redactedRowClassRules = useMemo(() => {
        return {
            'bg-red-grid-row': 'data.redacted',
        };
    }, []);

    const creditCardColumnDefs = [
        { 
            field: 'card_type', 
            maxWidth: 70,
            headerName: '', 
            /* Renders an image based on card type */
            cellRenderer: params =>  {
                const cardType = params.data.card_type
                let imagePath;
                switch(cardType) {
                    case 'american_express':
                        imagePath = CardAmericanExpress
                        break
                    case 'visa':
                        imagePath = CardVisa
                        break
                    case 'master':
                        imagePath = CardMaster
                        break
                    case 'discover':
                        imagePath = CardDiscover
                }
                return <img src={imagePath} />
            },
            cellStyle: {textAlign: 'center'}
        },
        { 
            field: 'card_lastfour', 
            headerName: intl.formatMessage({id: 'FORM.INPUT.PEOPLE.TAB.CARD.NUMBER'}), 
            width: 105,
            maxWidth: 105
        },
        { 
            field: 'name_on_card',
            width: 180,
            fles: 1,
            headerName: intl.formatMessage({id: 'FORM.INPUT.PEOPLE.TAB.CARD.NAMEONCARD'}),
        },
        { 
            field: 'card_exp', 
            headerName: intl.formatMessage({id: 'FORM.INPUT.PEOPLE.TAB.CARD.EXPIRYDATE'}), 
            /* Normalizes expiry date of credit card to MM/YY */
            valueGetter: (params) => {
                const expiryDate = params.data.card_exp
                const expiryDateLength = expiryDate.length;
                if (expiryDateLength === 4) {
                    return expiryDate.substring(0, 2) + "/" + expiryDate.substring(2, 4)
                }

                const slashIndex = expiryDate.indexOf("/")
                return expiryDate.substring(0, slashIndex + 1) + expiryDate.substring(expiryDateLength-2, expiryDateLength)
            },
            width: 50
        },
        { 
            field: 'description', 
            width: 300,
            headerName: intl.formatMessage({id: 'FORM.INPUT.PEOPLE.TAB.CARD.DESCRIPTION'}), 
        }
    ]

    // functionality for showRedacted checkbox toggle
    const filterRedactedRows = (checked) => {
        let paymentMethods = methods.getValues('paymentMethods')
        if (!checked) {
            paymentMethods = paymentMethods.filter(
                pm => pm.redacted === 0
            )
        }
        creditCardGridRef.setRowData(paymentMethods)
    }

    const calculateAge = (dob, referenceDate = '0000-00-00') =>  { 
        if(!dob || dob == '0000-00-00'){
            return 0;
        }

        if(!referenceDate || referenceDate == '0000-00-00'){
            referenceDate = moment().format('YYYY-MM-DD');
        }

        //Get year for reference date
        let referenceDateYear = parseInt(moment(referenceDate).format("YYYY"));
        let referencedDateMonth = parseInt(moment(referenceDate).format('MM'));
        if(referencedDateMonth < 12){  // calculate date based on previous year's 12/01 if we are NOT in month of december
            referenceDateYear--;
        }
  
        //Get year for DOB date
        let dobDateYear = parseInt(moment(dob).format("YYYY"));
        let dobDateMonth = parseInt(moment(dob).format('MM'));
        if(dobDateMonth >= 12){  // calculate date based on previous year's 12/01 if we are NOT in month of december
            dobDateYear++;
        }

        // Calculate age based on year difference
        var usefAge = referenceDateYear - dobDateYear;
        if(usefAge < 1){
            usefAge = 0;
        }
        
        return usefAge;
    }

    useEffect(() => {
        if (creditCardGridRef && methods.getValues("paymentMethods")) {
            filterRedactedRows(false)
        }
    }, [creditCardGridRef, methods.getValues('paymentMethods')]);
    
    useEffect(() => {    
        membershipStatus(methods.watch('people'))
        let disableStates = controlLookupFields(methods.getValues('people'), methods.getValues('organizationMembers'))
        methods.setValue('disableDOB', disableStates.disDob)
        methods.setValue('disableStatus', disableStates.disStatus)
    }, [methods.watch('people')])

    useEffect(() => {  
        let _disableFields = getDisabledFieldsForRtoPreference(methods.watch('people.prize_preference'))
        setDisableFields(_disableFields)
    }, [methods.watch('people.prize_preference')])

    const membershipStatus = (people) => { 
        // people["usef_suspension_status"]  = "Not Available"
        methods.setValue('people.usef_suspension_status', "Not Available") //people USEF No is empty, set as Not Available
        methods.setValue('people.feiStandingStatus', "Not Available")
        methods.setValue('people.ec_StandingStatus', "Not Available")
        
        //USEF Suspension Check
        if(people.usef_number_ahsa_number && people.usef_number_ahsa_number?.toString().trim() != ""){ //people USEF No is Not Empty, check usef_suspension value if true set suspended
            methods.setValue('people.usef_suspension_status', people.usef_suspension ? "Suspended" : "Not Suspended")
        }

        //EC Suspension Status Check
        if(people.ec_standingsstatus && people.ec_standingsstatus?.toString()?.trim() != ""){
            methods.setValue('people.ec_StandingStatus', people.ec_standingsstatus)
        }

        //FEI Suspension Status Check
        if(people.fei_id && people.fei_id?.toString()?.trim() != ""){
            // Note: FEI suspension verification is different in SGL Cloud then in 4D. Discussed with MA. Please see method ->  entry_VerifyPeopleSuspension 
            methods.setValue('people.feiStandingStatus', people.fei_isactive? "Active": "Inactive")
        }
    }

    const handleAddPaymentMethod = (params) => {
        // check if opened in edit mode
        if (params.data) {
            methods.setValue("newPaymentMethod",  {
                address_1: params.data.address_1 ? params.data.address_1 : "",
                address_2: params.data.address_2 ? params.data.address_2 : "", 
                card_exp: params.data.card_exp ? params.data.card_exp : "",
                card_number: params.data.card_lastfour ? params.data.card_lastfour : "",
                card_lastfour: params.data.card_lastfour ? params.data.card_lastfour : "",
                city: params.data.city ? params.data.city : "",
                country: params.data.country ? params.data.country : "",
                description: params.data.description ? params.data.description : "",
                email: params.data.email ? params.data.email :"",
                name_on_card: params.data.name_on_card ? params.data.name_on_card : "",
                payment_method_id: params.data.payment_method_id ? params.data.payment_method_id: 0,
                phone: params.data.phone ? params.data.phone :"",
                zip: params.data.zip ? params.data.zip : "",
                state: params.data.state ? params.data.state :""
            })
            setPaymentMethodPopupEditMode(true)
        } else {
            methods.setValue("newPaymentMethod",  {
                address_1: "",
                address_2: "", 
                card_exp: "",
                card_number: "",
                city: "",
                country: "",
                description: "",
                email: "",
                name_on_card: "",
                payment_method_id: 0,
                phone: "",
                zip: "",
                state: ""
            })
            setPaymentMethodPopupEditMode(false)
        }
        setShowPaymentMethodPopup(true)
    }

    const getPaymentMethodsList = () => {
        axios
            .get(process.env.REACT_APP_NEST_API_URL + '/people/paymentMethods', {
                params: {
                    people_id: people_id,
                    customer_id: customer_id,
                }
            })
            .then(response => {
                // update payment methods record
                methods.setValue('paymentMethods', response.data)
                // re-render grid
                filterRedactedRows(methods.getValues("showRedacted"))
            })
    }

    const handleRedactCard = async () => {
        const selectedRows = creditCardGridRef.getSelectedRows()
        if (selectedRows.length === 0) {
            alertDialog({message: 'Please select a credit card to redact', title: 'ALERT'})
            return
        }

        // Show loading overlay
        loadingOverlay({show: true})
        const selectedRow = selectedRows[0]
        axios.get( process.env.REACT_APP_NEST_API_URL + '/payment-methods/securedEntriesInfo', {
            params: {
                payment_method_id: selectedRow.payment_method_id,
                customer_id
            }
        })
        .then(async (response) => {
            // Hide loading overlay
            loadingOverlay({show: false})
            const { hasOutstandingBalanceEntries, hasFutureShowEntries } = response.data
            let message = ""
            if(hasOutstandingBalanceEntries && hasFutureShowEntries){
                message = intl.formatMessage({ id: "REDACTCARD.ENTRYSECURED.CURRENTANDFUTURESHOW.MESSAGE" })
            } else if(hasOutstandingBalanceEntries) {
                message = intl.formatMessage({ id: "REDACTCARD.ENTRYSECURED.OUTSTANDINGBALANCE.MESSAGE" })
            } else if(hasFutureShowEntries) {
                message = intl.formatMessage({ id: "REDACTCARD.ENTRYSECURED.CURRENTORFUTURESHOW.MESSAGE" })
            }

            // We won't allow redacting a credit card which is used to secure an entry.
            // Task on ClickUp: https://app.clickup.com/t/8669495yg
            if (message) {
                alertDialog({ message, title: 'ALERT', icon: 'ban' })
                return
            }

            message = intl.formatMessage({ id: "REDACTCARD.ENTRYSECURED.CONFIRMATION.MESSAGE" })
            const choice = await confirmDialog({message, title: 'confirm', okButtonTitle: 'OK', cancelButtonTitle: 'CANCEL' })
            
            if (choice) {
                // redacts the payment method
                loadingOverlay({show: true})
                axios.delete( process.env.REACT_APP_NEST_API_URL + '/payment-methods/redactPaymentMethod', {
                    params: {
                        payment_method_id: selectedRow.payment_method_id,
                        customer_id: customer_id,
                        people_id: people_id
                    }
                })
                .then(() => {
                    getPaymentMethodsList()
                    // Hide loading overlay
                    loadingOverlay({show: false})
                })
                .catch((reason) => {
                    
                    let errorMessage = 'An error occurred';
                    if (reason.response && reason.response.data && reason.response.data.message) {
                        if (Array.isArray(reason.response.data.message)) {
                            errorMessage = reason.response.data.message[0].message;
                        } else {
                            errorMessage = reason.response.data.message;
                        }
                    }

                    alertDialog({message: errorMessage, title: 'Error'});
                    loadingOverlay({show: false})
                })
            }
        })
        .catch((reason) => {
            // if errors are in the form of Array
            if(Array.isArray(reason.response.data.error)) { 
                alertDialog({message: reason.response.data.error.join(`<br/>`), title: 'error'});
            } else {
                alertDialog({message: reason.response.data.error, title: 'error'})
            }
            loadingOverlay({show: false})
        })
    }

    useEffect(() => {
        const usefLookupResponse = methods.getValues('people.usef_response_eti_lookup');
        if(usefLookupResponse && isValidJson(usefLookupResponse)) {
            const usefData = JSON.parse(usefLookupResponse);
            methods.setValue('people.usef_email_address', usefData.EmailAddress);
        }
    }, [methods.watch('people.usef_response_eti_lookup')])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_NEST_API_URL}/people/getTaxFormPostLookupEnabled?customer_id=${customer_id}`)
        .then((response) => {
            // If taxform lookup is enabled or not 
            if(response?.data?.success){
                setIsTaxFormLookupEnabled(response?.data?.isTaxFormLookupEnabled)
            }
        })
    }, [])

    const lookup_usef_data = () => { 
        methods.setValue('lookup_organization', 'USEF');
    }

    const lookup_fei_data = () => { 
        methods.setValue('lookup_organization', 'FEI');
    }
    
    const lookup_ec_data = () => { 
        methods.setValue('lookup_organization', 'EC');
    }

    //calling fei lookup api on accept name mismatch checkbox 
    function onForeignerChecked(checked){
        methods.setValue('people.foreigner', checked,{shouldDirty:true});
        axios.post( process.env.REACT_APP_NEST_API_URL + '/people/getWithholdingTax', {
            params: {
              people: methods.getValues("people"),
              customer_id: customer_id,
              year: methods.getValues("withHoldingTaxYear")
            }
          }).then((response) => {
              if(response.data.success) {
                methods.setValue('withholdingTax', response.data.withHoldingTaxes)
            }
            }).catch((reason) => {
              let errors = reason.response.data.response.errors.join("<br/>")
              alertDialog({message: errors, title: 'warning'})
          })
        
    }

    useEffect(() => {
        const organizationMembers = methods.getValues('organizationMembers');
        const taxStatus = organizationMembers.find((member) => member.organization_code === 'Tax Status');
        if (taxStatus) {
            setTaxMembership(taxStatus);
        }
    }, [methods.watch('organizationMembers')]);

    // This function will find states based on the abbreviation or value and set the value
    // Because the state field is a free text field, we need to make sure that the value is set to the correct value
    const onStateBlur = () => {
        const state = peopleMetaData.states.find(
            state => state.value?.toLowerCase() === methods.watch("people.state")?.toLowerCase() || state.abbreviation?.toLowerCase() === methods.watch("people.state")?.toLowerCase()
        )

        if (state) {
            methods.setValue("people.state", state.value)
        } else {
            methods.setValue("people.state", "")
        }
    }

    // Set state for react select dropdown value
    useEffect(() => {
        const peopleState = peopleMetaData?.states?.find(
            state => state.value?.toLowerCase() === methods.watch("people.state")?.toLowerCase() || state.abbreviation?.toLowerCase() === methods.watch("people.state")?.toLowerCase()
        )

        if (peopleState) {
            setPersonState(peopleState);
        } else {
            setPersonState({label: 'Select...', value: ''});
        }

    }, [methods.watch("people.state")])

    useEffect(() => {
        if(methods.watch('people.mobile_phone') && methods.watch('people.mobile_phone').length < 10){
            methods.setError('people.mobile_phone',{
                type: "custom",
                message: intl.formatMessage({id: 'FORM.INPUT.ALL.MOBILE.VALIDATION.MESSAGE'})
            })
        }
        if(methods.watch('people.phonenumber') && methods.watch('people.phonenumber').length < 10){
            methods.setError('people.phonenumber',{
                type: "custom",
                message: intl.formatMessage({id: 'FORM.INPUT.ALL.PHONE.VALIDATION.MESSAGE'})
            })
        }
    }, [methods.getValues('people.phonenumber'), methods.getValues('people.mobile_phone')]);

    const setStatusForTaxForm = () => {
        let organizations = methods.watch('organizationMembers')
        let taxStatusMembership = organizations ? organizations?.find((item) => item.organization_code == 'Tax Status') : ''
        let status = taxStatusMembership?.membership_level ? taxStatusMembership?.membership_level : ''
        let formType = 'N/A'

        if(!['requested', 'manual'].includes(taxStatusMembership?.membership_number?.toLowerCase())){ 
            // taxform is created and has type
            formType = taxStatusMembership?.membership_number
        }

        // check tax form membership is valid or not
        let isTaxformMembershipValid = false
        let today = moment().format('YYYY-MM-DD');

        // check membership level
        if(['completed', 'manual'].includes(status?.toLowerCase())){

            // check start and end date
            if(taxStatusMembership && taxStatusMembership?.valid_end && taxStatusMembership?.valid_end !='' && taxStatusMembership?.valid_start && taxStatusMembership?.valid_start !=''){
                if(moment(today).isSameOrBefore(moment(taxStatusMembership.valid_end).format('YYYY-MM-DD')) && moment(today).isSameOrAfter(moment(taxStatusMembership.valid_start).format('YYYY-MM-DD'))){ // if valid end is future date
                    isTaxformMembershipValid = true
                }
            }
        }

        if(status?.toLowerCase() == 'review'){
            let warning = [];
            if (methods.getValues('people.userFormSubmission')) {
                if (methods.getValues('people.userFormSubmission')?.has_missing_tax_info) {
                    warning.push('Tax information missing');
                }
                
                if (methods.getValues('people.userFormSubmission')?.has_name_discrepancy) {
                    warning.push('Name mismatch');
                }
            }

            setTaxformWarning(warning)
        }

        setIsTaxStatusValid(isTaxformMembershipValid)
        setTaxFormStatus(status)
        setTaxFormType(formType)
    }

    const getPDFLink = async () => {
        // call webservice for RTO with completed forms only
        if(taxFormStatus?.toLowerCase() != 'completed'){
            return
        }

        // Show loading overlay
        loadingOverlay({ show: true })

        const response = await axios.get(`${process.env.REACT_APP_NEST_API_URL}/people/getUserSubmissionFormLink`, {
            params: {
                people_id: methods.getValues('people.people_id'),
                customer_id,
            }
        })

        // Hide loading overlay
        loadingOverlay({ show: false })

        if(response?.data?.success){
            if(response?.data?.file){
                // Load the array buffer into pdf lib document
                const pdfDoc = await PDFDocument.load(response?.data?.file);
                const pdfBytes = await pdfDoc.save();
            
                const blob = await new Blob([pdfBytes], { type: 'application/pdf' });
                const documentBlobObjectUrl = URL.createObjectURL(blob);

                window.open(documentBlobObjectUrl, '_blank');
            }else{
                alertDialog({message: 'Error in fetching Tax Form.'})
            }
        }else{
            // document not found for the RTO
            alertDialog({message: response?.data?.error})
        }
    };

    useEffect(() => {
        setStatusForTaxForm()
    }, [methods.watch('organizationMembers')])

    // return formatted lookup date time
    const getLookupDateAndTime = (field) => {
        let date = ''
        let time = ''
        let formattedDate = ''
        let formattedTime = ''
        if(field == 'tin'){
            date = methods.getValues('people.tincheck_statuscheckdate')
            time = methods.getValues('people.tincheck_statuschecktime')
        }else if(field == 'taxStatus'){
            date = methods.getValues('people.updatedTaxStatusCheckDate')
            time = methods.getValues('people.updatedTaxStatusCheckTime')
        }

        if(date != '0000-00-00' && date && moment(date).isValid()){
            formattedDate = moment(date).format("MM/DD/YYYY")
        }

        if(time != '00:00:00' && time && moment(time, 'HH:mm:ss').isValid()){
            formattedTime = moment(time, 'HH:mm:ss').format("H:mm A")
        }
   
        return `${formattedDate}    ${formattedTime}`
    }

    // get options for tax document actions
    const getTaxFormOptions = () => {
        let options = [{label: 'Select', value: ''}, {label: 'Request Tax Documents', value: 'callTaxFormLookup'}, {label: 'Perform TIN Check', value: 'tinCheck'}]
        let taxFormMembership = methods.getValues().organizationMembers?.find((item) => item.organization_code == 'Tax Status')
        if(taxFormMembership && 'membership_exist' in taxFormMembership && !taxFormMembership['membership_exist']){
            options.push({label: 'Enter Manual Tax Document', value: 'manualRequest'})
        }    
        
        return options
    }

    const getTaxFormCheckDate = () => {
        /* NOTE: set the Tax Status Check date
        1. if the request is in history then use the latest timestamp of requested date from history otherwise use creation date
        2. When manual, we will need to store the date/time in cloud so that we can display when the manual status was set
        */

        let updatedTaxStatusCheckDate = ''
        let updatedTaxStatusCheckTime = ''
        let organizations = methods.watch('organizationMembers')
        let taxStatusMembership = organizations ? organizations?.find((item) => item.organization_code == 'Tax Status') : null
        let membershipLevel = taxStatusMembership?.membership_level?.toLocaleLowerCase()
        if(membershipLevel == 'manual'){
            // use people tax status check date time for manual membership
            updatedTaxStatusCheckDate = methods.getValues('people.taxform_statuscheckdate')
            updatedTaxStatusCheckTime = methods.getValues('people.taxform_statuschecktime')
            methods.setValue('people.taxform_statuscheckdate', updatedTaxStatusCheckDate)
            methods.setValue('people.taxform_statuschecktime', updatedTaxStatusCheckTime)
        }else if(membershipLevel && taxStatusMembership && methods.getValues('people.userFormSubmission')){
            let dateTime

            if(methods.getValues('people.userFormStatusHistory')){
                // if history records are created then use latest history created_at
                dateTime = methods.getValues('people.userFormStatusHistory')['created_at'];
            }else{
                // if no history records are created then use user form created_at 
                dateTime = methods.getValues('people.userFormSubmission')['created_at'];
            }

            if(dateTime){
                const formattedDate = moment(dateTime).format('YYYY-MM-DD'); 
                const formattedTime = moment(dateTime).format('HH:mm:ss'); 
                updatedTaxStatusCheckDate = formattedDate
                updatedTaxStatusCheckTime = formattedTime
            }
        }

        methods.setValue('people.updatedTaxStatusCheckDate', updatedTaxStatusCheckDate)
        methods.setValue('people.updatedTaxStatusCheckTime', updatedTaxStatusCheckTime)
    }

    // This function is added to get the user form submissions history records. 
    const getUserSubmissionFormHistory = async() => {
        let organizations = methods.watch('organizationMembers')
        let taxStatusMembership = organizations ? organizations?.find((item) => item.organization_code == 'Tax Status') : ''
        let status = taxStatusMembership?.membership_level ? taxStatusMembership?.membership_level : ''

        if(!status){
            return
        }

        await axios.get( process.env.REACT_APP_NEST_API_URL + '/people/getUserSubmissionFormHistory', {
            params: {
                person_id: methods.getValues('people.people_id'),
                customer_id,
                userFormSubmission: methods.getValues('people.userFormSubmission'),
                tax_status: status
            }
        })
        .then(async (response) => {
            methods.setValue('people.userFormSubmission', response.data.userFormSubmission)
            methods.setValue('people.userFormStatusHistory', response.data.userFormStatusHistory)
        })
        .catch((reason) => {
            
        })

        getTaxFormCheckDate()
    }

    useEffect(() => {
        getUserSubmissionFormHistory()
    }, [methods.watch('organizationMembers')])

    return (
        <>
            <div className='form-group row mb-2'>
                <div className='row mb-2'>
                    {
                        showPaymentMethodPopup ?
                        <GeneralPaymentMethodPopup
                            peopleMetaData={peopleMetaData}
                            paymentMethodPopupEditMode={paymentMethodPopupEditMode}
                            handleClose={() => setShowPaymentMethodPopup(false)} 
                            handleSubmit={() => {setShowPaymentMethodPopup(true)}} 
                            getPaymentMethodsList={getPaymentMethodsList}
                            />
                        :
                        ""
                    }
                    <div className='col-lg-7'> {/* Column 1 */}
                        {/* Row */}
                        <div className='row mb-2'>
                            {/* Address */}
                            <div className='col-lg-2'>
                                <label
                                    className='col-form-label fs-5 py-1'
                                    htmlFor='address1'
                                    data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.LABEL.ADDRESS">
                                    {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.ADDRESS' })}
                                </label>
                            </div>
                            <div className='col'>
                                <input
                                    {...methods.register("people.address", { required: false })}
                                    id='address1'
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.ADDRESS' })}
                                />
                            </div>
                        </div>

                        {/* Row */}
                        <div className='row mb-2'>
                            {/* Address 2 */}
                            <div className='col-lg-2'>
                                <label
                                    className='col-form-label fs-5 py-1 w-lg-125px'
                                    htmlFor="address_two"
                                    data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.LABEL.ADDRESS2">
                                    {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.ADDRESS2' })}
                                </label>
                            </div>
                            <div className='col'>
                                <input
                                    {...methods.register("people.address_two", { required: false })}
                                        id="address_two"
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.ADDRESS' })}
                                    />
                            </div>
                        </div>

                        {/* Row */}
                        <div className='row mb-2'>
                            {/* City */}
                            <label
                                className='col-lg-2 col-form-label fs-5 py-1'
                                htmlFor='city'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.LABEL.CITY">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.CITY' })}
                            </label>
                            <div className='col-lg-4'>
                                <input
                                    {...methods.register("people.city", { required: false })}
                                    id='city'
                                    type='text'                                      
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.CITY' })}
                                />
                            </div>

                            {/* State */}
                            <label
                                className='col-lg-2 col-form-label fs-5 py-1'
                                htmlFor='state'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.LABEL.STATE">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.STATE' })}
                            </label>
                            <div className='col-lg-3'>
                                {
                                    peopleMetaData.states &&
                                    <Controller
                                        name='people.state'
                                        render={({
                                            field: { onChange, value, name },
                                        }) => (
                                            <Select
                                                placeholder="Select"
                                                options={peopleMetaData.states}
                                                value={personState}                                                
                                                name={name}
                                                isSearchable={true}
                                                onChange={(state) => {
                                                    onChange(state.label);
                                                    methods.setValue("people.state", state.value)
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                            />
                                        )}
                                    />
                                }
                            </div>
                            <div className='col-lg-1 ps-0'>
                                <input
                                    id='state'
                                    {...methods.register("people.state", { required: false })}
                                    type='text'
                                    className='form-control form-control-sn fs-6 min-h-20px py-1'
                                    onBlur={onStateBlur}
                                />
                            </div>
                        </div>

                        {/* Row */}
                        <div className='row mb-2'>
                            {/* Postal Code */}
                            <label
                                className='col-lg-2 col-form-label fs-5 py-1'
                                htmlFor='postalCode'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.LABEL.POSTALCODE">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.POSTALCODE' })}
                            </label>
                            <div className='col-lg-4'>
                                <input
                                    {...methods.register("people.postal_code", { required: false })}
                                    id="postalCode"
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.POSTALCODE' })}
                                />
                            </div>

                            {/* Country */}
                            <label
                                className='col-lg-2 col-form-label fs-5 py-1'
                                htmlFor='country'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.LABEL.COUNTRY">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.COUNTRY' })}
                            </label>
                            <div className='col-lg-3'>
                                {
                                    peopleMetaData.countries &&
                                    <Controller
                                        name='people.country'
                                        render={({
                                            field: { onChange, value, name },
                                        }) => (
                                            <Select
                                                placeholder="Select"
                                                options = {peopleMetaData.countries}
                                                value={
                                                    // methods.watch("people.country")
                                                    peopleMetaData.countries.find((c) => c.label?.toLowerCase() === methods.watch("people.country")?.toLowerCase())
                                                }
                                                name={name}
                                                id="country"
                                                isSearchable={true}
                                                onChange={(country) => {
                                                    onChange(country.label);
                                                    methods.setValue("people.country_code", country.value, { shouldDirty: true })
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                            />
                                        )}
                                    />
                                }
                            </div>
                            <label className='col-lg-1 col-form-label fs-5 py-1'>
                                {methods.getValues("people.country_code")}
                            </label>
                        </div>

                        {/* Row */}
                        <div className='row mb-2'>
                            {/* Mobile Phone */}
                            <label
                                className='col-lg-2 col-form-label fs-5 py-1'
                                htmlFor='mobilePhone'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.LABEL.MOBILEPHONE">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.MOBILENUMBER' })}
                            </label>
                        <div className='col-lg-4 '>
                            <Controller
                                control={methods.control}
                                name="people.mobile_phone"
                                render={({ field: { onChange, value} }) => (
                                    <IntlTelInput
                                        id='mobilePhone'
                                        inputClassName='form-control fs-6 py-0'
                                        containerClassName='intl-tel-input country-code-picker'
                                        style={{ width: '100%', borderRadius: '0px' }}
                                        preferredCountries={["us", "ca"]}
                                        defaultCountry={parsePhoneNumber(`+${methods.getValues('people.mobile_phone_country_code')}-${value}`, 'US')?.country?.toLowerCase()}
                                        allowDropdown={true}
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.MOBILENUMBER' })}
                                        value={PhoneNumberFormatter(value)}
                                        onPhoneNumberChange={(_isValid, _rawValue,_countryData,_formattedValue,_extension) => {
                                            const unformattedValue = _rawValue.replace(/\D/g, ''); // Remove non-digit characters
                                            onChange(unformattedValue)
                                            if(_countryData.dialCode){
                                                methods.setValue('people.mobile_phone_country_code', _countryData.dialCode, { shouldDirty: true  })
                                            }
                                            if(unformattedValue.length < 10){
                                                methods.setError('people.mobile_phone',{
                                                    type: "custom",
                                                    message: intl.formatMessage({id: 'FORM.INPUT.ALL.MOBILE.VALIDATION.MESSAGE'})
                                                })
                                            }
                                            else{
                                                methods.clearErrors('people.mobile_phone')
                                            }
                                        }}
                                        onSelectFlag={(currentNumber, country) => {if(country){ methods.setValue('people.mobile_phone_country_code', country.dialCode, { shouldDirty: true})}}}
                                    />
                                )}
                            />
                            { methods.formState.errors?.people?.mobile_phone && <div><span className='error_message'>{methods.formState.errors.people.mobile_phone?.message}</span></div>}
                        </div>

                            {/* Riding For */}
                            <label
                                className='col-lg-2 col-form-label fs-5 py-1'
                                htmlFor='ridingFor'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.LABEL.RIDINGFOR">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.RIDINGFOR' })}
                            </label>
                            <div className='col-lg-3'>
                                {
                                    peopleMetaData.countries &&
                                    <Controller
                                        id="ridingFor"
                                        name='people.competing_nationality'
                                        render={({
                                            field: { onChange, value, name },
                                        }) => (
                                            <Select
                                                placeholder="Select"
                                                options={peopleMetaData.countries}
                                                value={peopleMetaData.countries.find((c) => c.value === methods.watch("people.competing_nationality"))}
                                                name={name}
                                                isSearchable={true}
                                                onChange={(country) => {
                                                    onChange(country.value);
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                            />
                                        )}
                                    />
                                }
                            </div>
                            <label className='col-lg-1 col-form-label fs-5 py-1'>
                                {methods.getValues("people.competing_nationality")}
                            </label>
                        </div>

                        {/* Row */}
                        <div className='row mb-2'>
                            {/* SGL Login Phone */}
                            <label
                                className='col-lg-2 col-form-label fs-5 py-1 pe-0'
                                htmlFor='PhoneNumber'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.LABEL.PHONENUMBER">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.PHONENUMBER' })}
                            </label>
                        <div className='col-lg-4 '>
                            <Controller
                                control={methods.control}
                                name="people.phonenumber"
                                render={({ field: { onChange, value} }) => (
                                    <IntlTelInput
                                        id='PhoneNumber'
                                        inputClassName='form-control fs-6 py-0'
                                        style={{ width: '100%', borderRadius: '0px' }}
                                        containerClassName='intl-tel-input country-code-picker'
                                        preferredCountries={["us", "ca"]}
                                        defaultCountry={parsePhoneNumber(`+${methods.getValues('people.phonenumber_country_code')}${value}`, 'US')?.country?.toLowerCase()}
                                        allowDropdown={true}
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.PHONENUMBER' })}
                                        value={PhoneNumberFormatter(value)}
                                        onPhoneNumberChange={(_isValid, _rawValue,_countryData,_formattedValue,_extension) => {
                                            const unformattedValue = _rawValue.replace(/\D/g, ''); // Remove non-digit characters
                                            onChange(unformattedValue)
                                            if(_countryData.dialCode){
                                                methods.setValue('people.phonenumber_country_code', _countryData.dialCode, { shouldDirty: true  })
                                            }
                                            if(unformattedValue.length < 10){
                                                methods.setError('people.phonenumber',{
                                                    type: "custom",
                                                    message: intl.formatMessage({id: 'FORM.INPUT.ALL.PHONE.VALIDATION.MESSAGE'})
                                                })
                                            }
                                            else{
                                                methods.clearErrors('people.phonenumber')
                                            }
                                        }}
                                        onSelectFlag={(currentNumber, country) => {if(country){ methods.setValue('people.phonenumber_country_code', country.dialCode, { shouldDirty: true})}}}
                                    />
                                )}
                            />
                            { methods.formState.errors?.people?.phonenumber && <div><span className='error_message'>{methods.formState.errors.people.phonenumber?.message}</span></div>}
                        </div>
                        </div>

                        {/* Row */}
                        <div className='row mb-2'>
                            {/* Emergency Contact */}
                            <label
                                className='col-lg-2 col-form-label fs-5 py-0 pe-0'
                                htmlFor='emergencyContact'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.LABEL.EMERGENCYCONTACT">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.EMERGENCYCONTACT' })}
                            </label>
                            <div className='col-lg-4'>
                                <input
                                    {...methods.register("people.emergency_contact", { required: false })}
                                    id="emergencyContact"
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.EMERGENCYCONTACT' })}
                                />
                            </div>
                        </div>

                        {/* Row */}
                        <div className='row mb-2'>
                            {/* Emergency Number */}
                            <label
                                className='col-lg-2 col-form-label fs-5 py-1'
                                htmlFor='emergencyPhone'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.LABEL.EMERGENCY#">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.EMERGENCYNUMBER' })}
                            </label>
                            <div className='col-lg-4'>
                                <Controller
                                    control={methods.control}
                                    name="people.emergency_phone"
                                    render={({ field: { onChange, value } }) => (
                                        <PatternFormat
                                            id="emergencyPhone"
                                            format="(###) ###-####"
                                            value={methods.getValues('people.emergency_phone') === '' ? '' : typeof value == 'string' ? Number(value?.match(/\d+/g)?.join('')) : value}
                                            onValueChange={(e) => {
                                                onChange(e.value)
                                            }}
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.EMERGENCYNUMBER' })}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        
                        {/* <div className='row mb-2'>
                            <label 
                                className='col-lg-1 col-form-label fs-5 py-1'
                                htmlFor='gender'>
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.GENDER' }) }
                            </label>
                            <div className='col-lg-3'>
                                {
                                    peopleMetaData.genders &&
                                    <Controller
                                        name='people.gender'
                                        render={({
                                            field: { onChange, value, name },
                                        }) => (
                                            <Select
                                                placeholder="Select"
                                                options = {peopleMetaData.genders}
                                                value={peopleMetaData.genders.find((g) => g.value === methods.watch("people.gender"))}
                                                name={name}
                                                isSearchable={true}
                                                onChange={(gender) => {
                                                   onChange(gender.value);
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                            />    
                                        )}
                                    /> 
                                }
                            </div>
                        </div> */}

                        {/* <div className='row mb-2'>
                            <label 
                                className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px'
                                htmlFor='phoneNumber'>
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.PHONENUMBER' }) }
                            </label>
                            <div className='col-lg-1 w-lg-90px'>
                                <div className='row mb-2'>
                                    <label className='col fs-5 py-1 pe-2'>+</label>
                                    <input
                                        id="phoneNumber"
                                        {...methods.register("people.phonenumber_country_code", { required: false })}
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1 w-lg-40px'
                                    />
                                    <label className='col fs-5 py-1 pe-1'>-</label>
                                </div>
                            </div>
                            <div className='col-lg-4 w-lg-200px ps-0'>
                                <Controller
                                    control={methods.control}
                                    name="people.phonenumber"
                                    render={({ field: { onChange, value} }) => (
                                        <PatternFormat
                                            id="phoneNumber"
                                            format="(###) ###-####"
                                            value={methods.getValues('people.phonenumber') === '' ? '' :typeof value == 'string' ? Number(value?.match(/\d+/g)?.join('')): value }
                                            onValueChange={(e)=>{
                                                onChange(e.value)
                                            }}
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        />
                                    )}
                                />
                            </div>
                            <label 
                                className='col-lg-1 col-form-label fs-5 py-1'
                                htmlFor='fax'>
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.FAX' }) }
                            </label>
                            <div className='col-lg-3'>
                            <Controller
                                    control={methods.control}
                                    name="people.fax_number"
                                    render={({ field: { onChange, value} }) => (
                                    <PatternFormat
                                        format="(###) ###-####"
                                        value={methods.getValues('people.fax_number') === '' ? '' : Number(value?.match(/\d+/g)?.join(''))}
                                        onValueChange={(e)=>{
                                            onChange(e.value)
                                        }}
                                        id="fax"
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.FAX' })}
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    />
                                    )}
                                />
                            </div>
                        </div> */}
                        
                        {/* Prize money heading */}
                        <fieldset>
                            <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.PRIZEMONEYFILINGDETAILS' })} <b></b></legend>
                        </fieldset>

                        {/* Row */}
                        <div className='row mb-2'>
                            <label 
                                className='col-lg-2 col-form-label fs-5 py-1'
                                htmlFor='socialSecurityNumber'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.PRIZEMONEYFILINGDETAILS.LABEL.SOCIALSECURITY">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.SOCIALSECURITY' }) }
                            </label>
                            <div className='col-lg-4'>
                                <Controller
                                    control={methods.control}
                                    name="people.social_security_number"
                                    render={({ field: { onChange, value } }) => (
                                        <PatternFormat
                                            id="socialSecurityNumber"   
                                            format="###-##-####" // AAA-GG-SSSS
                                            value={methods.getValues('people.social_security_number') === '' ? '' : typeof value == 'string' ? Number(value?.match(/\d+/g)?.join('')) : value}
                                            onValueChange={(e) => {
                                                onChange(e.value)
                                            }}
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            disabled={methods.watch('people.foreigner') > 0 ? true : false}
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.SOCIALSECURITY' })}
                                        />
                                    )}
                                />
                            </div>
                            <div className='col-lg-5 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...methods.register("people.foreigner", { required: false }) }
                                    className='form-check-input'
                                    type='checkbox'
                                    id='foreigner'
                                    onChange={(e) => onForeignerChecked(e.target.checked)}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='foreigner' data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.PRIZEMONEYFILINGDETAILS.LABEL.FOREIGNRESIDENTWITHHOLDING"> 
                                    {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.FOREIGNRESIDENT' })}
                                </label>
                            </div>
                            
                        </div>

                        {/* Row */}
                        <div className='row mb-2'>
                            {/* Federal ID */}
                            <label 
                                className='col-lg-2 col-form-label fs-5 py-1'
                                htmlFor='federalID'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.PRIZEMONEYFILINGDETAILS.LABEL.FEDERALID#">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.FEDERALID' }) }
                            </label>
                            <div className='col-lg-4'>
                                <Controller
                                    control={methods.control}
                                    name="people.federal_id"
                                    render={({ field: { onChange, value } }) => (
                                        <PatternFormat
                                            id="federalID"
                                            format="##-#######" // EIN format xx-xxxxxxx
                                            value={methods.getValues('people.federal_id') === '' ? '' : typeof value == 'string' ? Number(value?.match(/\d+/g)?.join('')) : value}
                                            onValueChange={(e) => {
                                                onChange(e.value)
                                            }}
                                            className='form-control form-control-sm fs-6 min-h-20px'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.FEDERALID' })}
                                            disabled={methods.watch('people.foreigner') > 0 ? true : false}
                                        />
                                    )}
                                />
                            </div>

                            {/* Country Taxes */}
                            <label
                                className='col-lg-3 col-form-label fs-5 py-1 rto-detail-w-170'
                                htmlFor='countryTaxes'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.PRIZEMONEYFILINGDETAILS.LABEL.FOREIGNTAXCOUNTRY">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.FOREIGNTAXCOUNTRY' })}
                            </label>
                            <div className='col'>
                                {
                                    peopleMetaData.countries &&
                                    <Controller
                                        name='people.tax_country'
                                        render={({
                                            field: { onChange, value, name },
                                        }) => (
                                            <Select
                                                placeholder="Select"
                                                options={peopleMetaData.countries.filter(country => country.label.toLowerCase() !== "united states")}
                                                value={ peopleMetaData.countries.find((c) => c.label?.toLowerCase() === methods.watch("people.tax_country")?.toLowerCase()) }
                                                name={name}
                                                id="countryTaxes"
                                                isSearchable={true}
                                                onChange={(country) => {onChange(country.label)}}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                                isDisabled={methods.watch('people.foreigner') > 0 ? false : true}
                                            />
                                        )}
                                    />
                                }
                            </div>
                        </div>

                        <div className='row mb-2'>
                            {/* Legal Name */}
                            <label
                                className='col-lg-2 col-form-label fs-5 py-1'
                                htmlFor='legalName'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.PRIZEMONEYFILINGDETAILS.LABEL.LEGALNAME">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.LEGALNAME' })}
                            </label>
                            <div className='col-lg-4'>
                                <input
                                    {...methods.register("people.legal_name", { required: false })}
                                    id='legalName'
                                    type='text'
                                    className='form-control form-control-sm  fs-5 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.LEGALNAME' })}
                                />
                            </div>

                            {/* SSN Notes */}
                            <label 
                                className='col-lg-3 col-form-label fs-5 py-1 rto-detail-w-170'
                                htmlFor='ssnNotes'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.PRIZEMONEYFILINGDETAILS.LABEL.SSNNOTES">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.SSNNOTES' }) }
                            </label>
                            <div className='col'>
                                <input
                                    {...methods.register("people.ssn_notes", { required: false })}
                                        id='ssnNotes'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.SSNNOTES' })}
                                    />
                            </div>
                        </div>

                        {
                            isTaxFormLookupEnabled ?
                                <>
                                    <div className='row mb-2'>
                                        {/* TIN Status */}
                                        {
                                            <>
                                                <label
                                                    className='col-lg-2 col-form-label fs-5 py-1'
                                                    htmlFor='tinStatus'
                                                >
                                                    {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.TINSTATUS' })}
                                                </label>
                                                <div className='col-lg-4'>
                                                    <label
                                                        className='col col-form-label fs-5 py-1'
                                                        htmlFor='tinStatus'
                                                        style={{ color: methods.getValues('people.tincheck_status')?.toLowerCase() == 'verified' ? 'green' : 'red' }}
                                                    >
                                                        {methods.getValues('people.tincheck_status') ? methods.getValues('people.tincheck_status') : 'N/A'}
                                                    </label>
                                                </div>
                                            </>
                                        }
                                        <label
                                            className='col-lg-3 col-form-label fs-5 py-1 rto-detail-w-170'
                                            htmlFor='taxDocumentActions'
                                        >
                                            {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.TAX.DOCUMENT.ACTIONS' })}
                                        </label>
                                        <div className='col'>
                                            <Select
                                                placeholder="Select"
                                                options={getTaxFormOptions()}
                                                isSearchable={true}
                                                value={taxDocumentAction}
                                                onChange={(state) => {
                                                    if(state.value == 'tinCheck'){
                                                        setRunTinCheck(prevState => prevState + 1)
                                                    }else if(['callTaxFormLookup', 'manualRequest'].includes(state.value)){
                                                        if(state.value == 'manualRequest'){
                                                            setSelectedTaxFormRequestType('Manual Request')
                                                        }
                                                        setShowTaxInformation(true)
                                                    }
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-2'>

                                        {/* TIN Check Date */}
                                        {
                                            <>
                                                <label 
                                                    className='col-lg-2 col-form-label fs-5 py-1'
                                                    htmlFor='tinCheckDateTime'
                                                >
                                                    { `${intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.TINCHECKDATE' })}` }
                                                </label>

                                                <div className='col-lg-4'>
                                                    <div className='row align-items-center'>
                                                        <div className='col-lg-10 d-flex align-items-center'>
                                                            <label
                                                                className={`col-form-label fs-5 py-1 flex-grow-1`}
                                                            >
                                                                {getLookupDateAndTime('tin')}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        }

                                        {/* Tax Form Status */}
                                        {
                                            <>
                                                <label 
                                                    className='col-lg-3 col-form-label fs-5 py-1 rto-detail-w-170'
                                                    htmlFor='taxformStatus'
                                                >
                                                    { `${intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.TAXSTATUS' })}` }
                                                </label>

                                                <div className='col'>
                                                    <div className='row align-items-center'>
                                                        {/* Tax Form Status and Tax Status Form */}
                                                        <div className='col-lg-10 d-flex align-items-center'>
                                                            <label
                                                                className={`col-form-label fs-5 py-1 flex-grow-1 ${taxFormStatus.toLowerCase() == 'completed' ? 'cursor-pointer text-decoration-underline' : ''}`}
                                                                htmlFor='taxStatus'
                                                                style={{ color: isTaxStatusValid ? 'green' : 'red' }}
                                                                onClick={getPDFLink}
                                                            >
                                                                {taxFormStatus ? `${taxFormStatus} (${taxFormType})` : 'N/A'}
                                                            </label>
                                                        </div>

                                                        {
                                                            taxformWarning?.length > 0 ?
                                                                <>
                                                                <div className='col-lg-12 d-flex align-items-center'>
                                                                    <label
                                                                        className={`col-form-label fs-7 py-0 flex-grow-1`}
                                                                        style={{ color: 'red', lineHeight: '1.25' }}
                                                                    >
                                                                        {taxformWarning.join(', ')}
                                                                    </label>
                                                                </div>
                                                                </>
                                                            : null
                                                        }
                                                    </div>
                                                </div>

                                            </>
                                        }
                                    </div>
                                    <div className='row mb-2'>

                                        <div className='col-lg-6'></div>
                                        {/* Tax Status Check Date */}
                                        {
                                            <>
                                                <label
                                                    className='col-lg-3 col-form-label fs-5 py-1 rto-detail-w-170'
                                                    htmlFor='tinStatusCheckDateTime'
                                                >
                                                    {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.TAXSTATUSCHECKDATE' })}
                                                </label>
                                                <div className='col'>
                                                    <label
                                                        className='col col-form-label fs-5 py-1'
                                                    >
                                                        {getLookupDateAndTime('taxStatus')}
                                                    </label>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </>
                            : null
                        }


                        {/* Additional Details */}
                        <fieldset>
                            <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.ADDITIONALDETAILS' })} <b></b></legend>
                        </fieldset>

                        <div className='row mb-2'>
                            {/* Status */}
                             <label
                                className='col-lg-2 col-form-label fs-5 py-1'
                                htmlFor='riderUserStatus'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.ADDITIONALDETAILS.LABEL.STATUS">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.STATUS' })}
                            </label>
                            <div className='col-lg-4'>
                                <input
                                    {...methods.register("people.rider_user_status", { required: false })}
                                    id='riderUserStatus'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.STATUS' })}
                                />
                            </div>

                            {/* Rider Status */}
                            <label 
                                className='col-lg-2 col-form-label fs-5 py-1'
                                htmlFor='riderStatus'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.ADDITIONALDETAILS.LABEL.RIDERSTATUS">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.RIDERSTATUS' }) }
                            </label>
                            <div className='col'>
                                {
                                    peopleMetaData.riderStatus &&
                                    <Controller
                                        name='people.rider_status'
                                        render={({
                                            field: { onChange, value, name },
                                        }) => (
                                            <Select
                                                placeholder="Select"
                                                options = {peopleMetaData.riderStatus}
                                                value={peopleMetaData.riderStatus.find(
                                                    rs => rs.value === value
                                                )}
                                                name={name}
                                                id="riderStatus"
                                                isSearchable={true}
                                                isDisabled={methods.watch('disableStatus')}
                                                onChange={(rs) => {
                                                    onChange(rs.label);
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                            />    
                                        )}
                                    /> 
                                }
                            </div>
                        </div>
                        
                        {/* Row */}
                        <div className='row mb-2'>
                            {/* DOB */}
                            <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='dob'  data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.ADDITIONALDETAILS.LABEL.DOB">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.DOB' })}
                            </label>
                            <div className='col-lg-4'>
                            <div className="input-group date">
                                <Controller
                                    control={methods.control}
                                    name="people.dob"
                                    render={({
                                        field: { onChange, value, name },
                                    }) => {
                                        const formattedValue = (value && moment(value).isValid()) 
                                            ? moment(value).format('MM/DD/YYYY') 
                                            : '';

                                        return (
                                            <Flatpickr 
                                                className="form-control form-control-sm fs-6 min-h-20px py-1 flatpickr-input"
                                                id="dob"
                                                name={name}
                                                autoComplete="off"
                                                value={formattedValue}
                                                placeholder="MM/DD/YYYY"
                                                options={date_options}
                                                disabled={methods.watch('disableDOB')}
                                                onClose={(value, dateStr, instance) => {
                                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                    if (!isValidDate) {
                                                        instance.input.value = ''; // Clear the input if the date is invalid
                                                    }
                                                    methods.setValue("people.age", calculateAge(moment(value[0]).format('MM/DD/YYYY')), { shouldDirty: true });
                                                    setDate(dateStr, onChange); // this sets the typed/selected date in the datepicker and also format date to YYYY-MM-DD (this is the format that backend accepts)
                                                }}
                                                onChange={(value, dateStr, instance) => {
                                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                    if (!isValidDate) {
                                                        instance.input.value = ''; // Clear the input if the date is invalid
                                                    }
                                                    methods.setValue("people.age", calculateAge(moment(value[0]).format('MM/DD/YYYY')), { shouldDirty: true });
                                                    setDate(dateStr, onChange); // this sets the typed/selected date in the datepicker and also format date to YYYY-MM-DD (this is the format that backend accepts)
                                                }}
                                                onInput={(e) => { maskDateInput(e) }}
                                            />
                                        );
                                    }}
                                />
                                <div className="input-group-append">
                                    <label htmlFor='dob' className='date-label'
                                        onClick={(e) => {
                                            e.stopPropagation(); 
                                            document.getElementById('dob')._flatpickr.open(); 
                                        }}
                                    >
                                        <span className="input-group-text">
                                            <i className="la la-calendar"></i>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            </div>
                            {/* Age */}
                            {methods.watch("people.age") > 0 && (
                            <>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-40px' data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.ADDITIONALDETAILS.LABEL.AGE">
                                    Age:&nbsp;
                                </label>
                                <label className='col-lg-2 col-form-label fs-5 py-1 ps-0'>
                                    { methods.getValues("people.age") }
                                </label> 
                            </>
                            )}          
                        </div>

                        {/* Row */}
                        {/* <div className='row mb-2'>
                            <label 
                                className='col-lg-2 col-form-label fs-5 py-1'
                                htmlFor='coach'
                                data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.ADDITIONALDETAILS.LABEL.COACH">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.COACH' }) }
                            </label>
                            <div className='col-lg-4'>
                                <input
                                    {...methods.register("people.coach.lf_name", { required: false })}
                                    id='coach'
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    disabled={true}
                                />
                            </div>
                        </div> */}
                        <div className='row mb-2'>
                            {/* Rider last rode at */}
                            <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='rider' data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.ADDITIONALDETAILS.LABEL.RIDER">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.RIDER' }) }
                            </label>
                            <div className='col-lg-9 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...methods.register("people.rider", 
                                    {
                                        required: false
                                    })
                                    }
                                    className='form-check-input'
                                    type='checkbox'
                                    id='rider'
                                    disabled={true}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1'> 
                                    { methods.getValues('people.lastRodeString') }
                                </label>
                            </div>
                        </div>

                        {/* Row */}
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='trainer' data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.ADDITIONALDETAILS.LABEL.TRAINER">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.TRAINER' }) }
                            </label>
                            <div className='col-lg-9 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...methods.register("people.trainer", 
                                    {
                                        required: false
                                    })
                                    }
                                    id="trainer"
                                    className='form-check-input'
                                    type='checkbox'
                                    disabled={true}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1'> 
                                { methods.getValues('people.lastTrainedString') }
                                </label>
                            </div>
                        </div>

                        {/* Row */}
                        <div className='row mb-2'>
                            <label htmlFor="trainerStable" className='col-lg-2 col-form-label fs-5 py-1' data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.ADDITIONALDETAILS.LABEL.TRAINERSSTABLE">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.TRAINERSTABLE' }) }
                            </label>
                            <div className='col-lg-4'>
                                <input
                                    {...methods.register("people.trainerStable.name", { required: false })}
                                    id='trainerStable'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    disabled={true}
                                />
                            </div>
                        </div>

                        {/* Row */}
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor='owner' data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.ADDITIONALDETAILS.LABEL.OWNER">
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.OWNER' }) }
                            </label>
                            <div className='col-lg-9 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...methods.register("people.owner", 
                                    {
                                        required: false
                                    })
                                    }
                                    className='form-check-input'
                                    type='checkbox'
                                    id='owner'
                                    disabled={true}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1'> 
                                    { methods.getValues("people.lastOwnedString") }
                                </label>
                            </div>
                        </div>
                    </div> {/* End Column 1 */}
                    <div className='col-lg-5'> {/* Column 2 */}
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor='emailaddress' data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.LABEL.EMAILADDRESS">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.EMAILADDRESS' })}</label>
                                <div className='col-lg-7'>
                                <input
                                    {...methods.register("people.email_address", { required: false })}
                                    id="emailaddress"
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.EMAILADDRESS' })}
                                />
                                </div>
                        </div>
                        <div className='row mb-2'>
                            <label 
                                className='col-lg-2 col-form-label fs-5 py-1 w-lg-150px pe-lg-0' htmlFor="alternateEmail" data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.LABEL.ALTERNATEEMAIL">
                                {
                                    intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.ALTERNATEEMAIL' })
                                }
                            </label>
                            <div className='col-lg-7'>
                                <input
                                    {...methods.register("people.alternate_email_address", { required: false })}
                                    id='alternateEmail'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.ALTERNATEEMAIL' })}
                                />
                            </div>
                        </div>

                        <fieldset className='mb-4'>
                            <legend className="fs-5 fw-bold col-lg-11">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.CREDITCARDINFORMATION' })}
                            </legend>
                            
                            <div className='row'>
                                <div className='col-lg-10 d-flex align-items-center'>
                                    <OutputListingGridStatic
                                        area ={"People - Detail General"}
                                        setGridRef={setCreditCardGridRef} 
                                        columnDefs={creditCardColumnDefs} 
                                        containerStyle={containerStyle} 
                                        rowClassRules={redactedRowClassRules}
                                        onCellDoubleClick={handleAddPaymentMethod}
                                        onCellClicked={params => redactCardButtonRef.current.disabled = params.data.redacted}
                                        />
                                </div>
                                <div className='col-lg-2'>
                                    <button type="button" 
                                        className="btn btn-sm btn-secondary me-2 fw-bold px-2 py-2" 
                                        onClick={handleAddPaymentMethod}
                                        // disabled for new record
                                        data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.CREDITCARDINFORMATION.BUTTON.ADD"
                                        disabled={people_id === "0"}
                                    >
                                        <i className="fas fa-plus fs-5 px-1 py-3"></i>
                                    </button>
                                    <div className="separator my-1 border-transparent d-xl-block d-lg-block d-md-none d-sm-none d-none"></div>
                                    <button 
                                        type="button"
                                        className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2' 
                                        onClick={handleRedactCard}
                                        ref={redactCardButtonRef}
                                        data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.CREDITCARDINFORMATION.BUTTON.REMOVE"
                                        >
                                        <i className='fas fa-minus fs-5 px-1 py-3'></i>
                                    </button>
                                </div>
                            </div>
                            <div className='col-lg-11 form-check-sm form-check-custom'>
                                <input
                                    id="showRedacted"
                                    {...methods.register("showRedacted", {required: false})}
                                    className='form-check-input'
                                    type='checkbox'
                                    onChange={(event) => filterRedactedRows(event.target.checked)}
                                />
                                <label className='col-form-label mx-2 fs-5 py-0' htmlFor="showRedacted" data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.CREDITCARDINFORMATION.LABEL.SHOWREDACTED"> 
                                    {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.CARD.SHOWREDACTED' })}
                                </label>
                            </div>
                        </fieldset>

                        {/* Prize Payment Preferences */}
                        <fieldset className='mb-2'>
                            <legend className="fs-5 fw-bold d-flex col-lg-11">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.GROUPLABEL.PRIZEMONEY.PREFERENCES' })} <b></b></legend>
                            {/* Prize Preference */}
                            <div className='row mb-2'>
                                <label 
                                    className='col-lg-4 col-form-label fs-5 py-1'
                                    htmlFor='prizePreference'>
                                    { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.PRIZEPREFERNCE' }) }
                                </label>
                                <div className='col-lg-7'>
                                    <Controller
                                        name='people.prize_preference'
                                        render={({
                                            field: { onChange, value, name },
                                        }) => (
                                            <Select
                                                placeholder="Select"
                                                options = {peopleMetaData?.prizePreferences ?? []}
                                                value={peopleMetaData?.prizePreferences?.find(
                                                    pp => pp.value === value
                                                )}
                                                name={name}
                                                id="prizePreference"
                                                isSearchable={true}
                                                onChange={(pp) => {
                                                    onChange(pp.label);
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                            />    
                                        )}
                                    /> 
                                </div>
                            </div>

                            {/* Bank Name */}
                            <div className='row mb-2'>
                                <label 
                                    className='col-lg-4 col-form-label fs-5 py-1'
                                    htmlFor='bankName'>
                                    { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.BANKNAME' }) }
                                </label>
                                <div className='col-lg-7'>
                                    <input
                                        {...methods.register("people.bank_name", { required: false })}
                                        id='bankName'
                                        type='text'
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.BANKNAME' })}
                                        disabled={disableFields.includes('bank_name')}
                                    />
                                </div>
                            </div>

                            {/* Bank Account No */}
                            <div className='row mb-2'>
                                <label 
                                    className='col-lg-4 col-form-label fs-5 py-1'
                                    htmlFor='accountNo'>
                                    { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.BANKACCOUNTNO' }) }
                                </label>
                                <div className='col-lg-7'>
                                    <input
                                        {...methods.register("people.account_number", { required: false })}
                                        id='accountNo'
                                        type='text'
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.BANKACCOUNTNO' })}
                                        disabled={disableFields.includes('account_number')}
                                    /> 
                                </div>
                            </div>

                            {/* Routing No */}
                            <div className='row mb-2'>
                                <label 
                                    className='col-lg-4 col-form-label fs-5 py-1'
                                    htmlFor='routingNo'>
                                    { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.ROUTING.NO' }) }
                                </label>
                                <div className='col-lg-7'>
                                    <input
                                        {...methods.register("people.routing_number", { required: false })}
                                        id='routingNo'
                                        type='text'
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.ROUTING.NO' })}
                                        disabled={disableFields.includes('routing_number')}
                                    />
                                </div>
                            </div>

                            {/* Swift Code */}
                            <div className='row mb-2'>
                                <label 
                                    className='col-lg-4 col-form-label fs-5 py-1'
                                    htmlFor='swiftCode'>
                                    { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.SWIFTCODE' }) }
                                </label>
                                <div className='col-lg-7'>
                                    <input
                                        {...methods.register("people.swift_code", { required: false })}
                                        id='swiftCode'
                                        type='text'
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.SWIFTCODE' })}
                                        disabled={disableFields.includes('swift_code')}
                                    />
                                </div>
                            </div>
                        </fieldset>

                        {/* Membership Status */}
                        <fieldset className='mb-2'>
                            <legend className="fs-5 fw-bold d-flex col-lg-11">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.GROUPLABEL.MEMBERSHIPSTATUS' })} <b></b></legend>
                            <div className='row mb-2 align-items-center'>
                                <div className='col-lg-1 w-lg-40px'>
                                    <img className='w-20px' src={USEFLogo} />
                                </div>
                                <label className='col-lg-4 col-form-label fw-bold fs-5 py-1' data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.MEMBERSHIPSTATUS.LABEL.USEF">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.LABEL.USEF' }) + " " + methods.watch('people.usef_suspension_status')}</label>
                                {methods.getValues('people.usef_suspensioncheckdate') != '0000-00-00' ? <label className='col-lg-5 col-form-label fs-5 py-1'>
                                    <span style={{ wordSpacing: '0.25rem' }}>{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.LABEL.ASOF' })}</span>
                                    <span className='mx-3'>{methods.getValues('people.usef_suspensioncheckdate') && moment(methods.getValues('people.usef_suspensioncheckdate')).isValid() ? moment(methods.getValues('people.usef_suspensioncheckdate')).format('MM/DD/YYYY') : ''}</span>
                                    <span>{moment(methods.getValues('people.usef_susepensionchecktime'), 'HH:mm:ss').isValid() ? moment(methods.getValues('people.usef_susepensionchecktime'), 'HH:mm:ss').format("H:mm A") : ''}</span>
                                </label> : <label className='col-lg-5 col-form-label fs-5 py-1'></label>}
                                <label className='col-lg-1'>
                                    <i onClick={() => (lookup_usef_data())} className='far fa-solid fa-rotate text-success fs-3 fw-bold cursor-pointer'></i>
                                </label>
                            </div>
                            <div className='row mb-2 align-items-center'>
                                <div className='col-lg-1 w-lg-40px'>
                                    <img className='w-20px' src={ECLogo} />
                                </div>
                                <label className='col-lg-4 col-form-label fw-bold fs-5 py-1'  data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.MEMBERSHIPSTATUS.LABEL.EC">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.LABEL.EC' }) + " " + methods.watch('people.ec_StandingStatus')}</label>
                                {methods.getValues('people.ec_statuscheckdate') != '0000-00-00' ? <label className='col-lg-5 col-form-label fs-5 py-1'>
                                    <span style={{ wordSpacing: '0.25rem' }}>{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.LABEL.ASOF' })}</span>
                                    <span className='mx-3'>{methods.getValues('people.ec_statuscheckdate') && moment(methods.getValues('people.ec_statuscheckdate')).isValid() ? moment(methods.getValues('people.ec_statuscheckdate')).format('MM/DD/YYYY') : ''}</span>
                                    <span>{moment(methods.getValues('people.ec_statuschecktime'), 'HH:mm:ss').isValid() ? moment(methods.getValues('people.ec_statuschecktime'), 'HH:mm:ss').format("H:mm A") : ''}</span>
                                </label> : <label className='col-lg-5 col-form-label fs-5 py-1'></label>}
                                <label className='col-lg-1'>
                                    <i onClick={() => (lookup_ec_data())} className='far fa-solid fa-rotate text-success fs-3 fw-bold cursor-pointer'></i>
                                </label>
                            </div>
                            <div className='row mb-2 align-items-center'>
                                <div className='col-lg-1 w-lg-40px'>
                                    <img className='w-20px' src={FEILogo} style={{scale: '1.3'}}/>
                                </div>
                                <label className='col-lg-4 col-form-label fw-bold fs-5 py-1'  data-tooltip-id="PEOPLE.DETAIL.TAB.GENERAL.SECTION.MEMBERSHIPSTATUS.LABEL.FEI">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.LABEL.FEI' }) + " " + methods.watch('people.feiStandingStatus')}</label>
                                {methods.getValues('people.fei_statuscheckdate') != '0000-00-00' ? <label className='col-lg-5 col-form-label fs-5 py-1'>
                                    <span style={{ wordSpacing: '0.25rem' }}>{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.LABEL.ASOF' })}</span>
                                    <span className='mx-3'>{methods.getValues('people.fei_statuscheckdate') && moment(methods.getValues('people.fei_statuscheckdate')).isValid() ? moment(methods.getValues('people.fei_statuscheckdate')).format('MM/DD/YYYY') : ''}</span>
                                    <span>{moment(methods.getValues('people.fei_statuschecktime'), 'HH:mm:ss').isValid() ? moment(methods.getValues('people.fei_statuschecktime'), 'HH:mm:ss').format("H:mm A") : ''}</span>
                                </label> : <label className='col-lg-5 col-form-label fs-5 py-1'></label>}
                                <label className='col-lg-1'>
                                    <i onClick={() => (lookup_fei_data())} className='far fa-solid fa-rotate text-success fs-3 fw-bold cursor-pointer'></i>
                                </label>
                            </div>
                        </fieldset>
                    </div> {/* End Column 2 */}
                </div>
            </div>
        </>
    );
}

export { GeneralTab }