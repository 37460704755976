import { useIntl } from 'react-intl'
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import { useEffect, useMemo, useRef, useState } from 'react';
import DateRenderer from '../../../../modules/output-listing/renderers/DateRenderer';
import { ShowSection } from '../ShowSection';
import { date_options, setDate, maskDateInput, reactSelectStyles } from '../../../../modules/sgl-utils/fieldControls';
import Flatpickr from "react-flatpickr";
import CreateBatchGrid from './CreateBatchGrid';
import { usePaymentBatchContext } from '../../PaymentBatchContext';
import axios from 'axios';
import { useAppSelector } from '../../../../redux/hooks';
import { useAlert, useLoadingOverlay, useProgress } from '../../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext } from '../../../../modules/output-listing/OutputListingContext';
import { addFloatingNumbers, loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../../modules/sgl-utils/SglFunctions';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import Select from "react-select";
import { useLoadReportAndPrint } from '../../../reports/ReportTypeModal';

const CreateBatchTab = () => {
    const intl = useIntl();
    const {selectedShow, closePaymentBatchDetail, selectedTab, setSelectedTab, updateCreateBatchTabData, setUpdateCreateBatchTabData, setUpdatePostPaymentsTabData, refreshPaymentBatch, selectedShowEndDate, prizePreferences}= usePaymentBatchContext()
    const currencyFormatter = useCurrencyFormatter()
    const currency = intl.formatMessage({ id: 'CURRENCY' })
    const moment = require('moment');
    const customerID = useAppSelector(state => state.showCompany.company_id);
    const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
    const alertDialog = useAlert()
    const {progressBar} = useProgress()
    const navigate = useNavigate()
    const { getEventID } = useOutputContext()
    const loadingOverlay = useLoadingOverlay();
    const {loadReportsAndPrint} = useLoadReportAndPrint();

    const rtoSummaryListGrid = useRef();
    const [paymentDate, setPaymentDate] = useState()
    const [paymentDesc, setPaymentDesc] = useState()
    const [batchType, setBatchType] = useState('Both')
    const [peopleData, setPeopleData] = useState([])
    const [displayLoadingOverlay, setDisplayLoadingOverlay] = useState(false)
    const [selectAllRTO, setSelectAllRTO] = useState(false)
    const [agingInformation, setAgingInformation] = useState({
        pendingBalance: 0,
        currentCharges: 0,
        currentPayment: 0,
        dueFrom1Months: 0,
        dueFrom2Months: 0,
        dueFrom3Months: 0,
        dueBefore3Months: 0,
        // related entries list
        currrentAndFutureEntries: [],
        dueFrom1MonthsEntries: [],
        dueFrom2MonthsEntries: [],
        dueFrom3MonthsEntries: [],
        dueBefore3MonthsEntries: []
    })
    const [selectedAging, setSelectedAging] = useState('')
    const [rtoSummary, setRTOSummary] = useState({
        showCreditEarned: 0, //total credit earned for show
        prizeMoneyEarned: 0,
        balanceRefundEarned: 0,
        nonTransferableCredit: 0, //credit not available for transfer
        showAvailableCredit: 0,
        batchTypeAvailableCredit: 0,
        batchTypePayableCredit: 0,
        showPayments: 0,
        checkPayments: 0,
        entryPayments: 0,
        nonTransferableCreditReasons: [],
        arBalance: 0,
        currentPastEntriesBalance: 0,
    })
    const [rtoCreditBalance, setRTOCreditBalance] = useState(0)
    const [rtoSummaryBreakdown, setRtoSummaryBreakdown] = useState(null)
    const [selectedRTOSummary, setSelectedRTOSummary] = useState('currentPastEntriesWithBalance')
    const [paymentType, setPaymentType] = useState('')

    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const rtoSummaryListContainerStyle = useMemo(() => ({ width: '100%', height: '30vh' }), []);
    const defaultColDef = useMemo(() => {
        return {
          resizable: true,
          suppressMenu: true,
          editable: false,
          wrapHeaderText: true,
          sortable: true,
          cellStyle: {textAlign: 'center', fontSize: '12px'},
          autoHeaderHeight: false,
          headerClass: "ag-center-aligned-header",
          singleClickEdit: true,
        };
    }, []);

    const getRowStyle = (params) => {
        if (params.node.rowPinned) {
            return { fontWeight: "bold" };
        }
    };

    const getColumnDefs = (selectedLabel) => {
        let columnDefs = [
            { field: 'number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.NUMBER' }), width: 75},
            { field: 'Show.show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.SHOW' }), flex: 1, cellStyle: {textAlign: 'left', paddingLeft: 4, paddingRight: 4}},
            { field: 'balance', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.BALANCE' }), flex: 1, cellStyle: {textAlign: 'right'}, cellRenderer: params => currencyFormatter(params.value)},
        ]
        switch(selectedLabel) {
            case 'showCredits':
                columnDefs = [
                    { field: 'Entry.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.NUMBER' }), width: 75},
                    { field: 'type', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.TYPE' }), flex: 1, cellStyle: {textAlign: 'left', paddingLeft: 10, paddingRight: 10}},
                    { field: 'amount', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.AMOUNT' }), flex: 1, cellStyle: {textAlign: 'right'}, cellRenderer: params => currencyFormatter(Math.abs(params.value))},
                ]
                break;
            case 'prizeMoneyCredits':
            case 'balanceRefundCredits':
            case 'entryPayments':
                columnDefs = [
                    { field: 'Entry.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.NUMBER' }), width: 75},
                    { field: 'description', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.DECRIPTION' }), flex: 1, cellStyle: {textAlign: 'left', paddingLeft: 4, paddingRight: 4},},
                    { field: 'amount', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.AMOUNT' }), flex: 1, cellStyle: {textAlign: 'right'}, cellRenderer: params => currencyFormatter(Math.abs(params.value))},
                ]
                break;
            case 'nonTransferableCredits':
            case 'showPayments':
            case 'checkPayments':
                columnDefs = [
                    { field: 'arentry_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.TOTAL.DATE' }), flex: 1, cellRenderer: DateRenderer },
                    { field: 'type', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.TYPE' }), flex: 1, cellStyle: {textAlign: 'left', paddingLeft: 10, paddingRight: 10}},
                    { field: 'amount', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.AMOUNT' }), flex: 1, cellStyle: {textAlign: 'right'}, cellRenderer: params => currencyFormatter(Math.abs(params.value))},
                ]
                break;
            case 'currentPastEntriesWithBalance':
            case 'currrentAndFutureEntries':
            case 'dueFrom1MonthsEntries':
            case 'dueFrom2MonthsEntries':
            case 'dueFrom3MonthsEntries':
            case 'dueBefore3MonthsEntries':
                columnDefs = [
                    { field: 'number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.NUMBER' }), width: 75},
                    { field: 'Show.show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.SHOW' }), flex: 1, cellStyle: {textAlign: 'left', paddingLeft: 4, paddingRight: 4}},
                    { field: 'balance', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.BALANCE' }), flex: 1, cellStyle: {textAlign: 'right'}, cellRenderer: params => currencyFormatter(params.value)},
                ]
                break;
            case 'accountRegisters':
                columnDefs = [
                    { field: 'type', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.TYPE' }), flex: 1, cellStyle: {textAlign: 'left', paddingLeft: 10, paddingRight: 10}},
                    { field: 'Show.show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.SHOW' }), flex: 1, cellStyle: {textAlign: 'left', paddingLeft: 4, paddingRight: 4}},
                    { field: 'amount', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.AMOUNT' }), flex: 1, cellStyle: {textAlign: 'right'}, cellRenderer: params => currencyFormatter(params.value)},
                ]
                break;
            case 'currrentAndFutureEntriesTotalFees':
                columnDefs = [
                    { field: 'number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.NUMBER' }), width: 75},
                    { field: 'Show.show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.SHOW' }), flex: 1, cellStyle: {textAlign: 'left', paddingLeft: 4, paddingRight: 4}},
                    { field: 'total_fees', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.TOTAL.FEES' }), flex: 1, cellStyle: {textAlign: 'right'}, cellRenderer: params => currencyFormatter(params.value)},
                ]
                break;
            case 'currrentAndFutureEntriesTotalPaid':
                columnDefs = [
                    { field: 'number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.NUMBER' }), width: 75},
                    { field: 'Show.show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.SHOW' }), flex: 1, cellStyle: {textAlign: 'left', paddingLeft: 4, paddingRight: 4}},
                    { field: 'total_paid', headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.TOTAL.PAID' }), flex: 1, cellStyle: {textAlign: 'right'}, cellRenderer: params => currencyFormatter(params.value)},
                ]
                break;
        }

        return columnDefs
    }

    const getRtoDetailGridLabel = (selectedLabel) => {
        let label = intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.PAST.ENTRIES.BALANCE' })
        switch(selectedLabel) {
            case 'showCredits':
                label = intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.ENTRIES.GRID.COL.TOTAL.SHOW.CREDIT' })
                break;
            case 'prizeMoneyCredits':
                label = intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.PRIZEMONEY.CREDIT' })
                break;
            case 'balanceRefundCredits':
                label = intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.BALANCEREFUND.CREDIT' })
                break;
            case 'currentPastEntriesWithBalance':
                label = intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.PAST.ENTRIES.BALANCE' })
                break;
            case 'checkPayments':
                label = intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.CHECK.PAYMENTS' })
                break;
            case 'entryPayments':
                label = intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.ENTRY.PAYMENTS' })
                break;
            case 'showPayments':
                label = intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.TOTAL.PAYMENTS' })
                break;
            case 'nonTransferableCredits':
                label = intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.NON.TRANSFERABLE.CREDIT' })
                break;
            case 'accountRegisters':
                label = intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.ACCOUNT.BALANCE.EXCLUDE' })
                break;
            case 'currrentAndFutureEntries':
                label = `AGING: ${intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.AGING.PENDING.ENTRIES' })}`
                break;
            case 'currrentAndFutureEntriesTotalFees':
                label = `AGING: ${intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.AGING.PENDING.ENTRIES.CHARGES' })}`
                break;
            case 'currrentAndFutureEntriesTotalPaid':
                label = `AGING: ${intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.AGING.PENDING.ENTRIES.PAYMENTS' })}`
                break;
            case 'dueFrom1MonthsEntries':
                label = `AGING: 1-30 ${intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.DAYS' })}`
                break;
            case 'dueFrom2MonthsEntries':
                label = `AGING: 30-60 ${intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.DAYS' })}`
                break;
            case 'dueFrom3MonthsEntries':
                label = `AGING: 60-90 ${intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.DAYS' })}`
                break;
            case 'dueBefore3MonthsEntries':
                label = `AGING: 90+ ${intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.DAYS' })}`
                break;

        }

        return label
    }

    const [columnDefs, setColumnDefs] = useState(getColumnDefs('currentPastEntriesWithBalance'))
    const [rowData, setRowData] = useState([])

    useEffect(() => {
        if(!paymentDate){
            // set the current date
            setPaymentDate(moment().format('YYYY-MM-DD'))
        }
        //TODO [FN]: add focus on flatpickr
    }, [])

    useEffect(() => {
        if(selectedShow){
            loadRTOForCreatePaymentBatch(batchType, paymentType)
            setPaymentDate(moment().format('YYYY-MM-DD')) // reset the date to current date
            setPaymentDesc('') // empty the user description field
        }
    }, [updateCreateBatchTabData, refreshPaymentBatch])

    useEffect(() => {
        if(selectedShow){
            setBatchType('Both')
            setPaymentType('')
            loadRTOForCreatePaymentBatch('Both', '')
        }
    }, [selectedShow])

    useEffect(() => {
        if(selectedRTOSummary){
            setSelectedAging('')
            setColumnDefs(getColumnDefs(selectedRTOSummary))
            let rowData = rtoSummaryBreakdown && rtoSummaryBreakdown[selectedRTOSummary] ? rtoSummaryBreakdown[selectedRTOSummary] : []
            setRowData(rowData)
            calculateTotals(selectedRTOSummary, rowData) //update pinned row data
        }
    }, [selectedRTOSummary, rtoSummaryBreakdown])

    useEffect(() => {
        if(selectedAging && agingInformation && rtoSummaryBreakdown){
            setSelectedRTOSummary('')
            let rowData = agingInformation && agingInformation[selectedAging] ? agingInformation[selectedAging] : []
            setColumnDefs(getColumnDefs(selectedAging))

            // 'currrentAndFutureEntriesTotalFees', 'currrentAndFutureEntriesTotalPaid' will have entry list same as 'currrentAndFutureEntries'
            if(agingInformation && ['currrentAndFutureEntries', 'currrentAndFutureEntriesTotalFees', 'currrentAndFutureEntriesTotalPaid'].includes(selectedAging)){
                rowData = agingInformation['currrentAndFutureEntries']
            }
            
            setRowData(rowData)
            calculateTotals(selectedAging, rowData) //update pinned row data
        }
    }, [selectedAging])

    const resetCreateBatchData = () => {
        setPeopleData([]) // empty the grid
        setAgingInformation({
            pendingBalance: 0,
            currentCharges: 0,
            currentPayment: 0,
            dueFrom1Months: 0,
            dueFrom2Months: 0,
            dueFrom3Months: 0,
            dueBefore3Months: 0,
            currrentAndFutureEntries: [],
            dueFrom1MonthsEntries: [],
            dueFrom2MonthsEntries: [],
            dueFrom3MonthsEntries: [],
            dueBefore3MonthsEntries: []
        })
        setRTOSummary({
            showCreditEarned: 0, //total credit earned for show
            prizeMoneyEarned: 0,
            balanceRefundEarned: 0,
            nonTransferableCredit: 0, 
            showAvailableCredit: 0,
            batchTypeAvailableCredit: 0,
            batchTypePayableCredit: 0,
            showPayments: 0,
            checkPayments: 0,
            entryPayments: 0,
            nonTransferableCreditReasons: [],
            currentPastEntriesBalance: 0,
            arBalance: 0,
        })
        setRTOCreditBalance(0)
        setRtoSummaryBreakdown(null)
        setSelectedRTOSummary('currentPastEntriesWithBalance')
        setSelectedAging('')
    }

    const loadRTOForCreatePaymentBatch = async(batchType, paymentType) => {
        if(!selectedShow || !customerID){
            return
        }
        setDisplayLoadingOverlay(true) // display overlay
        resetCreateBatchData() // reset data
        const eventID = '' //(selectedTab === 1 && !hideProgressbar) ? getEventID('processing-rto-for-create-batch') : ''
        if(eventID){
            progressBar({ show: true, eventID, timeElapsed: true, title: 'Processing RTOs with Credit' })
        }
        // update the RTO listing
        await axios.get(`${NEST_API_URL}/payment-batches/loadRTOForCreatePaymentBatch?show_id=${selectedShow}&customer_id=${customerID}&type=${batchType}&event_id=${eventID}&payment_type=${paymentType}`)
        .then(response => {
            if(response.data.success){
                // mark all rto as payable if the select All RTO checkbox is selected
                response.data.rtoData = response?.data?.rtoData.map((data) => {
                    if(data.canCreateBatch && selectAllRTO){
                        data['include'] = selectAllRTO
                    }
                    return data
                })
                setPeopleData(response?.data?.rtoData ?? [])
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            setDisplayLoadingOverlay(false)
            if(eventID){
                progressBar({ show: false, eventID })
            }
        })
    }

    const createPaymentBatch = async (event) => {

        //get selected RTO
        let selectedRTO = peopleData?.filter((people) => people.include)
        if(selectedRTO.length <= 0){
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.EEROR.NO.RTO.SELECTED' })})
            return
        }

        if(selectedRTO.reduce((val, people) => val + people.payment_amount, 0) >= 0){
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.LABEL.DATE' })});
            return 
        }

        // display loading
        setDisplayLoadingOverlay(true)
        if(event){
            loadingSpinnerBtnWait(event)
        }

        selectedRTO = selectedRTO.map((data) => {
            return { people_id: data?.people_id, amount: data?.payment_amount }
        })
        axios.post(`${NEST_API_URL}/payment-batches/createPaymentBatch`,{
            customer_id: customerID,
            show_id: selectedShow,
            batch_type: batchType,
            selectedRTO ,
            user_date: paymentDate,
            user_description: paymentDesc,
            payment_type: paymentType
        })
        .then(response => {
            if(response.data.success && response.data.paymentBatch){
                // refresh the tab data
                setUpdateCreateBatchTabData(!updateCreateBatchTabData)
                navigate(`/payment-batches/detail/${response.data.paymentBatch?.payment_batch_id}?customer_id=${customerID}&batch_show_id=${selectedShow}`)
                setSelectedTab(3) //Move to post payment tab
                setUpdatePostPaymentsTabData({show_id: selectedShow, batch_id: response.data.paymentBatch?.payment_batch_id})
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            setDisplayLoadingOverlay(false)
            if(event){
                loadingSpinnerBtnRelease(event)
            }
        })
    }

    const getAgingAndRTOSummaryDetails = async (peopleId) => {
        if(!peopleId) { return }

        if(!rtoSummaryListGrid?.current){ return }

        //reset the values
        setAgingInformation({
            pendingBalance: 0,
            currentCharges: 0,
            currentPayment: 0,
            dueFrom1Months: 0,
            dueFrom2Months: 0,
            dueFrom3Months: 0,
            dueBefore3Months: 0,
            currrentAndFutureEntries: [],
            dueFrom1MonthsEntries: [],
            dueFrom2MonthsEntries: [],
            dueFrom3MonthsEntries: [],
            dueBefore3MonthsEntries: []
        })
        setRTOCreditBalance(0)
        setRtoSummaryBreakdown(null)
        setSelectedRTOSummary('currentPastEntriesWithBalance')
    
        let selectedRTO = peopleData?.find((data) => data.people_id == peopleId)
        if(selectedRTO?.rtoSummary){
            setRTOSummary(selectedRTO.rtoSummary)
        }

        rtoSummaryListGrid?.current?.api?.showLoadingOverlay()
        axios.get(`${NEST_API_URL}/payment-batches/getAgingAndRelatedEntryList?show_id=${selectedShow}&customer_id=${customerID}&people_id=${peopleId}&batch_type=${batchType}&payment_type=${paymentType}`)
        .then(response => {
            if(response?.data?.success){
                setAgingInformation(response?.data?.agingInfo)
                setRTOCreditBalance(response?.data?.rtoCreditBalance)
                setRtoSummaryBreakdown(response?.data?.rtoSummaryBreakdown)
                setRTOSummary(response?.data?.rtoSummary)
            }
        })
        .catch((error) => {
            if (error?.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            rtoSummaryListGrid?.current?.api?.hideOverlay()
        })
    }

    const calculateTotals = (selectedLabel, records) => {

        let total = 0
        let updateColumn = 'balance'

        records?.map((record) => {
            switch(selectedLabel) {
                case 'currentPastEntriesWithBalance':
                case 'currrentAndFutureEntries':
                case 'dueFrom1MonthsEntries':
                case 'dueFrom2MonthsEntries':
                case 'dueFrom3MonthsEntries':
                case 'dueBefore3MonthsEntries':
                    total = addFloatingNumbers([record.balance, total], 2)
                    updateColumn = 'balance'
                    break;
                case 'showCredits':
                case 'entryPayments':
                case 'nonTransferableCredits':
                case 'showPayments':
                case 'checkPayments':
                case 'accountRegisters':
                case 'prizeMoneyCredits':
                case 'balanceRefundCredits':
                case 'entryPayments':
                    total = addFloatingNumbers([record.amount, total], 2)
                    updateColumn = 'amount'
                    break;
                case 'currrentAndFutureEntriesTotalFees':
                    total = addFloatingNumbers([record.total_fees, total], 2)
                    updateColumn = 'total_fees'
                    break;
                case 'currrentAndFutureEntriesTotalPaid':
                    total = addFloatingNumbers([record.total_paid, total], 2)
                    updateColumn = 'total_paid'
                    break;
            }
        })

        if(updateColumn === 'balance'){
            rtoSummaryListGrid?.current?.api?.setPinnedBottomRowData([{
                balance: total
            }])
        }else if(updateColumn === 'amount'){
            rtoSummaryListGrid?.current?.api?.setPinnedBottomRowData([{
                amount: total
            }])
        }else if(updateColumn === 'total_fees'){
            rtoSummaryListGrid?.current?.api?.setPinnedBottomRowData([{
                total_fees: total
            }])
        }else if(updateColumn === 'total_paid'){
            rtoSummaryListGrid?.current?.api?.setPinnedBottomRowData([{
                total_paid: total
            }])
        }
        
    }

    const handleRowDoubleClicked = (row_data) => {
        if(row_data.data.entry_id){
            window.open(process.env.PUBLIC_URL+'/entries/detail/'+row_data.data.entry_id+`?customer_id=${customerID}&selectedTab=3`,'_blank')
        }else if(row_data.data.people_id){
            window.open(process.env.PUBLIC_URL+'/people/detail/'+row_data.data.people_id+`?customer_id=${customerID}&selectedTab=2`,'_blank')
        }
    }

    // Print reports based on preferences, RTO reports take RTO Ids, and invocie report sends in entry ids as record_ids and is called from printinvoice function
    const printReports = async (event, prefName) => {
        // Get ids of RTOs from grid
        loadingSpinnerBtnWait(event)

        let recordIds = []
        if (prefName == 'PMRStatementReport') {
            recordIds = peopleData?.filter((people) => people.include).map((person) => person.people_id)
        }

        loadingOverlay({ show: true, message: `Loading Report...` })

        if (recordIds.length == 0) {   //  safety check
            loadingSpinnerBtnRelease(event);
            loadingOverlay({ show: false })
            return false;
        }

        await axios.post(process.env.REACT_APP_NEST_API_URL + '/reports/getPrefReportForPrinting', {
            pref_name: prefName,
            customer_id: customerID,
            show_id: selectedShow, 
            params: {ids: recordIds}
        })
            .then(async (response) => {
                if (response?.data?.success) {
                    const report = response?.data?.report;
                    report.report_definition = JSON.parse(report?.report_definition);

                    // Load report to ARJS and the print
                    await loadReportsAndPrint(report);
                } else {
                    alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS..ALERT.REPORTNOTFOUND' }) })
                }
                loadingOverlay({show: false})
            })
            .catch((error) => {
                loadingOverlay({show: false})
            })
        
        loadingSpinnerBtnRelease(event);
    }

    return (
        <>
            <div className='form-group'>
                <div className='row'>
                    <div className='col-9'>
                        <ShowSection tabName={'CREATEBATCH'} />

                        {/* Payment Type */}
                        <div className='row mb-2 col-lg-8'>
                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 label-width-130 pe-0' htmlFor='paymentType'>{intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.PAYMENT.TYPE.RADIO.LABEL' })}</label>
                            <div className='col min-w-250px mw-450px'>
                                <Select 
                                    options={prizePreferences}
                                    id="paymentType"
                                    value={prizePreferences?.find((option) => option.value == paymentType ?? '')}
                                    onChange={(e) => {
                                        setPaymentType(e.value)
                                        loadRTOForCreatePaymentBatch(batchType, e.value)
                                    }}
                                    styles={reactSelectStyles}
                                />
                            </div>
                        </div>
                        
                        <div className='row mb-2 col-lg-9'>
                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 label-width-130 pe-0' htmlFor='DATE' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.LABEL.DATE">{intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.LABEL.DATE' })}</label>
                            <div className='col-lg-5 mw-250px'>
                                <div className="input-group date">
                                    <Flatpickr
                                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                                        id="paymentBatchDate"
                                        placeholder="MM/DD/YYYY"
                                        value={paymentDate && paymentDate !== '00-00-00'
                                            ? moment(paymentDate).format('MM/DD/YYYY')
                                            : null}
                                        options={date_options}
                                        autoComplete="off"
                                        onClose={(value, dateStr, instance) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();
                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }
                                            // Set the date with formatting logic
                                            setDate(dateStr, (formattedDate) => {
                                                setPaymentDate(formattedDate);
                                            }, '00-00-00');
                                        }}
                                        onChange={(value, dateStr, instance) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();
                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }
                                            // Set the date with formatting logic
                                            setDate(dateStr, (formattedDate) => {
                                                setPaymentDate(formattedDate);
                                            }, '00-00-00');
                                        }}
                                        onInput={(e) => { maskDateInput(e); }}
                                        tabIndex={2}
                                    />
                                    <div className="input-group-append">
                                        <label htmlFor='paymentBatchDate' className='date-label'
                                            onClick={(e) => {
                                                e.stopPropagation(); 
                                                document.getElementById('paymentBatchDate')._flatpickr.open(); 
                                            }}
                                        >
                                            <span className="input-group-text">
                                                <i className="la la-calendar"></i>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 label-width-130 pe-0' htmlFor='DESCRIPTION' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.LABEL.DESCRIPTION"> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.LABEL.DESCRIPTION' })}</label>
                            <div className='col'>
                                <textarea
                                    rows={3}
                                    className='form-control form-control-sm mb-2 mb-lg-0 fs-6 py-1'
                                    value={paymentDesc}
                                    onChange={(e) => setPaymentDesc(e.target.value)}
                                ></textarea>
                            </div>
                        </div>

                        {/* Radio Button */}
                        <div className='row mb-2 col-lg-12'>
                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 label-width-130 pe-0' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.LABEL.BATCHTYPE">{intl.formatMessage({ id: `FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.RADIO.LABEL` })}</label>
                            <div className='col-lg-3 form-check-sm form-check-custom px-3 mw-200px'>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    id='prizeMoneyBatch'
                                    checked={batchType === 'Prize Money'}
                                    onChange={(e) => {
                                        setBatchType('Prize Money')
                                        loadRTOForCreatePaymentBatch('Prize Money', paymentType)
                                    }}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='prizeMoneyBatch' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.LABEL.PRIZEMONEYONLY"> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.RADIO.LABEL.PRIZEMONEYONLY' })}</label>
                            </div> 
                            <div className='col-lg-3 form-check-sm form-check-custom px-3 mw-200px'>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    id='balanceRefundBatch'
                                    checked={batchType === 'Balance Refund'}
                                    onChange={(e) => {
                                        setBatchType('Balance Refund')
                                        loadRTOForCreatePaymentBatch('Balance Refund', paymentType)
                                    }}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='balanceRefundBatch' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.LABEL.BALANCEREFUNDONLY"> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.RADIO.LABEL.BALANCEREFUNDONLY' })}</label>
                            </div> 
                            <div className='col-lg-3 form-check-sm form-check-custom px-3 mw-100px'>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    id='bothBatch'
                                    checked={batchType === 'Both'}
                                    onChange={(e) => {
                                        setBatchType('Both')
                                        loadRTOForCreatePaymentBatch('Both', paymentType)
                                    }}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='bothBatch' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.LABEL.BOTH"> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.RADIO.LABEL.BOTH' })}</label>
                            </div>  
                            <div className='col-lg-2 form-check-sm form-check-custom px-3'>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    id='allBatch'
                                    checked={batchType === 'All'}
                                    onChange={(e) => {
                                        setBatchType('All')
                                        loadRTOForCreatePaymentBatch('All', paymentType)
                                    }}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='allBatch' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.LABEL.ALL"> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.RADIO.LABEL.ALL' })}</label>
                            </div>                    
                        </div>
                        {/* Radio Button */}


                        <div className='row d-flex align-items-center mb-0'>
                            <CreateBatchGrid peopleData={peopleData} setPeopleData={setPeopleData} displayLoadingOverlay={displayLoadingOverlay} selectAllRTO={selectAllRTO} setSelectAllRTO={setSelectAllRTO} getAgingAndRTOSummaryDetails={getAgingAndRTOSummaryDetails} batchType={batchType}/>
                        </div>
                    </div>
                    <div className='col-3 mt-4'>
                        <div>
                            {/* Aging Information */}
                            <fieldset>
                                <legend className="fs-7 fw-bold d-flex  mb-1">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING' })} <b></b></legend>
                                <div className='row d-flex align-items-center mb-1'>
                                    <div className='col-7 d-flex flex-column'>
                                        <label className='fs-7 pe-0 rto-summary' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.AGING.LABEL.PENDINGBALANCE" onClick={() => setSelectedAging('currrentAndFutureEntries')}>{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.PENDINGBALANCE' })}</label>
                                        <label className='fs-7 pe-0 text-danger pl-5 rto-summary' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.AGING.LABEL.CHARGES" onClick={() => setSelectedAging('currrentAndFutureEntriesTotalFees')}>{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.CHARGES' })}</label>
                                        <label className='fs-7 pe-0 text-success pl-5 rto-summary' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.AGING.LABEL.PAYMENTS" onClick={() => setSelectedAging('currrentAndFutureEntriesTotalPaid')}>{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.PAYMENTS' })}</label>
                                        <label className='fs-7 pe-0 rto-summary' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.AGING.LABEL.1-30DAYS" onClick={() => setSelectedAging('dueFrom1MonthsEntries')}>1-30 {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.DAYS' })}</label>
                                        <label className='fs-7 pe-0 rto-summary' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.AGING.LABEL.30-60DAYS" onClick={() => setSelectedAging('dueFrom2MonthsEntries')}>30-60 {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.DAYS' })}</label>
                                        <label className='fs-7 pe-0 rto-summary' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.AGING.LABEL.60-95DAYS" onClick={() => setSelectedAging('dueFrom3MonthsEntries')}>60-90 {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.DAYS' })}</label>
                                        <label className='fs-7 pe-0 rto-summary' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.AGING.LABEL.95+DAYS" onClick={() => setSelectedAging('dueBefore3MonthsEntries')}>90+ {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.DAYS' })}</label>
                                    </div>
                                    <div className='col-5 d-flex flex-column ms-auto'>
                                        <label className='fs-7 text-end me-2 rto-summary text-decoration-underline' onClick={() => setSelectedAging('currrentAndFutureEntries')}>{ currencyFormatter(agingInformation?.pendingBalance)}</label>
                                        <label className='fs-7 text-danger text-end me-8 rto-summary text-decoration-underline' onClick={() => setSelectedAging('currrentAndFutureEntriesTotalFees')}>{currencyFormatter(agingInformation?.currentCharges)}</label>
                                        <label className='fs-7 text-success text-end me-8 rto-summary text-decoration-underline' onClick={() => setSelectedAging('currrentAndFutureEntriesTotalPaid')}>{currencyFormatter(agingInformation?.currentPayment)}</label>
                                        <label className='fs-7 text-end me-2 rto-summary text-decoration-underline' onClick={() => setSelectedAging('dueFrom1MonthsEntries')}>{currencyFormatter(agingInformation?.dueFrom1Months)}</label>
                                        <label className='fs-7 text-end me-2 rto-summary text-decoration-underline' onClick={() => setSelectedAging('dueFrom2MonthsEntries')}>{currencyFormatter(agingInformation?.dueFrom2Months)}</label>
                                        <label className='fs-7 text-end me-2 rto-summary text-decoration-underline' onClick={() => setSelectedAging('dueFrom3MonthsEntries')}>{currencyFormatter(agingInformation?.dueFrom3Months)}</label>
                                        <label className='fs-7 text-end me-2 rto-summary text-decoration-underline' onClick={() => setSelectedAging('dueBefore3MonthsEntries')}>{currencyFormatter(agingInformation?.dueBefore3Months)}</label>
                                    </div>
                                </div>
                            </fieldset>

                            {/* RTO Summary */}
                            <fieldset>
                                <legend className="fs-7 fw-bold d-flex mb-1">
                                    {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.RTO.SUMMARY' })}
                                    <b></b>
                                </legend>

                                <div className='row d-flex align-items-center'>
                                    <div className='col-12'>

                                        {/* Prize Money */}
                                        <div className='d-flex justify-content-between w-100' onClick={() => setSelectedRTOSummary('prizeMoneyCredits')}>
                                            <label className={batchType === 'Prize Money' ? 'fw-bold fs-7 rto-summary' : 'fs-7 rto-summary'}>
                                                {intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.PRIZEMONEY.CREDIT' })}
                                            </label>
                                            <span className={batchType === 'Prize Money' ? 'fw-bold fs-7 text-end me-2 rto-summary text-decoration-underline' : 'fs-7 text-end me-2 rto-summary text-decoration-underline'}>{currencyFormatter(Math.abs(rtoSummary?.prizeMoneyEarned))}</span>
                                        </div>
                                        
                                        {/* Balance Refund */}
                                        <div className='d-flex justify-content-between w-100' onClick={() => setSelectedRTOSummary('balanceRefundCredits')}>
                                            <label className={batchType === 'Balance Refund' ? 'fw-bold fs-7 rto-summary' : 'fs-7 rto-summary'}>
                                                {intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.BALANCEREFUND.CREDIT' })}
                                            </label>
                                            <span className={batchType === 'Balance Refund' ? 'fw-bold fs-7 text-end me-2 rto-summary text-decoration-underline' : 'fs-7 text-end me-2 rto-summary text-decoration-underline'}>{currencyFormatter(Math.abs(rtoSummary?.balanceRefundEarned))}</span>
                                        </div>

                                        {/* Total */}
                                        <div className='d-flex justify-content-between w-100' onClick={() => setSelectedRTOSummary('showCredits')}>
                                            <label className={batchType === 'Both' ? 'fw-bold fs-7 rto-summary' : 'fs-7 rto-summary'}>
                                                {intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.TOTAL' })}
                                            </label>
                                            <span className={batchType === 'Both' ? 'fw-bold fs-7 text-end me-2 rto-summary text-decoration-underline' : 'fs-7 text-end me-2 rto-summary text-decoration-underline'}>{currencyFormatter(Math.abs(rtoSummary?.showCreditEarned))}</span>
                                        </div>

                                        {/* Adjustments Separator*/}
                                        <label className='fs-7 rto-summary separator-heading mt-1'>
                                            {intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.ADJUSTMENTS' })}
                                        </label>

                                        {/* Non Transferable Credit */}
                                        <div className='d-flex justify-content-between w-100' onClick={() => setSelectedRTOSummary('nonTransferableCredits')}>
                                            <div className='row'>
                                                <label className='fs-7 rto-summary'>
                                                    {intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.NON.TRANSFERABLE.CREDIT' })}
                                                </label>
                                                {
                                                    rtoSummary?.nonTransferableCreditReasons?.length > 0 && 
                                                    <span className='fs-8 text-danger rto-summary pl-5'>
                                                        {rtoSummary?.nonTransferableCreditReasons?.join(', ')}
                                                    </span>
                                                }
                                            </div>
                                            <span className={rtoSummary?.nonTransferableCredit < 0 ? 'fs-7 text-end me-2 text-danger rto-summary text-decoration-underline' : 'fs-7 text-end me-2 rto-summary text-decoration-underline'}>
                                                {`${currencyFormatter(Math.abs(rtoSummary?.nonTransferableCredit))}`}
                                            </span>
                                        </div>

                                        {/* Show Payments */}
                                        <div className='d-flex justify-content-between w-100' onClick={() => setSelectedRTOSummary('showPayments')}>
                                            <label className='fs-7 rto-summary'>
                                                {intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.TOTAL.PAYMENTS' })}
                                            </label>
                                            <span className='fs-7 text-end me-2 rto-summary text-decoration-underline'>{currencyFormatter(rtoSummary?.showPayments)}</span>
                                        </div>

                                        {/* Check Payments */}
                                        <div className='d-flex justify-content-between w-100 pl-5' onClick={() => setSelectedRTOSummary('checkPayments')}>
                                            <label className='fs-7 rto-summary'>
                                                {intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.CHECK.PAYMENTS' })}
                                            </label>
                                            <span className='fs-7 text-end me-2 rto-summary text-decoration-underline me-8'>{currencyFormatter(rtoSummary?.checkPayments)}</span>
                                        </div>

                                        {/* Entry Payments */}
                                        <div className='d-flex justify-content-between w-100 pl-5' onClick={() => setSelectedRTOSummary('entryPayments')}>
                                            <label className='fs-7 rto-summary'>
                                                {intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.ENTRY.PAYMENTS' })}
                                            </label>
                                            <span className='fs-7 text-end me-2 rto-summary text-decoration-underline me-8'>{currencyFormatter(rtoSummary?.entryPayments)}</span>
                                        </div>

                                        <div className='row d-flex align-items-center border-bottom-black mx-0 mt-1'></div>

                                        {/* Remaining Credit */}
                                        <div className='d-flex fw-bold justify-content-between w-100'>
                                            <div className='row'>
                                                <label className='fs-7 rto-summary'>
                                                    {intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.REMAINING.CREDIT' })}
                                                </label>
                                            </div>
                                            <span className='fs-7 text-end me-2 rto-summary'>
                                                {`${currencyFormatter(Math.abs(rtoSummary?.showAvailableCredit))}`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset> 

                            <div className='row d-flex align-items-center my-1'>
                                {/* Account Register Balance */}
                                <div className='d-flex justify-content-between w-100' onClick={() => setSelectedRTOSummary('accountRegisters')}>
                                    <label className='fs-7 rto-summary'>
                                        {intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.ACCOUNT.BALANCE.EXCLUDE' })}
                                    </label>

                                    <span className={rtoSummary?.arBalance > 0 ? 'fs-7 text-end me-2 text-danger rto-summary text-decoration-underline' : 'fs-7 text-end me-2 rto-summary text-decoration-underline'}>{currencyFormatter(rtoSummary?.arBalance)}</span>
                                </div>

                                {/* Past and Current Entry Balance */}
                                <div className='d-flex justify-content-between w-100' onClick={() => setSelectedRTOSummary('currentPastEntriesWithBalance')}>
                                    <label className='fs-7 rto-summary'>
                                        {intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.PAST.ENTRIES.BALANCE' })}
                                        {
                                            selectedShowEndDate && 
                                            <span style={{fontStyle: 'italic', fontSize: '0.9rem'}}>
                                                ({intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.ACCOUNT.BALANCE.AS.OF' })} {selectedShowEndDate})
                                            </span>
                                        }
                                    </label>
                                    <span className='fs-7 text-end me-2 rto-summary text-decoration-underline'>{currencyFormatter(rtoSummary?.currentPastEntriesBalance)}</span>
                                </div>   
                            </div>

                            {/* Payable Balance */}
                            <div className='row d-flex align-items-center border-bottom-black mx-0'></div>
                            <div className='row d-flex align-items-center mb-2'>
                                <div className='col-7 d-flex flex-column'>
                                    <label className='fw-bold fs-7 pe-0'>{intl.formatMessage({ id: 'PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.SECTION.RTOSUMMARY.LABEL.PAYABLE.CREDIT' })}</label>
                                </div>
                                <div className='col-5 d-flex flex-column ms-auto'>
                                    <label className='fw-bold fs-7 text-end me-2 '>{ currencyFormatter(Math.abs(rtoSummary?.batchTypePayableCredit))}</label>
                                </div>
                            </div>

                            {/* Past and current entries list */}
                            <fieldset>
                                <legend className="fs-7 fw-bold d-flex">{selectedAging ? getRtoDetailGridLabel(selectedAging) : getRtoDetailGridLabel(selectedRTOSummary)} <b></b></legend>
                                <div style={rtoSummaryListContainerStyle}>
                                    <div style={gridStyle} className="ag-theme-alpine payment-batch">
                                        <AgGridReact
                                        ref={rtoSummaryListGrid}
                                        defaultColDef={defaultColDef}
                                        columnDefs={columnDefs}
                                        rowData={rowData}
                                        rowHeight={20}
                                        headerHeight={35}
                                        onRowDoubleClicked={(row_data) => handleRowDoubleClicked(row_data)}
                                        getRowStyle={params => getRowStyle(params)}
                                        overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while RTO Information is loading...</span>'}
                                        ></AgGridReact>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                {/* Footer */}
                <div className='card-footer py-4 px-0'>
                    <div className='row m-0 p-0'>
                        <div className='col m-0 p-0 d-flex justify-content-start'>
                            <button 
                                type="button" 
                                className='btn btn-sm btn-secondary fw-bold text-uppercase' 
                                onClick={(e) => printReports(e, 'PMRStatementReport')}
                                autoFocus
                            >
                                <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                {intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.BUTTON.PMRSTATEMENT" })}
                            </button>
                        </div>

                        <div className='col m-0 p-0 d-flex justify-content-end'>
                            <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={closePaymentBatchDetail} data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.BUTTON.CLOSE">
                                {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.BUTTON.CLOSE' })}
                            </button>

                            <button 
                                type="button" 
                                className='btn btn-sm btn-secondary fw-bold text-uppercase' 
                                onClick={(e) => createPaymentBatch(e)}
                                autoFocus
                                data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.CREATEBATCH.BUTTON.CREATEPAYMENTBATCH"
                            >
                                <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                {intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.BUTTON.CREATEPAYMENTBATCH" })}
                            </button>
                        </div>
                    </div>
                </div>
                {/* Footer */}
            </div>
        </>
    );
}

export { CreateBatchTab }