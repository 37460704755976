import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAnnouncerToolContext } from './AnnouncerToolContext';
import {useIntl} from 'react-intl'
import Split from 'react-split'
import UnorderedOrderOfGoGrid from './UnorderedOrderOfGoGrid';
import OrderedOrderOfGoGrid from './OrderedOrderOfGoGrid';
import moment from 'moment';

const OrderOfGoTab = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { selectedDate, selectedRing, selectedClassGroup, currentClassGroup } = useAnnouncerToolContext()
  const [oogStatus, setOOGStatus] = useState('')

  useEffect(() => {
    let status = ''
    if(currentClassGroup){
      let classObj = currentClassGroup?.ClassGroupxclasses[0]?.Class;
      if(currentClassGroup.check_in){
        status = intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.ORDEROFGO.MESSAGE.CHECKIN'})
      }
      else if(classObj?.order_of_go_set){
        status = "Order of Go Set at " + moment(classObj.order_set_date + ' ' + classObj.order_set_time).format('MM/DD/YY h:mm A');
        if(currentClassGroup.web_order_user_name != ''){
          status += ` by ${currentClassGroup.web_order_user_name}`;
        }
        if(currentClassGroup.order_tool != ''){
          status += ` using ${currentClassGroup.order_tool} tool`;
        }
        status += '.'
      }
    }
    setOOGStatus(status)
  }, [currentClassGroup]);
  
  //Horizontal Split Size
  var splitSizes = localStorage.getItem('split-sizes-order-of-go')

  if (splitSizes) {
    splitSizes = JSON.parse(splitSizes)
  } else {
    splitSizes = [40, 60]
  }

  return (
    <>
      <Split
            className="split-horizontal split-full pt-1 pb-2"
            minSize={[350, 600]}
            maxSize={[Infinity, Infinity]}
            gutterSize={5}
            snapOffset={0}
            sizes={[splitSizes[0], splitSizes[1]]}
            onDragEnd={(splitSizes) => {localStorage.setItem('split-sizes-order-of-go', JSON.stringify(splitSizes))}}
        >
            
            <div className='unordered-trips pe-2'>
              <UnorderedOrderOfGoGrid></UnorderedOrderOfGoGrid>
            </div>

            <div className='ordered-trips ps-2'>
              <OrderedOrderOfGoGrid></OrderedOrderOfGoGrid>
            </div>
      </Split>
      <div className='row d-flex justify-content-start px-3 mb-4'>
        { (oogStatus != '') && 
          <label className='col-form-label fs-6 mb-2 pe-0 py-1 red'>{ oogStatus }</label>
        } 
        <button 
            type='button'
            className="btn btn-sm btn-dark fw-bold h-30px py-1 w-150px align-items-center d-flex justify-content-center" 
            onClick={() => navigate('/classes/order-of-go?date='+selectedDate+'&ring='+selectedRing+'&cgroup='+selectedClassGroup)}
            data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.ORDEROFGO.BUTTON.SETORDEROFGO"
        >
        {intl.formatMessage({id: 'ANNOUNCER.GRID.ORDEREDTRIPS.BUTTONSAVEORDER'})} </button>
      </div>
    </>
  );
};

export {OrderOfGoTab};
  