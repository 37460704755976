import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import axios from 'axios'
import { useIntl } from 'react-intl'
import useFileDownloader from '../../../modules/hooks/use-file-downloader';
import { downloadTextFile } from '../../../modules/sgl-utils/SglFunctions';
import useGenerateExport from '../../../modules/hooks/use-generate-export';

const useEntriesExportEmails = () => {
    const intl = useIntl()
    const loadingOverlay = useLoadingOverlay()
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const { getOutputSelectionAreaIDs } = useOutputContext();
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const generateExport = useGenerateExport()

    const exportEmails = async () => {
        loadingOverlay({ show: true })

        let entryIds = await getOutputSelectionAreaIDs(false)
        if (entryIds.length === 0) {
            alertDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTEMAILS.SELECTENTRIES" } )})
            loadingOverlay({ show: false })
        }
        else{
            // if user selected any entries
            loadingOverlay({ show: false })
            // ask user for confirmation
            confirmDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTEMAILS.CONFIRMSELECTION"})+ entryIds.length + " " + intl.formatMessage({ id: "MENU.ENTRIES"}) + " " + intl.formatMessage({ id: "MENU.PEOPLE"}) + "?" })
            .then((choice) => {
                if(choice){
                    const settings = {
                        url: 'entries/exportEmails',
                        progressBarTitle: 'Preparing Data for Export',
                        customer_id: customerId,
                        entry_ids: entryIds,
                        showId: currentShowID,
                    }

                    generateExport(settings)
                    /* 
                    loadingOverlay({ show: true })
                    
                    axios.post( process.env.REACT_APP_NEST_API_URL + "/entries/exportEmails", {
                        customer_id: customerId,
                        entry_ids: entryIds,
                        show_id: currentShowID,
                    })
                    .then((res) => {
                        if(res.data.success){
                            downloadTextFile(res.data.emailTextData, res.data.filename)
                        }
                        else{
                            alertDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.EXPORTEMAILS.NORTOFOUND" })})
                        }
                     })
                    .catch((e) => {
                    })
                    .finally( () => {
                        loadingOverlay({ show: false })
                    })
                    */
                }
            })
        }
    }

    return exportEmails
}

export default useEntriesExportEmails;