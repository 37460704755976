import { useMemo, useState, useCallback, useRef, useEffect } from 'react'
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { useFormContext } from "react-hook-form";
import  { useCurrencyFormatter } from '../../../modules/sgl-utils/Formatters';
import { useAlert } from '../../../modules/sgl-utils/DialogsProvider';
import { useAppSelector } from '../../../redux/hooks';
import { useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import { useLoadReportAndPrint } from '../../reports/ReportTypeModal';
import axios from 'axios';
import { updateAGGridWithoutFlickering } from '../../../modules/sgl-utils/agGridHelpers';
import { addFloatingNumbers } from '../../../modules/sgl-utils/SglFunctions';

const GeneralTab = (props) => {
    const intl = useIntl();
    const currencyFormatter = useCurrencyFormatter()
    const methods = useFormContext();
    const [gridRef, setGridRef] = useState()
    const grid_ref = useRef();
    const loadingOverlay = useLoadingOverlay()
    const alertDialog = useAlert()
    const {loadReportsAndPrint, loadSinglePageReportsandPrint} = useLoadReportAndPrint();
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id)

    const paymentxEntriesColumnDefs = [
        { field: 'sgl_id',flex: 1, hide:true, headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.TAB.GENERAL.SGLID' }) },
        { field: 'entry_number',flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.TAB.GENERAL.NUMBER' }) },
        { field: 'horse', flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.TAB.GENERAL.HORSE' }) },
        { field: 'show_name', flex: 2, headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.TAB.GENERAL.SHOW' })},
        { 
            field: 'amount_applied',
            flex: 1,
            cellStyle: { textAlign: "center" }, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.TAB.GENERAL.AMOUNT' }),  
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: 'center' }
        },
        { field: 'entry_balance',flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.TAB.GENERAL.ENTRYBALANCE' }), cellRenderer: params => currencyFormatter(params.value), cellStyle: { textAlign: 'center' }},
        { field: 'isUpdated', hide:true, value: null}
    ]

    const paymentxEntriesRowData = useRef([]);
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
   
    useEffect(() => {
        
        if(gridRef){
            paymentxEntriesRowData.current = methods.watch("paymentxentries");
            // gridRef.setRowData(paymentxEntriesRowData.current);
            updateAGGridWithoutFlickering(({ current: { api : gridRef}}), paymentxEntriesRowData.current) // update grid without rerendering
        }
      }, [methods]);


    const onEditClick = () => {
        const selectedNodes = gridRef.getSelectedNodes();
        if (selectedNodes.length > 0) {
          const rowIndex = selectedNodes[0].rowIndex;
          const columnId = 'amount_applied';
          gridRef.startEditingCell({
            rowIndex,
            colKey: columnId,
          });
        }
      };

    function getTotalAmount(){
        let paymentxentries = methods.watch('paymentxentries')
        let total_amount = paymentxentries.map(pxe => parseFloat(pxe.amount_applied)).reduce(function (total, num) { return addFloatingNumbers([total, num], 2) }, 0);
        return total_amount
    }

    function getTotalEntryBalance(){
        let paymentxentries = methods.watch('paymentxentries')
        let total_entry_balance = paymentxentries.map(pxe => parseFloat(pxe.entry_balance)).reduce(function (total, num) { return addFloatingNumbers([total, num], 2) }, 0);
        return total_entry_balance
    }
    
    function onCellValueChanged(params) {
        
        let difference = params.newValue - params.oldValue
        let updated_data = params.node.data
        
        let updated_paymentxEntries_row_data = methods.getValues("paymentxentries")
        let found_sgl_id =  paymentxEntriesRowData.current.some(el => el.sgl_id === updated_data.sgl_id);
        if(found_sgl_id){

            let objIndex = paymentxEntriesRowData.current.findIndex((obj => obj.sgl_id == updated_data.sgl_id));
            
            updated_paymentxEntries_row_data[objIndex].amount_applied = updated_data.amount_applied;
            updated_paymentxEntries_row_data[objIndex].entry_balance = updated_paymentxEntries_row_data[objIndex].entry_balance  - difference
            updated_paymentxEntries_row_data[objIndex].isUpdated = true

            if (updated_paymentxEntries_row_data){
                paymentxEntriesRowData.current = updated_paymentxEntries_row_data
                methods.setValue("paymentxentries", paymentxEntriesRowData.current , {shouldDirty: true})
                methods.setValue('payment.amount', getTotalAmount(), {shouldDirty: true})
            }

        }
    }

    // Print invoice report, this wil get pref value for invoices and then it will give report definition which will be used for printing
    const printInvoiceReport = async () => {
        if (paymentxEntriesRowData.current && paymentxEntriesRowData.current.length > 0) {

        loadingOverlay({ show: true, message: `Loading Report...` })
        const record_ids = paymentxEntriesRowData.current.map(record => record.entry_id);

        if (record_ids?.length == 0) {   //  safety check
            loadingOverlay({ show: false })
            return false;
        }

        await axios.post(process.env.REACT_APP_NEST_API_URL + '/reports/getPrefReportForPrinting', {
            pref_name: 'Default_Invoice',
            customer_id: customer_id,
            show_id: currentShowID, 
            params: {ids: record_ids}
        })
            .then(async (response) => {
                if (response?.data?.success) {
                    const report = response?.data?.report;
                    report.report_definition = JSON.parse(report?.report_definition);

                    // Load report to ARJS and the print
                    await loadReportsAndPrint(report);
                }
                loadingOverlay({show: false})
            })
            .catch((error) => {
                loadingOverlay({show: false})
            })
        }
        else{
            alertDialog({ message: "No entries available to print statement", title: "alert" })
        }
    }

    useEffect(() => {
        if(gridRef && paymentxEntriesRowData?.current){
            //recalculate the totals
            gridRef.setPinnedBottomRowData([{
                amount_applied: getTotalAmount(),
                entry_balance: getTotalEntryBalance()
            }])
        }
    }, [gridRef, paymentxEntriesRowData.current, methods.watch('paymentxentries')])

    const getRowStyle = (params) => {
        if (params.node.rowPinned) {
            return { fontWeight: "bold" };
        }
    };
    
    return (
        <>
            <div className='form-group'>
                <div className='row mb-2 pe-4'>
                    {/* <label className='col col-form-label fs-5 py-1 text-end'>{paymentxEntriesRowData.length}</label> */}
                    <label className='col-lg-1 w-50px p-0'></label>
                </div>
                <div className='row mb-2 pe-4'>
                    <div className='col'>
                        <OutputListingGridStatic 
                            area ={"Payments - Payment"} 
                            selection={'multiple'}
                            ref={grid_ref}
                            onRowDoubleClicked={(row_data) => {props.callbackFromParent(row_data.entry_id, "Entries", "EntryDetail", parseFloat(row_data.amount_applied), row_data.sgl_id)}} 
                            columnDefs={paymentxEntriesColumnDefs} 
                            rowData={paymentxEntriesRowData.current} 
                            containerStyle={containerStyle} 
                            setGridRef = {setGridRef}
                            getRowStyle={params => getRowStyle(params)}
                        ></OutputListingGridStatic>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-8'>
                        <button onClick={printInvoiceReport} type='button' className="m-2 btn btn-sm btn-secondary w-150px fw-bold px-2 py-2 col-2 text-uppercase" data-tooltip-id="PAYMENTS.DETAIL.TAB.GENERAL.BUTTON.PRINTSTATEMENT">
                            { intl.formatMessage({ id: 'FORM.INPUT.PAYMENTS.TAB.GENERAL.PRINTSTATEMENT' }) }
                        </button>
                    </div>
                    {/* <div className='m-2 d-flex col-3'>
                        <label className='fs-5 col-4 text-end'>{currencyFormatter (getTotalAmount())}</label>
                        <label className='fs-5 col-8 text-end'>{currencyFormatter (getTotalEntryBalance())}</label>
                    </div> */}
                </div>
            </div>
        </>
    );
}

export { GeneralTab }