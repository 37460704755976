import { useEffect, useState, useCallback, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import axios from 'axios'
import { useAlert, useConfirm, useLoadingOverlay, useProgress, usePersistentLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider'
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait, logSiteActivity } from '../../modules/sgl-utils/SglFunctions'
import { useAppSelector } from '../../redux/hooks'
import { useOutputContext } from '../../modules/output-listing/OutputListingContext'
import { Core, PdfExport, TabularDataExport } from "@grapecity/activereports";
import { PDFDocument } from "pdf-lib";

// Metronic Components
import { KTSVG } from '../../../_metronic/helpers'
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
// Assets
import "flatpickr/dist/themes/material_blue.css";
import { useDateRange } from '../../modules/sgl-utils/DialogsProvider'
import { useDatePicker } from '../../modules/sgl-utils/DialogsProvider'
import { useAuth } from '../../modules/auth'
import { getExtraNarrowRowHeight } from '../../modules/sgl-utils/agGridHelpers'
import useKey from '../../modules/hooks/use-key'
import useTypeahead from '../../modules/hooks/use-typeahead'
import { letterKeys, arrowKeys } from '../../modules/hooks/use-key'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const modalsRoot = document.getElementById('root-modals') || document.body



// Report printing functions, can be called from anywhere
export const useLoadReportAndPrint = () => {
    const intl = useIntl();
    const loadingOverlay = useLoadingOverlay();
    const persistentLoadingOverlay = usePersistentLoadingOverlay();
    const { getEventID } = useOutputContext();
    const { cancelFlags, progressBar, resetCancelFlag } = useProgress();
    const alertDialog = useAlert();
    let confirmDialog = useConfirm();
    let customerId = useAppSelector(state => state.showCompany.company_id);
    let currentShowID = useAppSelector(state => state.currentShow.show_id);

    // Ref to track the current value of cancelFlags
    const cancelFlagsRef = useRef(cancelFlags);

    // Effect to update the ref whenever cancelFlags state changes
    useEffect(() => {
        cancelFlagsRef.current = cancelFlags;
    }, [cancelFlags]);

    // Function to load the PDF into the iframe
    const loadPdfIntoIframe = (iframe, documentBlobObjectUrl) => {
        return new Promise((resolve) => {
            iframe.src = documentBlobObjectUrl;
            document.body.appendChild(iframe);
            iframe.onload = () => {
                resolve();
            };
        });
    };

    // Convert pdf to base64 buffer, this is used to convert pdfs generated by ARJS to buffer, so we can copy pages and combine them
    const pdfToBase64 = (pdfBlob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(pdfBlob);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = (error) => reject(error);
        });
    };


    // Load and print reports (Not single page), but which might span to multiple pages
    const loadReportsAndPrint = async (reportDetails, displayedResultIds=null, printType='print') => {
        let isCancelled = false;

        const cancelOperation = () => {
            persistentLoadingOverlay({ show:true, message: `CANCELLING REQUEST`})
            isCancelled = true;
        };
        
        loadingOverlay({ show:true, message: `PROCESSING`, onCancel: cancelOperation})

        if (displayedResultIds && displayedResultIds.length === 0) {
            loadingOverlay({show: false})
            return;
        }
    
        const params = reportDetails.parameters;

        // Check if params has report label or not, if not then add it
        // Useful when we want to print reports other than listings
        for (let param of params) {
            if (param.Name === 'params') {
                let paramValue = JSON.parse(param.Value);
                if (!paramValue.report_label) {
                    let reportLabel = reportDetails.report_label != '' ? reportDetails.report_label : reportDetails.name;
                    paramValue.report_label = reportLabel;
                    param.Value = JSON.stringify(paramValue);
                }
            }
        }

        const report = new Core.PageReport();   // Create a blank report from arjs
        await report.load(reportDetails?.report_definition);    // Load report

        // If no report parameters, then print the report
        if (!params) {
            const reportDocument = await report.run();
    
            // Print
            reportDocument.print(undefined, x=>console.log(`${x} pages ready`), ()=>{
                return false;
            });
            return;
        }
    
        report.reportParameters.applySteps(params);
        let reportDocument = null;

        // Run report
        let printStatus = '';

        try {
            let processEntryFee = false;    // In case of feed delivery reports

            reportDocument = await report.run()

            // For feed delivery reports, get the data and update the status of the entry fees
            // We are not making another backend call to get to know if we had fees in the report
            // We have added a printStatus object in the report data, which is updated by the backend
            // This object is also hidden in the report behind the iterating lists (User wont see it but the object is present in the report because ARJS does not provide data object after running the report)
            // We will extract that object/data variable here and check if report had data or not

            // Get the data tree from ARJS generated report
            let dataTree = reportDocument?.data?.dataTree;

            if (dataTree) {    // Safety check
                // Check if data tree has @ key, if yes then check if it has Printed Correctly or No Data Found
                if ("@" in dataTree) {
                    processEntryFee = true;
                }

                // If data tree has @ key (Main objects are inside @, other are inside report groups or lists), then check if it has Printed Correctly or No Data Found
                if (processEntryFee) {
                    let requiredDataIndex = dataTree['@'];
                    if (requiredDataIndex) {
                        for (let key in requiredDataIndex) {
                            if (requiredDataIndex[key] == 'Printed Correctly') {
                                printStatus = 'Printed Correctly';
                                break;
                            } else if (requiredDataIndex[key] == 'No Data Found') {
                                printStatus = 'No Data Found';
                                break;
                            }
                        }
                    }
                }
            } else {
                printStatus = 'No Data Found';
            }
        } catch (error) {   // Catch error and display alert
            alertDialog({ title: "Report Processing Failed", message: error })
        }

        const pdfSettings = {
            info: {
                title: reportDetails.name,
            },
            autoPrint: printType === 'print' ? true : false
        }

        // For print to disk, which is specifically, export csv.
        const tabularReportSettings = {
            colSeparator: ',',
            outputType: 'plain',
            tableSeparator: '==========',
            quotationSymbol: '"',
            rowSeparator: '\n',
        }

        persistentLoadingOverlay({show: false})
        loadingOverlay({show: false})
        if(isCancelled){
            return;
        }
        // Initialize progress bar
        const eventID = getEventID("print-report");
        progressBar({ show: true, eventID, showProgress: 'none', title: 'PRINTING REPORT', timeElapsed: true, showCancelButton: true });

        // Define the cancel flag
        cancelFlagsRef.current[eventID] = false;
    
        let result = null;
        let csvResult = null;
        if (reportDocument) {
            let isExporting = true;
            try{
                if (printType != 'downloadCsv') { // Case specific to pdf exports
                    result = await PdfExport.exportDocument(reportDocument, pdfSettings, 
                        (pageNumber) => {
                            if (!isExporting) return;
                            //updateProcessingState(pageNumber); // Update progress
                            progressBar({ show: true, eventID, showProgress: 'none', title: 'PRINTING REPORT', timeElapsed: true, showCancelButton: true, clientSideMessage: `Processing Page #${pageNumber}...`});
                        },
                        () => {
                            if (!isExporting) return false;
                            //console.log("cancel", cancelFlagsRef.current[eventID])
                            return cancelFlagsRef.current[eventID]; // Check the cancel flag
                        }
                    );
                } else {    // Case specific to csv exports
                    csvResult = await TabularDataExport.exportDocument(reportDocument, tabularReportSettings,
                        (pageNumber) => {
                            if (!isExporting) return;
                            progressBar({ show: true, eventID, showProgress: 'none', title: 'PRINTING REPORT', timeElapsed: true, showCancelButton: true, clientSideMessage: `Processing Page #${pageNumber}...`});
                        },
                        () => {
                            if (!isExporting) return false;
                            return cancelFlagsRef.current[eventID]; // Check the cancel flag
                        }
                    );
                }
            } catch (error) {
                isExporting = false;
                if (error.message == "Export process was cancelled by user") {
                    resetCancelFlag(eventID); 
                    progressBar({ show: false, eventID });
                    return;
                }
            } finally {
                isExporting = false;
                resetCancelFlag(eventID);
            }
        }
    
        if (!result && printType != 'downloadCsv') {
            progressBar({ show: false, eventID });
            return;
        } else if (!csvResult && printType === 'downloadCsv') {
            progressBar({ show: false, eventID });
            return;
        }
        //loadingOverlay({show: false})

        // Download tabular report
        if (printType === 'downloadCsv') {
            csvResult.download(reportDetails.name);

            // Hide progress bar
            setTimeout(function () {
                progressBar({ show: false, eventID });
                resetCancelFlag(eventID); // Reset the cancel flag
            }, 100);
            return;
        }
        
        // Convert the PDF to a base64 buffer
        // The reason of converting is to get total page count from pdf-lib because pdf export in ARJS does not give count
        const reportArrayBuffer = await pdfToBase64(result.data);
    
        // Load the array buffer into pdf lib document
        const pdfDoc = await PDFDocument.load(reportArrayBuffer);
        const pdfBytes = await pdfDoc.save();
    
        const blob = await new Blob([pdfBytes], { type: 'application/pdf' });
        const documentBlobObjectUrl = URL.createObjectURL(blob);
    
        // Get total page count
        const pageCount = pdfDoc.getPageCount();
    
        // For reports greater than 2k pages, open them in new tab because of memory issues in the browsers
        if ((pageCount && pageCount > 2000) || printType === 'preview') {
            // Open the pdf in new tab without automatically printing
            window.open(documentBlobObjectUrl, '_blank');
        } else {
            // Print the PDF code starts here
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
    
            // Load the PDF into the iframe
            await loadPdfIntoIframe(iframe, documentBlobObjectUrl);
            
            // Wait for the iframe's content to be fully loaded before printing (Safari workaround)
            setTimeout(function(){
                // Print the PDF
                iframe.focus();
                const win = iframe.contentWindow || iframe;
                win.print();
            }, 700 );
        }
        // Print code ends heres

        setTimeout(function () {
            progressBar({ show: false, eventID });
            resetCancelFlag(eventID); // Reset the cancel flag
        }, 700);


        // After the report has been printed, ask user if the report was printed correctly and update the status of the entry fees
        if (printStatus == 'Printed Correctly' && printType === 'print') {
            if (reportDetails.name == "Feed Delivery reportDetails, By Barn" ||
                reportDetails.sgl_id == 111 ||
                reportDetails.name == "Feed Delivery reportDetails, By Item" ||
                reportDetails.sgl_id == 155 || reportDetails.name == "Feed Delivery reportDetails, By Trainer" ||
                reportDetails.sgl_id == 153 || reportDetails.name == "Feed Delivery reportDetails, By Trainer (Landscape)" ||
                reportDetails.sgl_id == 154 ||
                reportDetails.sgl_id == 160 ||
                reportDetails.name == 'Feed Delivery Report, By Category') {      // Show this modal only in print mode (not preview)

                // If the report was printed correctly, then ask user to update the status
                // Not needed when report has no data
                // Show confirm dialogue after 2 seconds
                let choice = false;

                // Add 2 seconds delay because otherwise it will open before print preview dialgue of the browser
                await new Promise(resolve => setTimeout(resolve, 2000));
                choice = await confirmDialog({ message: 'Did the feed report print properly?  OK to mark as delivered now?' })

                // If user clicks update then process the update queue
                if (choice) {
                    loadingOverlay({ show: true, message: 'UPDATING ENTRY FEES' })
                    await axios.post(`${NEST_API_URL}/reports/updateFees`,
                        {
                            reportReference: reportDetails.report_definition.ReportParameters[5].DefaultValue.Values[0],
                            customer_id: customerId,
                            show_id: currentShowID,
                            ids: displayedResultIds,
                            update_delivery: choice
                        }
                    ).then((response) => {
                        loadingOverlay({ show: false })
                    }).catch(error => { console.log(error); loadingOverlay({ show: false }) })
                }
            }
        }
    }

    // Load report to arjs (active report js)
    // Load single page report like entry blanks using this function
    // Print type = print then print directly, if preview then open in new tab
    const loadSinglePageReportsandPrint = async (reportDetails, displayedResultIds=null, printType='print') => {
        let isCancelled = false;

        const cancelOperation = () => {
            persistentLoadingOverlay({ show:true, message: `CANCELLING REQUEST`})
            isCancelled = true;
        };
        
        loadingOverlay({ show:true, message: `PROCESSING`, onCancel: cancelOperation})

        // If no rows
        if (displayedResultIds && displayedResultIds.length === 0) {
            return;
        }

        // Required for ARJS to process report
        // reportDetails.report_definition = JSON.parse(reportDetails.report_definition);

        const params = reportDetails.parameters;    // Get report parameters
        const report = new Core.PageReport();   // Create a blank report from arjs

        await report.load(reportDetails?.report_definition);    // Load report

        // If no report parameters, then print the report as it is because no need to fetch any data
        if (!params) {
            const document = await report.run();

            // Print
            document.print(undefined, x=>console.log(`${x} pages ready`), ()=>{
                return false;
            });
            return;
        }

        // Create a blank pdf document via pdf lib, this is the main report that will be printed at the end
        const mainPdfReport = await PDFDocument.create(); 

        const pdfSettings = {
            info: {
                title: reportDetails.report_name
            }
        }

        // Initialize subreport
        let subreportArray = [];
        let subreportPdfDocument = [];

        // If report has a subreport then load subreport
        if (reportDetails?.subreport?.length > 0) {
            // Loop on subreports, there might be many subreports
            for (let subreportIndex in reportDetails.subreport) {
                // Get subreport definition
                let subreportDefinition = reportDetails?.subreport[subreportIndex]?.report_definition;
                const subreportObj = {
                    report_id: reportDetails.subreport[subreportIndex]?.sgl_id,
                    pageReport: new Core.PageReport()
                }

                subreportArray[subreportIndex] = subreportObj;
                // subreport[subreportIndex].sgl_id = reportDetails.subreport[subreportIndex]?.sgl_id;
                // subreport[subreportIndex].pageReport = await new Core.PageReport();   // Create a blank report from arjs

                if (subreportObj && subreportObj.pageReport && subreportDefinition) {
                    // Load subreport and save it in the subreport arrays to be attached in the main report at the end
                    await subreportArray[subreportIndex]?.pageReport?.load(JSON.parse(subreportDefinition));    // Load report
                }

                if(isCancelled){
                    return;
                }
            }
        }

        if(isCancelled){
            return;
        }
        persistentLoadingOverlay({show: false})
        loadingOverlay({show: false})
        
        // Initialize progress bar
        const eventID = getEventID("print-report") 
        progressBar({ show: true, eventID, showProgress: 'inline', title: intl.formatMessage({id: 'REPORT.PRINT.PRINTING'}) , timeElapsed: true, showCancelButton: true })
        //console.log("event id", eventID)
        // Loop on parameters, reportParameters are calculated at the backend        
        for (let param of params) {

            //console.log("cancel flag array", cancelFlagsRef.current);
            if (cancelFlagsRef.current[eventID]) {
                //console.log("cancel button is clicked")
                resetCancelFlag(eventID); 
                progressBar({ show: false, eventID }); 
                return; // Exit the function if the cancel of progress bar is clicked
            }

            // Update progress bar, send the parameters to backend, then backend will respond will progress
            // Get params object from parameters, and add progress status, params object looks like {ids: [1223]}
            let paramsObject = param.find(obj => obj.Name === "params");
            if (paramsObject) {
                // Get params object which is added from the backed in the form { Name: 'params', Value:  JSON.stringify({ids: id }), Type: "Set"}, 
                paramsObject.Value = {
                    ...JSON.parse(paramsObject.Value),
                    //append progress bar parameters to the report params list
                    current_record: params.indexOf(param) + 1,
                    total_records: params.length,
                    event_id: eventID
                };  
                paramsObject.Value = JSON.stringify(paramsObject.Value);    // Convert back to string to handle on active report js
            }

            // Get a document created from active report js using param in the form of a buffer.
            const pdfDoc = await createReportDocument(param, report, pdfSettings);

            if (!pdfDoc) {
                // End progress bar
                progressBar({ show: false, eventID });
                return;
            }

            // Copy pages to main report
            await copyPdfPages(pdfDoc, mainPdfReport);

            // If we have a subreport of this report, then get its buffer too
            if (subreportArray.length > 0) {
                // Loop on subreports

                for (let subreportIndex in subreportArray) {
                    // For subreport, we update the report_id to subreport_id so that we can process waiver fonts
                    // Find report ID param from param obj
                    const reportIdParam = param.find(obj => obj.Name === "report_id");
                    if (reportIdParam) {
                        reportIdParam.Value = subreportArray[subreportIndex].report_id;
                    } else {
                        // If not found, then add it to the param list
                        param.push({ Name: "report_id", Value: subreportArray[subreportIndex].report_id, Type: "Set" });
                    }

                    // Get pdf buffer of subreport and attach it in the main report
                    subreportPdfDocument[subreportIndex] = await createReportDocument(param, subreportArray[subreportIndex]?.pageReport, pdfSettings);
                    if (subreportPdfDocument[subreportIndex]) {
                        // If we have a subreport, copy its pages to main report as well
                        await copyPdfPages(subreportPdfDocument[subreportIndex], mainPdfReport);
                    } else {
                        // End progress bar
                        progressBar({ show: false, eventID });
                        
                        // End for loop
                        return;
                    }
                }
            } 
            progressBar({ show: true, eventID, showProgress: 'inline', title: intl.formatMessage({id: 'REPORT.PRINT.PRINTING'}) , timeElapsed: true, showCancelButton: true, clientSideProgress: Math.round(((params.indexOf(param) + 1) / params.length) * 100) })
        }
        
        // Save the document
        const joinedPdfBytes = await mainPdfReport.save();

        // Convert to blob object
        const blob = await new Blob([joinedPdfBytes], { type: 'application/pdf' });
        const documentBlobObjectUrl = URL.createObjectURL(blob);

        if (printType === 'preview') {
            window.open(documentBlobObjectUrl, '_blank');   
        } else {
            // Print the PDF code starts here
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';

            // Load the PDF into the iframe
            await loadPdfIntoIframe(iframe, documentBlobObjectUrl);

            // Wait for the iframe's content to be fully loaded before printing (Safari workaround)
            setTimeout(function () {
                // Print the PDF
                iframe.focus();
                const win = iframe.contentWindow || iframe;
                win.print();
            }, 700);
        }

        // Print code ends here

        // Hide progress bar
        setTimeout(function () {
            progressBar({ show: false, eventID });
            resetCancelFlag(eventID); 
        }, 700);
    }

    // This function apply parameters to active report js loaded report
    // And returns a printable pdf buffer object or pdf document
    const createReportDocument = async (paramObj, loadedReportObj, pdfSettings) => {
        // Apply parameters
        loadedReportObj.reportParameters.applySteps(paramObj);

        // Run report
        let document = null;

        try {  
            document = await loadedReportObj.run();
        } catch (error) {
            alertDialog({ title: "Report Processing Failed", message: error })
        }

        if (!document) {
            return false;
        }

        // Get result, returns a blob object
        const reportBlob = await PdfExport.exportDocument(document, pdfSettings);

        // Convert the PDF to a base64 buffer
        const reportArrayBuffer = await pdfToBase64(reportBlob.data);

        // Load the array buffer into pdf lib document
        const pdfDoc = await PDFDocument.load(reportArrayBuffer);

        return pdfDoc;
    }

    // Update export report status
    // const updateProcessingState = async (pageNumber) => {
    //     loadingOverlay({ show:true, message: `Processing Page #${pageNumber}...`})
    // }

    // Copy pdf pages from one pdf document and add them to another
    const copyPdfPages = async (copyFromPdf, copyToPdf) => {
        // Get pages of subreport
        const pageIndices = copyFromPdf.getPageIndices(); 
        // If ceritificate document, then add it to main report as well
        for (let pageIndex of pageIndices) {
            const [page] = await copyToPdf.copyPages(copyFromPdf, [pageIndex]);
            copyToPdf.addPage(page);
        }
    }

    return { loadReportsAndPrint, loadSinglePageReportsandPrint };
}



const ReportType = ({ show, handleClose }) => {
    const intl = useIntl();
    const gridApi = useRef(null);
    const customerId = useAppSelector(state => state.showCompany.company_id);
    const alertDialog = useAlert();
    const [reportList, setReportList] = useState([]);
    const [selectedReport, setSelectedReport] = useState();
    const [displayedResultIds, setDisplayedResultIds] = useState([]);
    const { getOutputSelectionAreaIDs, outputGrid, showVerificationColumns, outputVerificationData } = useOutputContext();
    const loadingOverlay = useLoadingOverlay();
    const persistentLoadingOverlay = usePersistentLoadingOverlay();
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const customerID = useAppSelector(state=> state.showCompany.company_id)
    const [skipSubreport, setSkipSubreport] = useState(
        () => JSON.parse(localStorage.getItem('skipSubreport')) || false
    );
    const containerStyle = useMemo(() => ({ width: '100%', height: '200px'}), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const dateRangeDialog = useDateRange()
    const confirmDialog = useConfirm()
    const user = useAuth();
    const {loadReportsAndPrint, loadSinglePageReportsandPrint} = useLoadReportAndPrint();


    useEffect(() => {
        
        loadingOverlay({ show: true})
        // Fetch reports list
        axios.post(`${NEST_API_URL}/reports/reportList`, {customer_id: customerId, area: outputGrid.area})
        .then((res) => {
            setReportList(prevReportList => [...prevReportList, ...res.data.data]);
        })
        .catch(() => {})

        getOutputSelectionAreaIDs(false)
            .then(res => { 
                    if (res.length === 0) {
                        // Added negative IDs so that our backend can handle it properly and do not break in case of nothing in listing
                        // Because some reports run irrespective of the selection
                        let paramIds = [-999888999888999];
                        setDisplayedResultIds(paramIds);
                        loadingOverlay({ show: false})
                    } else {
                        setDisplayedResultIds(res);
                        loadingOverlay({ show: false})
                    }
                })
                .catch(() => loadingOverlay({ show: false }))
    }, [])

    // Handle submit function
    const handleSubmit = async (event, params = null, printType='print') => {
        // Added request user id here because reportData is called from ARJS where we can not get request user
        let data = {
            'ids': displayedResultIds, 
            'reqUserId': user?.currentUser?.id, 
            report_label: selectedReport?.report_label,
            exportType: printType    // Either print or CSV export, if CSV export, then check if we have csv section, then only include that for export
        }
      
        const selectedNode = gridApi?.current?.getSelectedNodes()[0]
        // If no report type is selected, show alert
        if (selectedNode?.data === undefined) {
            alertDialog({message: intl.formatMessage({id: 'REPORT.PRINT.SELECTREPORTFIRST'})})
            return;
        }
        
        if(params){
            data = {...data, ...params}
        }
        // Pass sorting of column of Ag grid to back end
        if (outputGrid?.columnApi) {
            let sortModel = outputGrid.columnApi.getColumnState().filter(s => s.sort !== null)
            if(sortModel.length > 0){
                data.sortModel = {colId: sortModel[0].colId, sort: sortModel[0].sort.toUpperCase()}
            }
        }

        try {
            loadingSpinnerBtnWait(event)

            // Get report defination
            axios.post(`${NEST_API_URL}/reports/print`, 
                {
                    report_id: selectedNode?.data?.sgl_id, 
                    customer_id: customerId, 
                    show_id: currentShowID, 
                    params: data,
                    skip_subreport: skipSubreport,
                }
            )
            .then(async (response) => {
                if (response?.data?.success) {
                    let report = response.data.report;
                    // let reportDefinitionObject = getObjectFromJsonString(response.data.report.report_definition);
                    let reportDefinitionObject = JSON.parse(response.data.report.report_definition)
                    report.report_definition = reportDefinitionObject;

                    if(report.single_print_per_record) {
                        // Load single page report and populate data
                        await loadSinglePageReportsandPrint(report, displayedResultIds, printType);
                    }else{
                        // Load other report
                        await loadReportsAndPrint(report, displayedResultIds, printType);
                    }
                    
                    loadingSpinnerBtnRelease(event)

                    if (printType === 'print') {    // Close modal only if print is clicked, not in case of preview
                        handleClose();
                    }

                    // Commented code, because we are not updating entry fees status from here now, its done in loadReportsAndPrint function
                    //loadingOverlay({ show:true, message: `Loading Report...`})
                    // Code to update entry fees delivered and printed status
                    // If user sets delivered, it will update status in entry fees to delivered and next time report won't print, else it sets it to printed
                    // if((report.name == "Feed Delivery Report, By Barn" || 
                    //     report.sgl_id == 111 || 
                    //     report.name == "Feed Delivery Report, By Item" || 
                    //     report.sgl_id == 155 || report.name == "Feed Delivery Report, By Trainer" || 
                    //     report.sgl_id == 153 || report.name == "Feed Delivery Report, By Trainer (Landscape)" || 
                    //     report.sgl_id == 154 ||
                    //     report.sgl_id == 160 || 
                    //     report.name == 'Feed Delivery Report, By Category') 
                    //     && printType === 'print'){      // Show this modal only in print mode (not preview)
                    //     let updateFeeDeliveredStatus = false; 

                    //     let response = await axios.post(`${NEST_API_URL}/reports/reportData`, {
                    //         // If you update report paramenters inside the design, please update them here as well
                    //         report_reference: report.report_definition.ReportParameters[5].DefaultValue.Values[0],
                    //         report_name: report.report_definition.ReportParameters[4].DefaultValue.Values[0],
                    //         customer_id: customerId, 
                    //         show_id: currentShowID, 
                    //         params: {ids: displayedResultIds},
                    //     })
                        
                    //     if (response.data.data?.entryfees && response.data.data?.entryfees?.length > 0) {
                    //         updateFeeDeliveredStatus = true;
                    //     }
                    //     // loadingOverlay({show: false});        

                    //     if (updateFeeDeliveredStatus) {
                    //         // If we get any number of records, we will update entry fee status to either printed or delivered
                    //         // Otherwise, no need to show this modal
                    //         let choice = await confirmDialog({message: 'Did the feed report print properly?  OK to mark as delivered now?'})
                            
                    //         if (choice) {
                    //             loadingOverlay({show: true, message: 'UPDATING ENTRY FEES'})
                    //             axios.post(`${NEST_API_URL}/reports/updateFees`,
                    //                 {
                    //                     reportReference: report.report_definition.ReportParameters[5].DefaultValue.Values[0],
                    //                     customer_id: customerId, 
                    //                     show_id: currentShowID,
                    //                     ids: displayedResultIds,
                    //                     update_delivery: choice
                    //                 }
                    //             ).then((response) => {
                    //                 loadingOverlay({show: false})
                    //             }).catch(error => { console.log(error); loadingOverlay({ show: false })})   
                    //         }
                    //     }
                    // }
                } else {
                    loadingOverlay({show: false})
                    alertDialog({
                        title: "Error",
                        message: response.data.message
                    });
                }
            })
            .catch(error => { console.error(error); throw error; });

        } catch (error) {
            alertDialog({
                message: error?.response?.data?.error ? error.response.data.error : error?.response?.data?.message, 
                title: "Error"
            })
            loadingOverlay({show: false})
            loadingSpinnerBtnRelease(event)
        }
    }

    // Column defs for AG Grid
    const columnDefs = [
        { 
            field: 'sortColumn',
            hide: true,
            sortable: true,
        },
        { 
            field: 'report_group',
            hide: true,
            rowGroup: true,
        },
        {
            minWidth: 500,
            showRowGroup: true,
            field: 'name',
            cellRenderer: 'agGroupCellRenderer', // AG Grids default group cell renderer
            cellRendererParams: {
                suppressPadding: false, // Adds a padding in the child rows (those which are a part of group)
            },
        },
        { 
            field: 'sgl_id',
            hide: true,
        },
        {
            field: 'report_label',
            hide: true,
        } 
    ]
    
    // On row selected for custom grouped rows in AG Grid
    const onRowSelected = useCallback((event) => {
        if ((!event.data || event.node.group) && event.node.isSelected()) {     // If group row is selected
            // If a grouped row is selected
            setSelectedReport(null);
        } else if (event.data && event.node.isSelected()) {     // If normal row is selected OR a child row is selected
            // If a regular row is selected
            const id = event?.data?.sgl_id;
            const name = event?.data?.name;
            const report_label = event?.data?.report_label;
            const allow_csv_export = event?.data?.allow_csv_export;
            setSelectedReport({ label: name, value: id, report_label: report_label, allow_csv_export: allow_csv_export });
        }
    }, [selectedReport])

    // Do not print on space button press
    const printButtonKeyDown = (event) => {
        // If space is pressed, then do nothing
        if (event.key === ' ') {
            event.preventDefault();
            return false;
        }
    }
    
    // Print type either preview or print
    // Preview will open a pdf in new tab and print will directly open print dialogue
    const handleSelection = async (event, printType ='print') => {
        // Return false if space is pressed
        if (event.key === ' ') {
            return false;
        }
        const selectedNode = gridApi?.current?.getSelectedNodes()[0]
        let message = ''
        // if(selectedNode?.data?.name == 'Invoice for Horses' || selectedNode?.data?.sgl_id == 28){
        //     message = 'Please Enter date range.'
        // }
        // else if(selectedNode?.data?.name == 'Horse Results' || selectedNode?.data?.sgl_id == 30){
        //     message = 'Enter date range for results for this horse.'
        // }
        // else if(selectedNode?.data?.name == 'RTO Schedule' || selectedNode?.data?.sgl_id == 71){
        //     message = 'Please enter the start and end dates for the schedule.'
        // }
        // else if(selectedNode?.data?.name == 'RTO Schedule - No Times' || selectedNode?.data?.sgl_id == 70){
        //     message = 'Please enter date range for schedule'
        // }
        // else if(selectedNode?.data?.name == 'RTO Schedule by Trainer Horse Day Class - No Times' || selectedNode?.data?.sgl_id == 72){
        //     message = 'Enter Date Range'
        // }
        if(
            selectedNode?.data?.name == 'Invoice for Horses' || 
            selectedNode?.data?.name == 'Horse Results' || 
            selectedNode?.data?.name == 'RTO Schedule' || 
            selectedNode?.data?.name == 'RTO Schedule - No Times' || 
            selectedNode?.data?.name == 'RTO Schedule by Trainer Horse Day Class - No Times' || 
            selectedNode?.data?.name == 'RTO Schedule by Trainer Horse Day Class' || 
            selectedNode?.data?.name == 'Payment Batch Summary' ||
            selectedNode?.data?.name == 'Payments Across Shows' ||
            selectedNode?.data?.name == 'Payment Summary for All Users' ||
            selectedNode?.data?.system_report == 663 || 
            selectedNode?.data?.system_report == 610 || 
            selectedNode?.data?.system_report == 664 ||
            selectedNode?.data?.system_report == 651 ||
            selectedNode?.data?.system_report == 711 ||
            selectedNode?.data?.system_report == 740 || 
            selectedNode?.data?.sgl_id == 206 ||
            selectedNode?.data?.sgl_id == 207
        ){
            sessionStorage.setItem('dateRangeModal', true)
            let data = await dateRangeDialog({show: true, type: 'Double Date Input', message})
            if(data){
                handleSubmit(event, data, printType)
            }
        } else if (
            selectedNode?.data?.name == 'Daily User Payment Report' || 
            selectedNode?.data?.system_report == 266 || 
            selectedNode?.data?.system_report == 755 || 
            selectedNode?.data?.name == 'Payments by Type (Current Show)' ||
            selectedNode?.data?.name == 'Payments by Type and Show' || 
            selectedNode?.data?.system_report == 693) {
            sessionStorage.setItem('dateRangeModal', true)
            // This report requires date selection for payment details on specific date
            let data = await dateRangeDialog({show: true, type: 'Single Date Input', message})
            if(data) {
               handleSubmit(event, data, printType)
                }
        } else if(
            selectedNode?.data?.name == '1099 Individual Winnings Detail' ||
            selectedNode?.data?.system_report == 270 ||
            selectedNode?.data?.name == '1099 Summary Report' ||
            selectedNode?.data?.system_report == 267 ||
            selectedNode?.data?.name == '1099 Report' ||
            selectedNode?.data?.system_report == 259 ||
            selectedNode?.data?.name == '1042 Individual Winnings Detail' ||
            selectedNode?.data?.system_report == 760 ||
            selectedNode?.data?.name == '1042 Report' ||
            selectedNode?.data?.system_report == 761 ||
            selectedNode?.data?.name == '1042 Summary Report' ||
            selectedNode?.data?.system_report == 763
        ){
            sessionStorage.setItem('dateRangeModal', true)
            let data = await dateRangeDialog({show: true, type: 'Year', message, name: 'Financial Reports'})
            if(data){
                handleSubmit(event, data, printType)
            }
        }else if(selectedNode?.data?.name == 'Entry Verification Report'){
            if(!showVerificationColumns){
                alertDialog({message: 'Please run the verification before printing this report'})
            }
            else{
                handleSubmit(event, {verificationData: outputVerificationData, verification: outputVerificationData, verify: true}, printType)
            }
        } else{
            handleSubmit(event, null, printType)
        }
        
        logSiteActivity(currentShowID, customerID, {
            source: "Reports",
            tab_name: outputGrid?.area,
            activity: selectedNode?.data?.name,
            description: 'Print'
        })
    }

    const handleSearch = useCallback((pressedKeys) => {
        if(sessionStorage.getItem('dateRangeModal')){
            return false;
        }
        
        const searchString = pressedKeys
        .map(key => key.slice(-1).toLowerCase()) // Extract lowercase letter from the key
        .join('')

        if (searchString.length > 0) {
            if (gridApi.current) {
                let found = false;
                gridApi.current.forEachNode(node => {
                    // Replace special characters from the report name for typeahead search
                    if (!found && node?.data?.name?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().startsWith(searchString)) {
                        node.setSelected(true);
                        gridApi.current.ensureIndexVisible(node.rowIndex);
                        found = true;
                    }
                });
            }
        }
    }, []);

    const handleArrowKeys = useCallback((event) => {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            if (gridApi.current) {
                const selectedNode = gridApi.current.getSelectedNodes()[0];
                if (selectedNode) {
                    const selectedRowIndex = selectedNode.rowIndex;
                    const rowCount = gridApi.current.getDisplayedRowCount();
    
                    let newRowIdx;
                    if (event.key === 'ArrowDown') {
                        newRowIdx = selectedRowIndex + 1;
                        if (newRowIdx >= rowCount) {
                            newRowIdx = rowCount - 1;
                        }
                    } else {
                        newRowIdx = selectedRowIndex - 1;
                        if (newRowIdx < 0) {
                            newRowIdx = 0;
                        }
                    }
 
                    let found = false;
                    gridApi.current.forEachNode(node => {
                        if (!found && node?.rowIndex === newRowIdx) {
                            node.setSelected(true);
                            gridApi.current.ensureIndexVisible(node.rowIndex);
                            found = true;
                        }
                    });
                }
            }
        }
    }, []);

    const onCellKeyDown=(e)=>{
        if (gridApi.current && e.event.key === "Enter") {
            const selectedNode = gridApi?.current?.getSelectedNodes()[0]
            if(selectedNode && selectedNode?.data){
                handleSelection(e.event)
            }
        }
    }

    // const handleEnter = useCallback((event) => {
    //     if(event.key === "Enter"){
    //         if (gridApi.current) {
    //             const selectedNode = gridApi?.current?.getSelectedNodes()[0]
    //             if(selectedNode && selectedNode?.data){
    //                 handleSelection(event)
    //             }
    //         }
    //     }
    // }, []);

    useTypeahead(letterKeys, handleSearch)
    useKey(arrowKeys, handleArrowKeys)
    // useKey("Enter", handleEnter)

    function onFirstDataRendered(params) {
        params.api.getRowNode(0).setSelected(true);
    }
    const autoGroupColumnDef = useMemo(() => {
        return {
            comparator: (value1, value2) => {
                if (value1 == '' && value2 == '') {
                    return 0;
                }

                if (value1 && value2) {
                    if (value1 > value2) {
                        return 1;
                    } else if (value1 < value2) {
                        return -1;
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            },
            hide: true,
            sortable: true,
            field: 'sortColumn',
            minWidth: 0,
            maxWidth: 0,
        };
      }, []);

    useEffect(() => {
        localStorage.setItem('skipSubreport', JSON.stringify(skipSubreport));
    }, [skipSubreport]);

    return createPortal(
        show &&
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px search-form'
            show={show}
            onHide={handleClose}
        >
            <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{ intl.formatMessage({id: "REPORT.POPUP.LABEL.SELECTREPORT" }) }</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}</div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='report_select' data-tooltip-id="REPORT.DETAIL.MODAL.SELECTREPORT.LABEL.AVAILABLEREPORTS">{intl.formatMessage({ id: 'REPORT.POPUP.LABEL.SELECT' })}</label>
                        <div className='row mb-2'>
                            <div className='col'>
                                <div style={containerStyle}>
                                    <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell  membership-data-grid">
                                        <AgGridReact
                                            groupAllowUnbalanced
                                            onGridReady={(params) => gridApi.current = params.api}
                                            rowHeight={getExtraNarrowRowHeight}
                                            rowData={reportList}
                                            columnDefs={columnDefs}
                                            rowStyle={{ fontSize: '14px', borderBottom: 'none' }}
                                            rowSelection={'single'}
                                            suppressScrollOnNewData={true}
                                            groupDefaultExpanded={1}
                                            rowMultiSelectWithClick={false}
                                            onRowSelected={onRowSelected}
                                            onFirstDataRendered={onFirstDataRendered}
                                            onCellKeyDown={onCellKeyDown}
                                            // groupDisplayType={'groupRows'}
                                            animateRows={true}
                                            autoGroupColumnDef={autoGroupColumnDef}
                                            groupRemoveSingleChildren={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex'>
                            <div className='col-lg-6'>
                                <label className='col col-form-label fw-bold fs-5 py-1' htmlFor='report_select' data-tooltip-id="REPORT.DETAIL.MODAL.SELECTREPORT.LABEL.REPORTDESCRIPTION">{intl.formatMessage({ id: 'REPORT.POPUP.LABEL.DESCRIPTION' })}</label>
                            </div>
                            { (selectedReport?.label?.includes('Entry Blank') && outputGrid.area == 'Entries') ?
                                <div className='col-lg-6 form-check-sm form-check-custom ps-lg-3 justify-content-end'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        checked={skipSubreport}
                                        id='firstPage'
                                        onChange={(e) => setSkipSubreport(e.target.checked)}
                                    />
                                    <label className='col-form-label mx-2 fs-6 py-1' htmlFor='firstPage' data-tooltip-id="REPORT.DETAIL.MODAL.SELECTREPORT.LABEL.ENTRYBLANKFIRSTPAGE">{intl.formatMessage({id: 'FORM.INPUT.REPORTS.INFO.LABEL.ENTRYBLANKFIRSTPAGE'})}</label>
                                </div>
                                : null
                                // setSkipSubreport(false)
                            }
                        </div>
                        <div className='row mb-2'>
                            <div className='col'>
                                <textarea
                                    readOnly
                                    className='form-control h-100px form-control fs-6'
                                    value={reportList?.find(report => report.sgl_id == selectedReport?.value)?.notes}
                                >
                                </textarea>
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase'
                            type="button"
                            onClick={handleClose}
                        >
                            {intl.formatMessage({ id: 'REPORT.POPUP.BUTTON.CANCEL' })}
                        </button>
                        <button className='btn btn-sm btn-dark fw-bold text-uppercase me-5' onKeyDown={printButtonKeyDown} onClick={(event) => handleSelection(event, 'preview')} type="submit" autoFocus data-tooltip-id="REPORT.DETAIL.MODAL.SELECTREPORT.BUTTON.PREVIEW">
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            { intl.formatMessage({ id: 'REPORT.POPUP.LABEL.PREVIEW' })}
                        </button>
                        <button className='btn btn-sm btn-dark fw-bold text-uppercase me-5' disabled={!selectedReport?.allow_csv_export} onKeyDown={printButtonKeyDown} onClick={(event) => handleSelection(event, 'downloadCsv')} type="submit" autoFocus data-tooltip-id="REPORT.DETAIL.MODAL.SELECTREPORT.BUTTON.PRINTTODISK">
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            { intl.formatMessage({ id: 'REPORT.POPUP.LABEL.PRINTTODISK' })}
                        </button>
                        <button className='btn btn-sm btn-dark fw-bold text-uppercase' onKeyDown={printButtonKeyDown} onClick={(event) => handleSelection(event, 'print')} type="submit" autoFocus data-tooltip-id="REPORT.DETAIL.MODAL.SELECTREPORT.BUTTON.PRINT">
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            { intl.formatMessage({ id: 'REPORT.POPUP.LABEL.PRINT' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { ReportType }