import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    trigger_render: false,
}

const renderShowPickerSlice = createSlice({
    name: 'renderShowPicker',
    initialState: initialState,
    reducers: {
        renderShowPicker(state) {
            return {
                trigger_render: !state.trigger_render
            }
        },
    },
})

export const { renderShowPicker } = renderShowPickerSlice.actions

export default renderShowPickerSlice.reducer