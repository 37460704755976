import { useState } from 'react'
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import axios from 'axios';
import { useAuth } from '../../../../modules/auth';
import { useAppSelector } from '../../../../redux/hooks';

const ChecklistItem = (props) => {
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const methods = useFormContext()
    const { currentUser } = useAuth()
    const [checked, setChecked] = useState(props?.data?.entrychecklistitems_id > 0 ?? false); 

    const updateCheckList = async (e) => {
        let checklist = methods.getValues('checklist')
        checklist = await Promise.all(checklist.map(async item => {
            if((props?.data?.scl_sgl_id && props.data.scl_sgl_id == item?.scl_sgl_id) || (props?.data?.ecl_sgl_id && props.data.ecl_sgl_id == item?.ecl_sgl_id)){
                item.checked = e.target.checked
                setChecked(e.target.checked)

                if(item.checked){ // Update data on check
                    let response = await axios.get( process.env.REACT_APP_NEST_API_URL + '/utility/getTimezoneInfo', {
                        params: { customer_id: customer_id }
                    })

                    if(response.data?.date){
                        item.verified_date = response.data?.date
                        item.verified_time = response.data?.time
                        item.verified_user = currentUser.first_name + ' ' + currentUser.last_name
                    }
                }else{ // Reset data on uncheck
                    item.verified_date = ''
                    item.verified_time = ''
                    item.verified_user = ''
                }
            }

            return item
        }))
        methods.setValue('checklist', checklist, {shouldDirty: true})
    }

    return (
        <div className='row flex-row mx-1 mb-3 checklist-item align-items-flex'>
            <div className='col-lg-1 form-check-custom px-1 justify-content-center align-items-center'>
                <input
                    className='form-check-input checklist-checkbox'
                    type='checkbox'
                    defaultChecked={props?.data?.entrychecklistitems_id > 0 ?? false}
                    onChange={updateCheckList}
                />
            </div>
            <div className={'col py-2' + (!checked ? ' d-flex align-items-center' : '')}>
                <div className='row'>
                    <label className={'col-lg-12 col-form-label fs-4 py-0 text-dark fw-bold'}>{props?.data?.check_list_item}</label>
                </div>
                <div className={'row fs-6 text-gray-600' + (!checked ? ' d-none' : '')}>
                <div className='col-lg-1 verified-date-container'>{props.data.verified_date && moment(moment(props.data.verified_date).format('YYYY-MM-DD') + ' ' + props.data.verified_time).format('D MMM, YYYY @ hh:mm A')}</div>
                <div className='col'>{props.data.verified_user}</div>
                </div>
            </div>
        </div>
    )
}

export { ChecklistItem }