import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { defaultColDef, getNarrowHeaderHeight,getNarrowRowHeight } from "../../../../modules/sgl-utils/agGridHelpers";

const ShowsGrid = ({ rowData, selectedShowIndex, setSelectedShow, tabIndex=0 }) => {
    const intl = useIntl()
    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    const columnDefs = [
        { 
            field: 'show_name', 
            headerName: intl.formatMessage({ id: 'QUICKACTION.MOVEENTRIESTOSHOW.LIST.SHOW' }),
            headerClass: 'ag-center-aligned-header'
        }
    ]

    const onFirstDataRendered = useCallback((params) => {
        params.api.sizeColumnsToFit();
    }, []);

    const handleRowClicked = (params) => {
        setSelectedShow(params.data)
    }

    useEffect(()=>{
        if(gridRef?.current?.api)
        {
            let rowNodeToSelect
            if(selectedShowIndex<=0)
            {
                rowNodeToSelect = gridRef?.current?.api?.getRowNode(0)
            }else{
                rowNodeToSelect = gridRef?.current?.api?.getRowNode(selectedShowIndex)
            }
            if(rowNodeToSelect)
            {
                setSelectedShow(rowNodeToSelect.data)
                gridRef?.current?.api?.selectNode(rowNodeToSelect, true);
                gridRef?.current?.api?.redrawRows({rowNodeToSelect})
                gridRef?.current?.api?.ensureNodeVisible(rowNodeToSelect);
            }
        }
    },[selectedShowIndex])
    
    return (
        <div>
            <div className="row mb-2">
                <label className='col-lg-11 col-form-label fs-5 py-1'>
                   
                </label>
                <div className="col-lg-1">
                    <label className='col-form-label fs-5 py-1 d-flex justify-content-end me-1'>
                        { rowData.length }
                    </label>
                </div>
            </div>
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        onFirstDataRendered={onFirstDataRendered}
                        columnDefs={columnDefs} 
                        rowStyle={{ fontSize: '12px' }}
                        suppressScrollOnNewData={true}
                        headerHeight={getNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                        rowSelection= {'single'}
                        onRowClicked={handleRowClicked}
                        {...(tabIndex !== 0 && { tabIndex })} //tabIndex is set conditionally, only if a prop was passed, provided that the prop is not 0 
                    />
                </div>
            </div>
        </div>
    )
}


export default ShowsGrid