import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../../_metronic/helpers'
import Select from 'react-select'
import { reactSelectStyles } from '../../../../modules/sgl-utils/fieldControls'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters'
import { AgGridReact } from 'ag-grid-react'
import { getNarrowHeaderHeight, getNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers'
import CreateWithholdingPaymentBatch from './CreateWithholdingPaymentBatch'
import axios from 'axios'
import { useAppSelector } from '../../../../redux/hooks'
import { addFloatingNumbers } from '../../../../modules/sgl-utils/SglFunctions'
import { useAlert, useLoadingOverlay } from '../../../../modules/sgl-utils/DialogsProvider'
import useReportPrint from '../../../../modules/hooks/use-report-print'
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../../modules/sgl-utils/SglFunctions';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
 
const ApplyWithholdingTax = ({show, handleClose}) => {
    const intl = useIntl() 
    const containerStyle = useMemo(() => ({ width: '100%', height: '70vh' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const currencyFormatter = useCurrencyFormatter()
    const alertDialog = useAlert()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);

    const [displayCreateWithholdingTax, setDisplayCreateWithholdingTax] = useState(false)
    const [paymentBatches, setPaymentBatches] = useState([])
    const [selectedBatch, setSelectedBatch] = useState('')
    const [paymentBatchItems, setPaymentBatchItems] = useState([])
    const gridRef = useRef()
    const { printReport } = useReportPrint()
    const loadingOverlay = useLoadingOverlay



    const defaultColDef = useMemo(() => {
        return {
          minWidth: 110,
          width: 110,
          resizable: true,
          sortable: true,
          suppressMenu: true,
          suppressMovable: true,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          headerClass: "ag-center-aligned-header",
          singleClickEdit: true,
        };
    }, []);

    const columnDefs = [
        { 
            field: 'People.fl_name', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.POPUP.COL.RTO' }),
            width: 400,
            flex: 1,
            cellStyle: { textAlign: 'left' }
        },{ 
            field: 'People.country_code', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.POPUP.COL.COUNTRY' }),
            width: 200,
            cellStyle: { textAlign: 'center' },
            valueGetter: (params) => { 
                if(!params.node.rowPinned && params.node.data?.People?.country_code){
                    let value = params.node.data?.People?.country_code
                    if(params.node.data?.tax_rate > 0){
                        value += ` (${params?.node.data?.tax_rate?.toFixed(2)}%)`
                    }

                    return value
                }
            }
        },
        { 
            field: 'tax_amount_limit', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.POPUP.COL.TREATY" }),
            wrapHeaderText: true,
            autoHeaderHeight: true,
            width: 200,
            cellStyle: { textAlign: 'right' },
            cellRendererSelector: (params) => {
                if (params.node.rowPinned) { //Renderer for footer row
                    return null
                } else { //Renderer for other rows
                    return {component: params => currencyFormatter(params.value)}
                }
            }
        },
        { 
            field: 'amount', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.POPUP.COL.AMOUNT" }),
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { textAlign: 'right' },
            width: 200,
            cellRenderer: params => currencyFormatter(params.value)
        }
    ]

    const getPaymentBatches = async () => {
        const moment = require('moment')
        await axios.get(`${NEST_API_URL}/withholding-tax/getWithholdingTaxPaymentBatches?customer_id=${customer_id}`)
        .then(response => {
            if(response.data.success){
                let batchoptions = response.data.paymentBatches.map((batch) => {
                    return {
                        label: `${batch.batchno}  -  ${moment(batch.date).format('MMM D, YYYY')}`,
                        value: batch.payment_batch_id
                    }
                })
                setPaymentBatches(batchoptions)
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            // Do Nothing
        }); 
    }

    const getRelatedPaymentBatchItems = async() => {
        if(!selectedBatch){
            return;
        }

        gridRef?.current?.api?.showLoadingOverlay()
        axios.get(`${NEST_API_URL}/withholding-tax/getWithholdingTaxBatchItems?customer_id=${customer_id}&batch_id=${selectedBatch}`)
        .then(response => {
            if(response.data.success){
                // update grid data
                setPaymentBatchItems(response.data.paymentBatchItems)

                gridRef?.current?.api?.setPinnedBottomRowData([{
                    amount: response.data.paymentBatchItems.reduce((val, batch) => addFloatingNumbers([val, batch.amount], 2), 0)
                }])
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            gridRef?.current?.api?.hideOverlay()
        }); 
    }
    
    const getRowStyle = (params) => {
        if (params.node.rowPinned) {
            return { fontWeight: "bold" };
        }
    };

    const closeDialogForCreatingNewBatch = () => {
        getPaymentBatches() // refresh batch items
        setPaymentBatchItems([]) // remove the data from the grid
        setSelectedBatch('') // remove the selected batch
        setDisplayCreateWithholdingTax(false)
    }

    useEffect(() => { // get payment batches
        getPaymentBatches()
    }, [])

    useEffect(() => { // get related payment batch item
        getRelatedPaymentBatchItems()
    }, [selectedBatch])

    // Print withholding tax Report 
    const printWithholdingTaxReport = async (event) => {
        // Show loading
        loadingSpinnerBtnWait(event)
        if (selectedBatch) {
            // 196 hardcoded report ID for Withholding Tax - Batch Summary
            await printReport(196, [], { payment_batch_id: selectedBatch })
        }
        // Hide loading
        loadingSpinnerBtnRelease(event)
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-xl search-form apply-withholding-tax-modal'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    handleClose()
                }
                if (event.key === 'Escape') {
                    handleClose()
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .manual-override-form .w-lg-140px { width: 140px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.POPUP.HEADING' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form manual-override-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>

                        {/* Batch */}
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1' htmlFor='batches'>{intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.POPUP.LABEL.BATCH' })}</label>
                            <div className='col-lg-5'>
                                <Select
                                    value={selectedBatch !== undefined ? paymentBatches.filter((ss) => ss.value === selectedBatch):""}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                    })}       
                                    options={paymentBatches}
                                    onChange={(e) => {
                                       setSelectedBatch(e.value)
                                    }}
                                    placeholder='Select'
                                    styles={reactSelectStyles}
                                    autoFocus
                                    tabIndex={1}
                                />
                            </div>

                            <div className='col-lg-5' onClick={() => setDisplayCreateWithholdingTax(true)}>
                                <button className="btn btn-sm btn-secondary me-2 fw-bold px-2 py-1" type='button' style={{ height: '30px' }} tabIndex={2}>
                                    <i className="fas fa-plus fs-5 px-1 py-3"></i>
                                </button>
                            </div>
                        </div>

                        {/* Batches Grid */}
                        <div style={containerStyle}>
                            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                <AgGridReact
                                    ref={gridRef}
                                    defaultColDef={defaultColDef}
                                    rowData={paymentBatchItems}
                                    columnDefs={columnDefs} 
                                    getRowStyle={params => getRowStyle(params)}
                                    suppressScrollOnNewData={true}
                                    headerHeight={getNarrowHeaderHeight}
                                    rowHeight={getNarrowRowHeight}
                                    overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while withholding tax batch items are loading...</span>'}
                                    tabIndex={3}
                                />
                            </div>
                        </div>    
                    </div>

                    {displayCreateWithholdingTax && <CreateWithholdingPaymentBatch show={displayCreateWithholdingTax} handleClose={closeDialogForCreatingNewBatch}/>}
                    
                    <div className='card-footer d-flex justify-content-between py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-secondary fw-bold' tabIndex={4} disabled={!selectedBatch} onClick={(e) => printWithholdingTaxReport(e)}>
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.POPUP.BUTTON.PRINT.BATCH' })}
                        </button>

                        <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={handleClose} tabIndex={5}>
                            {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.APPLY.WITHHOLDINGTAX.POPUP.BUTTON.DONE' })}
                        </button>
                    </div>
                </form>
            </div>

            
        </Modal>,
        modalsRoot
    )
}

export default ApplyWithholdingTax