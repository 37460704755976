/*
    Renders value upto numPlaces number of decimal points

    EXAMPLE: 
    Input:  params.value = 10, numPlaces = 2
    Output: 10.00 
*/
const DecimalPointRenderer = (params, numPlaces = 2, showZero = true) => {
    const value = params.value
    if (value && !isNaN(value)) {
        return Number(value).toFixed(numPlaces).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    if (showZero)
        return '0.' + '0'.repeat(numPlaces)
    return ''
}

const IntegerOrFloatRenderer = (params, numPlaces = 3, showZero = true) => {
    const value = params.value
    const isIntegerValue = value % 1 === 0
    if (value && !isNaN(value)) {
        if (isIntegerValue)
            return parseInt(value)
           
        return  Number(value).toFixed(numPlaces).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    if (showZero)
        return isIntegerValue ? 0 : ('0.' + '0'.repeat(numPlaces))
    return ''
}

export default DecimalPointRenderer;
export { IntegerOrFloatRenderer }