import { useState } from "react";
import { usePaymentBatchContext } from "../../PaymentBatchContext";
import { AccountDetailsDialog } from "./AccountDetailsDialog";
import { getRowData, updateAGGridWithoutFlickering } from "../../../../modules/sgl-utils/agGridHelpers";

const PreferenceRenderer = (params, updatePaymentBatchItems, gridReference) => {
    const { prizePreferences } = usePaymentBatchContext()
    let [ value, setValue ] = useState(params?.data?.prize_preference ? params?.data?.prize_preference : 'Check') // if no payment type is selected that by default select Check
    const [displayAccountDetailDialog, setDisplayAccountDetailDialog] = useState(false)

    const handleChange = (e) => {
        // update preze_preference value in db
        updatePaymentBatchItems([params.node.data.payment_batchitems_id], 'updateBatchItem', 'prize_preference', e.target.value, params?.data?.prize_preference, gridReference);
        setValue(e.target.value)
        params.node.data.prize_preference = e.target.value

        // update the grid to toggle the info icon based on prize preference value
        updateAGGridWithoutFlickering(gridReference, getRowData(gridReference?.current?.api))
    };

    return (
        <>
        {
            displayAccountDetailDialog ?
            <AccountDetailsDialog show={displayAccountDetailDialog} handleClose={() => setDisplayAccountDetailDialog(false)} batchItem={params.node.data} updatePaymentBatchItems={updatePaymentBatchItems} gridReference={gridReference}/>
            : null
        }
        <div className="row">
            <div className="col" style={{paddingLeft: '0px'}}>
                <select 
                    className='form-select form-select-sm fs-7 py-0 mt-1'
                    onChange={(e) => handleChange(e)}
                    value={value}
                    style={{ height: '22px' }}
                >
                    {/* filter out prize preferences with no value */}
                    { prizePreferences.filter((pref) => pref.value).map(option => ( 
                        option.value == '' ?
                            <option key={'Select'} value={''}>Select</option>
                            :
                            <option key={option.value} value={option.value}>{option.label}</option>
                    )) }
                </select>
            </div>

                    <div className='col-lg-1 d-flex justify-content-center align-items-center px-0 py-1'>
                    {
                        ['Wire Transfer', 'ACH', 'EFT'].includes(params?.data?.prize_preference) && (
                        <i 
                            className="fa-sharp fa-solid fa-circle-info fa-xl py-3" 
                            style={{ color: '#578EBE' }}  
                            onClick={() => {setDisplayAccountDetailDialog(true)}}
                        ></i> 
                        )}
                    </div>
        </div>
        </>
    );
}

export default PreferenceRenderer;