import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useAnnouncerToolContext } from './AnnouncerToolContext';
import BooleanCheckmarkRenderer from '../../../modules/output-listing/renderers/BooleanCheckmarkRenderer';

const OrderedOrderOfGoGrid = (props) => {
  const gridRef = useRef();
  const intl = useIntl();

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100% - 28px)' }), []);
  const { orderedTrips } = useAnnouncerToolContext()

  //Grid Class Groups
  const columnDefs = [
      { field: 'gone_in', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.ORDEREDTRIPS.LABEl.GONE'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, maxWidth: 85, minWidth: 85, headerClass: 'ag-center-aligned-header', cellRenderer: BooleanCheckmarkRenderer},
      { field: 'order_of_go', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.ORDEREDTRIPS.LABEl.ORDER'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, maxWidth: 85, minWidth: 85, headerClass: 'ag-center-aligned-header'},
      { field: 'Entry.number', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.UNORDEREDTRIPS.LABEl.ENTRY'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, maxWidth: 85, minWidth: 85, headerClass: 'ag-center-aligned-header'},
      { field: 'Entry.horse', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.UNORDEREDTRIPS.LABEl.HORSE'}), sortable: false, suppressMenu: true,cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, maxWidth: 500,  width: 150, resizable :true,cellClass: 'ag-left-aligned-cell'},
      { field: 'rider_name', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.UNORDEREDTRIPS.LABEl.RIDER'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, maxWidth: 500, width: 150, resizable :true,cellClass: 'ag-left-aligned-cell'},
      { field: 'Entry.trainer', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.UNORDEREDTRIPS.LABEl.TRAINER'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true,maxWidth: 500  , width: 150,cellClass: 'ag-left-aligned-cell'}
  ]
  
  const onFirstDataRendered = useCallback((params) => {
      params.api.sizeColumnsToFit();
  }, []);

  const onGridReady = useCallback((params) => {
  }, []);

  const defaultColDef = useMemo(() => {
  }, []);

  function getRowStyle(params) {
    let backgroundColor = "#FFFFFF"

    if (params.data.web_signup) {
        backgroundColor = "#00FF00"
    } 

    return { backgroundColor }
  };

  return (
    <>
    <div className='row mb-0'>
        <div className='col-lg-12 d-flex align-items-center'>
            <label className='col-lg-8 col-form-label fs-6 py-0'> {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.ORDEROFGO.LABEL.ORDEREDTRIPS'})}</label>
            <div className="col-lg-4 d-flex justify-content-end"><div className="col-lg-1 border-top border-success border-0 mt-0 me-2 square-shape-ordergo"></div><p className="col fs-7 mb-0">{intl.formatMessage({id: 'ANNOUNCER.GRID.ORDEREDTRIPS.LABEl.INDICATES'})}</p></div>
        </div>
    </div>

    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine ingate-grid">
        <AgGridReact
          ref={gridRef}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={(orderedTrips && orderedTrips.length > 0)? orderedTrips : []}
          rowHeight={20}
          headerHeight={26}
          onFirstDataRendered={onFirstDataRendered}
          onGridReady = {onGridReady}
          rowDragEntireRow={false}
          rowDragManaged={false}  
          animateRows = {true}      
          enableRangeSelection={false}
          getRowStyle={params => getRowStyle(params)}
          overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while ordered trips are loading.</span>'}
        ></AgGridReact>
      </div>
    </div>
    </>
  );
};

export default OrderedOrderOfGoGrid;
  