import { useState, useEffect} from 'react'
import { createPortal} from 'react-dom'
import { Modal} from 'react-bootstrap'
import { KTSVG} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import "flatpickr/dist/themes/material_blue.css";
import axios from 'axios'
import { useAppSelector } from '../../redux/hooks'
import { QueryBuilder } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import { QueryBuilderBootstrap } from '@react-querybuilder/bootstrap';
import { useOutputContext } from '../output-listing/OutputListingContext'
import { useConfirm, useLoadingOverlay } from '../sgl-utils/DialogsProvider'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

type Props = {
  recordIds: any
  area: string,
  show: boolean,
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModifyRecords = ({recordIds,area, show, handleClose}: Props) => {
  const intl = useIntl()
  const [errorMessage, setErrorMessage] = useState('')
  const customer_id = useAppSelector(state=> state.showCompany.company_id);
  const show_id = useAppSelector(state => state.currentShow.show_id);
  const { getServerData } = useOutputContext()
  const confirmDialog = useConfirm()
  const loadingOverlay = useLoadingOverlay()
  const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)

  // Define the EditableField type
  interface EditableField {
    name: string;
    label: string;
    type: 'string' | 'number' | 'checkbox' | 'date' | 'radio' | 'select' | 'time' | 'boolean';
    options?: Array<{ value: string | number; label: string }>; // Optional for radio and select
    disable_on_locked_show?: boolean;
  }
  const [fields, setFields] = useState<EditableField[]>([]);

  useEffect(() => {
    axios.get( `${NEST_API_URL}/modify-records/editable-fields` , {
      params: {
        entityName: area,
        customer_id,
        show_id
      }
    }).then( (response) => {
      setFields(response.data.fields);
    })
  }, []);

  const fieldOptions = [
    {
      name: 'please_select',
      label: 'Please Select...'
    },
    ...fields.map((field) => ({
    name: field.name,
    label: `${(showFinancialsLocked && field.disable_on_locked_show)?`${field.label} [LOCKED]`:field.label}`,
    inputType: field.type=='boolean'?'select':field.type,
    valueEditorType: field.type=='boolean'?'select':field.type,
    operators: [{name: "=" , label: '='}],
    values: field.type=='boolean'?[
      { label: 'Yes', name: 1 },
      { label: 'No', name: 0 },
    ]:field.options?.map( (option) => ({
      label: option.label,
      name: option.value
    })) || undefined, // Include options for radio and select types
  }))];
  
  const [query, setQuery] = useState<any>({
    combinator: 'and',
    rules: [
      {
        field: '', // No field selected initially
        operator: '=', // No operator selected initially
        value: '' // Empty value
      }
    ]
  });

  const getOutputSelectionRecordIDs = async () => { 
    let sglIDs = [];

    let serverData =  await getServerData({
      returnAll: true,
      returnIDs: true,
    });

    for(let record of serverData.data){
      sglIDs.push(record.sgl_id);
    }
    return sglIDs
};

  const handleSave = async () => {

    setErrorMessage('');
    // Validate the query to ensure it's properly structured
    if (!query.rules || query.rules.length === 0) {
      setErrorMessage('Please add at least one rule.');
      return;
    }

    if ( query.rules[0].field == 'please_select' || query.rules[0].field == '' ) {
      setErrorMessage('Please select a field to update.');
      return;
    }

    loadingOverlay({show: true})
    recordIds = await getOutputSelectionRecordIDs()
    loadingOverlay({show: false})
    
    if ( recordIds.length > 0 ) {
      const message = `Are you sure you want to modify ${recordIds.length} records?`
      const choice = await confirmDialog({ message })
  
      if ( !choice ) {
        return
      }
  
    } else {
      setErrorMessage('Please select some records to modify.');
      return;
    }
    
    loadingOverlay({show: true})
    // Send the formatted fields to the backend
    await axios.post(`${NEST_API_URL}/modify-records/save`, { recordIds, fields: query.rules, customer_id, show_id, entityName: area })
      .then((response) => {
        loadingOverlay({show: false})
        if (response.data.success) {
          // Optionally, reset the query or close the modal after saving
          setErrorMessage('');  // Clear any previous error message
          handleClose();        // Close the modal
        } else {
          setErrorMessage(response.data.errors.join(". "));
        }

      })
      .catch((error) => {
        loadingOverlay({show: false})
        setErrorMessage('Error saving fields.');
      });
  };
  
  return createPortal(
    <Modal
      id='kt_modal_create_license'
      enforceFocus={false}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-650px search-form'
      show={show}
      onHide={handleClose}
      onKeyDown={(event:any) => { //replaced onKeyPress with onKeyDown
        event.stopPropagation()
        let isButtonFocused = false
        let activeElement = document.activeElement //gets the currently focussed element
        if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
            isButtonFocused = true;
        }
        if (event.key === "Enter" && !isButtonFocused) {
          handleSave()
        }
        if (event.key === 'Escape') {
          handleClose()
        }
      }}
      autoFocus={true}
    >
      <div className='modal-header py-0 px-4'>
        <h2 className="fs-4">{intl.formatMessage({id: 'MODIFYRECORD.MODAL.TITLE'})}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4'>
        <form noValidate className='form' onSubmit={e => e.preventDefault()}>
          <div className='card-body p-0'>
          <div>
          <QueryBuilderBootstrap>
            <QueryBuilder
              fields={fieldOptions} // Pass the fields metadata to the query builder
              query={query}
              onQueryChange={setQuery}
              controlElements={{
                addRuleAction: null,  // Hide "Add Rule" button
                addGroupAction: null, // Hide "Add Group" button
                removeRuleAction: null, // Hide "Delete Rule" button
                removeGroupAction: null, // Hide "Delete Group" button
              }}
              controlClassnames={
                {
                  combinators: "d-none",
                  operators: "d-none",
                  fields: "w-lg-200px w-xl-200px w-xxl-200px me-5"
                }
              }
            />
          </QueryBuilderBootstrap>

          </div>
          </div>
          {(errorMessage != '') &&
            <div><span className='error_message'>{errorMessage}</span></div>
          }
          <div className='card-footer d-flex justify-content-end py-3 px-0'>
            
            <button className='btn btn-sm btn-secondary me-4 fw-bold' type='button' onClick={handleClose} tabIndex={7}>
              {intl.formatMessage({id: 'MODIFYRECORD.MODAL.BUTTON.CANCEL'})}
            </button>
            <button className='btn btn-sm btn-dark fw-bold'  type='button' onClick={handleSave} tabIndex={8} autoFocus>
              {intl.formatMessage({id: 'MODIFYRECORD.MODAL.BUTTON.SAVE'})}
            </button>
          </div>

        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModifyRecords}
