import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { useAppSelector } from '../../../redux/hooks';
import { useState } from 'react';
import { useAlert, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import { KTSVG } from '../../../../_metronic/helpers';
import { downloadFileFromBuffer } from '../../../modules/sgl-utils/SglFunctions';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import Flatpickr from 'react-flatpickr'
import { date_options, setDate, maskDateInput } from '../../../modules/sgl-utils/fieldControls';

// Constants
const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const ExportAccountRegister = ({ show, handleClose }) => {
    const intl = useIntl()
    const customerID = useAppSelector(state => state.showCompany.company_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const alertDialog = useAlert()
    const { getEventID } = useOutputContext();
    const {progressBar} = useProgress()
    const [startDate, setStartDate] = useState('00-00-00');
    const [endDate, setEndDate] = useState('00-00-00');
    const moment = require('moment');

    // Export account register
    const exportReport = async () => {
        let eventID = getEventID('export-account-register');
        handleClose();
        progressBar({ show: true, eventID, timeElapsed: true, title: 'Export Account Register' })
        axios.post(`${NEST_API_URL}/people/exportAccountRegister`, {
            customer_id: customerID,
            show_id: currentShowID,
            progress_event_id: eventID,
            start_date: startDate,
            end_date: endDate
        })
            .then(async response => {
                if (response.data.success) {
                    downloadFileFromBuffer(response.data.document, response.data.fileName, response.data.fileType)
                } else {
                    alertDialog({ message: response?.data?.errorMessage })
                }
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({ message: intl.formatMessage({ id: 'GENERIC.ERROR.MESSAGE' }) });
                }
            })
            .finally(() => {
                progressBar({ show: false, eventID })
            })
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-400px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    exportReport()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'PEOPLE.QUICKACTION.ACCOUNTREGISTER.POPUP.HEADING' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            {/* Body */}
            <div className='modal-body py-3 px-4'>
                <div className='row mb-2'>
                    <label className='col-lg-12 col-form-label fs-5 py-1 w-lg-140px w-lg-140px fw-bold'>
                        {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.ACCOUNTREGISTER.POPUP.LABEL.ENTERPERIOD' })}
                    </label>
                </div>

                <div className='row mb-2'>
                    <label className='col-lg-3 col-form-label  fs-5 py-1 w-lg-140px w-lg-140px' htmlFor='startDate'>{intl.formatMessage({ id: 'PEOPLE.QUICKACTION.ACCOUNTREGISTER.POPUP.LABEL.STARTDATE' })}</label>
                    <div className='col-lg-9'>
                        <div className="input-group date">
                            <Flatpickr
                                className="form-control form-control-sm fs-6 min-h-20px py-1"
                                id="startDate"
                                placeholder="MM/DD/YYYY"
                                value={startDate && startDate !== '00-00-00'
                                    ? moment(startDate).format('MM/DD/YYYY')
                                    : null}
                                options={date_options}
                                autoComplete="off"
                                onClose={(value, dateStr, instance) => {
                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();
                                    if (!isValidDate) {
                                        instance.input.value = ''; // Clear the input if the date is invalid
                                    }
                                    // Set the date with formatting logic
                                    setDate(dateStr, (formattedDate) => {
                                        setStartDate(formattedDate);
                                    }, '00-00-00');
                                }}
                                onChange={(value, dateStr, instance) => {
                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();
                                    if (!isValidDate) {
                                        instance.input.value = ''; // Clear the input if the date is invalid
                                    }
                                    // Set the date with formatting logic
                                    setDate(dateStr, (formattedDate) => {
                                        setStartDate(formattedDate);
                                    }, '00-00-00');
                                }}
                                onInput={(e) => { maskDateInput(e); }}
                                autoFocus
                                tabIndex={1}
                            />
                            <div className="input-group-append">
                                <label htmlFor='startDate' className='date-label'
                                    onClick={(e) => {
                                        e.stopPropagation(); 
                                        document.getElementById('startDate')._flatpickr.open(); 
                                    }}
                                >
                                    <span className="input-group-text">
                                        <i className="la la-calendar"></i>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-3 col-form-label  fs-5 py-1 w-lg-140px w-lg-140px' htmlFor='endDate'>{intl.formatMessage({ id: 'PEOPLE.QUICKACTION.ACCOUNTREGISTER.POPUP.LABEL.ENDDATE' })}</label>
                    <div className='col-lg-9'>
                        <div className="input-group date">
                            <Flatpickr
                                className="form-control form-control-sm fs-6 min-h-20px py-1"
                                placeholder="MM/DD/YYYY"
                                id="endDate"
                                value={endDate && endDate !== '00-00-00'
                                    ? moment(endDate).format('MM/DD/YYYY')
                                    : null}
                                options={date_options}
                                autoComplete="off"
                                onClose={(value, dateStr, instance) => {
                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();
                                    if (!isValidDate) {
                                        instance.input.value = ''; // Clear the input if the date is invalid
                                    } 
                                    // Set the date with formatting logic
                                    setDate(dateStr, (formattedDate) => {
                                        setEndDate(formattedDate);
                                    }, '00-00-00');
                                }}
                                onChange={(value, dateStr, instance) => {
                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();
                                    if (!isValidDate) {
                                        instance.input.value = ''; // Clear the input if the date is invalid
                                    } 
                                    // Set the date with formatting logic
                                    setDate(dateStr, (formattedDate) => {
                                        setEndDate(formattedDate);
                                    }, '00-00-00');
                                }}
                                onInput={(e) => { maskDateInput(e); }}
                                tabIndex={2}
                            />
                            <div className="input-group-append">
                                <label htmlFor='endDate' className='date-label'
                                    onClick={(e) => {
                                        e.stopPropagation(); 
                                        document.getElementById('endDate')._flatpickr.open(); 
                                    }}
                                >
                                    <span className="input-group-text">
                                        <i className="la la-calendar"></i>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-3 px-0'>

                    <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={3}>
                        {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.ACCOUNTREGISTER.POPUP.BUTTON.CANCEL' })}
                    </button>

                    <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={exportReport} tabIndex={4}>
                        {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.ACCOUNTREGISTER.POPUP.BUTTON.EXPORT' })}
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default ExportAccountRegister;
