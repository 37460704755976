import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useLoadingOverlay, useProgress, useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext, useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext';
import EntryFeesGrid from './RemoveFeeFromEntries/EntryFeesGrid';
import axios from 'axios';

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const RemoveFeeFromEntries = ({ show, handleClose }) => {
    const intl = useIntl() 
    const customerId = useAppSelector(state=> state.showCompany.company_id)
    const currentShowId = useAppSelector(state => state.currentShow.show_id)
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)

    const reasonInputRef = useRef()
    const [entryIds, setEntryIds] = useState([])
    const { getOutputSelectionAreaIDs, getEventID } = useOutputContext()
    const [gridRef, setGridRef] = useState()
    const outputContextUpdater = useOutputContextUpdater()

    const loadingOverlay = useLoadingOverlay()
    const {progressBar} = useProgress()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()

    // Get Ag-grid data
    const getRowData = (_gridRef) => {
        const rowData = []
        _gridRef.forEachNode(node => rowData.push(node.data));
        return rowData
    }

    useEffect(() => {
        if (show && gridRef) {
            if(showFinancialsLocked){
                alertDialog({ message: intl.formatMessage({ id: "Entries.QuickAction.RemoveFeeFromEntries.Error.ShowLocked" }), title: "warning" })
                onClose()
                return
            }
            loadingOverlay({ show: true })

            getOutputSelectionAreaIDs(false)
            .then(res => { 
                if (res.length === 0) {
                    alertDialog({ message: 'Please select some entries first.'})
                    handleClose()
                }
                setEntryIds(res)

                axios.post( NEST_API_URL + `/entries/getRemoveFeeFromEntriesMetadata`, {
                    customer_id: customerId,
                    current_show_id: currentShowId,
                    entry_ids: res
                })
                .then(({ data }) => {
                    gridRef.setRowData(data.entryFees)
                })

                loadingOverlay({ show: false })
            })
            .catch(() => loadingOverlay({ show: false }))
        }  
    }, [show, gridRef])

    const removeFeeFromEntries = async () => {
        const reason = reasonInputRef.current.value

        let message = ""
        const rowData = getRowData(gridRef)
        const selectedFees = rowData.filter(row => row.selected).map(row => row.showfees_id)

        if (selectedFees.length === 0) {
           message = "You must select some fee to remove from the entries in selection."
        } else if (reason === "") {
            message = "You must provide a reason to remove selected fees from the entries in selection."
        } else if (reason.length < 10) {
            message = "You must provide a reason of more than 10 characters to remove selected fees from the entries in selection."
        }

        if (message) {
            alertDialog({ message })
            return
        }
        
        message = "Do you really want to remove the "+ selectedFees.length +" selected fees from the " + entryIds.length +" entries in selection?" +" This action CANNOT BE UNDONE!"

        const choice = await confirmDialog({ message })

        if (choice) {
            const eventId = getEventID('remove-fee-from-entries')
            progressBar({ show: true, title: "Remove Fee From Entries", timeElapsed: true, eventID: eventId })
            axios.post(NEST_API_URL + '/entries/removeFeeFromEntries', { 
                selected_fees: selectedFees,
                customer_id: customerId,
                current_show_id: currentShowId,
                entry_ids: entryIds,
                event_id: eventId,
                reason
            })
            .then(() => progressBar({ show: false, eventID: eventId }))

            onClose()
        }
    }

    const onClose = () => {
        // Refresh Class List
        outputContextUpdater({action: 'refresh'})
        setGridRef(null)
        handleClose();  
    }
    
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px'
            show={show}
            onHide={onClose}
            backdrop='static'
            onEscapeKeyDown={onClose}
            onKeyDown={(event) => {
                let activeElement = document.activeElement
                let isButtonFocused = false
                if (activeElement && activeElement.tagName === 'BUTTON') {
                    isButtonFocused = true
                }
                if (!event.shiftKey && event.key === "Enter" && !isButtonFocused) {
                    removeFeeFromEntries()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'Entries.QuickAction.RemoveFeeFromEntries.Heading' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='card-body p-0 px-2'>
                    <div className='d-flex flex-column mb-2'>
                        <label className='col col-form-label fs-5 py-0 fw-bold'>
                            { intl.formatMessage({ id: 'Entries.QuickAction.RemoveFeeFromEntries.Subheading' })}
                        </label>
                        <label className='col col-form-label fs-5 py-1' htmlFor="enterClasses">
                            { intl.formatMessage({ id: 'Entries.QuickAction.RemoveFeeFromEntries.Label.TotalEntriesSelected' }) + ": " + entryIds.length }
                        </label>
                    </div>
                    <div className='row mb-2'>
                        <label className='col-lg-2 col-form-label fs-5 pt-0 fw-bold'>
                            { intl.formatMessage({ id: 'Entries.QuickAction.RemoveFeeFromEntries.Label.Reason' })}
                        </label>
                        <div className='col'>
                            <textarea
                                className='form-control h-70px form-control fs-6'
                                ref={reasonInputRef}
                                autoFocus
                                tabIndex={1}
                            />
                        </div>
                    </div>
                    <EntryFeesGrid 
                        setGridRef={setGridRef}
                        tabIndex={2}
                    />
                </div>
         
                <div className='card-footer d-flex justify-content-end py-3 px-0'>
                    <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={3}>
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CANCEL" })}
                    </button>

                    <button 
                        type="button" 
                        className='btn btn-sm btn-dark fw-bold text-uppercase'
                        onClick={removeFeeFromEntries}
                        tabIndex={4}
                        >
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.EXECUTE" })}
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default RemoveFeeFromEntries