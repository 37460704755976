import {useEffect, useRef} from 'react'
import {handlePrompt} from '../sgl-utils/unstableHistory'

const useNavigationPrompt = (formState) => {
  // Use ref to track if we already have an active prompt
  const isPromptActive = useRef(false);

  useEffect(() => {
    let unblock
    const {dirtyFields} = formState
    const fieldsToExclude = [
      'balance',
      'class_fees_subtotal',
      'entry_fee_subtotal',
      'tax',
      'total_fees',
      'total_paid',
    ]
    const isExcludedFieldDirty = Object.keys(dirtyFields).some((fieldName) =>
      fieldsToExclude.includes(fieldName)
    )

    // Block navigation and register a callback that fires when a navigation attempt is blocked.
    if (formState.isDirty && Object.values(dirtyFields).length > 0 && !isExcludedFieldDirty && !isPromptActive.current) {
      console.warn('Dirty: ', dirtyFields)
      isPromptActive.current = true;
      unblock = handlePrompt()
    }
    return () => {
      if (typeof unblock === 'function') {
        isPromptActive.current = false;
        unblock()
      }
    }
  }) // To force useEffect to run again when a prop changes.
}

export default useNavigationPrompt