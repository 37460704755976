import { reactSelectStyles } from "./fieldControls";
import Select from "react-select";
import { Controller } from "react-hook-form";


// This component will implement react-select with react-hook-form and made for generic implementation of react-select
// If you don't have methods defined, send them as null
const ReactSelectComponent = ({control, options, name, methods, id='#', disabled=false, tabIndex=-999}) => { //-999 tabIndex used by default, in case no tabIndex was passed
    // If we get methods then save the values
    const onChangeHandler = (option) => {
        if (methods) {
            methods.setValue(name, option.value, {shouldDirty: true});
        };
    }
    
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={options[0]?.value}
            render={({ field: { onChange, value, name } }) => (
                <Select
                    inputId={id}
                    name={name}
                    options={options}
                    menuPosition="fixed"
                    onChange={(option) => {onChangeHandler(option); onChange(option.value)}}                    
                    value={options?.find((option) => option.value == value)}
                    theme={(theme) => ({ ...theme, borderRadius: 0 })}
                    placeholder='Select'
                    styles={reactSelectStyles}
                    defaultValue={options[0]}
                    isDisabled={disabled}
                    {...(tabIndex !== -999 && { tabIndex })} //tabIndex is set conditionally, only if a prop was passed, provided that the prop is not -999
                />
            )}
        />
    );
};

export default ReactSelectComponent;