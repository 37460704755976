import React, {useEffect, useState} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import axios from 'axios'
import { KTSVG } from '../../../../_metronic/helpers'
import { useAlert, useConfirm, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider'
import { useAppSelector } from '../../../redux/hooks'
import { useOutputContext, useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext'
import { loadingSpinnerBtnRelease, loadingSpinnerBtnReleaseByEnter, loadingSpinnerBtnWait, loadingSpinnerBtnWaitByEnter } from '../../../modules/sgl-utils/SglFunctions'
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls'
import Select from 'react-select';
import { NumericFormat } from 'react-number-format'

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
 
const BulkSectionCode = ({show, handleClose}) => {
    const intl = useIntl() 
    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const {getOutputSelectionAreaIDs, getEventID} = useOutputContext()
    const outputContextUpdater = useOutputContextUpdater()
    const customerId = useAppSelector((state) => state.showCompany.company_id)
    const showId = useAppSelector(state => state.currentShow.show_id);
    const [sectionCode, setSectionCode] = useState('');
    const [classIds, setClassIds] = useState([])
    const [displaySectionCodeModal, setDisplaySectionCodeModal] = useState(false);
    const [updateMessage, setUpdateMessage] = useState('')
    const [org, setOrg] = useState('USEF');
    const [metaData, setMetaData] = useState({});
    const [error, setError] = useState('');

    const closeDialogs = () => {
        outputContextUpdater({action: 'unselectAll'})
        setDisplaySectionCodeModal(false)
        handleClose()
    }

    const submitSectionCode = async (e) => {
        //Webservice to remove official from selected classes >>>
        loadingSpinnerBtnWaitByEnter(e)

        axios.post(`${NEST_API_URL}/classes/bulkAssignSectionCode`, {
            class_ids: classIds,
            show_id: showId,
            org,
            section_code: sectionCode,
            customer_id: customerId,
        }).then(res => {
            if(res.data.success){
                setUpdateMessage(res.data.updateMessage)
                setSectionCode('')
                setError('')
            }else{
                setError(res.data.error)
                setUpdateMessage('')
            }
        }).catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        }).finally(() => {
            loadingSpinnerBtnReleaseByEnter(e)
            // loadingOverlay({show:false})
        })
    }
    
    useEffect(() => {
        if (show) {
            loadingOverlay({ show: true })

            //Get selected Class ID's
            getOutputSelectionAreaIDs(false)
            .then(res => { 
                if (res.length === 0) {
                    loadingOverlay({ show: false })
                    // error message if no class is selected
                    alertDialog({ message: intl.formatMessage({ id: "CLASSES.QUICKACTION.POPUP.HEADING.ASSIGN.SECTION.CODE.TO.CLASSES.ERROMESSAGE.SELECTCLASSES"}) })
                    handleClose()
                }
                else{
                    loadingOverlay({ show: false })
                    // if user selected any class
                    setClassIds(res)
                    setDisplaySectionCodeModal(true)
                    setOrg('USEF')
                    axios.get(NEST_API_URL + '/classes/classMetaData', {
                        params: {
                            class_id: res[0],
                            customer_id: customerId
                        }
                    })
                    .then(res => {
                        setMetaData(res.data)
                    }).catch(e => 
                        console.log(e)
                    )
                }
            })
            .catch(() => loadingOverlay({ show: false }))
        }  
    }, [show])

    return createPortal(
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
                show={displaySectionCodeModal}
                onHide={closeDialogs}
                onKeyDown={(event) => {
                    let isButtonFocused = false
                    let activeElement = document.activeElement //gets the currently focussed element
                    if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                        isButtonFocused = true;
                    }
                    if (event.key === "Enter" && !isButtonFocused) {
                        let okButton = document.getElementById('AddSectionCodeButton')
                        submitSectionCode(okButton)
                    }
                    if(event.key === 'Escape'){
                        closeDialogs()
                    }
                }}
            >
                <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
                <div className='modal-header py-0 px-4'>
                    <h2 className="fs-4">{intl.formatMessage({ id: "CLASSES.QUICKACTION.POPUP.HEADING.ASSIGN.SECTION.CODE.TO.CLASSES" })}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={closeDialogs}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
    
                <div className='modal-body py-3 px-4'>
                    <div className='row mb-2'>
                        <div className='col-lg-12 d-flex align-items-center'>
                            <input className="form-check-sm me-1" type="radio" value="USEF" id="USEF" name='org' checked={org == 'USEF'} onClick={e => {setOrg('USEF'); setSectionCode('');setUpdateMessage('');setError('')}} />
                            <label className='col-form-label fs-5 py-1 ignore-max-width me-5' htmlFor='USEF'> {intl.formatMessage({ id: 'CLASSES.QUICKACTION.POPUP.ASSIGN.SECTION.CODE.TO.CLASSES.LABEL.USEF' })}</label>

                            <input className="form-check-sm me-1" type="radio" value="EC" id="EC"  name='org' checked={org == 'EC'}  onClick={e => {setOrg('EC'); setSectionCode('');setUpdateMessage('');setError('')}}/>
                            <label className='col-form-label fs-5 py-1 ignore-max-width' htmlFor='EC'> {intl.formatMessage({ id: 'CLASSES.QUICKACTION.POPUP.ASSIGN.SECTION.CODE.TO.CLASSES.LABEL.EC' })}</label>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <label className='col-form-label fs-5 py-1 w-125px pe-0' htmlFor='section_code'  data-tooltip-id="CLASS.DETAIL.TAB.GENERAL.SECTION.CLASSSPECIFICATIONS.LABEL.USEFSECTION">{org == 'USEF'? intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.USEFSECTION' }): intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.ECSECTION' })}</label>
                        <div className='col align-items-center ps-0'>
                            <div className='row'>
                                <div className='col-lg-3'>
                                        <NumericFormat
                                        id="section_code"
                                        type="text"
                                        allowNegative={false}
                                        decimalScale={0}
                                        value={sectionCode}
                                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                                        onChange={(e) => {
                                            let sectionCodeList = org == 'USEF'? metaData.usef_section : metaData.ec_section
                                            if(sectionCodeList.filter(sc => sc.value == e.target.value).length > 0){
                                                setSectionCode(e.target.value)
                                            }
                                            else{
                                                setSectionCode(null)
                                            }
                                        }}
                                        tabIndex={1}
                                        autoFocus
                                        />
                                </div>
                                <div className='col-lg-9'>
                                    <Select
                                        options = {org == 'USEF'? metaData.usef_section : metaData.ec_section}
                                        getOptionLabel={option =>
                                            `${option.label} [${option.value}]`
                                        }
                                        value={sectionCode !== undefined ? org == 'USEF'? metaData?.usef_section?.filter((ss) => ss.value === sectionCode): metaData?.ec_section?.filter((ss) => ss.value == sectionCode):""}
                                        id = "section_code"
                                        isSearchable={true}
                                        onChange={(e)=>{
                                            setSectionCode(e.value)
                                            }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                        })}
                                        styles={reactSelectStyles}
                                        placeholder="Select"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-between py-3 px-0'>
                        
                        <div className='label-left-align mw-350px' style={{fontWeight: 'bold'}}>
                            { updateMessage ? <label className='text-green fw-bold'>{updateMessage}</label> :null }
                            { error? <label className='text-red fw-bold'>{error}</label> :null }
                        </div>
                        
                        <div className='buttons-right-align'>
                            <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={closeDialogs} tabIndex={2}>
                                {intl.formatMessage({ id: 'CLASSES.ADD.SECTION.CODE.DIALOG.BUTTON.DONE' })}
                            </button>

                            <button id='AddSectionCodeButton' type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={(event) => submitSectionCode(event)} tabIndex={3} >
                                <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                {intl.formatMessage({ id: 'CLASSES.ADD.SECTION.CODE.DIALOG.BUTTON.SET' })}
                            </button>
                        </div>
                    </div>

                </div>
      </Modal>
      ,modalsRoot
    )
}

export default BulkSectionCode