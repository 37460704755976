import axios from "axios";
import Decimal from 'decimal.js';
import moment from "moment";
import { Buffer } from 'buffer';

//Function to handle Invalid Records which will navigate to list page if (record does not exist)
export const isInvalidRecord = (recordData, recordId, idFieldName) => {
    if ((!recordData || recordData === undefined || !recordData[idFieldName]) && (recordId > 0 || isNaN(recordId))){
        return true  //return true to redirect to listing page for specific input Area
    }
    return false
}

// Button loading spinner handlers
// Input: event object of button
// Requirement: spinner <span> must be first child of button
export const loadingSpinnerBtnWait = (event) => {
    // disable button
    event.target.disabled = true
    // show loading spinner
    const span = event.target.children[0]
    span.classList.remove("d-none")
}

export const loadingSpinnerBtnRelease = (event) => {
    // enable button
    event.target.disabled = false
    // hide loading spinner
    const span = event.target.children[0]
    span.classList.add("d-none")
}

export const loadingSpinnerBtnWaitByEnter = (eventOrButton) => { // handles the case where enter was pressed to submit in modal
    if(eventOrButton instanceof HTMLElement){ //if submit button is passed (from enter press)
        // disable button
        eventOrButton.disabled = true
        // show loading spinner
        const span = eventOrButton.querySelector('span')
        span.classList.remove("d-none")
    }
    else{ //mouse click
        // disable button
        eventOrButton.target.disabled = true
        // show loading spinner
        const span = eventOrButton.target.children[0]
        span.classList.remove("d-none")
    }
}

export const loadingSpinnerBtnReleaseByEnter = (eventOrButton) => { // handles the case where enter was pressed to submit in modal
    if(eventOrButton instanceof HTMLElement){ //if submit button is passed (from enter press)
        // enable button
        eventOrButton.disabled = false
        // hide loading spinner
        const span = eventOrButton.querySelector('span');
        span.classList.add("d-none")
    }
    else{
        // enable button
        eventOrButton.target.disabled = false
        // hide loading spinner
        const span = eventOrButton.target.children[0]
        span.classList.add("d-none")
    }
}

//Use this function if you want to show loading spinner for a button but event is not called from onclick of that button. See example call in announcer tool scoringmode.js file
export const overrideEventTarget = (event, elementId) => {
    let element = document.getElementById(elementId);
    return element ? Object.create(event, { target: { value: element }}) : event;
}

/*
    Truncates a number upto numPlaces number of decimal points
    NOTE: It doesn't round up the number before truncating.

    EXAMPLE: 
    Input:  number = 6.666666, numPlaces = 2
    Output: 6.66 
*/
export const truncateToNumPlaces = (number, numPlaces) => {
    let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (numPlaces || -1) + '})?')
    return parseFloat(number.toString().match(re)[0])
}

// ! 4D -> inf_minNaturalNumber
const firstSeparatorPosition = (positions) => {
    let minPosition = -1;
    for(let position of positions){
        if(position >= 0){
            if(minPosition < 0){
                minPosition = position;
            }

            if(minPosition > position){
                minPosition = position;
            }
        }
    }
    return minPosition;
}

// ! 4D -> inf_minNaturalNumber
const getNumber = (text) => {
    return !isNaN(text) ? Math.abs(parseFloat(text)) : 0;
}

// ! 4D -> CreateArrayOfClassNumbers
export const createArrayOfNumbersFromText = (text, returnUnique = true) => {
    let numbersList = []

    while(text != ''){
        let comma = text.indexOf(','),
        space = text.indexOf(' '),
        plus = text.indexOf('+'),
        period = text.indexOf('.'),
        start = -1, end = -1;

        let pos = firstSeparatorPosition([comma, space, plus, period])  //calculate the minimum natural number
        
        //If we found something pull out what we found and process it.
        let tmpString = '';
        if(pos >= 0){
            tmpString = text.substring(0, pos);
            text = text.substring(pos + 1)
        }
        else{
            tmpString = text;
            text = '';
        }

        if(tmpString != ''){ //could have nothing here...two commas in a row?
            let dashPos = tmpString.indexOf('-')
            if(dashPos >= 0){
                start = getNumber(tmpString.substring(0, dashPos));
                end = getNumber(tmpString.substring(dashPos + 1));
            }
            else{
                start = getNumber(tmpString);
                end = start;
            }

            if(start <= end){ //make sure the start is less than the end
                for(let i = start; i <= end; i++ ){
                    if(returnUnique){
                        if(numbersList.indexOf(i) >= 0){
                            continue;
                        }
                    }
                    numbersList.push(i)
                }
            }
        }
    }

    return numbersList;
}

//toFixedTrunc(1.4456, 3) -> '1.445'  
// x -> number
// precision -> floating points/places (for values < 0, it will ignore applying precision, override to ignore precision rounding)
export const toFixedTrunc = (x, returnType = 'string', precision = 3) => {
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    v[0] = (Boolean(v[0]) && !isNaN(v[0])) ? v[0] : '0';
    let f = v[1] || '';
    if (precision <= 0){ //send negative value for no precision
        let value = (f && precision < 0) ? `${v[0]}.${f}` : `${v[0]}`;
        return returnType == 'string' ? `${value}` : parseFloat(`${value}`); 
    }
    if (f.length > precision) { f = f.substr(0, precision); }
    while (f.length < precision){ f += '0'; }
    return returnType == 'string' ? `${v[0]}.${f}` : parseFloat(`${v[0]}.${f}`);
}

// arrays -> numbers to add 
// precision -> upto decimal points/places (use -1 precision value if you need to avoid truncation of floating numbers)
export const addFloatingNumbers = (numbers, precision, truncateValuesBeforeAdd = false) => {
    let sum = new Decimal(0) // Initialize Sum
    let num = new Decimal(0)
    for (let number of numbers) {
        if (isNaN(number) || !number || Boolean(number) == false) {
            if(isNaN(number) || number != 0){ 
                console.warn(`Value: ${number} is not a number`)
            }
            continue
        }
        
        if(truncateValuesBeforeAdd){ //For scoring/announcer tool, we need to sum truncated version of values
            num = new Decimal(toFixedTrunc(number, 'number', precision))
        }
        else{ //For accounting and other areas, we need to round decimal floating points after calculating sum
            num = new Decimal(number)
        }

        sum = sum.plus(num)
    }
    
    //check if we need to return sum after rounding the original value w.r.t required precision
    if(!truncateValuesBeforeAdd && precision > 0){
        return roundFloatingNumber(parseFloat(sum.toString()), precision)
    }

    return parseFloat(sum.toString())
}

export const roundFloatingNumber = (number, precision) => {
    if (isNaN(number)) {
        number = 0;
    }

    const factor = Math.pow(10, precision);
    return Math.round( number * factor ) / factor;    
}

/**
* Sorts an array of objects by column/property.
* @param {Array} array - The array of objects.
* @param {object} sortObject - The object that contains the sort order keys with directions (asc/desc). e.g. { age: 'desc', name: 'asc' }
* @returns {Array} The sorted array.
*/
export const multiSort = (array, sortObject = {}) => {
    const sortKeys = Object.keys(sortObject);
    
    // Return array if no sort object is supplied.
    if (!sortKeys.length) {
        return array;
    }
    
    // Change the values of the sortObject keys to -1, 0, or 1.
    for (let key in sortObject) {
        sortObject[key] = sortObject[key] === 'desc' || sortObject[key] === -1 ? -1
                        : (sortObject[key] === 'skip' || sortObject[key] === 0 ? 0 : 1);
    }
    
    const keySort = (a, b, direction) => {
        direction = direction !== null ? direction : 1;
        
        if (a === b) { // If the values are the same, do not switch positions.
            return 0;
        }
        
        // If b > a, multiply by -1 to get the reverse direction.
        return a > b ? direction : -1 * direction;
    };
    
    return array.sort((a, b) => {
        let sorted = 0;
        let index = 0;
        
        // Loop until sorted (-1 or 1) or until the sort keys have been processed.
        while (sorted === 0 && index < sortKeys.length) {
            const key = sortKeys[index];
            
            if (key) {
                const direction = sortObject[key];
                
                sorted = keySort(a[key], b[key], direction);
                index++;
            }
        }
    
        return sorted;
    });
}

/**
* Sorts an array of objects by column/property.
* @param {number} currentShowID - The id of current show
* @param {number} customerID - The customer id
* @param {object} data - The object that will be store in site_monitoring_activity_log table 
e.g. {
       source: 'Quick Actions', // Reference e.g: Quick Search, Advance Search etc
       tab_name: 'Classes', // Area 
       activity: 'Announcer Interface', // Action name
       class_group_id: 12,
       ring_no: 9,
       trip_id: 12345,
       description: "Open"
     }
Note: the keys(e.g source, ring_no) in data object should be kept same otherwise the respective data will not be saved
*/
export const logSiteActivity = async (currentShowID, customerID, data) => {
    if(!customerID) 
        return false
    axios.post( process.env.REACT_APP_NEST_API_URL + '/utility/logSiteActivity', {
        show_id: currentShowID,
        customer_id: customerID,
        data: data
    })
}

//check if the string is valid json or not
//Note: This function works for simple object don't use it for nested object
export const isValidJson = (string) => {
    try {
        JSON.parse(string);
    } catch (e) {
        return false;
    }
    return true;
}

export const validateJSON = (data) => {
    try {
        const parsedJSON = JSON.parse(data);
        return { success: true, parsedJSON }
    } catch (e) {
        return { success: false, parsedJSON: null }
    }
}

// Deep copies an array of objects. Checks only 1 level down. 
export const deepCopyArrayOfObjects = (arrayOfObject) => {
    const newArrayOfObjects = []
    for (const object of arrayOfObject) {
        newArrayOfObjects.push({ ...object })
    }
    return newArrayOfObjects
}

export const getArrayFromObject = (column, recordSet, getUnique = false) => {
    let result = [];
    for (let key of Object.keys(recordSet)) {
        result.push(recordSet[key][column]);
    }

    if(getUnique){ //get distinct values
        result = [...new Set(result)]
    }
    return result;
}

export const getCurrentDateTimeFormatted = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${day}-${month}-${year}-${hours}-${minutes}-${seconds}`;
    return formattedDateTime;
};

export const downloadTextFile = (textData, file_name, file_type = "text/plain") => {
    console.log('downloading text file')
    const blob = new Blob([textData], { type: file_type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};
//Check if the manual membership override is allowed or not
export const allowManualMembershipOverrideForHorse = (orgCode) => {
    return ((orgCode == "USEF") || (orgCode == "USHJA") || (orgCode == "EC") || (orgCode == "FEI"))
}

export const getDefaultOverrideDate = (currentShowEndDate) => {
    let currentDate = moment()
    let showEndDate = moment(currentShowEndDate)
    if (currentShowEndDate !== '0000-00-00' && showEndDate.isAfter(currentDate)) {
        return moment(currentShowEndDate).add(1, 'days').format('YYYY-MM-DD');
    } else {
        return currentDate.format('YYYY-MM-DD')
    }
}

export function findReplaceOrAdd(arrayOfObjects, newElement, field) {

    if(!arrayOfObjects){
        return []
    }

    if(!newElement){
        return arrayOfObjects
    }
    // Create a copy of the original array
    const newArray = [...arrayOfObjects];
  
    // Find the index of the element to replace
    const index = newArray.findIndex(item => item[field] === newElement[field]);
  
    if (index !== -1) {
      // Element found, replace it
      if(newElement !== null){//Don't remove this check
        newArray[index] = newElement;
      }
    } else {
      // Element not found, add it
      newArray.push(newElement);
    }
  
    return newArray;
}

export function removeElementFromArray(arr, elementToRemove, field) {
    if(!arr){
        return []
    }

    if(!elementToRemove){
        return arr
    }
    return arr.filter(item => item[field] !== elementToRemove[field]);
}

//! 4D -> bf_FirstName
export const getFirstNameFromSearchString = (search_text) => {
    let index = search_text.indexOf(",")
    if (index > 0){
            return search_text.substring(index+1).trim()
    } else { 
        index = search_text.indexOf(" ")

        if (index > 0){
            return search_text.substring(0, index).trim()

        } else { 
            return""
        }
    }
}

//! 4D -> bf_LastName
export const getLastNameFromSearchString = (search_text) => {
    let index = search_text.indexOf(",")
    if (index > 0){
        return search_text.substring(0, index).trim()
    } else { 
        index = search_text.indexOf(" ")

        if (index > 0){
            return search_text.substring(index+1).trim()

        } else { 
            return search_text
        }
    }
}

// For excel file type should be 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const downloadFileFromBuffer = (buffer, fileName, fileType) => {
    // Create a Blob from the buffer
    buffer = Buffer.from(buffer, 'base64')
    const blob = new Blob([buffer], { type: fileType });

    // Check if the browser supports the 'a' (anchor) element download attribute
    if ('download' in document.createElement('a')) {
        // Create a link element
        const link = document.createElement('a');
        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Set link's attributes
        link.href = url;
        link.download = fileName;

        // Simulate a click on the link to trigger download
        link.click();

        // Clean up the URL object after the download is triggered
        URL.revokeObjectURL(url);
    } else {
        // Fallback for browsers that don't support the download attribute
        // Redirect to Blob's URL, which will trigger download but won't set the file name
        const url = URL.createObjectURL(blob);
        window.location.href = url;
        URL.revokeObjectURL(url);
    }
};

export const getCurrentTimeStamp = () => {
    return moment().unix()
}

export const getUserName = (person) => {
    let userName = person.full_name
    if (!userName) {
        if (person.first || person.last_name) {
            userName = `${person.first} ${person.last_name}`
        } else if (person.fl_name) {
            userName = person.fl_name
        } else if (person.lf_name) {
            userName = person.lf_name
        }
    } 
    
    return userName
}

export const removeDialogFromSession = (key) => {
    const storedModals = sessionStorage.getItem('modals_open');
    const modals_open = storedModals ? JSON.parse(storedModals) : [];
    const lastModal = modals_open.pop();
    if(lastModal === key){
      sessionStorage.setItem('modals_open',  JSON.stringify(modals_open))
    }
}

// Method: inf_ChckSocialFEDID_For_AAPM
// Description
// New policy of prize money not being auto applied if the Social or FED ID fields are blank.  If there is alpha
// character in either then it is ok to apply prize money.
export const checkSocialOrFederalId = (people) => {
    if(!people){
        return false
    }

    if (people.foreigner) {
        return true
    }

    if (!people.federal_id && !people.social_security_number) {
        return false
    }

    //Fed ID is empty but SSN is not
    let ssn = people.social_security_number.replace("_", "")
    ssn = ssn?.replace(" ", "")
        
    if (ssn) {
        return true
    }
    
    let federalId = people.federal_id.replace("_", "")
    federalId = federalId?.replace(" ", "")
        
    if (federalId) {
       return true
    }    
        
    return false
}

// Return which field will be disable based on RTO prize_prefence value.
// Used in RTO Detail (general tab), Entry Detail (PMR section), Payment Batch (post payments tab)
export const getDisabledFieldsForRtoPreference = (prizePreference) => {
    let disableFields = []  
    if(prizePreference == 'Wire Transfer'){
        disableFields = ['routing_number']
    }else if(['ACH', 'EFT'].includes(prizePreference)){
        disableFields = ['bank_name', 'swift_code']
    }else{
        disableFields = ['bank_name', 'account_number', 'routing_number', 'swift_code']
    }
    
    return disableFields
}