import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import Select from "react-select";
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls';
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import { horseConvertHeight } from '../../horses/Detail/AdditonalTab/MembershipFunctions';
import axios from 'axios';

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../modules/sgl-utils/SglFunctions';

const modalsRoot = document.getElementById('root-modals') || document.body

const AddRequirementToClasses = ({ show, handleClose }) => {
    const intl = useIntl() 
    const { getOutputSelectionAreaIDs, getEventID } = useOutputContext();
    const confirmDialog = useConfirm()
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const [classIds, setClassIds] = useState([]) 
    const [formMeta, setFormMeta] = useState({})
    const loadingOverlay = useLoadingOverlay()
    const alertDialog = useAlert()
    const {progressBar} = useProgress()

    useEffect(() => {
        if (show) {
            loadingOverlay({ show: true })

            getOutputSelectionAreaIDs()
            .then(res => { 
                if (res.length === 0) {
                    alertDialog({ message: intl.formatMessage({ id: "CLASSES.QUICKACTION.MODAL.LABEL.ADDREQUIREMENTTOCLASSES.SELECTCLASSMESSAGE"}) })
                    handleClose()
                }
                setClassIds(res)
                loadingOverlay({ show: false })
            })
            .catch(() => loadingOverlay({ show: false }))

            axios.get( process.env.REACT_APP_NEST_API_URL + `/classes/classRequirementsMeta?customer_id=${customerId}`)
            .then(response => {
                setFormMeta(response.data)
            })
        }  
    }, [show])

    const [measurementCard, setMeasurementCard] = useState({ value: "", error_warn_status: "" })
    const [horseAgeVerified, setHorseAgeVerified] = useState({ value: "", error_warn_status: "" })
    const [horseAge, setHorseAge] = useState({ value: "", error_warn_status: "", to: 0, from: 0 })
    const [horseHeight, setHorseHeight] = useState({ value: "", error_warn_status: "", to: "", from: "" })
    const [horseType, setHorseType] = useState({ value: "", error_warn_status: "" })
    const [riderMembership, setRiderMembership] = useState({ value: "", error_warn_status: "" })
    const [riderAge, setRiderAge] = useState({ value: "", error_warn_status: "", to: 0, from: 0 })
    const [riderStatus, setRiderStatus] = useState({ value: "", error_warn_status: "" })
    const [isHorseAgeUpdated, setIsHorseAgeUpdated] = useState(false)
    const [isHorseHeightUpdated, setIsHorseHeightUpdated] = useState(false)
    const [isRiderAgeUpdated, setIsRiderAgeUpdated] = useState(false)

    const onClose = () => {
        handleClose();  
    }

    const clearRequirements = async (event) => {
        const choice = await confirmDialog({ message: "Are you sure you want to clear Class Requirements for " +  classIds.length + " record(s)?"})
        if (choice) {
        loadingSpinnerBtnWait(event)
           axios.post( process.env.REACT_APP_NEST_API_URL + "/classes/clearBulkClassRequirements", {
                customer_id: customerId,
                class_ids: classIds,
           })
           .then(() => loadingSpinnerBtnRelease(event))
           .catch(() => {})
        }
    }

    const validateAge = (value, validationType, validationFor) => {
        let isNumber = /^[0-9]+$/.test(value)
        switch (validationFor) {
            // Rider Age Validation
            case "Rider":
                // From age validation
                if (validationType === "From" && ((value >  riderAge.to &&  riderAge.to != 0) || value < 0 || !value || !isNumber)) {
                    setRiderAge(prevRiderAge => ({...prevRiderAge, from: 0}))
                } else if (validationType === "To" && ((value < riderAge.from && riderAge.from != 0) || value < 0 || !value || !isNumber)) { // To Age validation
                    setRiderAge(prevRiderAge => ({...prevRiderAge, to: 0}))
                }
                break
            // Horse Age Validation
            case "Horse":
                // From age validation
                if(validationType === "From" && ((value >  horseAge.to &&  horseAge.to != 0) || value < 0 || !value || !isNumber)){
                    setHorseAge(prevHorseAge => ({...prevHorseAge, from: 0}))
                } else if (validationType === "To" && ((value < horseAge.from && horseAge.from != 0) || value < 0 || !value || !isNumber)) { // To Age validation
                    setHorseAge(prevHorseAge => ({...prevHorseAge, to: 0}))
                }
        }
    }

    const validateHeight = (value, validationType, validationFor) => {
        switch (validationFor) {
            case "Horse":
                const fromHeightInCm = horseConvertHeight(horseHeight.from, 'cm');
                const toHeightInCm = horseConvertHeight(horseHeight.to, 'cm');
    
                if (validationType === "From") {
                    const heightInCm = horseConvertHeight(value, 'cm');
                    if (heightInCm === 0 && (toHeightInCm === 0 || horseHeight.to === "0")) {
                        return;
                    } else if ((heightInCm > toHeightInCm && toHeightInCm !== 0) || heightInCm < 0 || !heightInCm) {
                        setHorseHeight(prevHorseHeight => ({ ...prevHorseHeight, from: "" }));
                    }
                } else if (validationType === "To") {
                    const heightInCm = horseConvertHeight(value, 'cm');
                    if (heightInCm === 0 && (fromHeightInCm === 0 || horseHeight.from === "0")) {
                        return;
                    } else if ((heightInCm < fromHeightInCm && fromHeightInCm !== 0) || heightInCm < 0 || !heightInCm) {
                        setHorseHeight(prevHorseHeight => ({ ...prevHorseHeight, to: "" }));
                    }
                }
                break;
            default:
                break;
        }
    }
    
    
    const addRequirements = async () => {
        let classRequirements = {
            // Horse_Age: {...horseAge},
            Horse_Age_Verified: {...horseAgeVerified},
            Horse_Type: {...horseType},
            Horse_Height: {...horseHeight},
            // Rider_Age: {...riderAge},
            Rider_Status: {...riderStatus},
            Rider_Membership: {...riderMembership},
            Measurement_Card: {...measurementCard}
        }

        // Filter out any empty or unchanged requirements
        classRequirements = Object.fromEntries(
            Object.entries(classRequirements).filter(([key, value]) => value.value != "")
        );

        if(isHorseAgeUpdated){
            classRequirements = {...classRequirements, Horse_Age: {...horseAge}}
        }

        if(isRiderAgeUpdated){
            classRequirements = {...classRequirements, Rider_Age: {...riderAge}}
        }

        if(isHorseHeightUpdated){
            classRequirements = {...classRequirements, Horse_Type: {...horseType}}
            classRequirements = {...classRequirements, Horse_Height: {...horseHeight}}
        }

        const eventID = getEventID("bulk-add-class-requirements") 
        progressBar({ show: true, eventID, showProgress: 'inline', title: "Adding class requirements"})
        await axios.post( process.env.REACT_APP_NEST_API_URL + "/classes/addBulkClassRequirements", {
            customer_id: customerId,
            class_ids: classIds,
            class_requirements: classRequirements,
            event_id: eventID
        })
        progressBar({ show: false, eventID })
    }
    
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-700px search-form'
            show={show}
            onHide={onClose}
            onKeyDown={(event) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) {
                    addRequirements()
                }
                else if(event.key === 'Escape'){
                    onClose()
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'CLASSES.QUICKACTION.MODAL.HEADING.ADDREQUIREMENTTOCLASSES' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <label className='col col-form-label fs-5 pt-0 fw-bold'>
                            Class Requirements will be applied for { classIds.length } record(s).
                        </label>
                        <fieldset>
                            <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.GROUPLABEL.HORSEREQUIREMENTS' })} </legend>
                        </fieldset>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0' htmlFor="measurementCard" data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDREQUIREMENTTOCLASSES.SECTION.HORSEREQUIREMENTS.LABEL.MEASCARD">
                            { intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.MEASCARD' }) }
                            </label>
                            <div className='col-lg-5'>
                                <Select
                                    styles={reactSelectStyles}
                                    inputId="measurementCard"
                                    options={formMeta.verifyAction}
                                    onChange={e => setMeasurementCard(prevMeasurementCard => ({ ...prevMeasurementCard, value: e.value }))}
                                    autoFocus
                                    tabIndex={1}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <Select
                                    styles={reactSelectStyles}
                                    options={formMeta.requirementAction}
                                    onChange={e => setMeasurementCard(prevMeasurementCard => ({ ...prevMeasurementCard, error_warn_status: e.value }))}
                                    tabIndex={2}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0' htmlFor="ageVerified" data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDREQUIREMENTTOCLASSES.SECTION.HORSEREQUIREMENTS.LABEL.AGEVERIFIED">
                            { intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.AGEVERIFIED' }) }
                            </label>
                            <div className='col-lg-5'>
                                <Select
                                    styles={reactSelectStyles}
                                    inputId="ageVerified"
                                    options={formMeta.verifyAction}
                                    onChange={e => setHorseAgeVerified(prevHorseAgeVerified => ({ ...prevHorseAgeVerified, value: e.value }))}
                                    tabIndex={3}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <Select
                                    styles={reactSelectStyles}
                                    options={formMeta.requirementAction}
                                    onChange={e => setHorseAgeVerified(prevHorseAgeVerified => ({ ...prevHorseAgeVerified, error_warn_status: e.value }))}
                                    tabIndex={4}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0' htmlFor="requiredAgeFrom" data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDREQUIREMENTTOCLASSES.SECTION.HORSEREQUIREMENTS.LABEL.REQUIREDAGE">
                            { intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.HORSEREQUIREDAGEFROM' }) }
                            </label>
                            <div className='col-lg-2'>
                                <input
                                    id="requiredAgeFrom"
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    onChange={e => {setHorseAge(prevHorseAge => ({...prevHorseAge, from: e.target.value}));setIsHorseAgeUpdated(true)}}
                                    value={horseAge.from}
                                    onBlur={e => validateAge(e.target.value, "From", "Horse")}
                                    tabIndex={5}
                                />
                            </div>
                            <div className='col-lg-1'>
                                <label className='col-lg-3 col-form-label fs-5 py-1' htmlFor="requiredAgeTo" data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDREQUIREMENTTOCLASSES.SECTION.HORSEREQUIREMENTS.LABEL.REQUIREDAGETO">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.HORSEREQUIREDAGETO' })}</label>
                            </div>
                            <div className='col-lg-2'>
                                <input
                                    id="requiredAgeTo"
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    onChange={e => {setHorseAge(prevHorseAge => ({...prevHorseAge, to: e.target.value}));setIsHorseAgeUpdated(true)}}
                                    value={horseAge.to}
                                    onBlur={e => validateAge(e.target.value, "To", "Horse")}
                                    tabIndex={6}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <Select
                                    styles={reactSelectStyles}
                                    options={formMeta.requirementAction}
                                    onChange={e => {setHorseAge(prevHorseAge => ({ ...prevHorseAge, error_warn_status: e.value }));setIsHorseAgeUpdated(true)}}
                                    tabIndex={7}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0' htmlFor='horseType' data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDREQUIREMENTTOCLASSES.SECTION.HORSEREQUIREMENTS.LABEL.HORSETYPE">
                            { intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.HORSETYPE' }) }
                            </label>
                            <div className='col-lg-5'>
                                <select
                                    id="horseType"
                                    multiple
                                    className='form-select  form-select-sm fs-6 min-h-20px py-1'
                                    onChange={(e) => {
                                        const options = e.target.options;
                                        const selectedOption = options[e.target.selectedIndex];
                                        const isSelectOption = selectedOption.value === '';
                                
                                        if (isSelectOption) {
                                            for (let i = 0; i < options.length; i++) {
                                                if (i !== e.target.selectedIndex) {
                                                    options[i].selected = false;
                                                }
                                            }
                                        }
                               
                                        setHorseType(prevHorseType => ({...prevHorseType, value: Array.from(e.target.selectedOptions).map(option => option.value) }))

                                        console.log(horseType)
                                    }}
                                    // onChange={e => setHorseType(prevHorseType => ({...prevHorseType, value: Array.from(e.target.selectedOptions).map(option => option.value) })) }
                                    disabled={(horseHeight.to && horseHeight.to !== "") || (horseHeight.from && horseHeight.from !== "")}
                                    tabIndex={8}
                                >
                                    { formMeta.horseType?.map(ht => <option value={ht.value}>{ ht.label }</option> )}
                                </select>
                            </div>
                            <div className='col-lg-4'>
                                <Select
                                    styles={reactSelectStyles}
                                    options={formMeta.requirementAction}
                                    onChange={e => setHorseType(prevHorseType => ({ ...prevHorseType, error_warn_status: e.value }))}
                                    isDisabled={(horseHeight.to && horseHeight.to !== "") || (horseHeight.from && horseHeight.from !== "")}
                                    tabIndex={9}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0' htmlFor="requiredHeightFrom" data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDREQUIREMENTTOCLASSES.SECTION.HORSEREQUIREMENTS.LABELHORSEHEIGHT">
                            { intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.HORSEREQUIREDHEIGHTFROM' }) }
                            </label>
                            <div className='col-lg-2'>
                                <input
                                    id="requiredHeightFrom"
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    onChange={e => {setHorseHeight(prevHorseHeight => ({...prevHorseHeight, from: e.target.value}));setIsHorseHeightUpdated(true)}}
                                    value={horseHeight.from}
                                    onBlur={e => validateHeight(e.target.value, "From", "Horse")}
                                    disabled={horseType.value && horseType.value !== '' && !horseType.value?.includes("") ? true : false}
                                    tabIndex={10}
                                />
                                {
                                    horseHeight?.from && horseHeight?.from !== "" ?
                                        <div className="mt-1">
                                            <span className='fs-6'>{horseConvertHeight(horseHeight.from, 'cm')} cm</span>
                                        </div>
                                    :   ""
                                }
                            </div>
                            <div className='col-lg-1'>
                                <label className='col-lg-3 col-form-label fs-5 py-1' htmlFor="requiredHeightTo" data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDREQUIREMENTTOCLASSES.SECTION.HORSEREQUIREMENTS.LABEL.HORSEHEIGTHTO">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.HORSEREQUIREDAGETO' })}</label>
                            </div>
                            <div className='col-lg-2'>
                                <input
                                    id="requiredHeightTo"
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    onChange={e => {setHorseHeight(prevHorseHeight => ({...prevHorseHeight, to: e.target.value}));setIsHorseHeightUpdated(true)}}
                                    value={horseHeight.to}
                                    onBlur={e => validateHeight(e.target.value, "To", "Horse")}
                                    disabled={horseType.value && horseType.value !== '' && !horseType.value?.includes("") ? true : false}
                                    tabIndex={11}
                                />
                                {
                                    horseHeight?.to && horseHeight?.to !== "" ?
                                        <div className="mt-1">
                                            <span className='fs-6'>{horseConvertHeight(horseHeight.to, 'cm')} cm</span>
                                        </div>
                                    :   ""
                                }
                            </div>
                            <div className='col-lg-4'>
                                <Select
                                    styles={reactSelectStyles}
                                    options={formMeta.requirementAction}
                                    onChange={e => {setHorseHeight(prevHorseHeight => ({ ...prevHorseHeight, error_warn_status: e.value }));setIsHorseHeightUpdated(true)}}
                                    isDisabled={horseType.value && horseType.value !== '' && !horseType.value?.includes("") ? true : false}
                                    tabIndex={12}
                                />
                            </div>
                        </div>
                        <fieldset>
                            <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.GROUPLABEL.RIDERREQUIREMENTS' })} </legend>
                        </fieldset>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0' htmlFor='memberOf' data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDREQUIREMENTTOCLASSES.SECTION.RIDERREQUIREMENTS.LABEL.MEMBEROF">
                            { intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.MEMBEROF' }) }
                            </label>
                            <div className='col-lg-5'>
                                <Select
                                    styles={reactSelectStyles}
                                    inputId="memberOf"
                                    options={formMeta.memberOf}
                                    onChange={e => setRiderMembership(prevRiderMembership => ({ ...prevRiderMembership, value: e.label }))}
                                    tabIndex={13}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <Select
                                    styles={reactSelectStyles}
                                    options={formMeta.requirementAction}
                                    onChange={e => setRiderMembership(prevRiderMembership => ({ ...prevRiderMembership, error_warn_status: e.value }))}
                                    tabIndex={14}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0' htmlFor="riderRequiredAgeFrom" data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDREQUIREMENTTOCLASSES.SECTION.RIDERREQUIREMENTS.LABEL.REQUIREDAGE">
                            { intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.HORSEREQUIREDAGEFROM' }) }
                            </label>
                            <div className='col-lg-2'>
                                <input
                                    onBlur={e => validateAge(e.target.value, "From", "Rider")}
                                    onChange={e => {setRiderAge(prevRiderAge => ({...prevRiderAge, from: e.target.value}));setIsRiderAgeUpdated(true)}}
                                    id="riderRequiredAgeFrom"
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    value={riderAge.from}
                                    tabIndex={15}
                                />
                            </div>
                            <div className='col-lg-1'>
                                <label className='col-lg-3 col-form-label fs-5 py-1' htmlFor="riderRequiredAgeTo" data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDREQUIREMENTTOCLASSES.SECTION.RIDERREQUIREMENTS.LABEL.REQUIREDAGETO">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.HORSEREQUIREDAGETO' })}</label>
                            </div>
                            <div className='col-lg-2'>
                                <input
                                    onBlur={e => validateAge(e.target.value, "To", "Rider")}
                                    onChange={e => {setRiderAge(prevRiderAge => ({...prevRiderAge, to: e.target.value}));setIsRiderAgeUpdated(true)}}
                                    id="riderRequiredAgeTo"
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    value={riderAge.to}
                                    tabIndex={16}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <Select
                                    styles={reactSelectStyles}
                                    options={formMeta.requirementAction}
                                    onChange={e => {setRiderAge(prevRiderAge => ({ ...prevRiderAge, error_warn_status: e.value }));setIsRiderAgeUpdated(true)}}
                                    tabIndex={17}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0' htmlFor="riderStatus" data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDREQUIREMENTTOCLASSES.SECTION.RIDERREQUIREMENTS.LABEL.RIDERSTATUS">
                                { intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.RIDERSTATUS' }) }
                            </label>
                            <div className='col-lg-5'>
                                <select
                                    id="riderStatus"
                                    multiple
                                    className='form-select  form-select-sm fs-6 min-h-20px py-1'
                                    onChange={e => setRiderStatus(prevRiderStatus => ({...prevRiderStatus, value: Array.from(e.target.selectedOptions).map(option => option.value) })) }
                                    tabIndex={18}
                                >
                                    { formMeta.riderStatus?.map(rs => <option value={rs.value}>{ rs.label }</option> )}
                                </select>
                            </div>
                            <div className='col-lg-4'>
                                <Select
                                    styles={reactSelectStyles}
                                    options={formMeta.requirementAction}
                                    onChange={e => setRiderStatus(prevRiderStatus => ({ ...prevRiderStatus, error_warn_status: e.value }))}
                                    tabIndex={19}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-between py-3 px-0'>
                        <button type="button" className='btn btn-sm btn-dark fw-bold me-5 text-uppercase' onClick={clearRequirements} tabIndex={20} data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDREQUIREMENTTOCLASSES.BUTTON.CLEARREQUIREMENTS">
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: 'CLASSES.QUICKACTION.MODAL.BUTTON.ADDREQUIREMENTTOCLASSES.CLEARREQUIREMENTS' })}
                        </button>
                        <div>
                            <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={21}>
                                {intl.formatMessage({ id: 'CLASSES.QUICKACTION.MODAL.BUTTON.ADDREQUIREMENTTOCLASSES.CANCEL' })}
                            </button>

                            <button type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={addRequirements} tabIndex={22} data-tooltip-id="CLASS.DETAIL.QUICKACTION.ADDREQUIREMENTTOCLASSES.BUTTON.ADD">
                                {intl.formatMessage({ id: 'CLASSES.QUICKACTION.MODAL.BUTTON.ADDREQUIREMENTTOCLASSES.ADD' })}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default AddRequirementToClasses