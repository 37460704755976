import { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Select from "react-select";

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Assets
import "flatpickr/dist/themes/material_blue.css";

const modalsRoot = document.getElementById('root-modals') || document.body

const CombinedClassPopup = ({ show, handleClose, area, isAddMode, classList, callAddClassesApi, callConfirmDivisionDrop, callRapidAddDropClassByNumbers,rapidAPIData, addClickHandler }) => {
    const intl = useIntl()
    // List of Actual Classes
    const [actualClasses, setActualClasses] = useState()
    const [error, setError] = useState("")
    const selectRef = useRef(null)

    useEffect(() => {
        // Focus on the dropdown search when modal is shown
        if (selectRef.current) {
            setTimeout(() => {
                selectRef.current.focus()
            }, 300)
        }
    }, [show])

    useEffect(() => {
        // Set actual class when there is only one possible actual class, otherwise, set empty and allow user to set.
        setActualClasses( Object.keys(classList).map(classNumber => classList[classNumber].length > 0 ? "" : classNumber ))
    }, [classList])

    const displaySelects = Object.keys(classList).map(
        (classNumber, idx) => {
            // Check for combined class
            if (classList[classNumber].length > 0) {
                const options = classList[classNumber].map(c => ({
                    value: c.number, label: c.number + " - " + c.name 
                }))
                return (
                    <div className='row mb-2'>
                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px' htmlFor={classNumber}>Class #  { classNumber }</label>
                        <div className='col'>
                            <Select 
                                id={classNumber}
                                options={options}
                                onChange={({ value }) => handleChange(idx, value)} 
                                ref={idx === 0 ? selectRef : null}
                            />    
                        </div>
                    </div>
                )
            }
        }
    )

    const handleChange = (idx, value) => {
        setActualClasses(prevActualClasses => {
            const newTrips = [...prevActualClasses]
            newTrips[idx] = value.toString()
            return newTrips
        }) 
    }

    const handleSubmit = () => {
        // Check if actual classes for all the combined classes were selected
        if (actualClasses.filter(number => number === "").length > 0) {
            setError(intl.formatMessage({ id: "ENTRIES.COMBINEDCLASS.MODAL.LABEL.ERRORMESSAGE"}))
            return
        }

        setError("")
        if (isAddMode) {
            switch(area) {
                case "Entries":
                    callAddClassesApi(actualClasses.join(","))
                    break
                case "Rapid":
                    callRapidAddDropClassByNumbers(rapidAPIData.entryNumber, rapidAPIData.riderId, actualClasses.join(","), rapidAPIData.classesToDrop)
                    break
                case "VerifyRoster":
                    addClickHandler(Number(actualClasses))
                    break
                case 'Ingate':
                    addClickHandler(actualClasses.join(','))
                    break
            }  
        } else {
            switch(area) {
                case "Entries":
                    callConfirmDivisionDrop(actualClasses.join(","), "")
                    break
                case "Rapid":
                    // callRapidAddDropClassByNumbers(entryNumber, riderId, classesToAdd, classesToDrop, showId = 0, confirmDivisionDropChoice = "", classesNotDroppable=[], divisionClassesToScratch=[], divisionClassesToDrop=[], addResponse={}, invalidClasses=[])
                    callRapidAddDropClassByNumbers(
                        rapidAPIData.entryNumber, 
                        rapidAPIData.riderId,
                        "",
                        actualClasses.join(","), 
                        0,
                        "",
                        [], 
                        [], 
                        [], 
                    )
                    break
            }
        }
           
        handleClose()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    handleSubmit()
                }
                if (event.key === 'Escape') {
                    event.stopPropagation()
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">Select Actual Class</h2>
                
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-5 py-1'>{ intl.formatMessage({ id: "ENTRIES.COMBINEDCLASS.MODAL.LABEL.SELECTMESSAGE"})}</label>
                        </div>
                       { displaySelects }
                    </div>

                    <div className="col error_message">{ error }</div>
                    
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase'
                            type="button"
                            onClick={handleClose}
                        >
                            { intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CANCEL"})}  
                        </button>
                        <button className='btn btn-sm btn-dark fw-bold text-uppercase' type="button" onClick={handleSubmit} autoFocus>
                            { isAddMode ? "Add" : "Drop" }
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { CombinedClassPopup }