// NOTE: Don't use this currency renderer. Use currencyFormatter instead (useCurrencyFormatter).
const CurrencyRenderer = (params) => {
    if (params.value !== undefined && params.value != null && params.value != '') {
        const valueToDisplay = Math.abs(Number(params?.value)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if(Number(params?.value) < 0){
            return <span>{'-'}{params.currency}{valueToDisplay}</span>; 
        }
        
        return <span>{params.currency}{valueToDisplay}</span>;
    }
}
export default CurrencyRenderer;