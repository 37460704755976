import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import { date_options, time_options_12hours, maskDateInput, setDate} from '../../../modules/sgl-utils/fieldControls';
import { useCurrencyFormatter } from '../../../modules/sgl-utils/Formatters';
import { PatternFormat } from 'react-number-format';
import { useAppSelector } from '../../../redux/hooks';
import { loadingSpinnerBtnRelease, loadingSpinnerBtnReleaseByEnter, loadingSpinnerBtnWait, loadingSpinnerBtnWaitByEnter, overrideEventTarget } from '../../../modules/sgl-utils/SglFunctions';
import Select from "react-select";
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls';
import axios from 'axios';
import moment from 'moment';
import IntlTelInput from 'react-intl-tel-input';
import { phoneParserForSingleInputField, getCountryCodeIsoFromPhoneNumber, updateCountryCodeInPhoneNumber } from '../../../modules/sgl-utils/Formatters';

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { useAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import { set } from 'react-hook-form';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const RapidAddEntryFee = ({ show, handleClose }) => {
    const intl = useIntl() 
    const alertDialog = useAlert()
    const currencyFormatter = useCurrencyFormatter()
    const loadingOverlay = useLoadingOverlay()
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)
    const quantityInputRef = useRef()
    const numberInputRef = useRef()

    const [selectedFee, setSelectedFee] = useState({})
    const [isDisabledForDel, setIsDisabledForDel] = useState(true)
    const [message, setMessage] = useState("")
    const [messageClass, setMessageClass] = useState("")

    const [feesData, setFeesData] = useState([]) // fee related data (description, unit_price, etc.)
    const feesOptions = feesData.map(fee => ({ label: fee.description, value: fee.sgl_id }))
    const [showBarns, setShowBarns] = useState([]) // barns available for the current
    const [selectedBarn, setSelectedBarn] = useState({}) // barn associated with the fee

    const [formData, setFormData] = useState({
        entry_id: 0,
        number: 0,
        quantity: 0,
        invoice_number: "",
        delivery_barn_location: "",
        delivery_stall_location: "",
        trainer_name: "",
        arrival_time: "",
        entry_stall: "",
        delivery_notification_phone: "",
        arrival_date: "",
    })
    const [trainerAccount, setTrainerAccount] = useState(0)
    const [notificationPhone, setNotificationPhone] = useState({country_code: '', phone_number: '', iso2: ''});
    const notificationPhoneRef = useRef(null);

    useEffect(() => {
        // Update country code if the country code is empty, and numbers are updated
        // Because country code will be assigned automatically to US if the numbers are 10 (accurate phone number)
        // But can be changed by the user through dropdown
        if (notificationPhone.iso2 == "") {
            let notificationCountryCode = getCountryCodeIsoFromPhoneNumber(notificationPhone.phone_number);
   
            setNotificationPhone(prevNotificationPhone => ({
                ...prevNotificationPhone, iso2: notificationCountryCode
            }))
        }

    }, [notificationPhone.phone_number])

    const handleChange = (e) => {
        const { type } = e.target
        setFormData(prevFormData => ({
            ...prevFormData, [e.target.name]: type === "text" ?  e.target.value : e.target.checked
        }))
    }

    const onFeeSelect = (e) => {
        let obj = feesData.find(fee => fee.sgl_id == e.value)
        setSelectedFee(obj ? obj : {})
        setIsDisabledForDel(obj && obj?.MasterFee?.physical_delivery ? false : true)
    }

    // Get Entry Fee    Select
    useEffect(() => {
        if (show) { // Fetch list only once popup is displayed 
            if(showFinancialsLocked){
                alertDialog({ message: intl.formatMessage({ id: "ENTRIES.RAPIDFEEINPUT.MODAL.ERRORMESSAGE.SHOWLOCKED" }), title: "warning" })
                onClose()
                return
            }
            loadingOverlay({ show: true })
            axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/metaData', {
                params: {
                    show_id: currentShowID,
                    customer_id: customerId
                }
            })
            .then(response => {
                setFeesData(response.data.showFeesDescriptions)
                loadingOverlay({ show: false })
            })
        }
    }, [show])

    const getEntryForRapidEntryFeeInput = () => {
        // input validation for entry number >>>
        let isNumber = /^[0-9]+$/.test(formData.number)
        if(!isNumber){
            setMessage("Please enter valid entry number.")
            setMessageClass('error_message')
        }else{
            setMessage("")
            setMessageClass('error_message')
        }
        // input validation for entry number  <<<

        if (formData.number > 0 && isNumber) {
            axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/getEntryForRapidEntryFeeInput', {
                params: {
                    show_id: currentShowID,
                    customer_id: customerId,
                    entry_number: formData.number
                }
            })
            .then(response => {
                // clear message
                setMessage("")
                setMessageClass('error_message')

                const { entry } = response.data

                let trainer_name = ""
                let delivery_notification_phone = ""
                let notificationPhoneWithoutCountryCode = ""
                let notificationCountryCodeIso2 = ""

                if (entry.Trainer) {
                    trainer_name = entry.Trainer.isfarmrto ? entry.Trainer.company_name : entry.Trainer.fl_name
                    delivery_notification_phone = entry.Trainer.sgl_mobile;


                    // Set phone number and country codes
                    if (delivery_notification_phone) {
                        notificationPhoneWithoutCountryCode = phoneParserForSingleInputField(delivery_notification_phone);
                        notificationCountryCodeIso2 = getCountryCodeIsoFromPhoneNumber(delivery_notification_phone);
                    } else {
                        notificationCountryCodeIso2 = "us"; // Default country code is US
                    }
                }

                setNotificationPhone({
                    iso2: notificationCountryCodeIso2,
                    phone_number: notificationPhoneWithoutCountryCode,
                    country_code: ""    // Initially setting country code to empty, it will be updated by react intl tel input
                });

                setFormData(prevFormData => ({
                    ...prevFormData, 
                    trainer_name,
                    delivery_barn_location: entry.location,
                    entry_id: entry.entry_id,
                    delivery_notification_phone
                }))
                setTrainerAccount(entry.trainer_account)
            })
            .catch(reason => {
                // set message
                setMessage(reason.response.data.error)
                setMessageClass('error_message')

                // reset to default values
                setFormData(prevFormData => ({
                    ...prevFormData, 
                    trainer_name: "",
                    delivery_barn_location: "",
                    entry_id: 0,
                    delivery_notification_phone: "",
                }))
                setTrainerAccount(0)
            })
        }
    }

    const addEntryFee = async (event) => {
        
        // input validation for quantity >>>
        if(isNaN(formData.quantity)){
            setMessage("Please enter valid quantity.")
            setMessageClass('error_message')
            return
        }else{
            setMessage("")
            setMessageClass('error_message')
        }
        // input validation for quantity <<<
        
        loadingSpinnerBtnWaitByEnter(event)

        // Update country code if the country code with the phone number
        let notificationPhoneWithCountryCode = updateCountryCodeInPhoneNumber(notificationPhone.phone_number, notificationPhone.country_code);
        formData.delivery_notification_phone = notificationPhoneWithCountryCode;

        let newEntryFee = {
            ...formData,
            description: selectedFee.description,
            unitprice: selectedFee.unit_price,
            category: selectedFee.category,
            showfees_id: selectedFee.showfees_id,
            ShowFee: {
                MasterFee: selectedFee.MasterFee
            },
            date: moment().format("YYYY-MM-DD"),
        } 
        newEntryFee.totalprice = (newEntryFee.unitprice * Number(newEntryFee.quantity)).toFixed(2)

        axios.post( process.env.REACT_APP_NEST_API_URL + "/entries/rapidEntryFeeSave", {
            entry_fee: newEntryFee,
            entry_number: formData.number,
            customer_id: customerId,
            show_id: currentShowID
        })
        .then(response => {
            if (response.data.success) {
                setMessage(`${newEntryFee.quantity} ${selectedFee.description} Fee's added to Entry ${formData.number} for a total of ${newEntryFee.totalprice}`)
                setMessageClass('text-success')
            }
            loadingSpinnerBtnReleaseByEnter(event)
            numberInputRef.current.focus()
            numberInputRef.current.select()
        })
        .catch(reason =>  {
            if (reason.response.data?.error) {
                setMessage(reason.response.data.error)
                setMessageClass('error_message')

                if (reason.response.data.error.includes("quantity")) {
                    quantityInputRef.current.focus()
                } else {
                    numberInputRef.current.focus()
                }
            }
            loadingSpinnerBtnReleaseByEnter(event)
        })
    }

    const onClose = () => {
        setSelectedFee({}); 
        setIsDisabledForDel(true)
        setFormData({
            entry_id: 0,
            number: 0,
            quantity: 0,
            invoice_number: "",
            delivery_barn_location: "",
            delivery_stall_location: "",
            trainer_name: "",
            arrival_time: "",
            entry_stall: "",
            delivery_notification_phone: "",
            arrival_date: "",
        })
        setMessage("")
        handleClose();  
    }

    const getShowBarns = async() => {
        // load the barn for the current show
        await axios.get(`${NEST_API_URL}/shows/getShowBarns?show_id=${currentShowID}&customer_id=${customerId}`)
        .then(response => {
            if(response.data){
                setShowBarns(response.data)
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        });
    }

    useEffect(() => {
        getShowBarns()
        // no barn entered for the fee
        setSelectedBarn({label: 'Select', value: ''})
    }, [])

    useEffect(() => {
        // if the barn entered in fee is present in show barns then auto select it other wise select others options
        let delivery_barn_location = formData?.delivery_barn_location
        if(delivery_barn_location){
            let _barnSelected = showBarns?.find((barn) => barn['value']?.toLowerCase()?.trim() == delivery_barn_location?.toLowerCase()?.trim())
            if(_barnSelected){
                setSelectedBarn(_barnSelected)
            }else{
                setSelectedBarn({label: 'Others', value: 'Others'})
            }
        }else{
            // no barn entered for the fee
            setSelectedBarn({label: 'Select', value: ''})
        }
    }, [showBarns, formData])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
            show={show}
            onHide={onClose}
            onKeyDown={(event) => {
                let activeElement = document.activeElement
                let isButtonFocused = false
                if (activeElement && activeElement.tagName === 'BUTTON') {
                    isButtonFocused = true
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    let executeButton = document.getElementById('RapidAddEntryFeeSubmitButton')
                    addEntryFee(executeButton)
                }
                if (event.key === 'Escape') {
                    onClose()
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.HEADING.RAPIDADDENTRYFEE' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' htmlFor='FEEDESC' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.LABEL.FEEDESC">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.FEEDESC' })}</label>
                            <div className='col-lg-7'>
                                <Select 
                                    options={feesOptions}
                                    id="FEEDESC"
                                    value={feesOptions.find((ss) => ss.value === selectedFee.sgl_id)}
                                    onChange={onFeeSelect}
                                    styles={reactSelectStyles}
                                    autoFocus
                                    tabIndex={1}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.LABEL.UNITPRICE"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.UNITPRICE' })}</label>
                            <label className='col col-form-label fs-5 py-1'>
                                { currencyFormatter(selectedFee['unit_price']) }
                            </label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' htmlFor="entryNumber" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.LABEL.ENTRYNUM"> 
                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.ENTRYNUM' })}
                            </label>
                            <div className='col-lg-4'>
                                <input
                                    ref={numberInputRef}
                                    name="number"
                                    onChange={handleChange}
                                    value={formData.number}
                                    id="entryNumber"
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.ENTRYNUM' })}
                                    tabIndex={2}
                                    onBlur={getEntryForRapidEntryFeeInput}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' htmlFor='QUANTITY' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.LABEL.QUANTITY">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.QUANTITY' })}</label>
                            <div className='col-lg-7'>
                                <input
                                    ref={quantityInputRef}
                                    name="quantity"
                                    onChange={handleChange}
                                    value={formData.quantity}
                                    id='QUANTITY'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.QUANTITY' })}
                                    tabIndex={3}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' htmlFor='INVOICENUM' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.LABEL.INVOICENUM">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.INVOICENUM' })}</label>
                            <div className='col-lg-7'>
                                <input
                                    name="invoice_number"
                                    onChange={handleChange}
                                    value={formData.invoice_number}
                                    id='INVOICENUM'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.INVOICENUM' })}
                                    tabIndex={4}
                                />
                            </div>
                        </div>
                        { !isDisabledForDel ?
                            <>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' htmlFor='NOTIFICATIONPHONE' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.LABEL.NOTIFICATIONPHONE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.NOTIFICATIONPHONE' })}</label>
                                    <div className='col-lg-7'>
                                        <IntlTelInput
                                            id="NOTIFICATIONPHONE"
                                            ref={notificationPhoneRef}
                                            containerClassName='intl-tel-input country-code-picker'
                                            inputClassName='form-control fs-6 py-0'
                                            style={{ width: '100%', borderRadius: '0px' }}
                                            preferredCountries={["us", "ca"]}
                                            defaultCountry={notificationPhone.iso2}
                                            allowDropdown={true}
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.NOTIFICATIONPHONE' })}
                                            value={phoneParserForSingleInputField(notificationPhone.phone_number)}
                                            onPhoneNumberChange={(_isValid, _rawValue, _countryData, _formattedValue, _extension, e) => {
                                                let unformattedPhone = _rawValue.replace(/\D/g, ''); // Remove non-digit characters
                                                setNotificationPhone(prevNotificationPhone => ({
                                                    ...prevNotificationPhone, phone_number: unformattedPhone
                                                }))

                                                if (_countryData) {
                                                    if (notificationPhone.iso2 != '') {
                                                        setNotificationPhone(prevNotificationPhone => ({
                                                            ...prevNotificationPhone, country_code: _countryData.dialCode
                                                        }))
                                                    }
                                                }
                                            }}
                                            
                                            onSelectFlag={(currentNumber, country) => {
                                                if (country) {
                                                    if (country.dialCode) {
                                                        setNotificationPhone(prevNotificationPhone => ({
                                                            ...prevNotificationPhone, iso2: country.iso2, country_code: country.dialCode
                                                        }))
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' htmlFor='BARN' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.LABEL.BARN">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.BARN' })}</label>
                                    <div className='col-lg-7'>
                                        <div className='row'>
                                            {/* Barn Drop Down */}
                                            <div className={selectedBarn?.value == 'Others' ? 'col-lg-6' : 'col-lg-12'}>
                                                <Select
                                                    placeholder="Any"
                                                    value={selectedBarn}
                                                    options = {showBarns}
                                                    isSearchable={true}
                                                    onChange={(option) => {
                                                        let _selectedBarn = ''
                                                        setSelectedBarn(option)
                                                        if(option.value == 'Others'){
                                                            // Clear out the delivery_barn_location field as user will enter it manually
                                                            _selectedBarn = ''
                                                        }else {
                                                            _selectedBarn = option.value
                                                        }

                                                        setFormData(prevFormData => ({
                                                            ...prevFormData, delivery_barn_location: _selectedBarn
                                                        }))
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                    })}
                                                    styles={reactSelectStyles}
                                                /> 
                                            </div>

                                            {/* Barn Input field for Others */}
                                            {
                                                selectedBarn?.value == 'Others' ? 
                                                <div className='col-lg-6'>
                                                    <input
                                                        name="delivery_barn_location"
                                                        onChange={handleChange}
                                                        value={formData.delivery_barn_location}
                                                        id='BARN'
                                                        type='text'
                                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.BARN' })}
                                                        tabIndex={6}
                                                    />
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' htmlFor='STALL' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.LABEL.STALL">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.STALL' })}</label>
                                    <div className='col-lg-7'>
                                        <input
                                            name="delivery_stall_location"
                                            onChange={handleChange}
                                            value={formData.delivery_stall_location}
                                            id='STALL'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.STALL' })}
                                            tabIndex={7}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' htmlFor='TRAINER' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.LABEL.TRAINER">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.TRAINER' })}</label>
                                    <div className='col-lg-7'>
                                        <input
                                            name="trainer_name"
                                            onChange={handleChange}
                                            value={formData.trainer_name}
                                            id='TRAINER'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.TRAINER' })}
                                            tabIndex={8}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' htmlFor='ARRIVALDATE' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.LABEL.ARRIVALDATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.ARRIVALDATE' })}</label>
                                    <div className='col-lg-7'>
                                        <div className="input-group date">
                                            <Flatpickr
                                                className="form-control form-control-sm mb-lg-0  fs-6 min-h-20px py-1"
                                                id="ARRIVALDATE"
                                                placeholder="MM/DD/YYYY"
                                                options={date_options}
                                                autoComplete={"off"}
                                                value = {moment(formData.arrival_date).format('MM/DD/YYYY')}
                                                onClose={(value, dateStr, instance) => {
                                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();
                                                    if (!isValidDate) {
                                                        instance.input.value = ''; // Clear the input if the date is invalid
                                                    }
                                                    setFormData(
                                                        prevFormData => ({...prevFormData, arrival_date: !dateStr ? "0000-00-00" : moment(value[0]).format('YYYY-MM-DD')}
                                                    ))
                                                }}
                                                onChange={(value, dateStr, instance) => {
                                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();
                                                    if (!isValidDate) {
                                                        instance.input.value = ''; // Clear the input if the date is invalid
                                                    }
                                                    setFormData(
                                                        prevFormData => ({...prevFormData, arrival_date: !dateStr ? "0000-00-00" : moment(value[0]).format('YYYY-MM-DD')}
                                                    ))
                                                }}
                                                onInput={(e) => { maskDateInput(e); }}
                                                tabIndex={9}
                                            />
                                            <div className="input-group-append">
                                                <label htmlFor='ARRIVALDATE' className='date-label'
                                                    onClick={(e) => {
                                                        e.stopPropagation(); 
                                                        document.getElementById('ARRIVALDATE')._flatpickr.open(); 
                                                    }}
                                                >
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar"></i>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' htmlFor='ARRIVALTIME' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.LABEL.ARRIVALTIME"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.ARRIVALTIME' })}</label>
                                    <div className='col-lg-7'>
                                        <div className="input-group date">
                                            <Flatpickr 
                                                className="form-control form-control-sm fs-6 min-h-20px py-1"
                                                id="ARRIVALTIME"
                                                placeholder="00 : 00"
                                                value = {formData.arrival_time}
                                                options={time_options_12hours}
                                                autoComplete={"off"}
                                                onClose={value => {
                                                    setFormData(
                                                        prevFormData => ({...prevFormData, arrival_time: value === "" ? "00:00:00" : moment(value[0]).format('HH:mm:ss')}
                                                    ))
                                                }}
                                                onChange={value => {
                                                    setFormData(
                                                        prevFormData => ({...prevFormData, arrival_time: value === "" ? "00:00:00" : moment(value[0]).format('HH:mm:ss')}
                                                    ))
                                                }}
                                                tabIndex={10}
                                            />
                                            <div className="input-group-append">
                                                <label htmlFor='ARRIVALTIME' className='date-label'
                                                    onClick={(e) => {
                                                        e.stopPropagation(); 
                                                        document.getElementById('ARRIVALTIME')._flatpickr.open(); 
                                                    }}
                                                >
                                                    <span className="input-group-text">
                                                        <i className="la la-clock-o"></i>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' htmlFor='PRIORITYORDER'></label>
                                    <div className='col-lg-7 form-check-sm form-check-custom ps-lg-3'>
                                        <input
                                            name="priority_order"
                                            onChange={handleChange}
                                            checked={formData.priority_order}
                                            className='form-check-input'
                                            type='checkbox'
                                            id='PRIORITYORDER'
                                            tabIndex={11}
                                        />
                                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor='PRIORITYORDER' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.LABEL.PRIORITYORDER"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.PRIORITYORDER' })}</label>
                                    </div>
                                </div>
                            </>
                            :
                            null
                        }      
                        <div className={'row mb-2' + (trainerAccount && (selectedFee?.stablefee || selectedFee?.MasterFee?.stablefee)  ? '' : ' d-none')}>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-145px' htmlFor='TRAINERSTALLINVENTORY'></label>
                            <div className='col-lg-7 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    name="entry_stall"
                                    onChange={handleChange}
                                    checked={formData.entry_stall}
                                    className='form-check-input'
                                    type='checkbox'
                                    id='TRAINERSTALLINVENTORY'
                                    tabIndex={12}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='TRAINERSTALLINVENTORY' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.LABEL.TRAINERSTALLINVENTORY"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.TRAINERSTALLINVENTORY' })}</label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className={`col col-form-label fs-5 py-1 text-center ${messageClass}`}>
                                { message }
                            </label>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <label className={'col-form-label py-1' + (trainerAccount && (selectedFee?.stablefee || selectedFee?.MasterFee?.stablefee)  ? '' : ' d-none')}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.LABEL.TRAINERSTALLINVENTORYMSG' })}</label>

                        <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={13} data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.BUTTON.DONE">
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.BUTTON.DONE' })}
                        </button>

                        <button type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' id='RapidAddEntryFeeSubmitButton' onClick={addEntryFee} tabIndex={14} data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.RAPIDENTRYFEEINPUT.BUTTON.EXECUTE">
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.BUTTON.EXECUTE' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default RapidAddEntryFee