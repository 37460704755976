import {useState, useRef, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {initialQueryState, KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import { Controller, useForm } from 'react-hook-form'
import axios from 'axios'
import { useAppSelector } from '../../../redux/hooks'
import { useAlert } from '../../../modules/sgl-utils/DialogsProvider'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

type Props = {
  show: boolean
  handleClose: () => void
  showData: any
  updateImportCount: () => void
  facility_id: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ImportShow = ({show, handleClose, showData, updateImportCount, facility_id}: Props) => {
  const intl = useIntl()
  const { register, watch, reset, getValues, control, setValue } = useForm({});
  const customerID = useAppSelector(state=> state.showCompany.company_id);
  const [errorMessage, setErrorMessage] = useState('')
  const [showsList, setShowsList] = useState([])
  const [selectedShow, setSelectedShow] = useState('-1');
  const [checkedDivision, setCheckedDivision] = useState(false);
  const [isFormDiabled, setIsFormDiabled] = useState(false);
  let alertDialog = useAlert()
  
  const show_id = showData.show.show_id;
  
  const navigate = useNavigate()

  const handleSave = () => {
    setErrorMessage('')
    var data = getValues();
    data.divisions = checkedDivision;
    data.facility_id = facility_id;

    if(facility_id <= 0){
      alertDialog({message: 'Please Select a facility for the show.'})
      return
    }
    
    var error_message = ''
    if(data.show_id == '-1'){
      error_message = 'Please Select a Show.'
    } else if(data.show_id == show_id){
      error_message = 'Please Select different show.'
    } else if (!(data.divisions || 
        (data.divisions && data.placings) || 
        (data.divisions && data.schedule) || 
        data.judges || data.fees || data.add_backs || 
        data.tax_rates || data.contact_info || data.barn_info)
      ){
      error_message = 'Please Select an Option.'
    }
    setErrorMessage(error_message);

    if(error_message == ''){
      setIsFormDiabled(true)
      axios.post(`${NEST_API_URL}/shows/importShow`, {
          customer_id: customerID,
          show_id: show_id,
          import_data: data
      })
      .then(result => {  
        setIsFormDiabled(false);

        if(result.data.success) {
          updateImportCount(); // to reload page
          handleClose();
        } else {
          setErrorMessage('There is some error while importing your data. Please contact support');
        }      
      })
      .catch(async function (error) {
          setIsFormDiabled(false);
          if (error.response) {
            setErrorMessage(error.response.data.error);
          }
      });
    }
  }

  useEffect(() => {
    axios.get(`${NEST_API_URL}/shows`, {
          params:{'customer_id':customerID}
        })
        .then(result => {   
          if(result.data.shows && result.data.shows.length > 0) {
            setShowsList(result.data.shows);
          }      
        })            
  },[]);

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-500px search-form'
      show={show}
      backdrop="static"
      onHide={handleClose}
      onKeyDown={(event:any) => { //replaced onKeyPress with onKeyDown
        let isButtonFocused = false
        let activeElement = document.activeElement //gets the currently focussed element
        if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
            isButtonFocused = true;
        }
        if (event.key === "Enter" && !isButtonFocused) {
          handleSave();
        }
      }}
    >
      <div className='modal-header py-0 px-4'>
        <h2 className="fs-4">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.IMPORT.TITLE'})}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4'>
        <form noValidate className='form' onSubmit={e => e.preventDefault()} >
        <fieldset disabled={isFormDiabled}>
          <div className='card-body p-0'>
            <div className='row mb-2'>
              <div className='col-lg-9'>
                <select
                  id='SELECTSHOW'
                  {...register("show_id")}
                  className='form-select form-select-sm fs-6 min-h-20px py-1'
                  onChange={(e) => setSelectedShow(e.target.value)}
                  autoFocus
                  tabIndex={1}
                >
                  <option value='-1'>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.IMPORT.SHOW.PLACEHOLDER'})}</option>
                    {/* excluding current show from the options list */}
                   {showsList.filter((show: any) => show.show_id !== show_id).map((show: any) =>
                      <option key={show.sgl_id} value={show.show_id}>{show.show_name}</option>
                    )}
                </select>
              </div>
            </div>
            <div className='ml-20'>
            <div className='row mb-2'>
                <div className='d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                        <input
                        {...register( 'divisions', 
                            {
                            required: false
                            })
                        }
                        id='divisions'
                        className='form-check-input'
                        type='checkbox'
                        disabled={!(showData.classes.length == 0 && showData.divisions.length == 0)}
                        onChange={(e) => {
                          setCheckedDivision(e.target.checked)
                          setValue("placings", false)
                          setValue("schedule", false)
                        }}
                        tabIndex={2}
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='divisions' data-tooltip-id="SHOWS.DETAIL.MODAL.COPYTEMPLATESHOW.LABEL.DIVISIONSANDCLASSES"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.IMPORT.DIVISIONSANDCLASSES'})}</label>
                </div>
            </div>
            <div className='ml-20'>
            <div className='row mb-2'>
                <div className='d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                        <input
                        {...register( 'placings', 
                            {
                            required: false
                            })
                        }
                        id='placings'
                        className='form-check-input'
                        type='checkbox'
                        disabled={!checkedDivision}
                        tabIndex={3}
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='placings' data-tooltip-id="SHOWS.DETAIL.MODAL.COPYTEMPLATESHOW.LABEL.PLACINGSANDPRIZES"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.IMPORT.PLACINGANDPRICES'})}</label>
                </div>
            </div>
            <div className='row mb-2'>
                <div className='d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                        <input
                        {...register( 'schedule', 
                            {
                            required: false
                            })
                        }
                        id='schedule'
                        className='form-check-input'
                        type='checkbox'
                        disabled={(!checkedDivision || showData.show.start_date =='00/00/0000' || showData.show.end_eate == '00/00/0000')}
                        tabIndex={4}
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='schedule' data-tooltip-id="SHOWS.DETAIL.MODAL.COPYTEMPLATESHOW.LABEL.SHOWSCHEDULE"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.IMPORT.SHOWSCHEDULE'})}</label>
                </div>
            </div>
            </div>
            {/* Removing checkbox to copy judges (recent modifications in 4D, import show process) */}
            {/* <div className='row mb-2'>
                <div className='d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                        <input
                        {...register( 'judges', 
                            {
                            required: false
                            })
                        }
                        id='judges'
                        className='form-check-input'
                        type='checkbox'
                        disabled={!(showData.officials.length == 0)}
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='judges'> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.IMPORT.JUDGES'})}</label>
                </div>
            </div> */}

            {/* Removing disbale fee condition (recent modifications in 4D, import show process) */}
            <div className='row mb-2'>
                <div className='d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                        <input
                        {...register( 'fees', 
                            {
                            required: false
                            })
                        }
                        id='fees'
                        className='form-check-input'
                        type='checkbox'
                        tabIndex={5}
                        // disabled={!(showData.show_fees.length == 0)}
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='fees' data-tooltip-id="SHOWS.DETAIL.MODAL.COPYTEMPLATESHOW.LABEL.FEES"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.IMPORT.FEES'})}</label>
                </div>
            </div>

            <div className='row mb-2'>
                <div className='d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                        <input
                        {...register( 'add_backs', 
                            {
                            required: false
                            })
                        }
                        id='add_backs'
                        className='form-check-input'
                        type='checkbox'
                        disabled={!(showData.add_backs.length == 0)}
                        tabIndex={6}
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='add_backs' data-tooltip-id="SHOWS.DETAIL.MODAL.COPYTEMPLATESHOW.LABEL.ADDBACKS"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.IMPORT.ADDBACKS'})}</label>
                </div>
            </div>
            {/* Removing checkbox to copy taxes (recent modifications in 4D, import show process) */}
            {/* <div className='row mb-2'>
                <div className='d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                        <input
                        {...register( 'tax_rates', 
                            {
                            required: false
                            })
                        }
                        id='tax_rates'
                        className='form-check-input'
                        type='checkbox'
                        disabled={!(showData.tax_rates.length == 0)}
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='tax_rates'> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.IMPORT.TAXRATES'})}</label>
                </div>
            </div> */}
            <div className='row mb-2'>
                <div className='d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                        <input
                        {...register( 'contact_info', 
                            {
                            required: false
                            })
                        }
                        id='contact_info'
                        className='form-check-input'
                        type='checkbox'
                        tabIndex={7}
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='contact_info' data-tooltip-id="SHOWS.DETAIL.MODAL.COPYTEMPLATESHOW.LABEL.CONTACTINFO"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.IMPORT.CONTACTINFO'})}</label>
                </div>
            </div>
            {
              (facility_id && facility_id > 0 && false) ?
              <div className='row mb-2'>
                <div className='d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                        <input
                        {...register( 'barn_info', 
                            {
                            required: false
                            })
                        }
                        id='barn_info'
                        className='form-check-input'
                        type='checkbox'
                        tabIndex={8}
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='barn_info'> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.IMPORT.BARNINFO'})}</label>
                </div>
              </div>
              : null
            }
            </div>
            
          </div>
          {(errorMessage != '') &&
            <div><span className='error_message'>{errorMessage}</span></div>
          }
          {(isFormDiabled) &&
            <div><span>Importing data...<br/> Please wait. It may take few minutes.</span></div>          
          }
          <div className='card-footer d-flex justify-content-end py-3 px-0'>
            <button className='btn btn-sm btn-secondary me-4 fw-bold' type='button' onClick={handleClose} tabIndex={8} data-tooltip-id="SHOWS.DETAIL.MODAL.COPYTEMPLATESHOW.BUTTON.DONTCOPY">
              {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.IMPORT.BUTTON.DONTCOPY'})}
            </button>
            <button className='btn btn-sm btn-dark fw-bold' type='button' onClick={e => {e.preventDefault(); handleSave();}} tabIndex={9} data-tooltip-id="SHOWS.DETAIL.MODAL.COPYTEMPLATESHOW.BUTTON.COPYSHOW">
              {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.IMPORT.BUTTON.COPYSHOW'})}
            </button>
          </div>
          </fieldset>

        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ImportShow}
