import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useLoadingOverlay, useConfirm, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import DivisionsGrid from './UnCombineDivisions/DivisionsGrid';
import axios from 'axios';
import { KTSVG } from '../../../../_metronic/helpers';
import "flatpickr/dist/themes/material_blue.css";
import Select from 'react-select';
import { useOutputContext, useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const UnCombineDivisions = ({ show, handleClose }) => {
    const intl = useIntl() 
    const loadingOverlay = useLoadingOverlay()
    const outputContextUpdater = useOutputContextUpdater()
    const { getEventID } = useOutputContext()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const {progressBar} = useProgress()
    const showId = useAppSelector(state => state.currentShow.show_id)
    const customerId = useAppSelector(state=> state.showCompany.company_id)

    const [gridRef, setGridRef] = useState()
    const [combinedDivisions, setCombinedDivisions] = useState([])
    const [selectedCombinedDivision, setSelectedCombinedDivision] = useState(null)

    // Metadata Call
    useEffect(() => {
        if (show && gridRef) {

            const validateAndGetMetaData = async () => { 
                try {
                    loadingOverlay({ show: true })
          
                    const response = await axios.get(`${NEST_API_URL}/divisions/getUnCombineDivisionsMetadata?customer_id=${customerId}&show_id=${showId}`)
                    
                    if (!response.data.success) { // Validations Failed!
                        alertDialog({ message: response.data.error })
                        handleClose() 
                    } else { // Validations Passed! We will always have at least 1 combinedDivision in such case! 
                        setCombinedDivisions(response.data.combinedDivisions)
                        setSelectedCombinedDivision(response.data.combinedDivisions[0]) 
                        gridRef.setRowData(response.data.combinedDivisions[0].actualDivisions)
                    }
                } catch (reason) {
                    alertDialog({ message: reason.response.data.message })
                } finally {
                    loadingOverlay({ show: false })
                } 
            }

            validateAndGetMetaData()
        }
    }, [show, gridRef])

    const unCombineDivisions = async () => {
        const eventId = getEventID('uncombine-divisions')
        try {
            loadingOverlay({ show: true })

            // Frontend validations
            let remainingClassesCount = 0
            for(const actualDivision of selectedCombinedDivision.actualDivisions) {
                if (!actualDivision.selected)
                    remainingClassesCount++
            }
            
            let message = ''
            if (remainingClassesCount === 1) 
                message = 'You cannot leave one division in a combined division.'
            else if (remainingClassesCount === selectedCombinedDivision.actualDivisions.length) // No class selected
                message = 'Please select division(s) to uncombine.'

            if (message) {
                alertDialog({ message })
                return
            }
             // Frontend validations

            const response = await axios.post( NEST_API_URL + `/divisions/unCombineDivisionValidations`, {
                customer_id: customerId,
                combined_division: selectedCombinedDivision
            })

            if (response.data.success) {
                if (await confirmDialog({ message: 'Are you sure you want to uncombine these divisions?' })) {
                    progressBar({ show: true, title: "Uncombine Divisions", eventID: eventId, timeElapsed: true })
                    const res2 = await axios.post( NEST_API_URL + `/divisions/unCombineDivisions`, {
                        customer_id: customerId,
                        actual_division_ids: response.data.actualDivisionIds,
                        combined_division_id: selectedCombinedDivision.value,
                        event_id: eventId
                    })
                    progressBar({ show: false })

                    if (res2.data.success)
                        onClose(res2.data.divisionSglIds)
                }
            } else {
                alertDialog({ message: response.data.error })
            }
        } catch (reason) {
            alertDialog({ message: reason.response.data.message })
        } finally {
            progressBar({ show: false, eventID: eventId })
            loadingOverlay({ show: false })
        }
    }

    const onClose = (sglIds) => {
        outputContextUpdater({ action: 'getDataBySglIds', subsetIDs: sglIds }) // Refresh listing with currently combined classes
        handleClose()
    }
    
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px'
            show={show}
            onHide={handleClose}
            backdrop='static'
            onKeyDown={(event) => { //added onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) {
                    unCombineDivisions()
                }
                if (event.key == 'Escape') {
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'Divisions.QuickAction.UnCombineDivisions' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='card-body p-0 px-2'>
                    <div className='row mb-2'>
                        <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor="selectClass">
                            {intl.formatMessage({ id: 'Divisions.QuickAction.UnCombineDivisions.Label.SelectDivision' })}
                        </label>
                        <div className='col'>
                            <Select
                                type="text"
                                inputId="selectClass" 
                                theme={theme => ({...theme, borderRadius: 'none'})}
                                value={selectedCombinedDivision}
                                onChange={e => { 
                                    gridRef.setRowData(e.actualDivisions)
                                    setSelectedCombinedDivision(e)
                                }}
                                options={combinedDivisions}
                                autoFocus
                                tabIndex={1}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <DivisionsGrid setGridRef={setGridRef} tabIndex={2} />
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-3 px-2'>
                    <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={handleClose} tabIndex={3}>
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CLOSE" })}
                    </button>

                    <button 
                        type="button" 
                        className='btn btn-sm btn-dark fw-bold text-uppercase'
                        onClick={unCombineDivisions}
                        tabIndex={4}
                        >
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.UNCOMBINE" })}
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default UnCombineDivisions