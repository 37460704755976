import React from 'react';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

const HelpLink = ({ tooltip, classname = '', positionTop = '', positionRight='', positionType='' }) => {
  const style = {};

  if (positionTop) {
    style.top = positionTop;
  }

  if (positionRight) {
    style.right = positionRight;
  }

  if (positionType) {
    style.position = positionType;
  }

  return (
    <a href="#" className={`help-link ${classname}`} target="_blank" rel="noopener noreferrer" data-tooltip-id={tooltip} style={style}>
      <img
        alt="Logo"
        src={toAbsoluteUrl('/media/icons/CustomIcons/help-elliptical-light.png')}
        className="help-link-icon"
      />
    </a>
  );
};

export default HelpLink;
