import React, {useEffect, useState} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import axios from 'axios'
import { KTSVG } from '../../../../_metronic/helpers'
import { useAlert, useConfirm, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider'
import { useAppSelector } from '../../../redux/hooks'
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls'
import { useOutputContext, useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext'
import Select from 'react-select';
import { loadingSpinnerBtnRelease, loadingSpinnerBtnReleaseByEnter, loadingSpinnerBtnWait, loadingSpinnerBtnWaitByEnter } from '../../../modules/sgl-utils/SglFunctions'

const modalsRoot = document.getElementById('root-modals') || document.body
 
const RemoveOfficial = ({show, handleClose}) => {
    const intl = useIntl() 
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const {getOutputSelectionAreaIDs, getEventID} = useOutputContext()
    const outputContextUpdater = useOutputContextUpdater()
    const customer_id = useAppSelector((state) => state.showCompany.company_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const [selectedOfficial, setSelectedOfficial] = useState('')
    const [classOfficialsList, setClassOfficialsList] = useState([])
    const [classIds, setClassIds] = useState([])
    const [displayRemoveOfficialModal, setDisplayRemoveOfficialModal] = useState(false)
    const [updateMessage, setUpdateMessage] = useState('')

    const removeOfficial = (e) => {
        //No official selected
        if(!selectedOfficial){
            alertDialog({ message: intl.formatMessage({ id: "CLASSES.QUICKACTION.POPUP.HEADING.REMOVE.OFFICALS.TO.CLASSES.ERROMESSAGE.NO.OFFICIAL.SELECTED"}) })
            return
        }
        
        //Webservice to remove official from selected classes >>>
        loadingSpinnerBtnWaitByEnter(e)
        // loadingOverlay({show:true})
        axios.post( process.env.REACT_APP_NEST_API_URL + "/classes/bulkRemoveOfficials", {
            customer_id: customer_id,
            show_id: currentShowID,
            class_ids: classIds,
            people_id: selectedOfficial
        })
        .then((res) => {
            setUpdateMessage(res.data.updateMessage)
            setClassOfficialsList(res.data.classJudgeDropDownList)
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        }).finally(() => {
            loadingSpinnerBtnReleaseByEnter(e)
            // loadingOverlay({show:false})
        })
        //Webservice to remove official from selected classes <<<
    }

    const closeDialogs = () => {
        outputContextUpdater({action: 'unselectAll'})
        setDisplayRemoveOfficialModal(false)
        handleClose()
    }
    
    useEffect(() => {
        if (show) {
            loadingOverlay({ show: true })
            //Get selected Class ID's
            getOutputSelectionAreaIDs(false)
            .then(res => { 
                if (res.length === 0) {
                    loadingOverlay({ show: false })
                    // error message if no class is selected
                    alertDialog({ message: intl.formatMessage({ id: "CLASSES.QUICKACTION.POPUP.HEADING.REMOVE.OFFICALS.TO.CLASSES.ERROMESSAGE.SELECTCLASSES"}) })
                    handleClose()
                }
                else{
                    loadingOverlay({ show: false })
                    // if user selected any class
                    setClassIds(res)
                    //Webservice to get the official for the selected classes >>>
                    axios.post( process.env.REACT_APP_NEST_API_URL + "/classes/getClassJudgesForClasses", {
                        customer_id: customer_id,
                        show_id: currentShowID,
                        class_ids: res
                    }).then((res) => {
                        setClassOfficialsList(res.data.classJudgeDropDownList)
                    }).catch((error) => {
                        if (error.response) {
                            alertDialog({message: error.response.data.error});
                        }
                    }).finally(() => {
                        // loadingOverlay({ show: false })
                    })
                    //Webservice to get the official for the selected classes <<<
                    setDisplayRemoveOfficialModal(true)
                }
            })
            .catch(() => loadingOverlay({ show: false }))
        }  
    }, [show])

    return createPortal(
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
                show={displayRemoveOfficialModal}
                onHide={closeDialogs}
                onKeyDown={(event) => {
                    let isButtonFocused = false
                    let activeElement = document.activeElement //gets the currently focussed element
                    if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                        isButtonFocused = true;
                    }
                    if (event.key === "Enter" && !isButtonFocused) {
                        let okButton = document.getElementById('RemoveOfficialRemoveButton')
                        removeOfficial(okButton)
                    }
                    if(event.key === 'Escape'){
                        closeDialogs()
                    }
                }}
            >
                <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
                <div className='modal-header py-0 px-4'>
                    <h2 className="fs-4">{intl.formatMessage({ id: 'CLASSES.QUICKACTION.POPUP.HEADING.REMOVE.OFFICALS.TO.CLASSES' })}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={closeDialogs}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
    
                <div className='modal-body py-3 px-4'>
    
                    <div className="row mb-2">
                        <label className="col-lg-3 fw-bolder fs-6 mt-2">{intl.formatMessage({ id: 'CLASSES.QUICKACTION.POPUP.HEADING.REMOVE.OFFICALS.LABEL.REMOVE.OFFICIAL' })}</label>
                        <div className='col-lg-9'>
                            <Select
                                value={selectedOfficial !== undefined ? classOfficialsList.filter((ss) => ss.value === selectedOfficial):""}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}       
                                options={classOfficialsList}
                                onChange={(e) => {
                                    setUpdateMessage('')
                                    setSelectedOfficial(e.value)
                                }}
                                placeholder='Select an official to remove..'
                                styles={reactSelectStyles}
                                autoFocus
                                tabIndex={1}
                            />
                        </div>
                    </div>  

                    <div className='card-footer d-flex justify-content-between py-3 px-0'>
                        
                        <div className='label-left-align mw-350px' style={{color: 'green', fontWeight: 'bold'}}>
                            <label className='text-green fw-bold'>{updateMessage}</label>
                        </div>
                        
                        <div className='buttons-right-align'>
                            <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={closeDialogs} tabIndex={2}>
                                {intl.formatMessage({ id: 'CLASSES.ADD.NEW.OFFICIALS.DIALOG.BUTTON.DONE' })}
                            </button>

                            <button id='RemoveOfficialRemoveButton' type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={(event) => removeOfficial(event)} tabIndex={3}>
                                <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                {intl.formatMessage({ id: 'CLASSES.ADD.NEW.OFFICIALS.DIALOG.BUTTON.REMOVE' })}
                            </button>
                        </div>
                    </div>

                </div>
      </Modal>
      ,modalsRoot
    )
}

export default RemoveOfficial