import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useAlert } from "../../../modules/sgl-utils/DialogsProvider";
import { useAppSelector } from "../../../redux/hooks";
import { useSearchParams } from "react-router-dom";

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

// create context
const OrderOfGoContext = createContext()

const OrderOfGoContextProvider = ({ children }) => {
    const customerID = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const [showDates, setShowDates] = useState([]);
    const [showRings, setShowRings] = useState([]);
    const [classGroups, setClassGroups] = useState([])
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedRing, setSelectedRing] = useState(0);
    const [selectedClassGroup, setSelectedClassGroup] = useState()
    const alertDialog = useAlert();

    const [trainerRowData, setTrainerRowData] = useState([])
    const [riderRowData, setRiderRowData] = useState([])
    const [selectedHorses, setSelectedHorses] = useState([])
    const [manualRowData, setManualRowData] = useState([])
    const [allTrips, setAllTrips] = useState([])
    const [tripsInBetween, setTripsInBetween] = useState()
    const [publishedOnWeb, setPublishedOnWeb] = useState()
    const [orderBy, setOrderBy] = useState("Trainer")
    const [editOOG, setEditOOG] = useState() 
    const [OOGStatus, setOOGStatus] = useState()
    const [isOrderOfGoSet, setIsOrderOfGoSet] = useState()
    const [orderType, setOrderType] = useState("None")
    const [manualOrderedTrips, setManualOrderedTrips] = useState([])

    const [orderedTripGridApi, setOrderedTripGridApi] = useState(null);
    const [orderedTripColumnApi, setOrderedTripColumnApi] = useState(null)
    const [riderGridApi, setRiderGridApi] = useState(null);
    const [trainerGridApi, setTrainerGridApi] = useState(null);
    const [manualGridApi, setManualGridApi] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const [minsPerTrip, setMinsPerTrip] = useState(0)
    const [classSetupTime, setClassSetupTime] = useState(0)
    const [plannedTime, setPlannedTime] = useState()
    const [collapseMultipleTrips, setCollapseMultipleTrips] = useState(false)

    useEffect(() => {
        if(!currentShowID || !customerID){ 
            clearOrderOfGoState()
        } else {
            axios.get(`${NEST_API_URL}/scheduler?show_id=${currentShowID}&customer_id=${customerID}`)
            .then(response => {
                if(!response.data.Days || !response.data.RingxShows) {            
                    clearOrderOfGoState() 
                } else {
                    setShowDates(response.data.Days);
                    setShowRings(response.data.RingxShows);

                    if(searchParams.get("date")){
                        setSelectedDate(searchParams.get("date"))
                        searchParams.delete('date');
                        setSearchParams(searchParams);
                    }else{
                        setSelectedDate(response.data.Days[0]);
                    }
                    
                    if(searchParams.get("ring") > 0){
                        setSelectedRing(searchParams.get("ring"))
                        searchParams.delete('ring');
                        setSearchParams(searchParams);
                    }else{
                        setSelectedRing(response.data.RingxShows[0].ring_id);
                    }                    
                } 
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
            });
        }
    }, [currentShowID, customerID])

    useEffect(() => {
        if(selectedRing && selectedDate){ 
            // Clear Ag Grids
            clearTripsStates()

            axios.get(`${NEST_API_URL}/order-of-go/getClassGroupsListForDayAndRing?show_id=${currentShowID}&customer_id=${customerID}&day=${selectedDate}&ring_id=${selectedRing}`)
            .then(response => {
                setClassGroups(response.data.classGroups)
                if (response.data.classGroups.length > 0) {
                    if(searchParams.get("cgroup") > 0){
                        setSelectedClassGroup(searchParams.get("cgroup"))
                        searchParams.delete('cgroup');
                        setSearchParams(searchParams);
                    }else{
                        setSelectedClassGroup(response.data.classGroups[0].class_group_id)
                    }
                } else {
                    setSelectedClassGroup(null)
                }
               
            })
            .catch((error) => {
                if (error.response) {
                    //alertDialog({message: error.response.data.error});
                }
            });
        }
    }, [selectedRing, selectedDate])


    const fetchTripsAndRelatedData = (clearStates = true) => {
        // Show Ag Grids Loading Overlay
        orderedTripGridApi?.showLoadingOverlay()
        trainerGridApi?.showLoadingOverlay()
        riderGridApi?.showLoadingOverlay()
        manualGridApi?.showLoadingOverlay()

        if (clearStates) {
            clearTripsStates()
        }
        
        axios.get(`${NEST_API_URL}/order-of-go/getClassGroup?show_id=${currentShowID}&customer_id=${customerID}&class_group_id=${selectedClassGroup}`)
        .then(response => {
            setTrainerRowData(response.data.multipleTripTrainers)

            // Retain Last Group's Order By if empty/'' is Set for Current Group
            if (response.data.orderBy !== '')
                setOrderBy(response.data.orderBy)

            // Set Trips in Manual whose order is not set
            setManualRowData(response.data.trips.filter(trip => !trip.order_of_go))
            setRiderRowData(response.data.multipleTripRiders)

            // Set selected horse for each rider
            const _selectedHorses = []
            for (let riderTrip of response.data.multipleTripRiders) {
                // set point horse as selected
                if (riderTrip.pointHorse) {
                    _selectedHorses.push(riderTrip.pointHorse)
                } else { // select first horse
                    _selectedHorses.push(riderTrip.horses[0].horse_id)
                }
            }
            setSelectedHorses(_selectedHorses)

            setMinsPerTrip(response.data.class_time_per_trip)
            setClassSetupTime(response.data.class_setup_prep_time)
            setPlannedTime(response.data.start_time_default)
            setTripsInBetween(response.data.tripsInBetween)
            setPublishedOnWeb(response.data.publishedOnWeb)
            setAllTrips(response.data.trips)
            setEditOOG(response.data.editOOG)
            setOOGStatus(response.data.oogStatus)
            setIsOrderOfGoSet(response.data.isOrderOfGoSet)

            // Retain Last Group's Order Type if None is Set for Current Group
            if (response.data.orderType !== 'None')
                setOrderType(response.data.orderType)
            
            setManualOrderedTrips(response.data.trips.filter(trip => trip.order_of_go))
          
            // Hide Ag Grids Loading Overlay
            orderedTripGridApi?.hideOverlay()
            trainerGridApi?.hideOverlay()
            riderGridApi?.hideOverlay()
            manualGridApi?.hideOverlay()
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }

            // Hide Ag Grids Loading Overlay
            orderedTripGridApi?.hideOverlay()
            trainerGridApi?.hideOverlay()
            riderGridApi?.hideOverlay()
            manualGridApi?.hideOverlay()
        });
    }

    useEffect(() => {
        if(selectedClassGroup){ 
            fetchTripsAndRelatedData()
        }
    }, [selectedClassGroup])

    const clearTripsStates = () => {
        setTrainerRowData([])
        setManualOrderedTrips([])
        setManualRowData([])
        setRiderRowData([])
        setAllTrips([])
    }

    const clearOrderOfGoState = () => {
        setShowDates([]);
        setShowRings([]);
        setSelectedDate('');
        setSelectedRing(0);
    }

    return (
        <OrderOfGoContext.Provider value={{ currentShowID, customerID, showDates, showRings, classGroups, selectedDate, setSelectedDate, selectedRing, setSelectedRing, selectedClassGroup, setSelectedClassGroup, trainerRowData, riderRowData, manualRowData, tripsInBetween, setTripsInBetween, publishedOnWeb, orderBy, setOrderBy, editOOG, setEditOOG, selectedHorses, setSelectedHorses, OOGStatus, allTrips, isOrderOfGoSet, setManualRowData,setAllTrips, orderType, setOrderType, manualOrderedTrips, setManualOrderedTrips, orderedTripGridApi, setOrderedTripGridApi, setClassGroups, setOOGStatus, setPublishedOnWeb, fetchTripsAndRelatedData, orderedTripColumnApi, setOrderedTripColumnApi, setRiderGridApi, setTrainerGridApi, setManualGridApi, minsPerTrip, classSetupTime, plannedTime, setMinsPerTrip, setClassSetupTime, setPlannedTime, collapseMultipleTrips, setCollapseMultipleTrips }}>
            {children}
        </OrderOfGoContext.Provider>
    );
}

// context consumer hook
const useOrderOfGoContext = () => {
    // get the context
    const context = useContext(OrderOfGoContext);
  
    // if `undefined`, throw an error
    if (context === undefined) {
      throw new Error("useOrderOfGoContext was used outside of its Provider");
    }
  
    return context;
};

export { useOrderOfGoContext, OrderOfGoContextProvider }