import { useIntl } from 'react-intl'
import { ClassesTab } from './Detail/ClassesTab';
import { StandingsTab } from './Detail/StandingsTab';
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import {PageTitle} from '../../../_metronic/layout/core'
import {useEffect, useState} from 'react'
import { useForm, FormProvider, Controller} from "react-hook-form";
import axios from 'axios'
import { useAppSelector } from '../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoadingOverlay, useAlert, useProgress, useConfirm } from '../../modules/sgl-utils/DialogsProvider';
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions';
import Select from 'react-select'
import {
  reactSelectStyles,
} from '../../modules/sgl-utils/fieldControls'
import { useOutputContext } from '../../modules/output-listing/OutputListingContext';
import { useSyncUpdateContext } from '../../modules/output-listing/synchronizeUpdateContext';
import { HistoryTab } from '../../modules/components/HistoryTab';
import useAutoFocus from '../../modules/hooks/use-auto-focus';

export const dirtyValues = (
    dirtyFields,
    allValues
  ) => {
    // NOTE: Recursive function.
  
    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
      return allValues;
    }
  
    // Here, we have an object.
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [
        key,
        dirtyValues(dirtyFields[key], allValues[key])
      ])
    );
  };

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const CircuitDivisionDetail = (props) => {

    const intl = useIntl();
    const {circuit_division_id} = useParams();
	let current_circuit_division_id = props.id == "" ? 0 : props.id || props.id == 0 ? props.id : circuit_division_id
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const [form_meta, setFormMeta] = useState({})
    const [isDisabled, setIsDisabled] = useState(false)
    const alertDialog = useAlert();
    const navigate = useNavigate();
    const loadingOverlay = useLoadingOverlay()
    const {progressBar} = useProgress()
    const confirmDialog = useConfirm()
    const { getEventID } = useOutputContext()
    const [syncEvent, setSyncEvent] = useState(null)
    const { removeAllEventListeners, addSubscribedEvents, sse } = useSyncUpdateContext()
    const [loading, setLoading] = useState(true)

    const methods = useForm({
        defaultValues: 
        {
            "circuit_division":{
                "add_c_r_points": 0,
                "circuit_division_id": 0,
                "circuit_id": 0,
                "customer_id": 0,
                "ec_section": "",
                "lastmodified_date_time": "00/00/00-00:00:00",
                "name": "",
                "owner_organization_code": "",
                "rider_organization_code": "",
                "sgl_id": 0,
                "standingsbasedon": "",
                "standingscalmethod": "",
                "standingsselectionmethod": "",
                "trainer_organization_code": "",
                "usef_section": "",
                "status": ""
            },
            "circuit": {
                "name":"",
                "circuit_id": 0,
            },
            "standings": [],
            "standingsCircuitPoints": [], // points of currently selected standing
            "circuit_division_classes":[],
            "delete_circuit_division_classes":[],
        },
    }); 

    const { register, handleSubmit, reset, formState, setFocus} = methods
    const { dirtyFields } = formState; 
      
    const [standingscalmethod, setStandingscalmethod] = useState('')
    const [historyLogs, setHistoryLogs] = useState([])

    const onSubmit = async (form_data, action = 'SaveButton') => { 
        let submit_data = dirtyValues(dirtyFields, form_data) 
        let circuit_division_classes = methods.watch('circuit_division_classes')
        let circuit_division = methods.watch('circuit_division')

        if(circuit_division_classes && circuit_division_classes.length > 0){
            let added_circuit_divisions = circuit_division_classes?.filter(cdc_selected_class => cdc_selected_class.hasOwnProperty('selected') && cdc_selected_class.selected)
            submit_data.added_circuit_divisions = added_circuit_divisions
        }
        if(submit_data.circuit_division && current_circuit_division_id == 0){
            submit_data.circuit_division.standingsbasedon = circuit_division.standingsbasedon
            submit_data.circuit_division.standingscalmethod = circuit_division.standingscalmethod
            submit_data.circuit_division.standingsselectionmethod = circuit_division.standingsselectionmethod
        
        }

        return axios.post( process.env.REACT_APP_NEST_API_URL + '/circuit-divisions/circuitdivision', {
            params: {
                circuit_division_id: current_circuit_division_id,
                circuit_id: props.parent_id,
                customer_id: customer_id,
                data: submit_data
            }
          }).then((response) => {
                if(response.data.success) {
                    if(props?.id == undefined){
                        navigate("/circuits")
                    } else if(props.updateCallbackFromParent){
                        if (action !== 'CalculateStandingsButton') { // Don't close modal if called from calculate standings.
                            props.updateCallbackFromParent()
                            props.closeModal()
                        }
                    }
                } else {
                alertDialog({message: response.data.error_message, title: 'Error'})
            }
          }).catch((reason) => {
            let errors = reason.response.data.response.errors.join("<br/>")
            alertDialog({message: errors, title: 'warning'})
          })    
    }

    const loadData = async () => {
        loadingOverlay({show: true})

        const response = await axios.get( process.env.REACT_APP_NEST_API_URL + '/circuit-divisions/detail', {
            params: {
                circuit_division_id: current_circuit_division_id,
                customer_id: customer_id,
                circuit_id: props.parent_id,
            }
        })

        const circuitDivisionData = response.data.data
        const circuitDivisionMetaData = response.data.metadata
        setHistoryLogs(response?.data.data.change_history_logs ?? [])

        //Check if record id is invalid then redirect to list page
        if ((circuitDivisionData || circuitDivisionData === '') && isInvalidRecord(circuitDivisionData?.circuit_division, current_circuit_division_id, 'circuit_division_id')){
            loadingOverlay({ show: false })

            if(props.updateCallbackFromParent){ // Close Modal
                props.updateCallbackFromParent()
                props.closeModal()
            }
        }
        setFormMeta(circuitDivisionMetaData)
        
        reset(circuitDivisionData)
        setIsDisabled(methods.getValues('circuit_division.standingsbasedon')==='Dollar')
        setStandingscalmethod(methods.getValues('circuit_division.standingscalmethod'))
        if (methods.getValues('circuit_division.standingsbasedon')==='Dollar'){
            methods.setValue('circuit_division.standingscalmethod', '')
        }

        // Hide loading overlay
        loadingOverlay({ show: false })
    }

    useEffect(() => {
        loadData()
        setLoading(false)
    },[circuit_division_id, current_circuit_division_id])
    

      const handleRoadioChange = (value) => {
        if (value === 'Points'){
            setIsDisabled(false)
            methods.setValue('circuit_division.standingscalmethod',standingscalmethod,{shouldDirty: false} )
            methods.setValue('circuit_division.standingsbasedon','Points',{shouldDirty: true} )
        } else {
            setIsDisabled(true)
            if (methods.watch('circuit_division.standingsbasedon') !== standingscalmethod){
                methods.setValue('circuit_division.standingscalmethod','',{shouldDirty: false} )
            }
            methods.setValue('circuit_division.standingsbasedon','Dollar',{shouldDirty: true} )
        }
      }

    const standingsBasedOnOptions = [
        { label: "Horse", value: 'Horse' },
        { label: "Horse/Rider", value: 'Horse/Rider' },
        { label: "Rider" , value: 'Rider'},
        { label: "Owner", value: 'Owner'  },
        { label: "Trainer", value: 'Trainer' },
    ]

    const calculateStandings = async () => {
        const eventID = getEventID('calculate-standings')
        try {
            if (await confirmDialog({ message: 'This will save Circuit Division record. Are you sure you want to Calculate Standings?'})) {
                // Save circuit division record before calculating standings
                // Call submit without redirecting the page and call standing where redirect
                loadingOverlay({ show: true, message: 'Saving Circuit Division..'})
                await onSubmit(methods.getValues(), 'CalculateStandingsButton')
                loadingOverlay({ show: false, message: 'Saving Circuit Division..'})

                progressBar({ show: true, eventID, timeElapsed: true, title: 'Calculate Standings' })
                const response = await axios.get(`${NEST_API_URL}/circuit-divisions/calculateStandings?circuit_division_id=${current_circuit_division_id}&customer_id=${customer_id}&circuit_id=${props.parent_id}&event_id=${eventID}`)
                progressBar({ show: false, eventID })
                if (!response.data.success) {
                    alertDialog({ message: response.data.error })
                } else { // Reload page data incase of successful standings calculation
                    await loadData()
                }
            }
        } catch (reason) {

        } finally {
            progressBar({ show: false, eventID })
        }
    }

    useEffect(() => { // Update callback useEffect
        if(syncEvent){
            const getCircuitDivisionById = async () => {
                const response = await axios.get( process.env.REACT_APP_NEST_API_URL + '/circuit-divisions/getCircuitDivisionById', {
                    params: {
                        customer_id,
                        circuit_division_id: methods.getValues('circuit_division.circuit_division_id'),
                    }
                })

                methods.setValue('circuit_division.status', response.data.status)
            }
            getCircuitDivisionById()        
        }
    }, [syncEvent]);

    useEffect(() => { // Remove all existing event listener and then generate and add new events with callback in SSE on change of output grid area
        const circuitDivisionId = methods.getValues('circuit_division.circuit_division_id')
        if(circuitDivisionId && sse){
            let syncEvents = []
            let eventIds = []
            // For Areas who contains show_id should add show id in its event id
            eventIds = [
                `CircuitDivisions-${customer_id}-${circuitDivisionId}`, 
            ]

            for(let eventId of eventIds){
                // Sync Event will hold an array of eventId and callback
                syncEvents.push({
                    eventId, 
                    callback: (event) => setSyncEvent(event)
                })
            }
            addSubscribedEvents(syncEvents) 
        }
    }, [customer_id, methods.watch('circuit_division.circuit_division_id'), sse]);

    useEffect(() => {
        const fieldsToCheck = ['owner_organization_code', 'trainer_organization_code', 'rider_organization_code', 'standingsbasedon', 'standingscalmethod', 'add_c_r_points', 'standingsselectionmethod']
        if (formState.isDirty && Object.values(dirtyFields).length > 0) {
            let isDirtyFieldIncluded = false
            if (formState.dirtyFields.circuit_division) {
                for (const fieldToCheck of fieldsToCheck) {
                    if (formState.dirtyFields.circuit_division[fieldToCheck]) {
                        isDirtyFieldIncluded = true
                    }
                }
            }

            if (isDirtyFieldIncluded) {
                methods.setValue('circuit_division.status', 'Computation required', { shouldDirty: true })
            }
        }
    }, [formState])
    
    const tabs = ["CLASSES", "STANDINGS", "HISTORY"]
    const componentTags = [
        <ClassesTab shows={props.shows} circuit_division_classes={methods.watch('circuit_division_classes')}/>, 
        <StandingsTab calculateStandings={calculateStandings} />,
        <HistoryTab area="Circuit Divisions" rowData={historyLogs ?? []} />
    ]

    useAutoFocus('NAME', loading)

    return (
        <>
        {!loading && (
		<>
        {props?.id == undefined ? <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CIRCUITDIVISIONS'})}</PageTitle> : "" }
            <FormProvider {...methods}>
                <div className='modal-body px-4 show-input-form input-form'>
                    <form  id={"CircuitDivisionDetail_"+current_circuit_division_id} noValidate  className='form max-width' onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }>
                        <div id="cicuit-divisions-input-form" className='modal-body py-3 px-4 horse-input-form input-form'>
                            <div className='card-body p-0'> {/* Card Body */}
                                <div className="form-group row"> {/* Column Group */}
                                    <div className='row mb-2'>
                                        <div className='col-lg-5'> {/* Column 1 */}
                                            <div className='row mb-2'>
                                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='NAME' data-tooltip-id="CIRCUITDIVISIONS.DETAIL.LABEL.NAME">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.NAME' })}</label>
                                                <div className='col'>
                                                    <input
                                                        {...methods.register('circuit_division.name', { required: "Enter Circuit Division Name." })}
                                                        id='NAME'
                                                        type='text'
                                                        autoFocus
                                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.NAME' })}
                                                    />
                                                    { methods.formState.errors?.circuit_division?.name && <div><span className='error_message'>{methods.formState.errors.circuit_division.name?.message}</span></div>}
                                                </div>
                                            </div>
                                            <div className='row mb-14'>
                                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='CIRCUITNAME' data-tooltip-id="CIRCUITDIVISIONS.DETAIL.LABEL.CIRCUITNAME">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.CIRCUITNAME' })}</label>
                                                <div className='col'>
                                                    <input
                                                        id='CIRCUITNAME'
                                                        {...register( 'circuit.name', 
                                                            {
                                                            required: false
                                                            })
                                                        }
                                                        type='text'
                                                        readOnly={true}
                                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.CIRCUITNAME' })}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='STANDINGSBASEDON' data-tooltip-id="CIRCUITDIVISIONS.DETAIL.LABEL.STANDINGBASEDON">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.STANDINGSBASEDON' })}</label>
                                                <div className='col'>
                                                    <Controller
                                                        name = 'circuit_division.standingsselectionmethod'
                                                        render={({field: { onChange, value }}) => (
                                                            <Select 
                                                                value={
                                                                    standingsBasedOnOptions.find(option => option.value === value) ?? { label: 'Horse', value: 'Horse' }
                                                                }
                                                                theme={(theme) => ({ ...theme, borderRadius: 0 })}
                                                                options={standingsBasedOnOptions}
                                                                onChange={e => onChange(e.value)}
                                                                styles={reactSelectStyles}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='OWNERISMEMBEROF' data-tooltip-id="CIRCUITDIVISIONS.DETAIL.LABEL.OWNERISMEMBEROF">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.OWNERISMEMBEROF' })}</label>
                                                <div className='col'>
                                                    {form_meta.org_list &&
                                                        <Controller
                                                            name='circuit_division.owner_organization_code'
                                                            render={({field: { onChange, value }}) => (
                                                                <Select
                                                                    options={form_meta.org_list}
                                                                    id='OWNERISMEMBEROF'
                                                                    value={form_meta.org_list.find(org => org.label.toUpperCase() == value?.toUpperCase()) ?? ''}
                                                                    isSearchable={true}
                                                                    onChange={org => onChange(org.label)}
                                                                    theme={(theme) => ({ ...theme, borderRadius: 0 })}
                                                                    styles={reactSelectStyles}
                                                                    placeholder='Select...'
                                                                />
                                                            )}
                                                        />
                                                    } 
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='TRAINERISMEMBEROF' data-tooltip-id="CIRCUITDIVISIONS.DETAIL.LABEL.TRAINERISMEMBEROF">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.TRAINERISMEMBEROF' })}</label>
                                                <div className='col'>
                                                    { form_meta.org_list && (  
                                                        <Controller
                                                            name = 'circuit_division.trainer_organization_code'
                                                            render={({field: {onChange, value, label}}) => (
                                                                <Select
                                                                    options={form_meta.org_list}
                                                                    id='TRAINERISMEMBEROF'
                                                                    value={form_meta.org_list.find(org => org.label.toUpperCase() == value?.toUpperCase()) ?? ''}
                                                                    name={label}
                                                                    isSearchable={true}
                                                                    onChange={org => onChange(org.label)}
                                                                    theme={(theme) => ({ ...theme, borderRadius: 0 })}
                                                                    styles={reactSelectStyles}
                                                                    placeholder='Select...'
                                                                />
                                                            )}
                                                        />
                                                    )} 
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-160px' htmlFor='RIDERISMEMBEROF' data-tooltip-id="CIRCUITDIVISIONS.DETAIL.LABEL.RIDERISMEMBEROF">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.RIDERISMEMBEROF' })}</label>
                                                <div className='col'>
                                                    { form_meta.org_list && ( 
                                                        <Controller
                                                            name = 'circuit_division.rider_organization_code'
                                                            render={({field: {onChange, value, label}}) => (
                                                                <Select
                                                                    options={form_meta.org_list}
                                                                    id='RIDERISMEMBEROF'
                                                                    value={form_meta.org_list.find(org => org.label.toUpperCase() == value?.toUpperCase()) ?? ''}
                                                                    name={label}
                                                                    isSearchable={true}
                                                                    onChange={org => onChange(org.label)}
                                                                    theme={(theme) => ({ ...theme, borderRadius: 0 })}
                                                                    styles={reactSelectStyles}
                                                                    placeholder='Select...'
                                                                />
                                                            )}
                                                        />
                                                    )} 
                                                </div>
                                            </div>
                                        </div> {/* End Column 1 */}
                                        <div className='col-lg-6'> {/* Column 2 */}
                                            <div className='row mb-2'>
                                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-110px' htmlFor='USEFSECTION'data-tooltip-id="CIRCUITDIVISIONS.DETAIL.LABEL.USEFSECTION">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.USEFSECTION' })}</label>
                                                <div className='col align-items-center'>
                                                    <div className='row'>
                                                        <div className='col-lg-3'>
                                                            <input
                                                                {...methods.register('circuit_division.usef_section', {
                                                                    required: false,
                                                                })}
                                                                id='USEFSECTION'
                                                                type='number'
                                                                onBlur={(e)=> {
                                                                    if(form_meta.usef_section.filter((ss) => ss.value === methods.getValues('circuit_division.usef_section')).length === 0){
                                                                        methods.setValue("circuit_division.usef_section",'',{shouldDirty: true})
                                                                    }
                                                                }}
                                                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                                placeholder={intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.USEFSECTION' })}
                                                            />
                                                        </div>
                                                        <div className='col-lg-9'>
                                                            {form_meta.usef_section && ( 
                                                        
                                                                <Controller
                                                                    name = 'circuit_division.usef_section'
                                                                    render={({field: {onChange, value, label}}) => (
                                                                    <Select
                                                                            options={form_meta.usef_section}
                                                                            getOptionLabel={option =>
                                                                                `${option.label} [${option.value}]`
                                                                            }
                                                                            value={value !== undefined ? form_meta.usef_section.filter((ss) => ss.value === value):""}
                                                            
                                                                            id='USEFSECTION'
                                                                            name={label}
                                                                            isSearchable={true}
                                                                            // setting value for USEF code field - two way binding
                                                                            onChange={(usef_section)=>{
                                                                                methods.setValue("circuit_division.usef_section",usef_section.value,{shouldDirty: true})
                                                                            }}
                                                                            
                                                                            theme={(theme) => ({
                                                                                ...theme,
                                                                                borderRadius: 0,
                                                                            })}
                                                                            styles={reactSelectStyles}
                                                                            placeholder='Select'
                                                                        />
                                                                )}
                                                            />
                                                        )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-5'>
                                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-110px' htmlFor='ECSECTION'data-tooltip-id="CIRCUITDIVISIONS.DETAIL.LABEL.ECSECTION">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.ECSECTION' })}</label>
                                                <div className='col align-items-center'>
                                                    <div className='row'>
                                                        <div className='col-lg-3'>
                                                            <input
                                                                {...methods.register('circuit_division.ec_section', {
                                                                    required: false,
                                                                })}
                                                                id='ECSECTION'
                                                                type='number'
                                                                onBlur={(e)=> {
                                                                    if(form_meta.ec_section.filter((ss) => ss.value === methods.getValues('circuit_division.ec_section')).length === 0){
                                                                        methods.setValue("circuit_division.ec_section",'',{shouldDirty: true})
                                                                    }
                                                                }}
                                                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                                placeholder={intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.ECSECTION' })}
                                                            />
                                                        </div>
                                                        <div className='col-lg-9'>
                                                            {form_meta.ec_section && ( 
                                                                <Controller
                                                                    name = 'circuit_division.ec_section'
                                                                    render={({field: {onChange, value, label}}) => (
                                                                    <Select
                                                                            options={form_meta.ec_section}
                                                                            getOptionLabel={option =>
                                                                                `${option.label} [${option.value}]`
                                                                            }
                                                                            id='ECSECTION'
                                                                            // add substring search for EC code 
                                                                            value={value !== undefined ? form_meta.ec_section.filter((ss) => ss.value === value):""}
                                                                            name={label}
                                                                            isSearchable={true}
                                                                            // setting value for EC code field - two way binding
                                                                            onChange={(ec_section)=>{
                                                                                methods.setValue("circuit_division.ec_section", ec_section.value, {shouldDirty:true})
                                                                            }}
                                                                            
                                                                            theme={(theme) => ({
                                                                            ...theme,
                                                                            borderRadius: 0,
                                                                            })}
                                                                            styles={reactSelectStyles}
                                                                            placeholder='Select'
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <fieldset>
                                                <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.GROUPLABEL.STANDINGSCALCULATIONMETHOD' })} <b></b></legend>
                                                <div className='row mb-2'>
                                                    <div className='col-lg-12 d-flex align-items-center ps-10'>
                                                        <input 
                                                            className="form-check-sm me-1" 
                                                            type="radio" 
                                                            onChange={(e)=>{handleRoadioChange('Points')}}
                                                            checked = {methods.watch('circuit_division.standingsbasedon') === 'Points'}
                                                            value={methods.watch('circuit_division.standingsbasedon') === 'Points'? 'Points': 'Dollar'}
                                                            id="POINTS" 
                                                        />
                                                        <label className='col-form-label fs-5 py-1 ignore-max-width me-5 w-lg-100px' htmlFor='POINTS'data-tooltip-id="CIRCUITDIVISIONS.DETAIL.SECTION.STANDINGSCALCULATIONMETHOD.LABEL.POINTS"> {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.POINTS' })}</label>

                                                        <input 
                                                            className="form-check-sm me-1" 
                                                            type="radio" 
                                                            onChange={(e)=>{handleRoadioChange('Dollar')}}
                                                            checked = {methods.watch('circuit_division.standingsbasedon') === 'Dollar'}
                                                            value={methods.watch('circuit_division.standingsbasedon') === 'Dollar'? 'Dollar': 'Points'}
                                                            id="DOLLAR" 
                                                        />
                                                        <label className='col-form-label fs-5 py-1 ignore-max-width' htmlFor='DOLLAR' data-tooltip-id="CIRCUITDIVISIONS.DETAIL.SECTION.STANDINGSCALCULATIONMETHOD.LABEL.DOLLAR"> {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.DOLLAR' })}</label>
                                                    </div>
                                                </div>
                                                <div className='row mb-2 mx-10 border-dotted w-lg-325px' style={{ borderWidth: '1px' }}>
                                                    <div className='col-lg-12 d-flex align-items-center'>
                                                        <input 
                                                            {...methods.register('circuit_division.standingscalmethod')}
                                                            className="form-check-sm me-1" 
                                                            type="radio" 
                                                            disabled= {isDisabled}
                                                            value = 'Linear'
                                                            id="FROMCLASSES" 
                                                        />
                                                        <label className='col-form-label fs-5 py-1 ignore-max-width me-5 w-lg-125px' htmlFor='FROMCLASSES' data-tooltip-id="CIRCUITDIVISIONS.DETAIL.SECTION.STANDINGSCALCULATIONMETHOD.LABEL.FROMCLASSES"> {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.FROMCLASSES' })}</label>
                                                    </div>
                                                    <div className='col-lg-12 d-flex align-items-center'>
                                                        <input 
                                                            {...methods.register('circuit_division.standingscalmethod')}
                                                            className="form-check-sm me-1" 
                                                            type="radio" 
                                                            value = 'Hunter Increment'
                                                            disabled= {isDisabled}
                                                            id="hunterIncrement" 
                                                        />
                                                        <label 
                                                            className='col-form-label fs-5 py-1 ignore-max-width me-5 w-lg-125px' htmlFor='hunterIncrement'
                                                            data-tooltip-id="CIRCUITDIVISIONS.DETAIL.SECTION.STANDINGSCALCULATIONMETHOD.LABEL.INCREMENTTABLE"
                                                        >
                                                            {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.HUNTERINCREMENT' })}
                                                        </label>
                                                        <div className='form-check-sm form-check-custom me-2'>
                                                            <input 
                                                                {...methods.register('circuit_division.add_c_r_points')}
                                                                className='form-check-input' 
                                                                type='checkbox' 
                                                                id='ADDCRPOINTS' 
                                                            />
                                                        </div>
                                                        <label className='col-form-label fs-5 py-1' htmlFor='ADDCRPOINTS' data-tooltip-id="CIRCUITDIVISIONS.DETAIL.SECTION.STANDINGSCALCULATIONMETHOD.LABEL.ADDCRPOINTS"> {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.ADDCRPOINTS' })}</label>
                                                    </div>
                                                    <div className='col-lg-12 d-flex align-items-center'>
                                                        <input 
                                                            {...methods.register('circuit_division.standingscalmethod')}
                                                            className="form-check-sm me-1" 
                                                            type="radio"
                                                            disabled= {isDisabled}
                                                            value = 'Circuit Points'
                                                            id="circuitPoints"
                                                        />
                                                        <label 
                                                            className='col-form-label fs-5 py-1 ignore-max-width me-5 w-lg-125px' htmlFor='circuitPoints'
                                                            data-tooltip-id="CIRCUITDIVISIONS.DETAIL.SECTION.STANDINGSCALCULATIONMETHOD.LABEL.CIRCUITPOINTS"
                                                        > 
                                                            {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.LABEL.CIRCUITPOINTS' })}
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div> {/* End Column 2 */}
                                    </div> {/* End Column Group */}

                                    <div className='d-flex flex-column my-2 border p-2 bg-white'>
                                        <ul className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                                            {
                                                tabs.map((tab, index) => {
                                                    return (
                                                        <li className='nav-item'>
                                                            <a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == 0 ? ' active' : '')}
                                                                id={tab + "-tab"}
                                                                data-bs-toggle='tab'
                                                                href={"#" + tab + "-Circuit-Divisions-" +current_circuit_division_id}
                                                                data-tooltip-id={`CIRCUITDIVISIONS.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.' + tab })}
                                                            </a>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>

                                        <div className="tab-content mt-2" id="myTabContent">
                                            {
                                                tabs.map((tab, index) => {
                                                    return (
                                                        <div className={"tab-pane fade show" + (index == 0 ? " active" : "")} id={tab + "-Circuit-Divisions-" +current_circuit_division_id} role="tabpanel" >
                                                            {componentTags[index]}
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div> {/* End Column Group Body */}
                            </div> {/* End Card Body */}
                        </div>
                        <InputFormFooter goBackPath={'/circuits'} propId={props?.id}/> 
                    </form>
                </div>    
            </FormProvider>
            </>
    		)}
        </>
    );
}

export { CircuitDivisionDetail }