import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Assets
import "flatpickr/dist/themes/material_blue.css";
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';
import { useMemo } from 'react';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';

const modalsRoot = document.getElementById('root-modals') || document.body

const RolledbackPeoplePaymentsDetail = ({ show, handleClose, data, calledFor }) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()

    const containerStyle = useMemo(() => ({ width: '100%', height: '200px' }), []);
    const columnDefs = [
        { field: 'number', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.PRIZEMONEYRECIPIENT.DIALOG.ROLLEDBACK.PRIZEMONEY.CHANGES.GRID.NUMBER' }), width: 90 },
        { field: 'Show.show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.PRIZEMONEYRECIPIENT.DIALOG.ROLLEDBACK.PRIZEMONEY.CHANGES.GRID.SHOW' }), width: 250, flex: 1 },
        { field: 'horse', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.PRIZEMONEYRECIPIENT.DIALOG.ROLLEDBACK.PRIZEMONEY.CHANGES.GRID.HORSE' }), width: 200 },
        { field: 'prev_balance', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.PRIZEMONEYRECIPIENT.DIALOG.ROLLEDBACK.PRIZEMONEY.CHANGES.GRID.PREVBALANCE'}), width: 120, cellRenderer: params => currencyFormatter(params.value) },
        { field: 'new_balance', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.PRIZEMONEYRECIPIENT.DIALOG.ROLLEDBACK.PRIZEMONEY.CHANGES.GRID.NEWBALANCE'}), width: 120, cellRenderer: params => currencyFormatter(params.value) },
    ]

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered payment-rollback-modal'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{calledFor === 'PMR' ? intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.PRIZEMONEYRECIPIENT.CHANGE.TITLE' }) : intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.RESPONSIBLEPARTY.CHANGE.TITLE' })}</h2>
                
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>

                    <div className='card-body p-0'>
                        <div className='row mb-3'>
                        <span className="d-inline-block align-bottom fs-6" dangerouslySetInnerHTML={{__html: calledFor === 'PMR' ? intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.PRIZEMONEYRECIPIENT.DIALOG.ROLLEDBACK.PRIZEMONEY.CHANGES.MESSAGE' }) :  intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.RESPONSIBLE.PARTY.CHANGES.MESSAGE' })}}></span>
                        </div>
                    </div>

                    <fieldset className='mb-2'>
                        <div className='row'>
                            <OutputListingGridStatic
                                area ={"Entires - PMR/ RP payment rollback Detail"}
                                columnDefs={columnDefs}
                                rowData={data?.entriesDetail}
                                containerStyle={containerStyle}
                            ></OutputListingGridStatic>
                        </div>
                    </fieldset>

                    <div className='card-footer px-0'>
                        <div className="row">
                            <div className="col-sm-6 d-flex align-items-center justify-content-start pe-2">
                                <label className="form-label text-start mb-0 fs-6 fw-bold">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.PRIZEMONEYRECIPIENT.DIALOG.ROLLEDBACK.PRIZEMONEY.CHANGES.ACCOUNT.BALANCE.BEFORE' })}</label>
                                <div className="ms-1 fs-6">{currencyFormatter(data?.accountBalanceBeforeRollback ? data?.accountBalanceBeforeRollback : 0)}</div>
                            </div>
                            <div className="col-sm-6 d-flex align-items-center justify-content-end">
                                <label className="form-label text-end mb-0 fs-6 fw-bold">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.PRIZEMONEYRECIPIENT.DIALOG.ROLLEDBACK.PRIZEMONEY.CHANGES.ACCOUNT.BALANCE.AFTER' })}</label>
                                <div className="ms-1 fs-6">{currencyFormatter(data?.accountBalanceAfterRollback ? data?.accountBalanceAfterRollback : 0)}</div>
                            </div>
                        </div>
                    </div>

                    
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <div>
                            <button className='btn btn-sm btn-secondary fw-bold text-uppercase' type="button" onClick={handleClose} autoFocus>
                            { intl.formatMessage({ id: "FORM.INPUT.ENTRIES.TAB.CLASSES.PRIZEMONEYRECIPIENT.CHANGE.BUTTON.DONE"})}
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { RolledbackPeoplePaymentsDetail }