import { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import Select from 'react-select';

// Assets
import "flatpickr/dist/themes/material_blue.css";

// Custom Components
import { AllClassesTab } from './Classes/AllClassesTab';
import { PrizeAndRecipientSection } from './Classes/PrizeAndRecipientSection';
import { RTOTab } from './Classes/RTOTab';
import { PersonLookupForm } from './General/PersonLookupForm';
import { SearchInput } from './SearchInput';
import { MetronicSplashScreenProvider } from '../../../../_metronic/layout/core';
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls';

const ClassesTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext();
    const alertDialog = useAlert()
    const [tabNames, setTabNames] = useState([]);
    const [trigger, setTrigger] = useState(true);
    const riderRef = useRef()
    const confirmDialog = useConfirm()

    const customer_id = useAppSelector(state=> state.showCompany.company_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const governingOrganization = useAppSelector(state => state.showCompany.governing_organization)

    const [selectedTab, setSelectedTab] = useState(0)
    const [showPersonLookup, setShowPersonLookup] = useState(false)
    const watchTrainerAccount = methods.watch("entry.trainer_account")

    const [person_search_status, setPersonSearchStatus] = useState('');
    const [search_count_status, setSearchCountStatus] = useState('');
    const [default_org, setDefaultOrg] = useState(governingOrganization);
    const [personStatus, setPersonStatus] = useState(''); // use to store status like EC Non-Active Coach for RTO
    const [people_search_list, setPeopleSearchList] = useState([]);
    const [dialogData, setDialogData] = useState({
        first_name: '',
        last_name: '',
        city: '',
        state:'',
        usef_no: 0,
        search_term: '',
        company_name: ''
    })
    const loadingOverlay = useLoadingOverlay()


    const onBlur = (e) => {
        if(e.target.value && e.target.value !=''){
            callSearchPersonApi(e.target.value)
        }
    }

    const onSearch = (e) => {
        if(e.key == 'Enter'){
            e.target.blur();
        }
    }

    useEffect(() => {
        setTabNames(tabName())
    }, [methods.watch('entry.EntryRiders')]);

    useEffect(() => {
        if(selectedTab > 0){
            const rider_id = tabNames[selectedTab].id
            let status = props.RTOSuspensionStatus('RIDER', rider_id)
            setPersonStatus(status)
        }else{
            setPersonStatus('')
        }
    }, [selectedTab])

    const tabName = () => {
        let tabs = [{name: 'ALLCLASSES', id: '0'}]
        if(methods.getValues('entry.EntryRiders')){
            methods.getValues('entry.EntryRiders').map(rider => {
                // Create tab if rider is not deleted
                if(!rider.isDeleted){
                    tabs.push({id:rider.rider_id, name: rider.rider_name})
                }  
            })
        }
        return tabs
    }

    useEffect(() => {
        setTabNames(tabName())
        setSelectedTab(0)
    }, [trigger]);

    // //Function for handling Search Tem
    const handleSearchTerm = (search_term) => {
    
        let firstName = props.getFirstNameFromSearchString(search_term)
        let lastName = props.getLastNameFromSearchString(search_term)
        let searchTerm = firstName != ''? firstName + (lastName != '' ?' ':'')+ lastName : lastName == '' ? '' : lastName
        setDialogData((prevDialogData) => ({...prevDialogData, first_name: firstName, last_name: lastName, search_term: searchTerm}))
    }

    async function callSearchPersonApi(search_term){
        loadingOverlay({show: true})
        let current_riders = methods.getValues('entry.EntryRiders')?.filter(rider => !rider.isDeleted)
        
        if(methods.getValues('max_rider_limit') > 1 && methods.getValues('max_rider_limit') <= current_riders?.length){
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.MAXRIDERLIMIT.ERROR'}), title: 'Error'})
        } else {
            let search_response = await props.callSearchPersonApi(search_term, 'RIDER')
            if (search_response.showPersonLookup){
                setDialogData(search_response.dialog_data)
                setPeopleSearchList(search_response.search_list)
                setSearchCountStatus(search_response.people_count_status)
                setDefaultOrg(search_response.default_org)
                setShowPersonLookup(search_response.showPersonLookup)
            } else {
                setPersonSearchStatus(search_response.search_status)
                methods.setValue('rider_search', '')
            }
        
        }
        loadingOverlay({show: false})
    }

    const riderSelect = () => {
        if (methods.getValues("entry")) {
            let entryRiders = methods.getValues("entry.EntryRiders")?methods.getValues("entry.EntryRiders"):[]
            entryRiders = entryRiders.filter(rider => !rider.isDeleted)
            let rider_ids = entryRiders.map(key => key.rider_id)
            const entry = methods.getValues('entry')

            let new_rider_ids = [Number(entry?.prizemoneyrecipient_id), entry?.entryowner_id, entry?.trainer_id, Number(entry?.responsibleparty_id)]
            new_rider_ids = new_rider_ids.filter(item => rider_ids.indexOf(item) < 0);

            let riders = {}
            for(let new_rider_id of new_rider_ids){
                let people = methods.getValues(`linked_people[${new_rider_id}].people_data`)
                if(people && !people.isfarmrto && people.fl_name){
                    riders[new_rider_id] = methods.getValues(`linked_people[${new_rider_id}].people_data.fl_name`)
    
                }
            }

            // commented react select
            // const riderOptions = Object.keys(riders)?.map((rto_id) => ({
            //     value: rto_id, 
            //     label: riders[rto_id]
            // }));
            // riderOptions.unshift({value: '', label: 'Select New Rider'})

            return (
                <>
                    <select
                        ref={riderRef}
                        className='form-select form-select-sm fs-6 min-h-20px py-1 w-lg-225px'
                        onChange={(e) => onAddRiderChange(e)}
                        disabled={watchTrainerAccount}
                    >
                        <option value=''>Select New Rider</option>
                        {Object.keys(riders).map(rto_id => {
                            return <option value={rto_id}>{riders[rto_id]}</option>
                        })
                        }
                    </select>

                    {/* <Select
                        ref={riderRef}
                        onChange={(e) => onAddRiderChange(e)}
                        defaultValue={riderOptions[0]}
                        options={riderOptions}
                        styles={{
                            ...reactSelectStyles,
                            control: (provided) => ({
                                ...provided,
                                fontSize: 12,
                                color: "#3F4254 !important",
                                fontWeight: 'normal',
                                width: '180px',
                                minHeight: '12px',
                            }),
                            indicatorsContainer: (provided, state) => ({
                                ...provided,
                                height: '30px',
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                fontSize: 12,
                                padding: 0,
                                color: state.isSelected ? "#ffffff" : "#3F4254 !important",
                                fontWeight: 'normal',
                                padding: '0 6px'
                            }),
                            valueContainer: (provided, state) => ({
                                ...provided,
                                height: '30px',
                                padding: '0 6px'
                            }),
                        }}
                    /> */}
                </>
            )
        }
    }

    const onAddRiderChange = async (e) => {
        let people = methods.getValues('linked_people['+ e.target.value +']')
        if(e.target.value != ''){
            let message = intl.formatMessage({id: "ENTRIES.CLASSES.RIDERDROP.CONFRIMMESSAGE"})+' '+people?.people_data?.fl_name+ ' as Rider ?'
            const choice = await confirmDialog({message, icon: 'warning' })
            if(choice){
                let current_riders = methods.getValues('entry.EntryRiders')?.filter(rider => !rider.isDeleted)
                if(methods.getValues('max_rider_limit') > 1 && methods.getValues('max_rider_limit') <= current_riders?.length){
                    alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.MAXRIDERLIMIT.ERROR'}), title: 'Error'})
                    e.target.value = ''
                    return
                }

                people.memberships_data = people.membership_data
                props.updatePeople(people, governingOrganization, 'RIDER')
                e.target.value = ''
            }
        }
    }

    async function getSelectedPeople(people, organization, person_role){
        let search_status = await props.getSelectedPeople(people, organization, person_role)
        setTrigger(!props.trigger)

        if(search_status){
            setPersonSearchStatus(search_status)
        }
    }

    return (
        <>
            <div className='form-group mb-2'>
                {showPersonLookup && <PersonLookupForm 
                    show={showPersonLookup} 
                    handleClose={() => {methods.setValue('rider_search', '');setShowPersonLookup(false);}} 
                    customer_id={customer_id} 
                    fieldName={'rider_search'} 
                    dialogData={dialogData}    
                    setDialogData={setDialogData}                 
                    person_search_list={people_search_list}
                    default_org={default_org}
                    person_search_status={search_count_status}
                    type = {'RIDER'}
                    show_id = {currentShowID}
                    handleSearchTerm = {handleSearchTerm}
                    getSelectedPeople = {getSelectedPeople}
                    generateNewRTOId = {props.generateNewRTOId}
                    updatePeople = {props.updatePeople}
                />}
                <fieldset className='mb-2'>
                    <legend className="fs-5 fw-bold d-flex entries-search-legend">
                        <div className='d-flex align-self-center w-50px me-10'><label data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.RIDER.LABEL.RIDER`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.GROUPLABEL.RIDER' })}</label></div>
                        <SearchInput containerClass='me-2 w-225px' onSearch={onSearch} onBlur={onBlur} fieldName={'rider_search'} disabled={watchTrainerAccount}/>
                        <label className='fs-6 py-1 text-danger col-lg-3 d-flex align-items-center ms-2 text-capitalize'>{person_search_status}</label>
                        {
                            personStatus ? 
                            <div className={'d-flex align-items-center col'}>
                                <i className="fas fa-xl fa-regular fa-triangle-exclamation me-1 text-warning"></i>
                                <label className='fs-6 py-1'>{personStatus}</label>
                            </div>
                            : '' 
                        }
                        <div className={'col d-flex align-items-center my-1 justify-content-end' + (selectedTab != 0 ? ' d-none' : '')}>
                            {riderSelect()}
                        </div>
                        <b></b>
                    </legend>
                    <div className='d-flex flex-column my-2 border bg-white px-0'>
                        <ul className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                            {tabNames.length > 0 &&
                                tabNames.map((tab, index) => {
                                    let formattedTab = tab?.name?.split(' ')?.join('-')?.replaceAll('.', '\\.')
                                    return (
                                        <li key={index} className='nav-item' onClick={() => setSelectedTab(index)}>
                                            <a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == selectedTab ? ' active' : '') }
                                                id={tab.name + "-tab"}
                                                data-bs-toggle='tab'
                                                href={"#" + formattedTab}>{index == 0 ? intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.' + tab.name }) : tab.name}
                                            </a>
                                        </li>
                                    );
                                })
                            }
                        </ul>

                        <div className="tab-content mt-5 px-1 pb-2" id="myTabContent">
                            {tabNames.length > 0 &&
                                tabNames.map((tab, index) => {
                                    return (
                                        <div key={index} className={"tab-pane fade show" + (index == selectedTab ? ' active' : '')} id={tab?.name?.split(' ')?.join('-')} role="tabpanel" >
                                            {
                                                tab.name == "ALLCLASSES" ? <AllClassesTab callbackFromParent={props.callbackFromParent} data-tooltip-id="ENTRIES.DETAIL.TAB.CLASSES.SECTION.ALLCLASSES" /> : <RTOTab setRTO={props.setRTO} RTO={props.RTO} form_meta={props.form_meta} person_id={tab.id} trigger={trigger} setTrigger={setTrigger} RTOSuspensionStatus={props.RTOSuspensionStatus} entryCheckOnHold={props.entryCheckOnHold} callbackFromParent={props.callbackFromParent} entry_id={props.entryId} callFormSubmit={props.callFormSubmit} showTaxInformation={props.showTaxInformation} setShowTaxInformation={props.setShowTaxInformation} personIdForTaxStatusLookup={props.personIdForTaxStatusLookup} setPersonIdForTaxStatusLookup={props.setPersonIdForTaxStatusLookup} clearMembership={props.clearMembership} getRiderStatus={props.getRiderStatus}/>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <PrizeAndRecipientSection 
                        generateNewRTOId={props.generateNewRTOId} 
                        getSelectedPeople={props.getSelectedPeople} 
                        updatePeople={props.updatePeople}  
                        callSearchPersonApi={props.callSearchPersonApi} 
                        getFirstNameFromSearchString={props.getFirstNameFromSearchString}
                        getLastNameFromSearchString={props.getLastNameFromSearchString}
                        callbackFromParent={props.callbackFromParent}
                        lockPrizeMoneyAction={props.lockPrizeMoneyAction} 
                        setLockPrizeMoneyAction={props.setLockPrizeMoneyAction}
                        showTaxInformation={props.showTaxInformation}
                        setShowTaxInformation={props.setShowTaxInformation}
                        personIdForTaxStatusLookup={props.personIdForTaxStatusLookup} 
                        setPersonIdForTaxStatusLookup={props.setPersonIdForTaxStatusLookup}
                        selectedTaxFormRequestType={props.selectedTaxFormRequestType} 
                        setSelectedTaxFormRequestType={props.setSelectedTaxFormRequestType}
                        handleTinStatusCheck={props.handleTinStatusCheck}
                        form_meta={props.form_meta}
                    />
                </fieldset>
            </div>
        </>
    );
}

export { ClassesTab }