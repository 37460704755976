import { LoadingComponent } from "../LoadingComponent"

export const InProgressJobsWidget = ({ isLoading, queuedJobsData }) => {
    return (
        <div className={`card widget no-height-limit`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Job Stats</span>
                </h3>
            </div>
            { isLoading ? <LoadingComponent /> : 
            <div className='card-body py-3'>
                <div className='tab-content'>
                    <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='border-0'>
                            <th className='p-0 min-w-150px'></th>
                            <th className='p-0 min-w-140px'></th>
                            <th className='p-0 max-w-90px'></th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                queuedJobsData.inProgressQueuedJobs.map(queuedJob => {
                                    return(<tr>
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                { queuedJob.job_name }
                                            </a>
                                            <span className='text-muted fw-semibold d-block'>Completed: {queuedJob.completed_job_count} Job(s)</span>
                                        </td>
                                        <td className='text-end text-muted fw-semibold'>
                                            <div>{ queuedJob.started_job_count } Job(s)</div>
                                            <div>{ queuedJob.queued_job_count } Job(s)</div>
                                        </td>
                                        <td className='text-end'>
                                            <span className={`badge badge-light-success d-block mb-1`}>In-Progress</span>
                                            <span className={`badge badge-light-warning d-block`}>Queued</span>
                                        </td>
                                    </tr>)
                                }) 
                            }
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div> }
      </div>
    )
}