const HideJsonRenderer = (props) => {
    const getCellValueToDisplay = (props) => {
        // Check if the value is an array, and if so, return an empty string to hide the cell content
        if (Array.isArray(props.value) && props.value.length > 0) {
            return null; 
        }
        
        // Render the value if it's not an array
        let value = props.value
        if(typeof value == 'boolean'){
            value=value?.toString()
        }
        return <span>{value}</span>
    }

    return getCellValueToDisplay(props);
}

export default HideJsonRenderer;
