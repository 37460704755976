import { AgGridReact } from "ag-grid-react";
import { useIntl } from "react-intl";
import { getRowData, renderCheckBox, updateAGGridWithoutFlickering } from "../../../../modules/sgl-utils/agGridHelpers";
import { getNarrowHeaderHeight,getNarrowRowHeight } from "../../../../modules/sgl-utils/agGridHelpers";
import { useCurrencyFormatter } from "../../../../modules/sgl-utils/Formatters";
import { useEffect, useMemo } from "react";
import './../tab.css'
import CheckboxHeader from "../../../../modules/components/CheckboxHeader";
import { addFloatingNumbers } from "../../../../modules/sgl-utils/SglFunctions";
import { useAppSelector } from "../../../../redux/hooks";

const MoveCreditToAccountGrid = ({ selectAllEntries, setSelectAllEntries, summary, setSummary, moveCreditToAccountsGridRef, setMoveCreditToAccountsGridRef, moveCreditToAccountDataUpdated, setMoveCreditToAccountDataUpdated, creditType }) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()
    const customerID = useAppSelector(state => state.showCompany.company_id);
    const containerStyle = useMemo(() => ({ width: '100%', height: '60vh' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    const defaultColDef = useMemo(() => {
        return {
          resizable: true,
          sortable: true,
          suppressMenu: true,
          suppressMovable: true,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          headerClass: "ag-center-aligned-header",
          singleClickEdit: true,
        };
    }, []);

    const columnDefs = [
        { 
            field: 'include',
            headerName: "", 
            cellRenderer: params => params.node.rowPinned ? null : renderCheckBox(
                params, 
                (checked) => {
                    setMoveCreditToAccountDataUpdated()
                },
                params.node.data.transferCredit == 0
            ),
            maxWidth: 70,
            cellStyle: ({textAlign: 'center'}),
            headerComponentFramework: CheckboxHeader,
            headerComponentParams: {
                headerName: '',
                onChange: (checked) => { 
                    setSelectAllEntries(checked)
                },
                checked: selectAllEntries
            },
        },
        { 
            field: 'number', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TOACCOUNT.TAB.GRID.COL.ENTRY" }), 
            maxWidth: 90,
        },
        { 
            field: 'horse', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TOACCOUNT.TAB.GRID.COL.HORSE' }),
            flex: 1,
        },
        { 
            field: 'responsibleparty', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TOACCOUNT.TAB.GRID.COL.RP" }),
            flex: 1,
        },
        { 
            field: 'prizemoneyrecipient', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TOACCOUNT.TAB.GRID.COL.PRIZEMONEYRECIEPENT" }),
            flex: 1,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
        { 
            field: 'creditType', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TOACCOUNT.TAB.GRID.COL.TYPE" }),
            maxWidth: 120,
        },
        { 
            field: 'balance', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TOACCOUNT.TAB.GRID.COL.BALANCE" }),
            maxWidth: 110,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: 'right' }
        },
        // { 
        //     field: 'appliedPrizeMoney', 
        //     headerClass: 'ag-center-aligned-header',
        //     headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TOACCOUNT.TAB.GRID.COL.APPLIED.PRIZE.MONEY" }),
        //     maxWidth: 150,
        //     cellRendererSelector: (params) => {
        //         if (params.node.rowPinned) { //Renderer for footer row
        //             return null
        //         } else { //Renderer for other rows
        //             return {component: params => currencyFormatter(params.value)}
        //         }
        //     },
        //     wrapHeaderText: true,
        //     autoHeaderHeight: true,
        //     cellStyle: { textAlign: 'right' }
        // },
        { 
            field: 'unappliedPrizeMoney', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TOACCOUNT.TAB.GRID.COL.UNAPPLIED.PRIZE.MONEY" }),
            cellRenderer: params => currencyFormatter(params.value),
            maxWidth: 150,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { textAlign: 'right' }
        },
        { 
            field: 'transferCredit', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TOACCOUNT.TAB.GRID.COL.TO.BE.PAID" }),
            cellRenderer: params => currencyFormatter(params.value < 0 ? params.value * -1 : params.value),
            maxWidth: 150,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { textAlign: 'right' }
        }
    ]

    // update checkbox when header checkbox is updated
    useEffect(() => {
        if (moveCreditToAccountsGridRef) {
            const rowData = getRowData(moveCreditToAccountsGridRef)
           
            rowData.forEach(row => {
                row.include = selectAllEntries

                // Don't include rows with 0 transferable credit.
                if (selectAllEntries && row.transferCredit === 0) {
                    row.include = false
                }
                
            })

            updateAGGridWithoutFlickering(({ current: { api : moveCreditToAccountsGridRef}}), rowData)
            setMoveCreditToAccountDataUpdated()
        }
    }, [moveCreditToAccountsGridRef, selectAllEntries])

    // update total when grid data is updated
    useEffect(() => {
        if (moveCreditToAccountsGridRef) {
            const rowData = getRowData(moveCreditToAccountsGridRef)
            let _summary = {
                unappliedPrizeMoney: {count: 0, amount: 0}, 
                balance: {count: 0, amount: 0}, 
                total: {count: 0, amount: 0}
            }

            rowData.forEach(row => {
                                
                if (row.include) { //Set summary variables
                    if(Number(row.unappliedPrizeMoney) > 0 && ['Prize Money', 'Both'].includes(creditType)){
                        _summary.unappliedPrizeMoney.amount = addFloatingNumbers([_summary.unappliedPrizeMoney.amount, Math.abs(row.unappliedPrizeMoney)], 2) 
                        _summary.unappliedPrizeMoney.count++ 
                    }

                    if(Number(row.balance) < 0 && ['Balance Refund', 'Both'].includes(creditType)){
                        _summary.balance.amount = addFloatingNumbers([_summary.balance.amount, Math.abs(row.balance)], 2) 
                        _summary.balance.count++ 
                    }

                    _summary.total.count++ 
                    _summary.total.amount = addFloatingNumbers([_summary.total.amount, Math.abs(row.transferCredit)], 2) 
                }
            })

            moveCreditToAccountsGridRef.setPinnedBottomRowData([{
                unappliedPrizeMoney: _summary.unappliedPrizeMoney.amount,
                balance:_summary.balance.amount,
                transferCredit: _summary.total.amount
            }])

            setSummary({...summary, ..._summary})
        }
    }, [moveCreditToAccountDataUpdated])

    const getRowStyle = (params) => {
        if (params.node.rowPinned) {
            return { fontWeight: "bold" };
        }
    };

    const handleRowDoubleClicked = (row_data) => {
        if(row_data.data.entryId){
            window.open(process.env.PUBLIC_URL+'/entries/detail/'+row_data.data.entryId+`?customer_id=${customerID}&selectedTab=3`,'_blank')
        }
    }

    return (
        <div>
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact
                        onGridReady={params => setMoveCreditToAccountsGridRef(params.api)}
                        defaultColDef={defaultColDef}
                        rowData={[]}
                        columnDefs={columnDefs} 
                        rowStyle={{ fontSize: '12px' }}
                        suppressScrollOnNewData={true}
                        headerHeight={getNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                        stopEditingWhenCellsLoseFocus={true}
                        getRowClass={params => {
                            if (params.data.isRedRow) {
                                return 'expired-text'
                            }  
                        }}
                        onRowDoubleClicked={(row_data) => handleRowDoubleClicked(row_data)}
                        overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while entries are loading...</span>'}
                        getRowStyle={params => getRowStyle(params)}
                    />
                </div>
            </div>
        </div>
       
    )
}


export default MoveCreditToAccountGrid