import React, { useState } from 'react';
import { IStatusPanelParams } from 'ag-grid-community';
import { useOutputContext } from './OutputListingContext';

export default (props: IStatusPanelParams) => {
  const [filtered, setFiltered] = useState(0);
  const [total, setTotal] = useState(0);
  const {outputGrid} = useOutputContext();

  props.api.addEventListener('modelUpdated', () => {
    // On the firstDataRendered event rows will now be available
    let model = props.api.getModel();
    setFiltered(model.getRowCount()); //set current view's total in 'filtered' count

    //set possible total records for current views(all shows) in 'total' count
    setTotal(outputGrid.totalRecords);
  });
  
  return (
    <div className="ag-status-name-value" >
      {(total > 0) &&
      <span className="component">
        Showing <span className="ag-status-name-value-value">{filtered}</span> of <span className="ag-status-name-value-value"> {total}</span> records
      </span>
      }
    </div>
  );
};