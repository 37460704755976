import moment from 'moment';

export const reactSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      background: state?.isDisabled? '#eff2f5' : '#fff',
      height: 30,
      minHeight: 30,
      fontSize: 14,
      fontFamily: 'Lato',
      color: "#3F4254",
      borderColor: "#A1A5B7",
      borderWidth: 1,
      boxShadow: 'none',
      borderRadius: 0,
      outline:0,
      '&:focus': {
          borderColor: '#A1A5B7',
      },
      '&:hover': {
          borderColor: '#A1A5B7',
      }
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
    placeholder: (defaultStyles) => {
      return {
          ...defaultStyles,
          color: '#3F4254',
      }
  }
};

export const reactSelectAsMenuStyles = {
  control: (provided, state) => ({
    ...provided,
    background: state.isFocused ? '#b3b2bf' : 'rgb(225, 229, 242)',
    // height: 30,
    // minHeight: 30,
    fontSize: 14.3,
    fontWeight: 700,
    fontFamily: 'Lato',
    color: 'rgb(22, 22, 24)',
    borderColor: '#9da0a9',
    borderWidth: 1,
    boxShadow: 'none',
    borderRadius: 0,
    outline: 0,
    '&:focus': {
      borderColor: '#9da0a9',
    },
    '&:hover': {
      borderColor: '#9da0a9',
    }
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '30px',
    paddingLeft: '13px',
    paddingRight: '0px'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (state) => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    // height: '30px',
    paddingRight: '7.5px',
    color: 'rgb(22, 22, 24)',
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: 'rgb(22, 22, 24)',
    };
  },
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'black', // Set arrow color to black
    padding: '0px'
  }),
  menu: (provided, state) => ({
    ...provided,
    right: 0,
    left: 'auto',
    minWidth: '200px',
    width: "max-content",
    // minWidth: '250px',
    //width: 'auto',
    margin: '0px',
    position: 'absolute',
  }),
  menuList: (provided, state) => ({
    ...provided,
    width: '100%',
    fontSize: '13.975px',
    borderBottom: '1px solid #eee',
    maxHeight: '525px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#fedec9' : 'inherit',
    borderBottom: '1px solid #eee',
    maxHeight: '525px',
  }),
};

export const date_options = {
    dateFormat: "MM/DD/YYYY",
    allowInput: true, // manually type date
    parseDate: (datestr, format) => {
      return moment(datestr, format, true).toDate();
    },
    formatDate: (date, format, locale) => {
      return moment(date).format(format);
    },
    clickOpens: false
};

export const future_date_options = {
  dateFormat: "MM/DD/YYYY",
  allowInput: true, // manually type date
  parseDate: (datestr, format) => {
    return moment(datestr, format, true).toDate();
  },
  formatDate: (date, format, locale) => {
    return moment(date).format(format);
  },
  minDate: "today",
  clickOpens: false
}

export const time_options_24hours = {
    enableTime: true,
    noCalendar: true,
    dateFormat: "h:i",
    time_24hr: true,
    minuteIncrement: 1,
    allowInput: true
}

export const time_options_12hours = {
    enableTime: true,
    noCalendar: true,
    dateFormat: "h:i K",
    //time_24hr: true,
    minuteIncrement: 1,
    //enableSeconds: true,
    allowInput: true,
    clickOpens: false 
};

/**
 * Sets the date value and manages focus for the date input.
 * @param {any} value - The date value to set.
 * @param {Function} onChange - Callback function to handle the date change.
 * @param {any} [defaultValue="0000-00-00"] - The default value if `value` is null, undefined.
 */
export const setDate = (value, onChange , defaultValue = "0000-00-00") => {
  // At the time of save we will send dateformat YYYY-MM-DD
  onChange(!value ? defaultValue : moment(value).format('YYYY-MM-DD'));

  // removing focus from date input when datepicker is closed 
  const activeElement = document.activeElement;
  const isFlatpickr = activeElement.classList.contains('flatpickr-input');
  if (activeElement && isFlatpickr) {
      activeElement.blur();
  }
}

export const maskDateInput = (e) => {
  // Format the input manually
  const input = e.target;
  let value = input.value;

  // Allow only digits and slashes
  value = value.replace(/[^0-9/]/g, '');

  // If the value has 8 digits and no slashes, format it as MM/DD/YYYY
  if (value.length === 8 && !value.includes('/')) {
    value = value.slice(0, 2) + '/' + value.slice(2, 4) + '/' + value.slice(4, 8);
  }

  // Break value into segments using `/`
  const segments = value.split('/').filter(Boolean); // Remove empty parts caused by consecutive slashes

  let formattedValue = '';

  // Format MM (up to 2 digits)
  if (segments[0]) {
    let month = segments[0];
    if (month.length === 2 || segments.length > 1) {
      month = month.padStart(2, '0'); // Ensure two digits only when moving to the next segment
    }
    formattedValue += month;
  }

  // Add / and format DD (up to 2 digits)
  if (segments[1]) {
    let day = segments[1];
    if (day.length === 2 || segments.length > 2) {
      day = day.padStart(2, '0'); // Ensure two digits only when moving to the next segment
    }
    formattedValue += '/' + day;
  }

  // Add / and format YYYY (up to 4 digits)
  if (segments[2]) {
    let year = segments[2];
    if (year.length === 4 || value.endsWith('/')) {
      year = year.padStart(4, '0'); // Ensure four digits for the year
    }
    formattedValue += '/' + year;
  }

  // Preserve slashes for incomplete entries
  if (value.endsWith('/')) {
    formattedValue += '/';
  }

  // Update the input value
  input.value = formattedValue;
};

export const setTime = (value, onChange) => {
  onChange(value == "" ? "00:00:00" : moment(value[0]).format('HH:mm:ss'));
}