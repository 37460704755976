
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import { SeparatedDateTimeRenderer } from '../../modules/output-listing/renderers/DateTimeRenderer';
import { useAlert , useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider';
import { useAppSelector } from '../../redux/hooks';
import axios from 'axios';

const CloudApiLogsList = () => {
  const intl = useIntl()
  const alertDialog = useAlert()
  const loadingOverlay = useLoadingOverlay()
  const customer_id = useAppSelector(state=> state.showCompany.company_id)

  const columnDefs = [
    { field: 'sgl_id', maxWidth: 100, headerName: intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.ID' }), filter: 'agNumberColumnFilter'},
    { field: 'unix_time', minWidth: 100, headerName: intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.DATE' }), filter: 'agDateColumnFilter', headerClass: "ag-center-aligned-header", rowClass: "text-center", cellStyle: { textAlign: 'center' }, cellRendererFramework: (params) => {
      return (
          <SeparatedDateTimeRenderer params = {params} date={params?.data?.log_date} time={params?.data?.log_time}/>
      )}
    },
    { field: 'process_id', minWidth: 100, headerName: intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.PROCESSID' }), filter: 'agTextColumnFilter', },
    { field: 'username', minWidth: 200, headerName: intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.USER' }), filter: 'agTextColumnFilter', },
    { field: 'referer', minWidth: 200, headerName: intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.REFERER' }), filter: 'agTextColumnFilter', },
    { field: 'module_name', minWidth: 200, headerName: intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.MODULE' }), filter: 'agTextColumnFilter', },
    { field: 'action_name', minWidth: 200, headerName: intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.ACTION' }), filter: 'agTextColumnFilter', },
    { field: 'Company.urlpath', minWidth: 100, headerName: intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.COMPANY' }), filter: 'agTextColumnFilter', valueGetter: function getURLPath(params) { return (params?.data?.customer_id == 0)?"":params?.data?.Company.urlpath?.toUpperCase();} ,cellStyle: { textAlign: 'center' }}, 
    { field: 'status_code', minWidth: 100, headerName: intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.STAUSCODE' }), filter: 'agTextColumnFilter'},
    { field: 'request_time', minWidth: 100, headerName: intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.REQTIME' }), filter: 'agTextColumnFilter'}
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CLOUDAPILOGS' })}</PageTitle>
      <OutputListingComponent area={'CloudApiLogs'} columnDefs={columnDefs} apiEndPoint={'utility/cloud-api-logs'} onRowDoubleClicked={ (row_data) => {
          loadingOverlay({show: true})
          axios.get( process.env.REACT_APP_NEST_API_URL + '/utility/cloud-api-detail?id=' + row_data.sgl_id, {
            params: {
                customer_id: customer_id,
            }
          }).then((response) => {
              loadingOverlay({show: false})
              if(response.data.success) {
                alertDialog({message: `<pre style="overflow: scroll;width:450px;">${JSON.stringify(response.data.log , undefined , 2)}</pre>`, icon: 'info', title: `Log: ${row_data.sgl_id}`, okButtonTitle: 'Close'})
              } else {
                alertDialog({message: response.data.message, icon: 'warn', title: `Log: ${row_data.sgl_id}`, okButtonTitle: 'Close'})
              }
            })
        }}
      >
      </OutputListingComponent>
    </>
  )
}

export { CloudApiLogsList }
