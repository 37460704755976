import axios from "axios"
import { useOutputContext, useOutputContextUpdater } from "../output-listing/OutputListingContext"
import { useAlert, useConfirm, useProgress } from "../sgl-utils/DialogsProvider"
import { useAppSelector } from "../../redux/hooks"

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const useMergeTool = () => {
    const { getOutputSelectionSelectedRowsAreaIDs, outputGrid, getEventID } = useOutputContext()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const outputContextUpdater = useOutputContextUpdater()
    const {progressBar} = useProgress()
    
    const getSelectionInfo = () => {
        const selectedRowsIds = getOutputSelectionSelectedRowsAreaIDs() // Rows that are selected (highlighted by user)
        const selectionRowIds = [] // Rows that are in current selection (current pool of data)
        outputGrid.gridApi.forEachNode(node => selectionRowIds.push(node.data.area_id))

        return { 
            selectedRowsIds,
            selectionRowIds
        }

    }

    const merge = async (area) => {
        const { selectedRowsIds, selectionRowIds } = getSelectionInfo()
        
        let message = ''
        // TODO: move error messages in en.json file
        if (selectedRowsIds.length === 0) {
            message = 'You need to highlight the record you want to keep.'
        } else if (selectedRowsIds.length > 1) {
            message = 'You can highlight only one record.'
        } else if (selectionRowIds.length === 1) { // Don't need to merge single record. 
            message = 'Merge operation cannot be performed for only one record.'
        }

        if (message) {
            alertDialog({ message })
            return
        }
        
        const choice = await confirmDialog({ message: `You are about to Merge ${selectionRowIds.length} records.  You will not be able to reverse this operation.` })
        if (!choice) { return }

        
        const eventID = getEventID('merge-records-tool')
        progressBar({ title: 'Merge Records', show: true, eventID, timeElapsed: true })
        const records = selectionRowIds.map(id => ({ areaId: id, recordToKeep: id === selectedRowsIds[0] }))
        const response = await axios.post(`${NEST_API_URL}/common/mergeRecords`,  {
            customer_id: customerId,
            area,
            records,
            event_id: eventID
        })
        progressBar({ show: false, eventID })

        const { success } = response.data
        message = response.data.message ?? 'Something went wrong..'

        if (success) {
            //refresh list
            outputContextUpdater({action: 'refresh'})
        } else {
            alertDialog({ message })
        } 
    }

    return merge
}

export default useMergeTool