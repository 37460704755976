import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { useAppSelector } from '../../../redux/hooks';
import { useEffect, useState } from 'react';
import { useAlert, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import { KTSVG } from '../../../../_metronic/helpers';
import { downloadTextFile } from '../../../modules/sgl-utils/SglFunctions';

// Constants


const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const ExportHorses = ({ show, handleClose }) => {
    const intl = useIntl()
    const customerID = useAppSelector(state => state.showCompany.company_id)
    const currentShowID =  useAppSelector(state => state.currentShow.show_id)
    const [shows, setShows] = useState([])
    const [selectedShow, setSelectedShow] = useState(currentShowID)

    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()

    // Export horses (exports a csv file) 
    const exportHorses = async () => {
        loadingOverlay({ show: true, message: 'Downloading File'});
        axios.post(process.env.REACT_APP_NEST_API_URL + `/horses/exportHorses`, {
            customer_id: customerID,
            selected_show_id: selectedShow
        })
        .then(async response => {
            if(response.data.success){
                downloadTextFile(response.data.file_info.text_data, response.data.file_info.doc_name);
                loadingOverlay({ show: false, message: 'Downloading File'});
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: intl.formatMessage({ id: 'GENERIC.ERROR.MESSAGE' })});
            }
        })
        .finally(() => {
            handleClose()
            loadingOverlay({ show: false, message: 'Downloading File'});
        })
    }

    useEffect(() => {
        if (show) {
            loadingOverlay({ show: true })
            // get shows for current company
            axios.get(`${NEST_API_URL}/shows?customer_id=${customerID}`)
            .then(response => {   
                if(response?.data?.shows.length > 0){
                    setShows(response?.data?.shows)
                }
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: intl.formatMessage({ id: 'GENERIC.ERROR.MESSAGE' })});
                }
            })
            .finally(() => loadingOverlay({ show: false }));
        }  
    }, [show])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    exportHorses()
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .manual-override-form .w-lg-140px { width: 140px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'HORSES.QUICKACTION.POPUP.HEADING.EXPORTHORSES' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='row mb-2'>
                    <label className='col-lg-3 col-form-label fs-5 py-1 w-lg-140px w-lg-140px' htmlFor='selectedShow'>{intl.formatMessage({ id: 'HORSES.QUICKACTION.EXPORTHORSES.MODAL.LABEL.SELECTSHOW' })}</label>
                    <div className='col-lg-9'>
                        <select 
                            id="selectedShow" 
                            className='form-select form-select-sm fs-6 h-30px py-1'
                            value={selectedShow} 
                            onChange={e => setSelectedShow(e.target.value)}   
                            autoFocus
                            tabIndex={1}
                        >
                            { shows.map(show => <option value={show.show_id}>{ show.show_name }</option>)}
                        </select>
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-3 px-0'>

                    <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={2}>
                        {intl.formatMessage({ id: 'HORSES.QUICKACTION.EXPORTHORSES.MODAL.BUTTON.CANCEL' })}
                    </button>

                    <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={exportHorses} tabIndex={3}>
                        {intl.formatMessage({ id: 'HORSES.QUICKACTION.EXPORTHORSES.MODAL.BUTTON.EXPORT' })}
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default ExportHorses


