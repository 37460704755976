
const BooleanFilterOptions = {
    filterOptions: [
        'empty',
        {
          displayKey: 'true',
          displayName: 'Yes',
          predicate: (_, cellValue) => cellValue ? true : false,
          numberOfInputs: 0,
        },
        {
          displayKey: 'false',
          displayName: 'No',
          predicate: (_, cellValue) => !cellValue,
          numberOfInputs: 0,
        },
      ],
      suppressAndOrCondition: true,
};

export {BooleanFilterOptions};