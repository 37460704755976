import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext, useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext';
import axios from 'axios'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const useMarkPaymentAsVerifiedOrUnverified = () => {
    const { getOutputSelectionSelectedRows } = useOutputContext()
    const customer_id = useAppSelector(state=> state.showCompany.company_id)
    const loadingOverlay = useLoadingOverlay()
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const outputContextUpdater = useOutputContextUpdater()

    const markPaymentsAsVerifiedOrUnverified = async () => {
        loadingOverlay({ show: true })

        let payments = await getOutputSelectionSelectedRows()
        if (payments.length === 0) {
            alertDialog({ message: 'Please select some payments.' })
        } else {
            try {
                if (payments.some(p => p.verified)) {
                    const choice = await confirmDialog({ message: 'Are you sure you want to unmark the selected payment(s) as verified?'})
                    if (!choice) {
                        payments = payments.filter(p => !p.verified)
                    }
                }
                
                if (payments.length > 0) {
                    const response = await axios.post( NEST_API_URL + '/payments/markPaymentsAsVerifiedOrUnverified', {
                        customer_id,
                        payment_ids: payments.map(p => p.payment_id)
                    })

                    if (response.data.success) {
                        //refresh list
                        outputContextUpdater({action: 'refresh'})
                    }
                }
            } catch (reason) {
                loadingOverlay({ show: false })
            } 
        }
        loadingOverlay({ show: false })
    }

    return markPaymentsAsVerifiedOrUnverified
}

export default useMarkPaymentAsVerifiedOrUnverified