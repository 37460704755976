const SymbolRenderer = (value, symbol = '%') => {
    if (value != null && value != undefined && !isNaN(value)) {
        const valueToDisplay = Math.abs(Number(value)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return valueToDisplay + symbol 
    }
    if (value == null || value == undefined) {
        return ''
    }
    return "0.00" + symbol
}
export default SymbolRenderer;