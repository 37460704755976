import { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

type Props = {
    show: boolean
    handleClose: any
    conflictedClass: any
    classNumberList: any
    setClassNumberList: any
    calledFrom: string
    setTripsToChange: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CheckRider = ({ show, handleClose, conflictedClass, classNumberList, setClassNumberList, calledFrom, setTripsToChange}: Props) => {
    const intl = useIntl()
    const methods = useFormContext()

    useEffect(() => {
        if (show) {
            sessionStorage.setItem("modal_is_open", JSON.stringify(true));
            const storedModals = JSON.parse(sessionStorage.getItem('modals_open') || '[]');
            sessionStorage.setItem('modals_open', JSON.stringify([...storedModals, 'CheckRider']));
        }
    }, [show])

    useEffect(() => {
        if (show) {
            const handleEnterKey = (event: any) => {
                event.preventDefault();
                event.stopPropagation();
                if (event.key === "Enter" || event.key === "Escape") {
                    const storedModals = sessionStorage.getItem('modals_open');
                    const modals_open = storedModals ? JSON.parse(storedModals) : [];
                    const lastModal = modals_open.pop();
                    if (lastModal === 'CheckRider' && event.key === "Enter") {
                        updateTrip()
                    }else{
                        handleClose()
                    }
                }
            };
    
            document.addEventListener('keydown', handleEnterKey, true);
    
            return () => {
                document.removeEventListener('keydown', handleEnterKey, true);
            };
        }
      }, [show]);

    // Get all the riderTrips and change the selected rider trip's rider_id and rider_name
    const updateTrip = () => {
        if (calledFrom === "Entries") {
            let trips = methods.getValues('trips')
            let index = trips.findIndex((trip: any) => (trip.Class.number == conflictedClass.class_number || (trip.ActualClass && trip.ActualClass?.number == conflictedClass.class_number)))
    
            if(index >= 0){
                trips[index].rider_id = conflictedClass.new_rider_id
                trips[index].rider_name = methods.getValues(`linked_people[${conflictedClass.new_rider_id}].people_data.fl_name`)
                trips[index]["updated_trip"] = 1
                methods.setValue('trips', trips, {shouldDirty: true})
                let numberList = [...classNumberList]
                let num_index = numberList?.findIndex((classNumber : any) => conflictedClass.class_number == classNumber)
        
                if(num_index >= 0){
                    numberList.splice(num_index, 1)
                    setClassNumberList(numberList)
                }
            }
        } else if (calledFrom === "Rapid") {
            setTripsToChange((prevTripsToChange: any) => { 
                const newTripsToChange = [...prevTripsToChange]
                newTripsToChange.push(conflictedClass)
                return newTripsToChange
            }) 
        }
       
        handleClose()
    }

    const removeClassList = () => {
        let numberList = [...classNumberList]
        let index = numberList?.findIndex((classNumber : any) => conflictedClass.class_number == classNumber)

        if(index >= 0){
            numberList.splice(index, 1)
            setClassNumberList(numberList)
            handleClose()
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-400px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event: any) => { //replaced onKeyPress with onKeyDown
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    updateTrip()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                {/* <h2 className="fs-4">{intl.formatMessage({ id: '' })}</h2> */}
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0 d-flex'>
                        <div className='row mb-3'>
                            <div className='col-lg-1 d-flex align-self-center'>
                                <i className="fa-2xl fa-sharp fa-solid fa-circle-xmark text-danger"></i>
                            </div>
                            <div className='col ms-lg-2'>
                                <span className="d-inline-block align-bottom">{conflictedClass.message}</span>
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={removeClassList}>
                            {intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type='button' className='btn btn-sm btn-secondary fw-bold me-4 text-uppercase' onClick={handleClose} data-tooltip-id="ENTRIES.DETAIL.MODAL.CHECKRIDER.BUTTON.ADD">
                            {intl.formatMessage({ id: "FORM.INPUT.ENTRIES.TAB.RTO.CHECKRIDER.MODAL.BUTTON.ADD" })}
                        </button>

                        <button type='button' className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={updateTrip} autoFocus data-tooltip-id="ENTRIES.DETAIL.MODAL.CHECKRIDER.BUTTON.CHANGE">
                            {intl.formatMessage({ id: "FORM.INPUT.ENTRIES.TAB.RTO.CHECKRIDER.MODAL.BUTTON.CHANGE" })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { CheckRider }