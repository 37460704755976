import { useIntl } from 'react-intl'
import Select from "react-select";
import axios from 'axios'
import {  useAppSelector } from '../../../redux/hooks';
import { useParams } from 'react-router-dom';
import {reactSelectStyles} from '../../../modules/sgl-utils/fieldControls';
import {Controller,  useFormContext} from "react-hook-form";
import { NumericFormat} from 'react-number-format';
import { ChampionReserveTab } from './GeneralTab/ChampionReserveTab';
import { PointSummaryTab } from './GeneralTab/PointSummaryTab';
import { useAlert, useConfirm, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

export const dirtyValues = (
	dirtyFields,
	allValues
  ) => {
	// NOTE: Recursive function.
  
	// If *any* item in an array was modified, the entire array must be submitted, because there's no
	// way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
	if (dirtyFields === true || Array.isArray(dirtyFields)) {
	  return allValues;
	}
  
	// Here, we have an object.
	return Object.fromEntries(
	  Object.keys(dirtyFields).map((key) => [
		key,
		dirtyValues(dirtyFields[key], allValues[key])
	  ])
	);
  };

const GeneralTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext();
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const loadingOverlay = useLoadingOverlay()
    const tabs = ["CHAMPIONRESERVE", "POINTSUMMARY"]
    const componentTags = [<ChampionReserveTab />, <PointSummaryTab />]
    const {division_id} = useParams();
    let current_division_id = props.id == "" ? 0 : props.id || props.id == 0 ? props.id : division_id
    const currentShowID = useAppSelector(state => state.currentShow.show_id);

    const customer_id = useAppSelector(state=> state.showCompany.company_id)
    const show_financials_locked = useAppSelector(state=> state.currentShow.islocked)

    const saveAndRecalculate = async () => {
        let confirmation_msg = intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.CONFIRMATION.SAVEONCALCULATE' })
        
        const choice = await confirmDialog({ message: confirmation_msg })
        if (choice) {
            let form_data = methods.getValues()
            const { dirtyFields} = methods.formState
            let submit_data = dirtyValues(dirtyFields, form_data)
            loadingOverlay({show: true, message: 'Saving record..'})
            let response = await axios.post( process.env.REACT_APP_NEST_API_URL + '/divisions/detail', {
            params: {
                division_id: current_division_id,
                customer_id: customer_id,
                show_id: props?.parent_area === "Shows" ? props.parent_id : currentShowID,
                data: submit_data
            }
            }) 
                loadingOverlay({show: false})
                if(response.data.success) {
                    await recalculate()
                } else {
                    alertDialog({message: response.data.error_message, title: 'Error'})
                }
        }
    }
    const recalculate = async (choice = false) => {
        try {
            loadingOverlay({ show: true, message: 'Recalculating..'})

            const response = await axios.get(`${NEST_API_URL}/divisions/recalculate?customer_id=${customer_id}&division_id=${current_division_id}&user_choice=${choice}`)  

            loadingOverlay({ show: false, message: 'Recalculating..'})
            if ('success' in response.data && !response.data.success) {
                alertDialog({ message: response.data.error })
            } else if (response.data.success){
                
                if ( response.data.message) {
                    const choice = await confirmDialog({ message: response.data.message })
                    if (choice) {
                        await recalculate(true)
                    }
                } else if (response.data.champ_res){
                    methods.setValue('champRes', response.data.champ_res)
                }
            }
            props.setUpdateData( props.update_data+1)
        } catch (reason) {
            alertDialog({ message: reason?.response?.data?.error ?? reason })
        } finally {
            loadingOverlay({ show: false, message: 'Recalculating..' })
        }
    }

    return (
        <>
            <div className='form-group row mb-2'>
                <div className='row mb-2'> {/* Form Section */}
                    <div className='col-lg-5'> {/* Column 1 */}
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-100px' htmlFor='TYPE' data-tooltip-id="DIVISION.DETAIL.TAB.GENERAL.LABEL.TYPE">{intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.LABEL.TYPE' })}</label>
                            <div className='col'>
                                 {
                                    props.form_meta.division_type && 
                                    <Controller
                                        name="division.type"
                                        render={({
                                            field: { onChange, value, name },
                                        }) => {
                                        const selectedOption = props.form_meta.division_type.filter(dt=> value.toLowerCase()?.includes(dt.value.toLowerCase()))
                                        return(
                                            <Select
                                                readOnly
                                                options = {props.form_meta.division_type}
                                                value={selectedOption || null}
                                                id = "TYPE"
                                                name={name}
                                                isSearchable={true}
                                                onChange={(type) => {
                                                    onChange(type.value);
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                                placeholder="Select"
                                            />    
                                        )}
                                    }
                                    />
                                }
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-100px' htmlFor='RATING' data-tooltip-id="DIVISION.DETAIL.TAB.GENERAL.LABEL.RATING">{intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.LABEL.RATING' })}</label>
                            <div className='col'>
                                {
                                    props.form_meta.division_rating && 
                                    <Controller
                                        name="division.rating"
                                        render={({
                                            field: { onChange, value, name },
                                        }) => {
                                        const selectedOption = props.form_meta.division_rating.find((ss) => ss.value.toLowerCase() === value.toLowerCase())
                                        return(
                                            <Select
                                                readOnly
                                                options = {props.form_meta.division_rating}
                                                value = {selectedOption || null}
                                                id = "RATING"
                                                name={name}
                                                isSearchable={true}
                                                onChange={(rating) => {
                                                    onChange(rating.value);
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                                placeholder="Select"
                                            />    
                                        )}}
                                    />
                                }
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-100px' htmlFor='PRICE' data-tooltip-id="DIVISION.DETAIL.TAB.GENERAL.LABEL.PRICE">{intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.LABEL.PRICE' })}</label>
                            <div className='col-lg-4'>
                                 <Controller
                                    control={methods.control}
                                    name="division.price"
                                    render={({ field: { onChange, value} }) => (
                                    <NumericFormat
                                        prefix={'$'}
                                        id='PRICE'
                                        allowNegative={false}
                                        value={value !== '' ? Number(value): ""}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        onValueChange={(e) => {
                                            // Only update the value if it's changed by the user
                                            if (Number(e.value) !== Number(value)) {
                                              onChange(e.value);
                                            }
                                        }}
                                        onFocus={(e) => {
                                            e.target.select(); // Highlight the value
                                        }}
                                        disabled={show_financials_locked && current_division_id > 0}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                    />
                                    )}
                                />
                            </div>
                        </div>
                    </div> {/* End Column 1 */}
                    <div className='col-lg-1'></div>
                    <div className='col-lg-6'> {/* Column 2 */}
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-150px' htmlFor='USEFSECTIONCODE' data-tooltip-id="DIVISION.DETAIL.TAB.GENERAL.LABEL.USEFSECTIONCODE">{intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.LABEL.USEFSECTIONCODE' })}</label>
                            <div className='col-lg-3'>
                                {/* <input
                                    {...methods.register( 'division.usef_section', 
                                        {
                                            required: false
                                        })
                                    }
                                    id='USEFSECTIONCODE'
                                    type='number'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    onBlur={(e)=> {
                                        if(props.form_meta.usef_section.filter((ss) => ss.value === methods.getValues('division.usef_section')).length === 0){
                                            methods.setValue("division.usef_section",'',{shouldDirty: true})
                                        }
                                    } }
                                /> */}
                                <Controller
                                    name="division.usef_section"
                                    control={methods.control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <NumericFormat
                                        {...field}
                                        id="USEFSECTIONCODE"
                                        allowNegative={false}
                                        decimalScale={0}
                                        type="text"
                                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                                        onBlur={(e) => {
                                            if (props.form_meta.usef_section.filter((ss) => ss.value === field.value).length === 0) {
                                            field.onChange('', { shouldDirty: true });
                                            }
                                        }}
                                        />
                                    )}
                                />
                            </div>
                            <div className='col-lg-6'>
                                  {
                                    props.form_meta.usef_section && 
                                    <Controller
                                        name="division.usef_section"
                                        render={({
                                            field: { onChange, value, name },
                                        }) => (
                                        <Select
                                            readOnly
                                            options = {props.form_meta.usef_section}
                                            getOptionLabel={option =>
                                                `${option.label} [${option.value}]`
                                            }
                                            // add substring search for USEF code 
                                            value={value !== undefined ? props.form_meta.usef_section.filter((ss) => ss.value === value):""}
                                            id = "usef_section"
                                            name={name}
                                            isSearchable={true}
                                            // setting value for USEF code field - two way binding
                                            onChange={(usef_section)=>{
                                                methods.setValue("division.usef_section",usef_section.value,{shouldDirty: true})
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}
                                            styles={reactSelectStyles}
                                            placeholder="Select"
                                        />    
                                        )}
                                    />
                                }
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-150px' htmlFor='ECSECTIONCODE' data-tooltip-id="DIVISION.DETAIL.TAB.GENERAL.LABEL.ECSECTIONCODE">{intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.LABEL.ECSECTIONCODE' })}</label>
                            <div className='col-lg-3'>
                                {/* <input
                                    {...methods.register( 'division.ec_section', 
                                        {
                                         required: false
                                        })
                                    }
                                    id='ECSECTIONCODE'
                                    type='number'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    onBlur={(e)=> {
                                        if(props.form_meta.ec_section.filter((ss) => ss.value === methods.getValues('division.ec_section')).length === 0){
                                            methods.setValue("division.ec_section",'',{shouldDirty: true})
                                        }
                                    }}
                                /> */}
                                    <Controller
                                        name="division.ec_section"
                                        control={methods.control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <NumericFormat
                                            {...field}
                                            allowNegative={false}
                                            decimalScale={0}
                                            id="ECSECTIONCODE"
                                            type="text"
                                            className="form-control form-control-sm fs-6 min-h-20px py-1"
                                            onBlur={(e) => {
                                                if (props.form_meta.ec_section.filter((ss) => ss.value === field.value).length === 0) {
                                                field.onChange('', { shouldDirty: true });
                                                }
                                            }}
                                            />
                                        )}
                                    />
                            </div>
                            <div className='col-lg-6'>
                                {
                                    props.form_meta.ec_section && 
                                    <Controller
                                        name="division.ec_section"
                                        render={({
                                            field: { onChange, value, name },
                                        }) => (
                                        <Select
                                            
                                            options = {props.form_meta.ec_section}
                                            getOptionLabel={option =>
                                                `${option.label} [${option.value}]`
                                            }
                                            // add substring search for EC code 
                                            value={value !== undefined ? props.form_meta.ec_section.filter((ss) => ss.value === value):""}
                                            id = "ec_section_code"
                                            name={name}
                                            isSearchable={true}
                                            // setting value for EC code field - two way binding
                                            onChange={(ec_section)=>{
                                                methods.setValue("division.ec_section",ec_section.value,{shouldDirty: true})
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}
                                            styles={reactSelectStyles}
                                            placeholder="Select"
                                        />    
                                        )}
                                    />
                                }
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...methods.register( 'division.no_champ_reserve_award', 
                                      {
                                       required: false
                                      })
                                    }
                                    className='form-check-input'
                                    type='checkbox'
                                    id='NOCHAMPIONRESERVEAWARDED'
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='NOCHAMPIONRESERVEAWARDED' data-tooltip-id="DIVISION.DETAIL.TAB.GENERAL.LABEL.NOCHAMPIONRESERVEAWARDED"> {intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.LABEL.NOCHAMPIONRESERVEAWARDED' })}</label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col form-check-sm form-check-custom ps-lg-3'>
                                <input
                                     {...methods.register( 'division.champion_from_prizemoney', 
                                        {
                                        required: false
                                        })
                                     }
                                    className='form-check-input'
                                    type='checkbox'
                                    id='CHAMPIONBASEDONPRIZEMONEY'
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='CHAMPIONBASEDONPRIZEMONEY' data-tooltip-id="DIVISION.DETAIL.TAB.GENERAL.LABEL.CHAMPIONBASEDONPRIZEMONEY"> {intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.LABEL.CHAMPIONBASEDONPRIZEMONEY' })}</label>
                            </div>
                        </div>
                        { methods.getValues('division.combined_division_id') ? "" :
                            <div className='row'>
                                <div className='col d-flex justify-content-end'>
                                    <button 
                                        type='button' 
                                        className="btn btn-sm btn-secondary fw-bold h-30px py-1 text-uppercase" 
                                        onClick={e => saveAndRecalculate()}
                                        disabled={methods.watch('division.no_champ_reserve_award') || methods.watch('division.division_id') == 0 || !methods.getValues('classesList') || methods.getValues('classesList').length == 0}
                                        data-tooltip-id="DIVISION.DETAIL.TAB.GENERAL.BUTTON.RECALCULATE"
                                    > 
                                        {intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.RECALCULATE' })}
                                    </button>
                                </div>
                            </div> 
                        } {/* End Form Section */}
                    </div> {/* End Column 2 */}
                </div>
                { methods.getValues('division.combined_division_id') ?
                ""
                :  <>
                <div className='d-flex flex-column mb-2'> {/* Column 2 */}
                        <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap mb-0' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                            {
                                tabs.map((tab, index) => {
                                    return (
                                        <li key={index} className='nav-item'>
                                            <a
                                                tabIndex="-1"
                                                className={"nav-link text-active-dark py-1 text-gray-700" + (index == 0 ? ' active' : '')}
                                                id={tab + "-tab"}
                                                data-bs-toggle='tab'
                                                href={"#" + tab + "-Division-Genral-" +current_division_id}
                                                data-tooltip-id={`DIVISION.DETAIL.TAB.GENERAL.SECTION.${tab}`}
                                            >
                                                {intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.TAB.' + tab })}
                                            </a>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                        <div className="tab-content mt-2 pb-2" id="myTabContent">
                            {
                                tabs.map((tab, index) => {
                                    return (
                                        <div key={index} className={"tab-pane fade show" + (index == 0 ? " active" : "")} id={tab + "-Division-Genral-" +current_division_id} role="tabpanel" >
                                            {componentTags[index]}
                                        </div>
                                    );
                                })
                            }
                        </div>
                </div>
                </> 
                }
                { (methods.getValues('pointSummary').length > 0 && !methods.getValues('all_results_verified')) &&
                    <div className='col-lg-6'>
                        <label className='text-danger' style={{fontSize: '13px'}}>
                            {intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.UNVERIFIED.ERROR' })}
                        </label> 

                    </div>
                }
                  {/* End Column 2 */} 
            </div>
        </>
    );
}

export { GeneralTab }