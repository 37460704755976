import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useOrderOfGoContext } from '../OrderOfGoContext';
import HorseSelectRenderer from './RiderGrid/HorseSelectRenderer';
import { getExtraNarrowHeaderHeight } from '../../../../modules/sgl-utils/agGridHelpers';

const RiderGrid = () => {
    const intl = useIntl();
    const { riderRowData, selectedHorses , setSelectedHorses, setRiderGridApi } = useOrderOfGoContext()
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const containerStyle = useMemo(() => ({ width: '100%', minHeight: '200px' }), []);

    const handleSelectedHorseChange = (rowIndex, value) => {
        setSelectedHorses(
            prevSelectedHorses => {
                const newSelectedHorses = [...prevSelectedHorses]
                newSelectedHorses[rowIndex] = Number(value)
                return newSelectedHorses
            }
        )
    }

    const columnDefs = [
        {   
            field: 'rider', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.RIDER"}), 
            sortable: false, 
            suppressMenu: true, 
            suppressSizeToFit: true,
            headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header',
            flex: 1
        },
        { 
            field: 'trips', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.TRIPS"}), 
            sortable: false, 
            suppressMenu: true, 
            headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header',
            cellClass: 'text-center'
        },
        { 
            field: 'horses', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.POINTHORSE"}), 
            sortable: false, 
            suppressMenu: true, 
            headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header',
            cellClass: 'text-center',
            flex: 1,
            cellRenderer: params => HorseSelectRenderer(params, selectedHorses[params.rowIndex], handleSelectedHorseChange)
        },
    ]

    const onGridReady = (params) => {
        setRiderGridApi(params.api)
    };

    return (
        <>
            <div className="col cursor-pointer">
                <fieldset>
                    <legend className='mt-2 fs-5 py-1 fw-bold d-flex justify-content-between align-items-center'>
                        <span>
                            { intl.formatMessage({ id: "ORDEROFGO.RIDERGRID.LABEL.STEP.TWO" }) }
                        </span>
                        <span>
                            { intl.formatMessage({ id: "ORDEROFGO.RIDERGRID.LABEL.MULTIPLEHORSERIDER" }) }
                            {": "} 
                            { riderRowData.length }
                        </span>
                    </legend>                    
                </fieldset>
            </div>
            <div className={`col d-flex`}>
                <div style={containerStyle}>
                    <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                            onGridReady={onGridReady}
                            headerHeight={getExtraNarrowHeaderHeight}
                            rowHeight={40}
                            columnDefs={columnDefs}
                            rowData={riderRowData}
                            rowDragEntireRow={true}
                            rowDragManaged = {true}
                            suppressMoveWhenRowDragging = {false}
                            animateRows = {true}      
                            rowSelection={'multiple'}
                            enableRangeSelection={false}
                            getRowStyle={params => ({ backgroundColor: params.data.rowColor })}
                            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while riders list is being loaded..</span>'}
                            domLayout='autoHeight'
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default RiderGrid