import axios from "axios";
import { useLoadingOverlay } from "../sgl-utils/DialogsProvider";
import { useAlert } from "../sgl-utils/DialogsProvider";
import { useAppSelector } from "../../redux/hooks";
import { useLoadReportAndPrint } from "../../pages/reports/ReportTypeModal";

const API_URL = process.env.REACT_APP_NEST_API_URL;

const useReportPrint = () => {
    
    const loadingOverlay = useLoadingOverlay()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const showId = useAppSelector(state => state.currentShow.show_id)
    const { loadReportsAndPrint } = useLoadReportAndPrint()

    const printReport = async (reportId, ids, additionalParams) => {
        try {
            loadingOverlay({ show: true });
            await axios.post(`${API_URL}/reports/print`, {
                report_id: reportId,
                customer_id: customerId,
                show_id: showId,
                params: { ids, ...additionalParams },
            }).then(async (response) => {
                if (response?.data?.success) {
                    const report = response?.data?.report;
                    report.report_definition = JSON.parse(report?.report_definition);

                    // Add report label in params
                    // Get params from report parameters
                    const reportParams = report?.parameters;

                    // Added report label in params because it is not called from the listing page
                    if (reportParams && reportParams.length > 0 && report?.report_label) {
                        for (let param of reportParams) {
                            if (param.Name == 'params') {
                                // Params are like these {"ids":[2168],"recordLimit":20}"
                                let paramValue = JSON.parse(param.Value);
                                let newValue = { ...paramValue, report_label: report?.report_label };
                                param.Value = JSON.stringify(newValue);
                                break;
                            }
                        }

                        // Add params to report
                        report.parameters = reportParams;
                    }

                    // Load report to ARJS and the print
                    await loadReportsAndPrint(report);
                }
                loadingOverlay({show: false})
            })
        }catch(e){
            console.log(e)
            loadingOverlay({show: false})
        }
    }

    return { printReport };
};

export default useReportPrint;
