
import { useAppSelector } from '../../../redux/hooks';

const DateRenderer = (params, calledFrom = '') => {
    const moment = require('moment');
    const inValidValues = [undefined, '00/00/0000', '0000-00-00']
    const currentShowStartDate = useAppSelector(state=> state.currentShow.start_date);
    
    if (params.value && !inValidValues.includes(params.value)) {
        const momentObj = moment.utc(params.value);
        if(!momentObj.isValid()) return '';

        const formattedDate = momentObj.format('M/D/YYYY');

        if(params?.data?.scratched && calledFrom == 'Entries Listing'){
            return <span style={{ color: 'var(--kt-primary)' }}>{formattedDate}</span>; 
        }else if(params?.data?.['effective_date'] && calledFrom == 'TaxRate Listing'){
            const effectiveDate = new Date(params.data['effective_date']);
            if (effectiveDate > new Date(currentShowStartDate)) {
                return <span style={{ color: 'var(--kt-primary)' }}>{formattedDate}</span>; 
            }else{
                return formattedDate;
            }
        }else{
            return formattedDate;
        }
    }

    return '';
}

export default DateRenderer;