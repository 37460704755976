import { useIntl } from "react-intl";
import { MoveCreditToAccountTab } from "./Detail/MoveCreaditToAccount/MoveCreditToAccountTab";
import { CreateBatchTab } from "./Detail/CreateBatch/CreateBatchTab";
import { PostPaymentsTab } from "./Detail/PostPayments/PostPaymentsTab";
import { PageTitle } from "../../../_metronic/layout/core";
import { useEffect } from "react";
import { PaymentBatchContextProvider, usePaymentBatchContext } from "./PaymentBatchContext";
import HelpLink from '../../modules/components/HelpLink'
import { CheckoutEntriesTab } from "./Detail/CheckoutEntries/CheckoutEntriesTab";

const PaymentBatchWrapper = () => {
    const intl = useIntl()
    return (
        <PaymentBatchContextProvider>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.PAYMENT.BATCHES' })}</PageTitle>
            <PaymentBatchDetail />
        </PaymentBatchContextProvider>
    )
}

const PaymentBatchDetail = (props) => {
    const intl = useIntl();
    const {selectedBatch, selectedTab, setSelectedTab, openedFrom}= usePaymentBatchContext()

    const tabs = ["MOVECREDITTOACCOUNT",  "CHECKOUTENTRIES", "CREATEBATCH", "POSTPAYMENTS"]
    const componentTags = [
        <MoveCreditToAccountTab />,
        <CheckoutEntriesTab />,
        <CreateBatchTab />,
        <PostPaymentsTab />
    ]

    useEffect(() => {
        if(!selectedTab){
           //when new payment batch is created open 'Move Credit to Account Tab' first. For existing payment batch open 'Post Payments Tab' first.
           if(selectedBatch){
                setSelectedTab(3)
            }else{
                setSelectedTab(0)
            }
        }
    }, [selectedBatch])

    return (
        <>     
        {props?.id == undefined ? <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PAYMENT.BATCHES'})}</PageTitle> : "" }
            <div className='modal-body py-0 px-4 scheduler bg-white' style={{ height: "100%", overflow:"hidden" }}>
                <form noValidate className='form' onSubmit={e => e.preventDefault()} style={{ height: "100%" }}>
                <HelpLink tooltip="PAYMENTBATCHES.DETAIL.LINK.HELP" classname="top" />
                    <div className='card-body pt-0 pb-3' style={{ height: "100%" }}> {/* Card Body */}
                    
                        <div className='d-flex flex-column my-2 border p-2 bg-white' style={{ height: "100%", position: "relative" }}>
                            {/* Display tabs if Detail is opened for Create Batch */}
                            {
                                openedFrom === 'Add New' && 
                                <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist">
                                {
                                    tabs.map((tab, index) => 
                                        <li key={`${tab}-${index}`} className='nav-item' style={{lineHeight: "16px",fontSize: "13px", paddingBottom: "1px", backgroundColor:"#2274a5"}} onClick={() => setSelectedTab(index)}>
                                            <a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == selectedTab ? ' active' : '')}
                                                tabIndex="-1"
                                                id={tab + "-tab"}
                                                data-bs-toggle='tab'
                                                href={"#" + tab + "-PaymentBatches" }
                                                data-tooltip-id={`PAYMENTBATCHES.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.' + tab })}
                                            </a>
                                        </li>
                                    )
                                }
                                </ul>
                            }
                           
                            <div className="tab-content" id="myTabContent" style={{ height: "100%" }}>
                                {    
                                    tabs.map((tab, index) => {
                                        return (
                                            <div className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab + "-PaymentBatches"} role="tabpanel" style={{ height: "100%" }}>
                                                <HelpLink tooltip={"PAYMENTBATCHES.DETAIL.TAB."+tab+".LINK.HELP"} positionTop="-1.95rem"  />
                                                {componentTags[index]}
                                            </div>
                                        );
                                    }) 
                                }
                            </div>
                        </div>
                        
                    </div>
                </form>
            </div>
        </>
    )
  }

  export { PaymentBatchWrapper as PaymentBatchDetail }