import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useLoadingOverlay, useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
import DivisionsGrid from './CombineDivisions/DivisionsGrid';
import axios from 'axios';
import { KTSVG } from '../../../../_metronic/helpers';
import "flatpickr/dist/themes/material_blue.css";
import Select from 'react-select';
import { useOutputContext, useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext';
import { editableCellStyle, getRowData } from '../../../modules/sgl-utils/agGridHelpers';
import { deepCopyArrayOfObjects } from '../../../modules/sgl-utils/SglFunctions';
import ClassesSelectRenderer from './CombineDivisions/ClassesSelectRenderer';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const DEFAULT_SELECTED_CLASSES = {
    division1: [],
    division2: [],
    division3: [],
    division4: [],
    division5: [],
    changedDivision: -1
}

const DEFAULT_REF_VALUE = {
    division1: [],
    division2: [],
    division3: [],
    division4: [],
    division5: [],
}

const CombineDivisions = ({ show, handleClose }) => {
    const intl = useIntl() 
    const loadingOverlay = useLoadingOverlay()
    const outputContextUpdater = useOutputContextUpdater()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const showId = useAppSelector(state => state.currentShow.show_id)
    const { getOutputSelectionSelectedRowsAreaIDs } = useOutputContext()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    
    const [gridRef, setGridRef] = useState(null)
    const [maxNumberOfDivisionClasses, setMaxNumberOfDivisionClasses] = useState(0)
    const [combinedDivisionName, setCombinedDivisionName] = useState('') 
    const [combinedDivisions, setCombinedDivisions] = useState([]) // Keep all divisions currently added in this array
    const [divisions, setDivisions] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [selectedClasses, setSelectedClasses] = useState(DEFAULT_SELECTED_CLASSES)

    const divisionClassesRef = useRef(DEFAULT_REF_VALUE)

    const [columnDefs, setColumnDefs] = useState([
        { 
            field: 'number', 
            headerName: 'Class Nos.', 
            editable: true,
            valueSetter: params => { 
                const newNumber = Number(params.newValue) // Typecast to Number
                params.data.number = isNaN(newNumber) ? 0 : newNumber // Don't allow invalid numbers/characters
                if (params.data.number < 0) params.data.number *= -1 // Don't allow negative
                return true
            },
            cellStyle: editableCellStyle
        },
        { 
            field: 'name',
            headerName: 'Class Names',
            editable: true,
            cellStyle: editableCellStyle
        }
    ])

    // Metadata Call
    useEffect(() => {
        if (show && gridRef) {
            const validateAndGetMetaData = async () => { 
                try {
                    loadingOverlay({ show: true })
                    
                    const [selectedDivisionIds, response] = await Promise.all([
                        getOutputSelectionSelectedRowsAreaIDs(),
                        axios.get(`${NEST_API_URL}/divisions/getCombinedDivisionsMetadata?show_id=${showId}&customer_id=${customerId}`)
                    ])
                    
                    if (response.data.divisions.length > 0) {
                        setDivisions(response.data.divisions)
                        const firstDivision = response.data.divisions[0]
                        setSelectedDivision({ label: firstDivision.name, value: firstDivision.division_id, ...firstDivision })

                        if (selectedDivisionIds.length > 0) {
                            // Loop through selected divisions and prefill/add
                            await addDivisionsOnLoad(selectedDivisionIds, response.data.divisions)
                        }
                    }
                        
                } catch (reason) {
                    alertDialog({ message: reason.response?.data.message ?? reason })
                } finally {
                    loadingOverlay({ show: false })
                } 
            }
            validateAndGetMetaData()
        }
    }, [show, gridRef])

    useEffect(() => {
        if(show){      
            const handleEnterKey = (event) => {
                let activeElement = document.activeElement
                let isButtonFocused = false
                const isModalOpen = JSON.parse(sessionStorage.getItem("modal_is_open") || "false");
                let isNotAGGridEditableCell = (!event?.target?.className?.includes('ag-input-field-input') && !event?.target?.className?.includes('ag-text-field-input'))
               
                if (activeElement && activeElement.tagName === 'BUTTON') {
                    isButtonFocused = true
                }

                if (event.key === 'Enter' && !event.shiftKey && !isButtonFocused && !isModalOpen) {
                    if (isNotAGGridEditableCell) {
                        const saveButton = document.getElementById('CombineDivisionSubmitButton');
                        saveButton?.click();
                    }
                }
                if(event.key === 'Escape' && activeElement.id.includes('select')){
                    onClose()
                }
            };
            
            document.addEventListener('keydown', handleEnterKey, true);
            
            return () => {
                document.removeEventListener('keydown', handleEnterKey, true);
            };
        }
    }, [show]);

    // Description: Removes a division from ag-grid (column) 
    const handleRemoveButton = async (selectedDivision, divisionNumber) => {

        try {
            loadingOverlay({ show: true })

            let localCombinedDivisions = []

            // Remove division from local array
            setCombinedDivisions(prevCombinedDivisions => {
                let newCombinedDivisions = deepCopyArrayOfObjects(prevCombinedDivisions)
                newCombinedDivisions = newCombinedDivisions.filter(d => d.division_id !== selectedDivision.division_id)
                localCombinedDivisions = deepCopyArrayOfObjects(newCombinedDivisions)
                return newCombinedDivisions
            })

            let rowData = getRowData(gridRef)
            // Check if the class nos and class names are of the division that is being removed. Update these columns. 
            if (rowData.find(r => r.division_id === selectedDivision.division_id)) {
                
                if (localCombinedDivisions.length > 0) { // Check if we have more divisions, populate ag-grid with classes of those division
                    let maxClasses = localCombinedDivisions[0].numberOfClasses
                    let maxClassesDivisionId = localCombinedDivisions[0].division_id  
                    // Check which division in our left divisions has maximum number of classes
                    for (let counter = 1; counter < localCombinedDivisions.length; counter++) {
                        if (maxClasses < localCombinedDivisions[counter].numberOfClasses) {
                            maxClassesDivisionId = localCombinedDivisions[counter].division_id
                            maxClasses = localCombinedDivisions[counter].numberOfClasses
                        }
                    }
                    
                    const { data: { divisionClasses }} = await axios.get(`${NEST_API_URL}/divisions/validateAndAddDivision?division_id=${maxClassesDivisionId}&customer_id=${customerId}`)

                    rowData = []
                    divisionClasses.forEach(cl => rowData.push({ 
                        number: Number('11' + cl.number), 
                        name: cl.name + ' (COMBINED)',
                        class_type: cl.class_type,
                        schedule_sequencetype: cl.schedule_sequencetype,
                        division_id: cl.division_id // To keep track of which division classes are in ag-grid  
                    }))

                    setMaxNumberOfDivisionClasses(maxClasses)
                    gridRef.setRowData(rowData)
                } else { // Clear Ag-Grid on last Division Remove
                    setMaxNumberOfDivisionClasses(0)
                    setSelectedClasses(DEFAULT_SELECTED_CLASSES)
                    divisionClassesRef.current = DEFAULT_REF_VALUE
                    gridRef.setRowData([])
                }
            }
        } catch (error) { console.error(error) }
        finally { loadingOverlay({ show: false })}

        // remove division selected classes from state & ref as division is being removed        
        setSelectedClasses(prevSelectedClasses => {
            const newSelectedClasses = structuredClone(prevSelectedClasses)
            for (let counter = divisionNumber; counter < 5; counter++) {
                newSelectedClasses[`division${counter}`] = [...newSelectedClasses[`division${counter+1}`]]
                divisionClassesRef.current[`division${counter}`] =  [...divisionClassesRef.current[`division${counter+1}`]]
            }
            newSelectedClasses[`division5`] = []
            newSelectedClasses.changedDivision = 999
            divisionClassesRef.current[`division5`] = []
            return newSelectedClasses
        })

        // Remove column from column def
        setColumnDefs(prevColumnDefs => {
            // This is necessary to avoid changing state directly. Objects/Arrays are by reference and not by copy in JS/Node.
            let newColumnDefs = deepCopyArrayOfObjects(prevColumnDefs)
            newColumnDefs = newColumnDefs.filter(col => col.field !== selectedDivision.name)

            for (let counter = 0; counter < newColumnDefs.length - 2; counter++) {
                newColumnDefs[counter].headerComponentParams.divisionNumber = counter + 1
            }
            
            return newColumnDefs
        })
    }

    const RemoveButton = ({ selectedDivision, divisionNumber }) => {
        return (
            <div className='d-flex flex-column gap-2 justify-content-center align-items-center text-center text-wrap'>
              { selectedDivision.name.toUpperCase() }
               <button type="button" className='btn btn-sm btn-secondary fw-bold text-uppercase py-1 px-4' onClick={() => handleRemoveButton(selectedDivision, divisionNumber)}>
                    Remove
                </button>
            </div>
        )
    }

    useEffect(() => {
        if (gridRef && selectedClasses) {
            const index = selectedClasses.changedDivision - 1
            const changedDivision = selectedClasses.changedDivision
            if (index >= 0 && index < columnDefs.length - 2) { // Safety check
                setColumnDefs(prevColumnDefs => {
                    const newColumnDefs = deepCopyArrayOfObjects(prevColumnDefs)
                    newColumnDefs[index].cellRenderer = params => params.rowIndex < selectedClasses[`division${changedDivision}`].length ?
                        ClassesSelectRenderer(params, divisionClassesRef.current[`division${changedDivision}`], selectedClasses[`division${changedDivision}`][params.rowIndex], changedDivision, (rowIndex, value, divisionNumber) => {
                            setSelectedClasses(prevSelectedClasses => {
                                const newSelectedClasses = structuredClone(prevSelectedClasses)
                                const oldIndex = newSelectedClasses[`division${changedDivision}`].findIndex(classId => classId === Number(value))
                                newSelectedClasses[`division${changedDivision}`][oldIndex] = newSelectedClasses[`division${changedDivision}`][rowIndex]
                                newSelectedClasses[`division${changedDivision}`][rowIndex] = Number(value) 
                                newSelectedClasses.changedDivision = divisionNumber
                                return newSelectedClasses
                            })     
                        })
                    :
                        <></>
                    return newColumnDefs
                })
            } else if (selectedClasses.changedDivision === 999) { //ON LOAD
                setColumnDefs(prevColumnDefs => {
                    const newColumnDefs = deepCopyArrayOfObjects(prevColumnDefs)

                    for(let counter = 0; counter < newColumnDefs.length - 2; counter++) {
                        let changedDivision = counter + 1
                        newColumnDefs[counter].cellRenderer = params =>  params.rowIndex < selectedClasses[`division${changedDivision}`].length ? ClassesSelectRenderer(params, divisionClassesRef.current[`division${changedDivision}`], selectedClasses[`division${changedDivision}`][params.rowIndex], changedDivision, (rowIndex, value, divisionNumber) => {
                            setSelectedClasses(prevSelectedClasses => {
                                const newSelectedClasses = structuredClone(prevSelectedClasses)
                                const oldIndex = newSelectedClasses[`division${changedDivision}`].findIndex(classId => classId === Number(value))
                                newSelectedClasses[`division${changedDivision}`][oldIndex] = newSelectedClasses[`division${changedDivision}`][rowIndex]
                                newSelectedClasses[`division${changedDivision}`][rowIndex] = Number(value) 
                                newSelectedClasses.changedDivision = divisionNumber
                                return newSelectedClasses
                            })     
                        })
                        :
                        <></>
                    }
                    return newColumnDefs
                })
            } 
        }
    }, [gridRef, selectedClasses])

    // Description: Adds selected division in Ag-Grid
    const addDivisionsOnLoad = async (divisionIds, divisions) => {

        loadingOverlay({ show: true })
        
        const _combinedDivisions = [] // To keep track of divisions already added
        const divisionErrors = [] // To keep errors occurred during bulk add
        let maxDivisionClasses = [] // contains classes of division with max number of classes
        const allDivisionClasses = []
        for (const divisionId of divisionIds) {
            
            const currentDivision = divisions.find(d => d.division_id === divisionId)
            let numCurrentDivClasses = 0

            let message = ''
            if (!currentDivision)
                message = 'Division not found.'
            else if (_combinedDivisions.length === 5)
                message = 'You can only combine upto 5 divisions.'
            else if (_combinedDivisions.length > 0 && currentDivision.type !== _combinedDivisions[0].type) 
                message = 'Divisions of same type can be combined. Please select division of same type.'
            else if (_combinedDivisions.find(d => d.division_id === divisionId))
                message = 'Division is already added in the list.'
            else { // Check remaining validations on Backend
                const response = await axios.get(`${NEST_API_URL}/divisions/validateAndAddDivision?division_id=${divisionId}&customer_id=${customerId}`)

                if (!response.data.success) {
                    message = response.data.error
                } else {
                    // Next division being added has more classes than last max, update classes!
                    if (maxDivisionClasses.length < response.data.divisionClasses.length) {
                        maxDivisionClasses = response.data.divisionClasses
                    }
                    allDivisionClasses.push(response.data.divisionClasses)
                    numCurrentDivClasses = response.data.divisionClasses.length
                }
            }

            if (message) { // Abort add incase of failing validations.
                divisionErrors.push(`Division ${currentDivision?.name}<br/> ${message}`)
                continue
            }

            _combinedDivisions.push({ ...currentDivision, numberOfClasses: numCurrentDivClasses })
        }

        setCombinedDivisions(_combinedDivisions)

        const rowData = [] // Clear existing data
        maxDivisionClasses.forEach(cl => rowData.push({ 
            number: Number('11' + cl.number), 
            name: cl.name + ' (COMBINED)',
            class_type: cl.class_type,
            schedule_sequencetype: cl.schedule_sequencetype,
            division_id: cl.division_id // To keep track of which division classes are in ag-grid  
        }))
        setMaxNumberOfDivisionClasses(maxDivisionClasses.length)

        setSelectedClasses(prevSelectedClasses => {
            const newSelectedClasses = structuredClone(prevSelectedClasses)
            for (let counter = 0; counter < _combinedDivisions.length; counter++) {
                newSelectedClasses[`division${counter + 1}`] = allDivisionClasses[counter] .map(cl => cl.class_id)
                divisionClassesRef.current[`division${counter + 1}`] = allDivisionClasses[counter] 
            }
            newSelectedClasses.changedDivision = 999 // Hack to run use effect once
            return newSelectedClasses
        })
            
        // Add new column in ag-grid for division
        setColumnDefs(prevColumnDefs => {
            const mappedColumnsDefs = _combinedDivisions.map((_combinedDivision, index) => ({ 
                headerComponentFramework: RemoveButton,
                headerComponentParams: {
                    selectedDivision: _combinedDivision,
                    divisionNumber: index + 1
                },
                field: _combinedDivision.name,
            }))
        
            return [...mappedColumnsDefs, ...deepCopyArrayOfObjects(prevColumnDefs)]
        })

        gridRef.setRowData(rowData)

        loadingOverlay({ show: false })
        if (divisionErrors.length > 0) {
            alertDialog({ message: divisionErrors.join('<br/><br/>')})
        }
    }
    // Description: Adds a division in Ag-Grid (column)
    const addDivision = async () => {

        try {
            loadingOverlay({ show: true })

            let divisionClasses = []
            let message = ''
            if (!selectedDivision)
                message = 'Please select Division to be combined.'
            else if (columnDefs.length >= 7) // 5 divisions + 2 class columns 
                message = 'You can only combine upto 5 divisions.'
            else if (combinedDivisions.length > 0 && selectedDivision.type !== combinedDivisions[0].type) 
                message = 'Divisions of same type can be combined. Please select division of same type.'
            else if (combinedDivisions.find(d => d.division_id === Number(selectedDivision.value)))
                message = 'Division is already added in the list.'
            else { // Check remaining validations on Backend
                const response = await axios.get(`${NEST_API_URL}/divisions/validateAndAddDivision?division_id=${selectedDivision.division_id}&customer_id=${customerId}`)

                if (!response.data.success) message = response.data.error
                else divisionClasses = response.data.divisionClasses
            }

            if (message) { // Abort add incase of failing validations.
                alertDialog({ message })
                return
            }

            // To keep track of divisions already added
            setCombinedDivisions(prevCombinedDivisions => {
                const newCombinedDivisions = deepCopyArrayOfObjects(prevCombinedDivisions)
                newCombinedDivisions.push({...selectedDivision, numberOfClasses: divisionClasses.length })
                return newCombinedDivisions
            })

            // Get existing grid data
            let rowData = getRowData(gridRef)
            // Check division being added has more classes than old division OR its the first division being added.
            // Set class nos and class names column
            if (columnDefs.length === 2 || divisionClasses.length > maxNumberOfDivisionClasses) { 
                rowData = [] // Clear existing data
                divisionClasses.forEach(cl => rowData.push({ 
                    number: Number('11' + cl.number), 
                    name: cl.name + ' (COMBINED)',
                    class_type: cl.class_type,
                    schedule_sequencetype: cl.schedule_sequencetype,
                    division_id: cl.division_id // To keep track of which division classes are in ag-grid  
                }))
                setMaxNumberOfDivisionClasses(divisionClasses.length)
            }

            setSelectedClasses(prevSelectedClasses => {
                const newSelectedClasses = structuredClone(prevSelectedClasses)
                const currentDivision = columnDefs.length - 1
                newSelectedClasses[`division${currentDivision}`] = divisionClasses.map(cl => cl.class_id)
                newSelectedClasses.changedDivision = currentDivision

                divisionClassesRef.current[`division${currentDivision}`] = divisionClasses 
                
                return newSelectedClasses
            })

            // Add new column in ag-grid for division
            setColumnDefs(prevColumnDefs => {
                // This is necessary to avoid changing state directly. Objects/Arrays are by reference and not by copy in JS/Node.
                let newColumnDefs = deepCopyArrayOfObjects(prevColumnDefs)
                const newColumnDef = { 
                    headerComponentFramework: RemoveButton,
                    headerComponentParams: {
                        selectedDivision,
                        divisionNumber: prevColumnDefs.length - 1 // Division Number to keep track of which division is being removed
                    },
                    field: selectedDivision.name,
                }
                
                // Insert divisions at start.. First, second, third, fourth, fifth, class nos, class names
                newColumnDefs.splice(prevColumnDefs.length - 2, 0, newColumnDef)
                return newColumnDefs
            })
        
            gridRef.setRowData(rowData)

        } catch(error) {
            console.error(error)
        } finally {
            loadingOverlay({ show: false })
        }
    }

    const combineDivisions = async () => {
        try {
            loadingOverlay({ show: true })

            if (!combinedDivisionName) {
                alertDialog({ message: 'Please enter Division Name.' })
                return
            }

            const rowData = getRowData(gridRef)
            const combinedClasses = []
            for (let counter = 0; counter < rowData.length; counter++) {
                const row = rowData[counter]
                if ('number' in row && 'name' in row && 'class_type' in row && 'schedule_sequencetype' in row) {
                    const actualClassIds = []
                    for (const key in selectedClasses) {
                        if (Array.isArray(selectedClasses[key]) && counter < selectedClasses[key].length) {
                            actualClassIds.push(selectedClasses[key][counter])
                        }
                    }

                    combinedClasses.push({ 
                        name: row.name, 
                        number: row.number, 
                        class_type: row.class_type, 
                        schedule_sequencetype: row.schedule_sequencetype,
                        actual_class_ids: actualClassIds
                    })
                }
            }

            const combiningDivisionIds = combinedDivisions.map(d => d.division_id)
            const response = await axios.post( NEST_API_URL + `/divisions/validateCombineDivisions`, {
                customer_id: customerId,
                show_id: showId,
                combined_division_name: combinedDivisionName,
                combined_classes: combinedClasses,
                combining_division_ids: combiningDivisionIds
            })

            if (response.data.success) {
                if (await confirmDialog({ message: 'Are you sure you want to combine these divisions?' })) {
                    const { data: { success, error } } = await axios.post( NEST_API_URL + `/divisions/combineDivisions`, {
                        customer_id: customerId,
                        show_id: showId,
                        combined_division_name: combinedDivisionName,
                        combined_division_type: combinedDivisions[0].type,
                        combining_division_ids: combiningDivisionIds,
                        combined_classes: combinedClasses
                    })

                    if (success) // Divisions combined successfully!
                        onClose()
                    else 
                        alertDialog({ message: error })
                }
            } else {
                alertDialog({ message: response.data.error })
            }
        } catch (reason) {
            alertDialog({ message: reason.response.data.message })
        } finally {
            loadingOverlay({ show: false })
        }
    }

    const onClose = () => {
        outputContextUpdater({action: 'refresh'})   // Refresh Class List
        setMaxNumberOfDivisionClasses(0)
        handleClose()
    }
    
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
            show={show}
            onHide={handleClose}
            backdrop='static'
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'Divisions.QuickAction.CombineDivisions' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='card-body p-0 px-2'>
                    <div className='row mb-2'>
                        <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold' htmlFor="divisionName">
                            { intl.formatMessage({ id: 'LIST.DIVISIONS.NAME' }) }
                        </label>
                        <div className='col-lg-6'>
                            <input
                                type="text"
                                id="divisionName" 
                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                value={combinedDivisionName}
                                onChange={e => setCombinedDivisionName(e.target.value)}
                                tabIndex={6}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <label className='col-lg-2 col-form-label fs-5 fw-bold py-2' htmlFor="selectDivision">
                            {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.LABEL.DIVISION' })}
                        </label>
                        <div className='col-lg-6'>
                            <Select
                                type="text"
                                inputId="selectDivision" 
                                theme={theme => ({...theme, borderRadius: 'none'})}
                                value={selectedDivision}
                                onChange={e => { 
                                    //gridRef.setRowData(e.rowData)
                                    setSelectedDivision(e)
                                }}
                                options={divisions.map(d => ({ label: d.name, value: d.division_id, ...d }))}
                                autoFocus
                                tabIndex={1}
                            />
                        </div>
                        <button 
                            type="button" 
                            className="btn btn-sm btn-secondary ps-6 py-1 w-30px d-flex justify-content-center align-items-center"
                            onClick={() => addDivision()} 
                            tabIndex={2}
                        >
                            <i className="fas fa-plus fs-5"></i>
                        </button>
                    </div>
                    <div className='row mb-2'>
                        <DivisionsGrid 
                            setGridRef={setGridRef}
                            columnDefs={columnDefs}
                            stopEditingWhenCellsLoseFocus={true}
                            tabIndex={3}
                        />
                    </div>
                    <div className='row mb-2'>
                        <label className='col-form-label fs-5 py-1 text-danger' htmlFor="divisionName">
                            ALERT - Classes in combined divisions will be removed from the schedule and will need to be rescheduled manually.
                        </label>
                    </div>
                   
                </div>
                <div className='card-footer d-flex justify-content-end py-3 px-2'>
                    <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={onClose} tabIndex={4}>
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.CANCEL" })}
                    </button>

                    <button 
                        type="button" 
                        id= 'CombineDivisionSubmitButton'
                        className='btn btn-sm btn-dark fw-bold text-uppercase'
                        onClick={combineDivisions}
                        tabIndex={5}
                        >
                        {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.COMBINE" })}
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default CombineDivisions