import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm, FormProvider } from "react-hook-form";
import axios from 'axios';

// Metronic Components
import { PageTitle } from '../../../_metronic/layout/core';

// Custom Components
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt';
import { useAlert, useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider';
import { useAppSelector } from '../../redux/hooks';
import { ChangePasswordDialog } from './Profile/ChangePasswordDialog';
import { ChangeEmailDialog } from './Profile/ChangeEmailDialog';
import { useAuth } from '../../modules/auth';

// Assets
import "flatpickr/dist/themes/material_blue.css";

// Functions
import { getUserByToken } from '../../modules/auth/core/_requests';
export const dirtyValues = (
    dirtyFields,
    allValues
  ) => {
    // NOTE: Recursive function.
  
    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
      return allValues;
    }
  
    // Here, we have an object.
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [
        key,
        dirtyValues(dirtyFields[key], allValues[key])
      ])
    );
};

const Profile = (props) => {
    const intl = useIntl()
    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const customer_id = useAppSelector(state => state.showCompany.company_id)
    const { auth, setCurrentUser } = useAuth()
    const methods = useForm({
        defaultValues: {
            first: '',
            last_name: '',
            email_address: '',
            address: '',
            city: '',
            state: '',
            postal_code: '',
            phonenumber: '',
            phonenumber_verified: false,
            profile_photo_thumbnail: '',
        },
    });
    const { register, handleSubmit, reset, formState, getValues, watch, setValue } = methods
    const { dirtyFields } = formState;
    useNavigationPrompt(formState)

    const profileImageRef = useRef(null)
    const [metaData, setMetaData] = useState({})
    const [profileImageName, setProfileImageName] = useState('')
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false)
    const [showChangeEmailDialog, setShowChangeEmailDialog] = useState(false)

    const profile_photo_thumbnail = watch('profile_photo_thumbnail')
    const phonenumber = watch('phonenumber')
    const phonenumber_verified = watch('phonenumber_verified')
    const email_address = watch('email_address')

    useEffect(() => {
        loadingOverlay({ show: true })

        axios.all([
            axios.get(process.env.REACT_APP_NEST_API_URL + '/users/getLoggedInUser', {
                params: {
                    customer_id: customer_id,
                }
            })
        ]).then(
            axios.spread(({ data: currentUser }) => {
                setMetaData(metaData)
                if (currentUser && Object.keys(currentUser).length > 0) {
                    reset({ ...currentUser?.user })
                }

                loadingOverlay({ show: false })
            })
        )
    }, [])

    useEffect(() => {
        if (profile_photo_thumbnail && typeof profile_photo_thumbnail == 'string') {
            setProfileImageName(profile_photo_thumbnail.match(/.*\/(.*)$/)[1])
        }
    }, [profile_photo_thumbnail])

    const onImageUpload = (e) => {
        setValue('profile_photo_thumbnail', e.target.files[0], { shouldDirty: true })
        setProfileImageName(e.target.files[0]?.name)
    }

    const removeImage = () => {
        setValue('profile_photo_thumbnail', '', { shouldDirty: true })
        setProfileImageName('')
        profileImageRef.current.value = ''
    }

    // TODO: Save user info
    const onSubmit = async (form_data) => {
        let submit_data = dirtyValues(dirtyFields, form_data)

        const HTMLFormData = new FormData()
        for (let key in submit_data) {
            HTMLFormData.append(key, submit_data[key])
        }
        HTMLFormData.append('customer_id', customer_id)

        const config = {
            headers: {
                "content-type": "multipart/form-data"
            }
        }

        loadingOverlay({ show: true })
        axios.post(process.env.REACT_APP_NEST_API_URL + `/preferences/saveAccount?customer_id=${customer_id}`, HTMLFormData, config).then(response => {
            if (response?.data?.success) {
                reset(getValues(), { keepValues: true })
                setTimeout(async () => {
                    const {data: user} = await getUserByToken(auth.api_token)
                    setCurrentUser(user)
                    loadingOverlay({ show: false })
                }, 1000)
            }else if(response?.data?.message){
                loadingOverlay({ show: false })
                alertDialog({message: response.data.message, title: 'error'})
            }
        }).catch(error => console.log(error))
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.MYPREFERENCES' })}</PageTitle>
            <div className='py-3 px-4 m-2 input-form bg-white' style={{ border: '1px solid #eee' }}>
                <ChangePasswordDialog show={showChangePasswordDialog} handleClose={() => setShowChangePasswordDialog(false)} />
                <ChangeEmailDialog show={showChangeEmailDialog} handleClose={() => setShowChangeEmailDialog(false)} />
                <FormProvider {...methods}>
                    <form noValidate className='form max-width' onSubmit={handleSubmit(onSubmit)}>
                        <div className='card-body p-0'> {/* Card Body */}
                            <div className="form-group row"> {/* Column Group */}
                                <div className='row mb-2'>
                                    <div className='col-lg-12 mb-5'>
                                        <div className='row mb-2'>
                                            <label className='col-lg-1 w-lg-125px col-form-label fs-5 fw-bold py-1' htmlFor='FISRTNAME'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.LABEL.FISRTNAME' })}</label>
                                            <div className='col'>
                                                <input
                                                    {...register("first", { required: false })}
                                                    id='FISRTNAME'
                                                    type='text'
                                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                />
                                            </div>
                                            <label className='col-lg-1 w-lg-125px col-form-label fs-5 fw-bold py-1 text-end' htmlFor='LASTNAME'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.LABEL.LASTNAME' })}</label>
                                            <div className='col'>
                                                <input
                                                    {...register("last_name", { required: false })}
                                                    id='LASTNAME'
                                                    type='text'
                                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <label className='col-lg-1 w-lg-125px col-form-label fs-5 fw-bold py-1' htmlFor='ADDRESS'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.LABEL.ADDRESS' })}</label>
                                            <div className='col'>
                                                <input
                                                    {...register("address", { required: false })}
                                                    id='ADDRESS'
                                                    type='text'
                                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <label className='col-lg-1 w-lg-125px col-form-label fs-5 fw-bold py-1' htmlFor='CITY'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.LABEL.CITY' })}</label>
                                            <div className='col'>
                                                <input
                                                    {...register("city", { required: false })}
                                                    id='CITY'
                                                    type='text'
                                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                />
                                            </div>
                                            <label className='col-lg-1 w-lg-125px col-form-label fs-5 fw-bold py-1 text-end' htmlFor='STATE'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.LABEL.STATE' })}</label>
                                            <div className='col'>
                                                <input
                                                    {...register("state", { required: false })}
                                                    id='STATE'
                                                    type='text'
                                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <label className='col-lg-1 w-lg-125px col-form-label fs-5 fw-bold py-1' htmlFor='POSTALCODE'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.LABEL.POSTALCODE' })}</label>
                                            <div className='col'>
                                                <input
                                                    {...register("postal_code", { required: false })}
                                                    id='POSTALCODE'
                                                    type='text'
                                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                />
                                            </div>
                                            {
                                                !phonenumber_verified &&
                                                <>
                                                    <label className='col-lg-1 w-lg-125px col-form-label fs-5 fw-bold py-1 text-end' htmlFor='TELEPHONE'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.LABEL.TELEPHONE' })}</label>
                                                    <div className='col'>
                                                        <input
                                                            {...register("phonenumber", { required: false })}
                                                            id='TELEPHONE'
                                                            type='text'
                                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className='row mb-2'>
                                            <label className='col-lg-1 w-lg-125px col-form-label fs-5 fw-bold py-1' htmlFor='PROFILEIMAGE'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.LABEL.PROFILEIMAGE' })}</label>
                                            <div className='col'>
                                                <div className='row mx-0'>
                                                    <div className='col px-0'>
                                                        <label className='row border border-right-0 mx-0 pe-0 align-items-center border-gray-400'>
                                                            <div className='col-lg-1 bg-secondary px-0 w-lg-90px text-center' style={{ borderRight: '1px solid #B5B5C3' }}>
                                                                <span className='btn btn-sm fw-bold py-2 px-0 fs-8'>{intl.formatMessage({ id: 'FORM.INPUT.COMMON.LABEL.SELECTIMAGE' })}</span>
                                                            </div>
                                                            <div className='col ps-1 pe-0 text-truncate'>{profileImageName}</div>
                                                            <input
                                                                ref={profileImageRef}
                                                                className='d-none'
                                                                type={'file'}
                                                                onChange={onImageUpload}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div className='col-lg-1 px-0 w-20px d-flex border border-left-0 border-gray-400 align-items-center'>
                                                        <i className={"fas fa-x fs-8 px-1 text-black-50" + (profileImageName == '' ? ' d-none' : '')} onClick={removeImage}></i>
                                                    </div>
                                                </div>
                                                {profile_photo_thumbnail ? <a target={'_blank'}><img src={typeof profile_photo_thumbnail == 'string' ? profile_photo_thumbnail : URL.createObjectURL(profile_photo_thumbnail)} className="mt-2" style={{ maxHeight: '135px', maxWidth: '100%' }} accept="image/png, image/jpeg, image/jpg" /></a> : null}
                                            </div>
                                            <label className='col-lg-1 w-lg-125px col-form-label fs-5 fw-bold py-1 text-end'></label>
                                            {/* TODO: Verify Mobile Webservice */}
                                            <a className='col fs-5 text-end fw-bold'>{true || phonenumber_verified ? '' : intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.LINK.VERIFYMOBILE' })}</a>
                                        </div>
                                    </div>

                                    <div className='col-lg-12 mb-5'>
                                        <div className={false && phonenumber_verified ? 'row mb-2' : 'd-none'}>
                                            <label className='col-lg-12 col-form-label fs-5 py-1'>
                                                {/* TODO: Change mobile number webservice */}
                                                <strong>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.LABEL.TELEPHONE' })}</strong>: {phonenumber}
                                                <a className='ms-5 fw-bold'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.LINK.CHANGEMOBILENUMBER' })}</a>
                                            </label>
                                        </div>
                                        <div className='row mb-2'>
                                            <label className='col-lg-12 col-form-label fs-5 py-1'><strong>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.LABEL.EMAIL' })}</strong>: {email_address}</label>
                                        </div>
                                        <div className='row mb-2'>
                                            {/* <div className='col-lg-2 w-lg-150px'> */}
                                                {/* TODO: Update email webservice */}
                                                {/* <button disabled={true} onClick={() => setShowChangeEmailDialog(true)} className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase" type="button" >
                                                    {intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.BUTTON.CHANGEEMAIL' })}
                                                </button> */}
                                            {/* </div> */}
                                            <div className='col-lg-2 w-lg-175px'>
                                                {/* TODO: Update change password email */}
                                                <button onClick={() => setShowChangePasswordDialog(true)} className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase" type="button" >
                                                    {intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.BUTTON.CHANGEPASSWORD' })}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div> {/* End Column Group */}
                            </div> {/* End Card Body */}
                        </div> {/* End Card Body */}

                        <div className='card-footer input-form-buttons py-3 px-0 row bg-white'> {/* Card Footer */}
                            <div className='input-form-buttons-inner d-flex'>
                                <div className='col-12 d-flex justify-content-end'>
                                    <button type='submit' id={"detail_submit_button"} className='btn btn-sm btn-dark fw-bold me-20 ms-10'>
                                        {intl.formatMessage({ id: `FORM.INPUT.COMMON.BUTTON.UPDATE` })}
                                    </button>
                                </div>
                            </div>
                        </div> {/* End Card Footer */}
                    </form>
                </FormProvider>
            </div>
        </>
    )
}

export { Profile }