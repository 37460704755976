import axios from 'axios';
import { useIntl } from 'react-intl'

// Custom Components
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const useSetWebOrdering = () => {
    const intl = useIntl()
    const customerID = useAppSelector(state => state.showCompany.company_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const { getOutputSelectionAreaIDs } = useOutputContext();

    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const loadingOverlay = useLoadingOverlay()

    const callAPI = (ids = [], enable) => {
        loadingOverlay({ show: true })
        axios.post( NEST_API_URL + `/fees/setWebOrdering`, {
            customer_id: customerID,
            show_fee_ids: ids,
            show_id: currentShowID,
            enable: enable
        }).then((res) => {
            // Do Nothing
        }).catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally( () => {
            loadingOverlay({ show: false })
        })
    }

    const setWebOrdering = async (enable) => {
        loadingOverlay({ show: true })
        //Get selected peopls ids
        getOutputSelectionAreaIDs(false)
        .then(async res => { 
            if (res.length === 0) {
                loadingOverlay({ show: false })
                // error message if no rto selected
                alertDialog({ message: 'Please select some show fees first.' })
            }
            else{
                let choice = await confirmDialog({ message: `${intl.formatMessage({ id: `SHOWFEES.QUICKACTION.MODAL.LABEL.${enable ? 'ENABLEWEBORDERING1' : 'DISABLEWEBORDERING1'}`})} ${res.length} ${intl.formatMessage({ id: "SHOWFEES.QUICKACTION.MODAL.LABEL.DISABLEWEBORDERING2"})}` })
                if(choice){
                    callAPI(res, enable)
                }else{
                    loadingOverlay({ show: false })
                }
            }
        })
        .catch(() => loadingOverlay({ show: false }))
    }

    return setWebOrdering
    
}

export default useSetWebOrdering