import { useEffect, useState } from "react";
const DropdownRenderer = (props) => {
    const [selectedOption, setSelectedOption] = useState(props.value);

    useEffect(() => {
        setSelectedOption(props.value);
    }, [props.value]);

    const onOptionChange = (event) => {
        setSelectedOption(event.target.value);
        const column = props.column;
        if (props?.onUpdate) {
            props.onUpdate(props.rowId, column, event.target.value);
        }
    };

    return (
        <div>
            <select
                value={selectedOption}
                onChange={(e) => onOptionChange(e)}
                className='form-select form-select-sm fs-6 min-h-5px py-1 mt-2 ag-select'
                style={{ height: "100%" }}
                disabled={props.disabled}
            >
                {props.options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DropdownRenderer;