import React from 'react';
import { useOutputContext, useOutputContextUpdater } from '../../output-listing/OutputListingContext'

const ClearSearchLink = () => {
  const outputContextUpdater = useOutputContextUpdater();
  const {outputAdvanceFilter, outputSearchTerm, outputSearchType, savedRefinedSelectionRecordIDs, quickSearch} = useOutputContext();

  return (
    <>
      { 
        (Object.keys(outputAdvanceFilter).length > 0 || outputSearchTerm!='' || outputSearchType == 'Add' || (outputSearchType == 'Refine' && savedRefinedSelectionRecordIDs?.length > 0) || quickSearch.searchCriteria !== '') &&
        <a href="#" 
              className='fs-6 fw-bold text-decoration-underline'
              onClick={()=>outputContextUpdater({action: 'clearFiltersAndRefresh'})}
            >
              Clear Search
        </a>
      }
    </>
  );
};

export default ClearSearchLink;
