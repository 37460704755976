const HorseSelectRenderer = (params, selectedHorse, handleSelectedHorseChange) => {
    return (
        <select 
            className='form-select form-select-sm fs-6 min-h-20px py-1 mt-2 ag-select'
            onChange={(e) => handleSelectedHorseChange(params.rowIndex, e.target.value)}
            value={selectedHorse}
            >
            { params.data.horses.map(({horse, horse_id}) => <option value={horse_id}>{ horse }</option>) }
        </select>
    );
}

export default HorseSelectRenderer;