import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { defaultColDef, getNarrowHeaderHeight,getNarrowRowHeight } from "../../../../modules/sgl-utils/agGridHelpers";

const OverridesGrid = ({ data, gridRef, totalOverrides, setTotalOverrides, tabIndex=0}) => {
    const intl = useIntl()
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    const onFirstDataRendered = useCallback((params) => {
        params.api.sizeColumnsToFit();
    }, []);

    const onGridReady = useCallback((params) => {
        params.api.sizeColumnsToFit();  
    }, []);

    const renderCheckBox = (params) => {
        let checked =  (params?.node?.data?.apply_override == undefined || params?.node?.data?.apply_override) ? true: false
        params.node.setDataValue('apply_override', checked)
        const handleChange = (e) =>{  
            params.node.setDataValue('apply_override', e.target.checked) 
            if(e.target.checked){
                setTotalOverrides(totalOverrides+1)
            }else{
                setTotalOverrides(totalOverrides-1)
            }
        }
        return (
            <input
            type="checkbox"
            checked={params?.node?.data?.apply_override ? true:false}
            onChange={(e) => handleChange(e)}
            />
        )
    }

    const columnDefs = [
        { 
            field: 'apply_override', 
            headerName: '',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            cellRenderer: params => renderCheckBox(
                params
            ),
            minWidth: 50,
            maxWidth: 50
        },
        { 
            field: 'name', 
            headerName: intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.GRID.COLUMN.NAME' }),
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            minWidth: 180,
            maxWidth: 180
        },
        { 
            field: 'membership_number', 
            headerName: intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.GRID.COLUMN.MEMBERSHIPID' }),
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            minWidth: 150,
            maxWidth: 150
        },
        { 
            field: 'existing_override_info', 
            headerName: intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.GRID.COLUMN.EXISTINGOVERRIDE' }),
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            minWidth: 300
        }
    ]

    return (
        <div>
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        rowData={data}
                        onFirstDataRendered={onFirstDataRendered}
                        columnDefs={columnDefs} 
                        rowStyle={{ fontSize: '12px' }}
                        suppressScrollOnNewData={true}
                        headerHeight={getNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                        rowSelection= {'single'}
                        onGridReady={onGridReady}
                        {...(tabIndex !== 0 && { tabIndex })} //tabIndex is set conditionally, only if a prop was passed, provided that the prop is not 0 
                    />
                </div>
            </div>
            <div className='row mt-2'>
                <label className='text-end col-form-label fs-5 py-1 fw-bold'>{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.SETMEMBERSHIPOVERRIDESVERIFYDIALOG.MODAL.TOTAL' }) + " "+totalOverrides}</label>
            </div>
        </div>
    )
}
export default OverridesGrid