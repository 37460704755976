import { useMemo, useEffect, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form';
import { useLoadingOverlay, useAlert, useConfirm, useTaskScheduler, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import { useAppSelector } from '../../../redux/hooks';
import useReportPrint from '../../../modules/hooks/use-report-print';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import { downloadTextFile, loadingSpinnerBtnWait, loadingSpinnerBtnRelease } from '../../../modules/sgl-utils/SglFunctions';
import moment from 'moment';
import { DateTimeRenderer2 } from '../../../modules/output-listing/renderers/DateTimeRenderer';
import { useSyncUpdateContext } from '../../../modules/output-listing/synchronizeUpdateContext';
import { AgGridReact } from 'ag-grid-react';
import { getNarrowHeaderHeight, getNarrowRowHeight, getRowData, defaultColDef } from '../../../modules/sgl-utils/agGridHelpers';
import { useDebouncedCallback } from 'use-debounce';
import useAccessChecker from '../../../modules/hooks/use-access-checker';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const CircuitDivisionsTab = (props) => {
    const intl = useIntl()
    const loadingOverlay = useLoadingOverlay()
    const alertDialog = useAlert()
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []); 
    const [syncEvent, setSyncEvent] = useState(null)
    const { removeAllEventListeners, addSubscribedEvents, sse } = useSyncUpdateContext()
    const [gridRef, setGridRef] = useState();
    const methods = useFormContext()
    const confirmDialog = useConfirm()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const { printReport } = useReportPrint()
    const taskScheduler = useTaskScheduler()
    const { getEventID } = useOutputContext()
    const {progressBar} = useProgress()
    const { hasAreaWritePermission } = useAccessChecker()
    const [jobInfo, setJobInfo] = useState({
        message: '',
        showRemoveBtn: false,
        disableCalculateStandingBtn: false
    })

    const columnDefs = [
        { 
            field: 'name', 
            flex: 1, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITDIVISIONS.NAME' }),  
            cellClass: 'text-left'
        },
        { 
            field: 'usef_section', 
            width: 120, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITDIVISIONS.USEFSECTION' }),  
        },
        { 
            field: 'standingsselectionmethod', 
            width: 130, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITDIVISIONS.STANDINGSCALCULATIONMETHOD' }), 
        },
        { 
            field: 'standingsbasedon', 
            width: 190, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITDIVISIONS.STANDINGSBASEDON' }), 
            cellClass: 'text-left'
        },
        {
            field: 'lastmodified_date_time',
            width: 170,
            headerName: 'Last Computed',
            cellRenderer: DateTimeRenderer2,
        },
        {
            field: 'status',
            width: 220,
            cellStyle: params => {
                if (params.value === 'Computation required') {
                    return { color: 'red' }
                }
            },
            cellRenderer: params => params.value ? params.value : 'Completed',
        }
    ]

    const getCircuitDivisionsOfCircuit = async () => {
        const response = await axios.get(`${NEST_API_URL}/circuits/getCircuitDivisionsOfCircuit?customer_id=${customerId}&circuit_id=${methods.getValues('circuit.circuit_id')}`)

        const circuitDivisions = response.data
        if(!circuitDivisions.find(cd => cd.status !== 'Completed')) {
            setJobInfo({ 
                message: '',
                showRemoveBtn: false,
                disableCalculateStandingBtn: false    
            })
        } 

        methods.setValue('circuit_divisions', circuitDivisions)
    }
    const debouncedGetCircuitDivisionsOfCircuit = useDebouncedCallback(getCircuitDivisionsOfCircuit, 5000, { leading: true, maxWait: 5000 })

    useEffect(() => { // Update callback useEffect
        if(syncEvent && gridRef){
            const circuitDivisions = getRowData(gridRef)
            let parsedEvent = JSON.parse(syncEvent.data)
            let updateId =  parsedEvent.additional_data.triggered_id

            switch(parsedEvent.type) {
                case `CircuitDivisions-${customerId}`:
                    // Check if saved/updated circuit division was part of this circuit
                    if (circuitDivisions.find(cd => cd.circuit_division_id === updateId)) {
                        debouncedGetCircuitDivisionsOfCircuit()      
                    }    
                    break
            }  
        }
    }, [syncEvent, gridRef]);

    useEffect(() => { // Remove all existing event listener and then generate and add new events with callback in SSE on change of output grid area
        removeAllEventListeners()
        const circuitId = methods.getValues('circuit.circuit_id')
        if(circuitId && sse){
            let syncEvents = []
            let eventIds = []
            // For Areas who contains show_id should add show id in its event id
            eventIds = [
                `Circuits-${customerId}-${circuitId}`, 
                `CircuitDivisions-${customerId}`
            ]

            for(let eventId of eventIds){
                // Sync Event will hold an array of eventId and callback
                syncEvents.push({
                    eventId, 
                    callback: (event) => setSyncEvent(event)
                })
            }
            addSubscribedEvents(syncEvents) 
        }

        return () => removeAllEventListeners()

    }, [customerId, methods.watch('circuit.circuit_id'), sse]);

    useEffect ( ()=>{
        if (gridRef) {
            const circuitDivisions = methods.getValues ('circuit_divisions')
            if (circuitDivisions) {
                gridRef.setRowData(circuitDivisions)
            }
        }
    }, [gridRef, methods.watch('circuit_divisions')])

    useEffect(() => {
        if(gridRef){
            let colDefs = gridRef.getColumnDefs()
            if(methods.getValues('circuit_divisions')){     
                // colDefs[9].hide = false
                gridRef.setColumnDefs(colDefs)
            }
            gridRef.setRowData(methods.getValues('circuit_divisions'))
        }
    }, [gridRef, methods.watch('circuit_divisions')]);


    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 450px)' }), []);

    const getCurrentCircuitDivisionids = () =>{
        let circuit_division_ids =  methods.getValues('circuit_divisions').map(item => item.circuit_division_id)
        return circuit_division_ids
    }

    async function deleteSelectedCircuitDivision() {
        if ( gridRef ) {
            let selected_circuit_divisions = gridRef.getSelectedRows()
            if ( selected_circuit_divisions.length > 0 ) {
                let selected_circuit_division = selected_circuit_divisions[0]
                const choice = await confirmDialog({message: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITDIVISIONS.DELETE.ALERT'}), title: '', icon: 'Warning', okButtonTitle: intl.formatMessage({id: 'FORM.INPUT.CLASSES.OPENCARD.COMMON.BUTTON.YES'}),  cancelButtonTitle: intl.formatMessage({id: 'DIALOG.COMMON.BUTTON.CANCEL'}) })
                    if(choice){
                        let circuit_divisions =  methods.getValues('circuit_divisions')?methods.getValues('circuit_divisions'):[]
                        let remaining_circuit_divisions =  circuit_divisions?.filter(cd => cd.circuit_division_id != selected_circuit_division.circuit_division_id)
                        let deleted_circuit_divisions = methods.getValues('deleted_circuit_divisions')?methods.getValues('deleted_circuit_divisions'):[]
                        deleted_circuit_divisions.push(selected_circuit_division.circuit_division_id)

                        methods.setValue('circuit_divisions', remaining_circuit_divisions);
                        methods.setValue('deleted_circuit_divisions', deleted_circuit_divisions, {shouldDirty:true});
                    }
            }
        }
      }

    const callCalculateStandingsForAll = async (date, time) => {
        try {
            loadingOverlay({ show: true })
            const response = await axios.post(`${NEST_API_URL}/circuit-divisions/calculateCircuitStandings`, {
                customer_id: customerId,
                circuit_id: methods.getValues('circuit.circuit_id'),
                execution_schedule: {
                    runImmediately: date === null && time === null,
                    schedule: { date, time } 
                } 
            })

            if (response.data.success) {
                methods.setValue('queuedJob', response.data.queuedJob)
                alertDialog({ message: `Standings for all divisions are in queue for processing.`, icon: 'info'})
            } else {
                alertDialog({ message: response.data.error })
            }
        }  catch (reason) {} 
        finally {
            loadingOverlay({ show: false })
        }
    }

    const printCircuitDivisionReport = async (event, recordLimit = null) => {
        // Loading icon on button
        loadingSpinnerBtnWait(event)

        let ids = []

        // In case of "Print All" and "Print Top Twenty", get selected records
        // In case of "Print Top Ten for All", get ids of all records

        let selectedDivisions = [];

        if (recordLimit == 10) {
            // Get all rows of the grid
            selectedDivisions = gridRef.getRenderedNodes().map(node => node.data)
        } else {
            selectedDivisions = gridRef.getSelectedRows();
        }

        for(let division of selectedDivisions){
            ids.push(division.circuit_division_id)
        }

        if(ids.length > 0){
           await printReport(157, ids, { recordLimit })
        }else{
            alertDialog({message: 'Please select a circuit division first.'})
        }

        // Remove loading icon on button
        loadingSpinnerBtnRelease(event)
    }
    
    const calculateStandingsForAll = async () => {
        // Save Circuit Record before Calculating
        await props.saveCircuit()

        await taskScheduler({ 
            show: true, 
            message: 'Would you like to run calculate standings for all divisions immediately or schedule?',
            handleSubmit: callCalculateStandingsForAll
        })  
    }

    const exportStandings = async () => {
        let eventID = getEventID('export-all-standings')
        progressBar({ show: true, eventID, timeElapsed: true, title: 'Export All Standings' })

        axios.post( process.env.REACT_APP_NEST_API_URL + "/circuit-divisions/exportStandings", {
            customer_id: customerId,
            circuit_id: methods.getValues('circuit.circuit_id'),
            progress_event_id: eventID,
        })
        .then((res) => {
            if(res.data.success){
                downloadTextFile(res.data.circuitStandingsData, res.data.filename)
            }
            else{
                alertDialog({ message: "Standings were not found"})
            }
         })
        .catch((e) => {
        })
        .finally( () => {
            progressBar({ show: false, eventID})
        })
    }

    useEffect(() => {
        const queuedJob = methods.getValues('queuedJob')
        if (queuedJob) {
            if ((!queuedJob.start_time || queuedJob.start_time === '0000-00-00 00:00:00') && queuedJob.delay_time > 0) {
                setJobInfo({ 
                    message: `Circuit standing computation is scheduled for ${moment(methods.getValues('queuedJob.scheduled_time')).format('MM/DD/YYYY h:mm A')}`,
                    showRemoveBtn: true,
                    disableCalculateStandingBtn: true  
                }) 
            } else {
                setJobInfo({ 
                    message: `Circuit standings computations are in progress.`,
                    showRemoveBtn: false,
                    disableCalculateStandingBtn: true    
                })
            }
        } else {
            setJobInfo({ 
                message: '',
                showRemoveBtn: false,
                disableCalculateStandingBtn: false    
            })
        }
    }, [methods.watch('queuedJob')])

    const removeJob = async () => {
        await axios.delete(`${NEST_API_URL}/utility/removeJobFromQueue?job_id=${methods.getValues('queuedJob.job_id')}&customer_id=${customerId}`)

        setJobInfo({
            showRemoveBtn: false,
            message: '',
            disableCalculateStandingBtn: false 
        })
    }

    return (
        <>
            <div className='form-group circuit-divisions-tab'>
                <div className='row mb-2'>
                    <div className='col'>
                        <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                            <AgGridReact 
                                onRowDoubleClicked={
                                    async (params) => {
                                        // Save Circuit Record before Opening Division
                                        if (hasAreaWritePermission('circuits')) {
                                            await props.saveCircuit()
                                        }
                                        props.callbackFromParent(params.data.circuit_division_id, "Circuit Divisions", "CircuitDivisionDetail", getCurrentCircuitDivisionids())
                                    }
                                }
                                defaultColDef={{ ...defaultColDef, wrapHeaderText: true, headerClass: "ag-center-aligned-header", sortable: true, cellClass: 'text-center' }}
                                columnDefs={columnDefs} 
                                rowData={[]} 
                                onGridReady={params => setGridRef(params.api)}
                                containerStyle={containerStyle} 
                                rowSelection='multiple'
                                rowHeight={getNarrowRowHeight}
                                suppressScrollOnNewData={true}
                            />
                        </div>
                    </div>
                    <div className='col-lg-1 w-75px p-0'>
                        <label className='col col-form-label fs-5 py-1 mb-2' data-tooltip-id="CIRCUITS.DETAIL.TAB.CIRCUITDIVISIONS.LABEL.TOTAL">Total: {gridRef ? getRowData(gridRef).length : ""}</label>
                     
                        { hasAreaWritePermission('circuits') && [
                        <button disabled={methods.watch('circuit.circuit_id') > 0 ? false : true} type = 'button' className="btn btn-sm btn-secondary me-2 fw-bold px-2 py-2" onClick ={async () => {
                            if (hasAreaWritePermission('circuits')) {
                                await props.saveCircuit()
                                props.callbackFromParent(0,"Circuit Divisions","CircuitDivisionDetail", getCurrentCircuitDivisionids())
                            }
                        }} data-tooltip-id="CIRCUITS.DETAIL.TAB.CIRCUITDIVISIONS.BUTTON.ADD">
                            <i className="fas fa-plus fs-5 px-1 py-3"></i>
                        </button>
                        ,
                        <div className="separator my-1 border-transparent d-xl-block d-lg-block d-md-none d-sm-none d-none"></div>
                        ,
                        <button disabled={methods.watch('circuit.circuit_id') > 0 ? false : true} type = 'button' className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2' onClick={(e) => {deleteSelectedCircuitDivision(); e.preventDefault (); }} data-tooltip-id="CIRCUITS.DETAIL.TAB.CIRCUITDIVISIONS.BUTTON.REMOVE">
                            <i className='fas fa-minus fs-5 px-1 py-3'></i>
                        </button>
                        ]}
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col'>
                        <div className='row justify-content-between'>
                            <div className='col-lg-1 w-lg-750px'>
                                <div className='row'>
                                    <div className='w-lg-150px'>
                                        <button disabled={methods.watch('circuit.circuit_id') > 0 ? false : true} type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12" onClick={(event) => printCircuitDivisionReport(event)} data-tooltip-id="CIRCUITS.DETAIL.TAB.CIRCUITDIVISIONS.BUTTON.PRINTALL">
                                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                            {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITDIVISIONS.PRINTALL' })}
                                        </button>
                                    </div>
                                    <div className='col-lg-1 w-lg-200px'>
                                        <button disabled={methods.watch('circuit.circuit_id') > 0 ? false : true} type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12" onClick={(event) => printCircuitDivisionReport(event, 20)} data-tooltip-id="CIRCUITS.DETAIL.TAB.CIRCUITDIVISIONS.BUTTON.PRINTTOPTWENTY">
                                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                            {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITDIVISIONS.PRINTTOPTWENTY' })}
                                        </button>
                                    </div>
                                    <div className='col-lg-1 w-lg-200px'>
                                        <button disabled={methods.watch('circuit.circuit_id') > 0 ? false : true} type='button'className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12" onClick={(event) => printCircuitDivisionReport(event, 10)} data-tooltip-id="CIRCUITS.DETAIL.TAB.CIRCUITDIVISIONS.BUTTON.PRINTTOPTENFORALL">
                                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                            {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITDIVISIONS.PRINTTOPTENFORALL' })}
                                        </button>
                                    </div>
                                    <div className='w-lg-150px'>
                                        <button disabled={methods.watch('circuit.circuit_id') > 0 ? false : true} type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12" onClick={() => exportStandings()} data-tooltip-id="CIRCUITS.DETAIL.TAB.CIRCUITDIVISIONS.BUTTON.EXPORTALL">
                                            {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITDIVISIONS.EXPORTALL' })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            { hasAreaWritePermission('circuits') &&
                            <div className='col'>
                                <div className='row justify-content-end'>
                                    <div id="calculate-standing-btn" className='col-lg-1 w-lg-250px'>
                                        <button 
                                            type='button' 
                                            className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12"
                                            onClick={calculateStandingsForAll}
                                            disabled={jobInfo.disableCalculateStandingBtn || (methods.watch('circuit.circuit_id') > 0 ? false : true)}
                                            data-tooltip-id="CIRCUITS.DETAIL.TAB.CIRCUITDIVISIONS.BUTTON.CALCULATESTANDINGSFORALL"
                                        >
                                            {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITDIVISIONS.CALCULATESTANDINGSFORALL' })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <label className='col-lg-1 w-75px p-0'></label>
                </div>
                <div className='row'>
                    <div className='col'> 
                        <div className='d-flex justify-content-between g-2 align-items-end'>
                            <label className='col-form-label fs-5 py-1 text-danger'>
                                { methods.getValues('circuit_divisions') && methods.getValues('circuit_divisions').find(cd => cd.status === 'Computation required') && 'Some circuit divisions require recomputation.'}
                            </label>
                            <div className='d-flex'>
                                <label className='col-form-label fs-5 py-1' style={{ marginRight: '6px'}}>{ jobInfo.message }</label>
                                { jobInfo.showRemoveBtn && <div className='col-lg-1 w-lg-100px'>
                                    <button 
                                        type='button' 
                                        className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12"
                                        onClick={removeJob}
                                    >
                                        Remove Job
                                    </button>
                                </div> }
                            </div>
                        </div>
                    </div>
                    <label className='col-lg-1 w-75px p-0'></label>
                </div>
            </div>
        </>
    );
}

export { CircuitDivisionsTab }