// Font descriptors for Active report js
export const fontDescriptors = [
    {
        "name": "Arial",
        "source": "./media/activeReportJs/embed_fonts/ARIAL.ttf",
        "weight": "100"
    },
    {
        "name": "Arial",
        "source": "./media/activeReportJs/embed_fonts/ARIAL.ttf",
        "weight": "200"
    },
    {
        "name": "Arial",
        "source": "./media/activeReportJs/embed_fonts/ARIAL.ttf",
        "weight": "300"
    },
    {
        "name": "Arial",
        "source": "./media/activeReportJs/embed_fonts/ARIAL.ttf",
        "weight": "400"
    },
    {
        "name": "Arial",
        "source": "./media/activeReportJs/embed_fonts/ARIALBD.ttf",
        "weight": "500"
    },
    {
        "name": "Arial",
        "source": "./media/activeReportJs/embed_fonts/ARIALBD.ttf",
        "weight": "600"
    },
    {
        "name": "Arial",
        "source": "./media/activeReportJs/embed_fonts/ARIALBD.ttf",
        "weight": "700"
    },
    {
        "name": "Arial",
        "source": "./media/activeReportJs/embed_fonts/ARIALBD.ttf",
        "weight": "800"
    },
    {
        "name": "Impact",
        "source": "./media/activeReportJs/embed_fonts/impact.ttf",
        "weight": "100"
    },
    {
        "name": "Impact",
        "source": "./media/activeReportJs/embed_fonts/impact.ttf",
        "weight": "200"
    },
    {
        "name": "Impact",
        "source": "./media/activeReportJs/embed_fonts/impact.ttf",
        "weight": "300"
    },
    {
        "name": "Impact",
        "source": "./media/activeReportJs/embed_fonts/impact.ttf",
        "weight": "400"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana.ttf",
        "weight": "100"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana.ttf",
        "weight": "200"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana.ttf",
        "weight": "300"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana.ttf",
        "weight": "400"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana-Bold.ttf",
        "weight": "500"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana-Bold.ttf",
        "weight": "600"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana-Bold.ttf",
        "weight": "700"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana-Bold.ttf",
        "weight": "800"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana-Italic.ttf",
        "weight": "100",
        "style": "italic"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana-Italic.ttf",
        "weight": "200",
        "style": "italic"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana-Italic.ttf",
        "weight": "300",
        "style": "italic"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana-Italic.ttf",
        "weight": "400",
        "style": "italic"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana-BoldItalic.ttf",
        "weight": "500",
        "style": "italic"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana-BoldItalic.ttf",
        "weight": "600",
        "style": "italic"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana-BoldItalic.ttf",
        "weight": "700",
        "style": "italic"
    },
    {
        "name": "Verdana",
        "source": "./media/activeReportJs/embed_fonts/Verdana-BoldItalic.ttf",
        "weight": "800",
        "style": "italic"
    }
];

