import { useState } from 'react';
import axios from 'axios';
import { useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
import { useSchedulerContext } from './SchedulerContext';

const RemoveRenderer = (params) => {
    const { currentClassGroup, customerID, currentShowID, updateGroupSchedule } = useSchedulerContext();
    const confirmDialog = useConfirm()

    if(params.node.data.schedule_breaks_id > 0){
        return (
            <>
                <a className="btn-dark" onClick={ async () => {
                    const choice = await confirmDialog({message: 'Are you sure you want to remove this break?' })
                    if(!choice){
                        return false;
                    }

                    axios.delete(`${process.env.REACT_APP_NEST_API_URL}/scheduler/removeclassgroupschedulebreak?show_id=${currentShowID}&customer_id=${customerID}&class_group_id=${currentClassGroup.class_group_id}&schedule_break_id=${params.data.schedule_breaks_id}`)
                    .then(result => {
                        // Refresh class group schedule
                        updateGroupSchedule(true);
                    })
                }}>
                    &nbsp;<span className="fas fa-trash text-dark">&nbsp;</span>
                </a>
            </>
        )
    }else {
        return params.value;
    }
}

export default RemoveRenderer;