import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import { useFormContext, Controller } from "react-hook-form";
import moment from 'moment';
import { useAppSelector } from '../../../../redux/hooks';
import { useAlert, useConfirm } from '../../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic'
import { AdditionalManualOverrideForm } from '../../../horses/Detail/AdditonalTab/AdditionalManualOverrideForm';
import { MembershipDetailForm } from './MembershipDetailForm';
import { renderIcon, getExtraNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers'
import { SearchInput } from '../SearchInput';
import { HorseLookupForm } from './HorseLookupForm';
import { hasVerifiedMeasurementCard, controlLookupFields, deleteMembershipOverride, allowManualMembershipOverrideForHorse } from '../../../horses/Detail/AdditonalTab/MembershipFunctions';
import Select from "react-select"
import { HeightVerifiedForm } from './HeightVerifiedForm';
import { horseType, horseConvertHeight } from '../../../horses/Detail/AdditonalTab/MembershipFunctions';
import { updateAGGridWithoutFlickering } from '../../../../modules/sgl-utils/agGridHelpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";

// Constants
import { date_options, setDate, maskDateInput } from '../../../../modules/sgl-utils/fieldControls';
import DateRenderer from '../../../../modules/output-listing/renderers/DateRenderer';
import { reactSelectStyles } from '../../../../modules/sgl-utils/fieldControls';
import { useLoadingOverlay } from '../../../../modules/sgl-utils/DialogsProvider';
import { USEFLookupMismatchModal } from '../../../horses/Detail/AdditonalTab/USEFLookupMismatchModal';
import { FEINameMismatchAlert } from '../../../horses/Detail/AdditonalTab/FEINameMismatchAlert';

import { DateStringEditor } from '../../../../modules/sgl-utils/DateStringEditor';

const HorseSection = (props) => {
    const intl = useIntl();
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const governingOrganization = useAppSelector(state => state.showCompany.governing_organization)
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)
    const methods = useFormContext();
    const { watch } = methods
    const confirmDialog = useConfirm()
    const loadingOverlay = useLoadingOverlay()

    const watchTrainerAccount = watch("entry.trainer_account")
    const show_financials_locked = methods.watch('show.islocked')

    const [selectedRow, setSelectedRow] = useState(false)
    const [showManualOverrideForm, setShowManualOverrideForm] = useState(false)
    const [showMembershipDetailForm, setShowMembershipDetailForm] = useState(false)
    const [showHorseLookupForm, setShowHorseLookupForm] = useState(false)
    const [isLocked, setIsLocked] = useState(true)
    const [gridRef, setGridRef] = useState();
    const [rowData, setRowData] = useState();
    const [verifiedHeight, setVerifiedHeight] = useState({});
    const currentShowEndDate = useAppSelector(state => state.currentShow.end_date);
    const customer_id = useAppSelector((state) => state.showCompany.company_id);
    const [horse_status, setHorseStatus] = useState('');
    const [horse_search_status, setHorseSearchStatus] = useState('');
    const [horse_search_list, setHorseSearchList] = useState([]);
    const [search_count_status, setSearchCountStatus] = useState('');
    const [default_org, setDefaultOrg] = useState(governingOrganization);
    const [verifiedHeightModal, setVerifiedHeightModal] = useState(false);
    const [lookupResponse, setLookupResponse] = useState([]);
    const [showUsefMismatchForm, setShowUsefMismatchForm] = useState(false)
    const [mismatch, setMismatch] = useState(false);
    const [mismatchHorse, setMismatchHorse] = useState({});
    const [FEINameMismatch, setFEINameMismatch] = useState(false);
    const [feiMismatch, setFeiMismatch] = useState(false);
    const [horseData, setHorseData] = useState({});
    const alertDialog = useAlert()
    const [updateRiderStatusTrigger, setUpdateRiderStatusTrigger] = useState(0)

    const [breed, setBreed] = useState('');
    const [watchHorseDOB, setWatchHorseDOB] = useState();
    const [watchHorseHeight, setWatchHorseHeight] = useState();

    const columnDefs = [
        { field: 'organization_code', headerName: "", sortable: false, cellRenderer: renderIcon, maxWidth: 30, lockVisible: false, lockPosition: 'left', cellClass: 'text-center pl-r-5 image-cell', headerClass: 'ag-header-cell-wrap-text pl-r-5', resizable: true },
        { field: 'organization_code', cellStyle: {'paddingLeft': 5, 'paddingRight': 5}, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.CODE' }), maxWidth: 140, lockVisible: false, lockPosition: 'left', headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header', resizable: true },
        { field: 'membership_number', cellStyle: {'paddingLeft': 5, 'paddingRight': 5}, cellClass: 'editable-cell ag-left-aligned-cell', editable: (params) => checkEditable(params), headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.MEMBERSHIPID' }), maxWidth: 110, lockVisible: false, lockPosition: 'left', headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header', resizable: true },
        { field: 'valid_start', cellStyle: {'paddingLeft': 5, 'paddingRight': 5}, cellClass: (params) => checkDateEditable(params).cellClass, cellEditor: DateStringEditor, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.STARTDATE' }), lockVisible: false, lockPosition: 'left', editable: (params) => checkDateEditable(params).editable, minWidth: 95, maxWidth: 95, headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header', cellRenderer: DateRenderer, resizable: true},
        { field: 'valid_end', cellStyle: {'paddingLeft': 5, 'paddingRight': 5}, cellClass: (params) => checkDateEditable(params).cellClass, cellEditor: DateStringEditor, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.ENDDATE' }), lockVisible: false, lockPosition: 'left', editable: (params) => checkDateEditable(params).editable, minWidth: 95, maxWidth: 95, headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header', cellRenderer: DateRenderer, resizable: true},
    ]

    const checkEditable = (params) => {
        if(methods.getValues('entry.horse_id') || methods.getValues('entry.horse')){
            
            if(showFinancialsLocked){ // show is locked, disable EC number cell. Horse is changed when we perform EC lookup
                let current_org = methods.getValues('horse.memberships_data')
                if(current_org.findIndex(membership => membership.organization_code == 'EC')>=0){
                    return false //make it non editable       
                }
            }
            return true

        }else{                         
            return false
        }
    }

    const rowClassRules = useMemo(() => {
        return {
            'bg-override': 'data.override',
            'bg-expired': 'data.expired',
            'bg-current': 'data.current',
            'bg-valid': 'data.valid',
            'bg-special': 'data.special',
            'bg-none': 'data.none',
        };
    }, []);

    const manageMembershipDetail = (params, showMembershipDetail = true) => {
        let endPoint = ''
        let lookupResponse = ''
        if(params.data?.organization_code === 'USEF' && params.data?.membership_number){
            endPoint = 'usefLookup'
            lookupResponse = 'usef_response_eti_lookup'
            
        }else if(params.data?.organization_code == 'EC'  && params.data?.membership_number){
            endPoint = 'ecLookup'
            lookupResponse = 'ec_lookup_response'
        }else if(params.data?.organization_code == 'FEI'  && params.data?.membership_number){
            endPoint = 'feiLookup'
            lookupResponse = 'fei_lookup_response'
        }else if(params.data?.organization_code == 'USHJA'  && params.data?.membership_number){
            endPoint = 'ushjaLookup'
            lookupResponse = 'ushja_lookup_response'
        }else if(params.data?.organization_code?.toUpperCase() == 'NORCAL'  && params.data?.membership_number){
            endPoint = 'norcalLookup'
            lookupResponse = 'norcal_lookup_response'
        }

        if(endPoint !== ''){   
            loadingOverlay({show: true})
            axios.post( process.env.REACT_APP_NEST_API_URL + '/horses/'+ endPoint, {
                params: {
                    horse: methods.getValues("horse.horse_data"),
                    customer_id: customer_id,
                    membership_obj: params.data,
                    accept_mismatch_checked: false
                }
            }).then((response) => {
                loadingOverlay({show: false})
                if(response.data.success) {
                    let mismatch = false
                    if(endPoint == 'usefLookup' && response.data.mismatch_fields && response.data.mismatch_fields.length > 0 && showMembershipDetail){
                        methods.setValue('usef_lookup_field', response.data.mismatch_fields)
                        mismatch = true
                        setMismatch(true)
                        setFeiMismatch(false)
                    }else if(endPoint == 'feiLookup'){
                        if(response.data.mismatch_fields?.length){
                            mismatch = true
                            setFeiMismatch(true)
                            setMismatchHorse(response.data.mismatch_fields[0])
                        }
                        axios.post( process.env.REACT_APP_NEST_API_URL + '/horses/' + 'feiStatsLookup', {
                            params: {
                                feiId: methods.getValues("horse.horse_data")?.fei_id,
                                horse_master_sgl_id: methods.getValues("horse.horse_data")?.master_id,
                                customer_id: methods.getValues("horse.horse_data")?.customer_id ?? -1
                            }
                        })
                        setMismatch(false)
                    }
                    
                    else{
                        setMismatch(false)
                    }

                    updateMembership(response.data.updated_memberships_list)
                    setHorseData(response.data.horse)
                    let lookup = JSON.parse(response.data.horse[lookupResponse])
                    parseLookupData(lookup, lookupResponse)
                    if(showMembershipDetail){
                        setShowMembershipDetailForm(true);
                    }else{
                        handleUpdateData(response.data.horse, endPoint, mismatch)
                    }
                } else {
                    let error_id = 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.ERROR.'+response.data.error_message_id
                    alertDialog({message: intl.formatMessage({ id: error_id })})
                    clearMembership(params.data.organization_code)
                }
            
            })
        }
        
    }

    const parseLookupData = (lookup, lookupResponse) => {
        if (lookup){
            var lookup_entries = Object.entries(lookup)
            let lookupData = [{'key': lookupResponse, 'value': JSON.stringify(lookup)}]
            lookup_entries.map(([key, value]) => {
                // if(value != null && Array.isArray(value)){
                //     if(value.length > 0){
                //         value.map((data) => {
                //             let lookup = Object.entries(data)
                //             lookup.map(([key, value]) => {
                //                 lookupData.push({'key': key, "value": value})
                //             })
                //         })
                //     }
                // }
                // else{
                    lookupData.push({'key':key, "value":value})
                // }
            })
            setLookupResponse(lookupData)
        }
    }

    const onCellDoubleClick = (params) => {
        setSelectedRow(params.data)
        switch (params.column.colId) {
            case "organization_code": 
                manageMembershipDetail(params)
                break;
            // TODO - when membership override will be implemented change this manage membership call with setShowManualOverrideForm
            case "organization_code_1": 
                if(allowManualMembershipOverrideForHorse(params.data.organization_code) && methods.getValues('entry.horse_id') !== 0){//Check if the manual membership override is allowed or not for organization
                    setShowManualOverrideForm(true)
                }
                break;
        }
    }
    
    const getSelectedHorse = (horse, organization, number_lookup = false) => {

        axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/selectedHorse', {
            params: {
                horse: horse,
                customer_id: customer_id,
                number_lookup: number_lookup,
                show_id: methods.getValues('entry.show_id')?methods.getValues('entry.show_id'):currentShowID,
                organization: organization
            }
        }) 
        .then(async (result) => { 
            if(result.data.success){
                if(result.data.show_alert){
                    checkUpdateHorseWarning(result.data.horse, result.data.alert_message, organization, result.data.search_status)
                } else {
                    setHorseSearchStatus(result.data.search_status)
                    await updateHorse(result.data.horse, organization)
                    if(result.data?.showfees_to_delete?.length){ // Remove default entry fees
                        const entryfees_after_delete = methods.getValues("entryFeesData.entryFees").filter(ef => {
                            return !result.data?.showfees_to_delete.includes(ef.showfees_id)
                        })

                        const entryfees_to_delete = methods.getValues("entryFeesData.entryFees").filter(ef => {
                            return result.data?.showfees_to_delete.includes(ef.showfees_id)
                        })

                        let deletedEntryFees = methods.getValues('deletedEntryFees')? methods.getValues('deletedEntryFees'):[]
                        deletedEntryFees = deletedEntryFees.concat(entryfees_to_delete)
                        methods.setValue('deletedEntryFees', deletedEntryFees, {shouldDirty: true})
                        methods.setValue("entryFeesData.entryFees", entryfees_after_delete)
                    }                  
                }
                
            }
        })
    }

    const checkUpdateHorseWarning = async (horse, alert_message, organization, search_status) => {
        const choice = await confirmDialog({message: alert_message})
        if(choice){ 
            setHorseSearchStatus(search_status)
            updateHorse(horse, organization)
        }   
    }

    const updateHorse = async (horse, organization) => {
        methods.setValue("horse.horse_data", horse.horse_data, {shouldDirty: true})
        methods.setValue("horse.horse_data.sex", horse.horse_data.sex, {shouldDirty: true})
        methods.setValue("horse.horse_data.dob", horse.horse_data.dob, {shouldDirty: true})
        for(let key in horse.horse_data){ //update whole state object for horse_data as name for newly added horses from lookup was not sent to db and were created with empty name
            methods.setValue(`horse.horse_data.${key}`, horse.horse_data[key], {shouldDirty: true})
        }
        methods.setValue("horse.memberships_data", horse.memberships_data, {shouldDirty: true})
        methods.setValue("existing_horse", horse, { shouldDirty: true})
        methods.setValue('entry.horse_id', horse.horse_data.horse_id, {shouldDirty: true})
        methods.setValue('entry.horse', horse.horse_data.name, {shouldDirty: true})
        methods.setValue('entry.status', horse.horse_data.status, {shouldDirty: true})
        methods.setValue('entry.prizemoneyrecipientsource', 0, {shouldDirty: true})
        methods.setValue('entry.horse_age', horse.horse_data.age, {shouldDirty: true})
        methods.setValue('entry.on_vet_hold', horse.horse_data.on_vet_hold, {shouldDirty: true})
        
        // if(horse.horse_data.horse_id > 0){
            await getSelectedHorseData(horse, organization)
        // }
    }
    const onBlur = (e) => {
        callSearchHorseApi(e.target.value)
    }

    const onSearch = (e) => {
        if(e.key == 'Enter'){
            e.target.blur();
        }
    }

    function callSearchHorseApi(search_term){
        if(search_term.trim()){
            loadingOverlay({show: true})
            axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/searchHorse', {
                params: {
                    horse_name: search_term,
                    customer_id: customer_id,
                    show_id: methods.getValues('entry.show_id')?methods.getValues('entry.show_id'):currentShowID,
                }
            }) 
            .then(async result => { 
                loadingOverlay({show: false})
                if(result.data.success){
                    if(result.data.update_horse){ // update horse directly 
                        setHorseSearchStatus(result.data.search_status)
                        // updateHorse(result.data.horse, result.data.default_org)
                        await getSelectedHorse(result.data.horse, result.data.default_org, true)
                        methods.setValue('horse_search', '')
                    }else { // open search dialog
                        setHorseSearchList(result.data.horses_list)
                        setSearchCountStatus(result.data.horse_count_status)
                        setDefaultOrg(result.data.default_org)
                        setShowHorseLookupForm(true)
                    }
                    
                } 
            })
        }
    }

    // function to check if membership date is editable
    function checkDateEditable(params) {
        const { data } = params.node;

        // make the organization uneditable if it is managed and system-org >>>
        let organizations = methods.getValues("horse")?.horse_organizations ?? []
        let currentOrganization = organizations?.find((org) => org.organization_code === data.organization_code)
        let isEditable = !(currentOrganization?.managed && currentOrganization?.system_org);
        // make the organization uneditable if it is managed and system-org <<<

        const cellClass = isEditable ? 'editable-cell' : 'non-editable-cell';
        return {
          editable: isEditable,
          cellClass: cellClass,
        };
    }
    
    const getSelectedHorseData = async (horse, organization) => {
        await axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/selectedHorseData', {
            params: {
                horse: horse.horse_data,
                customer_id: customer_id,
                show_id: methods.getValues('entry.show_id')?methods.getValues('entry.show_id'):currentShowID,
                entry: methods.getValues('entry'),
                organization: organization
            }
        }) 
        .then(result => { 
            if(result.data.success){
                let linked_people = {
                    ...methods.getValues("linked_people"),
                    ...result.data.linked_people
                }
                methods.setValue("linked_people", linked_people)
                methods.setValue('entry', result.data.entry, {shouldDirty: true})
                methods.setValue('stable', result.data.stable, {shouldDirty: true})
                methods.setValue('horse.horse_data', result.data.horse_data, {shouldDirty: true})
               // call update rider status for all riders
               setUpdateRiderStatusTrigger(updateRiderStatusTrigger + 1)
            }
        })
    }

    useEffect(() => {
        if(updateRiderStatusTrigger > 0){
            updateRiderStatus()
        }
    }, [updateRiderStatusTrigger]);

    async function updateRiderStatus(){
        let entryRiders = methods.getValues('entry.EntryRiders')

        for(let entry_rider of entryRiders) {
            let people = methods.getValues(`linked_people[${entry_rider.rider_id}]`)
            console.log(people, entry_rider)
            if(people){
                let rider_status = await props.getRiderStatus(people, entry_rider)
                if(rider_status && rider_status != ''){
                    // let riderIndex = entryRiders.findIndex(rider => rider.rider_id == entry_rider.rider_id)
                    // console.log(rider_status, riderIndex)

                    // if(riderIndex >= 0){
                        entry_rider['status'] = rider_status
                        entry_rider['isUpdated'] = true
                        // setRider(entryRiders[riderIndex]);

                    // }
                }
            }
        }
        methods.setValue('entry.EntryRiders', entryRiders, {shouldDirty: true})

    }

    
    useEffect(() => {
        if(gridRef && methods.getValues('horse.memberships_data')){
            // If horse has a verified measurement card update its height verified status
            if(Object.keys(methods.getValues("horse.horse_data")).length > 0 && methods.getValues('horse.horse_data.horse_id') != 0){
                if(hasVerifiedMeasurementCard(methods.getValues('horse.horse_data.usef_response_eti_lookup'),methods.getValues('horse.horse_data.measurement_override_date')))
                {
                    setVerifiedHeight(
                        {
                            text:intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.VERIFIED' }),
                            color: '#18ca87'
                        }
                    )
                }
                else{
                    setVerifiedHeight(
                        {
                            text:intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.UNVERIFIED' }),
                            color: '#e62721'
                        }
                    ) 
                }
            }

            let disableStates = controlLookupFields(methods.getValues('horse.horse_data'), methods.getValues('horse.memberships_data'))
            setWatchHorseHeight(disableStates?.disHeight)
            setWatchHorseDOB(disableStates?.disDob)

            priorityColor(methods.getValues('horse'))
            setHorseStatus(props.horseSuspensionStatus())
        }
    }, [gridRef, methods.watch('horse.horse_data'), methods.watch('horse.memberships_data'), methods.watch('horse.horse_data.measurement_override_date')]);

    useEffect(() => {
        if(methods.getValues('horse.horse_data')){
            let horse_data = methods.getValues('horse.horse_data')
            let breed = horse_data.breed? horse_data.breed: ''
            let sire = horse_data.sire? horse_data.sire: ''
            let dam = horse_data.dam? horse_data.dam: ''

            setBreed( breed + (breed && (sire || dam)?' x ':'') + sire + ( sire && dam?' x ':'') + dam)
        }
    }, [methods.watch('horse.horse_data.breed'), methods.watch('horse.horse_data.sire'), methods.watch('horse.horse_data.dam')]);


    useEffect(() => {
        if(methods.getValues('horse.horse_data')){
            setHorseStatus(props.horseSuspensionStatus())
        }
    }, [methods.watch('horse.horse_data.microchip_number')]);

    const priorityColor = (horseDetails) => {
        let organizationMembers = horseDetails?.memberships_data
        let horseData = horseDetails?.horse_data
        let rowData = organizationMembers?.map((data) => {
            // initializing color schemes
            data.none = false;
            data.expired = false;
            data.special = false;
            data.override = false;
            data.valid = false;

            if (data.membership_number && data?.membership_number != "")
                {
                    let ab_horseMembershipValid = false;
                    let membership_color = 'white';
                    
                    let today = moment().format('YYYY-MM-DD'); // default  //ADD Time zone info
                    // if(props.form_meta.timezone_info){
                    //     today = moment(props.form_meta.timezone_info.date)
                    // }
                    
                    // if(data.is_membershipvalid == true || data.is_membershipvalid == 1){
                    if(data.valid_end && data.valid_end !='' && data.valid_start && data.valid_start !='')
                        if(moment(today).isSameOrBefore(moment(data.valid_end).format('YYYY-MM-DD')) && moment(today).isSameOrAfter(moment(data.valid_start).format('YYYY-MM-DD'))){ // if valid end is future date
                            data.is_membershipvalid = true
                            ab_horseMembershipValid = true
                        }
                        else{
                            data.is_membershipvalid = false
                            ab_horseMembershipValid = false
                        }
                    // }

                    let membershipOverride = data.membership_override
                    
                    if(membershipOverride && membershipOverride.inactive_override_date >= currentShowEndDate){
                        // al_membershipcolor = '#FFFF00' //Yellow Color
                        membership_color = 'yellow'
                        if(membershipOverride.owner_nonmemberfeepaid || (membershipOverride.ec_override_reason == 20 && membershipOverride.organization_code == 'EC')){
                            // al_membershipcolor = '#FFA500' //Orange Color
                            membership_color = 'orange'
                        }
                    }else if (ab_horseMembershipValid){
                        // al_membershipcolor = '#00FF00' //Green Color
                        membership_color = 'green';

                        // Check USEF suspension and change row color to red if suspended, regardless of their current membership validity
                        if (horseData && horseData?.usef_suspended == true && data?.organization_code === 'USEF') {
                            membership_color = 'red';
                        }

                        // Check EC suspension and change row color to red if suspended, regardless of their current membership validity
                        else if (horseData && horseData?.ec_status == "SUSPENDED" && data?.organization_code === 'EC') {
                            membership_color = 'red';
                        }

                        // Check FEI suspension and change row color to red if suspended, regardless of their current membership validity
                        else if (horseData && horseData?.fei_inactive_reason == "SUSPENDED" && horseData.fei_id !='' && data?.organization_code === 'FEI') {
                            membership_color = 'red';
                        }
                    }else {
                        if(!data.valid_end || data?.valid_end == '0000-00-00' || data?.valid_end == ''){ //change format
                            // ec statuses in array
                            let ec_inactive_statuses = ['DECEASED', 'RETIRED', 'SOLD'];
                            if(membershipOverride && membershipOverride.membership_number != "" && membershipOverride.organization_code == "EC" && 
                            ec_inactive_statuses.includes(methods.getValues("people.ec_status") )){
                                // al_membershipcolor = "#FF0000" //Red
                                membership_color = 'red'
                            } else {
                                // al_membershipcolor = "#FFFFFF" //White
                                membership_color = 'white'
                            }
                        } else{
                            // al_membershipcolor = "#FF0000" //Red
                            membership_color = 'red'
                        }
                    }
            
                    switch(membership_color){
                        case 'white':
                            data.none = true;
                            break;
                        case 'red':
                            data.expired = true;
                            break;
                        case 'orange':
                            data.special = true;
                            break;
                        case 'yellow':
                            data.override = true;
                            break;
                        case 'green':
                            data.valid = true;
                            break;
                    }
            } else{
                data.none = true
            }
            return data
        })

        // gridRef?.setRowData(rowData)
        if(gridRef){
            updateAGGridWithoutFlickering(({ current: { api : gridRef}}), rowData, "organization_code") // update grid without rerendering 
        }
    }

    // Update horse data after user consent
    const handleUpdateData = (horse = null, endPoint = '', mismatch = false) => {
        if(horseData && !horse){
            horse = horseData
        }
        setShowMembershipDetailForm(false)
        if(endPoint == 'usefLookup' && mismatch){
            setShowUsefMismatchForm(true)
        }else if(endPoint == 'feiLookup' && mismatch){
            setFEINameMismatch(true)
        }
        methods.setValue('horse.horse_data', horse, {shouldDirty: true})
    }

    // Update horse memberships return from lookups
    const updateMembership = (memberships) => {
        let horseMemberships = methods.getValues('horse.memberships_data')
        if(Object.keys(memberships).length > 0){
            for(let code in memberships){
                let index = horseMemberships.findIndex(hm => hm.organization_code.toLowerCase() == code.toLowerCase())
                if(index >= 0){
                    if(!memberships[code]){
                        horseMemberships[index]['membership_number'] = ''
                        horseMemberships[index]['valid_end'] = '0000-00-00'
                        horseMemberships[index]['valid_start'] = '0000-00-00'
                    }else{
                        horseMemberships[index] = memberships[code]
                    }
                    horseMemberships[index]['isUpdated'] = true
                }
            }
            methods.setValue('horse.memberships_data', horseMemberships, {shouldDirty: true})
        }
    }

    const acceptMismatch = () => {
        // methods.setValue('horse.name', props.horseName, {shouldDirty: true}) // DO NOT update horse name from FEI
        let organizationMembers = methods.getValues("horse.memberships_data")
        // , organization = ""
        // organization = organizationMembers.find(membershipData =>{
        //     if (membershipData.organization_code.toLowerCase() == 'fei'){
        //         return membershipData
        //     }
        // })
        let index = organizationMembers.findIndex(pm => pm.organization_code.toLowerCase() == 'fei')

        // if(index >= 0) {
        //     organizationMembers[index].membership_id = 0
        //     organizationMembers[index].organization_code = "FEI"
        //     organizationMembers[index].horse_id = methods.getValues('horse.horse_id')
        // }
        organizationMembers[index].name_mismatch_accepted = true
        organizationMembers[index].membership_name = mismatchHorse?.lookupField

        let membership_year = methods.getValues("horse.horse_data.fei_membership_year")?parseInt(methods.getValues("horse.horse_data.fei_membership_year")):0;
        let fei_isactive = methods.getValues("horse.horse_data.fei_isactive")
        if(organizationMembers[index].valid_start == '0000-00-00' && organizationMembers[index].valid_start == '0000-00-00' && membership_year > 0 && fei_isactive){
            organizationMembers[index].valid_start = `${membership_year}-01-01` //If year is 2019 , the data will be 01/01/2019
            organizationMembers[index].valid_end = `${membership_year}-12-31` //set date to the 1 year after the valid start date and -1 day, e.g 12/31/2019
            // organization.membership_level = props.personFirstName + " " + props.personLastName

            let current_date = moment().format('YYYY-MM-DD')
            if (organizationMembers[index].valid_end >= current_date) { //RTO has current compition year membership of FEI
                organizationMembers[index].is_membershipvalid = true
            }

        }
        methods.setValue("horse.memberships_data", organizationMembers)

        // updateMembership(organization)

    }

    //4D! [Entry]Input.vl_HorsemembershipBox.as_HorseMembershipValue
    const horseMembershipValue = (params, lookup = true) =>{
        let membership = params.data
        let horseMemberships = methods.getValues('horse.memberships_data')
        let membershipDataIndex = horseMemberships.findIndex(member => member.organization_code == membership.organization_code)

        let lookupVariable = ''
        switch(membership.organization_code.toUpperCase()){
            case 'USEF':
                lookupVariable = 'usef_response_eti_lookup'
                break;

            case 'EC':
                lookupVariable = 'ec_lookup_response'
                break;

            case 'FEI':
                lookupVariable = 'fei_lookup_response'
                break;

            case 'USHJA':
                lookupVariable = 'ushja_lookup_response'
                break;

            case 'NORCAL':
                lookupVariable = 'norcal_lookup_response'
                break;
        }

        if(lookupVariable){
            methods.setValue(`horse.horse_data.${lookupVariable}`, '', { shouldDirty: true })
        }
        //If membership Number is empty set date to 00/00/0000 else set valid is true
        if(membership.membership_number == ""){
            horseMemberships[membershipDataIndex].valid_start = "00/00/0000"
            horseMemberships[membershipDataIndex].valid_end = "00/00/0000"
            //Remove membership override
            if(horseMemberships[membershipDataIndex].membership_override){
                let updatedOverrideRemoved = methods.getValues().horse?.membership_override_removed ?? []
                let updatedOverrideAdded = methods.getValues().horse?.membership_override_added ?? []
                let updatedData = deleteMembershipOverride(horseMemberships[membershipDataIndex], updatedOverrideAdded, updatedOverrideRemoved)
                methods.setValue('horse.membership_override_removed',updatedData.membership_override_removed, { shouldDirty: true })
                methods.setValue('horse.membership_override_added',updatedData.membership_override_added, { shouldDirty: true })
            }
        }
        else if(membership.membership_number != ""){
            horseMemberships[membershipDataIndex].membership_number = membership.membership_number
            //If current date is before end date of Membership validity set isValid as true else false
            if(moment().isBefore(membership.valid_end)){
                horseMemberships[membershipDataIndex]['is_membershipvalid'] = true
            }
            else{
                horseMemberships[membershipDataIndex]['is_membershipvalid'] = false
            }
            horseMemberships[membershipDataIndex].valid_start = (membership.valid_start && moment(membership.valid_start).isValid())? membership.valid_start : '' 
            horseMemberships[membershipDataIndex].valid_end = (membership.valid_end && moment(membership.valid_end).isValid())? membership.valid_end : ''
            if(lookup){
                manageMembershipDetail(params, false)
            }
        }
        //Set is Updated Flag ture and call Priority Color Function
        horseMemberships[membershipDataIndex]['isUpdated'] = true
        methods.setValue('horse.memberships_data', horseMemberships, {shouldDirty: true})
        priorityColor(methods.getValues('horse'))
    }

    const onCellValueChanged = (params) => {
        const moment = require('moment');
        const inValidValues = [undefined, '00/00/0000', '0000-00-00']
    
        if (params.node.data.valid_start && !inValidValues.includes(params.node.data.valid_start)) { //format valid_start date if not formattted already on input
            if(!params.node.data.valid_start.includes('/') && !params.node.data.valid_start.includes('-')){ // if date is not in format (e.g. 01012024)
                params.node.data.valid_start = `${params.node.data.valid_start.slice(0, 2)}/${params.node.data.valid_start.slice(2, 4)}/${params.node.data.valid_start.slice(4)}` // convert date into MM/DD/YYYY format (e.g. 01/01/2024)
            }
        }

        if (params.node.data.valid_end && !inValidValues.includes(params.node.data.valid_end)) { //format valid_end date if not formattted already on input
            if(!params.node.data.valid_end.includes('/') && !params.node.data.valid_end.includes('-')){ // if date is not in format (e.g. 01012024)
                params.node.data.valid_end = `${params.node.data.valid_end.slice(0, 2)}/${params.node.data.valid_end.slice(2, 4)}/${params.node.data.valid_end.slice(4)}` // convert date into MM/DD/YYYY format (e.g. 01/01/2024)
            }
        }
        horseMembershipValue(params)
    }
    
    const containerStyle = useMemo(() => ({ width: '100%', height: '97%' }), []);

    const horseAge = (foalDate) =>{
        let horseAges = ""
        if(moment(foalDate).isValid()) {
            let date = new Date(moment(foalDate).year(), "0", "1");
            horseAges = moment().diff(date,"years")
            if (moment(foalDate).add(horseAges + 1, "years") < moment()){
                horseAges = horseAges + 1
            }
            if (horseAges < 1){
                horseAges = ""
            }
        }else{
            horseAges = ""
        }
        return horseAges == "" ? horseAges: "Age: "+horseAges
    }

    //Clear membership dates if membership not found from look up
    function clearMembership(org){
        let horseMemberships = methods.getValues('horse.memberships_data')
        let membershipDataIndex = horseMemberships.findIndex(member => member.organization_code == org) 
        horseMemberships[membershipDataIndex].valid_start = "0000-00-00"
        horseMemberships[membershipDataIndex].valid_end = "0000-00-00"  
        horseMemberships[membershipDataIndex].valid = false
        horseMemberships[membershipDataIndex].is_membershipvalid = false   
        horseMemberships[membershipDataIndex].name_mismatch_accepted = false
        // horseMemberships[membershipDataIndex].membership_name = ''
        horseMembershipValue({data: horseMemberships[membershipDataIndex]}, false)
    }

    function getTypeAbbreviation(horse_type){
        let abbreviation = '';
        
        if(horse_type == 'Small Pony'){
		    abbreviation = 'SP';
        }
        else if(horse_type == 'Medium Pony'){
		    abbreviation = 'MP';
        }
        else if(horse_type == 'Large Pony'){
		    abbreviation = 'LP';
        }
        else if(horse_type == 'Small Horse'){
		    abbreviation = 'SH';
        }
        else if(horse_type == 'Large Horse'){
		    abbreviation = 'LH';
        }
        
        return abbreviation;
    }

    const updateHeightRelatedData = () => {
        if(methods.getValues('horse.horse_data.type')?.trim() != ""){
            if(!hasVerifiedMeasurementCard(methods.getValues('horse.horse_data.usef_response_eti_lookup', methods.getValues('horse.horse_data.measurement_override_date')))){
                if(methods.getValues('horse.horse_data.height') == "" || methods.getValues('horse.horse_data.height') <= 0){
                    methods.setValue('horse.horse_data.type',"", {shouldDirty:true})
                }
            }
        }
        methods.setValue('horse.horse_data.height_centimeters', horseConvertHeight(methods.getValues('horse.horse_data.height', 'cm')), {shouldDirty:true})

        let horse_type = horseType(methods.getValues('horse.horse_data.height_centimeters'))
        let type = horse_type !="" ? horse_type : methods.getValues('horse.horse_data.type')
        methods.setValue('horse.horse_data.type', type , {shouldDirty:true})
        methods.setValue('entry.horse_type', type , {shouldDirty: true})
    }

    return (
        <>
            <fieldset>
                {showUsefMismatchForm && <USEFLookupMismatchModal show={showUsefMismatchForm} handleClose= {() => setShowUsefMismatchForm(false)} horse={'horse.horse_data'} usef_lookup_field={methods.getValues('usef_lookup_field')}/>}
                {/* {FEINameMismatch && <FeiNameMismatchAlert show={FEINameMismatch} handleClose={() => setFEINameMismatch(false)} personFirstName={mismatchPerson?.first} personLastName={mismatchPerson?.last_name} acceptMismatch = {acceptMismatch} handleClearMembership={handleClearMembership} tabname={'FEI'} first={methods.getValues(`linked_people[${props.person_id}].people_data.first`)} last_name={methods.getValues(`linked_people[${props.person_id}].people_data.last_name`)} />} */}
                {FEINameMismatch && <FEINameMismatchAlert show={FEINameMismatch} handleClose={() => setFEINameMismatch(false)} horseName={mismatchHorse.lookupField} acceptMismatch = {acceptMismatch} handleClearMembership={clearMembership} name={methods.getValues('horse.horse_data.name')} />}

                {showMembershipDetailForm && <MembershipDetailForm show={showMembershipDetailForm} handleClose={() => setShowMembershipDetailForm(false)} lookupResponse={lookupResponse} handleUpdateData={() => handleUpdateData()}/>}
                {showManualOverrideForm && <AdditionalManualOverrideForm show={showManualOverrideForm} handleClose={() => setShowManualOverrideForm(false)} rowData={selectedRow} showId={methods?.getValues().entry?.show_id} horseId={methods?.getValues().entry?.horse_id} calledFrom={"entries"}/>}
                {verifiedHeightModal && <HeightVerifiedForm show={verifiedHeightModal} handleClose={() => setVerifiedHeightModal(false)} />}
                {showHorseLookupForm && <HorseLookupForm 
                    show={showHorseLookupForm} 
                    handleClose={() => {methods.setValue('horse_search', '');setShowHorseLookupForm(false);document.getElementById("owner_search_field").focus();}} 
                    customer_id={customer_id} 
                    horse_search_list={horse_search_list}
                    horse_search_status={search_count_status}
                    default_org={default_org}
                    getSelectedHorse={getSelectedHorse}
                    updateHorse={updateHorse}
                    show_id={currentShowID}
                />}
                <legend className="fs-5 fw-bold d-flex entries-search-legend">
                    <div className='d-flex align-self-center w-50px me-10'><label data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.HORSE.LABEL.HORSE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.GROUPLABEL.HORSE' })}</label></div>
                    <SearchInput  
                        id={"horse_search_field"}
                        containerClass='me-2 w-225px'
                        disabled={watchTrainerAccount || show_financials_locked} 
                        fieldName={'horse_search'}
                        onBlur={onBlur}
                        onSearch={onSearch}
                    />
                    <label className='fs-6 py-1 text-danger col-lg-3 d-flex align-items-center ms-2 text-capitalize'>{horse_search_status}</label>
                    {horse_status?<div className='d-flex align-items-center col'>
                        <i className="fas fa-xl fa-regular fa-triangle-exclamation me-1 text-warning"></i>
                        <label className='fs-6 py-1'>{horse_status}</label>
                    </div>:''}
                    <b></b>
                </legend>
                <div className='row mb-2'>
                    <div className='col-lg-7'>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor='HORSENAME' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.HORSE.LABEL.NAME">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.HORSENAME' })}</label>
                            <div className='col-lg-4 px-0'>
                                <input
                                    {...methods.register("horse.horse_data.name", 
                                    {
                                        required: false
                                    })
                                    }
                                    value={methods.watch('horse.horse_data.name') ?? ""}
                                    id='HORSENAME'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.HORSENAME' })}
                                    disabled={isLocked}
                                    onChange={e => {methods.setValue('entry.horse', e.target.value, {shouldDirty:true});methods.setValue('horse.horse_data.name', e.target.value, {shouldDirty:true})}}
                                />
                            </div>
                            <div className='col-lg-2 w-lg-100px'>
                                <div className='row'>
                                    <div className='col d-flex justify-content-center'>
                                        <i onClick={() => setIsLocked(!isLocked)} className={"fas fa-solid py-3" + (isLocked ? ' fa-lock' : ' fa-lock-open')}></i>
                                    </div>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-65px d-flex justify-content-end' htmlFor='HEIGHT' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.HORSE.LABEL.HEIGHT">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.HEIGHT' })}</label>
                                </div>
                            </div>
                            <div className='col ps-0'>
                                <div className='row'>
                                    <div className='col-5'>
                                        <input
                                            {...methods.register("horse.horse_data.height", {
                                                required: false
                                            })}
                                            value={methods.watch('horse.horse_data.height') ?? ""}
                                            id='HEIGHT'
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.HEIGHT' })}
                                            disabled={(watchHorseHeight || methods.getValues('entry.horse_id') == 0) ? true : false}
                                            onKeyUp={(e) => {
                                                if (e.key != "Tab" && e.key != "Shift") {
                                                    updateHeightRelatedData()
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className='col-1 ps-0'>
                                        <div className='align-items-center fw-bold fs-5 col-form-label py-1'> {getTypeAbbreviation(methods.getValues('horse.horse_data.type'))} </div>
                                    </div>
                                    {!watchTrainerAccount && (
                                    <div className='col-1'>
                                        <div className='d-flex align-items-center justify-content-end' style={{ width: '95px' }}>
                                            <a className={`fs-5 fw-bold py-1 text-decoration-underline me-1`} onClick={() => setVerifiedHeightModal(true)} data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.HORSE.LINK.VERIFIED" style={{color:`${verifiedHeight.color}`}}> {verifiedHeight.text} </a>
                                            {methods.getValues('entry.horse_id') > 0 && Object.keys(methods.getValues("horse.horse_data")).length > 0 ? <i className="fa-sharp fa-solid fa-circle-info fa-xl py-3" style={{ color: '#578EBE' }} onClick={() => props.callbackFromParent(methods.getValues('entry.horse_id'), methods.getValues('horse'), "Horses", "HorseDetail", methods.getValues('horse.horse_data'))}></i> : null }
                                        </div>
                                    </div> )}
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor='HORSEDOB' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.HORSE.LABEL.DOB">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.HORSEDOB' })}</label>
                            <div className='col-lg-4 px-0'>
                                <div className='row'>
                                    <div className='col-lg-8'>
                                        <div className="input-group date">
                                            <Controller
                                                name="horse.horse_data.dob"
                                                control={methods.control}
                                                render={({
                                                    field: { onChange, value, name },
                                                }) => {
                                                    const formattedValue = (value && moment(value).isValid())
                                                        ? moment(value).format('MM/DD/YYYY')
                                                        : '';

                                                    return (
                                                        <Flatpickr
                                                            className="form-control form-control-sm fs-6 min-h-20px py-1 date-placeholder"
                                                            id="horse_dob"
                                                            name={name}
                                                            value={formattedValue}
                                                            placeholder="MM/DD/YYYY"
                                                            options={date_options}
                                                            autoComplete="off"
                                                            onClose={(value, dateStr, instance) => {
                                                                const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                                if (!isValidDate) {
                                                                    instance.input.value = ''; // Clear the input if the date is invalid
                                                                }
                                                                methods.setValue('entry.horse_age', moment().diff(value[0], 'years'), {shouldDirty: true})
                                                                methods.setValue('horse.horse_data.age', moment().diff(value[0], 'years'), {shouldDirty: true})
                                                                setDate(dateStr, onChange); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                                setUpdateRiderStatusTrigger(updateRiderStatusTrigger + 1)

                                                            }}
                                                            onChange={(value, dateStr, instance) => {
                                                                const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                                if (!isValidDate) {
                                                                    instance.input.value = ''; // Clear the input if the date is invalid
                                                                }
                                                                methods.setValue('entry.horse_age', moment().diff(value[0], 'years'), {shouldDirty: true})
                                                                methods.setValue('horse.horse_data.age', moment().diff(value[0], 'years'), {shouldDirty: true})
                                                                setDate(dateStr, onChange); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                                setUpdateRiderStatusTrigger(updateRiderStatusTrigger + 1)

                                                            }}
                                                            onInput={(e) => { maskDateInput(e) }}
                                                            disabled={(watchHorseDOB || methods.getValues('entry.horse_id') == 0)}
                                                        />
                                                    );
                                                }}
                                            />
                                            <div className="input-group-append">
                                                <label htmlFor='horse_dob' className='date-label'
                                                    onClick={(e) => {
                                                        e.stopPropagation(); 
                                                        document.getElementById('horse_dob')._flatpickr.open(); 
                                                    }}
                                                >
                                                    <span className="input-group-text">
                                                        <i className="la la-calendar"></i>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <label className='col-lg-4 col-form-label fs-5 py-1 ps-1' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.HORSE.LABEL.AGE">{horseAge(methods.getValues('horse.horse_data.dob'))}</label>
                                </div>
                            </div>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-100px d-flex justify-content-end' htmlFor='MICROCHIPNUM' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.HORSE.LABEL.MICROCHIP">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.MICROCHIPNUM' })}</label>
                            <div className='col ps-0'>
                                <input
                                    {...methods.register("horse.horse_data.microchip_number", 
                                    {
                                        required: false
                                    })
                                    }
                                    value={methods.watch('horse.horse_data.microchip_number') ?? ""}
                                    id='MICROCHIPNUM'
                                    type='text'
                                    disabled={methods.getValues('entry.horse_id') == 0}
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.MICROCHIPNUM' })}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor='SEX' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.HORSE.LABEL.SEX">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.SEX' })}</label>
                            <div className='col-lg-4 px-0'>
                                    {props.form_meta?.sex && (
                                        <Controller
                                        name='horse.horse_data.sex'
                                        render={({field: {onChange, value, name}}) => (
                                            <Select
                                            options={props.form_meta.sex}
                                            id='horse.horse_data.sex'
                                            value={props.form_meta.sex.find(
                                                (ss) => ss.value === value
                                            )}
                                            name={name}
                                            isSearchable={true}
                                            isDisabled={methods.getValues('entry.horse_id') == 0}
                                            onChange={(sex) => {
                                                onChange(sex.value)
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}
                                            styles={reactSelectStyles}
                                            placeholder='Select'
                                            />
                                        )}
                                        />
                                    )}
                            </div>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-100px d-flex justify-content-end' htmlFor='COLOR' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.HORSE.LABEL.COLOR">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.COLOR' })}</label>
                            <div className='col ps-0'>
                                <input
                                    {...methods.register("horse.horse_data.color", 
                                    {
                                        required: false
                                    })
                                    }
                                    value={methods.watch('horse.horse_data.color') ?? ""}
                                    id='COLOR'
                                    disabled={methods.getValues('entry.horse_id') == 0}
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.COLOR' })}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor='BREED' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.HORSE.LABEL.BREED">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.BREED' })}</label>
                            <label className='col-lg-4 px-0 col-form-label pt-2 pb-1 d-flex align-items-center'>{breed}</label>
                            <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-100px d-flex justify-content-end' htmlFor='MEASCARD' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.HORSE.LABEL.MEASUREMENTCARD">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.MEASCARD' })}</label>
                            <div className='col ps-0'>
                                <input
                                    {...methods.register("horse.horse_data.meas_card_no", 
                                        {
                                            required: false
                                        })
                                    }
                                    id='MEASCARD'
                                    disabled={methods.getValues('entry.horse_id') == 0}
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.MEASCARD' })}
                                />
                            </div>
                        </div>
                        {/* <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1' style={{ width: '105px' }} htmlFor='HORSESTATUS'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.HORSESTATUS' })}</label>
                            <div className='col ps-0'>
                                <input
                                    {...methods.register("horse.horse_data.horse_data.status", 
                                    {
                                        required: false
                                    })
                                    }
                                    id='HORSESTATUS'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.HORSESTATUS' })}
                                    disabled={true}
                                />
                            </div>
                            <div className='col-lg-1 w-lg-25px d-flex align-items-center justify-content-end'>
                                <i className="fa-sharp fa-solid fa-circle-exclamation fa-xl py-3" style={{ color: '#e62721' }}></i>
                            </div>
                        </div> */}
                    </div>
                    <div className='col-lg-5 membership-data-grid'>
                        <OutputListingGridStatic
                            // area ={"Entries - Horse Section"}
                            columnDefs={columnDefs}
                            rowData={rowData}
                            rowHeight={getExtraNarrowRowHeight}
                            setGridRef={setGridRef}
                            containerStyle={containerStyle}
                            rowClassRules={rowClassRules}
                            onCellDoubleClick={onCellDoubleClick}
                            onCellValueChanged={onCellValueChanged}
                            enableRangeSelection={true}
                            stopEditingWhenCellsLoseFocus={true}
                        ></OutputListingGridStatic>
                    </div>
                </div>
            </fieldset>
        </>
    );
}

export { HorseSection }