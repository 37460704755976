import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form';

// Custom Components
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import DecimalPointRenderer from '../../../modules/output-listing/renderers/DecimalPointRenderer';

const ClassFeesTab = () => {
    const intl = useIntl();
    const methods = useFormContext()

    const columnDefs = [
        { field: 'description', flex: 4, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSFEES.DESCRIPTION' }) },
        { field: 'price', flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSFEES.PRICE' }), cellClass: 'ag-right-aligned-cell', cellRenderer: (params) => DecimalPointRenderer(params, 2) },
    ]

    const rowData = methods.getValues("entryPrices")
    const recordCount = rowData.length

    const containerStyle = useMemo(() => ({ width: '100%', height: '400px' }), [])

    return (
        <>
            <div className='form-group mb-2'>
                <div className='row mb-1'>
                    <label className='col col-form-label fs-5 py-1 text-end' data-tooltip-id="ENTRIES.DETAIL.TAB.CLASSFEES.LABEL.TOTAL">
                        Total: { recordCount}
                    </label>
                </div>
                <div className='row mb-2'>
                    <OutputListingGridStatic  area ={"Entries - ClassFees"} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle}></OutputListingGridStatic>
                </div>
            </div>
        </>
    );
}

export { ClassFeesTab }