import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useSchedulerContext } from './SchedulerContext';
import { useAlert, useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../modules/sgl-utils/SglFunctions';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const ScheduleBreaksGrid = (props) => {
  const intl = useIntl();
  const gridStyle = useMemo(() => ({ height: '147px', width: '100%' }), []);
  const containerStyle = useMemo(() => ({ width: '100%', height: '144px' }), []);
  const rowHeight = 28;
  const headerHeight = 30;

  const {currentClassGroup, currentShowID, customerID, setScheduleBreaksGridRef, scheduleBreaksGridRef, classGroupScheduleGridRef, scheduleBreakAddedParams, updateGroupSchedule} = useSchedulerContext();
  const [rowData, setRowData] = useState([]);
  const [afterXTrips, setAfterXTrips] = useState(0);
  const alertDialog = useAlert()
  const confirmDialog = useConfirm()

  //Grid Schedule Breaks
  const columnDefs = [
      { field: 'title', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.TITLE'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px'}  },
      { field: 'duration', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.DURATION'}), sortable: false, suppressMenu: true,cellStyle: {fontSize: '13px',textAlign: 'center'}, maxWidth: 130  ,headerClass: 'ag-center-aligned-header'  },
  ]

  const onFirstDataRendered = useCallback((params) => {
      params.api.sizeColumnsToFit();
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: false,
      editable: true,
      wrapHeaderText: true,
      cellClass: 'editable-cell',
      singleClickEdit: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    //set new grid api in scheduler context
    setScheduleBreaksGridRef(params.api);
  }, []);

  useEffect(() => {
    if (classGroupScheduleGridRef && scheduleBreaksGridRef && scheduleBreakAddedParams) {
      scheduleBreaksGridRef.addRowDropZone(scheduleBreakAddedParams);
    }
  }, [scheduleBreakAddedParams]);

  const addScheduleBreak = () => {
    axios.get(`${NEST_API_URL}/scheduler/addmasterschedulebreak?customer_id=${customerID}`)
    .then(result => {
      let gridRowsData = [];
      scheduleBreaksGridRef.forEachNode(node => gridRowsData.push(node.data));
      gridRowsData.push(result.data)
      scheduleBreaksGridRef.setRowData(gridRowsData)
    })
    .catch(error => { console.error(error); throw error; });
  }

  const deleteScheduleBreak = async () => {
    let selectedRows = scheduleBreaksGridRef.getSelectedNodes()
    if(selectedRows.length > 0){
      const choice = await confirmDialog({message: `Are you sure you want to delete breaks with title "${(selectedRows[0].data.title)}"?`})
      if(!choice){
          return false;
      }

      let selectedRow = selectedRows.length ? selectedRows[0] : {}
      let class_group_id = currentClassGroup.class_group_id ? currentClassGroup.class_group_id : 0
      axios.delete(`${NEST_API_URL}/scheduler/removemasterschedulebreak?show_id=${currentShowID}&customer_id=${customerID}&class_group_id=${class_group_id}&schedule_break_id=${selectedRow.data.schedule_breaks_id}`)
      .then(result => {
        let rowData = [];
        scheduleBreaksGridRef.forEachNode(node => {
          if(selectedRow.data.schedule_breaks_id != node.data.schedule_breaks_id)
          rowData.push(node.data)
        });
        scheduleBreaksGridRef.setRowData(rowData)
        // Refresh class group schedule
        props.setScheduleStatus('Pending');
        updateGroupSchedule(true);
      })
      .catch(error => { console.error(error); throw error; });
    }
  }

  const onCellEdit = params => {
    const { data } = params
    if(params.colDef.field == 'duration' && isNaN(data.duration)){
      params.node.setDataValue('duration', params.oldValue)
      return
    }

    axios.get(`${NEST_API_URL}/scheduler/editmasterschedulebreak?title=${data.title}&duration=${data.duration}&customer_id=${customerID}&class_group_id=${currentClassGroup.class_group_id}&schedule_break_id=${data.schedule_breaks_id}`)
    .then(result => { 
      //Refresh class group schedule
      props.setScheduleStatus('Pending');
      updateGroupSchedule(true);
    })
    .catch(error => { console.error(error); throw error; });
  }
  
  function handleAfterXTripsChange(event) {
    let vl_after_x_trips = parseInt(event.target.value)
    if(isNaN(vl_after_x_trips) || vl_after_x_trips < 0){
      vl_after_x_trips = 0;
    }
    setAfterXTrips(vl_after_x_trips)
  }

  function insertBreaksAfterXTrips(event) {
    var selectedRows = scheduleBreaksGridRef.getSelectedRows(); //get selected rows
    if(selectedRows.length == 0){
      alertDialog({message: "Please select a break before adding break between trips."})
    }
    else if(!('class_group_id' in currentClassGroup)){
      alertDialog({message: "Please select class group."})
    }
    else{
      //add break to schedule
      loadingSpinnerBtnWait(event)
      axios.get(`${NEST_API_URL}/scheduler/insertschedulebreaksafterxtrips?show_id=${currentShowID}&customer_id=${customerID}&class_group_id=${currentClassGroup.class_group_id}&schedule_break_id=${selectedRows[0].schedule_breaks_id}&after_x_trips=${afterXTrips}`)
      .then(result => { 
        //Refresh class group schedule
        props.setScheduleStatus('Pending');
        updateGroupSchedule(true);
      })
      .catch(error => { console.error(error); throw error; })
      .finally(() =>{
          loadingSpinnerBtnRelease(event)
      });
    }
  }

  const removeClassGroupBreaks = async (event) => {
    if(!('class_group_id' in currentClassGroup)){
      alertDialog({message: "Please select class group."})
      return false;
    }

    //Confirm from user before calling webservice
    const choice = await confirmDialog({message: `Are you sure you want to delete Schedule Breaks in this Group?`})
    if(!choice){
        return false;
    }

    //Call webservice to remove schedule breaks for class group
    loadingSpinnerBtnWait(event)
    axios.delete(`${NEST_API_URL}/scheduler/removeallclassgroupschedulebreaks?show_id=${currentShowID}&customer_id=${customerID}&class_group_id=${currentClassGroup.class_group_id}`)
      .then(result => {
        // Refresh class group schedule
        props.setScheduleStatus('Pending');
        updateGroupSchedule(true);
      })
      .catch(error => { console.error(error); throw error; })
      .finally(() =>{
          loadingSpinnerBtnRelease(event)
      });
  }

  return (
    <>
        <div style={containerStyle}>
          <div className="ag-theme-alpine row">
            <div style={gridStyle} className='col grid-schedule-breaks pe-0'>
            <AgGridReact
              headerHeight={headerHeight}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowData={rowData}
              rowHeight={rowHeight}
              rowSelection={'single'}
              enableRangeSelection={false}
              onFirstDataRendered={onFirstDataRendered}
              rowDragEntireRow={true}
              rowDragManaged={true}
              suppressMoveWhenRowDragging={true}
              animateRows={true}
              onGridReady = {onGridReady}
              onCellValueChanged={onCellEdit}
              stopEditingWhenCellsLoseFocus={true}
            ></AgGridReact>
            </div>
            <div className='col-lg-1 w-lg-40px pe-0 ps-1'>
                <button type='button' onClick={addScheduleBreak} className="btn btn-sm btn-secondary fw-bold p-1" data-tooltip-id="SIMPLESCHEDULER.DETAIL.SECTION.SCHEDULEBREAKS.BUTTON.ADD">
                    <i className="fas fa-plus fs-5 px-1 py-3"></i>
                </button>
                <div className="separator my-1 border-transparent d-xl-block d-lg-block d-md-none d-sm-none d-none"></div>
                <button type='button' onClick={() => deleteScheduleBreak()} className='btn btn-sm btn-secondary fw-bold p-1' data-tooltip-id="SIMPLESCHEDULER.DETAIL.SECTION.SCHEDULEBREAKS.BUTTON.REMOVE">
                    <i className='fas fa-minus fs-5 px-1 py-3'></i>
                </button>
            </div>
          </div>
      </div>
      <div className='row mb-2 mt-3'>
        <div className='col-lg-5 col-form-label py-1 d-flex align-items-center justify-content-between mw-200px'>
          <label className='fs-6 fw-bold' htmlFor='APPLYBREAK' data-tooltip-id="SIMPLESCHEDULER.DETAIL.SECTION.SCHEDULEBREAKS.LABEL.BREAKAFTERXTRIPS">
              <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.APPLYBREAK'})}</span>
          </label>
          <input
              id='APPLYBREAK'
              className='form-control form-control-sm fs-6 min-h-20px py-1 w-40px'
              placeholder="0"
              value={afterXTrips}
              onChange={handleAfterXTripsChange}
              />
        </div>

        <div className='col d-flex justify-content-start align-items-center '>
            <button type='button' className='btn btn-sm btn-secondary fw-bold py-1 px-2 fs-8 me-2 min-h-30px' onClick={(e) => { insertBreaksAfterXTrips(e) }} data-tooltip-id="SIMPLESCHEDULER.DETAIL.SECTION.SCHEDULEBREAKS.BUTTON.INSERTBREAKS">
                <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                {intl.formatMessage({id: 'SCHEDULER.BUTTON.INSERTBREAK'})}
            </button>

            <button type='button' className='btn btn-sm btn-secondary fw-bold px-2 py-1 fs-8 min-h-30px' onClick={(e) => { removeClassGroupBreaks(e) }} data-tooltip-id="SIMPLESCHEDULER.DETAIL.SECTION.SCHEDULEBREAKS.BUTTON.REMOVEALLBREAKS">
                <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                {intl.formatMessage({id: 'SCHEDULER.BUTTON.DELETEBREAKS'})}
            </button>
        </div>
      </div>
    </>
  );
};

export default ScheduleBreaksGrid;
  