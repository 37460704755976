import { useEffect, useRef} from 'react';

const useTypeahead = (Keys, callBack) => {
    const callBackRef = useRef(callBack);
    useEffect(() => {
        callBackRef.current = callBack;
    }, []);

    useEffect(() => {
        let keyPressTimeout;
        let pressedKeys = [];

        function handleKeyDown(event) {
            if (Keys.includes(event.code)) {
                clearTimeout(keyPressTimeout);
                pressedKeys.push(event.code);

                // Immediately trigger the callback with the current pressed keys
                callBackRef.current(pressedKeys);

                keyPressTimeout = setTimeout(() => {
                    pressedKeys = [];
                }, 1000);
            }
        }

        // Attach the keydown event listener
        document.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener when the component unmounts
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [Keys]);
};

export default useTypeahead;
