
import { RingsWidget } from './RingsWidget';
const RingsTab = (props) => {
    return (
        <>
            <div className='row m-5 mt-0 p-0 mb-0'>
                <div className="col-12 ms-0 p-0 me-5 mb-5">
                    <RingsWidget />
                </div>
            </div>
        </>
    );
}
export {RingsTab}