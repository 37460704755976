import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setNewShowId } from '../../redux/reducers/newShowIdReducer';
import { useAlert, useFlashAlert } from '../../modules/sgl-utils/DialogsProvider';
import { useIntl } from 'react-intl';
import moment from 'moment';

const CalendarWidget = ({ isActiveTab }) => {
    const intl = useIntl();
    const [events, setEvents] = useState([]);
    const [showId, setShowID] = useState();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const calendarRef = useRef(null);
    const customerId = useAppSelector((state) => state.showCompany.company_id);
    const [isLoading, setIsLoading] = useState(true);
    const flashAlert = useFlashAlert()

    useEffect(() => {
        if (isActiveTab && calendarRef.current === null) {
            // Initialize the calendar only when the tab is active and calendar is not yet initialized
            const calendar = new Calendar(document.getElementById('calendar'), {
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                events: events,
                eventClick: handleClick,
                eventDidMount: handleEventDidMount,
                // eventDidMount: (info) => {
                //     info.el.addEventListener('dblclick', function () {
                //         handleDoubleClick(info);
                //     });
                // },
            });

            // Save the calendar instance to the ref
            calendarRef.current = calendar;

            // Render the calendar
            calendar.render();
            

            // Cleanup on component unmount or tab switch
            return () => {
                if (calendarRef.current) {
                    calendarRef.current.destroy();
                    calendarRef.current = null;
                }
            };
        }
    }, [events]);

    useEffect(() => {
        // Update events when the isActiveTab prop changes
        if (isActiveTab) {
            setIsLoading(true);
            axios
                .get(
                    process.env.REACT_APP_NEST_API_URL +
                    '/dashboard/getRecentAndUpcomingShows?customer_id=' +
                    customerId
                )
                .then((response) => {
                    let current_date = moment().format('YYYY-MM-DD HH:mm:ss');
                    const shows = response.data.map((show) => ({
                        id: show.show_id,
                        title: show.show_name,
                        start: show.start_date,
                        end: show.end_date,
                        color: (current_date >= show.start_date && current_date <= show.end_date) ? '#17c653' : '#fd9800',
                        show_id: show.show_id,
                    }));
                    setEvents(shows);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching events:', error);
                    setIsLoading(false);
                });
        }
    }, [isActiveTab, customerId]);

    const handleClick = (eventInfo) => {
        setShowID(eventInfo.event.extendedProps.show_id);
    };

    const handleEventDidMount = (info) => {
        const eventElement = info.el;
        eventElement.setAttribute('title', info.event.title);
    };

    // const handleDoubleClick = (eventInfo) => {
    //     dispatch(setNewShowId(eventInfo.event.extendedProps.show_id));
    //     flashAlert({ type: 'success', title: intl.formatMessage({id: 'DASHBOARD.SHOW.MESSAGE'}), message: eventInfo.event.title });
    //     //navigate('/entries');
    // };

    return (
        <div className="widget no-height-limit calendar card mb-0">
            {isLoading &&
                <>
                    <br/>
                    <span className="spinner-border spinner-border-sm m-5" role="status" aria-hidden="true"></span>
                </>
            }
            {!isLoading && <div id="calendar" className='m-5 mt-2'></div>}
        </div>
    );
};

export { CalendarWidget };
