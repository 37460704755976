/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import "flatpickr/dist/themes/material_blue.css";
import { Controller, useForm } from 'react-hook-form';
import { useAppSelector } from '../../redux/hooks'
import axios from 'axios'
import { useOutputContext } from '../../modules/output-listing/OutputListingContext'
import { NumericFormat } from 'react-number-format'
import Flatpickr from "react-flatpickr";
import { date_options, maskDateInput, setDate } from '../../modules/sgl-utils/fieldControls';
import moment from 'moment'; 
import ReactSelectComponent from '../../modules/sgl-utils/ReactSelectComponent'
import { searchOptions } from '../../modules/sgl-utils/reactSelectOptions'

type Props = {
  show: boolean
  onSubmitForm: ({}) => void,
  onClearFilter: () => void,
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SearchPaymentsForm = ({show, onSubmitForm, onClearFilter, handleClose}: Props) => {
  const intl = useIntl()
  const { register, reset, getValues, control, formState } = useForm({});
  const customer_id = useAppSelector(state=> state.showCompany.company_id);
  const currentShowID = useAppSelector(state => state.currentShow.show_id);
  const [paymentTypes, setPaymentTypes] = useState<any>([])
  const { setOutputIncludeAllShows } = useOutputContext();
  const context=useOutputContext()

  const handleReset = () => {
    reset();
  }
  
  const handleSave = async () => {
      let data = getValues();

      // remove prefix $ from amounts >>>
      if(data.amount_start && data.amount_start !== '' && isNaN(data.amount_start)){
        data.amount_start = Number(data.amount_start.replace('$', ''))
      }

      if(data.amount_end && data.amount_end !== '' && isNaN(data.amount_end)){
        data.amount_end = Number(data.amount_end.replace('$', ''))
      }
      // remove prefix $ from amounts <<<

			data.payment_types = []
			for (let counter = 0; counter < data?.payment_type_ids?.length; counter++) {
				const paymentType = paymentTypes.find((ele:any) => ele.value == data.payment_type_ids[counter])
				// Multiple payment gateways can have same type e.g., WI-Stripe, Staging-Stripe both are linked to one payment type of Stripe. To avoid duplicate values, we are using value as paymentType.sgl_id - paymentTypeXShowCompany.sgl_id for Gateway types. 
				// Parse the value to get payment type id
				if (paymentType) {
					data.payment_types.push({ ...paymentType, sgl_id: data.payment_type_ids[counter].split('-')[0] })
				}
			}

      onSubmitForm(data);
  }  

  useEffect(() => {
    if(show){
      reset(context.outputAdvanceFilter,{keepDefaultValues:true})
      //api call to get payment types meta
      axios.get(process.env.REACT_APP_NEST_API_URL + '/payments/advanceSearchMetaData', {
        params: {
          customer_id: customer_id,
          show_id: currentShowID
        },
      }).then((res) => {
        setPaymentTypes(res.data.paymentTypesList)
      }).catch((e) => {})
    }
  },[show])

  return createPortal(
      
    <Modal
      id='kt_modal_create_app'
      enforceFocus={false}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-650px search-form'
      show={show}
      onHide={handleClose}
      onKeyPress={(event:any) => { //replaced onKeyPress with onKeyDown
        let isButtonFocused = false
        let activeElement = document.activeElement //gets the currently focussed element
        if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
            isButtonFocused = true;
        }
        if (event.key === "Enter" && !isButtonFocused) {
          handleSave();
        }
        if (event.key === "Escape") {
          handleClose();
        }
      }}
    >
      <div className='modal-header py-0 px-4'>
        <h2 className="fs-4">{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.TITLE.FINDPAYMENTS'})}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-0 px-4'>
        <legend className="mt-2 fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PAYMENTS.GROUPLABEL.FINDPAYMENTS' })} <b></b></legend>   
        <form id='shows-filter-form' noValidate className='form' onSubmit={e => e.preventDefault()}>
        <div className='card-body p-0'>
           <div className='row mb-2'>
              <label className='col-lg-3 col-form-label  fw-bold fs-5 py-1' htmlFor='REFCODE'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.REFCODE'})}</label>

              <div className='col-lg-9 fv-row'>
                <input
                  id='REFCODE'
                  type='text'
                  {...register("refcode")}
                  className='form-control form-control-sm fs-6 min-h-20px py-1'
                  placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.REFCODE'})}
                  autoFocus
                  tabIndex={1}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='INVOICENUMBER'>
                <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.INVOICENUMBER'})}</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  id='INVOICENUMBER'
                  type='tel'
                  {...register("invoice_number")}
                  className='form-control form-control-sm fs-6 min-h-20px py-1'
                  placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.INVOICENUMBER'})}
                  tabIndex={2}
                />
              </div>
            </div>

            
            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='CREDITCART'>
                <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.CREDITCART'})}</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  id='CREDITCART'
                  type='tel'
                  {...register("credit_card")}
                  className='form-control form-control-sm fs-6 min-h-20px py-1'
                  placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.CREDITCART'})}
                  tabIndex={3}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='TRANSACTIONID'>
                <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.TRANSACTIONID'})}</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  id='TRANSACTIONID'
                  type='tel'
                  {...register("transaction_id")}
                  className='form-control form-control-sm fs-6 min-h-20px py-1'
                  placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.TRANSACTIONID'})}
                  tabIndex={4}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label  fw-bold fs-5 py-1' htmlFor='AMOUNTBETWEEN'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.AMOUNTBETWEEN'})}</label>

              <div className='col-lg-9 '  style={{display: 'flex', flexDirection: 'row' }}>
                <div className='col ps-0'>
                {/* <input
                    id='amount_start'
                    {...register("amount_start")}
                    type='number'
                    className='form-control form-control-sm mb-2 mb-lg-0  fs-6 min-h-20px py-1' 
                    placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.DOLLERZERO'})}
                /> */}
                    <Controller
                      name="amount_start"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <NumericFormat
                          {...field}
                          prefix={intl.formatMessage({ id: 'CURRENCY' })}
                          id="AMOUNTBETWEEN"
                          allowNegative={true}
                          decimalScale={2}
                          className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                          placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.DOLLERZERO' })}
                          tabIndex={5}
                        />
                      )}
                  />
                </div>
                <label className='col-form-label d-flex fs-5 fw-bold justify-content-center p-0 py-1 w-30px'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.AND' })}</label>
                <div className='col ps-0'>
                    {/* <input
                        type='number'
                        id="amount_end"
                        {...register("amount_end")}
                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.DOLLERZERO'})}
                    /> */}
                     <Controller
                      name="amount_end"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <NumericFormat
                          {...field}
                          prefix={intl.formatMessage({ id: 'CURRENCY' })}
                          allowNegative={true}
                          decimalScale={2}
                          id="amount_end"
                          className="form-control form-control-sm fs-6 min-h-20px py-1"
                          placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.DOLLERZERO' })}
                          tabIndex={6}
                        />
                      )}
                    />
                </div>
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label  fw-bold fs-5 py-1' htmlFor='ONDAYS'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.ONDAYS'})}</label>

              <div className='col-lg-9'  style={{display: 'flex', flexDirection: 'row' }}>
                  {/* <div className='col ps-0'>
                    <input
                      id='ONDAYS'
                      {...register("date_start")}
                      type='date'
                      className='form-control form-control-sm mb-2 mb-lg-0  fs-6 min-h-20px py-1' 
                      placeholder={intl.formatMessage({id: "FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.STARTDATE"})}
                    />
                  </div> */}
                  <div className='col pe-0'>
                  <div className="input-group date">
                      <Controller
                          name="date_start"
                          control={control}
                          render={({ field: { onChange, value, name } }) => {
                              const formattedValue = (value && moment(value).isValid())
                                  ? moment(value).format('MM/DD/YYYY') // Display format with four-digit year
                                  : '';

                              return (
                                  <Flatpickr
                                      className="form-control form-control-sm mb-lg-0 fs-6 min-h-20px py-1"
                                      id="ONDAYS"
                                      name={name}
                                      autoComplete="off"
                                      value={formattedValue}
                                      placeholder="MM/DD/YYYY"
                                      options={date_options}
                                      onClose={(value: any, dateStr: string, instance: { input: { value: string } }) => {
                                          const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                          if (!isValidDate) {
                                              instance.input.value = ''; // Clear the input if the date is invalid
                                          } 

                                          setDate(dateStr, onChange, null); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                      }}
                                      onChange={(value: any, dateStr: string, instance: { input: { value: string } }) => {
                                        const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                        if (!isValidDate) {
                                            instance.input.value = ''; // Clear the input if the date is invalid
                                        } 

                                        setDate(dateStr, onChange, null); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                      }}
                                      onInput={(e: any) => { maskDateInput(e) }} // Ensure input masking is applied
                                      tabIndex={7}
                                  />
                              );
                          }}
                      />
                      <div className="input-group-append">
                          <label htmlFor='ONDAYS' className='date-label'
                            onClick={(e) => {
                              e.stopPropagation();
                              const atModal = document.getElementById('ONDAYS') as HTMLElement & { _flatpickr?: { open: () => void } };
                              if (atModal?._flatpickr) {
                                  atModal._flatpickr.open();
                              }
                            }}
                          >
                              <span className="input-group-text">
                                  <i className="la la-calendar"></i>
                              </span>
                          </label>
                      </div>
                    </div>
                  </div>
                  <label className='col-form-label d-flex fs-5 fw-bold justify-content-center p-0 py-1 w-30px'>{intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.TO' })}</label>
                {/* <div className='col ps-0'>
                    <input
                      type='date'
                      id='date_end'
                      {...register("date_end")}
                      className='form-control form-control-sm  fs-6 min-h-20px py-1'
                      placeholder={intl.formatMessage({id: "FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.ENDDATE"})}
                    />
                  </div> */}
                  <div className='col ps-0'>
                    <div className="input-group date">
                        <Controller
                            name="date_end"
                            control={control}
                            render={({ field: { onChange, value, name } }) => {
                                const formattedValue = (value && moment(value).isValid())
                                    ? moment(value).format('MM/DD/YYYY') // Display format with four-digit year
                                    : '';

                                return (
                                    <Flatpickr
                                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                                        id="date_end"
                                        name={name}
                                        autoComplete="off"
                                        value={formattedValue}
                                        placeholder="MM/DD/YYYY"
                                        options={date_options}
                                        onClose={(value: any, dateStr: string, instance: { input: { value: string } }) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }
                                            setDate(dateStr, onChange, null); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                        }}
                                        onChange={(value: any, dateStr: string, instance: { input: { value: string } }) => {
                                          const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                          if (!isValidDate) {
                                              instance.input.value = ''; // Clear the input if the date is invalid
                                          }
                                          setDate(dateStr, onChange, null); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                        }}
                                        onInput={(e: any) => { maskDateInput(e) }} // Ensure input masking is applied
                                        tabIndex={8}
                                    />
                                );
                            }}
                        />

                        <div className="input-group-append">
                          <label htmlFor='date_end' className='date-label'
                            onClick={(e) => {
                              e.stopPropagation();
                              const atModal = document.getElementById('date_end') as HTMLElement & { _flatpickr?: { open: () => void } };
                              if (atModal?._flatpickr) {
                                  atModal._flatpickr.open();
                              }
                            }}
                          >
                            <span className="input-group-text">
                                <i className="la la-calendar"></i>
                            </span>
                          </label>
                        </div>
                      </div>
                  </div>
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='SELECTPAYMENTTYPE'>
                <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.SELECTPAYMENTTYPE'})}</span>
              </label>

              <div className='col-lg-9'>
                    <div className='row'>
                        <div className='col ps-3'>
                            <select
                            {...register( 'payment_type_ids',{ required: false })}
                                multiple
                                className='form-select  form-select-sm fs-6 min-h-20px py-1'
                                name='payment_type_ids'
                                id='SELECTPAYMENTTYPE'
                                tabIndex={9}
                            >
                              {paymentTypes.map((ele:any, i: number) => {
                                  return(
                                    <option value={ele.value} key={i} selected={context?.outputAdvanceFilter?.payment_type_ids && context?.outputAdvanceFilter?.payment_type_ids?.includes(ele.value)}>{ele.label}</option>
                                  )
                                })}
                            </select>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='paymentIds'>
                {intl.formatMessage({id: 'Form.AdvanceSearch.Payments.Label.PaymentIDs'})}
              </label>

              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col ps-3'>
                    <input
                      id='paymentIds'
                      type='text'
                      {...register("payment_ids")}
                      className='form-control form-control-sm fs-6 min-h-20px py-1'
                      placeholder={intl.formatMessage({id: 'Form.AdvanceSearch.Payments.Placeholder.PaymentIDs'})}
                      tabIndex={10}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-9 offset-lg-3 d-flex align-items-center'>
                <div className='form-check-sm form-check-custom me-2'>
                  <input
                      className='form-check-input'
                      {...register("exact_matches")}
                      type='checkbox'
                      id='exactMatches'
                      tabIndex={11}
                  />
                </div>

                <label className='col-form-label fw-bold fs-5 py-1' htmlFor='exactMatches'>
                  <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.COMMON.LABEL.EXACTMATCHES'})}</span>
                </label>
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='SEARCHBEHAVIOR'>
                <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.SEARCHBEHAVIOR'})}</span>
              </label>

              <div className='col-lg-9 '>
                <ReactSelectComponent 
                    name='search'
                    id='SEARCHBEHAVIOR'
                    options={searchOptions} 
                    control={control} 
                    methods={null} 
                    tabIndex={12}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label fw-bold fs-5 py-1' htmlFor='exactMatches'></label>

              <div className='col-lg-9 form-check-sm form-check-custom ps-lg-3'>
                {/* <div className='col-lg-0 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom'>
                    <input
                        className='form-check-input'
                        {...register("all_shows")}
                        type='checkbox'
                        id='allShows'
                    />
                    </div>
                </div>
                <label className='col-form-label mx-2 fs-5 fw-bold' htmlFor='allShows'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.ALLSHOWS'})}</label> */}

                <div className='col-lg-3 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom'>
                    <input
                        className='form-check-input'
                        {...register("web_add")}
                        type='checkbox'
                        id='webAdd'
                        tabIndex={13}
                    />
                    </div>
                    <label className='col-form-label mx-2 fs-5 fw-bold' htmlFor='webAdd'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.WEBADD'})}</label>
                </div>
                <div className='col-lg-0 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom'>
                    <input
                        className='form-check-input'
                        {...register("verified")}
                        type='checkbox'
                        id='verified'
                        tabIndex={14}
                    />
                    </div>
                    <label className='col-form-label mx-2 fs-5 fw-bold' htmlFor='verified'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.PAYMENTS.LABEL.VERIFIED'})}</label>
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-3 px-0'>
            <button type="button" className='btn btn-sm btn-secondary fw-bold' onClick={handleReset} tabIndex={14}>
              {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CLEARVALUES'})}
            </button>

            <button type="button" className='btn btn-sm btn-secondary me-4 fw-bold' style={{marginLeft:"auto"}} onClick={handleClose} tabIndex={15}>
              {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL'})}
            </button>

            <button type="button" className='btn btn-sm btn-dark fw-bold' onClick={handleSave} tabIndex={16}>
              {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.SEARCH'})}
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {SearchPaymentsForm}
