import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '../../../../redux/hooks';
import axios from 'axios';

// Custom Components
import { SearchInput } from '../SearchInput';
import { StableLookupForm } from './StableLookupForm';
import { useAlert } from '../../../../modules/sgl-utils/DialogsProvider';

const StableSection = (props) => {
    const intl = useIntl();
    const methods = useFormContext()
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const [showStableLookupForm, setShowStableLookupForm] = useState(false)
    const [isLocked, setIsLocked] = useState(true)
    const [stables, setStables] = useState([])
    const [searchTerm, setSearchTerm] = useState()
    const alertDialog = useAlert()

    const onBlur = (e) => {
        callSearchStableApi(e.target.value)
        setSearchTerm(e.target.value)
    }

    const onSearch = (e) => {
        if (e.key == 'Enter') {
            e.target.blur();
        }
    }

    function callSearchStableApi(searchTerm){
        if(searchTerm.trim()){
            axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/searchStable', {
                params: {
                    search_term: searchTerm,
                    customer_id: customerId,
                }
            }) 
            .then(result => { 
                if(result.data.success){
                    setStables(result.data.stables)
                    setShowStableLookupForm(true)
                } else if (result.data.error_message) {
                    alertDialog({
                        title: "error",
                        message: result.data.error_message,
                        icon: "ban"
                    })
                }
            })
        }
    }

    return (
        <>
            {showStableLookupForm && <StableLookupForm 
                show={showStableLookupForm} 
                handleClose={() => {methods.setValue('stable_search', '');setShowStableLookupForm(false)}}
                stables={stables}
                searchTerm={searchTerm}
                />
            }
            <fieldset className='mb-2'>
                <legend className="fs-5 fw-bold d-flex entries-search-legend">
                    <div className='d-flex align-self-center w-50px me-10'><label data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.STABLE.LABEL.STABLE`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.GROUPLABEL.STABLE' })}</label></div>
                    <SearchInput id={"stable_search_field"} fieldName={'stable_search'} containerClass='me-2 w-225px' onSearch={onSearch} onBlur={onBlur}/>
                </legend>
                <div className='row mb-2'>
                    <div className='col-lg-6'>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor='STABLENAME' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.STABLE.LABEL.NAME">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.STABLENAME' })}</label>
                            <div className='col px-0'>
                                <input
                                    {...methods.register("stable.name", 
                                        {
                                            required: false
                                        })
                                    }
                                    id='STABLENAME'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.STABLENAME' })}
                                    disabled={isLocked}
                                />
                            </div>
                            <div className='col-lg-1 w-lg-60px d-flex'>
                                <div className='col-lg-1 w-lg-20px d-flex align-items-center'>
                                    <i onClick={() => setIsLocked(!isLocked)} className={"fas fa-solid py-3" + (isLocked ? ' fa-lock' : ' fa-lock-open')}></i>
                                </div>
                                <div className='col-lg-1 w-lg-25px d-flex align-items-center'>
                                    {methods.getValues('entry.stable_id') > 0 &&
                                        (methods.getValues('stable') !== null ||
                                            (methods.getValues('stable') == null && methods.getValues('entry.entry_id') == 0)) ? (
                                        <i
                                            className="fa-sharp fa-solid fa-circle-info fa-xl py-3"
                                            style={{ color: '#578EBE' }}
                                            onClick={() => { props.callbackFromParent( methods.getValues('entry.stable_id'), methods.getValues('stable'), 'Stables', 'StableDetail' ); }}
                                        ></i>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor='STABLEADDRESS' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.STABLE.LABEL.ADDRESS">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.STABLEADDRESS' })}</label>
                            <div className='col ps-0'>
                                <input
                                    {...methods.register("stable.address", 
                                        {
                                            required: false
                                        })
                                    }
                                    id='STABLEADDRESS'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.STABLEADDRESS' })}
                                    disabled={methods.getValues('entry.stable_id') <= 0}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor='STABLECITY' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.STABLE.LABEL.CITY">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.STABLECITY' })}</label>
                            <div className='col ps-0'>
                                <input
                                    {...methods.register("stable.city", 
                                        {
                                            required: false
                                        })
                                    }
                                    id='STABLECITY'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.STABLECITY' })}
                                    disabled={methods.getValues('entry.stable_id') <= 0}
                                />
                            </div>
                            <label className='col-lg-1 col-form-label fs-5 py-1 px-0 d-flex justify-content-center w-lg-45px' htmlFor='STABLEPHONE' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.STABLE.LABEL.PHONE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.STABLEPHONE' })}</label>
                            <div className='col'>
                                <input
                                    {...methods.register("stable.telephone", 
                                        {
                                            required: false
                                        })
                                    }
                                    id='STABLEPHONE'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.STABLEPHONE' })}
                                    disabled={methods.getValues('entry.stable_id') <= 0}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor='STABLESTATE' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.STABLE.LABEL.STATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.STABLESTATE' })}</label>
                            <div className='col ps-0'>
                                <input
                                    {...methods.register("stable.state", 
                                        {
                                            required: false
                                        })
                                    }
                                    id='STABLESTATE'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.STABLESTATE' })}
                                    disabled={methods.getValues('entry.stable_id') <= 0}
                                />
                            </div>
                            <label className='col-lg-1 col-form-label fs-5 py-1 px-0 d-flex justify-content-center w-lg-45px' htmlFor='STABLEZIP' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.STABLE.LABEL.ZIP">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.STABLEZIP' })}</label>
                            <div className='col'>
                                <input
                                    {...methods.register("stable.zip", 
                                        {
                                            required: false
                                        })
                                    }
                                    id='STABLEZIP'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.STABLEZIP' })}
                                    disabled={methods.getValues('entry.stable_id') <= 0}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-100px' htmlFor='FOODCARDNUM' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.STABLE.LABEL.FOODCARD">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.FOODCARDNUM' })}</label>
                            <div className='col ps-0'>
                                <input
                                    {...methods.register("entry.card_no", 
                                        {
                                            required: false
                                        })
                                    }
                                    id='FOODCARDNUM'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.FOODCARDNUM' })}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-form-label fs-5 py-1 cursor-pointer' htmlFor='NOTESREQUESTS' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.STABLE.LABEL.NOTES">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.NOTESREQUESTS' })}</label>
                            <div>
                                <textarea
                                    {...methods.register("entry.stablerequest", 
                                        {
                                            required: false
                                        })
                                    }
                                    id="NOTESREQUESTS"
                                    rows={3}
                                    className='form-control h-lg-75px form-control-sm fs-6'
                                    disabled={methods.getValues('entry.stable_id') <= 0 || true} // AR - added "|| true" as KU suggested to keep it always disabled - keeping existing condition for reference
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </>
    );
}

export { StableSection }