import { AgGridReact } from "ag-grid-react"
import { useEffect, useMemo, useRef, useState } from "react";
import { useSchoolingRoundsContext } from "./SchoolingRoundsContext";
import TimeRenderer from "../../../modules/output-listing/renderers/TimeRenderer";
import moment from "moment";
import { defaultColDef } from "../../../modules/sgl-utils/agGridHelpers";
import { useAlert, useConfirm, useLoadingOverlay } from "../../../modules/sgl-utils/DialogsProvider";
import axios from "axios";
const BASE_URL = process.env.REACT_APP_NEST_API_URL

const LapsedTimerRenderer = (params) => {
    const [timeElapsed, setTimeElapsed] = useState(moment().subtract(params.data.lastTrip, 'seconds'));
    const timerRef = useRef(null)

    useEffect(() => {
        setTimeElapsed(moment().subtract(params.data.lastTrip, 'seconds'))
    }, [params.data.trips])

    useEffect(() => {
        clearTimeout(timerRef.current)
     
        timerRef.current = setInterval(() => {
            setTimeElapsed(moment().subtract(params.data.lastTrip, 'seconds'));
        }, 1000);
      
        return () => { clearTimeout(timerRef.current)  }
    }, [])

    return <>{ timeElapsed.format('HH:mm:ss') }</>
}

const FinishedRenderer = (params) => {
    const { gridRef, setNumEntries } = useSchoolingRoundsContext()
    const confirmDialog = useConfirm()

    return (
        <span className="text-danger" onClick={async () => {
            let nodeToDelete = null
            gridRef.forEachNode(node => {
                if (node.data.sgl_id === params.data.sgl_id) {
                    nodeToDelete = node
                }
            });

            if (nodeToDelete) {
                if (await confirmDialog({ message: `Are you sure you want to mark trip of Entry #${nodeToDelete.data.number} - ${nodeToDelete.data.horse} as finished?`})) {
                    gridRef.applyTransaction({ 
                        remove: [nodeToDelete.data]
                    }) 
                    setNumEntries(prevNumEntries => prevNumEntries - 1)
                }
            }
        }}>
            Finished
        </span>
    )
}

const HorseRenderer = params => {
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const { customerId, currentShow, gridRef, schoolingShowFee } = useSchoolingRoundsContext()
    const loadingOverlay = useLoadingOverlay()

    const handleClick = async () => {
        loadingOverlay({ show: true, message: 'Adding Schooling Round..'})

        let incrementTripCounter = true
        const currentTime = moment()
        const lastTrip = moment(params.data.lastTrip, 'HH:mm:ss');
        // Add 120 seconds to the last trip time
        lastTrip.add(120, 'seconds');

        if (lastTrip.isAfter(currentTime)) { // There is still time left in 2 minutes
            incrementTripCounter = await confirmDialog({ message: 'It has been less than two minutes since the last schooling round was added to this entry.  Add anyway?'})
        }

        if (incrementTripCounter) {
            try {
                const response = await axios.get(`${BASE_URL}/ingate-announcer/addSchoolingRoundEntry`, {
                    params: { 
                        customer_id: customerId,
                        show_id: currentShow.show_id,
                        entry_number: params.data.number,
                        schooling_show_fees_id: schoolingShowFee.showfees_id
                    }
                })

                if (response.data.success) {
                    let nodeToUpdate = null
                    gridRef.forEachNode(node => {
                        if (node.data.sgl_id === params.data.sgl_id) {
                            nodeToUpdate = node
                        }
                    });
        
                    if (nodeToUpdate) {
                        nodeToUpdate.setDataValue('trips', params.data.trips + 1);
                        nodeToUpdate.setDataValue('lastTrip',  moment().format('HH:mm:ss'))
                    }
                }
            }  catch (reason) {
                if (reason?.response?.data?.message) {
                    alertDialog({ message: reason?.response?.data?.message })
                } else {
                    alertDialog({ message: 'Something went wrong..' })
                }
            }
        }
        loadingOverlay({ show: false, message: 'Adding Schooling Round..'})
    }

    return (
        <span className="text-info" onClick={handleClick}>
            { params.data.horse }
        </span>
    )
}

const RoundTripsGrid = () =>{
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const containerStyle = useMemo(() => ({ width: '100%', height: '70vh' }), []);
    const { setGridRef } = useSchoolingRoundsContext()

    const columnDefs = [
        {
            headerName: 'Entry',
            field: 'number',
            maxWidth: 100,
            cellClass: 'text-center',
            sortable: true
        },
        {
            headerName: 'Horse',
            flex: 1,
            cellRenderer: HorseRenderer,
            cellClass: 'text-center cursor-pointer',
            field: 'horse',
            sortable: true
        },
        {
            headerName: 'Trips',
            field: 'trips',
            maxWidth: 110,
            cellClass: 'text-center',
            sortable: true
        },
        {
            headerName: 'Last Trip',
            field: 'lastTrip',
            cellRenderer: TimeRenderer,
            maxWidth: 150,
            cellClass: 'text-center'
        },
        {
            headerName: 'Lapsed',
            field: 'lapsed',
            cellRenderer: LapsedTimerRenderer,
            maxWidth: 150,
            cellClass: 'text-center'
        },
        {
            headerName: 'Finished',
            flex: 1,
            cellRenderer: FinishedRenderer,
            cellClass: 'text-center cursor-pointer'
        },
    ]

    return(
        <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact 
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    rowData={[]}
                    onGridReady={params => setGridRef(params.api)}
                    suppressDragLeaveHidesColumns={true}
                />
            </div>
        </div>
    )
}


export default RoundTripsGrid