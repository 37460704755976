import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { useAppSelector } from '../../../redux/hooks';
import { useState } from 'react';
import { useAlert, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import { KTSVG } from '../../../../_metronic/helpers';
import { downloadTextFile } from '../../../modules/sgl-utils/SglFunctions';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';
import moment from 'moment';
import { PatternFormat } from 'react-number-format';

// Constants
const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const Export1099Report = ({ show, handleClose }) => {
    const intl = useIntl()
    const [year, setYear] = useState(moment().subtract(1, 'years').format('YYYY'))  // default year is current year -1 
    const [taxRateId, setTaxRateId] = useState('')
    const customerID = useAppSelector(state => state.showCompany.company_id)
    const currentShowID =  useAppSelector(state => state.currentShow.show_id)
    const alertDialog = useAlert()
    const { getOutputSelectionAreaIDs, getEventID } = useOutputContext();
    const {progressBar} = useProgress()
    const loadingOverlay = useLoadingOverlay();

    // Export 1099 report
    const export1099Report = async () => {
        loadingOverlay({ show: true, message: 'Fetching Records...' })
        let peopleIds = await getOutputSelectionAreaIDs();
        loadingOverlay({ show: false, message: 'Fetching Records...' })

        if (peopleIds.length === 0) { return false; };

        let eventID = getEventID('export-1099-report');
        progressBar({ show: true, eventID, timeElapsed: true, title: 'Export 1099 Report' })

        handleClose();
        axios.post(`${NEST_API_URL}/people/export1099Report`, {
            customer_id: customerID,
            year: year,
            show_tax_id: taxRateId,
            record_ids: peopleIds,
            show_id: currentShowID,
            progress_event_id: eventID,
        })
        .then(async response => {
            if(response.data.success){
                downloadTextFile(response.data.document, response.data.fileName);
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: intl.formatMessage({ id: 'GENERIC.ERROR.MESSAGE' })});
            }
        })
        .finally(() => {
            progressBar({ show: false, eventID })
        })
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(e) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (e.key === "Enter" && !isButtonFocused) {
                    export1099Report()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'PEOPLE.QUICKACTION.1099EXPORT.POPUP.HEADING' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            {/* Body */}
            <div className='modal-body py-3 px-4'>
                <div className='row mb-2'>
                    <label className='col-lg-5 col-form-label fs-5 py-1 w-lg-140px w-lg-140px' htmlFor='year'>{intl.formatMessage({ id: 'PEOPLE.QUICKACTION.1099EXPORT.POPUP.LABEL.YEARFORREPORT' })}</label>
                    <div className='col-lg-7'>
                        <input 
                            type='text' 
                            id='year' 
                            className='form-control form-control-sm fs-6 h-30px py-1' 
                            value={year} 
                            onChange={e => setYear(e.target.value)} 
                            autoFocus
                            tabIndex={1}
                        />
                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-5 col-form-label fs-5 py-1 w-lg-140px w-lg-140px' htmlFor='taxRateId'>{intl.formatMessage({ id: 'PEOPLE.QUICKACTION.1099EXPORT.POPUP.LABEL.SHOWTAXIDOPTIONAL' })}</label>
                    <div className='col-lg-7'>
                        <PatternFormat
                            id='taxRateId'
                            format="##-#############"
                            value={taxRateId == "" ? "" : Number(taxRateId)}
                            onValueChange={e => 
                                {console.log(e.value)
                                setTaxRateId(e.value)}
                            }
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            tabIndex={2}
                        />
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-3 px-0'>

                    <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={3}>
                        {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.1099EXPORT.POPUP.BUTTON.CANCEL' })}
                    </button>

                    <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={export1099Report} tabIndex={4}>
                        <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                        {intl.formatMessage({ id: 'PEOPLE.QUICKACTION.1099EXPORT.POPUP.BUTTON.OK' })}
                    </button>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default Export1099Report;
