  
  export const getExtensionOnly=(filename)=> {
    return filename.split('.').pop()
  } 

  export const getTimeStampOnly = (filename) =>{
    const parts = filename.split("_");
    filename = parts[1]; 
    var parts2 = filename.split(".");
    filename= parts2[0]; 
    return filename;
  } 

  // Note: these functions can be used to upload image to S3 for ckedior. Use them in file where ckeditor is used
  // function uploadAdapter(loader) {
  //   return {
  //       upload: () => {
  //           return new Promise((resolve, reject) => {
  //               const timeStamp = ((new Date().getTime()).toString())
  //               const formData = new FormData();
  //               loader.file.then((file) => {
  //                   const fileInformation = "assets/master_fees/" + methods.getValues('master_fee.sgl_id')+"_"+timeStamp+"."+getExtensionOnly(file.name)
  //                   formData.append('file', file);
  //                   formData.append('fileInformation', fileInformation);
  //                   formData.append('customer_id', 15);
  //                   axios.post( process.env.REACT_APP_NEST_API_URL + `/master-fees/upload?customer_id=${15}`, formData
  //                     ).then((response) => {
  //                         if(response.data.success) {
  //                           resolve({ default: `${response.data.image_url}` })
  //                         }
  //                     }).catch((reason) => {
  //                         let errors = reason.response.data.response.errors.join("<br/>")
  //                         //alertDialog({message: errors, title: 'warning'})
  //                     })
  //               })
  //           })
  //       }
  //   }
  // }

  // function uploadPlugin(editor) {
  //     editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
  //         return uploadAdapter(loader);
  //     }
  // }
  