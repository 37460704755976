import React, {useState, useEffect} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import ErrorGrid from './ErrorsGrid'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAppSelector } from "../../../../redux/hooks"
import axios from 'axios'
import { useOutputContext, useOutputContextUpdater } from '../../../../modules/output-listing/OutputListingContext'
import { useAlert, useProgress } from '../../../../modules/sgl-utils/DialogsProvider'
import { downloadTextFile, getCurrentDateTimeFormatted } from '../../../../modules/sgl-utils/SglFunctions'

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
 
const MoveEntriesToShowVerification = ({show, handleClose, verificationData, entryIds, selectedShow}) => {
    const intl = useIntl() 
    const currentShowName = useAppSelector(state => state.currentShow.show_name);
    const currentShowId = useAppSelector(state => state.currentShow.show_id)
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const {getEventID} = useOutputContext()
    const outputContextUpdater = useOutputContextUpdater()
    const {progressBar} = useProgress()
    const alertDialog = useAlert();

    const moveEntries = () =>{
        // show progress bar
        handleClose()
        let eventID = getEventID("move-entries-to-show")
        progressBar({ show: true, eventID, showProgress: 'inline', title: intl.formatMessage({ id: "ENTRIES.MOVEENTRIESFROMSHOW.PROGRESSBAR.MESSAGE" })+selectedShow.show_name })
        axios.patch(`${NEST_API_URL}/entries/moveEntriesToShow`, {
            customer_id: customerId,
            entry_ids: entryIds,
            source_show_id: currentShowId,
            destination_show_id: selectedShow.show_id,
            event_id: eventID
        })
        .then(response => {
            if(response?.data?.success){
                outputContextUpdater({action: 'unselectAll'})
                let alertMessage = '';
                if(response?.data?.numEntriesNotMoved > 0){
                    alertMessage += response?.data?.numEntriesNotMoved+intl.formatMessage({ id: 'ENTRIES.MOVEENTRIESFROMSHOW.ENTRIESNOTMOVED.MESSAGE' })+selectedShow?.show_name + '.';
                }

                if (response?.data?.entriesConflictedByHorseRiderMessage) {
                    // Add \n if there is already a message
                    if (alertMessage != "") {
                        alertMessage += '<br>';
                    }
                    let conflictMessage = response?.data?.entriesConflictedByHorseRiderMessage;

                    // Replace (SHOW_NAME) with the selected show name
                    conflictMessage = conflictMessage.replace('(SHOW_NAME)', selectedShow?.show_name);

                    alertMessage += conflictMessage;
                }

                if(alertMessage != ''){
                    alertDialog({message: alertMessage});
                }

               

            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            progressBar( {show: false, eventID} )
            outputContextUpdater({action: 'refresh'})
        })
    }

    const exportErrors = () =>{
        if(verificationData.error_messages.length>0){
            var tabSeparatedData = verificationData.error_messages.map((item) => 
                `${item.error}`).join('\n');
       
            let doc_name = 'Move_Entries_From_Show_'+currentShowName.replace(/ /g, "_")+getCurrentDateTimeFormatted()+'.txt'
            downloadTextFile(tabSeparatedData, doc_name)
        }
    }

    return createPortal(
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-800px search-form'
                show={show}
                onHide={handleClose}
                onKeyDown={(event) => {
                    let isButtonFocused = false
                    let activeElement = document.activeElement //gets the currently focussed element
                    if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                        isButtonFocused = true;
                    }
                    if (event.key === "Enter" && !isButtonFocused) {
                        verificationData.error_messages.length > 0 ? exportErrors() : moveEntries()
                    }
                }}
            >
                <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-145px { width: 145px; }}"}</style>
                <div className='modal-header py-0 px-4'>
                    <h2 className="fs-4">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.MOVEENTRIESFROMSHOWVERIFICATION' })}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
    
                <div className='modal-body py-3 px-4'>
                    <div className='row mb-2'>
                        <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold' htmlFor='NOOFENTRIES'>{intl.formatMessage({ id: "ENTRIES.MOVEENTRIESFROMSHOWVERIFICATION.MODAL.LABEL.SOURCESHOW" })}</label>
                        <label className='col-lg-10 col-form-label fs-5 py-1' htmlFor='NOOFENTRIES'>{currentShowName}</label>
                    </div>

                    <div className='row mb-2 ms-0 me-0 d-flex' style={{justifyContent: 'space-between'}}>
                        <div className='col-auto p-0'>
                            <div className='d-flex align-items-center'>
                                <label className='col-form-label fs-6 py-1 fw-bold me-2' htmlFor='NOOFENTRIES'>{intl.formatMessage({ id: "ENTRIES.MOVEENTRIESFROMSHOWVERIFICATION.MODAL.LABEL.NOOFENTRIES" })}</label>
                                <input
                                    id='NOOFENTRIES'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input w-50px'
                                    value={verificationData.noOfEntries}
                                    placeholder='0.00'
                                    inputmode="numeric"
                                    disabled
                                />
                            </div>
                        </div>

                        <div className='col-auto'>
                            <div className='d-flex align-items-center'>
                                <label className='col-form-label fs-6 py-1 fw-bold me-2' htmlFor='NOOFDIVISIONS'>{intl.formatMessage({ id: "ENTRIES.MOVEENTRIESFROMSHOWVERIFICATION.MODAL.LABEL.NOOFDIVISION" })}</label>
                                <input
                                    id='NOOFDIVISIONS'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input w-50px'
                                    value={verificationData.noOfSourceShowDivisions}
                                    placeholder='0.00'
                                    inputmode="numeric"
                                    disabled
                                />
                            </div>
                        </div>

                        <div className='col-auto'>
                            <div className='d-flex align-items-center'>
                                <label className='col-form-label fs-6 py-1 fw-bold me-2' htmlFor='NOOFCLASSES'>{intl.formatMessage({ id: "ENTRIES.MOVEENTRIESFROMSHOWVERIFICATION.MODAL.LABEL.NOOFCLASSES" })}</label>
                                <input
                                    id='NOOFCLASSES'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input w-50px'
                                    value={verificationData.noOfSourceShowClasses}
                                    placeholder='0.00'
                                    inputmode="numeric"
                                    disabled
                                />
                            </div>
                        </div>

                        <div className='col-auto p-0'>
                            <div className='d-flex align-items-center'>
                                <label className='col-form-label fs-6 py-1 fw-bold me-2' htmlFor='NOOFFEES'>{intl.formatMessage({ id: "ENTRIES.MOVEENTRIESFROMSHOWVERIFICATION.MODAL.LABEL.NOOFFees" })}</label>
                                <input
                                    id='NOOFFEES'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input w-50px'
                                    value={verificationData.noOfSourceShowFees}
                                    placeholder='0.00'
                                    inputmode="numeric"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>



                    <ErrorGrid data={verificationData.error_messages} tabIndex={1}/>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={handleClose} tabIndex={2}>
                            {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CANCEL' })}
                        </button>

                        { verificationData.error_messages.length > 0 ?
                            <button type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={exportErrors} tabIndex={3}>
                                <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                {intl.formatMessage({ id: "ENTRIES.MOVEENTRIESFROMSHOWVERIFICATION.FORM.INPUT.COMMON.BUTTON.EXPORTERRORS"})}
                            </button> :
                            <button type="button" className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={() => moveEntries()} tabIndex={3}>
                                <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                {intl.formatMessage({ id: 'ENTRIES.MOVEENTRIESFROMSHOWVERIFICATION.FORM.INPUT.COMMON.BUTTON.MOVEENTRIES' })}
                            </button>
                        }
                    </div>
                </div>
      </Modal>
      ,modalsRoot
    )
}

export default MoveEntriesToShowVerification