import { useIntl } from 'react-intl'
import { useAppSelector } from '../../redux/hooks';
import axios from 'axios';
import { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider} from "react-hook-form";
import { useAlert, useLoadingOverlay} from '../../modules/sgl-utils/DialogsProvider';
import { useNavigate } from 'react-router-dom';

// Custom Components
import { GeneralTab } from './Detail/GeneralTab';
import { HistoryTab } from '../../modules/components/HistoryTab';
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions';
import { PageTitle } from '../../../_metronic/layout/core'
import history from '../../modules/sgl-utils/unstableHistory';
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt';
import { ProductInfoTab } from './Detail/ProductInfoTab';
import useAutoFocus from '../../modules/hooks/use-auto-focus';

const { PUBLIC_URL } = process.env

export const dirtyValues = (
    dirtyFields,
    allValues
  ) => {
    // NOTE: Recursive function.
  
    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
      return allValues;
    }
  
    // Here, we have an object.
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [
        key,
        dirtyValues(dirtyFields[key], allValues[key])
      ])
    );
  };

const MasterfeeDetail = (props) => {
    const intl = useIntl();
    const [form_meta, setFormMeta] = useState({})
    const {master_fee_id} = useParams();
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const[loading,setLoading] = useState(true)
    const alertDialog = useAlert()
    const navigate = useNavigate();
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const loadingOverlay = useLoadingOverlay()

    const methods = useForm({
        defaultValues: 
        {
            "master_fee": {
                "sgl_id": 0,
                "master_fee_id": 0,
                "name": "",
                "description": "",
                "creation_date": "",
                "creation_time": "",
                "sync_id": "",
                "customer_id": 0,
                "master_fee_uuid": "",
                "feed_delivery_email": "",
                "category": "",
                "default_price": 0,
                "physical_delivery": 0,
                "default_tax_rate_id": 0,
                "default_tax_rate": 0,
                "default_qty": 0,
                "web_default_qty": 0,
                "cost": 0.00,
                "tax_rate_id": 0,
                "default_show_inventory_cap": 0,
                "stablefee": 0,
                "directuserordering": 0,
                "feed_bedding_fee": 0,
                "appearonstatement": 0,
                "web_deposit_required": 0,
                "dont_delete_forscratchentry": 0,
                "pass_through": 0,
                "card_fee": 0,
                "active": 1,
                "abbreviation": "",
                "donation": 0, 
                "web_summary": "",
                "web_description": ""
              },
              "fee_assigned": [],
              "class_rule": [], 
              "files": [],
              "file":"",
              "images":"",
              "deletedImage":"",
              "showsToAddMasterFee": [],
              "fees_deleted": []
        }
      }); 

    useEffect(() => {
        // Show loading overlay
        loadingOverlay({show: true})
        axios.all(
        [
          axios.get( process.env.REACT_APP_NEST_API_URL + '/master-fees/detail', {
            params: {
              master_fee_id: master_fee_id,
              customer_id: customer_id,
            }
          }),
          axios.get( process.env.REACT_APP_NEST_API_URL + '/master-fees/MetaData', {
            params: {
                show_id: currentShowID ,
                customer_id: customer_id,
                master_fee_id: master_fee_id ,
            }
          }),
        ]  
        ).then (
          axios.spread( ({data : masterFeeData},{data : masterFeeMetaData}) => {
            //Check if record id is invalid then redirect to list page
            if ((masterFeeData || masterFeeData === '') && isInvalidRecord(masterFeeData?.master_fee, master_fee_id, 'master_fee_id')){
              navigate('/masterfees')
              loadingOverlay({show: false})
            }
            setFormMeta(masterFeeMetaData)
            reset(masterFeeData)
      
            // Hide loading overlay
			      loadingOverlay({show: false})
            setLoading(false)
          })
        )
    },[master_fee_id])

    const { handleSubmit, reset, formState, setFocus} = methods
    const { dirtyFields } = formState; 
    useNavigationPrompt(formState)
    const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
    const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);

    const tabs = ["GENERAL","PRODUCTINFO", "HISTORY"]
    const componentTags = [
        <GeneralTab form_meta={form_meta} />, 
        <ProductInfoTab/>,
        <HistoryTab area={"MasterFees"} rowData={methods.getValues('change_history_logs')}/>
    ]
    const [selectedTab, setSelectedTab] = useState(() => {
      const hash = window.location.hash.substring(1);
      return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
    });

    useEffect(() => {
      const next_button = document?.getElementById('next-btn');
      const last_button = document?.getElementById('last-btn');
      const prev_button = document?.getElementById('prev-btn');
      const first_button = document?.getElementById('first-btn');
      if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
        next_button.disabled = true;
        last_button.disabled = true;
      }
      if (row_selected == 0 && prev_button && first_button) {
        prev_button.disabled = true;
        first_button.disabled = true;
      }
    }, [loading])

    const onSubmit = async (form_data) => {
        const next_button = document.getElementById('next-btn');
        const last_button = document.getElementById('last-btn');
        const prev_button = document.getElementById('prev-btn');
        const first_button = document.getElementById('first-btn');

        // means detail page is not open in modal
        if(props?.id === undefined){
          next_button.disabled = false;
          prev_button.disabled = false;
          last_button.disabled = false;
          first_button.disabled = false;
        }

        if(master_fee_id == 0 && !dirtyFields?.master_fee?.hasOwnProperty('active')){
          methods.setValue('master_fee.active', true, {shouldDirty:true})
        }
        let submit_data = dirtyValues(dirtyFields, form_data)
        loadingOverlay({show: true})
        return axios.post( process.env.REACT_APP_NEST_API_URL + '/master-fees/detail', {
          params: {
            master_fee_id: master_fee_id,
            customer_id: customer_id,
            show_id: currentShowID,
            data: submit_data
          }
        }).then((response) => {
          loadingOverlay({show: false})
          if(response.data.success) {
            // Use Case (Param Id changed): Next/Prev buttons
            if(props?.id == undefined && Number(methods.getValues('master_fee.master_fee_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
              if(row_selected && row_selected != "" && row_ids[row_selected]){
                setLoading(true)
                history.push(`${PUBLIC_URL}/masterfees/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
              }
            }
						else if(props?.id == undefined && master_fee_id > 0){
              history.push(`${PUBLIC_URL}/masterfees`, { allowNavigation: true })
            }
            else if(props?.id == undefined && master_fee_id == 0){
              history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
            }
            if( row_selected == row_ids?.length -1 && props?.id === undefined){
              if (next_button && last_button) {
                  next_button.disabled = true;
                  last_button.disabled = true;
                  prev_button.disabled = false;
                  first_button.disabled = false;
              }
            }
            if(row_selected == 0 && props?.id === undefined){
              if (prev_button && first_button) {
                  prev_button.disabled = true;
                  first_button.disabled = true;
                  next_button.disabled = false;
                  last_button.disabled = false;
              }
            }
        } else {
            alertDialog({message: response.data.error_message, title: 'Error'})
        }
        })
    }

    useAutoFocus('NAME', loading)

    return (
        <>
        {!loading && (
			  <>
        {props?.id == undefined ? <PageTitle breadcrumbs={[]} description={[methods.watch('master_fee.name'), methods.getValues('master_fee.master_fee_id')]}>{intl.formatMessage({ id: 'MENU.MASTERFEES' })}</PageTitle> : ""}
        <FormProvider {...methods}>
            <div id={"masterfees_detail_page"} className='modal-body py-3 px-4 masterfee-input-form input-form'>
                <form noValidate className='form max-width' onSubmit={handleSubmit(onSubmit)} 
                onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }
                >
                    <div className='card-body p-0'> {/* Card Body */}
                        <div className="form-group row">
                            <div className='d-flex flex-column my-2 border p-2 bg-white'>
                                <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
                                                    <a className={"nav-link text-active-dark py-1 px-10 text-gray-700" + (index == selectedTab ? ' active' : '')}
                                                        tabIndex="-1" // to make the tab links non-focusable
                                                        id={tab + "-tab"}
                                                        data-bs-toggle='tab'
                                                        href={"#" + tab}>{intl.formatMessage({ id: 'FORM.INPUT.MASTERFEES.TAB.' + tab })}
                                                    </a>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>

                                <div className="tab-content mt-5 pb-2" id="myTabContent">
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab} role="tabpanel" >
                                                    {componentTags[index]}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div> {/* End Card Body */}
                    </div> {/* End Card Body */}

                    <InputFormFooter goBackPath={'/masterfees'} data={'master_fee'}/>
                </form>
            </div>
            </FormProvider>
            </>
    		)}
        </>
    );
}

export { MasterfeeDetail }