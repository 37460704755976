import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useSchedulerContext } from './SchedulerContext';

const ClassesGrid = (props) => {
  const intl = useIntl();
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const containerStyle = useMemo(() => ({ width: '100%', height: '144px' }), []);
  const rowHeight = 28;
  const headerHeight = 30;
  
  const {setClassesGridRef, currentClassGroup} = useSchedulerContext();
  const [rowData, setRowData] = useState([]);

  //Grid Classes
  const columnDefs = [
    { field: 'Class.number', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.CNUMBER'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center'}, maxWidth: 100  ,headerClass: 'ag-center-aligned-header'  },
    { field: 'Class.name', headerName: intl.formatMessage({id: 'SCHEDULER.GRID.LABEL.CNAME'}), sortable: false, suppressMenu: true,cellStyle: {fontSize: '13px'}  },
  ]

  useEffect(() => {
    let classesData = [];
    if(currentClassGroup){
      if("ClassGroupxclasses" in currentClassGroup){
        classesData = currentClassGroup.ClassGroupxclasses;        
      }
    }
    setRowData(classesData);
  }, [currentClassGroup]);

  const onFirstDataRendered = useCallback((params) => {
      params.api.sizeColumnsToFit();
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: false,
      editable: false,
      wrapHeaderText: true
    };
  }, []);

  const onGridReady = useCallback((params) => {
    //set new grid api in scheduler context
    setClassesGridRef(params.api);
  }, []);

  return (
    <>
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          headerHeight={headerHeight}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowData}
          rowHeight={rowHeight}
          onFirstDataRendered={onFirstDataRendered}
          onGridReady = {onGridReady}
          overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while class list is loading.</span>'}
        ></AgGridReact>
      </div>
    </div>
    </>
  );
};

export default ClassesGrid;
  