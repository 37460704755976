import { useIntl } from 'react-intl'
import Select from 'react-select'
import { reactSelectStyles,} from '../../../modules/sgl-utils/fieldControls'
import {useFormContext, Controller} from 'react-hook-form'
import { NumericFormat} from 'react-number-format';
// Assets
import "flatpickr/dist/themes/material_blue.css"
import './tab.css'
import { useAppSelector } from '../../../redux/hooks';


const GeneralTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext()
    const watchDirectUserOrdering = methods.watch("showfees.directuserordering");
    const watchWebDefaultQty = methods.watch("showfees.web_default_qty");
    const showFinancialsLocked = methods.watch('show.islocked')

    const isMasterFeeEnabled = (methods.getValues('master_fee.master_fee_id') > 0 && methods.getValues('show.masterfees_changeenabled'))
    const MAX_VAL = 9999;
    const withValueCap = (inputObj) => {
      const { value } = inputObj;
      if (value <= MAX_VAL) return true;
      return false;
    };

    return (
        <>
            <div className='form-group showfee-tab row mb-2'>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-155px' htmlFor='CATEGORY' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.CATEGORY">{intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.CATEGORY' })}</label>
                    <div className='col-lg-4'>
                        {props.form_meta.fee_category && (
                            <Controller
                                name='showfees.category'
                                render={({ field: { onChange, value, name } }) => {
                                    const selectedOption = props.form_meta.fee_category.find((option) => option.value === value);
                                    return (
                                    <Select
                                        options={props.form_meta.fee_category}
                                        value={selectedOption || null} // Use null as the fallback if no option matches the value
                                        id='category'
                                        isDisabled={(methods.getValues('show.masterfees_changeenabled')) || (showFinancialsLocked && props?.showfees_id > 0)}
                                        name={name}
                                        isSearchable={true}
                                        onChange={(category) => {
                                        onChange(category.value);
                                        }}
                                        theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        })}
                                        styles={reactSelectStyles}
                                        placeholder='Select'
                                    />
                                    );
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-155px' htmlFor='SHOWDESCRIPTION' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.SHOWDESCRIPTION">{intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.SHOWDESCRIPTION' })}</label>
                    <div className='col-lg-4'>
                        <input
                            {...methods.register('showfees.description', {
                                required: false,
                            })}
                            id='SHOWDESCRIPTION'
                            type='text'
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        />
                    </div>
                    <div className='col'>
                        <div className='row'>
                            <label className='col-lg-1 col-form-label fs-5 py-1'></label>
                            <label className='col-lg-1 col-form-label fs-5 py-1' htmlFor='ABBR' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.ABBR">{intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.ABBR' })}</label>
                            <div className='col-lg-3'>
                                <>{
                                isMasterFeeEnabled?
                                <input
                                    {...methods.register('master_fee.abbreviation' , {
                                        required: false,
                                    })}
                                    disabled = {(methods.getValues('show.masterfees_changeenabled'))}
                                    id='ABBR'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.ABBR' })}
                                /> : 
                                <input
                                    {...methods.register('showfees.abbreviation' , {
                                        required: false,
                                    })} 
                                    disabled = {(methods.getValues('show.masterfees_changeenabled'))}
                                    id='ABBR'
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.ABBR' })}
                                /> 
                                }</>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-155px' htmlFor='SHOW' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.SHOW">{intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.SHOW' })}</label>
                    <div className='col-lg-4'>
                        {props.form_meta.show_names && (
                        <Controller
                            name='showfees.show_id'
                            render={({field: {onChange, value, name}}) => (
                            <Select
                                options={props.form_meta.show_names}
                                value={props.form_meta.show_names?.find((ss) => ss.value == value)}
                                id='show_id'
                                name={name}
                                isSearchable={true}
                                onChange={(show_names) => {
                                onChange(show_names.value)
                                }}
                                theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                })}
                                styles={reactSelectStyles}
                                placeholder='Select'
                                isDisabled = {(methods.getValues('show.masterfees_changeenabled')) || (showFinancialsLocked && props?.showfees_id > 0)}
                            />
                            )}
                        />
                    )}
                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-155px' htmlFor='TAXRATE' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.TAXRATE">
                        {intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.TAXRATE' })}
                        </label>
                    <div className='col-lg-4'>
                        {props.form_meta.tax_rate && (
                            <Controller
                                name='showfees.show_tax_rate_id'
                                render={({field: {onChange, value, name}}) => (
                                <Select
                                    options={props.form_meta.tax_rate}
                                    value={
                                    methods.getValues('showfees.show_tax_rate_id') == 0
                                        ? props.form_meta?.tax_rate?.filter(
                                            (option) => option.label == 'Non Taxable'
                                        )
                                        : props.form_meta?.tax_rate?.find((ss) => ss.value == value)
                                    }
                                    id='tax_rate_id'
                                    name={name}
                                    isSearchable={true}
                                    onChange={(tax_rate) => {
                                    onChange(tax_rate.value)
                                    }}
                                    theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    })}
                                    styles={reactSelectStyles}
                                    placeholder='Select'
                                    isDisabled={(showFinancialsLocked && props?.showfees_id > 0)}
                                />
                                )}
                            />
                        )}
                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-155px' htmlFor='UNITPRICE' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.UNITPRICE">{intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.UNITPRICE' })}</label>
                    <div className='col-lg-8'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <Controller
                                    control={methods.control}
                                    name="showfees.unit_price"
                                    render={({ field: { onChange, name, value } }) => (
                                    <NumericFormat
                                        id='UNITPRICE'
                                        prefix={'$'}
                                        allowNegative={false}
                                        value={value !== '' ? Number(value): ""}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        onValueChange={(e) => {
                                            // Only update the value if it's changed by the user
                                            if (Number(e.value) !== Number(value)) {
                                              onChange(e.value);
                                            }
                                        }}
                                        onFocus={(e) => {
                                            e.target.select(); // Highlight the value
                                        }}
                                        className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                        disabled={(showFinancialsLocked && props?.showfees_id > 0)}
                                    />
                                    )}
                                />
                            </div>
                            <label className='col-lg-1 col-form-label fs-5 py-1'> {(methods.watch('show.masterfees_changeenabled'))?methods.getValues('master_fee.default_price'):''}</label>
                        </div>
                    </div> 
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-155px' htmlFor='DEFAULTQTY' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.DEFAULTQTY">{intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.DEFAULTQTY' })}</label>
                    <div className='col-lg-8'>
                        <div className='row'>
                            <div className='col-lg-3'>
                            <Controller
                                control={methods.control}
                                name="showfees.default_qty"
                                render={({ field: { onChange, name, value } }) => (
                                <NumericFormat
                                    id='DEFAULTQTY'
                                    value={Number(value)}
                                    allowNegative={false}
                                    onValueChange={(e) => {
                                        if (Number(e.value) !== Number(value)) {
                                            onChange(e.value)
                                        }
                                    }}
                                    isAllowed={withValueCap}
                                    thousandSeparator=","
                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                />
                                )}
                            />
                            </div>
                            <label className='col-lg-1 col-form-label fs-5 py-1'>{(methods.getValues('show.masterfees_changeenabled'))?methods.getValues('master_fee.default_qty'):''}</label>
                        </div>
                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-155px' htmlFor='DEFAULTWEBQTY' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.DEFAULTWEBQTY">{intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.DEFAULTWEBQTY' })}</label>
                    <div className='col-lg-8'>
                        <div className='row'>
                            <div className='col-lg-3'> 
                            <Controller
                                control={methods.control}
                                name="showfees.web_default_qty"
                                render={(
                                { field: { onChange, name, value } }) => (
                                <NumericFormat
                                    id='DEFAULTWEBQTY'
                                    value={Number(value)}
                                    allowNegative={false}
                                    onValueChange={(e) => {
                                        if (Number(e.value) !== Number(value)) {
                                            onChange(e.value)
                                        }
                                    }}
                                    isAllowed={withValueCap}
                                    thousandSeparator=","
                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                    disabled = {watchDirectUserOrdering ? true : false}
                                />
                                )}
                            />
                            </div> 
                            <label className='col-lg-1 col-form-label fs-5 py-1'>{(methods.getValues('show.masterfees_changeenabled'))?methods.getValues('master_fee.web_default_qty'):''}</label>
                            <div className='col form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {  ...methods.register('showfees.directuserordering' , {
                                            required: false,
                                        })
                                    }
                                    className='form-check-input'
                                    type='checkbox'
                                    id='AVAILABLEFORWEBORDERING'
                                    disabled = { watchWebDefaultQty > 0   }
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='AVAILABLEFORWEBORDERING' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.AVAILABLEFORWEBORDERING"> {intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.AVAILABLEFORWEBORDERING' })}</label>
                                 
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-155px' htmlFor='SHOWINVENTORYCAP' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.SHOWINVENTORYCAP">{intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.SHOWINVENTORYCAP' })}</label>
                    <div className='col-lg-8'>
                        <div className='row'>
                            <div className='col-lg-3'>
                            <Controller
                                control={methods.control}
                                name="showfees.inventory_cap"
                                render={({ field: { onChange, name, value } }) => (
                                <NumericFormat
                                    id='SHOWINVENTORYCAP'
                                    value={Number(value)}
                                    allowNegative={false}
                                    onValueChange={(e) => {
                                    onChange(e.value)
                                    }}
                                    thousandSeparator=","
                                    isAllowed={withValueCap}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                />
                                )}
                            />
                            </div>
                            <label className='col-lg-1 col-form-label fs-5 py-1'>{(methods.getValues('show.masterfees_changeenabled'))?methods.getValues('master_fee.default_show_inventory_cap'):''}</label>
                        </div>

                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-155px' htmlFor='COST' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.COST">{intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.COST' })}</label>
                    <div className='col-lg-8'>
                        <div className='row'>
                            <div className='col-lg-3'>
                            <Controller
                                control={methods.control}
                                name= {isMasterFeeEnabled ? "master_fee.cost" : "showfees.cost"}
                                render={({ field: { onChange, name, value } }) => (
                                <NumericFormat
                                    id='COST'
                                    prefix={'$'}
                                    value={methods.getValues(name) !== '' ? Number(methods.getValues(name)): ""}
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    name={name}
                                    fixedDecimalScale={true}
                                    onValueChange={(e) => {
                                        if (Number(e.value) !== Number(value)) {
                                            onChange(e.value)
                                        }
                                     }}
                                     onFocus={(e) => {
                                        e.target.select(); // Highlight the value
                                    }}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                    disabled = {(methods.getValues('show.masterfees_changeenabled')) || (props?.showfees_id > 0)}
                                />
                                )}
                            />
                            </div>
                            <label className='col-lg-1 col-form-label fs-5 py-1'></label>
                        </div>
                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-155px'></label>
                    <div className='col-lg-8'>
                        <div className='row'>
                            <div className='col-lg-4 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...isMasterFeeEnabled ?
                                        {...methods.register('master_fee.stablefee' , {
                                            required: false,
                                        })} : 
                                        {...methods.register('showfees.stablefee' , {
                                            required: false,
                                        })}
                                    }

                                    className='form-check-input'
                                    type='checkbox'
                                    id='STABLEFEE'
                                    disabled = {!(methods.getValues('show.masterfees_changeenabled')) ? false : true}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='STABLEFEE' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.STABLEFEE"> {intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.STABLEFEE' })}</label>
                            </div>
                            <div className='col form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...isMasterFeeEnabled ?
                                        {...methods.register('master_fee.dont_delete_forscratchentry' , {
                                            required: false,
                                        })} : 
                                        {...methods.register('showfees.dont_delete_forscratchentry' , {
                                            required: false,
                                        })}
                                    }   
                                    className='form-check-input'
                                    type='checkbox'
                                    id='DONTDELETEFORSCRATCHENTRY'
                                    disabled = {!(methods.getValues('show.masterfees_changeenabled')) ? false : true}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='DONTDELETEFORSCRATCHENTRY' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.DONTDELETEFORSCRATCHENTRY"> {intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.DONTDELETEFORSCRATCHENTRY' })}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-155px'></label>
                    <div className='col-lg-8'>
                        <div className='row'>
                            <div className='col-lg-4 form-check-sm form-check-custom ps-lg-3'>
                            <>{
                                isMasterFeeEnabled?
                                <input
                                    {...methods.register('master_fee.feed_bedding_fee' , {
                                        required: false,
                                    })}
                                    className='form-check-input'
                                    type='checkbox'
                                    id='FEEDBREEDINGITEM'
                                    disabled = {!(methods.getValues('show.masterfees_changeenabled')) ? false : true}
                                /> : 
                                <input
                                    {...methods.register('showfees.feed_bedding_fee' , {
                                        required: false,
                                    })} 
                                    className='form-check-input'
                                    type='checkbox'
                                    id='FEEDBREEDINGITEM'
                                    disabled = {!(methods.getValues('show.masterfees_changeenabled')) ? false : true}
                                /> 
                                }</>
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='FEEDBREEDINGITEM' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.FEEDBEDDINGITEM"> {intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.FEEDBEDDINGITEM' })}</label>
                            </div>
                            <div className='col form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...isMasterFeeEnabled ?
                                        {...methods.register('master_fee.pass_through' , {
                                            required: false,
                                        })} : 
                                        {...methods.register('showfees.pass_through' , {
                                            required: false,
                                        })}
                                    }   
                                    className='form-check-input'
                                    type='checkbox'
                                    id='PASSTHROUGH'
                                    disabled = {(methods.getValues('show.masterfees_changeenabled'))}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='PASSTHROUGH' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.PASSTHROUGH"> {intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.PASSTHROUGH' })}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-155px'></label>
                    <div className='col-lg-8'>
                        <div className='row'>
                            <div className='col-lg-4 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...isMasterFeeEnabled ?
                                        {...methods.register('master_fee.appearonstatement' , {
                                            required: false,
                                        })} : 
                                        {...methods.register('showfees.appearonstatement' , {
                                            required: false,
                                        })}
                                    }
                                    className='form-check-input'
                                    type='checkbox'
                                    id='APPEARONENTRYBLANK'
                                    disabled = {(methods.getValues('show.masterfees_changeenabled')) }
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='APPEARONENTRYBLANK' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.APPEARONENTRYBLANK"> {intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.APPEARONENTRYBLANK' })}</label>
                            </div>
                            <div className='col form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...isMasterFeeEnabled ?
                                        {...methods.register('master_fee.card_fee' , {
                                            required: false,
                                        })} : 
                                        {...methods.register('showfees.card_fee' , {
                                            required: false,
                                        })}
                                    }   
                                    className='form-check-input'
                                    type='checkbox'
                                    id='CHARGECARDTOOL'
                                    disabled = {(methods.getValues('show.masterfees_changeenabled'))}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='CHARGECARDTOOL' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.CHARGECARDTOOL"> {intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.CHARGECARDTOOL' })}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-155px'></label>
                    <div className='col-lg-8'>
                        <div className='row'>
                            <div className='col-lg-4 form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    {...isMasterFeeEnabled ?
                                        {...methods.register('master_fee.web_deposit_required' , {
                                            required: false,
                                        })} : 
                                        {...methods.register('showfees.web_deposit_required' , {
                                            required: false,
                                        })}
                                    }                        
                                    className='form-check-input'
                                    type='checkbox'
                                    id='WEBDEPOSITREQUIRED'
                                    disabled = {!(methods.getValues('show.masterfees_changeenabled')) ? false : true}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='WEBDEPOSITREQUIRED' data-tooltip-id="FEES.DETAIL.TAB.GENERAL.LABEL.WEBDEPOSITREQUIRED"> {intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.GENERAL.LABEL.WEBDEPOSITREQUIRED' })}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { GeneralTab }