import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import axios from 'axios'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic'
import { useAppSelector } from '../../../../redux/hooks'
import { useFormContext } from 'react-hook-form'

type Props = {
    show: boolean
    handleClose: () => void
    stables : any []
    searchTerm: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const StableLookupForm = ({ show, handleClose, stables, searchTerm }: Props) => {
    const intl = useIntl()
    const methods = useFormContext()
    const customerId = useAppSelector(state => state.showCompany.company_id);
    const [gridRef, setGridRef] = useState<any>()

    const columnDefs = [
        { field: 'name', flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.STABLELOOKUP.STABLE' })},
        { field: 'address',flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.STABLELOOKUP.ADDRESS' })},
        { field: 'city_state',flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.STABLELOOKUP.CITYSTATE' }), valueGetter: (params : any) => `${params.data.city}/${params.data.state}`, },
        { field: 'telephone',flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.STABLELOOKUP.TELEPHONE' }) },
    ]

    const selectStable = () => {
        if (gridRef.getSelectedRows().length > 0) {
            const stableId = gridRef.getSelectedRows()[0].stable_id
            axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/selectedStableData', {
                params: {
                    stable_id: stableId,
                    customer_id: customerId,
                }
            }) 
            .then(result => { 
                if(result.data.success){
                    methods.setValue("stable", result.data.stable)
                    methods.setValue(`linked_people[${methods.getValues("entry.trainer_id")}].people_data.trainer_stable_id`, methods.getValues("entry.stable_id"))
                    methods.setValue(`linked_people[${methods.getValues("entry.trainer_id")}].people_data.isUpdated`, true)
                    methods.setValue("entry.stable_id", result.data.stable.stable_id,{shouldDirty: true})
                    methods.setValue("entry.stable", result.data.stable.name, {shouldDirty: true})
                    methods.setValue("existing_stable", result.data.stable, { shouldDirty: true })
                    handleClose()
                } 
            })
        }
    }

    const addNewStable = () => {
        // Set Stable_Id to -1 for new stable
        methods.setValue("stable.stable_id", -1, {shouldDirty: true})
        methods.setValue("stable.name", searchTerm, {shouldDirty: true})
        methods.setValue("stable.address", "", {shouldDirty: true})
        methods.setValue("stable.city", "", {shouldDirty: true})
        methods.setValue("stable.state", "", {shouldDirty: true})
        methods.setValue("stable.zip", "", {shouldDirty: true})
        methods.setValue("stable.telephone", "",{shouldDirty: true})
        methods.setValue("entry.stable_id", -1,{shouldDirty: true})
        methods.setValue("entry.stable", searchTerm, {shouldDirty: true})
        handleClose()
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '400px' }), []);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            enforceFocus={true}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-850px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event :any) => {
                const activeElement = document.activeElement;
                if (event.key === "Enter" && activeElement && activeElement.tagName !== 'BUTTON') {
                    selectStable()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.STABLELOOKUP.TITLE.SELECTSTABLE' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form ' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-12 col-form-label fs-5 py-1' tabIndex={1}>Found {stables.length} stable(s) in local database.</label>
                        </div>
                        <div className='row mb-2'>
                            <OutputListingGridStatic
                                area ={"Entries - StableLookupForm"}
                                columnDefs={columnDefs}
                                rowData={stables}
                                containerStyle={containerStyle}
                                setGridRef={setGridRef}
                                entriesSearchDialog={true}
                            ></OutputListingGridStatic>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button className='btn btn-sm btn-secondary fw-bold text-uppercase'
                            type="button"
                            onClick={addNewStable}
                            tabIndex={1}
                            data-tooltip-id="ENTRIES.DETAIL.MODAL.STABLELOOKUP.BUTTON.ADDNEW"
                            >
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.STABLELOOKUP.BUTTON.ADDNEW' })}
                        </button>

                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold text-uppercase' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={2}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button className='btn btn-sm btn-dark fw-bold text-uppercase'
                            type="button"
                            onClick={selectStable}
                            tabIndex={3}
                            autoFocus
                            data-tooltip-id="ENTRIES.DETAIL.MODAL.STABLELOOKUP.BUTTON.SELECT">
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.MODAL.STABLELOOKUP.BUTTON.SELECT' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { StableLookupForm }