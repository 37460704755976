import { createBrowserHistory } from "history";
import { customConfirm } from "./customConfirmDialog";
const history = createBrowserHistory()

export const handlePrompt = (promptMessage = 'You have unsaved changes. Are you sure you want to leave this page?') => {
    let allowNavigation = false
    // Block navigation and register a callback that fires when a navigation attempt is blocked.
    const funcRef = history.block(tx => { // Navigation was blocked!
        if (
            allowNavigation || 
            // Unblock Navigation! (Use case: Save Button)
            (tx.location.state && tx.location.state.allowNavigation)
            // Let's show a confirmation dialog
            // so the user can decide if they actually want to navigate
            // away and discard changes they've made in the current page.
        ) {
            // Clear history state
            if (!allowNavigation) {
                allowNavigation = true
                history.replace(history.location.pathname, null)
            }
            
            // Unblock the navigation.
            funcRef()

            // Retry the transition.
            tx.retry()
        } else { 
            // Show a confirmation dialog if there are unsaved changes.
            sessionStorage.setItem("modal_is_open", JSON.stringify(true));
            let return_value = Promise.resolve(customConfirm(promptMessage));
            return_value.then((value) => {
                sessionStorage.removeItem("modal_is_open");
                if(value){
                    // // Unblock the navigation.
                    funcRef();

                    // // Retry the transition.
                    tx.retry();
                }
            });
        }


    })

    
    
    return funcRef
}

export default history