import { useEffect, useRef } from 'react';

const useKey = (Keys, callBack) => {
    const callBackRef = useRef(callBack)
    useEffect(() => {
        callBackRef.current = callBack;
    },[])

    useEffect(() => {
        function handleKeyDown(event) {
            if (Keys.includes(event.code)) {
                callBackRef.current(event);
            }
        }
        
        // Attach the keydown event listener
        document.addEventListener('keydown', handleKeyDown);
        // Cleanup the event listener when the component unmounts
        return () => document.removeEventListener('keydown', handleKeyDown) 
    },[Keys]);
}

export default useKey


export const letterKeys = [
    'KeyA', 'KeyB', 'KeyC', 'KeyD', 'KeyE', 'KeyF', 'KeyG', 'KeyH', 'KeyI', 'KeyJ',
    'KeyK', 'KeyL', 'KeyM', 'KeyN', 'KeyO', 'KeyP', 'KeyQ', 'KeyR', 'KeyS', 'KeyT',
    'KeyU', 'KeyV', 'KeyW', 'KeyX', 'KeyY', 'KeyZ',
    'Digit0', 'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9',
];

export const arrowKeys = [ 'ArrowUp', 'ArrowDown', ]

export const enterEscKeys = [ 'Escape', 'Enter', ]

