import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useOutputContext } from "../../output-listing/OutputListingContext";
import { useAuth } from "../../auth";

const FeesTabs = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { currentCompany } = useAuth()
    const hasMasterFeesAccess = currentCompany && currentCompany.permissions.includes(`cloud_masterfees:write`) 
    const tabs = ['SHOWFEES', hasMasterFeesAccess && 'ALLFEES'].filter(Boolean)
    const [activeTab, setActiveTab] = useState(0)
    const {outputGrid} = useOutputContext();

    const handleTabChanged = (tabName) => {
        // Move to selected page
        if(tabName === 'ALLFEES'){
            navigate('/masterfees')
        }else if(tabName === 'SHOWFEES'){
            navigate('/fees')
        }
    }

    useEffect(() => {
        // Mark the tab as active according to selected tab
        if(outputGrid?.area === 'MasterFees'){
            setActiveTab(1)
        }else if(outputGrid?.area === 'Fees'){
            setActiveTab(0)
        }
    }, [outputGrid?.area])

    return (
        <>
            <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap mb-2' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                {
                    tabs.map((tab, index) => {
                        return (
                            <li key={index} className='nav-item'>
                                <a className={"nav-link text-active-dark py-1 px-10 text-gray-700" + (index == activeTab ? ' active' : '')}
                                    tabIndex="-1" // to make the tab links non-focusable
                                    id={tab + "-tab"}
                                    data-bs-toggle='tab'
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => handleTabChanged(tab)}>{intl.formatMessage({ id: 'LIST.FEES.LABEL.' + tab })}
                                </a>
                            </li>
                        );
                    })
                }
            </ul>
        </>
    )
}

export { FeesTabs }
