
import { useIntl } from 'react-intl'
import Select from 'react-select'
import { reactSelectStyles } from '../../modules/sgl-utils/fieldControls'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useAppSelector } from '../../redux/hooks'
import { useAlert } from '../../modules/sgl-utils/DialogsProvider'
import { useAuth } from '../../modules/auth'
import { useOutputContext } from '../../modules/output-listing/OutputListingContext'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const SiteMonitoringFilter = () => {
  const intl = useIntl()
  const customerID = useAppSelector(state=> state.showCompany.company_id);
  const [ dropDownLists, setDropDownLists ] = useState(null)
  const alertDialog = useAlert();
  const { currentUser } = useAuth()
  const {customFilters, setCustomFilters} = useOutputContext();
  const reactSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      background: state?.isDisabled? '#eff2f5' : '#fff',
      height: 40,
      minHeight: 40,
      fontSize: 14,
      fontFamily: 'Lato',
      color: "#3F4254",
      borderColor: "#A1A5B7",
      borderWidth: 1,
      boxShadow: 'none',
      borderRadius: 0,
      outline:0,
      '&:focus': {
          borderColor: '#A1A5B7',
      },
      '&:hover': {
          borderColor: '#A1A5B7',
      }
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px',
      width: '180px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '39px',
    }),
    placeholder: (defaultStyles) => {
      return {
          ...defaultStyles,
          color: '#3F4254',
      }
  }
};

  useEffect(()=> {//Populate drop down values
    axios.get(`${NEST_API_URL}/utility/getSiteMonitoringMetaData?customer_id=${customerID}`)
    .then(response => {   
        setDropDownLists({
            sourceOptions: response?.data?.sourceOptions && response?.data?.sourceOptions || [],
            tabNameOptions: response?.data?.tabNameOptions && response?.data?.tabNameOptions || [],
            activity: response?.data?.activity && response?.data?.activity || []
        })
    })
    .catch((error) => {
        if (error.response) {
            alertDialog({message: error.response.data.error});
        }
    }).finally(() => {
        //populate company drop down
        const companyOptions = currentUser?.smc_access.map(company => ({
            label: company?.urlpath?.toUpperCase(), value: company.company_id
        })).sort((a, b) => a.label.localeCompare(b.label))// sort the array by label in desc order
        companyOptions?.unshift({label: 'All Companies', value: ''})
        setDropDownLists((prevState) => ({...prevState, companyOptions}))
    })

    //by default select current company
    setCustomFilters({...customFilters, company: customerID})
  },[])

  useEffect(() => {
    setCustomFilters(() => {return {...customFilters, company: customerID}})
  },[customerID])

  return (
    <div className='w-450px ms-3' style={{ display: 'flex', gap: '10px' }}>
    <Select
        options={dropDownLists?.companyOptions ?? []}
        id='company'
        name={'company'}
        isSearchable={true}
        className='fs-6'
        onChange={e => setCustomFilters({...customFilters, company: e.value})}
        value={customFilters.company !== undefined ? dropDownLists?.companyOptions.filter((ss) => ss.value === customFilters.company):""}
        theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        })}
        styles={reactSelectStyles}
        placeholder={intl.formatMessage({ id: 'LIST.SITEMONITORING.SELECT.ALLCOMPANIES' })}
    />

    <Select
        options={dropDownLists?.sourceOptions ?? []}
        id='source'
        name={'source'}
        isSearchable={true}
        className='fs-6'
        onChange={e => setCustomFilters({...customFilters, source: e.value})}
        value={customFilters.source !== undefined ? dropDownLists?.sourceOptions.filter((ss) => ss.value === customFilters.source):""}
        theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        })}
        styles={reactSelectStyles}
        placeholder={intl.formatMessage({ id: 'LIST.SITEMONITORING.SELECT.ALLSOURCES' })}
    />

    <Select
        options={dropDownLists?.tabNameOptions ?? []}
        id='tab_name'
        name={'tab_name'}
        isSearchable={true}
        className='fs-6'
        onChange={e => setCustomFilters({...customFilters, tab_name: e.value})}
        value={customFilters.tab_name !== undefined ? dropDownLists?.tabNameOptions.filter((ss) => ss.value === customFilters.tab_name):""}
        theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        })}
        styles={reactSelectStyles}
        placeholder={intl.formatMessage({ id: 'LIST.SITEMONITORING.SELECT.ALLAREAS' })}
    />
    
    </div>

  )
}

export { SiteMonitoringFilter }
