import { useMemo, useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useFormContext } from "react-hook-form"
import { KTSVG } from '../../../_metronic/helpers'
import { useAlert } from '../../modules/sgl-utils/DialogsProvider'
import { AgGridReact } from 'ag-grid-react'
import { defaultColDef, getNarrowHeaderHeight, getNarrowRowHeight } from '../../modules/sgl-utils/agGridHelpers'

const modalsRoot = document.getElementById('root-modals') || document.body

const UpdateEntryNumberForm = ({ show, handleClose, seriesEntryNumber }) => {
    const intl = useIntl()
    const alertDialog = useAlert()
    const methods = useFormContext();
    const [gridRef, setGridRef] = useState(null)
    const containerStyle = useMemo(() => ({ width: '100%', height: '250px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    const columnDefs = [
        { 
            field: 'number', 
            width: 90,
            headerName: intl.formatMessage({ id: "FORM.INPUT.ENTRIES.MODAL.SETSERIES.ENTRY" }) 
        },
        { 
            field: 'horse', 
            width: 140, 
            headerName: intl.formatMessage({ id: "FORM.INPUT.ENTRIES.MODAL.SETSERIES.HORSE" }) 
        },
        { 
            field: 'trainer', 
            width: 150, 
            headerName: intl.formatMessage({ id: "FORM.INPUT.ENTRIES.MODAL.SETSERIES.TRAINER" }) 
        },
        { 
            field: 'owner', 
            width: 150, 
            headerName: intl.formatMessage({ id: "FORM.INPUT.ENTRIES.MODAL.SETSERIES.OWNER" }) 
        },
        { 
            field: 'showName', 
            width: 200, 
            headerName: intl.formatMessage({ id: "FORM.INPUT.ENTRIES.MODAL.SETSERIES.SHOW" }) 
        },
        { 
            field: 'entryStatus', 
            flex:1, 
            headerName: intl.formatMessage({ id: "FORM.INPUT.ENTRIES.MODAL.SETSERIES.ENTRYSTATUS" }) 
        },
    ]

    useEffect(() => {
        if (show && gridRef && seriesEntryNumber.entries.length > 0) {
            gridRef.setRowData(seriesEntryNumber.entries)
        }
    }, [gridRef, seriesEntryNumber])

    const updateSeriesEntryNumbers = () => {
        let recordsUpdated = 0
        let updatedSeriesNumber = []

        for(let entry of seriesEntryNumber.entries){
             if(!entry.hasError){
                updatedSeriesNumber.push(entry)
                recordsUpdated++    
            }
        }

        if(recordsUpdated > 0){
            methods.setValue('seriesEntryNumber', updatedSeriesNumber, {shouldDirty: true})
            alertDialog({message:`Entry No. successfully updated for ${recordsUpdated} entries.`})
        }

        handleClose()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-1100px-max-width'
            show={show}
            onHide={handleClose}
            onKeyDown={event => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) {
                    updateSeriesEntryNumbers()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">
                    {intl.formatMessage({ id: "FORM.INPUT.ENTRIES.MODAL.SETSERIES.TITLE"})}
                </h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form ' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <div className='col'>
                                <div className='row mb-2'>
                                    <label className='col col-form-label fs-5 py-1 fw-bold'>{`Would you like to set Entry No. ${methods.getValues('entry.number')} for all shows in ${seriesEntryNumber.seriesName} ?`}</label>
                                </div>
                            </div>
                        </div>
                        <div style={containerStyle}>
                            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                <AgGridReact 
                                    defaultColDef={defaultColDef}
                                    rowData={[]}
                                    columnDefs={columnDefs} 
                                    onGridReady={ params => { setGridRef(params.api) }}
                                    headerHeight={getNarrowHeaderHeight}
                                    rowHeight={getNarrowRowHeight}
                                    rowClassRules={{ 'bg-red-grid-row': 'data.hasError' }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' onClick={handleClose} className='btn btn-sm btn-secondary me-4 fw-bold text-uppercase' style={{ marginLeft: "auto" }}>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type='button' onClick={() => updateSeriesEntryNumbers()} className='btn btn-sm btn-dark fw-bold text-uppercase' autoFocus>
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.RTO.CHECKRIDER.MODAL.BUTTON.CHANGE' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { UpdateEntryNumberForm }