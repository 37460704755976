export class DecimalEditor {
    // gets called once before the renderer is used
    init(params) {
      // create the cell
      this.eInput = document.createElement('input');

      // remove default css
      this.eInput.style.border = 'none'
      this.eInput.style.outline = 'none'

      // add a ag grid classes
      this.eInput.classList.add('ag-input-field-input', 'ag-text-field-input');
  
      if (this.isCharDecimal(params.charPress)) {
        this.eInput.value = params.charPress;
      } else {
        if (params.value !== undefined && params.value !== null) {
          this.eInput.value = params.value;
        }
      }
  
      this.eInput.addEventListener('keypress', (event) => {
        if (!this.isKeyPressedDecimal(event)) {
          this.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        } else if (this.isKeyPressedNavigation(event)) {
          event.stopPropagation();
        }
      });
  
      // only start edit if key pressed is a number, not a letter
      const charPressIsNotANumber =
        params.charPress && '1234567890'.indexOf(params.charPress) < 0;
      this.cancelBeforeStart = !!charPressIsNotANumber;
    }
  
    isKeyPressedNavigation(event) {
      return event.key === 'ArrowLeft' || event.key === 'ArrowRight';
    }
  
    // gets called once when grid ready to insert the element
    getGui() {
      return this.eInput;
    }
  
    // focus and select can be done after the gui is attached
    afterGuiAttached() {
      this.eInput.focus();
    }
  
    // returns the new value after editing
    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    }
  
    // example - will reject the number if it contains the value 007
    // - not very practical, but demonstrates the method.
    isCancelAfterEnd() {
      const value = this.getValue();
      return value.indexOf('007') >= 0;
    }
  
    // returns the new value after editing
    getValue() {
      return this.eInput.value;
    }
  
    // any cleanup we need to be done here
    destroy() {
      // but this example is simple, no cleanup, we could  even leave this method out as it's optional
    }
  
    // if true, then this editor will appear in a popup
    isPopup() {
      // and we could leave this method out also, false is the default
      return false;
    }
  
    // ^ - Beginning of the line;
    // \d* - 0 or more digits;
    // \.? - An optional dot (escaped, because in regex, . is a special character);
    // \d* - 0 or more digits (the decimal part);
    // $ - End of the line.
    isCharDecimal(charStr) {
      return charStr && !!/^\d*\.?\d*$/.test(charStr);
    }
  
    isKeyPressedDecimal(event) {
      const charStr = event.key;
      return this.isCharDecimal(charStr);
    }
  }