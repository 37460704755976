import { AgGridReact } from "ag-grid-react";
import { memo, useMemo } from "react";
import { defaultColDef  } from "../../../../modules/sgl-utils/agGridHelpers";

const DivisionsGrid = ({ setGridRef, columnDefs, tabIndex=0 }) => {
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    return (
        <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                <AgGridReact
                    defaultColDef={{...defaultColDef, autoHeaderHeight: true}}
                    rowData={[]}
                    columnDefs={columnDefs} 
                    onGridReady={params =>  { 
                        setGridRef(params.api)
                    }}
                    headerHeight={65}
                    rowHeight={40}
                    {...(tabIndex !== 0 && { tabIndex })} //tabIndex is set conditionally, only if a prop was passed, provided that the prop is not 0   
                />
            </div>
        </div>
    )
}


export default memo(DivisionsGrid)