import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { KTSVG } from '../../../../_metronic/helpers';
import { useAnnouncerToolContext } from './AnnouncerToolContext';
import CheckboxRenderer from './CheckboxRenderer';
import { useAuth } from '../../../modules/auth';
import { useAppSelector } from '../../../redux/hooks';
import { SaveModifiedColumnDefinitions, overwriteColumnDefinitions } from '../../../modules/sgl-utils/agGridHelpers';
import axios from "axios";
import { useAlert, useConfirm, useFlashAlert } from '../../../modules/sgl-utils/DialogsProvider';
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../modules/sgl-utils/SglFunctions';
import { useSGLContext } from '../../../modules/contexts/SGLContext';
import { isMobile, isTablet } from 'react-device-detect';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const UpcomingTripsGrid = (props) => {
  const gridRef = useRef();
  const intl = useIntl();
  const alertDialog = useAlert();
  const confirmDialog = useConfirm()
  const { localStorageChanged } = useSGLContext()
  const { currentClassGroup, selectedClassGroup, upcomingTrips, selectCurrentTrip, setGoneStatus, highlightCurrentTrip, updateColumnDefToggle, setUpcomingTrips, currentShowID, customerID, classesWithCompletedTrips, setClassesWithCompletedTrips, setOrderedTrips, setUnorderedTrips, showGridLoadingOverlays, isTimeDifferenceValid, setLastIngateActivity, unorderedTrips, maximumValueOfActualOrder } = useAnnouncerToolContext()
  const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)
  const [ hideScratched, setHideScratched] = useState(false);
  const [ hideWarmupClasses, setHideWarmupClasses] = useState(false);
  const [ filteredUpcomingTrips, setFilteredUpcomingTrips] = useState([]);
  const [ upcomingTripsWithUpdatedOrderOfGo, setUpcomingTripsWithUpdatedOrderOfGo] = useState([]) //save upcoming trips with updated order of go 
  const { preferences } = useAnnouncerToolContext();
  const {currentUser} = useAuth()
  const customer_id = useAppSelector(state=> state.showCompany.company_id);
  const [ tripsGoAtOnce, setTripsGoAtOnce ] = useState(false)
  const flashAlert = useFlashAlert()

  const dynamic_grid_height = upcomingTripsWithUpdatedOrderOfGo?.length > 0 ? 'calc(100% - 40px)' : upcomingTrips?.length > 0 ? 'calc(100% - 30px)' : '100%'
  const gridStyle = useMemo(() => ({ height: dynamic_grid_height, width: '100%' }), [dynamic_grid_height]);
  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100% - 45px)' }), []);

  const nativeDragandDrop = true; //isMobile || isTablet; SK - Forcing native grid drag/drop option for desktop as well.
  let noOfNonScratchedUpcomingTrips = upcomingTrips?.filter((trip) => !trip.scratch_trip).length

  useEffect(()=>{
    //overwrite column defs with user modified column defs
    let colDefs = localStorage.getItem('Announcer Interface')
    if (colDefs != "undefined"){
      let custom_column_definitions = JSON.parse(colDefs)
      let overwrite_colDefs = overwriteColumnDefinitions(custom_column_definitions, columnDefs)

      const firstColumn = {"resizable":false,"editable":false,"wrapHeaderText":true,"field":"","headerName":"","rowDrag":true,"maxWidth":25,"minWidth":25,"sortable":false,"headerClass":"ag-center-aligned-header","colId":"0","width":25,"rowGroup":false,"rowGroupIndex":null,"pivot":false,"pivotIndex":null,"aggFunc":null,"pinned":null,"sort":null,"sortIndex":null};
      const hasFirstColumn = overwrite_colDefs.some(col => col.field === "" && col.headerName === "");

      if (!hasFirstColumn) {
          console.log("drag column not found");
          // Add the first column to the beginning of the array
          overwrite_colDefs.unshift(firstColumn);
          if(overwrite_colDefs.length > 1){
            setColumnDefs(overwrite_colDefs)
          }
      }else{
        if(overwrite_colDefs.length > 0){
          setColumnDefs(overwrite_colDefs)
        }
      }

      
    }
  },[localStorageChanged])

  useEffect(()=>{
    // reset the upcoming trips
    setUpcomingTripsWithUpdatedOrderOfGo([])
  },[upcomingTrips])

  useEffect(()=>{
    // filter remaining trips for hide scratched trip and hide warmup trip
    if(upcomingTrips && upcomingTrips.length > 0)
    {
      let trips = upcomingTripsWithUpdatedOrderOfGo.length > 0 ? upcomingTripsWithUpdatedOrderOfGo : upcomingTrips
      if(hideScratched)
      {
        trips = trips?.filter((trip) => !trip.scratch_trip)
      }
      if(hideWarmupClasses)
      {
        if(currentClassGroup.hasWarmupClass)
        {
          trips = trips.filter((trip) => currentClassGroup.realEntryIds.includes(trip.entry_id))
        }       
      }
      trips = trips?.map((trip, index) => ({...trip, lined_up_pos: index + 1}));
      setFilteredUpcomingTrips(trips)
      if (gridRef) {
        gridRef?.current?.api?.refreshCells()
      }
    }else{
      setFilteredUpcomingTrips(upcomingTrips)
    }
  },[hideScratched, hideWarmupClasses, upcomingTrips, upcomingTripsWithUpdatedOrderOfGo])

  useEffect(() => {
    if(upcomingTrips && gridRef)
    {
      highlightCurrentTrip(gridRef?.current?.api)
    }
  }, [filteredUpcomingTrips]);

  useEffect(() => {
    if(showGridLoadingOverlays){//Display Grid Overlay
       gridRef?.current?.api?.showLoadingOverlay()
    }else{ //Hide Grid Overlay
      gridRef?.current?.api?.hideOverlay()
    }
  }, [showGridLoadingOverlays]);

  useEffect(() => {
    // update the visibility of column when class group is changed
    if(currentClassGroup && gridRef && gridRef?.current?.columnApi)
    {
      updateColumnDefToggle(gridRef?.current?.columnApi)
    }
  }, [currentClassGroup]);

  const setClassNumber = (params) => {
    if(params?.data?.Class?.number)
    {
      return params.data.Class.number
    }else if(params?.data?.class_number){
      return params?.data?.class_number
    }else{
      return ''
    }
  }

  const reorderUpcomingTrips = (params) => {
    
    let updatedUpcomingTrips = []
    // update the lined_up_pos for all the trips after row is dropped 
    updatedUpcomingTrips = params?.api?.rowModel?.rowsToDisplay?.map((trip, index) => {
      let tripData = trip.data
      tripData.lined_up_pos = index + 1
      return tripData
    });

    if (hideScratched) {
      // get the scratched trips and place them at the end
      const scratchedTrips = upcomingTrips.filter(trip => trip.scratch_trip);
      updatedUpcomingTrips = [...updatedUpcomingTrips, ...scratchedTrips];
    }

    if (hideWarmupClasses) {
      // get the warmup trips and place them at the end
      const warmUpTrips = upcomingTrips.filter(trip => trip.Class.warmup_class);
      updatedUpcomingTrips = [...updatedUpcomingTrips, ...warmUpTrips];
    }

    setUpcomingTripsWithUpdatedOrderOfGo(updatedUpcomingTrips)
  };

  const updateOrderOfGoForUpcomingTrips = async (event) => {
    let classGroupTrips = []
    //If order is changed than use the upcomingTripsWithUpdatedOrderOfGo otherwise upcomingTrips
    let upcomingTripsForClasses = upcomingTripsWithUpdatedOrderOfGo?.length > 0 ? upcomingTripsWithUpdatedOrderOfGo : upcomingTrips
    
    if(upcomingTrips?.length == 0){ return }// Don't call order update webservice if no upcoming trip present

    if(unorderedTrips?.length > 0){//If some unordered trips are present
      let choice = await confirmDialog({message: intl.formatMessage({id: "FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.LABEL.CONFIRM.MESSAGE"})})
      if(!choice){ return }// Don't call order update webservice in case of cancel
    }
    
    //add completed trips in classGroupTrips(entryxclasses_id, order_of_go) 
    classesWithCompletedTrips.forEach(classWithCompletedTrips => {
      classWithCompletedTrips.Entryxclasses.forEach(trip => {
        let tripData = {
          entryxclasses_id: trip.entryxclasses_id,
          order_of_go: trip.order_of_go
        }
        classGroupTrips.push(tripData)
      })
    });

    //sort the array on order_of_go
    classGroupTrips = classGroupTrips.sort((a,b) => a.order_of_go - b.order_of_go)

    //add upcoming trips in classGroupTrips(entryxclasses_id, order_of_go)
    upcomingTripsForClasses.forEach(trip => {
      let tripData = {
        entryxclasses_id: trip.entryxclasses_id,
        order_of_go: trip.order_of_go
      }
      classGroupTrips.push(tripData)
    });

    //update the order_of_go for all the tripss of class group
    classGroupTrips = classGroupTrips.map((trip,index) => {
      trip.order_of_go = index + 1
      return trip
    })
    
    loadingSpinnerBtnWait(event) // DIPLAY SPINNER
    // call webservice for update
    axios.patch(`${NEST_API_URL}/ingate-announcer/updateOrderOfGoForTrips`, {
        show_id: currentShowID,
        customer_id: customerID,
        class_group_id: currentClassGroup.class_group_id,
        class_group_trips: classGroupTrips
    })
    .then(response => {
        if (response.data.success) {
            //Set Lined Up Trips
            setUpcomingTrips(response?.data?.upcomingTrips);

            //Set completed trips for classes of class group
            setClassesWithCompletedTrips(response?.data?.completedTrips)

            //Set OOG Tab
            setOrderedTrips(response?.data?.orderedTrips)
            setUnorderedTrips(response?.data?.unorderedTrips)

            setUpcomingTripsWithUpdatedOrderOfGo([])
            flashAlert({ type: 'success', message: intl.formatMessage({id: 'ANNOUNCER.INGATEANNOUNCER.ORDER.OF.GO.SAVED'}) });
        }
    })
    .catch((error) => {
        if (error.response) {
            alertDialog({message: error.response.data.error});
        }
    })
    .finally(() => {
      loadingSpinnerBtnRelease(event)
    });

    setLastIngateActivity('Set Order Of Go')
  }

  //Grid Class Groups
  const [columnDefs, setColumnDefs] = useState([
      ...(nativeDragandDrop ? [{field: '', headerName: '', rowDrag: true, maxWidth: 25, minWidth: 25, sortable: false, headerClass: 'ag-center-aligned-header' }] : []),
      { field: 'lined_up_pos', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.REMAININGTRIPS.LABEl.IN'}), sortable: true, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, maxWidth: 35, minWidth: 35, headerClass: 'ag-center-aligned-header', sortable: true },
      { field: 'Entry.number', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.REMAININGTRIPS.LABEl.ENTRY'}), sortable: true, suppressMenu: true,cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, maxWidth: 60,  minWidth: 60, resizable :true, headerClass: 'ag-center-aligned-header', sortable: true   },
      { field: 'class_number', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.REMAININGTRIPS.LABEl.CLASS'}), sortable: true, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, maxWidth: 60, minWidth: 60, resizable :true, headerClass: 'ag-center-aligned-header', valueGetter: setClassNumber, sortable: true },
      { field: 'team', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.REMAININGTRIPS.LABEl.TEAM'}), sortable: true , suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, maxWidth: 55, minWidth: 55 ,headerClass: 'ag-center-aligned-header', valueGetter: 
      function getTeam(params) {
        if(params?.data?.TeamxClass?.Team?.team_abbr)
        {
          return params?.data?.TeamxClass?.Team?.team_abbr
        }else if(params?.data?.TeamxClass?.Team?.team_name){
          return params?.data?.TeamxClass?.Team?.team_name
        }else{
          return ''
        }
      }},
      { field: 'gone_in', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.REMAININGTRIPS.LABEl.GOING'}), sortable: true, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true,maxWidth: 45  , minWidth: 45 ,headerClass: 'ag-center-aligned-header',
      cellRendererFramework: (params) => {
        return (
            <CheckboxRenderer params = {params} column = "gone_in" grid="upcomingTrips"/>
        );
      }},
      { field: 'scratch_trip', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.REMAININGTRIPS.LABEl.SCRATCH'}), sortable: true, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, maxWidth: 50 , minWidth: 50  ,headerClass: 'ag-center-aligned-header', sortable: true,
      cellRendererFramework: (params) => {
        return (
            <CheckboxRenderer params = {params} column = "scratch_trip"/>
        );
      }},
      { field: 'Entry.horse', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.REMAININGTRIPS.LABEl.HORSE'}), sortable: true, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, maxWidth: 500,  minWidth: 150, cellClass: 'ag-left-aligned-cell'},
      { field: 'rider_name', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.REMAININGTRIPS.LABEl.RIDER'}), sortable: true, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, maxWidth: 500,  minWidth: 150, cellClass: 'ag-left-aligned-cell'},
      { field: 'Entry.trainer', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.REMAININGTRIPS.LABEl.TRAINER'}), sortable: true, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, maxWidth: 500,  minWidth: 150, cellClass: 'ag-left-aligned-cell'},
      {
        field: 'EntryRider.status',
        headerName: intl.formatMessage({ id: 'ANNOUNCER.GRID.REMAININGTRIPS.LABEl.STATUS' }),
        sortable: true,
        resizable: true,
        minWidth: 100,
        maxWidth: 200,
        cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0},
        suppressMenu: true
      },
      { field: 'delay_in_minutes', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.REMAININGTRIPS.LABEl.DELAY'}), sortable: true, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0} , resizable :true, maxWidth: 65 , minWidth: 65  ,headerClass: 'ag-center-aligned-header'},
      { field: 'checked_in', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.REMAININGTRIPS.LABEl.CHKIN'}), sortable: true, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0} , resizable :true,  maxWidth: 65 , minWidth: 65  ,headerClass: 'ag-center-aligned-header',
      cellRendererFramework: (params) => {
        return (
            <CheckboxRenderer params = {params} column = "checked_in"/>
        );
      }},
      { field: 'estimated_go_time', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.REMAININGTRIPS.LABEl.ESTGOTIME'}), sortable: true, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0} , resizable :true, maxWidth: 70 , minWidth: 70  ,headerClass: 'ag-center-aligned-header'},
      { field: 'actual_go_time', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.REMAININGTRIPS.LABEl.ACTGOTIME'}), sortable: true, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0} , resizable :true, maxWidth: 70 , minWidth: 70  ,headerClass: 'ag-center-aligned-header'}
  ])
  
  const setTripGone = (params) => {
    if(!params.data.scratch_trip)
    {
      isTimeDifferenceValid(true)
      params?.node?.setDataValue('gone_in', 1)
      setGoneStatus(params?.data?.class_id, params?.data?.entryxclasses_id, 1)
      setLastIngateActivity('Trip Gone')
    }
  }

  const onGridReady = useCallback((params) => {
    updateColumnDefToggle(params?.columnApi)
    
  }, []);

  const onFirstDataRendered = useCallback((params) => {
      params.api.sizeColumnsToFit();
  }, []);

  // this event will trigger on column sort
  const onSortChanged = useCallback((params) => {
    SaveModifiedColumnDefinitions(params.api.getColumnDefs(), 'Announcer Interface', currentUser, customer_id)
  });

  const defaultColDef = useMemo(() => {
    return {
      resizable: false,
      editable: false,
      wrapHeaderText: true
    };
  }, []);

  const searchUpcomingTrips = (event) => {
    if(filteredUpcomingTrips?.length > 0 && event.target.value && gridRef?.current?.api)
    {
      gridRef.current.api.forEachNode((node) => {//Find the searched entry number
        if(node.data.Entry.number == parseInt(event.target.value)){//if found make it the current trip
          let params = {
            node: node,
            data: node.data
          }
          selectCurrentTrip(params)
          event.target.value = ''
        }
      })
    }
  }

  const onKeyDown = (event) => {
    if (event.key == '-') {// Ignore when input is minus
      event.preventDefault();
    }else if(event.key === 'Enter'){// Trigger search on enter
      searchUpcomingTrips(event)
    }
  }

  const markAllTripsGone = (event) => {// Use for UnderSaddleClass to check off all trips at once
    const checked = event.target.checked ? true : false;
    if(upcomingTrips?.length <= 1 || !checked){//If single or no upcoming trip is present don't call webservice
      event.target.checked = false
      setTripsGoAtOnce(false)
      return
    }
    setTripsGoAtOnce(checked)
    let actual_order = maximumValueOfActualOrder
    axios.patch(`${NEST_API_URL}/ingate-announcer/markAllTripsAsGone`, {
        show_id: currentShowID,
        customer_id: customerID,
        class_group_id: currentClassGroup.class_group_id,
        actual_order: actual_order,
        gone: checked
    })
    .then(response => {
        if (response.data.success) {
            setUpcomingTrips(response?.data?.upcomingTrips)
            setClassesWithCompletedTrips(response?.data?.classCompletedTrips) 
            alertDialog({message: intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.LABEL.MARKALLTRIPSGONE.MESSAGE'})});  
        }
    })
    .catch((error) => {
        if (error.response) {
            alertDialog({message: error.response.data.error});
        }
    })
    .finally(() => {
      event.target.checked = false
      setTripsGoAtOnce(false)
    })
  }

  return (
    <>
    <div className='row mb-1'>
        <div className='col-lg-9 pe-0 mw-225px'>
            <div className='d-flex align-items-center position-relative'>
                <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-4 position-absolute ms-3'
                />
                <input
                type='number'
                data-kt-user-table-filter='search'
                className='form-control form-control ps-10 h-35px'
                placeholder={intl.formatMessage({id: 'TOOLBAR.LABEL.SEARCH'})}
                onBlur={searchUpcomingTrips}
                onKeyDown={onKeyDown}
                />
            </div>
        </div>

        { Boolean(preferences['AddEntryFromIngate']) &&
            <div className='col-lg-2 d-flex justify-content-start align-self-start pe-0'>
                <button type="button" disabled={showFinancialsLocked} className="btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px min-w-25px rect" onClick={props.handleOpen} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.BUTTON.ADD">
                    <i className="fas fa-plus fs-7" style={{"margin-top":"1px"}}></i>
                </button>
            </div>
        }
    </div>

    {
      selectedClassGroup && currentClassGroup &&
      <div className='row py-1'>
        <div className="col fw-bold" data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.LABEL.TRIPSREMAINING"> Trips Remaining {noOfNonScratchedUpcomingTrips} of {currentClassGroup?.total_trips} </div>
      </div>
    }

    <div className='row mb-1'>
        { currentClassGroup?.tripgoatonce ? 
          <div className='col-lg-6 mw-150px d-flex align-items-center'>
              <div className='form-check-sm form-check-custom  me-2'>
              <input
                  className='form-check-input'
                  type='checkbox'
                  id='TRIPSGOATONCE'
                  value={tripsGoAtOnce}
                  onChange={(e) => markAllTripsGone(e)}
                  checked={tripsGoAtOnce}
              />
              </div>
              <label className='col-form-label fs-6 py-0' htmlFor='TRIPSGOATONCE' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.LABEL.TRIPGOATONCE"> {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.LABEL.MARKALLTRIPSGONE'})}</label>
          </div> :
          <div className='col-lg-6 mw-150px d-flex align-items-center'>
              <div className='form-check-sm form-check-custom  me-2'>
              <input
                  className='form-check-input'
                  type='checkbox'
                  id='HIDEWARMUPTRIPS'
                  value= {hideWarmupClasses}
                  onChange={() => {setHideWarmupClasses(!hideWarmupClasses)}}
                  checked={hideWarmupClasses}
              />
              </div>
              <label className='col-form-label fs-6 py-0' htmlFor='HIDEWARMUPTRIPS' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.LABEL.HIDEWARMUP"> {hideWarmupClasses ? intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.LABEL.SHOWWARMUPTRIPS'}) : intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.LABEL.HIDEWARMUPTRIPS'})}</label>
          </div>
        }
        <div className='col-lg-6 d-flex align-items-center'>
            <div className='form-check-sm form-check-custom  me-2'>
            <input
                className='form-check-input'
                type='checkbox'
                id='HIDESCRATCHED'
                value= {hideScratched}
                onChange={() => setHideScratched(!hideScratched)}
                checked={hideScratched}
            />
            </div>
            <label className='col-form-label fs-6 py-0' htmlFor='HIDESCRATCHED' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.LABEL.HIDESCRATCHED"> {hideScratched ? intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.LABEL.SHOWSCRATCHED'}) : intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.LABEL.HIDESCRATCHED'})}</label>
        </div>
    </div>

    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine ingate-grid">
        <AgGridReact
          ref={gridRef}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={upcomingTrips ? filteredUpcomingTrips : []}
          rowHeight={20}
          headerHeight={30}
          onFirstDataRendered={onFirstDataRendered}
          onGridReady = {onGridReady}
          rowDragEntireRow={!nativeDragandDrop}
          rowDragManaged = {true}
          onRowDragEnter={(params) => {params.columnApi.applyColumnState({ defaultState: { sort: null } });}}//remove sort
          onDragStopped={(params) => { 
            reorderUpcomingTrips(params); 
            onSortChanged(params); 
          }}
          onRowDoubleClicked={(params) => {setTripGone(params)}}
          onSortChanged={onSortChanged}
          onRowClicked={selectCurrentTrip}
          rowClassRules= {{
            'scratched-row': function(params) { return params?.data?.scratch_trip },
          }} 
          suppressMoveWhenRowDragging = {false}
          suppressDragLeaveHidesColumns={true}
          animateRows = {true}      
          suppressAnimationFrame={true}
          enableRangeSelection={false}
          overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while remaining trips are loading.</span>'}
        ></AgGridReact>
      </div>

        <div className='row mb-1 mt-2'>
          <label className='col-lg-9 col-form-label fs-6 pe-0 py-1 red' htmlFor='trip_one_status'>{upcomingTripsWithUpdatedOrderOfGo?.length > 0 ? intl.formatMessage({id: 'ANNOUNCER.INGATEANNOUNCER.ORDER.OF.GO.SET.MESSAGE'}) : ''}</label>
          <div className='col-lg-3'>
            <div className='row flex justify-content-between'>
              <div className=' d-flex justify-content-end align-self-center'> 
                <button type="button" className='btn btn-sm btn-secondary w-100px py-1 px-1 fw-bold h-30px rect' onClick={(e) => {updateOrderOfGoForUpcomingTrips(e)}} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.BUTTON.SAVE"><span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.BUTTON.SAVE.ORDER'})}</button>
              </div>
            </div>
          </div>
        </div>
      
    </div>
    </>
  );
};

export default UpcomingTripsGrid;
  