import { useMemo, useEffect, useState, isValidElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../redux/hooks';
import { useFormContext } from 'react-hook-form'
import { useAlert } from '../../../../modules/sgl-utils/DialogsProvider';


// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';
import { getExtraNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers';

// Assets
import '../tab.css'
import { isValidJson } from '../../../../modules/sgl-utils/SglFunctions';
import HideJsonRenderer from '../../../../modules/output-listing/renderers/HideJsonRenderer';

const MembershipTab = (props) => {
    const intl = useIntl();
    const [gridRef, setGridRef] = useState()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const [loading, setLoading] = useState(true)
    const [rowData, setRowData] = useState()
    const methods = useFormContext()
    const alertDialog = useAlert()

    const columnDefs = [
        { field: 'key',  maxWidth: 245, minWidth:245, lockVisible: false, lockPosition: 'left', cellClass: 'text-start', cellStyle: {'border-right': '1px solid #eee'}
            , cellRenderer: 'agGroupCellRenderer',
            cellRendererSelector: function (params) {
                if (params.data.value != null && Array.isArray(params.data.value) && params.data.value?.length > 0) {
                    params.node.setExpanded(true);
                }
            }
        },
        { field: 'value', flex:1, lockVisible: false, lockPosition: 'left', cellClass: 'text-start', cellRenderer: HideJsonRenderer },
    ]

    const containerStyle = useMemo(() => ({ width: '100%', height: '400px' }), []);

    useEffect(() => {
        if(gridRef){
            if (props.tabname == "EC" && methods.getValues('horse.ec_lookup_response') && isValidJson(methods.getValues('horse.ec_lookup_response'))){
                parseLookupData(JSON.parse(methods.getValues('horse.ec_lookup_response')))
            }
            else if (props.tabname.toUpperCase() == "NORCAL" && methods.getValues('horse.norcal_lookup_response') && isValidJson(methods.getValues('horse.norcal_lookup_response'))){
                parseLookupData(JSON.parse(methods.getValues('horse.norcal_lookup_response')))
            }
            else if (props.tabname == "USHJA" && methods.getValues('horse.ushja_lookup_response') && isValidJson(methods.getValues('horse.ushja_lookup_response'))){
                parseLookupData(JSON.parse(methods.getValues('horse.ushja_lookup_response')))
            }
            else{
                gridRef.setRowData([])
            }
        }
    }, [gridRef, methods.getValues('horse.ec_lookup_response'), methods.getValues('horse.norcal_lookup_response'), methods.getValues('horse.ushja_lookup_response')])

    const parseLookupData = (lookup) => {
        if (lookup){
            var lookup_entries = Object.entries(lookup)
            let lookupResponseData = []
            lookup_entries.map(([key, value]) => {
                // if(value != null && Array.isArray(value)){
                //     value.map((data) => {
                //         let lookup = Object.entries(data)
                //         lookup.map(([key, value]) => {
                //             lookupResponseData.push({'key': key, "value": value})
                //         })
                //     })
                // }
                // else{
                    lookupResponseData.push({'key':key, "value":value})
                // }
            })
            gridRef.setRowData(lookupResponseData)
        }
    }

    const lookup_data = (associated_membership) => {
        let memObj = {}
        let org = props.tabname
        if(associated_membership){
            org = associated_membership
        }
        props.membershipGridState.forEachNode(node => {
          if(org == node.data.organization_code){
            memObj = JSON.parse(JSON.stringify(node.data))
          }
        });

        if(associated_membership){
            memObj.organization_code = props.tabname
        }
        
        if (props.tabname.toLowerCase() == "ec"){
            if(String(memObj.membership_number)?.trim() != ""){
                props.getLookupData(org, memObj, 'ecLookup')
            } else {
                alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.ERROR.EC.INVALID' })})
            } 
        }
        else if (props.tabname.toLowerCase() == "norcal"){
            if(String(memObj.membership_number)?.trim() != ""){
                props.getLookupData(org, memObj, 'norcalLookup', false, associated_membership)
            } else {
                alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.ERROR.NORCAL.INVALID' })})
            } 
        }else if (props.tabname.toLowerCase() == "ushja"){
            if(String(memObj.membership_number)?.trim() != ""){
                props.getLookupData(org, memObj, 'ushjaLookup')
            } else {
                alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.ERROR.USHJA.INVALID' })})
            } 
        }
    }

    return (
        <>
            <div className='form-group'>
                <div className='row align-items-center mb-2 membership-data-grid'>
                    <OutputListingGridStatic  area ={"Horses - Membership"} setGridRef={setGridRef} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle} rowHeight={getExtraNarrowRowHeight} suppressRowClickSelection={true} masterDetail={true} keepDetailRows={true} keepDetailRowsCount={2} detailRowAutoHeight={true}></OutputListingGridStatic>
                </div>
                <div className='row align-items-center mb-2'>
                    <div className='col-lg-5 w-350px'>
                        <button type="button" onClick={() => (lookup_data())} className="btn btn-sm btn-secondary fw-bold h-30px py-1 text-uppercase me-2">
                            {intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.UPDATE' })}
                        </button>

                        { props.tabname.toLowerCase() === 'norcal'? 
                            <button type="button" onClick={() => (lookup_data(props.tabname.toLowerCase() == 'norcal'? 'USEF': ''))} className="btn btn-sm btn-secondary fw-bold h-30px py-1 text-uppercase">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.BUTTON.'+ props.tabname.toUpperCase() })}
                            </button>
                            : 
                            null
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export { MembershipTab }