/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { date_options, maskDateInput, setDate } from '../../modules/sgl-utils/fieldControls'
import { Controller, useForm, FormProvider } from 'react-hook-form';
import moment from 'moment';
import axios from 'axios'
import { useAppSelector } from '../../redux/hooks';
import NonSystemORG from './NonSystemOrg'
import { NumericFormat } from 'react-number-format'
import ReactSelectComponent from '../../modules/sgl-utils/ReactSelectComponent'
import { getCustomOptionsFromArray, searchOptions, entriesOptions, entriesValueOptions, classesOptions, membershipOptions } from '../../modules/sgl-utils/reactSelectOptions'
import { useOutputContext } from '../../modules/output-listing/OutputListingContext'

// Constants
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL


type Props = {
  show: boolean
  onSubmitForm: ({}) => void,
  onClearFilter: () => void,
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SearchEntriesForm = ({show, onSubmitForm, onClearFilter, handleClose}: Props) => {
  const intl = useIntl()
  const customerID = useAppSelector(state => state.showCompany.company_id);
  const current_show_id =  useAppSelector(state => state.currentShow.show_id);
  const [fees, setFees] = useState<any[]>([]);
  const [horseTypes, setHorseTypes] = useState<any[]>([]);
  const [classTypes, setClassTypes] = useState<any[]>([]);
  const [riderStatuses, setRiderStatuses] = useState<any[]>([]);
  const [rings, setRings] = useState<any[]>([]);
  const [usef, setUSEF] = useState(false);
  const [ec, setEC] = useState(false);
  const [fei, setFEI] = useState(false);
  const [ushja, setUSHJA] = useState(false)
  const { register, watch, reset, getValues, control, setValue, formState} = useForm({});
  const context=useOutputContext()
  const defaultValues: { [key: string]: any } = {
    "entry_number": "",
    "balance_due": false, 
    "trainer_account": false, 
    "horse_name" : "",
    "rider_first_name": "",
    "rider_last_name": "",
    "trainer_first_name": "",
    "ec": false, 
    "trainer_last_name": "",
    "usef": false,
    "ushja": false, 
    "any_sanctioning_org": false,
    "membership":"",
    "trainer": false, 
    "owner_first_name": "",
    "owner": false, 
    "owner_last_name": "",
    "all_riders":"null",
    "any_rider": "null",
    "start_date": '0000-00-00',
    "end_date": '0000-00-00',
    "horse": false, 
    "responsile_party_first_name": "",
    "scheduling_in_ring": "",
    "horse_type": "",
    "rider_status": "",
    "responsile_party_last_name": "",
    "search":'replace',
    "balance_from":"0",
    "balance_to":"0",
    "prize_money_recipient_first_name": "",
    "riders": false,
    "measurement_card": false,
    "prize_money_recipient_last_name": "",
    "exact_match": false,
    "all_shows": false, 
    "stable_name": "",
    "classes_search":"",
    "class_number": "",
    "entries": "all_entries",
    "horse_age_verified": false, 
    "entries_value": "has",
    "fee": "",
    "fei": false,
    "nonSystemORG":[],
    "previous_record_ids":[],
    "class_type": ""
  }

  const methods = useForm({
    defaultValues: defaultValues
  })

  const handleReset = () => {
    setUSEF(false)
    setEC(false);
    setFEI(false);
    setUSHJA(false);
    methods.reset(defaultValues);
    methods.setValue('nonSystemORG', []);
    methods.setValue("start_date","")
    methods.setValue("end_date","")
  }
  const handleSave = async () => {
      var data = methods.getValues();
      onSubmitForm(data);
  }

  useEffect(() => {
    if(fees.length == 0 && show){
      getFees()
    }
  },[show]); // todo: removed fee from dependency list as it was causing infinite rendering

  useEffect(() => {
    methods.reset(context.outputAdvanceFilter,{keepDefaultValues:true})
  },[]);

  const getFees = async () =>{
    const response: any = await axios.get(`${NEST_API_URL}/entries/AdvanceSearchMetaData?customer_id=${customerID}&show_id=${current_show_id}`);
      setFees(response?.data?.fees ?? []);
      setHorseTypes(response?.data?.horse_type ?? []);
      setRiderStatuses(response?.data?.rider_status ?? []);
      setRings(response?.data?.rings ?? [])
      setClassTypes(response?.data?.class_type ?? [])
  }


  // get scheduled in rings options
  const getScheduledRingOptions = () => {
    const ringsOptions = rings?.map((ring) => ({value: ring.ring_id, label: ring?.ring_name ? ring.ring_name : ring?.Ring?.name}));
    ringsOptions?.unshift({label: 'Select', value: ''})
    return ringsOptions;
  }

  // change class number
  const changeClassNumber = () =>{
    if ((methods.watch('classes_search')==='Any of Class(es)')|| (methods.watch('classes_search')==='All of Class(es)')){
      return false
    } else {
      // value should be "" and will be hidden 
      // todo: commenting out following line as it was causing infinite rendering
      // methods.setValue('class_number', "", {shouldDirty: true}) 
      return true
    }
  }

  useEffect(()=> {
    if ((methods.watch('classes_search') === 'Any Class(es)') || (methods.watch('classes_search') === 'No Classes') && methods.watch('class_number') !== ''){
      methods.setValue('class_number', "", {shouldDirty: true}) 
    }
  },[methods.watch('classes_search')])

  // Change value and disable enable Organizations 
  // TODO: commented out setvalue calls as it was causing infinite rendering
  const changeValue = (value1: string,valueToChange: string) => {
    if(value1 == 'EC' && valueToChange == 'EC'){
      // methods.setValue('ec', ec, {shouldDirty: true})
      return false
    } else if(value1 == 'FEI' && valueToChange == 'FEI'){
      // methods.setValue('fei', fei, {shouldDirty: true})
      return false
    } else if(value1 == 'USEF' && valueToChange == 'USEF'){
      // methods.setValue('usef', usef, {shouldDirty: true})
      return false
    } else if(value1 == 'USHJA' && valueToChange == 'USHJA'){
      // methods.setValue('ushja', usef, {shouldDirty: true})
      return false
    }  
    else if(value1 == 'any_sanctioning_org'){
      if( valueToChange == 'USEF'){
        // methods.setValue('usef', false, {shouldDirty: true})
      } else if( valueToChange == 'EC'){
        // methods.setValue('ec', false, {shouldDirty: true})
      } else if( valueToChange == 'FEI'){
        // methods.setValue('fei', false, {shouldDirty: true})
      }
      return true
    } 
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      enforceFocus={false}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered modal-xl search-form'
      show={show}
      onHide={handleClose}
      onKeyPress={(event:any) => { //replaced onKeyPress with onKeyDown
        let isButtonFocused = false
        let activeElement = document.activeElement //gets the currently focussed element
        if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
            isButtonFocused = true;
        }
        if (event.key === "Enter" && !isButtonFocused) { //call function only when no button is in focus
          handleSave();
        }
        if (event.key === "Escape") {
          handleClose();
        }
      }}
      // onShow= {getFees}
    >
      <div className='modal-header py-0 px-4' >
        <h2 className="fs-4" >{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.TITLE.SEARCHENTRIES'})}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4'>
        <FormProvider {...methods}>
          <form noValidate className='form' onSubmit={e => e.preventDefault()}>
            <div className='card-body p-0'> {/* Card Body */}
              
            <div className="form-group row"> {/* Column Group */}

            <div className='col-lg-6'>{/* Column 1 */}

              <div className='row mb-2'>
                <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor="ENTRY">{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.ENTRY'})}</label>

                <div className='col-lg-3'>
                   <Controller
                    name="entry_number"
                    control={methods.control}
                    render={({ field }) => (
                      <NumericFormat
                        {...field}
                        id="ENTRY"
                        type="text" 
                        className="form-control form-control-sm fs-6 min-h-20px py-1"
                        placeholder={intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.ENTRY' })}
                        allowNegative={false}
                        decimalScale={0}
                        autoFocus
                        tabIndex={1}
                      />
                    )}
                  />
                </div>
                <div className='col-lg-3 d-flex align-items-center'>
                  <div className='form-check-sm form-check-custom  me-2'>
                    <input
                      className='form-check-input'
                      {...methods.register("balance_due")}
                      type='checkbox'
                      id='BALANCEDUE'
                      tabIndex={2}
                    />
                  </div>
                  <label className='form-check-label fs-6 fw-bold py-1' htmlFor="BALANCEDUE">{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.BALANCEDUE'})}</label>
                  
                </div>

                <div className='col-lg-3 d-flex align-items-center'>
                  <div className='form-check-sm form-check-custom  me-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      {...methods.register("trainer_account")}
                      id='TRAINERACCOUNT'
                      tabIndex={3}
                    />
                  </div>
                  <label className='form-check-label fs-6 fw-bold py-1' htmlFor="TRAINERACCOUNT">{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.TRAINERACCOUNT'})}</label>
                </div>
              </div>

              <div className='row mb-2'>
                <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='HORSENAME'>
                  <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.HORSE'})}</span>
                </label>

                <div className='col-lg-9 '>
                  <input
                    id='HORSENAME'
                    type='tel'
                    {...methods.register("horse_name")}
                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                    placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.HORSE'})}
                    tabIndex={4}
                  />
                </div>
              </div>

              
              <div className='row mb-2'>
                <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='RIDERFIRSTNAME'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.RIDER'})}</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-6 '>
                      <input
                        id='RIDERFIRSTNAME'
                        type='text'
                        {...methods.register("rider_first_name")}
                        className='form-control form-control-sm  mb-2 mb-lg-0  fs-6 min-h-20px py-1' 
                        placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.FIRSTNAME'})}
                        tabIndex={5}
                      />
                    </div>
                    
                    <div className='col-lg-6 '>
                      <input
                        type='text'
                        id='RIDERLASTNAME'
                        className='form-control form-control-sm   fs-6 min-h-20px py-1'
                        {...methods.register("rider_last_name")}
                        placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.LASTNAME'})}
                        tabIndex={6}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-2'>
                <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='TRAINERFIRSTNAME'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.TRAINER'})}</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-6 '>
                      <input
                        id='TRAINERFIRSTNAME'
                        type='text'
                        {...methods.register("trainer_first_name")}
                        className='form-control form-control-sm  mb-2 mb-lg-0  fs-6 min-h-20px py-1' 
                        placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.FIRSTNAME'})}
                        tabIndex={7}
                      />
                    </div>
                    
                    <div className='col-lg-6 '>
                      <input
                        type='text'
                        id='TRAINERLASTNAME'
                        className='form-control form-control-sm   fs-6 min-h-20px py-1'
                        {...methods.register("trainer_last_name")}
                        placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.LASTNAME'})}
                        tabIndex={8}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-2'>
                <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='OWNERFIRSTNAME'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.OWNER'})}</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-6 '>
                      <input
                        id='OWNERFIRSTNAME'
                        type='text'
                        {...methods.register("owner_first_name")}
                        className='form-control form-control-sm  mb-2 mb-lg-0  fs-6 min-h-20px py-1' 
                        placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.FIRSTNAME'})}
                        tabIndex={9}
                      />
                    </div>
                    
                    <div className='col-lg-6 '>
                      <input
                        type='text'
                        id='OWNERLASTNAME'
                        className='form-control form-control-sm   fs-6 min-h-20px py-1'
                        {...methods.register("owner_last_name")}
                        placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.LASTNAME'})}
                        tabIndex={10}
                      />
                    </div>
                  </div>
                </div>
              </div>


              <div className='row mb-2'>
                <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor="RESPPARTYFIRSTNAME">{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.RESPPARTY'})}</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-6 '>
                      <input
                        id='RESPPARTYFIRSTNAME'
                        {...methods.register("responsile_party_first_name")}
                        type='text'
                        className='form-control form-control-sm  mb-2 mb-lg-0  fs-6 min-h-20px py-1' 
                        placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.FIRSTNAME'})}
                        tabIndex={11}
                      />
                    </div>
                    
                    <div className='col-lg-6 '>
                      <input
                        type='text'
                        id='RESPPARTYLASTNAME'
                        {...methods.register("responsile_party_last_name")}
                        className='form-control form-control-sm   fs-6 min-h-20px py-1'
                        placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.LASTNAME'})}
                        tabIndex={12} 
                      />
                    </div>
                  </div>
                </div>
              </div>


              <div className='row mb-2'>
                <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='PRIZEMONEYRECIPIENTFIRSTNAME'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.PRIZE'})}</label>

                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-6 '>
                      <input
                        id='PRIZEMONEYRECIPIENTFIRSTNAME'
                        {...methods.register("prize_money_recipient_first_name")}
                        type='text'
                        className='form-control form-control-sm  mb-2 mb-lg-0  fs-6 min-h-20px py-1' 
                        placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.FIRSTNAME'})}
                        tabIndex={13}
                      />
                    </div>
                    
                    <div className='col-lg-6 '>
                      <input
                        id='PRIZEMONEYRECIPIENTLASTNAME'
                        {...methods.register("prize_money_recipient_last_name")}
                        type='text'
                        className='form-control form-control-sm   fs-6 min-h-20px py-1'
                        placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.LASTNAME'})}
                        tabIndex={14}
                      />
                    </div>
                  </div>
                </div>
              </div>


              <div className='row mb-2'>
                <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='STABLENAME'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.STABLENAME'})}</label>

                <div className='col-lg-9 '>
                  <input
                    id='STABLENAME'
                    {...methods.register("stable_name")}
                    type='text'
                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                    placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.STABLENAME'})}
                    tabIndex={15}
                  />
                </div>
              </div>

              <fieldset className='mb-7 mt-7'>
                <legend className="fs-4 fw-bold d-flex">{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.GROUPLABEL.FINANCIALS'})} <b></b></legend>
                <div className='row mb-2'>
                  <div className='col-lg-12'>
                    <ReactSelectComponent 
                      name='entries'
                      options={entriesOptions} 
                      control={methods.control} 
                      methods={methods} 
                      tabIndex={16}
                    />
                  </div>
                </div>

                <div className='row mb-2'>
                  <div className='col-lg-3'>                    
                      <ReactSelectComponent 
                        name='entries_value' 
                        options={entriesValueOptions} 
                        control={methods.control} 
                        methods={methods} 
                        tabIndex={17}
                      />
                  </div>

                  <label className='col-lg-1 col-form-label fs-5 fw-bold py-1' htmlFor='FEE'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.FEE'})}</label>

                  <div className='col-lg-8 align-items-center'>
                      <ReactSelectComponent 
                        name='fee'
                        id='FEE'
                        options={getCustomOptionsFromArray(fees, 'showfees_id', 'description', {label: 'Select', value: ''})} 
                        control={methods.control} 
                        methods={methods} 
                        tabIndex={18}
                      />
                  </div>
                </div>

                <div className='row mb-2'>
                  <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='BALANCEBETWEEN'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.BALANCEBETWEEN'})}</label>

                  <div className='col-lg-4 '>
                    <input
                      id='BALANCEBETWEEN'
                      {...methods.register("balance_from")}
                      type='text'
                      className='form-control form-control-sm  fs-6 min-h-20px py-1'
                      placeholder="0"
                      tabIndex={19}
                    />
                  </div>

                  <label className='col-lg-1 col-form-label fs-5 fw-bold py-1' htmlFor='AND'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.AND'})}</label>

                  <div className='col-lg-4'>
                    <input
                        id='AND'
                        {...methods.register("balance_to")}
                        type='text'
                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        placeholder="0"
                        tabIndex={20}
                      />
                  </div>
                </div>

              </fieldset>


              <div className='row mb-2'>
                <div className='col-lg-9 offset-lg-3 d-flex align-items-center'>
                  <div className='form-check-sm form-check-custom me-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        {...methods.register("exact_match")}
                        id='EXACTMATCHES'
                        tabIndex={21}
                      />
                  </div>

                  <label className='col-form-label fs-5 fw-bold py-1 w-lg-145px pe-0' htmlFor='EXACTMATCHES'>
                    <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.COMMON.LABEL.EXACTMATCHES'})}</span>
                  </label>
                </div>
              </div>

              <div className='row mb-2'>
                <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='SEARCHBEHAVIOR'>
                  <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.SEARCHBEHAVIOR'})}</span>
                </label>

                <div className='col-lg-9 '>
                  <ReactSelectComponent 
                    name='search' 
                    id='SEARCHBEHAVIOR'
                    options={searchOptions} 
                    control={methods.control} 
                    methods={methods} 
                    tabIndex={22}
                  />
                </div>
              </div>

              {/* <div className='row mb-2'>
                <div className='col-lg-9 offset-lg-3 d-flex align-items-center'>
                  <div className='form-check-sm form-check-custom me-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        {...methods.register("all_shows")}
                        id='ALLSHOWS'
                      />
                  </div>

                  <label className='col-lg-3 col-form-label fs-5 fw-bold py-1 w-lg-145px pe-0' htmlFor='ALLSHOWS'>
                    <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.ALLSHOWS'})}</span>
                  </label>
                </div>
              </div> */}
              
              </div>{/* End Column 1 */}

            
            <div className='col-lg-6 '>{/* Column 2 */}
            <fieldset>
                <legend className="fs-4 fw-bold d-flex">{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.GROUPLABEL.CLASSPARTICIPATION'})} <b></b></legend>
                <div className='row mb-2'>
                  <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='INCLASS'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.INCLASS'})}</label>
                  <div className="col">
                    <div className='row mb-2'>
                      <div className='col-lg-6 '>
                        <ReactSelectComponent 
                          name='classes_search'
                          id='INCLASS'
                          options={classesOptions}
                          control={methods.control}
                          methods={methods}
                          tabIndex={23}
                        />
                      </div>
                      
                      <div className='col-lg-5 '>
                        <input
                          id='CLASSNUMBER'
                          type='text'
                          
                          {...methods.register("class_number")}
                          hidden={changeClassNumber()}
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                          placeholder={intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.CLASSNUMBER'})}
                          tabIndex={24}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-2'>
                  <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='INCLASS'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.CLASSTYPE'})}</label>
                  <div className="col">
                    {/* Class Type */}
                    <div className='row mb-2'>
                      <div className='col-lg-12 '>
                        <ReactSelectComponent 
                          name='class_type'
                          id='class_type'
                          options={getCustomOptionsFromArray(classTypes, 'value', 'label', {label: 'Select', value: ''})} 
                          control={methods.control}
                          methods={methods}
                          tabIndex={25}
                        />
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='HORSEAGEVERIFIED'
                            {...methods.register("horse_age_verified")}
                            tabIndex={26}
                          />
                        </div>
                        <label className='col-form-label fs-5 fw-bold py-1' htmlFor='HORSEAGEVERIFIED'> {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.HORSEAGE'})}</label>
                      </div>

                      <div className='col d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            {...methods.register("measurement_card")}
                            id='MEASUREMENTCARD'
                            tabIndex={27}
                          />
                        </div>
                        <label className='col-form-label fs-5 fw-bold py-1' htmlFor='MEASUREMENTCARD'> {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.MEASUREMENTCARD'})}</label>
                      </div>
                    </div>
                  </div>
                </div>

                

                <div className='row mb-2'>
                  <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='HORSETYPE'>
                    <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.HORSETYPE'})}</span>
                  </label>

                  <div className='col-lg-9 '>
                    <ReactSelectComponent 
                      name='horse_type' 
                      id='HORSETYPE'
                      options={getCustomOptionsFromArray(horseTypes, 'value', 'label', {label: 'Select', value: ''})} 
                      control={methods.control} 
                      methods={methods} 
                      tabIndex={28}
                    />
                  </div>
                </div>

                <div className='row mb-2'>
                  <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='RIDERSTATUS'>
                    <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.RIDERSTATUS'})}</span>
                  </label>

                  <div className='col-lg-9 '>
                    <ReactSelectComponent 
                      name='rider_status' 
                      id='RIDERSTATUS'
                      options={getCustomOptionsFromArray(riderStatuses, 'value', 'label', {label: 'Select', value: ''})} 
                      control={methods.control} 
                      methods={methods}
                      tabIndex={29}
                    />
                  </div>
                </div>

                <div className='row mb-2'>
                  <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='SCHEDULEDINRING'>
                    <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.SCHEDULEDINRING'})}</span>
                  </label>

                  <div className='col-lg-9 '>
                    <ReactSelectComponent 
                      name='scheduling_in_ring'
                      id='SCHEDULEDINRING'
                      options={getScheduledRingOptions()} 
                      control={methods.control} 
                      methods={methods}
                      tabIndex={30}
                    />
                  </div>
                </div>

                <div className='row mb-2'>
                  <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='ONDAYS'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.ONDAYS'})}</label>

                  <div className='col-lg-4 '>
                      <div className="input-group date">
                          <Controller
                              name="start_date"
                              control={methods.control}
                              render={({
                                  field: { onChange, value, name },
                              }) => {
                                  const formattedValue = (value && moment(value).isValid()) 
                                      ? moment(value).format('MM/DD/YYYY') 
                                      : '';

                                  return (
                                      <Flatpickr 
                                          className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                                          id="DATESFROM"
                                          name={name}
                                          autoComplete="off"
                                          value={formattedValue}
                                          placeholder="MM/DD/YYYY"
                                          options={date_options}
                                          onClose={(value: any[], dateStr: moment.MomentInput, instance: { input: { value: string } }) => {
                                              const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                              if (!isValidDate) {
                                                  instance.input.value = ''; // Clear the input if the date is invalid
                                              }
                                              setDate(dateStr, onChange, null); // Set the typed/selected date in the datepicker and also format date to YYYY-MM-DD (this is the format that backend accepts)
                                          }}
                                          onChange={(value: any[], dateStr: moment.MomentInput, instance: { input: { value: string } }) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                            }
                                            setDate(dateStr, onChange, null); // Set the typed/selected date in the datepicker and also format date to YYYY-MM-DD (this is the format that backend accepts)
                                        }}
                                          onInput={(e: any) => { maskDateInput(e) }}
                                          tabIndex={31}
                                      />
                                  );
                              }}
                          />
                          <div className="input-group-append">
                            <label htmlFor='DATESFROM' className='date-label'
                              onClick={(e) => {
                                e.stopPropagation();
                                const atModal = document.getElementById('DATESFROM') as HTMLElement & { _flatpickr?: { open: () => void } };
                                if (atModal?._flatpickr) {
                                    atModal._flatpickr.open();
                                }
                              }}
                            >
                              <span className="input-group-text">
                                  <i className="la la-calendar"></i>
                              </span>
                            </label>
                          </div>
                      </div>
                  </div>

                  <label className='col-lg-1 col-form-label fs-5 fw-bold py-1' htmlFor='TO'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.TO'})}</label>

                  <div className='col-lg-4'>
                      <div className="input-group date">
                          <Controller
                              name="end_date"
                              control={methods.control}
                              render={({
                                  field: { onChange, value, name },
                              }) => {
                                  const formattedValue = (value && moment(value).isValid()) 
                                      ? moment(value).format('MM/DD/YYYY') 
                                      : '';

                                  return (
                                      <Flatpickr 
                                          className="form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1"
                                          id="DATESTO"
                                          name={name}
                                          autoComplete="off"
                                          value={formattedValue}
                                          placeholder="MM/DD/YYYY"
                                          options={date_options}
                                          onClose={(value: moment.MomentInput[], dateStr: moment.MomentInput, instance: { input: { value: string } }) => {
                                              const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                              if (!isValidDate) {
                                                  instance.input.value = ''; // Clear the input if the date is invalid
                                                  // Handle invalid date scenario if needed
                                              }
                                              setDate(dateStr, onChange, null); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                          }}
                                          onChange={(value: moment.MomentInput[], dateStr: moment.MomentInput, instance: { input: { value: string } }) => {
                                            const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                            if (!isValidDate) {
                                                instance.input.value = ''; // Clear the input if the date is invalid
                                                // Handle invalid date scenario if needed
                                            }
                                            setDate(dateStr, onChange, null); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                          }}
                                          onInput={(e: any) => { maskDateInput(e) }}
                                          tabIndex={32}
                                      />
                                  );
                              }}
                          />
                          <div className="input-group-append">
                            <label htmlFor='DATESTO' className='date-label'
                              onClick={(e) => {
                                e.stopPropagation();
                                const atModal = document.getElementById('DATESTO') as HTMLElement & { _flatpickr?: { open: () => void } };
                                if (atModal?._flatpickr) {
                                    atModal._flatpickr.open();
                                }
                              }}
                            >
                                <span className="input-group-text">
                                    <i className="la la-calendar"></i>
                                </span>
                              </label>
                          </div>
                      </div>
                  </div>
                </div>
            </fieldset>

            <fieldset className="mt-7">
                <legend className="fs-4 fw-bold d-flex">{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.GROUPLABEL.MEMBERSHIP'})} <b></b></legend>

                <div className='row mb-2'>
                  <div className='col-lg-5 '>
                    <label className='col-lg-3 col-form-label fs-5 fw-bold py-1' htmlFor='ROLE'>
                      <span className=''>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.ROLE'})}</span>
                    </label>
                    <div className='row mb-2'>
                      <div className='form-check-sm form-check-custom  mx-6'>
                        <input
                          id='HORSE2'
                          className='form-check-input'
                          {...methods.register("horse")}
                          type='checkbox'
                          tabIndex={33}
                        />
                        <label className='form-check-label fs-6 fw-bold' htmlFor='HORSE2'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.HORSE'})}</label>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='form-check-sm form-check-custom  mx-6'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='RIDERS'
                          {...methods.register("riders")}
                          onChange={(e) => {
                            if(e.target.checked){
                              methods.setValue('riders', true, {shouldDirty: true})
                              methods.setValue('any_rider', '', {shouldDirty: true})
                              methods.setValue('all_riders', "null", {shouldDirty: true})
                            } else {
                              methods.setValue('riders', false, {shouldDirty: true})
                              methods.setValue('any_rider', "null", {shouldDirty: true})
                              methods.setValue('all_riders', "null", {shouldDirty: true})
                            }
                          }}
                          tabIndex={34}
                        />
                        <label className='form-check-label fs-6 fw-bold' htmlFor='RIDERS'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.RIDERS'})}</label>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='form-check-sm form-check-custom  mx-12 form-check-m'>
                        <input
                          className='form-check-input me-2'
                          type='radio'
                          id='ANYRIDER'
                          {...methods.register("any_rider")}
                          value=''
                          onChange={(e) => {
                            methods.setValue('any_rider', '', {shouldDirty: true})
                            methods.setValue('all_riders', "null", {shouldDirty: true})
                          }}
                          disabled={(methods.watch('riders'))?false:true}
                          tabIndex={35}
                        />
                        <label className='form-radio-label fs-6 fw-bold' htmlFor='ANYRIDER'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.ANYRIDER'})}</label>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='form-check-sm form-check-custom  mx-12 form-check-m'>
                        <input
                          className='form-check-input me-2'
                          type='radio'
                          id='ALLRIDER'
                          {...methods.register("all_riders")}
                          value=''
                          onChange={(e) => {
                            methods.setValue('any_rider', "null", {shouldDirty: true})
                            methods.setValue('all_riders', '', {shouldDirty: true})
                          }}
                          disabled={(methods.watch('riders'))?false:true}
                          tabIndex={36}
                        />
                        <label className='form-radio-label fs-6 fw-bold' htmlFor='ALLRIDER'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.ALLRIDER'})}</label>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='form-check-sm form-check-custom  mx-6'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='OWNER'
                          {...methods.register("owner")}
                          tabIndex={37}
                        />
                        <label className='form-check-label fs-6 fw-bold' htmlFor='OWNER'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.OWNER'})}</label>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='form-check-sm form-check-custom  mx-6'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='TRAINER'
                          {...methods.register("trainer")}
                          tabIndex={38}
                        />
                        <label className='form-check-label fs-6 fw-bold' htmlFor='TRAINER'>{intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.TRAINER'})}</label>
                      </div>
                    </div>
                  </div>


                  <div className='col-lg-7 '>
                    <div className='row mb-2'>
                      <div className='col-lg-12 '>
                        <ReactSelectComponent 
                          name='membership'
                          id='TRAINER'
                          options={membershipOptions} 
                          control={methods.control} 
                          methods={methods} 
                          tabIndex={39}
                        />
                      </div>
                    </div>

                    <div className='row mb-0'>
                      <div className='col-lg-12 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            {...methods.register("any_sanctioning_org")}
                            id='ANYSANCTIONING'
                            tabIndex={40}
                          />
                      </div>
                      <label className='col-form-label fs-5 fw-bold py-1' htmlFor='ANYSANCTIONING'> {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.ANYSANCTIONING'})}</label>
                      </div>
                    </div>


                    <div className='row mb-2 px-4'>
                      <div className='col-lg-4 d-flex align-items-center mw-100px'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            {...methods.register("usef")}
                            onChange={(e) => {
                              setUSEF(e.target.checked)
                            }}
                            disabled= {methods.watch("any_sanctioning_org")?changeValue('any_sanctioning_org', 'USEF'):changeValue('USEF', 'USEF')}
                            id='USEF'
                            tabIndex={41}
                          />
                        </div>
                      <label className='col-form-label fs-5 fw-bold py-1' htmlFor='USEF'> {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.USEF'})}</label>
                      </div>

                      <div className='col-lg-4 d-flex align-items-center mw-100px'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            {...methods.register("ushja")}
                            onChange={(e) => {
                              setUSHJA(e.target.checked)
                            }}
                            disabled= {methods.watch("any_sanctioning_org")?changeValue('any_sanctioning_org', 'USHJA'):changeValue('USHJA', 'USHJA')}
                            id='USHJA'
                            tabIndex={42}
                          />
                        </div>
                      <label className='col-form-label fs-5 fw-bold py-1' htmlFor='USHJA'> {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.USHJA'})}</label>
                      </div>

                      <div className='col-lg-3 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            {...methods.register("ec")}
                            onChange={(e) => {
                              setEC(e.target.checked)
                            }}
                            disabled= {methods.watch("any_sanctioning_org")?changeValue('any_sanctioning_org', 'EC'):changeValue('EC', 'EC')}
                            id='EC'
                            tabIndex={43}
                          />
                        </div>
                      <label className='col-form-label fs-5 fw-bold py-1' htmlFor='EC'> {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.EC'})}</label>
                      </div>

                      <div className='col-lg-3 d-flex align-items-center'>
                        <div className='form-check-sm form-check-custom  me-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            {...methods.register("fei")}
                            onChange={(e) => {
                              setFEI(e.target.checked)
                            }}
                            disabled= {methods.watch("any_sanctioning_org")?changeValue('any_sanctioning_org', 'FEI'):changeValue('FEI', 'FEI')}
                            id='FEI'
                            tabIndex={44}
                          />
                        </div>
                      <label className='col-form-label fs-5 fw-bold py-1' htmlFor='FEI'> {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.LABEL.FEI'})}</label>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <div className='col-lg-12 '>
                        <NonSystemORG tabIndex={45}/>
                      </div>
                    </div>

                  </div>
                </div>
            </fieldset>
            </div>

            </div> {/* End Column Group */}


            </div>{/* End Card Body */}

            <div className='card-footer d-flex justify-content-end py-3 px-0'>
              <button type="button" className='btn btn-sm btn-secondary fw-bold'  onClick={handleReset} tabIndex={46}>
                {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CLEARVALUES'})}
              </button>

              <button type="button" className='btn btn-sm btn-secondary me-4 fw-bold' style={{marginLeft:"auto"}} onClick={handleClose} tabIndex={47}>
                {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL'})}
              </button>

              <button type="button" className='btn btn-sm btn-dark fw-bold' onClick={handleSave} tabIndex={48}>
                {intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.SEARCH'})}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {SearchEntriesForm}
