import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "",
    description: "",   
    sponsor: "",          
    ec_section_code: "",
    usef_section_code: "", 
    class_type: "",    
    level: "",              
    unit: "",
    class_price: "",
    division_id: "",
    required: "",
    division_price: "", 
    schedule_sequencetype: "" 
}

const previousClassDetailsSlice = createSlice({
    name: 'previousClassDetails',
    initialState: initialState,
    reducers: {
      setPreviousClassDetails(state, action) {
        let classDetails = action.payload;
        return {
          ...state,
          name: classDetails.name,   
          description: classDetails.description,   
          sponsor: classDetails.sponsor,          
          ec_section_code: classDetails.ec_section_code,
          usef_section_code: classDetails.usef_section_code, 
          class_type: classDetails.class_type,    
          level: classDetails.level,              
          unit: classDetails.unit,
          class_price: classDetails.class_price,
          division_id: classDetails.division_id,
          required: classDetails.required,
          division_price: classDetails.division_price,
          schedule_sequencetype: classDetails.schedule_sequencetype
        }
      },

      clearPreviousClassDetails() {
        return {
            ...initialState
        }
      }
    },
  })
  
  export const { setPreviousClassDetails, clearPreviousClassDetails } = previousClassDetailsSlice.actions

  export default previousClassDetailsSlice.reducer