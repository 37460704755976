import {useIntl} from 'react-intl'
import {useAppSelector} from '../../redux/hooks'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import { useEffect, useState} from 'react'
import {useForm, FormProvider} from 'react-hook-form'
import {useAlert, useLoadingOverlay} from '../../modules/sgl-utils/DialogsProvider'
import {useNavigate} from 'react-router-dom'
import { ShowsTab } from './Detail/ShowsTab'
import { FacilityTab } from './Detail/FacilityTab'
import { HistoryTab } from '../../modules/components/HistoryTab'

// Custom Components
import {InputFormFooter} from '../../modules/sgl-utils/InputFormFooter'
import {isInvalidRecord} from '../../modules/sgl-utils/SglFunctions'
import {PageTitle} from '../../../_metronic/layout/core'
import history from '../../modules/sgl-utils/unstableHistory'
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt'
import { useConfirm } from '../../modules/sgl-utils/DialogsProvider'
import useAutoFocus from '../../modules/hooks/use-auto-focus'
import HelpLink from '../../modules/components/HelpLink'

const { PUBLIC_URL } = process.env

export const dirtyValues = (dirtyFields, allValues) => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])])
  )
}

const RingsDetail = (props) => {
  const intl = useIntl()
  const {ring_id} = useParams()
  const customer_id = useAppSelector((state) => state.showCompany.company_id)
  const alertDialog = useAlert()
  const navigate = useNavigate()
  const confirmDialog = useConfirm()
  const loadingOverlay = useLoadingOverlay()
  const [loading, setLoading] = useState(true)
  const methods = useForm({
    defaultValues: {
      ring: {
        name: '',
        active: '',
      },
      update_ring_name: 0
    },
  })

  const {handleSubmit, reset, formState, setFocus} = methods

  const tabs = ["FACILITIES", "SHOWS", "HISTORY"]
  const componentTags = [<FacilityTab/>, <ShowsTab />,  <HistoryTab area="Rings" rowData={methods.getValues("change_history_logs")} />]
  const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	});

  const {dirtyFields} = formState
  useNavigationPrompt(formState)
  const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
  const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);

	useEffect(() => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');
		if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
			next_button.disabled = true;
			last_button.disabled = true;
		}
		if (row_selected == 0 && prev_button && first_button) {
			prev_button.disabled = true;
			first_button.disabled = true;
		}
	}, [loading])

  const onSubmit = async (form_data) => {
    let submit_data = dirtyValues(dirtyFields, form_data)
    let message = ''
    if (ring_id > 0 && submit_data?.ring?.name && methods.getValues("future_shows_count") > 0) {
      const futureShowsCount = methods.getValues("future_shows_count");
      if (futureShowsCount === 1 && methods.getValues('linkedFacilities')?.length == 1) {
        message += `Would you like to apply ring name update to the linked facility and upcoming (${futureShowsCount}) show?`;
      } else if (futureShowsCount === 1) {
        message += `Would you like to apply ring name update to all the linked facilities (${methods.getValues('linkedFacilities')?.length}) and upcoming (${futureShowsCount}) show?`;
      } 
      else {
        message += `Would you like to apply ring name update to all linked facilities (${methods.getValues('linkedFacilities')?.length}) and upcoming (${futureShowsCount}) shows?`;
      }
      let choice = await confirmDialog({ message });
      if (choice) {
        methods.setValue('update_ring_name', 1, {shouldDirty: true})
        // Manually update the value of the field in form_data
        form_data['update_ring_name'] = 1
      }
    }else if(ring_id > 0 && submit_data?.ring?.name && methods.getValues('linkedFacilities')?.length > 0){
      if (methods.getValues('linkedFacilities')?.length == 1) {
        message += `Would you like to apply ring name update to the linked facility?`;
      } else {
        message += `Would you like to apply ring name update to all the linked (${methods.getValues('linkedFacilities')?.length}) facilities?`;
      }
      let choice = await confirmDialog({ message });
      if (choice) {
        methods.setValue('update_ring_name', 1, {shouldDirty: true})
        // Manually update the value of the field in form_data
        form_data['update_ring_name'] = 1
      } 
    }

    submit_data = dirtyValues(dirtyFields, form_data)
    if(ring_id == 0 && submit_data.ring.active === undefined){
         submit_data.ring.active = true
    }

    const next_button = document.getElementById('next-btn');
    const last_button = document.getElementById('last-btn');
    const prev_button = document.getElementById('prev-btn');
    const first_button = document.getElementById('first-btn');

    // means detail page is not open in modal
    if(props?.id === undefined){
      next_button.disabled = false;
      prev_button.disabled = false;
      last_button.disabled = false;
      first_button.disabled = false;
    }


    loadingOverlay({show: true})
    return axios
      .post(process.env.REACT_APP_NEST_API_URL + '/rings/detail', {
        params: {
          ring_id,
          customer_id,
          data: submit_data,
        },
      })
      .then((response) => {
        loadingOverlay({show: false})
        if (response.data.success) {
          // Use Case (Param Id changed): Next/Prev buttons
          if(props?.id == undefined && Number(methods.getValues('ring.ring_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
            if(row_selected && row_selected != "" && row_ids[row_selected]){
              setLoading(true)
              history.push(`${PUBLIC_URL}/rings/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
            }
          }
          else if(props?.id == undefined && ring_id > 0){
              history.push(`${PUBLIC_URL}/rings`, { allowNavigation: true })
          }
          else if(props?.id == undefined && ring_id == 0){
            history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
          }
          
          if( row_selected == row_ids?.length -1 && props?.id === undefined){
            if (next_button && last_button) {
                next_button.disabled = true;
                last_button.disabled = true;
                prev_button.disabled = false;
                first_button.disabled = false;
            }
          }
          if(row_selected == 0 && props?.id === undefined){
            if (prev_button && first_button) {
                prev_button.disabled = true;
                first_button.disabled = true;
                next_button.disabled = false;
                last_button.disabled = false;
            }
          }
        } else {
          alertDialog({message: response.data.error_message, title: 'Error'})
        }
      })
  }
  
  useEffect(() => {
    // Show loading overlay
    loadingOverlay({show: true})
    axios
      .all([
        axios.get(process.env.REACT_APP_NEST_API_URL + '/rings/detail', {
          params: {
            ring_id,
            customer_id,
          },
        }),
      ])
      .then(
        axios.spread(({data: ringData}) => {
          //Check if record id is invalid then redirect to list page
          if ((ringData || ringData === '') && isInvalidRecord(ringData.ring, ring_id, 'ring_id')) {
            navigate('/rings')
            loadingOverlay({show: false})
          }

          if (ring_id == 0) {
            ringData['ring']['active'] = true
          }

          reset(ringData)
          // Hide loading overlay
          loadingOverlay({show: false})
          setLoading(false)
        })
      )
  }, [ring_id])

  useAutoFocus('NAME', loading)

  return (
    <>
    	{!loading && (
			<>
      {props?.id == undefined ? (
        <PageTitle breadcrumbs={[]} description={[methods.watch('ring.name'), methods.getValues('ring.ring_id')]}>{intl.formatMessage({id: 'MENU.RINGS'})}</PageTitle>
      ) : (
        ''
      )}
      <FormProvider {...methods}>
        <div id={"rings_detail_page"} className='modal-body py-3 px-4 showfee-input-form input-form'>
          <form noValidate className='form max-width' onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }>
          <HelpLink tooltip="RINGS.DETAIL.LINK.HELP" classname="top" />
            <div className='card-body p-0'>
              {' '}
              {/* Card Body */}
              <div className='form-group row'>
                <div className='row mb-2'>
                  {/* Column Group */}
                  <div className='col-lg-6'>
                    {/* Column 1 */}
                    <div className='row mb-2'>
                      <label className='col-lg-2 col-form-label fs-5 fw-bold py-1' htmlFor='NAME' data-tooltip-id="RINGS.DETAIL.LABEL.NAME">
                        {intl.formatMessage({id: 'FORM.INPUT.RING.LABEL.NAME'})}
                      </label>
                      <div className='col-lg-8'>
                        <input
                          {...methods.register('ring.name', {
                            required: 'Ring name is required.',
                          })}
                          id='NAME'
                          type='text'
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                          placeholder={intl.formatMessage({id: 'FORM.INPUT.RING.LABEL.NAME'})}
                        />
                        {methods.formState.errors?.ring?.name && (
                          <div>
                            <span className='error_message'>
                              {methods.formState.errors?.ring.name?.message}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* End Column 1 */}
                  </div>
                  <div className='col-lg-6'>
                    {/* Column 2 */}
                    <div className='row mb-2'>
                      <div className='col-lg-8 form-check-sm form-check-custom ps-lg-3'>
                        <input
                          {...methods.register('ring.active')}
                          id='ACTIVE'
                          type='checkbox'
                          className='form-check-input'
                        />
                        <label
                          className='col-lg-2 col-form-label fs-5 fw-bold py-1'
                          htmlFor='ACTIVE'
                          style={{marginLeft: '10px'}}
                          data-tooltip-id="RINGS.DETAIL.LABEL.ACTIVE"
                        >
                           {intl.formatMessage({id: 'FORM.INPUT.RING.LABEL.ACTIVE'})}
                        </label>
                      </div>
                    </div>
                    {/* End Column 2 */}
                  </div>
                  {/* End Column Group */}
                    <div className='d-flex flex-column my-2 border p-2 bg-white'>
                      <ul
                        tabIndex="-1"
                        className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap'
                        id='myTab'
                        role='tablist'
                        style={{overflowX: 'auto', overflowY: 'hidden'}}
                      >
                        {tabs.map((tab, index) => {
                          return (
                            <li 
                                key={index}
                                className='nav-item' 
                                onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}
                            >
                              <a
                                tabIndex="-1"
                                className={
                                  'nav-link text-active-dark px-10 py-1 text-gray-700' +
                                  (index == selectedTab ? ' active' : '')
                                }
                                id={tab + '-tab'}
                                data-bs-toggle='tab'
                                href={'#' + tab}
                                data-tooltip-id={`BARNS.DETAIL.TAB.${tab}`}
                              >
                                {intl.formatMessage({id: 'FORM.INPUT.RINGS.TAB.' + tab})}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                      <div className='tab-content mt-5 pb-2' id='myTabContent'>
                        {tabs.map((tab, index) => {
                          return (
                            <div
                              key={index} 
                              className={'tab-pane fade show' + (index == selectedTab ? ' active' : '')}
                              id={tab}
                              role='tabpanel'
                            >
                              <HelpLink tooltip={"RINGS.DETAIL.TAB."+tab+".LINK.HELP"}  />
                              {componentTags[index]}
                            </div>
                          )
                        })}
                      </div>
                    </div> 
                </div>
              </div>{' '}
              {/* End Card Body */}
            </div>{' '}
            {/* End Card Body */}
            <InputFormFooter goBackPath={'/rings'}  data={'ring'}/>
          </form>
        </div>
      </FormProvider>
      </>
    	)}
    </>
  )
}

export {RingsDetail}
