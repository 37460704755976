import React, {useState, useEffect} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider'
import { useOutputContext, useOutputContextUpdater} from '../../../modules/output-listing/OutputListingContext'
import axios from 'axios';
import { useAppSelector } from '../../../redux/hooks'

const modalsRoot = document.getElementById('root-modals') || document.body
 
const RollBackPrizeMoney = ({show, handleClose}) => {
    const intl = useIntl() 
    const loadingOverlay = useLoadingOverlay()
    const alertDialog = useAlert()
    const {progressBar} = useProgress()
    const {getOutputSelectionAreaIDs, getEventID} = useOutputContext()
    const outputContextUpdater = useOutputContextUpdater()
    const confirmDialog = useConfirm()
    const customerId = useAppSelector(state => state.showCompany.company_id);
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked) 
    const [showModal, setShowModal] = useState(false)
    
    const onClose = () => {
        setShowModal(false)
        handleClose()
    }

    // we are getting entry ids of those entries are are currently present in listing area
    // we will send these entry ids to the backend and the backend will handle apply prize money   
    function rollBackPrizeMoney(current_entry_ids){
        
        let eventID = getEventID("bulk-roll-back-prize-money-tool")
        setShowModal(false)
        // show progress bar
        progressBar({ show: true, eventID, showProgress: 'inline', title: intl.formatMessage({ id: "ENTRIES.ROLLBACKPRIZEMONEY.PROGRESSBAR.MESSAGE" }) })
        axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/bulkRollBackPrizeMoney', {
            params: {
                customer_id: customerId,
                entry_ids: current_entry_ids,
                event_id: eventID
            }
        })
        .then((res) => {
            // close progress bar
            progressBar({ show: false, eventID})
            // Alert user about apply prize money status
            alertDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.ROLLBACKPRIZEMONEY.SUCCESSMESSAGE"}) })
            //refresh list
            outputContextUpdater({action: 'refresh'})
            handleClose()
        })
        .catch((e) => {
        })
        onClose();
    }


    useEffect(() => {
        if (show) {
            if(showFinancialsLocked){
                alertDialog({ title: "warning", message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.ROLLBACKPRIZEMONEY.SHOWLOCKED"}) })
                handleClose()
                return
            }
            loadingOverlay({ show: true })
            getOutputSelectionAreaIDs(false)
            .then(res => { 
                    loadingOverlay({ show: false })
                    // ask user for confirmation
                    confirmDialog({ message: intl.formatMessage({ id: "ENTRIES.QUICKACTION.ROLLBACKPRIZEMONEY.MODAL.CONFIRM"} ) })
                    .then((choice) => {
                        if(!choice){
                            // close the modal if choice is false means user selected no
                            handleClose()
                        }
                        else{
                            // user confirmation by selecting yes
                            rollBackPrizeMoney(res);
                        }
                    })
            })
            .catch(() => loadingOverlay({ show: false }))
        }  
    }, [show])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
            show={showModal}
            onHide={onClose}
        >

        </Modal>
        ,modalsRoot
    )
}

export default RollBackPrizeMoney