import {useIntl} from 'react-intl'
import { useAnnouncerToolContext } from './AnnouncerToolContext';
import { useEffect, useState } from 'react';
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../modules/sgl-utils/SglFunctions';
import axios from 'axios';
import { useAlert, useFlashAlert } from '../../../modules/sgl-utils/DialogsProvider';
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const ScoreBoardTab = (props) => {
  const intl = useIntl();
  const { currentShowID, customerID,  showRings, selectedRing } = useAnnouncerToolContext()
  const [ scoreBoardSelectedRing, setScoreBoardSelectedRing ] = useState(selectedRing)
  const [ message, setMessage ] = useState({Line1: '', Line2:'', Line3:'', Line4:''})
  const alertDialog = useAlert()
  const flashAlert = useFlashAlert()

  const sendScoreBoardMessage = (event, action) => {
      let ring = showRings.filter(ring => ring.ring_id == scoreBoardSelectedRing)//get selected ring from showRings
      let ringNo = ring.length > 0 ? ring[0].ring_number : 0
      let ringName = ring.length > 0 ? ring[0].ring_name : ''
      if(scoreBoardSelectedRing){
        //Convert message json to base64 due to "&" character in data breaking JSON input
        let messageParam = JSON.stringify(message);
        const Buffer = require("buffer").Buffer;
        messageParam = Buffer.from(messageParam).toString('base64');
        loadingSpinnerBtnWait(event);
        axios.get(`${NEST_API_URL}/ingate-announcer/sendScoreBoardMessage?show_id=${currentShowID}&customer_id=${customerID}&ring_no=${ringNo}&ring_id=${scoreBoardSelectedRing}&ring_name=${ringName}&update_type=${action}&message=${messageParam}`)
        .then(response => {
            flashAlert({ type: 'success', message: `${action} update sent to scoreboard.`});
        })
        .catch((error) => {
          if (error.response) {
            alertDialog({message: error.response.data.error});
          }
        })
        .finally(() => {
          loadingSpinnerBtnRelease(event)
        })
      }
  }

  useEffect(() => {
    setScoreBoardSelectedRing(selectedRing)
  },[selectedRing])

  return (
    <div className="container col-5 mt-5 ingate-scoreboard mx-0">
        <div className="row mb-3">
            <div className="col-6 d-flex justify-content-start px-2">
                <button className='btn btn-sm btn-secondary me-3 h-30px text-uppercase fw-bold' type="button" onClick={(e) => sendScoreBoardMessage(e, 'Clear')} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.SCOREBOARD.SECTION.SCOREBOARDMESSAGE.BUTTON.CLEAR">
                    <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1" role="status" aria-hidden="true"></span>
                    {intl.formatMessage({ id: 'ANNOUNCER.TOOL.SCOREBOARD.BUTTON.CLEAR' })}
                </button>
            </div>
            <div className="col-6 d-flex justify-content-end px-2">
                <button className='btn btn-sm btn-dark fw-bold text-uppercase me-3 h-30px fw-bold' type="button" onClick={(e) => sendScoreBoardMessage(e, 'Run Active')} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.SCOREBOARD.SECTION.SCOREBOARDMESSAGE.BUTTON.RUNACTIVE">
                    <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1" role="status" aria-hidden="true"></span>
                    {intl.formatMessage({ id: 'ANNOUNCER.TOOL.SCOREBOARD.BUTTON.RUNACTIVE' })}
                </button>
                <button className='btn btn-sm btn-dark fw-bold text-uppercase h-30px fw-bold' type="button" onClick={(e) => sendScoreBoardMessage(e, 'MARKETING')} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.SCOREBOARD.SECTION.SCOREBOARDMESSAGE.BUTTON.RUNMARKETING">
                    <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1" role="status" aria-hidden="true"></span>
                    {intl.formatMessage({ id: 'ANNOUNCER.TOOL.SCOREBOARD.BUTTON.RUNMARKETING' })}
                </button>
            </div>
        </div>

        <div className="row">
            <div className="px-2">
                <select
                  id="ring"
                  className='form-select py-1'
                  onChange={(e) => setScoreBoardSelectedRing(e.target.value)}
                  value={scoreBoardSelectedRing}
                >
                {
                  showRings.map(ring =>
                  <option key={ring.ring_id} value={ring.ring_id}>{(ring?.ring_name ? ring.ring_name : ring?.Ring?.name)} {ring?.Ring?.active ? '' : '[INACTIVE]' }</option>)
                }
                </select>
            </div>
        </div>

        <div className="row mt-3">
            <div className="px-2 scoreboard-messages">
            <fieldset className='mb-1 mt-0'>
                <legend className="fs-5 fw-bold d-flex py-1">{intl.formatMessage({id: 'ANNOUNCER.TOOL.SCOREBOARD.LEGEND'})}<b></b></legend>
                <input
                    id='Line1'
                    type='text'
                    className='form-control fs-6 h-30px py-1 px-1'
                    onChange={(e) => setMessage((prevMessage) => ({ ...prevMessage, Line1: e.target.value }))}
                    autoComplete='off'
                />

                <input
                    id='Line2'
                    type='text'
                    className='form-control fs-6 h-30px py-1 px-1 mt-2'
                    onChange={(e) => setMessage((prevMessage) => ({ ...prevMessage, Line2: e.target.value }))}
                    autoComplete='off'
                />

                <input
                    id='Line3'
                    type='text'
                    className='form-control fs-6 h-30px py-1 px-1 mt-2'
                    onChange={(e) => setMessage((prevMessage) => ({ ...prevMessage, Line3: e.target.value }))}
                    autoComplete='off'
                />

                <input
                    id='Line4'
                    type='text'
                    className='form-control fs-6 h-30px py-1 px-1 mt-2'
                    onChange={(e) => setMessage((prevMessage) => ({ ...prevMessage, Line4: e.target.value }))}
                    autoComplete='off'
                />
                <div className="col-lg-9 mx-0 mt-3"></div>
                <div className="d-flex justify-content-end">
                  <button type='button' className="btn btn-sm btn-dark fw-bold text-uppercase h-30px fw-bold" onClick={(e) => sendScoreBoardMessage(e, 'General')} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.SCOREBOARD.SECTION.SCOREBOARDMESSAGE.BUTTON.SEND"><span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1" role="status" aria-hidden="true"></span>{intl.formatMessage({id: 'ANNOUNCER.TOOL.SCOREBOARD.BUTTON.SEND'})}</button>
                </div>
              </fieldset>
            </div>
        </div>
    </div>
  );
};

export {ScoreBoardTab};
  