import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useAnnouncerToolContext } from './AnnouncerToolContext';

const SplitViewGrid = (props) => {

    const splitViewGridRef = useRef();
    const intl = useIntl();
    const {updateColumnDefToggle} = useAnnouncerToolContext()

    const suppressKeyboardEvent = (params) => {       
        // Check if the pressed key is the up arrow key (keyCode 38) or the down arrow key (keyCode 40)
        if (params.event.keyCode === 38 || params.event.keyCode === 40) {
        // Suppress the event
        return true;
        }

        // Allow other keyboard events to proceed
        return false;
    }
    
    const defaultColDef = useMemo(() => {
        return {
            resizable: false,
            editable: false,
            wrapHeaderText: true,
            suppressKeyboardEvent: suppressKeyboardEvent
        };
    }, []);

    const onGridReady = useCallback((params) => {
        //update column defs
        updateColumnDefToggle(params?.columnApi)
        params.api.sizeColumnsToFit();
    
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        params.api.sizeColumnsToFit();
    }, []);

    useEffect(() => {
        //update column defs
        updateColumnDefToggle(splitViewGridRef?.current?.columnApi)
    },[props.rowData])

    const SplitViewColumnDefs = [
        { field: 'pos', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.POS'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px', textAlign: 'center','paddingLeft': 0, 'paddingRight': 0}, maxWidth: 70, minWidth: 70, resizable :true },
        { field: 'team', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.TEAM'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, maxWidth: 55, minWidth: 55,
          valueGetter: function getTeam(params) { 
            //Display team abbreviation if not empty otherwise display team name for team scoring class group trips
            if(params?.data?.TeamxClass?.Team?.team_abbr)
            {
              return params?.data?.TeamxClass?.Team?.team_abbr
            }else if(params?.data?.TeamxClass?.Team?.team_name){
              return params?.data?.TeamxClass?.Team?.team_name
            }else{
              return ''
            }
          } 
        },
        { field: 'Entry.number', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.ENTRY'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true,maxWidth: 100  , minWidth: 100 ,headerClass: 'ag-center-aligned-header'},
        { field: 'Entry.horse', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.HORSE'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'left', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, maxWidth: 250  , minWidth: 250  ,headerClass: 'ag-center-aligned-header', cellClass: 'ag-left-aligned-cell'},
        { field: 'rider_name', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.RIDER'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'left', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, maxWidth: 250 , minWidth: 250  ,headerClass: 'ag-center-aligned-header', cellClass: 'ag-left-aligned-cell'},
        { field: 'total_score', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.SCORE'}), sortable: false, suppressMenu: true,cellStyle: {fontSize: '13px', textAlign: 'center','paddingLeft': 0, 'paddingRight': 0},  minWidth: 300, resizable :true, hide: false, suppressColumnsToolPanel: false },
        { field: 'time_one', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.R1T'}), sortable: false , suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, maxWidth: 100, minWidth: 100   ,headerClass: 'ag-center-aligned-header', hide: false, suppressColumnsToolPanel: false },
        { field: 'total_faults_one', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.R1F'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true,maxWidth: 90  , minWidth: 90 ,headerClass: 'ag-center-aligned-header', hide: false, suppressColumnsToolPanel: false},
        { field: 'time_two', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.JOT'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, maxWidth: 90 , minWidth: 90  ,headerClass: 'ag-center-aligned-header', hide: false, suppressColumnsToolPanel: false},
        { field: 'total_faults_two', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.JOF'}), sortable: false, suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, minWidth: 90  ,headerClass: 'ag-center-aligned-header', hide: false, suppressColumnsToolPanel: false},
    ]

    return (
    <>
        <div className='row h-25px'>
            <label className="col-lg-auto col-form-label fs-5 py-1 fw-bold mt-2">{props.label}</label>
        </div>
        <div style={props.splitViewContainerStyle}>
            <div style={props.gridStyle} className="ingate-grid ag-theme-alpine">
            <AgGridReact
                ref={splitViewGridRef}
                defaultColDef={defaultColDef}
                columnDefs={SplitViewColumnDefs}
                rowData={props.rowData}
                rowHeight={20}
                headerHeight={26}
                onFirstDataRendered={onFirstDataRendered}
                onGridReady = {onGridReady}
                overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while trips are loading...</span>'}
            ></AgGridReact>
            </div>
        </div>
    </>
  );
};

export default SplitViewGrid;
  