import { Modal } from "react-bootstrap"
import { createPortal } from "react-dom"
import { KTSVG } from "../../../../../_metronic/helpers"
import { useIntl } from "react-intl"
import TimeRenderer from "../../../../modules/output-listing/renderers/TimeRenderer"
import DateRenderer from "../../../../modules/output-listing/renderers/DateRenderer"
import { useEffect, useRef } from "react"

const modalsRoot = document.getElementById('root-modals') || document.body

const HtmlIframe = ({ htmlContent }) => {
    const iframeRef = useRef(null);
  
    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            iframe.srcdoc = htmlContent;
        }
    }, [htmlContent]);
  
    return (
        <iframe
            ref={iframeRef}
            style={{ width: '100%', height: '500px', border: '1px solid gray' }}
            title="HTML Content"
        />
    );
}

export const CommunicationDetail = ({ show, data, handleClose }) => {
    const intl = useIntl()

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-850px search-form'
            show={show}
            onHide={handleClose}
            onKeyPress={(event) => {
                if (event.key === "Enter") {
                    handleClose()
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-125px fw-bold { width: 145px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">RTO Communication</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose} >
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold'>
                               { intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.CREATIONDATE' }) }
                            </label>
                            <label className='col col-form-label fs-5 py-1'>
                                { DateRenderer({ value: data.created_date })}
                            </label>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold'>
                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.CREATIONTIME' })}
                            </label>
                            <label className='col col-form-label fs-5 py-1'>
                                { TimeRenderer({ value: data.created_time }) }
                            </label>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold'>
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.COMMUNICATIONS.EMAILQUEUEID'})}
                            </label>
                            <label className='col col-form-label fs-5 py-1'>
                                { data.sgl_id}
                            </label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold'>
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.COMMUNICATIONS.SENTDATE'})}
                            </label>
                            <label className='col col-form-label fs-5 py-1'>
                                { DateRenderer({ value: data.sent_date })}
                            </label>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold'>
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.COMMUNICATIONS.SENTTIME'})}
                            </label>
                            <label className='col col-form-label fs-5 py-1'>
                                { TimeRenderer({ value: data.sent_time }) }
                            </label>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold'>
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.COMMUNICATIONS.STATUS'})}
                            </label>
                            <label className='col col-form-label fs-5 py-1'>
                                { data.status }
                            </label>
                        </div>
                        <div className='row mb-2'>   
                            <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold'>
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.COMMUNICATIONS.FROM'})}
                            </label>
                            <label className='col col-form-label fs-5 py-1'>
                                { data.from_email}
                            </label>      
                        </div>
                        <div className="row mb-2">
                            <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold'>
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.COMMUNICATIONS.RECIPIENT'})}
                            </label>
                            <label className='col col-form-label fs-5 py-1'>
                                { data.to_email}
                            </label>
                        </div>
                        <div className="row mb-2">
                            <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold'>
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.COMMUNICATIONS.RECIPIENTNAME'})}
                            </label>
                            <label className='col col-form-label fs-5 py-1'>
                                { data.to_name}
                            </label>
                        </div>
                        <div className="row mb-2">
                            <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold'>
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.COMMUNICATIONS.SUBJECT'})}
                            </label>
                            <label className='col col-form-label fs-5 py-1'>
                                { data.subject}
                            </label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold'>
                                Message
                            </label>
                        </div>
                        <div style={{ maxWidth: '100%', overflow: 'hidden'}}>
                            <HtmlIframe htmlContent={data.email_body} />
                        </div>
                        <div className="row mb-2">
                            <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold'>
                                { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.COMMUNICATIONS.ERROR'})}
                            </label>
                            <label className='col col-form-label fs-5 py-1 text-danger'>
                                { data.error }
                            </label>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={handleClose} autoFocus>
                            {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CLOSE' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )

}